<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span *ngIf="optionItem === 'request'" style="margin-left: 45%; font-size:large">{{ 'Requests' | translate }}</span>
      <span *ngIf="optionItem === 'accessData'" style="margin-left: 45%; font-size:large">{{ 'access data (RGPD)' | translate }}</span>
      <span *ngIf="optionItem === 'labelData'" style="margin-left: 45%; font-size:large">{{ 'label data' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <mat-sidenav-container fxFlexFill class="container-sidenav w-100">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav">
          <div fxLayout="column" *ngIf="isRegisterRequest">
            <a (click)="toggleSidenav('')" *ngIf="doCreateRequest" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}</a>
            <a (click)="toggleSidenav('')" *ngIf="!doCreateRequest" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}</a>
            <a>
              <mdi-request-card [statutCall]="statutRegister" [requestToUpdate]="requestToUpdate" (onload)="ngOnInit()"></mdi-request-card>
            </a>
          </div>
          <div fxLayout="column" *ngIf="statutRegister === 'accessData'">
            <a (click)="toggleSidenav('')" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'dataAccess' | translate}}</a>
            <a>
              <mdi-request-dataaccess [statutCall]="statutRegister" [requestToUpdate]="requestToUpdate" (onload)="ngOnInit()"></mdi-request-dataaccess>
            </a>
          </div>
          <div fxLayout="column" *ngIf="statutRegister === 'labelData'">
            <a (click)="toggleSidenav('')" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'labelData' | translate}}</a>
            <a>
              <mdi-request-datalabel [statutCall]="statutRegister" [requestToUpdate]="requestToUpdate" [userLangCur]="userLangCur" (onload)="ngOnInit()"></mdi-request-datalabel>
            </a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div class="container-fluid">
          <div class="flex-row row stdTheme w-100" >
            <div class="col-4 d-flex justify-content-start align-items-center  " >
              <input type="text"  id="filter-text-box" matInput
                    style="height: 50px;"
                    placeholder="{{ 'global filter' | translate }}" 
                    (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
            </div>
            <div class="col-5 d-flex justify-content-start align-items-center  "  >
             
            </div>
             <div class="col-3 d-flex justify-content-end align-items-center">
              <button *ngIf="optionItem === 'request'" mat-icon-button class="buttonTheme" 
                     style="margin-left: 10px;margin-right: 10px;" (click)="addRequest()">
                <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                      style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
            </div>
          </div>
          <div>
            <ag-grid-angular 
              style="width: 100%; height: 600px;" 
              class="ag-theme-material"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [pagination]="true"
              paginationPageSize=10
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              [rowGroupPanelShow]="rowGroupPanelShow"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<!--https://valor-software.com/ngx-bootstrap/#/modals#confirm-window-->
<ng-template #modalTemmplate>
  <div class="modal-header stdTheme w-100" style="margin: 0px; padding: 0px;" >
    <div class="flex-row row stdTheme w-100" style="margin: 0px; padding: 0px;" >
      <div class="col-10 d-flex justify-content-start align-items-center">
        <h4 class="modal-title pull-left">{{'Test request' | translate}}</h4>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <button type="button" class="close pull-right " mat-icon-button aria-label="Close" 
            style="margin: 0px; padding: 0px;" (click)="modalRef.hide()">
          <span aria-hidden="true" style="font-size: 40px; margin: 0px; padding: 0px;">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body stdTheme">
    <div *ngIf="isTestRequestOK" >
        <p>{{'test request OK' | translate : modalText }}</p>
        <button type="button" class="btn btn-success pull-right " mat-raised-button (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
    <div *ngIf="!isTestRequestOK" >
        <p>{{'test request KO' | translate : modalText }}</p>
        <button type="button" class="btn btn-danger pull-right " mat-raised-button (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
  </div>
</ng-template>

