<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%; overflow-y: auto; height: 600px; max-height: 600px;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span style="margin-left: 45%; font-size:large">{{ 'invoicing' | translate }}</span>
    </div>
  </div>

  <div class="flex-row row  stdTheme" style="padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col-4" style="padding: 0px"> <!-- Pending invoices -->
      <div class="spinner-container  stdTheme" *ngIf="!worksLoaded" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        <br>
        <span> 
            Loading works ....
        </span>
      </div>
      <mat-card class="stdTheme" *ngIf="worksLoaded" style="padding: 0px;margin-top: 25px">
        <mat-card-title style="text-align: center;" >{{ 'billing pending' | translate }}</mat-card-title> 
        <mat-card-content>
          <fieldset >
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-12 table-responsive" style="padding: 0px; margin: 0px;">
                <table class="table stdTheme" id="worksList" style="width: 100%" >
                  <thead class="theadWork">
                    <tr class="trWork">
                      <th style="width: 5%;padding: 1px; text-align: center;vertical-align: middle;">#</th>
                      <th style="width: 15%;padding: 1px; text-align: center;vertical-align: middle;" >{{'workRef' | translate}}</th>
                      <th style="width: 40%;padding: 1px; text-align: center;vertical-align: middle;">{{'workObject' | translate}}</th>
                      <th style="width: 40%;padding: 1px; text-align: center;vertical-align: middle;">{{'workCustomerName' | translate}}</th>

                    </tr>
                  </thead>
                  <tbody class="tbodyWork" >
                    <!--
                      <tr *ngFor="let workCurRow of worksList; let i = index" class="trWork " 
                            [class.data-selected]="i === selectedRow" 
                            (click)="setClickedRow(i)" 
                            (mouseover)="displayDetail($event, i)" 
                            style="width: 100%!important">
                    -->
                      <tr *ngFor="let workCurRow of worksList; let i = index" class="trWork " 
                            [class.data-selected]="i === selectedRow" 
                            (click)="setClickedRow(i)" 
                            style="width: 100%!important">
                        <td style="width: 5%;padding: 1px; text-align: center;"
                          (mouseover)="displayDetail($event, i)" >

                          <span *ngIf="i === selectedRow" 
                                      [mdePopoverTriggerFor]="detailPopover" 
                                      mdePopoverTriggerOn="hover" 
                                      #popoverTrigger="mdePopoverTrigger">
                              <i class="fas fa-asterisk fa-xs"></i>
                          </span>
              
                        </td>    
                        <td style="width: 15%;padding: 1px; text-align: left; vertical-align: middle; " >{{workCurRow.workRef}}</td>
                        <td style="width: 40%;padding: 1px; text-align: left; vertical-align: middle; ">{{workCurRow.workObject}}</td>
                        <td style="width: 40%;padding: 1px; text-align: center; vertical-align: middle;">{{workCurRow.workCustomerName}}</td>
   
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </mat-card-content>
      </mat-card>                
    </div>
    <div class="col-8" style="padding: 0px">
    
      <div *ngIf="displayInvoicingOK" style="margin-bottom: 20px;">
        <mdi-work-recap-amount
            [ihmOptionsCkeck]="ihmOptionsCkeck"
            [statutCall]="'invoicing'" 
            [workCur]="workCur"
            (dataOut)="dataOutInvoicing($event)"
        >
        </mdi-work-recap-amount>
      </div>
    </div>
  </div>
</div>

<mde-popover #detailPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
  <div *ngIf="workCur !== undefined" style="width: 100%!important; min-width: 100%!important;">

      <mdi-works-card 
      [dataIn]="dataInWork" 
      [statutCall]="'popover'"  
      [actionType]="'display'"
      (dataOut)="dataOutWork($event)" 
      (onload)="ngOnInit()"></mdi-works-card>

  </div>
</mde-popover>


