import {Component, OnInit , ViewEncapsulation} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '../../services/translate.service';
import { environment } from '../../../environments/environment';
import { SUPPORTED_LANGS, LangSupported } from '../../i18n/translation';

@Component({
  selector: 'mdi-choice-lang',
  templateUrl: './choice-lang.component.html',
  styleUrls: ['./choice-lang.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChoiceLangComponent implements OnInit {
 
  public translatedText: string;
  public supportedLangs: any[];
    // var default= 'UK';
  public selectedLangage: LangSupported;
  constructor(private _translate: TranslateService) {
  }

  ngOnInit() {
        // standing data
        this.supportedLangs = SUPPORTED_LANGS;

        this.subscribeToLangChanged(); // subscribe to language changes
        const langDefault = `${environment.langDefault}`;
        // set language
        this._translate.setDefaultLang(langDefault); // set English as default
        this._translate.enableFallback(true); // enable fallback
        // set current langage with stored item
        const currentLang = this._translate.currentLang;
        const value = window.localStorage.getItem('rememberLang');
        if (value) {
          const langStored: string = JSON.parse(value);
          this.selectLang(langStored);
        } else {
          // set current langage with default
          this.selectLang(currentLang);
        }
  }
  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    return lang === this._translate.currentLang;
  }
  selectLang(lang: string) {
    // set current lang;
    this._translate.use(lang);
    for (const langObj  of this.supportedLangs) {
      if (langObj.value === lang) {
          this.selectedLangage = langObj;
      }
    }
    window.localStorage.setItem('rememberLang', JSON.stringify(lang));
    const value = window.localStorage.getItem('rememberLang');
    // console.log(JSON.stringify(value));
    // this.refreshText();
    this.translatedText = this._translate.instant('hello world');
  }
  subscribeToLangChanged() {
    // refresh text
    // please unsubscribe during destroy
    return this._translate.onLangChanged.subscribe((x: string) =>
      this.refreshText(x));
    }
  /*
  Appel au service et mise à jour de la proriété
  */
  refreshText(_lang: string) {
    for (const langObj  of this.supportedLangs) {
      if (langObj.value === _lang) {
          this.selectedLangage = langObj;
      }
    }
    // refresh translation when language change
    this.translatedText = this._translate.instant('hello world');
  }
  onChange($event: any) {
    console.log(this.selectedLangage);
    this.selectLang(this.selectedLangage.value);

    // tslint:disable-next-line: deprecation
    // event.preventDefault();
// tslint:disable-next-line: deprecation
    // event.stopPropagation();
  }

}
