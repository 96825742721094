<div #refElement  fxLayout="column" fxLayoutAlign="space-between center" 
                class="mat-elevation-z8 container-fluid stdTheme" style="width: 50%; margin-top: 10px" >
  <mat-card class="register-card stdTheme" >
    <mat-card-actions>
      <div id="registration-error" class="alert alert-danger buttonTheme" *ngIf="registrationFailed ">
        <button type="button" class="close buttonTheme" aria-label="Close" (click)="registrationFailed = false;">
          <!-- <span aria-hidden="true">&#215;</span> -->
        </button>
        {{ messageTranslate }}
      </div>
    </mat-card-actions>
    <form  [formGroup]="grpForm"  >
      <mat-card-content class="stdTheme">
        <div class="flex-row row" >
          <mat-form-field class="mdiselect form-group stdTheme" appearance="standard" >
          <mat-label>{{ 'entity' | translate }}</mat-label>
            <input *ngIf="isUpdateStatut" id="entity" class="form-control inputTheme" formControlName="entity" matInput #input minlength="3" placeholder="{{ 'usergrouplabel' | translate }}"
          required>
            <mat-select *ngIf="!isUpdateStatut" id="entity" #selectEntity placeholder="{{ 'usergrouplabel' | translate }}"      
                        disableOptionCentering formControlName="entity" ngDefaultControl required (selectionChange)="doGetListGrps(selectEntity.value)">
                <mat-option  class="selectOptionsTheme" *ngFor="let entity of entitiesList" [value]="entity">{{ entity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="entityCtrl.invalid">{{getErrorMessage('entityCtrl')}}</mat-error>
          </mat-form-field>
          <!-- </section>-->
        </div>
        <div class="flex-row row">
          <mat-form-field class="mdiinput form-group" appearance="standard" >
            <mat-label>{{ 'usergroup' | translate }}</mat-label>
            <input id="usergroup" class="form-control" formControlName="usergroup" matInput #input minlength="3" placeholder="{{ 'usergroup' | translate }}"
              required >
            <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
            <mat-error *ngIf="usergroupCtrl.invalid">{{getErrorMessage('usergroupCtrl')}}</mat-error>
            <mat-error *ngIf="isUserGroupFound">{{getErrorMessage('usergroupFound')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row row">
          <mat-form-field class="mdiinput form-group" appearance="standard">
            <mat-label>{{ 'usergrouplabel' | translate }}</mat-label>
            <input id="usergrouplabel" class="form-control" formControlName="usergrouplabel" matInput #input minlength="3" placeholder="{{ 'usergrouplabel' | translate }}"
              required>
            <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
            <mat-error *ngIf="usergrouplabelCtrl.invalid">{{getErrorMessage('usergrouplabel')}}</mat-error>
          </mat-form-field>
        </div>
  
        <div class="flex-row row">
          <mat-form-field class="mdiinput form-group" appearance="standard">
            <mat-label>{{ 'supervisoremail' | translate }}</mat-label>
            <input id="supervisoremail" class="form-control" formControlName="supervisoremail" matInput #input minlength="3" placeholder="{{ 'supervisoremail' | translate }}"
              required>
            <mat-error *ngIf="supervisoremailCtrl.invalid">{{getErrorMessage('supervisoremail')}}</mat-error>
          </mat-form-field>
        </div>

        <!-- extended access start -->
        <div class="flex-row row">
          <mat-form-field class="mdiselect form-group" appearance="standard" style="max-height: 50px">
            <mat-label>{{ 'extendedaccess' | translate }}</mat-label>
              <mat-select #selectAccess placeholder="{{ 'Add extended access' | translate }}" 
                        panelClass="mdiselectcontent"
                        disableOptionCentering formControlName="extendedaccess" multiple ngDefaultControl >
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button class="buttonTheme"  (click)="selectAccess.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option  class="selectOptionsTheme" *ngFor="let usergroup of usergroupList" [value]="usergroup">{{ usergroup }}</mat-option>
            </mat-select>
            <mat-error *ngIf="extendedaccessCtrl.invalid">{{getErrorMessage('extendedAccess')}}</mat-error>
          </mat-form-field>
        </div>
        <!-- extended access end -->
        <!-- authorized functions start -->
        <div class="flex-row row">
          <mat-form-field class="selectSize form-group" appearance="standard" style="max-height: 60px">
            <mat-label>{{ 'authorizedFunctions' | translate }}</mat-label>
              <mat-select #selectAuthorizedFunctions placeholder="{{ 'Add authorized functions' | translate }}" 
                       
                        disableOptionCentering formControlName="authorizedFunctions" multiple ngDefaultControl >
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button class="buttonTheme"  (click)="selectAuthorizedFunctions.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme"  *ngFor="let functionAuth of authorizedFunctionsList" [value]="functionAuth">{{ functionAuth }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- authorized functions end -->
        <!-- authorized tags start -->
        <div class="flex-row row">
          <mat-form-field class="selectSize form-group" appearance="standard" style="max-height: 60px">
            <mat-label>{{ 'authorizedTags' | translate }}</mat-label>
              <mat-select #selectAuthorizedTags placeholder="{{ 'authorizedTags' | translate }}" 
                        disableOptionCentering formControlName="authorizedTags" multiple ngDefaultControl >
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button class="buttonTheme"  (click)="selectAuthorizedTags.toggle();updateProhibitedTags()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme"  *ngFor="let tagCur of tagsAuthorized" [value]="tagCur.value">{{ tagCur.display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- authorized tags end -->
        <!-- prohibited tags start -->
        <div class="flex-row row">
          <mat-form-field class="selectSize form-group" appearance="standard" style="max-height: 60px">
            <mat-label>{{ 'prohibitedTags' | translate }}</mat-label>
              <mat-select #selectProhibitedTags placeholder="{{ 'prohibitedTags' | translate }}" 
                        disableOptionCentering formControlName="prohibitedTags" multiple ngDefaultControl >
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button class="buttonTheme"  (click)="selectProhibitedTags.toggle(); updateAuthorizedTags()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme"  *ngFor="let tagCur of tagsNotAuthorized" [value]="tagCur.value">{{ tagCur.display }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

          <!-- prohibited tags end -->
        <!-- data habilitations - start -->
        <div class="flex-row row">

          <div class="mat-elevation-z8 container-fluid" style="padding-top: 10px; margin-top: 20px;">
            <div >
                <h6>{{ 'dataHabilitations' | translate }}</h6>
            </div>
            <mat-table #tableHabil  [dataSource]="dataHabilitations" class="stdTheme">
              <ng-container matColumnDef="dataCategory">
                <th mat-header-cell  class="stdTheme" style="height: 10px;margin: 0px; padding: 0px" *matHeaderCellDef>{{'dataCategory' | translate}}</th>
                <td mat-cell *matCellDef="let row; let index=index" style="height: 10px;margin: 0px; padding: 0px"> 
                  <!--{{row.dataCategory}} -->
                  <mat-form-field id="fieldHabil" class="form-group" appearance="standard">
                      <mat-select id="selectHabil" #selectHabilData placeholder="{{ 'Choose one category' | translate }}" 
                                  panelClass="mdiselectcontent"
                                  ngDefaultControl [(value)]="row.dataCategory" (selectionChange)="setValueArray('dataCategory',selectHabilData.value,index)">
                        <mat-option  class="selectOptionsTheme" id="optionHabil" *ngFor="let dataCateg of dataCategoriesList" [value]="dataCateg.dataCategory">{{ dataCateg.label }}</mat-option>
                      </mat-select>
                  </mat-form-field >
                </td>
              </ng-container>
              <ng-container matColumnDef="level">
                <th mat-header-cell class="stdTheme" style="height: 10px;margin: 0px; padding: 0px" *matHeaderCellDef>{{'dataLevel' | translate }}</th>
                <td mat-cell *matCellDef="let row; let index=index" style="height: 10px;margin: 0px; padding: 0px"> 
                  <!--{{row.dataLevel}} -->
                  <button type="button" class="btn btn-default buttonTheme" 
                        (click)="levelMinus(row)">
                      <span class="fa fa-minus"></span>
                  </button>
                  <mat-form-field class="form-group" style="width: 10px;margin: 5px;">
                    <input type="text" #spinner2 matInput #input style="width: 10px;" [(value)]="row.dataLevel" 
                            (change)="setValueArray('dataLevel',spinner2.value,index)">
                  </mat-form-field>
                  <button type="button" class="btn btn-default buttonTheme" 
                            (click)="levelPlus(row)">
                      <span class="fa fa-plus"></span>
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="actionsColumn">
                <mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button class="buttonTheme" (click)="addNewHabData()">
                    <i class="fa fa-plus mat-icon" style="font-size: 25px;"></i>
                  </button>
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                  <button mat-icon-button class="buttonTheme" focusable="false" (click)="deleteHabData(i)">
                    <i class="fa fa-times mat-icon" style="font-size: 25px;"></i>
                  </button>
                </mat-cell>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="dataHabilitationsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: dataHabilitationsColumns"></tr>
            </mat-table>
          </div>
        </div>
        <!-- data habilitations - end -->
      </mat-card-content>
      <mat-card-actions>
        <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
          <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" (click)="register()"
                    [disabled]="!grpForm.valid || isUserGroupFound">
            <mat-icon>create</mat-icon>
            {{ 'submit' | translate }}
          </button>
          <button mat-raised-button class="buttonTheme" #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
