import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'mdi-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
/*
https://www.npmjs.com/package/@kolkov/angular-editor
https://stackblitz.com/edit/angular-editor-wysiwyg?file=src%2Fapp%2Fapp.component.ts
*/
export class TextEditorComponent implements OnInit, OnChanges {
  editorConfig: AngularEditorConfig;
  htmlContent: string;
  @Input() applicationCall: string;
  @Input() textInit: string;
  @Output() dataOut = new EventEmitter<any>();

  editorForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
 
    this.editorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '50',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      // uploadUrl: 'v1/image',
      // upload: (file: File) => { ... }
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      /*toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
      ]*/
    };
    this.htmlContent = '';

  }
  ngOnChanges(changes: SimpleChanges) {
    
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'textInit': {
            // tslint:disable-next-line:no-string-literal
            this.textInit = changes['textInit'].currentValue;
            break;
          }
          case 'applicationCall': {
            // tslint:disable-next-line:no-string-literal
            this.applicationCall = changes['applicationCall'].currentValue;
            break;
          }
        }
      }
    }
    this.initData();
  }
  initData() {
    this.htmlContent = '';
    
    if (this.textInit) {
      if (this.applicationCall === 'docShared') {
        this.htmlContent = '<p><a href="'+ this.textInit +'"> sharedLink </a> </p>';
        this.dataOut.emit(this.htmlContent);
      } else {
        this.htmlContent = this.textInit;
      }
    }
    this.editorForm = this.formBuilder.group({
      htmlContent: this.htmlContent,
    });
  }
  onChange($event: any): void {
    // console.log($event);
    this.dataOut.emit($event);
  }
}
