<div class="um container-fluid">
  <nav class="navbar navbar-light navbar-toggleable-md fixed-top brandTheme" >
    <!--
    <mat-toolbar-row class="brandTheme">
    <a class="navbar-brand ">
      <img src="../../assets/img/logo.png" alt="Logo" style="width:100px;">
    </a>
  </mat-toolbar-row>
  -->
    <mat-toolbar class="mat-elevation-z24 fixed-top col-*-24">
      <mat-toolbar-row class="brandTheme">
          <div fxFlex fxLayout fxLayoutAlign="start" fxHide.xs >
              <a class="navbar-brand" >
                  <img src="../../assets/img/logo.png" alt="Logo" style="width:100px;">
              </a>
          </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </nav>
</div>
