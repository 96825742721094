/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular-calendar";
import * as i2 from "../../../../../../services/translate.pipe";
import * as i3 from "../../../../../../services/translate.service";
import * as i4 from "./calendar-header.component";
var styles_CalendarHeaderComponent = [];
var RenderType_CalendarHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarHeaderComponent, data: {} });
export { RenderType_CalendarHeaderComponent as RenderType_CalendarHeaderComponent };
export function View_CalendarHeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.ɵi, [i1.CalendarDateFormatter, i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 29, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 12, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "btn btn-primary"], ["mwlCalendarPreviousView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.ɵf, [i1.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"], excludeDays: [2, "excludeDays"] }, { viewDateChange: "viewDateChange" }), (_l()(), i0.ɵted(6, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "btn btn-outline-secondary"], ["mwlCalendarToday", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i1.ɵh, [i1.DateAdapter], { viewDate: [0, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i0.ɵted(10, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "btn btn-primary"], ["mwlCalendarNextView", ""]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, null, 0, i1.ɵg, [i1.DateAdapter], { view: [0, "view"], viewDate: [1, "viewDate"], excludeDays: [2, "excludeDays"] }, { viewDateChange: "viewDateChange" }), (_l()(), i0.ɵted(14, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, ["", ""])), i0.ɵppd(19, 3), (_l()(), i0.ɵeld(20, 0, null, null, 10, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 9, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "div", [["class", "btn "]], [[2, "btn-outline-secondary", null], [2, "btn-primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(23, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(25, 0, null, null, 2, "div", [["class", "btn "]], [[2, "btn-outline-secondary", null], [2, "btn-primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("week") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(26, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["class", "btn"]], [[2, "btn-outline-secondary", null], [2, "btn-primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("day") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(29, null, [" ", " "])), i0.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i0.ɵeld(31, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.viewDate; var currVal_2 = _co.excludeDays; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.viewDate; _ck(_v, 9, 0, currVal_4); var currVal_6 = _co.view; var currVal_7 = _co.viewDate; var currVal_8 = _co.excludeDays; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Calendar.previous")); _ck(_v, 6, 0, currVal_3); var currVal_5 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("Calendar.today")); _ck(_v, 10, 0, currVal_5); var currVal_9 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Calendar.next")); _ck(_v, 14, 0, currVal_9); var currVal_10 = i0.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i0.ɵnov(_v, 0), _co.viewDate, (_co.view + "ViewTitle"), _co.locale)); _ck(_v, 18, 0, currVal_10); var currVal_11 = (_co.view === "month"); var currVal_12 = (_co.view !== "month"); _ck(_v, 22, 0, currVal_11, currVal_12); var currVal_13 = i0.ɵunv(_v, 23, 0, i0.ɵnov(_v, 24).transform("Calendar.month")); _ck(_v, 23, 0, currVal_13); var currVal_14 = (_co.view === "week"); var currVal_15 = (_co.view !== "week"); _ck(_v, 25, 0, currVal_14, currVal_15); var currVal_16 = i0.ɵunv(_v, 26, 0, i0.ɵnov(_v, 27).transform("Calendar.week")); _ck(_v, 26, 0, currVal_16); var currVal_17 = (_co.view === "day"); var currVal_18 = (_co.view !== "day"); _ck(_v, 28, 0, currVal_17, currVal_18); var currVal_19 = i0.ɵunv(_v, 29, 0, i0.ɵnov(_v, 30).transform("Calendar.day")); _ck(_v, 29, 0, currVal_19); }); }
export function View_CalendarHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mdi-calendar-header", [], null, null, null, View_CalendarHeaderComponent_0, RenderType_CalendarHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.CalendarHeaderComponent, [i3.TranslateService], null, null)], null, null); }
var CalendarHeaderComponentNgFactory = i0.ɵccf("mdi-calendar-header", i4.CalendarHeaderComponent, View_CalendarHeaderComponent_Host_0, { view: "view", viewDate: "viewDate", locale: "locale", excludeDays: "excludeDays" }, { viewChange: "viewChange", viewDateChange: "viewDateChange" }, []);
export { CalendarHeaderComponentNgFactory as CalendarHeaderComponentNgFactory };
