<div *ngIf="OKtoDisplayCalculation" class="flex-row row stdTheme" style="width: 100%;margin: 0px;margin-bottom: 20px;">
    <mat-sidenav-container  fxFlexFill class="container-sidenav w-100" autosize  (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop"
            style="min-height: 200px!important; min-width: 100%; width: 100%; margin-bottom: 20px;">
        <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav-95-100" disableClose
            style="min-height: 200px!important; min-width: 100%; width: 100%; margin-bottom: 20px;">
            <div *ngIf="editedRow > -1" >
                <mat-card class="stdTheme w-100"  style="padding: 0px">
                    <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;">
                        <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;overflow: hidden!important">
                            <div class="col-2 d-flex justify-content-center">
                                
                            </div>
                            <div class="col-8 d-flex justify-content-center" style="padding-top: 10px;display:flex; flex-direction:column;">
                                <h6 >{{ 'dataRef' | translate }} : {{editedRow + 1 }} </h6>  
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <button  mat-raised-button  #buttonSubmit  (click)="toggleSidenav('goback')">
                                    <mat-icon>arrow_back</mat-icon>
                                    {{ 'goback' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px;"
                            *ngIf="calculationType === 'localPrice' ">
                            <div class="col-12 d-flex justify-content-start" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'dataLabel' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="dataLabel" #dataLabel  [disabled]="calculationAlgo[editedRow].fixedData === true ? true : isReadOnly"
                                        value="{{calculationAlgo[editedRow].dataLabel}}" style="padding-top: 5px;"
                                        matInput  (change)="onRowInputChange('dataLabel',dataLabel.value, -1)"
                                    /> 
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px;"
                            *ngIf="calculationType === 'importedPrice'">
                            <div class="col-9 d-flex justify-content-start" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'dataLabel' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="dataLabel" #dataLabel  [disabled]="calculationAlgo[editedRow].fixedData === true ? true : isReadOnly"
                                        value="{{calculationAlgo[editedRow].dataLabel}}" style="padding-top: 5px;"
                                        matInput  (change)="onRowInputChange('dataLabel',dataLabel.value, -1)"
                                    /> 
                                </mat-form-field>
                            </div>
                            <div class="col-2 d-flex justify-content-center" 
                                style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: space-between; margin: .5rem;margin-top: 0px;margin-bottom: 0px;padding-top: 38px;">
                                <label  for="duties" 
                                    style="margin-left: 0px; margin-right: 15px;margin-bottom: 0px;" >
                                    {{ 'duties' | translate }}
                                </label>
                                <input type="checkbox" id="duties"  #duties class="inputTheme" name="duties"
                                    style="margin-top: 5px;"
                                    (click)="onRowInputChange('duties',duties.checked, -1)"
                                    [disabled]="!optionDuties"
                                    [checked]="calculationAlgo[editedRow].duties" 
                                />
                            </div>
                        </div>
                        <div class="lineWks" style="margin-top: 0px;margin-bottom: 0px;"></div>
                    </mat-card-actions>
                    <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;overflow-y: scroll; height: 350px; max-height: 350px">
                        <div class="stdTheme" *ngIf="calculationAlgo[editedRow].dataLabel !== ''"  >
                            <div *ngFor="let dataOperationCur of calculationAlgo[editedRow].dataOperations; let idxOperation = index">
                                <ng-container *ngIf="!dataOperationCur.conditionnal" 
                                    [ngTemplateOutlet]="expressionTemplate" 
                                    [ngTemplateOutletContext]="{dataOperationCur: dataOperationCur, idxOperation: idxOperation}">
                                </ng-container>
                                <ng-container *ngIf="dataOperationCur.conditionnal" [ngTemplateOutlet]="conditionTemplate" [ngTemplateOutletContext]="{dataOperationCur: dataOperationCur, idxOperation: idxOperation}"></ng-container>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-sidenav>

        <mat-sidenav-content style="padding-left: 15px">
            <mat-card class="stdTheme w-100"  style="padding: 0px">
                <mat-card-title style="text-align: center;" >
                    <!--
                    <h6>
                        {{ entityCur }} - {{ calculationType | translate }}
                    </h6>
                    -->
                </mat-card-title>
                <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;">
                    <div class="container-fluid d-flex justify-content-between" >
                        <button mat-raised-button  #buttonSubmit type="reset" (click)="doTest()">
                            <mat-icon>settings_backup_restore</mat-icon>
                            {{ 'testAlgo' | translate }}
                        </button>

                        <button  [disabled]="!testOK" mat-raised-button  #buttonSubmit type="submit" (click)="validAlgo()" >
                            <mat-icon>insert_drive_file</mat-icon>
                            {{ 'validAlgo' | translate }}
                        </button>
                    </div>
                </mat-card-actions>
                <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;overflow-y: scroll; height: 450px; max-height: 450px">
                    <div class="stdTheme" *ngFor="let calculationRowCur of calculationAlgo; let idxItem = index"  >
                        <div class="flex-row row rowCalculation" >
                            <div class="col-1">
                                <button mat-icon-button (click)="removeRowCalculation(idxItem)" title="{{'delete row' | translate }}"
                                        [disabled]="idxItem === 0 || calculationRowCur.fixedData === true ? true : isReadOnly">
                                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="col-4 d-flex justify-content-start">
                                <span style="margin-right: 15px;">{{idxItem + 1 }} </span>  
                                {{calculationRowCur.dataLabel }}
                            </div>
                            <!--
                            <div class="col-2 d-flex justify-content-start">{{calculationRowCur.condition }}</div>
                            -->
                            <div class="col-4 d-flex justify-content-start" *ngIf="!calculationRowCur.fixedData">{{calculationRowCur.calculs }}</div>
                            <div class="col-4 d-flex justify-content-end" *ngIf="calculationRowCur.fixedData">{{'testingValue' | translate }}</div>
                            <div class="col-1 d-flex justify-content-end" *ngIf="!calculationRowCur.fixedData && calculationRowCur.currencyFmt"
                                style="padding-left: 0px;padding-right: 0px;" [ngStyle]="{'text-align': numberFormat.justifCurrency}">
                                <div style="clear:both;float:left;width: 90%!important">
                                    {{calculationRowCur.currentValue  | formatNumber : ['price',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                </div>
                                <div style="clear:both;float:left;width: 10%!important;margin-left: 3px">
                                    <span *ngIf="calculationRowCur.currencyImported">{{currencyImported }}</span>
                                    <span *ngIf="!calculationRowCur.currencyImported">{{currencyLocale }}</span>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end" *ngIf="!calculationRowCur.fixedData && !calculationRowCur.currencyFmt && calculationRowCur.numberValue"
                                style="padding-left: 0px;padding-right: 0px;" [ngStyle]="{'text-align': numberFormat.justifCurrency}">
                                {{calculationRowCur.currentValue | formatNumber : ['price',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                            </div>
                            <div class="col-1 d-flex justify-content-end" 
                                *ngIf="!calculationRowCur.fixedData && calculationRowCur.stringValue"
                                style="padding-left: 0px;padding-right: 0px;" >
                                {{calculationRowCur.currentValue }}
                            </div>
                            <!-- start fixed data -->
                            <div class="col-1 d-flex justify-content-end" 
                                *ngIf="calculationRowCur.fixedData && calculationRowCur.stringValue && !calculationRowCur.inputTest"
                                style="padding-left: 0px;padding-right: 0px;" >
                                {{calculationRowCur.currentValue }}
                            </div>
                            <div class="col-1 d-flex justify-content-center" *ngIf="calculationRowCur.fixedData  && calculationRowCur.currencyInput"
                                style="padding-left: 0px;padding-right: 0px;" >
                                
                                <select id="incomingCurrency" class="selectOp inputTheme" #incomingCurrency
                                    (change)="onRowInputChange('incomingCurrency',incomingCurrency.value, idxItem)">

                                    <option *ngFor="let currencyCur of currenciesFilteredList" 
                                            [value]="currencyCur.value"
                                            [selected]="currencyCur.value === calculationRowCur.currentValue"
                                            >{{ currencyCur.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 d-flex justify-content-center" *ngIf="calculationRowCur.fixedData  && calculationRowCur.countryInput"
                                style="padding-left: 0px;padding-right: 0px;" >
                                
                                <select id="countryOrigin" class="selectOp inputTheme" #countryOrigin
                                    (change)="onRowInputChange('countryOrigin',countryOrigin.value, idxItem)">

                                    <option *ngFor="let countryCur of countriesFilteredList" 
                                            [value]="countryCur.value"
                                            [selected]="countryCur.value === calculationRowCur.currentValue"
                                            >{{ countryCur.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 d-flex justify-content-end " 
                                *ngIf="calculationRowCur.fixedData && calculationRowCur.numberValue 
                                        && calculationRowCur.currencyFmt && calculationRowCur.inputTest" 
                                style="padding-left: 0px;padding-right: 0px;">

                                <div  style="clear:both;float:left;width: 90%!important">
                                    <input class="form-control inputTheme" name="currentValue" #currentValue  [disabled]="isReadOnly"
                                    value="{{calculationRowCur.currentValue | formatNumber : ['price',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}"
                                    style="padding-top: 5px" (keypress)="numberAndDotOnly($event)" [ngStyle]="{'text-align': numberFormat.justifCurrency}"
                                    matInput  (change)="onRowInputChange('numberValue',currentValue.value, idxItem)"
                                    /> 
                                </div>
                                <div style="clear:both;float:left;width: 10%!important;margin-left: 3px">
                                    <span *ngIf="calculationRowCur.currencyImported">{{currencyImported }}</span>
                                    <span *ngIf="!calculationRowCur.currencyImported">{{currencyLocale }}</span>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end" 
                                *ngIf="calculationRowCur.fixedData && calculationRowCur.numberValue 
                                        && !calculationRowCur.currencyFmt  && calculationRowCur.inputTest" 
                                style="padding-left: 0px;padding-right: 0px;">
                                <input class="form-control inputTheme" name="currentValue" #currentValue  [disabled]="isReadOnly"
                                    value="{{calculationRowCur.currentValue | formatNumber : ['price',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}"
                                    style="padding-top: 5px;" (keypress)="numberAndDotOnly($event)" [ngStyle]="{'text-align': numberFormat.justifCurrency}"
                                    matInput  (change)="onRowInputChange('numberValue',currentValue.value, idxItem)"
                                /> 
                            </div>
                            <div class="col-1 d-flex justify-content-start" 
                                *ngIf="calculationRowCur.fixedData && calculationRowCur.stringValue 
                                        && !calculationRowCur.currencyInput && !calculationRowCur.countryInput  && calculationRowCur.inputTest" 
                                style="padding-left: 0px;padding-right: 0px;">
                                <input class="form-control inputTheme" name="currentValue" #currentValue  [disabled]="isReadOnly"
                                    value="{{calculationRowCur.currentValue }}"
                                    style="padding-top: 5px;"
                                    matInput  (change)="onRowInputChange('stringValue',currentValue.value, idxItem)"
                                /> 
                            </div>
                            <!-- end fixed data -->
                            <div class="col-1">
                                <button  mat-icon-button (click)="addRowCalculation(idxItem)" [disabled]="isReadOnly" title="{{'adding row' | translate }}">
                                    <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="col-1">
                                <button  mat-icon-button (click)="editRowCalculation(idxItem)" [disabled]="calculationRowCur.fixedData === true ? true : isReadOnly"
                                        title="{{'editing row' | translate }}">
                                    <span class="fa fa-edit" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-sidenav-content>

    </mat-sidenav-container>
</div>
<ng-template #expressionTemplate let-dataOperationCur='dataOperationCur' let-idxOperation = 'idxOperation' >
    <div class="flex-row row rowDataOperation" style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;margin-top: 10px;" >
        <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
            <button mat-icon-button (click)="removeRowOperation(idxOperation)" title="{{'delete row' | translate }}"
                    [disabled]="calculationAlgo[editedRow].dataOperations.length === 1  ? true : isReadOnly" style="margin-top: 0px;">
                <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
            </button>
        </div>
        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
            <select *ngIf="idxOperation > 0" class="selectOp inputTheme" #operande1
            (change)="onOperationInputChange('operande1',operande1.value, idxOperation)">
                <option *ngFor="let operator of operatorsList" 
                        [value]="operator.value"
                        [selected]="operator.value === dataOperationCur.cmdExpression.operande[0]"
                        >{{ operator.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
            <select class="selectOp inputTheme" #input1 
                *ngIf="(idxOperation > 0 && dataOperationCur.cmdExpression.operande[0] !== 'empty') ? true : idxOperation === 0 ? true : false"
                (change)="onOperationInputChange('input1',input1.value, idxOperation)">
                <option *ngFor="let cmdCur of cmdList" 
                        [value]="cmdCur.value"
                        [selected]="cmdCur.value === dataOperationCur.cmdExpression.technicalCodes[0]"
                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                        >{{ cmdCur.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-end" style="height: 50px!important">
            <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -20px;margin-bottom: 0px;text-align: right"
                *ngIf="idxOperation > 0 && dataOperationCur.cmdExpression.operande[0] !== 'empty' && dataOperationCur.cmdExpression.technicalCodes[0] === 'empty' ? true : idxOperation === 0 && dataOperationCur.cmdExpression.technicalCodes[0] === 'empty' ? true : false">
                <mat-label>{{ 'numberValue' | translate }}</mat-label>
                <input class="form-control inputTheme" name="numberValue1" #numberValue1  [disabled]="dataOperationCur.cmdExpression.technicalCodes[0] !== 'empty' ? true : isReadOnly"
                    value="{{dataOperationCur.cmdExpression.numberValues[0]}}" style="padding-top: 5px;"
                    (keypress)="numberAndDotOnly($event)" type="number"
                    matInput  (change)="onOperationInputChange('numberValue1',numberValue1.value, idxOperation)"
                /> 
            </mat-form-field>
        </div>
        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
            <select class="selectOp inputTheme" #operande2
            (change)="onOperationInputChange('operande2',operande2.value, idxOperation)">
                <option *ngFor="let operator of operatorsList" 
                        [value]="operator.value"
                        [selected]="operator.value === dataOperationCur.cmdExpression.operande[1]"
                        >{{ operator.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
            <select class="selectOp inputTheme" #input2 *ngIf="dataOperationCur.cmdExpression.operande[1] !== 'empty'"
            (change)="onOperationInputChange('input2',input2.value, idxOperation)">
                <option *ngFor="let cmdCur of cmdList" 
                        [value]="cmdCur.value"
                        [selected]="cmdCur.value === dataOperationCur.cmdExpression.technicalCodes[1]"
                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                        >{{ cmdCur.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-end">
            <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -20px;margin-bottom: 0px;text-align: right"
                            *ngIf="dataOperationCur.cmdExpression.operande[1] !== 'empty' && dataOperationCur.cmdExpression.technicalCodes[1] === 'empty'">
                <mat-label>{{ 'numberValue' | translate }}</mat-label>
                <input class="form-control inputTheme" name="numberValue2" #numberValue2  [disabled]="dataOperationCur.cmdExpression.technicalCodes[1] !== 'empty' ? true : isReadOnly"
                    (keypress)="numberAndDotOnly($event)" type="number"
                    value="{{dataOperationCur.cmdExpression.numberValues[1]}}" style="padding-top: 5px;"
                    matInput  (change)="onOperationInputChange('numberValue2',numberValue2.value, idxOperation)"
                /> 
            </mat-form-field>
        </div>
        <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
            <button  mat-icon-button class="customIconSize" (click)="addRowOperation(idxOperation, 'expression')" 
                style="margin-top: 0px;padding-right: 0px;padding-left: 0px;margin-left: 10px;" 
                [disabled]="isReadOnly" title="{{'adding expression' | translate }}">
                <span class="fa fa-plus"  aria-hidden="true"></span>
            </button>
            <button  mat-icon-button class="customIconSize" (click)="addRowOperation(idxOperation, 'conditionnal')" 
                style="margin-top: 0px;padding-right: 0px;padding-left: 0px;margin-left: 10px;" 
                [disabled]="isReadOnly" title="{{'adding condition' | translate }}">
                <span class="fa fa-cog"  aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <div class="lineWks" style="margin-top: 5px;margin-bottom: 5px;"></div>
</ng-template>

<ng-template #conditionTemplate let-dataOperationCur='dataOperationCur' let-idxOperation = 'idxOperation'>
    <fieldset class="conditional-border " style="margin-top: 15px; margin-bottom: 10px">
        
        <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;margin-bottom: 5px;margin-top: 10px;" >
            
            <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
                <button mat-icon-button (click)="removeRowOperation(idxOperation)" title="{{'delete row' | translate }}"
                        [disabled]="calculationAlgo[editedRow].dataOperations.length === 1  ? true : isReadOnly" style="margin-top: 0px;">
                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                </button>
            </div>
            <div class="col-1 d-flex justify-content-center" 
                style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;  display:flex; flex-direction:column;">
                <h6 >{{ 'condExpression' | translate }}</h6>
            </div>
            <div class="col-9 clauseTheme " >
                <div  *ngFor="let condClause of dataOperationCur.conditionnalExpression.condClauses; let idxClause = index" style="width: 100%!important" >
                    <div  class="flex-row row rowDataOperation"  >
                        <div class="col-1 d-flex justify-content-center" >
                            <button mat-icon-button (click)="removeRowClauseCond(idxOperation, idxClause)" title="{{'delete row' | translate }}"
                            [disabled]="dataOperationCur.conditionnalExpression.condClauses.length === 1  ? true : isReadOnly" style="margin-top: 0px;">
                        <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                    </button>
                        </div>
                        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
                            <select class="selectOp inputTheme" #andOrInput *ngIf="idxClause > 0"
                                (change)="onCondClauseInputChange('andOrInput',andOrInput.value, idxOperation, idxClause)">
                                <option *ngFor="let andOrCur of andOrList" 
                                        [value]="andOrCur.value"
                                        [selected]="andOrCur.value === condClause.andOr"
                                        >{{ andOrCur.label }}</option>
                            </select>
                        </div>
                        <div class="col-3 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
                            <select class="selectOp inputTheme" #condInput1
                                (change)="onCondClauseInputChange('condInput1',condInput1.value, idxOperation, idxClause)">
                                <option *ngFor="let cmdCur of cmdList" 
                                        [value]="cmdCur.value"
                                        [selected]="cmdCur.value === condClause.technicalCodes[0]"
                                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                                        >{{ cmdCur.label }}</option>
                            </select>
                        </div>
                        
                        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
                            <select class="selectOp inputTheme" #comparatorInput
                            (change)="onCondClauseInputChange('comparatorInput',comparatorInput.value, idxOperation, idxClause)">
                                <option *ngFor="let comparatorCur of comparatorsList" 
                                        [value]="comparatorCur.value"
                                        [selected]="comparatorCur.value === condClause.comparator"
                                        >{{ comparatorCur.label }}</option>
                            </select>
                        </div>
                        <div class="col-2 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
                            <select class="selectOp inputTheme" #condInput2 *ngIf="condClause.comparator !== 'empty'"
                            (change)="onCondClauseInputChange('condInput2',condInput2.value, idxOperation, idxClause)">
                                <option *ngFor="let cmdCur of cmdList" 
                                        [value]="cmdCur.value"
                                        [selected]="cmdCur.value === condClause.technicalCodes[1]"
                                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                                        >{{ cmdCur.label }}</option>
                            </select>
                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -20px;margin-bottom: 0px;text-align: right"
                                            *ngIf="condClause.comparator !== 'empty' && condClause.technicalCodes[1] === 'empty'">
                                <mat-label>{{ 'numberValue' | translate }}</mat-label>
                                <input class="form-control inputTheme" name="condDataValue" #condDataValue  [disabled]="isReadOnly"
                                    (keypress)="numberAndDotOnly($event)" type="number"
                                    value="{{condClause.numberValues[1]}}" style="padding-top: 5px;"
                                    matInput  (change)="onCondClauseInputChange('condDataValue',condDataValue.value, idxOperation, idxClause)"
                                /> 
                            </mat-form-field>
                        </div>
                        <div class="col-1 d-flex justify-content-center" >
                            <button  mat-icon-button (click)="addRowClauseCond(idxOperation, idxClause)" style="margin-top: 0px;padding-right: 0px;padding-left: 0px;" [disabled]="isReadOnly" title="{{'adding expression' | translate }}">
                                <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
                <button  mat-icon-button class="customIconSize" (click)="addRowOperation(idxOperation, 'expression')" 
                    style="margin-top: 0px;padding-right: 0px;padding-left: 0px;margin-left: 10px;" 
                    [disabled]="isReadOnly" title="{{'adding expression' | translate }}">
                    <span class="fa fa-plus"  aria-hidden="true"></span>
                </button>
                <button  mat-icon-button class="customIconSize" (click)="addRowOperation(idxOperation, 'conditionnal')" 
                    style="margin-top: 0px;padding-right: 0px;padding-left: 0px;margin-left: 10px;" 
                    [disabled]="isReadOnly" title="{{'adding condition' | translate }}">
                    <span class="fa fa-cog"  aria-hidden="true"></span>
                </button>
            </div>
            <div *ngIf="dataOperationCur.conditionnalExpression.nestedExpressions.length > 0" style="width: 100%!important;margin-top: 10px;">
                <div *ngFor="let dataOperationCond of dataOperationCur.conditionnalExpression.nestedExpressions; let idxCondOperation = index">
                    <ng-container 
                        [ngTemplateOutlet]="condExpressionTemplate" 
                        [ngTemplateOutletContext]="{dataOperationCond: dataOperationCond, idxOperation: idxOperation ,idxCondOperation: idxCondOperation}">
                    </ng-container>
                </div>
            </div>
            <!--<div class="lineWks" style="margin-top: 5px;"></div>-->
        </div>
    </fieldset>
</ng-template>

<ng-template #condExpressionTemplate let-dataOperationCond='dataOperationCond' let-idxOperation = 'idxOperation' let-idxCondOperation = 'idxCondOperation' >
    <div class="flex-row row rowDataOperation" style="margin-left: 0px;margin-right: 0px;margin-bottom: 15px;" >
        <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
            <button mat-icon-button (click)="removeRowCondOperation(idxOperation, idxCondOperation)" title="{{'delete row' | translate }}"
                    [disabled]="calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions.length === 1  ? true : isReadOnly" style="margin-top: 0px;">
                <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
            </button>
        </div>
        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
            <select *ngIf="idxCondOperation > 0" class="selectOp inputTheme" #condExpOperande1
            (change)="onCondOperationInputChange('condExpOperande1',condExpOperande1.value, idxOperation, idxCondOperation)">
                <option *ngFor="let operator of operatorsList" 
                        [value]="operator.value"
                        [selected]="operator.value === dataOperationCond.operande[0]"
                        >{{ operator.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
            <select class="selectOp inputTheme" #condExpInput1 
                *ngIf="(idxCondOperation > 0 && dataOperationCond.operande[0] !== 'empty') ? true : idxCondOperation === 0 ? true : false"
                (change)="onCondOperationInputChange('condExpInput1',condExpInput1.value, idxOperation, idxCondOperation)">
                <option *ngFor="let cmdCur of cmdList" 
                        [value]="cmdCur.value"
                        [selected]="cmdCur.value === dataOperationCond.technicalCodes[0]"
                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                        >{{ cmdCur.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-end" style="height: 50px!important">
            <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -20px;margin-bottom: 0px;text-align: right"
                *ngIf="idxCondOperation > 0 && dataOperationCond.operande[0] !== 'empty' && dataOperationCond.technicalCodes[0] === 'empty' ? true : idxCondOperation === 0 && dataOperationCond.technicalCodes[0] === 'empty' ? true : false">
                <mat-label>{{ 'numberValue' | translate }}</mat-label>
                <input class="form-control inputTheme" name="condExpDataValue1" #condExpDataValue1  [disabled]="dataOperationCond.technicalCodes[0] !== 'empty' ? true : isReadOnly"
                    value="{{dataOperationCond.numberValues[0]}}" style="padding-top: 5px;"
                    (keypress)="numberAndDotOnly($event)" type="number"
                    matInput  (change)="onCondOperationInputChange('condExpDataValue1',condExpDataValue1.value, idxOperation, idxCondOperation)"
                /> 
            </mat-form-field>
        </div>
        <div class="col-1 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;padding-left: 0px;padding-right: 0px;">
            <select class="selectOp inputTheme" #condExpOperande2
            (change)="onCondOperationInputChange('condExpOperande2',condExpOperande2.value, idxOperation, idxCondOperation)">
                <option *ngFor="let operator of operatorsList" 
                        [value]="operator.value"
                        [selected]="operator.value === dataOperationCond.operande[1]"
                        >{{ operator.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-start" style="height: 50px!important;padding-top: 10px;">
            <select class="selectOp inputTheme" #condExpInput2 *ngIf="dataOperationCond.operande[1] !== 'empty'"
            (change)="onCondOperationInputChange('condExpInput2',condExpInput2.value, idxOperation, idxCondOperation)">
                <option *ngFor="let cmdCur of cmdList" 
                        [value]="cmdCur.value"
                        [selected]="cmdCur.value === dataOperationCond.technicalCodes[1]"
                        [disabled]="checkCmdSelection(cmdCur, idxOperation)"
                        >{{ cmdCur.label }}</option>
            </select>
        </div>
        <div class="col-2 d-flex justify-content-end">
            <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -20px;margin-bottom: 0px;text-align: right"
                            *ngIf="dataOperationCond.operande[1] !== 'empty' && dataOperationCond.technicalCodes[1] === 'empty'">
                <mat-label>{{ 'numberValue' | translate }}</mat-label>
                <input class="form-control inputTheme" name="condExpDataValue2" #condExpDataValue2  [disabled]="dataOperationCond.technicalCodes[1] !== 'empty' ? true : isReadOnly"
                    value="{{dataOperationCond.numberValues[1]}}" style="padding-top: 5px;"
                    (keypress)="numberAndDotOnly($event)" type="number"
                    matInput  (change)="onCondOperationInputChange('condExpDataValue2',condExpDataValue2.value, idxOperation, idxCondOperation)"
                /> 
            </mat-form-field>
        </div>
        <div class="col-1" style="height: 50px!important;padding-right: 0px;padding-left: 0px;">
            <button  mat-icon-button (click)="addRowCondOperation(idxOperation, idxCondOperation)" style="margin-top: 0px;padding-right: 0px;padding-left: 0px;" [disabled]="isReadOnly" title="{{'adding expression' | translate }}">
                <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
            </button>

        </div>
    </div>
</ng-template>
