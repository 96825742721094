import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalEventsManagerService } from '../../../job/common/services/global-events-manager.service';
import { TranslateService } from '../../../services/translate.service';
import { ModalCommonComponent } from '../../../job/common/components/modal-common/modal-common.component';
import { UserService } from '../../../services/user.service';
import { UserCard } from '../../../models/user.model';

@Component({
  selector: 'mdi-update-pwd',
  template: ''
})
export class UpdatePwdComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  constructor( private _userService: UserService,
                private router: Router,
                private _translate: TranslateService,
                private _globalEventsManagerService: GlobalEventsManagerService,
                private _dialog: MatDialog) { }

  ngOnInit() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate('updatePwdModal'),
      typeDialog: 'updatePwd',
      panelClass: 'stdTheme'
      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);
    this.subscription = this._globalEventsManagerService.synchroEvents.subscribe( event => {

      if (event === 'cancelModal') {
        this.router.navigate(['/jobHome']);
      } else if (event) {
        const typeEvent = typeof event;
        // const eventString = JSON.stringify(event).toString();
        const eventString = String(event);
        console.log(typeEvent);
        if (eventString.startsWith('newPwd:')) {
          const pwdArray = eventString.split(':');
          this.register(pwdArray[1]);
          this.router.navigate(['/jobHome']);
        }
      }
      // this.router.navigate(['/jobHome']);
    });
    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        console.log('Dialog output:', data);

        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      });

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  register(newPwd: string) {
    const userCard = new UserCard();
    const statutCall = 'updatePwd';
    userCard.password = newPwd;
    userCard.username = this._userService.getUserLogged().username;

    this._userService.register(userCard, statutCall)
      .subscribe(
        () => {
          if (statutCall) {
            this._userService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/jobHome']);
          }
        },
        () => {
          // this.messageTranslate = this._translate.getTranslate('Validation error');
        }
      );
  }
}
