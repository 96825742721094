
export interface Telephone {
    ITU_T_Code?: string;
    domesticFmt?: string;
    domesticDisplay?: string;
    E_164_Fmt?: string;
    E_164_Display?: string;
    phoneLengthMax?: string;
    domesticMask: string;
}

export class PhoneInput {
    controlFmt: string;
    displayFmt: string;
    phoneLengthMax: string;
    phoneLength?: string;
    phoneCountry: string;
    phonePrefix: string;
    phoneNumber: string;
    phoneType: string;
    inputError?: boolean;
}

export class EmailInput {
    controlFmt: string;
    displayFmt: string;
    emailAddress: string;
    emailType: string;
    inputError?: boolean;
}

export interface ElemAddr {
    elmtNumber: string;
    lineNumber: string;
    lineDataIndex: string;
    dataName: string;
    dataMandatory: string;
    dataInput: string;
    dataValue: string;
    dataType: string;
    dataLengthMax: string;
    controlType?: string;
    controlFmt?: string;
    displayFmt?: string;
    elemCountry: string;
    elemLabel?: string;
    inputValue?: string;
    latinValue?: string;
    inputError?: boolean;
    elemUpperCase?: boolean;
    enable?: boolean;
}
export interface StatesList {
    stateName: string;
    stateAbb: string;
}
export interface Address {
    nbLinesMax: number;
    nbLinesMin: number;
    uppercaseLastLines: string;
    elemAddr: ElemAddr[];
    citiesCountry?: CitiesCountry[];
    statesList?: StatesList[];
}
export interface LocalAddress {
    nbLinesMax: number;
    nbLinesMin: number;
    uppercaseLastLines: string;
    elemAddr: ElemAddr[];
    citiesCountry?: CitiesCountry[];
    statesList?: StatesList[];
  }
export interface CtrlAddr {
    ctrlName: string;
    elemList: string;
    elemAction: string;
    elemExpression: string;
}
export interface CitiesCountry {
    nameCity: string;
}
export interface CountryDef {
    isoNum: string;
    isoAlpha2: string;
    isoAlpha3: string;
    commonName: string;
    currency?: string;
    measurementUnit?:  string;
    telephone?: Telephone;
    address?: Address;
    localAddress?: Address;
    sovereignty: string;
    timezones?: string[];
    taxation: Taxation;
    customRules?: string;
}
export interface CountryLib  {
    isoAlpha2: string;
    commonName: string;
  }
export interface TaxRow {
    taxPercent: number;
    taxName: string;
    taxBase: string;
}
export interface ProfileTax {
    profileName: string;
    taxRows: TaxRow[];
}
export interface Taxation {
    profilesTax: ProfileTax[];
}
export interface CountryLabel {
    isoAlpha2: string;
    commonName: string;
    disabled?: boolean;
    selected?: boolean;
}

export interface StatesLabel {
    stateName: string;
    stateAbb: string;
    disabled?: boolean;
    selected?: boolean;
}
export interface Identity {
    cntCivility: string;
    cntFirstname: string;
    cntLastname: string;
}
export class ContactInput {
    identity: Identity;
    phones: PhoneInput[];
    emails: EmailInput[];
}
export interface ContactInt {
    id?: string;
    statut: string;
    selectApps: string;
    identity: Identity;
    phones: PhoneInput[];
    emails: EmailInput[];
    fonctionDept?: string;
    selectDept?: string;
    isCollapsed?: boolean;
}
/*
export interface ShareDataInt {
    isProfessionnal: boolean;
    isAdministration: boolean;
    isPerson: boolean;
    isCompany: boolean;
    isIndividual: boolean;
  }

*/
export interface Currencies {
    iso: string;
    label: string;
}
export interface InternationalsFormats {
    datetime: NameValue[];
    numbers: NameValue[];
    separators: NameValue[];
    justifs: NameValue[];
}
export interface NameValue {
    name: string;
    value: string;
}

export interface NameValueArgs {
    nameArg: string;
    valueArg: string;
}
export interface EconomicAreas {
    eaId: string;
    countriesMembers: string[];
    dutyFreeZones: string[];
}

export interface LabelValue {
    label: string;
    value: any;
    otherData?: any;
    hidden?: boolean;
    otherValues?: any[];
    additionnalValue?: any;
  }

export interface FieldValue {
    name?: string;
    label?: string;
    value?: any;
    latin?: any;
    values?: any[];
  }
export interface FieldInput {
    value?: any;
    initValue?: string;
    inputError?: boolean;
  }
export interface GoogleMaps { 
    isModal: boolean;
    action: string;
    addressInput?: string;
    displayLocal: boolean;
    zoom?: number;
    mapOptions?: google.maps.MapOptions;
    markers?: any[];
    position?: { 
        lat: number; 
        lng: number;
     };
     style?: { 
        width: string; 
        height: string;
     };
}
export interface LatLng  { 
    lat: number; 
    lng: number;
}
export interface GeoValues {
    latLng?: LatLng;
    latLngs?: LatLng[];
    address?: string;
    addresses?: string[];
    status?: any;
}

export interface EmailData {
    from: string;
    recipients: string;
    copy: string;
    ccopy: string;
    subject: string;
    body: string;
    attachmentPath: string;
}
