<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span style="margin-left: 45%; font-size:large">{{ 'docs' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <div *ngIf="displayType === 'add' || displayType === 'search' || displayType === 'newsearch' || displayType === 'update'">
      <mdi-doc-card 
          [statutCall]="'direct'" 
          [optionItem]="'docs'" 
          [actionType]="displayType" 
          [docToUpdate]="wksDocToUpdate">
      </mdi-doc-card>
    </div>
    <mat-sidenav-container *ngIf="displayType === 'list'" fxFlexFill class="container-sidenav w-100" autosize>
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav-50">
          <div fxLayout="column" *ngIf="isRegisterDoc">
            <a (click)="toggleSidenav('')" *ngIf="doCreateDoc" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}
              <a> ({{ 'docs' | translate }})</a>
            </a>
            <a (click)="toggleSidenav('')" *ngIf="!doCreateDoc" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}
              <a> ({{ 'docs' | translate }})</a>
            </a>
            <a>
            <mdi-doc-card style="width: 100%;"
              [statutCall]="'sidenav'" 
              [optionItem]="'docs'" 
              [actionType]="actionType" 
              [docToUpdate]="wksDocToUpdate">
            </mdi-doc-card>
            </a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div class="container-fluid" style="height: 100%;">
          <div class="flex-row row stdTheme w-100" >
            <div class="col-4 d-flex justify-content-start align-items-center  " >
              <input type="text"  id="filter-text-box" matInput
                    style="height: 50px;"
                    placeholder="{{ 'global filter' | translate }}" 
                    (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
            </div>
            <div class="col-2 d-flex justify-content-start align-items-center  "  >
             
            </div>
             <div class="col-6 d-flex justify-content-end align-items-center">
              <button 
                      mat-icon-button class="buttonTheme" 
                     style="margin-left: 10px;margin-right: 10px;" (click)="addDoc()">
                <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                      style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
              <button *ngIf="isInjected" mat-icon-button class="buttonTheme"  (click)="newsearch()" 
                  style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-search" style="font-size: 20px" ></span>
              </button>
                <!-- <share-button button="twitter" theme="circles-dark" [icon]="['fab', 'twitter-square']"></share-button>-->
                <!--
              <share-button *ngIf="doShared" [theme]="'modern-dark'" 
                    [button]="'whatsapp'" 
                    [showText]="true" 
                    [url]="currentSharedLink" 
                    [description]="currentLabelLink" 
                    [autoSetMeta]="false"
                >
              </share-button>
              <share-button *ngIf="doShared" [theme]="'modern-dark'" 
                    [button]="'sms'" 
                    [showText]="true" 
                    [url]="currentSharedLink" 
                    [description]="currentLabelLink" 
                    [autoSetMeta]="false"
                >
              </share-button>
              <share-button *ngIf="doShared" [theme]="'modern-dark'" 
                  [button]="'email'" 
                  [showText]="true" 
                  [url]="currentSharedLink" 
                  [description]="currentLabelLink" 
                  [autoSetMeta]="false"
                >
              </share-button>
              -->
            </div>
          </div>
          <div style="height: 90%;">
            <ag-grid-angular 
              style="width: 100%; height: 100%;" 
              class="ag-theme-material"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [localeText]="localeText"
              [pagination]="true"
              [paginationAutoPageSize]="true"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<ng-template #pdfModalViewer style="width: 100%">
    <div class="modal-body">
      <mdi-doc-pdf [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-pdf>
    </div>
    <div class="modal-footer ">
      <!--<p>{{modalText}}</p>-->
      <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
      </div>
    </div>
</ng-template>
<ng-template #imgModalViewer style="width: 100%">
    <div class="modal-body">
      <mdi-doc-image [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-image>
    </div>
    <div class="modal-footer ">
      <!--<p>{{modalText}}</p>-->
      <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
      </div>
    </div>
</ng-template>
<ng-template #sharedMailModal style="width: 100%">
  <div class="modal-body">
    <mdi-email-form  [applicationCall]="'docShared'"  
                    [entity]="entityCur" [emailDataInput]="emailData" (dataOut)="closeEmailForm($event)"></mdi-email-form>
  </div>
  <!--
  <div class="modal-footer ">

    <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
      <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
  </div>
  -->
</ng-template>