/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reload-config.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../job/common/components/topbar/topbar.component.ngfactory";
import * as i3 from "../../../job/common/components/topbar/topbar.component";
import * as i4 from "../../../services/user.service";
import * as i5 from "../../../services/theme.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/translate.service";
import * as i9 from "../../../job/job-wks/services/wks-central.service";
import * as i10 from "../../../services/translate.pipe";
import * as i11 from "./reload-config.component";
import * as i12 from "../../../job/common/services/remote-files.service";
import * as i13 from "@angular/material/dialog";
var styles_ReloadConfigComponent = [i0.styles];
var RenderType_ReloadConfigComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReloadConfigComponent, data: {} });
export { RenderType_ReloadConfigComponent as RenderType_ReloadConfigComponent };
export function View_ReloadConfigComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-topbar", [], null, null, null, i2.View_TopbarComponent_0, i2.RenderType_TopbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopbarComponent, [i4.UserService, i5.ThemeService, i6.AuthService, i7.Router, i8.TranslateService, i9.WksCentralService], { isHome: [0, "isHome"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "mat-elevation-z8 container-fluid "], ["style", "margin-top: 75px; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "flex-row row"], ["style", "margin-left: 5px;margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-12 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h6", [["style", "padding-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(0, i10.TranslatePipe, [i8.TranslateService]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex-row row"], ["style", "margin-left: 5px;margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-2 d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadWebdav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", " "])), i1.ɵpid(0, i10.TranslatePipe, [i8.TranslateService])], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("reloadConfig")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("reloadWebdav")); _ck(_v, 11, 0, currVal_2); }); }
export function View_ReloadConfigComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-reload-config", [], null, null, null, View_ReloadConfigComponent_0, RenderType_ReloadConfigComponent)), i1.ɵdid(1, 114688, null, 0, i11.ReloadConfigComponent, [i12.RemoteFilesService, i13.MatDialog, i4.UserService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReloadConfigComponentNgFactory = i1.ɵccf("mdi-reload-config", i11.ReloadConfigComponent, View_ReloadConfigComponent_Host_0, {}, {}, []);
export { ReloadConfigComponentNgFactory as ReloadConfigComponentNgFactory };
