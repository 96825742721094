import { Component, OnInit, ViewEncapsulation, SimpleChanges, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { WksEntityParams } from '../../../../models/wks-common.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { WksWorksModel, DataWorkModel, WorkEquipmentRef } from '../../../../models/wks-works.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { UserResponse } from '../../../../../../models/user.model';
import { CalendarEvt, CalendarEventBdd, EventChanged, WorkingTime } from '../../../../../../models/common.model';
import { MatDialog } from '@angular/material';
import { MdePopoverTrigger } from '@material-extended/mde';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { CalendarService } from '../../../../../common/services/calendar.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { InternationalsFormats } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-works-planning',
  templateUrl: './works-planning.component.html',
  styleUrls: ['./works-planning.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WorksPlanningComponent implements OnChanges {
  
  @Input() actorSelected: UserResponse;
  @Input() workCur: WksWorksModel;
  @Output() eventClickedOut = new EventEmitter<EventChanged>();
  
  @ViewChild(MdePopoverTrigger, { static: false }) localPopover: MdePopoverTrigger;
  @ViewChild('detailPopover', { static: false }) detailPopover: MdePopoverTrigger;
  
  private readonly onDestroy = new Subject<void>();

  wksEntity: WksEntityModel;
  mechanicUsers: UserResponse[];

  wksEntityParams: WksEntityParams;
  isModalMode: boolean;
  worksLoaded: boolean;
  planningLoaded: boolean;
  mechanicUsersLoaded: boolean;
  dotsLine: string;
  cacheDatatemp: any[];
  worksList: WksWorksModel[];
  eventsList: CalendarEvt[];
  calendardEventLBddist: CalendarEventBdd[];

  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;
  doLoadCalendar: boolean;
  workingTime: WorkingTime;
  displayPopover: boolean;
  dataInWork: DataWorkModel;

  constructor(private _dialog: MatDialog,
    private _userService: UserService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _calendarService: CalendarService, 
    private _ressourcesService: RessourcesService,
    private adapterDate: DateAdapter<any>, 
    private dialogRef: MatDialogRef<WorksPlanningComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.actorSelected = data.actorSelected;
      this.workCur = data.workCur;
      if (data) {
        this.initData();
        this.isModalMode = true;
      }

    }

  ngOnChanges(changes: SimpleChanges) {
    
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actorSelected': {
            this.actorSelected = changes['actorSelected'].currentValue;
            this.isModalMode = false;
            break;
          } // end case
          case 'workCur': {
            this.workCur = changes['workCur'].currentValue;
            this.isModalMode = false;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {
    if (this._userService.getUserLogged() === undefined)  { return ; }
    this.initAllBooleans();
    this.loadParams();
  }
  initAllBooleans() {

    this.worksLoaded = false;
    this.planningLoaded = false;
    this.doLoadCalendar = false;
    this.mechanicUsersLoaded = false;
    this.displayPopover = false;
  }
  loadParams() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.settingIntFmts();
    this.wksEntityParams = this._ressourcesService.getWksEntityParams();
    this.workingTime = {
      dayHours: this.wksEntityParams.dayHours,
      weekStartsOn: this.wksEntityParams.weekStartsOn,
      weekendDays: this.wksEntityParams.weekendDays,
    };
    this.getListMechanics(this._userService.getUserLogged().entity)
    .then (
      (responseMechanics: any ) => {
        this.mechanicUsers = responseMechanics;
        this.mechanicUsersLoaded = true;
        if (this.actorSelected) {
          const event = { index: -1, value: this.actorSelected.username };
          this.onChangeMechanic(event);
        }
      },
      (err: any) => {
        this.mechanicUsersLoaded = true;
        console.log('getListMechanics' + err.message);
        if (err.status === 404) {
        }
      }
    );
    
  }
  
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
    // this.localelang = userLang;
    this.localelang =  this._translate.currentLang;
    this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
  }
  getListMechanics(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this._userService.getEntityUsersList(entityCur, 'MECHANIC')
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('getListMechanics' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }

  loadEvents(entityCur: string, actor: string): any {
    let listActors: string;
    if (actor === undefined) {
      for (const actorCur of this.mechanicUsers) {
        if (listActors === undefined) {
          listActors = actorCur.username;
        } else {
          listActors += ';' + actorCur.username;
        }
      }
    } else {
      listActors = actor;
    }

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActors(entityCur, listActors, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, 
        err => {
          console.log('loadEvents' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadEventsDate(entityCur: string): any {
    let listActors: string;
    for (const actor of this.mechanicUsers) {
      if (listActors === null) {
        listActors = actor.username;
      } else {
        listActors += ';' + actor.username;
      }
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const startDate = moment({year: year, month: month, day: day - 1 }).toDate();
    const endDate = moment({year: year, month: month + 2, day: day}).toDate();

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActorsDates(entityCur, listActors, startDate, endDate, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadEventsDate' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  onChangeMechanic(event: { index: any, value: any }) {
    this.doLoadCalendar = true;
    this.planningLoaded = false;
    this.eventsList = [];
    this.calendardEventLBddist = [];
    for (const userCur of this.mechanicUsers) {
      if (userCur.username === event.value) {
        this.actorSelected = userCur;
        break;
      }
    }
    this.loadEvents(this._userService.getUserLogged().entity, this.actorSelected.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  }
  formatEvents() {
    this.eventsList = [];
    let evtId = 0;

    for (const eventCur of this.calendardEventLBddist) {
      let modifOption = false;
      if ((eventCur.calStatut === '') ||
        (eventCur.calStatut === null) ||
        (eventCur.calStatut === 'WAITING')) {
        modifOption = true;
      }
      if (eventCur.calDateStart === undefined) {
        eventCur.calDateStart = eventCur.calStart;
      }
      if (eventCur.calDateEnd === undefined) {
        eventCur.calDateEnd = eventCur.calEnd;
      }
      const otherDatas = JSON.parse(eventCur.calOtherdatas);
      this.eventsList.push({
        id: evtId,
        start: new Date(eventCur.calDateStart),
        end: new Date(eventCur.calDateEnd),
        title: eventCur.calTitle,     
        allDay: false,
        resizable: {
          beforeStart: modifOption,
          afterEnd: modifOption
        },
        draggable: modifOption,
        linkId: eventCur.calLinkid,
        calType: eventCur.calType,
        bddId: eventCur.id,
        calStatut: eventCur.calStatut,
        ref: (otherDatas && otherDatas.appointmentCustomer && otherDatas.appointmentCustomer.ref) ? otherDatas.appointmentCustomer.ref :
                                      (otherDatas && otherDatas.workAssignment && otherDatas.workAssignment.workRef) ? otherDatas.workAssignment.workRef : undefined,
        outside: eventCur.calOutside,
        stdCreationDate: eventCur.stdCreationDate,
        stdCreationUser: eventCur.stdCreationUser,
        oldTime: {
          start: new Date(eventCur.calDateStart),
          end: new Date(eventCur.calDateEnd),
        }, 
        calObject: eventCur.calObject
      });
      evtId++;
    }
  }

  eventClicked(event: EventChanged): void {
    // console.log('eventClicked ' + JSON.stringify(event));
    // const eventBdd = this.fillModelBdd(event.eventCur);
    if (this.isModalMode) {
      event.action = 'renewal';
      this.isModalMode = false;
      this.dialogRef.close(event);
    } else { 
      this.eventClickedOut.emit(event);
    }
  }
  updateListEvents(calendarEventBdd: CalendarEventBdd, bddAction: string): void {
    let idxRow = 0;
    for (const eventTmp of this.calendardEventLBddist) {
      if (eventTmp.id === calendarEventBdd.id ) {
        this.calendardEventLBddist.splice(idxRow, 1);
        if ( (bddAction !== 'deleted')) {
          this.calendardEventLBddist.push(calendarEventBdd);
        }
        break;
      }
      idxRow ++ ;
    }
    if (bddAction === 'add')  {
      this.calendardEventLBddist.push(calendarEventBdd);
    }
    this.formatEvents();
  }
  displayRequest(event: EventChanged): void {
    if (this.localPopover === undefined) {
      return;
    } 
    if (event.action === 'displayRequest' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
        return;
      }
      this.workCur = event.eventCur.calObject;
      this.detailPopover._elementRef.nativeElement.style.top = '25px';
      this.detailPopover._elementRef.nativeElement.style.left = '15px';
      this.localPopover.openPopover();
      this.displayPopover = true;
      this.dataInWork = {
        work: this.workCur,
        iRow: -1,
      };
      
    }
    if (event.action === 'closePopover' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
    }
  }
  
  close() {
    this.dialogRef.close('okAction');
  }
}
