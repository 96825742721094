<div *ngIf="checkEquipmentsIsLoaded" [ngClass]="{
                                    'quotationHeigth': statutCall === 'quotation',
                                    'otherHeigth': statutCall !== 'quotation'
                                }">
    <div *ngIf="statutCall === 'quotation'" class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
        <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between">
            <!--<button  class="mat-raised-button buttonTheme"  style="margin-bottom: 10px;" (click)="displayModalVerification()" >{{'verif check' | translate}}</button>-->
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToAction" (click)="saveData('doAction')" >{{actionTitle | translate}}</button>
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToSave" (click)="saveData('save')" >{{'save' | translate}}</button>
        </div>
    </div>
    <div *ngIf="statutCall === 'quotation'" class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
        <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;">
            <ng-container [ngTemplateOutlet]="recapGenTemplate"  ></ng-container>
        </mat-card-content>
    </div>
    <div *ngIf="statutCall === 'advances'" >
        <div class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
            <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between">
                <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToSave" (click)="saveData('save')" >{{'save' | translate}}</button>
                <button mat-icon-button (click)="addAdvanceRow()" [disabled]="isReadOnly">
                    <span class="fa fa-plus" style="font-size: 20px" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <div class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
            <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;">
                <div *ngIf="displayAdvancesOK" style="margin-bottom: 20px;">
                    <div class="flex-row row" style="margin-left: 0px; margin-right: 28px;height: 20px; line-height: 20px;">
                        <div class="col-3"></div>
                        <div class="col-2 d-flex justify-content-end">{{'suppliesWT'| translate}}</div>
                        <div class="col-2 d-flex justify-content-end">{{'laborWT'| translate}}</div>
                        <div class="col-2 d-flex justify-content-end">{{'totalWT'| translate}}</div>
                        <div class="col-2 d-flex justify-content-center">{{'emitDate'| translate}}</div>
                        <div class="col-1"></div>
                    </div>
                    <div class="stdTheme" *ngFor="let advanceItem of workInvoicingData.advances; let idxItem = index" 
                            style="margin-left: 10px;margin-right: 10px;margin-bottom: 10px;">
            
                        <div class="flex-row row" *ngIf="advanceItem.generatedRow" style="margin-left: 0px; margin-right: 0px;height: 30px; line-height: 30px;">
                            <div class="col-2 d-flex justify-content-start">
                                <span>
                                    <button mat-icon-button (click)="removeAdvanceRow(idxItem)"
                                                            [disabled]="isReadOnly || advanceItem.emitDate !== null">
                                        <span class="fa fa-trash" style="font-size: 15px;margin-top: -15px;"></span>
                                    </button>
                                </span>
                                <span >{{advanceItem.invoiceOtherData.rowLabel | translate}}</span>

                            </div>
                            <div class="col-1 d-flex justify-content-end">{{advanceItem.invoiceOtherData.rowPercent}} %</div>
                            <div class="col-2 d-flex justify-content-end">{{advanceItem.invoiceOtherData.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">{{advanceItem.invoiceOtherData.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">
                                    {{advanceItem.invoiceOtherData.supplies + advanceItem.invoiceOtherData.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}
                            </div>
                            <div  class="col-2 d-flex justify-content-end"> 
                                <span  *ngIf="advanceItem.emitDate !== null">{{getDateEmit(advanceItem.emitDate)}}</span>
                                <span *ngIf="advanceItem.emitDate === undefined"></span>
                            </div>
                            <div class="col-1">
                                <button  class="mat-raised-button buttonTheme" 
                                        [disabled]="isReadOnly || advanceItem.emitDate !== null" 
                                        (click)="sendAdvanceRow(idxItem)" >
                                    {{'sendAdvance' | translate}}
                                </button>
                            </div>
                        </div>

                        <div class="flex-row row" *ngIf="!advanceItem.generatedRow" style="margin-left: 0px; margin-right: 0px;height: 60px; line-height: 60px;">
                            <div class="col-2 d-flex justify-content-start" style="height: 30px; line-height: 30px;">
                                <span>
                                    <button mat-icon-button (click)="removeAdvanceRow(idxItem)" 
                                                            [disabled]="isReadOnly || advanceItem.emitDate !== null">
                                        <span class="fa fa-trash" style="font-size: 15px;margin-top: -15px;"></span>
                                    </button>
                                </span>
                                <span style="width: 100%!important">
                                    <select class="form-control inputTheme" id="termAdvance" #termAdvance  
                                            style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;"
                                            [disabled]="isReadOnly || advanceItem.emitDate !== null" 
                                            (change)="onChangeInput('termPayment', $event.target.value, idxItem)">
                                        <option class="selectOptionsTheme"  style="font-size: 15px!important;"
                                                    *ngFor="let condCur of conditionsAdvances" 
                                                    [(value)]="condCur.value" [selected]="condCur.value === advanceItem.invoiceOtherData.rowLabel">
                                            {{condCur.label}}
                                        </option>
                                    </select>
                                </span>
                            </div>
                            <div class="col-1 d-flex justify-content-end" style="padding-left: 10px;padding-right: 10px;height: 30px; line-height: 30px;">
                                <input class="form-control inputTheme" name="rowPercent" #rowPercent  style="text-align: right;padding-right: 5px;" [disabled]="isReadOnly"
                                    value="{{advanceItem.invoiceOtherData.rowPercent}}" min="0" max="100" type="number" 
                                    [disabled]="isReadOnly || advanceItem.emitDate !== null" 
                                    matInput (keypress)="numberAndDotOnly($event)" (change)="onChangeInput('percentPayment', rowPercent.value, idxItem)"
                                    placeholder="{{ 'rowPercent' | translate }}"
                                />
                                <span id="percent-sign" style="margin-left: 5px;">
                                    %
                                </span>
                            </div>
                            <div class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;">{{advanceItem.invoiceOtherData.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;">{{advanceItem.invoiceOtherData.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;">
                                    {{advanceItem.invoiceOtherData.supplies + advanceItem.invoiceOtherData.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}
                            </div>
                            <div  class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;"> 
                                <span *ngIf="advanceItem.emitDate !== null">{{getDateEmit(advanceItem.emitDate)}}</span>
                                <span *ngIf="advanceItem.emitDate === undefined"></span>
                            </div>
                            <div class="col-1" style="height: 30px; line-height: 30px;">
                                <button  class="mat-raised-button buttonTheme" 
                                        [disabled]="isReadOnly || advanceItem.emitDate !== null" 
                                        (click)="sendAdvanceRow(idxItem)" >
                                    {{'sendAdvance' | translate}}
                                </button>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-3 d-flex justify-content-start" style="padding-left: 55px;height: 30px; line-height: 30px;padding-right: 25px;margin-top: 10px;">
                                <input class="form-control inputTheme" name="rowLabelCplt" #rowLabelCplt 
                                        value="{{advanceItem.invoiceOtherData.rowLabelCplt}}" [disabled]="isReadOnly"
                                    matInput (change)="onChangeInput('rowLabelCplt',rowLabelCplt.value, idxItem)" 
                                    placeholder="{{ 'otherRowLabel' | translate }}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div> <!-- end advances-->
    <div *ngIf="statutCall === 'creditnotes'" >
        <div class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
            <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between">
                <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToSave" (click)="saveData('save')" >{{'save' | translate}}</button>
                <button mat-icon-button (click)="addCreditnoteRow()" [disabled]="isReadOnly">
                    <span class="fa fa-plus" style="font-size: 20px" aria-hidden="true"></span>
                </button>
            </div>
        </div>
        <div class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
            <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;">
                <div *ngIf="displayCreditnotesOK" style="margin-bottom: 20px;">
                    <div class="flex-row row" style="margin-left: 0px; margin-right: 28px;height: 20px; line-height: 20px;">
                        <div class="col-3"></div>
                        <div class="col-2 d-flex justify-content-end">{{'supplies&parts'| translate}}</div>
                        <div class="col-2 d-flex justify-content-end">{{'labor'| translate}}</div>
                        <div class="col-2 d-flex justify-content-end">{{'total'| translate}}</div>
                        <div class="col-2 d-flex justify-content-center">{{'emitDate'| translate}}</div>
                        <div class="col-1"></div>
                    </div>
                    <div class="stdTheme" *ngFor="let creditnoteItem of workInvoicingData.creditnotes; let idxItem = index" 
                            style="margin-left: 10px;margin-right: 10px;margin-bottom: 10px;">
                        <!-- start creditnoteWorkRow -->
                        <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;height: 40px; line-height: 40px;">
                            <div class="col-2 d-flex justify-content-start" >
                                <span>
                                    <button mat-icon-button (click)="removeCreditnoteRow(idxItem)" 
                                                            [disabled]="isReadOnly || creditnoteItem.emitDate !== null">
                                        <span class="fa fa-trash" style="font-size: 15px;margin-top: -15px;"></span>
                                    </button>
                                </span>
                                
                                <span style="width: 100%!important">
                                    <input class="form-control inputTheme" name="rowLabel" #rowLabel 
                                            value="{{creditnoteItem.creditnoteWorkRow.rowLabel}}" [disabled]="isReadOnly"
                                            matInput (change)="onChangeInput('rowLabel',rowLabel.value, idxItem)" 
                                            placeholder="{{ 'otherRowLabel' | translate }}"
                                        />
                                </span>
                                
                            </div>
                            <div class="col-1 d-flex justify-content-end" style="padding-left: 10px;padding-right: 10px;">
                                <input class="form-control inputTheme" name="rowPercent" #rowPercent  style="text-align: right;padding-right: 5px;" [disabled]="isReadOnly"
                                    value="{{creditnoteItem.creditnoteWorkRow.rowPercent}}" min="0" max="100" type="number" 
                                    [disabled]="isReadOnly || creditnoteItem.emitDate !== null" 
                                    matInput (keypress)="numberAndDotOnly($event)" (change)="onChangeInput('percentPayment', rowPercent.value, idxItem)"
                                    placeholder="{{ 'rowPercent' | translate }}"
                                />
                                <span id="percent-sign" style="margin-left: 5px;">
                                    %
                                </span>
                            </div>

                            <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">
                                <input class="form-control inputTheme" name="supplies" #supplies [ngStyle]="{'text-align': numberFormat.justifCurrency}" [disabled]="isReadOnly"
                                        value="{{creditnoteItem.creditnoteWorkRow.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                        matInput (keypress)="numberAndDotOnly($event)" (change)="onChangeInput('supplies',supplies.value, idxItem)"
                                        placeholder="{{ 'otherRowAmount' | translate }}"
                                />
                            </div>
                            <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">
                                <input class="form-control inputTheme" name="labor" #labor  [ngStyle]="{'text-align': numberFormat.justifCurrency}" [disabled]="isReadOnly"
                                        value="{{creditnoteItem.creditnoteWorkRow.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                        matInput (keypress)="numberAndDotOnly($event)" (change)="onChangeInput('labor',labor.value, idxItem)"
                                        placeholder="{{ 'otherRowAmount' | translate }}"
                                />
                            </div>
                            <div class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;">
                                    {{creditnoteItem.creditnoteWorkRow.supplies + creditnoteItem.creditnoteWorkRow.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}
                            </div>
                            <div  class="col-2 d-flex justify-content-end" style="height: 30px; line-height: 30px;"> 
                                <span *ngIf="creditnoteItem.emitDate !== null">{{getDateEmit(creditnoteItem.emitDate)}}</span>
                                <span *ngIf="creditnoteItem.emitDate === undefined"></span>
                            </div>
                            <div class="col-1" style="height: 30px; line-height: 30px;">
                                <button  class="mat-raised-button buttonTheme" 
                                        [disabled]="isReadOnly || creditnoteItem.emitDate !== null || !itemCreditnote[idxItem].isOkToAction" 
                                        (click)="sendCreditnoteRow(idxItem)" >
                                    {{'sendCreditnote' | translate}}
                                </button>
                            </div>

                        </div>
                        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-3 d-flex justify-content-start" style="padding-left: 55px;height: 40px; line-height: 40px;padding-right: 25px;margin-bottom: 0px">
                                <input class="form-control inputTheme" name="rowLabelCplt" #rowLabelCplt 
                                        value="{{creditnoteItem.creditnoteWorkRow.rowLabelCplt}}" [disabled]="isReadOnly"
                                    matInput (change)="onChangeInput('rowLabelCplt',rowLabelCplt.value, idxItem)" 
                                    placeholder="{{ 'otherRowLabel' | translate }}"
                                />
                            </div>
                        </div>
                        <!-- end creditnoteWorkRow -->
                        <!-- start creditnoteWorkTax -->

                        <div class="stdTheme" *ngFor="let taxItem of creditnoteItem.creditnoteWorkTax; let taskItem = index">
                            <div class="flex-row row"  style="margin-left: 0px;margin-right: 0px;height: 22px; line-height: 22px;">
                                <div class="col-3 d-flex justify-content-start" style="padding-left: 55px;">
                                    {{taxItem.taxName  | translate}} {{taxItem.taxPercent  | translate}}  % ({{taxItem.taxBaseWork  | translate}})
                                </div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{taxItem.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{taxItem.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{taxItem.labor + taxItem.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            </div>
                        </div>
                        <!-- end creditnoteWorkTax -->
                        <!-- start creditnoteWorkTotalWT -->
                        <div class="flex-row row" style="margin-left: 0px;margin-right: 10px;height: 30px; line-height: 30px;" >
                            <div class="col-3" style="padding-left: 55px;">{{creditnoteItem.creditnoteWorkTotalWT.rowLabel | translate}}</div>
                            <div class="col-2 d-flex justify-content-end">{{creditnoteItem.creditnoteWorkTotalWT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">{{creditnoteItem.creditnoteWorkTotalWT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">{{creditnoteItem.creditnoteWorkTotalWT.supplies + creditnoteItem.creditnoteWorkTotalWT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                        </div>
                        <!-- end creditnoteWorkTotalWT -->
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div> <!-- end creditnotes -->
    <div *ngIf="statutCall === 'invoicing'" class="flex-row row" style="margin-left: 10px;margin-right: 10px;margin-top: 10px;">
        <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between">
            <!--<button  class="mat-raised-button buttonTheme"  style="margin-bottom: 10px;" (click)="displayModalVerification()" >{{'verif check' | translate}}</button>-->
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToAction" (click)="saveData('doAction')" >{{actionTitle | translate}}</button>
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToUpdateWorks" (click)="updateWorks()" >{{'update works' | translate}}</button>
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToSave" (click)="saveData('save')" >{{'save' | translate}}</button>
        </div>
    </div>
    <div *ngIf="statutCall === 'invoicing'" class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
        <mat-tab-group #tabsSubEquipment mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" 
                                style="margin: 0px; width: 100%" (selectedTabChange)="selectedTabChange('checkEquip',$event)">
            <div *ngIf="onlyMainEquip">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="flex-row row" >
                            <span class="col-10 left-label-span" style="text-align: start; font-size: 15px; margin-top: 5px;">
                                {{ workEquipments.mainEquipment.equipDesign ? workEquipments.mainEquipment.equipDesign : workEquipments.mainEquipment.equipName }}
                            </span>
                        </div>
                    </ng-template>
                    <mat-card-content class="stdTheme" style="margin-bottom: 0px;">
                        <ng-container [ngTemplateOutlet]="checkEquipTemplate"  ></ng-container>
                    </mat-card-content>
                </mat-tab>
            </div>                 
            <div *ngIf="!onlyMainEquip">
                <div class="stdTheme" *ngFor="let subItem of workEquipments.subEquipments; let row = index" >
                    <!--<mat-tab label="{{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}" >-->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="flex-row row" >
                                <span class="col-10 left-label-span" style="text-align: start; font-size: 15px; margin-top: 5px;">
                                    {{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}
                                </span>
                            </div>
                        </ng-template>
                        <mat-card-content class="stdTheme" style="margin-bottom: 0px;">
                            <ng-container [ngTemplateOutlet]="checkEquipTemplate"  ></ng-container>
                        </mat-card-content>
                    </mat-tab>
                </div>
            </div>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <span *ngIf="statutCall === 'invoicing'" class="recap-label-span" >
                            {{'invoiceRecap' | translate}}
                        </span>
                        <span *ngIf="statutCall === 'quotation'" class="recap-label-span" >
                            {{'quotationRecap' | translate}}
                        </span>
                    </div>
                </ng-template>
                <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;">
                    <ng-container [ngTemplateOutlet]="recapGenTemplate"  ></ng-container>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </div> <!-- end invoicing  -->

</div>
<ng-template let-value="value" #checkEquipTemplate>
    <div *ngIf="displayCheckOK">
        <mdi-tasks-checklist
            [ihmOptions]="ihmOptionsCkeck"
            [statutCall]="'invoicing'" 
            [tasksActionsArgs]="tasksActionsArgs"
            [itemEquip]="itemEquip"
            (dataOut)="inputData($event)"
        ></mdi-tasks-checklist>
    </div>
</ng-template>
<ng-template let-value="value" #recapGenTemplate>
    <div *ngIf="displayRecapAmountOK" style="margin-bottom: 20px;">
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;" >
            <button class="col-1" mat-icon-button (click)="toggleRecap()">
                <span *ngIf="recapAmountInput.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                <span *ngIf="!recapAmountInput.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
            </button> 
            <h6 class="col-11" (click)="toggleRecap()" > {{'recapGen' | translate}} </h6>
        </div>

        <div *ngIf="!recapAmountInput.isCollapsed" style="margin-left: 10px;margin-right: 10px;">
            <div class="flex-row row" style="margin-left: 0px; margin-right: 28px;height: 20px; line-height: 20px;">
                <div class="col-6"></div>
                <div class="col-2 d-flex justify-content-end">{{'suppliesBT' | translate}}</div>
                <div class="col-2 d-flex justify-content-end">{{'laborBT' | translate}}</div>
                <div class="col-2 d-flex justify-content-end">{{'totalBT' | translate}}</div>
            </div>
            <div class="stdTheme" *ngFor="let workEquip of recapAmountInput.equipRecapAmount; let equipItem = index" 
                                    style="margin-left: 10px;margin-right: 10px;margin-bottom: 10px;">
                <fieldset class="equipment-border" >

                    <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;height: 30px; line-height: 30px;">
                        <button class="col-1" mat-icon-button (click)="workEquip.isCollapsed =! workEquip.isCollapsed ">
                            <span *ngIf="workEquip.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                            <span *ngIf="!workEquip.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                        <div class="col-2 d-flex justify-content-start">{{workEquip.equipDesign}}</div>
                        <div class="col-2 d-flex justify-content-start">{{workEquip.equipSN}}</div>
                        <div class="col-1"></div>
                        <div class="col-2 d-flex justify-content-end">{{workEquip.suppliesTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                        <div class="col-2 d-flex justify-content-end">{{workEquip.laborTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                        <div class="col-2 d-flex justify-content-end">{{workEquip.suppliesTotalBT + workEquip.laborTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    </div>
                    <div *ngIf="!workEquip.isCollapsed">
                        <div class="lineWks" style="margin-top: 5px;"></div>
                        <!--
                        <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-5"></div>
                        <div class="col-1 d-flex justify-content-end">{{'suppliesBT' | translate}}</div>
                        <div class="col-1 d-flex justify-content-end">{{'laborBT' | translate}}</div>
                        <div class="col-1 d-flex justify-content-end">{{'totalBT' | translate}}</div>
                        </div>
                        -->
                        <div class="rowTheme" *ngFor="let taskEquip of workEquip.tasksRecapAmount; let taskItem = index">
                        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-1"></div>
                            <div class="col-5 d-flex justify-content-start">{{taskEquip.taskrefLabel}}</div>
                            <div class="col-2 d-flex justify-content-end">{{taskEquip.suppliesTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">{{taskEquip.laborTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            <div class="col-2 d-flex justify-content-end">{{taskEquip.suppliesTotalBT + taskEquip.laborTotalBT | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                        </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div style="margin-left: 10px;margin-right: 10px;">
            <fieldset class="total-border" >
                <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;height: 20px; line-height: 20px;">
                    <div class="col-6 d-flex justify-content-center"><h6>{{'recap_totalisation' | translate}}</h6></div>
                    <div class="col-2 d-flex justify-content-center"><h6>{{'recap_supplies' | translate}}</h6></div>
                    <div class="col-2 d-flex justify-content-center"><h6>{{'recap_labor' | translate}}</h6></div>
                    <div class="col-2 d-flex justify-content-center"><h6>{{'recap_total' | translate}}</h6></div>
                </div>
                <div class="flex-row row" style="margin-left: 0px;margin-right: 10px;height: 30px; line-height: 30px;" >
                    <div class="col-1"></div>
                    <div class="col-5">{{recapAmountInput.equipTotalBT.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.equipTotalBT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.equipTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.equipTotalBT.supplies + recapAmountInput.equipTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <!-- start otherWorkTotalBT -->
                <div class="flex-row row" style="margin-left: 0px;margin-right:10px;height: 30px; line-height: 30px;" >
                    <button class="col-1" mat-icon-button (click)="toggleOtherRows()">
                        <span *ngIf="recapAmountInput.isOtherCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                        <span *ngIf="!recapAmountInput.isOtherCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                    </button> 
                    <div class="col-5">{{recapAmountInput.otherWorkTotalBT.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.otherWorkTotalBT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.otherWorkTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.otherWorkTotalBT.supplies + recapAmountInput.otherWorkTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <div *ngIf="!recapAmountInput.isOtherCollapsed" style="margin-left: 0px;margin-right: 0px;">
                    <fieldset class="tax-border" style="margin-bottom: 10px">
                        <div class="stdTheme" *ngFor="let otherItem of recapAmountInput.otherWorkRows; let idxItem = index">
                            <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;height: 40px; line-height: 40px;">
                                <div class="col-1 d-flex justify-content">
                                    <button mat-icon-button (click)="addOtherRow(idxItem)" [disabled]="isReadOnly">
                                        <span class="fa fa-plus" style="font-size: 10px" aria-hidden="true"></span>
                                    </button>
                                    <button mat-icon-button (click)="removeOtherRow(idxItem)" [disabled]="isReadOnly">
                                        <span class="fa fa-minus" style="font-size: 10px" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-4 d-flex justify-content-between" style="padding-left: 10px;">
                                    <input class="form-control inputTheme" name="rowLabel" #rowLabel value="{{otherItem.rowLabel}}" [disabled]="isReadOnly"
                                        matInput (change)="setOtherChangeValues('rowLabel',rowLabel.value, idxItem)" placeholder="{{ 'otherRowLabel' | translate }}"
                                    />
                                </div>
                                <button class="col-1" mat-icon-button (click)="changeOtherRowSubtracting(idxItem)" [disabled]="isReadOnly">
                                    <span *ngIf="!otherItem.rowSubtracting" class="fa fa-plus" style="font-size: 10px" aria-hidden="true"></span>
                                    <span *ngIf="otherItem.rowSubtracting" class="fa fa-minus" style="font-size: 10px" aria-hidden="true"></span>
                                </button> 
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">
                                    <input class="form-control inputTheme" name="supplies" #supplies [ngStyle]="{'text-align': numberFormat.justifCurrency}" [disabled]="isReadOnly"
                                            value="{{otherItem.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                            matInput (keypress)="numberAndDotOnly($event)" (change)="setOtherChangeValues('supplies',supplies.value, idxItem)"
                                            placeholder="{{ 'otherRowAmount' | translate }}"
                                    />
                                </div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">
                                    <input class="form-control inputTheme" name="labor" #labor  [ngStyle]="{'text-align': numberFormat.justifCurrency}" [disabled]="isReadOnly"
                                            value="{{otherItem.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                            matInput (keypress)="numberAndDotOnly($event)" (change)="setOtherChangeValues('labor',labor.value, idxItem)"
                                            placeholder="{{ 'otherRowAmount' | translate }}"
                                    />
                                </div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{otherItem.supplies + otherItem.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <!-- end otherWorkTotalBT -->
                <!-- start financialWorkTotalBT -->
                <div class="flex-row row" style="margin-left: 0px;margin-right:10px;height: 30px; line-height: 30px;" >
                    <button class="col-1" mat-icon-button (click)="toggleFinancialRows()">
                        <span *ngIf="recapAmountInput.isFinancialCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                        <span *ngIf="!recapAmountInput.isFinancialCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                    </button> 
                    <div class="col-5">{{recapAmountInput.financialWorkTotalBT.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.financialWorkTotalBT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.financialWorkTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.financialWorkTotalBT.supplies + recapAmountInput.financialWorkTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <div *ngIf="!recapAmountInput.isFinancialCollapse" style="margin-left: 0px;margin-right: 0px;">
                    <fieldset class="tax-border" style="margin-bottom: 10px">
                        <div class="stdTheme" *ngFor="let financialItem of recapAmountInput.financialWorkRows; let idxItem = index">
                            <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;height: 40px; line-height: 40px;">
                                <div class="col-1 d-flex justify-content">
                                    <button mat-icon-button (click)="addFinancialRow(idxItem)" 
                                        [disabled]="recapAmountInput.financialWorkRows.length === financialListRef.length || isReadOnly">
                                        <span class="fa fa-plus" style="font-size: 10px" aria-hidden="true"></span>
                                    </button>
                                    <button mat-icon-button (click)="removeFinancialRow(idxItem)" [disabled]="isReadOnly">
                                        <span class="fa fa-minus" style="font-size: 10px" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-1 d-flex justify-content" style="padding-left: 10px;padding-right: 0px;" >
                                    <select class="selectBox inputTheme" #financialOption [disabled]="isReadOnly"
                                            (change)="onFinancialSelected(idxItem, financialOption.value)">
                                        <option *ngFor="let itemOption of financialList" 
                                                [value]="itemOption.value"
                                                [selected]="itemOption.value === financialItem.rowType"
                                                [hidden]="itemOption.hidden"
                                                >{{ itemOption.label }}</option>
                                    </select>
                                </div>

                                <div class="col-2 d-flex justify-content" style="padding-left: 10px;padding-right: 0px;" >
                                    <select class="selectBox inputTheme" #rowBaseWork (change)="onBaseWorkSelected(idxItem, rowBaseWork.value)" [disabled]="isReadOnly">
                                        <option *ngFor="let itemOption of baseWorkList" 
                                            [value]="itemOption.value"
                                            [selected]="itemOption.value === financialItem.rowBaseWork"
                                            [hidden]="itemOption.hidden"
                                            >{{ itemOption.label }}</option>
                                    </select>
                                </div>
                            
                                <div *ngIf="financialItem.rowType === 'rounding'" 
                                        class="col-2 d-flex justify-content-end" style="padding-left: 10px;padding-right: 0px;">
                                    <input class="form-control inputTheme" name="rounding" #rounding  [ngStyle]="{'text-align': numberFormat.justifCurrency}" [disabled]="isReadOnly"
                                        value="{{financialItem.rowBase | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                        matInput (keypress)="numberAndDotOnly($event)" (change)="financialChangeValues('rounding',rounding.value, idxItem)"
                                        placeholder="{{ 'rowBase' | translate }}"
                                    />
                                </div>
                                <div *ngIf="financialItem.rowType === 'discount' || financialItem.rowType === 'discountCash' || financialItem.rowType === 'surcharge'" 
                                        class="col-1 d-flex justify-content-end" style="padding-left: 10px;padding-right: 0px;">
                                
                                    <input class="form-control inputTheme" name="rowPercent" #rowPercent  style="text-align: right;padding-right: 5px;" [disabled]="isReadOnly"
                                        value="{{financialItem.rowPercent}}" min="0" max="100" type="number"
                                        matInput (keypress)="numberAndDotOnly($event)" (change)="financialChangeValues(financialItem.rowType,rowPercent.value, idxItem)"
                                        placeholder="{{ 'rowPercent' | translate }}"
                                    />
                                    <!--<span id="percent-sign">%</span>-->
                                    <span id="percent-sign" style="margin-left: 5px;">
                                        <i class='fa fa-percent'></i>
                                    </span>
                                </div>
                                <div *ngIf="financialItem.rowType === 'discount' || financialItem.rowType === 'discountCash' || financialItem.rowType === 'surcharge'" class="col-1"></div>

                                <div class="col-4" *ngIf="financialItem.rowBaseWork === 'supplies&parts'">
                                <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                    <div class="col-6 d-flex justify-content-end" style="padding-right: 20px;">{{financialItem.rowAmount | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                    <div class="col-6"></div>
                                </div>
                                </div>
                                <div class="col-4" *ngIf="financialItem.rowBaseWork === 'labor'">
                                <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                    <div class="col-6"></div>
                                    <div class="col-6 d-flex justify-content-end" style="padding-right: 5px;">{{financialItem.rowAmount | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                </div>
                                </div>

                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{financialItem.rowAmount | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <!-- end financialWorkTotalBT -->
                <!-- start workTotalBT -->
                <div class="lineWks" style="margin-top: 5px;"></div>
                <div class="flex-row row" style="margin-left: 0px;margin-right: 10px;height: 30px; line-height: 30px;" >
                    <div class="col-1"></div>
                    <div class="col-5">{{recapAmountInput.workTotalBT.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalBT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalBT.supplies + recapAmountInput.workTotalBT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <div class="flex-row row" style="margin-left: 0px;margin-right:10px;height: 30px; line-height: 30px;" >
                    <button class="col-1" mat-icon-button (click)="recapAmountInput.isTaxCollapsed =! recapAmountInput.isTaxCollapsed ">
                        <span *ngIf="recapAmountInput.isTaxCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                        <span *ngIf="!recapAmountInput.isTaxCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                    </button> 
                    <div class="col-5">{{recapAmountInput.workTotalTax.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalTax.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalTax.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalTax.supplies + recapAmountInput.workTotalTax.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <!-- end workTotalBT -->
                <!-- start workTax -->
                <div *ngIf="!recapAmountInput.isTaxCollapsed" style="margin-left: 0px;margin-right: 0px;">
                    <fieldset class="tax-border" style="margin-bottom: 10px">
                        <div class="stdTheme" *ngFor="let taxItem of recapAmountInput.workTax; let taskItem = index">
                            <div class="flex-row row" *ngIf="taxItem.supplies > 0" style="margin-left: 0px;margin-right: 0px;height: 22px; line-height: 22px;">
                                <div class="col-1"></div>
                                <div class="col-5 d-flex justify-content-start" style="padding-left: 10px;">
                                    {{taxItem.taxName  | translate}} {{taxItem.taxPercent  | translate}}  % ({{'supplies&parts'  | translate}})
                                </div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{taxItem.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                <div class="col-2" style="padding-right: 20px;"></div>
                                <div class="col-2" style="padding-right: 20px;"></div>
                            </div>
                        </div>

                        <div class="stdTheme" *ngFor="let taxItem of recapAmountInput.workTax; let taskItem = index">
                            <div class="flex-row row" *ngIf="taxItem.labor > 0" style="margin-left: 0px;margin-right: 0px;height: 22px; line-height: 22px;">
                                <div class="col-1"></div>
                                <div class="col-5 d-flex justify-content-start" style="padding-left: 10px;">
                                    {{taxItem.taxName  | translate}} {{taxItem.taxPercent  | translate}}  % ({{'labor'  | translate}})
                                </div>
                                <div class="col-2" style="padding-right: 20px;"></div>
                                <div class="col-2 d-flex justify-content-end" style="padding-right: 20px;">{{taxItem.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                                <div class="col-2" style="padding-right: 20px;"></div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <!-- end workTax -->
                <!-- start workTotalWT -->
                <div class="flex-row row" style="margin-left: 0px;margin-right: 10px;height: 30px; line-height: 30px;" >
                    <div class="col-1"></div>
                    <div class="col-5">{{recapAmountInput.workTotalWT.rowLabel | translate}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalWT.supplies | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalWT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                    <div class="col-2 d-flex justify-content-end">{{recapAmountInput.workTotalWT.supplies + recapAmountInput.workTotalWT.labor | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                </div>
                <!-- end workTotalWT -->
            </fieldset>
        </div>
        <!-- payment -->
        <div style="margin-left: 10px;margin-right: 10px;margin-top: 10px">
            <div class="flex-row row" >
                <div class="col-6">
                    <fieldset class="recap-payment-border" style="margin-bottom: 10px">
                        <legend class="recap-payment-border" align="center" ><b>{{ 'terms of payment' | translate }}</b></legend>
                        <div class="flex-row row" >
                            <div class="col-12" style="margin-top: 7px;">
                                <mat-radio-group class="payment-radio-group"   #paymentCondition="matRadioGroup" labelPosition="before"
                                        style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"  
                                        [disabled]="isReadOnly"
                                        (change)="onChangePaymentField('paymentCondition',paymentCondition.value)">
                                    <mat-radio-button class="radioButton" style="padding-right: 15px;" 
                                            [checked]="isCustomerCondition"
                                            
                                            value="customerConditions">{{ 'customerConditions' | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button class="radioButton" 
                                            [checked]="!isCustomerCondition"
                                            value="specificsConditions">{{ 'specificsConditions' | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div class="flex-row row" *ngIf="recapAmountInput.paymentRecap !== undefined && recapAmountInput.paymentRecap.customerConditions" style="margin-left: 0px;margin-right: 0px;margin-top: 10px">
                            <div class="col-12" >
                                <fieldset class="payment-border">
                                    <legend class="payment-border" align="right"> {{ 'terms of payment customer' | translate }}</legend>
                                    <div class="flex-row row wkscard" style="margin-left: 0px; margin-right: 0px;">
                                        <div class="col-4 " >
                                            <div *ngIf="recapAmountInput.paymentRecap.customerAccount.bsnCashpay" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                                <h6>{{ 'bsnCashPay' | translate }}</h6>
                                            </div>
                                            <div *ngIf="!recapAmountInput.paymentRecap.customerAccount.bsnCashpay" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                                <h6>{{ 'inAccount' | translate }}</h6>
                                            </div>
                                        </div>
                                        <div *ngIf="!recapAmountInput.paymentRecap.customerAccount.bsnCashpay" class="col-3 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;padding: 0px">
                                            <label class="wkscard" for="bsnDelayDays" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnDelayDays' | translate }}</label>
                                            <input id="bsnDelayDays" class="form-control stdTheme"  name="bsnDelayDays" style="width: 80%;padding: 0px;padding-left: 5px;"
                                                    #bsnDelayDays [value] = "recapAmountInput.paymentRecap.customerAccount.bsnDelayDays + ' (' + translateLabel('days') + ')'" disabled >
                                        </div>
                                        <div *ngIf="!recapAmountInput.paymentRecap.customerAccount.bsnCashpay" class="col-4 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;">
                                            <label class="wkscard" for="bsnDefferedPaiement" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnDefferedPaiement' | translate }}</label>
                                            <input id="bsnDefferedPaiement" class="form-control stdTheme"  name="bsnDefferedPaiement" style="width: 80%;padding: 0px;padding-left: 5px;"
                                                    #bsnDefferedPaiement [value] = "translateLabel(recapAmountInput.paymentRecap.customerAccount.bsnDefferedPaiement)" disabled >
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div class="flex-row row wkscard" *ngIf="recapAmountInput.paymentRecap !== undefined &&  !recapAmountInput.paymentRecap.customerConditions" style="margin-left: 0px;margin-right: 0px;margin-top: 10px">
                            <div class="col-12" *ngIf="!recapAmountInput.paymentRecap.customerAccount.bsnCashpay" >
                                <div class="flex-row row wkscard" style="margin-left: 0px;margin-right: 0px">
                                    <div class="col-4" >
                                        <div  style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                            <label  for="specificCashpay" style="margin-left: 0px; margin-right: 15px" >{{ 'bsnCashPay' | translate }}</label>
                                            <input type="checkbox" id="specificCashpay"  #specificCashpay class="inputTheme" name="specificCashpay"
                                                (click)="onChangePaymentField('specificCashpay', specificCashpay.checked)" 
                                                [disabled]="statutCall === 'invoicing'"
                                                [checked]="recapAmountInput.paymentRecap.specificsConditions.cashpay" 
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!recapAmountInput.paymentRecap.specificsConditions.cashpay" class="col-12" [ngStyle]="{'margin-top': '0px', 'width': '100%','padding': '0px'}" >
                                <table class="table stdTheme table-striped" id="listConditions" style="margin-bottom: 0px;" #listConditions>
                                    <thead class="theadWork">
                                        <tr class="trWork">
                                            <th style="width: 5%; padding: 0px; text-align: center;" >*</th>
                                            <th style="width: 10%; padding: 0px; text-align: center;" >{{'orderNumber' | translate}}</th>
                                            <th style="width: 40%; padding: 0px; text-align: center;" >{{'termPayment' | translate}}</th>
                                            <th style="width: 30%; padding: 0px; text-align: center;" >{{'delayPayment' | translate}}</th>
                                            <th style="width: 20%; padding: 0px; text-align: center;" >{{'percentPayment' | translate}}</th>
                                            <th style="width: 5%; padding: 0px; text-align: center;" >*</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbodyWork" >
                                        <div *ngFor="let paymentCur of recapAmountInput.paymentRecap.specificsConditions.payments; let i = index">
                                            <tr class="trWork " >
                                                <td style="width: 5%; padding: 0px; text-align: center;vertical-align: middle">
                                                    <a><button type="button" class="btn btn-default" mat-icon-button 
                                                        [disabled]="statutCall === 'invoicing'"
                                                        (click)="counterPayment('numberPayments',1, i)">
                                                        <span class="fa fa-plus"></span>
                                                    </button></a>
                                                </td>
                                                <td style="width: 10%; padding: 0px; text-align: center;vertical-align: middle">
                                                    <span class="inputTheme">{{ i+1}}</span>
                                                </td>
                                                <td style="width: 40%; padding: 0px; text-align: center;vertical-align: middle">
                                                    <select class="form-control inputTheme" id="termPayment_{{i}}" #termPayment_{{i}}  
                                                            style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;"
                                                            [disabled]="statutCall === 'invoicing'"
                                                            (change)="onChangePaymentConditions('termPayment', $event.target.value, i)">
                                                        <option class="selectOptionsTheme" 
                                                                    *ngFor="let condCur of conditionsQuotes" 
                                                                    [(value)]="condCur.value" [selected]="condCur.value === paymentCur.term">
                                                            {{condCur.label}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td style="width: 30%; padding: 0px; text-align: center;vertical-align: middle">     
                                                    <div >                              
                                                        <select class="form-control inputTheme" id="shedulerPayment_{{i}}" #shedulerPayment_{{i}}  
                                                                style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;"
                                                                [disabled]="statutCall === 'invoicing'"
                                                                (change)="onChangePaymentConditions('delayScheduler', $event.target.value, i)">
                                                            <option class="selectOptionsTheme" 
                                                                        *ngFor="let delayCur of delayList" 
                                                                        [(value)]="delayCur.value" [selected]="delayCur.value === paymentCur.delay">
                                                                {{delayCur.label}}
                                                            </option>
                                                        </select>
                                                    </div> 
                                                </td>
                                                <td class="inputTheme" style="width: 10%; padding: 0px; text-align: center;vertical-align: middle">
                                                    <div *ngIf="paymentCur.term !== ''"  > 
                                                        <input  #percentPayment_{{i}}   matInput (blur)="this.value = paymentCur.percent"
                                                        [disabled]="statutCall === 'invoicing'"
                                                        [(value)]="paymentCur.percent" (change)="onChangePaymentConditions('percentPayment', $event.target.value,i)">
                                                    </div>  
                                                </td>
                                                <td style="width: 5%; padding: 0px; text-align: center;vertical-align: middle">
                                                    <a><button type="button" class="btn btn-default" mat-icon-button 
                                                        [disabled]="statutCall === 'invoicing'"
                                                        (click)="counterPayment('numberPayments',-1, i)">
                                                        <span class="fa fa-minus"></span>
                                                    </button></a>
                                                </td>
                                            </tr>
                                        </div>
                                    </tbody>
                                    
                                    <tfoot class="tbodyWork" *ngIf="totPaymentPercent !== 0">
                                        <tr class="trWork ">
                                            <td style="width: 80%; padding: 0px; text-align: right;vertical-align: middle">
                                                {{'controlPercent' | translate}}
                                            </td>
                                            <td class="inputTheme" style="width: 20%; padding: 0px; text-align: center;vertical-align: middle">
                                                <span>{{totPaymentPercent}}</span>
                                            </td>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-6">
                    <fieldset class="recap-payment-border" style="margin-bottom: 10px">
                        <legend class="recap-payment-border" align="center" ><b>{{ 'comments' | translate }}</b></legend>
                            <textarea  id="comments" class="inputTheme" [disabled]="isReadOnly"
                                        matInput #comments placeholder="{{ 'comments' | translate }}"
                                        cdkTextareaAutosize
                                        (keyup)="onChangeComment(comments.value)"
                                        #autosizeComment="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="10"
                            ></textarea >
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</ng-template>
