<div #refElement *ngIf="isLoaded" class="mat-elevation-z8 container-fluid stdTheme"
    [ngStyle]="{'margin-top': '20px', width: '95%' ,padding: '0px'}" >
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="partSupplieForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-header>
                        <mat-card-title>
                        <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                        <section class="mat-typography">
                            <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                        </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="container-fluid d-flex justify-content-between" >
                            <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'goback' | translate }}
                            </button>
                            <button *ngIf="(!isRegistered && partSupplieForm.valid)" mat-raised-button  #buttonSubmit type="submit" (click)="registerPartSupplie()" >
                                <mat-icon>save</mat-icon>
                                {{ 'save' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                    <mat-card-content class="stdTheme" style="padding-bottom: 20px;">
                        <div  class="flex-row row">          
                            <div class="col-12">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paramLabel' | translate }}</mat-label>
                                    <input id="paramLabel" class="form-control inputTheme" formControlName="paramLabel" readonly matInput #paramLabel placeholder="{{ 'actionrefLabel' | translate }}"
                                    required >
                                </mat-form-field>
                            </div>    
                        </div>
                        <div class="flex-row row">
                            <div class="col-12" style="margin-top: 10px">
                                <div *ngFor= "let langCur of langLabelsList; let rowLang=index; let isLast=last" >
                                    <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                        <tbody class="stdTheme" style="overflow-y: auto;  height: 15%;" >
                                            <tr  >
                                                <td class="stdTheme" style="width: 10%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{langCur.langDisplay}}
                                                </td>
                                                <td class="stdTheme" style="width: 90%; padding: 2px; padding-left: 5px; padding-right: 5px;">

                                                    <input type="text" style="width: 100%" class="inputNormal" #langLabel name="langLabel" value="{{langCur.langLabel}}"
                                                                    (keyup)="setLangLabelValue(langLabel.value,rowLang)" [direction]="langCur.langDirection"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>    
                                    </table>
                                </div>                                      
                            </div>
                        </div>
                        <div class="flex-row row">
                            <div class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paramType' | translate }}</mat-label>
                                    <mat-select id="equipType" placeholder="{{ 'Choose paramType' | translate }}" formControlName="paramType" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let paramTypeCur of paramTypeList" 
                                            [value]="paramTypeCur.value"  >{{paramTypeCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'brandName' | translate }}</mat-label>
                                    <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" formControlName="brandName" 
                                                panelClass="mdiselectcontent" #equipBrand
                                                required
                                                (ngModelChange)="onChangeBrand($event)"
                                                ngDefaultControl >
                                        <mat-select-header>
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matPrefix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                        (keyup)="onKeyBrand($event)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-select-header>
                                        <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                            [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'brandRef' | translate }}</mat-label>
                                    <input id="brandRef" class="form-control inputTheme" formControlName="brandRef" 
                                                    required maxlength="25" matInput #brandRef placeholder="{{ 'brandRef' | translate }}"
                                     >
                                </mat-form-field>
                            </div>  
                        </div>
                        <div  class="flex-row row">                                    
                            <div  class="col-3" style="margin-bottom: 10px;" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paramUnit' | translate }}</mat-label>
                                    <mat-select id="paramUnit" placeholder="{{ 'Choose paramUnit' | translate }}" formControlName="paramUnit" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let unitQuantity of unitsQuantity" 
                                            [value]="unitQuantity.value"  >{{unitQuantity.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>   
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paramRate' | translate }}</mat-label>
                                    <input id="paramRate" class="form-control inputTheme" required formControlName="paramRate" matInput #paramRate placeholder="{{ 'paramRate' | translate }}"
                                        onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,4})?$/.test(this.value)?'inherit':'red'"
                                     >
                                </mat-form-field>
                            </div>  
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paramCurrency' | translate }}</mat-label>
                                    <input id="paramCurrency" class="form-control inputTheme" readonly formControlName="paramCurrency" maxlength="3" 
                                            matInput #paramCurrency placeholder="{{ 'paramCurrency' | translate }}"
                                     >
                                </mat-form-field>
                            </div>  
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</div>
