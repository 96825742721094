/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-location.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/google-maps";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../../node_modules/@angular/google-maps/index.ngfactory";
import * as i8 from "../../../../../services/translate.pipe";
import * as i9 from "../../../../../services/translate.service";
import * as i10 from "./google-location.component";
import * as i11 from "../../../services/global-events-manager.service";
import * as i12 from "../../../../../services/theme.service";
var styles_GoogleLocationComponent = [i0.styles];
var RenderType_GoogleLocationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GoogleLocationComponent, data: {} });
export { RenderType_GoogleLocationComponent as RenderType_GoogleLocationComponent };
function View_GoogleLocationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "map-marker", [], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.MapMarker, [i2.GoogleMap, i1.NgZone], { title: [0, "title"], position: [1, "position"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = _v.context.$implicit.position; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_GoogleLocationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { width: 0, height: 1 }), i1.ɵdid(5, 933888, null, 0, i4.DefaultStyleDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgStyleImpl, i6.DomSanitizer, [6, i3.NgStyle], [2, i5.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { width: 0, height: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "google-map", [], null, null, null, i7.View_GoogleMap_0, i7.RenderType_GoogleMap)), i1.ɵdid(8, 770048, null, 0, i2.GoogleMap, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { height: [0, "height"], width: [1, "width"], options: [2, "options"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GoogleLocationComponent_2)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (_co.displayWidth ? _co.displayWidth : "100%"), (_co.displayHeight ? _co.displayHeight : "100%")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (_co.displayWidth ? _co.displayWidth : "100%"), (_co.displayHeight ? _co.displayHeight : "100%")); _ck(_v, 5, 0, currVal_1); var currVal_2 = null; var currVal_3 = null; var currVal_4 = _co.googleMaps.mapOptions; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.googleMaps.markers; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_GoogleLocationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mapLatLng: 0 }), i1.ɵqud(402653184, 2, { pacInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoogleLocationComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["pacInput", 1]], null, 1, "input", [["class", "controls"], ["id", "pacInput"], ["type", "text"]], [[8, "placeholder", 0]], null, null, null, null)), i1.ɵpid(0, i8.TranslatePipe, [i9.TranslateService]), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["mapLatLng", 1]], null, 5, "div", [["id", "mapLatLng"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { width: 0, height: 1 }), i1.ɵdid(11, 933888, null, 0, i4.DefaultStyleDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgStyleImpl, i6.DomSanitizer, [6, i3.NgStyle], [2, i5.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { width: 0, height: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.googleMaps.action === "displayLatLng"); _ck(_v, 3, 0, currVal_0); var currVal_2 = _ck(_v, 10, 0, (_co.displayWidth ? _co.displayWidth : "100%"), (_co.displayHeight ? _co.displayHeight : "100%")); _ck(_v, 9, 0, currVal_2); var currVal_3 = _ck(_v, 12, 0, (_co.displayWidth ? _co.displayWidth : "100%"), (_co.displayHeight ? _co.displayHeight : "100%")); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, " ", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Search Box")), ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_GoogleLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-google-location", [], null, null, null, View_GoogleLocationComponent_0, RenderType_GoogleLocationComponent)), i1.ɵdid(1, 1163264, null, 0, i10.GoogleLocationComponent, [i11.GlobalEventsManagerService, i12.ThemeService, i1.Renderer2, i3.DOCUMENT, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleLocationComponentNgFactory = i1.ɵccf("mdi-google-location", i10.GoogleLocationComponent, View_GoogleLocationComponent_Host_0, { googleMaps: "googleMaps" }, {}, []);
export { GoogleLocationComponentNgFactory as GoogleLocationComponentNgFactory };
