<div #refElement  fxLayout="column" fxLayoutAlign="space-between center" 
                class="dataAccess-container mat-elevation-z8 container-fluid stdTheme" style="width: 50%; margin-top: 10px" >
  <mat-card class="dataaccess-card stdTheme w-100" layout="column" flex>
   <!-- <mat-card-header>
      <mat-card-title  >
        <span #titleCard>{{ registerTitle | translate }}</span>
      </mat-card-title>
    </mat-card-header> -->
    <!--<mat-card-actions>

    </mat-card-actions> -->
    <mat-card-content [style.overflow]="'auto'" [style.height.px]="'500'" >

      <table mat-table #tableHabil [dataSource]="dataHabilitations">
        <ng-container matColumnDef="colName">
          <th mat-header-cell *matHeaderCellDef>{{'colName' | translate}}</th>
          <td mat-cell class="padding-0 margin-0" *matCellDef="let row; let index=index" [(value)]="row.colName" >{{row.colName}}</td>
        </ng-container>

        <ng-container matColumnDef="dataCategory">
          <th mat-header-cell *matHeaderCellDef>{{'dataCategory' | translate}}</th>

          <td mat-cell class="padding-0 margin-0" *matCellDef="let row; let index=index" > 
            <mat-form-field class="form-group" appearance="standard" fxLayoutAlign="center center" >
                <mat-select id="selectHabil" #selectHabilData placeholder="{{ 'Choose one category' | translate }}" 
                            class="selectHabilContent"
                            ngDefaultControl [(value)]="row.dataCategory" (selectionChange)="setValueArray('dataCategory',selectHabilData.value,index)">
                  <mat-option class="selectOptionsTheme" *ngFor="let dataCateg of dataCategoriesList" [value]="dataCateg.dataCategory">{{ dataCateg.label }}</mat-option>
                </mat-select>
            </mat-form-field >
          </td>
        </ng-container>

        <ng-container matColumnDef="dataLevel">
          <th mat-header-cell *matHeaderCellDef>{{'dataLevel' | translate}}</th>
          <!--<td mat-cell *matCellDef="let row; let index=index" [(value)]="row.dataLevel">{{row.dataLevel}}</td>-->
          <td mat-cell class="padding-0 margin-0" *matCellDef="let row; let index=index"> 
              <!--{{row.dataLevel}} -->
            <button type="button" class="btn btn-default " mat-icon-button (click)="levelMinus(row)">
                <span class="fa fa-minus"></span>
            </button>
            <mat-form-field class="form-group" style="width: 2vmax">
              <input type="text" #spinner2 matInput #input  [(value)]="row.dataLevel" style="text-align: center" (change)="setValueArray('dataLevel',spinner2.value,index)">
            </mat-form-field>
            <button type="button" class="btn btn-default " mat-icon-button (click)="levelPlus(row)">
                <span class="fa fa-plus"></span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dataHabilitationsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: dataHabilitationsColumns" ></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
        <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
            <button mat-raised-button  #buttonSubmit type="submit" (click)="register()"
                      >
              <mat-icon>create</mat-icon>
              {{ 'submit' | translate }}
            </button>
            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
              <mat-icon>cancel</mat-icon>
              {{ 'cancel' | translate }}
            </button>
          </div>
    </mat-card-actions>
  </mat-card>
</div>

