import { Injectable } from '@angular/core';

import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBroadcastService {
  public onChangeStatut = new BehaviorSubject<any>(undefined);
  constructor() { }
  broadcastEvent(event: any) {
    this.onChangeStatut.next(event);
  }
}
