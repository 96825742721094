import { Component, OnInit, ViewEncapsulation, Input, Output,  SimpleChanges, OnChanges, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WksCentralService } from '../../../../../../../job/job-wks/services/wks-central.service';
import { TranslateService } from '../../../../../../../services/translate.service';
import { UserService } from '../../../../../../../services/user.service';
import { WksManufacturersService } from '../../../../../../../job/job-wks/services/wks-manufacturers.service';
import { RessourcesService } from '../../../../../../../job/common/services/ressources.service';
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';
import { SUPPORTED_LANGS } from '../../../../../../../i18n/translation';

import { IhmOptions, JobManufacturerAtt, LangLabelModel, ParamActionsrefModel, ParamLabelsI18n, ParamWorksRatesModel, TasksActionsContentModel, TasksActionsModel, WksTasksModel } from '../../../../../../../job/job-wks/models/wks-param.model';
import { JobJson, JobLabel, Manufacturers, ManufacturersFilesType, PeriodicitiesRef, PeriodicityRef, SpecificationsEvent, 
          SpecificationsJobs, SpecificationsJobsInput, SpecificationsParts, SpecificationsPartsInput, SpecjobsModel } from '../../../../../../../job/job-wks/models/wks-manufacturers.model';

import { LabelValue } from '../../../../../../../models/data.model';
import { MessageDisplay } from '../../../../../../../models/common.model';
import { ModalCommonComponent } from '../../../../../../../job/common/components/modal-common/modal-common.component';
import { Label } from 'ng2-charts';


@Component({
  selector: 'mdi-specifications-card',
  templateUrl: './specifications-card.component.html',
  styleUrls: ['./specifications-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SpecificationsCardComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() idxUpdate: number;
  @Input() specjobsUpdate: SpecjobsModel;
  @Input() specjobsList: SpecjobsModel[];
  @Input() ihmOptions: IhmOptions;
  @Input() uuidCur: any;
  @Input() specBrand: string;
  @Input() workCodeDefault: string;

  @Output() dataOut = new EventEmitter<SpecificationsEvent>();
  
  private readonly onDestroy = new Subject<void>();
  
  specJobForm: FormGroup;
  isReadOnly: boolean;
  displayOK: boolean;
  arrayPartsValid: boolean;
  okToRegister: boolean;
  isNotValid: boolean;
  specjobsInput: SpecjobsModel;
  specjobsCache: SpecjobsModel;
  specjobsListInput: SpecjobsModel[];

  // periodicitiesRef: PeriodicitiesRef;
  jobMajor: boolean;
  periodicityInput: {
    hours: number;
    months: number;
  };
  
  specificationsPartsInput: SpecificationsPartsInput[];
  specificationsJobsInput: SpecificationsJobsInput[];

  fieldFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
  };
  levelOptions: {
    normal: string;
    major: string;
  };
  checkOptions: {
    checked: string;
    unchecked: string;
    value: string;
  };
  timeFormat: string;
  langCur: LangLabelModel;

  jobActionList: LabelValue[];
  specTypeList: LabelValue[];
  monthsList: LabelValue[];
  hoursList: LabelValue[];

  dialogRef: MatDialogRef<ModalCommonComponent, any>;
  filteredhoursList: any;
  listTasksCur: WksTasksModel[];
  listTasksFiltered: WksTasksModel[];
  listTasksCache: WksTasksModel[];
  listTasksLabel: LabelValue[];
  listLabelCache: LabelValue[];

  filteredTasksList: any;
  // workCodeList: LabelValue[];
  // listActions: ParamActionsrefModel[];
  actionRefList: LabelValue[];

  constructor(private fb: FormBuilder,
    private _userService: UserService, 
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _wksManufacturersService: WksManufacturersService,
    private _ressources: RessourcesService,
    private _dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case
           // actionType
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          } // end case
          // specjobsUpdate
          case 'specjobsUpdate': {
            this.specjobsUpdate = changes['specjobsUpdate'].currentValue;
            break;
          } // end case
          // uuidCur : forcing SimpleChanges
          case 'uuidCur': {
            this.uuidCur = changes['uuidCur'].currentValue;
            break;
          } // end case
          // specBrand
          case 'specBrand': {
            this.specBrand = changes['specBrand'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this.actionType === undefined ) { return; }
    if (this.actionType !== 'add' &&  this.actionType !== 'edit') { return; }
    if (this.specjobsUpdate !== undefined) { 
      this.initCache();
    }
    this.initAllBooleans();
    this.initParams();
    if (!this.settingIntFmts()) {
      return;
    }
    this.loadListsParameters();
    this.loadParamActionsRefList()
    .then (dataReturn => {
      this.initActionrefList(dataReturn as ParamActionsrefModel[]);
    // this._wksManufacturersService.loadWorksType(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
      // .then (dataReturn => {
        // this.initWorksTypeList(dataReturn as ParamWorksRatesModel[] );
        this.loadParamTasksRefByType(this.specjobsInput.equipType, this.specjobsInput.equipBrand, undefined)
        .then ((responseType2: any) => {
          this.buildSpecJobForm();
          this.initAllSubscribes(); 
        })
        .catch ((error: any) => {
          console.log('loadParamTasksRefByType : ' + error);
          if (error === 404 ) {
            this.buildSpecJobForm();
            this.initAllSubscribes(); 
          } else  {
            return;
          }
        });
      })
      .catch ((error: any) => {
        console.log('loadWorksType : ' + error);
        return;
      });
  }
  initAllBooleans() {
    this.isReadOnly = false;
    this.displayOK = false;
    this.arrayPartsValid = false;
    this.okToRegister = false;
    this.isNotValid = true;
  }
  initParams() {

  }
  loadListsParameters() {
    this.levelOptions = {
      normal: 'normal',
      major: 'major'
    };
    this.checkOptions = {
      checked: 'checked',
      unchecked: 'unchecked',
      value: ''
    };
    const supportedLangs = [...SUPPORTED_LANGS];
    const langUser = this._userService.getUserLogged().userlang;
    for (const langCur of supportedLangs) {
      if (langUser === langCur.value ) {
        this.langCur = {
          langValue: langCur.value,
          langDisplay: this._translate.getTranslate(langCur.display),
          langDirection: langCur.direction,
          langLabel: ''
        } ;
        break;
      }
    }
    this.jobActionList = [];
    const listactions = ['ExchFirst', 'Exchange', 'check'];
    for (const actionCur of listactions) {
      const labelValue: LabelValue =  {
        label: this._translate.getTranslate(actionCur),
        value:  actionCur,
      };
      this.jobActionList.push(labelValue);
    }
    this.jobActionList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.jobActionList.splice(0, 0, {
      value: '',
      label: '',
    });
    this.specTypeList = [];
    try {
      const  manufacturersList = this._wksCentralService.getManufacturers() as Manufacturers[];
      for (const manufacturerCur of manufacturersList) {
        if (manufacturerCur.brandName === this.specBrand) {
          const filesType = manufacturerCur.filesType as  ManufacturersFilesType[];
          for (const fileType of filesType) {
            const labelValue: LabelValue =  {
              label: fileType.typeName,
              value:  fileType.typeName,
            };
            this.specTypeList.push(labelValue);
          }
          break;
        }
      } 
      this.specTypeList.sort((obj1, obj2) => {
        return obj1.label > obj2.label ? 1 : -1;
      });
      this.specTypeList.splice(0, 0, {
        value: '',
        label: '',
      });
    } catch (error) {
      console.log('initParams ' + error);
      return false;
    } finally { }
    this.monthsList = [];
    for (let iIdx = 1; iIdx < 37; iIdx++) { 
      const labelValue: LabelValue =  {
        label: iIdx.toString(),
        value: iIdx.toString()
      };
      this.monthsList.push(labelValue);
    }

    this.hoursList = [];
    for (let iIdx = 50; iIdx < 550; iIdx = iIdx + 50) { 
      const labelValue: LabelValue =  {
        label: iIdx.toString(),
        value: iIdx.toString()
      };
      this.hoursList.push(labelValue);
    }
    // https://stackblitz.com/edit/mat-select-filter?file=src%2Fapp%2Fapp.component.ts
    this.filteredhoursList = this.hoursList.slice();
    
  }
  /*
  initWorksTypeList(dataReturn: ParamWorksRatesModel[]) {

    this.workCodeList = [];

    for (const workCur of dataReturn) {
      const jobTypeCur: LabelValue = {
        label: workCur.workLabel,
        value: workCur.workCode,
        otherData: {
          default: (workCur.workCode === this.workCode ? true : false),
          workUnit: workCur.workUnit
        }
      };

      this.workCodeList.push(jobTypeCur);
    }
    
    this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.workCodeList.splice(0, 0, {
      label: '',
      value: ''
    });
    
  }
  */
  initActionrefList(dataReturn: ParamActionsrefModel[]) {
    this.actionRefList = [];

    for (const actionRefCur of dataReturn) {
      if (actionRefCur.workCode !== this.workCodeDefault) {
        continue;
      }
      const jobTypeCur: LabelValue = {
        label: actionRefCur.actionrefLabel,
        value: actionRefCur.actionrefId,
        otherData: {
          workCode: actionRefCur.workCode,
          workUnit: 'hour'
        }
      };

      this.actionRefList.push(jobTypeCur);
    }
    
    this.actionRefList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.actionRefList.splice(0, 0, {
      label: '',
      value: ''
    });
  }
  initAllSubscribes() {
  }
  loadParamTasksRefByType(_equipType: string, _equipBrand: string, _equipModel: string) {

    this.listTasksCur = [];

    return new Promise((resolve, reject) => {
     
    this._wksCentralService.getParamTasksrefByTypeList(this._userService.getUserLogged().entity, 
                      _equipType, this._userService.getUserLogged().userlang, _equipBrand, _equipModel)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const tasksRead = data.body;
        this.listTasksCur = [];
        this.listTasksCache = [];
        for (const taskCur of tasksRead) {
          if (!taskCur.taskrefJson) {
            continue;
          }
          const taskrefJson = JSON.parse(taskCur.taskrefJson);
          if (!taskrefJson.jobManufacturerAtt) {
            continue;
          }
          const jobManufacturerAtt = taskrefJson.jobManufacturerAtt;
      
          if (jobManufacturerAtt !== undefined) {
            taskCur.jobManufacturerAtt = JSON.parse(JSON.stringify(jobManufacturerAtt));
            const isRevision = (taskCur.jobManufacturerAtt.specType.startsWith('revision') ? true : false);
            if (isRevision) {
              this.listTasksCur.push(taskCur);
              continue;
            }
          }
        }

        /*
        if (_equipSN !== undefined && _equipSN !== null && _equipSN !== '') {
          this.listTasksCur = [];
          this.listTasksCur = this.getRestrictedList(tasksRead, _equipSN);
        }
        */
        // this.affectTooltip(this.tasksReferenced);
        this.listTasksCache = JSON.parse(JSON.stringify(this.listTasksCur));
        for ( const taskRef of this.listTasksCur) {

          const labelValue: LabelValue =  {
            label: taskRef.taskrefLabel,
            value: taskRef.taskrefLabel
          };
          taskRef.selectedTask = false;
          taskRef.taskrefLabel += (taskRef.equipBrand ? ' - ' + taskRef.equipBrand : '' );
          taskRef.taskrefLabel += (taskRef.equipModel ? ' - ' + taskRef.equipModel : '' );
        }
        this.listTasksFiltered = JSON.parse(JSON.stringify(this.listTasksCur));
        this.filteredTasksList = this.listTasksCur.slice();
        resolve(tasksRead);
      },
      err => {
        console.log(err);
        reject(err.status);
        }
      );
    });
  }
  loadParamActionsRefList() {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getParamActionsRefList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          let actionsRead = data.body as ParamActionsrefModel[];
          // this.listActions = [...this.translateActionsRef(actionsRead)];
          actionsRead = this.translateActionsRef(actionsRead);
          resolve(actionsRead);
        },
        err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  translateActionsRef(actionsCur: ParamActionsrefModel[]): ParamActionsrefModel[] {
    const localArray:  ParamActionsrefModel[] = [];
    for (const localData of actionsCur ) {
      let langLabels: ParamLabelsI18n[];
      
      langLabels = localData.langLabels;
      if (langLabels !== null ) {
        for (const langLabel of langLabels) {
          if (langLabel.labelLang === this._translate.getCurrentLang()) {
            localData.actionrefLabel =  langLabel.labelText;
            break;
          }
        }
      }
      localArray.push(localData);
    }
    return localArray;
  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.fieldFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText:  this._ressources.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers:  this._ressources.getFormat(internationnalFormat, 'justifs', 'numbers')
    };
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    return true;
  }
  buildSpecJobForm() {
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.specJobForm = this.fb.group({
      equipModel: this.fb.control({value: '', disabled: this.isReadOnly}),
      jobPage: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(50), Validators.min(1)]),
      jobNumber: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(100), Validators.min(1)]),
      jobLabel: this.fb.control({value: '', disabled: this.isReadOnly}),
      jobNotes: this.fb.control({value: '', disabled: this.isReadOnly}),
      specType: this.fb.control({value: '', disabled: this.isReadOnly}),
      periodicityHours: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(500), Validators.min(50)]),
      periodicityMonths: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(60), Validators.min(6)]),
      jobLevel: this.fb.control({value: '', disabled: this.isReadOnly}),
      taskrefId: this.fb.control({value: '', disabled: this.isReadOnly})
    });

    this.fillForm();

  }
  initCache(): void {
    this.specjobsInput = JSON.parse(JSON.stringify(this.specjobsUpdate)); 
    this.specjobsCache = JSON.parse(JSON.stringify(this.specjobsUpdate)); 
    
    let specificationsParts: SpecificationsParts[] = [];
    let specificationsJobs: SpecificationsJobs[] = [];
   
    let jobJson: JobJson;
    if (this.specjobsInput.jobJson !== undefined 
      && this.specjobsInput.jobJson !== null 
      && this.specjobsInput.jobJson !== '') { 
        jobJson = JSON.parse(this.specjobsInput.jobJson) as JobJson;
        if (jobJson.parts) { 
          specificationsParts = JSON.parse(jobJson.parts) as SpecificationsParts[];
        }
        if (jobJson.work) { 
          specificationsJobs = JSON.parse(jobJson.work) as SpecificationsJobs[];
        }
    } else { 
      jobJson = {
        parts: JSON.stringify(specificationsParts),
        work: JSON.stringify(specificationsJobs)
      };
    } 

    this.specjobsCache.jobJson = JSON.stringify(jobJson);
    this.specjobsListInput = JSON.parse(JSON.stringify(this.specjobsList)); 
    this.specjobsInput.specJobOtherData = { 
      checked: false,
      jobLabels: undefined,
      taskrefTarget: undefined
    };
    if (this.specjobsInput.specOtherdata !== undefined 
      && this.specjobsInput.specOtherdata !== null
      && this.specjobsInput.specOtherdata !== '') { 
      this.specjobsInput.specJobOtherData = JSON.parse(this.specjobsInput.specOtherdata);
      this.specjobsCache = JSON.parse(JSON.stringify(this.specjobsInput)); 
    } else { 
      this.specjobsInput.specOtherdata = JSON.stringify(this.specjobsInput.specJobOtherData); // init value for ctrlInput
      this.specjobsCache = JSON.parse(JSON.stringify(this.specjobsInput)); 
    } 
  }
  fillForm(): void {
    if (this.specjobsInput === undefined) {
      this.formDefaultsValues();
      return;
    }
    if (this.listLabelCache === undefined) {
      this.listLabelCache = [];
    }
    this.periodicityInput = {
      hours: 0,
      months: 0
    };
   
    this.specificationsPartsInput = [];
    this.specificationsJobsInput = [];
    if (this.specjobsInput.specFrequency !== undefined 
      && this.specjobsInput.specFrequency !== null 
      && this.specjobsInput.specFrequency !== '') {
      const periodicitiesRef = JSON.parse(this.specjobsInput.specFrequency) as PeriodicitiesRef;
      for (const periodicityItem of periodicitiesRef.periodicities) {
        if (periodicityItem.timeUnit === 'hours') {
          this.periodicityInput.hours = periodicityItem.timeUse;
        }
        if (periodicityItem.timeUnit === 'months') {
          this.periodicityInput.months = periodicityItem.timeUse;
        }
      }
    } else  {
      const periodicitiesEmpty: PeriodicityRef[] = []; 
      let periodicityRefTmp: PeriodicityRef = {
        timeUnit: 'hours',
        timeUse: -1
      };
      periodicitiesEmpty.push(periodicityRefTmp);
      periodicityRefTmp = {
        timeUnit: 'months',
        timeUse: -1
      };
      periodicitiesEmpty.push(periodicityRefTmp);
    }
    this.listTasksLabel = [];

    for ( const taskRef of this.listTasksCache) {
      if ( taskRef.jobManufacturerAtt.hours === this.periodicityInput.hours
        && taskRef.jobManufacturerAtt.months === this.periodicityInput.months)  {
          const labelValue: LabelValue =  {
            label: taskRef.taskrefLabel,
            value: taskRef.taskrefLabel
          };
          this.listTasksLabel.push(labelValue);
        }
    }
    for (const labelCacheCur of this.listLabelCache) {
      if ( labelCacheCur.otherData.hours === this.periodicityInput.hours
        && labelCacheCur.otherData.months === this.periodicityInput.months)  {
          const labelValue: LabelValue =  {
            label: labelCacheCur.label,
            value: labelCacheCur.value
          };
          this.listTasksLabel.push(labelValue);
        }
    } 
    this.listTasksLabel.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    // adding current label
    const labelCur: LabelValue = {
      label: this.specjobsInput.jobLabel,
      value: this.specjobsInput.jobLabel
    };
    this.listTasksLabel.unshift(labelCur);

    this.jobMajor = (this.specjobsInput.jobNormal ? false : true);
    this.specJobForm.controls.equipModel.setValue(this.specjobsInput.equipModel);
    this.specJobForm.controls.jobPage.setValue(this.specjobsInput.jobPage);
    this.specJobForm.controls.jobNumber.setValue(this.specjobsInput.jobNumber);
    this.specJobForm.controls.jobLabel.setValue(this.specjobsInput.jobLabel);
    this.specJobForm.controls.jobNotes.setValue(this.specjobsInput.jobNotes);
    this.specJobForm.controls.specType.setValue(this.specjobsInput.specType);
    this.specJobForm.controls.taskrefId.setValue(this.specjobsInput.taskrefId);
    this.specJobForm.controls.periodicityHours.setValue(this.periodicityInput.hours);
    this.specJobForm.controls.periodicityMonths.setValue(this.periodicityInput.months);
    // const specificationsParts: SpecificationsParts[] = JSON.parse(this.specjobsInput.partsJson) as SpecificationsParts[];
    let specificationsParts: SpecificationsParts[] = [];
    let specificationsJobs: SpecificationsJobs[] = [];
    if (this.specjobsInput.jobJson !== undefined 
      && this.specjobsInput.jobJson !== null 
      && this.specjobsInput.jobJson !== '') { 
      const jobJson = JSON.parse(this.specjobsInput.jobJson) as JobJson;
      if (jobJson.parts) { 
        specificationsParts = JSON.parse(jobJson.parts) as SpecificationsParts[];
      }
      if (jobJson.work) { 
        specificationsJobs = JSON.parse(jobJson.work) as SpecificationsJobs[];
      }
    }
/*
    if (this.specjobsInput.partsJson !== undefined 
        && this.specjobsInput.partsJson !== null 
        && this.specjobsInput.partsJson !== '') { 
      specificationsParts = JSON.parse(this.specjobsInput.partsJson) as SpecificationsParts[];
    }
*/
    if (this.specjobsInput.specOtherdata !== undefined && this.specjobsInput.specOtherdata !== null) { 
      this.specjobsInput.specJobOtherData = JSON.parse(this.specjobsInput.specOtherdata);
      if (this.specjobsInput.specJobOtherData.checked) { 
        this.checkOptions.value = 'checked';
        this.isNotValid = false;
      } else { 
        this.checkOptions.value = 'unchecked';
        this.isNotValid = false;
      }
    } else { 
      this.specjobsInput.specJobOtherData = { 
        checked: false,
        jobLabels: undefined,
        taskrefTarget: undefined
      };
      this.checkOptions.value = 'unchecked';
    } 
    // this.specJobForm.controls.checked.setValue(this.checkOptions.value);

    let idxRow = 0;
    for (const specificationPartItem of specificationsParts) {
      const partItem: SpecificationsPartsInput = {
        specificationsParts : specificationPartItem,
        action: (specificationPartItem.replaceFirstYear ? 'ExchFirst' : (specificationPartItem.replace ? 'Exchange' : 'check')),
        isEven: (idxRow % 2 === 0 ? true : false),
        isOdd: (idxRow % 2 !== 0 ? true : false),
      };
      idxRow++;
      this.specificationsPartsInput.push(partItem);
    } 
    if (specificationsJobs.length > 0) {
      idxRow = 0;
      for (const specificationJobItem of specificationsJobs) {
        const partItem: SpecificationsJobsInput = {
          specificationsJob : specificationJobItem,
          isEven: (idxRow % 2 === 0 ? true : false),
          isOdd: (idxRow % 2 !== 0 ? true : false),
          workLabel: ''
        };
        idxRow++;
        this.specificationsJobsInput.push(partItem);
      } 
    } else  {
      this.addRowWork();
    }
    this.filteringTasks();
    this.displayOK = true;
  }
  formDefaultsValues() {
    this.jobMajor = false;
    this.periodicityInput = {
      hours: 0,
      months: 0
    };

    this.specificationsPartsInput = [];
    this.specificationsJobsInput = [];
    this.specJobForm.controls.jobPage.setValue(-1);
    this.specJobForm.controls.jobNumber.setValue(-1);
    this.specJobForm.controls.equipModel.setValue('');
    this.specJobForm.controls.jobLabel.setValue('');
    this.specJobForm.controls.jobNotes.setValue('');
    this.specJobForm.controls.specType.setValue('');
    this.specJobForm.controls.taskrefId.setValue('');
    this.specJobForm.controls.periodicityHours.setValue(0);
    this.specJobForm.controls.periodicityMonths.setValue(0);

    this.displayOK = true;
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  doCancelSpec(): void {
    if (this.okToRegister) {
      const messageDisplay: MessageDisplay = {
        titleBox: 'valid specification',
        messageLabel: 'lost data',
        messageArgs: [],
        messageType: 'ERROR',
        messageDatas: undefined,
        typeDialog: 'confirmAction',
        actionCur: 'validSpecification',
        progressDeterminate: false,
      };
      this.messageBoxDisplay(messageDisplay);
      return;
    } else {
      this.closeSidenavSpec();
    }
  }
  closeSidenavSpec(): void {
    const specificationsEvent: SpecificationsEvent = {
      objectCur: 'SpecificationsCard',
      action: 'toggleSidenavSpec',
      rowIdx: -1
    };
    this.dataOut.emit(specificationsEvent);
  }
  onClearTask(event: any, value: any): void {

    if (value === undefined) {
      return;
    }
    let workCodeToClear: string;
    for (const taskCur of this.filteredTasksList) {
      if (taskCur.taskrefId === value) {
        workCodeToClear = taskCur.workCode;
        break;
      }
    }
    for (const jobCur of this.specificationsJobsInput ) {
      if (jobCur.specificationsJob.workCode === workCodeToClear) {
        jobCur.specificationsJob.quantity = 0;
        break;
      }
    }

    this.specjobsInput.taskrefId = '';
    this.specJobForm.controls.taskrefId.setValue('');

    event.stopPropagation();
    this.ctrlInput();
  }
  onInputChange(_typeInput: string, inputValue: any): void {

    switch (_typeInput) {
      case 'equipModel':
        this.specjobsInput.equipModel = inputValue;
        this.ctrlInput();
        break;
      case 'jobPage':
        this.specjobsInput.jobPage = inputValue;
        this.ctrlInput();
        break;
      case 'jobNumber':
        this.specjobsInput.jobNumber = inputValue;
        this.ctrlInput();
        break;
      case 'newJobLabel':
        const labelCur: LabelValue = {
          label: inputValue,
          value: inputValue,
          otherData: this.periodicityInput
        };
        this.listLabelCache.push(labelCur);
        this.listTasksLabel.unshift(labelCur);      
        this.specjobsInput.jobLabel = inputValue;
        this.otherDataLabels(inputValue);
        this.ctrlInput();
        break;
      case 'jobLabel':
        this.specjobsInput.jobLabel = inputValue;
        this.otherDataLabels(inputValue);
        this.ctrlInput();
        break;
      case 'jobNotes':
        this.specjobsInput.jobNotes = inputValue;
        this.ctrlInput();
        break;  
      case 'specType':
        this.specjobsInput.specType = inputValue;
        this.ctrlInput();
        break;
      case 'periodicityHours':
        this.periodicityInput.hours = inputValue;
        this.filteringTasks();
        this.ctrlInput();
        break;
      case 'periodicityMonths':
        this.periodicityInput.months = inputValue;
        this.ctrlInput();
        this.filteringTasks();
        break;
      case 'jobLevel':
        if (inputValue === 'major' ) {
          this.specjobsInput.jobMajor = true;
          this.specjobsInput.jobNormal = false;
          this.jobMajor = true;
        } else {
          this.specjobsInput.jobMajor = false;
          this.specjobsInput.jobNormal = true;
          this.jobMajor = false;
        }
        this.filteringTasks();
        this.ctrlInput();
        break;
      case 'taskrefId':
        this.specjobsInput.taskrefId = inputValue;

        this.readTaskReferenced(inputValue)
        .then (
          (returnValue: any) => {
            this.ctrlInput();
              },
          (error: any) => {
            this.ctrlInput();
        }); 
        break;
      case 'checked':
        if (this.specjobsInput.specJobOtherData === undefined || this.specjobsInput.specJobOtherData === null) {
          this.specjobsInput.specJobOtherData =  {
            checked: false,
            jobLabels: undefined,
            taskrefTarget: undefined
          };
        }
        if (inputValue === 'checked' ) {
          this.specjobsInput.specJobOtherData.checked = true;
          this.checkOptions.value = 'checked';
        } else {
          this.specjobsInput.specJobOtherData.checked = false;
          this.checkOptions.value = 'unchecked';
        }
        this.ctrlInput();
        break;
    }
  }
  otherDataLabels(inputValue: string): void {
    // jobLabels: JobLabel [] 
    const jobLabels = this.specjobsInput.specJobOtherData.jobLabels;
    let idxLang = 0;
    for (const jobLabel of jobLabels) {
      if (jobLabel.lang === this._userService.getUserLogged().userlang) {
        jobLabels.splice(idxLang, 1);
        break;
      }
      idxLang++;
    }
    const jobLabelTmp: JobLabel = {
      label: inputValue,
      lang: this._userService.getUserLogged().userlang
    };
    this.specjobsInput.specJobOtherData.jobLabels.push(jobLabelTmp);
  }
  filteringTasks(): void {
    this.listTasksFiltered = [];
    for (const taskCur of this.listTasksCur) {
      if (taskCur.jobManufacturerAtt.jobMajor === this.specjobsInput.jobMajor
        && taskCur.jobManufacturerAtt.hours === this.periodicityInput.hours
        && taskCur.jobManufacturerAtt.months === this.periodicityInput.months) {
          this.listTasksFiltered.push(taskCur);
      }
    }
    this.filteredTasksList = this.listTasksFiltered.slice();
  }
  readTaskReferenced(taskrefId: string): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getParamTasksref(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang, taskrefId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          // this.dataSource.data = data.body;
          const wksTasksCur = data.body;
          const taskrefJson = JSON.parse(wksTasksCur.taskrefJson);
          const tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
          const works = tasksActionsContent.works as TasksActionsModel[];
          
          this.specificationsJobsInput[0].specificationsJob = {
            workCode: works[0].workCode,
            actionrefId:  works[0].actionrefId,
            workUnit: 'hour',
            quantity: works[0].quantity
          };
          this.specJobForm.controls.jobLabel.setValue(wksTasksCur.taskrefLabel);
          this.otherDataLabels(wksTasksCur.taskrefLabel);
          resolve(wksTasksCur);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  onInputPartsArrayChange(_typeInput: string, inputValue: any, inputRow: number): void {

    switch (_typeInput) {
      case 'brandRef':
        this.specificationsPartsInput[inputRow].specificationsParts.brandRef = inputValue;
        if (this.ctrlNotDuplicatedPart(inputRow, inputValue)) {
          this.ctrlInput();
        }
        break;
      case 'brandLabel':
        this.specificationsPartsInput[inputRow].specificationsParts.brandLabel = inputValue;
        this.ctrlInput();
        break;
      case 'quantity':
        this.specificationsPartsInput[inputRow].specificationsParts.quantity = inputValue;
        this.ctrlInput();
        break;
      case 'jobAction':
        this.specificationsPartsInput[inputRow].action = inputValue;
        this.specificationsPartsInput[inputRow].specificationsParts.check = false;
        this.specificationsPartsInput[inputRow].specificationsParts.replace = false;
        this.specificationsPartsInput[inputRow].specificationsParts.replaceFirstYear = false;
        if (inputValue === 'ExchFirst') {
          this.specificationsPartsInput[inputRow].specificationsParts.replaceFirstYear = true;
        }
        if (inputValue === 'Exchange') {
          this.specificationsPartsInput[inputRow].specificationsParts.replace = true;
        }
        if (inputValue === 'ckeck') {
          this.specificationsPartsInput[inputRow].specificationsParts.check = true;
        }
        this.ctrlInput();
        break;

    }
  }
  removeRowWork(idxJob: number): void {
    this.specificationsJobsInput.splice(idxJob, 1);
    if (this.specificationsJobsInput.length === 0) {
      this.addRowWork();
    }
    this.ctrlInput();
  }
  addRowWork(): void {

    const idxRow = this.specificationsJobsInput.length;

    const tmpWork: SpecificationsJobs = {
      workCode: '',
      actionrefId: '',
      workUnit: '',
      quantity: 0,
    };
    if (this.specificationsJobsInput.length === 0 ) {
      tmpWork.workCode = this.workCodeDefault; 
    }
    const newWorkItem: SpecificationsJobsInput = {
      specificationsJob: tmpWork,
      workLabel: '',
      isEven: (idxRow % 2 === 0 ? true : false),
      isOdd: (idxRow % 2 !== 0 ? true : false),
    };
    this.specificationsJobsInput.push(newWorkItem);
    this.ctrlInput();
  }
  onInputJobsArrayChange(_typeInput: string, inputValue: any, inputRow: number): void {

    switch (_typeInput) {
      case 'workCode':
        this.specificationsJobsInput[inputRow].specificationsJob.workCode = inputValue.workCode;
        if (this.ctrlNotDuplicatedJob(inputRow, inputValue)) {
          this.specificationsJobsInput[inputRow].specificationsJob.workUnit = inputValue.otherData.workUnit;
          this.ctrlInput();
        }
        break;
      case 'actionRef' :
        let selectedValue: LabelValue;
        for (const actionRefCur of this.actionRefList) {
          if (actionRefCur.value === inputValue) {
            selectedValue = actionRefCur;
            break;
          }
        }
        this.specificationsJobsInput[inputRow].specificationsJob.workCode = selectedValue.otherData.workCode;
        this.specificationsJobsInput[inputRow].specificationsJob.actionrefId = selectedValue.value;
        if (this.ctrlNotDuplicatedJob(inputRow, inputValue)) {
          this.specificationsJobsInput[inputRow].specificationsJob.workUnit = selectedValue.otherData.workUnit;
          this.ctrlInput();
        }
      break;
      case 'quantity':
        let numberValue = 0;
        try {
          numberValue = Number(inputValue);
        } catch (error) {
        } finally { }
        this.specificationsJobsInput[inputRow].specificationsJob.quantity = numberValue;
        if (numberValue === 0) {
          this.specjobsInput.specJobOtherData.checked = false;
          this.checkOptions.value = 'unchecked';
        }
        this.ctrlInput();
        break;
    }
  }
  ctrlNotDuplicatedJob(idxRow: number, inputValue: any ): boolean {
    // this.specificationsPartsInput[inputRow].specificationsParts.brandRef
    let idxItem = 0;
    let notDuplicated = true;
    for (const specJobItem of this.specificationsJobsInput) {
      if (idxItem === idxRow) {
        continue;
      }
      if (specJobItem.specificationsJob.actionrefId === inputValue)  {

        const  messageDisplay: MessageDisplay = {
          titleBox: 'valid specification',
          messageLabel: 'duplicated work',
          messageArgs: [inputValue],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'duplicatedWorkCode',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
        notDuplicated = false;
        break;
      }
      idxItem++;
    }
    return notDuplicated;
  }
  ctrlNotDuplicatedPart(idxRow: number, inputValue: any ): boolean {
    // this.specificationsPartsInput[inputRow].specificationsParts.brandRef
    let idxItem = 0;
    let notDuplicated = true;
    for (const specPartItem of this.specificationsPartsInput) {
      if (idxItem === idxRow) {
        continue;
      }
      if (specPartItem.specificationsParts.brandRef === inputValue)  {

        const  messageDisplay: MessageDisplay = {
          titleBox: 'valid specification',
          messageLabel: 'duplicated brand ref',
          messageArgs: [inputValue],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'duplicatedBrandRef',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
        notDuplicated = false;
        break;
      }
      idxItem++;
    }
    return notDuplicated;
  }
  validArrayParts(): boolean {
    let isValid = true;
    if (this.specificationsPartsInput.length === 0) {
      isValid = false;
    }
    for (const partsItem of this.specificationsPartsInput) {
      if (partsItem.specificationsParts.brandLabel === '') {
        isValid = false;
        break;
      }
      if (partsItem.specificationsParts.brandRef === '') {
        isValid = false;
        break;
      }
      if (partsItem.specificationsParts.quantity < 1) {
        isValid = false;
        break;
      }
      if (partsItem.action === '') {
        isValid = false;
        break;
      }
    }
    return isValid;
  }
  validArrayJobs(): boolean {
    let isValid = true;
    if (this.specificationsJobsInput.length === 0) {
      isValid = false;
    }
    for (const jobItem of this.specificationsJobsInput) {
      if (jobItem.specificationsJob.workCode === '') {
        isValid = false;
        break;
      }
      if (jobItem.specificationsJob.quantity < 1 ) {
        isValid = false;
        break;
      }

    }
    return isValid;
  }
  ctrlInput(): void {
    this.okToRegister = false;
    this.isNotValid = true;
    if (this.specJobForm.status === 'VALID' && this.validArrayParts() && this.validArrayJobs() ) {
      const fromCache = JSON.stringify(this.specjobsCache);
      // fromCache = fromCache.replace('\\', '');
      // fromCache = fromCache.replace('\\/S', '');

      const fromInput = JSON.stringify(this.fillModel());
      // fromInput = fromInput.replace('\\', '');
      // fromInput = fromInput.replace('\\/S', ''); 
      if (fromCache !== fromInput) {
        this.okToRegister = true;
      }
      this.isNotValid = false;
    } 
  }
  removeRowSpecifications(idxPart: number): void {
    this.specificationsPartsInput.splice(idxPart, 1);
    this.ctrlInput();
  }
  removeRowJobSpecifications(idxPart: number): void {
    this.specificationsJobsInput.splice(idxPart, 1);
    this.ctrlInput();
  }
  addRowPartSpecifications(idxPart: number): void {

    const idxRow = this.specificationsPartsInput.length;

    const tmpPart: SpecificationsParts = {
      brandRef: '',
      brandNotes: '',
      quantity: 0,
      brandDatas: '',
      replaceFirstYear: false,      
      replace: false,     
      brandLabel: '',
      check: false,     
    };

    const newPartItem: SpecificationsPartsInput = {
      specificationsParts: tmpPart,
      action: '',
      isEven: (idxRow % 2 === 0 ? true : false),
      isOdd: (idxRow % 2 !== 0 ? true : false),
    };
    this.specificationsPartsInput.push(newPartItem);
    this.ctrlInput();
  }
  addRowJobSpecifications(idxPart: number): void {

    const idxRow = this.specificationsJobsInput.length;

    const tmpPart: SpecificationsJobs = {
      workCode: '',
      actionrefId: '',
      workUnit: '',
      quantity: 0,
  
    };

    const newJobItem: SpecificationsJobsInput = {
      specificationsJob: tmpPart,
      isEven: (idxRow % 2 === 0 ? true : false),
      isOdd: (idxRow % 2 !== 0 ? true : false),
      workLabel: ''
    };
    this.specificationsJobsInput.push(newJobItem);
    this.ctrlInput();
  }
  registerSpecification(): void {
    let messageDisplay: MessageDisplay = {
      titleBox: 'valid specification',
      messageLabel: 'validation in progress',
      messageArgs: [],
      messageType: 'INFO',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'validSpecification',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);

// ==========================
    this.register()
    .then(
      (returnValue: any) => {
        this.okToRegister = false;
        this.specjobsUpdate = returnValue;
        this.initCache();
        if (this.actionType === 'add') {
          this.specjobsListInput.push(returnValue);
        }
        this.closeMatDialog();
        messageDisplay = {
          titleBox: 'valid specification',
          messageLabel: 'validation OK',
          messageArgs: [],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'validSpecificationOK',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
      },
      (error) => {
        this.okToRegister = false;
        console.log('registerSpecification KO  : ' + error);
        this.closeMatDialog();

        messageDisplay = {
          titleBox: 'valid specification',
          messageLabel: 'validation KO',
          messageArgs: [error.status],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'validSpecificationKO',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
      } ); 

  }
  // register card
  
  register() {
    return new Promise((resolve, reject) => {
      this._wksManufacturersService.saveSpecjobs(this.fillModel(), this.actionType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        specjobsData => {
          resolve(specjobsData.body);
        },
        (err) => {
          console.log ('error register specifications');
          reject(err.status);
        }
      );
    });
  }
  // ----------------------- navigation list
  navJob(askArg: string): void {

    switch (askArg) {
      case 'lastJob': {
        this.idxUpdate = this.specjobsListInput.length - 1;
        this.specjobsUpdate = this.specjobsListInput[this.idxUpdate];
        this.initCache();
        this.fillForm();
        break;
      } // end case
      case 'firstJob': {
        this.idxUpdate = 0;
        this.specjobsUpdate = this.specjobsListInput[this.idxUpdate];
        this.initCache();
        this.fillForm();
        break;
      } // end case
      case 'previousJob': {
        this.idxUpdate --;
        if (this.idxUpdate < 0) {
          this.idxUpdate = 0;
          break;
        } 
        this.specjobsUpdate = this.specjobsListInput[this.idxUpdate];
        this.initCache();
        this.fillForm();
        break;
      } // end case     
      case 'nextJob': {
        this.idxUpdate ++;
        if (this.idxUpdate > this.specjobsListInput.length - 1) {
          this.idxUpdate = this.specjobsListInput.length - 1;
          break;
        } 
        this.specjobsUpdate = this.specjobsListInput[this.idxUpdate];
        this.initCache();
        this.fillForm();
        break;
      } // end case    
    }
  } 
  
  // fill model to validation
  fillModel(): SpecjobsModel {
    const specjobsCard: SpecjobsModel = JSON.parse(JSON.stringify(this.specjobsUpdate));

    const specificationsParts: SpecificationsParts[] = [];
    for (const partsItem of this.specificationsPartsInput) {
      specificationsParts.push(partsItem.specificationsParts);
    }
    const specificationsJobs: SpecificationsJobs[] = [];
    for (const jobItem of this.specificationsJobsInput) {
      specificationsJobs.push(jobItem.specificationsJob);
    }
    const jobJson: JobJson =  { 
      parts: JSON.stringify(specificationsParts),
      work: JSON.stringify(specificationsJobs),
    };
    const periodicitiesInput: PeriodicityRef[] = []; 
    let periodicityRefTmp: PeriodicityRef = {
      timeUnit: 'hours',
      timeUse: this.periodicityInput.hours
    };
    periodicitiesInput.push(periodicityRefTmp);
    periodicityRefTmp = {
      timeUnit: 'months',
      timeUse: this.periodicityInput.months
    };
    periodicitiesInput.push(periodicityRefTmp);

    const periodicitiesRef: PeriodicitiesRef =  {
      periodicities: periodicitiesInput
    };
    specjobsCard.specFrequency = JSON.stringify(periodicitiesRef);
    specjobsCard.jobJson = JSON.stringify(jobJson);
    specjobsCard.equipModel = this.specjobsInput.equipModel;
    specjobsCard.jobPage = this.specjobsInput.jobPage;
    specjobsCard.jobNumber = this.specjobsInput.jobNumber;
    specjobsCard.jobLabel = this.specjobsInput.jobLabel;
    specjobsCard.jobNotes = this.specjobsInput.jobNotes;
    specjobsCard.specType = this.specjobsInput.specType;
    specjobsCard.taskrefId = this.specjobsInput.taskrefId;
    specjobsCard.jobMajor = (this.jobMajor ? true : false);
    specjobsCard.jobNormal = (this.jobMajor ? false : true);
    specjobsCard.specOtherdata = JSON.stringify(this.specjobsInput.specJobOtherData);
    return specjobsCard;
  }
  // ========================================================== errors alerts 
  messageBoxDisplay(_messageDisplay: MessageDisplay) {

    const dialogConfig = new MatDialogConfig();
    const messageBox = this._translate.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate( _messageDisplay.titleBox),
      typeDialog: _messageDisplay.typeDialog,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: _messageDisplay.messageDatas,
      data2: '',
      actionCur: _messageDisplay.actionCur,
      progressDeterminate: _messageDisplay.progressDeterminate,
      errorsList: undefined,
      messageType: _messageDisplay.messageType
    };
    this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    
    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') && 
          ( _messageDisplay.actionCur === 'validSpecification')) {
          this.specjobsUpdate = undefined;
          this.closeSidenavSpec();
        } else if (( data === 'okAction') && 
          (_messageDisplay.actionCur === 'validSpecificationKO')) {
          this.specjobsUpdate = undefined;

        } else if (( data === 'okAction') && 
        (_messageDisplay.actionCur === 'validSpecificationOK')) {
          this.specjobsUpdate = undefined;
          const specificationsEvent: SpecificationsEvent = {
            objectCur: 'SpecificationsCard',
            action: 'refreshList',
            rowIdx: -1
          };
          this.dataOut.emit(specificationsEvent);
        } else {
          // this.specjobsInput = JSON.parse(JSON.stringify(this.specjobsUpdate)); 
          
          this.endProcessFile();
        } 
      });

  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {
  
      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.dialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
}
