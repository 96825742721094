<div  class="container-fluid stdTheme" style="margin-top: 0px; height: 98%;overflow:hidden !important" >
    <div *ngIf="!dataPdfLoaded" class="flex-row row" style="margin: 0px">
        <div class="container-fluid d-flex justify-content-center" >
            <mat-progress-bar 
                mode="indeterminate"
                style="margin-left: 30px;">
          </mat-progress-bar>
        </div>
    </div>
    <div *ngIf="dataPdfLoaded" class="flex-row row stdTheme" style="margin: 0px; margin-bottom: 5px; margin-top: 5px;overflow:hidden !important">
        <div class="container-fluid d-flex justify-content-between">
           
            <select class="form-control stdTheme " id="pdfSelect"  #pdfSelect style="width:auto;"  (change)="setPdfCur(pdfSelect.value)" >
                <option class="optionSelect" *ngFor="let pdfCur of pdfFiles" [selected]="pdfCur.fileLabel === selectedPdf.fileLabel" >{{ pdfCur.fileLabel}}</option>
            </select>
            <div  *ngIf="toValidated">
                <button  mat-raised-button class="buttonTheme" 
                        (click)="validateEvent($event)"
                    style="margin-left: 10px;margin-right: 10px;">
                    <mat-icon><i class="material-icons">fact_check</i></mat-icon>
                    {{ 'submiting' | translate }}
                </button>
            </div>
            <div  *ngIf="toApproved">
                <button  mat-raised-button class="buttonTheme" 
                        (click)="approvedEvent($event)"
                    style="margin-left: 10px;margin-right: 10px;">
                    <mat-icon><i class="material-icons">approval</i></mat-icon>
                    {{ 'approved' | translate }}
                </button>
            </div>
            <div  *ngIf="toRefused">
                <button  mat-raised-button class="buttonTheme" 
                        (click)="refusedEvent($event)"
                    style="margin-left: 10px;margin-right: 10px;">
                    <mat-icon><i class="material-icons">report_off</i></mat-icon>
                    {{ 'refused' | translate }}
                </button>
            </div>
            <div  *ngIf="displayClose">
                <button  mat-raised-button class="buttonTheme" 
                        (click)="closeEvent($event)"
                    style="margin-left: 10px;margin-right: 10px;">
                    <mat-icon>close</mat-icon>
                    {{ 'close' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="flex-row row stdTheme" style="margin: 0px;">
        <div class="col-12" style="height: 75vh">
            <ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>
        </div>
    </div>
</div>