import { Component, OnInit, Input,  OnChanges, SimpleChanges, AfterContentInit, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';

import { RequestInt,  ColumnsList, RequestModel } from '../../../models/job-data.model';
import { UserService } from '../../../../../services/user.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';



@Component({
  selector: 'mdi-request-dataaccess',
  templateUrl: './request-dataaccess.component.html',
  styleUrls: ['./request-dataaccess.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RequestDataaccessComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() statutCall: any;
  @Input() requestToUpdate: RequestInt;

  dataHabilitationsColumns: any;
  dataCategoriesList: any;
  dataHabilitations: ColumnsList[];
  dataSource: any;

  registrationFailed: boolean;
  messageTranslate: string;

  constructor(private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    ) { }

  ngOnInit() {
    this.initData();
  }
  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: forin
    for (const propName in changes) {  
      const change = changes[propName];
      
      const curVal  = JSON.stringify(change.currentValue);
      const prevVal = JSON.stringify(change.previousValue);
      const changeLog = `${propName}: currentValue = ${curVal}, previousValue = ${prevVal}`;
      // console.log(changeLog);
    }
    this.initData();    
  }
  ngAfterContentInit() { 

  }
  initData() {
    const dataCategories = this._userService.getUserLogged().dataCategories.split(',');
    this.dataCategoriesList = [];
    for (const dataCateg of dataCategories) {
      const dataCat = {
        dataCategory: dataCateg,
        label: this._translate.getTranslate(dataCateg),
      };
        this.dataCategoriesList.push(dataCat);
    }

    this.dataHabilitationsColumns = ['colName', 'dataCategory', 'dataLevel'];
    this.setValuesToUpdate();
  }

  setValueArray(dataField: string, dataValue: string, index: number) {
    if ( dataField ===  'dataCategory') { this.dataHabilitations[index].dataCategory  = dataValue; }
    if ( dataField ===  'dataLevel') {
      let curValue = Number(dataValue);
      if (curValue > 9) {
        curValue = 9;
      }
      if ( curValue < 0 ) {
        curValue = 0;
      }
      this.dataHabilitations[index].dataLevel = curValue.toString();
      dataValue  = curValue.toString();
    }
    return dataValue;
  }
  levelPlus(raw: any) {
    let levelCur = +raw.dataLevel;
    if (levelCur === undefined) { levelCur = 0; }
    if (levelCur < 9) {
      levelCur += 1;
      raw.dataLevel = levelCur.toString();
      return raw.dataLevel;
    } else { return raw.dataLevel; }
  }
  levelMinus(raw: any) {
    let levelCur = +raw.dataLevel;
    if (levelCur === undefined) { levelCur = 0; }
    if (levelCur > 0 ) {
      levelCur -= 1;
      raw.dataLevel = levelCur.toString();
      return raw.dataLevel;
    } else { return raw.dataLevel; }
  }

  setValuesToUpdate() {
    const categDefault = this._userService.getUserLogged().dataCategoriesTypeDefault;
    const levelDefault = this._userService.getUserLogged().dataCategoriesLevelDefault;

    const requestCol = JSON.parse(this.requestToUpdate.requestColumns);

    this.dataHabilitations = [];
    for (const col of requestCol.columns) {
      // console.log(col.colName);
      let dataCateg = col.dataCategory;
      let dataLevel = col.dataLevel;
      if (!dataCateg) {
        dataCateg = categDefault;
        dataLevel = levelDefault;
      }
      const dataCur: ColumnsList = {
        colName: col.colName,
        colDesc: col.colDesc,
        dataCategory: dataCateg,
        dataLevel: dataLevel,
      };
      this.dataHabilitations.push(dataCur);
      this.dataHabilitations.sort(function(obj1, obj2) {
        return obj1.colName > obj2.colName ? 1 : -1;
      });
    }
  }
  register() {
    this.registrationFailed = false;
    let requestCard: RequestInt;
    requestCard = this.prepareValidation();
    this._jobDataService.saveRequestDataAccess(requestCard)
      .subscribe(
        () => {

          if (this.statutCall) {
            this._jobDataService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation(): RequestInt {

    const requestCard: RequestModel = new RequestModel();
    requestCard.id = this.requestToUpdate.id;
    requestCard.requestLabel = this.requestToUpdate.requestLabel;
    requestCard.requestEngine = this.requestToUpdate.requestEngine;
    requestCard.dbsId = this.requestToUpdate.dbsId;
    requestCard.requestDefault = this.requestToUpdate.requestDefault;

    requestCard.requestVersion = this.requestToUpdate.requestVersion;
    requestCard.requestContent = this.requestToUpdate.requestContent;
    requestCard.accessEntities = this.requestToUpdate.accessEntities;
    requestCard.accessGrp = this.requestToUpdate.accessGrp;
    requestCard.requestRestrictions = this.requestToUpdate.requestRestrictions;
    // requestCard.requestColumns = this.dataHabilitations
    // const requestCol = '{"columns":'+JSON.stringify(this.dataHabilitations)+'}';
    // console.log(requestCol);
    requestCard.requestColumns = '{"columns":' + JSON.stringify(this.dataHabilitations) + '}';
    return requestCard;
  }
  doCancel() {

    if (this.statutCall) {
      this._jobDataService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
}
