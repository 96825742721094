<mat-card id="bns-contacts" class="wkscard stdTheme" layout="column" flex 
        style="width: 100%; margin-top: 0px; margin-bottom: 0px;padding-left: 0px; padding-right: 0px;" >
    <mat-card-header>
        <div *ngIf="isLoaded" class="container-fluid" style="margin-top: 0px; margin-bottom: 0px;">
            <div class="row justify-content-around">
                <div class="col-10">      
                    {{entityParent.stdEntity}}
                </div>
                <div class="col-1 container-fluid d-flex justify-content-end" style="padding-left: 0px; padding-right: 0px;">
                    <button mat-icon-button (click)="addSubsidiary()">
                        <span class="fa fa-plus-square" 
                            style="margin-left: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 30px" >
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-header>
    
    <mat-card-content class="wkscard" >
        <div *ngIf="isLoaded"  [style.overflow]="'auto'">
           
            <div  *ngFor= "let subsidiaryCur of subsidiariesTemplate; let iRow=index; let c=count;let isLast=last" >
                <div class="container-fluid contactCur" style="margin: 0px; padding: 0px;"  >
                    <div id="subsidiaryCur{{iRow}}" #subsidiaryCur_{{iRow}} class="row rowLine" style="line-height: 30px; " >
                        <div class="col-1" style="margin-top: 10px;">
                            <button mat-icon-button (click)="removeSubsidiary(iRow) ">
                                <span class="fa fa-trash" style="font-size: 15px" ></span>
                            </button>      
                        </div>
                        <div class="col-10 labelLine" style="margin-top: 10px;">
                            <span style="margin: 0px;"><h6>{{ subsidiaryCur.stdEntity }} </h6> </span>               
                        </div>
                        <div class="col-1" style="margin-top: 10px;">
                            <button mat-icon-button
                                (click)="subsidiaryCur.isCollapsed = !subsidiaryCur.isCollapsed"
                                [attr.aria-expanded]="!subsidiaryCur.isCollapsed" aria-controls="collapseExample">
                                    <span *ngIf="subsidiaryCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                    <span *ngIf="!subsidiaryCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                            </button> 
                        </div>

                    </div>             
                    <div class="flex-row  row stdTheme" [ngbCollapse]="subsidiaryCur.isCollapsed" style="margin-right: 0px;margin-left: 0px;">
                        <div *ngIf="!subsidiaryCur.isCollapsed" style="margin-right: 0px;margin-left: 0px;width: 100%;" >
                            
                            <div class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;margin-bottom: 10px;" >
                                <div *ngIf="inputSubsidiary" class="container-fluid d-flex justify-content-between" >
                                    <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel(iRow)">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{ 'goback' | translate }}
                                    </button>
                                    <button  mat-raised-button  #buttonSubmit type="submit" (click)="doRegisterSubsidiary(iRow)" >
                                        <mat-icon>save</mat-icon>
                                        {{ 'save' | translate }}
                                    </button>
                                </div>
                            </div>
                            
                            <div class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                                <div class="col-12" style="margin-right: 0px; margin-left: 0px;padding-left: 0px; padding-right: 0px;">
                                    <mdi-address-block [config]="configSubsidiary[iRow]" [markChange]="markChange" [dataIn]="dataAddress[iRow]" 
                                                            (dataOut)="dataOutSubsidiary($event,iRow)"></mdi-address-block>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

</mat-card>