import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';

import { DataWorkModel, WksWorksModel } from '../../../../../models/wks-works.model';
@Component({
  selector: 'mdi-works-history-card',
  templateUrl: './works-history-card.component.html',
  styleUrls: ['./works-history-card.component.css']
})

export class WorksHistoryCardComponent implements OnInit {

  workCur: WksWorksModel;
  isModalMode: boolean;
  dataInWork: DataWorkModel;

  constructor(private _dialog: MatDialog,
    private dialogRef: MatDialogRef<WorksHistoryCardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

      this.workCur = data.workCur;
      this.dataInWork = {
        work: this.workCur,
        iRow: data.row,
      };
      if (data) {
        this.initData();
        this.isModalMode = true;
      }

    }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => { this.close(); });
  }
  initData(): void {

  }
  close() {
    this.dialogRef.close('okAction');
  }
}
