<form [formGroup]="equipmentForm" [dir]="wksEntity.directionInput" >
    <mat-card class="stdTheme">
     
      <mat-card-content>
        <fieldset class="informationsborder">

          <div >
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
              <div class="col-10">
                <mat-form-field appearance="standard"  style="width: 100%!important;">
                  <mat-label>{{ 'type' | translate }}</mat-label>
                  <mat-select id="language" placeholder="{{ 'Choose equipment' | translate }}" formControlName="type" 
                            #equipType required
                            (ngModelChange)="onChangeType(equipType.value)"
                            ngDefaultControl >
                            <mat-option class="selectOptionsTheme" *ngFor="let equipCur of equipmentParam" 
                                        [value]="equipCur.value"  >{{ equipCur.label | translate }}</mat-option>
                  </mat-select>         
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
              <div class="col-10">
                <mat-form-field appearance="standard" style="width: 100%!important;">
                  <mat-label>{{ 'brand' | translate }}</mat-label>
                  <mat-select id="brand" placeholder="{{ 'Choose brand' | translate }}" formControlName="brand" 
                            #brand required 
                            (focus)="onFocus('brand', brand.value)" 
                            (ngModelChange)="onChangeBrand($event)"
                            ngDefaultControl >
                            <mat-option class="selectOptionsTheme" *ngFor="let brandCur of equipmentBrand"  
                                        [value]="brandCur.label"  >{{ brandCur.label }}</mat-option>
                  </mat-select>         
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
              <div class="col-10">
                <mat-form-field appearance="standard" style="width: 100%!important;">
                  <mat-label>{{ 'model' | translate }}</mat-label>
                  <input formControlName="model" matInput type="text" required #model (change)="onChangeModel(model.value)">
                 
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isSerialNumberPresent" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
              <div class="col-10" style="margin-top: -20px">
                <mat-form-field appearance="standard" style="width: 100%!important;">
                  <mat-label>{{ 'serialNumber' | translate }}</mat-label>
                  <!--<input formControlName="serialNumber" matInput type="text">-->
                  
                  <input matInput class='serialNumber' type="text" id="serialNumber" 
                    placeholder="{{ serialNumberInput.holder }}"
                    [attr.maxlength]="serialNumberInput.dataLengthMax" 
                    matInput #serialNumber 
                    [ngClass]="{
                        'inputAlert': serialNumberInput.inputError,
                        'inputNormal': !serialNumberInput.inputError
                        }"
                    [value]="serialNumberInput.inputValue" 
                    (input)="formatField('serialNumber',serialNumberInput,serialNumber.value)"
                    formControlName="serialNumber"
                    mdiInputFmtField= "{{ formField }}"
                  >
                  
                </mat-form-field>
              </div>
            </div>
            
            <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px;height: 60px" 
                            [style.margin-top]="isSerialNumberPresent ? '-20px' : '0px'">
              <div class="col-5" style="padding-top: 15px;">
                <mat-label>{{ 'nbHoursOfUse' | translate }}</mat-label>
              </div>
              <div class="col-5" style="padding-left: 0px;margin-left: 0px;" >
                <mat-form-field appearance="standard" floatLabel="never" style="height: 60px; margin-top: -15px" >
                  <input matInput  formControlName="nbHoursOfUse" #nbHoursOfUse style="height: 30px;text-align: center" 
                       (input)="formatField('nbHoursOfUse','',nbHoursOfUse.value)"
                        />
                </mat-form-field>
              </div>
            </div>
  
            <div  class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px" >
              <div class="col-10" style="margin-top: 0px">
                <mat-form-field appearance="standard"  style="margin-top: -30px">
                  <mat-label>{{ 'askedService' | translate }}</mat-label>
                  <!--<input formControlName="askedService" matInput type="text" required>-->
                  <mat-select id="askedService" formControlName="askedService" 
                            #askedService required
                            (ngModelChange)="onChangeService($event)"
                            ngDefaultControl >
                            <mat-option class="selectOptionsTheme" *ngFor="let serviceCur of servicesList" 
                                        [value]="serviceCur.value"  >{{ serviceCur.label }}</mat-option>
                  </mat-select>         
                </mat-form-field>
              </div>
            </div>
            <!-- REGULAR_SERVICE -->
            <div *ngIf="displayCountHours" class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:10px; height: 60px" >
              <div class="col-3" style="margin-top: 0px;">
                <mat-label>{{ 'hours_service' | translate }}</mat-label>
              </div>
              <div class="col-5" style="margin-top: -30px;padding-left: 0px;margin-left: 0px;margin-left: -10px;">
                <mat-form-field appearance="standard" floatLabel="never">
                  <!-- <mat-label>{{ 'EQUIPMENT.NB_HOURS_OF_USE' | translate }}</mat-label>-->
                  <button 
                    mat-icon-button 
                    matPrefix 
                    aria-label="Remove" 
                    (click)="counter('askedServiceHours',-100, askedServiceHours.value)" >
                    <mat-icon style="margin-top: 10px;">remove</mat-icon>
                  </button>
                  <input matInput formControlName="askedServiceHours" #askedServiceHours readonly style="height: 30px;text-align: center" />
                  <div matSuffix style="position: relative">
                    <button 
                      mat-icon-button
                      aria-label="Add" 
                      (click)="counter('askedServiceHours',100,  askedServiceHours.value)" >
                      <mat-icon style="margin-top: 10px;">add</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </fieldset>
      </mat-card-content>
      <mat-card-actions class="actions">
          <!--
        <button mat-raised-button (click)="onPrevious()">
          <mat-icon>navigate_before</mat-icon>
          {{ 'COMMUN.BUTTON.BEFORE' | translate }}
        </button>
        <button [disabled]="equipmentForm.invalid" mat-raised-button (click)="onSubmit()">
          {{ 'COMMUN.BUTTON.NEXT' | translate }}
          <mat-icon>navigate_next</mat-icon>
        </button>
        -->
      </mat-card-actions>
    </mat-card>
  </form>
  