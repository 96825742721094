import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class EventBroadcastService {
    constructor() {
        this.onChangeStatut = new BehaviorSubject(undefined);
    }
    broadcastEvent(event) {
        this.onChangeStatut.next(event);
    }
}
EventBroadcastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventBroadcastService_Factory() { return new EventBroadcastService(); }, token: EventBroadcastService, providedIn: "root" });
