import { Component, OnInit } from '@angular/core';

import {IAfterGuiAttachedParams, ICellRendererParams} from "ag-grid-community";
import {ICellRendererAngularComp } from "ag-grid-angular";

import { JobDataService } from '../../../../../../job-data/services/job-data.service';

@Component({
  selector: 'mdi-alphanum-renderer',
  templateUrl: './alphanum-renderer.component.html',
  styleUrls: ['./alphanum-renderer.component.css']
})
export class AlphanumRendererComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  currentValue : any;
  
  constructor(_jobDataService: JobDataService) { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.currentValue = params.value;
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
}
