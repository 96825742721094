import { Component, OnInit, Output, Input, ViewChild, NgZone, ViewEncapsulation, 
          EventEmitter, SimpleChanges, HostListener, ElementRef, OnChanges } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import {take} from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RemoteFilesService } from '../../../../../common/services/remote-files.service';
import { ReadFile, ReportEvent, CommonReport, AlertType, LevelType } from '../../../../../../models/common.model';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksDocsModel, DocEvent } from '../../../../models/wks-equipment.model';

import { BrandWksModel } from '../../../../models/wks-common.model';

import { TypesLabel  } from '../../../../models/wks-common.model';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

@Component({
  selector: 'mdi-doc-card',
  templateUrl: './doc-card.component.html',
  styleUrls: ['./doc-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DocCardComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() docToUpdate: WksDocsModel;
  @Output() dataOut = new EventEmitter<any>();

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  @ViewChild('filtermodel', {static: false}) filtermodel: ElementRef;

  private readonly onDestroy = new Subject<void>();
  // upload files
  fileExt: String = 'JPG, GIF, PNG, PDF, TXT, CSV, XLSX';

  fileRead: ReadFile;
  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  @Input() maxSizePdf = 100; // 100MB
  progress: { percentage: number } = { percentage: 0 };
  listFilesJson: any;
  
  // public readMode = ReadMode.dataURL;
  public picked: ReadFile;
  public status: string;
  displayedColumns: any;
  public file: ReadFile;
  isUploadTodo = false;
  isUploadInProgress = false;
  // end upload file
  messageInfo = '';
  dataMessageInfo1 = '';
  dataMessageInfo2 = '';
  messageType = '';
  alertMessage = false;
  subscription: Subscription;

  isUpdateStatut: boolean;
  isFinishLoading: boolean;
  actionValue: string;
  isSearch: boolean;
  isReadOnly: boolean;
  isInput: boolean;
  isAddOne: boolean;
  // isRegistered: boolean;
  isModalBsn: boolean;
  isModalEquip: boolean;

  equipTypeCtrl: FormControl;
  docBrandCtrl: FormControl;
  docModelCtrl: FormControl;
  docVersionCtrl: FormControl;
  docYearCtrl: FormControl;
  docNameCtrl: FormControl;
  docTagsCtrl: FormControl;
  docFileNameCtrl: FormControl;
  docCommentCtrl: FormControl;
  docsForm: FormGroup;
  isOkToValid: boolean;
  titleCard: string;
  messageTranslate: string;

  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  modelCacheList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;
  listYears: any[];
  classificationList: string[];
  equipTypeList: TypesLabel[];
  docOld: string;

  @ViewChild('autosizeComment', {static: true})  autosizeComment: CdkTextareaAutosize;
  @ViewChild('dropArea', {static: false}) dropArea: any;

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private router: Router,
    private _remoteFilesService: RemoteFilesService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _dialog: MatDialog,
    private _ngZone: NgZone) { }

  ngOnInit() {
    this.isFinishLoading = false;

  }
  ngOnChanges(changes: SimpleChanges) {
    this.isFinishLoading = false;
    this.isAddOne = false;
    // this.isRegistered = false;
    this.isReadOnly = false;
    this.isSearch = false;
    this.isInput = false;
    this.isUpdateStatut = false;
    this.isModalBsn = false;
    this.isModalEquip = false;
    this.isOkToValid = false;
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionValue = changes['actionType'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'docToUpdate': {
            this.docToUpdate = changes['docToUpdate'].currentValue;
            break;
          }

        } // end switch
      } // end if
    } // end loop
    if (this.statutCall === 'modalBsn') {
      this.isModalBsn = true;
    }
    if (this.statutCall === 'modalEquip') {
      this.isModalEquip = true;
    }
    if ((this.actionValue === 'add') &&  (this.statutCall === 'direct')) {
      this.isAddOne = true;
    }
    if ( this._translate.currentLang === undefined) {
      return;
    }
    if (this.actionValue === 'display') {
      this.isReadOnly = true;
    }
    if ((this.actionValue === 'add') || (this.actionValue === 'update')) {
      this.isInput = true;
    }
    if ((this.actionValue === 'search') || (this.actionValue === 'newsearch')) {
      this.isSearch = true;
    }
    this.initData();
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosizeComment.resizeToFitContent(true));
  }
  initData() {
    if (this.actionType === 'add') {
      this.titleCard = 'new document';
    } if (this.actionType === 'update') {
      this.titleCard = 'update document';
      this.isUpdateStatut = true;
    } else if ((this.actionType === 'search') || (this.actionType === 'newsearch')) {
      this.titleCard = 'searching document';
    }
    const yearMax = (new Date()).getFullYear();
    const yearMin = yearMax - 50;
    this.listYears = this.initYearsList(50);

    this.equipTypeCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docBrandCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docModelCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docYearCtrl = this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(yearMax), Validators.min(yearMin)]);
    this.docNameCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docTagsCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docFileNameCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docCommentCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docVersionCtrl = this.fb.control({value: '', disabled: this.isReadOnly});

    this.docsForm = this.fb.group({
      docName: this.docNameCtrl,
      docVersion: this.docVersionCtrl,
      equipType: this.equipTypeCtrl,
      docBrand: this.docBrandCtrl,
      docModel: this.docModelCtrl,
      docYear: this.docYearCtrl,
      docFileName: this.docFileNameCtrl,
      docTags: this.docTagsCtrl,
      docComment: this.docCommentCtrl,
    },
    {
      updateOn: 'blur'
    });
    this.classificationList = this._wksCentralService.getDocClassification();
    this.loadTypeComponents();
    this.loadBrands()
    .then (brandList => {
      if ((this.docToUpdate !== undefined) && (this.docToUpdate.docBrand) && (this.docToUpdate.docModel)) {
        let brandSelected: BrandWksModel;
        for (const curBrand of this.brandCacheList) {
          if (curBrand.brandName === this.docToUpdate.docBrand) {
            brandSelected = curBrand;
            break;
          }
        }
        this.loadModels(brandSelected)
        .then (modelList => { 
          if (this.actionValue === 'update') {
            this.isUpdateStatut = true;
            this.setValuesToUpdate();
          }
          if (this.actionValue === 'newsearch') {
            this.setValuesToUpdate();
          }
        })
        .catch(error  => {
          console.log(error);
          this.isFinishLoading = true;
        });
      } else {
        if (this.actionValue === 'update') {
          this.isUpdateStatut = true;
          this.setValuesToUpdate();
        }
        if (this.actionValue === 'newsearch') {
          this.setValuesToUpdate();
        }
      }
      this.isFinishLoading = true;
    })
    .catch(error  => {
      console.log(error);
      this.isFinishLoading = true;
    });
    // this.loadBrands();

  }
  loadTypeComponents() {
    const listType = this._wksCentralService.getComponentsList();
    this.equipTypeList = [];
    for (const typeCur of listType) {
      this.equipTypeList.push({
        labelType: this._translate.getTranslate(typeCur),
        equipType: typeCur,
        equipAttachedBoat: true
      });
    }
    const listType1 = this._wksCentralService.getEquipmentsList();
    for (const typeCur of listType1) {
      this.equipTypeList.push({
        labelType: this._translate.getTranslate(typeCur.equipName),
        equipType: typeCur.equipType,
        equipAttachedBoat: false
      });
    }
    this.equipTypeList.sort((obj1, obj2) => {
      return obj1.labelType > obj2.labelType ? 1 : -1;
    });

  }
  initYearsList(minYear: number): string[] {
    const currentYear = new Date().getUTCFullYear();
   
    const years = [];
    let startYear = currentYear - minYear;  
    while ( startYear <= currentYear ) {
        years.push((startYear++).toString());
    }
    years.sort(function (a, b) {
      if (a > b) {
          return -1;
      }
      if (b > a) {
          return 1;
      }
      return 0;
    });   
    years.splice(0, 0, '');
    return years;
  }
  initBrandList() {

    this.loadBrands()
    .then ((brandList: BrandWksModel[]) => {

    })
    .catch((error) => {
      console.log(error);
 
    });
  }
  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    this.modelCacheList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    this.brandSelected = brandSelected;
    this.initModelList(brandSelected);
   
  }
  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '' ) { 
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];
    this.modelCacheList = [];
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this.saveBrandModel(newBrand);
  }
  initModelList(_brandSelected: BrandWksModel) {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.modelCacheList = [];
    this.modelFilteredList = [];
    this.loadModels(_brandSelected)
    .then ((modelList: BrandWksModel[]) => {

    })
    .catch((error) => {
 
    });
  }
  loadModels(_brandSelected: BrandWksModel) {
    let modelList: BrandWksModel[];
    const equipTypeCur = (this.docsForm.controls.equipType.value ? this.docsForm.controls.equipType.value : 
                        ((this.docToUpdate !== undefined &&  this.docToUpdate.equipType) ? this.docToUpdate.equipType : undefined));
    if (equipTypeCur === undefined) {
      return;
    }
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksModelsList(this._userService.getUserLogged().entity, _brandSelected.brandName, equipTypeCur)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          modelList = data.body;
          for (const brandCur of modelList) {
            this.modelCacheList.push(brandCur);
          }
          this.modelCacheList.sort((obj1, obj2) => {
            return obj1.modelName > obj2.modelName ? 1 : -1;
          });
          this.modelCacheList.splice(0, 0,  {
              stdEntity: this._userService.getUserLogged().entity, 
              brandRow: 'model', 
              brandName: '',
              equipType: '',
              modelName: ''} );
          this.modelFilteredList = [...this.modelCacheList];
          resolve(modelList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.modelCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'model',
              brandName: _brandSelected.brandName,
              equipType: equipTypeCur,
              modelName: '',
            });
            this.modelFilteredList = [...this.modelCacheList];
          }
           reject(err);
        }
      );
    });

  }
  onKeyModel($event: any) {
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.modelFilteredList = this.searchModel(valueFinal);
    this.filtermodel.nativeElement.value = valueFinal;
  }
  searchModel(value: string) { 
    const filter = value.toLowerCase();
    return this.modelCacheList.filter(modelCur => modelCur.modelName.toLowerCase().includes(filter));
  }
  addModel(newModelName: string) {
    // console.log(newModel);
    const newModel = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'model',
      brandName: this.brandSelected.brandName,
      equipType: this.docsForm.controls.equipType.value,
      modelName: newModelName,
    };
    this.modelCacheList.push(newModel);
    this.modelCacheList.sort((obj1, obj2) => {
      return obj1.modelName > obj2.modelName ? 1 : -1;
    });
    if (this.modelCacheList[0].modelName === '') {
      this.modelCacheList.splice(0, 1);
    }
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newModel, 'create');
    this.saveBrandModel(newModel);
  }
  saveBrandModel(newBrandModel: BrandWksModel) {
    this._wksCentralService.saveWksBrand( newBrandModel, 'create')
    .subscribe(
      () => {
        console.log('saveBrandModel OK');
      },
      () => {
        console.log('saveBrandModel KO');
      }
    );
  }
  searching() {
    const currentDoc = this.prepareValidation();
    this._wksCentralService.searchWksDoc(currentDoc)
    .subscribe(
      data => {
        const docEvent: DocEvent = {
          searchCriteria: currentDoc,
          searchResult: data.body,
        };
        this._wksCentralService.onSearchDoc.emit(docEvent);
      },
      () => {
        this.errorMessage('searching', null);
      }
    );
  }
  controlValidForm(): void  {
    this.isOkToValid = false;
    if (this.docsForm.controls['docName'].value === '') {
      this.docsForm.controls['docName'].setErrors({'incorrect': true});
    }
    if (!this.isModalBsn && this.docsForm.controls['equipType'].value === '') {
      this.docsForm.controls['equipType'].setErrors({'incorrect': true});
    }
    if (!this.isModalBsn && this.docsForm.controls['docTags'].value === '') {
      this.docsForm.controls['docTags'].setErrors({'incorrect': true});
    }
    if (this.docsForm.controls['docFileName'].value === '') {
      this.docsForm.controls['docFileName'].setErrors({'incorrect': true});
    }
    this.isOkToValid = (this.docsForm.status === 'VALID' ? true : false);
  }
// ============================================================== start files
  onFileChange(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;
    
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if ((files.length > 0) && (this.isValidFiles(files))) {
        const file: File = files[0];
        const readingFile: ReadFile = new ReadFile();
        readingFile.nameFile = file.name;
        readingFile.sizeFile = file.size;
        readingFile.typeFile = file.type;
        readingFile.fileObject = file;
        readingFile.messageFile = '';
        readingFile.uploadedFile = false;
        readingFile.validatedFile = false;
        this.isUploadTodo = true;
        this.fileRead = readingFile;
        this.docsForm.controls.docFileName.setValue(readingFile.nameFile);
      }
    }
    this.controlValidForm();
  }
  private isValidFiles(files): boolean {
    // Check Number of files
    if (files.length > this.maxFiles) {
      this.alertMessage = true;
      this.messageType = 'ERROR';
      this.messageInfo = 'The maximum number of files is reached';
      this.dataMessageInfo1 = files.length.toString() ;
      this.dataMessageInfo2 = this.maxFiles.toString() ;
    return false;
    } else {
      return this.isValidFileExtension(files);
    }

  }
  private isValidFileExtension(files: any): boolean {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
                    .map(function (x) { return x.toLocaleUpperCase().trim(); } );
    for (let i = 0; i < files.length; i++) {
        // Get file extension
        const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
        // Check the extension exists
        const exists = extensions.includes(ext);
        if (!exists) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'unrecognized extension';
            this.dataMessageInfo1 = ext ;
            this.dataMessageInfo2 = files[i].name ;
            this.errorMessage('extensionfile', undefined);
            break;
        }
        if (!this.alertMessage) {
        // Check file size
          return this.isValidFileSize(files[i], ext);
        } else {
          return false;
        }
    }
  }

  private isValidFileSize(_file: any, ext: string): boolean {
    const fileSizeinMB = _file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    const maxSize = (ext === 'PDF') ? this.maxSizePdf : this.maxSize;
    if (((ext === 'PDF') && (size > this.maxSizePdf)) || 
        ((ext !== 'PDF') &&  (size > this.maxSize))) {
        this.alertMessage = true;
        this.messageType = 'ERROR';
        this.messageInfo = 'too heavy file size';
        this.dataMessageInfo1 = _file.name  ;
        this.dataMessageInfo2 = size.toString() + ' MB / ' + maxSize.toString() + 'MB MAX' ;
        this.errorMessage('heavyfile', undefined);
        return false;
      } else { return true; }
  }
// drag and drop 
  @HostListener('dragover', ['$event']) public onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'red';
    this.dropArea.nativeElement.style.border = 'dashed 3px red';
    this.alertMessage = false;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey'; 
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
    const files = evt.dataTransfer.files;

    if ((files.length > 0) && (this.isValidFiles(files))) {
      const file: File = files[0];
      const readingFile: ReadFile = new ReadFile();
      readingFile.nameFile = file.name;
      readingFile.sizeFile = file.size;
      readingFile.typeFile = file.type;
      readingFile.fileObject = file;
      readingFile.messageFile = '';
      readingFile.uploadedFile = false;
      readingFile.validatedFile = false;
      this.isUploadTodo = true;
      this.fileRead = readingFile;
      this.docsForm.controls.docFileName.setValue(readingFile.nameFile);
    }
  }
// ============================================================== end files
  updateDoc() {
    const currentDoc = this.prepareValidation();
    this._wksCentralService.saveWksDoc(currentDoc, this.actionType)
        .subscribe(
          wksEquipReturn => {
            this.docToUpdate = wksEquipReturn.body;
            // this.router.navigate(['/jobHome']);
            if  (this.statutCall === 'sidenav') {
              this._wksCentralService.onRegisterValid.emit('closeRegister');
              return;
            } else {
              this.router.navigate(['/jobHome']);
            }
          },
          () => {
            this.errorMessage('register', null);
          }
        );
  }
  registerDoc() {
 
    if (this.fileRead === undefined) {
      this.updateDoc();
      return;
    }
    const currentDoc = this.prepareValidation();
    const suffix =  this.fileRead.nameFile.split('.').pop();
    let commonReport =  new CommonReport();
    commonReport.isStartReport = true;
    this._globalEventsManagerService.commonReportSubject.next(commonReport);

    this.uploadFile(this.fileRead.fileObject, currentDoc).then(
      (val: any) => {
        commonReport =  new CommonReport();
        commonReport.level = LevelType.child;
        commonReport.type = AlertType.Success;
        commonReport.pathParent = this.fileRead.nameFile;
        commonReport.message = this._translate.instant('UploadFileOK', [commonReport.pathParent]);
        this._globalEventsManagerService.commonReportSubject.next(commonReport);

        this.endProcessFile();
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('closeRegister');
          return;
        } if  ((this.statutCall === 'modalBsn')  || (this.statutCall === 'modalEquip') ) {
          this._globalEventsManagerService.synchroEvents.next('closeDocModal');
          return;
        }  else {
          this.router.navigate(['/jobHome']);
        }
      },
      (error) => {
        console.log('UPLOAD KO ' + this.fileRead.fileObject.name + ' : ' + error);
        commonReport =  new CommonReport();
        commonReport.level = LevelType.child;
        commonReport.type = AlertType.Error;
        commonReport.pathParent = this.fileRead.nameFile;
        commonReport.message = this._translate.instant('UploadFileKO', [commonReport.pathParent, error]);
        this._globalEventsManagerService.commonReportSubject.next(commonReport);
        this.errorMessage('upload', this._translate.instant('UploadFileKO', [commonReport.pathParent, error]));
        this.endProcessFile();
      }
    ); // end then uploadFile
    
  }
  private uploadFile(_file: File, currentDoc: WksDocsModel) {

    // console.log('fichier transféré ' + _file.name);
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFileToStorageWksdav(_file, currentDoc, this.docOld, this.actionType).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
                    this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translate.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this._translate.getTranslate('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
  
            default:
              message = this._translate.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }

          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  private endProcessFile() {
    const commonReport =  new CommonReport();
    commonReport.isEndReport = true;
    this._globalEventsManagerService.commonReportSubject.next(commonReport);
  }
  removeDoc() {
    this._wksCentralService.saveWksDoc(this.docToUpdate, 'delete')
    .subscribe(
      () => {

        // this.router.navigate(['/jobHome']);
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('deleteDoc');
          return;
        }
        if  ((this.statutCall === 'modalBsn') || (this.statutCall === 'modalEquip')) {
          this._wksCentralService.onRegisterValid.emit('deleteDoc');
          return;
        }
      },
      () => {
        this.errorMessage('remove', null);
      }
    );
   
  }
  prepareValidation(): WksDocsModel {

    const localFmt = this._translate.getLocalFmt('timeFormat');
    let fileProperties: any;
    if ((!this.isSearch) && (this.fileRead !== undefined)) {
      fileProperties = {
        lastModified: this.fileRead.fileObject.lastModified,
        lastModifiedDate: CommonMethods.dateToString(this.fileRead.fileObject.lastModified, 'date', localFmt),
        name: this.fileRead.fileObject.name,
        size: this.fileRead.fileObject.size,
        type: this.fileRead.fileObject.type,
      };
    } else {
      fileProperties = '';
    }

    const wksDocCard: WksDocsModel = {
      id: (this.docToUpdate === undefined ? undefined : 
        this.docToUpdate.id ? this.docToUpdate.id : undefined),
      stdEntity: this._userService.getUserLogged().entity,
      ownerId: ((this.statutCall === 'modalBsn') ? this.docToUpdate.ownerId : '-1'),
      equipType: ((this.statutCall === 'modalEquip') ? this.docToUpdate.equipType : this.docsForm.controls.equipType.value),
      docBrand: ((this.statutCall === 'modalEquip') ? this.docToUpdate.docBrand : this.docsForm.controls.docBrand.value),
      docModel: ((this.statutCall === 'modalEquip') ? this.docToUpdate.docModel : this.docsForm.controls.docModel.value),
      docYear: this.docsForm.controls.docYear.value,
      docVersion: this.docsForm.controls.docVersion.value,
      docLang: '',
      docName: this.docsForm.controls.docName.value,
      docStorage: '',
      docPath: '',
      docFilename: this.docsForm.controls.docFileName.value,
      docTags: (((this.statutCall === 'modalBsn') || (this.statutCall === 'modalEquip')) ? this.docToUpdate.docTags : this.docsForm.controls.docTags.value),
      docComment: this.docsForm.controls.docComment.value,
      stdCreation: (this.docToUpdate === undefined ? undefined :   
                  this.docToUpdate.stdCreation ? this.docToUpdate.stdCreation : undefined),
      docJson: this.fileRead !== undefined ? '{ \"properties\": ' + JSON.stringify(fileProperties) + '}' : 
                      (((this.docToUpdate !== undefined ) && (this.docToUpdate.docJson !== undefined )) ? this.docToUpdate.docJson : undefined),
     };
 
    return wksDocCard;
  }

  setValuesToUpdate() {

    if (this.docToUpdate === undefined) {
      this.docOld = '';
      return;
    }

    this.docsForm.controls.equipType.setValue(this.docToUpdate.equipType),
    this.docsForm.controls.docBrand.setValue(this.docToUpdate.docBrand);
    this.docsForm.controls.docModel.setValue(this.docToUpdate.docModel);
    this.docsForm.controls.docYear.setValue(this.docToUpdate.docYear);
    this.docsForm.controls.docVersion.setValue(this.docToUpdate.docVersion);
    this.docsForm.controls.docName.setValue(this.docToUpdate.docName);
    this.docsForm.controls.docFileName.setValue(this.docToUpdate.docFilename);
    this.docsForm.controls.docTags.setValue(this.docToUpdate.docTags);
    this.docsForm.controls.docComment.setValue(this.docToUpdate.docComment);
    this.docOld = JSON.stringify(this.docToUpdate);
 
  }
  errorMessage(_actionCur: string, _sMessage: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;

    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording doc';
    } if (_actionCur === 'upload') {
      titleBox = 'upload doc';
    } else {
      titleBox = 'searching documents';
      messageBox = 'no doc found';
    }
    if (_actionCur === 'lenghtfile') {
      titleBox = 'too heavy file'; 
      const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
      _sMessage = this._translate.instant(this.messageInfo, argsMessage );
    }
    if (_actionCur === 'extensionfile') {
      titleBox = 'extension error';
      const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
      _sMessage = this._translate.instant(this.messageInfo, argsMessage );
    }
    if (_actionCur === 'heavyfile') {
      titleBox = 'too heavy file'; 
      const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
      _sMessage = this._translate.instant(this.messageInfo, argsMessage );
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (_sMessage === null ) {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: this._translate.getTranslate(messageBox),
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    } else {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: _sMessage,
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    }
 //   this._dialog.open(ModalCommonComponent, dialogConfig);
    this._globalEventsManagerService.synchroEvents
      .pipe(takeUntil(this.onDestroy))
      .subscribe( event => {
        if (event === 'cancelModal') {
          console.log('fermeture');
        } else if (event) {
          const typeEvent = typeof event;
          // const eventString = JSON.stringify(event).toString();
          const eventString = String(event);
          console.log(typeEvent);
        }
        // this.router.navigate(['/jobHome']);
      });
    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  // isBoatEquip
  doClose() {
    if  ((this.statutCall === 'modalBsn')  || (this.statutCall === 'modalEquip') ) {
      this._globalEventsManagerService.synchroEvents.next('closeDocModal');
      return;
    }
    if (this.docToUpdate) {
      this.dataOut.emit('collapseNode;' + this.docToUpdate.id);
    } else {
      this.dataOut.emit('collapseNode' );
    }
  }
  doCancel() {

    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeRegister');
      return;
    }
    if  ((this.statutCall === 'modalBsn')  || (this.statutCall === 'modalEquip') ) {
      this._globalEventsManagerService.synchroEvents.next('closeDocModal');
      return;
    }

    if ((this.actionValue === 'add') || (this.actionValue === 'search')  || (this.actionValue === 'newsearch')) {
      this.router.navigate(['/jobHome']);
      return;
    }
    if (this.statutCall) {
      this._wksCentralService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
  
  /** Gestion affichage modal */
/** https://blog.angular-university.io/angular-material-dialog/  */
openDialog() {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: this._translate.getTranslate('Upload document'),
    eventOkToLaunch: 'okToLaunchUpload',
    typeDialog: 'upload',
    };
//   this._dialog.open(ModalCommonComponent, dialogConfig);

  const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(
    data => {
      console.log('Dialog output:', data);
      this.isUploadTodo = false;
      this.alertMessage = false;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    });

  }
}
