/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./job/common/directives/no-right-click.directive";
import * as i3 from "./auth/menu/menu.component.ngfactory";
import * as i4 from "./auth/menu/menu.component";
import * as i5 from "./services/user.service";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["mdiNoRightClick", ""], ["style", "min-height: 100%!important;"]], null, [[null, "contextmenuDebug"]], function (_v, en, $event) { var ad = true; if (("contextmenuDebug" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onRightClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NoRightClickDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mdi-menu", [], null, null, null, i3.View_MenuComponent_0, i3.RenderType_MenuComponent)), i1.ɵdid(3, 245760, null, 0, i4.MenuComponent, [i5.UserService, i6.Router], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("mdi-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
