export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    // supported lang
    'English': 'English',
    'French': 'French',
    'Please choose an option': '--Please choose an option--',
    'delete': 'Delete',
    'view': 'View',
    'approve': 'Approve',
    'reject': 'Reject',
     // calendar
     'Calendar.today': 'Today',
     'Calendar.next': 'Next',
     'Calendar.previous': 'Previous',
     'Calendar.week': 'Week',
     'Calendar.in': 'in',
     'Calendar.month': 'Month',
     'Calendar.day': 'day',
    'mrs': 'Mrs',
    'miss': 'Miss',
    'mr': 'Mr',
    // Home component
    'yes': 'Yes',
    'no': 'No',
    'an interface for data': 'an interface for data',
    'Home': 'Home',
    'Register': 'Register',
    'goback': 'Back',
    'save': 'Save',
    'detail': 'Detail',
    'Connexion': 'Connexion',
    'Help': 'Help',
    'Settings': 'Settings',
    // app component
    'hello world': 'hello world',
    'hello greet': 'Hello, %0 %1!', // two placeholder
    'well done': 'Well done %0', // one placeholder
    'good bye': 'bye bye', // only define in English
    'Double click to edit': 'Double click to edit',
    'responsesCount': 'Number of responses',
    'rowsCount': 'Number of rows',
    // login component
    'Sign in': 'Sign in',
    'Sign out': 'Sign out',
    'Dark Theme': 'Dark Theme',
    'Ligth Theme': 'Ligth Theme',
    'ID': 'ID',
    'ID field': 'Input ID',
    'Password': 'Password',
    'Login': 'Login',
    'language': 'Language',
    'lang': 'Lang',
    'Country': 'Country',
    'Choose country': 'Choose a country',
    'Nope, try again.': 'Nope, try again.',
    'Try again with another login': 'Try again with another login',
    'Login is required': 'Login is required',
    'Your login should be at 3 characters': 'Your login should be at 3 characters',
    'Confirm password': 'Confirm password',
    'Your password does not match': 'Your password does not match',
    'edit user': 'edit user %0',
    'delete user': 'delete user %0',
    'change password': 'request to change the password. User : %0',
    'confirm change password': 'Password of the user <b>%0<b> has  been changed. An email was sent to : <b>%1</b>',
    'Birth day': 'Birth day',
    'Birth day is Required': 'Birth day is Required',
    'This is not a valid year': 'This is not a valid year (min 1920)',
    'Creation': 'Create',
    'Update': 'Update',
    'submit': 'Submit',
    'cancel': 'Cancel',
    'dataAccess': 'Habilitations',
    'edit tooltip': 'Edit record',
    'add tooltip': 'Add record',
    'quotation tooltip': 'quote',
    'statut tooltip': 'Change statut',
    'file-text-o tooltip': 'new file',
    'try tooltip': 'Try record',
    'dataAccess tooltip': 'Data access',
    'labelData tooltip': 'Labeling of data',
    'labelData': 'Labeling of data',
    'data display tooltip': 'Choice data to display',
    'dataDisplay': 'Choice data to display',
    'data attributes tooltip': 'columns attributes',
    'dataAttributes': 'columns attributes',

    'trash tooltip': 'Delete record',
    'file tooltip': 'Download fileTélécharger le fichier',
    'Test connect': 'Test connection',
    'test connection OK': '%0 : Connection OK !',
    'test connection KO': '%0 : Connection KO !',
    'userNameFound': 'This ID is already used',
    'Input error': 'The information entered is not correct',
    'Invalid birthday': 'this birthday is invalid',
    'Confirming password is required': 'Confirming password is required',
    'tooltipBirthDay': 'The birthday format is : %0',
    'FirstName': 'Firstname',
    'FirstName field': 'Input Firstname',
    'Firstname is required': 'Firstname is required',
    'Your Firstname should be at 3 characters': 'Your Firstname should be at 3 characters',
    'LastName': 'Lastname',
    'LastName field': 'Input Lastname',
    'Lastname is required': 'Lastname is required',
    'Your Lastname should be at 3 characters': 'Your Lastname should be at 3 characters',
    'Password field': 'Input password',
    'Password is required': 'Password is required',
    'Your Password should be more than 3  characters': 'Your Password should be more than 3 characters',
    'Sexe': 'Gender',
    'Choose one gender': 'Choose one gender',
    'M': 'M',
    'F': 'F',
    'T': 'T',
    'Sexe is required': 'Sexe is required',
    'Email': 'Email',
    'Email field': 'Input valid email',
    'Email is required': 'Email is required',
    'This is not a valid email': 'This is not a valid email',
    'Email is already used': 'Email is already used',
    'USER': 'User',
    'ADMIN': 'Administrator',
    'MANAGER': 'Manager',
    'REGISTER': 'Profils',
    'EXPERT': 'Expert',
    'ENTITY': 'Entity manager',
    'DESIGNER': 'Designer',
    'SUPERVISOR': 'Supervisor',
    'DATA-MANAGER': 'Data Manager',

    'UsersAccount': 'Users accounts',
    'Profiles': 'Profils',
    'Entities': 'Entities',
    'EntitiesMenu': 'Entities menus',
    'EntityMenu': 'Menu : %0',
    'EntityMenuAddRoot': 'Adding root level',
    'EntityMenuAddNode': 'Adding an item in %0',
    'EntityMenuRemoveNode': 'Remove item %0',
    'EntityMenuRenameNode': 'Rename item %0',
    'UsersGroups': 'Users groups',
    'UpdatePwd': 'Update password',
    'data-management': 'Data Management tools',
    'designer': 'reports',
    'datasources': 'Data sources',
    'SQL requests': 'SQL requests',
    'access data (RGPD)': 'Access data (GRPD)',
    'label data': 'Labeling of data',
    'reports design': 'designing reports',
    'reports validation': 'Production validation',
    'working validation': 'Reports validation',
    'reports validated': 'Validated reports',
    'changeEntity': 'Change entity',
    'global filter': 'Global filter ...',
    'clear filter': 'Clear filters',
    'filter by column': 'Filter by column',
    'filter editor': 'Filter editor',
    'uploadFiles': 'Upload',
    'verifyFiles': 'File control',
    'displayImages': 'Display images',
    'displayList': 'Display list files',
    'displayPdf': 'Display pdf',
    'Select': 'Select files',
    'Drop zone': 'Drop zone',
    'nameFile': 'File name',
    'sizeFile': 'File size',
    'ProgressFile': 'Progress',
    'uploadedFile': 'Statut',
    'legendFile': 'Legend file',
    'typeFile': 'File type',
    'ActionsFile': 'Actions',
    'UploadQueue': 'Upload queue',
    'QueueLength': 'Queue length',
    'QueueProgress': 'Queue progress',
    'UploadFile': 'Upload file',
    'CancelFile': 'Cancel file',
    'RemoveFile': 'Remove file',
    'UploadAll': 'Upload All',
    'CancelAll': 'Cancel All',
    'RemoveAll': 'Remove All',
    'Drop your file here!': 'Drop your file here!',
    'SelectFiles': 'Sélect files',
    'SelectFile': 'Sélect file',
    'waiting': 'Waiting',
    'uploaded': 'Uploaded',
    'Duplicate file': 'Duplicate file',
    'Upload error file': 'Upload error file',
    // modal
    'I never close': 'I never close',
    'I will always close': 'I will always close',
    // end modal
    'mat-paginator-intl.first_page': 'first page',
    'mat-paginator-intl.items_per_page': 'items per page',
    'mat-paginator-intl.last_page': 'last page',
    'mat-paginator-intl.next_page': 'next page',
    'mat-paginator-intl.previous_page': 'previous page',
    'mat-paginator-intl.of': 'of',
    'Delete / Edit': 'Delete / Edit',
    'empty': 'is empty',
    'contain': 'contain',
    'start': 'start by',
    'end': 'end by',
    'less than': 'less than',
    'greater than': 'greater than',
    'equal': 'is equal',
    'columns selection': 'Columns selection',
    'Close': 'Close',
    'Delete': 'Remove',
    'Apply': 'Apply',
    'Confirmation': 'Confirmation',
    'Choose Columns': 'Choose Columns',
    'updatePwdModal': 'Update password',
    'modifPwd': 'Modify password',
    // messages bootsrap
    'TEST MESSAGE INFO': 'THIS IS A MESSAGE INFO TEST => %0  %1',
    'The maximum number of files is reached': 'The maximum number of files is reached : %0 for %1',
    'extension error': 'Unrecognized extension',
    'unrecognized extension': 'File :  %1 - Extension %0 unrecognized',
    'This file already exist': 'The file %0 is already in waiting list',
    'ReportAnalyzeCSV': 'Report Analyze CSV',
    'missingColumn': 'Column %0 missing',
    'unknownColumn': 'Column %0 unknow',
    'AnalyzeFile': 'Analyze File %0',
    'headersName': 'Headers names OK',
    'headersNameError': 'File : %0 => Error in colomns names => %1 ',
    'allColumnsPresents': 'File : %0 => The name of the specified columns is OK ',
    'noLinesDataPresents': 'File : %0 => No data line',
    'dataLinesAnalyzed': 'File : %0 => Dat lines OK',
    'too many files': 'Too many files',
    'too heavy file': 'Wrong file size',
    'too heavy file size': 'File : %0 => too heavy => %1',
    'dataLinesAnalyzedKO': 'File : %0 => Error in data lines => %1',
    'columnActionMissing': 'File : %0 => Action column missing',
    'actionLabelKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unauthorized action - expected : %4',
    'dataKOMandatory': 'File : %0 (col %1) (line %2) Mandatory value for action ( %3 )',
    'dataKOForbidden': 'File : %0 (col %1) (line %2)  Prohibited value for action ( %3 )',
    'decimalFormatKO': 'File : %0 (col %1) (lgn %2) Prohibited value ( %3 )',
    'dataKOAcceptedValues': 'File : %0 (col %1) (line %2) Valeur ( %3 ) not accepted for action ( %4 )- expected : %5',
    'columnsMissing': 'File : %0 Mandatory column(s) missing(s) %1 for action ( %2 )',
    'SupportedLangKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unsupported language  - expected : %4',
    'SupportedRoleKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unsupported role  - expected : %4',
    'ControlRoleKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) role outside habilitation',
    'EmailFormatKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) non-compliant email format',
    'userEntityNameKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unrecognized entity ( %4 )',
    'habilEntityNameKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unauthorized entity ( %4 )',
    'userGrpNameKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unrecognized group ( %4 )',
    'habilGrpNameKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unauthorized group ( %4 )',
    'userGrploadKO': 'File : %0 (col %1) (line %2) Valeur ( %3 ) unloaded group for entity ( %4 )',
    'verifyFileOK': 'Fichier : %0  => Checking content OK',
    'verifyFileKO': 'Fichier : %0  => Checking content KO',
    'StartUploadFile': 'File : %0  => Start upload',
    'UploadFileOK': 'File : %0  => Upload OK',
    'UploadFileKO': 'File : %0 => Upload failure  : %1',
    'FileNameProcess': 'File Name',
    'MessageProcess': 'Message',
    'NbErrors': 'Error(s)',
    'BSN': 'Business',
    'HEALTH': 'Health',
    'PERSO': 'Personnal',
    'PROD': 'Production',
    'TECH': 'Technical',
    'OTHER': 'Other',
    'FIN': 'Financial',
    'COMMON': 'Commons',
    'IDENTITY': 'Identity',
    'RESTRAINT': 'Restraints',
    'PRIVATE': 'Privates',
    'ALLData': 'All Data',
    // data table des mjl_users
    'username': 'User ID',
    'firstName': ' Firstname',
    'lastName': 'Lastname',
    'sexe': 'Gender',
    'birthday': 'Birthday',
    'email': 'Email',
    'organization': 'Organization',
    'profile': 'Profile',
    'dataFilter': 'Data filter',
    'roles': 'Roles',
    'Role is required': 'At least one role is required',
    'Role': 'Role (s)',
    'Validation error': 'Validation error',
    'enabledAccount': 'Enabled account',
    'Choice a role': 'Choice a role',
    'Choose lang': 'Choose user language',
    'accountNonExpired': 'Account non expired',
    'accountNonLocked': 'Account non locked',
    'credentialsNonExpired': 'Credentials non expired',
    'enabled': 'Enabled',
    'userdebug': 'Under debug',
    'userLang': 'User Language',
    'extendedEntities': 'Access entities',
    'extendedGrp': 'Access groups',
    'authorities': 'Authorities',
    // scheduler
    'scheduler': 'Scheduler',
    'jobDetail': 'Detail',
    'jobJournal': 'Journal',
    'jobName': 'Job name',
    'jobGroup': 'Group',
    'jobStatus': 'Status',
    'jobClass': 'Class',
    'jobArgs': 'Arguments',
    'cronExpression': 'Cron expression',
    'jobDesc': 'Description',
    'deleteJob': 'Delete',
    'cancelJob': 'Cancel',
    'runJob': 'Test',
    'pauseJob': 'Pause',
    'resumeJob': 'Resume',
    'dateJob': 'Date',
    'jobState': 'State',
    'STARTED': 'Started',
    'ENDED': 'Done',
    'lastRun': 'Last run',
    'choose class': 'Choose class',
    'jobArgName': 'Arg name',
    'jobArgValue': 'Arg value',
    'jobCreate': 'Creating process',
    'remove job': 'Remove process',
    'stop journal': 'Stop journal',
    'activate journal': 'Resume journal',
    // entities card component errors messages
    'Entity is required': 'Entity is required',
    'Your entity should be at 2 characters': 'Your entity should be more than 2 characters',
    'entityFound': 'Entity already exists',
    'Entity label is required': 'Entity label is required',
    'Your entity label should be at 3 characters': 'Your entity label should be at 3 characters',
    'Supervisor email is required': 'Supervisor email is required',
    'Group is required': 'Group is required',
    'authorizedFunctions': 'Authorized functions',
    'authorizedTags': 'Authorized tags ',
    'prohibitedTags': 'Prohibited tags',
    // datasource card errors
    'Datasource label is required': 'Datasource label is required',
    'Datasource engine is required': 'Datasource engine is required',
    'Datasource url is required': 'Datasource url or IP is required',
    'Datasource port is required': 'Datasource port is required',
    'datasourceFound': 'This datasource name already exists',
    'Test connection error': 'Test connection error',
    'Test connection OK': 'Test connection error',
    'test request OK': '%0 : Request execution OK !',
    'test request KO': '%0 : Request execution failure !',
    'Test request': 'Test request',
    'testappli': 'Application test',
    'reloadConfig': 'Reload configuration',
    'reloadWebdav': 'Reload webdav',
    'ReloadWebdavInprogress': 'Reload webdav in progress ... ',
    'ReloadWebdavDone': 'Reload webdav done.',
    'ReloadWebdavError': 'Reload webdav failed !!',
    'testJms': 'Jms test',
    'test submitted': 'Test submitted',
    // request card 
    'Test request error': 'Test request error', 
    'Test request OK': 'Test request OK', 
    'Connect Error': 'Connection Error',
    'Statement Error': 'Connection Error : no request',
    'Execution Error': 'Execution request failed',
    'Request Error': 'undefined error : request failed',
    'Request not valid': 'Request not valid',
    'Forbiddens words': 'Request not valid',
    'dynamic restrictions': 'Dynamic filters',
    'Datasource is required': 'Datasource is required',
    'Documentary datasource is required': 'Documentary datasource is required',
    'Mandatory filename field mapping': 'Mandatory filename field mapping',
    'Mandatory document name field mapping': 'Mandatory document name field mapping',
    'Path and filename fields can not be the same': 'Path and filename fields can not be the same',
    'Path and docname fields can not be the same': 'Path and docname fields can not be the same',
    'Filename and docname fields can not be the same': 'Filename and docname fields can not be the same',
    'ColumnName': 'Column name',
    'Operande': 'Operand',
    'RestrictValue': 'Criterion',
    'Choose one column': 'Choose one column',
    'Choose one operande': 'Choose one operand',
    'Choose one value': 'Choose one criterion',
    // report sheets
    'ReportCard': 'Card',
    'ReportOrganisation': 'Organization',
    'ReportShowing': 'Showing',
    'ReportRowsPinned': 'Pinned Rows',
    'SubReport': 'Secundary reports',
    'dashboard': 'Dashboard',
    'columnsHidden': 'Hidden columns',
    'columnsDisplayed': 'Displayed columns',
    'colNameReport': 'Column name',
    'ranking': 'Rank',
    'grouping': 'Group',
    'filterList': 'List',
    'filterInput': 'Filter',
    'pinning': 'Pin',
    'nonePinning': 'None',
    'startPinning': 'Start',
    'endPinning': 'End',

    // rowsPinned
    'rowPinnedLabel': 'Label row',
    'rowPinnedPosition': 'Position',
    'rowPinnedRank': 'Rank',
    'rowPinnedCol': 'Column',
    'colSum': 'Sum',
    'colAvg': 'Average',
    'colCount': 'Count',
    'colTop': 'Top',
    'colBottom': 'Bottom',
    'ChoiceColumn': 'Column',
    'ChoiceOper': 'Operation',
    'ChoicePosition': 'Position',
    'InputFormat': 'Format',
    // report card  
    'reportStatut': 'Statut report',
    'reportSample': 'Extract test',
    'menuParent': 'Menu parent',
    'menuRank': 'Menu position',
    'Choose one parent': 'Choose one parent',
    'Choose one rank': 'Choose rank in menu',
    'HomeMenu': 'Home',
    'requestRefSelect': 'Request',
    'Choose one request': 'Choose one request',
    'Choose one statut': 'Choose one statut',
    'Report label is required': 'Report label is required',
    'Statut is required': 'Statut is required',
    'Request is required': 'Request is required',
    'testReport': 'Test report : %0 ',
    // subReport
    'detailZoom': 'detail',
    'zoomPresent': 'Zoom enabled',
    'zoomCols': 'Number of columns',
    'zoomRows': 'Number of rows', 
    'reportName': 'Report name',
    'reportInitialAction': 'Action',
    'foreignKeys': 'Foreign keys',
    'limitationCol': 'Limitation columns',
    'dataRecall': 'Data recall',
    // dashboard
    'dashboardRows': 'Number of rows',
    'dashboardCols': 'Number of columns',
    'dashboardName': 'Dashboard name',
    'widgetName': 'Widget name',
    'topWidget': 'Start row',
    'leftWidget': 'Start column',
    'heightWidget': 'Height (rows)',
    'widthWidget': 'Width (columns)',
    'chartType': 'Chart type',
    'defaultDisplay': 'Displayed by default',
    'updateFrequency': 'Update frequency',
    'updateFrequencyTip': 'Frequency of the update in minutes',
    'generatedTime': 'Dataset :  %0',
    'editWidgets': 'Edit widgets',
    'datetime': 'Display datetime',
    'saveWidgets': 'Save configuration',
     // report-grid
    'LoadingReportData': 'Loading data - in progress ...',
    'LoadingReportConfig': 'Loading configuration - in progress ...',
    'settingReportFilters': 'Setting filters - in progress ...',
    'settingReportPinnedRows': 'Setting pinned rows - in progress ...',

    // entities menu
    'sharedEntities': 'Shared Entities',
    'MenuStructure': 'Structure ',
    'MenuLabeling': 'Labeling ',
    'menuName': 'Menu',
    'menuLabel': 'Label',
    'menuToolTip': 'Tooltip',
    // data table mjl_entities
    'entity ID': 'Entity ID',
    'entity': 'Entity',
    'entitylabel': 'Label',
    'entitydesc': 'Description',
    'datafilter': 'Data Filter',
    'supervisoremail': 'Mail supervisor',
    'extendedaccess': 'Extended access',
    'master': 'Master',
    'tags': 'tags',
    'models': 'Documents models',
    // data table mjl_usergroup
    'usersgroup': 'Users group',
    'usergroup': 'User group',
    'usergrouplabel': 'User group label',
    'dataHabilitations': 'Habilitations ',
    'colName': 'Col name',
    'dataCategory': 'Data category',
    'dataLevel': 'Data level',
    'Choose one category': 'Choose one category',
    // data table mjl_jobdbs
    'dbsLabel': 'Label',
    'dbsDoc': 'Documentary source',
    'dbsEngine': 'Engine',
    'dbsServer': 'Server',
    'dbsPort': 'Port',
    'dbsName': 'Name',
    'dbsUser': 'User',
    'dbsPwd': 'Password',
    'accessGrp': 'Group(s)',
    'accessEntities': 'Entity(ies)',
    'updateDate': 'Update',


    // data table mjl_jobrequests
    'Requests': 'Requests',
    'requestLabel': 'Label ',
    'requestEngine': 'Engine',
    'dbsId': 'Id datasource',
    'requestVersion': 'Version',
    'requestContent': 'Content',
    'requestDefault': 'Default',
    'docRequest': 'Documentary request',
    'docDbsLabel': 'Data source',
    'docPath': 'Path',
    'docFilename': 'File name',
    'docName': 'Document name',
    // data table mjl_jobRequestLabel
    'requestRef': 'Request ref',
    'checkLabel': 'Generic label',
    'colHeader': 'Column label',
    'colToolTip': 'Extended label',
    'colAttributes': 'Attributes column',
    'labelList': 'Labels list',
    'specialFormat': 'Specials formats',
    // data table mjl_jobReports
    'Reports': 'reports',
    'reportLabel': 'Label ',
    'reportPagination': 'Pagination ',
    'reportDocument': 'Documentary ',
    'reportDashboard': 'Dashboard',
    'reportVersion': 'Version ',
    'colType': 'Type',
    'colFmt': 'Format',
    'fmtDate': 'Date',
    'fmtHour': 'Hour',
    'fmtTimeStamp': 'TimeStamp',
    'fmtInteger': 'Integer',
    'fmtDec2': 'Decimal 2',
    'frmtZeroLleftInt5': 'Zero left 5',
    'frmtZeroLleftInt6': 'Zero left 6',
    'fmtDecCurrency2': 'Currency 2 Dec',
    'fmtIntCurrency': 'Currency integer',
    'fmtPercent': 'Percent',
    'Choose one format': 'Choose one format',
    'Reports validation': 'Reports validation',
    'reportAccept': 'Accept',
    'reportReject': 'Reject',
    'Report Area': 'Report area',
    // WKS - Menu
    'wks_administration': 'Administration',
    'wks_parameters': 'Settings',
    'wks_repositories': 'Repositories',
    'wks_customers': 'Customers',
    'wks_subcontractors': 'Subcontractors',
    'wks_suppliers': 'Suppliers',
    'wks_equipments': 'Customers equipements',
    'wks_boats': 'Boats',
    'wks_jets': 'Jets',
    'wks_trailers': 'Trailers',
    'wks_engines': 'Engines',
    'Customers': 'Customers',
    'Subcontractors': 'Subcontractors',
    'Suppliers': 'Suppliers',
    'wks_entity': 'Entity settings',
    'wks_manufacturers': 'Manufacturers specifications',
    'param_worksrates': 'Works & rates settings',
    'param_partssupplies': 'Parts & supplies settings',
    'param_actionsref': 'Actions settings',
    'param_tasksref': 'Tasks settings',
    'associated_task': 'Associated task',
    'param_rh_planning': 'HR planning',
    'wks_jobs': 'Jobs',
    'wks_quotations': 'Quotations',
    'wks_works': 'Jobs',
    'wks_worksOptions': 'Files',
    'wks_assignments': 'Assignment jobs',
    'wks_checklists': 'Checklists',
    'wks_invoices': 'Invoices',
    'wks_invoicing': 'Invoicing',
    'wks_appointments_list': 'Requests list',
    'wks_planning': 'Appointments planning',
// WKS entity
    'accountCash': 'Cash accounting account number',
    'accountFmt': 'Accounting account number format',
    'reportingActivate': 'Reporting activate',
    'entityDataPurge': 'Inactive data purge time (months)',
    'entityScales': 'Tariff scales',
    'entityCurrency': 'Currency',
    'no models': 'No model',
    'Choose format': 'Choose internationnal format ',
    'usersLanguages': 'Users languages',
    'internationalFormat': 'International format',
    'subsidiaries': 'Subsidiaries',
    'entityPosition': 'Geo location',
    'entityTz': 'Time zone',
    'quotationActivation': 'Quotation activation',
    'invoicingActivation': 'Invoicing activation',
    'invoicingNumber': 'Numbering',
    'activity': 'Activity',
    'generalSettings': 'General settings',
    'workshop': 'Workjob',
    'concierge': 'Concierge',
    'accounting': 'Accounting',
    'location': 'Location',
    'customerOutstandingDefault': 'Default Customer Outstanding',
    'maxBdd': 'Max go bdd',
    'maxWebdav': 'Max go webdav',
    'maxSms': 'Max SMS / month',
    'maxNotifs': 'Max notifs / month',
    'paginationActivation': 'Activation pagination',
    'paginationLabel': 'Pagination label',
    'paginationFormat': 'Pagination format',
    'paginationPosV':  'Position V',
    'pagingationPosH': 'Position H',
    'sales price calculation': 'Sales price calculation',
    'local price': 'local product',
    'imported price': 'imported product',
    'stock management api': 'Stock management',
    'accounting api': 'Accounting',
    'notifications api': 'Notifications',
    'customers api': 'Customers',
    'smtpServerParams': 'Server parameters',
    'smtpServer': 'Smtp server',
    'smtpPort': 'Port',
    'smtpConnectSecurity': 'Connect sSecurity',
    'smtpUserAuthentification': 'Authentification',
    'smtpUser': 'User',
    'smtpPasswd': 'PAssword',
    'smtpParamResponse': 'Response prarameters',
    'smtpFrom': 'Mail sender',
    'smtpReplyTo': 'Mail response',
    'smtpNoreply': 'No response',
    'smtpDebug': 'Debug option',
    'smtpStorage': 'Storage of outgoing messages',
    'storePrefix': 'Storage prefix',
    'storeWorks': 'Works messsages',
    'storeAppointments': 'Appointments messages',
    'storeRH': 'HR messages',
    'storeQuotation': 'Quotations messages',
    'storeInvoice': 'Invoices messages',
    'storeAdvance': 'Advances messages',
    'storeCustomer': 'Customers messsages',
    'storeRevisions': 'Revision messages',
    'storeSmtp': 'Smtp messages',
    'smtpTest': 'SMTP test',
    'error to send mail': 'Error to send mail test',
    'send mail error': 'Sending mail in error',
    'test smtp OK': 'Test smtp OK',
    'send mail OK': 'Sending mail OK',
    'invoiceAppendix': 'Appendix invocie', 
    'creditnoteAppendix': 'Appendix credit note', 
    'backPage': 'Backgroung page', 
    'quotationAppendix': 'Appendix quotation',
    'modelInvoice': 'Invoice model',
    'modelQuotation': 'Quotatioon model',
    'modelCreditnote': 'Creditnote model',
// WKS manufacturer
    'loading loading manufacturers specifications': 'Loading manufacturers specifications',
    'manufacturers specifications': 'Manufacturers specifications',
    'specFiletype': 'File type',
    'specFileversion': 'Version',
    'specFilename': 'File name',
    'specStatut': 'Status',
    'doUpload': 'Upload',
    'waitingUpload': 'Waiting upload',
    'waitingExtractText': 'Waiting extract text',
    'waitingRegex': 'Waiting extract data',
    'waitingVerifRegex': 'Waiting verif data',
    'waitingInjectProcess': 'Waiting injection data',
    'waitingVerifInjection': 'Waiting verif injection',
    'uploadProcessTitle': 'Upload file',
    'uploadProcess': 'Upload file in progress ...',
    'uploadFileOKTitle': 'Upload file',
    'uploadProcessDone': 'Done',
    'fileUploaded': 'Uploaded',
    'historyDate': 'Processing date',
    'recordedDate': 'Recorded date',
    'downloadFile': 'Dowload file',
    'addFile': 'Adding file',
    'badFileName': 'Selected file %0. attempted file : %1 ',
    'removeFileTitle': 'Remove File',
    'removefileLabel': 'Removing file %0 ?',
    'removefileLabelKO': 'Error in deletion file %0',
    'extractText': 'Extract text',
    'processingRegex': 'Extract data',
    'validRegex': 'Validation data',
    'injectProcess': 'Injection DB',
    'validInjection': 'Validation DB',
    'new job label': 'New job label',
    'extractText_OK': 'Extract text file %0 ?',
    'processingRegex_OK': 'Extract data file %0 ?',
    'validRegex_OK': 'Validation data file %0 ?',
    'injectProcess_OK': 'Injection DB file %0 ?',
    'validInjection_OK': 'Validation DB file %0 ?',
    'extractText_PROGRESS': 'Error on extract text file %0 in progress ...',
    'processingRegex_PROGRESS': 'Error on extract data file %0 in progress ...',
    'validRegex_PROGRESS': 'Error on validation data file %0 in progress ...',
    'injectProcess_PROGRESS': 'Error on injection DB file %0 in progress ...',
    'validInjection_PROGRESS': 'Error on validation DB file %0 in progress ...',
    'extractText_DONE': 'Error on extract text file %0 done !!',
    'processingRegex_DONE': 'Error on extract data file %0 done !!',
    'validRegex_DONE': 'Error on validation data file %0 done !!',
    'injectProcess_DONE': 'Error on injection DB file %0 done !!',
    'validInjection_DONE': 'Error on validation DB file %0 done !!',
    'extractText_KO': 'Error on extract text file %0',
    'processingRegex_KO': 'Error on extract data file %0',
    'validRegex_KO': 'Error on validation data file %0',
    'injectProcess_KO': 'Error on injection DB file %0',
    'validInjection_KO': 'Error on validation DB file %0',
    'verifList': 'Verification list',
// WKS - Specjobs
    'loading specificationsImportList': 'Loading imported specifications ', 
    'specificationsImportList': 'Imported specifications list', 
    'readingImports': 'Reading imports',
    'searching imports': 'Searching imports in progress ...',
    'no import found ': 'No import found. Error %0 ',
    'jobNumber:': 'Job number',
    'jobLabel1': 'Label',
    'jobPage': 'Page',
    'jobMajor': 'Major',
    'jobNormal': 'Normal',
    'jobLevel': 'Level',
    'jobNotes': 'Notes',
    'specType': 'Job type',
    'periodicityHours': 'Hours',
    'periodicityMonths': 'Months',
    'frequency': 'Frequency',
    'jobParts': 'Parts',
    'jobAction': 'Actions',
    'replace': 'Exchange',
    'replaceFirstYear': 'Exchange fst year',
    'check': 'Check',
    'brandLabel': 'Label',
    'valid specification': 'Validation specification',
    'lost data': 'Would you like to continue? Data will be lost !!',
    'validation in progress': 'Validation in progress',
    'validation KO': 'Validation failed : %0 ',
    'validation OK': 'Validation done',
    'removeImports': 'Remove specification',
    'remove specification': 'Confirming deletion of job %1 on page %0 ? ',
    'removeSpecification': 'Remove specification',
    'remove in progress': 'Temove in progress',
    'remove KO': 'Remove failed : %0 ',
    'remove OK': 'Removing done',
    'duplicated brand ref': 'Brand ref %0 duplicated',
    'duplicated work': 'work type duplicated',
// APIS
    'toolName': 'Name',
    'toolVersion': 'Version',
    'toolProtocol': 'Protocol',
    'authentification': 'Authentification',
    'tooluser': 'User',
    'toolkey': 'Key',
    'toolusername': 'User ID',
    'toolpwd': 'Pwd',
    'toolkeyname': 'Key name',
    'toolkeyvalue': 'Key value',
    'toolServerUrl': 'URL',
    'toolServerPort': 'Port',
    'toolServerArgs': 'Args',
    'toolServiceName': 'Service name',
    'toolServiceMethod': 'Method',
    'toolServiceUrl': 'Url',
    'toolServiceArgs': 'Args',
    'toolJwtServerUrl': 'Url',
    'toolJwtServerPort': 'Port',
    'toolJwtServerArgs': 'Args',
    'toolserver': 'Server',
    'toolServices': 'Services',
    'toolauth2': 'Authentification 2',
    'activateJwt': 'Jwt activation',
    'headers': 'Headers',
    'headerKey': 'Key',
    'headerValue': 'Value',
    'args': 'Arguments',
    'testsApi': 'Tests',
    'create': 'Creation',
    'read': 'Reading',
    'update': 'Update',
    'list': 'List',
    'stock': 'Stock',
    'movement': 'Movement',
    'notAuthorised': 'Not authorised',
    'service': 'Service',
    'dateTest': 'Date test',
    'testApi': 'Test Api',
    'stockManagement': 'Stock management',
    'notifications': 'Notifications',
    'customers': 'Customers',
    'stockManagementMes': 'Stock management %0 - %1',
    'invoicingMes': 'Invoicing %0 - %1',
    'notificationsMes': 'Notifications %0 - %1',
    'accountingMes': 'accounting %0 - %1',
    'customersMes': 'Customers %0 - %1',
    'inProgress': 'In progress',
// WKS param worksrates
    'workCode': 'Work code',
    'workLabel': 'Work label',
    'workUnit': 'Billing unit',
    'workPricing': 'Price list',
    'searching works rates': 'Searching works rates',
    'unitary price per contract': 'Unit price per contract',
    'no work rate found': 'No work rate found',
    'unit': 'Unit',
    'hour': 'Hour',
    'day': 'Day',
    'week': 'Week',
    'month': 'Month',
    'quarter': 'Quarter',
    'semester': 'Semester',
    'year': 'Year',
    'Choose workunit': 'Choose billing unit',
    'recording entity': 'Recording entity',
    'recording work rate': 'Speciality',
    'error loading': 'Error loading',
    'loading entity': 'Loading entity',
    'deleting work rate': 'Deleting work rate',
// WKS param_actionsref,
    'brandRef': 'Brand ref',
    'actionrefType': 'Action type',
    'actionrefLabel': 'Action label',
    'billingUnit': 'Billing unit',
    'suppliesLabel': 'Label',
    'actionTask': 'Action',
    'searching jobs ref': 'Searching jobs ref',
    'no action ref found': 'No action ref found',
    'recording action ref': 'Recording action ref',
    'supplies&parts': 'Supplies & parts', 
    'labor': 'Labor', 
    'works': 'Works',
    'check list': 'Check list',
    'check list empty': 'Create a specific task or choose a referenced task',
    'allEquipments': 'All equipments',
    'equipmentsSelected': 'Equipments',
    'liter': 'Liter',
    'meter': 'Meter',
    'Check': 'Check',
    'Exchange': 'Exchange',
    'ExchFirst': '1st change', 
    'taskrefQuantity': 'Quantity',
    'taskrefUnit': 'Unity',
    'taskrefDuration': 'Duration',
    'taskrefComment': 'Observations',
    'actionsReferenced': 'Referenced actions',
    'actionsSelected': 'Selected actions',
    'quantity': 'Quantity',
    'duration': 'Duration',
    'unitPrice': 'Unit P',
    'extPrice': 'Ext P',
    'available': 'Available',
    'totalDuration': 'Total duration',
    'serial numbers': 'Serial numbers',
    'allSN': 'All SN',
    'snFrom': 'From',
    'snUpto': 'Up to',
    'jobPud': 'Manufacturer update',
    'revision': 'Revision',
// WKS param_tasksref,
    'tasksReferenced': 'Referenced tasks',
    'CreateReferencedTask': 'Create referenced task',
    'Refresh': 'Refresh list',
    'remove from the list': 'Remove from the list',
    'create action': 'Create action',
    'taskrefLabel': 'Label task',
    'fournitureLabel': 'Label',
    'jobLabel': 'Job label',
    'taskSN': 'Serial numbers',
    'partRef': 'Part ref',
    'searching tasks ref': 'Searching tasks ref',
    'no task ref found': 'No task ref found',
    'recording task ref': 'Recording task ref',
    'deleting task ref': 'Deleting task ref',
// param_partsupplies,
    'updateRatesPartSupplie': 'Update of unit prices',
    'addPartSupplies': 'Create a new item',
    'uploadPartSupplie': 'Upload a reference file',
    'paramType': 'Type',
    'paramTypeLabel': 'Type',
    'brandName': 'Brand',
    'paramId': 'Reference',
    'paramLabel': 'Label',
    'partsSuppliesLabel': 'Label',
    'paramUnit': 'Billing unit',
    'paramRate': 'Rate',
    'paramCurrency': 'Currency',
    'paramQuantity': 'Quantity',
    'part': 'Part',
    'supplie': 'Supplie',
    'prestation': 'Service',
    'deleting part & supplie': 'Deleting part & supplie',
    'searching part & supplie': 'Searching part & supplie',
    'no part or supplie found': 'No part or supplie found',
    'recording part or supplie ': 'Recording part or supplie ',
    'supply': 'Supply',
    'referential': 'Referential',
    'upload referential': 'Upload referential',
    'upload supply': 'Upload supply',
    'upload local supply': 'Local supply',
    'upload import supply': 'Import supply',
    'analysisFileTitle': 'Analysis file',
    'analysisFile': 'Analysis file %0 in progress ...',
    'analysisDatas': 'Analysis datas',
    'modifFiles': 'Modifier fichiers',
    'buildCrossreferences': 'Buildind cross references ... ',
    'buildCrossrequest' : 'Building cross requests ...',
    'ProcessTerminate': 'Process terminated',
    'line': 'line',
    'out of': 'out of',
    'nbErrorsMultiFiles': 'Number of errors',
    'nbRowsMultiFiles': 'Number of rows',
    'downloadErrorsMultiFiles': 'Download errors file',
    'downloadRowsMultiFiles': 'Download file',
    'extractData': 'Data extraction',
    'analysisData': 'Vrification mandatory datas',
    'extractDataMainFile': 'Extracting data from main file',
    'extractDataSubFile': 'Extracting data from main file : %0',
    'sellingPriceCalculation': 'Selling price calculation',
    'buildWhereClauseFileTitle': 'Building clauses extraction',
    'buildWhereClauseFile': 'Building clauses extraction file : %0',
    'verifCrossReferencesTitle' : 'Current cross-reference check',
    'buildCrossReferences': 'Building cross references ...',
    'verifCrossReferences' : 'Cross-reference check in progress ...',
    'uploadMultiFilesOKTitle': 'Upload files',
    'uploadMultiFilesOK': 'Upload completed',
    'uploadMultiProcessTitle': 'Upload files',
    'uploadMultiProcess': 'Upload files in progress ...',
    'readingLines': 'Lines detected',
    'colFile': 'File column',
    'bddName': 'Bdd name',
    'hsCode': 'HS code',
    'paramFamily': 'Family',
    'paramSubFamily': 'Sub family',
    'paramCountry': 'Country origin',
    'paramState': 'State origin',
    'columnsBddCrossRef': 'Cross references',
    'currencyTarget': 'Target currency',
    'incomingCurrency': 'Incoming currency',
    'exchangeRate': 'Exchange rate',
    'columnsBddUpload': 'Correspondence database ',
    'columnsBddList': 'Database columns',
    'uploadFileName': 'File name',
    'file exists': 'File already selected',
    'upload File parts & supplies': 'Upload file',
    'upload file OK': 'Upload file %0 : %1 ',
    'register action KO': 'Recorded file %0 action %1 failure',
    'upload file KO': 'Erreur on upload file %0 : %1 ',
    'control file content': 'File control',
    'partsSuppliesAdded': '%0 product(s) added',
    'partsSuppliesUpdated': '%0 product(s) updated',
    'partsSuppliesDeleted': '%0 Product(s) removed',
    'partSupplieSearchForm': 'Search references parts supplies & services ',
    'partsSuppliesSelected': 'References parts supplies & services selected',
    'partsSuppliesFounded': 'References parts supplies & services founded',
    'addPartSupplieList': 'Add to list',
    // update rates
    'updateRatesTitle': 'Update of unit prices',
    'processRates': 'Updating',
    'brandRefs': 'References',
    'percentRate': 'Percent',
    'increaseRates': 'Increase',
    'reductionRates': 'Reduction',
    'Process update': 'Processing update rates',
    'End process update': 'Process updating prices completed',
    'Process update OK': 'Process OK.  %0 element(s) updated',
    'Process update KO': 'Process KO.',
    'OK to process ?': 'OK to process ?',
// WKS - parentTaskRef
    'taskRefParent': 'Parent task',
// WKS - bsn
    'searching customers': 'Searching customers',
    'no customer': 'No customer founded',
    'searching subcontractors': 'Searching subcontractors',
    'no subcontractor': 'No subcontractor founded',
    'searching suppliers': 'Searching suppliers',
    'no supplier': 'No supplier founded',
    'deleting customers': 'Remove customer',
    'deleting subcontractors': 'Remove subcontractor',
    'deleting suppliers': 'Remove supplier',
    'deleting contact customer': 'Remove contact customer',
    'deleting contact subcontractor': 'Remove contact subcontractor',
    'deleting contact supplier': 'Remove contact supplier',
    'deleting contact anchorage': 'Remove contact anchorage',
    'OK alert': 'OK',
    'company': 'Company',
    'administration': 'Administration',
    'person': 'Person',
    'professional': 'Professional',
    'individual': 'Individual',
    'BsnCard': 'Card ',
    'BsnAddresses': 'Addresses ',   
    'BsnContacts': 'Contacts', 
    'administrative': 'Administrative',
    'contracts': 'Contracts',
    'guarantees': 'Guarantees',
    'quotations': 'Quotations',
    'invoices': 'Invoices',
    'invoicing': 'Invoicincing',
    'other docs': 'Other docs',
// wks_businness
    'bsnEntity': 'Entity',
    'bsnCountry': 'Nationality',
    'bsnNature': 'Nature',
    'bsnParent': 'Parent',
    'bsnType': 'Type',
    'bsnTax': 'Fiscality',
    'customerOutstanding': 'Authorized outstanding',
    'corporateName': 'Corporate name',
    'bsnCivility': 'Civility',
    'bsnFirstName': 'First name',
    'bsnLastName': 'Last name',
    'bsnName': 'Name',
    'bsnDenom': 'Denomination',
    'bsnAccount': 'Account',
    'bsnCashPay': 'Cashpay',
    'scaleName': 'Scale',
    'bsnCoef': 'Coefficient',
    'taxableVat': 'VAT',
    'idVat': 'Id Vat',
    'customsRegistration': 'Customs',
    'customsId': 'Customs id',
    'freeZone': 'Free zone',
    'freeZoneId': 'Free zone Id',
    'days': 'Days',
    'bsnDefferedPaiement': 'Paiement',
    'bsnDelayDays': 'Differed',
    'delayValidity': 'Delay validity',
    'invoiceEmit': 'Invoice emit',
    'endMonth': 'End month',
    'OrderPrePaid': 'Order pre-paid',
    'receptMaterials': 'Recept materials',
    'materialsInstallation': 'Materials installation',
    'completionWork': 'Completion works',
    'afterInvoice': 'AfterInvoice',
    'inAccount': 'Account',
    'orderNumber': '#',
    'controlPercent': 'Control',
    'bsnNatIdentity': 'Id National',
    'bsnNatRegister': 'National Register',
    'bsnAddress': 'Address',
    'bsnZipcode': 'ZipCode',
    'bsnCity': 'City',
    'bsnState': 'State',
    'bsnEmail': 'Email',
    'bsnTel': 'Tel',
    'bsnFunction': 'Function',
    'bsnHabilquote': 'Quote',
    'bsnHabilbill': 'Bill',
    'bsnRole': 'Role',
    'stdCreation': 'Creation',
    'stdLastupdate': 'Update',
    'stdDisable': 'Disabled',
    'loading Customers': 'Loading customers',
    'loading Suppliers': 'Loading suppliers',
    'loading Subcontractors': 'Loading subcontractors',
    'balance': 'Balance',
    // address 
    'purchase department': 'Purchase department',
    'juridical department': 'Juridical department', 
    'financial department': 'Financial department',
    'technical department': 'Technical department',
    'identity': 'Identity',
    'Fonction dept': 'Fonction',
    'fonctionDept': 'Fonction',
    'Select dept': 'Department',
    'selectDept': 'Department',
    'Select country': 'Select country',
    'Select apps': 'Notifications',
    'cntCivility': 'Civility',
    'cntFirstname': 'First name',
    'cntLastname': 'Last name',
    'addrElem1': 'Recipient',
    'addrElem2': '',
    'addrElem3': 'Address',
    'addrElem4': '>',
    'addrElem5': '>',
    'addrElem6': '>',
    'addrelem1': 'Recipient',
    'addrelem2': '',
    'addrelem3': 'Address',
    'addrelem4': '>',
    'addrelem5': '>',
    'addrelem6': '>',
    'area': 'Area',
    'block': 'Block',
    'street_name': 'street',
    'house_number': 'House number',
    'paci_number': 'PACI',
    'AREA': 'Area',
    'BLOCK': 'Block',
    'STREET_NAME': 'Street',
    'HOUSE_NUMBER': 'House',
    'PACI_NUMBER': 'PACI',
    'pobox': 'PoBox',
    'district': 'District',
    'zipcode': 'Zip Code',
    'addrElem7': '',
    'city': 'City',
    'country': 'Country',
    'countryCode': '',
    'state': 'State',
    'denomination': 'Denomination',
    'fullName': 'Full Name',
    'FEIN': 'FEIN',
    'SIRET': 'SIRET',
    'addressPosition': 'Geo location',
    'phoneNumber': 'Num',
    'phonePrefix': 'Prefix',
    'phoneType': 'Type',
    'phoneDesk': 'Desk',
    'phoneCell': 'Cell',
    'phoneCellWhatsApp': 'Cell & WhatsApp',
    'phoneHome': 'Home',
    'phoneOther': 'Other',

    'emailAddress': 'Email address',
    'emailType': 'Type',
    'prof': 'Professionnal',
    'perso': 'Personnal',
    'profID': 'Professionnal Id',
    'individualID': 'Individual Id',
    'unknow': 'Unknow',

    'recording customers': 'Recording customers',
    'error recording': 'Writing error',
    'recording subcontractors': 'Recording subcontractors',
    'recording suppliers': 'Recording suppliers',
    'customer exists': 'This customer already exists',
    'subcontractor exists': 'This subcontractor already exists',
    'supplier exists': 'This supplier already exists',
    // WKS - wks-equipment
    'id': 'Identifiant',
    'stdEntity': 'Entity',
    'equipId': 'Id Equipment',
    'ownerId': 'Owner Id',
    'ownerName': 'Owner name',
    'equipPartOf': 'Part of',
    'equipImmat': 'Immatriculation',
    'equipType': 'Type Equipment',
    'equipTypeLabel': 'Type Equipment',
    'equipDesign': 'Designation',
    'equipCountry': 'Country',
    'equipBrand': 'Brand',
    'equipModel': 'Model',
    'equipYear': 'Year',
    'equipName': 'Unit name',
    'equipSN': 'SN',
    'equipAddInfo': 'Additional info',
    'equipHours': 'Hours',
    'equipHoursDate': 'Date hours record',
    'equipValid': 'Validated',
    'otherData': 'Other datas',
    'outboard engine': 'Outboard engine',
    'hull': 'Hull',
    'work history': 'Work history',
    'revisions': 'Revisions',
    'revisionsHours': 'Hours',
    'revisionsMonths': 'Months',
    'revisionsTask': 'Job',
    // WKS - usage hours

    'histoHours': 'Hours',
    'histoMonths': 'Months',
    'histoDate': 'Date',
    'histoRevision': 'Revision',
    'Control values hours': 'Checking hours of use',
    'hours lt': 'Hours entered lower than previous hours : %0 / %1',
    'hours gt': 'Hours entered greater than following hours : %0 / %1',
    'Control values date': 'Checking dates',
    'date lt': 'Date entered less than previous date : %0 / %1',
    'date gt': 'Date entered greater than next date : %0 / %1',
    'usage hours': 'Usage hours',
    'no hours': 'No time sheets',
    'register error': 'Recording error',
    // WKS Jobs / equipments
    'boat': 'Boat',
    'engine': 'Engine',
    'jet': 'Jet',
    'trailer': 'Trailer',
    'electronic & navigation': 'Electronic & navigation',
    'appliances': 'Appliances',
    'fourniture': 'Fourniture',
    'chandlery': 'Chandlery',
    'generator': 'Generator',
    'air conditioning system': 'Air conditioning system',
    'gyro stabilizer': 'Gyro stabilizer',
    'welding jobs': 'Welding jobs',
    'carpentry jobs': 'Carpentry jobs',
    'fiberglass jobs': 'Fiberglass jobs',
    'upholstery jobs': 'Upholstery jobs',
    'painting jobs': 'Painting jobs',
    'machine shop jobs': 'Machine shop jobs',
    'electrical jobs': 'Electrical jobs',
    'storage': 'Storage',
    'mechanical jobs': 'Mechanical jobs',
    'all jobs type': 'All jobs',
    'hauling & towing': 'Hauling & Towing',
    'search': 'Search',
    'searching boats': 'Searching Boats',
    'searching jets': 'Searching Jets',
    'searching trailers': 'Searching Trailers',
    'searching engines': 'Searching Engines',
    'no boat found': 'No boat founded',
    'no jet found': 'No Jet founded',
    'no trailer found': 'No Trailer founded',
    'no engine found': 'No Engine founded',
    'Choose owner': 'Choose owner',
    'Choose brand': 'Choose brand',
    'Choose model': 'Choose model',
    'Choose year': 'Choose year',
    'add equipment': 'Creating',
    'search equipment': 'Searching',
    'all equipments': 'List of',
    'Searching boat': 'Searching boat',
    'Searching jet': 'Searching jet',
    'Searching trailer': 'Searching trailer',
    'Searching engine': 'Searching engine',
    'New boat': 'New boat',
    'New jet': 'New jet',
    'New trailer': 'New trailer',
    'New engine': 'New engine',
    'recording boat': 'Recording boat',
    'recording jet': 'Recording jet',
    'recording engine': 'Recording engine',
    'recording trailer': 'Recording trailer',
    'Equipments': 'Equipments',
    'motorisation inboard': 'In-bord', 
    'motorisation outboard': 'Hors-bord', 
    'motorisation jet': 'Jet', 
    'propulsion': 'Propulsion',
    'Choose propulsion': 'Choose propulsion',   
    'motorsConfiguration': 'Configuration motorisation',
    'Choose motorsConfiguration': 'Choose motors configuration',
    'sails': 'Voiles',
    'single installation': 'Single',
    'dual installation': 'Dual',
    'triple installation': 'Triple',
    'quad installation': 'Quad',
    'quint installation': 'Quint',
    'Port side': 'Port side', 
    'Starboard': 'Starboard',
    'center': 'Center',
    'Port side outside': 'Port side outside', 
    'Port side inside': 'Port side inside', 
    'Starboard inside': 'Starboard inside', 
    'Starboard outside': 'Starboard outside',
    'equipBoatManeuvring': 'Maneuvring motorisation',
    'motorType': 'Motor type',
    'Choose motorType': 'Choose motor type',
    'motorPosition': 'Motor position',
    'commissioningDate': 'Commissioning date',
    'thruster motor': 'Thruster motor',
    'stern thruster': 'Stern thruster',
    'bow thruster': 'Bow thruster',
    'ownerContact' : 'Contact',
    'equipHitch': 'Hitch',
    'ball hitch' : 'Ball',
    'ring hitch' : 'Ring',
    'hitchSizes': 'Hitch size',
    'Choose hitchSizes': 'Choose hitch size',
    'axles': 'Axles',
    'Choose axles': 'Number axles',
    'tires': 'Tires',
    'Choose tires': 'Tires size',
    'rims': 'Rims',
    'Choose rims': 'Rims size',
    'nuts': 'Nuts',
    'Choose nuts': 'Number nuts',
    'sizes' : 'Size',
    'trailer axles': 'Axles',
    'trailer tires': 'Tires',
    'trailer rims': 'Rims',
    'equipBoatHomeport': 'Home port',
    'equipBoatAnchorage': 'Anchorage',
    'equipBoatMooring': 'Mooring',
    'equipBoatWintering': 'Wintering',
    'portPool': 'Pool',
    'portPontoon': 'Pontoon',
    'portRing': 'Ring',
    // wks-changeStatut
    'changeWorkStatut': 'Change status',
    'workStatutCur': 'Current status',
    'workStatutNew': 'New status',
    'confirmChangeStatut': 'Confirmation of status change',
    'changeWorkStatutLabel': 'From %0 to %1',
    // wks_docrepository
    'wks_docs': 'Documents',
    'new document': 'New document',
    'update document': 'Update document',
    'searching document': 'Searching document',
    'docBrand': 'Brand',
    'docModel': 'Model',
    'docTags': 'Classification',
    'docYear': 'Model year',
    'docComment': 'Comments',
    'docVersion': 'Version',
    'technical': 'Technical',
    'user': 'User',
    'technical doc': 'Technical doc',
    'user doc': 'User doc',
    'Choose tag': 'Choose classification',
    'searching documents': 'Searching documents',
    'no doc found': 'No document found',
    'Searching docs': 'Searching documents',
    'recording doc': 'Recording document',
    'Upload document': 'Upload document',
    'upload doc': 'Upload document',
    'deleting docs': 'Deleting document',
    'uploadDate': 'Upload date',
// WKS  checkList
    'realized': 'Realized',
    'checked': 'Checked',
    'unchecked': 'Un checked',
    'changed': 'Changed',
    'not_changed': 'Not changed',
    'additionalTasks': 'Further work',
    'taskParts': 'Parts',
    'taskDescription': 'Description',
    'taskDuration': 'Duration / hours',
    'taskComment': 'Comment',
  // WKS - wksAnchorages
    'anchorages': 'Anchorages',
    'anchorage': 'Anchorage',
    'anchorageCountry': 'Country',
    'anchorageState': 'State',
    'anchorageCity': 'City',
    'anchorageName': 'Name',
    'anchorageWintering': 'Wintering',
    'anchoragePosition': 'Position Lat/Lng',
    'anchoragePositionCoordError': 'Wrong position',
    'searching anchorages': 'Searching anchorages',
    'no anchorage found': 'No anchorage found',
    'searching anchorage': 'Searching anchorage',
    'new anchorage': 'New anchorage',
    'update anchorage': 'Update anchorage',
    'Location': 'Location',
    'geoLocation': 'Geo location ',
    'subsidiaryOf': 'Subsidiary of',
    'recording subsidiary': 'Recording subsidiary',
    'removing subsidiary': 'Removing subsidiary',
    // upload
    'upload File anchorages': 'Uploading file',
    'anchoragesAdded': '%0 anchorage(s) added',
    'anchoragesUpdated': '%0 anchorage(s) updated',
    'anchoragesDeleted': '%0 anchorage(s) deleted',
    // calculation algo
    'localPrice': 'Selling price local product',
    'importedPrice': 'Selling price imported product',
    'purchasePriceET': 'Purchase price excl tax',
    'purchasePriceIT': 'Purchase price Incl tax',
    'testingValue': 'Testing value',
    'dataLabel': 'Data label',
    'dataRef': 'Data num', 
    'operande': 'Operator',
    'addition': 'Addition',
    'substraction': 'Substraction',
    'multiplication': 'Multiplication',
    'division': 'Division',
    'numberValue': 'Value',
    'adding condition': 'Adding condition',
    'adding expression': 'Adding expression',
    'condExpression': 'Condition',
    'validAlgo': 'Validation',
    'testAlgo': 'Testing algo',
    'previous oper': 'Previous oper',
    'countryOrigin': 'Country of origin',
    'duties': 'Customs duties',
    'economicAreaEntity': 'Economic area entity',
    'economicAreaOrigin': 'Economic area origin',
// WKS - wks-works
    'workIdCopied': 'Job identifier copied !',
    'loadingWorks': 'Loading works',
    'owner': 'Owner',
    'equipment': 'Equipment',
    'tasks': 'Tasks',
    'description': 'Description',
    'intervention': 'Intervention',
    'newCustomer': 'New customer',
    'existsCustomer': 'Existing customer',
    'newEquipment': 'New equipment',
    'existsEquipment': 'Existing equipment',
    'loadingCustomer': 'Loading customers in progress ...',
    'loadingEquipments': 'Loading customer equipments in progress ...',
    'loadingSubEquipments': 'Loading equipments in progress ...',
    'loadingBoats': 'Loading boats in progress ...',
    'Equipment attached to a boat': 'Equipment attached to a boat ?',
    'search equipment customer': 'Search customer equipment',
    'no equipment found': 'No equipment found',
    'search boats customer': 'Search customer boats',
    'no boat found customer': 'No boat found',
    'workId': 'ID',
    'workRef': 'Ref',
    'workMainEquipName': 'Main unit',
    'workCustomerName': 'Customer',
    'workObject': 'Object',
    'workPrevious': 'Linked job',
    'workComment': 'Comment',
    'mooringPort': 'Mooring port',
    'locationWorkshop': 'Workshop',
    'locationMooring': 'Mooring',
    'locationHomePort': 'Home port',
    'locationOther': 'Other mooring',
    'locationHomeOwner': 'Home owner',
    'criticalityState': 'Criticality state',
    'dispatchtab': 'Dispatch',
    'workCriticalityLabel': 'Criticality state',
    'availableLabel': 'Parts',
    'immobilized': 'Immobilized',
    'priority': 'Priority',
    'normal': 'Normal',
    'back_job': 'Back-job',
    'planning intervention': 'Planning intervention',
    'workStartDate': 'Start date',
    'workEndDate': 'End date',
    'CreateTask': 'Create task',
    'CreateSpecificTask': 'Create specific task',
    'workRecordedDate': 'Recorded',
    'workStatut': 'Statut',
    'workTicket': 'Ticket',
    'no work found': 'No work found',
    'searching works': 'Searching works',
    'recording work': 'Recording work',
    'All statuts': 'All statuts',
    'undefined': 'Undefined',
    'appointment': 'Request',
    'appointment request': 'Request',
    'elaboration': 'Elaboration',
    'completed': 'Completed',
    'submitted': 'Submitted',
    'accepted': 'Accepted',
    'refused': 'Refused',
    'assigned': 'Assigned',
    'in-progress': 'In progress',
    'finished': 'Finished',
    'closed': 'Closed',
    'invoiced': 'Invoiced',
    'historized': 'Historized',
    'cancelled': 'Cancelled',
    'stand-by': 'Stand by',
    'statusJournal': 'Status journal',
    'recordedTrack': 'Date',
    'userLogged': 'User',
    'numberTrack': 'Order',
    'photos': 'Photos',
    'new photo': 'Photos deposit',
    'new doc': 'Documents deposit',
    'Choose equipment': 'Choose equipment',
    'Choose task': 'Choose task',
    'Choose moment': 'Choose moment',
    'BEFORE': 'Before',
    'DURING': 'In progress',
    'AFTER': 'After',
    'sequenceTime': 'Hour',
    'sequenceDate': 'Date',
    'sequencePhotos': 'Photos sequence',
    'sequenceLabel': 'Sequence',
    'loadingPhotos': 'loading photos in progress ',
    'workdocLegend': 'Legend',
    'workdocLabel': 'Label',
    'workdocFilename': 'file name',
    'workdocType': 'Type',
    'quote': 'Quotations',
    'quoteNews': 'Issued quotes',
    'quoteValidated': 'Validated quotes',
    'quoteApproved': 'Approved quotes',
    'quoteSubmitted': 'Submitted quotes',
    'quoteRefused': 'Refused quotes',
    'quoteInprogress': 'Job in progress',
    'submiting': 'Submit',
    'computingQuoteTitle': 'Calculation of the quote',
    'computingQuoteInProgress': 'Calculation of the current estimate in progress ...',
    'computingQuoteInError': 'Calculation of the current estimate in error :\n%0', 
    'No more data': 'No more data',
    'the file is under study': 'Under study',
    'study completed': 'Study completed',
    'submitted to client': 'submitted to customer',
    'accepted by the customer': 'Accepted by the customer',
    'assigned to a worker (workshop, on site, subcontractor, etc.)': 'Assigned to a worker',
    'the work is in stand by': 'The work is in stand by',
    'the work is in progress': 'The work is in progress',
    'the intervention is finished': 'The intervention is finished',
    'If the planned work is not carried out, it is rejected': 'The work is finish',
    'transmitted for invoicing': 'Transmitted for invoicing',
    'placed in the equipment history': 'Placed in the equipment history',
    'close': 'Close',
    'validated': 'Validated',
    'approved': 'Appovated',
    'waitingAdvance': 'Waiting advance',
    'disapproved': 'Disable approbation',
    'unvalidated': 'Disable validation',
    'unvailable parts': 'Unvailables parts',
    'all parts availables': 'Parts availables',
    'phonesList': 'Phones',
    'emailList': 'Emails',
    'terms of payment': 'Terms of payment',
    'terms of payment customer': 'Terms of payment customer',
    'terms of payment specifics': 'Terms of payment specifics',
    'financial conditions': 'Financial conditions',
    'financial conditions customer': 'Financial conditions customer',
    'financial conditions specifics': 'Financial conditions specifics',
    'specificsConditions': 'Specifics conditions',
    'scheduling of payments': 'Scheduling of payments',
    'customerFinancials': 'Customer conditions',
    'customerConditions':  'Customer conditions',
    'numberPayments': 'Number payments',
    'termPayment': 'Term',
    'delayPayment': 'Delay',
    'percentPayment': ' % ',
    'save customer': 'Validate customer',
    'change customer': 'Change customer',
    'change equipement': 'Change equipment',
    'save equipement': 'Validate equipment',
    'save main equipement': 'Validate main equipment',
    'choice boat': 'Choice a boat',
    'choose type equipment': 'Choice type equipment',
    'choice equipment': 'Choice one equipment',
    'create equipment': 'Adding a new equipment',
    'no condition' : 'No condition',
    'workPositionCoord': 'Lat / lng',
    'save intervention': 'Save intervention',
    'data no validate': 'Data no validate',
    'confirmCancel': 'Cancellation confirmation',
    'calStart': 'Start date',
    'calEnd': 'End date',
    'calActor': 'Technician',
    'billing pending': 'Billing pending',
    'tasksBox': 'Tasks',
    'removeTask': 'Remove task',
    'verifyStock': 'Verify stock',
    'verifyStockParts': 'Verification of stock parts',
    'verifyStockPartsDone': 'Verification stock parts: Completed',
    'verifyStockPartsFailed': 'Verification of stock items: Error',
// wks accounting
    'accOperation': 'Operation',
    'accLabel': 'Label',
    'accStatut': 'Statut',
    'accAmount': 'Amount',
    'paid': 'Paid',
    'unpaid': 'Unpaid',
    'customer outstanding': 'Customer outstanding',
    'outstanding committed': 'Committed',
    'authorized': 'Authorized',
    'current': 'Current',
    'remove row': 'Deletion',
    'save row': 'Submit',
    'error remove': 'Error on deletion',
    'validationError': 'Error on validation',
    'balance_inprogress': 'Balance in progress',
    'balance_failure': 'Failure to get balance',
    'paymentRef': 'payment reference',
    'payment': 'Payment',
    'adding row': 'Adding row',
    'editing row': 'Editing row',
    'delete row': 'Delete row',
    'detail row': 'row detail',
    'submit row': 'Submit row',
    'accNwsref': 'Internal reference',
    'workPosition': 'Work position',
// works assignments
    'Works assignments': 'Assignment jobs',
// google maps
    'Click the map to get Lat/Lng': 'Click the map to get Lat/Lng',
    'Search Box': 'Search box',
// appointments
    'wks_appointments': 'Appointments',
    'Appointments': 'Requests list',
    'client': 'Customer',
    'equipments': 'Equipments',
    'equipmentLocation': 'Location',
    'equipmentPosition': 'Position',
    'equipmentJobsite': 'Location job ',
    'askedComment': 'Comment',
    'appointmentStatut': 'Statut',
    'creationDate': 'Creation date',
    'lastUpdateDate': 'Last Update',
    'appointmentsOtherdata': 'Other data',
    'customer': 'Customer',
    'appointmentRef': 'Request Ref',
    'appointmentId': 'Request Id',
    'type': 'Type',
    'brand': 'Brand',
    'model': 'Model',
    'serialNumber': 'Serial number',
    'nbHoursOfUse': 'Hours of use',
    'equipmentNum': 'Equipment num',
    'askedService': 'Service',
    'askedServiceHours': 'Service hours',
    'companyName': 'Company name',
    'nbEquipments': 'Number equipments',
    'mailAddress': 'Mail',
    'appointment card': 'Request card',
    'appointment location': 'Location',
    'tracking': 'Tracking',
    'REGULAR_SERVICE': 'Regular service',
    'quotation_waiting': 'Waiting quotation',
    'quotation_sent': 'Quotation sent',
    'quotation_accepted': 'Quotation accepted',
    'quotation_paid': 'Quotation paid',
    'quotationJournal': 'Quotation journal',
    'invoicingJournal': 'Invoicing journal',
    'appointment_fixed': 'Appointment fixed',
    'work_assigned': 'Work assigned',
    'workAssigned': 'Work assigned',
    'appointmentFixed': 'Appointment fixed',
    'work_started': 'Work started',
    'work_done': 'Work done',
    'final_invoice': 'Final invoice',
    'final_payment': 'Final payment',
    'work_closed': 'Closed',
    'statut': 'Statut',
    'AllStatuts': 'All statuts',
    'selectedStatut': 'Selected statut',
    'addingStatut': 'Adding statut',
    'trackingStatut': 'Tracking statut',
    'appointmentDate': 'Appointment date',
    'appointmentTime': 'Appointment time',
    'workerName': 'Technician',
    'workStartedDate': 'Started date',
    'workStartedTime': 'Started time',
    'workDoneDate': 'Done date',
    'workDoneTime': 'Done time',
    'appointmentsSearch': 'search appointment',
    'noAppointments': 'No appointment',
    'New appointment': 'New appointment',
    'verif check': 'Verif check-list',
    'missingParts': 'Check',
    'checkListControl': 'Check list control',
    // appointments planning
    'Appointments planning': 'Appointments planning',
    'Pending requests': 'Pending requests',
    'phoneNum': 'Phone num',
// wks - calendar
    'eventDetail': 'Event detail',
    'eventTitle': 'Title',
    'eventType' : 'Type',
    'eventDescription': 'Description',
    'eventComment': 'Comment',
    'eventStart': 'Start',
    'eventEnd': 'end',
    'eventOutside': 'At',
    'appointmentCustomer': 'Appointment customer',
    'appointmentWork': 'Appointment works',
    'rhPlanning': 'HR Planning',
    'technician': 'Technician',
    'workshopJob': 'Workshop job',
    'restDay': 'Rest day',
    'vehicleMaintenance': 'Vehicle maintenance',
    'partSupply': 'Part supply',
    'appointmentTimeError': 'Appointment error',
    'appointmentStartTimeError': 'The start time slot has already been selected !!',
    'appointmentEndTimeError': 'The end time slot has already been selected !!',
    'appointmentChangeTime': 'Schedule change appointment',
    'appointmentDelete': 'Cancel appointment',
    'appointmentNewDate': 'New appointment',
    'scheduledFor': 'Scheduled for',
    'referencedRequest': 'Referenced request',
    'appointmentimetable': 'Timetable confirmation',
    'appointmentOldTime': 'Old time',
    'appointmentNewTime': 'New time',
    'appointmentSeparator': ' --> ',
    'detailedSheet': 'Detailed sheet',
    'RH': 'HR',
    'HR': 'HR',
    'detailAppointment': 'Detail',
    'editAppointment': 'Edit',
    'deleteAppointment': 'Cancel',
    'calendar_valid': '<div>Appointment validate. <b>Reference : %0</b></div>',
    'calendar_not_valid': '<div>Appointment not validate <b>Reference : %0</b></div>',
    'calendar_validation': 'Validation appointmnt in progress ...',
    // location
    'locationJobsite': 'Location',
    'locationAddress': 'Address',
    'locationWintering': 'Wintering',
    'locationAnother': 'Another anchorage',
    'homeAnchoragePort': 'Home port',
    'anchoragePort': 'Mooring',
    'winteringPort': 'Wintering',
    'anotherPort': 'Another anchorage',
    'harbor': 'Harbor',
    'pool': 'Pool',
    'pontoon': 'Pontoon',
    'ring': 'Ring',
    'berth': 'Location',
    'landPark': 'Land park',
    'marinaName': 'Marina',
// invoicing - quotation
    'invoiceRecap': 'Recap invoice',
    'quotationRecap': 'Recap quotation',
    'recapGen': 'General summary',
    'suppliesBT': 'Supplies BT',
    'laborBT': 'Labor BT',
    'totalBT': 'Total BT',
    'totalEquipBT': 'Total equipment BT',
    'suppliesTax': 'Supplies Tax',
    'laborTax': 'Labor Tax',
    'totalTax': 'Total tax',
    'suppliesWT': 'Supplies WT',
    'laborWT': 'Labor WT',
    'totalWT': 'Total WT',
    'totalGenBT': 'General total BT',
    'taxProfile': 'Taxes profile',
    'vat': 'VAT',
    'default': 'Default',
    'recap_supplies': 'Supplies',
    'recap_labor': 'Labor',
    'recap_total': 'Total',
    'recap_totalisation': 'Totalization',
    'totalOtherBT': 'Others elements HT',
    'otherRowLabel': 'Label',
    'otherRowAmount': 'Amount',
    'totalFinancialBT': 'Financial agreements',
    'rounding': 'Rounding',
    'discount': 'Discount',
    'discountCash': 'Cash',
    'surcharge': 'Surcharge',
    'rowLabelCplt': 'Label cplt',
    'rowBase': 'Rounding at',
    'rowPercent': 'Percentage',
    'invoice': 'Invoice',
    'creditnote': 'Credit note',
    'advance': 'Advance',
    'quotation': 'Quotation',
    'error invoicing': 'Error emitting invoice',
    'error advance': 'Error emitting advance',
    'error creditnote': 'Error emitting creditnote',
    'Ok to emit invoice': 'OK to issue the bill ?',
    'Ok to emit creditnote': 'OK to issue the creditnote ?',
    'Ok to emit advance': 'OK to issue the advance invoice ?',
    'Quotation emit': 'Quotation issued',
    'invoice emit': 'Bill issued',
    'credit note emit': 'Credit note issued',
    'advance emit': 'Advance issued',
    'quotation_inprogress': 'Quotation in progress ...',
    'invoicing_inprogress': 'Billing in progress ...',
    'creditnote_inprogress': 'Credit note in progress ...',
    'advance_inprogress': 'Advance emit in progress ...',
    'multiplePayments': 'Schedule',
    'update works': 'Update works',
    'process finished': 'Process finished',
    'creditnotes': 'Credit  notes',
    'advances': 'Advances',
    'emitDate': 'Emit date',
    'sendAdvance': 'Send',
    'sendCreditnote': 'Send',
    'comments': 'Comments',
    'totalCreditnoteWT': 'Credit note WT',
// common - EmailFormComponent
    'recipient': 'Recipient',
    'copy': 'Copy',
    'ccopy': 'Cached copie',
    'fromField': 'From',
    'subjectField': 'Subject',
    'bodyField': 'Body',
    'emailform': 'Email',
    'sendEmail': 'Send email',
// messages 
    'statutDetail': 'status detail',
    'messageDetail': 'Message detail',
    'sendBy': 'Send by',
    'recipientsMes': 'Recipients',
    'ccMes': 'Copy',
    'sendDate': 'Send date',
    'mediaMes': 'Media',
    'objectMes': 'Object',
    'bodyMes': 'Body',
    'metaData': 'Meta data',
};
export const MONTHS_EN_TRANS = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
};

export const DAYS_EN_TRANS = {
    su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat'
};

export const LOCAL_EN_FMT = {
    dateFormat: 'MM/DD/YYYY',
    timeFormat: 'MM/DD/YYYY HH:mm:ss',
    hourFormat: 'HH:mm:ss',
    timeFormatNgx: 'MM/dd/yyyy HH:mm:ss',
    markCurrentDay: true,
    showTodayBtn: true,
    todayBtnTxt: 'Today',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    showWeekNumbers: true,
    ageMin: 18,
    ageMax: 99,
};
export const AG_GRID_EN = {
    // for filter panel
    page: 'Page',
    more: 'More',
    to: 'to',
    of: 'of',
    next: 'Next',
    last: 'Last',
    first: 'First',
    previous: 'Previous',
    loadingOoo: 'Loading ...',

    // for set filter
    selectAll: 'Select allr',
    searchOoo: 'Search ...',
    blanks: 'Blank',

    // for number filter and text filter
    filterOoo: 'Filter ...',
    applyFilter: 'apply filter ...',
    equals: 'Egal',
    // notEquals: 'Non Egal',
    notEqual: 'Not Egal',
    // for number filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'Range',

    // for text filter
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Start with ',
    endsWith: 'End with',

    // filter conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // the header of the default group column
    group: 'Groupe',

    // tool panel
    columns: 'Columns',
    filters: 'Filters',
    rowGroupColumns: 'Row group columns',
    rowGroupColumnsEmptyMessage: 'Ruw group columns empty message',
    valueColumns: 'Value columns',
    pivotMode: 'Pivot mode',
    groups: 'groups',
    values: 'values',
    pivots: 'pivots',
    valueColumnsEmptyMessage: 'move here to aggregate',
    pivotColumnsEmptyMessage: 'move here to pivot',
    toolPanelButton: 'Tool panel button',

    // other
    noRowsToShow: 'No rows',

    // enterprise menu
    pinColumn: 'Pin column',
    valueAggregation: 'Aggregate value',
    autosizeThiscolumn: 'Auto size this column',
    autosizeAllColumns: 'Auto size all columns',
    groupBy: 'Group by',
    ungroupBy: 'Ungroup by',
    resetColumns: 'Reset columns',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    toolPanel: 'Tool panel',
    export: 'Export',
    csvExport: 'CSV export',
    excelExport: 'Excel export (.xlsx)',
    excelXmlExport: 'Excel export (.xml)',

    // enterprise menu pinning
    pinLeft: 'Pin left <<',
    pinRight: 'Pin right >>',
    noPin: 'No pin <>',

    // enterprise menu aggregation and status bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    average: 'Average',
    filteredRows: 'Filtered rows',
    selectedRows: 'Selected  rows',
    totalRows: 'Total rows',
    totalAndFilteredRows: 'Total and filtered rows',

    // standard menu
    copy: 'Copy',
    copyWithHeaders: 'Copy with headers',
    ctrlC: 'ctrl-C',
    paste: 'Paste',
    ctrlV: 'ctrl-V'
};
