import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, NgZone  } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from '../../../../../../../environments/environment';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { NotificationsService } from '../../../../../common/services/notifications.service';

import { AppointmentModel, ClientColumns, AppointmentColumns, EquipmentColumns, AppointmentDefaultCol } from '../../../../models/wks-appointments.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { InternationalsFormats, LabelValue } from '../../../../../../models/data.model';
import { WksEntityParams, Statuts } from '../../../../models/wks-common.model';
import { ColumnTableDef, Page, PagedData, NotifUsers } from '../../../../../../models/common.model';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { UserResponse } from '../../../../../../models/user.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

@Component({
  selector: 'mdi-appointments-table',
  templateUrl: './appointments-table.component.html',
  styleUrls: ['./appointments-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentsTableComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  @ViewChild('sidenav', {static: false}) mSidenav: any;
  @ViewChild('dateTemplate', {static: false}) dateTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatable', {static: false}) ngxDatatable: DatatableComponent;
  @ViewChild('inputSearch', {static: false}) inputSearch: any;

  wksEntityParams: WksEntityParams;
  statutsParams: Statuts[];
  statutsList: LabelValue[];
  notificationsSubscription: Subscription;
  inProgress: boolean;
  dataLoaded: boolean;
  sidenavOpen: boolean;
  displayCalendar: boolean;
  dataSource: any[];
  filteredRows: any[];
  appointmentsDisplayedColumns: ColumnTableDef[];
  wksEntity: WksEntityModel;
  appointmentsColumnsDef = AppointmentColumns;
  appointmentDefaultCol = AppointmentDefaultCol;
  cacheDatatemp: any[];
  selectedRowIndex: any;
  internationalsFormats: InternationalsFormats;
  currentFilters: string;
  dateFormat: string;
  dateTimeFormat: string;

  sidenavCollapsed = true;
  reorderable = true;
  pageSize = 12;
 
  selected: any[];

  page = new Page();
  rows = new Array<AppointmentModel>();
  rowCard: any;
  rowCardIdx: number;
  rawEvent: any;
  contextmenuRow: any;
  contextmenuColumn: any;
  statutCur: string;
  mechanicUsers: UserResponse[];
  
  constructor(
    private _dialog: MatDialog, 
    private _userService: UserService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _ressourceService: RessourcesService,
    private _notificationsService: NotificationsService,
    private _zone: NgZone,
    private snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder) {
      this.inProgress = true;
      this.translateLabels();
  }
  ngOnInit() {
    
    if (this._userService.getUserLogged() === undefined) {
      return;
    }
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    this.sidenavOpen = false;
    this.dataLoaded = false;
    this.displayCalendar = false;
    this.selected = [];
    this.statutsList = [];
    this.statutsParams = [];
    this.statutCur = 'quotation_waiting';
    this._translate.onLangChanged.subscribe(() => {
      this.translateLabels();
    });
    this.connectSnackBar();
    this.loadEntity().then(
      () => {

        this.getListMechanics(this._userService.getUserLogged().entity)
        .then (
          (responseMechanics: any ) => {
            this.mechanicUsers = responseMechanics;
          },
          (err: any) => {
            console.log('getListMechanics' + err.message);
            if (err.status === 404) {
            }
          }
        );
        this.getListAppointments(this._userService.getUserLogged().entity, this.wksEntity.entityTz).then(
          () => {
            this.settingFmts();
            this.setPage({ offset: 0 });
            this.inProgress = false;
            this.wksEntityParams = this._ressourceService.getWksEntityParams();
            this.buildStatutsList();
          },
          err => {
            console.log('getListAppointments' + err.message);
            this.inProgress = false;
            if (err.status === 404) {
            }
          });
      });
  }
  connectSnackBar(): void {

    this.notificationsSubscription = this._notificationsService.connectNotif('appointments')
    .pipe(takeUntil(this.onDestroy))
    .subscribe({
      next: notif => {
        this._zone.run(() => {
          const msgData: NotifUsers = JSON.parse(notif);
          // console.log(msgData.recipients, ' ' + msgData.contentMessage);
          if (msgData.recipients === this._userService.getUserLogged().entity) {
            const appointmentCur: AppointmentModel = JSON.parse(msgData.contentMessage);
            if (this.statutCur === 'quotation_waiting') {
              // this.rows.unshift(appointmentCur);
              this.rows.splice(0, 0, appointmentCur);
              this.rows = [...this.rows];
              setTimeout(() => {
                this.ngxDatatable.element.click();
               }, 500);
            }
            const messageCur = this._translate.getTranslate('New appointment') + ' : ' + appointmentCur.appointmentRef;
            this.openSnackBar(messageCur, 'No-Action');
          }
        });
      }
    });

}
  buildStatutsList(): void {
   
    this.statutsList = [];
    this.statutsParams = JSON.parse(JSON.stringify(this.wksEntityParams.statuts));
    this.statutsParams.sort((obj1: Statuts, obj2: Statuts) => {
      return obj1.ranking > obj2.ranking ? 1 : -1;
    });

    const allStatuts =  {
      value: 'AllStatuts',
      label: this._translate.instant('AllStatuts')
    };
    
    for (const statutCur of this.statutsParams ) {
      this.statutsList.push({
        value: statutCur.name,
        label: this._translate.instant(statutCur.name)
      });
    }
    this.statutsList = [allStatuts, ...this.statutsList];
   
  }
  onChangeStatut(_value: any): void {
    // console.log('onChangeStatut : ' + _value);
    // this.statutForm.controls.trackingStatut.setValue(_value);
    this.statutCur = _value;
    this.refresh();
  }
  translateLabels() {

    if ( this.appointmentDefaultCol === undefined) {
      return;
    }
    const nbCol = this.appointmentDefaultCol.length;

    this.appointmentsDisplayedColumns = [];
    for (const colNameCur of this.appointmentDefaultCol) {
      for (const colDef of this.appointmentsColumnsDef) {
        if (colDef.colName === colNameCur) {
          this.appointmentsDisplayedColumns.push({
            prop: colDef.colName,
            name: this._translate.getTranslate(colDef.colName),
            width: colDef.width ? colDef.width : 200,
            isDate: (colDef.colType === 'date' ? true : false),
            isNumber: (colDef.colType === 'number' ? true : false),
            isCurrency: (colDef.colType === 'number' ? true : false),
            cellTemplate: (colDef.colType === 'date' ? this.dateTemplate : undefined) 
          });

          break;
        } // end test colName
      } // end for appointmentsColumnsDef
    } // end  for appointmentDefaultCol
  }
  onSort(event: any) {
    const sortCur = event.sorts[0];
    // console.log(sortCur.dir + '  ' + sortCur.prop);
    let reverse = 1;
    if (sortCur.dir === 'desc')  {
        reverse = -1;
    }
    let isString = false;
    for (const colDef of this.appointmentsColumnsDef) {
      if (colDef.colName === sortCur.prop) {
        if (colDef.colType === 'string') {
          isString = true;
        }
        break;
      } // end test colName
    } // end for appointmentsColumnsDef
    const arrayCur = [...this.cacheDatatemp];
    const newArray = this.sortByKey(arrayCur, sortCur.prop, reverse, isString);
    // console.log(newArray);
    this.filteredRows = [...newArray];
    this.setPage({ offset: 0 });
  }
  sortByKey(array: any[], key: string, reverse: number, isString: boolean) {
    return array.sort(function(a, b) {
        let x = a[key]; 
        let y = b[key];
        if (isString) {
          x = x.toUpperCase();
          y = y.toUpperCase();
        }
        // return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        let compareValue = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        compareValue = compareValue * reverse;
        return compareValue;
    });
  }
  // pager : https://stackblitz.com/edit/angular-ngx-datatable-custom-paginator-ltqvk8?file=app%2Fpager.component.ts
  /*
  https://github.com/swimlane/ngx-datatable/blob/master/src/app/basic/contextmenu.component.ts
    <p *ngIf="rawEvent">
      <strong>Mouse position:</strong> <code>(x: {{ rawEvent?.x }}, y: {{ rawEvent?.y }})</code>
    </p>
    <p *ngIf="contextmenuRow"><strong>Row:</strong> {{ contextmenuRow?.name }}</p>
    <p *ngIf="contextmenuColumn">
      <strong>Header:</strong> name: {{ contextmenuColumn?.name }} prop: {{ contextmenuColumn?.prop }}
    </p>
  */
  clearfilter()  {
      this.inputSearch.nativeElement.value = '';
      this.onFilterChanged('') ;
  }
  onFilterChanged(inputSearch: string)  {
    // const arrayCur = [...this.cacheDatatemp];
    let newArray = [...this.cacheDatatemp];
    if (inputSearch !== '') {
      const listSearch = inputSearch.split('+');
      for (const searchCur of  listSearch) {
        let searchString  = searchCur;
        let isPresent = true;
        if (searchCur.startsWith('!')) {
          searchString = searchCur.substring(1);
          isPresent = false;
        }
        newArray = CommonMethods.filterArray(newArray, searchString, isPresent);
      }
    }
    // console.log(newArray);
    this.filteredRows = [...newArray];
    this.setPage({ offset: 0 });
  }
  onTableContextMenu(contextMenuEvent: any) {
    // console.log(contextMenuEvent);

    this.rawEvent = contextMenuEvent.event;
    if (contextMenuEvent.type === 'body') {
      this.contextmenuRow = contextMenuEvent.content;
      this.contextmenuColumn = undefined;
      // console.log(this.contextmenuRow);
    } else {
      this.contextmenuColumn = contextMenuEvent.content;
      this.contextmenuRow = undefined;
      // console.log(this.contextmenuColumn);
    }

    contextMenuEvent.event.preventDefault();
    contextMenuEvent.event.stopPropagation();
  }
  settingFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourceService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
      }
    }
  }
  getListMechanics(entityCur: string): any {
  
    return new Promise((resolve, reject) => {
      this._userService.getEntityUsersList(entityCur, 'MECHANIC')
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadParams' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
   
  loadEntity() {
    
    return  new Promise<void>((resolve, reject) => {
       /* this._wksCentralService.getWksEntity(this._userService.getUserLogged().entity)
        .subscribe(
          data => {
            this.wksEntity = data.body;
            resolve();
          },
          () => {
          reject();
          }
        );
      }*/
      this.wksEntity = this._wksCentralService.getWksEntityCur();
      resolve();
    });
   
  }
  getListAppointments(_entity: string, _timeZone: string) {
    this.dataLoaded = false;
    let curentStatut = this.statutCur;
    if (this.statutCur === 'AllStatuts') {
      curentStatut = undefined;
    }
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.getWksAppointments(_entity, curentStatut, _timeZone)
      .subscribe(
        data => {
          this.dataSource = data.body;
          this.filteredRows = data.body;
          this.dataLoaded = true;
          // this.dataSource.data.sort((a: EntityModel, b: EntityModel) => a.entity - b.entity);
          this.cacheDatatemp = [...data.body];
          resolve();
        },
        err => {
          console.log('getListAppointments' + err.message);
          this.dataLoaded = false;
          this.inProgress = false;
          if (err.status === 404) {
          }
          this.errorMessage('nodata', this.statutCur);
          reject(err);
        });
      }
    );
  }
  setPagerFooter(pageInfo: any) {
    this.page.pageNumber = pageInfo.page - 1;
    this.getPagedData(this.page)
    .then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }
  /**
   * https://github.com/swimlane/ngx-datatable/blob/master/src/app/paging/paging-server.component.ts
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
   setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset;
    this.getPagedData(this.page)
    .then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }
  /**
   * based on : https://github.com/swimlane/ngx-datatable/blob/master/src/app/paging/mock-server-results-service.ts
  * Package companyData into a PagedData object based on the selected Page
  * @param page The page data used to get the selected data from companyData
  * @returns {PagedData<CorporateEmployee>} An array of the selected data and page
  */
  private getPagedData(page: Page) {
    return  new Promise<PagedData<any>>((resolve, reject) => {
      const pagedData = new PagedData<any>();
      page.totalElements = this.filteredRows.length;
      page.totalPages = page.totalElements / page.size;
      const start = page.pageNumber * page.size;
      const end = Math.min(start + page.size, page.totalElements);
      for (let i = start; i < end; i++) {
        const recordCur = this.filteredRows[i];
        pagedData.data.push(recordCur);
      }
      pagedData.page = page;
      resolve(pagedData);
      // return pagedData;
    });
  }
  calendar() {
    this.displayCalendar = true;
  }
  refresh() {
    this.inProgress = true;
    this.dataLoaded = false;
    this.dataSource =  [];
    this.filteredRows =  [];
    this.dataLoaded = true;
    // this.dataSource.data.sort((a: EntityModel, b: EntityModel) => a.entity - b.entity);
    this.cacheDatatemp = [];
    this.getListAppointments(this._userService.getUserLogged().entity, this.wksEntity.entityTz).then(
      () => {
        
        this.getPagedData(this.page)
        .then((pagedData) => {
          this.page = pagedData.page;
          this.rows = pagedData.data;
          this.inProgress = false;
          this.dataLoaded = true;
        });

      },
      (err) => {
        if (err.status === 404) {
          this.inProgress = false;
        }
        this.inProgress = false;
        this.dataLoaded = false;
      });
  }
  toggleSidenav(_arg: string) {
    if (_arg === 'backdrop') {
      return;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.mSidenav.close();
      this.sidenavOpen = false;
    } else {
      this.mSidenav.open();
      this.sidenavOpen = true;
    }
  }
  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }
  editAppointment(row: any) {
    this.rowCardIdx = this.getRowIndex(row);
    this.rowCard = row;
    this.toggleSidenav('');

  }
  saveAppointment($event: any)  {
    if ($event.statut === 'cancel') {
      this.toggleSidenav('');
      return;
    }
    if ($event.statut === 'register') {
      this.rows[this.rowCardIdx] = $event.rowCard;
      this.rows = [...this.rows];
      this.registerAppointment($event.rowCard);
      setTimeout(() => {
        this.ngxDatatable.element.click();
       }, 500);
      return;
    }
  }
  getRowIndex(row: any): number {
    return this.ngxDatatable.bodyComponent.getRowIndex(row);   // row being data object passed into the template
  }
  doAction(row: any): boolean {
    if (row.master) {
      return false;
    } else {
      return true;
    }
  }
  registerAppointment(appointmentItem: AppointmentModel) {
    // problem compatibility timestamp between datasources (appointment application / nautiko).
    let dateTimeCur = CommonMethods.dateMomentForBdd(moment(appointmentItem.creationDate), undefined);
    appointmentItem.creationDate = new Date(dateTimeCur);
    dateTimeCur = CommonMethods.dateMomentForBdd(moment(appointmentItem.lastUpdateDate), undefined);
    appointmentItem.lastUpdateDate = new Date(dateTimeCur);

    this._wksCentralService.saveWksAppointment(appointmentItem, undefined, undefined)
    .subscribe(
      () => {
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
      },
      (err) => {
        console.log('registerAppointment ' + appointmentItem + ' KO ' + err );
      }
    );
   }
  // https://material.angular.io/components/snack-bar/overview
  // evolution : http://www.eduforbetterment.com/snackbar-model-in-angualr/
  // 2021-05 : https://stackblitz.com/edit/angular-material-snackbar-example
  // 2021-05 : https://stackblitz.com/edit/angular-snackbar
  openSnackBar(message: string, action: string) {
    let actionCur = action;
    if (action === 'No-Action') {
      actionCur = '';
    }
    this.snackBar.open(message, actionCur, {
      duration: 7000,
      panelClass: ['custom-style']
    });

  }
  errorMessage(option: string, statut: string): void {
    const titleBox = this._translate.getTranslate('appointmentsSearch');
    let messageBox: string;
    if (option === 'nodata') {
      messageBox = this._translate.getTranslate('statut') + ' : ' +  
                    this._translate.getTranslate(statut) + ' --> ' + 
                    this._translate.getTranslate('noAppointments') ;
    }
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'nodata');
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if ((data === 'okAction') ) {

        }
        if ((_actionCur === 'nodata')) {
          this.router.navigate(['/jobHome']);
        }
      });

  }
}
