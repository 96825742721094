import { Component, OnInit, SimpleChanges, Input, OnChanges, EventEmitter, Injectable, Inject } from '@angular/core';

import { Subscription, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

import { WksBusinessModel } from '../../../../models/wks-business.model';
import { EquipmentMetadata } from './equipement-metadata';
import { WksCentralService } from '../../../../services/wks-central.service';

@Component({
  selector: 'mdi-equipment-metadata',
  templateUrl: './equipment-metadata.component.html',
  styleUrls: ['./equipment-metadata.component.css']
})
export class EquipmentMetadataComponent implements OnInit, OnChanges {

  @Input() dataIn: any;
  private readonly onDestroy = new Subject<void>();

  alreadyPass: boolean;

  constructor(@Inject(EquipmentMetadata) private equipMetadata: EquipmentMetadata,     
              private _wksCentralService: WksCentralService) { 
    this.dataIn = this.dataIn || this.equipMetadata;
    // console.log(this.dataIn.ownerName);
 
    this.alreadyPass = false;
  }

  ngOnInit() {
    if (this.alreadyPass) {
      return;
    }
    this.alreadyPass = true;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.dataIn = this.dataIn || this.equipMetadata;
    if (this.dataIn === undefined) {
      return;
    }
    // console.log(this.dataIn.name);
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {

          case 'dataIn': {
            this.dataIn = changes['dataIn'].currentValue;
            break;
          }

        } // end switch
      } // end if
    } // end loop
  }
}
