import { Component, OnInit } from '@angular/core';

import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../../services/translate.service';

@Component({
  selector: 'mdi-criticality-renderer',
  templateUrl: './criticality-renderer.component.html',
  styleUrls: ['./criticality-renderer.component.css']
})
export class CriticalityRendererComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  currentValue: any;

  criticalAlert: boolean;
  constructor(private _translate: TranslateService) { }

  ngOnInit() {
  }
  
  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.criticalAlert = false;
    this.params = params;
    this.currentValue = params.value;
    if (this.params.data.workCriticality === 'immobilized') {
      this.criticalAlert = true;
    }
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
}
