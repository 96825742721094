import { Component, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from '../../../../../../../environments/environment';
import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../../job/common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksEquipmentModel, equipment_sch, EquipEvent, ParentParam } from '../../../../models/wks-equipment.model';
import { DataWorkModel } from '../../../../models/wks-works.model';
import { IhmOptions } from '../../../../models/wks-param.model';

@Component({
  selector: 'mdi-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentTableComponent implements OnInit {

  optionItem: string;
  isTrailers: boolean;
  isEngines: boolean;
  isJets: boolean;
  isBoats: boolean;
  sidenavCollapsed: boolean;

  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  dataSource = new MatTableDataSource();
  randomIdString: string;
  private readonly onDestroy = new Subject<void>();
// Modeal
  @ViewChild('modalWorks', {read: TemplateRef, static: false}) modalWorks: TemplateRef<any>;

  modalRef: BsModalRef;
  configModal = {
    class: 'modal-works',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    
  };
  valueWidth = false;

  dataIn: DataWorkModel;
// end modal
  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = equipment_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterEquip: boolean;
  doCreateEquip: boolean;
  doCreateWork: boolean;
  doUpdateEquip = [];
  wksEquipmentToUpdate: WksEquipmentModel;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;
  displayType: string;
  actionType: string;
  parentParam: ParentParam;
  equipmentList: WksEquipmentModel[];
  isInjected: boolean;
  lateralSize: string;

  ihmOptions: IhmOptions;
  hasBackdrop: boolean;

  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private modalService: BsModalService, // modal popup
    private _dialog: MatDialog, // erreurs
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    const labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.sidenavCollapsed = true;
    this.hasBackdrop = true;
    this.lateralSize = 'lateral-sidenav-50';
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });

    if (this.displayType === null) {
      this.displayType = 'list';
    }
    this.ihmOptions = {
      optionName: 'list',
      display: true,
      readOnly: false,
    };
    if (labelItem === 'boats') {

      this.lateralSize = 'lateral-sidenav-80';
    } 
    this.isBoats = false;
    this.isJets = false;

    this.isTrailers = false;
    this.isEngines = false;

    switch (labelItem) {
      case 'boats': {
        this.isBoats = true;
          break;
      }
      case 'jets': {
          this.isJets = true;
          break;
      }
      case 'trailers': {
          this.isTrailers = true;
          break;
      }
      case 'engines': {
        this.isEngines = true;
        break;
      }
    }
    
    this.optionItem = labelItem;
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.subscribesAll();
    
    if (this.displayType === 'list') {
      this.buildList();
    }
  }
  subscribesAll() {
    this._wksCentralService.onSearchList
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: EquipEvent) => {
      this.equipmentList = event.searchResult;
      this.wksEquipmentToUpdate = event.searchCriteria;
      this.isInjected = true;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'closeWorkModal') {
        this.closeModal();
      } else if (event) {
        const typeEvent = typeof event;
        // const eventString = JSON.stringify(event).toString();
        const eventString = String(event);
        console.log(typeEvent);
      }
    });
  }
  buildList() {
    this.displayedColumns = [
      
      { field: 'equipBrand', headerName: this._translate.instant('equipBrand'), sortable: true, filter: true, resizable: true },
      { field: 'equipModel', headerName: this._translate.instant('equipModel'), sortable: true, filter: true, resizable: true },
      { field: 'equipSN', headerName: this._translate.instant('equipSN'), sortable: true, filter: true, resizable: true },
      /*{ field: 'stdCreation', headerName: this._translate.instant('stdCreation'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: 'stdLastupdate', headerName: this._translate.instant('stdLastupdate'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
      /*{ field: 'stdDisable', headerName: this._translate.instant('stdDisable'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(),
      },
    ];
    if ( this.isBoats) {
      this.displayedColumns.splice( 0, 0, 
          { field: 'equipImmat', headerName: this._translate.instant('equipImmat'), sortable: true, filter: true, resizable: true });
      this.displayedColumns.splice( 0, 0, 
          { field: 'equipName', headerName: this._translate.instant('equipName'), sortable: true, filter: true, resizable: true });
          
    }
    if ( this.isTrailers) {
      this.displayedColumns.splice( 0, 0, 
        { field: 'equipDesign', headerName: this._translate.instant('equipDesign'), sortable: true, filter: true, resizable: true, enableRowGroup: false });
    }
    this.displayedColumns.splice( 0, 0, 
      { field: 'ownerName', headerName: this._translate.instant('ownerName'), sortable: true, filter: true, resizable: true, enableRowGroup: false });
    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }

    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getWksEquipmentList();

    this._wksCentralService.onRegisterValid
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if (event === 'cancelRegister') {
          this.toggleSidenav('');

        }
        if (event === 'closeRegister') {
          this.toggleSidenav('');
          this.getWksEquipmentList();
        }
        if (event === 'deleteEquip') {
          this.getWksEquipmentList();
        }
      });
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  refresh() {
    this.getWksEquipmentList();
  }
  newsearch() {
    this.displayType = 'newsearch';

    this._wksCentralService.onSearchList
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: EquipEvent) => {
      this.equipmentList = event.searchResult;
      this.wksEquipmentToUpdate = event.searchCriteria;
      this.isInjected = true;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
  }
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
   
    const workIcon = environment.workIcon.path  + environment.workIcon.name + '-light' + '.' + environment.workIcon.type;

    actions = '<button type="button" class="btn btn-default mat-icon-button" data-action-type="work"  data-toggle="tooltip" data-placement="left" title="' +
              this._translate.instant('file-text-o tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
              '<span data-action-type="work" class="fa fa-file-text-o" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
                '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' ;
   
    return actions;
  }
  getWksEquipmentList() {
    this.selected = [];

    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this.isInjected) {
      this.dataSource.data = this.equipmentList;
      this.cacheDatatemp = [...this.equipmentList];
      this.extractColumnsName(this.dataSource.data);
      this.loadingSubject.next(false);
      this.loadingIndicator = false;
      return;
    }
    this._wksCentralService.getWksEquipmentList(this._userService.getUserLogged().entity, this.optionItem)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        if (err.status === 404) {
          let titleBox: string;
          let messageBox: string;
          switch (this.optionItem) {
            case 'boats': {
                titleBox = 'searching boats';
                messageBox = 'no boat found';
                break;
            }
            case 'jets': {
              titleBox = 'searching jets';
              messageBox = 'no jet found';
              break;
            }

            case 'trailers': {
              titleBox = 'searching trailers';
              messageBox = 'no trailer found';
              break;
            }
            case 'engines': {
              titleBox = 'searching engines';
              messageBox = 'no engine found';
              break;
            }
          }

          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeEquipment')) {
          this.deleteEquipmentOK(dataCur);
        }
      });

  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // Page size
    // this.gridApi.paginationSetPageSize(Number(this.nbRows));
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editEquipment(data);
                break;
            }
            case 'trash': {
                this.deleteEquipment(data);
                break;
            }
            case 'work': {
              this.openModalWork(data);
              break;
          }
        }
    }
}
  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateEquip = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef, this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  toggleSidenav(_arg: string) {

    if (_arg === 'backdrop') {
      return;
    }

    this.isRegisterEquip = false;
    this.doCreateWork = false;
    if (this.displayType === 'newsearch') {
      return;
    }

    if (_arg === 'registerEquipment') {
      this.isRegisterEquip = true;
      this.randomIdString =  CommonMethods.randomString(5) + ' ' + Math.floor(Math.random() * 10000).toString();
    }
    if (_arg === 'work') {
      this.doCreateWork = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.m_sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.m_sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  editEquipmentAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.wksEquipmentToUpdate = selectedRow;
    

    this.actionType = 'update';

    this.doCreateEquip = false;
    this.toggleSidenav('registerEquipment');
  }
  editEquipment(row: WksEquipmentModel) {
    this.wksEquipmentToUpdate = row;

    this.parentParam = {
      equipPartOf: undefined,
      ownerId: this.wksEquipmentToUpdate.ownerId,
      equipType: this.optionItem,
    };

    this.actionType = 'update';

    this.doCreateEquip = false;
    this.toggleSidenav('registerEquipment');
  }
  addEquipment() {
  
    this.doCreateEquip = true;
    this.parentParam = {
      equipPartOf: undefined,
      ownerId: undefined,
      equipType: this.optionItem,
    };

    this.actionType = 'add';

    this.toggleSidenav('registerEquipment');
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteEquipment(row: WksEquipmentModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;

    switch (this.optionItem) {
      case 'boats': {
        titleBox = 'deleting boats';
        break;
      }
      case 'jets': {
        titleBox = 'deleting jets';
        break;
      }

      case 'trailers': {
        titleBox = 'deleting trailers';
        break;
      }
      case 'engines': {
        titleBox = 'deleting engines';
        break;
      }
    }

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.equipDesign;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeEquip', row );

  }
  deleteEquipmentOK(row: WksEquipmentModel) {

      this._wksCentralService.saveWksEquipment(row, 'deleteEquipment')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteEquipment');
        },
        () => {

        }
      );
    
  }
// Modal
  openModalWork(_dataIn: any) {

    this.dataIn = {
      work: undefined,
      iRow: -1,
      newWork :  {
        ownerId: _dataIn.ownerId,
        equipments:  {
          mainEquipment: _dataIn,
        }
      }
    };
    // this.openModal(this.modalWorks);    
    this.toggleSidenav('work');
  }
  openModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, this.configModal);
  }
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }

  }
  setModalClass() {
    this.valueWidth = !this.valueWidth;
    const modalWidth = this.valueWidth ? 'modal-lg' : 'modal-sm';
    this.modalRef.setClass(modalWidth);
  }
}
