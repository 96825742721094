import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import {LicenseManager} from "ag-grid-enterprise";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

// LicenseManager.setLicenseKey('Evaluation_License-_Not_For_Production_Valid_Until_2_September_2019__MTU2NzM3ODgwMDAwMA==5fa88976eef6a791f6e3592a88a9d24f');

if (environment.production) {
  enableProdMode();
}
/*
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
*/
// https://stackblitz.com/edit/angular-reactive-animation

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherise, log the boot error
}).catch(err => console.error(err));