import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../../../../../../services/user.service';
import { RessourcesService } from '../../../../../../../job/common/services/ressources.service';
import { TranslateService } from '../../../../../../../services/translate.service';
import { WksCentralService } from '../../../../../services/wks-central.service';
import { GlobalEventsManagerService } from '../../../../../../../job/common/services/global-events-manager.service';
import { WksWorksService } from '../../../../../services/wks-works.service';
import { WksEntityModel } from '../../../../../models/wks-entity.model';
import { WksEquipmentModel} from '../../../../../models/wks-equipment.model';
import { WksWorksModel, WorkEquipmentRef, WorkEquipments } from '../../../../../models/wks-works.model';
import { TasksActionsModel, IhmOptions, TasksActionCompletedModel, TaskrefJsonModel  } from '../../../../../models/wks-param.model';

export interface CheckEquipment {
  equipDesign: string;
  equipId: string;
  equipSN: string;
  equipHours: number;
  equipFailed: boolean;
  missingParts: boolean;
  isCollapsed: boolean;
  // tasksCompleted: TasksActionCompletedModel[];
  tasksList: {
    taskrefId: string;
    taskrefLabel: string;
    missingParts: boolean;
  }[];
}

@Component({
  selector: 'mdi-verification-ckecklist',
  templateUrl: './verification-ckecklist.component.html',
  styleUrls: ['./verification-ckecklist.component.css']
})
export class VerificationCkecklistComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  modalTitle: string;
  workId: string;
  workCur: WksWorksModel;
  ihmOptions: IhmOptions;

  checkEquipmentsIsLoaded: boolean;
  itemChoose: string;
  nameParent: string;
  wksEntity: WksEntityModel;

  checkEquipments: WksEquipmentModel [];
  checkEquipmentsList: CheckEquipment[];

  isReadOnly: boolean;
  isCheckLoaded: boolean;
  isOkToValid: boolean;
  itemEquip: WksEquipmentModel;
  itemTasks: TasksActionCompletedModel;
  tasksActionsCompleted: TasksActionCompletedModel[];
  tasksActionsArgs: TasksActionCompletedModel[];
  equipLabels: string[];
  workEquipments: WorkEquipments;
  workEquipmentId: WorkEquipmentRef;
  checkEquipmentExpand: CheckEquipment;
  isFinished: boolean;

  constructor(private dialogRef: MatDialogRef<VerificationCkecklistComponent>,
              private _userService: UserService, 
              private translateService: TranslateService,
              private _wksCentralService: WksCentralService,
              private _globalEventsManagerService: GlobalEventsManagerService,
              private _wksWorksService: WksWorksService,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.modalTitle = data.title;
                this.workId = data.workId;
                this.ihmOptions = data.ihmOptions;
  }
  ngOnInit(): void {
    this.initBooleans();
    this.initAllParam();
    this.buildForm();
    this.loadWork()
    .then (() => {
     
    }, () => {

    }); // loadWork
   

  }
  initAllParam() {
    this.getIhmOptions();
  }
  initAllSubscribe() {
    
  }
  getIhmOptions(): void {
    this.ihmOptions.collapse = false;
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
  }
  loadWork()  {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    return new Promise<void>((resolve, reject) => {
      this._wksCentralService.getWorksById(this.wksEntity.stdEntity,  this._userService.getUserLogged().userlang, this.workId)
      .subscribe(
        data => {
          this.workCur = data.body as WksWorksModel;
          this.workEquipments = {
            mainEquipment: undefined,
            subEquipments: undefined
          };
          this.workEquipmentId = JSON.parse(this.workCur.workEquip);
          this.loadMainEquipment(this.workEquipmentId.mainEquipment)
          .then ((response: WksEquipmentModel) => {
            this.workEquipments.mainEquipment = response;
            if (this.workEquipmentId.subEquipments) {
              this.workEquipments.subEquipments = [];
              this.loadsubEquipments()
              .then ((responseCur: WksEquipmentModel[]) => {
                // responseCur.isWorkEquip = true;
                this.workEquipments.subEquipments = responseCur;
                for (const itemEquipment of this.workEquipments.subEquipments) {
                  itemEquipment.isWorkEquip = true;
                }
                this.initCheck();
              })
              .catch ((error: number) => {
                console.log('loadSubEquipment ' + error);
                return;
              });
            } else {
              this.initCheck();
            }
            
            resolve();
          })
          .catch ((error: number) => {
            console.log('loadEquipment ' + error);

            return;
          });
     
        },
        err =>  {
          console.log('loadWork ' + err);
          reject(err);
        }
      );
    });
  }
  
  loadsubEquipments() {

    if (this._userService.getUserLogged() === undefined ) { return; }
    let equipList = '';
    for (const equipCur of this.workEquipmentId.subEquipments) {
      if (equipList === '') {
        equipList = equipCur;
      } else {
        equipList = equipList + ';' + equipCur;
      }
    }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksSubEquipment(this.workCur.stdEntity, equipList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  loadMainEquipment(equipId: string) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentByEquipId(this.workCur.stdEntity, equipId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }

  initCheck(): void {
    this.checkEquipments = [];
    if (this.workEquipments.subEquipments) {
      this.checkEquipments = this.workEquipments.subEquipments;
    } else {
      this.checkEquipments.push(this.workEquipments.mainEquipment);
    }
    this.fillCheckTasks();
    this.itemEquip = this.checkEquipments[0];
    this.tasksActionsArgs = [];
    for (const itemTasks of this.tasksActionsCompleted) {
      if (itemTasks.equipId === this.itemEquip.id) {
        this.tasksActionsArgs.push(itemTasks);
      }
    }
    this.checkEquipments[0].isSelected = true;
    this.checkEquipmentsIsLoaded = true;
  }
  fillCheckTasks(): void {

    let worksActionsSelected: TasksActionsModel[] = [];
    let suppliesActionsSelected: TasksActionsModel[] = [];

    this.tasksActionsCompleted = [];
    let workEquipmentId: WorkEquipmentRef;
    let numberEquip = 0;
    workEquipmentId = JSON.parse(this.workCur.workEquip);
    if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
      numberEquip = 1;
    } else {
      numberEquip = workEquipmentId.subEquipments.length;
    }
    this.checkEquipmentsList = [];
    for (const taskCur of this.workCur.workTasksContent) {
      worksActionsSelected = [];
      suppliesActionsSelected = [];

      const taskrefJson = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskrefJson.tasksActionCompleted !== undefined) {
        // this.tasksActionsCompleted = [...taskrefJson.tasksActionCompleted as TasksActionCompletedModel[]];
        for (const taskTmpCur of taskrefJson.tasksActionCompleted as TasksActionCompletedModel[] ) {
          if (taskTmpCur.equipId ===  this.workEquipments.mainEquipment.id) {
            taskTmpCur.equipSN = this.workEquipments.mainEquipment.equipSN;
          } else {
            if (this.workEquipments.subEquipments) {
              for (const equipCur of this.workEquipments.subEquipments) {
                if (taskTmpCur.equipId ===  equipCur.id) {
                  taskTmpCur.equipSN = equipCur.equipSN;
                  break;
                }
              }
            }
          }
          this.tasksActionsCompleted.push(taskTmpCur);
        }
      }
    }
    for (const equipItem of this.checkEquipments) {
      const equipmentItem: CheckEquipment = {
        equipDesign: (equipItem.equipName ? equipItem.equipName : equipItem.equipDesign),
        equipId: equipItem.id,
        equipSN: equipItem.equipSN,
        equipHours: (equipItem.equipHours ? equipItem.equipHours : 0),
        equipFailed: false,
        missingParts: false,
        tasksList: [],
        isCollapsed: true,
      };

      let missingPartsEquipCur = false;
      if (this.tasksActionsCompleted.length === 0) {
        equipmentItem.missingParts = true;
        missingPartsEquipCur = true;
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      } else {
        for (const taskItem of this.tasksActionsCompleted) {
          if (taskItem.equipId ===  equipItem.id) {
            if (taskItem.equipHours > 0 ) {
              equipmentItem.equipHours = taskItem.equipHours;
            }
            let missingPartsCur = false;
            if (taskItem.additionalTasks === undefined) {
              for (const suppliescur of taskItem.supplies) {
                if (suppliescur.actionTaken === undefined || suppliescur.actionTaken === null) {
                  missingPartsCur = true;
                  missingPartsEquipCur = true;
                  this.isOkToValid = false;
                  equipmentItem.equipFailed = true;
                  break;
                }
              }
            }
            const taskCur =  {
              taskrefId: taskItem.taskrefId,
              taskrefLabel: taskItem.taskrefLabel,
              missingParts: missingPartsCur
            };
            if (taskItem.equipHours > 0 ) {
              equipmentItem.equipHours = taskItem.equipHours;
            }
            equipmentItem.missingParts = missingPartsEquipCur;
            equipmentItem.tasksList.push(taskCur);
          }
        }
      }
      if (equipmentItem.equipSN === '' || equipmentItem.equipSN === undefined  || equipmentItem.equipSN === null)  {
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      }
      if (equipmentItem.equipHours < 1 || equipmentItem.equipHours === undefined  || equipmentItem.equipHours === null)  {
        this.isOkToValid = false;
        equipmentItem.equipFailed = true;
      }
      equipmentItem.isCollapsed = true;
      this.checkEquipmentsList.push(equipmentItem);
    }
    this.isCheckLoaded = true;
    this.checkEquipmentExpand = this.checkEquipmentsList[0];

  }

  initBooleans(): void {
    this.checkEquipmentsIsLoaded = false;
    this.isReadOnly = true;
    this.isCheckLoaded = false;
    this.isOkToValid = true;
    this.isFinished = false;
  }
  closeModal(): void {
    let response: string;
    if (this.isFinished) {
      response = 'finished';
    }
    this.dialogRef.close(response);
  }
  validFinished(): void {
    this.lockWork()
    .then (() => {
      this.dialogRef.close('finished');
      this._globalEventsManagerService.synchroEvents.next('workfinished');
    }, (status: any) => {
    }); // lockWork
  }
  lockWork(): any {

    this.fillModel();

    return new Promise((resolve, reject) => {
      this._wksCentralService.lockWork(this.workCur, this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang).subscribe(result => {
        const reponseCur = result.body;
        resolve(reponseCur);
      },
      err => {
        console.log(err);
        reject(err.status);
      });
    });
  
  }
  fillModel(): void {
    this.workCur.statutChange = true;
    this.workCur.workStatut = 'finished';
    /*
    let wksWorksTracking: WksWorksTracking[] = [];

    if (this.workCur.workJsonData) {
      const workJsonData = JSON.parse(this.workCur.workJsonData);
      if (workJsonData.wksWorksTracking) {
        wksWorksTracking = JSON.parse(JSON.stringify(workJsonData.wksWorksTracking));
      }
    }

    wksWorksTracking.push({
      numberTrack: (wksWorksTracking.length === 0) ? 0 : wksWorksTracking.length,
      userLogged: this._userService.getUserLogged().username,
      recordedTrack: undefined,
      workStatut: this.workCur.workStatut,
      workComment: '',
      alarmsMes: undefined
      });
    this.workCur.wksWorksTracking =  wksWorksTracking;
    */
    this.workCur = this._wksWorksService.fillTracking(this.workCur, this._userService.getUserLogged().username, this.workCur.workStatut, '');
  }
  buildForm(): void {
    this.equipLabels = [];
    this.equipLabels[0] = this.translateService.instant('equipDesign');
    this.equipLabels[1] = this.translateService.instant('equipHours');
    this.equipLabels[2] = this.translateService.instant('equipSN');
    this.equipLabels[3] = this.translateService.instant('missingParts');
  }

}
