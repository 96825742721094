import { Component, ViewEncapsulation, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
// import WKS_PARAMS from '../../../../../../../assets/data/wks_params.json';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksTasksModel, LangLabelModel, EventStatutCur, ParentTask, TasksActionsContentModel, IhmOptions } from '../../../../models/wks-param.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { WksCentralService } from '../../../../services/wks-central.service';
import { throwToolbarMixedModesError } from '@angular/material';

@Component({
  selector: 'mdi-tasksref-accordion',
  templateUrl: './tasksref-accordion.component.html',
  styleUrls: ['./tasksref-accordion.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TasksrefAccordionComponent implements OnInit, OnChanges {
  @Input() parentTask: ParentTask;
  @Input() tasksActionsContent: TasksActionsContentModel;
  @Input() tasksActionsParentContent: TasksActionsContentModel;
  @Input() wksEntity: WksEntityModel;
  @Input() actionType: any;
  @Input() orig: string;
  @Input() rowTask: number;
  @Input() ihmOptions: IhmOptions;

  @Output() dataOut = new EventEmitter<EventStatutCur>();

  private readonly onDestroy = new Subject<void>();
  
  dataList: any  = [];
  panelOpenState = false;
  
  tasksActionsCurrentContent: TasksActionsContentModel;
  
  constructor(private _translate: TranslateService, private _wksCentralService: WksCentralService) { }
// getTasksHeadingsList
  ngOnChanges(changes: SimpleChanges) { 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'parentTask': {
            this.parentTask = changes['parentTask'].currentValue;
            break;
          }
          case 'tasksActionsContent': {
            this.tasksActionsContent = changes['tasksActionsContent'].currentValue;
            break;
          }
 // tasksActionsParentContent
          case 'tasksActionsParentContent': {
            this.tasksActionsParentContent = changes['tasksActionsParentContent'].currentValue;

            break;
          }
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'orig': {
            this.orig = changes['orig'].currentValue;
            break;
          }
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'rowTask': {
            this.rowTask = changes['rowTask'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.initData();
  }
  ngOnInit() {
  }
  initData() {
    this.dataList = [];
    const listItem = this._wksCentralService.getTasksHeadingsList();
    let idxItem = 0;
    for (const item of listItem) {
      const itemCur = {
        'parentName': this._translate.getTranslate(item),
        'parentTech': item,
        'xpandStatus' : false,
        'childProperties': [ ]
      };
      this.dataList.push(itemCur);
      idxItem++;
    }
    this.dataList.sort((obj1: any, obj2: any) => {
      return obj1.parentName > obj2.parentName ? 1 : -1;
    });
    this.affectTasksrefChildren();
    this.loadTasksrefChildren();
    this._wksCentralService.taskWksEvent
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {

      if (event.todo === 'changeValuesTask') {
        this.rowTask = event.rowTask;
        this.setContentTask(event.orig, event.content as TasksActionsContentModel);
      } else if (event) {
        const typeEvent = typeof event;
        // const eventString = JSON.stringify(event).toString();
        const eventString = String(event);
        // console.log(typeEvent);
      }
    });
  }
  affectTasksrefChildren() {

    this.tasksActionsCurrentContent = {
        works: [],
        supplies: [],
    };

    if ( this.tasksActionsParentContent !== undefined && 
      this.tasksActionsParentContent.works !== undefined && 
      this.tasksActionsParentContent.works.length !== 0) {
      for (const tasksActionCur of this.tasksActionsParentContent.works) {
        if (tasksActionCur.actionrefLabel === '') {
          continue;
        }
        tasksActionCur.parentElement = true;
        this.tasksActionsCurrentContent.works.push(tasksActionCur);
      }
    }
    if ( this.tasksActionsParentContent !== undefined && 
        this.tasksActionsParentContent.supplies !== undefined && 
        this.tasksActionsParentContent.supplies.length !== 0) {
      for (const tasksActionCur of this.tasksActionsParentContent.supplies) {
        if (tasksActionCur.actionrefLabel === '') {
          continue;
        }
        tasksActionCur.parentElement = true;
        this.tasksActionsCurrentContent.supplies.push(tasksActionCur);
      }
    }
    if ( this.tasksActionsContent !== undefined && 
        this.tasksActionsContent.works !== undefined && 
        this.tasksActionsContent.works.length !== 0) {
      for (const tasksActionCur of this.tasksActionsContent.works) {
        if (tasksActionCur.actionrefLabel === '') {
          continue;
        }
        tasksActionCur.parentElement = false;
        this.tasksActionsCurrentContent.works.push(tasksActionCur);
      }
    }
    if ( this.tasksActionsContent !== undefined && 
        this.tasksActionsContent.supplies !== undefined && 
        this.tasksActionsContent.supplies.length !== 0) {
      for (const tasksActionCur of this.tasksActionsContent.supplies) {
        if (tasksActionCur.actionrefLabel === '') {
          continue;
        }
        tasksActionCur.parentElement = false;
        this.tasksActionsCurrentContent.supplies.push(tasksActionCur);
      }
    }
  }
  loadTasksrefChildren() {
 
      let childList = [];
      let localParentTask: any ;
      for (const childItem of this.dataList) {
        childList = childItem.childProperties;
        let duration: number;
        if (childItem.parentTech ===  'works') {
          // totalization duration
          duration = 0;
          if ( this.tasksActionsCurrentContent !== undefined && 
              this.tasksActionsCurrentContent.works !== undefined && 
              this.tasksActionsCurrentContent.works.length !== 0) {
            for (const tasksActionCur of this.tasksActionsCurrentContent.works) {
              if (tasksActionCur.quantity !== undefined) {
                duration +=  Number(tasksActionCur.quantity);
              }
            }
          }
           
          localParentTask = {
            parentParam: ((this.tasksActionsCurrentContent !== undefined && this.tasksActionsCurrentContent.works !== undefined ) ? 
                                                  this.tasksActionsCurrentContent.works  : undefined),
            statutCall: 'accordion',
           };
        }
        if (childItem.parentTech ===  'supplies&parts') {
          duration = undefined;
          localParentTask = {
            parentParam: ((this.tasksActionsCurrentContent !== undefined && this.tasksActionsCurrentContent.supplies !== undefined ) ? 
                                                  this.tasksActionsCurrentContent.supplies  : undefined),
            statutCall: 'accordion',
          };
        }
        childList.push(
          { 'propertyName': childItem.parentTech,
            'propertyContent': localParentTask,
            'propertyLength': (localParentTask.parentParam !== undefined ? localParentTask.parentParam.length : 0),
            'xpandStatus' : false,
            'actionType': '',
            'duration': duration
          });
        childList.sort((obj1: any, obj2: any) => {
          return obj1.propertyName > obj2.propertyName ? 1 : -1;
        });
  

      } // end loop dataList

  }
  statutCur(event: EventStatutCur): void  {
    this.dataOut.emit(event);
  }
  setContentTask(_origEvent: string, _contentEvent: TasksActionsContentModel) {
    if (this.tasksActionsContent === undefined ) {
      this.tasksActionsContent = {
        works: [],
        supplies : [],
      };
    }
    let localParentTask: any ;
    let duration: number;
    for (const childItem of this.dataList) {
      if (childItem.parentTech ===  _origEvent) {
        const childCur = childItem.childProperties[0];
        if (_origEvent === 'works') {
          this.tasksActionsContent.works = _contentEvent.works;
          duration = 0;
          if ( this.tasksActionsContent.works.length !== 0) {
            for (const tasksActionCur of this.tasksActionsContent.works) {
              if (tasksActionCur.quantity !== undefined) {
                duration +=  Number(tasksActionCur.quantity);
              }
            }
          }
          localParentTask = {
            parentParam: ((this.tasksActionsContent !== undefined && this.tasksActionsContent.works !== undefined ) ? 
                                                  this.tasksActionsContent.works  : undefined),
            statutCall: 'accordion',
          };
        }
        if (_origEvent === 'supplies&parts') {

          this.tasksActionsContent.supplies = _contentEvent.supplies;
          duration = undefined;
          localParentTask = {
            parentParam: ((this.tasksActionsContent !== undefined && this.tasksActionsContent.supplies !== undefined ) ? 
                                                  this.tasksActionsContent.supplies  : undefined),
            statutCall: 'accordion',
          };
        }
        childCur.propertyContent = localParentTask;
        childCur.propertyLength = (localParentTask.parentParam !== undefined ? localParentTask.parentParam.length : 0);
        childCur.duration = duration;
      }
    }
    if (this.orig === 'workCard') {
      this._wksCentralService.taskWksEvent.emit({
        orig: this.rowTask.toString(),
        todo: 'workCard',
        content: this.tasksActionsContent
      });
    }
  }
}
