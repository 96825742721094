import { Component, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, Input } from '@angular/core';
import {  WksEntityModel } from '../../../../../models/wks-entity.model';
import {  WksEntityParams } from '../../../../../models/wks-common.model';

@Component({
  selector: 'mdi-appointments-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.css']
})
export class AppointmentCardComponent implements OnChanges {

  titleCard = 'appointment card';
  @Input() rowCard: any;
  @Input() wksEntity: WksEntityModel;
  @Input() wksEntityParams: WksEntityParams; 
  @Input() mechanicUsers: any[];
  @Input() popoverAppointment: boolean;

  @Output() dataOut = new EventEmitter<any>();

  ctrlInput: {
    multiEquipment: boolean;
    inputSerialNumber: boolean;
    ctrlSerialNumber: boolean;
  };

  doRegister: boolean;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.doRegister = false;

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'rowCard': {
            this.rowCard = changes['rowCard'].currentValue;
            if (this.rowCard === undefined)  {
              break;
            }
            if (this.rowCard.trackingList === undefined) {
              this.rowCard.trackingList = [];
            }
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'wksEntityParams': {
            this.wksEntityParams = changes['wksEntityParams'].currentValue;
            this.ctrlInput = this.wksEntityParams.ctrlInput;
            break;
          }
          case 'mechanicUsers': {
            this.mechanicUsers = changes['mechanicUsers'].currentValue;
            break;
          }
          case 'popoverAppointment': {
            this.popoverAppointment = changes['popoverAppointment'].currentValue === 'true' ? true : false;
            break;
          }
        } // end switch
      } // end if
    } // end loop

  }
  doCancel() {
    const dataReturn =  {
      statut: 'cancel'
    };
    this.dataOut.emit(dataReturn);
  }
  saveAppointment($event: any)  {
    this.dataOut.emit($event);
  }

}
