<div>
  <div *ngIf="dataIsLoaded" class="chart">
    <canvas baseChart
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [plugins]="pieChartPlugins"
      [colors]="pieChartColors"
      [legend]="pieChartLegend">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
  </div>
</div>
