import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { EntityModel, UserLoggedModel} from '../../models/user.model';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '../../services/translate.service';
import { ThemeService } from '../../services/theme.service';
import { WksCentralService } from '../../job/job-wks/services/wks-central.service';

@Component({
  selector: 'mdi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

  credentials = {
    login: '',
    password: ''
  };
  authenticationFailed = false;
  showPassword = false;
  // userLogged = new UserLogged();
  userLogged: UserLoggedModel;
  entityLogged: EntityModel;
  
  themeIsDark: boolean;
  workshop: boolean;
  forcedEntity: string;

  constructor(private router: Router, private themeService: ThemeService,
              private _userService: UserService, 
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private authService: AuthService) { 
                this.workshop = environment.workshop;
              }
  ngOnInit() { 
    this.themeIsDark = this.themeService.isDarkTheme();
    this.themeService.setDarkTheme(this.themeIsDark);
    this._translate.setDefaultLang(this._userService.getNavigatorLanguage());
    const pathCur = window.location.href.split('?');
    if (pathCur.length > 1) {
      const argsArray = pathCur[1].split('&');
      for (const argCur of argsArray) {
        if (argCur.startsWith('entity')) {
          const args = argCur.split('=');
          this.forcedEntity = args[1];
        }
      }
    }
   }
  toggleDarkTheme(event: any) {
    this.themeIsDark = !this.themeIsDark;
    this.themeService.setDarkTheme(this.themeIsDark);
  }
  ngOnDestroy() { }
  authenticate() {
    this.authenticationFailed = false;
    this._wksCentralService.setWksMenuItems(undefined);

     this._userService.connectToken(this.credentials)
     .subscribe(
       (data)  => {
        this._userService.getUserDetail(this.credentials)
        .subscribe(
          (userDetail)  => {
              const oAuthResp = userDetail.body;
              const bodyValues = new Map<string,any>(Object.entries(oAuthResp));
              const entityLogged = bodyValues.get('entity') as string;
              // const isMaster = entityLogged.startsWith('nautiko') ? true : false;
              // const isMaster = bodyValues.get('entityMaster') === 'true' ? true : false;
              const additionalInfo = new Map<string,any>(Object.entries(bodyValues.get('additionalInfo')));
              const isMaster = additionalInfo.get('entityMaster') as boolean;
          
              this.userLogged = {
                id: bodyValues.get('id') as number,
                username: bodyValues.get('username') as string,
                firstname: bodyValues.get('firstName') as string,
                lastname: bodyValues.get('lastName') as string,
                email: bodyValues.get('email') as string,
                entity: this.forcedEntity && isMaster ? this.forcedEntity : bodyValues.get('entity') as string,
                profile: bodyValues.get('profile') as string,
                datafilter: bodyValues.get('datafilter') as string,
                authorities: bodyValues.get('authorities') as string,
                usergroup: bodyValues.get('usergroup') as string,
                applications: bodyValues.get('applications') as string,
                isMaster: isMaster,
              };

              this.authService.loadEnvironment(this.userLogged, this.forcedEntity);
              // localStorage.setItem('JWT_TOKEN', this._userService.accessToken);
            },
          );
       },
       () =>  {
        this.authenticationFailed = true;
      }
     );
  }
}
