<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="padding: 0px; margin-top: 75px; width: 100%;margin-left: 0px; margin-right: 0px;">

    <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col top-sidenav">
        <span class="horizontal-spacer5"></span>
        <span style="margin-left: 45%; font-size:large">{{ 'anchorages' | translate }}</span>
        </div>
    </div>
    
    <div  class="container-fluid" style="margin: 0px; padding: 0px;height: 100%;">
        <div class="flex-row row stdTheme w-100" style="margin: 0px; padding: 0px;" >
            <div class="col-12" style="padding: 0px;">
                <div class="flex-row row stdTheme w-100" style="margin: 0px">
                    <div class="col-4 d-flex justify-content-start align-items-center  " >
                        <input type="text"  id="filter-text-box" matInput
                            style="height: 50px;"
                            placeholder="{{ 'global filter' | translate }}" 
                            (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
                    </div>
                    <div class="col-2 d-flex justify-content-start align-items-center  "  >
                   
                    </div>
                    <div class="col-6 d-flex justify-content-end align-items-center">
                        <button mat-icon-button class="buttonTheme" 
                                style="margin-left: 10px;margin-right: 10px;" (click)="uploadAnchorages()">
                            <span class="fas fa-cloud-upload-alt" style="font-size: 20px" ></span>
                        </button>
                        <button 
                            mat-icon-button class="buttonTheme" 
                           style="margin-left: 10px;margin-right: 10px;" (click)="addAnchorage()">
                            <span class="fa fa-plus-square" style="font-size: 20px" ></span>
                        </button>
                        <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                                style="margin-left: 10px;margin-right: 10px;">
                            <span class="fas fa-redo" style="font-size: 20px" ></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row row stdTheme w-100" style="margin: 0px; min-height: 50vh;">
            <div class="col-7">
                <ag-grid-angular 
                    style="width: 100%; height: 100%;" 
                    class="ag-theme-material"
                    [gridOptions]="gridOptions"
                    [groupMultiAutoColumn] = "true"
                    [groupHideOpenParents]="true"
                    [rowData]="dataSource.data"
                    [localeText]="localeText"
                    [pagination]="true"
                    [paginationAutoPageSize]="true"
                    [autoGroupColumnDef]="autoGroupColumnDef"
                    [suppressDragLeaveHidesColumns]="true"
                    [suppressMakeColumnVisibleAfterUnGroup]="true"
                    (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)"
                    (rowClicked)="onRowClicked($event)"
                    [defaultColDef]="defaultColDef"
                    >
                </ag-grid-angular>
            </div>
            <div class="col-5" style="height: 100%;">
                <div class="flex-row row stdTheme w-100" style="margin: 0px; height: 100%;">
                    <div class="col-12" style="margin: 0px; padding: 0px;">
                        <div *ngIf="displayType === 'list'" >
                            <mdi-anchorages-card
                                [statutCall]="'table'" 
                                [optionItem]="'anchorages'" 
                                [actionType]="actionType" 
                                [anchorageToUpdate]="anchorageToUpdate"
                                (dataOut)="updateRow($event)">
                            </mdi-anchorages-card>
                        </div>
                        <div *ngIf="displayType === 'upload'" >
                            <mdi-anchorages-upload style="width: 100%;"
                            [statutCall]="'sidenav'" 
                            [optionItem]="''" 
                            [actionType]="actionType">
                            </mdi-anchorages-upload>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
