
<!--<div class="container d-flex justify-content-center ">-->
<!--<div   class="container col-lg-6 col-md-offset-3" [ngClass]="{'dark-theme': isDarkTheme}">-->
  <div   class="container col-lg-6 col-md-offset-3" >
  <mat-card [_nghost-pmm-5] class="stdTheme" style="padding: 0px;width: 100%;" fxLayout="column" fxLayoutAlign="center" >
      <mat-card-header class="titleTheme"  >
          <mat-card-title >
            <section class="mat-typography">
              <h1 class="titleTheme" style="padding-top: 10px;">{{ 'Sign in' | translate }}</h1>
            </section>
          </mat-card-title>
      </mat-card-header>

      <mat-card-content class="stdTheme" style="padding-top: 10px ;">
        <!-- <div style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em"> -->
        <div fxLayoutAlign="end" style="padding-inline-end: 10px ;">
                  <!--  
          <input type="checkbox" (change)="toggleDarkTheme($event.target.checked)" class="form-check-input" 
                          #themeCheck id="themeCheck" [checked]="isDarkTheme">
          <label *ngIf="!themeCheck.checked" class="form-check-label" for="themeCheck">
            {{ 'Dark Theme' | translate }}
          </label>
          <label *ngIf="themeCheck.checked"class="form-check-label" for="themeCheck">
            {{ 'Ligth Theme' | translate }}
          </label>
          -->
          <!--
          <mat-checkbox #themeCheck (change)="toggleDarkTheme($event.checked)">
            <span *ngIf="!themeCheck.checked">{{ 'Dark Theme' | translate }}</span>
            <span *ngIf="themeCheck.checked"> {{ 'Ligth Theme' | translate }}</span>
          </mat-checkbox>
          -->
          <button mat-raised-button class="buttonTheme" #buttonTheme style="border-radius: 20px;" (click)="toggleDarkTheme($event)">
            <i class="fas fa-adjust"> </i>
              <span *ngIf="!themeIsDark" style="padding-left: 10px;">{{ 'Dark Theme' | translate }}</span>
              <span *ngIf="themeIsDark" style="padding-left: 10px;" > {{ 'Ligth Theme' | translate }}</span>
           
          </button>
   
        </div>
        <div style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em">
          <span class="fa fa-language" ></span>
          <span style="padding-left: 1em"></span>
          <span for="lang" >{{ 'language' | translate }}</span>
          <span style="display:inline-block; margin-left: 80px;" >
              <mdi-choice-lang></mdi-choice-lang>
          </span>
          <span class="spacer5" ></span>
        </div>
        <div class="alert alert-danger" *ngIf="authenticationFailed">
          <button type="button" class="close" aria-label="Close" (click)="authenticationFailed = false;">
            <span aria-hidden="true">&#215;</span>
          </button>
          {{ 'Nope, try again.' | translate }}
        </div>
        <form (ngSubmit)="authenticate()" #loginForm="ngForm" >  
            <div class="form-group" style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em">
              <div >
                <span class="fa fa-user" ></span>
                <span style="padding-left: 1em"></span>
                <span for="login" >{{ 'ID' | translate }}</span>
                <span class="spacer5" ></span>
              </div>
              <div>
                <input id="login" class="form-control inputTheme"  name="login" [(ngModel)]="credentials.login" placeholder="{{ 'ID' | translate }}"
                            required #loginCtrl="ngModel">
              </div>
              <div *ngIf="loginCtrl.control.dirty && loginCtrl.control.hasError('required')">Login is required</div>
            </div>

            <div class="form-group "  style="text-align:left; font-size: large;padding-left: 5em; padding-right: 5em">
              <div >
                <span class="fa fa-lock" ></span>
                <span style="padding-left: 1em"></span>
                <span for="password" >{{ 'Password' | translate }}</span>
              </div>
              <div class="input-group">
                <input  id="password" class="form-control inputTheme" type="password" name="password" placeholder="{{ 'Password' | translate }}"
                        [type]="showPassword ? 'text' : 'password'"         
                        [(ngModel)]="credentials.password" required #passwordCtrl="ngModel">
                <div class="input-group-append inputTheme">
                  <div class="input-group-text inputTheme">
                    <i class="fa fa-eye-slash inputTheme" *ngIf="!showPassword" (click)="showPassword = !showPassword"></i>
                    <i class="fa fa-eye inputTheme" *ngIf="showPassword" (click)="showPassword = !showPassword"></i>
                  </div>
                </div>
              </div>
              <div *ngIf="passwordCtrl.control.dirty && passwordCtrl.control.hasError('required')">Password is required</div>
            </div>
 
            <div style="padding-bottom: 20px">
              <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!loginForm.valid" >
                <mat-icon>input</mat-icon>  {{ 'Login' | translate }}
              </button>
            </div>

        </form>
      </mat-card-content>

    </mat-card>
</div>
