import { Component, OnInit } from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from "ag-grid-community";
import {ICellRendererAngularComp } from "ag-grid-angular";
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';

import { JobDataService } from '../../../../../../job-data/services/job-data.service';

@Component({
  selector: 'mdi-numeric-renderer',
  template: `<div style="width: 100%;text-align: right!important;">
                    {{currentValue}}
                    </div>`,
 })
export class NumericRendererComponent implements OnInit, ICellRendererAngularComp  {
  
  params: ICellRendererParams;
  currentValue : any;
  constructor(_jobDataService: JobDataService) { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {

    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName + ' lang ' + params.colDef.cellRendererParams.currentLang+ ' valeur ' + params.value);
    const currentLang = params.colDef.cellRendererParams.currentLang;

    let numberFmt = CommonMethods.fmtNumber(params.value,params.colDef.cellRendererParams.colFmtString, currentLang);
    // console.log(currentLang +' : '+numberFmt);

    this.params = params;
    this.currentValue = numberFmt;
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName+ ' valeur ' + params.value);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }

}
