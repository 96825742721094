<div #refElement class="mat-elevation-z8 container-fluid stdTheme" style="width: 50%; margin-top: 10px" >
    <mat-card class="register-card stdTheme" layout="column" flex>
     <!-- <mat-card-header>
        <mat-card-title  >
          <span #titleCard>{{ RegisterTitle | translate }}</span>
        </mat-card-title>
      </mat-card-header> -->
      <mat-card-actions>
        <div id="registration-error" class="alert alert-danger" *ngIf="registrationFailed">
          <button type="button" class="close buttonTheme" aria-label="Close" (click)="registrationFailed = false;">
            <!-- <span aria-hidden="true">&#215;</span> -->
          </button>
          {{ messageTranslate }}
        </div>
        <div *ngIf="doTestConnect">
          <button mat-raised-button class="buttonTheme" (click)="testConnect()" [disabled]="!datasourceForm.valid || isDatasourceFound || isTestConnectOK">
            <mat-icon>perm_data_setting</mat-icon>
            {{ 'Test connect' | translate }}
          </button>
        </div>
      </mat-card-actions>
      <form (ngSubmit)="register()" [formGroup]="datasourceForm"  >
        <mat-card-content class="stdTheme">
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'dbsLabel' | translate }}</mat-label>
                <input id="dbsLabel" class="form-control inputTheme" formControlName="dbsLabel" matInput #input minlength="3" placeholder="{{ 'dbsLabel' | translate }}"
                  required>
                <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
                <mat-error *ngIf="dbsLabelCtrl.invalid">{{getErrorMessage('dbsLabel')}}</mat-error>
                <mat-error *ngIf="isDatasourceFound">{{getErrorMessage('isDatasourceFound')}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col" style="margin: 0em; padding: 0em">
              <mat-checkbox formControlName="dbsDoc" labelPosition="before" >
                {{ 'dbsDoc' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <!--
          <div class="flex-row row">
            <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'dbsEngine' | translate }}</mat-label>
              <input id="dbsEngine" class="form-control" formControlName="dbsEngine" matInput #input placeholder="{{ 'dbsEngine' | translate }}"
              required >
            </mat-form-field>
          </div>
          -->
          <div class="flex-row row">
            <mat-form-field class="mdiselect form-group" appearance="standard">
              <mat-label>{{ 'dbsEngine' | translate }}</mat-label>
              <mat-select id="dbsEngine" placeholder="{{ 'Choose one engine' | translate }}" formControlName="dbsEngine" 
                          panelClass="mdiselectcontent"
                          ngDefaultControl required>
                <mat-option *ngFor="let engine of engineList" [value]="engine">{{ engine}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'dbsServer' | translate }}</mat-label>
                <input id="dbsServer" class="form-control inputTheme" formControlName="dbsServer" matInput #input placeholder="{{ 'dbsServer' | translate }}"
                required >
              </mat-form-field>
            </div>
            <div class="col" style="margin-right: 0em; padding-right: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'dbsPort' | translate }}</mat-label>
                <input id="dbsPort" class="form-control inputTheme" formControlName="dbsPort" matInput #input placeholder="{{ 'dbsPort' | translate }}"
                  required>
                <mat-error *ngIf="dbsPortCtrl.invalid">{{getErrorMessage('dbsPort')}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row">
            <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'dbsName' | translate }}</mat-label>
              <input id="dbsName" class="form-control inputTheme" formControlName="dbsName" matInput #input placeholder="{{ 'dbsName' | translate }}"
                required>
              <mat-error *ngIf="dbsNameCtrl.invalid">{{getErrorMessage('dbsName')}}</mat-error>
            </mat-form-field>
          </div>
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'dbsUser' | translate }}</mat-label>
                <input id="dbsUser" class="form-control inputTheme" formControlName="dbsUser" matInput #input placeholder="{{ 'dbsUser' | translate }}"
                  required>
                <mat-error *ngIf="dbsUserCtrl.invalid">{{getErrorMessage('dbsUser')}}</mat-error>
              </mat-form-field>
            </div>            
            <div class="col" style="margin-right: 0em; padding-right: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'dbsPwd' | translate }}</mat-label>
                <input id="dbsPwd" class="form-control" [type]="showPassword ? 'password' : 'text'" formControlName="dbsPwd" matInput #input placeholder="{{ 'dbsPwd' | translate }}"
                  required>
                  <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="dbsPwdCtrl.invalid">{{getErrorMessage('dbsPwd')}}</mat-error>
              </mat-form-field>
            </div>
          </div> 
          <div class="flex-row row">
            <mat-form-field class="mdiselect form-group" appearance="standard">
              <mat-label>{{ 'accessEntities' | translate }}</mat-label>
                <mat-select #selectAccessEntities placeholder="{{ 'accessEntities' | translate }}" 
                            panelClass="mdiselectcontent" 
                            disableOptionCentering formControlName="accessEntities" multiple ngDefaultControl required>
                  <mat-select-header>
                    <div class="flex-row button-row text-center" >
                      <button mat-raised-button class="buttonTheme"  (click)="selectAccessEntities.toggle()">
                        {{'Close' | translate}}
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-select-header>
                  <mat-option  *ngFor="let entity of entitiesList" [value]="entity" (onSelectionChange)="loadGrps($event)">{{ entity }}</mat-option>
              </mat-select>
              <mat-error *ngIf="accessEntitiesCtrl.invalid">{{getErrorMessage('accessEntities')}}</mat-error>
            </mat-form-field>
            <!-- </section>-->
          </div> 
          <div class="flex-row row">
            <mat-form-field class="mdiselect form-group" appearance="standard" style="max-height: 50px" >
              <mat-label>{{ 'accessGrp' | translate }}</mat-label>
                <mat-select #selectAccessGrp placeholder="{{ 'accessGrp' | translate }}" 
                          panelClass="mdiselectcontent" 
                          disableOptionCentering formControlName="accessGrp" multiple ngDefaultControl required>
                  <mat-select-header>
                    <div class="flex-row button-row text-center" >
                      <button mat-raised-button class="buttonTheme"  (click)="selectAccessGrp.toggle()">
                        {{'Close' | translate}}
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let accessGrp of grpsList" [value]="accessGrp">{{ accessGrp }}</mat-option>
              </mat-select>
              <mat-error *ngIf="accessGrpCtrl.invalid">{{getErrorMessage('accessGrp')}}</mat-error>
            </mat-form-field>
              <!-- </section>-->
          </div> 
        </mat-card-content>
        <mat-card-actions>
          <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
            <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!datasourceForm.valid || isDatasourceFound || !isTestConnectOK">
              <mat-icon>create</mat-icon>
              {{ 'submit' | translate }}
            </button>
            <button mat-raised-button class="buttonTheme" #buttonSubmit type="reset" (click)="doCancel()">
              <mat-icon>cancel</mat-icon>
              {{ 'cancel' | translate }}
            </button>
          </div>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  