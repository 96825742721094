import { Component, OnInit } from '@angular/core';

import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../../services/translate.service';

@Component({
  selector: 'mdi-accounting-renderer',
  templateUrl: './accounting-renderer.component.html',
  styleUrls: ['./accounting-renderer.component.css']
})
export class AccountingRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  currentValue: any;

  accountingPosition: string;

  constructor(private _translate: TranslateService) { }
  // constructor() { }

  refresh(params: ICellRendererParams): any {
    this.getValueToDisplay(params);
    // return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {

    this.getValueToDisplay(params);
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
  getValueToDisplay(params: ICellRendererParams): void {
    this.accountingPosition = 'green';

    this.params = params;
    
    if (params.data.accountingBalance.balance > 0) {
      this.accountingPosition = 'amber';
    }
    if (params.data.accountingBalance.balance === 0 && params.data.accountingBalance.customerOutstanding === 0) {
      this.accountingPosition = 'green';
    } else if (params.data.accountingBalance.balance >= params.data.accountingBalance.customerOutstanding) {
      this.accountingPosition = 'red';
    }

    this.currentValue = params.value;
   }
}
