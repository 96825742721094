import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../../services/translate.service';
import { WksCentralService } from '../../../../../../services/wks-central.service';
import { GlobalEventsManagerService } from '../../../../../../../../job/common/services/global-events-manager.service';

@Component({
  selector: 'mdi-work-id-renderer',
  templateUrl: './work-id-renderer.component.html',
  styleUrls: ['./work-id-renderer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorkIdRendererComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  workId: string;
  currentValue: string;

  constructor(private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _globalEventsManagerService: GlobalEventsManagerService,
  ) { }

  ngOnInit() {
  }
  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.currentValue = params.value;
    this.workId = params.data.id;

    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
  public copyWorkIdToClipBoard(): void {
    this._wksCentralService.copyToClipboard(this.workId);
    const eventWork = {
      workIdEvent: 'copied',
      workId: this.workId
    };
    this._globalEventsManagerService.synchroEvents.next(eventWork);
  }
}
