<div id="dashboard" #refElement class="mat-elevation-z8 stdTheme " style="width: 100%;" >

  <mat-card class="dashboard-card stdTheme" layout="column" flex >
     <mat-card-header>
       <!-- <mat-card-title  >
          <span #titleCard>{{ registerTitle | translate }}</span>
        </mat-card-title> -->
        <div *ngIf="isLoaded" class="container-fluid" style="margin-top: 0px; margin-bottom: 0px;">
          <div class="row justify-content-around">
            <div class="col-5">
              <label style="margin-right: 10px" > {{ 'dashboardRows' | translate }}</label>

              <button type="button" class="btn btn-default" mat-icon-button (click)="counter('dashboardRows',-1)">
                <span class="fa fa-minus"></span>
              </button>

              <span class="form-group" >
                  <input class="input-sm" type="number" matInput #dashboardRows  #input readonly 
                        [(value)]="listWidgets.dashboardRows" 
                        style="width: 40px;  text-align: center; padding:0px; margin: 0px"
                        (blur)="listWidgets.dashboardRows = dashboardRows.value">
              </span>
 
              <button type="button" class="btn btn-default" mat-icon-button (click)="counter('dashboardRows',1)">
                  <span class="fa fa-plus"></span>
              </button>
            </div>
  
            <div class="col-5">
              <label class="my-auto" style="margin-left: 10px; margin-right: 10px" > {{ 'dashboardCols' | translate }}</label>

              <button type="button" class="btn btn-default" mat-icon-button (click)="counter('dashboardCols',-1)">
                <span class="fa fa-minus"></span>
              </button>

              <span class="form-group" >
                  <input class="input-sm" type="number" #dashboardCols matInput #input readonly 
                        [(value)]="listWidgets.dashboardCols" 
                        style="width: 40px;  text-align: center; padding:0px; margin: 0px"
                        (blur)="listWidgets.dashboardCols = dashboardCols.value">
              </span>

              <button type="button" class="btn btn-default" mat-icon-button (click)="counter('dashboardCols',1)">
                  <span class="fa fa-plus"></span>
              </button>
            </div>
            <div class="col-2">
              <div class="flex-row row">
                <button class="my-auto" mat-icon-button (click)="addWidget()">
                  <span class="fa fa-plus-square" 
                      style="margin-left: 40px; margin-top: 0px; margin-bottom: 0px; font-size: 30px" ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
    </mat-card-header>

    <mat-card-content  >

      <div *ngIf="isLoaded"  [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'1050'" >
          <!--<ul class='fa-ul'> -->
        

        <ng-template ngFor let-iRow="index" let c = "count" let-widgetCur [ngForOf]="listWidgets.widgets">
          <div class="container-fluid widgetCur" style="margin: 0px; "  >
              <!-- <div id="pinnedRow_{{i}}" #pinnedRow_{{i}} class="row"></div>--> 
            <div id="widgetCur_{{iRow}}" #widgetCur_{{iRow}} class="row rowLine" style="line-height: 30px; " >
              <div class="col-1" style="margin-top: 10px;">
   
                  <button mat-icon-button (click)="removeWidget(iRow) ">
                    <span class="fa fa-times" style="font-size: 15px" ></span>
                  </button>      
              </div>

              <div class="col-10 labelLine" style="margin-top: 10px;">
                  <span style="margin: 0px;"><h6>{{ widgetCur.widgetName }}</h6> </span>               
              </div>
              <div class="col-1" style="margin-top: 10px;">
                <button mat-icon-button
                    (click)="widgetCur.isCollapsed = !widgetCur.isCollapsed"
                    [attr.aria-expanded]="!widgetCur.isCollapsed" aria-controls="collapseExample">
                    <span *ngIf="widgetCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                    <span *ngIf="!widgetCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                </button> 
              </div>
              <div class="col-7 " [ngbCollapse]="widgetCur.isCollapsed">
                <div class="row labelLine">
                  <div class="col-3">
                    <label class="labelLine"> {{ 'widgetName' | translate }}</label>
                  </div>
                  <div class="col-3">
                    <input type="text" #widgetName #input matInput class="text" style="width:120px; height: auto; font-size: 15px; margin: 0px; padding: 0px" 
                        [(value)]="widgetCur.widgetName" (change)="setValueArrayWidget('widgetName',widgetName.value,iRow)">
                  </div>
                  <div class="col-3" >
                    <span class = "labelLine">{{ 'chartType' | translate }}</span>
                  </div>
                  <div class="col-3" >
                    <select class="form-control stdTheme" id="chartType"  #chartType 
                                    style="width:auto; height: auto; font-size: 15px; " (change)="setChartTypeSelect(chartType.value,iRow)">
                      <option class="selectOptionsTheme" *ngFor="let charTypeCur of chartTypeList" [selected]="charTypeCur === widgetCur.chartType" >{{ charTypeCur}}</option>
                    </select>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-3">
                    <label class="my-auto labelLine"  > {{ 'heightWidget' | translate }}</label>
                  </div>
                  <div class="col-3 margin-0 padding-0">
                    <button type="button" class="btn btn-default margin-0 padding-0" style="width: 20;height: 20;" mat-icon-button (click)="counter('heightWidget',-1, iRow)">
                      <span class="fa fa-minus" style="font-size: 12px"></span>
                    </button>
      
                    <span class="form-group" >
                        <input class="input-sm  margin-0 padding-0" type="text" matInput #heightWidget  #input readonly 
                              [(value)]="widgetCur.height" 
                              style="width: 40px;text-align: center;"
                              (blur)="widgetCur.height = widthWidget.value">
                    </span>
      
                    <button type="button" class="btn btn-default  margin-0 padding-0" style="width: 20;height: 20;" mat-icon-button (click)="counter('heightWidget',1,iRow)">
                        <span class="fa fa-plus" style="font-size: 12px"></span>
                    </button>
                  </div>
                  <div class="col-3">
                    <label class="my-auto labelLine"  > {{ 'widthWidget' | translate }}</label>
                  </div>
                  <div class="col-3  margin-0 padding-0">
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('widthWidget',-1, iRow)">
                      <span class="fa fa-minus" style="font-size: 12px"></span>
                    </button>
      
                    <span class="form-group" >
                        <input class="input-sm  margin-0 padding-0" type="text" #widthWidget matInput #input readonly 
                              [(value)]="widgetCur.width" 
                              style="width: 40px;text-align: center;"
                              (blur)="widgetCur.width = widthWidget.value">
                    </span>
      
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('widthWidget',1,iRow)">
                        <span class="fa fa-plus" style="font-size: 12px"></span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <label class="my-auto labelLine"  > {{ 'topWidget' | translate }}</label>
                  </div>
                  <div class="col-3  margin-0 padding-0">
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('topWidget',-1, iRow)">
                      <span class="fa fa-minus" style="font-size: 12px"></span>
                    </button>
      
                    <span class="form-group" >
                        <input class="input-sm  margin-0 padding-0" type="text" #topWidget matInput #input readonly 
                              [(value)]="widgetCur.top" 
                              style="width: 40px;text-align: center;"
                              (blur)="widgetCur.top = topWidget.value">
                    </span>
      
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('topWidget',1,iRow)">
                        <span class="fa fa-plus" style="font-size: 12px"></span>
                    </button>
                  </div>
         
                  <div class="col-3">
                    <label class="my-auto labelLine"  > {{ 'leftWidget' | translate }}</label>
                  </div>
                  <div class="col-3  margin-0 padding-0">
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('leftWidget',-1, iRow)">
                      <span class="fa fa-minus" style="font-size: 12px"></span>
                    </button>
      
                    <span class="form-group" >
                        <input class="input-sm  margin-0 padding-0" type="text" #leftWidget matInput #input readonly 
                              [(value)]="widgetCur.left" 
                              style="width: 40px;text-align: center;"
                              (blur)="widgetCur.left = leftWidget.value">
                    </span>
      
                    <button type="button" class="btn btn-default  margin-0 padding-0" mat-icon-button  (click)="counter('leftWidget',1,iRow)">
                        <span class="fa fa-plus" style="font-size: 12px"></span>
                    </button>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="col-3">
                      <label class="my-auto labelLine"  > {{ 'updateFrequency' | translate }}</label>
                  </div>
                  <div class="col-3">
                    <input type="number" #updateFrequency #input matInput class="text" 
                            style="width: 50px; height: auto; font-size: 15px; margin: 0px; padding: 0px; text-align: end;" 
                            [(value)]="widgetCur.updateFrequency" 
                            min="-1" max ="60"
                            data-toggle="tooltip" title="{{ 'updateFrequencyTip' | translate }}"
                            (change)="setValueArrayWidget('updateFrequency',updateFrequency.value,iRow)">
                  </div>
                  <div class="col-6">
                    <div >
                        <label class="labelLine" style="margin-left: 10px">{{ 'defaultDisplay' | translate }}
                      
                          <input type="checkbox" id="defaultDisplay" #defaultDisplay style="margin-left: 80px;font-size: 15px;"
                            [checked]="widgetCur.defaultDisplay" 
                            (change)="setValueArrayWidget('defaultDisplay',defaultDisplay.checked,iRow)">

                        </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-5" [ngbCollapse]="widgetCur.isCollapsed" >
                <div class="flex-row row ">
                  <div class="col" >
                    <span class = "labelLine">{{ 'requestRefSelect' | translate }}</span>
                  </div>
                  <div class="col" >
                    <select class="form-control stdTheme" id="requestRefSelect"  #requestRefSelect 
                                    style="width:auto; font-size: 15px" (change)="setRequestSelect(requestRefSelect.value,iRow)">
                      <option class="selectOptionsTheme" *ngFor="let requestCur of requestsList" [selected]="requestCur.requestRef === widgetCur.requestRef" >{{ requestCur.requestLabel}}</option>
                    </select>
                  </div>
                </div>
                <div class="flex-row row">
                  <table class="table table-striped" >
                    <tbody style="overflow-y: auto;">
                      <tr *ngFor="let restrictionValue of widgetCur.requestRestrictions; let iRestrict = index" 
                      style="height: 10px; line-height: 10px; padding:0px;margin-top: 0px;">
                        <td style="width: 30%">  
                          <span class = "labelLine">{{ restrictionValue.columnName }}</span>           
                        </td>
                        <td style="width: 30%" >
                          <span class = "labelLine">{{ restrictionValue.operande }}</span>     
                        </td>
                        <td style="width: 40%">
                          <input type="text" #restrictValue #input  matInput class="text"
                          [(value)]="restrictionValue.restrictValue" (change)="setRestrictionValue(restrictValue.value,iRow,iRestrict)">
                        </td>
                      
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div>        
        <div class="container-fluid d-flex justify-content-between" style="margin-top: 10px;">
          <button mat-raised-button #buttonSubmit type="submit" (click)="register()" [disabled]="!doValidation ">
            <mat-icon>create</mat-icon>
            {{ 'submit' | translate }}
          </button>
          <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'cancel' | translate }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>