// https://valor-software.com/ng2-charts/#PieChart
import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {

  @Input() widgetData: Widget;
  @Input() displayTime: boolean;
  private readonly onDestroy = new Subject<void>();

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
    animation: {
      duration: 2000, // general animation time
      easing: 'easeInOutQuart',
    },
    hover:  {
        animationDuration: 200 // duration of animations when hovering an item
      },
    responsiveAnimationDuration: 1000 // animation duration after a resize
  };
  // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartLabels: Label[];
  // public pieChartData: number[] = [300, 500, 100];
  public pieChartData: number[] ;
  // public pieChartType: ChartType = 'pie';
  public pieChartType: ChartType ;
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  /*public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];*/
  public pieChartColors: any[];

  updateFrequency: number;

  dataIsLoaded : boolean = false;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
    console.log('widgetData pieChart : ' + this.widgetData);
    this.updateFrequency = this.widgetData.updateFrequency;
    this.displayDateTime = this.displayTime;

    this._jobDataService.displayGenerateTime
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if ((event === 'displayTime') || (event === 'hideTime')) {
          this.toogleTime(event);
        }
    });
    this.buildRequest();
    this.pieChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction,'unixM',localFmt);
      const jsonData = JSON.parse(resultChart.json_data);
      this.pieChartLabels = jsonData.labelCols as Label[];
      this.pieChartData = jsonData.datasets[0].data as number[];
      const colorAlphas = CommonMethods.genAlphaHslaColors(-1,70,[20,100,80],this.pieChartData.length);
      this.pieChartColors = [];
      let colorList = [];
      let idx = 0;
      for(const serieCur of this.pieChartData) {
        colorList.push(colorAlphas[idx][1]);
        idx++;
      }
      const colorCur = {
        backgroundColor: colorList,
      }
      this.pieChartColors.push(colorCur);
      this.dataIsLoaded = true;
    })
    .then((error) => {

    });
  }
  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if(restrict.columnName) {
        if(requestRestrictions != '') {
          requestRestrictions+=';';
        }
        requestRestrictions+= restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if(resultChart.length === 0) {
            reject();
          }
          else resolve(resultChart[0]);
        }, err => {
          
          reject(err);
        }
      );
    });
  }
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  changeLabels() {
    const words = ['hen', 'variable', 'embryo', 'instal', 'pleasant', 'physical', 'bomber', 'army', 'add', 'film',
      'conductor', 'comfortable', 'flourish', 'establish', 'circumstance', 'chimney', 'crack', 'hall', 'energy',
      'treat', 'window', 'shareholder', 'division', 'disk', 'temptation', 'chord', 'left', 'hospital', 'beef',
      'patrol', 'satisfied', 'academy', 'acceptance', 'ivory', 'aquarium', 'building', 'store', 'replace', 'language',
      'redeem', 'honest', 'intention', 'silk', 'opera', 'sleep', 'innocent', 'ignore', 'suite', 'applaud', 'funny'];
    const randomWord = () => words[Math.trunc(Math.random() * words.length)];
    this.pieChartLabels = Array.apply(null, { length: 3 }).map(_ => randomWord());
  }

  addSlice() {
    this.pieChartLabels.push(['Line 1', 'Line 2', 'Line 3']);
    this.pieChartData.push(400);
    this.pieChartColors[0].backgroundColor.push('rgba(196,79,244,0.3)');
  }

  removeSlice() {
    this.pieChartLabels.pop();
    this.pieChartData.pop();
    this.pieChartColors[0].backgroundColor.pop();
  }

  changeLegendPosition() {
    this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }

}
