import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../../services/translate.service';

@Component({
  selector: 'mdi-available-renderer',
  templateUrl: './available-renderer.component.html',
  styleUrls: ['./available-renderer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AvailableRendererComponent implements OnInit, ICellRendererAngularComp {
  
  params: ICellRendererParams;
  currentValue: boolean;

  criticalAlert: boolean;
  constructor(private _translate: TranslateService) { }

  ngOnInit() {
  }
  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.criticalAlert = false;
    this.params = params;
    this.currentValue = params.value;
    if (!this.params.data.allAvailable) {
      this.criticalAlert = true;
    }
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
}
