import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map} from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class CmdService {

    httpHeaders: HttpHeaders;
    httpParams: HttpParams;

    constructor(public  httpClient: HttpClient) {
        
    }
    setHeaders(_headers: HttpHeaders) {
        this.httpHeaders = _headers;
    }
    
    runCmd(_action: string, bodyArg: any): Observable<HttpResponse<string>> {

        const urlService = `${environment.baseUrl}/cmd/testcmd/?` + 'cmdarg=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        let body;
        if (bodyArg !== undefined) {
            body = JSON.stringify(bodyArg);
        }
        return this.httpClient
        .post<string>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
