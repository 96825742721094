
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';

import { TranslateService } from '../../../services/translate.service';
import { UserService } from '../../../services/user.service';
import { RessourcesService } from './ressources.service';
import { environment } from '../../../../environments/environment';

import { Currencies, EconomicAreas, InternationalsFormats } from '../../../models/data.model';
import { WksDocsModel } from '../../job-wks/models/wks-equipment.model';
import { WksWorksDocs } from '../../job-wks/models/wks-works.model';
import { Criterias, FileUpload } from '../../../models/common.model';
import { CommonMethods } from '../tools/commonMethods';
// import { InternationalsFormats } from 'app/models/data.model';
// https://grokonez.com/frontend/angular/angular-4-uploadget-multipartfile-tofrom-spring-boot-server


@Injectable({
  providedIn: 'root'
})
export class RemoteFilesService {

  constructor(public  _httpClient: HttpClient, 
            private _userService: UserService,
            private _ressourcesService: RessourcesService) {

  }
  downloadDoc(_url: string, _path: string, _type: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    // const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;
    const urlCur = `${environment.baseUrl}` + _url + '?path=' + _path + '&type=' + _type;
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();

    
    return this._httpClient.get<any> ( urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
  loadCountries(): Observable<HttpResponse<string>> {

      // return this.http.get('/getallfiles');
      const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
      const urlCur = `${environment.baseUrl}/config/countries`;
  
      return this._httpClient.get<string>(urlCur,
        {
          headers: localHttpHeaders,
          observe: 'response'
        });
    }
  loadCurrencies(): Observable<HttpResponse<Currencies[]>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const urlCur = `${environment.baseUrl}/config/currencies`;

    return this._httpClient.get<Currencies[]>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
  loadEconomicAreas(): Observable<HttpResponse<EconomicAreas[]>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const urlCur = `${environment.baseUrl}/config/economicAreas`;

    return this._httpClient.get<EconomicAreas[]>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
  loadIntFormats(): Observable<HttpResponse<Map<string, InternationalsFormats>>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const urlCur = `${environment.baseUrl}/config/internationalsFormats`;

    return this._httpClient.get<Map<string, InternationalsFormats>>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
  reloadWebdav(): Observable<HttpResponse<any>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const urlCur = `${environment.baseUrl}/config/reloadWebdav`;

    return this._httpClient.post<any>(urlCur, undefined,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
  pushFileToStorage(_file: File, _uploadType: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', _file, _file.name);

   /* const req = new HttpRequest('POST', '/post', formdata, {
      reportProgress: true,
      responseType: 'text'
    });*/

    // return this.http.request(req);
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();


    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/file/${_uploadType}`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
  pushMultifilesPartsWks(filesLight: FileUpload[],  filesUpload: FileUpload[], entity: string, criterias: Criterias, targetDataList: any[]): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileNum = 0;

    for (const fileUploadCur of filesUpload) {
      fileNum++;
      if (fileNum > 4 ) {
        break;
      }
      let fileName = fileUploadCur.readFile.nameFile;
      fileName = fileName.replace(/[&]/g, '%26');
      fileName = fileName.replace(/[ ]/g, '%20');
      formdata.append('file' + fileNum , fileUploadCur.readFile.fileObject, fileName);
      formdata.append('upload' + fileNum, JSON.stringify(filesLight[fileNum - 1]));
    }
    if (fileNum < 4) {
      for (let i = fileNum + 1 ; i < 5 ; i++) {
        formdata.append('file' + i , new Blob(), '');
        formdata.append('upload' + i , '');
      }
    }

    formdata.append('entity', entity);
    formdata.append('criterias', JSON.stringify(criterias));
    formdata.append('dataList', JSON.stringify(targetDataList));
    
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/files/parts`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFileToStorageWks(_file: File, currentDoc: WksDocsModel): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', _file, _file.name);
    formdata.append('wksDoc', JSON.stringify(currentDoc));
   /* const req = new HttpRequest('POST', '/post', formdata, {
      reportProgress: true,
      responseType: 'text'
    });*/

    // return this.http.request(req);
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();


    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/documents/wks`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFileAnchorages(_file: File,  _folder: string, _entity: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileName = _file.name;
    fileName = fileName.replace(/[&]/g, '%26');
    fileName = fileName.replace(/[ ]/g, '%20');

    formdata.append('file', _file, fileName);
    formdata.append('folder', _folder);
    formdata.append('entity', _entity);
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();

    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/file/anchorages`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFileSpecifications(_file: File,  _folder: string, _entity: string, _specBrand: string, _action: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileName = _file.name;
    fileName = fileName.replace(/[&]/g, '%26');
    fileName = fileName.replace(/[ ]/g, '%20');

    formdata.append('file', _file, fileName);
    formdata.append('folder', _folder);
    formdata.append('brand', _specBrand);
    formdata.append('entity', _entity);
    formdata.append('action', _action);
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();

    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/file/uploadSpecificationsFile`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFilePartsSupplies(_file: File,  _folder: string, _entity: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileName = _file.name;
    fileName = fileName.replace(/[&]/g, '%26');
    fileName = fileName.replace(/[ ]/g, '%20');

    formdata.append('file', _file, fileName);
    formdata.append('folder', _folder);
    formdata.append('entity', _entity);
    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();

    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/file/partssuplies`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFileToStorageWksdav(_file: File, currentDoc: WksDocsModel, _wksDocOld: string, _actionType: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileName = _file.name;
    fileName = fileName.replace(/[&]/g, '%26');
    fileName = fileName.replace(/[ ]/g, '%20');

    formdata.append('file', _file, fileName);
    formdata.append('wksDoc', JSON.stringify(currentDoc));
    formdata.append('wksDocOld', _wksDocOld);
    formdata.append('action', _actionType);

    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();


    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/documents/wksdav`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  pushFileToStorageWksWorkdav(_file: File, currentDoc: WksWorksDocs, _actionType: string, 
                              _dateSequence: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    let fileName = _file.name;
    fileName = fileName.replace(/[&]/g, '%26');
    fileName = fileName.replace(/[ ]/g, '%20');
    formdata.append('file', _file, fileName);    
    formdata.append('wksworksDoc', JSON.stringify(currentDoc));
    formdata.append('action', _actionType);
    formdata.append('dateSequence', _dateSequence);

    const curHttpHeaders: HttpHeaders  = this._userService.getHeaders();


    const localHttpHeaders = new HttpHeaders ({
      'Authorization': `${curHttpHeaders.get('Authorization')}`,
      'Accept': 'application/json',
      'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
    });

    const urlCur = `${environment.baseUrl}/upload/documents/wksworkdav`;
    return this._httpClient
        .post<HttpEvent<{}>>(urlCur, formdata, {
              headers: localHttpHeaders,
              reportProgress: true,
              observe: 'response',
          });
  }
  getFiles(): Observable<HttpResponse<String[]>> {
    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this._userService.getHeaders();
    const urlCur = `${environment.baseUrl}/upload/getallfiles`;

    return this._httpClient.get<String[]>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
}
