import { Component, OnInit, ViewChild, ViewEncapsulation, Input, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../../job/common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

import { WksCentralService } from '../../../../services/wks-central.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';

import { WksDocsModel, docs_sch, DocEvent } from '../../../../models/wks-equipment.model';
import { EmailData, NameValue } from '../../../../../../models/data.model';
import { WksEntityParams } from '../../../../models/wks-common.model';
import { environment } from '../../../../../../../environments/environment';

import { DocPdfComponent } from '../doc-pdf/doc-pdf.component';

@Component({
  selector: 'mdi-docs-table',
  templateUrl: './docs-table.component.html',
  styleUrls: ['./docs-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DocsTableComponent implements OnInit, OnDestroy {
  displayType: string;
  optionItem: string;
  entityCur: string;

  statusModal: string;

  sidenavCollapsed: boolean;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  @ViewChild('pdfModalViewer', {read: TemplateRef, static: false}) pdfModalViewer: TemplateRef<any>;
  @ViewChild('imgModalViewer', {read: TemplateRef, static: false}) imgModalViewer: TemplateRef<any>;
  @ViewChild('sharedMailModal', {read: TemplateRef, static: false}) sharedMailModal: TemplateRef<any>;

  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = docs_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterDoc: boolean;
  doCreateDoc: boolean;
  doUpdateDoc = [];
  wksDocToUpdate: WksDocsModel;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;
  emailData: EmailData;

  actionType: string;

  docList: WksDocsModel[];
  isInjected: boolean;
  lateralSize: string;

  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modal-lg modal-dialog-std modal-body-std',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    
  };
  valueWidth = false;
  modalText: any[];
  dataList: WksDocsModel[];
  doShared: boolean;
  currentSharedLink: string;
  currentLabelLink: string;

  wksEntityParams: WksEntityParams;
  mailSupport: string;

  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _ressourcesService: RessourcesService,
    private _wksCentralService: WksCentralService,
    private modalService: BsModalService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    if (this._userService.getUserLogged()) {
      this.entityCur = this._userService.getUserLogged().entity;
    } else {
      return;
    }
    // let labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });
    if (this.displayType === null) {
      this.displayType = 'list';
    }
    const labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.sidenavCollapsed = true;
    this.doShared = false;
    this.lateralSize = 'lateral-sidenav-50';

    this._wksCentralService.onSearchDoc
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: DocEvent) => {
      this.docList = event.searchResult;
      this.wksDocToUpdate = event.searchCriteria;
      this.isInjected = true;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
    this.wksEntityParams = this._ressourcesService.getWksEntityParams();
    this.mailSupport = this.wksEntityParams.supportMails[0];
    
    this.optionItem = labelItem;
    this.timeFormat = this._translate.getLocalFmt('timeFormat');

    if (this.displayType === 'list') {
      this.buildList();
    }
  }
buildList() {
    this.displayedColumns = [
      { field: 'equipType', headerName: this._translate.instant('equipType'), sortable: true, filter: true, resizable: true },
      { field: 'docBrand', headerName: this._translate.instant('docBrand'), sortable: true, filter: true, resizable: true },
      { field: 'docModel', headerName: this._translate.instant('docModel'), sortable: true, filter: true, resizable: true },
      { field: 'docYear', headerName: this._translate.instant('docYear'), sortable: true, filter: true, resizable: true },
      { field: 'docName', headerName: this._translate.instant('docName'), sortable: true, filter: true, resizable: true, width: 500 },
      /*{ field: 'stdCreation', headerName: this._translate.instant('stdCreation'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: 'stdLastupdate', headerName: this._translate.instant('stdLastupdate'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
      /*{ field: 'stdDisable', headerName: this._translate.instant('stdDisable'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(), width: 300
      },
    ];

    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }

    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getWksDocList();

    this._wksCentralService.onRegisterValid
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if (event === 'cancelRegister') {
          this.toggleSidenav('');

        }
        if (event === 'closeRegister') {
          this.toggleSidenav('');
          this.getWksDocList();
        }
        if (event === 'deleteDoc') {
          this.getWksDocList();
        }
      });
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  refresh() {
    this.getWksDocList();
  }
  newsearch() {
    this.displayType = 'newsearch';

    this._wksCentralService.onSearchDoc
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: DocEvent) => {
      this.docList = event.searchResult;
      this.wksDocToUpdate = event.searchCriteria;
      this.isInjected = true;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
  }
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
   
    actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="file"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('file tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="file" class="fa fa-download" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="share"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('share tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="share" class="fa fa-share-alt" style="font-size: 20px"></span></button>'
                  ;
   
    return actions;
  }
  getWksDocList() {
    this.selected = [];

    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this.isInjected) {
      this.dataSource.data = this.docList;
      this.cacheDatatemp = [...this.docList];
      this.extractColumnsName(this.dataSource.data);
      this.loadingSubject.next(false);
      this.loadingIndicator = false;
      return;
    }
    this._wksCentralService.getWksDocList(this._userService.getUserLogged().entity)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        if (err.status === 500) {
          let titleBox: string;
          let messageBox: string;
          titleBox = 'searching docs';
          messageBox = 'no doc found';
          

          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeDoc')) {
          this.deleteDocOK(dataCur);
        }
      });

  }
  ngOnDestroy() {

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editDoc(data);
                break;
            }
            case 'trash': {
                this.deleteDoc(data);
                break;
            }
            case 'file': {
              this.openFile(data);
              break;
          }
          case 'share': {
            this.generateLinkShare(data);
            break;
        }
        }
    }
}
  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows();
    this.doShared = false;
    // console.log('Row selected : ' + selectedRow );

  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateDoc = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  toggleSidenav(_arg: string) {

    this.isRegisterDoc = false;
    if (this.displayType === 'newsearch') {
      return;
    }

    if (_arg === 'registerDoc') {
      this.isRegisterDoc = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.m_sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.m_sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  editDocAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.wksDocToUpdate = selectedRow;
    

    this.actionType = 'update';

    this.doCreateDoc = false;
    this.toggleSidenav('registerDoc');
  }
  editDoc(row: WksDocsModel) {
    this.wksDocToUpdate = row;

    this.actionType = 'update';

    this.doCreateDoc = false;
    this.toggleSidenav('registerDoc');
  }
  addDoc() {
  
    this.doCreateDoc = true;
    this.actionType = 'add';

    this.toggleSidenav('registerDoc');
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteDoc(row: WksDocsModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'deleting docs';
    

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.docName;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeDoc', row );

  }
  deleteDocOK(row: WksDocsModel) {

      this._wksCentralService.saveWksDoc(row, 'delete')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteDoc');
        },
        () => {

        }
      );
  }
  saveDoc(row: WksDocsModel, fileExtension: string) {
    const path = row.docPath + '/' + row.docFilename;

    const mimeApp = this._wksCentralService.getContentMime(fileExtension);
    this._wksCentralService.downloadDoc(path, mimeApp)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          // window.location.href = response.url;
          this._wksCentralService.saveToFileSystem(response, row.docFilename, mimeApp);
        }, err => {
 
        }
      );

  }
  
  generateLinkShare(row: WksDocsModel) {
    this.doShared = false;
    // console.log('generateLinkShare ' + JSON.stringify(row));
    this._wksCentralService.getLinkSharing(row)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body as NameValue;

          // this.currentSharedLink = response.value;

          this.currentSharedLink = `${environment.baseUrl}/wks/sharedLink/?` + 'doclink=' + response.value;
          this.currentLabelLink = row.docName;
          this._wksCentralService.copyToClipboard(this.currentSharedLink);
          this.doShared = true;
          this.mailTo();
          // console.log('generateLinkShare getNewData  ' + JSON.stringify(response));

          /**
           *  https://web.whatsapp.com/send?phone=phoneNumHere&text=Hi&source=&data=
              https://api.whatsapp.com/send?phone=33671041697&text=Hello
              https://wa.me/33671041697?text=I'm%20interested%20in%20your%20car%20for%20sale
           */
        }, err => {
          console.log('error : ' + err.message);
        }
      );

  }
  mailTo(): void {
    /*const mailTo='mailto:jlegousse@majalog.fr?subject='+ this.currentLabelLink +'&cc=jlegousse@majalog.fr&bcc=jlegousse@majalog.fr&body=Bonjour,' +
    +'%20'+ this.currentSharedLink+ '';
    window.location.href = mailTo;*/
    this.emailData = {
      from: this.mailSupport,
      recipients: '',
      copy: '',
      ccopy: '',
      subject: this.currentLabelLink,
      body: this.currentSharedLink,
      attachmentPath: ''
    };
    this.openMailShared();
  }
  openFile(row: WksDocsModel) {
    const fileExtension = row.docFilename.split('.').pop();
    if ( fileExtension === 'pdf') {
      this.openPdf(row);
    } else if ((fileExtension === 'jpeg') || 
                (fileExtension === 'jpg') || 
                (fileExtension === 'png')  ) {
      this.openImg(row);
    } else {
      this.saveDoc(row, fileExtension);
    }
  }
  openPdf(row: WksDocsModel) {
    // this.openModal(this.pdfModalViewer, row);    
    this.openModalPdf(row);
  }
  openImg(row: WksDocsModel) {
    this.openModal(this.imgModalViewer, row);    
  }
  openMailShared() {
    
    this.modalRef = this.modalService.show(this.sharedMailModal, this.configModal);
  }
  openModal(template: TemplateRef<any>, row: WksDocsModel) {
    this.dataList = [];
    this.dataList.push(row);
    this.modalText = [row.docName];
    this.modalRef = this.modalService.show(template, this.configModal);
  }
  openModalPdf(row: WksDocsModel) {
    this.dataList = [];
    this.dataList.push(row);
    this.modalText = [row.docName];
    this.statusModal = 'pdf_read';

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '85%';
    dialogConfig.width = '60%';

    dialogConfig.data = {
      statutCall: this.statusModal,
      dataList: this.dataList,
      titleReport: this.modalText[0]
      };
    
      const dialogRef = this._dialog.open(DocPdfComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        this._dialog.closeAll();
      });

  }
  closeModal() {

    this.modalRef.hide();
  }
  setModalClass() {
    this.valueWidth = !this.valueWidth;
    const modalWidth = this.valueWidth ? 'modal-lg' : 'modal-sm';
    this.modalRef.setClass(modalWidth);
  }
  closeEmailForm(dataReturn: any) {
    if (dataReturn.close) {
      this.closeModal();
    }
  }
}
