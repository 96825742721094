import { Component, OnInit, Input , OnChanges, SimpleChanges, ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
// import 'ag-grid-enterprise';
import {GridOptions, RowNode} from 'ag-grid-community/main';
import 'numeral/locales';
import { catchError, takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material';
import { UserService } from '../../../../../../services/user.service';
import { ReportInt, AgGridColModel, ColReportModel, ColLabelRow, RequestInt, RowPinned, KeyValue, SubReports, SubReport } from '../../../../models/job-data.model';
import { JobDataService } from '../../../../services/job-data.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { GridTools } from '../../../../../common/tools/gridTools';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
// https://www.ag-grid.com/javascript-grid-filter-component/
import { FilterCellComponent } from '../filters/filter-cell/filter-cell.component';
import { NumericRendererComponent } from '../renderers/numeric-renderer/numeric-renderer.component';
import { AlphanumRendererComponent } from '../renderers/alphanum-renderer/alphanum-renderer.component';
import { RowPinnedComponent } from '../rows/row-pinned/row-pinned.component';
import { DatetimeRendererComponent} from '../renderers/datetime-renderer/datetime-renderer.component';
import { DataDetailRendererComponent } from '../renderers/datadetail-renderer/datadetail-renderer.component';
import { DetailiconRendererComponent } from '../renderers/detailicon-renderer/detailicon-renderer.component';


@Component({
  selector: 'mdi-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportGridComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() reportToExecute: ReportInt;
  @Input() titleReport: string;
  @Input() subReportData: any;

  @ViewChild('modalViewer', {read: TemplateRef, static: false}) modalViewer: TemplateRef<any>;

  private readonly onDestroy = new Subject<void>();

  gridOptions: GridOptions;
  gridApi: any;
  gridColumnApi: any;
  colDef: any;
  rowData: any;
  dataLabel: Map<string, ColLabelRow>;
  userCurLang: string;
  requestCur: RequestInt;
  // https://stackblitz.com/edit/delete-rows-mat-table?file=app%2Ftable-selection-example.ts
  dataSource = new MatTableDataSource();
  configLoaded: boolean;
  dataLoaded: boolean;

  filtersSetting: boolean;
  pinnedRowsSetting: boolean;
  filtersList: string[];
  localeText: any;
  frameworkComponents: any;
  columnNameList: any;
  rowsPinnedList: RowPinned[];
// rows 
  getRowStyle: any;
  pinnedTopRowData: any;
  pinnedBottomRowData: any;
  timeFormat: any;
  hourFormat: any;
  dateFormat: any;
  reportPagination: boolean;
  reportDocument: boolean;
  getRowHeight: any;
  isFullWidthCell: any;
  fullWidthCellRenderer: any;
  subReports: SubReports = null;
  isSubReport = false;
  reportPdf: any;
  currentNode: any;
  modalRef: BsModalRef;
  config = {
    class: 'modal-dialog-centered modal-lg  modal-dialog-std modal-body-std',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    
  };
  modalText: any[];

  constructor(private _userService: UserService, private router: Router,
        private _translate: TranslateService,
        private _jobDataService: JobDataService,
        private modalService: BsModalService,
        private _sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges) {

    this.initData();

  }
  ngOnInit() {

    this.initData();
  }
  openModal(template: TemplateRef<any>) {

    this.modalText = [this.reportToExecute.reportLabel];
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {

    this.modalRef.hide();
  }
  
  initData() {
    if (this.subReportData) {
      this.isSubReport = true;
    }
    if (this.reportToExecute.reportChildren === null) {
      this.subReports = undefined;
    } else {
      const jsonObject = JSON.parse(this.reportToExecute.reportChildren);
      this.subReports = jsonObject.subreports as SubReports;
    }
    this.getOptionsReport();
    this.buildOptions();
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.hourFormat = this._translate.getLocalFmt('hourFormat');
    this.dateFormat = this._translate.getLocalFmt('dateFormat');

    this.configLoaded = false;
    this.dataLoaded = false;

    this.filtersSetting = false;
    this.pinnedRowsSetting = false;
 
    this.userCurLang = this._userService.getUserLogged().userlang;
    this.colDef = [];
    this.rowData = [];
    this.localeText = this._translate.getAgGridLabels();
    this.getRequest() // read request
    .then (() => {
     
        this.getListRequestLabels() // reading labels
        .then (() => {
          this.buildColumns();
//           this.buildReport();
          this.configLoaded = true;
        })
        .then((error) => { // error read label
          // console.log('Label:'+ error);
        }); 
        
      })
    .then((error) => { // error reading request
      // console.log('Request:'+ error);
    });

  }
  getOptionsReport() {
    this.reportPagination = false;
    this.reportDocument = false;
    
    if (this.reportToExecute.reportOptions) {
      try {
        const jsonObject = JSON.parse(this.reportToExecute.reportOptions);
        for (const optCur  of jsonObject.options) {
          const optTmp = optCur as KeyValue;
          // console.log(optTmp.key + ' ' + optTmp.value);
          if (optTmp.key === 'pagination') {
            this.reportPagination = optTmp.value;
          }
          if (optTmp.key === 'documents') {
            this.reportDocument = optTmp.value;
          }
        }      
        // this.detailRowHeight = 260;
        // this.detailCellRenderer = 'datadetailRendererComponent';
      } catch (error) {
        console.log(error);
      }
    }

  }
  buildColumns() {
    // this.generateTestCol();
    const jsonObject = JSON.parse(this.reportToExecute.reportColumns);

    this.columnNameList = jsonObject.columns;
    this.columnNameList.sort(function(obj1: any, obj2: any) {
      return obj1.ranking > obj2.ranking ? 1 : -1;
    });
    this.rowsPinnedList = jsonObject.rowpinned;
    this.setColumnReportList();
  }
  buildDataTest() {

    this.rowData = this.generateTestData();
    this.dataSource.data = this.rowData;
    this.setListFilter();
  }
  buildOptions() {
  
    this.gridOptions = {
      // rowData: this.dataSource.data,
      floatingFilter: true,
      groupMultiAutoColumn: true,
      groupHideOpenParents: true,
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      suppressCsvExport: false,
      suppressExcelExport: false,
      pagination: this.reportPagination,
      paginationAutoPageSize: true,
      // paginationPageSize: 20,
      // columnDefs: this.colDef,
      rowDeselection: true,
      rowSelection: 'single',
      rowGroupPanelShow: 'always',
      defaultColDef: {sortable: true, resizable: true, filter: true },
      autoGroupColumnDef: {
        filterValueGetter: function(params: any) {
          const colGettingGrouped = params.colDef.showRowGroup;
          const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
          return valueForOtherCol;
        },
      },
      isFullWidthCell: (params: any) => {
        if (params.data.isFullWidthCell === undefined) { 
          return false; 
        } else {
          return (params.data.isFullWidthCell === true);
        }
      },
      // fullWidthCellRendererFramework: 'DataDetailRendererComponent',
      // https://stackoverflow.com/questions/40407578/how-to-translate-no-rows-to-show-message-in-ag-grid
      // localeText: {noRowsToShow: 'No hay nada'}
    };
    this.frameworkComponents = { 
      filterCellComponent: FilterCellComponent,
      rowPinnedComponent: RowPinnedComponent,
      numericRendererComponent: NumericRendererComponent,
      alphanumRendererComponent: AlphanumRendererComponent,
      datetimeRendererComponent: DatetimeRendererComponent,
      dataDetailRendererComponent: DataDetailRendererComponent,
      detailiconRendererComponent: DetailiconRendererComponent
    };

    this.getRowHeight = function(params: any) {

      if (params.data.isFullWidthCell === undefined) { 
        return 25; 
      } else if (params.data.isFullWidthCell === true) { 
        return 200; 
      } else { return 25; }
    };
    // this.fullWidthCellRenderer = "fullWidthCellRenderer";
    this.fullWidthCellRenderer = 'dataDetailRendererComponent';
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.buildDataTest() ;
    this.launchReport()
    .then (() => {
      
      this.setListFilter();
      this.filtersSetting = true;

      // row bottom pinned
      this.buildPinnedRows();
      this.pinnedRowsSetting = true; 

    // stting columns size
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach(function(column: any) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
      // this.gridApi.sizeColumnsToFit();
    })
    .then((error) => { // error read label
     //  console.log('Read data:'+ error);
    }); 
  }
  displayPdf() {
    this.openModal(this.modalViewer);
    
  }
  onFilterTextBoxChanged(event: any) {
   
    const valueFilter = event;
    this.gridOptions.api.setQuickFilter(valueFilter);
    this.gridApi.onFilterChanged();
  }
  onFilterChanged () {
    // https://www.ag-grid.com/javascript-grid-filtering/
    // https://github.com/ag-grid/ag-grid/issues/972
    // console.log('test');
    this.gridApi.selectAllFiltered();
    const list = this.gridApi.getSelectedRows();
    this.gridApi.deselectAll();
  }
  setData() {
    this.gridOptions.api.setRowData(this.rowData);
  }
  setColumnReportList() {

    let col: AgGridColModel;
    this.filtersList = [];
    if (this.colDef.length > 0) {
      return;
    }
    let iCol = 0;
    for (const colCur of this.columnNameList) {
      const colTmp  = colCur as ColReportModel;
      if (colTmp.colName === '') {
        continue;
      }
      iCol++;
     
      let colFmtNameCur = 'alphanumeric';
      let colFmtStringCur = 'alphanumeric';
      if (this.dataLabel.get(colTmp.colName).colAttributes) {
        const stringFmt = this.dataLabel.get(colTmp.colName).colAttributes.split(';');
        colFmtNameCur = stringFmt[0];
        colFmtStringCur = stringFmt[1];
      }
      
      // const colFmtStringCur = 'intCurrency';
      // console.log(colTmp.colName);
      const colHeader = this.dataLabel.get(colTmp.colName).colHeader;
      let filterType = null;
      let menuTab = null;
      if (colCur.filterInput) { filterType =  this.getFilterType(colTmp.colType); }
      if (colCur.filterList) {
        filterType = 'filterCellComponent';
        menuTab = 'filterMenuTab';
        this.filtersList.push(colCur.colName);
      }
      let posPinned = 'left';
      if (colFmtNameCur === 'numeric') {
        posPinned = 'right';
      }

      col = {
        headerName: colHeader,
        // headerName: colTmp.colName,
        field: colTmp.colName,
        filter: filterType,
        filterParams: {
          clearButton: true,
          applyButton: true,
        },
        // cellRendererSelector: GridTools.cellRendererSelector,
        // cellRenderer: cellRendererClass,
        // https://www.ag-grid.com/javascript-grid-cell-rendering-components/
        /* cellRendererParams: { colFmtName: colFmtNameCur, colFmtStrparams.data.reportCompleted !== undefineding: colFmtStringCur, currentLang: this.userCurLang, 
            masterDetail:this.detailPresent, colRank: iCol},*/
        // type:  ['nonEditableColumn'],
        width: null,
        editable: false,
        pinned: colCur.pinning,
        rowGroupIndex: null,
        colId: colTmp.colName,
        sortable: true, 
        resizable: true, 
        enableRowGroup: colCur.grouping,
        menuTabs: [menuTab],
        pinnedRowCellRenderer: 'rowPinnedComponent',
        pinnedRowCellRendererParams: { style: { 'font-style': 'italic', 'font-weight': 'bold', 'text-align': posPinned },
                      colFmtName: colFmtNameCur, colFmtString: colFmtStringCur, currentLang: this.userCurLang, pinnedRow: colCur.pinnedRow },
      };

      col.cellRendererParams = { colFmtName: colFmtNameCur, colFmtString: colFmtStringCur, currentLang: this.userCurLang};
      col.cellRendererSelector = GridTools.cellRendererSelector;

      // =====
      
      this.colDef.push(col);
    }
    if (this.subReports !== undefined) {
      if (this.subReports.zoomPresent) {
        this.colDef.splice(0, 0, this.buildFullWidthRow('zoom', undefined, undefined));
      }
      if (this.subReports.subReport !== undefined) {
        const curIdx = 0;
        for (const subCur of this.subReports.subReport) {
          if (subCur.reportInitialAction.toLocaleLowerCase() === 'pdf') {
            this.colDef.splice(0, 0, this.buildFullWidthRow('docPdf', subCur.reportInitialAction, subCur.reportName));
          } else {
            this.colDef.splice(0, 0, this.buildFullWidthRow('subReport', subCur.reportInitialAction, subCur.reportName));
          }
        }
      }
    }
  }
  buildFullWidthRow(_detailType: string, _detailAction: string , _detailName: string): any {
  let widthCur = 25;
  if (_detailType !== 'zoom') {
    widthCur = 50 ;
  }
  return  { field: '', headerName: 'detail', 
      width: widthCur,
      minWidth: widthCur, 
      maxWidth: widthCur,
      sortable: false, 
      resizable: false,
      filter: null,
      cellRenderer: 'detailiconRendererComponent',
      cellRendererParams: { detailType: _detailType, detailAction: _detailAction, detailName: _detailName},
      cellStyle: function(params: any) {
          return {margin: '0px', padding: '0px'};
          /* if (params.value=='Police') {
              //mark police cells as red
              return {color: 'red', backgroundColor: 'green'};
          } else {
              return null;
          }*/
        },
      };
  }
  setListFilter() {
    this._jobDataService.initFilterValues();
    for (const colName of this.filtersList) {
      const mapTmp  =  CommonMethods.sqlArrayGroup(this.dataSource.data, colName);
      mapTmp.sort(function(obj1: any, obj2: any) {
        return obj1.a > obj2.a ? 1 : -1;
      });
      this._jobDataService.settingFilterValues(colName, mapTmp);
      // console.log(mapTmp);
    }
  }
  getFilterType(dataType: string): string {
    let filterString = '';
    switch (dataType) {
      case 'string': {
        filterString = 'agTextColumnFilter';
        break;
      }
      case 'Date': {
        filterString = 'agDateColumnFilter';
        break;
      }
      case 'number': {
        filterString = 'agNumberColumnFilter';
        break;
      }
      default: {
        filterString = 'agTextColumnFilter';
        break;
      }
    }
    return filterString;
  }
  public onCellClicked(e: any) {
    // console.log('action '+e.event.target.getAttribute('data-action-type') + ' text '+e.event.target.innerText);
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
      const selectedNodeRefresh: RowNode [] = this.gridApi.getSelectedNodes();
      const selectedNode: RowNode = selectedNodeRefresh[0];
      const nodeIndex = this.gridApi.getFocusedCell().rowIndex;
      // console.log('nodeIndex '+nodeIndex );
      const data = e.data;
      
      if ((!data) || (data === 'undefined')) { return; }
      const actionType = e.event.target.getAttribute('data-action-type');

      switch (actionType) {

        case 'detail-open': {
            // console.log('Detail');
          this.addRowDetail(nodeIndex, selectedNode, selectedNodeRefresh, undefined);
          break;
        }
        case 'detail-close': {
          // console.log('Detail');
          if ((selectedNode.data.detailIsOpen !== undefined) && (selectedNode.data.detailIsOpen === true)) {
            this.removeRowDetail(selectedNode, selectedNodeRefresh);
           
          }
          this.closeModal();
          break;
        }
        case 'subreport-open': {
          // console.log('Detail');
          this.addRowDetail(nodeIndex, selectedNode, selectedNodeRefresh, e.event.target.innerText);
          // console.log('action '+e.event.target.getAttribute('data-action-type') + ' text '+e.event.target.innerText);
          break;
        }
        case 'subreport-close': {
          if ((selectedNode.data.detailIsOpen !== undefined) && (selectedNode.data.detailIsOpen === true)) {
            this.removeRowDetail(selectedNode, selectedNodeRefresh);
            break;
          }
          break;
        }
        case 'pdf-open': {
          this.reportPdf = e.event.target.innerText;
          this.currentNode = selectedNode;
          this.displayPdf();
        break;
        }                            
      }
    }
  }
  addRowDetail(_nodeIndex: any, _selectedNode: RowNode, _selectedNodeRefresh: RowNode [], _subReportAction: string) {

    _nodeIndex++;

    const newItem = Object.create(null);
    for (const k of Object.keys(_selectedNode.data)) {
      newItem[k] = _selectedNode.data[k];
    }
    newItem['isFullWidthCell'] = true;
    newItem['_colDef'] = this.colDef;
    newItem['_dataLabel'] = this.dataLabel;
    newItem['_currentLang'] = this.userCurLang;
    newItem['_reportOrig'] = this.reportToExecute;
    newItem['_subReport'] = _subReportAction;
    newItem['_colTitle'] = 'octa_code';

    // console.log(newItem);
    const res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: _nodeIndex
    });
    res.add.forEach((rowNode: RowNode) => {
      _nodeIndex = rowNode.id;
    });

    _selectedNode.data['detailIsOpen'] = true;
    _selectedNode.data['detail'] = true;
    _selectedNode.data['detailNodeIndex'] = _nodeIndex;
    
    _selectedNode.setData(_selectedNode.data);
    this.gridApi.refreshCells({
      rowNodes: _selectedNodeRefresh,
      force: true,
    });
  }
  removeRowDetail(_selectedNode: RowNode, _selectedNodeRefresh: RowNode [])  {
    const rowIndex = _selectedNode.data.detailNodeIndex;
    const rowDetail: RowNode = this.gridApi.getRowNode(rowIndex);
    rowDetail.selectThisNode(true);
    const rowNodeToDelete: RowNode [] = this.gridApi.getSelectedRows();
    rowNodeToDelete.splice(0, 1);
    const res = this.gridApi.updateRowData({
      remove: rowNodeToDelete,
    });
    // this.printResult(res);
    _selectedNode.data['detailIsOpen'] = false;
    _selectedNode.data['detail'] = false;
    _selectedNode.data['detailNodeIndex'] = undefined;
    _selectedNode.setData(_selectedNode.data);
    this.gridApi.refreshCells({
      rowNodes: _selectedNodeRefresh,
      force: true,
    });
    this.gridApi.redrawRows();
  }
  getRequest() {
    
    this.requestCur = null;
    return new Promise<void>((resolve, reject) => {
      this._jobDataService.getRequestRef(this.reportToExecute.requestRef, true)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
          data => {
            this.requestCur  = data.body[0];
            resolve();
          }, err => {
            this.requestCur = null;
            reject(err);
          }
        );
    });
  }

  getListRequestLabels() {
    const requestCol = JSON.parse(this.requestCur.requestColumns);
    let colNameList = 'titleRequest';
    for (const col of requestCol.columns) {
      colNameList += 'XX' + col.colName;
    }
    return new Promise<void>((resolve, reject) => {
      this._jobDataService.getListRequestLabels(this.reportToExecute.requestRef, this.userCurLang, colNameList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          this.setValuesLabels([...data.body]);
          resolve();
        }, err => {
          this.dataLabel = null;
          reject(err);
        }
      );
    });
  }
  setValuesLabels(_dataArray: Array<any>) {

    this.dataLabel = new Map<string, ColLabelRow>();
    
    for (const col of _dataArray) {
      // console.log(col.colName);
      if (col.colName === 'titleRequest') {
        continue;
      }
      const dataCur: ColLabelRow = {
        id: col.id,
        colName: col.colName,
        userLang: col.userLang,
        checkLabel: false,
        colHeader: col.colHeader,
        colToolTip: col.colToolTip,
        colAttributes: col.colAttributes,
      };

      this.dataLabel.set(col.colName, dataCur);
    } // end for

  }
 
  launchReport() {

    // console.log('deleteEntity Row clicked: ', row);
    // let messageTranslate: string;
    let nbLimit = this.reportToExecute.reportSample;
    if (this.statutCall === 'execute') { nbLimit = 10000; }
    if (this.isSubReport) {
     this.reportToExecute.subReportData = '{\"subReportData\":' + JSON.stringify(this.subReportData) + '}';
    }
    return new Promise((resolve, reject) => {
      this._jobDataService.launchReport(this.reportToExecute, nbLimit)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes) => {
          const response = JSON.parse(JSON.stringify(retMes.body));
          // console.log(retMes.body);
          this.dataSource.data = retMes.body;
          this.dataLoaded = true;
          resolve(retMes.body);
        }, err => {
          this.dataLabel = null;
          reject(err);
        }
      );
    });
  }
  buildPinnedRows() {

    if ((this.rowsPinnedList === null) || (this.rowsPinnedList === undefined)) {
      return;
    }
    const topRowPinned = [];
    const bottomRowPinned = [];
    let rowCur = [];
    for (const rowPinned of this.rowsPinnedList) {
      // console.log(rowPinned);
      rowCur = [];
      rowCur[rowPinned.colNameDisplay] = rowPinned.rowLabel;
      for (const colAgg of rowPinned.colAggregate) {
        let colType = 'alphanumeric';
        if (this.dataLabel.get(colAgg.colName).colAttributes) {
          const stringFmt = this.dataLabel.get(colAgg.colName).colAttributes.split(';');
          colType = stringFmt[0];

        }
        if (colType) {
          let result: any;
          result = CommonMethods.sqlAggregate(this.dataSource.data, colAgg.colName, colAgg.aggregateType, colType);
          rowCur[colAgg.colName] = result[0].a;
        }
      }

      if (rowPinned.rowPosition === 'colBottom') {
        bottomRowPinned.splice(rowPinned.rowRank, 0, rowCur);
      } else {
        topRowPinned.splice(rowPinned.rowRank, 0, rowCur);
      }
    }

    if (bottomRowPinned.length > 0 ) {
      this.gridApi.setPinnedBottomRowData(bottomRowPinned);
      this.pinnedRowsSetting = true;
    }
    if (topRowPinned.length > 0 ) {
      this.gridApi.setPinnedTopRowData(topRowPinned);
      this.pinnedRowsSetting = true;
    }

  }
  createRowsPinned(_count: number, _prefix: string) {
    const rowCur = []; // this.dataSource.data[0];
    const result = [];
    for (let i = 0; i < _count; i++) {
      for (const colCur of this.columnNameList) {
        const colTmp  = colCur as ColReportModel;
        rowCur[colCur.colName] = _prefix + '_' + colCur.colName;
      }
      result.push(rowCur);
    }
    return result;
  }
  generateTestCol() {
    const columnNameList = ['ColA', 'colB'];
    for (const colCur of columnNameList) {
      
      const col = {
        headerName: colCur,
        field: colCur,
        filter: 'agTextColumnFilter',
        type:  ['nonEditableColumn'],
        width: null,
        editable: false,
        pinned: null,
        rowGroupIndex: null,
        colId: colCur,
        sortable: true, 
        resizable: true, 
        enableRowGroup: false,
      };
      this.colDef.push(col);
    }
  }
  generateTestData(): any[] {
    const rowData = [];
    for (let i = 0; i < 100; i++) {
      const rowItem = {};
      for (const colCur of this.colDef) {
        rowItem[colCur.field] = CommonMethods.randomString(5) + ' ' + Math.floor(Math.random() * 10000).toString();

      }
      rowData.push(rowItem);
    }

    return rowData;
  }
  printResult(res: any) {
    console.log('---------------------------------------');
    if (res.add) {
      res.add.forEach(function(rowNode: RowNode) {
        console.log('Added Row Node', rowNode);
      });
    }
    if (res.remove) {
      res.remove.forEach(function(rowNode: RowNode) {
        console.log('Removed Row Node', rowNode);
      });
    }
    if (res.update) {
      res.update.forEach(function(rowNode: RowNode) {
        console.log('Updated Row Node', rowNode);
      });
    }
  }
}
