import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CalendarService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    setCalObject(calObjectArg) {
        this.calObject = calObjectArg;
    }
    getCalObject() {
        return this.calObject;
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    getCalendarEvent(eventId) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getCalendarEvent/?` + 'eventId=' + eventId;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    getEventsByType(stdEntity, calAppli, calType) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getEventsByType/?`
            + 'stdEntity=' + stdEntity + '&calAppli=' + calAppli + '&calType =' + calType;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    getEventsByActor(stdEntity, calAppli, calType, calActor) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActor/?`
            + 'stdEntity=' + stdEntity
            + '&calAppli=' + calAppli
            + '&calType =' + calType
            + '&calActor =' + calActor;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    getEventsByLinkId(stdEntity, calAppli, calType, calLinkId) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getEventsByLinkId/?`
            + 'stdEntity=' + stdEntity
            + '&calAppli=' + calAppli
            + '&calType =' + calType
            + '&calLinkId =' + calLinkId;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    getEventsByActors(stdEntity, calActors, entityTz) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActors/?`
            + 'stdEntity=' + stdEntity
            + '&calActors=' + calActors
            + '&entityTz=' + entityTz;
        // urlService = CommonMethods.cleanURLString(urlService);
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    getEventsByActorsDates(stdEntity, calActors, startDate, endDate, entityTz) {
        const localHttpHeaders = this.httpHeaders;
        const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActorsDate/?`
            + 'stdEntity=' + stdEntity
            + '&calActors=' + calActors
            + '&startDate=' + startDate
            + '&endDate=' + endDate
            + '&entityTz=' + entityTz;
        // urlService = CommonMethods.cleanURLString(urlService);
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    saveWksCalendar(_eventCur, entityTz, action, actorEmail, ownerId) {
        const urlCur = `${environment.baseUrl}/wks/jobs/wksCalendar/save/?`
            + 'entityTz=' + entityTz
            + '&action=' + action
            + '&actorEmail=' + actorEmail
            + '&ownerId=' + ownerId;
        const body = JSON.stringify(_eventCur);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    handleErrorObservable(error) {
        console.error(error.message || error);
        return observableThrowError(error.message || error);
    }
}
CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.HttpClient)); }, token: CalendarService, providedIn: "root" });
