import { Injectable, EventEmitter } from '@angular/core';
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import {catchError, tap, map} from 'rxjs/operators';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';
import { environment } from '../../../../environments/environment';
import { DatasourceInt, RequestInt, ColLabelList, ColReportInt,  SubReports,
        ReportInt, ColReportService, RowPinned, MenuInt, Widgets, UserEnvtInt } from '../models/job-data.model';


@Injectable ({
  providedIn: 'root'
})
export class JobDataService {

  curHttpHeaders: HttpHeaders;
  httpParams: HttpParams;
  public onRegisterValid: EventEmitter<string> = new EventEmitter<string>();
  public displayGenerateTime: EventEmitter<string> = new EventEmitter<string>();
  public onChangeStatut = new BehaviorSubject<string>(undefined);
  public filtersValues: any;

  colReportService: ColReportService;
  userEnvt: UserEnvtInt;
  constructor(private _userService: UserService, public  httpClient: HttpClient, private _translate: TranslateService) {
    
   }
  // agGrid customized 
  settingFilterValues(_filterName: string, _values: any) {
    if (this.filtersValues === undefined) {
      // this.filtersValues.set(_filterName, _values);
      this.initFilterValues();
    }
    this.filtersValues.set(_filterName, _values);
  }
  initFilterValues() {
      this.filtersValues = new Map();
  }
  // user envt
  setUserEnvt(_userEnvt: UserEnvtInt) {
    this.userEnvt = _userEnvt;
  }
  getUserEnvt(): UserEnvtInt {
    return this.userEnvt;
  }
  getUserEnvtElement(_elemType: string, _elemName: string): any {

    if (this.getUserEnvt() === undefined) {
      const userEnvt: UserEnvtInt = {
        id: this.getUserLogged().id,
        userenvt: undefined,
      };
      this.setUserEnvt(userEnvt);
      return undefined;
    }
    if (this.getUserEnvt().userenvt === undefined) {
      return undefined;
    }
    switch (_elemType) {
      case 'dashboard': {
        const userEventLoc = JSON.parse(this.getUserEnvt().userenvt);
        const dashboards = JSON.parse(userEventLoc['dashboards']);
        if (dashboards === undefined) {
          return undefined;
        }
        for (const dashboardCur of dashboards) {
          // const dashboardCur = JSON.parse(dashBoard);
          if (dashboardCur.dashboardName === _elemName) {
            return dashboardCur;
          }
        }
        break;
      }
      case 'dashboards': {
        const dashboards = JSON.parse(this.getUserEnvt().userenvt).dashboards;
        if (dashboards === undefined) {
          return undefined;
        } else {
          return JSON.parse(dashboards);
        }

      }
      default: {
        break;
      }
    }

  }
  getUserLogged() {
    return this._userService.getUserLogged();
  }
  // Event
  changeStatutEvent(event: string) {
      this.onChangeStatut.next(event);
    }
  // columnList: reportSheets
  // changeColReportService
  changeColReportService (_widgets: Widgets, _subReports: SubReports, _rowPinned: RowPinned[], 
            _cur: ColReportInt[], _doEvent: boolean, _statut: string, _isCompleted: boolean ) {

    if (_subReports) { this.colReportService.subReports = _subReports; }
    if (_rowPinned) { this.colReportService.rowPinned = _rowPinned; }
    if (_cur) { this.colReportService.colCur = _cur; }
    if (_statut)  { this.colReportService.colStatut = _statut; }
    if (_doEvent) { this.colReportService.colDoEvent = _doEvent; }
    if (_isCompleted) { this.colReportService.isCompleted = _isCompleted; }
    if (_widgets) { this.colReportService.widgets = _widgets; }
  }
  setColReportService() {
    this.colReportService = new ColReportService();
  }

  getColReportService(): ColReportService {
    return this.colReportService;
  }

  // Datasource
  getDatasources(): Observable<HttpResponse<DatasourceInt[]>> {
      const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/`;
      this.curHttpHeaders  = this._userService.getHeaders();
      return this.httpClient.get<DatasourceInt[]>(urlCur,
        {
          headers: this.curHttpHeaders,
          observe: 'response'
        });
    }

  getDatasource(_dbsLabel: string): Observable<HttpResponse<DatasourceInt>> {
    const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/` + _dbsLabel;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<DatasourceInt>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }

  deleteDatasource(_dbsLabel: string): Observable<HttpResponse<string>> {

    const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/` + _dbsLabel;
    this.curHttpHeaders  = this._userService.getHeaders();

    return this.httpClient
      .delete<string>(urlCur, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  testConnect(_datasource: DatasourceInt): Observable<HttpResponse<boolean>> {
      const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/testJobDbs/`;
      this.curHttpHeaders  = this._userService.getHeaders();
      const body = JSON.stringify(_datasource);

      return this.httpClient
        .put<boolean>(urlCur, body, {
          headers: this.curHttpHeaders,
          observe: 'response',
        }).pipe(
          catchError(this.handleErrorObservable), );

    }
  saveDatasource(_datasource: DatasourceInt, statutCall: String ): Observable<HttpResponse<DatasourceInt>> {
    let urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/updateJobDbs/`;
    this.curHttpHeaders  = this._userService.getHeaders();
    if (statutCall === 'createDatasource') {
      urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/addJobDbs/`;
    }

    const body = JSON.stringify(_datasource);
    // this.retrieveUser();

    return this.httpClient
      .put<DatasourceInt>(urlCur, body, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  getListDbs(_requestEngine: string): Observable<HttpResponse<DatasourceInt[]>> {

    const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/listdbs/` + _requestEngine;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<DatasourceInt[]>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }
  // Requests
  getListRequests(_criteria: string): Observable<HttpResponse<RequestInt[]>> {
    const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` + 'criteria=' + _criteria;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<RequestInt[]>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }

  getRequestLabel(_requestLabel: string): Observable<HttpResponse<RequestInt[]>> {
    const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` +
              'criteria=none&requestBy=requestLabel&requestSearch=' + _requestLabel;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<RequestInt[]>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }
  getRequestRef(_requestRef: string, _onlyDefault: boolean): Observable<HttpResponse<RequestInt[]>> {
    let criteria = 'onlyDefault';
    if (!_onlyDefault) { criteria = 'none'; }
    const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` +
                        'criteria=' + criteria + '&requestBy=requestRef&requestSearch=' + _requestRef;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<RequestInt[]>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }
  deleteRequest(_id: string): Observable<HttpResponse<string>> {

    const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/` + _id;
    this.curHttpHeaders  = this._userService.getHeaders();

      return this.httpClient
        .delete<string>(urlCur, {
          headers: this.curHttpHeaders,
          observe: 'response',
        }).pipe(
          catchError(this.handleErrorObservable));
  
    }
    pdfViewerRequest(_path: string): Observable<HttpResponse<any>> {
      const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/pdfViewer/?` +
                'path=' + _path;
      const localHttpHeaders  = this._userService.getHeaders();
      localHttpHeaders.set('Accept', 'application/pdf');

      return this.httpClient.get<any> ( urlCur,
        {
          headers: localHttpHeaders,
          observe: 'response',
          responseType: 'blob' as 'json',
        }).pipe(
          map((result: any) => {
              return result;
          })
      );
      /*
      return this.httpClient
        .put<any>(urlCur, body, {
          headers: localHttpHeaders,
          observe: 'response',
          responseType: 'blob' as 'json',
        }).pipe(
          catchError(this.handleErrorObservable) );
          */
  }
  getRequestChart(_requestTodo: RequestInt): Observable<HttpResponse<any[]>> {
    
    const body = JSON.stringify(_requestTodo);
    // const restructionsValue = JSON.stringify(_restructionsValue);
    const restructionsValue = 'test';
    
    /*let urlCur = `${environment.baseUrl}/jobdata/jobdatareports/requestchart/?`+
              'requestRef='+_requestRef+'&restructionsValue='+restructionsValue;*/
  
    const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/requestchart/`;
    this.curHttpHeaders  = this._userService.getHeaders();

    return this.httpClient
      .put<any[]>(urlCur, body, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable) );
  }
  
  launchReport(_report: ReportInt, _sample: number): Observable<HttpResponse<any[]>> {
      const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/launchreport/?` +
                'sample=' + _sample;
      this.curHttpHeaders  = this._userService.getHeaders();
      _report.tagsauthorized = this._userService.getUserLogged().grpCur.tagsauthorized;
      _report.tagsprohibited = this._userService.getUserLogged().grpCur.tagsprohibited;
      const body = JSON.stringify(_report);

      return this.httpClient
        .put<any[]>(urlCur, body, {
          headers: this.curHttpHeaders,
          observe: 'response',
        }).pipe(
          catchError(this.handleErrorObservable) );
    }
  testRequest(_request: RequestInt): Observable<HttpResponse<string>> {
      const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/testJobRequest/`;
      this.curHttpHeaders  = this._userService.getHeaders();
      const body = JSON.stringify(_request);

      return this.httpClient
        .put<string>(urlCur, body, {
          headers: this.curHttpHeaders,
          observe: 'response',
        }).pipe(
          catchError(this.handleErrorObservable) );

    }
  saveRequestDataAccess(_request: RequestInt): Observable<HttpResponse<RequestInt>> {
    const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/updateJobRequestDataAccess/`;
    this.curHttpHeaders  = this._userService.getHeaders();

    const body = JSON.stringify(_request);
    // this.retrieveUser();

    return this.httpClient
      .put<RequestInt>(urlCur, body, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
    }
  saveRequest(_request: RequestInt, statutCall: String ): Observable<HttpResponse<RequestInt>> {
    let urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/updateJobRequest/`;
    this.curHttpHeaders  = this._userService.getHeaders();
    // console.log(JSON.stringify(_request));
    if (statutCall === 'createRequest') {
      urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/addJobRequest/`;
    }

    const body = JSON.stringify(_request);
    // this.retrieveUser();

    return this.httpClient
      .put<RequestInt>(urlCur, body, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  // requestLabels
  getListRequestLabels(_requestRef: string, _userLangCur: string, colNameList: string): Observable<HttpResponse<ColLabelList[]>> {

    const urlCur = `${environment.baseUrl}/jobdata/getJobRequestDataLabels/?` +
                      'requestRef=' + _requestRef + '&userLang=' + _userLangCur + '&colNameList=' + colNameList;
    this.curHttpHeaders  = this._userService.getHeaders();
    return this.httpClient.get<ColLabelList[]>(urlCur,
      {
        headers: this.curHttpHeaders,
        observe: 'response'
      });
  }
  saveRequestLabels(_requestLabels: ColLabelList[], _requestRef: string, _userLangCur: string, _colNameList: string ): Observable<HttpResponse<string>> {
    // jobdata/saveJobRequestDataLabels/?requestRef=AA&userLang=fr&colNameList=titleRequestXXent_rsXXent_code
    const urlCur = `${environment.baseUrl}/jobdata/saveJobRequestDataLabels/?` +
            'requestRef=' + _requestRef + '&userLang=' + _userLangCur + '&colNameList=' + _colNameList;
    this.curHttpHeaders  = this._userService.getHeaders();

    const body = JSON.stringify(_requestLabels);
    // this.retrieveUser();

    return this.httpClient
      .put<string>(urlCur, body, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
// Reports
getListReports(_optionItem: string): Observable<HttpResponse<ReportInt[]>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/?` + 
                      'optionList=' + _optionItem;
  this.curHttpHeaders  = this._userService.getHeaders();
  return this.httpClient.get<ReportInt[]>(urlCur,
    {
      headers: this.curHttpHeaders,
      observe: 'response'
    });
}
getLastVersionReport(_reportLabel: string): Observable<HttpResponse<any>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/lastVersion/` + _reportLabel;
  this.curHttpHeaders  = this._userService.getHeaders();
  return this.httpClient.get<any>(urlCur,
    {
      headers: this.curHttpHeaders,
      observe: 'response'
    });
}
getReport(_reportLabel: string): Observable<HttpResponse<any>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/` + _reportLabel;
  this.curHttpHeaders  = this._userService.getHeaders();
  return this.httpClient.get<any>(urlCur,
    {
      headers: this.curHttpHeaders,
      observe: 'response'
    });
}

deleteReport(_id: string): Observable<HttpResponse<string>> {

  const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/` + _id;
  this.curHttpHeaders  = this._userService.getHeaders();

    return this.httpClient
      .delete<string>(urlCur, {
        headers: this.curHttpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable));

  }

saveReportDataAccess(_request: ReportInt): Observable<HttpResponse<ReportInt>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/updateJobReportDataAccess/`;
  this.curHttpHeaders  = this._userService.getHeaders();

  const body = JSON.stringify(_request);

  return this.httpClient
    .put<ReportInt>(urlCur, body, {
      headers: this.curHttpHeaders,
      observe: 'response',
    }).pipe(
      catchError(this.handleErrorObservable), );
  }
saveReport(_report: ReportInt, statutCall: String ): Observable<HttpResponse<ReportInt>> {
  let urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/updateJobReport/`;
  this.curHttpHeaders  = this._userService.getHeaders();
  if (statutCall === 'createReport') {
    urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/addJobReport/`;
  }

  const body = JSON.stringify(_report);

  return this.httpClient
    .put<ReportInt>(urlCur, body, {
      headers: this.curHttpHeaders,
      observe: 'response',
    }).pipe(
      catchError(this.handleErrorObservable), );
}
saveEnvtUser(): Observable<HttpResponse<any>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/saveEnvtUser/`;
  this.curHttpHeaders  = this._userService.getHeaders();

  const body = JSON.stringify(this.getUserEnvt());
  // this.retrieveUser();

  return this.httpClient
    .put<any>(urlCur, body, {
      headers: this.curHttpHeaders,
      observe: 'response',
    }).pipe(
      catchError(this.handleErrorObservable), );
}
saveMenu(_menu: MenuInt, _userLangCur: string ): Observable<HttpResponse<MenuInt>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/saveJobMenu?` +
            'userLang=' + _userLangCur;
  this.curHttpHeaders  = this._userService.getHeaders();

  const body = JSON.stringify(_menu);
  // this.retrieveUser();

  return this.httpClient
    .put<MenuInt>(urlCur, body, {
      headers: this.curHttpHeaders,
      observe: 'response',
    }).pipe(
      catchError(this.handleErrorObservable), );
}
buildMenu(_entity: string, _userLang: string, _userId: number): Observable<HttpResponse<MenuInt[]>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/buildmenu?` +
          'entity=' + _entity + '&userLang=' + _userLang + '&userid=' + _userId;
           

  this.curHttpHeaders  = this._userService.getHeaders();
  return this.httpClient.get<MenuInt[]>(urlCur,
    {
      headers: this.curHttpHeaders,
      observe: 'response'
    });
}
getMenu(_entity: string, _userLang: string): Observable<HttpResponse<MenuInt[]>> {
  const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/?` +
          'entity=' + _entity + '&userLang=' + _userLang;
           

  this.curHttpHeaders  = this._userService.getHeaders();
  return this.httpClient.get<MenuInt[]>(urlCur,
    {
      headers: this.curHttpHeaders,
      observe: 'response'
    });
}

// Errors

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}
