    <div >
        <div  class="container-fluid stdTheme"  >
            <div *ngIf="titleReport !== undefined" class="flex-row row"> 
                <div class="col-12 d-flex justify-content-center"> 
                    <h4 >{{titleReport}}</h4>
                </div>
            </div>
            <div class="flex-row row" style="margin-bottom: 10px;">
                <div class="container-fluid d-flex justify-content-between">
                    <select class="form-control stdTheme " id="pdfSelect"  #pdfSelect style="width:auto;"  (change)="setPdfCur(pdfSelect.value)" >
                        <option class="optionSelect" *ngFor="let pdfCur of pdfFiles" [selected]="pdfCur.fileLabel === selectedPdf.fileLabel" >{{ pdfCur.fileLabel}}</option>
                    </select>
                    <button  mat-raised-button class="buttonTheme" 
                            (click)="closeEvent($event)"
                        style="margin-left: 10px;margin-right: 10px;">
                        <mat-icon>close</mat-icon>
                        {{ 'close' | translate }}
                    </button>
                </div>
            </div>
            <div *ngIf="!dataPdfLoaded" class="flex-row row" style="margin: 0px">
                <div class="container-fluid d-flex justify-content-center" >
                    <mat-progress-bar 
                        mode="indeterminate"
                        style="margin-left: 30px;">
                </mat-progress-bar>
                </div>
            </div>

            <div class="flex-row row stdTheme" style="margin: 0px;">
                <div class="col-12" style="height: 75vh">
                    <ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>
                </div>
            </div>
        </div>
    </div>