import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})
export class BarchartComponent implements OnInit {

  @Input() widgetData: Widget;
  @Input() displayTime: boolean;
  private readonly onDestroy = new Subject<void>();

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    // https://www.chartjs.org/docs/latest/configuration/animations.html#easing
    animation: {
        duration: 2000, // general animation time
        easing: 'easeInOutQuart',
      },
    hover:  {
        animationDuration: 200 // duration of animations when hovering an item
      },
    responsiveAnimationDuration: 1000 // animation duration after a resize
  };
  // public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartLabels: Label[] ;
  // public barChartType: ChartType = 'bar';
  public barChartType: ChartType ;
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  /*public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];*/
  public barChartData: ChartDataSets[];

  updateFrequency: number;

  dataIsLoaded: boolean;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
    console.log('widgetData barChart : ' + this.widgetData);
    this.displayDateTime = this.displayTime;

    this._jobDataService.displayGenerateTime
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if ((event === 'displayTime') || (event === 'hideTime')) {
          this.toogleTime(event);
        }
    });
    this.updateFrequency = this.widgetData.updateFrequency;

    this.buildRequest();
    this.barChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction, 'unixM', localFmt);

      const jsonData = JSON.parse(resultChart.json_data);
      this.barChartLabels = jsonData.labelCols as Label[];
      const barChartDataCur = jsonData.datasets as ChartDataSets[];
      const colors = CommonMethods.generateHslaColors(-1, 60, -1, barChartDataCur.length);
      const colorAlphas = CommonMethods.genAlphaHslaColors(-1, 60, [20, 100, 80], barChartDataCur.length);
      // this.barChartData[0].backgroundColor = colors;
      const dataSet: any[] = [];
      let idx = 0;
      for (const dataSetCur of jsonData.datasets as ChartDataSets[]) {
        const dataCur = {
          label: dataSetCur.label,
          backgroundColor: colorAlphas[idx][0], 
          borderColor: colorAlphas[idx][1], 
          pointBackgroundColor: colorAlphas[idx][1], 
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: colorAlphas[idx][2], 
          data: dataSetCur.data,
        };
        dataSet.push(dataCur);
        idx++;
      }
      this.barChartData = dataSet;
      const barChartDataTmp = {
        labels: jsonData.labelCols as Label[],
        datasets: dataSet,
      };

      console.log(barChartDataTmp);
      this.dataIsLoaded = true;
    })
    .then((error) => {

    });
  }

  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if (restrict.columnName) {
        if (requestRestrictions !== '') {
          requestRestrictions += ';';
        }
        requestRestrictions += restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions;
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if (resultChart.length === 0) {
            reject();
          } else {
            resolve(resultChart[0]);
          }
        }, err => {
          
          reject(err);
        }
      );
    });
  }
 
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
