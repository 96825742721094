
<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="container-fluid stdTheme" style="margin-top: 5px; width: 100%;" (click)="closePopover()">
 
    <mdi-calendar-header
      [(view)]="view"
      [(viewDate)]="viewDate"
      [locale]="locale"
      [excludeDays] = "weekendDays">
    </mdi-calendar-header>
  
    <div [ngSwitch]="view">

        <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [openDayEventsTemplate]="customOpenDayEventsTemplate"
            [cellTemplate]="customMonthCellTemplate"
            (dayClicked)="dayClicked($event.day); closePopover()"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeMonthViewRender($event)"
            [excludeDays] = "weekendDays"
            >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [refresh]="refresh"
            [dayStartHour]="8"
            [dayEndHour]="20"
            (eventClicked)="handleEvent('Clicked', $event.event); closePopover()"
            (beforeViewRender)="beforeWeekViewRender($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            [excludeDays] = "weekendDays"
            >
        </mwl-calendar-week-view>
        <!-- https://angular-calendar.com/docs/components/CalendarDayViewComponent.html -->
        <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [locale]="locale"
            [events]="events"
            [refresh]="refresh"
            [hourSegments]="4"
            [dayStartHour]="8"
            [dayEndHour]="20"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (hourSegmentClicked)="hourSegmentClicked($event); closePopover()"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeDayViewRender($event)"
            >
        </mwl-calendar-day-view>
    </div>
</div>
<!--
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">OK</button>
  </div>
</ng-template>
-->
<ng-template #modalDetailEvent let-close="close" let-event="event">
  <!--<div *ngIf="eventCur !== undefined">-->
    
      <div class="modal-header" style="padding: 10px">
        <h5 class="modal-title">{{ 'eventDetail' | translate }}</h5>
        <h5 class="modal-title" style="margin-left: 20px" *ngIf="applicationCall === 'appointmentCustomer'">{{eventCur.ref}}</h5>
        <h5 class="modal-title" style="margin-left: 20px" *ngIf="applicationCall === 'appointmentWork'">{{eventCur.ref}}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0px;margin-bottom: 0px;height: 80%!important">
        <form [formGroup]="detailEventForm" > 
          <div class="flex-row row" style="margin: 0px;height: 70px" *ngIf="applicationCall === 'appointmentCustomer' ||  applicationCall === 'appointmentWork'">
            <div class="col-5">
              <mat-form-field class="wksInput form-group" appearance="standard" >
                  <mat-label>{{ 'eventTitle' | translate }}</mat-label>
                  <input id="eventTitle" #eventTitle class="form-control inputTheme" 
                          formControlName="eventTitle" required
                          (ngModelChange)="onChangeTitle(applicationCall, eventTitle.value)"
                              matInput placeholder="{{ 'eventTitle' | translate }}"
                              >
              </mat-form-field>
            </div>
            <div class="col-2">
            </div>
            <div class="col-3" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                  <mat-label>{{ 'eventType' | translate }}</mat-label>
                  <input id="eventType" #eventType class="form-control inputTheme" 
                          formControlName="eventType" required readonly matInput  >
              </mat-form-field>
            </div>
          </div>
          
          <div class="flex-row row" style="margin: 0px;height: 100px" *ngIf="applicationCall === 'appointmentCustomer' ||  applicationCall === 'appointmentWork'">
            <div class="col-12" >
              <div  class="container-fluid stdTheme" style="margin-top: 10px;">
                <mdi-location-wks  
                    [applicationCall]="applicationCall" 
                    [readOnlyData]="true" 
                    [dataLocationEquip]="undefined"
                    [dataLocationArg]="dataLocationWork" >
                </mdi-location-wks>
              </div>
            </div>

          </div>
          
          <div class="flex-row row" style="margin: 0px;height: 70px" *ngIf="applicationCall === 'rhPlanning'">

            <div class="col-3" >
              <mat-form-field appearance="standard"  style="width: 100%!important;">
                <mat-label>{{ 'eventTitle' | translate }}</mat-label>
                <mat-select id="eventTitle" placeholder="{{ 'Choose equipment' | translate }}" formControlName="eventTitle" 
                          #eventTitle required
                          (ngModelChange)="onChangeTitle('rhPlanning', eventTitle.value)"
                          ngDefaultControl >
                          <mat-option class="selectOptionsTheme" *ngFor="let eventRh of eventsRh" 
                                      [value]="eventRh.label"  >{{ eventRh.label | translate }}</mat-option>
                </mat-select>         
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row" style= "margin: 0px;height: 100px">
            <div class="col-5">
              <mat-form-field class="wksInput form-group" appearance="standard">
                  <mat-label>{{ 'eventDescription' | translate }}</mat-label>
                  <!--<input id="eventDescription" #eventDescription class="form-control inputTheme" 
                          formControlName="eventDescription" 
                              matInput placeholder="{{ 'eventDescription' | translate }}"
                              >-->
                  <textarea matInput 
                    cdkTextareaAutosize
                    id="eventDescription"
                    class="form-control inputTheme textarea1"
                    formControlName="eventDescription" 
                    #eventDescription="cdkTextareaAutosize"
                    placeholder="{{ 'eventDescription' | translate }}"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3">
                  </textarea>
              </mat-form-field>
            </div>

            <div class="col-5" style="min-height: 70px;">
              <mat-form-field class="wksInput form-group" appearance="standard">
                  <mat-label>{{ 'eventComment' | translate }}</mat-label>
                  <!--<input id="eventComment" #eventComment class="form-control inputTheme" 
                          formControlName="eventComment" 
                              matInput placeholder="{{ 'eventComment' | translate }}"
                              >-->
                  <textarea matInput 
                    cdkTextareaAutosize
                    id="eventComment"
                    class="form-control inputTheme textarea1"
                    formControlName="eventComment" 
                    #eventComment="cdkTextareaAutosize"
                    placeholder="{{ 'eventComment' | translate }}"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3">
                  </textarea>
              </mat-form-field>
            </div>
          </div>
        <div class="flex-row row" style="margin: 0px;height: 70px">
          <div class="col-2">
            <mat-form-field class="wksInput form-group" appearance="standard">
              <mat-label>{{ 'eventStart' | translate }}</mat-label>

              <input matInput  class="form-control inputTheme" 
                            formControlName="eventStartDate"
                            #eventStartDate 
                            placeholder="{{ eventStart | translate }}" 
                            [max]="maxInputDate" 
                            [min]="minInputDate"  
                            [matDatepickerFilter]="filterDate"
                            [matDatepicker]="eventStartDatePicker" 
                            (dateChange)="validStatutDate($event, 'eventStartDate')" 
                            readonly required>
              <mat-datepicker-toggle matSuffix [for]="eventStartDatePicker"></mat-datepicker-toggle>
              <mat-datepicker name="eventStartDatePicker" #eventStartDatePicker >
              </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-2">
            <mat-form-field  class="wksInput form-group" appearance="standard">
              <input matInput [ngxTimepicker]="toggleStartTimepicker" [disableClick]="true" readonly required #eventStartTime
                      formControlName="eventStartTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]"
                      class="form-control inputTheme" >
                <!-- https://github.com/h2qutc/angular-material-components/issues/170 -->
                <!--<mat-datepicker-toggle matSuffix [for]="toggleStartTimepicker">
                  <mat-icon matDatepickerToggleIcon style="margin-top: 20px;" class="stdTheme">access_time</mat-icon>
                </mat-datepicker-toggle>-->
                <button mat-icon-button matSuffix (click)="toggleStartTimepicker.open(); $event.stopPropagation();">
                  <mat-icon>access_time</mat-icon>
                </button>
                <ngx-material-timepicker name="toggleStartTimepicker" (timeChanged)="changeTime('start', $event)" (timeSet)="validTimeInput('start',$event, true)" #toggleStartTimepicker></ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="col-2"></div>
          <div class="col-2">
            <mat-form-field class="wksInput form-group" appearance="standard">
              <mat-label>{{ 'eventEnd' | translate }}</mat-label>

              <input matInput  class="form-control inputTheme" 
                            formControlName="eventEndDate"
                            #eventEndDate 
                            placeholder="{{ eventEnd | translate }}" 
                            [max]="maxInputDate" 
                            [min]="minInputDate"  
                            [matDatepickerFilter]="filterDate"
                            [matDatepicker]="eventEndDatePicker" 
                            (dateChange)="validStatutDate($event, 'eventEndDate')" 
                            readonly required>
              <mat-datepicker-toggle matSuffix [for]="eventEndDatePicker"></mat-datepicker-toggle>
              <mat-datepicker name="eventEndDatePicker" #eventEndDatePicker >
              </mat-datepicker>
            </mat-form-field>
          </div>
          
          <div class="col-2">
            <mat-form-field  class="wksInput form-group" appearance="standard">
              <input matInput [ngxTimepicker]="toggleEndTimepicker" [disableClick]="true" readonly required
                      formControlName="eventEndTime"  [format]="24" [min]="dayHours[0]" [max]="dayHours[1]" 
                      class="form-control inputTheme">
              <!-- https://github.com/h2qutc/angular-material-components/issues/170 -->
              <button mat-icon-button matSuffix (click)="toggleEndTimepicker.open(); $event.stopPropagation();">
                <mat-icon>access_time</mat-icon>
              </button>
              <ngx-material-timepicker name="toggleEndTimepicker" (timeChanged)="changeTime('end',$event)" (timeSet)="validTimeInput('end',$event, true)" #toggleEndTimepicker></ngx-material-timepicker>
            </mat-form-field>
          </div>
        </div> 
      </form>
    </div>

    <div class="modal-footer" style="padding-bottom: 0px;padding-top: 0px;">
      <div class="flex-row row w-100">
        <div class="col-2" style="text-align: start">
          <button type="button" mat-raised-button (click)="closeModal('canceled')">
            <mat-icon>arrow_back</mat-icon>
            {{ 'goback' | translate }}
          </button>
        </div>
        <div class="col-8"></div>
        <div class="col-2"  style="text-align: end">
          <button type="button" *ngIf="detailEventForm.valid" mat-raised-button (click)="validEvent()">
            <mat-icon>save</mat-icon>
            {{ 'save' | translate }}
          </button>
        </div>
      </div>
    </div>
  <!--</div>-->

</ng-template>
<ng-template
  #customOpenDayEventsTemplate
  let-events="events"
  let-isOpen="isOpen">

  <div class="cal-open-day-events stdTheme" *ngIf="isOpen">
      <div *ngFor="let event of events" [ngClass]="event?.cssClass">
        <span class="cal-event" [style.backgroundColor]="event.color?.primary"></span>
        <mwl-calendar-event-title [event]="event" view="month" [customTemplate]="eventTitleTemplate"> </mwl-calendar-event-title>
        <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
      </div>
      <div class="flex-row row" style= "margin: 0px;padding: 0px;">
        <p style= "margin: 0px;padding: 0px;">
          <button mat-icon-button class="buttonTheme" 
            style="margin-left: 0px;margin-right: 0px;height:20px !important;width:20px !important;font-size:20px !important;" 
              (click)="addEvent(viewDate)">
            <span class="fa fa-plus-square" style="font-size: 20px;margin-top: -20px" ></span>
          </button>
        </p>
      </div>

  </div>

</ng-template>
<ng-template #eventTitleTemplate let-event="event" let-view="view">
  <!-- code original
  <span  [innerHTML]="event.start">
   
  </span>
  <span
    class="cal-event-title"
    [innerHTML]="event.title | calendarEventTitle: view:event"
    [attr.aria-hidden]="{} | calendarA11y: 'hideEventTitle'"
  >
  </span>
  -->
  <span >
    {{getEventItem(event)}}
  </span>
</ng-template>
<ng-template #customMonthCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked">
  <div class="cell-border stdTheme" style="height: 100%">
    <div class="cal-cell-top " [class.todayLoc]="day.isToday" [class.stdTheme]="!day.isToday" >
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      <!--
      <br>
      <button mat-icon-button class="buttonTheme" 
        style="margin-left: 10px;margin-right: 10px;" (click)="toggleDetail('add')">
        <span class="fa fa-plus-square" style="font-size: 20px" (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })" ></span>
      </button>
      -->
      </div>
    <div class="cell-totals stdTheme" *ngIf="day.events.length > 0">
      <span
        *ngFor="let group of day.eventGroups"
        class="badge badge-{{ group[0] }}">
        {{ group[1].length }}
      </span>
    </div>
    <div class="cal-events stdTheme" style="margin: 0px; padding: 3px" *ngIf="day.events.length > 0">
      <div
        class="cal-event"
        *ngFor="let event of day.events; trackBy: trackByEventId"
        [ngStyle]="{ backgroundColor: event.color?.primary }"
        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({ event: event })"
        (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        mwlDraggable
        [class.cal-draggable]="event.draggable"
        dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }"
        [dragAxis]="{ x: event.draggable, y: event.draggable }"
        [validateDrag]="validateDrag"
        [touchStartLongPress]="{ delay: 300, delta: 30 }"
        (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
        [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"
      ></div>
    </div>

  </div>
</ng-template>

<!-- https://github.com/mattlewis92/angular-calendar/issues/370
  exemple customisation openDayEentsTemplate
<ng-template
  #openDayEventsTemplate
  let-events="events"
  let-eventClicked="eventClicked">
  <div
    *ngFor="let event of events"
    [ngClass]="event?.cssClass"
    mwlDraggable
    [dropData]="{event: event}"
    [dragAxis]="{x: event.draggable, y: event.draggable}">
    <span
      class="cal-event"
      [style.backgroundColor]="event.color.primary">
    </span>
    <mwl-calendar-event-title
      [event]="event"
      [customTemplate]="eventTitleTemplate"
      view="month"
      (mwlClick)="eventClicked.emit({event: event})">
    </mwl-calendar-event-title>
    <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
  </div>
</ng-template>
-->
<!-- https://stackoverflow.com/questions/53063562/more-than-one-badge-on-a-cell-angular-calendar
  <ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <div class="cell-totals">
    <span
      *ngFor="let group of day.eventGroups"
      class="badge badge-{{ group[0] }}">
      {{ group[1].length }}
    </span>
  </div>
</ng-template>
-->