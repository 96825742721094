<div #refElement  fxLayout="column" fxLayoutAlign="space-between center" 
                class="dataAccess-container mat-elevation-z8 container-fluid stdTheme" style="width: 50%; margin-top: 10px" >
  <mat-card class="dataaccess-card stdTheme" layout="column" flex>
      <div class="col" style="align-content: flex-end; margin-right: 0em; padding-right: 0em">
          <mat-form-field style="padding-top: 0px; margin-top: 0px;">
              <mat-label>{{ 'userLang' | translate }}</mat-label>
              <mat-select [(value)]="userLangLabel" (selectionChange)="onLangChange($event)" style="padding-top: 0px; margin-top: 0px;" ngDefaultControl>
                <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" selected="userLangLabel" [value]="lang" >{{ lang.display | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    <mat-card-header>
        <div class="col stdTheme" style="margin-left: 0em; padding-left: 0em">
        <!--<mat-card-title >-->
        <h6> 
          <mat-form-field class="form-group" >
            <input type="text" #titleRequest matInput  [(value)]="titleRequestLabel" (change)="setValueTitle(titleRequest.value)">
          </mat-form-field> 
        </h6>
        <!--</mat-card-title>-->
      </div>

    </mat-card-header>
    <!--<mat-card-actions>

    </mat-card-actions> -->
    <mat-card-content  >

      <div class="spinner-container stdTheme" *ngIf="loading$ | async">
          <mat-spinner></mat-spinner>
      </div>
      
      <mat-tab-group mat-align-tabs="center">
        <mat-tab class="stdTheme" label="{{ 'labelList' | translate }}">
          <div  [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'800'">
            <table mat-table #tableLabel [dataSource]="dataLabel">
              <ng-container matColumnDef="colName">
                <th mat-header-cell  *matHeaderCellDef>{{'colName' | translate}}</th>
                <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colName" >{{row.colName}}</td>
              </ng-container>
              <!--
              <ng-container matColumnDef="requestRef">
                <th mat-header-cell *matHeaderCellDef>{{'requestRef' | translate}}</th>
                <td mat-cell *matCellDef="let row; let index=index" [(value)]="row.requestRef" >{{row.requestRef}}</td>
              </ng-container>
              -->
              <ng-container matColumnDef="checkLabel" >
                <th mat-header-cell  *matHeaderCellDef>{{'checkLabel' | translate}}</th>
                <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.checkLabel" >
                  
                  <mat-checkbox  #checkLabel color="primary" [(checked)]="row.checkLabel" (change)="setValueArray('checkLabel',checkLabel.checked,index)"></mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="colHeader">
                <th mat-header-cell  *matHeaderCellDef>{{'colHeader' | translate}}</th>
                <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colHeader" >
                  <mat-form-field class="form-group" >
                    <input type="text" #colHeader matInput #input  [(value)]="row.colHeader" (change)="setValueArray('colHeader',colHeader.value,index)">
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="colToolTip">
                <th mat-header-cell *matHeaderCellDef>{{'colToolTip' | translate}}</th>
                <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colToolTip" >
                    <mat-form-field class="form-group" >
                        <input type="text" #colToolTip matInput #input  [(value)]="row.colToolTip" (change)="setValueArray('colToolTip',colToolTip.value,index)" >
                      </mat-form-field>
                </td>
              </ng-container>
              <tr mat-header-row style="height: 50px"*matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab class="stdTheme" label="{{ 'specialFormat' | translate }}">
            <div  [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'800'">
                <table mat-table #tableFmt [dataSource]="dataFmt">
                  <ng-container matColumnDef="colName">
                    <th mat-header-cell  *matHeaderCellDef>{{'colName' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colName" >{{row.colName}}</td>
                  </ng-container>

                  <ng-container matColumnDef="colType">
                    <th mat-header-cell  *matHeaderCellDef>{{'colType' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colType" >
                      <mat-form-field class="form-group" >
                        <input type="text" #colType matInput #input  [(value)]="row.colType" (change)="setValueArray('colType',colType.value,index)">
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="colFmt">
                    <th mat-header-cell *matHeaderCellDef>{{'colFmt' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.colFmt" >
                        <mat-form-field *ngIf="row.colType == 'alphanum'" class="form-group" >
                            <input type="text" #colFmt matInput #input  [(value)]="row.colFmt" (change)="setValueArray('alphanum',colFmt.value,index)" >
                        </mat-form-field>
                        <mat-form-field *ngIf="row.colType == 'numeric'" class="form-group" >
                          <mat-select id="selectNumFmt" #selectNumFmt placeholder="{{ 'Choose one format' | translate }}" 
                            class="selectHabilContent"
                            ngDefaultControl [(value)]="row.colFmt" (selectionChange)="setValueArray('selectNumFmt',selectNumFmt.value,index)">
                            <mat-option *ngFor="let dataFmt of numFmtList" [value]="dataFmt.dataFmt">{{ dataFmt.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="row.colType == 'date' || row.colType == 'datetime' || row.colType == 'time'" class="form-group" >
                          <mat-select id="selectDateFmt" #selectDateFmt placeholder="{{ 'Choose one format' | translate }}" 
                            class="selectHabilContent"
                            ngDefaultControl [(value)]="row.colFmt" (selectionChange)="setValueArray('selectDateFmt',selectDateFmt.value,index)">
                            <mat-option *ngFor="let dataFmt of dateFmtList" [value]="dataFmt.dataFmt">{{ dataFmt.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </td>
                  </ng-container>
                  <tr mat-header-row style="height: 50px"*matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns2" ></tr>
                </table>
              </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
        <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
            <button mat-raised-button color="primary" #buttonSubmit type="submit" (click)="register()" [disabled]="!doValidation ">
              <mat-icon>create</mat-icon>
              {{ 'submit' | translate }}
            </button>
            <button mat-raised-button color="primary" #buttonSubmit type="reset" (click)="doCancel()">
              <mat-icon>cancel</mat-icon>
              {{ 'cancel' | translate }}
            </button>
          </div>
    </mat-card-actions>
  </mat-card>
</div>
