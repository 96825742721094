import { Component, AfterViewInit , ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


import { WksWorksModel } from '../../../../models/wks-works.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-works-change-statut',
  templateUrl: './works-change-statut.component.html',
  styleUrls: ['./works-change-statut.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksChangeStatutComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription;
  modalTitle: string;
  workTitle: string;
  workJob: WksWorksModel;
  workStatutForm: FormGroup;
  workStatutsList: LabelValue[];

  currentStatus: string;
  newStatus: string;

  constructor(
    private fb: FormBuilder,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private dialogRef: MatDialogRef<WorksChangeStatutComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

      this.modalTitle = data.title;
      this.workTitle = data.contentMessage;

      
      this.workJob = data.data1;
      this.currentStatus = this.workJob.workStatut;
      this.newStatus = this.workJob.workStatut;
      if (data.typeDialog === 'upload') {

      }
      if (data.typeDialog === 'updatePwd') {

      }
     
  }
  ngAfterViewInit () {

  }
  ngOnInit() {
    this.buildForm();
    this.loadParam();
    this.fillForm();

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  buildForm() {
    this.workStatutForm = this.fb.group({
      workStatutCur: this.fb.control({value: ''}),
      workStatutNew: this.fb.control({value: ''})
    });
  }
  loadParam() {

    const workListTmp = this._wksCentralService.getWorkStatuts();
    this.workStatutsList = [];
    let statusNumber: number;
    for (const statutCur of workListTmp) {
      if (statutCur.label === this.workJob.workStatut) {
        statusNumber = statutCur.number;
        break;
      }
    }

    for (const statutCur of workListTmp) {
      if (statutCur.label === 'undefined') {
        continue;
      }
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(statutCur.label),
        value: statutCur.label,
        otherData: statutCur.number
      };
      if (statusNumber >= 20 ) {
        if (statutCur.number === 99) {
          continue;
        }
      } 
      /*
      if (statusNumber >= 25 ) { // assigned : status previous 25 not possible
        if (statutCur.number < 25) {
          continue;
        }
      } 
      */
      if (statutCur.number === 30) { // 30 : assignment only by planning
        continue;
      }
    
      this.workStatutsList.push(curLabel);
    }

  }
  fillForm()  {
    this.workStatutForm.controls.workStatutCur.setValue(this._translate.getTranslate(this.currentStatus));
    this.workStatutForm.controls.workStatutNew.setValue(this.currentStatus);
  }
  onChangeWorkStatut(_value: any)  {
    // console.log('onChangeWorkStatut : ' + _value);
    this.newStatus = _value;
  }
  onNoClick() {
    const result = {
      action: 'cancelAction'
    };
    this.dialogRef.close(result);
  }
  onOKClick() {
    const result = {
      action: 'okAction',
      workStatut: this.newStatus,
    };
    this.dialogRef.close(result);
  }
}
