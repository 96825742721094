import { Injectable } from '@angular/core';
import { CalculationCmd, CalculationValues, CmdExpression, CondExpression, DataOperation } from '../../../models/common.model';
import { EconomicAreas } from '../../../models/data.model';
import { Dutie, EconomicAreaDuties, ParamCustoms, RulesJson } from '../../job-wks/models/wks-param.model';

@Injectable({
  providedIn: 'root'
})
export class CalculationAlgoService {

  private calculationAlgo: CalculationCmd[];
  private economicAreas: EconomicAreas[];
  private paramCustoms: ParamCustoms[];

  constructor() { }

  public setParamCustoms(paramCustomsArg: ParamCustoms[]): void { 
      this.paramCustoms = paramCustomsArg;
  }
  public getParamCustoms(): ParamCustoms[] { 
    return this.paramCustoms;
  }
  public setEconomicAreas(economicAreasArg: EconomicAreas[]): void { 
    this.economicAreas = economicAreasArg;
  }
  public getEconomicArea(entityCountry: string): string  {
    let currentArea: string;
    for (const economicArea of this.economicAreas) {
      if (economicArea.countriesMembers.includes(entityCountry))  {
        currentArea = economicArea.eaId;
        break;
      }
    }
    return currentArea;
  }
  getParamCustomsByHsCode( hsCode: string): ParamCustoms {
    let paramCustoms: ParamCustoms;
    if (hsCode === undefined || hsCode === '') {
      hsCode = 'default';
    }
    for (const paramCustomCur of this.paramCustoms) {
      if (paramCustomCur.hsCode === hsCode ) {
            paramCustoms = paramCustomCur;
            break;
      }
    }
    if (paramCustoms === undefined) {
      for (const paramCustomCur of this.paramCustoms) {
        if (paramCustomCur.hsCode === 'default' ) {
              paramCustoms = paramCustomCur;
              break;
        }
      }
    }
    return paramCustoms;
  }
  
  public executeAlgo( calculationAlgoArg:  CalculationCmd[]): void { 
    this.calculationAlgo = calculationAlgoArg;
    let ecoZoneOrigin = '';
    let hsCode = '';
    for (const cmdCur of calculationAlgoArg) { 
      let resultValue = 0;
      if (cmdCur.constantName === 'countryOrigin') { 
        ecoZoneOrigin = cmdCur.otherData;
      }
      if (cmdCur.constantName === 'hsCode') { 
        hsCode = cmdCur.currentValue;
      }
      for (const dataOp of cmdCur.dataOperations) { 
        if (cmdCur.duties) { 
          const duties = this.customsDuties(dataOp.cmdExpression, ecoZoneOrigin, hsCode);
          cmdCur.otherData = JSON.stringify(duties);
          resultValue = 0;
          for (const dutie of duties) {
            resultValue += dutie.result;
          }
        } else if (dataOp.conditionnal) { 
          resultValue = this.conditionnalCalcul(dataOp.conditionnalExpression, resultValue);
        } else  { 
          resultValue = this.expressionCalcul(dataOp.cmdExpression, resultValue);
        }
      }
      if (!cmdCur.fixedData) { 
        cmdCur.currentValue = isNaN(resultValue) ? 0 : resultValue;
      }
    }
  }
  private customsDuties(cmdExpression: CmdExpression, ecoZoneOrigin: string, hsCode: string): Dutie[] {  
    
    const duties: Dutie[] = [];
    const calculationValues = this.extractvalue(cmdExpression.numberValues, cmdExpression.technicalCodes);
    const baseCalcul = calculationValues.value1;
    const paramCustoms = this.getParamCustomsByHsCode(hsCode);
    const rulesJson = JSON.parse(paramCustoms.rulesJson) as RulesJson;
    /*
    if (rulesJson.dutyFreeZones.includes(ecoZoneOrigin)) {
      const dutieTmp: Dutie = { 
        dutieName: 'dutieFree',
        method: '',
        value: 0,
        base: baseCalcul,
        result: 0
      };
      duties.push(dutieTmp);
      return duties;
    }
    */
    // console.log(JSON.stringify(rulesJson));
    let isFound = false;
    let economicAreaDuties: EconomicAreaDuties;
    for (const areaOrigin of rulesJson.economicAreasOrigin) {
      if (areaOrigin.originId === ecoZoneOrigin) {
        isFound = true;
        economicAreaDuties = areaOrigin;
        break;
      }
    }
    if (!isFound) { 
      for (const areaOrigin of rulesJson.economicAreasOrigin) {
        if (areaOrigin.originId === 'default') {
          isFound = true;
          economicAreaDuties = areaOrigin;
          break;
        }
      }
    }

    for (const dutie of economicAreaDuties.duties) {
      const dutieTmp = JSON.parse(JSON.stringify(dutie)) as Dutie;
      if (dutie.method === 'rates') {
        dutieTmp.result = this.executeOperations(baseCalcul, 'multi', Number(dutie.value) );
      }
      duties.push(dutieTmp);
    }
    return duties;
  }
  private conditionnalCalcul(conditionnalExpression: CondExpression, resultValue: number): number { 
    if (!this.verifCond(conditionnalExpression)) { 
      return resultValue;
    }
    for (const nestExpression of conditionnalExpression.nestedExpressions) { 
      resultValue = this.expressionCalcul(nestExpression, resultValue);
    }
    return resultValue;
  }
  private verifCond(conditionnalExpression: CondExpression): boolean { 
    const returnVerif: boolean[][] = [];
    let comparatorCur: string;
    let iCond = 0;
    let andNum = 0;
    for (const clauseCond of conditionnalExpression.condClauses) { 

      if (clauseCond.orAnd === 'empty') {
        comparatorCur = clauseCond.orAnd;
        returnVerif[iCond] = [];
      } else if (clauseCond.orAnd === 'or') {
        comparatorCur = clauseCond.orAnd;
        andNum = 0;
        iCond++;
        returnVerif[iCond] = [];
      }
      if (clauseCond.comparator === 'empty') { 
        returnVerif[iCond][andNum] =  false;
        break;
      }
      const calculationValues: CalculationValues = this.extractvalue(clauseCond.numberValues, clauseCond.technicalCodes);

      if (calculationValues.value1 === undefined || calculationValues.value2 === undefined) { 
        returnVerif[iCond][andNum] =  false;
        break;
      }

      returnVerif[iCond][andNum] = this.comparatorNumericCond(clauseCond.comparator, calculationValues.value1, calculationValues.value2);
      andNum++;

    }
    const bValues: boolean[] = [];
    let bValue = 0;
    iCond = 0 ;
    for (const returnsCur  of returnVerif) { 
      bValue = 0;
      for (const rowCur of returnsCur) { 
        bValue += rowCur ? 1 : 0;
      }
      if (bValue === returnsCur.length) { 
        bValues[iCond] = true;
      } else  { 
        bValues[iCond] = false;
      }
      iCond++;
    }
    let isTrue = false;

    for (const rowCur of bValues) { 
      if (rowCur) { 
        isTrue = true;
        break;
      }
    }

    return isTrue;

  }
  private comparatorNumericCond(comparatorArg: string, value1: number, value2: number): boolean  {   
    let returnVerif: boolean;
    switch (comparatorArg) {
      case 'equal':
        returnVerif = (value1 === value2 ? true : false);
        break;
      case 'not-equal':
        returnVerif = (value1 !== value2 ? true : false);
        break;
      case 'gt-equal':
        returnVerif = (value1 >= value2 ? true : false);
        break;
      case 'lt-equal':
        returnVerif = (value1 <= value2 ? true : false);
        break;
      case 'gt':
        returnVerif = (value1 > value2 ? true : false);
        break;
      case 'lt':
        returnVerif = (value1 < value2 ? true : false);
        break;
      default:
        returnVerif = false;
        break;
    }

    return returnVerif;
  }
  private extractvalue(numberValues: number[], technicalCodes: string[]): CalculationValues {  

    const calculationValues: CalculationValues = {  
      value1: 0,
      value2: 0
    };
    let incrementLoop = 0;

    for (const numberValue of numberValues) { 
      if (technicalCodes[incrementLoop] === 'empty') { 
        if (incrementLoop === 0) { 
          calculationValues.value1 = Number(numberValues[incrementLoop]);
        } else {
          calculationValues.value2 = Number(numberValues[incrementLoop]);
        } 
      }
      incrementLoop++;
    }

    incrementLoop = 0;

    for (const technicalCode of technicalCodes) { 
      if (technicalCode !== 'empty') { 
        if (incrementLoop === 0) { 
          calculationValues.value1 = Number(this.getCurrentValue(technicalCode));
        } else {
          calculationValues.value2 = Number(this.getCurrentValue(technicalCode));
        } 
      }
      incrementLoop++;
    }

    return calculationValues;
  }
  private getCurrentValue(technicalCodeArg: string): number {  
    let currentValue: number;
    for (const cmdCur of this.calculationAlgo) { 
      if (cmdCur.technicalCode === technicalCodeArg) { 
        currentValue = cmdCur.currentValue;
        break;
      }
    }
    return currentValue;
  }
  private expressionCalcul(cmdExpression: CmdExpression, resultValue: number): number {  
    let calculValue = resultValue;
    const calculationValues = this.extractvalue(cmdExpression.numberValues, cmdExpression.technicalCodes);
    if (calculationValues.value2 === 0) { 
      calculationValues.value2 = resultValue;
    }
    
    if (cmdExpression.operande[0] !== 'empty') { 
      calculValue = this.executeOperations(resultValue, cmdExpression.operande[0], calculationValues.value1);
    } else { 
      calculValue = calculationValues.value1;
    } 
    if (cmdExpression.operande[1] !== 'empty') { 
      calculValue = this.executeOperations(calculValue, cmdExpression.operande[1], calculationValues.value2);
    }
    return calculValue;
  }
  private executeOperations(value1: number, operator: string, value2: number ): number {  
    let returnValue: number;

    switch (operator) {
      case 'add':
        returnValue = value1 + value2;
        break;
      case 'sub':
        returnValue = value1 - value2;
        break;
      case 'multi':
        returnValue = value1 * value2;
        returnValue = Math.round( returnValue * 100 ) / 100 ;
        break;
      case 'divi':
        returnValue = value1 / value2;
        returnValue = Math.round( returnValue * 100 ) / 100 ;
        break;
      case 'percent':
        let percent = value2 / 100 ;
        percent = Math.round( percent * 100 ) / 100 ;
        returnValue = value1 * percent;
        returnValue = Math.round( returnValue * 100 ) / 100 ;
        break;
    }

    return returnValue;
  }

}
