import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { MatDialog, MatDialogConfig, MatTabChangeEvent } from '@angular/material';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';
import { WksCentralService } from '../../../../services/wks-central.service';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { WksWorksModel, DataWorkModel, WksWorksRecapAmountModel } from '../../../../models/wks-works.model';
import { IhmOptions } from '../../../../models/wks-param.model';
import { WksWorksService } from '../../../../services/wks-works.service';

@Component({
  selector: 'mdi-works-invoicing',
  templateUrl: './works-invoicing.component.html',
  styleUrls: ['./works-invoicing.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None
})
export class WorksInvoicingComponent implements OnInit {
  
  @ViewChild(MdePopoverTrigger, { static: false }) localPopover: MdePopoverTrigger;
  @ViewChild('detailPopover', { static: false }) detailPopover: MdePopoverTrigger;

  private readonly onDestroy = new Subject<void>();
  worksLoaded: boolean;
 
  cacheDatatemp: any[];
  worksList: WksWorksModel[];
  workCur: WksWorksModel;
  wksEntity: WksEntityModel;
  
  selectedRow: number;

  displayPopover: boolean;

  dataInWork: DataWorkModel;
  ihmOptionsCkeck: IhmOptions;

  wksWorksInvoicingCache: WksWorksRecapAmountModel;
  wksWorksInvoicingValid: WksWorksRecapAmountModel;
  displayInvoicingOK: boolean;


  constructor(private _userService: UserService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _dialog: MatDialog,
    private _workService: WksWorksService,
    ) {
      this._translate.onLangChanged.subscribe(() => {
        // this.translateLabels();
      });
  }
 
  ngOnInit() {
    
    if (this._userService.getUserLogged() === undefined)  { return ; }
    this.initAllBooleans();
    this.loadParams();
    this.getWorksWaitingInvoice(this._userService.getUserLogged().entity, this.wksEntity.entityTz, 'closed')
    .then(
      () => {
        this.worksLoaded = true;
      },
      err => {
        // console.log('getWorksWaitingDate' + err.message);
        this.worksLoaded = true;
      });
  }
  initAllBooleans() {
    this.displayPopover = false;
    this.worksLoaded = false;
    this.displayInvoicingOK = false;
  }
  loadParams() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();    
  }

  getWorksWaitingInvoice(_entity: string, _timeZone: string, _statutCur: string) {
    this.worksLoaded = false;
    let curentStatut = _statutCur;
    this.cacheDatatemp = [];
    this.worksList = [];
    if (_statutCur === 'AllStatuts') {
      curentStatut = undefined;
    }
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.getWorksByStatut(_entity,  this._userService.getUserLogged().userlang, curentStatut)
      .subscribe(
        data => {
          this.cacheDatatemp = [...data.body];
          this.worksList = [...data.body];
          
          resolve();
        },
        err => {
          // console.log('getWorksWaitingDate' + err.message);
          this.worksLoaded = true;
          if (err.status === 404) {
          }
          // this.displayMessage('nodata', _statutCur);
          reject(err);
        });
      }
    );
  }
  displayDetail(event: Event, row: number)  {
    
    if (this.localPopover === undefined) {
      return;
    } 
    this.dataInWork = {
      work: this.workCur,
      iRow: row,
    };
    // this.detailPopover.closePopover();
    if ( this.selectedRow !== row) {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
      return;
    } 
    // console.log('displayDetail : ' + row + ' displayPopover ' + this.displayPopover);
    // https://stackblitz.com/edit/mde-popover-dynamic-target-position?file=app%2Fapp.component.ts

    if (this.displayPopover) {
      this.localPopover.togglePopover();
      this.displayPopover = false;
    } else  {
      this.detailPopover._elementRef.nativeElement.style.top = '25px';
      this.detailPopover._elementRef.nativeElement.style.left = '15px';
      this.localPopover.openPopover();
      this.displayPopover = true;
    }
  }
  setClickedRow(row: number)  {

    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.workCur = undefined;
      this.displayInvoicingOK = false;  
    } else {
      this.selectedRow = row;
      this.workCur = this.worksList[row];
      if (this.workCur.workJsonInvoicing !== undefined) {
        this.wksWorksInvoicingCache = JSON.parse(JSON.stringify(this.workCur.workJsonInvoicing));
      }
      const workStatut = this._wksCentralService.getWorkStatut(this.workCur.workStatut);
      this.ihmOptionsCkeck = this._wksCentralService.getIhmOption('invoicing', workStatut);

      this.displayInvoicingOK = true;      
    }
  } 
  dataOutInvoicing($event: any): void {
    let action = $event.action;
    const typeEvent = $event.typeEvent;
    const dataOut = $event.data;

    if (action === undefined || dataOut === undefined) {
      return;
    }
    if (action === 'doAction') {
      action = 'invoice';
    }
    try {
      JSON.stringify(dataOut);
    } catch (error) {
      console.log('dataOutInvoicing : ' + error);
      return;
    } finally { }

    if (JSON.stringify(this.wksWorksInvoicingCache) !== JSON.stringify(dataOut)) {
      this.wksWorksInvoicingValid = JSON.parse(JSON.stringify(dataOut));
      this.workCur.workJsonInvoicing = JSON.stringify(dataOut);
      this.worksList[this.selectedRow] = this.workCur;
      if (action === 'invoice') {
        this.displayMessage('validInvoice', action);
      } else {
        this.validInvoice(action);
      } 
    }

    this.selectedRow = -1;
    this.displayInvoicingOK = false;  
  }
  validInvoice(actionArg: string): void {
    this.fillModel();
    this._wksCentralService.saveWksWorks(this.workCur, actionArg)
    .subscribe(
      returnValue => {
        // this.workRateToUpdate = paramWorksRatesReturn.body;
        this.worksList[this.selectedRow] =  returnValue.body;
        if (actionArg === 'invoice') {
          this._dialog.closeAll();
          this.displayMessage('closeInvoicing');
        }
      },
      err => {
        const message = JSON.stringify(err);
        // console.log(JSON.stringify(err));
        this._dialog.closeAll();
        if (message.includes('303')) {
          this.displayMessage('invoice');
        } else  {
          this.displayMessage('register');
        }
      }
    );
  }
  fillModel(): void  {
    this.workCur.statutChange = true;
    this.workCur.workStatut = 'invoiced';
    this._workService.fillTracking(this.workCur, this._userService.getUserLogged().username, this.workCur.workStatut, undefined);
  }
  displayMessage(_actionCur: string, messageContent?: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording work';
      
    }
    if (_actionCur === 'invoice') {
      messageBox = 'error invoicing';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'validInvoice') {
      messageBox = 'Ok to emit invoice';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'closeInvoicing') {
      messageBox = 'invoice emit';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'waitingInvoicing') {
      messageBox = 'invoicing_inprogress';
      titleBox = 'invoicing';
      
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: (_actionCur === 'waitingInvoicing' ?  'infoProgressWks' : 'alertWks'),
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (_actionCur === 'validInvoice' && data === 'okAction') {
          this.validInvoice(messageContent);
          this.displayMessage('waitingInvoicing');
        }
      });

  }
}
