// import { OpaqueToken } from '@angular/core';
import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_NAME, LANG_EN_TRANS } from '../../assets/i18n/lang-en';
import { LANG_FR_NAME, LANG_FR_TRANS } from '../../assets/i18n/lang-fr';

import { DAYS_EN_TRANS, MONTHS_EN_TRANS, LOCAL_EN_FMT, AG_GRID_EN } from '../../assets/i18n/lang-en';
import { DAYS_FR_TRANS, MONTHS_FR_TRANS, LOCAL_FR_FMT, AG_GRID_FR } from '../../assets/i18n/lang-fr';

// translation token
// export const TRANSLATIONS = new OpaqueToken('translations');
export const TRANSLATIONS = new InjectionToken('translations');
export const TRANSLATIONS_DAY = new InjectionToken('translationsDay');
export const TRANSLATIONS_MONTH = new InjectionToken('translationsMonth');
export const TRANSLATIONS_FMT = new InjectionToken('translationsFmt');
export const TRANSLATIONS_AG_GRID = new InjectionToken('translationsAgGrid');

// all translations
export const dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_FR_NAME]: LANG_FR_TRANS
};

export const dictionaryDay = {
    [LANG_EN_NAME]: DAYS_EN_TRANS,
    [LANG_FR_NAME]: DAYS_FR_TRANS
};

export const dictionaryMonth = {
    [LANG_EN_NAME]: MONTHS_EN_TRANS,
    [LANG_FR_NAME]: MONTHS_FR_TRANS
};

export const dictionaryFmt = {
    [LANG_EN_NAME]: LOCAL_EN_FMT,
    [LANG_FR_NAME]: LOCAL_FR_FMT
};

export const dictionaryAg_grid = {
    [LANG_EN_NAME]: AG_GRID_EN,
    [LANG_FR_NAME]: AG_GRID_FR
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
    { provide: TRANSLATIONS_DAY, useValue: dictionaryDay },
    { provide: TRANSLATIONS_MONTH, useValue: dictionaryMonth },
    { provide: TRANSLATIONS_FMT, useValue: dictionaryFmt },
    { provide: TRANSLATIONS_AG_GRID, useValue: dictionaryAg_grid },
];

export const SUPPORTED_LANGS = [
    { display: 'English', value: 'en', direction: 'lrt' },
    { display: 'French', value: 'fr', direction: 'lrt' }
  ];

export const SUPPORTED_FMTS = [
    { display: 'English', value: 'EN' },
    { display: 'French', value: 'FR'},
    { display: 'USA', value: 'US' }
  ];

export class LangSupported {
    display: string;
    value: string;
    direction: string;
  }

export interface LangSupportedModel {
    display: string;
    value: string;
    direction: string;
  }
