import { Component, OnInit, ViewChild, ViewEncapsulation, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GridTools } from '../../../../../common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksTasksModel, Paramtasksref_sch, ParamTasksrefEvent, ParentTask, TaskSN } from '../../../../models/wks-param.model';
import { environment } from '../../../../../../../environments/environment';

import { SnRendererComponent } from './sn-renderer/sn-renderer.component';


@Component({
  selector: 'mdi-tasksref-table',
  templateUrl: './tasksref-table.component.html',
  styleUrls: ['./tasksref-table.component.css']
})
export class TasksrefTableComponent implements OnInit {

  displayType: string;
  optionItem: string;

  sidenavCollapsed: boolean;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;

  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = Paramtasksref_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator: boolean;
  cacheDatatemp = [];

  isRegisterTasksref: boolean;
  doCreateTasksref: boolean;
  doUpdateDoc = [];
  // tasksrefToUpdate: WksTasksModel;
  parentTask: ParentTask;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  frameworkComponents: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;

  actionType: string;

  tasksrefList: WksTasksModel[];
  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private modalService: BsModalService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    // let labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });
    if (this.displayType === null) {
      this.displayType = 'list';
    }
    const labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.sidenavCollapsed = true;

    this._wksCentralService.onRegisterValid
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      if (event === 'cancelRegister') {
        this.toggleSidenav('');

      }
      if (event === 'closeRegisterTask') {
        this.toggleSidenav('');
        this.buildList();
      }
      if (event === 'deleteTasksref') {
        this.buildList();
      }
    });
    /*
    this._wksCentralService.onSearchDoc
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: ParamTasksrefEvent) => {
      this.tasksrefList = event.searchResult;
      // this.tasksrefToUpdate = event.searchCriteria;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });*/
    this.optionItem = labelItem;
    this.timeFormat = this._translate.getLocalFmt('timeFormat');

    if (this.displayType === 'list') {
      this.buildList();
    }
  }
  buildList() {
    this.frameworkComponents = { 
      snRenderer: SnRendererComponent
    };
    this.loadingIndicator = true;
    this.displayedColumns = [
      { field: 'taskrefLabel', headerName: this._translate.instant('taskrefLabel'), sortable: true, filter: true, resizable: true, width: 250, },
      { field: 'equipTypeLabel', headerName: this._translate.instant('equipType'), sortable: true, filter: true, resizable: true },
      { field: 'equipBrand', headerName: this._translate.instant('equipBrand'), sortable: true, filter: true, resizable: true },
      { field: 'equipModel', headerName: this._translate.instant('equipModel'), sortable: true, filter: true, resizable: true },
      /*{ field: 'stdCreation', headerName: this._translate.instant('stdCreation'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
          // taskSN
      { field: 'taskSN', headerName: this._translate.instant('taskSN'), sortable: true, filter: true, resizable: true, wrapText: true,
                autoHeight: true, width: 350, cellRenderer: 'snRenderer', valueGetter: 'data.taskSN'},
      { field: 'taskMaj', headerName: this._translate.instant('stdLastupdate'), type: 'TimeFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
      /*{ field: 'stdDisable', headerName: this._translate.instant('stdDisable'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(), width: 300
      },
    ];

    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        'TimeFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }

    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getParamTasksrefList();

    
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onFilterTextBoxChanged(event: any): void {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  onClearFilter(): void {
    document.getElementById('filter-text-box')['value'] = '';
    this.gridOptions.api.setQuickFilter('');
  }
  refresh() {
    // this.getParamTasksrefList();
    this.buildList();
  }
  
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
    
    actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>'
                  ;
    
    return actions;
  }
  getParamTasksrefList() {
    this.selected = [];
    this.tasksrefList = [];
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.dataSource.data = [];
    this._wksCentralService.getParamTasksrefList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.dataSource.data = this.translateLabelsTasks(data.body);
        // this.cacheDatatemp = [...data.body];
        this.cacheDatatemp = [...this.translateLabelsTasks(data.body)];
        this.tasksrefList = [...this.translateLabelsTasks(data.body)];
        this.extractColumnsName(this.dataSource.data);
        this.affectData(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        if ((err.status === 500) || (err.status === 404)) {
          let titleBox: string;
          let messageBox: string;
          titleBox = 'searching tasks ref';
          messageBox = 'no task ref found';
          

          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  translateLabelsTasks(_dataArray: Array<any>):  Array<any> {
    if (!_dataArray[0]) {
      return null;
    }
    // ParamWorksRatesModel[]
    // langLabels?: ParamLabelsI18n[];
    const paramWksTasksRefList: WksTasksModel[] = [];
    for (const rowCur of _dataArray) {
   
      const langLabels = rowCur.langLabels;
      if (langLabels === null ) {
        continue;
      }
      for (const langLabel of langLabels) {
        if (langLabel.labelLang === this._translate.getCurrentLang()) {
          rowCur.taskrefLabel =  langLabel.labelText;
          break;
        }
      }
      rowCur.equipTypeLabel = this._translate.getTranslate(rowCur.equipType);
      paramWksTasksRefList.push(rowCur);
    }
    return paramWksTasksRefList;
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeTaskref')) {
          this.deleteTasksrefOK(dataCur);
        }
      });

  }
 

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editTasksref(data);
                break;
            }
            case 'trash': {
                this.deleteTasksref(data);
                break;
            }
        }
    }
  }
  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateDoc = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
                                        this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  affectData(dataArray: any[]): any[] {
    for (const itemCur of dataArray)  {

      const dateRecorded = (itemCur.stdLastupdate ?  itemCur.stdLastupdate : itemCur.stdCreation);
      itemCur.taskMaj = moment(dateRecorded).format(environment.fmtDateTimeTZ);

      let taskrefSN: TaskSN[] = [];
    
    if ((itemCur.taskrefSN !== undefined) && (itemCur.taskrefSN !== null)) {
      try {
        const taskrefSNJson = JSON.parse(itemCur.taskrefSN);
        taskrefSN = [...taskrefSNJson];
      } catch {
        taskrefSN = [];
        
      }
    }
 
    if (taskrefSN.length === 0) {
      itemCur.taskSN = 'allSN';
    } else  {
      let snList = '';
      for (const snCur of taskrefSN) {
        const lineCur = this._translate.getTranslate('snFrom') + ' : ' + snCur.snFrom + ' >> ' + 
                        this._translate.getTranslate('snUpto') + ' : ' + snCur.snUpto;
        /*
        if (snList === '') {
          snList = lineCur;
          // snList += '\n' + lineCur;
        } else {
          snList += '\n' + lineCur;
        } 
        */
        if (snList === '') {
          snList = lineCur;
          // snList += ';' + lineCur;
        } else {
          snList += ';' + lineCur;
        } 
      }
      itemCur.taskSN = snList;
    }


    }
    return dataArray;
  }
  toggleSidenav(_arg: string) {

    this.isRegisterTasksref = false;
    if (this.displayType === 'newsearch') {
      return;
    }

    if (_arg === 'registerTasksref') {
      this.isRegisterTasksref = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.m_sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.m_sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  editTasksrefAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    // this.tasksrefToUpdate = selectedRow;
    this.parentTask = {
      rootParam: selectedRow,
      parentParam: selectedRow,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };

    this.actionType = 'update';

    this.doCreateTasksref = false;
    this.toggleSidenav('registerTasksref');
  }
  editTasksref(row: WksTasksModel) {
    // this.tasksrefToUpdate = row;

    this.actionType = 'update';
    this.parentTask = {
      rootParam: row,
      parentParam: row,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.doCreateTasksref = false;
    this.toggleSidenav('registerTasksref');
  }
  addTaskRef() {
  
    this.doCreateTasksref = true;
    this.actionType = 'add';
    // this.tasksrefToUpdate = undefined;
    this.parentTask = {
      rootParam: undefined,
      parentParam: undefined,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.toggleSidenav('registerTasksref');
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteTasksref(row: WksTasksModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'deleting task ref';
    

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.taskrefLabel;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeTaskref', row );

  }
  deleteTasksrefOK(row: WksTasksModel) {

      this._wksCentralService.saveParamTasksref(row, 'deleteTasksref')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteTasksref');
        },
        () => {

        }
      );
    
  }
}
