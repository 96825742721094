import { registerLocaleData } from '@angular/common';
import { MDIMatPaginatorIntl } from './i18n/mdimat-paginator-intl';
import { rxStompServiceFactory } from '@stomp/ng2-stompjs';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/en';
import { localRxStompConfig } from './job/common/components/websocket/config/local-rx-stomp.config';
registerLocaleData(localeFr);
registerLocaleData(localeEs);
const ɵ0 = adapterFactory, ɵ1 = localRxStompConfig, ɵ2 = rxStompServiceFactory, ɵ3 = { float: 'auto' }, ɵ4 = (translate) => {
    const service = new MDIMatPaginatorIntl();
    service.injectTranslateService(translate);
    return service;
};
/*
export class CustomDateFormatter extends CalendarNativeDateFormatter {

  public dayViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }

  public weekViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }

}
*/
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
