
<div>
  <div *ngIf="dataIsLoaded" style="display: block">
    <canvas #doughnutChart baseChart
      [data]="doughnutChartData"
      [labels]="doughnutChartLabels"
      [chartType]="doughnutChartType"
      [colors]="colorsCur">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
  </div>
</div>
    

