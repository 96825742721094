import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SchedulerService } from '../../../services/scheduler.service';
import { UserService } from '../../../services/user.service';
import { SchedulerJobInfosModel } from '../../../models/schedulerJob.model';
import { Subscription } from 'rxjs';
import { WksCentralService } from '../../../job/job-wks/services/wks-central.service';
import { WksEntityModel } from '../../../job/job-wks/models/wks-entity.model';
import { LabelValue } from '../../../models/data.model';

@Component({
  selector: 'mdi-scheduler-table',
  templateUrl: './scheduler-table.component.html',
  styleUrls: ['./scheduler-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerTableComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();
  
  okToDisplay: boolean;
  displayDetailOK: boolean;
  schedulerJobInfosList: SchedulerJobInfosModel[];
  selectedRow: number;
  schedulerJobInfosCur: SchedulerJobInfosModel;
  schedulerJobInfosEmpty: SchedulerJobInfosModel;
  statutCall: string; 
  displayJournalOK: boolean;
  subscriptionJournal: Subscription;
  entitiesList: LabelValue[];

  constructor(private  _schedulerService: SchedulerService,
              private _wksCentralService: WksCentralService,
              private _userService: UserService) { 

  }

  ngOnInit() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this._userService.getEntityLogged() === undefined) { return; }
    this.initAllBooleans();
    this.initAllParams();
    this.loadListJobs();
  }
  initAllBooleans() {
    this.okToDisplay = false;
    this.displayDetailOK = false;
    this.displayJournalOK = false;
   }
  initAllParams() { 

    this.loadAllWksEntities()
    .then ((responseEntity: WksEntityModel[]) => {
      this.entitiesList = [];
      for (const entityCur of responseEntity) {
        /*
        if (entityCur.subsidiaryOf !== undefined) {
          continue;
        }
        */
        const opt = {
          value: entityCur.stdEntity,
          label: entityCur.stdEntity,
        };
        this.entitiesList.push(opt);
      }
      this.entitiesList.sort((obj1: LabelValue, obj2: LabelValue) => {
        return obj1.label > obj2.label ? 1 : -1;
      });
      const optAll = {
        value: 'ALL',
        label: 'ALL'
      };
      this.entitiesList.unshift(optAll);
    })
    .catch ((error: any) => {
      console.log('loadAllWksEntities : ' + error);
      return;
    });
    
  }
  loadAllWksEntities() {

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksAllEntities()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const responseEntity = data.body as WksEntityModel[];
          
          resolve(responseEntity);
        }, err => {
          // console.log('loadHeadquarters' + err.message);
          if (err.status === 404) {
            
          }
          reject(err.status);
        }
      );
    });
  }
  
  loadListJobs() {

    this._schedulerService.getAllJobs('ALL')
    .subscribe(
      data => {
        this.schedulerJobInfosList = data.body;
        this.schedulerJobInfosList.sort((obj1: SchedulerJobInfosModel, obj2: SchedulerJobInfosModel) => {
          return obj1.jobName > obj2.jobName ? 1 : -1;
        });
        this.okToDisplay = true;

        this.setBlankItem();
        this.schedulerJobInfosCur = this.schedulerJobInfosEmpty;
        this.displayDetailOK = true;
        this.displayJournalOK = false;
      },
      err =>  {
        this.okToDisplay = true;
        console.log('loadListJobs : ' + JSON.stringify(err));
      }
    );
  }
  refresh() {
    // this.getWorksList();
    this.okToDisplay = false;
    this.loadListJobs();
    this.displayDetailOK = true;
    this.displayJournalOK = false;
    this.selectedRow = -1;
    this.schedulerJobInfosCur = this.schedulerJobInfosEmpty;
    this.statutCall = 'empty';
    if (this.subscriptionJournal !== undefined) {
      this.subscriptionJournal.unsubscribe();
    }
  }
  addNewJob() {
    this.statutCall = 'add';
    this.schedulerJobInfosCur = this.schedulerJobInfosEmpty;
    if (this.subscriptionJournal !== undefined) {
      this.subscriptionJournal.unsubscribe();
    }
    this.displayJournalOK = false;
    this.selectedRow = -1;
  }
  setClickedRow(row: number) {

    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.schedulerJobInfosCur = this.schedulerJobInfosEmpty;
      this.statutCall = 'empty';
      this.displayJournalOK = false;
      if (this.subscriptionJournal !== undefined) {
        this.subscriptionJournal.unsubscribe();
      }
    } else {
      this.selectedRow = row;
      this.schedulerJobInfosCur = this.schedulerJobInfosList[row];
      this.statutCall = 'update';
      this.displayJournalOK = true;
    }
  }

  setBlankItem() {
    this.schedulerJobInfosEmpty = {
      jobId: null,
      jobName: '',
      jobGroup: '',
      jobStatus: '',
      jobClass: '',
      jobArgs: '',
      cronExpression: '',
      jobDesc: '',
      interfaceName: '',
      repeatTime: 0,
      cronJob: false
    };
  }
  dataOutCard(dataReturn: any) {
    /*
    if (dataReturn.row === -10 ) {
      this.schedulerJobInfosList.push(dataReturn.schedulerJobInfosCur);
    } else {
      this.schedulerJobInfosList[dataReturn.row] = dataReturn.schedulerJobInfosCur;
    }
    */
    this.refresh();

  }
  dataOutJournal(dataReturn: Subscription) {
    this.subscriptionJournal = dataReturn;
  }
}
