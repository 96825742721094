import { Component, OnInit, Input, ViewEncapsulation, Output,  SimpleChanges, OnChanges, EventEmitter, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatDialog, MatDialogConfig, MatTabChangeEvent, MatTabGroup } from '@angular/material';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksWorksService } from '../../../../services/wks-works.service';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksBusinessModel} from '../../../../models/wks-business.model';
import { WksEquipmentModel, ParentParam } from '../../../../models/wks-equipment.model';
import { WorkEquipments, WorkEquipmentRef } from '../../../../models/wks-works.model';
import { TypesLabel} from '../../../../models/wks-common.model';
import { IhmOptions } from '../../../../models/wks-param.model';

@Component({
  selector: 'mdi-works-equipment',
  templateUrl: './works-equipment.component.html',
  styleUrls: ['./works-equipment.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    // A voir : https://stackblitz.com/edit/mat-datepicker-for-period?file=src%2Fapp%2Fviews%2Fadd-period%2Fadd-period.component.ts
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class WorksEquipmentComponent implements OnInit {


  @Input() actionType: string;
  @Input() ownerArg: WksBusinessModel;
  @Input() workEquipmentId: WorkEquipmentRef;
  @Input() workEquipmentsArgs: WorkEquipments;
  @Input() optionItem: string;
  @Input() changeEquipment: boolean;
  @Input() ihmOptions: IhmOptions;
  // @Input() workEquipmentItem: WorkEquipment;

  @Output() dataOut = new EventEmitter<any>();

  @ViewChild('tabsSubEquipment', {static: false}) tabsSubEquipment: MatTabGroup;
  private readonly onDestroy = new Subject<void>();


  subEquipmentNew: boolean;
  parentParam: ParentParam;
  anchoragesIsLoaded: boolean;

  equipmentChoiceType: boolean;
  equipmentChoiceDisplay: boolean;

  workEquipments: WorkEquipmentRef;

  subEquipmentsCur: WksEquipmentModel[];

  mainEquipmentCur: WksEquipmentModel;
  subEquipmentsLoading: boolean;
  subEquipmentsLoaded: boolean;
  subEquipmentIsLoaded: boolean;
  isReadOnly: boolean;
  mainEquipmentsLoading: boolean;
  mainEquipmentsLoaded: boolean;
  mainEquipmentIsLoaded: boolean;
  isMainWorkEquip: boolean;
  workEquipmentsLoading: boolean;
  workEquipmentsLoaded: boolean;

  
  equipTypeSelected: TypesLabel;
  equipTypeList: TypesLabel[];

  attachedEquipement: boolean;

  mainEquipmentDisplay: boolean;
  subEquipmentDisplay: boolean;
  boatsList: WksEquipmentModel[];
  defaultBoat: WksEquipmentModel;
  mainEquipmentNew: boolean;
  isBoatEquip: boolean;
  loadBoats: boolean;
  changeBoat: boolean;
  choiceBoatDisplay: boolean;
  mainItemType: string;
  subItemType: string;
  numberSubEquipements: number;
  entityCur: string; 

  constructor(
    private _userService: UserService, 
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _wksWorksService: WksWorksService,
    private _dialog: MatDialog,
    private adapterDate: DateAdapter<any>,
    ) { }

  ngOnInit() {
    this.initData();
  }
/*
  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case
           // actionType
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          } // end case
          // ownerArg
          case 'ownerArg': {
            this.ownerArg = changes['ownerArg'].currentValue;
            break;
          } // end case
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          } // end case
          case 'changeEquipment': {
            this.changeEquipment = changes['changeEquipment'].currentValue;
            break;
          } // end case
          case 'workEquipmentId': {
            this.workEquipmentId = changes['workEquipmentId'].currentValue;
            break;
          } // end case

        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  */
  initData() {

    this.initAllBooleans();
    this.initGlobalsParameters();
    this.initAllSubscribes();
    this.loadListsParameters();
    if (((this.workEquipmentId === undefined) || (this.workEquipmentId === null)) && (this.actionType === 'addWork') ) {
      this.resetEquipmentChoice();
    } else {
      if (this.workEquipmentsArgs !== undefined) {
        this.affectWorkEquipments();
      } else  {
        this.readWorkEquipments();
      }
    }
  }
  initAllBooleans() {

    this.equipmentChoiceDisplay = false;
    this.equipmentChoiceType = false;
    this.attachedEquipement = false;
    // work equipment
    this.workEquipmentsLoading = false;
    this.workEquipmentsLoaded = false;
    // main equipemnt
    this.mainEquipmentsLoaded = false;
    this.mainEquipmentsLoading = false;
    this.mainEquipmentDisplay = false;
    this.mainEquipmentNew = false;
    this.isMainWorkEquip = false;
    // sub equipment
    this.subEquipmentsLoading = false;
    this.subEquipmentsLoaded = false;
    this.subEquipmentDisplay = false;
    this.subEquipmentNew = false;
    // boat : main equipement for sub equipement
    this.changeBoat = false;
    this.choiceBoatDisplay = false;
    this.loadBoats = false;
    this.isReadOnly = false;
    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
  }
  resetEquipmentChoice(): void {
    // choice equipment
    this.equipmentChoiceDisplay = false;
    this.equipmentChoiceType = true;
    this.attachedEquipement = false;
    // work equipment
    this.workEquipmentsLoading = false;
    this.workEquipmentsLoaded = false;
    // main equipemnt
    this.mainEquipmentsLoaded = false;
    this.mainEquipmentsLoading = false;
    this.mainEquipmentDisplay = false;
    this.mainEquipmentNew = false;
    this.isMainWorkEquip = false;
    this.mainEquipmentCur = undefined; 
    // sub equipment
    this.subEquipmentsLoading = false;
    this.subEquipmentsLoaded = false;
    this.subEquipmentDisplay = false;
    this.subEquipmentNew = false;
    this.subEquipmentsCur = [];
    // boat : main equipement for sub equipement
    this.changeBoat = false;
    this.choiceBoatDisplay = false;
    this.loadBoats = false;
  }
  initAllSubscribes () {
    this._wksCentralService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
      if (valueEvent === 'changeEquipment') {
        this.resetEquipmentChoice();
      }
      /* if (valueEvent === 'validEquipment') {
        
      }*/
    }) ;
  }
  initGlobalsParameters() {

    this.adapterDate.setLocale(this._translate.currentLang);
    const wksEntity = this._wksCentralService.getWksEntityCur();
    this.entityCur = wksEntity.stdEntity;

    const listItem = this._wksCentralService.getComponentsList();

    this.equipTypeList = [];
    for (const item of listItem) {
      const itemCur: TypesLabel = {
        labelType: this._translate.getTranslate(item),
        equipType: item,
        equipAttachedBoat: true
      };
      this.equipTypeList.push(itemCur);
    }
    const listEquip = this._wksCentralService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      const itemCur: TypesLabel = {
        labelType: this._translate.getTranslate(item.equipName),
        equipType: item.equipType,
        equipAttachedBoat: false
      };
      this.equipTypeList.push(itemCur);
    }
    this.equipTypeList.sort((obj1: TypesLabel, obj2: TypesLabel) => {
      return obj1.labelType > obj2.labelType ? 1 : -1;
    });
  }
  loadListsParameters()  {

  }
  loadSubEquipements() {
       // 	liste équipements du type pour le client (equipmentListOwnerType)
    if (this.equipTypeSelected === undefined && this.subEquipmentsCur !== undefined && this.subEquipmentsCur.length > 0)  {
      let isFound = false;
      for (const equipTypeCur of this.equipTypeList) {
        if (equipTypeCur.equipType === this.subEquipmentsCur[0].equipType)  {
          this.equipTypeSelected = equipTypeCur;
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        return;
      }
    }
    
    this.subEquipmentsLoading = true;
    this.subEquipmentsCur = [];
    this.calcSubEquipments(this.equipTypeSelected.equipType);
    this.getWksEquipmentByPartOfandEquipType(this.mainEquipmentCur.id, this.equipTypeSelected.equipType)
    .then ((response: WksEquipmentModel[]) => {
      // si équipements existants 
      this.subEquipmentsLoading = false;
      this.subEquipmentsLoaded = true;
      this.subEquipmentsCur =  [...response];
      this.subEquipmentDisplay = true;
      this.dataOutSubEquipements();
    })
    .catch ((error: number) => {
      this.subEquipmentsLoading = false;
      // si aucun équipement pour le client -- > nouvel équipement (addEquipmentProcess)
      if (error === 404) {
        const titleBox = this._translate.getTranslate('search equipment customer');
        const messageBox = this._translate.getTranslate('no equipment found');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'noSubEquipmentFound', undefined );
      }
      return;
    });
  }
  dataOutSubEquipements(): void {

    if (this.subEquipmentsCur === undefined || this.subEquipmentsCur.length === 0) {
      return;
    }
    for (const itemSub of this.subEquipmentsCur ) {
      itemSub.isMainEquip = false;
      itemSub.isSubEquip = true;
      itemSub.isWorkEquip = true;
      if (this.equipTypeSelected.equipType !== itemSub.equipType) {
        itemSub.isWorkEquip = false;
      }
      this.dataOut.emit(itemSub);
    }
  }
  calcSubEquipments(subItem: string)  {
    this.numberSubEquipements = -1;
    // "outboard engine","inboard engine"
    if ((subItem !== 'engine') && (subItem !== 'outboard engine') && (subItem !== 'inboard engine'))  {
      return; 
    }
    if (this.mainEquipmentCur.equipBoatConfiguration !== undefined) {
      for (const configMotors of this._wksCentralService.getMotorsconfigList()) {
        if (configMotors.configName === this.mainEquipmentCur.equipBoatConfiguration) {
          this.numberSubEquipements = configMotors.numberMotors;
          break;
        }
      }
    }
  }
  affectWorkEquipments() {
    this.mainEquipmentCur = JSON.parse(JSON.stringify(this.workEquipmentsArgs.mainEquipment));
    this.mainEquipmentCur.isMainEquip = true;
    this.equipmentChoiceType = false;
    this.equipmentChoiceDisplay = false;
    this.isMainWorkEquip = true;
    this.mainEquipmentDisplay = true;
    this.choiceBoatDisplay = false;
    this.mainItemType = this.mainEquipmentCur.equipType;
    this.parentParam = {
      equipPartOf: undefined,
      ownerId: this.ownerArg.id,
      equipType: this.mainItemType,
      equipBoatPropulsion: this.mainEquipmentCur.equipBoatPropulsion,
      equipBoatConfiguration: this.mainEquipmentCur.equipBoatConfiguration,
      equipBoatManeuvring: this.mainEquipmentCur.equipBoatManeuvring,
      commissioningDate: this.mainEquipmentCur.commissioningDate,
    };
    this._wksWorksService.setMainEquipment(this.mainEquipmentCur);
    this.mainEquipmentCur.isWorkEquip = true;
    // this.dataOut.emit(this.mainEquipmentCur);
    if (this.workEquipmentId.subEquipments) {
      this.subEquipmentsCur = [];
      this.subEquipmentDisplay = true;
      const subEquipmentsArgs = JSON.parse(JSON.stringify(this.workEquipmentsArgs.subEquipments));
      for (const subEquip of subEquipmentsArgs) {
        this.subItemType = subEquip.equipType;
        this.calcSubEquipments(this.subItemType);
        subEquip.isWorkEquip = true;
        this.subEquipmentsCur.push(subEquip);
        // this.dataOut.emit(subEquip);
      }
    }
  }
  readWorkEquipments() {

    this.getWksEquipmentByEquipId(this.workEquipmentId.mainEquipment)
    .then ((response: WksEquipmentModel) => {
      this.mainEquipmentCur = response;
      this.mainEquipmentCur.isMainEquip = true;
      this.equipmentChoiceType = false;
      this.equipmentChoiceDisplay = false;
      this.isMainWorkEquip = true;
      this.mainEquipmentDisplay = true;
      this.choiceBoatDisplay = false;
      this.mainItemType = this.mainEquipmentCur.equipType;
      this.parentParam = {
        equipPartOf: undefined,
        ownerId: this.ownerArg.id,
        equipType: this.mainItemType,
        equipBoatPropulsion: this.mainEquipmentCur.equipBoatPropulsion,
        equipBoatConfiguration: this.mainEquipmentCur.equipBoatConfiguration,
        equipBoatManeuvring: this.mainEquipmentCur.equipBoatManeuvring,
        commissioningDate: this.mainEquipmentCur.commissioningDate,
      };

      this._wksWorksService.setMainEquipment(this.mainEquipmentCur);
      this.mainEquipmentCur.isWorkEquip = true;
      this.dataOut.emit(this.mainEquipmentCur);
      if (this.workEquipmentId.subEquipments) {
        this.subEquipmentsCur = [];
        this.subEquipmentDisplay = true;
        
        for (const subKey of this.workEquipmentId.subEquipments) {
          this.getWksEquipmentByEquipId(subKey)
          .then ((responseCur: WksEquipmentModel) => {
            this.subItemType = responseCur.equipType;
            this.calcSubEquipments(this.subItemType);
            responseCur.isWorkEquip = true;
            this.subEquipmentsCur.push(responseCur);
            this.dataOut.emit(responseCur);
          })
          .catch ((error: number) => {
            if (error === 404) {

            }
            return;
          });
         
        } 
      }
    })
    .catch ((error: number) => {
      if (error === 404) {
        const titleBox = this._translate.getTranslate('search boats customer');
        const messageBox = this._translate.getTranslate('no boat found customer');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'noBoatFound', undefined );
      }
      return;
    });
  }

  addMainEquipment(): void {
    
    this.mainEquipmentNew = true;
    this.equipmentChoiceType = false;
    this.equipmentChoiceDisplay = false;
    this.mainEquipmentDisplay = true;
    this.isMainWorkEquip = true;
    this.mainEquipmentCur = undefined;
    this.mainItemType = this.equipTypeSelected.equipType;
    if (this.equipTypeSelected.equipAttachedBoat) {
      this.isMainWorkEquip = false;
      this.mainItemType = 'boats';
    }
    
    this.parentParam = {
      equipPartOf: undefined,
      ownerId: this.ownerArg.id,
      equipType: this.mainItemType,
      equipBoatPropulsion: undefined,
      equipBoatConfiguration: undefined,
      equipBoatManeuvring: undefined,
      commissioningDate: undefined,
    };
  }
  refreshSubEquipment(): void {
    this.loadSubEquipements();
  }
  removeSubEquipment(irow: number): void {
    const itemSub = this.subEquipmentsCur[irow];
    itemSub.doRemove = true;
    this.subEquipmentsCur.splice(irow, 1);
    this.dataOut.emit(itemSub);
  }

  addSubEquipment(): void {
    if (this.subEquipmentsCur === undefined) {
      this.subEquipmentsCur = [];
    }
    if (this.equipTypeSelected === undefined && this.subEquipmentsCur !== undefined && this.subEquipmentsCur.length > 0)  {
      let isFound = false;
      for (const equipTypeCur of this.equipTypeList) {
        if (equipTypeCur.equipType === this.subEquipmentsCur[0].equipType)  {
          this.equipTypeSelected = equipTypeCur;
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        return;
      }
    }
    const itemEmpty: WksEquipmentModel = {
      stdEntity: this.entityCur,
      subEquipmentNew: true,
      equipPartOf: this.parentParam.equipPartOf,
      ownerId: this.parentParam.ownerId,
      equipType: this.equipTypeSelected.equipType,
      equipName: this._translate.instant('New engine'),
    };
    this.subEquipmentsCur.push(itemEmpty);
    this.subEquipmentDisplay = true;
    if (this.tabsSubEquipment !== undefined) {
      this.tabsSubEquipment.selectedIndex = this.subEquipmentsCur.length - 1;
    }
  }

  selectedTabChange(matTabSelected: MatTabChangeEvent) {
    // console.log (matTabSelected);
  }

  onChangeEquipType(equipTypeInput: TypesLabel) {
    this.equipTypeSelected = equipTypeInput;
    this.equipmentChoiceDisplay = true;
    if (this.equipTypeSelected.equipAttachedBoat) {
      this.subItemType = equipTypeInput.equipType;
      this.attachedEquipement = true;
    } else {
      this.mainItemType = equipTypeInput.equipType;
    }
    if (this.actionType === 'addWork' && this.workEquipmentsArgs !== undefined  && this.workEquipmentsArgs.mainEquipment !== undefined) {
      this.mainEquipmentCur = JSON.parse(JSON.stringify(this.workEquipmentsArgs.mainEquipment));
      this.mainEquipmentCur.isMainEquip = true;
      this.equipmentChoiceType = false;
      this.equipmentChoiceDisplay = false;
      this.isMainWorkEquip = true;
      this.mainEquipmentDisplay = true;
      this.choiceBoatDisplay = false;
      this.mainItemType = this.mainEquipmentCur.equipType;
      this.parentParam = {
        equipPartOf: undefined,
        ownerId: this.ownerArg.id,
        equipType: this.mainItemType,
        equipBoatPropulsion: this.mainEquipmentCur.equipBoatPropulsion,
        equipBoatConfiguration: this.mainEquipmentCur.equipBoatConfiguration,
        equipBoatManeuvring: this.mainEquipmentCur.equipBoatManeuvring,
        commissioningDate: this.mainEquipmentCur.commissioningDate,
      };
      this._wksWorksService.setMainEquipment(this.mainEquipmentCur);
      this.mainEquipmentCur.isWorkEquip = true;
      this.dataOut.emit(this.mainEquipmentCur);
      if (this.equipTypeSelected.equipAttachedBoat) {
        this.loadSubEquipements();
      }
    } else {
      this.loadBoatsList();
    }
    // console.log(equipTypeSelected);
  }
  
  loadBoatsList(): void {
    
    this.mainEquipmentsLoading = true;
    this.mainEquipmentNew = false;

    this.getWksEquipmentListOwner('boats')
    .then ((response: WksEquipmentModel[]) => {
      this.boatsList = response;
      this.mainEquipmentsLoaded = true;
      this.mainEquipmentsLoading = false;
      this.choiceBoatDisplay = true;
      this.defaultBoat = this.boatsList[0];
    })
    .catch ((error: number) => {
      if (error === 404) {
        const titleBox = this._translate.getTranslate('search boats customer');
        const messageBox = this._translate.getTranslate('no boat found customer');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'noBoatFound', undefined );
      }
      return;
    });

  }
 
  onChangeworkBoat(event: WksEquipmentModel) {
    this.mainEquipmentCur = event;
    this._wksWorksService.setMainEquipment(this.mainEquipmentCur);
    this.isBoatEquip = false;
    this.mainEquipmentsLoading = false;
    this.mainEquipmentsLoaded = false;
    this.changeBoat = false;
    this.mainEquipmentNew = false;
    this.mainEquipmentDisplay = true;
    this.mainItemType = 'boats';
    this.isMainWorkEquip = true;

    this.equipmentChoiceType = false;
    this.equipmentChoiceDisplay = false;

    this.parentParam = {
      equipPartOf: this.mainEquipmentCur.id,
      ownerId: this.mainEquipmentCur.ownerId,
      equipType: '',
      equipBoatPropulsion: this.mainEquipmentCur.equipBoatPropulsion,
      equipBoatConfiguration: this.mainEquipmentCur.equipBoatConfiguration,
      equipBoatManeuvring: this.mainEquipmentCur.equipBoatManeuvring,
      commissioningDate: this.mainEquipmentCur.commissioningDate,
    };
    this.mainEquipmentCur.isMainEquip = true;
    this.mainEquipmentCur.isWorkEquip = true;
    if (this.equipTypeSelected.equipType !== this.mainEquipmentCur.equipType) {
      this.mainEquipmentCur.isWorkEquip = false;
    }
    this.dataOut.emit(this.mainEquipmentCur);
    if (this.equipTypeSelected.equipAttachedBoat) {
      this.loadSubEquipements();
    }
  }
  getWksEquipmentListOwner(typeEquip: string) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentListOwner(this.entityCur, this.ownerArg.id, typeEquip)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  getWksEquipmentByPartOfandEquipType(equipPartOf: string, equipType: string ) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentListBoat(this.entityCur, equipPartOf, equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  getWksEquipmentByEquipId(equipId: string) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentByEquipId(this.entityCur, equipId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  dataOutEquipmentCard(orig: string, event: any): void {
    switch (orig) {
      case 'mainEquipment': {
        try {
          const object = event as WksEquipmentModel;
          this.parentParam = {
            equipPartOf: object.id,
            ownerId: object.ownerId,
            equipType: '',
            equipBoatPropulsion: object.equipBoatPropulsion,
            equipBoatConfiguration: object.equipBoatConfiguration,
            equipBoatManeuvring: object.equipBoatManeuvring,
            commissioningDate: object.commissioningDate,
          };
          object.isMainEquip = true;
          this.mainEquipmentCur = object;
          this.calcSubEquipments(this.equipTypeSelected.equipType);
          this.mainEquipmentDisplay = true;
          this.isMainWorkEquip = true;
          object.isWorkEquip = true;
          if (this.equipTypeSelected.equipType !== object.equipType) {
            object.isWorkEquip = false;
          }
          if (this.mainEquipmentNew && !object.isWorkEquip)  {
            this.subEquipmentsCur = undefined;
            this.subEquipmentNew = true;
            this.subEquipmentDisplay = true;
            this.addSubEquipment();
            this.mainEquipmentNew = false;
          } else if (!object.isWorkEquip)  {
            this.mainEquipmentNew = false;
            this.subEquipmentNew = false;
            this.loadSubEquipements();
          }
          
          this.dataOut.emit(object); 
        } catch (error) {
          console.error(error);
        }
        break;
      } // end case
      case 'subEquipment': {
        if (this.equipTypeSelected === undefined && this.subEquipmentsCur !== undefined && this.subEquipmentsCur.length > 0)  {
          let isFound = false;
          for (const equipTypeCur of this.equipTypeList) {
            if (equipTypeCur.equipType === this.subEquipmentsCur[0].equipType)  {
              this.equipTypeSelected = equipTypeCur;
              isFound = true;
              break;
            }
          }
          if (!isFound) {
            return;
          }
        }
        try {
          const object = event as WksEquipmentModel;
          object.isMainEquip = false;
          object.isSubEquip = true;
          object.isWorkEquip = true;
          if (this.equipTypeSelected.equipType !== object.equipType) {
            object.isWorkEquip = false;
          }
          if (this.subEquipmentNew ) {
            if (this.subEquipmentsCur === undefined) {
              this.subEquipmentsCur = [];
              this.subEquipmentsCur.push(object);
            } else {
              this.subEquipmentsCur[object.equipNumber] = object;
            }
            this.subEquipmentDisplay = true;
            this.subEquipmentNew = false;
          } else {
            this.subEquipmentsCur[object.equipNumber] = object;
            this.subEquipmentDisplay = true;
          }
          this.dataOut.emit(event);
        } catch (error) {
          console.error(error);
        }
        break;
      } // end case
    }
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);

        if (( data === 'okAction') && (_actionCur === 'noSubEquipmentFound')) {
          this.subEquipmentsLoading = false;
          this.subEquipmentsLoaded = false;
          this.subEquipmentNew = true;
          this.subEquipmentsCur = undefined;
          this.subEquipmentDisplay = true;
          this.subEquipmentNew = true;
          this.subEquipmentDisplay = true;
          this.addSubEquipment();
        }
        if (( data === 'okAction') && (_actionCur === 'noBoatFound')) {
          this.mainEquipmentsLoaded = true;
          this.mainEquipmentNew = true;
          this.mainEquipmentsLoading = false;
          this.mainEquipmentCur = undefined;
          this.mainEquipmentDisplay = true;
          this.equipmentChoiceType = false;
          this.mainItemType = 'boats';
          this.addMainEquipment();
        }
      });

  }

}
