<div #refElement class="mat-elevation-z8 container-fluid stdTheme" style="overflow-x: hidden; overflow-y: auto;"
        [ngStyle]="{'margin-top': '10px', 
                        width: (((isBoat && !isSearch && !isBoatEquip) 
                                    || (statutCall === 'tasks') || statutCall === 'work') ? '100%' : 
                                    (isBoatEquip || (optionItem === 'jets')) ? '100%' : '50%'),
                        padding: (isBoatEquip) ? '5px' : '10px', height: '100%'}" >
    <div *ngIf="!isFinishLoading" class="flex-row row" style="margin: 0px">
        <div class="container-fluid d-flex justify-content-center" >
            <mat-progress-bar 
                mode="indeterminate"
                style="margin-left: 30px;">
          </mat-progress-bar>
        </div>
    </div>
    <div *ngIf="isFinishLoading" class="flex-row row" style="margin: 0px; height: 100%;overflow-x: hidden; overflow-y: hidden;">
        <div [class]="equipmentCol"  style="padding-right: 5px; padding-left: 5px;" 
                        (mouseenter) ="mouseEnter() "  (mouseleave) ="mouseLeave()">
            <form [formGroup]="equipmentForm"  style="margin: 0px; height: 100%;" >
                <mat-card class="register-card stdTheme" layout="column" flex 
                        style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;padding-bottom: 10px; overflow-x: hidden; overflow-y: hidden;">
                    <mat-card-header>
 
                        <mat-card-title>
                            <section class="mat-typography">                          
                                <h1 class="titleTheme" style="padding-top: 10px;"
                                [mdePopoverTriggerFor]="metadataPopover" 
                                mdePopoverTriggerOn="hover" 
                                #popoverTrigger="mdePopoverTrigger"
                                mdePopoverOffsetX="-1"
                                mdePopoverOffsetY="-20"
                                >{{ titleCard | translate }}</h1>
                            </section>
                        </mat-card-title>
<!--
                        <button *ngIf="isUpdateStatut && !isSearch" mat-icon-button  
                                    [mdePopoverTriggerFor]="metadataPopover" 
                                    mdePopoverTriggerOn="hover" 
                                    #popoverTrigger="mdePopoverTrigger">
                            <i class="fas fa-asterisk fa-xs"></i>
                        </button>
-->
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="container-fluid d-flex justify-content-between" >
                            <div *ngIf="statutCall !== 'tasks' && statutCall !== 'work' && statutCall !== 'accordion' ">
                                <button *ngIf="isBoatEquip" mat-raised-button  #buttonSubmit type="reset" (click)="doClose()">
                                    <mat-icon>close</mat-icon>
                                    {{ 'Close' | translate }}
                                </button>
                                <button *ngIf="!isBoatEquip" mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                    <mat-icon>arrow_back</mat-icon>
                                    {{ 'goback' | translate }}
                                </button>
                                <!--
                                <button *ngIf="isUpdateStatut" mat-raised-button  #buttonSubmit (click)="openModalWork()">
                                    <mat-icon>add</mat-icon>
                                    {{ 'works' | translate }}
                                </button>
                                -->
                            </div>
                            <div *ngIf="statutCall !== 'tasks'">
                                <button *ngIf="!isBoatEquip && (isInput && !isRegistered) && equipmentForm.valid" mat-raised-button  #buttonSubmit type="submit" (click)="registerEquip('card')" >
                                    <mat-icon>save</mat-icon>
                                    {{ 'save' | translate }}
                                </button>
                                <button *ngIf="isSearch" mat-raised-button  #buttonSubmit type="submit" (click)="searching()" >
                                    <mat-icon>search</mat-icon>
                                    {{ 'search' | translate }}
                                </button>
                            </div>
                        </div>
                    </mat-card-actions>
 
                    <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px;" (selectedTabChange)="tabChanged($event)">
                        <mat-tab label="{{ 'description' | translate }}" (click)="currentTabSelected('description')">
                            <mat-card-content class="stdTheme" style="overflow-x: hidden; overflow-y: auto;margin-bottom: 0px;"
                                                [ngStyle]="{height: (isBoatEquip && isWorkDisplay) ? '400px' :
                                                        (!isBoatEquip && isWorkDisplay) ? '450px' :
                                                        (isBoatEquip && !isWorkDisplay) ? '500px' : '590px'}">
                                <div class="flex-row row">
                                    <div class="col-5">
                                        <div *ngIf="isSearch" >
                                            <mat-form-field class="mdiinput form-group" appearance="standard">
                                                <mat-label>{{ 'ownerName' | translate }}</mat-label>
                                                <input id="ownerName" class="form-control inputTheme" formControlName="ownerName" 
                                                        matInput #ownerName placeholder="{{ 'ownerName' | translate }}"
                                                        >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="ownerName.value" (click)="onKeyClearOwnerName()">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="ownerArg !== undefined">
                                            <mat-form-field class="mdiinput form-group" appearance="standard">
                                                <mat-label>{{ 'ownerName' | translate }}</mat-label>
                                                <input id="ownerName" class="form-control inputTheme" formControlName="ownerName" readonly
                                                            matInput #ownerName placeholder="{{ 'ownerName' | translate }}" 
                                                            [(ngModel)]="ownerArg.bsnName"
                                                >
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="isInput && !isBoatEquip && ownerArg === undefined">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" >
                                                <mat-label>{{ 'ownerId' | translate }}</mat-label>
                                                <mat-select id="ownerId" placeholder="{{ 'Choose owner' | translate }}" formControlName="ownerId" 
                                                            panelClass="mdiselectcontent" #ownerId
                                                            (selectionChange)="onChangeOwner(ownerId.value)"
                                                            ngDefaultControl required>
                                                    <mat-select-header>
                                                        <div style="display: inline-block;" >
                                                            <button mat-icon-button matPrefix aria-label="search" >
                                                                <mat-icon>search</mat-icon>
                                                            </button>
                                                            <input class="searchTheme" #filterowner placeholder="{{ 'filterInput' | translate }}" 
                                                                    (keyup)="onKeyOwner($event.target.value)" >
                                                            <button mat-icon-button matSuffix aria-label="clear" 
                                                                    *ngIf="ownerId.value" (click)="ownerId.value=''; onKeyOwner('');">
                                                                <mat-icon>close</mat-icon>
                                                            </button>

                                                        </div>
                                                    </mat-select-header>
                                                    <mat-option class="selectOptionsTheme" *ngFor="let customerCur of customerFilteredList; let iRow=index; let c=count;let isLast=last" 
                                                        [value]="customerCur.id"  >{{customerCur.bsnName | translate}}
                                                    </mat-option>
                                                </mat-select>  
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-4" *ngIf="isBoat">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipName' | translate }}</mat-label>
                                            <input id="equipName" class="form-control inputTheme" formControlName="equipName" 
                                                    matInput #equipName placeholder="{{ 'equipName' | translate }}" required
                                                    (change)="onChangeField('equipName', equipName.value)">
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-4" *ngIf="!isBoat">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label >{{ 'equipDesign' | translate }}</mat-label>
                                            <input id="equipDesign" class="form-control inputTheme" formControlName="equipDesign" matInput #equipDesign 
                                            placeholder="{{ 'equipDesign' | translate }}" required
                                            (change)="onChangeField('equipDesign', equipDesign.value)"
                                            required  >
                                        </mat-form-field>
                                    </div> 
                                    <!--
                                            <input matInput #input="ngModel" [(ngModel)]="date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" 
                                                            placeholder="Choose a date">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                    -->
                                    <div class="col-3" >
                                        <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                            <mat-label>{{ 'commissioningDate' | translate }}</mat-label>
                                            <input matInput [min]="minDate" [max]="maxDate" class="form-control inputTheme" formControlName="commissioningDate" 
                                                        [matDatepicker]="endPicker" #commissioningDate placeholder="{{ 'commissioningDate' | translate }}" disabled
                                                        (dateChange)="validCommissioningDate($event)" required>
                                            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                            <mat-datepicker name="endPicker" #endPicker [disabled]="isReadOnly" ></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="flex-row row">
                                    <div class="col-5">
                                        <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                            <mat-label>{{ 'equipBrand' | translate }}</mat-label>
                                            <mat-select id="equipBrand" placeholder="{{ 'Choose brand' | translate }}" formControlName="equipBrand" 
                                                        panelClass="mdiselectcontent" #equipBrand required
                                                        (selectionChange)="onChangeBrand(equipBrand.value)"
                                                        ngDefaultControl >
                                                <mat-select-header>
                                                    <div style="display: inline-block;" >
                                                        <button mat-icon-button matPrefix aria-label="search" >
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                        <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                                (keyup)="onKeyBrand($event)" >
                                                        <button mat-icon-button matSuffix aria-label="clear" 
                                                                *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        <button mat-icon-button matSuffix aria-label="add" 
                                                            *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                            <mat-icon>add</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-select-header>
                                                <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                                    [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                                </mat-option>
                                            </mat-select>                         
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipModel' | translate }}</mat-label>
                                            <mat-select id="equipModel" placeholder="{{ 'Choose model' | translate }}" formControlName="equipModel" 
                                                    panelClass="mdiselectcontent"#equipModel required
                                                    (selectionChange)="onChangeField('equipModel', equipModel.value)"
                                                    ngDefaultControl >
                                                <mat-select-header>
                                                    <div style="display: inline-block;" >
                                                        <button mat-icon-button matPrefix aria-label="search" >
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                        <input class="searchTheme" #filtermodel placeholder="{{ 'filterInput' | translate }}" 
                                                                (keyup)="onKeyModel($event)" >
                                                        <button mat-icon-button matSuffix aria-label="clear" 
                                                                *ngIf="filtermodel.value" (click)="filtermodel.value=''; onKeyModel('');">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        <button mat-icon-button matSuffix aria-label="add" 
                                                            *ngIf="modelFilteredList.length === 0" (click)="addModel(filtermodel.value)">
                                                            <mat-icon>add</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-select-header>
                                                <mat-option class="selectOptionsTheme" *ngFor="let modelCur of modelFilteredList" 
                                                    [value]="modelCur.modelName"  >{{modelCur.modelName}}
                                                </mat-option>
                                            </mat-select>    
                                        </mat-form-field>
                                    </div>      
                                    <div class="col-2">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipYear' | translate }}</mat-label>
                                            <mat-select id="equipYear" placeholder="{{ 'Choose year' | translate }}" formControlName="equipYear" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipYear
                                                    (selectionChange)="onChangeField('equipYear', equipYear.value)">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let yearCur of listYears" 
                                                        [value]="yearCur"  >{{yearCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>   
                                </div>
                                <div class="flex-row row"  >
                                    <div class="col-4" *ngIf="isBoat || isTrailer">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipCountry' | translate }}</mat-label>

                                            <mat-select id="equipCountry" #equipCountry placeholder="{{ 'Choose country' | translate }}" formControlName="equipCountry" 
                                                panelClass="panelOptions" ngDefaultControl required (selectionChange)="onChangeCountry(equipCountry.value)">
                                                <mat-select-header>
                                                    <div class="filterInput" style="display: inline-block;" >            
                                                        <button mat-icon-button matPrefix aria-label="search" >
                                                            <mat-icon>search</mat-icon>
                                                        </button>  
                                                        <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                                                    style="margin: 5px" (keyup)="onKeyCountry($event.target.value)" >
                                                        <button mat-icon-button matSuffix aria-label="clear" 
                                                                    *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-select-header>
                                                <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                                                    [value]="country.isoAlpha2"  >{{country.commonName | translate}}
                                                </mat-option>
                                            </mat-select>    
                                        </mat-form-field>
                                    </div>   
                                    <div class="col-4" *ngIf="isBoat || isTrailer">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipImmat' | translate }}</mat-label>
                                            <input id="equipImmat" #equipImmat class="form-control inputTheme" formControlName="equipImmat" 
                                                (keyup)="equipImmat.value = $event.target.value.toUpperCase()" 
                                                        matInput placeholder="{{ 'equipImmat' | translate }}"
                                                        (change)="onChangeField('equipImmat', equipYear.value)" >
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipSN' | translate }}</mat-label>
                                            <input id="equipSN" #equipSN class="form-control inputTheme" formControlName="equipSN" 
                                                (keyup)="equipSN.value = $event.target.value.toUpperCase()"
                                                (change)="onChangeField('equipSN', equipYear.value)"
                                                        matInput placeholder="{{ 'equipSN' | translate }}">
                                        </mat-form-field>
                                    </div>  
                                </div>
                                <div *ngIf="isBoat" class="flex-row row">
                                    <div  class="container-fluid stdTheme" >
                                        <mdi-location-wks  
                                            [applicationCall]="'equipmentCard'" 
                                            [dataLocationArg]="homeAnchorageData"
                                            [readOnlyData]="false" 
                                            (dataOut)="dataOutLocation($event)">
                                        </mdi-location-wks>
                                      </div>
                                </div>
                                <div *ngIf="isBoat" class="flex-row row" >  
                                    <div  class="container-fluid stdTheme" >
                                        <mdi-location-wks  
                                            [applicationCall]="'equipmentCard'" 
                                            [dataLocationArg]="mooringData"
                                            [readOnlyData]="false" 
                                            (dataOut)="dataOutLocation($event)">
                                        </mdi-location-wks>
                                    </div>
                                </div>
                                <div *ngIf="isBoat" class="flex-row row">     
                                    <div  class="container-fluid stdTheme" >
                                        <mdi-location-wks
                                            [applicationCall]="'equipmentCard'" 
                                            [dataLocationArg]="winteringData"
                                            [readOnlyData]="false" 
                                            (dataOut)="dataOutLocation($event)">
                                        </mdi-location-wks>
                                    </div>
                                </div>
                                <div class="flex-row row" *ngIf="isBoat || (isEngine && !isBoatEquip)">
                                    <div class="col-4" *ngIf="isBoat">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'propulsion' | translate }}</mat-label>
                                            <mat-select id="equipBoatPropulsion" placeholder="{{ 'Choose propulsion' | translate }}" formControlName="equipBoatPropulsion" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #propulsion (selectionChange)="onChangePropulsion(propulsion.value)"
                                                        required>
                                                    <mat-option class="selectOptionsTheme" *ngFor="let propulsionCur of propulsionsList" 
                                                        [value]="propulsionCur.propulsionName"  >{{propulsionCur.propulsionName | translate}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                    <div class="col-4" *ngIf="isMotorBoat || (isEngine && !isBoatEquip && !isThusterMotor)">
                                        <mat-form-field  class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'motorsConfiguration' | translate }}</mat-label>
                                            <mat-select id="equipBoatConfiguration" #equipBoatConfiguration placeholder="{{ 'Choose motorsConfiguration' | translate }}" formControlName="equipBoatConfiguration" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl (selectionChange)="onChangeMotorConfig(equipBoatConfiguration.value,'')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let configCur of motorsConfigList" 
                                                        [value]="configCur.configName"  >{{configCur.configName | translate}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                    <div class="col-4 " *ngIf="isThusterMotor" >
                                        <div class="checkbox"  >
                                            <label  class="labelLine" style="margin-top: 38px; margin-left: 0px; margin-right: 15px" >{{ 'equipBoatManeuvring' | translate }}</label>
                                            <input type="checkbox" id="equipBoatManeuvring"  #equipBoatManeuvring class="inputTheme" formControlName="equipBoatManeuvring"
                                                [checked]="(equipmentToUpdate !== undefined && equipmentToUpdate.equipBoatManeuvring) ? equipmentToUpdate.equipBoatManeuvring : false" 
                                                (click)="changeEquipBoatManeuvring(equipBoatManeuvring.checked)"/>
                                        </div>
                                    </div>
                                    <div class="col-4" *ngIf="isEngine && isMotorBoat && !isSingleMotor">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'motorPosition' | translate }}</mat-label>
                                            <mat-select id="motorPosition" placeholder="{{ 'Choose motorPosition' | translate }}" formControlName="motorPosition" 
                                                    #motorPosition  (selectionChange)="onChangeMotorPosition(motorPosition.value,'motorPosition')"
                                                    panelClass="mdiselectcontent"   ngDefaultControl >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let motorPosition of positionsMotorList" 
                                                        [value]="motorPosition"  >{{motorPosition | translate}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                </div>
                                <div class="flex-row row" *ngIf="isEngine">

                                    <div class="col-4" *ngIf="(thrusterPossible) || (!isBoatEquip)">
                                        <div class="checkbox">
                                            <label class="labelLine" style="margin-top: 38px; margin-left: 0px; margin-right: 15px" >{{ 'thruster motor' | translate }}</label>
                                            <input type="checkbox" id="thrusterOption"  #thrusterOption class="inputTheme" formControlName="thrusterOption"
                                                [checked]="(equipmentToUpdate !== undefined && equipmentToUpdate.thrusterOption) ? equipmentToUpdate.thrusterOption : false" 
                                                (click)="changeThrusterOption(thrusterOption.checked)">
                                        </div>
                                    </div> 
                                    <div class="col-4" *ngIf="!isSingleMotor && isMotorBoat">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'motorPosition' | translate }}</mat-label>
                                            <mat-select id="motorPosition" placeholder="{{ 'Choose motorPosition' | translate }}" formControlName="motorPosition" 
                                                    #motorPosition  (selectionChange)="onChangeMotorPosition(motorPosition.value,'motorPosition')"
                                                    panelClass="mdiselectcontent"   ngDefaultControl >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let motorPosition of positionsMotorList" 
                                                        [value]="motorPosition"  >{{motorPosition | translate}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!--isThusterMotor-->
                                    <div class="col-4" *ngIf="isThusterMotor">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'motorPosition' | translate }}</mat-label>
                                            <mat-select id="motorPosition" placeholder="{{ 'Choose motorPosition' | translate }}" formControlName="motorPosition" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl  #motorPositionTh
                                                    (selectionChange)="onChangeMotorPosition(motorPositionTh.value,'motorPositionTh')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let motorPosition of thrustersConfigList" 
                                                        [value]="motorPosition.thrusterName"  >{{motorPosition.thrusterName | translate}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 

                                </div>
                                <div class="flex-row row" *ngIf="isTrailer">
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipHitch' | translate }}</mat-label>
                                            <mat-select id="equipHitch"  #equipHitch placeholder="{{ 'Choose equipHitch' | translate }}" formControlName="equipHitch" 
                                                    panelClass="mdiselectcontent"   (selectionChange)="getHitchesSizes(equipHitch.value, 'equipHitch'); onChangeOthers(equipHitch.value,'equipHitch')"
                                                    ngDefaultControl >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let hitchCur of hitchesList" 
                                                        [value]="hitchCur.value"  >{{hitchCur.label}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'hitchSizes' | translate }}</mat-label>
                                            <mat-select id="hitchSizes" placeholder="{{ 'Choose hitchSizes' | translate }}" formControlName="equipHitchSize" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #hitchSizes
                                                    (selectionChange)="onChangeOthers(hitchSizes.value,'hitchSizes')"
                                                    >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let hitchSizeCur of hitchSizes" 
                                                        [value]="hitchSizeCur"  >{{hitchSizeCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'axles' | translate }}</mat-label>
                                            <mat-select id="equipAxles" placeholder="{{ 'Choose axles' | translate }}" formControlName="equipAxles" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipAxles
                                                    (selectionChange)="onChangeOthers(equipAxles.value,'equipAxles')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let axlesCur of axlesList" 
                                                        [value]="axlesCur"  >{{axlesCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'tires' | translate }}</mat-label>
                                            <mat-select id="equipTires" placeholder="{{ 'Choose tires' | translate }}" formControlName="equipTires" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipTires
                                                    (selectionChange)="onChangeOthers(equipTires.value,'equipTires')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let tireCur of tiresList" 
                                                        [value]="tireCur"  >{{tireCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'rims' | translate }}</mat-label>
                                            <mat-select id="equipRims" placeholder="{{ 'Choose rims' | translate }}" formControlName="equipRims" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipRims
                                                    (selectionChange)="onChangeOthers(equipRims.value,'equipRims')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let rimCur of rimsList" 
                                                        [value]="rimCur"  >{{rimCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-2" >
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'nuts' | translate }}</mat-label>
                                            <mat-select id="equipNuts" placeholder="{{ 'Choose nuts' | translate }}" formControlName="equipNuts" 
                                                    panelClass="mdiselectcontent"   ngDefaultControl #equipNuts
                                                    (selectionChange)="onChangeOthers(equipNuts.value,'equipNuts')">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let nutsCur of nutsList" 
                                                        [value]="nutsCur"  >{{nutsCur}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> 
                                </div>
                                <div class="flex-row row" *ngIf="hoursInput" style="margin-bottom: 15px">
                                    <div class="col-2">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipHours' | translate }}</mat-label>
                                            <input id="equipHours" class="form-control inputTheme" 
                                            formControlName="equipHours" matInput #equipHours placeholder="{{ 'equipHours' | translate }}"
                                            (change)="onChangeField('equipHours', equipHours.value)"
                                            >
                                        </mat-form-field>
                                    </div>   
                                    <div class="col-3">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipHoursDate' | translate }}</mat-label>
                                            <input matInput id="equipHoursDate" [min]="minBirthDayDate" class="form-control  inputTheme" [max]="maxBirthDayDate" formControlName="equipHoursDate" 
                                                    [matDatepicker]="pickerHours" placeholder="{{ 'equipHoursDate' | translate }}" #equipHoursDate disabled
                                                    (dateChange)="onChangeField('equipHoursDate', equipHoursDate.value)" >
                                            <mat-datepicker-toggle matSuffix [for]="pickerHours"></mat-datepicker-toggle>
                                            <mat-datepicker name="matDpBirthDay" #pickerHours  [disabled]="isReadOnly" ></mat-datepicker>
                                        </mat-form-field>
                                    </div>    
                                    <div *ngIf="!isSearch"  class="col-6">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipAddInfo' | translate }}</mat-label>
                                            <textarea  id="equipAddInfo" class="form-control inputTheme textarea1" formControlName="equipAddInfo" 
                                                        matInput #equipAddInfo placeholder="{{ 'equipAddInfo' | translate }}"
                                                        cdkTextareaAutosize
                                                        #autosize="cdkTextareaAutosize"
                                                        style="height: 50px; min-height: 50px;"
                                                        cdkAutosizeMinRows="1"
                                                        cdkAutosizeMaxRows="4"
                                                        (change)="onChangeField('equipAddInfo', equipAddInfo.value)" 
                                            ></textarea >
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="flex-row row" *ngIf="!hoursInput && !isSearch" style="margin-bottom: 15px">
                                    <div class="col-6">
                                        <mat-form-field class="mdiinput form-group" appearance="standard">
                                            <mat-label>{{ 'equipAddInfo' | translate }}</mat-label>
                                            <textarea  id="equipAddInfo" class="form-control inputTheme textarea1" formControlName="equipAddInfo" 
                                                        matInput #equipAddInfo placeholder="{{ 'equipAddInfo' | translate }}"
                                                        cdkTextareaAutosize
                                                        #autosize="cdkTextareaAutosize"
                                                        style="height: 50px; min-height: 50px;"
                                                        cdkAutosizeMinRows="1"
                                                        cdkAutosizeMaxRows="4"
                                                        (change)="onChangeField('equipAddInfo', equipAddInfo.value)" 
                                            ></textarea >
                                        </mat-form-field>
                                    </div>
                                </div>   
                                <div class="flex-row row d-flex align-items-center"
                                    style="padding: 0px; margin-left: 0px; margin-right: 0px;margin-top: 10px">
                                    <fieldset class="revisions-border" >
                                        <legend class="revisions-border d-flex justify-content-start"  ><b>{{ 'revisions' | translate }}</b></legend>
                                        <div formArrayName="revisionsArray">
                                            <div  class="stdTheme" *ngFor="let revisionCur of revisionsList; let idxRevision = index" style="margin-left: 10px; margin-right: 10px" >
                                                <div class="flex-row row d-flex align-items-center rowRevisions " >
                                                    <div class="col-9 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;" [formGroupName]="idxRevision">
                                                        <mat-form-field *ngIf="filteredTasksList !== undefined && filteredTasksList.length > 0" 
                                                                        class="wksInput form-group" 
                                                                        appearance="standard" style="margin-right: 15px;margin-top: -50px;">
                                                            <mat-label>{{ 'tasksReferenced' | translate }}</mat-label>
                                                            <mat-select id="tasksReferenced" 
                                                                    panelClass="mdiselectcontent" #tasksReferenced  required
                                                                    [ngStyle]="{'text-align': numberFormat.justifText}"
                                                                    formControlName="taskrefId"
                                                                    (selectionChange)="onRevisionChange('revisionsTaskrefId',tasksReferenced.value, idxRevision)"
                                                                    [value]="revisionCur.taskrefId"
                                                                    ngDefaultControl >
                                                                <mat-select-filter 
                                                                    [placeholder]="'filterInput' | translate" 
                                                                    [displayMember]="'taskrefLabel'" 
                                                                    [array]="listTasksFiltered" 
                                                                    (filteredReturn)="filteredTasksList = $event">
                                                                </mat-select-filter>
                                                                <mat-option class="selectOptionsTheme" *ngFor="let taskCur of filteredTasksList" 
                                                                    [value]="taskCur.taskrefId"  >{{taskCur.taskrefLabel}}
                                                                </mat-option>
                                                            </mat-select>                   
                                                        </mat-form-field>
                                                    </div>
                                            
                                                    <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                                        <button mat-icon-button *ngIf="filteredTasksList !== undefined && filteredTasksList.length > 0" 
                                                                (click)="addRowRevisions(idxRevision)" [disabled]="isReadOnly"
                                                            data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                                            <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                    <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                                        <button  mat-icon-button *ngIf="filteredTasksList !== undefined && filteredTasksList.length > 0"  
                                                                    (click)="removeRowRevisions(idxRevision)" title="{{'delete row' | translate }}"
                                                                [disabled]="isReadOnly">
                                                            <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="flex-row row" *ngIf="!isBoatEquip">
                                    <div class="col-12">
                                        <hr size="10"
                                        [ngClass]="{'hrcolor':formIsActive,
                                                    'stdTheme':!formIsActive}" > 
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-tab>
                        <mat-tab label="{{ 'usage hours' | translate }}">
                            <!--
                            <ng-template mat-tab-label>
                                <span (click)="currentTabSelected('hours')">{{ 'usage hours' | translate }}</span>
                            </ng-template>
                            -->
                            <div *ngIf="actionType === 'update'">
                                <mdi-equip-usage-hours
                                    [equipmentToUpdate]="equipmentToUpdate" 
                                    [workEquipArgs]="workEquipArgs" 
                                    [equipIdCall]="equipIdCall" 
                                    [optionsArg]="'equipmentSelect'"
                                    [ihmOptions]="ihmOptions"
                                    [okInputHours]="okInputHours"
                                    [randomIdString]="randomIdString" 
                                > </mdi-equip-usage-hours>
                            </div>
                        </mat-tab>
                        <mat-tab label="{{ 'docs' | translate }}" (click)="currentTabSelected('docs')">
                            <mat-tab-group *ngIf="actionType === 'update'"  mat-align-tabs="start" class="mat-tab-fill-height">
                                <mat-tab label="{{ 'administrative' | translate }}" >
                                    <ng-template matTabContent >
                                        <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:administrativeList, type: 'administrative'}"></ng-container> 
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="{{ 'technical doc' | translate }}">
                                    <div >
                                        <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:technicalDocList, type: 'technical'}"></ng-container>
                                    </div>
                                </mat-tab>
                                <mat-tab label="{{ 'user doc' | translate }}">
                                    <div >
                                        <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:userDocList, type: 'user'}"></ng-container>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-tab>
                        <mat-tab label="{{ 'work history' | translate }}" (click)="currentTabSelected('history')">
                            <div *ngIf="actionType === 'update'">
                                <mdi-works-history
                                    [workEquipArgs]="workEquipArgs" 
                                    [equipIdCall]="equipIdCall" 
                                    [optionsArg]="'equipmentSelect'"
                                    > </mdi-works-history>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    <mat-card-actions *ngIf="isBoatEquip && actionType === 'update'">
                        <div class="container-fluid d-flex justify-content-between" >
                            <button [disabled]="isReadOnly && !isRemovePossible" mat-raised-button  #buttonSubmit type="reset" (click)="removeEquip()">
                                <mat-icon>delete_sweep</mat-icon>
                                {{ 'Delete' | translate }}
                            </button>
                            <button [disabled]="!isInput || isRegistered || !equipmentForm.valid" mat-raised-button  #buttonSubmit type="submit" (click)="registerEquip('card')" >
                                <mat-icon>save</mat-icon>
                                {{ 'save' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>

                </mat-card>
            </form>
        </div>
        <div *ngIf="(isBoat && !isSearch && isBoatRegistered && statutCall !== 'work')" class="col-6">
            <span>
                <h4 class="titleTheme" style="padding-top: 10px;">{{ 'Equipments' | translate }}</h4>
            </span>
            <mdi-equipment-accordion 
                [parentParam]="parentParam"
                [ihmOptions]="ihmOptions"
                [okInputHours]="okInputHours"
                 ></mdi-equipment-accordion>
            
        </div>
    </div>     
</div>
<ng-template #tableDocs let-list='list' let-type='type'>
    <table class="table listcol" id="otherDocs" class="listCol stdTheme" >
        <thead >
            <th style="width: 5%" > </th>
            <th style="width: 50%">{{'docName' | translate}}</th>
            <th style="width: 20%">{{'docFilename' | translate}}</th>
            <th style="width: 20%">{{'stdCreation' | translate}}</th>
            <th style="width: 5%" >
                <button mat-icon-button (click)="addDoc(type)">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </th>
        </thead>
        <tbody style="overflow-y: auto;  height: 100px;">
            <tr class="curCol curColHidden" *ngFor="let docCur of list; let i = index" >
                <th style="width: 5%" >
                    <button *ngIf="!isReadOnly" mat-icon-button (click)="removeDoc(docCur)">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                </th>
                <td style="width: 50%; text-align: left;">{{docCur.docName }}</td>
                <td style="width: 20%; text-align: left;">{{docCur.docFilename }}</td>
                <td style="width: 20%; text-align: left;">{{getDateCreation(docCur.stdCreation)}}</td>
                <td style="width: 5%" >                                                    
                    <button mat-icon-button (click)="openDoc(docCur)">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>  
</ng-template>
<ng-template #pdfModalViewer style="width: 100%">
    <div class="modal-body">
        <mdi-doc-pdf [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-pdf>
    </div>
    <div class="modal-footer ">
        <!--<p>{{modalText}}</p>-->
        <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
            <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
        </div>
    </div>
</ng-template>
<ng-template #imgModalViewer style="width: 100%">
    <div class="modal-body">
        <mdi-doc-image [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-image>
    </div>
    <div class="modal-footer ">
        <!--<p>{{modalText}}</p>-->
        <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
        </div>
    </div>
</ng-template>
<ng-template #docModal style="width: 100%">
    <div class="modal-body">
        <mdi-doc-card 
            [statutCall]="'modalEquip'" 
            [optionItem]="'docs'" 
            [actionType]="'add'" 
            [docToUpdate]="pseudoDoc">
        </mdi-doc-card>
    </div>
   
</ng-template>
<!--
<ng-template #modalWorks let-modal >
    <div class="modal-body modal-works-body" >
      <mdi-works-card [dataIn]="dataInWork" 
                       [statutCall]="'modal'"
                       [actionType]="'addWork'"  
                       (onload)="ngOnInit()">
    </mdi-works-card>
    </div>
</ng-template>
-->
<!-- popup voir :   https://www.c-sharpcorner.com/article/use-of-ngx-bootstrap-modal-popup-in-angular-8/ -->
<!-- https://stackblitz.com/edit/mde-popover?file=app%2Fapp.component.html -->
<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <div *ngIf="equipmentToUpdate !== undefined">
        <mdi-equipment-owner [dataIn]="getPopoverData()"></mdi-equipment-owner>
    </div>
</mde-popover>
<mde-popover #metadataPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <div *ngIf="equipmentToUpdate !== undefined">
        <mdi-equipment-metadata [dataIn]="getPopoverMetadata()"></mdi-equipment-metadata>
    </div>
</mde-popover>