<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="container-fluid" style="margin-top: 75px; height: 80%;  width: 100%;">
  <div class="flex-row row  stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
      <div class="col top-sidenav" style ="text-align: center!important">
        <span style="font-size:large"><strong>{{ 'scheduler' | translate }}</strong></span>
        <div class="lineWks" style="margin-top: 5px;"></div>
      </div>
  </div>
  <div  class="flex-row row  stdTheme" style="padding: 0px; margin-left: 5px; margin-right: 0px;">
    <div class="col-3">
        <mat-card-content class="mat-elevation-z8 stdTheme" style="width: 100%;margin-bottom: 0px;">
            <div style="margin-bottom: 20px;">
                <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;height: 40px; line-height: 40px;">
                    <!--<div class="col-3"></div>-->
                    <div class="col-10 d-flex justify-content-center" style="padding-left: 5px;padding-right: 0px;"><strong>{{'jobName'| translate}}</strong></div>
                    <div class="col-2" style="padding-left: 0px;padding-right: 0px;">              
                        <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                            style="margin-left: 10px;margin-right: 10px;">
                            <span class="fas fa-redo" style="font-size: 15px" ></span>
                        </button>
                    </div>
                </div>
                <div class="lineWks" style="margin-top: 5px;"></div>
                <div *ngIf="okToDisplay" >
                    <div class="stdTheme" *ngFor="let jobItem of schedulerJobInfosList; let idxItem = index" 
                                        style="margin-bottom: 10px;">
                        <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;height: 40px; line-height: 40px;"
                                    [class.data-selected]="idxItem === selectedRow" 
                                     >
                            <div class="col-11 d-flex justify-content-start" style="padding-left: 5px;padding-right: 0px;"
                                (click)="setClickedRow(idxItem)">{{jobItem.jobName}}</div>
<!--
                            <div class="col-1" style="padding-left: 0px;padding-right: 0px;">
                                <button mat-icon-button class="buttonTheme" (click)="setEditRow(idxItem)" 
                                    >
                                    <span class="fa fa-edit" style="font-size: 15px"></span>
                                </button>              
                            </div>
-->
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </div>
    <div class="col-6">
        <mat-card-content class="mat-elevation-z8 stdTheme" style="width: 100%;margin-bottom: 0px;">
            <div class="flex-row row" style="margin-left: 0px; margin-right: 28px;height: 40px; line-height: 40px;">
                <div class="col-11 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"><strong>{{'jobDetail'| translate}}</strong></div>
                <div class="col-1"> 
                    <button mat-icon-button (click)="addNewJob()" >
                        <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="lineWks" style="margin-top: 5px;"></div>
            <div class="flex-row row" *ngIf="displayDetailOK" style="margin-left: 0px; margin-right: 0px;">
                <!--{{schedulerJobInfosCur.jobName}}-->
                <mdi-scheduler-card  style=" width: 100%;"
                    [schedulerJobInfosCur]="schedulerJobInfosCur" 
                    [statutCall]="statutCall" 
                    [selectedRow]="selectedRow" 
                    [entitiesList]="entitiesList"
                    (dataOut)="dataOutCard($event)"
                    >
                </mdi-scheduler-card>
            </div>
        </mat-card-content>
    </div>
    <!-- 
        la connection pour le journal provoque des erreurs liées au client utilisé. 
        la messagerie MQ (stomp) ne marche pas non plus : pas token dans le header. 
    -->
    <!--
    <div class="col-3">
        <mat-card-content class="mat-elevation-z8 stdTheme" style="width: 100%;margin-bottom: 0px;">
            <div style="margin-bottom: 20px;">
                <div class="flex-row row" style="margin-left: 0px; margin-right: 28px;height: 40px; line-height: 40px;">
                    <div class="col-12 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                        <strong>{{'jobJournal'| translate}}</strong>
                    </div>
                    <div class="lineWks" style="margin-top: 5px;"></div>
                    <div class="flex-row row" *ngIf="displayJournalOK" style="width: 100%;margin-left: 0px; margin-right: 0px;">
                        <div class="col-12"> 
                            <mdi-scheduler-journal style=" width: 100%;"
                                    [schedulerJobInfosCur]="schedulerJobInfosCur"
                                    (dataOut)="dataOutJournal($event)" >
                            </mdi-scheduler-journal>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </div>
-->    
</div>
