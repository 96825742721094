/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./datetime-renderer.component";
import * as i2 from "../../../../../../../services/translate.service";
var styles_DatetimeRendererComponent = [];
var RenderType_DatetimeRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DatetimeRendererComponent, data: {} });
export { RenderType_DatetimeRendererComponent as RenderType_DatetimeRendererComponent };
export function View_DatetimeRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; _ck(_v, 1, 0, currVal_0); }); }
export function View_DatetimeRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mdi-datetime-renderer", [], null, null, null, View_DatetimeRendererComponent_0, RenderType_DatetimeRendererComponent)), i0.ɵdid(1, 114688, null, 0, i1.DatetimeRendererComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DatetimeRendererComponentNgFactory = i0.ɵccf("mdi-datetime-renderer", i1.DatetimeRendererComponent, View_DatetimeRendererComponent_Host_0, {}, {}, []);
export { DatetimeRendererComponentNgFactory as DatetimeRendererComponentNgFactory };
