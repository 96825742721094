
<div>
  <div *ngIf="dataIsLoaded" style="display: block">
    <canvas baseChart
      [data]="polarAreaChartData"
      [labels]="polarAreaChartLabels"
      [legend]="polarAreaLegend"
      [chartType]="polarAreaChartType"
      [colors]="polarAreaChartColors">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
  </div>
</div>

