<div class="container-fluid w-100 stdTheme" style="width: 100%; height: 100%;">
  <div *ngIf="!configLoaded" >
    <mat-progress-bar class="stdTheme" mode="indeterminate"></mat-progress-bar>
    <div  class="stdTheme" style="text-align: center">
      <span >{{ 'LoadingReportConfig' | translate }}</span>
    </div>
  </div>
  <div *ngIf="configLoaded && !dataLoaded" >
    <mat-progress-bar class="stdTheme" mode="query"></mat-progress-bar>
    <div  class="stdTheme" style="text-align: center">
      <span >{{ 'LoadingReportData' | translate }}</span>
    </div>
  </div>
  <div *ngIf="dataLoaded && !filtersSetting" >
    <mat-progress-bar class="stdTheme"  mode="query"></mat-progress-bar>
    <div  class="stdTheme" style="text-align: center">
      <span >{{ 'settingReportFilters' | translate }}</span>
    </div>
  </div>
  <div *ngIf="filtersSetting && !pinnedRowsSetting" >
    <mat-progress-bar class="stdTheme"  mode="query"></mat-progress-bar>
    <div class="stdTheme" style="text-align: center">
      <span >{{ 'settingReportPinnedRows' | translate }}</span>
    </div>
  </div>
  <div *ngIf="configLoaded" style="height: 100%;" >
    <div class="stdTheme" >
      <h4 *ngIf="!isSubReport">{{titleReport}}</h4>
      <h6 *ngIf="isSubReport">{{titleReport}}</h6>
    </div>

    <div *ngIf="!isSubReport" class="flex-row row" style="margin-left: 5em; padding-left: 5em">
      <input type="text" id="filter-text-box" style="width: 100%" matInput placeholder="{{ 'global filter' | translate }}" (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch/>
    </div>
    <!--  https://www.ag-grid.com/javascript-grid-styling/  -->
    <div class="flex-row row" style="height: 90%;">
      <ag-grid-angular #agGrid
          style="width: 100%; height: 100%;" 
          class="ag-theme-balham"
          [rowData]="dataSource.data"
          [columnDefs]="colDef"
          [suppressAutoSize]=false
          [localeText]="localeText"
          [gridOptions]="gridOptions"
          [frameworkComponents]="frameworkComponents"
          (filterModified)="onFilterChanged()"
          (rowClicked)="onRowClicked($event)"
          (cellClicked)="onCellClicked($event)"
          [getRowHeight]="getRowHeight"
          [isFullWidthCell]="isFullWidthCell"
          [fullWidthCellRenderer]="fullWidthCellRenderer"
          (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>

  </div>
</div>
<ng-template #modalViewer>

  <div class="modal-body">
    <mdi-display-pdf [titleReport]="modalText[0]" [statutCall]="'subReportPdfViewer'" [reportOrig]="reportToExecute" [reportNameToExecute]="reportPdf" [dataRecord]="currentNode" (onload)="ngOnInit()"></mdi-display-pdf>
  </div>
  <div class="modal-footer ">
    <!--<p>{{modalText}}</p>-->
    <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
  </div>
  
</ng-template>
