import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';

import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';
import { CalendarEventBdd } from '../../../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  httpHeaders: HttpHeaders;
  private calObject: any;

  constructor(private httpClient: HttpClient ) { }
  
  public setCalObject(calObjectArg: any) { 
    this.calObject = calObjectArg;
  }
  public getCalObject() { 
    return this.calObject ;
  }
  setHeaders(_headers: HttpHeaders) {
    this.httpHeaders = _headers;
  }
  
  getCalendarEvent(eventId: string): Observable<HttpResponse<any>> {
  
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getCalendarEvent/?` + 'eventId=' + eventId;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByType(stdEntity: string, calAppli: string, calType: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByType/?` 
                                  + 'stdEntity=' + stdEntity + '&calAppli=' + calAppli + '&calType =' + calType;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActor(stdEntity: string, calAppli: string, calType: string, calActor: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActor/?` 
                                  + 'stdEntity=' + stdEntity 
                                  + '&calAppli=' + calAppli 
                                  + '&calType =' + calType
                                  + '&calActor =' + calActor;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByLinkId(stdEntity: string, calAppli: string, calType: string, calLinkId: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByLinkId/?` 
                                  + 'stdEntity=' + stdEntity 
                                  + '&calAppli=' + calAppli 
                                  + '&calType =' + calType
                                  + '&calLinkId =' + calLinkId;

    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActors(stdEntity: string, calActors: string, entityTz: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActors/?` 
                                  + 'stdEntity=' + stdEntity 
                                  + '&calActors=' + calActors
                                  + '&entityTz=' + entityTz;
    // urlService = CommonMethods.cleanURLString(urlService);
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  getEventsByActorsDates(stdEntity: string, calActors: string, startDate: Date, endDate: Date, entityTz: string): Observable<HttpResponse<any>> {

    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlService = `${environment.baseUrl}/wks/jobs/getEventsByActorsDate/?` 
                                  + 'stdEntity=' + stdEntity 
                                  + '&calActors=' + calActors
                                  + '&startDate=' + startDate 
                                  + '&endDate=' + endDate
                                  + '&entityTz=' + entityTz;
    // urlService = CommonMethods.cleanURLString(urlService);
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }
  saveWksCalendar(_eventCur: CalendarEventBdd, entityTz: String, action: string, actorEmail: string, ownerId: string): Observable<HttpResponse<CalendarEventBdd>> {
    
    
    const urlCur = `${environment.baseUrl}/wks/jobs/wksCalendar/save/?` 
                                        + 'entityTz=' + entityTz 
                                        + '&action=' + action 
                                        + '&actorEmail=' + actorEmail
                                        + '&ownerId=' + ownerId;
    
    const body = JSON.stringify(_eventCur);

    return this.httpClient
      .put<CalendarEventBdd>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}
