import { EventEmitter } from '@angular/core';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';
import { environment } from '../../../../environments/environment';
import { ColReportService } from '../models/job-data.model';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/user.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../services/translate.service";
export class JobDataService {
    constructor(_userService, httpClient, _translate) {
        this._userService = _userService;
        this.httpClient = httpClient;
        this._translate = _translate;
        this.onRegisterValid = new EventEmitter();
        this.displayGenerateTime = new EventEmitter();
        this.onChangeStatut = new BehaviorSubject(undefined);
    }
    // agGrid customized 
    settingFilterValues(_filterName, _values) {
        if (this.filtersValues === undefined) {
            // this.filtersValues.set(_filterName, _values);
            this.initFilterValues();
        }
        this.filtersValues.set(_filterName, _values);
    }
    initFilterValues() {
        this.filtersValues = new Map();
    }
    // user envt
    setUserEnvt(_userEnvt) {
        this.userEnvt = _userEnvt;
    }
    getUserEnvt() {
        return this.userEnvt;
    }
    getUserEnvtElement(_elemType, _elemName) {
        if (this.getUserEnvt() === undefined) {
            const userEnvt = {
                id: this.getUserLogged().id,
                userenvt: undefined,
            };
            this.setUserEnvt(userEnvt);
            return undefined;
        }
        if (this.getUserEnvt().userenvt === undefined) {
            return undefined;
        }
        switch (_elemType) {
            case 'dashboard': {
                const userEventLoc = JSON.parse(this.getUserEnvt().userenvt);
                const dashboards = JSON.parse(userEventLoc['dashboards']);
                if (dashboards === undefined) {
                    return undefined;
                }
                for (const dashboardCur of dashboards) {
                    // const dashboardCur = JSON.parse(dashBoard);
                    if (dashboardCur.dashboardName === _elemName) {
                        return dashboardCur;
                    }
                }
                break;
            }
            case 'dashboards': {
                const dashboards = JSON.parse(this.getUserEnvt().userenvt).dashboards;
                if (dashboards === undefined) {
                    return undefined;
                }
                else {
                    return JSON.parse(dashboards);
                }
            }
            default: {
                break;
            }
        }
    }
    getUserLogged() {
        return this._userService.getUserLogged();
    }
    // Event
    changeStatutEvent(event) {
        this.onChangeStatut.next(event);
    }
    // columnList: reportSheets
    // changeColReportService
    changeColReportService(_widgets, _subReports, _rowPinned, _cur, _doEvent, _statut, _isCompleted) {
        if (_subReports) {
            this.colReportService.subReports = _subReports;
        }
        if (_rowPinned) {
            this.colReportService.rowPinned = _rowPinned;
        }
        if (_cur) {
            this.colReportService.colCur = _cur;
        }
        if (_statut) {
            this.colReportService.colStatut = _statut;
        }
        if (_doEvent) {
            this.colReportService.colDoEvent = _doEvent;
        }
        if (_isCompleted) {
            this.colReportService.isCompleted = _isCompleted;
        }
        if (_widgets) {
            this.colReportService.widgets = _widgets;
        }
    }
    setColReportService() {
        this.colReportService = new ColReportService();
    }
    getColReportService() {
        return this.colReportService;
    }
    // Datasource
    getDatasources() {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/`;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getDatasource(_dbsLabel) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/` + _dbsLabel;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    deleteDatasource(_dbsLabel) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/` + _dbsLabel;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient
            .delete(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    testConnect(_datasource) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/testJobDbs/`;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_datasource);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveDatasource(_datasource, statutCall) {
        let urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/updateJobDbs/`;
        this.curHttpHeaders = this._userService.getHeaders();
        if (statutCall === 'createDatasource') {
            urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/addJobDbs/`;
        }
        const body = JSON.stringify(_datasource);
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    getListDbs(_requestEngine) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatadbs/listdbs/` + _requestEngine;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    // Requests
    getListRequests(_criteria) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` + 'criteria=' + _criteria;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getRequestLabel(_requestLabel) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` +
            'criteria=none&requestBy=requestLabel&requestSearch=' + _requestLabel;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getRequestRef(_requestRef, _onlyDefault) {
        let criteria = 'onlyDefault';
        if (!_onlyDefault) {
            criteria = 'none';
        }
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/?` +
            'criteria=' + criteria + '&requestBy=requestRef&requestSearch=' + _requestRef;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    deleteRequest(_id) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/` + _id;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient
            .delete(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    pdfViewerRequest(_path) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/pdfViewer/?` +
            'path=' + _path;
        const localHttpHeaders = this._userService.getHeaders();
        localHttpHeaders.set('Accept', 'application/pdf');
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
        /*
        return this.httpClient
          .put<any>(urlCur, body, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob' as 'json',
          }).pipe(
            catchError(this.handleErrorObservable) );
            */
    }
    getRequestChart(_requestTodo) {
        const body = JSON.stringify(_requestTodo);
        // const restructionsValue = JSON.stringify(_restructionsValue);
        const restructionsValue = 'test';
        /*let urlCur = `${environment.baseUrl}/jobdata/jobdatareports/requestchart/?`+
                  'requestRef='+_requestRef+'&restructionsValue='+restructionsValue;*/
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/requestchart/`;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    launchReport(_report, _sample) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/launchreport/?` +
            'sample=' + _sample;
        this.curHttpHeaders = this._userService.getHeaders();
        _report.tagsauthorized = this._userService.getUserLogged().grpCur.tagsauthorized;
        _report.tagsprohibited = this._userService.getUserLogged().grpCur.tagsprohibited;
        const body = JSON.stringify(_report);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    testRequest(_request) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/testJobRequest/`;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_request);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveRequestDataAccess(_request) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/updateJobRequestDataAccess/`;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_request);
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveRequest(_request, statutCall) {
        let urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/updateJobRequest/`;
        this.curHttpHeaders = this._userService.getHeaders();
        // console.log(JSON.stringify(_request));
        if (statutCall === 'createRequest') {
            urlCur = `${environment.baseUrl}/jobdata/jobdatarequests/addJobRequest/`;
        }
        const body = JSON.stringify(_request);
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    // requestLabels
    getListRequestLabels(_requestRef, _userLangCur, colNameList) {
        const urlCur = `${environment.baseUrl}/jobdata/getJobRequestDataLabels/?` +
            'requestRef=' + _requestRef + '&userLang=' + _userLangCur + '&colNameList=' + colNameList;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    saveRequestLabels(_requestLabels, _requestRef, _userLangCur, _colNameList) {
        // jobdata/saveJobRequestDataLabels/?requestRef=AA&userLang=fr&colNameList=titleRequestXXent_rsXXent_code
        const urlCur = `${environment.baseUrl}/jobdata/saveJobRequestDataLabels/?` +
            'requestRef=' + _requestRef + '&userLang=' + _userLangCur + '&colNameList=' + _colNameList;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_requestLabels);
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    // Reports
    getListReports(_optionItem) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/?` +
            'optionList=' + _optionItem;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getLastVersionReport(_reportLabel) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/execute/lastVersion/` + _reportLabel;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getReport(_reportLabel) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/` + _reportLabel;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    deleteReport(_id) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/` + _id;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient
            .delete(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveReportDataAccess(_request) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/updateJobReportDataAccess/`;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_request);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveReport(_report, statutCall) {
        let urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/updateJobReport/`;
        this.curHttpHeaders = this._userService.getHeaders();
        if (statutCall === 'createReport') {
            urlCur = `${environment.baseUrl}/jobdata/jobdatareports/manage/addJobReport/`;
        }
        const body = JSON.stringify(_report);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveEnvtUser() {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/saveEnvtUser/`;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(this.getUserEnvt());
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    saveMenu(_menu, _userLangCur) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/saveJobMenu?` +
            'userLang=' + _userLangCur;
        this.curHttpHeaders = this._userService.getHeaders();
        const body = JSON.stringify(_menu);
        // this.retrieveUser();
        return this.httpClient
            .put(urlCur, body, {
            headers: this.curHttpHeaders,
            observe: 'response',
        }).pipe(catchError(this.handleErrorObservable));
    }
    buildMenu(_entity, _userLang, _userId) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/buildmenu?` +
            'entity=' + _entity + '&userLang=' + _userLang + '&userid=' + _userId;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    getMenu(_entity, _userLang) {
        const urlCur = `${environment.baseUrl}/jobdata/jobdatamenus/?` +
            'entity=' + _entity + '&userLang=' + _userLang;
        this.curHttpHeaders = this._userService.getHeaders();
        return this.httpClient.get(urlCur, {
            headers: this.curHttpHeaders,
            observe: 'response'
        });
    }
    // Errors
    handleErrorObservable(error) {
        console.error(error.message || error);
        return observableThrowError(error.message || error);
    }
}
JobDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobDataService_Factory() { return new JobDataService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.TranslateService)); }, token: JobDataService, providedIn: "root" });
