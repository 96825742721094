import {MatPaginatorIntl} from '@angular/material';
import { TranslateService } from '../services/translate.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MDIMatPaginatorIntl extends MatPaginatorIntl {

    translate: TranslateService;
    firstPageLabel = 'First page';
    itemsPerPageLabel = 'Items per page';
    lastPageLabel = 'Last page';
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';

  /*  constructor(private _translate: TranslateService) {
        this.translate = _translate;
    }*/
    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const of = this.translate ? this.translate.getTranslate('mat-paginator-intl.of') : 'of';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = ((page * pageSize) > length) ?
            (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
        const endIndex = Math.min(startIndex + pageSize, length);
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    }

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;
        this.translate.onLangChanged.subscribe(() => {
            this.translateLabels();
          });
          this.translateLabels();
    }

    translateLabels() {
        this.firstPageLabel = this.translate.getTranslate('mat-paginator-intl.first_page');
        this.itemsPerPageLabel = this.translate.getTranslate('mat-paginator-intl.items_per_page');
        this.lastPageLabel = this.translate.getTranslate('mat-paginator-intl.last_page');
        this.nextPageLabel = this.translate.getTranslate('mat-paginator-intl.next_page');
        this.previousPageLabel = this.translate.getTranslate('mat-paginator-intl.previous_page');

    }

}
