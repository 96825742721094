<div  class="container stdTheme"  >
    <div class="row"> 
      <div class="col-12"> 
        <h4 align="center">{{titleReport}}</h4>
      </div>
    </div>
  <div class="row">
      <div class="container-fluid d-flex justify-content-between">

        <select class="form-control stdTheme " id="pdfSelect"  #pdfSelect style="width:auto;"  (change)="setPdfCur(pdfSelect.value)" >
          <option class="optionSelect" *ngFor="let pdfCur of pdfFiles" [selected]="pdfCur.fileLabel === selectedPdf.fileLabel" >{{ pdfCur.fileLabel}}</option>
        </select>

      </div>
      <!--
      <div class="container-fluid d-flex justify-content-between stdTheme">
        <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
          <mat-select #pdfSelect  fid="pdfSelect" (change)="setPdfCur(pdfSelect.value)" [(value)]="selected"
                  panelClass="mdiselectcontent"
                  ngDefaultControl >
            <mat-option class="selectOptionsTheme" *ngFor="let pdfCur of pdfFiles"  [value]="pdfCur.fileLabel">{{ pdfCur.fileLabel }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      -->
    </div>
    <div class="row">
      <!--
        <div class="col-2" style="margin: 0px; padding: 0px"> 
            <ng-template ngFor let-i="index" let-c="count" let-pdfFile [ngForOf]="pdfFiles">
              <button  class="btn btn-default btn-block buttonpdf" >{{pdfFile.fileName}}</button>
            </ng-template>
        </div>
        -->
        <div class="col-12" style="width: 800px; height: 75vh">
          <!--
            https://www.npmjs.com/package/ngx-extended-pdf-viewer#how-to-use-the-library
            https://www.pdfviewer.net/multiple-documents
          -->
          <!--  <ngx-extended-pdf-viewer [src]="pdfDisplay" backgroundColor="#ffffff" 
                  useBrowserLocale="true" [height]="'75vh'" [zoom]="'page-width'"></ngx-extended-pdf-viewer> -->
                  <!-- <ng2-pdfjs-viewer #pdfViewer pdfSrc="pdfDisplay" [openFile]="false"></ng2-pdfjs-viewer>-->
                  <ng2-pdfjs-viewer  #pdfViewer></ng2-pdfjs-viewer>
          <!-- <pdf-viewer  [src]="pdfDisplay" 
                [render-text]="true"
                [render-text-mode]="2"
                [show-all]="false"
                [page]="1"
                [fit-to-page]="true"
                [autoresize]="true"
                (after-load-complete)="afterLoadPdfComplete($event)"
                style="display: block;" >
            </pdf-viewer>-->
      </div>
     </div>
</div>