/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../common/components/address-block/address-block.component.ngfactory";
import * as i9 from "../../../../../common/components/address-block/address-block.component";
import * as i10 from "../../../../../common/services/ressources.service";
import * as i11 from "../../../../../../services/translate.service";
import * as i12 from "../../../../../../services/user.service";
import * as i13 from "@angular/forms";
import * as i14 from "ngx-bootstrap/modal";
import * as i15 from "../../../../../common/services/event-broadcast.service";
import * as i16 from "angular-coordinates/src/coordinates.service";
import * as i17 from "../../../../../common/services/global-events-manager.service";
import * as i18 from "../../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i19 from "@angular/material/card";
import * as i20 from "./contacts-card.component";
import * as i21 from "@angular/material/dialog";
var styles_ContactsCardComponent = [i0.styles];
var RenderType_ContactsCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsCardComponent, data: {} });
export { RenderType_ContactsCardComponent as RenderType_ContactsCardComponent };
function View_ContactsCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "span", [["class", "fa fa-plus-square"], ["style", "margin-left: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 30px"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ContactsCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid"], ["style", "margin-top: 0px; margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row justify-content-around"], ["style", "margin-left: 0px;margin-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-11"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-1 container-fluid d-flex justify-content-end"], ["style", "padding-left: 0px; padding-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCardComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isReadOnly; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nameParent; _ck(_v, 3, 0, currVal_0); }); }
function View_ContactsCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeContact(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "span", [["class", "fa fa-trash"], ["style", "font-size: 15px"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ContactsCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "fa fa-chevron-down"], ["style", "font-size: 15px"]], null, null, null, null, null))], null, null); }
function View_ContactsCardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "fa fa-chevron-up"], ["style", "font-size: 15px"]], null, null, null, null, null))], null, null); }
function View_ContactsCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "container-fluid contactCur"], ["style", "margin: 0px; padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["contactCur_{{iRow}}", 1]], null, 14, "div", [["class", "row rowLine"], ["style", "line-height: 30px; "]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-1"], ["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCardComponent_5)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-10 labelLine"], ["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["style", "margin: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "col-1"], ["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "button", [["aria-controls", "collapseExample"], ["mat-icon-button", ""]], [[1, "aria-expanded", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ((_v.context.$implicit.isCollapsed = !_v.context.$implicit.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ContactsCardComponent_6)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ContactsCardComponent_7)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "row"], ["style", "margin-right: 0px;margin-left: 0px;"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i7.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "col-12"], ["style", "margin-right: 0px; margin-left: 0px;padding-left: 0px; padding-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "mdi-address-block", [], null, [[null, "dataOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataOut" === en)) {
        var pd_0 = (_co.dataOutContact($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_AddressBlockComponent_0, i8.RenderType_AddressBlockComponent)), i1.ɵdid(21, 1687552, null, 0, i9.AddressBlockComponent, [i10.RessourcesService, i11.TranslateService, i12.UserService, i13.FormBuilder, i1.ElementRef, i1.Renderer2, i14.BsModalService, i15.EventBroadcastService, i16.CoordinatesService, i17.GlobalEventsManagerService], { dataIn: [0, "dataIn"], config: [1, "config"] }, { dataOut: "dataOut" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isReadOnly; _ck(_v, 5, 0, currVal_1); var currVal_7 = _v.context.$implicit.isCollapsed; _ck(_v, 14, 0, currVal_7); var currVal_8 = !_v.context.$implicit.isCollapsed; _ck(_v, 16, 0, currVal_8); var currVal_11 = _v.context.$implicit.isCollapsed; _ck(_v, 18, 0, currVal_11); var currVal_12 = _co.getJsonContact(_v.context.$implicit, _v.context.index); var currVal_13 = _co.configContacts; _ck(_v, 21, 0, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "contactCur_", _v.context.index, ""); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.identity.cntFirstname; var currVal_3 = _v.context.$implicit.identity.cntLastname; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = !_v.context.$implicit.isCollapsed; var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = true; var currVal_10 = !i1.ɵnov(_v, 18).collapsed; _ck(_v, 17, 0, currVal_9, currVal_10); }); }
function View_ContactsCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[4, "overflow", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCardComponent_4)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.listContacts; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "auto"; _ck(_v, 0, 0, currVal_0); }); }
export function View_ContactsCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "wkscard stdTheme mat-card"], ["flex", ""], ["id", "bns-contacts"], ["layout", "column"], ["style", "width: 100%; margin-top: 0px; margin-bottom: 0px;padding: 0px;"]], [[2, "_mat-animation-noopable", null]], null, null, i18.View_MatCard_0, i18.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i19.MatCard, [[2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i18.View_MatCardHeader_0, i18.RenderType_MatCardHeader)), i1.ɵdid(3, 49152, null, 0, i19.MatCardHeader, [], null, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ContactsCardComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "mat-card-content", [["class", "wkscard mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i19.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCardComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoaded; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isLoaded; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ContactsCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-contacts-card", [], null, null, null, View_ContactsCardComponent_0, RenderType_ContactsCardComponent)), i1.ɵdid(1, 1687552, null, 0, i20.ContactsCardComponent, [i21.MatDialog, i11.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsCardComponentNgFactory = i1.ɵccf("mdi-contacts-card", i20.ContactsCardComponent, View_ContactsCardComponent_Host_0, { dataInput: "dataInput", config: "config", nameParent: "nameParent", optionItem: "optionItem", ihmOptions: "ihmOptions" }, { dataOut: "dataOut" }, []);
export { ContactsCardComponentNgFactory as ContactsCardComponentNgFactory };
