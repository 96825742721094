import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MdePopoverTrigger } from '@material-extended/mde';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { WksCentralService } from '../../../../services/wks-central.service';
import { UserService } from '../../../../../../services/user.service';
import { DataWorkModel, WksEquipmentsRequest, WksWorksModel, WorkEquipmentRef, WorkEquipments } from '../../../..//models/wks-works.model';

import { WorksHistoryCardComponent } from '../works-history/works-history-card/works-history-card.component';

@Component({
  selector: 'mdi-works-history',
  templateUrl: './works-history.component.html',
  styleUrls: ['./works-history.component.css']
})
export class WorksHistoryComponent implements OnChanges {

  @Input() workEquipArgs: WksEquipmentsRequest;
  @Input() equipIdCall: string;
  @Input() optionsArg: any;

  @ViewChild( MdePopoverTrigger, { static: false }) localPopover: MdePopoverTrigger;
  @ViewChild('detailWorkPopover', { static: false }) detailWorkPopover: MdePopoverTrigger;
  
  private readonly onDestroy = new Subject<void>();
  
  historyWorksList = [];
  dataInWork: DataWorkModel;
  displayPopover: boolean;
  selectedRow: number;
  workCur: WksWorksModel;
  
  constructor(private _userService: UserService,
            private _dialog: MatDialog,
            private _wksCentralService: WksCentralService) { }


  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'workEquipArgs': {
            this.workEquipArgs = changes['workEquipArgs'].currentValue;
            break;
          } // end case
           // equipIdCall
          case 'equipIdCall': {
            this.equipIdCall = changes['equipIdCall'].currentValue;
            break;
          } // end case
          // optionsArg
          case 'optionsArg': {
            this.optionsArg = changes['optionsArg'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.getWorksHistory();
    this.displayPopover = false;

  }
  getWorksHistory() {
    this.historyWorksList = [];

    if (this._userService.getUserLogged() === undefined ) { return; }
 
    this._wksCentralService.getWorksHistory(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang, this.workEquipArgs)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const response = data.body;
        for (const item of response) {
          const workEquipments: WorkEquipmentRef = JSON.parse(item.workEquip);
          if (this.equipIdCall === workEquipments.mainEquipment) {
            this.historyWorksList.push(item);
          } else {
            for (const subEquipRef of workEquipments.subEquipments) {
              if (this.equipIdCall === subEquipRef) {
                this.historyWorksList.push(item);
              }
            }
          }
        }
      },
      _err => {
        // console.log(err);

      }
    );
  }
  setClickedRow(row: number)  {
    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.workCur = undefined;
    } else {
      this.selectedRow = row;
      this.workCur = this.historyWorksList[row];
      this.dataInWork = {
        work: this.workCur,
        iRow: row,
      };
      this.displayWorkDetail(row);
    }
  }
  deselectRow(row: number)  {
    // this.selectedRow = -1;
  }
  displayDetail(event: Event, row: number)  {
    
    if (this.localPopover === undefined) {
      return;
    } 
    this.localPopover._elementRef.nativeElement.style.position = 'absolute';
    this.localPopover._elementRef.nativeElement.style.top = '100px';
    this.localPopover._elementRef.nativeElement.style.left = '30px';
    // this.detailPopover.closePopover();
    if ( this.selectedRow !== row) {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
      return;
    } 
    // console.log('displayDetail : ' + row + ' displayPopover ' + this.displayPopover);
    // https://stackblitz.com/edit/mde-popover-dynamic-target-position?file=app%2Fapp.component.ts
    /*this.detailWorkPopover._elementRef.nativeElement.style.position = 'absolute';
    this.detailWorkPopover._elementRef.nativeElement.style.top = '100px';
    this.detailWorkPopover._elementRef.nativeElement.style.left = '30px';
    */
    if (this.displayPopover) {
      this.localPopover.togglePopover();
      this.displayPopover = false;
    } else  {

      this.localPopover.openPopover();
      this.displayPopover = true;
    }
  }
  displayWorkDetail(rowCur: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100%';
    dialogConfig.width = '80%';
    // dialogConfig.panelClass = 'no-padding';
    dialogConfig.data = {
      row: rowCur,
      workCur: this.workCur
      };

    const dialogRef = this._dialog.open(WorksHistoryCardComponent, dialogConfig);
    
    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') ) {
          this.selectedRow = -1;
          this.workCur = undefined;
        }

      });
  }
}
