import { Component, OnInit, OnChanges, Input, ViewChild, ViewEncapsulation, Renderer2, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../services/user.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { DatasourceInt, DatasourceModel } from '../../../models/job-data.model';

@Component({
  selector: 'mdi-datasource-card',
  templateUrl: './datasource-card.component.html',
  styleUrls: ['./datasource-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DatasourceCardComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() datasourceToUpdate: DatasourceInt;

  @ViewChild('buttonSubmit', {static: false}) buttonSubmit: any;
  @ViewChild('refElement', {static: true}) refElement: any;
  @ViewChild('titleCard', {static: false}) titleCard: any;

  registrationFailed: boolean;
  showPassword = true;
  dbsLabelCtrl: FormControl;
  dbsEngineCtrl: FormControl;
  dbsServerCtrl: FormControl;
  dbsPortCtrl: FormControl;
  dbsNameCtrl: FormControl;
  dbsUserCtrl: FormControl;
  dbsPwdCtrl: FormControl;
  dbsDocCtrl: FormControl;
  accessEntitiesCtrl: FormControl;
  accessGrpCtrl: FormControl;

  datasourceForm: FormGroup;
  RegisterTitle = 'Register';
  messageTranslate: string;

  isDatasourceFound: boolean;
  isUpdateStatut: boolean;
  datasourceCard: DatasourceInt;

  doTestConnect = false;
  isTestConnectOK = true;

  engineList: string[];
  entitiesList: string[];
  grpsList: string[];

  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const statutCallValue = changes['statutCall'];
    const userToUpdateValue = changes['datasourceToUpdate'];

    this.initData();
  }
  initData() {
    // console.log('Testing ngOnInit');
    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createEntity';
    }

    this.engineList = CommonMethods.listToArray(`${environment.enginesDb}`, ',');
    this.dbsLabelCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.dbsEngineCtrl = this.fb.control('');
    this.dbsServerCtrl = this.fb.control('');
    this.dbsPortCtrl = this.fb.control('', Validators.required);
    this.dbsNameCtrl = this.fb.control('', Validators.required);
    this.dbsUserCtrl = this.fb.control('', Validators.required);
    this.dbsPwdCtrl = this.fb.control('', Validators.required);
    this.dbsDocCtrl = this.fb.control('');
    this.accessEntitiesCtrl = this.fb.control('', Validators.required);
    this.accessGrpCtrl = this.fb.control('', Validators.required);

    this.datasourceForm = this.fb.group({
      dbsLabel: this.dbsLabelCtrl,
      dbsEngine: this.dbsEngineCtrl,
      dbsServer: this.dbsServerCtrl,
      dbsPort: this.dbsPortCtrl,
      dbsName: this.dbsNameCtrl,
      dbsUser: this.dbsUserCtrl,
      dbsPwd: this.dbsPwdCtrl,
      accessEntities: this.accessEntitiesCtrl,
      accessGrp: this.accessGrpCtrl,
      dbsDoc: this.dbsDocCtrl,
    },
      {
        updateOn: 'blur'
      });

    this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
    if (this.statutCall === 'createDatasource') {
      this.RegisterTitle = 'Creation';
    }
    this.isUpdateStatut = false;
    if (this.statutCall === 'updateDatasource') {
      this.isUpdateStatut = true;
      this.RegisterTitle = 'Update';

      this.setValuesToUpdate();
    }

    this.getListEntities();

    this.isDatasourceFound = false;

    this.datasourceForm.controls['dbsLabel'].valueChanges.subscribe (
      () => {
        this.validdbsLabel();
        this.doTestConnect = false;
        this.isTestConnectOK = true;
      }
    );
    this.datasourceForm.valueChanges.subscribe (
      () => {
        this.doTestConnect = true;
        this.isTestConnectOK = false;
      }
    );
    this.datasourceForm.controls['accessEntities'].valueChanges.subscribe (
      (val) => {
        this.loadListGrps(val);
      }
    );
  }
  getListEntities() {
    this.entitiesList = [];
    if (this._userService.getUserLogged().extendedentities.includes('ALL')) {
      this._userService.getEntities()
      .subscribe(
        data => {
          const listEntities = data.body;
          // this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
          this.entitiesList.push('ALL');
          const curEntities = CommonMethods.jsonPropToArray(listEntities, 'entity');
          for (const curEntity of curEntities) {
            this.entitiesList.push(curEntity);
            }
        }
      );
    } else {
      const curEntities = this._userService.getUserLogged().extendedentities.split(',');
      for (const curEntity of curEntities) {
        this.entitiesList.push(curEntity);
        }
    }
  }
  loadListGrps(_val: any) {

    if (_val === null ) { return; }
    this.grpsList = [];
    if (('ALL' === _val[0]) && (_val.length > 1)) {
      this.datasourceForm.controls.accessEntities.setValue(['ALL']);
      return;
    }
    if (('ALL' === _val[0]) || (_val.length > 1)) {
      this.grpsList.push('ALL');
    } else {
      // grps of entity
      this.doGetListGrps(_val[0]);
    }
  }
  doGetListGrps(_entity: string) {
    // this.getListGrps(_entity);
    this.getListGrps(_entity)
    .then ((grpsList) => {

    })
    .then((error) => {

    });
  }
  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .subscribe(
          data => {
            const usergroupLists = data.body;
            this.grpsList = CommonMethods.jsonPropToArray(usergroupLists, 'usergroup');
              resolve(usergroupLists);
          }, err => {
            this.grpsList = [];
            reject(err);
          }
        );
      });
  }
  loadGrps(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value, event.source.selected);
    }

  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'dbsLabel': {
        !this.datasourceForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.datasourceForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request label is required') : messageLib = '';
        break;
      }
      case 'isRequestFound': {
        messageLib = this._translate.getTranslate('requestFound');
        break;
      }
      case 'dbsEngine': {
        !this.datasourceForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.datasourceForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request engine is required') : messageLib = '';
        break;
      }
      case 'dbsServer': {
        !this.datasourceForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.datasourceForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request url is required') : messageLib = '';
        break;
      }
      case 'dbsPort': {
        !this.datasourceForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.datasourceForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request port is required') : messageLib = '';
        break;
      }
      case 'dbsName': {
        break;
      }
      case 'dbsUser': {
        break;
      }
      case 'dbsPwd': {
        break;
      }
      case 'accessEntities': {
        break;
      }
      case 'accessGrp': {
        break;
      }
      case 'dbsDoc': {
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
  doCancel() {
    this.datasourceForm.reset();
    if (this.statutCall) {
      this._jobDataService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  validdbsLabel() {
    const dbsLabel = this.datasourceForm.controls['dbsLabel'].value;
    if ((dbsLabel) && (dbsLabel.length > 3)) {
      if ((this.statutCall === 'createDatasource')
       || ((this.statutCall === 'updateDatasource')
              && (dbsLabel !== this.datasourceToUpdate.dbsLabel))) {
        this.ctrldbsLabel(dbsLabel);
      }
    }
  }
  ctrldbsLabel(_dbsLabel: string): DatasourceInt {
    this.isDatasourceFound = false;
    let dataResponse: DatasourceInt = null;
    this._jobDataService.getDatasource(_dbsLabel)
    .subscribe(
      data => {
        dataResponse = data.body;
        this.isDatasourceFound = true;
        if (this.statutCall === 'createDatasource') {
          this.datasourceForm.controls['dbsLabel'].setErrors({ 'isDatasourceFound': true});
        }
        // console.log('Entity found : ', _entity);
      }, () => {
        // console.log('Entity unfound : ', _entity);
      }
    );
    return dataResponse;
  }

  testConnect() {
    this.doTestConnect = false;
    this.isTestConnectOK = false;

    this.datasourceCard = this.prepareValidation();
    this._jobDataService.testConnect(this.datasourceCard)
    .subscribe(
      (isOK) => {
        if (isOK) {
          this.doTestConnect = false;
          this.isTestConnectOK = true;
          this.messageTranslate = this._translate.getTranslate('Test connection OK');
        } else {
          this.doTestConnect = true;
          this.isTestConnectOK = false;
          this.registrationFailed = true;
          this.messageTranslate = this._translate.getTranslate('Test connection error');
        }

      },
      () => {
        this.messageTranslate = this._translate.getTranslate('Test connection error');
        this.doTestConnect = true;
        this.isTestConnectOK = false;
        this.registrationFailed = true;
      }
    );

  }
  register() {
    this.registrationFailed = false;

    this.datasourceCard = this.prepareValidation();
    this._jobDataService.saveDatasource(this.datasourceCard, this.statutCall)
      .subscribe(
        () => {
          this.datasourceForm.reset();
          if (this.statutCall) {
            this._jobDataService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation(): DatasourceInt {

    const datasourceCard: DatasourceModel = new DatasourceModel();
    datasourceCard.dbsLabel = this.datasourceForm.value.dbsLabel;
    datasourceCard.dbsEngine = this.datasourceForm.value.dbsEngine;
    datasourceCard.dbsServer = this.datasourceForm.value.dbsServer;
    datasourceCard.dbsPort = this.datasourceForm.value.dbsPort;
    datasourceCard.dbsName = this.datasourceForm.value.dbsName;
    datasourceCard.dbsUser = this.datasourceForm.value.dbsUser;
    datasourceCard.dbsPwd = this.datasourceForm.value.dbsPwd;
    datasourceCard.dbsDoc = this.datasourceForm.value.dbsDoc;
    datasourceCard.dbsConnect = 'false';
    if (this.isTestConnectOK) { datasourceCard.dbsConnect = 'true'; }
    if ('ALL' === this.datasourceForm.value.accessEntities[0]) {
      datasourceCard.accessEntities = 'ALL';
    } else {
      datasourceCard.accessEntities = CommonMethods.arrayStringToString(this.datasourceForm.value.accessEntities, ',');
    }
    if (('ALL' === this.datasourceForm.value.accessGrp[0]) || (datasourceCard.accessEntities === 'ALL')) {
      datasourceCard.accessGrp = 'ALL';
    } else if (this.datasourceForm.value.accessEntities.length > 1 ) {
      datasourceCard.accessGrp = 'ALL';
     } else {
      datasourceCard.accessGrp = CommonMethods.arrayStringToString(this.datasourceForm.value.accessGrp, ',');
    }
    return datasourceCard;
  }

  setValuesToUpdate() {
    this.datasourceForm.controls.dbsLabel.setValue(this.datasourceToUpdate.dbsLabel);
    this.datasourceForm.controls.dbsEngine.setValue(this.datasourceToUpdate.dbsEngine);
    this.datasourceForm.controls.dbsServer.setValue(this.datasourceToUpdate.dbsServer);
    this.datasourceForm.controls.dbsPort.setValue(this.datasourceToUpdate.dbsPort);
    this.datasourceForm.controls.dbsName.setValue(this.datasourceToUpdate.dbsName);
    this.datasourceForm.controls.dbsUser.setValue(this.datasourceToUpdate.dbsUser);
    this.datasourceForm.controls.dbsPwd.setValue(this.datasourceToUpdate.dbsPwd);
    this.datasourceForm.controls.dbsDoc.setValue(this.datasourceToUpdate.dbsDoc);

    let curEntities = this._userService.getUserLogged().accessentities.split(',');
    if ((this.datasourceToUpdate.accessEntities !== null) && (this.datasourceToUpdate.accessEntities.trim().length > 3)) {
      curEntities = this.datasourceToUpdate.accessEntities.split(',');
    }

    const bindEntities: any[] = [];
    for (const curEntity of curEntities) {
      bindEntities.push(curEntity);
    }
    this.datasourceForm.controls.accessEntities.setValue(bindEntities);

    let curGrps: any[] = ['ALL'];
    if ((this.datasourceToUpdate.accessGrp !== null) && (this.datasourceToUpdate.accessGrp.trim().length > 1)) {
      curGrps = this.datasourceToUpdate.accessGrp.split(',');
    }
    const bindGrps: any[] = [];
    for (const curGrp of curGrps) {
      bindGrps.push(curGrp);
    }
    this.datasourceForm.controls.accessGrp.setValue(bindGrps);
    this.loadListGrps(bindEntities);
  }
}
