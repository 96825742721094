<div class="container-fluid" *ngIf="okToDisplay" style="width: 100%;margin-bottom: 40px">
    <div class="flex-row row" *ngIf="statutCall === 'add'" style="margin-left: 5px;margin-right: 5px;">
        <div class="col-2 d-flex justify-content-start" >
            <button style="margin-top: 5px;" class="mat-raised-button buttonTheme" 
                style="margin-bottom: 10px;" (click)="cancelJob()" >{{'cancelJob' | translate}}
            </button>
        </div>
        <div class="col-8 d-flex justify-content-center"  >
            <h6 style="padding-top: 10px;"> {{ 'jobCreate' | translate }}</h6>
        </div>
        <div class="col-2 d-flex justify-content-end" >
            <button style="margin-top: 5px;" class="mat-raised-button buttonTheme" 
                [disabled]="!isOkToSave" style="margin-bottom: 10px;" (click)="validData()" >{{'submit' | translate}}
            </button>
        </div>
    </div>
    <div class="flex-row row" *ngIf="statutCall !== 'add'" style="margin-left: 5px;margin-right: 5px;">
        <div class="container-fluid d-flex justify-content-between">
            <button  class="mat-raised-button buttonTheme" 
                    [disabled]="isOkToSave || !isOkToAction" 
                    style="margin-bottom: 10px;" (click)="deleteJob()" >{{'deleteJob' | translate}}
            </button>
            
            <button  class="mat-raised-button buttonTheme" [disabled]="isOkToSave || !isOkToAction" style="margin-bottom: 10px;" (click)="runJob()" >{{'runJob' | translate}}</button>
            
            <button  class="mat-raised-button buttonTheme"
                        [disabled]="isOkToSave || !isOkToAction || schedulerJobInfosCur.jobStatus === 'PAUSED'" 
                        style="margin-bottom: 10px;" (click)="pauseJob()" >{{'pauseJob' | translate}}
            </button>
            <button  class="mat-raised-button buttonTheme"
                        [disabled]="isOkToSave || !isOkToAction || schedulerJobInfosCur.jobStatus === 'RESUMED' || schedulerJobInfosCur.jobStatus === 'SCHEDULED' || schedulerJobInfosCur.jobStatus === 'EDITED & SCHEDULED'" 
                        style="margin-bottom: 10px;" (click)="resumeJob()" >{{'resumeJob' | translate}}
            </button>
            <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToSave" style="margin-bottom: 10px;" (click)="validData()" >{{'submit' | translate}}</button>
        </div>
    </div>
    <form [formGroup]="jobInfosForm"  style="margin: 0px; height: 100%; width: 100%;" >
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-6 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'jobName' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="jobName" required >
                </mat-form-field>
            </div>

            <div class="col-6 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'jobGroup' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="jobGroup" required>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-8 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'jobDesc' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="jobDesc" required>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-8 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ 'jobClass' | translate }}</mat-label>
                    <mat-select id="jobClass" placeholder="{{ 'Choose class' | translate }}" formControlName="jobClass" 
                            [disabled] = "statutCall === 'update' || statutCall === 'add' ? false : true"
                            panelClass="mdiselectcontent" #jobClass required
                            (selectionChange)="onClassSelected(jobClass.value)"
                            ngDefaultControl >
                        <mat-option class="selectOptionsTheme" *ngFor="let classCur of classList" 
                                    [value]="classCur.value">
                                    {{classCur.label}}
                        </mat-option>
                    </mat-select>    
                </mat-form-field>
            </div>
        </div>       
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;margin-top: 10px;">
            <div class="col-8 d-flex justify-content-start">
                <div #argsListFa  formArrayName="argListFg" style="width: 100%;" >
                    <div *ngFor="let argItem of argListFa.controls; let idxRow=index" style="width: 100%;">
                        <div [formGroupName]="idxRow" style="width: 100%;">
                            <div class="flex-row row rowArg" >
                                <div class="col-1 d-flex justify-content-center">
                                    <!--
                                    <button *ngIf="i > 0" mat-icon-button (click)="removeItemArg(i)" title="{{'delete row' | translate }}" style="padding-top: 25px;">
                                        <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                    </button>
                                    -->
                                </div>
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'jobArgName' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="nameArg" readonly
                                                    [value]="argsNameList[idxRow].label"
                                            >
                                        <!--
                                        <mat-label>{{ 'jobArgName' | translate }}</mat-label>
                                        <mat-select id="jobArgName"  formControlName="nameArg" 
                                                [disabled] = "statutCall === 'update' || statutCall === 'add' ? false : true"
                                                panelClass="mdiselectcontent" #nameArg [required] = "i > 0"
                                                (selectionChange)="onChangeArgs('nameArg', i, nameArg.value)"
                                                ngDefaultControl >
                                            <mat-option class="selectOptionsTheme" *ngFor="let argNameCur of argsNameList" 
                                                        [value]="argNameCur.value">
                                                        {{argNameCur.label}}
                                            </mat-option>
                                        </mat-select>  
                                        -->
                                    </mat-form-field>
                                </div>
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'jobArgValue' | translate }}</mat-label>
                                        <mat-select id="jobArgValue"  formControlName="valueArg" 
                                                [disabled] = "statutCall === 'update' || statutCall === 'add' ? false : true"
                                                [required] = "inputArgs[idxRow].mandatory"
                                                panelClass="mdiselectcontent" #valueArg 
                                                (selectionChange)="onChangeArgs('valueArg', idxRow, valueArg.value)"
                                                ngDefaultControl >
                                            <mat-option class="selectOptionsTheme" *ngFor="let itemValueCur of argsValueList[idxRow]" 
                                                        [value]="itemValueCur.value">
                                                        {{itemValueCur.label}}
                                            </mat-option>
                                        </mat-select>  
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center">
                                    <!--
                                    <button  mat-icon-button (click)="addItemArg()" title="{{'adding row' | translate }}" style="padding-top: 25px;">
                                        <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                                    </button>
                                    -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-8 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'cronExpression' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="cronExpression" required>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-4 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'jobStatus' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="jobStatus" >
                </mat-form-field>
            </div>
            <div class="col-4 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'lastRun' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="lastRun" >
                </mat-form-field>
            </div>

            <div class="col-4 d-flex justify-content-start">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'nextRun' | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" formControlName="nextRun" >
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
