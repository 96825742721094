<div *ngIf="isLoaded" #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', 'width': '100%','padding': '10px'}" >
       
        <div class="flex-row row dropzone" style="margin: 0px"  draggable="true" #dropArea >
            <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
                <form [formGroup]="docworksForm"  >
                    <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                        <mat-card-header>
                            <mat-card-title>
                            <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                            <section class="mat-typography">
                                <h2 class="titleTheme" style="padding-top: 10px;"><b>{{ titleCard | translate }}</b></h2>
                            </section>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <form [formGroup]="sequenceForm" *ngIf="uploadPhotos" style="margin: 0px; height: 100%; width: 100%;" >
                                <div class="flex-row row" *ngIf="uploadPhotos" style="margin-left: 2px;width: 100%; margin-bottom: 20px;">
                                    <div class="col-2 d-flex justify-content-left" style=" align-items: center;">
                                        <span>
                                            <b>{{'sequencePhotos' | translate}}</b>
                                        </span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" style="align-items: center;;margin-top: -15px" >
                                        <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                            <mat-label><b>{{ 'sequenceDate' | translate }}</b></mat-label>
                                            <!-- 
                                                https://www.tutorialspoint.com/ngx_bootstrap/ngx_bootstrap_datepicker.htm 
                                                https://github.com/valor-software/ngx-bootstrap/issues/2716
                                            -->
                                            <input matInput style="text-align: center;"
                                                placeholder="Datepicker"
                                                class="form-control inputTheme"
                                                bsDatepicker 
                                                [bsValue]="sequenceDateInput"
                                                [minDate]="minDate"
                                                [maxDate]="maxDate"
                                                formControlName="sequenceDateFc"
                                                [bsConfig]="datePickerConfig"
                                                (bsValueChange)="onSequenceDateChange($event)">
                                        </mat-form-field>
                                    </div>

                                    <div class="col-3 flex " >
                                        <div class="flex-row row" style="margin-left: px;margin-right: 0px; width: 100%; align-items: center;">
                                            <div class="col-4" style=" align-items: center;">
                                                <span>
                                                    <b>{{'sequenceTime' | translate}}</b>
                                                </span>
                                            </div>
                                            <div class="col-8" style=" align-items: center;">
                                                <ngb-timepicker 
                                                    [(ngModel)]="sequenceTimeInput"
                                                    (ngModelChange)="onSequenceTimeChange($event)" 
                                                    formControlName="sequenceTimeFc"
                                                >
                                                </ngb-timepicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!--<div class="container-fluid d-flex justify-content-between" >-->
                            <div class="flex-row row" style="margin-left: 2px;width: 100%">
                                <div class="col-2" >
                                    <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" >
                                                <mat-icon>library_add</mat-icon>
                                        <span>{{'SelectFiles' | translate}}</span>
                                        <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                        <input #fileInput type="file" (change)="onFileChange($event)" multiple [accept]="fileExt" style="display:none;" />
                                    </button>
                                </div>
                                <div class="col-8" >
                                    <div *ngIf="alertMessage" >
                                        <mdi-alert-common
                                            [messageInfo]="messageData.messageInfo"
                                            [data1]="messageData.dataMessageInfo1"
                                            [data2]="messageData.dataMessageInfo2"
                                            [messageType]="messageData.messageType"
                                            [alertType]=""
                                            >
                                        </mdi-alert-common>
                                    </div>
                                </div>
                                <div class="col-2" >
                                    <div >
                                        <button (click)="startUploadProcess() " mat-raised-button class="buttonTheme" [disabled]="!isUploadTodo" >
                                            <mat-icon>cloud_upload</mat-icon>
                                            <span>{{'uploadFiles' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                           <!-- </div> -->
                        </mat-card-actions>

                        <mat-card-content class="stdTheme">
                            <div class="flex-row row" style="margin-left: 2px;width: 100%; line-height: 1.0;">
                                <table class="table tableDocWork table-responsive stdTheme">
                                    <thead class="theadDocWork">
                                        <tr class="trDocWork ">
                                            <th  *ngFor="let titleColum of displayedColumns; let i=index"
                                                [ngStyle]="{'width': styleColumns[i].width, 'text-align':'center'}">
                                                {{titleColum | translate}} 
                                            </th>
                                            <th style="width: 5%">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbodyDocWork" >
                                        <tr *ngFor="let file of filesDeposit; let i = index" class="trDocWork" style="height: 60px!important;">
                                            <td *ngIf="!selectTags" class="tdDocWork align-middle" 
                                                    [ngStyle]="{'width': styleColumns[0].width, 'text-align': styleColumns[0].textAlign, 'vertical-align': 'middle' }" >
                                                <input class="form-control inputTheme" name="legendFile" #legendFile value="{{file.legendFile}}"
                                                matInput (change)="setCommentValue(legendFile.value, i)"
                                            />
                                            </td>
                                            <td  *ngIf="selectTags" class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[0].width, 'text-align': styleColumns[0].textAlign, 'vertical-align': 'middle' }" >
                                                <select class="selectBox inputTheme w-100" #tagsItem
                                                    (change)="onTagSelected(tagsItem.value)">
                                                    <option *ngFor="let itemTags of tagsArg" 
                                                            [value]="itemTags.value"
                                                            [selected]="itemTags.value === tagValue"
                                                            >{{ itemTags.label }}</option>
                                                </select>
                                            </td>  
                                            <td class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[1].width, 'text-align': styleColumns[1].textAlign, 'vertical-align': 'middle' }" >{{file.nameFile}}</td> 
                                            <td class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[2].width, 'text-align': styleColumns[2].textAlign, 'vertical-align': 'middle' }">({{file.sizeFile}} bytes)</td> 
                                            <td class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[3].width, 'text-align': styleColumns[3].textAlign, 'vertical-align': 'middle' }">{{file.typeFile}} </td>
                                            <td *ngIf="!file.uploadedFile" class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[4].width, 'text-align': styleColumns[4].textAlign, 'vertical-align': 'middle' }">
                                                {{'waiting' | translate}} 
                                                <span class="fa fa-cloud-upload mx-auto" style="color:green;font-size: 20px;" ></span>
                                            </td>
                                            <td *ngIf="file.uploadedFile" class="tdDocWork align-middle" 
                                                [ngStyle]="{'width': styleColumns[4].width, 'text-align': styleColumns[4].textAlign, 'vertical-align': 'middle' }">
                                                {{'uploaded' | translate}} 
                                                <span class="fa fa-cloud" style="color:red;font-size: 20px;" ></span>
                                            </td>
                                            <td style="width: 5%;padding-left: 0px; padding-right: 0px; vertical-align: middle">
                                                <button mat-icon-button  #buttonSubmit type="reset" (click)="removeFile(i)" style="padding-left: 0px; padding-right: 0px;">
                                                    <mat-icon>delete_sweep</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                            <div class="flex-row row" *ngIf="doComment">
                                
                                <div class="col-12">
                                    <!-- https://stackblitz.com/angular/gdbgelrxapk?file=src%2Fapp%2Ftext-field-autosize-textarea-example.ts-->
                                    <mat-form-field  style="width: 100%">
                                        <mat-label>{{ 'docworksComment' | translate }}</mat-label>
                                        <textarea id="docworksComment" class="form-control inputTheme docworksComment" formControlName="docworksComment" 
                                                    matInput #docComment placeholder="{{ 'docworksComment' | translate }}" 
                                                   
                                                  cdkTextareaAutosize
                                                  #autosizeComment="cdkTextareaAutosize"
                                                  cdkAutosizeMinRows="1"
                                                  cdkAutosizeMaxRows="4"></textarea>
                                      </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>

</div>
