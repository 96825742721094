import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css']
})
export class LinechartComponent implements OnInit {


  @Input() widgetData: Widget;
  @Input() displayTime: boolean;

  private readonly onDestroy = new Subject<void>();
  

  /*public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];*/
  public lineChartData: ChartDataSets[];
  // public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartLabels: Label[] ;
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
    // https://www.chartjs.org/docs/latest/configuration/animations.html#easing
    animation: {
      duration: 2000, // general animation time
      easing: 'easeInOutQuart',
    },
    hover:  {
        animationDuration: 200 // duration of animations when hovering an item
      },
    responsiveAnimationDuration: 1000 // animation duration after a resize
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  // public lineChartType = 'line';
  public lineChartType;
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, {static: false}) chart: BaseChartDirective;

  updateFrequency: number;

  chartData: ChartDataSets[];
  dataIsLoaded = false;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
    console.log('widgetData Linechart : ' + this.widgetData);
    this.updateFrequency = this.widgetData.updateFrequency;
    this.displayDateTime = this.displayTime;

    this._jobDataService.displayGenerateTime
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if ((event === 'displayTime') || (event === 'hideTime')) {
          this.toogleTime(event);
        }
    });
    this.buildRequest();
    this.lineChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction, 'unixM', localFmt);
      const jsonData = JSON.parse(resultChart.json_data);
      this.lineChartLabels = jsonData.labelCols as Label[];
      this.lineChartData = jsonData.datasets as ChartDataSets[];
      const colorAlphas = CommonMethods.genAlphaHslaColors(-1, 70, [20, 100, 80], this.lineChartData.length);
      this.lineChartColors = [];
      let idx = 0;
      for (const serieCur of this.lineChartData) {
        const colorCur = {
          backgroundColor: 'transparent',
          borderColor: colorAlphas[idx][1],
          pointBackgroundColor: colorAlphas[idx][1],
          pointBorderColor: colorAlphas[idx][1],
          pointHoverBackgroundColor: colorAlphas[idx][1],
          pointHoverBorderColor: colorAlphas[idx][2]
        };
        this.lineChartColors.push(colorCur);
        idx++;
      }
      this.dataIsLoaded = true;
    })
    .then((error) => {

    });
  }
  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if (restrict.columnName) {
        if (requestRestrictions !== '') {
          requestRestrictions += ';';
        }
        requestRestrictions += restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions;
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if (resultChart.length === 0) {
            reject();
          } else {
            resolve(resultChart[0]);
          }
        }, err => {
          
          reject(err);
        }
      );
    });
  }
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
