import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges, HostListener, ViewChild } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription, Subject } from 'rxjs';
import { HttpResponse, HttpEventType } from '@angular/common/http';

import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { ControlCsvService } from '../../../../../common/services/control-csv.service';
import { ReadFile, ColumnsHeaderStatut } from '../../../../../../models/common.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { RemoteFilesService } from '../../../../../common/services/remote-files.service';
import { UserService } from '../../../../../../services/user.service';

@Component({
  selector: 'mdi-anchorages-upload',
  templateUrl: './anchorages-upload.component.html',
  styleUrls: ['./anchorages-upload.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AnchoragesUploadComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;

  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  @Input() maxSizePdf = 15; // 5MB
  progress: { percentage: number } = { percentage: 0 };
  private readonly onDestroy = new Subject<void>();

  @ViewChild('dropArea', {static: false}) dropArea: any;

  uploadFileNameCtrl: FormControl;
  uploadForm: FormGroup;
  isReadOnly: boolean;
  isFinishLoading: boolean; 
  alertMessage: boolean; 
  doUpload: boolean;
  doVerify: boolean;

  fileExt: String = 'TXT, CSV, XLSX';
  fileRead: ReadFile;
  messageInfo = '';
  dataMessageInfo1 = '';
  dataMessageInfo2 = '';
  messageType = '';
  colErrors: string[];
  isUploadInProgress: boolean;
  isSearch: boolean;
  isAddOne: boolean;
  constructor(private fb: FormBuilder, 
              private _wksCentralService: WksCentralService,
              private _userService: UserService,
              private _controlCsvService: ControlCsvService,
              private _remoteFilesService: RemoteFilesService,
              private _dialog: MatDialog,
              private _translate: TranslateService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    this.isFinishLoading = false;
    this.alertMessage = false;
    this.doUpload = false;
    this.doVerify = false;
    this.isUploadInProgress = false;
    this.buildForm(); 
    this.isFinishLoading = true;
  }
  buildForm() {
    this.isReadOnly = false;
    this.uploadFileNameCtrl = this.fb.control({value: '', disabled: this.isReadOnly});

    this.uploadForm = this.fb.group({
      uploadFileName: this.uploadFileNameCtrl,
    },
    {
      updateOn: 'blur'
    });
  }
// ============================================================== start files
onFileChange(event: any) {
  event.preventDefault();
  event.stopPropagation();
  this.alertMessage = false;
  this.doUpload = false;
  this.doVerify = false;

  if (event.target.files && event.target.files.length > 0) {
    const files = event.target.files;
    if ((files.length > 0) && (this.isValidFiles(files))) {
      const file: File = files[0];
      const readingFile: ReadFile = new ReadFile();
      readingFile.nameFile = file.name;
      readingFile.sizeFile = file.size;
      readingFile.typeFile = file.type;
      readingFile.fileObject = file;
      readingFile.messageFile = '';
      readingFile.uploadedFile = false;
      readingFile.validatedFile = false;
      this.doUpload = false;
      this.doVerify = true;
      this.fileRead = readingFile;
      this.uploadForm.controls.uploadFileName.setValue(readingFile.nameFile);
    } else  {
      this.doUpload = false;
      this.doVerify = false;
      this.uploadForm.controls.uploadFileName.setValue('');
      this.fileRead = undefined;
    }
  } 

}
private isValidFiles(files): boolean {

  // Check Number of files
  if (files.length > this.maxFiles) {
    this.alertMessage = true;
    this.messageType = 'ERROR';
    this.messageInfo = 'The maximum number of files is reached';
    this.dataMessageInfo1 = files.length.toString() ;
    this.dataMessageInfo2 = this.maxFiles.toString() ;
    this.errorMessage('lenghtfile');
    return false;
  } else {
    return this.isValidFileExtension(files);
  }

}
private isValidFileExtension(files: any): boolean {
  // Make array of file extensions
  const extensions = (this.fileExt.split(','))
                  .map(function (x) { return x.toLocaleUpperCase().trim(); } );
  this.alertMessage = false;
  for (let i = 0; i < files.length; i++) {
      // Get file extension
      const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
      // Check the extension exists
      const exists = extensions.includes(ext);
      if (!exists) {
          this.alertMessage = true;
          this.messageType = 'ERROR';
          this.messageInfo = 'unrecognized extension';
          this.dataMessageInfo1 = ext ;
          this.dataMessageInfo2 = files[i].name ;
          this.errorMessage('extensionfile');
          break;
      }
      if (!this.alertMessage) {
      // Check file size
        this.isValidFileSize(files[i], ext);
        break;
      } else {
        continue;
      }
  }
  if (this.alertMessage) {
    return false;
  } else {
    return true;
  }
}

private isValidFileSize(_file: any, ext: string): boolean {
  const fileSizeinMB = _file.size / (1024 * 1000);
  const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place

  if (((ext === 'PDF') && (size > this.maxSizePdf)) || 
      ((ext !== 'PDF') &&  (size > this.maxSize))) {
      this.alertMessage = true;
      this.messageType = 'ERROR';
      this.messageInfo = 'too heavy file size';
      this.dataMessageInfo1 = _file.name  ;
      this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSize.toString() + 'MB MAX' ;
      this.errorMessage('heavyfile');
      return false;
    } else { return true; }
}
// drag and drop 
@HostListener('dragover', ['$event']) public onDragOver(evt: Event) {
  evt.preventDefault();
  evt.stopPropagation();
  this.dropArea.nativeElement.style.background = 'red';
  this.dropArea.nativeElement.style.border = 'dashed 3px red';
  this.alertMessage = false;
}

@HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
  evt.preventDefault();
  evt.stopPropagation();
  this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
  this.dropArea.nativeElement.style.border = 'dotted 3px grey'; 
}

@HostListener('drop', ['$event']) public onDrop(evt: any) {
  evt.preventDefault();
  evt.stopPropagation();
  this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
  this.dropArea.nativeElement.style.border = 'dotted 3px grey';
  const files = evt.dataTransfer.files;


  if ((files.length > 0) && (this.isValidFiles(files))) {
    const file: File = files[0];
    const readingFile: ReadFile = new ReadFile();
    readingFile.nameFile = file.name;
    readingFile.sizeFile = file.size;
    readingFile.typeFile = file.type;
    readingFile.fileObject = file;
    readingFile.messageFile = '';
    readingFile.uploadedFile = false;
    readingFile.validatedFile = false;
    this.doUpload = false;
    this.doVerify = true;
    this.fileRead = readingFile;
    this.uploadForm.controls.uploadFileName.setValue(readingFile.nameFile);
  } else  {
    this.doUpload = false;
    this.doVerify = false;
    this.uploadForm.controls.uploadFileName.setValue('');
    this.fileRead = undefined;
  }
}
// ============================================================== end files
  doCancel() {
      this._wksCentralService.onRegisterValid.emit('cancelUploadAnchorages');
  }
  uploadFile() {
    this.uploadProcess(this.fileRead.fileObject).then(
      (val: any) => {
        const messageRows: string[] = [];
        // ADD:6;UPDATE:0;DEL:0
        const messageDetail = val.split(';');
        const addedRows = messageDetail[0].split(':')[1];
        const updatedRows = messageDetail[1].split(':')[1];
        const deletedRows = messageDetail[2].split(':')[1];
        if (addedRows !== '0')  {
          messageRows.push( this._translate.instant('anchoragesAdded', [addedRows]));
        }
        if (updatedRows !== '0')  {
          messageRows.push( this._translate.instant('anchoragesUpdated', [updatedRows]));
        }
        if (deletedRows !== '0')  {
          messageRows.push( this._translate.instant('anchoragesDeleted', [deletedRows]));
        }
        let row = 0;
        for (const rowCur of messageRows)  {
          if (row === 0) {
            this.dataMessageInfo2 = rowCur;
          } else {
            this.dataMessageInfo2 = ' -- ' + rowCur;
          }
          row++;
        }
        this.messageInfo = 'upload file OK';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        
        this.doVerify = false;
        this.doUpload = false;
        this.uploadForm.controls.uploadFileName.setValue('');
        this.errorMessage('uploadOK');
      },
      (error) => {
        console.log('UPLOAD KO ' + this.fileRead.fileObject.name + ' : ' + error);
        this.messageInfo = 'upload file KO';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        this.dataMessageInfo2 = '(' + error + ')'  ;
        this.doVerify = false;
        this.doUpload = false;
        this.uploadForm.controls.uploadFileName.setValue('');
        this.errorMessage('uploadKO');
      }
    ); // end then uploadFile

  }
  private endProcessFile() {
    
    this._wksCentralService.onRegisterValid.emit('closeUploadAnchorages');
   
  }
  verifyFile(): boolean {
    this._controlCsvService.setColumsHeaders('anchorages', this._wksCentralService.getColumnsHeadersAnchoragesCsv());
    let isErrors = false;
    this.readFile(this.fileRead.fileObject)
    .then((result) => {
      const arrayOfLines = result as any[];
      const colErrorList: ColumnsHeaderStatut[] = this._controlCsvService.controlColumns(arrayOfLines, this.fileRead.fileObject.name, 'anchorages');
      if (colErrorList.length > 0) {
        isErrors = true;
        this.colErrors = [];
        for (const errorCur of colErrorList) {
          if (errorCur.notPresent) {
            this.colErrors.push(this._translate.instant('missingColumn', [errorCur.columnName] ));
          }
          if (errorCur.unKnow) {
            this.colErrors.push(this._translate.instant('unknownColumn', [errorCur.columnName] ));
          }
        }
        this.errorMessage('csvControl');
      } else {
        this.colErrors = [];
        const listError = this._controlCsvService.linesAnalyzeProcess(arrayOfLines,  
                  this._wksCentralService.getColumnsHeadersAnchoragesCsv(), this. _userService.getUserLogged(), this.fileRead.fileObject.name);
       
        if (listError.length > 0) {
          for (const errorCur of listError) {
            this.colErrors.push(this._controlCsvService.getErrorProcess(errorCur));
          }
          this.errorMessage('csvControl');
          this.doVerify = true;
          this.doUpload = false;
          return false;
        } else {
          this.doVerify = false;
          this.doUpload = true;
          return true;
        }
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    }); 
    return !isErrors;
  }
  private readFile(_file: File) {

    return  new Promise((resolve, reject) => {
      this._controlCsvService.readCsvFile(_file).then(
        (arrayOfLines) => {
          resolve(arrayOfLines);
        },
        (error) => {
          console.error('File could not be read! Code ' + error);
          reject('ProcessControlKO');
        },
      ); 
    });
  }
  // upload
  private uploadProcess(_file: File) {
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFileAnchorages(_file, 'anchorages', this._userService.getUserLogged().entity).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translate.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this._translate.getTranslate('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
              case 200:
                break;
              case 202:
                break;
            default:
              message = this._translate.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }
            if (numError === 200 || numError === 202 ) {
              const messageText = response.error.text;
              resolve(messageText);
            }
          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  // ========================================================== errors alerts 
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let action;
    const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
    const messageBox = this._translate.instant(this.messageInfo, argsMessage );
    if (_actionCur === 'heavyfile') {
      titleBox = 'too heavy file'; 
      this.colErrors = undefined;
    }
    if (_actionCur === 'lenghtfile') {
      titleBox = 'too many files'; 
      this.colErrors = undefined;
    }
    if (_actionCur === 'extensionfile') {
      titleBox = 'extension error';
      this.colErrors = undefined;
    }
    if (_actionCur === 'csvControl') {
      titleBox = 'ReportAnalyzeCSV';
      action = 'csvControl';
    }
    if (_actionCur === 'uploadOK') {
      titleBox = 'upload File anchorages';
      this.colErrors = undefined;
    }
    if (_actionCur === 'uploadKO') {
      titleBox = 'upload File anchorages';
      this.colErrors = undefined;
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: '',
      data2: '',
      actionCur: action,
      errorsList: this.colErrors,
      messageType: 'ERROR'
    };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (_actionCur === 'uploadOK') {
          this.endProcessFile();
        }
        // console.log('Dialog output:', data);
      });

  }

}
