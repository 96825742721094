import { Component, EventEmitter, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { WksCentralService } from '../../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../../services/translate.service';
import { EquipmentModel, AppointmentModel, ClientModel } from '../../../../../models/wks-appointments.model';
import { BrandParam, CustomParam, WksEntityParams, EquipmentParam, FormFieldFmt } from '../../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../../models/wks-entity.model';
import { LabelValue } from '../../../../../../../models/data.model';
@Component({
  selector: 'mdi-appointment-equipment-card',
  templateUrl: './appointment-equipment-card.component.html',
  styleUrls: ['./appointment-equipment-card.component.css']
})
export class AppointmentEquipmentCardComponent implements OnChanges {

  @Input()
  wksEntity: WksEntityModel;
  @Input()
  equipment: EquipmentModel;
  @Input()
  wksEntityParams: WksEntityParams;
  @Input()
  appointment: AppointmentModel;
  @Input()
  client: ClientModel;
  
  equipmentForm: FormGroup;
  servicesList: LabelValue[];
  isSerialNumberPresent: boolean;
  imgHelpLoaded: boolean;
  helpToShow: any;
  formField: string;
  equipmentParam: LabelValue[];
  equipmentBrand: BrandParam[];
  modelParam: CustomParam;
  serialNumberParam: CustomParam;
  displayCountHours: boolean;

  serialNumberInput = {
    fmt: '',
    holder: '',
    display: '',
    dataLengthMax: 0,
    inputError: false,
    inputValue: '',
    separator: ''
  };

  hours = {
    min: 100,
    max: 2000,
  };

  constructor(    private _wksCentralService: WksCentralService, public translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'wksEntityParams': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntityParams = changes['wksEntityParams'].currentValue;
            break;
          }
          case 'equipment': {
            // tslint:disable-next-line:no-string-literal
            this.equipment = changes['equipment'].currentValue;
            break;
          }
          case 'client': {
            // tslint:disable-next-line:no-string-literal
            this.client = changes['client'].currentValue;
            break;
          }
          case 'appointment': {
            // tslint:disable-next-line:no-string-literal
            this.appointment = changes['appointment'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }

  initData(): void {

    this.displayCountHours = false;
    this.imgHelpLoaded = false;
    this.isSerialNumberPresent = false;
    this.equipmentForm = new FormGroup({
      type: new FormControl('', Validators.required),
      brand: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      serialNumber: new FormControl('', Validators.required),
      nbHoursOfUse: new FormControl('', Validators.required),
      askedService: new FormControl(''),
      // askedServiceHours: new FormControl('', [Validators.max(this.hours.max), Validators.min(this.hours.min)]),
      askedServiceHours: new FormControl(''),
    });
/*
    this.equipmentForm.controls.model.disable();
    this.equipmentForm.controls.serialNumber.disable();
    this.equipmentForm.controls.askedService.disable();
*/
    this.buildParams(this.wksEntityParams.equipments);
    this.fillForm();

  }
  buildParams(equipParams: EquipmentParam[]): void {

    this.equipmentParam = [];
    this.equipmentBrand = [];
    for (const equipCur of equipParams) {
      const typeCur: LabelValue = {
        label : equipCur.type,
        value: equipCur.type,
        otherValues: equipCur.brands
      };
      this.equipmentParam.push(typeCur);
    }
    if (this.equipmentParam.length === 1) {
      this.onChangeType(this.equipmentParam[0].value);
    }
  }
  buildListServices(): void {
    this.displayCountHours = false;
    this.servicesList = [];
    for (const equipCur of this.wksEntityParams.equipments) {
      if (equipCur.type === this.equipment.type)  {
        for (const serviceCur of equipCur.services ) {
          const prestationCur: LabelValue = {
            label : this.translate.instant(serviceCur),
            value: serviceCur,
            otherValues: [],
          };
          this.servicesList.push(prestationCur);
        }
        break;
      }
    }
    if (this.servicesList.length === 1 ) {
      if (this.servicesList[0].value === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
    }
  }
  onChangeService($event: any): void {
    this.equipment.askedService = $event;
    if (this.equipment.askedService === 'REGULAR_SERVICE') {
      // this.displayCountHours = true;
    }
  }
  onSubmit(): void {
    this.fillModel();
    
  }

  
  onChangeType($event: any): void {
    // console.log($event);
    this.equipmentBrand = [];
    this.equipment.type = $event;
    for (const equipCur of this.equipmentParam) {
      if (equipCur.value === $event)  {
        for (const brandCur of equipCur.otherValues ) {
          this.equipmentBrand.push(brandCur);
        }
        break;
      }
    }

  }
  onFocus(field: string, value: any): void {
    if (value !== '') {
      if (field === 'brand') {
        this.onChangeBrand(value);
      }
    }
  }
  onChangeBrand($event: any): void {

    for (const brandCur of this.equipmentBrand) {
      if (brandCur.label === $event)  {
        this.serialNumberParam = brandCur.serialNumber;
        this.modelParam = brandCur.model;
        this.serialNumberInput.fmt = brandCur.serialNumber.fmt;
        this.serialNumberInput.holder = brandCur.serialNumber.holder;
        this.serialNumberInput.display = brandCur.serialNumber.display;
        this.serialNumberInput.inputError = false;
        this.serialNumberInput.dataLengthMax = brandCur.serialNumber.holder.length + 2;
        this.serialNumberInput.separator = brandCur.serialNumber.separator;
        const fmtLocal: FormFieldFmt = {
          pattern: brandCur.serialNumber.fmt,
          displayFmt: brandCur.serialNumber.display,
          displayUnfmt: '[ -]',
          fieldType: 'text',
          lenght: brandCur.serialNumber.lenght,
          separator: brandCur.serialNumber.separator,
          upperCase: brandCur.serialNumber.upperCase
        };
        this.formField = JSON.stringify(fmtLocal);

        // this.equipmentForm.controls.model.enable();
        break;
      }
    }
  }
  onChangeModel(value: string): void {
    if (value !== '') {
      // this.equipmentForm.controls.serialNumber.enable();
      // this.equipmentForm.controls.askedService.enable();
      this.buildListServices();
    }
  }
 
  formatField(typeRaw: string, paramsCur: any, inputValue: any): string {
    let valueReturn: any;
    let pattern: any;
    let displayFmt: string;
    let matchesGroup: any;
    let testMatch: any;
    switch (typeRaw) {
      case 'serialNumber': {
        const patternUnFmt = new RegExp(paramsCur.separator, 'g');
        const valueUnFmt = inputValue.replace(patternUnFmt, '');
        pattern = new RegExp(paramsCur.fmt, 'i');
        displayFmt = paramsCur.display;
        testMatch = valueUnFmt.match(pattern);
        if (testMatch === null) {
          this.serialNumberInput.inputError = true;
          // matchesGroup = inputValue.replace(pattern, displayFmt);
        } else {
          this.serialNumberInput.inputError = false;
          matchesGroup = inputValue.replace(pattern, displayFmt);
        }
        valueReturn = matchesGroup;
        this.serialNumberInput.inputValue = valueReturn;
        this.equipment.serialNumber = valueReturn;
        for (const equipmentCur of this.appointment.equipments) {
          if (equipmentCur.equipmentNum === this.equipment.equipmentNum) {
            continue;
          }
          if (equipmentCur.serialNumber === this.equipment.serialNumber) {
            valueReturn = '';
            this.serialNumberInput.inputValue = '';
            this.equipment.serialNumber = '';
            // this.equipmentForm.controls.serialNumber.setValue('');
            // this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
            // tslint:disable-next-line:no-string-literal
            this.equipmentForm.controls['serialNumber'].patchValue(valueReturn, { emitEvent: true, onlySelf: false });
            break;
          }
        }
        break;
      }
      case 'nbHoursOfUse' : {

        if (isNaN( Number.parseInt(inputValue, 10))) {
          this.equipmentForm.controls.nbHoursOfUse.setValue('');
          this.equipment.nbHoursOfUse = 0;
          valueReturn = '';
        } else {
          valueReturn = Number.parseInt(inputValue, 10);
          this.equipmentForm.controls.nbHoursOfUse.setValue(valueReturn);
          this.equipment.nbHoursOfUse = valueReturn;
        }
        break;
      }
      default: {
        valueReturn = '';
        break;
      }
    }

    return valueReturn;
  }
  counter(field: string, increment: number, value: any ): void  {

    switch (field) {
      case 'askedServiceHours': {
        let valueCur = 0;
        if (value === '') {
          valueCur = 0;
        } else {
          valueCur = Number.parseInt(value, 10);
        }
        valueCur = valueCur + increment;
        if (valueCur < this.hours.min)  {
          valueCur = this.hours.min;
        }
        if (valueCur > this.hours.max)  {
          valueCur = this.hours.max;
        }
        this.equipmentForm.controls.askedServiceHours.setValue(valueCur);
        break;
      }
      default: {
        break;
      }
    }
  }
  fillForm(): void {

    this.buildListServices();
    if (this.equipment.equipmentNum > 1) {
      const equipFirst: EquipmentModel = this.appointment.equipments[0];
      this.equipment.type = equipFirst.type;
      this.equipment.brand = equipFirst.brand;
      this.equipment.model = equipFirst.model;
      this.equipment.askedService = equipFirst.askedService;
      this.equipment.askedServiceHours = equipFirst.askedServiceHours;
      if (this.equipment.askedService === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
      this.equipment.nbHoursOfUse = equipFirst.nbHoursOfUse;
      // this.equipmentForm.controls.serialNumber.enable();
    } else {
      this.equipment.type = this.wksEntityParams.equipments[0].type;
      this.equipment.brand = this.equipmentBrand[0].label;
      this. onChangeBrand( this.equipment.brand);
      // this.equipmentForm.controls.model.enable();
      this.equipment.askedService = this.servicesList[0].value;
      if (this.equipment.askedService === 'REGULAR_SERVICE') {
        // this.displayCountHours = true;
      }
    }
    this.serialNumberInput.inputValue =  this.equipment.serialNumber ? this.equipment.serialNumber : '';
    this.equipmentForm.setValue({
      type: this.equipment.type,
      brand: this.equipment.brand,
      model: this.equipment.model,
      serialNumber: this.serialNumberInput.inputValue,
      nbHoursOfUse: (this.equipment.nbHoursOfUse > 0 ? this.equipment.nbHoursOfUse : '') ,
      askedService: this.equipment.askedService,
      askedServiceHours: this.equipment.askedServiceHours,
    });
  }

  fillModel(): void {
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.type = this.equipmentForm.get('type')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.brand = this.equipmentForm.get('brand')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.model = this.equipmentForm.get('model')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    // this.equipment.serialNumber = this.equipmentForm.get('serialNumber')!.value;
     // tslint:disable-next-line:no-non-null-assertion
    this.equipment.nbHoursOfUse = this.equipmentForm.get('nbHoursOfUse')!.value;
      // tslint:disable-next-line:no-non-null-assertion
    this.equipment.askedService = this.equipmentForm.get('askedService')!.value;
      // tslint:disable-next-line:no-non-null-assertion
    this.equipment.askedServiceHours = this.equipmentForm.get('askedServiceHours')!.value;
    // this.equipment.equipmentNum = 1;
  }

}
