import { Component, OnInit, SimpleChanges, OnChanges, Input, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { GridTools } from '../../../../../common/tools/gridTools';

import { WksWorksTracking, WksAlarmMessage } from '../../../../models/wks-works.model';

@Component({
  selector: 'mdi-works-tracking',
  templateUrl: './works-tracking.component.html',
  styleUrls: ['./works-tracking.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksTrackingComponent implements OnInit, OnChanges {

  @Input() optionItem: any;
  @Input() trackingList: WksWorksTracking[];
  @Input() orig: any;

  private readonly onDestroy = new Subject<void>();
  selectedRow: number;
  selectedAlarm: number;
  wksAlarmMessages: WksAlarmMessage[];
  trackingCur: WksWorksTracking;
  alarmMessagesCur: WksAlarmMessage;
  displayTracking: boolean;
  displayAlarm: boolean;

  constructor(private _userService: UserService,
    private _translate: TranslateService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'trackingList': {
            this.trackingList = changes['trackingList'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'orig': {
            this.optionItem = changes['orig'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }  
    this.displayTracking = false;
    this.displayAlarm = false;
  }
  getDateTracking(_value: string) {
    return GridTools.date_json(_value, this._translate.getLocalFmt('timeFormat'));
  }
  setClickedRow(row: number)  {
    this.displayTracking = false;
    this.displayAlarm = false;
    this.selectedAlarm = -1;
    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.wksAlarmMessages = undefined;
      this.trackingCur = undefined;
      this.alarmMessagesCur = undefined;
    } else {
      this.selectedRow = row;
      this.wksAlarmMessages = this.trackingList[row].alarmsMes;
      this.displayTracking = true;
      this.trackingCur = this.trackingList[row];
    }
  }
  setClickedAlarm(alarmRow: number)  {
    
    this.displayTracking = false;
    this.displayAlarm = false;

    if ( this.selectedAlarm === alarmRow) {
      this.selectedAlarm = -1;
      this.alarmMessagesCur = undefined;
    } else {
      this.selectedAlarm = alarmRow;
      this.alarmMessagesCur = this.wksAlarmMessages[alarmRow];
      this.displayAlarm = true;
    }
  }
}
