<div *ngIf="OKtoDisplayAccoutingRows" class="flex-row row stdTheme" style="margin: 0px;margin-bottom: 20px;">
    <mat-card class="stdTheme w-100"  style="padding: 0px">
        <mat-card-title style="text-align: center;" >
            <fieldset [ngClass]="{'balance-border-green':accountingPosition === 'green' ,
                                'balance-border-red':accountingPosition === 'red',
                                'balance-border-amber':accountingPosition === 'amber'}">
                <div class="flex-row row rowBalance" >
                    <div class="col-6 d-flex justify-content-start">
                        <h6 [ngClass]="{'greenTheme':accountingPosition === 'green' ,
                                'redTheme':accountingPosition === 'red',
                                'amberTheme':accountingPosition === 'amber'}">
                            {{'customer outstanding' | translate }}
                        </h6>
                    </div>
                    <div class="col-3 d-flex justify-content-end">
                        <h6 [ngClass]="{'greenTheme':accountingPosition === 'green' ,
                                'redTheme':accountingPosition === 'red',
                                'amberTheme':accountingPosition === 'amber'}">
                            <span>{{'authorized' | translate }} : </span>
                            <span style="margin-left: 10px">{{accountingBalance.customerOutstanding  | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}} </span>
                        </h6>
                    </div>
                    <div class="col-3 d-flex justify-content-end">
                        <h6 [ngClass]="{'greenTheme':accountingPosition === 'green' ,
                                'redTheme':accountingPosition === 'red',
                                'amberTheme':accountingPosition === 'amber'}">
                            <span>{{'outstanding committed' | translate }} : </span>
                            <span style="margin-left: 10px">{{accountingBalance.balance  | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</span>
                        </h6>
                    </div>
                </div>
            </fieldset>
        </mat-card-title>
        <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;">
            <div class="stdTheme" *ngFor="let accountingWork of accountingBalance.wksAccountingWorks; let idxItem = index"  >
                <div class="flex-row row rowAccounting" >
                    <div *ngIf="statutCall === 'bsn'" class="col-9 d-flex justify-content-start">
                        <h6 style="padding-top: 10px;">{{accountingWork.workRef}} - {{accountingWork.workObject}}</h6>
                    </div>
                    <div *ngIf="statutCall === 'work'" class="col-1 d-flex justify-content-start">
                        <button  mat-icon-button (click)="addRow(idxItem)" [disabled]="isReadOnly" title="{{'adding row' | translate }}">
                            <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div *ngIf="statutCall === 'work'" class="col-8 d-flex justify-content-center">
                        <h6>{{'workPosition'| translate}}</h6>
                    </div>

                    <!--<div class="col-3 d-flex justify-content-end"></div>-->
                    <div class="col-3 d-flex justify-content-end">
                        <span>{{'balance' | translate }} : </span>
                        <span style="margin-left: 10px">{{accountingWork.workBalance  | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</span>
                    </div>
                </div>
                <!--<div class="lineWks" style="margin-top: 5px;"></div>-->
                <div class="flex-row row rowTitle" >
                    <div class="col-2 d-flex justify-content-start">{{'accOperation'| translate}}</div>
                    <div class="col-4 d-flex justify-content-start">{{'accLabel'| translate}}</div>
                    <div class="col-2 d-flex justify-content-start">{{'accStatut'| translate}}</div>
                    <div class="col-2 d-flex justify-content-end" [ngStyle]="{'text-align': numberFormat.justifCurrency}">{{'accAmount'| translate}}</div>
                    <div class="col-2"></div>
                </div>
                <!--<div class="lineWks" style="margin-top: 5px;"></div>-->
                <div class="stdTheme" *ngFor="let accountingRow of accountingWork.wksAccountings; let idxRow = index"  >
                    <div class="flex-row row rowlist" *ngIf="(!rowStatut[idxRow].newRow && !rowStatut[idxRow].generated && !rowStatut[idxRow].editOpen)
                                                            || (rowStatut[idxRow].generated)" >
                        <div class="col-2 d-flex justify-content-start">{{accountingRow.accOperation  | translate}}</div>
                        <div class="col-4 d-flex justify-content-start">{{accountingRow.accLabel}}</div>
                        <div class="col-2 d-flex justify-content-start">
                            <span *ngIf="!rowStatut[idxRow].editOpen">{{accountingRow.accStatut | translate}}</span>
                            <span *ngIf="rowStatut[idxRow].editOpen 
                                        && rowStatut[idxRow].generated">
                                <select class="selectBox inputTheme" #accStatut
                                (change)="onStatutSelected(idxItem, idxRow, accStatut.value)">
                                    <option *ngFor="let itemOption of statutsList" 
                                            [value]="itemOption.value"
                                            [selected]="itemOption.value === accountingRow.accStatut"
                                            >{{ itemOption.label }}</option>
                                </select>
                            </span>
                        </div>
                        <div class="col-2 d-flex justify-content-end">{{accountingRow.accAmount  | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}</div>
                        <div class="col-2 d-flex justify-content-end" *ngIf="statutCall === 'work'">
                            <span>
                                <button mat-icon-button (click)="editRow(idxItem, idxRow)" title="{{'editing row' | translate }}"
                                        [disabled]="isReadOnly || !rowStatut[idxRow].editDisplay">
                                    <span class="fa fa-edit" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="saveRow(idxItem, idxRow)" title="{{'submit row' | translate }}"
                                        [disabled]="isReadOnly || !rowStatut[idxRow].submitDisplay">
                                    <span class="fa fa-paper-plane" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="removeRow(idxItem, idxRow)" title="{{'delete row' | translate }}"
                                        [disabled]="isReadOnly || !rowStatut[idxRow].trashDisplay">
                                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="toggleRow(idxRow)" [disabled]="!rowStatut[idxRow].detailDisplay"
                                        title="{{'detail row' | translate }}">
                                    <span *ngIf="rowStatut[idxRow].detailCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                    <span *ngIf="!rowStatut[idxRow].detailCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                </button> 
                            </span>
                        </div>
                    </div>
                    <!-- row generated : detail display colapse false-->
                    <div class="flex-row row rowpaid" 
                            *ngIf="rowStatut[idxRow].generated 
                                    && accountingRow.accToTransfer
                                    && !rowStatut[idxRow].detailCollapse
                                    && !rowStatut[idxRow].newRow" >
                        <div class="col-3 d-flex justify-content-start" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'accTransferAccount' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <span>{{ accountingRow.accTransferAccount}}</span>
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-start" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'accTransferEntry' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <span>{{ accountingRow.accTransferEntry}}</span>
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-end" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'accTransferDate' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <span>{{getDateTimeFmt(accountingRow.accTransferDate)}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- if advance row has been generated ans statut change-->
                    <div class="flex-row row rowpaid" 
                        *ngIf="rowStatut[idxRow].editOpen 
                                && accountingRow.accStatut === 'paid' 
                                && rowStatut[idxRow].generated 
                                && !rowStatut[idxRow].detailCollapse
                                && !rowStatut[idxRow].newRow" >
                        <div class="col-4 d-flex justify-content-start" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'paymentRef' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <input class="form-control inputTheme" name="paymentRef" #paymentRef  [disabled]="isReadOnly"
                                    value="{{rowInput[idxRow].paymentRef}}"
                                    matInput  (change)="onRowInputChange('paymentRef',paymentRef.value, idxItem, idxRow)"
                                /> 
                            </div>
                        </div>
                    </div>
                    <div class="flex-row row rowpaid" 
                            *ngIf="!rowStatut[idxRow].editOpen 
                                    && !rowStatut[idxRow].detailCollapse
                                    && accountingRow.accStatut === 'paid' 
                                    && rowStatut[idxRow].generated
                                    && !rowStatut[idxRow].newRow">
                        <div class="col-4 d-flex justify-content-start" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'paymentRef' | translate }}</span>
                            </div>
                            <div  style="width: 70%" >
                                <input class="form-control inputTheme" name="paymentRef" #paymentRef  [disabled]="isReadOnly || !rowStatut[idxRow].editOpen"
                                    value="{{rowInput[idxRow].paymentRef}}"
                                    matInput 
                                /> 
                            </div>
                        </div>
                    </div>
                    <!-- new row -->
                    <div class="flex-row row newrow" 
                                *ngIf="(rowStatut[idxRow].editOpen  && rowStatut[idxRow].newRow)
                                        || (rowStatut[idxRow].editOpen  && !rowStatut[idxRow].generated && !rowStatut[idxRow].newRow)">
                        <div class="col-2 d-flex justify-content-start">
                            <select class="selectBox inputTheme" #accOperation
                                (change)="onRowInputChange('accOperation',accOperation.value, idxItem, idxRow)">
                                <option *ngFor="let itemOption of operationsList" 
                                        [value]="itemOption.value"
                                        [selected]="itemOption.value === accountingRow.accOperation"
                                        >{{ itemOption.label }}</option>
                            </select>
                        </div>
                        <div class="col-4 d-flex justify-content-start">
                            <input class="form-control inputTheme" name="accLabel" #accLabel
                                [value]="accountingRow.accLabel"
                                matInput  (change)="onRowInputChange('accLabel',accLabel.value, idxItem, idxRow)"
                            /> 
                        </div>
                        <div class="col-2 d-flex justify-content-start">
                            <!--
                            <select class="selectBox inputTheme" #accStatut
                                (change)="onRowInputChange('accStatut',accStatut.value, idxItem, idxRow)">
                                <option *ngFor="let itemOption of statutsList" 
                                        [value]="itemOption.value"
                                        [selected]="itemOption.value === accountingRow.accStatut"
                                        >{{ itemOption.label }}</option>
                            </select>
                            -->
                        </div>
                        <div class="col-2 d-flex justify-content-end"> 
                            <input class="form-control inputTheme" name="accAmount" #accAmount 
                                        [ngStyle]="{'text-align': numberFormat.justifCurrency}"
                                value="{{accountingRow.accAmount | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                (keypress)="numberAndDotOnly($event)"
                                matInput (change)="onRowInputChange('accAmount',accAmount.value, idxItem, idxRow)"
                            /> 
                        </div>
                        <div class="col-2 d-flex justify-content-end" *ngIf="statutCall === 'work'">
                            <span>
                                <button mat-icon-button (click)="editRow(idxItem, idxRow)" title="{{'editing row' | translate }}"
                                    [disabled]="isReadOnly || !rowStatut[idxRow].editDisplay || rowStatut[idxRow].newRow">
                                    <span class="fa fa-edit" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="saveRow(idxItem, idxRow)" title="{{'submit row' | translate }}"
                                        [disabled]="isReadOnly || !rowStatut[idxRow].submitDisplay">
                                    <span class="fa fa-paper-plane" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="removeRow(idxItem, idxRow)" title="{{'delete row' | translate }}"
                                        [disabled]="isReadOnly || !rowStatut[idxRow].trashDisplay">
                                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span>
                                <button mat-icon-button (click)="toggleRow(idxRow)" [disabled]="!rowStatut[idxRow].detailDisplay"
                                        title="{{'detail row' | translate }}" >
                                    <span *ngIf="rowStatut[idxRow].detailCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                    <span *ngIf="!rowStatut[idxRow].detailCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                </button> 
                            </span>
                        </div>    
                    </div>
                    <div class="flex-row row rowpaid" *ngIf="(rowStatut[idxRow].editOpen 
                                                && rowStatut[idxRow].newRow 
                                                && accountingRow.accOperation === 'payment')
                                                || (rowStatut[idxRow].editOpen  && !rowStatut[idxRow].generated && !rowStatut[idxRow].newRow)">
                        <div class="col-4 d-flex justify-content-end" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'paymentRef' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <input class="form-control inputTheme" name="paymentRef" #paymentRef  [disabled]="isReadOnly"
                                    value="{{rowInput[idxRow].paymentRef}}"
                                    matInput  (change)="onRowInputChange('paymentRef1',paymentRef.value, idxItem, idxRow)"
                                /> 
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-end" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'accNwsref' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <input class="form-control inputTheme" name="accNwsref" #accNwsref  [disabled]="isReadOnly"
                                    value="{{accountingRow.accNwsref}}"
                                    matInput  (change)="onRowInputChange('accNwsref',accNwsref.value, idxItem, idxRow)"
                                /> 
                            </div>
                        </div>
                    </div>
                    <!-- row not generated - not collapse - not edit - paid -->
                    <div class="flex-row row rowpaid" *ngIf="!rowStatut[idxRow].editOpen 
                                            && !rowStatut[idxRow].detailCollapse
                                            && !rowStatut[idxRow].generated
                                            && !rowStatut[idxRow].newRow
                                            && accountingRow.accOperation === 'payment'">
                        <div class="col-4 d-flex justify-content-end" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'paymentRef' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <span>{{rowInput[idxRow].paymentRef}}</span>
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-end" style="padding-left: 15px;padding-right: 0px;">
                            <div  style="width: 30%">
                                <span>{{ 'accNwsref' | translate }}</span>
                            </div>
                            <div  style="width: 70%">
                                <span>{{accountingRow.accNwsref}}</span>
                            </div>
                        </div>                                            
                    </div>
                    <div class="lineWks" style="margin-top: 5px;"></div>
                </div>
                <div *ngIf="statutCall === 'bsn'" class="lineDoubleWks" style="margin-top: 5px;"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
