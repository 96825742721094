<div class="container-fluid " >
    <div *ngIf="originCall === 'bsnTable'"class="d-flex justify-content-between" style="margin-top: 10px" >
       
        <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'goback' | translate }}
        </button>
        <button mat-raised-button *ngIf="okToRegister" #buttonSubmit type="submit" (click)="register()" >
            <mat-icon>save</mat-icon>
            {{ 'save' | translate }}
        </button>
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" class="mat-tab-fill-height" (selectedTabChange)="selectedTabChange($event)" >
            <mat-tab label="{{ 'BsnCard' | translate }}">
                <ng-template matTabContent>
                    <mdi-bns-card [statutCall]="statutCall" 
                            [ihmOptions]="ihmOptions"
                            [wksBnsToUpdate]="wksBnsToUpdate"
                            [optionItem]="optionItem" 
                            (dataOut)="dataOutBsnCard($event)"
                            (onload)="ngOnInit()"></mdi-bns-card>
                </ng-template>
            </mat-tab>
            <!--
            <mat-tab *ngIf="doInputAddress" label="{{ 'BsnAddresses' | translate }}" >
                <ng-template matTabContent >
                    <mat-card class="wkscard stdTheme" layout="column" flex style="padding-top: 5px; padding-bottom: 0px;">
                        <mat-card-content class="wkscard" style="margin-right: 10px; margin-left: 10px;">
                            <mdi-address-block [config]="configAddress" 
                                [dataIn]="addressCur" (dataOut)="dataOutAddress($event)"></mdi-address-block>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </mat-tab>
            -->
            <div >
                <mat-tab  label="{{ 'BsnContacts' | translate }}" [disabled]="!nameParent" >
                    <ng-template matTabContent >
                        <mdi-contacts-card 
                            [ihmOptions]="ihmOptions" 
                            [config]="configAddress" 
                            [optionItem]="optionItem" 
                            [nameParent]="nameParent"
                            [dataInput]="dataContacts" 
                            (dataOut)="dataOutContacts($event)">
                        </mdi-contacts-card>
                    </ng-template>
                </mat-tab>
            </div>

            <div *ngIf="statutCall === 'updateBsn' || statutCall === 'readBsn'">
                <mat-tab  label="{{ 'Documents' | translate }}" >
                    <ng-template matTabContent >
                        <mat-tab-group>
                            <mat-tab label="{{ 'administrative' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:administrativeList, type: 'administrative'}"></ng-container> 
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="{{ 'contracts' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:contractsList, type: 'contracts'}"></ng-container> 
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="{{ 'guarantees' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:guaranteesList, type: 'guarantees'}"></ng-container> 
                                </ng-template>
                            </mat-tab>
                            <!--
                            <mat-tab label="{{ 'quotations' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:quotationsList, type: 'quotations'}"></ng-container>           
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="{{ 'invoices' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:invoicesList, type: 'invoices'}"></ng-container> 
                                </ng-template>
                            </mat-tab>
                            -->
                            <mat-tab label="{{ 'other docs' | translate }}" >
                                <ng-template matTabContent >
                                    <ng-container [ngTemplateOutlet]="tableDocs" [ngTemplateOutletContext]="{list:otherDocsList, type: 'otherDocs'}"></ng-container>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </ng-template>
                </mat-tab>
            </div>
            <div *ngIf="statutCall === 'updateBsn' || statutCall === 'readBsn'">
                <mat-tab  label="{{ 'balance' | translate }}" >
                    <mdi-works-accounting 
                        [statutCall]="'bsn'" 
                        [accountingBalance]="accountingBalance"
                        [bsnCur]="wksBnsToUpdate"
                        [ihmOptions]="ihmOptions">
                    </mdi-works-accounting>
                </mat-tab>
            </div>
        </mat-tab-group>
    </div>
</div>
<ng-template #tableDocs let-list='list' let-type='type'>
    <table class="table listcol" id="otherDocs" class="listCol stdTheme" >
        <thead >
            <th style="width: 5%" > </th>
            <th style="width: 50%">{{'docName' | translate}}</th>
            <th style="width: 20%">{{'docFilename' | translate}}</th>
            <th style="width: 20%">{{'stdCreation' | translate}}</th>
            <th style="width: 5%" >
                <button mat-icon-button (click)="addDoc(type)">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </th>
        </thead>
        <tbody style="overflow-y: auto;  height: 50px;">
            <tr class="curCol curColHidden" *ngFor="let docCur of list; let i = index" >
                <th style="width: 5%" >
                    <button mat-icon-button (click)="deleteDoc(docCur)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </th>
                <td style="width: 50%; text-align: left;">{{docCur.docName }}</td>
                <td style="width: 20%; text-align: left;">{{docCur.docFilename }}</td>
                <td style="width: 20%; text-align: left;">{{getDateCreation(docCur.stdCreation)}}</td>
                <td style="width: 5%" >                                                    
                    <button mat-icon-button (click)="openDoc(docCur)">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>  
</ng-template>
<ng-template #pdfModalViewer style="width: 100%">
    <div class="modal-body">
      <mdi-doc-pdf [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-pdf>
    </div>
    <div class="modal-footer ">
      <!--<p>{{modalText}}</p>-->
      <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
      </div>
    </div>
</ng-template>
<ng-template #imgModalViewer style="width: 100%">
    <div class="modal-body">
      <mdi-doc-image [titleReport]="modalText[0]" [statutCall]="'one-pdf'" [dataList]="dataList"  (onload)="ngOnInit()"></mdi-doc-image>
    </div>
    <div class="modal-footer ">
      <!--<p>{{modalText}}</p>-->
      <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
        <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
      </div>
    </div>
</ng-template>
<ng-template #docModal style="width: 100%">
    <div class="modal-body">
        <mdi-doc-card 
            [statutCall]="'modalBsn'" 
            [optionItem]="'docs'" 
            [actionType]="'add'" 
            [docToUpdate]="pseudoDoc">
        </mdi-doc-card>
    </div>
   
</ng-template>