<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid " style="margin-top: 75px; width: 100%;">
  <div class="spinner-container stdTheme" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <div class="flex-row row stdTheme " style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <button *ngIf="sidenavCollapsed" mat-icon-button class="buttonTheme" matTooltip="{{ 'columns selection' | translate }}" (click)="toggleSidenav('choiceCol')">
        <mat-icon style="font-size: 18px;">view_column</mat-icon>
      </button>
     <!-- <span style="margin-left: 45%; font-size:large; font-weight: bold">{{ 'Entities' | translate }}</span>-->
      <span *ngIf="optionItem === 'admin'" style="margin-left: 45%; font-size:large">{{ 'Entities' | translate }}</span>
      <span *ngIf="optionItem === 'menu'" style="margin-left: 45%; font-size:large">{{ 'EntitiesMenu' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100 " style="margin-top: 125px;">
    <mat-sidenav-container fxFlexFill class="container-sidenav w-100 stdTheme">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" class="lateral-sidenav-50-max stdTheme" 
            style="padding-left: 20px;padding-right: 10px;">
        <div fxLayout="column" *ngIf="isChoiceCol">
          <a (click)="toggleSidenav('')" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Apply' | translate}}</a>
          <a>
            <ng-template ngFor let-dataColumn [ngForOf]="dataColumns">
              <li>
                <!--
                <mat-checkbox [checked]="dataColumn.isSelected" (change)="chkbColumnChoice($event)"  style="font-size: 12px;line-height: 10px;"
                  id="{{dataColumn.value}}">
                  {{dataColumn.label}}
                </mat-checkbox>
                -->
                <div class="checkbox">
                  <label class="labelLine" >
                    <input type="checkbox" id="{{dataColumn.value}}"  class="inputTheme" style="font-size: 12px;line-height: 10px;"
                      [checked]="dataColumn.isSelected" 
                      (change)="chkbColumnChoice($event)" >
                    {{dataColumn.label}}
                  </label>
                </div>
              </li>
            </ng-template>
          </a>
        </div>
        <div fxLayout="column" *ngIf="isRegisterEntity" class="stdTheme">
          <a (click)="toggleSidenav('')" *ngIf="doCreateEntity" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}</a>
          <a (click)="toggleSidenav('')" *ngIf="!doCreateEntity"mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}</a>
          <a >
            <mdi-entity-card [statutCall]="statutRegister" [entityToUpdate]="entityToUpdate" (onload)="ngOnInit()"></mdi-entity-card>
          </a>
        </div>
        <div fxLayout="column" *ngIf="isRegisterMenu" class="stdTheme">
          <a (click)="toggleSidenav('')"  mat-raised-button class="buttonTheme" style="font-size: 12px">{{'EntityMenu' | translate: [entityToUpdate.entitylabel]}}</a>
          <a>
            <mdi-entities-menu [statutCall]="statutRegister" [entityToUpdate]="entityToUpdate.entitylabel" (onload)="ngOnInit()"></mdi-entities-menu>
          </a>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">
        <div *ngIf="openFilters" class="mat-elevation-z8 container-fluid" [@fadeInOut]="stateFilters">
          <mdi-dynamic-form [fields]="fields" [currentFilters]="currentFilters"></mdi-dynamic-form>
        </div>
        <div class="container-fluid ">
          <ngx-datatable [rows]="dataSource.data" class="material striped" [loadingIndicator]="loadingIndicator" [columnMode]="'force'"
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [reorderable]="reorderable" [limit]="pageSize" [sortable]="true"
            [draggable]="true" [resizeable]="true" [canAutoResize]="true" [scrollbarH]="true" [selected]="selected" [selectionType]="'single'"
            (select)='onSelect($event)'>
            <ngx-datatable-column *ngFor="let col of displayedColumns" [name]="col.name" [prop]="col.prop">
              <ng-template *ngIf="col.isDate" let-value="value" ngx-datatable-cell-template>
                {{ value | date : dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions">
              <ng-template  let column="column" ngx-datatable-header-template>
                <button *ngIf="optionItem === 'admin'" mat-icon-button class="buttonTheme" 
                    (click)="addEntity()" style="margin-left: 10px;margin-right: 10px;">
                  <!--<mat-icon aria-label="Add">person_add</mat-icon>-->
                  <span class="fa fa-user-plus" style="font-size: 20px" ></span>
                </button>
              </ng-template>
              <ng-template  let-row="row" ; let-value="value" ngx-datatable-cell-template >
                <button mat-icon-button class="buttonTheme" (click)="editEntity(row)" 
                      *ngIf="optionItem === 'admin' && doAction(row)" style="margin-left: 10px;margin-right: 10px;">
                 <!--<mat-icon style="font-size: 17px; vertical-align: middle; padding: 0px" aria-label="Edit">edit</mat-icon>-->
                  <span class="fa fa-edit" style="font-size: 20px"></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="deleteEntity(row)" 
                      *ngIf="optionItem === 'admin' && doAction(row)" style="margin-left: 10px;margin-right: 10px;">
                  <!--<mat-icon style="font-size: 20px" aria-label="Delete">delete</mat-icon>-->
                  <span class="fa fa-trash" style="font-size: 20px"></span>
                </button>
                <button mat-icon-button class="buttonTheme"  
                      *ngIf="optionItem === 'admin' && !doAction(row)" style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-ban" style="font-size: 20px"></span>
                  <!-- <mat-icon style="font-size: 17px" aria-label="Master" >block</mat-icon> -->
                </button>
                <button mat-icon-button class="buttonTheme" (click)="editMenu(row)" 
                      *ngIf="optionItem === 'menu' && doAction(row)" style="margin-left: 10px;margin-right: 10px;">
                    <!--<mat-icon style="font-size: 17px; vertical-align: middle; padding: 0px" aria-label="Edit">edit</mat-icon>-->
                      <span class="fa fa-edit" style="font-size: 20px"></span>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
