/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-metadata.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./equipment-metadata.component";
import * as i4 from "./equipement-metadata";
import * as i5 from "../../../../services/wks-central.service";
var styles_EquipmentMetadataComponent = [i0.styles];
var RenderType_EquipmentMetadataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentMetadataComponent, data: {} });
export { RenderType_EquipmentMetadataComponent as RenderType_EquipmentMetadataComponent };
function View_EquipmentMetadataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataIn.ownerName; var currVal_1 = _co.dataIn.ownerId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_EquipmentMetadataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataIn.equipName; var currVal_1 = _co.dataIn.equipId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_EquipmentMetadataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataIn.equipDesign; var currVal_1 = _co.dataIn.equipId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_EquipmentMetadataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataIn.equipDesign; var currVal_1 = _co.dataIn.equipId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_EquipmentMetadataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "mat-elevation-z8 rcorners1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentMetadataComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentMetadataComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentMetadataComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentMetadataComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataIn.ownerName !== ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.dataIn.equipName !== _co.dataIn.equipDesign) && (_co.dataIn.equipName !== undefined)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.dataIn.equipDesign !== _co.dataIn.equipName) && (_co.dataIn.equipDesign !== undefined)) && (_co.dataIn.equipDesign !== null)); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((((_co.dataIn.equipDesign === _co.dataIn.equipName) && (_co.dataIn.equipDesign !== null)) && (_co.dataIn.equipDesign !== undefined)) && (_co.dataIn.equipName !== undefined)); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_EquipmentMetadataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-equipment-metadata", [], null, null, null, View_EquipmentMetadataComponent_0, RenderType_EquipmentMetadataComponent)), i1.ɵdid(1, 638976, null, 0, i3.EquipmentMetadataComponent, [i4.EquipmentMetadata, i5.WksCentralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentMetadataComponentNgFactory = i1.ɵccf("mdi-equipment-metadata", i3.EquipmentMetadataComponent, View_EquipmentMetadataComponent_Host_0, { dataIn: "dataIn" }, {}, []);
export { EquipmentMetadataComponentNgFactory as EquipmentMetadataComponentNgFactory };
