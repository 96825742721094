import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/translate.service';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RestrictionsModel, Widgets, RequestInt } from '../../../../models/job-data.model';
import { CommonMethods } from '../../../../../common/tools/commonMethods';

@Component({
  selector: 'mdi-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css']
})
export class ReportDashboardComponent implements OnInit, OnDestroy {

  @Input() reportLabelCur: any;
  private readonly onDestroy = new Subject<void>();

  doValidation: boolean; 
  isLoaded: boolean;
  listWidgets: Widgets;
  requestsList: any[];
  chartTypeList = ['', 'bar', 'doughnut', 'line', 'pie', 'polarArea', 'radar'];
  constructor(private _translate: TranslateService, private _jobDataService: JobDataService) { }

  ngOnInit() {
    this.isLoaded = false;
    this.initData();
   
    this._jobDataService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
     
      if (valueEvent === 'widgetActive') {
        this.initData();
      }
      if (valueEvent === 'validOK') {
        this.doValidation = true;
      }
      if (valueEvent === 'validKO') {
        this.doValidation = false;
      }

    }) ;
  }
  ngOnDestroy() {
    this.onDestroy.next();

  }
  initData() {

    this.listWidgets = undefined;
    this.getListRequests();
    if (this._jobDataService.getColReportService().widgets) {
      this.listWidgets = this._jobDataService.getColReportService().widgets;
    } else {
      this.listWidgets = {
        dashboardCols: 20,
        dashboardRows: 25,
        widgets: []
      };
      const widget = {
          widgetName: '',
          widgetUpdate: 0,
          chartType: '',
          updateFrequency: 0,
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          defaultDisplay: false,
          requestRef: '',
          requestRestrictions:  [],
        };

      this.listWidgets.widgets.push(widget);
    }
    this.isLoaded = true;
  }
  getListRequests() {
    this.requestsList = [];
    this._jobDataService.getListRequests('onlyDefault')
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.initSelectRequest(data.body);
      }
    );
  }
  initSelectRequest(dataRequestList: RequestInt[]) {
    this.requestsList = [];
    let reqCur = {
      requestLabel: '',
      requestRef: '',
      requestRestrictions: undefined,
    };
    this.requestsList.push(reqCur);

    for (const requestCur of dataRequestList) {
      reqCur = {
        requestLabel: requestCur.requestLabel,
        requestRef: requestCur.requestRef,
        requestRestrictions: requestCur.requestRestrictions,
      };
      this.requestsList.push(reqCur);
    }
    
  }
  setRequestSelect(_labelRequestSelected: any, _iRow: number) {
    console.log(_labelRequestSelected);

    for (const requestCur of this.requestsList) {
      if (requestCur.requestLabel === _labelRequestSelected) {
        this.listWidgets.widgets[_iRow].requestRef = requestCur.requestRef;
        this.initValuesList(requestCur, _iRow);
        break;
      }
    }

  }
  initValuesList(_requestSelected: any, _iRow: number) {
    this.listWidgets.widgets[_iRow].requestRestrictions = [];
    
    // if (arrayGrp.length === 1 ) arrayGrp.removeAt(0);
    if (_requestSelected.requestRestrictions) {
      const restrictList = _requestSelected.requestRestrictions.split(';');
      
      for (const restrictRow of restrictList ) {
        const restrict = restrictRow.split('|');
        const restrictUnit = new RestrictionsModel();
        restrictUnit.columnName = restrict[0];
        restrictUnit.operande = restrict[1];
        restrictUnit.restrictValue = restrict[2];
        if (restrict[2] !== 'VALUE') {
          continue;
        }
        const grpRestrict = {
          columnName: restrict[0],
          operande: restrict[1],
          restrictValue : '',
        };
        this.listWidgets.widgets[_iRow].requestRestrictions.push(grpRestrict);
      }
    }
    this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
  }
  setChartTypeSelect(_chartTypeSelected: string, _iRow: number) {
    console.log(_chartTypeSelected);
    this.listWidgets.widgets[_iRow].chartType = _chartTypeSelected;
    this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );

  }
  counter(_item: string, _idx: number, _pos?: number) {

    if (_item === 'dashboardCols') {
      if (!this.listWidgets.dashboardCols) {
        this.listWidgets.dashboardCols = 0;
      }
      this.listWidgets.dashboardCols += _idx;
      if (this.listWidgets.dashboardCols < 1) { this.listWidgets.dashboardCols = 1; }
      if (this.listWidgets.dashboardCols > 30) { this.listWidgets.dashboardCols = 30; }  
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );  
    }
    if (_item === 'dashboardRows') {
      if (!this.listWidgets.dashboardRows) {
        this.listWidgets.dashboardRows = 0;
      }
      this.listWidgets.dashboardRows += _idx;
      if (this.listWidgets.dashboardRows < 1) { this.listWidgets.dashboardRows = 1; }
      if (this.listWidgets.dashboardRows > 25) { this.listWidgets.dashboardRows = 25; }
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );   
    }
    if (_item === 'heightWidget') {
      if (!this.listWidgets.widgets[_pos].height) {
        this.listWidgets.widgets[_pos].height = 0;
      }
      this.listWidgets.widgets[_pos].height += _idx;
      if (this.listWidgets.widgets[_pos].height < 1) { this.listWidgets.widgets[_pos].height = 1; }
      if (this.listWidgets.widgets[_pos].height > this.listWidgets.dashboardRows) {
        this.listWidgets.widgets[_pos].height = this.listWidgets.dashboardRows;     
      }
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
    }
    if (_item === 'widthWidget') {
      if (!this.listWidgets.widgets[_pos].width) {
        this.listWidgets.widgets[_pos].width = 0;
      }
      this.listWidgets.widgets[_pos].width += _idx;
      if (this.listWidgets.widgets[_pos].width < 1) { this.listWidgets.widgets[_pos].width = 1; }
      if (this.listWidgets.widgets[_pos].width > this.listWidgets.dashboardCols) {
        this.listWidgets.widgets[_pos].width = this.listWidgets.dashboardCols;     
      }
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
    }
    if (_item === 'topWidget') {
      if (!this.listWidgets.widgets[_pos].top) {
        this.listWidgets.widgets[_pos].top = 0;
      }
      this.listWidgets.widgets[_pos].top += _idx;
      if (this.listWidgets.widgets[_pos].top < 1) { this.listWidgets.widgets[_pos].top = 1; }
      if (this.listWidgets.widgets[_pos].top > this.listWidgets.dashboardRows - this.listWidgets.widgets[_pos].height) {
        this.listWidgets.widgets[_pos].top = this.listWidgets.dashboardRows - this.listWidgets.widgets[_pos].height;     
      }
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
    }
    if (_item === 'leftWidget') {
      if (!this.listWidgets.widgets[_pos].left) {
        this.listWidgets.widgets[_pos].left = 0;
      }
      this.listWidgets.widgets[_pos].left += _idx;
      if (this.listWidgets.widgets[_pos].left < 1) { this.listWidgets.widgets[_pos].left = 1; }
      if (this.listWidgets.widgets[_pos].left > this.listWidgets.dashboardCols - this.listWidgets.widgets[_pos].width) {
        this.listWidgets.widgets[_pos].left = this.listWidgets.dashboardCols - this.listWidgets.widgets[_pos].width;     
      }
      this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
    }
  }
  addWidget() {
    
    const widget = {
      widgetName: '',
      widgetUpdate: 0,
      chartType: '',
      updateFrequency: 0,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      defaultDisplay: false,
      requestRef: '',
      requestRestrictions:  [],
    };
    this.listWidgets.widgets.push(widget);
    this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null );
  }
  removeWidget(i: number) {
    this.listWidgets.widgets.splice(i, 1);
    this._jobDataService.changeColReportService(this.listWidgets, null, null, null, false, null, null);
  }
  setValueArrayWidget(_dataField: string, _dataValue: any, _index: number) {
    this.doValidation  = true;
    if ( _dataField ===  'widgetName') { 
      if (_dataValue === '') {
        this.listWidgets.widgets[_index].widgetName  = '';
        this._jobDataService.changeColReportService(this.listWidgets, null , null, null, false, null, null);
        return;
      }
      this.listWidgets.widgets[_index].widgetName  = _dataValue;
      this._jobDataService.changeColReportService(this.listWidgets, null, null, null, false, null, null);
    }
    if ( _dataField ===  'defaultDisplay') { 
      
      this.listWidgets.widgets[_index].defaultDisplay  = _dataValue;
      this._jobDataService.changeColReportService(this.listWidgets, null, null, null, false, null, null);
    }

    if ( _dataField ===  'updateFrequency') { 
      
      this.listWidgets.widgets[_index].updateFrequency  = _dataValue;
      this._jobDataService.changeColReportService(this.listWidgets, null, null, null, false, null, null);
    }
    return _dataValue;
  }
  setRestrictionValue( _dataValue: any, _iRow: number, _iResctrict: number) {

    this.listWidgets.widgets[_iRow].requestRestrictions[_iResctrict].restrictValue = _dataValue;
    this._jobDataService.changeColReportService(this.listWidgets, null, null, null, false, null, null);
    return _dataValue;
  }
  register() {
    this._jobDataService.changeStatutEvent('doValidation');
  }
  doCancel() {
    this._jobDataService.changeStatutEvent('doCancel');
  }
}
