<!--
  https://material.angular.io/components/tabs/examples
  https://material.angular.io/components/tabs/overview
-->
<div class="container-fluid ">
  <span  class="stdTheme">
    <h5>{{reportLabelCur}}</h5>
  </span>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="{{ 'ReportCard' | translate }}">
      <ng-template matTabContent>
        <mdi-report-card [statutCall]="statutCall" [reportToUpdate]="reportToUpdate"  (onload)="ngOnInit()"></mdi-report-card>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'ReportOrganisation' | translate }}" [disabled]="!columnsActive ">
      <ng-template matTabContent style="height: 100% ">
        <mdi-report-columns [statutCall]="statutCall" (onload)="ngOnInit()" ></mdi-report-columns>
      </ng-template>
    </mat-tab>
 
    <mat-tab label="{{ 'ReportRowsPinned' | translate }}" [disabled]="!rowsPinnedActive">
      <ng-template matTabContent style="height: 100% ">
          <mdi-report-pinned-row [statutCall]="statutCall" (onload)="ngOnInit()" ></mdi-report-pinned-row>
      </ng-template>
    </mat-tab>
 
    <mat-tab label="{{ 'SubReport' | translate }}" [disabled]="!subReportActive">
      <ng-template matTabContent style="height: 100% ">
          <mdi-report-subreport [statutCall]="statutCall" [reportLabelCur]="reportLabelCur" (onload)="ngOnInit()" ></mdi-report-subreport>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'Dashboard' | translate }}" [disabled]="!dashboardActive">
      <ng-template matTabContent style="height: 100% ">
          <mdi-report-dashboard [statutCall]="statutCall" [reportLabelCur]="reportLabelCur" (onload)="ngOnInit()" ></mdi-report-dashboard>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<!--
<nav mat-tab-nav-bar [backgroundColor]="background">
  <a mat-tab-link *ngFor="let link of links"
     (click)="activeLink = link"
     [active]="activeLink == link"> {{link}} </a>
  <a mat-tab-link disabled>Disabled Link</a>
</nav>
-->