import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../services/translate.service';

import { WksEntityModel } from '../../../models/wks-entity.model';
import { ModalCommonComponent } from '../../../../../job/common/components/modal-common/modal-common.component';
import { WksCentralService } from '../../../services/wks-central.service';

@Component({
  selector: 'mdi-subsidiaries',
  templateUrl: './subsidiaries.component.html',
  styleUrls: ['./subsidiaries.component.css']
})
export class SubsidiariesComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() config: any;
  @Input() entityParent: WksEntityModel;
  @Input() optionItem: string;

  private readonly onDestroy = new Subject<void>();
  isLoaded: boolean;
  listSubsidiaries: WksEntityModel[];
  dataAddress: any[];
  listSubsidiariesCache: WksEntityModel[];
  listSubsidiariesInput: any[];
  configSubsidiary: any[];

  dataOrig: any;
 
  isDataIn: boolean;
  markChange: boolean;
  nRow: number;
  inputSubsidiary: boolean;

  constructor(private _dialog: MatDialog, 
      private _translate: TranslateService,
      private _wksCentralService: WksCentralService) { }

  ngOnInit() {
    this.initData();
    this.isLoaded = false;
    this.markChange = true;
    this.inputSubsidiary = false;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.initData();
    this.isLoaded = true;
  }
  ngAfterContentInit() {

  }
  initData() {
    this.listSubsidiaries = [];
    this.listSubsidiariesInput = [];
    this.listSubsidiariesCache = [];
    this.loadSubsidiaries()
    .then ((responseEntity: any) => {
      this.listSubsidiaries = responseEntity;
      if ((this.listSubsidiaries === undefined) || (this.listSubsidiaries === null) || (this.listSubsidiaries.length === 0)) {
        this.addSubsidiary();
      } else  {
        this.listSubsidiariesCache = [];
        this.dataAddress = [];
        this.configSubsidiary = [];
        
        this.listSubsidiaries.sort((obj1, obj2) => {
          return obj1.stdEntity > obj2.stdEntity ? 1 : -1;
        });
        for (const subsidiaryCur of this.listSubsidiaries) {
          subsidiaryCur.isCollapsed = true;
          this.dataInSubsidiaryCur(subsidiaryCur, -1);
          this.listSubsidiariesCache.push(Object.assign({}, subsidiaryCur));
        }
       
      }
      this.isLoaded = true;
    })
    .catch ((error: any) => {
     // console.log('loadSubsidiaries : ' + error);

      this.addSubsidiary();
      this.isLoaded = true;

      return;
    });
  }
  dataInSubsidiaryCur(subsidiaryCur: WksEntityModel, iRow: number) {

    let jsonAddress: any ;
    if ((subsidiaryCur.entityAddress !== undefined) 
          && (subsidiaryCur.entityAddress !== null) 
          && (subsidiaryCur.entityAddress !== '')) {
      try {
        jsonAddress = JSON.parse(subsidiaryCur.entityAddress);
      } catch (error) {
        jsonAddress = subsidiaryCur.entityAddress;
      }
    }
    if (this.dataAddress === undefined) {
      this.dataAddress = [];
      this.configSubsidiary = [];
    }
    const dataAddress = {
      corporateName: subsidiaryCur.stdEntity,
      address: jsonAddress,
      country: subsidiaryCur.entityCountry,
      addressPosition: subsidiaryCur.entityPosition,
    };
    if (iRow ===  -1) {
      this.dataAddress.push(dataAddress);
    } else {
      this.dataAddress[iRow] = dataAddress;
    }
    
   
    if (iRow ===  -1) {
      const configSubsidiaryLoc = Object.assign('', this.config);
      configSubsidiaryLoc.address = true;
      configSubsidiaryLoc.professionnal = true;
      configSubsidiaryLoc.telephone = false;
      configSubsidiaryLoc.contact = false;
      configSubsidiaryLoc.email = false;
      configSubsidiaryLoc.corporate = true;
      configSubsidiaryLoc.latLng = true;
      configSubsidiaryLoc.cpltEventName = 'subsidiary_' + (this.dataAddress.length - 1);
      this.configSubsidiary.push(configSubsidiaryLoc);
    }
   // return JSON.stringify(contactCur);
  }
  addSubsidiary() {
    if ((this.listSubsidiaries === undefined) || (this.listSubsidiaries === null) || (this.listSubsidiaries.length === 0)) {
      this.listSubsidiaries = [];
      this.listSubsidiariesCache = [];
    }
    const subsidiary = {
      stdEntity: '',
      entityCountry: this.entityParent.entityCountry,
      entityAddress: '',
      subsidiaryOf: this.entityParent.entityId,
      entityId: '',
      entityPosition: '',
    }; 
    this.listSubsidiaries.push(subsidiary);
    this.dataInSubsidiaryCur(subsidiary, -1);
    this.listSubsidiariesCache.push(Object.assign({}, subsidiary));
  }
  removeSubsidiary (iRow: number) {
    if (this.listSubsidiaries.length > 0) {
      if (this.listSubsidiaries[iRow].id !== undefined) {
        let titleBox: string;
        let messageBox: string;
        titleBox = 'deleting subsidiary';
        titleBox = this._translate.getTranslate(titleBox);
        messageBox = this.listSubsidiaries[iRow].stdEntity ;
    
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeCnt', iRow );
      }
    }
  }
  loadSubsidiaries()  {
    

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEntitySubsidiaries(this.entityParent.entityId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const responseEntity = data.body as WksEntityModel[];
          resolve(responseEntity);
        }, err => {
          console.log('loadSubsidiaries' + err.message);
          if (err.status === 404) {
            
          }
          reject(err.status);
        }
      );
    });
  }
  removeSubsidiaryOK (iRow: number) {
    if (this.listSubsidiaries.length > 0) {
      const entityCur = this.listSubsidiaries[iRow];
      this._wksCentralService.saveWksEntity(entityCur, 'deleteEntity')
      .subscribe(
        wksEntityReturn => {
          this.listSubsidiaries.splice(iRow, 1);
          this.listSubsidiariesCache.splice(iRow, 1); 
          if (this.listSubsidiaries.length === 0) {
            this.addSubsidiary();
          }  
         
        },
        () => {
          this.errorMessage('remove');
        }
      );
    }
  }
  dataOutSubsidiary(jsonData: any, iRow: number) {
    
    if (jsonData.cpltEventName === this.configSubsidiary[iRow].cpltEventName) {
      const subsidiaryCur = Object.assign({}, this.listSubsidiaries[iRow]);
      subsidiaryCur.stdEntity = (jsonData.corporateName ? jsonData.corporateName : '');
      subsidiaryCur.entityAddress = (jsonData.address ? jsonData.address : '');
      subsidiaryCur.entityPosition = (jsonData.addressPosition ? jsonData.addressPosition : '');
      this.listSubsidiariesInput[iRow] = Object.assign({}, subsidiaryCur);
      // this.dataInSubsidiaryCur(subsidiaryCur, iRow);
      this.inputSubsidiary = true;
    }
    
  }
  doCancel(iRow: number) {
    this.listSubsidiaries[iRow].isCollapsed = true;
    this.listSubsidiaries[iRow] = this.listSubsidiariesCache[iRow];
    this.dataInSubsidiaryCur(this.listSubsidiaries[iRow], iRow);
    this.inputSubsidiary = false;
  }
  doRegisterSubsidiary(iRow: number) {
    const subsidiaryCard = this.prepareValidation(this.listSubsidiariesInput[iRow]);
   
    this._wksCentralService.saveWksEntity(subsidiaryCard, 'update')
    .subscribe(
      wksEntityReturn => {
        this.inputSubsidiary = false;
        
        this.listSubsidiaries[iRow] = Object.assign({}, this.listSubsidiariesInput[iRow] as WksEntityModel);
        this.listSubsidiaries[iRow].isCollapsed = true;
        this.dataInSubsidiaryCur(this.listSubsidiaries[iRow], iRow);
      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  prepareValidation(subsidiaryCur: WksEntityModel) {
  
    let jsonAddress: any ;
    if ((subsidiaryCur.entityAddress !== undefined) 
          && (subsidiaryCur.entityAddress !== null) 
          && (subsidiaryCur.entityAddress !== '')) {
      jsonAddress = JSON.stringify(subsidiaryCur.entityAddress);
    }
   
    const subsidiaryCard: WksEntityModel = {
      id:  (subsidiaryCur.id ? subsidiaryCur.id : undefined),
      stdEntity: subsidiaryCur.stdEntity,
      entityCountry: this.entityParent.entityCountry,
      entityAddress: jsonAddress,
      subsidiaryOf: this.entityParent.entityId,
      entityId: (subsidiaryCur.entityId ? subsidiaryCur.entityId : undefined),
      entityPosition: subsidiaryCur.entityPosition,
      entityDataPurge: -1,
      accountCash: '',
      accountFmt: '',
      entityScales: '',
      otherData: this.entityParent.otherData,
    };
    return subsidiaryCard;
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, rowCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') && (_actionCur === 'removeCnt')) {
          this.removeSubsidiaryOK(rowCur);
        }
      });

  }
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording subsidiary';
    }
    if (_actionCur === 'remove') {
      messageBox = 'error recording';
      titleBox = 'removing subsidiary';
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doClose() {
    // this.router.navigate(['/jobHome']);
  }
  
}

