import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class EquipmentData {
    ownerName: string;
    ownerId: string;
    stdEntity: string;
}
