import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { environment } from '../../../../../../environments/environment';

export const localRxStompConfig: InjectableRxStompConfig = {
  // Which server?
  brokerURL: environment.wsBaseUrl + '/stomp/',
/*
  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: '',
    passcode: ''
  },
*/
  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 0, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.log(new Date(), msg);
  },
  beforeConnect: (stompClient: any): Promise<void> => {
    const token = localStorage.getItem('JWT_TOKEN');
    return new Promise<void>((resolve, _) => {
      stompClient.connectHeaders = {
        Authorization: 'Bearer ' + token, // also tried x-auth-token
      };
      resolve();
    });
  },
};
