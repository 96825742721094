<div #refElement *ngIf="isLoaded" class="mat-elevation-z8 container-fluid stdTheme"
    [ngStyle]="{'margin-top': '20px', width: '95%' ,padding: '0px'}" >
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="actionRefForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-header>
                        <mat-card-title>
                        <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                        <section class="mat-typography">
                            <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                        </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="container-fluid d-flex justify-content-between" >
                            <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'goback' | translate }}
                            </button>
                            <button *ngIf="(!isRegistered && actionRefForm.valid)" mat-raised-button  #buttonSubmit type="submit" (click)="registerJobRef()" >
                                <mat-icon>save</mat-icon>
                                {{ 'save' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                    <mat-card-content class="stdTheme">
                        <div  class="flex-row row">          
                            <div class="col-12">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'actionrefLabel' | translate }}</mat-label>
                                    <input id="actionrefLabel" class="form-control inputTheme" formControlName="actionrefLabel" readonly matInput #input placeholder="{{ 'actionrefLabel' | translate }}"
                                    required >
                                </mat-form-field>
                            </div>    
                        </div>
                        <div class="flex-row row">
                            <div class="col-12" style="margin-top: 10px">
                                <div *ngFor= "let langCur of langLabelsList; let rowLang=index; let isLast=last" >
                                    <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                        <tbody class="stdTheme" style="overflow-y: auto;  height: 15%;" >
                                            <tr  >
                                                <td class="stdTheme" style="width: 10%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{langCur.langDisplay}}
                                                </td>
                                                <td class="stdTheme" style="width: 90%; padding: 2px; padding-left: 5px; padding-right: 5px;">

                                                    <input type="text" style="width: 100%" class="inputNormal" #langLabel name="langLabel" value="{{langCur.langLabel}}"
                                                                    (keyup)="setLangLabelValue(langLabel.value,rowLang)" [direction]="langCur.langDirection"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>    
                                    </table>
                                </div>                                      
                            </div>
                        </div>
                        <div class="flex-row row">
                            <div class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipType' | translate }}</mat-label>
                                    <mat-select id="equipType" placeholder="{{ 'Choose equipType' | translate }}" formControlName="equipType" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList" 
                                            [value]="equipTypeCur.value"  >{{equipTypeCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipBrand' | translate }}</mat-label>
                                    <mat-select id="equipBrand" placeholder="{{ 'Choose brand' | translate }}" formControlName="equipBrand" 
                                                panelClass="mdiselectcontent" #equipBrand
                                                (ngModelChange)="onChangeBrand(equipBrand.value)"
                                                ngDefaultControl >
                                        <mat-select-header>
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matPrefix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                        (keyup)="onKeyBrand($event)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-select-header>
                                        <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                            [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipModel' | translate }}</mat-label>
                                    <mat-select id="equipModel" placeholder="{{ 'Choose model' | translate }}" formControlName="equipModel" 
                                            panelClass="mdiselectcontent"
                                            
                                            ngDefaultControl >
                                        <mat-select-header>
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matPrefix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filtermodel placeholder="{{ 'filterInput' | translate }}" 
                                                        
                                                        (keyup)="onKeyModel($event)" type="text" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="filtermodel.value" (click)="filtermodel.value=''; onKeyModel('');">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="modelFilteredList.length === 0" (click)="addModel(filtermodel.value)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-select-header>
                                        <mat-option class="selectOptionsTheme" *ngFor="let modelCur of modelFilteredList" 
                                            [value]="modelCur.modelName"  >{{modelCur.modelName}}
                                        </mat-option>
                                    </mat-select>    
                                </mat-form-field>
                            </div>   
                        </div>
                        <div  class="flex-row row" style="margin-bottom: 15px;">          
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'brandRef' | translate }}</mat-label>
                                    <input id="brandRef" class="form-control inputTheme" formControlName="brandRef" maxlength="25" matInput #input placeholder="{{ 'brandRef' | translate }}"
                                     >
                                </mat-form-field>
                            </div>  
                            <div class="col-5" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'workCode' | translate }}</mat-label>
                                    <mat-select id="workCode" placeholder="{{ 'Choose workCode' | translate }}" formControlName="workCode" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required 
                                            (ngModelChange)="onChangeWork($event)">
                                        <mat-option class="selectOptionsTheme" *ngFor="let workCodeCur of workCodeList" 
                                            [value]="workCodeCur.value"  >{{workCodeCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div  class="col-3" style="margin-bottom: 10px;" hidden>
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'billingUnit' | translate }}</mat-label>
                                    <mat-select id="billingUnit" placeholder="{{ 'Choose billingUnit' | translate }}" formControlName="billingUnit" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let billingUnitCur of billingUnitsList" 
                                            [value]="billingUnitCur.value"  >{{billingUnitCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>   
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</div>
