import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, TemplateRef, OnChanges, ViewEncapsulation  } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';


import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksTasksModel, TaskSN, LangLabelModel, ParamLabelsI18n, ParamWorksRatesModel, ParentTask, 
                TasksActionsContentModel, EventStatutCur, JobManufacturerAtt, TaskrefJsonModel } from '../../../../models/wks-param.model';
import { BrandWksModel, MeasurementList, UnitQuantity, DataUpstream } from '../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';


import { Manufacturers, ManufacturersFilesType } from '../../../../../../job/job-wks/models/wks-manufacturers.model';
import { LabelValue } from '../../../../../../models/data.model';


@Component({
  selector: 'mdi-tasksref-card',
  templateUrl: './tasksref-card.component.html',
  styleUrls: ['./tasksref-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TasksrefCardComponent implements OnInit, OnChanges {

  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() parentTask: ParentTask;
  @Input() tasksrefList: WksTasksModel[];
  @Output() dataOut = new EventEmitter<any>();

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  @ViewChild('filtermodel', {static: false}) filtermodel: ElementRef;

  @ViewChild('actionModal', {read: TemplateRef, static: false}) actionModal: TemplateRef<any>;

  private readonly onDestroy = new Subject<void>();

  isRegistered: boolean;
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];
  equipTypeList: LabelValue[];
  workCodeList: LabelValue[];
  tasksrefListCur: WksTasksModel[];
  taskParentsList: LabelValue[];
  tasksrefCache: WksTasksModel;

  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  modelCacheList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;


  taskrefCol: string;
  tasksRefForm: FormGroup;
  okToDisplayForm: boolean; 

  titleCard: string;

  entityCur: string;
  wksEntity: WksEntityModel;
  isLoaded: boolean;
  isParamTaskRef: boolean;
  isFourniture: boolean;
  isRevision: boolean;
  openedAccordion: boolean;

  rootTask: ParentTask;
  isMetric: boolean;
  unitsQuantity: LabelValue[];
  billingUnitsList: LabelValue[];
  tasksActionsContent: TasksActionsContentModel;
  tasksActionsParentContent: TasksActionsContentModel;
  
  taskrefParentCur: string;
  isModalCall: boolean;

  associatedTaskChecked: boolean;
  allSNChecked: boolean;
  inputFailed: boolean;
  taskrefSN: TaskSN[];
  restrictSN: TaskSN[];

  specTypeList: LabelValue[];
  monthsList: LabelValue[];
  hoursList: LabelValue[];

  jobManufacturerAtt: JobManufacturerAtt;

  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
  };
  levelOptions: {
    normal: string;
    major: string;
  };
  timeFormat: string;
  langCur: LangLabelModel;
  rowTask = 0;
  
  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _ressourcesService: RessourcesService,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _wksCentralService: WksCentralService) { }

  ngOnInit() {
 /*   this.isRegistered = false;
    this.isLoaded = false;
    this.isParamTaskRef = false;

    this.initData();*/
  }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'parentParam': {
            this.parentTask = changes['parentTask'].currentValue;
            break;
          }
          case 'tasksrefList': {
            this.tasksrefList = changes['tasksrefList'].currentValue;

            break;
          }
        } // end switch
      } // end if
    } 

    this.initData();
  }
  initData() {
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }
    this.initBooleans();
    this.buildForm(); 
    this.initParams();
    if (!this.settingIntFmts()) {
      return;
    }

    this.buildList();
    
  }
  initBooleans() {
    this.isRegistered = false;
    this.isLoaded = false;
    this.isParamTaskRef = false;
    this.openedAccordion = false;
    this.isModalCall = false;
    this.inputFailed = false;
    this.okToDisplayForm = false;

    if (this.parentTask.statutCall === 'modal') {
      this.isModalCall = true;
    }
    this.associatedTaskChecked = false;
    this.allSNChecked = false;
    this.isFourniture = (this.optionItem !== 'works');
    this.isRevision = false;
  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'numbers')
    };
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    return true;
  }
  initParams() {
    this.taskrefCol = 'col-12';
    if ((this.parentTask.statutCall === 'sidenav') || (this.parentTask.statutCall === 'modal')) {
      this.isParamTaskRef = true;
      this.taskrefCol = 'col-4';
      this.rootTask = this.parentTask;
    }

    const listItem = this._wksCentralService.getComponentsList();

    this.equipTypeList = [];
    for (const item of listItem) {
      const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.equipTypeList.push(itemCur);
    }
    const listEquip = this._wksCentralService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      const itemCur: LabelValue = {
          label: this._translate.getTranslate(item.equipName),
          value: item.equipType,
      };
      this.equipTypeList.push(itemCur);
    }
    this.equipTypeList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });

    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }

    this.taskrefParentCur = undefined;
    this.entityCur = this._userService.getUserLogged().entity;   
    this.titleCard = this._translate.instant(this.optionItem);
    const countryEntity  = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry);
    this.isMetric = (countryEntity.measurementUnit === 'METRIC');
    const jsonUnits = this._wksCentralService.getUnitsQuantity() as MeasurementList[];
    let measurementLocal = 'METRIC';
    if (!this.isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    // tires
    this.unitsQuantity  = [];
    for (const measurLocal of jsonUnits) {
      if (measurLocal.measurement === measurementLocal) {
        const arrayUnits = [...measurLocal.list];
        for (const unitName of arrayUnits) {

          this.unitsQuantity.push({
            label: this._translate.getTranslate(unitName),
            value: unitName
          });
        }
        this.unitsQuantity.splice(0, 0, {
          label: '',
          value: ''
        });
        break;
      }
    }
    const listTmp = this._wksCentralService.getBillingUnits();
    this.billingUnitsList = [];
    for (const buCur of listTmp) {
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(buCur),
        value: buCur
      };
      this.billingUnitsList.push(curLabel);
    }
    this.taskrefSN = [];
    this.specTypeList = [];
   
    this.monthsList = [];
    for (let iIdx = 1; iIdx < 37; iIdx++) { 
      const labelValue: LabelValue =  {
        label: iIdx.toString(),
        value: iIdx.toString()
      };
      this.monthsList.push(labelValue);
    }

    this.hoursList = [];
    for (let iIdx = 50; iIdx < 550; iIdx = iIdx + 50) { 
      const labelValue: LabelValue =  {
        label: iIdx.toString(),
        value: iIdx.toString()
      };
      this.hoursList.push(labelValue);
    }
    this.levelOptions = {
      normal: 'normal',
      major: 'major'
    };
    this.loadEntity();
  }
  buildForm() {

    this.tasksRefForm = this.fb.group({
      taskrefLabel: this.fb.control(''),
      jobPud: this.fb.control(''),
      equipType: this.fb.control(''),
      equipBrand: this.fb.control(''),
      equipModel: this.fb.control(''),
      partRef: this.fb.control(''),
      taskrefUnit: this.fb.control(''),
      taskrefQuantity: this.fb.control(''),
      workCode: this.fb.control(''),
      taskrefComment: this.fb.control(''),
      associatedTaskCheck: this.fb.control(''),
      allSNCheck: this.fb.control(''),
      revision: this.fb.control(''),
      periodicityHours: this.fb.control({value: ''}, [Validators.max(500), Validators.min(50)]),
      periodicityMonths: this.fb.control({value: ''}, [Validators.max(60), Validators.min(6)]),
      jobLevel: this.fb.control({value: ''}),
    },
    {
      updateOn: 'blur'
    });
  }
  buildList() {
    this.filterTaskList();
    this._wksCentralService.taskWksEvent
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event.todo === 'changeValuesTask') {
        this.setContentTask(event.orig, event.content as TasksActionsContentModel);
      } else if (event) {
        const typeEvent = typeof event;
        // const eventString = JSON.stringify(event).toString();
        const eventString = String(event);
        // console.log(typeEvent);
      }
    });
  }
  filterTaskList() {
    this.tasksrefListCur = [];
    if (this.tasksrefList === undefined) {
      return;
    }
    if (this.parentTask.rootParam === undefined) {
      this.tasksrefListCur = [...this.tasksrefList];
      return;
    }
    let newArray = [...this.tasksrefList];
    newArray = CommonMethods.filterArray(newArray, this.parentTask.rootParam.taskrefId, false);
    this.tasksrefListCur = [...newArray];
  }
  loadEntity() {
    
    this._wksCentralService.getWksEntity(this.entityCur)
    .subscribe(
      data => {
        this.wksEntity = data.body;
        // this.fillForm();
        this.isLoaded = true;
        this.loadBrands()
        .then (brandList => {
          if ((this.parentTask.parentParam !== undefined) && (this.parentTask.parentParam.equipBrand) && (this.parentTask.parentParam.equipModel)) {
            let brandSelected: BrandWksModel;
            for (const curBrand of this.brandCacheList) {
              if (curBrand.brandName === this.parentTask.parentParam.equipBrand) {
                brandSelected = curBrand;
                break;
              }
            }
            // this.loadModels(brandSelected)
            // .then (modelList => { 
              this.loadWorksType()
              .then (dataReturn => {
                this.fillForm();
                this.isLoaded = true;
              })            
              .catch(error  => {
                
                this.isLoaded = true;
              });
 
            // })
            // .catch(error  => {
              
              // this.isLoaded = true;
            // });
          } else {
            this.loadWorksType()
            .then (dataReturn => {
              this.fillForm();
              this.isLoaded = true;
            })            
            .catch(error  => {
              
              this.isLoaded = true;
            });

          }
          this.isLoaded = true;
        })
        .catch(error  => {
          
          this.isLoaded = true;
        });
      },
      () => {
        this.errorMessage('entity');
      }
    );
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    this.modelCacheList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    if (this.brandSelected !== brandSelected) {
      this.isRegistered = false;
    }
    this.brandSelected = brandSelected;
    this.initModelList(brandSelected);
    this.loadSpecBrand(brandSelected.brandName);
    
  }
  onKeyBrand($event: any) {
    // console.log(value);
  
    // let valueFinal = $event.target.value;
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '' ) { 
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];
    this.modelCacheList = [];
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this.saveBrandModel(newBrand);
  }
  loadSpecBrand(brandName: string): void {

    this.specTypeList = [];
    try {
      const  manufacturersList = this._wksCentralService.getManufacturers() as Manufacturers[];
      for (const manufacturerCur of manufacturersList) {
        if (manufacturerCur.brandName === brandName) {
          const filesType = manufacturerCur.filesType as  ManufacturersFilesType[];
          for (const fileType of filesType) {
            const labelValue: LabelValue =  {
              label: fileType.typeName,
              value:  fileType.typeName,
            };
            this.specTypeList.push(labelValue);
          }
          break;
        }
      } 
      this.specTypeList.sort((obj1, obj2) => {
        return obj1.label > obj2.label ? 1 : -1;
      });
      this.specTypeList.splice(0, 0, {
        value: '',
        label: '',
      });
    } catch (error) {
      console.log('loadSpecBrand ' + error);
      return ;
    } finally { }
  }
  initModelList(_brandSelected: BrandWksModel) {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.modelCacheList = [];
    this.modelFilteredList = [];
    this.loadModels(_brandSelected)
    .then ((modelList: BrandWksModel[]) => {

    })
    .catch((error) => {
 
    });
  }
  loadModels(_brandSelected: BrandWksModel) {
    let modelList: BrandWksModel[];
    let equipType = null;
    if (this.parentTask.parentParam && this.parentTask.parentParam.equipType) {
      equipType = this.parentTask.parentParam.equipType;
    } else {
      try {
        equipType = this.tasksRefForm.controls.equipType.value;
      } catch (err) {
        return;
      }
    }
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksModelsList(this._userService.getUserLogged().entity, _brandSelected.brandName, equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          modelList = data.body;
          for (const brandCur of modelList) {
            this.modelCacheList.push(brandCur);
          }
          this.modelCacheList.sort((obj1, obj2) => {
            return obj1.modelName > obj2.modelName ? 1 : -1;
          });
          this.modelCacheList.splice(0, 0,  {
              stdEntity: this._userService.getUserLogged().entity, 
              brandRow: 'model', 
              brandName: '',
              equipType: '',
              modelName: ''} );
          this.modelFilteredList = [...this.modelCacheList];
          resolve(modelList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.modelCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'model',
              brandName: _brandSelected.brandName,
              equipType: equipType,
              modelName: '',
            });
            this.modelFilteredList = [...this.modelCacheList];
          }
           reject(err);
        }
      );
    });

  }
  onKeyModel($event: any) {
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.modelFilteredList = this.searchModel(valueFinal);
    this.filtermodel.nativeElement.value = valueFinal;
  }
  searchModel(value: string) { 
    const filter = value.toLowerCase();
    return this.modelCacheList.filter(modelCur => modelCur.modelName.toLowerCase().includes(filter));
  }
  addModel(newModelName: string) {
    // console.log(newModel);
    let equipType = null;
    try {
      equipType = this.tasksRefForm.controls.equipType.value;
    } catch (err) {
      return;
    }
    const newModel = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'model',
      brandName: this.brandSelected.brandName,
      equipType: equipType,
      modelName: newModelName,
    };
    this.modelCacheList.push(newModel);
    this.modelCacheList.sort((obj1, obj2) => {
      return obj1.modelName > obj2.modelName ? 1 : -1;
    });
    if (this.modelCacheList[0].modelName === '') {
      this.modelCacheList.splice(0, 1);
    }
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newModel, 'create');
    this.saveBrandModel(newModel);
  }
  saveBrandModel(newBrandModel: BrandWksModel) {
    this._wksCentralService.saveWksBrand( newBrandModel, 'create')
    .subscribe(
      () => {
        // console.log('saveBrandModel OK');
      },
      () => {
        console.log('saveBrandModel KO');
      }
    );
  }
  onChangeModel($event: any) {
    this.isRegistered = false;
  }
  onInputChange(_typeInput: string, inputValue: any): void {

    switch (_typeInput) {
      /*
      case 'specType':
        this.jobManufacturerAtt.specType = inputValue;
        break;
      */
      case 'revision':
        this.isRevision = inputValue;
        this.jobManufacturerAtt.specType = this.isRevision ? 'revision' : 'other';
        break;
      case 'periodicityHours':
        this.jobManufacturerAtt.hours = inputValue;
        break;
      case 'periodicityMonths':
        this.jobManufacturerAtt.months = inputValue;
        break;
      case 'jobLevel':
        if (inputValue === 'major' ) {
          this.jobManufacturerAtt.jobMajor = true;
          this.jobManufacturerAtt.jobNormal = false;
        } else {
          this.jobManufacturerAtt.jobMajor = false;
          this.jobManufacturerAtt.jobNormal = true;
        }
        break;
  }
}
  showAssociatedTask($event: boolean) {
    this.associatedTaskChecked = $event;
    if (!this.associatedTaskChecked) {
      this.tasksActionsParentContent = undefined;
      this.taskrefParentCur = undefined;
    } else  {
      this.restrictSN = []; 
      if (!this.allSNChecked) {
        this.restrictSN = this.taskrefSN; 
      }
    }
    // console.log($event);
  }
  showAllSN($event: boolean) {
    this.allSNChecked = $event;
    if (!this.allSNChecked) {
      this.taskrefSN = [];
      this.addTaskRefSN(0);
    } else {
      if ((this.tasksrefCache.taskrefSN !== undefined) && (this.tasksrefCache.taskrefSN !== null)) {
        try {
          const taskrefSNJson = JSON.parse(this.tasksrefCache.taskrefSN) as TaskSN[];
          if (taskrefSNJson.length > 0 && this.allSNChecked) {
            this.isRegistered = false;
          }
        } catch {

        }
      } else  {
        if (this.allSNChecked) {
          this.isRegistered = false;
        }
      }
    
    }
    // console.log($event);
  }
  addTaskRefSN(idx: number) {
    const taskSN: TaskSN = {
      snFrom: '',
      snUpto: ''
    };
    if (idx === 0) {
      this.taskrefSN.push(taskSN);
    } else {
      this.taskrefSN.splice(idx + 1, 0, taskSN);
    } 
  }
  removeTaskRefSN(idx: number) {

    if (this.taskrefSN.length > 1) {
      this.taskrefSN.splice(idx, 1);
    } else  {
      this.taskrefSN = [];
      this.addTaskRefSN(0);
    } 
  }
  setTaskSNValues(field: string, inputValue: string, idx: number) {
    
    switch (field) {
      case 'snFrom':
        this.taskrefSN[idx].snFrom = inputValue;
        break;
      case 'snUpto':
        this.taskrefSN[idx].snUpto = inputValue;
        break;
    }
  }
  loadWorksType() {
    let dataReturn: ParamWorksRatesModel[];
    this.workCodeList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
       data => {
          dataReturn = data.body;
          for (const workCur of dataReturn) {
            const jobTypeCur: LabelValue = {
              label: workCur.workLabel,
              value: workCur.workCode
            };
            this.workCodeList.push(jobTypeCur);
          }
          
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  onKeyPartRef($event: any) {
    this.isRegistered = false;
  }
  onChangeJobRef($event: any) {
    this.isRegistered = false;
  }
  setTaskrefQuantityValue(_event: string) {
    const eventNum = Number(_event);
    this.tasksRefForm.controls.taskrefQuantity.setValue((Number.isNaN(eventNum) ? 0.0 : eventNum)); 
  }
 
  fillForm() {
    this.jobManufacturerAtt = {
      hours: -1,
      months: -1,
      specType: '',
      jobMajor: false,
      jobNormal: false,
      jobPud: undefined,
      specjobId: undefined
    };
    if (this.actionType === 'add') {
      this.okToDisplayForm = true;
      return;
    }
    if (this.parentTask.statutCall === 'accordion') {
      return;
    }
    if (this.parentTask.parentParam === undefined) {
      return;
    }

    if  (this.parentTask.statutCall === 'sidenav') {
      this.rootTask = {
        rootParam: ((this.parentTask && this.parentTask.rootParam ) ? this.parentTask.rootParam : this.parentTask.parentParam),
        parentParam: this.parentTask.parentParam,
        statutCall: 'loadChildren',
        stdEntity: this.parentTask.stdEntity, 
        userlang: this.parentTask.userlang,
      };
      
    }
    this.isRegistered = true;
    this.tasksrefCache = JSON.parse(JSON.stringify(this.parentTask.parentParam)) as WksTasksModel;
    this.tasksRefForm.controls.taskrefLabel.setValue(this.parentTask.parentParam.taskrefLabel); 
    this.tasksRefForm.controls.equipType.setValue(this.parentTask.parentParam.equipType); 

    this.tasksRefForm.controls.equipBrand.setValue(this.parentTask.parentParam.equipBrand);
    // this.tasksRefForm.controls.equipModel.setValue(this.parentTask.parentParam.equipModel);
    this.tasksRefForm.controls.partRef.setValue(this.parentTask.parentParam.partRef);
    this.tasksRefForm.controls.workCode.setValue(this.parentTask.parentParam.workCode);
    this.loadSpecBrand(this.parentTask.parentParam.equipBrand);
    
    if ((this.parentTask.parentParam.taskrefParent !== '') 
      && (this.parentTask.parentParam.taskrefParent !== undefined)
      && (this.parentTask.parentParam.taskrefParent !== null))  {
      this.associatedTaskChecked = true;
      this.taskrefParentCur = this.parentTask.parentParam.taskrefParent;
    }
    if ((this.parentTask.parentParam.langLabels !== undefined) && (this.parentTask.parentParam.langLabels !== null)) {
      for (const labelCur of this.parentTask.parentParam.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
          }
        } 
      }
    }
    if ((this.parentTask.parentParam.taskrefJson !== undefined) && (this.parentTask.parentParam.taskrefJson !== null))  {
      try {
        const taskrefJson = JSON.parse(this.parentTask.parentParam.taskrefJson);
        const unitQuantity = taskrefJson.unitquantity;
        if (unitQuantity !== undefined) {
          this.tasksRefForm.controls.taskrefUnit.setValue(unitQuantity.unit);
          this.tasksRefForm.controls.taskrefQuantity.setValue(unitQuantity.quantity);
        }
        const taskrefComment = taskrefJson.taskrefComment;
        if (taskrefComment !== undefined) {
          this.tasksRefForm.controls.taskrefComment.setValue(taskrefComment);
        }
        // const tasksActionsContent  = taskrefJson.tasksActionsContent;
        if (taskrefJson.tasksActionsContent !== undefined) {
          this.tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        } else {
          this.tasksActionsContent = undefined;
        }
        if (taskrefJson.tasksActionsParentContent !== undefined) {
          this.tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
        } else {
          this.tasksActionsParentContent = undefined;
        }
        const jobManufacturerAtt = taskrefJson.jobManufacturerAtt;
        let jobPud = '';          
        if (jobManufacturerAtt !== undefined) {
          this.jobManufacturerAtt = JSON.parse(JSON.stringify(jobManufacturerAtt));
          this.isRevision = (this.jobManufacturerAtt.specType.startsWith('revision') ? true : false);
          if (this.jobManufacturerAtt.jobPud !== undefined && this.isRevision) {
            const jobPudDate = (jobManufacturerAtt.jobPud ? jobManufacturerAtt.jobPud : this.parentTask.parentParam.stdLastupdate);
            jobPud = CommonMethods.dateToString(jobPudDate, 'unixM', this.timeFormat);
            this.tasksRefForm.controls.jobPud.setValue(jobPud); 
          } else {
            this.tasksRefForm.controls.jobPud.setValue(''); 
          }
          // this.tasksRefForm.controls.specType.setValue(this.jobManufacturerAtt.specType);
          this.tasksRefForm.controls.periodicityHours.setValue(this.jobManufacturerAtt.hours);
          this.tasksRefForm.controls.periodicityMonths.setValue(this.jobManufacturerAtt.months);
          this.tasksRefForm.controls.jobLevel.setValue(this.jobManufacturerAtt.jobMajor ? 'jobMajor' : 'jobNormal');
          this.tasksRefForm.controls.revision.setValue(this.isRevision);
        } 
        if (jobPud === '' && this.isRevision) {
          jobPud = CommonMethods.dateToString(this.parentTask.parentParam.stdLastupdate, 'unixM', this.timeFormat);
          this.tasksRefForm.controls.jobPud.setValue(jobPud);
        } else {
          this.tasksRefForm.controls.jobPud.setValue('');
        }
      } catch {}
    } 
    this.taskrefSN = [];
    
    if ((this.parentTask.parentParam.taskrefSN !== undefined) && (this.parentTask.parentParam.taskrefSN !== null)) {
      try {
        const taskrefSNJson = JSON.parse(this.parentTask.parentParam.taskrefSN);
        this.taskrefSN = [...taskrefSNJson];
        this.restrictSN = this.taskrefSN;
      } catch {
        this.taskrefSN = [];
        this.restrictSN = this.taskrefSN;
      }
    }
    this.allSNChecked = (this.taskrefSN.length === 0 ? true : false);
    if (this.parentTask.parentParam.equipBrand !== undefined) {
      const brandSelected: BrandWksModel =  {
        stdEntity: this._userService.getUserLogged().entity,
        brandRow: 'model',
        brandName: this.parentTask.parentParam.equipBrand
      };
      this.loadModels(brandSelected)
      .then ((modelList: BrandWksModel[]) => {
        this.tasksRefForm.controls.equipModel.setValue(this.parentTask.parentParam.equipModel);
        this.okToDisplayForm = true;
        this.openedAccordion = true;
      })
      .catch((error) => {
        this.okToDisplayForm = true;
      this.openedAccordion = true;
      });
    } else {

      this.okToDisplayForm = true;
      this.openedAccordion = true;
    }
  }

  setLangLabelValue(_event: string, _row: string) {
    const value = _event.charAt(0).toUpperCase() + _event.slice(1);
    if (this.langLabelsList[_row].langLabel !== value) {
      this.isRegistered = false;
    }
    if (this.langLabelsList[_row].langValue === this._translate.getCurrentLang()) {
      this.tasksRefForm.controls.taskrefLabel.setValue(value);
    }
    this.langLabelsList[_row].langLabel = value;
  }
  onChangeComment(_event: string) {
    const value = _event.charAt(0).toUpperCase() + _event.slice(1);
    this.isRegistered = false;
    this.tasksRefForm.controls.taskrefComment.setValue(value);
  }
  removeTaskRef() {
    this._wksCentralService.saveParamTasksref(this.parentTask.parentParam, 'delete')
    .subscribe(
      () => {
        this.dataOut.emit('removeNode');
      },
      () => {
        this.errorMessage('remove');
      }
    );
   
  }
  registerTaskRef(origRegister: string) {

    const taskRefValidation = this.fillModel();
    this._wksCentralService.saveParamTasksref(taskRefValidation, this.entityCur)
    .subscribe(
      paramWorksRatesReturn => {
        this.parentTask.parentParam = paramWorksRatesReturn.body;

        this.isRegistered = true;

        if ((this.parentTask.statutCall === 'sidenav') || (this.isModalCall)) {
          this.parentTask = {
            rootParam: this.parentTask.parentParam,
            parentParam: this.parentTask.parentParam,
            statutCall: 'loadChildren',
            stdEntity: this.parentTask.stdEntity, 
            userlang: this.parentTask.userlang
          };
        } 
        if (this.openedAccordion) {
          if ((this.isModalCall) && (origRegister === 'main&detail')) {
            this._wksCentralService.taskWksEvent.emit({
              orig: 'registerTaskRef',
              todo: 'newTaskRef',
              content: this.parentTask.parentParam
            });
          } 
          this.doCancel();
        }
        this.openedAccordion = true;
        if (this.parentTask.statutCall === 'accordion') {
          // this.dataOut.emit(this.parentTask);
        }
      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  setContentTask(_origEvent: string, _contentEvent: TasksActionsContentModel) {
    if (this.tasksActionsContent === undefined)  {
      this.tasksActionsContent = {
        works: [],
        supplies: []
      };
    }
    if (this.tasksActionsParentContent === undefined)  {
      this.tasksActionsParentContent = {
        works: [],
        supplies: []
      };
    }
    if (_origEvent === 'works') {
      this.tasksActionsContent.works = [];
      this.tasksActionsParentContent.works = [];

      // this.tasksActionsContent.works = _contentEvent.works;
      for (const taskCur of _contentEvent.works) {
        if (taskCur.parentElement) {
          this.tasksActionsParentContent.works.push(taskCur);
        } else {
          this.tasksActionsContent.works.push(taskCur);
        }
      }
    }
    if (_origEvent === 'supplies&parts') {
      // this.tasksActionsContent.supplies = _contentEvent.supplies;
      this.tasksActionsContent.supplies = [];
      this.tasksActionsParentContent.supplies = [];
      for (const taskCur of _contentEvent.supplies) {
        if (taskCur.parentElement) {
          this.tasksActionsParentContent.supplies.push(taskCur);
        } else {
          this.tasksActionsContent.supplies.push(taskCur);
        }
      }
    }
    this._wksCentralService.taskWksEvent.emit({
      orig: _origEvent,
      todo: 'changeValuesWorks',
      content: _contentEvent
    });
    this.isRegistered = false;
  }
  fillModel(): WksTasksModel {
    const paramLabelsI18nArray: ParamLabelsI18n[] = [];
    const tasksActionsContentRegister: TasksActionsContentModel = {
      works: [],
      supplies : [],
    };
    if (this.tasksActionsContent === undefined) {
      this.tasksActionsContent = {
        works: [],
        supplies : [],
      };
    } else  {
      for (const taskWork of this.tasksActionsContent.works) { 
        if (!taskWork.parentElement) { 
          tasksActionsContentRegister.works.push(taskWork);
        }
      }
      for (const taskSupplie of this.tasksActionsContent.supplies) { 
        if (!taskSupplie.parentElement) { 
          tasksActionsContentRegister.supplies.push(taskSupplie);
        }
      }
    }

    if ((this.parentTask.parentParam !== undefined) 
      && (this.parentTask.parentParam.langLabels !== undefined) 
      && (this.parentTask.parentParam.langLabels !== null)) {
      for (const paramLabelsI18n of this.parentTask.parentParam.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = local.langLabel;
          }
        }
        paramLabelsI18nArray.push(paramLabelsI18n); 
      }
    } else {
      for (const labelCur of this.langLabelsList) {
        const paramLabelsI18n = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18n);
      }
    }
    const unitQuantity: UnitQuantity = {
      unit: this.tasksRefForm.controls.taskrefUnit.value,
      quantity: this.tasksRefForm.controls.taskrefQuantity.value,
    };
    if (!this.associatedTaskChecked) {
      // this.parentTask.rootParam.taskrefParent = '';
      this.tasksActionsParentContent = undefined;
      this.taskrefParentCur = undefined;
    }
    const taskrefJsonData:  TaskrefJsonModel = {
      unitquantity:  JSON.stringify(unitQuantity),
      taskrefComment: JSON.stringify(this.tasksRefForm.controls.taskrefComment.value),
      tasksActionsContent: tasksActionsContentRegister,
      tasksActionsParentContent: this.tasksActionsParentContent !== undefined ? this.tasksActionsParentContent : undefined,
      tasksActionCompleted: undefined,
      jobManufacturerAtt: this.jobManufacturerAtt
    };


    const tasksrefValidation: WksTasksModel = {
      id: (this.parentTask.parentParam !== undefined ? this.parentTask.parentParam.id : undefined),
      stdEntity: this.entityCur,
      taskrefId: (this.parentTask.parentParam !== undefined ? this.parentTask.parentParam.taskrefId : undefined),
      taskrefLabel: this.tasksRefForm.controls.taskrefLabel.value,
      taskrefRow: (this.parentTask.statutCall === 'accordion' ? this.optionItem : 'reference'),
      taskrefParent: ((this.parentTask.statutCall === 'accordion') ? this.parentTask.rootParam.taskrefId : 
                        (this.taskrefParentCur) ? this.taskrefParentCur : ''),
      equipType: this.tasksRefForm.controls.equipType.value,
      equipBrand: this.tasksRefForm.controls.equipBrand.value,
      equipModel: this.tasksRefForm.controls.equipModel.value,
      partRef: this.tasksRefForm.controls.partRef.value,
      workCode: this.tasksRefForm.controls.workCode.value,
      actionrefType: undefined,
      actionrefId: undefined,
      taskrefJson: JSON.stringify(taskrefJsonData),
      stdCreation: (this.parentTask.parentParam !== undefined ? this.parentTask.parentParam.stdCreation : undefined),
      langLabels: paramLabelsI18nArray,
      userLang: this._userService.getUserLogged().userlang,
      taskrefSN: (this.allSNChecked ? '' : JSON.stringify(this.taskrefSN))
    };
    return tasksrefValidation;
  }
 
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording task ref';
      
    }
    if (_actionCur === 'entity') {
      messageBox = 'error loading';
      titleBox = 'loading entity ';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
      });

  }
  doClose() {
    if  (((this.parentTask.statutCall === 'sidenav') || (this.parentTask.statutCall ===  'loadChildren'))
       && (!this.isModalCall)) {
      this._wksCentralService.onRegisterValid.emit('closeRegisterTask');
      return;
    } else if ((this.parentTask.statutCall === 'modal') || (this.isModalCall)) {
      this._globalEventsManagerService.synchroEvents.next('closeTaskRefModal');
    } else {
      if (this.parentTask.parentParam) {
        this.dataOut.emit('collapseNode;' + this.parentTask.parentParam.id);
      } else {
        this.dataOut.emit('collapseNode' );
      }
    }
  }
  doCancel() {
    if  (((this.parentTask.statutCall === 'sidenav') || (this.parentTask.statutCall ===  'loadChildren')) 
        && (!this.isModalCall)) {
      this._wksCentralService.onRegisterValid.emit('closeRegisterTask');
      return;
    } else if ((this.parentTask.statutCall === 'modal') || (this.isModalCall)) {
      this._globalEventsManagerService.synchroEvents.next('closeTaskRefModal');
      this._wksCentralService.taskWksEvent.emit({
        orig: 'registerTaskRef',
        todo: 'closeTaskRef',
        content: undefined
      });
    } else {
      if (this.parentTask.parentParam) {
        this.dataOut.emit('collapseNode;' + this.parentTask.parentParam.id);
      } else {
        this.dataOut.emit('collapseNode' );
      }
    }
  }
  dataOutParent(parentTaskref: LabelValue) {
    if (parentTaskref !== undefined)  {
      // this.parentTask.rootParam.taskrefParent = parentTaskref.value;
      this.tasksActionsParentContent = JSON.parse(parentTaskref.additionnalValue).tasksActionsContent;
      this.taskrefParentCur = parentTaskref.value;
    }
  }
  statutCur(event: EventStatutCur): void  {
    // console.log(event);
    if (event.subject === 'partsSupplies') {
        this.inputFailed = event.failed;
    } 
  }
}
