import { Component, OnInit } from '@angular/core';

import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { TranslateService } from '../../../../../../../services/translate.service';
@Component({
  selector: 'mdi-detailicon-renderer',
  templateUrl: './detailicon-renderer.component.html',
  styleUrls: ['./detailicon-renderer.component.css']
})
export class DetailiconRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  masterGridApi: any;
  masterRowIndex: any;
  detailIsOpen: boolean;
  subReportIsOpen: boolean;
  docReportIsOpen: boolean;
  detailTypeZoom: boolean;
  detailSubReport: boolean;
  subReportAction: string;
  subReportName: string;
  isDocReport: boolean;
  zoomStatut: string;
  pdfStatut: string;
  subReportStatut: string;

  constructor(private _translate: TranslateService) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.detailTypeZoom = false;
    this.detailSubReport = false;
    this.subReportIsOpen = false;
    this.docReportIsOpen = false;
    this.detailIsOpen = false;
    if ( this.params['detailType'] === 'zoom' ) {
      this.subReportIsOpen = false;
      this.detailTypeZoom = true;
      if((params.data.detailIsOpen !== undefined) && (params.data.detailIsOpen === true)) {
        this.detailIsOpen = true;
        this.zoomStatut = 'detail-close';
      } else {
        this.zoomStatut = 'detail-open';
        this.detailIsOpen = false;
      }
    }
    if ( this.params['detailType'] === 'subReport' ) {
      this.detailSubReport = true;
      this.isDocReport = false;
      this.subReportAction = this.params['detailAction'];
      this.subReportName = this.params['detailName'];

      if((params.data.detailIsOpen !== undefined) && (params.data.detailIsOpen === true)) {
        this.subReportIsOpen = true;
        this.subReportStatut='subreport-close';
      } else {
        this.subReportIsOpen = false;
        this.subReportStatut='subreport-open';
      }
    }
  
    if ( this.params['detailType'] === 'docPdf' ) {
      this.detailSubReport = true;
      this.isDocReport = true;
      this.subReportAction = this.params['detailAction'];
      this.subReportName = this.params['detailName'];

      if((params.data.detailIsOpen !== undefined) && (params.data.detailIsOpen === true)) {
        this.docReportIsOpen = true;
        this.pdfStatut = 'pdf-close';
      } else {
        this.docReportIsOpen = false;
        this.pdfStatut = 'pdf-open';
      }
    }
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;


  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
      return false;
  }

  onGridReady(params: any) {

  }

  ngOnDestroy(): void {

  }
}
