<div id="rowPinned" #refElement class="mat-elevation-z8 stdTheme " style="width: 100%;" >

  <mat-card class="register-card stdTheme" layout="column" flex >
     <mat-card-header>
       <!-- <mat-card-title  >
          <span #titleCard>{{ registerTitle | translate }}</span>
        </mat-card-title> -->

    </mat-card-header>

    <mat-card-content >
  
      <div class="flex-row row">
        <button  mat-icon-button  (click)="addPinnedRow()">
          <span class="fa fa-plus-square" style="font-size: 20px" ></span>
        </button>
      </div>
      <!--[style.width.px]="'800'"-->
      <div class="stdTheme" [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'1050'" >
        <!--<ul class='fa-ul'> -->
          <ng-template ngFor let-iRow="index" let c = "count" let-pinnedRow [ngForOf]="listPinnedRows">

            <div class="container-fluid pinnedRow" style="margin-bottom: 15px;"  >
              <!-- <div id="pinnedRow_{{i}}" #pinnedRow_{{i}} class="row"></div>--> 
              <div id="pinnedRow_{{iRow}}" #pinnedRow_{{iRow}} class="row rowLine stdTheme" >
                <div class="col-1">
                    <button type="button" class="btn btn-sm " mat-icon-button  (click)="removePinnedRow(iRow) " style="margin: 10px;">
                      <i class="fa fa-times icon-small " aria-hidden="true"></i>
                    </button>
                </div>

                <div class="col-10" >
                    <span style="margin: 10px;"><h6 >{{ pinnedRow.rowLabel }}</h6> </span>               
                </div>
                <div class="col-1" >
                  <button type="button" class="btn btn-sm " mat-icon-button style="margin: 10px;"
                    (click)="pinnedRow.isCollapsed = !pinnedRow.isCollapsed"
                    [attr.aria-expanded]="!pinnedRow.isCollapsed" aria-controls="collapseExample">
                    <i *ngIf="pinnedRow.isCollapsed" class="fa fa-chevron-down icon-small " aria-hidden="true"></i>
                    <i *ngIf="!pinnedRow.isCollapsed" class="fa fa-chevron-up icon-small " aria-hidden="true"></i>
                  </button> 

                </div>
<!-- labelled column -->
                <div class="col-5" [ngbCollapse]="pinnedRow.isCollapsed">
                  <div class="row">
                    <div class="col" >
                      <label class="pinnedRow"> {{ 'rowPinnedLabel' | translate }}</label>
                    </div>
                    <div class="col">
                      <input type="text" matInput #rowLabel #input  [(value)]="pinnedRow.rowLabel" (change)="setValueArrayRow('rowLabel',rowLabel.value,iRow)">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col ">
                      <label class="pinnedRow" > {{ 'rowPinnedPosition' | translate }}</label>
                    </div>
                    <div class="col">
                      <!--<input type="text" #rowPosition #input  [(value)]="pinnedRow.rowPosition" (change)="setValueArrayRow('rowPosition',rowPosition.value,iRow)">-->
                      <select class="form-control form-control-sm stdTheme" id="colPosition" [(value)]="pinnedRow.rowPosition" #colPosition 
                            style="width:auto; font-size:small; padding:0px; margin: 0px" 
                            placeholder="{{'ChoicePosition' | translate}}"
                            (blur)="pinnedRow.rowPosition = colPosition.value">
                        <option class="selectOptionsTheme" *ngFor="let colPosition of listPositions" [(value)]="colPosition" >{{ colPosition | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 ">
                      <label class="pinnedRow" style="margin-right: 10px" > {{ 'rowPinnedRank' | translate }}</label>
                    </div>

                    <div class="col-2">
                      <button type="button" style="padding:0px; margin: 0px" class="btn btn-default" mat-icon-button (click)="levelRank(iRow,-1)">
                        <span class="fa fa-minus"></span>
                      </button>
                    </div>
                    <div class="col-2">
                      <span class="form-group" >
                          <input class="input-sm" class="inputTheme" type="number" #rowRank  #input readonly 
                                    [(value)]="pinnedRow.rowRank" 
                                    style="width: 60px;  text-align: center; padding:0px; margin: 0px"
                                    (blur)="pinnedRow.rowRank = rowRank.value">
                      </span>
                    </div>
                    <div class="col-2">
                      <button type="button" style="padding:0px; margin: 0px" class="btn btn-default " mat-icon-button (click)="levelRank(iRow,1)">
                          <span class="fa fa-plus"></span>
                      </button>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col ">
                      <label class="pinnedRow" style="margin-right: 10px" > {{ 'rowPinnedCol' | translate }}</label>
                    </div>
                    <div class="col">
                      <select class="form-control form-control-sm stdTheme" id="colNameDisplay" [(value)]="pinnedRow.colNameDisplay" #colNameDisplay 
                          style="width:auto; font-size:small" 
                          (blur)="pinnedRow.colNameDisplay = colNameDisplay.value"
                          >
                        <option class="selectOptionsTheme" *ngFor="let colCur of listColActive" [value]="colCur.colName">{{ colCur.colName}}</option>
                      </select>
                    </div>
                  </div>

                </div>
<!-- aggegates columns-->
                <div class="col-7" [ngbCollapse]="pinnedRow.isCollapsed" >

                  <table class="table table-striped" >
       
                    <tbody style="overflow-y: auto;">
                      <tr *ngFor="let colAgg of pinnedRow.colAggregate; let iCol = index" style="height: 10px; line-height: 10px; padding:0px;margin-top: 0px;">
                          <td style="width: 2%">  
                            <button style="padding:0px; margin: 0px" class="btn btn-default " mat-icon-button (click)="removeColumnAggregate(iRow,iCol)">
                              <span class="fa fa-minus-square" style="font-size: 20px" ></span>
                            </button>                    
                          </td>
                          <td style="width: 40%" >
                            <select class="form-control form-control-sm stdTheme" id="colAggcolName" [(value)]="colAgg.colName" #colAggcolName 
                                  style="width:auto; font-size:small; padding:0px; margin: 0px" 
                                  placeholder="{{'ChoiceColumn' | translate}}"
                                  (blur)="colAgg.colName = colAggcolName.value">
                              <option class="selectOptionsTheme" *ngFor="let colCur of listColActive" [(value)]="colCur.colName">{{ colCur.colName}}</option>
                            </select>
                          </td>
                          <td style="width: 16%">
                            <select class="form-control form-control-sm stdTheme" id="colAggType" [(value)]="colAgg.aggregateType" #colAggType 
                                  style="width:auto; font-size:small; padding:0px; margin: 0px" 
                                  placeholder="{{'ChoiceOper' | translate}}"
                                  (blur)="colAgg.aggregateType = colAggType.value">
                              <option class="selectOptionsTheme" *ngFor="let aggType of listOperations" [(value)]="aggType">{{ aggType | translate}}</option>
                            </select>
                          </td>
                          <td  style="width: 40%; text-align: left;">
                            <input class="form-control form-control-sm inputTheme" type="text" #colFormat #input   
                                    style="padding: 0px;"
                                    [(value)]="colAgg.colFormat" placeholder="{{'InputFormat' | translate}}"
                                    (blur)="colAgg.colFormat = colFormat.value">
                          </td>
                          <td style="width: 2%">      
                            <button style="padding:0px; margin: 0px" class="btn btn-default " mat-icon-button  (click)="addColumnAggregate(iRow,iCol)">
                              <span class="fa fa-plus-square" style="font-size: 20px" ></span>
                            </button>
                          </td>
                      </tr>
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
      </div>

    </mat-card-content>
    <mat-card-actions>
      <div>        
        <div class="container-fluid d-flex justify-content-between" style="margin-top: 10px;">
          <button mat-raised-button #buttonSubmit type="submit" (click)="register()" [disabled]="!doValidation ">
            <mat-icon>create</mat-icon>
            {{ 'submit' | translate }}
          </button>
          <button mat-raised-button #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'cancel' | translate }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
