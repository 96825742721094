<mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
    <mat-card-title>
        <h6>{{'emailform' | translate }}</h6>
    </mat-card-title>
    <mat-card-content class="stdTheme">
        <div  *ngIf='formIsBuild' class="addressForm" style="margin-top: 0px; margin-right: 10px;margin-left: 10px;">
            <form [formGroup]="emailForm"  id="emailForm"  >
                <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                    <div class="col-6" style="margin-right: 0px;margin-left: 0px;">
                        <mat-form-field style="width: 100%;" >
                            <mat-label style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'fromField' | translate }}</mat-label>
                            <input matInput  type="email" id="fromField"
                                style="width: 90%"
                                [ngClass]="{
                                    'inputAlert': fromField.inputError,
                                    'inputNormal': !fromField.inputError
                                    }"
                                [value]="fromField.value"    
                                [attr.maxlength]="emailAttr.emailLengthMax" 
                                (input)="clearError('fromField', 0, $event.target.value)"
                                (change)="formatField('fromField',0, $event.target.value)"
                                formControlName="fromField"
                            >
                        </mat-form-field> <!-- end emailAddress-->   
                    </div>
                    <div *ngIf="buttonsCopyOK" class="col-6 text-right" style="margin-right: 0px;margin-left: 0px;">
                        <!-- https://www.angularjswiki.com/angular/buttons-in-angular-using-material-design-mat-button-example/#outlined-buttons -->
                        <button mat-flat-button class="stdTheme" (click)="clickCopy()">{{'copy' | translate }}</button>
                        <button mat-flat-button class="stdTheme" (click)="clickCcopy()">{{'ccopy' | translate }}</button>
                        <!--<a mat-flat-button routerLink="https://www.arungudelli.com">mat-flat-button</a>-->
                    </div>
                </div>
                <div #recipientsBlock formArrayName="recipîentsFg" >
                    <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;width: 60%" 
                                    *ngFor= "let emailCur of recipientsList; let rowRecipient=index; let isLast=last"  >
                        <div class="col" style="width: 100%;padding-right: 0px; padding-left: 0px"  [formGroupName]="rowRecipient">
                            <table class="addressTable table table-bordered table-striped">
                                <tbody class="stdTheme" >
                                    <tr style="height: 100%">
                                        <td class="addressTable" style="width: 15%; padding: 2px; padding-left: 2px; padding-right: 2px;padding-top: 15px;">
                                            <span style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'recipient' | translate }}</span> 
                                        </td>
                                        <td class="addressTable" style="width: 45%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                            <mat-form-field style="width: 95%;" >
                                                <mat-label style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'emailAddress' | translate }}</mat-label>
                                                <input matInput type="email" id="rowRecipient"
                                                    style="width: 90%"
                                                    [ngClass]="{
                                                        'inputAlert': emailCur.inputError,
                                                        'inputNormal': !emailCur.inputError
                                                        }"
                                                    [value]="emailCur.value"   
                                                     
                                                    [attr.maxlength]="emailAttr.emailLengthMax" 
                                                    (input)="clearError('recipients',rowRecipient, $event.target.value)"
                                                    (change)="formatField('recipients',rowRecipient, $event.target.value); false"
                                                    formControlName="emailAddress"
                                                        >
                                            </mat-form-field> <!-- end emailAddress-->   
                                        </td>
                                        <td class="addressTable" style="width: 10%; padding: 0px; vertical-align: middle;">
                                            <div class="row" *ngIf="!isReadOnly" style="padding-bottom: 0px; margin-left: 0px; margin-right: 0px; vertical-align: middle;" >
                                                <button class="buttonFunction" mat-icon-button (click)="addEmail('recipients',rowRecipient)" >
                                                    <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                                                </button>
                                                <button class="buttonFunction" mat-icon-button (click)="removeEmail('recipients',rowRecipient)">
                                                    <mat-icon class="iconeFunction" >remove_circle_outline</mat-icon>
                                                </button>
                                            </div><!-- end butttons -->
                                        </td>    
                                    </tr>
                                </tbody>
                            </table>
                        </div> <!-- end butttons -->
                    </div>  <!-- end email loop -->   
                </div>  <!-- end recipients block-->                 
                <div #copyBlock formArrayName="copyFg" *ngIf="isCopy" >
                    <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;width: 60%" 
                                    *ngFor= "let emailCur of copyList; let rowCopy=index; let isLast=last"  >
                        <div class="col" style="width: 100%;padding-right: 0px; padding-left: 0px"  [formGroupName]="rowCopy">
                            <table class="addressTable table table-bordered table-striped">
                                <tbody class="stdTheme" >
                                    <tr style="height: 100%">
                                        <td class="addressTable" style="width: 15%; padding: 2px; padding-left: 2px; padding-right: 2px;padding-top: 15px;">
                                            <span style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'copy' | translate }}</span> 
                                        </td>
                                        <td class="addressTable" style="width: 45%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                            <mat-form-field style="width: 95%;" >
                                                <mat-label style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'emailAddress' | translate }}</mat-label>
                                                <input matInput type="email" id="rowCopy"
                                                    style="width: 90%"
                                                    [ngClass]="{
                                                        'inputAlert': emailCur.inputError,
                                                        'inputNormal': !emailCur.inputError
                                                        }"
                                                    [value]="emailCur.value"    
                                                    [attr.maxlength]="emailAttr.emailLengthMax" 
                                                    (input)="clearError('copy',rowCopy, $event.target.value)"
                                                    (change)="formatField('copy',rowCopy, $event.target.value)"
                                                    formControlName="emailAddress"
                                                        >
                                            </mat-form-field> <!-- end emailAddress-->   
                                        </td>
                                        <td class="addressTable" style="width: 10%; padding: 0px; vertical-align: middle;">
                                            <div class="row" *ngIf="!isReadOnly" style="padding-bottom: 0px; margin-left: 0px; margin-right: 0px; vertical-align: middle;" >
                                                <button class="buttonFunction" mat-icon-button (click)="addEmail('copy',rowCopy)" >
                                                    <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                                                </button>
                                                <button class="buttonFunction" mat-icon-button (click)="removeEmail('copy',rowCopy)">
                                                    <mat-icon class="iconeFunction" >remove_circle_outline</mat-icon>
                                                </button>
                                            </div><!-- end butttons -->
                                        </td>    
                                    </tr>
                                </tbody>
                            </table>
                        </div> <!-- end butttons -->
                    </div>  <!-- end email loop -->   
                </div>  <!-- end copy block-->  
                <div #ccopyBlock formArrayName="ccopyFg" *ngIf="isCcopy" >
                    <div  class="row stdTheme" style="margin-left: 0px; margin-right: 0px;width: 60%" 
                                    *ngFor= "let emailCur of ccopyList; let rowCcopy=index; let isLast=last"  >
                        <div class="col" style="width: 100%;padding-right: 0px; padding-left: 0px" [formGroupName]="rowCcopy">
                            <table class="addressTable table table-bordered table-striped">
                                <tbody class="stdTheme" >
                                    <tr style="height: 100%">
                                        <td class="addressTable" style="width: 15%; padding: 2px; padding-left: 2px; padding-right: 2px;padding-top: 15px;">
                                            <span style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'ccopy' | translate }}</span> 
                                        </td>
                                        <td class="addressTable" style="width: 45%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                            <mat-form-field style="width: 95%;" >
                                                <mat-label style="width: 10%;text-align: right; margin-right: 5px;" class="stdTheme">{{'emailAddress' | translate }}</mat-label>
                                                <input matInput type="email" id="rowCcopy"
                                                    style="width: 90%"
                                                    [ngClass]="{
                                                        'inputAlert': emailCur.inputError,
                                                        'inputNormal': !emailCur.inputError
                                                        }"
                                                    [value]="emailCur.value"    
                                                    [attr.maxlength]="emailAttr.emailLengthMax" 
                                                    (input)="clearError('ccopy',rowCcopy, $event.target.value)"
                                                    (change)="formatField('ccopy',rowCcopy, $event.target.value)"
                                                    formControlName="emailAddress"
                                                        >
                                            </mat-form-field> <!-- end emailAddress-->   
                                        </td>
                                        <td class="addressTable" style="width: 10%; padding: 0px; vertical-align: middle;">
                                            <div class="row" *ngIf="!isReadOnly" style="padding-bottom: 0px; margin-left: 0px; margin-right: 0px; vertical-align: middle;" >
                                                <button class="buttonFunction" mat-icon-button (click)="addEmail('ccopy',rowCcopy)" >
                                                    <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                                                </button>
                                                <button class="buttonFunction" mat-icon-button (click)="removeEmail('ccopy',rowCcopy)">
                                                    <mat-icon class="iconeFunction" >remove_circle_outline</mat-icon>
                                                </button>
                                            </div><!-- end butttons -->
                                        </td>    
                                    </tr>
                                </tbody>
                            </table>
                        </div> <!-- end butttons -->
                    </div>  <!-- end email loop -->   
                </div>  <!-- end copy block-->  
                <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
                    <div class="col-12" style="padding-right: 2px; padding-left: 2px">
                        <mat-form-field class="mdiinput form-group" appearance="standard"  >
                            <mat-label>{{ 'subjectField' | translate }}</mat-label>
                            <input id="subjectField" matInput class="addressItem" formControlName="subjectField" 
                                (change)="formatField('subjectField', -1, bodyField.value)"
                                #subjectField placeholder="{{ 'subjectField' | translate }}"
                            >
                        </mat-form-field>
                    </div>
                </div>
                <!--
                <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
                    <div class="col-12" style="padding-right: 2px; padding-left: 2px">
                       
                        <mat-form-field  style="width: 100%">
                            <mat-label>{{ 'bodyField' | translate }}</mat-label>
                            <textarea id="docComment" class="form-control inputTheme" formControlName="bodyField" 
                                        matInput #bodyField placeholder="{{ 'bodyField' | translate }}" 
                                        (change)="formatField('bodyField', -1, bodyField.value)"
                                    cdkTextareaAutosize
                                    #autosizeComment="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="4"
                                    cdkAutosizeMaxRows="10"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            -->
                <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
                    <div class="col-12" style="padding-right: 2px; padding-left: 2px">
                        <mdi-text-editor [applicationCall]="applicationCall"  [textInit]="bodyField.initValue" (dataOut)="textBody($event)"></mdi-text-editor>
                    </div>
                </div>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="container-fluid d-flex justify-content-between" >
            <button mat-raised-button  #buttonSubmit type="reset" (click)="doClose()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'goback' | translate }}
            </button>
            <button *ngIf="isOkToValid && emailForm.valid" mat-raised-button  #buttonSubmit type="submit" (click)="sendEmail()" >
                <mat-icon>save</mat-icon>
                {{ 'sendEmail' | translate }}
            </button>
        </div>
    </mat-card-actions>
</mat-card>