export interface UserModel {
  id: number;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  sexe: string;
  birthday: Date;
  email: string;
  datafilter: string;
  authorities: any;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  entity: string;
  profile: string;
  usergroup: string;
  bearerToken: string;
  jti?: string;
  loglang: string;
  forbiddenWords: string;
  userdebug: boolean;
  userlang: string;
  extendedgrp: string;
  extendedentities: string;
  accessentities: string;
  accessgrps: string;
  otherData: string;
  authorizedFunctionsList: string;
  dataCategories: string;
  dataCategoriesTypeDefault: string;
  dataCategoriesLevelDefault: string;
  dataHabil: string;
  entityCur?: EntityModel;
  grpCur?: UsersGrpModel;
  entitycountry?: string;
  entitylang?: string;
  applications?: string;
  isMaster?: boolean;
}

export interface UserResponse {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  sexe: string;
  birthday: Date;
  email: string;
  entity: string;
  profile: string;
  usergroup: string;
  datafilter: string;
  roles: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  authorities: any;
  loglang: string;
  userdebug: boolean;
  userlang: string;
  extendedgrp: string;
  extendedentities: string;
  accessentities: string;
  accessgrps: string;
  otherdata: string;
  authorizedFunctionsList: string;
  dataCategories: string;
  dataCategoriesTypeDefault: string;
  dataCategoriesLevelDefault: string;
  dataHabil: string;
  entitycountry?: string;
  entitylang?: string;
  applications?: string;
}
export interface UserLoggedModel {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  entity: string;
  profile: string;
  usergroup: string;
  datafilter: string;
  authorities: string;
  entityCur?: EntityModel;
  grpCur?: UsersGrpModel;
  applications: string;
  isMaster?: boolean;
}

export class UserLogged {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  entity: string;
  profile: string;
  usergroup: string;
  datafilter: string;
  authorities: string;
  entityCur?: EntityModel;
  grpCur?: UsersGrpModel;
  applications: string;
  isMaster?: boolean;
}
export class UserCard {
  id?: number;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  birthday: string;
  sexe: string;
  email: string;
  entity: string;
  usergroup: string;
  userlang: string;
  roles: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  userdebug: boolean;
}

export interface MenuModel {
  id: number;
  itemLabel: string;
  itemParent: string;
  itemOrder: string;
  itemChild: string;
  itemStatut: string;
  itemRoles: string;
  itemRoutingPath: string;
  itemRoutingArgs: string;
  rolesAllowed: string;
  rolesProhibited: string;
  itemConstraints: string;
}

export interface EntityModel {
  entity: string;
  entitylabel: string;
  entitydesc: string;
  datafilter: string;
  supervisoremail: string;
  extendedaccess: string;
  master: boolean;
  tags: string;
  entitycountry: string;
  entitylang: string;
  otherDatas?: string;
  isMaster?: boolean;
}

export class EntityCard {
  entity: string;
  entitylabel: string;
  entitydesc: string;
  datafilter: string;
  supervisoremail: string;
  extendedaccess: string;
  master: boolean;
  tags: string;
  entitycountry: string;
  entitylang: string;
  otherDatas?: string;
}

export interface UsersGrpModel {
  entity: string;
  usergroup: string;
  usergrouplabel: string;
  roles: string;
  datafilter: string;
  supervisoremail: string;
  extendedaccess: string;
  authorizedfunctions: string;
  datahabil: string;
  tagsauthorized: string;
  tagsprohibited: string;
}

export class UsersGrpCard {
  entity: string;
  usergroup: string;
  usergrouplabel: string;
  roles: string;
  datafilter: string;
  supervisoremail: string;
  extendedaccess: string; 
  authorizedfunctions: string;
  datahabil: string;
  tagsauthorized: string;
  tagsprohibited: string;
}
export interface HabilData {
  dataCategory: string;
  dataLevel: string;
}
export const SUPPORTED_ROLES = [
  { display: 'USER', value: 'ROLE_USER' },
  { display: 'REGISTER', value: 'ROLE_REGISTER' },
  { display: 'DATA-MANAGER', value: 'ROLE_DATAMANAGER' },
  { display: 'EXPERT', value: 'ROLE_EXPERT' },
  { display: 'DESIGNER', value: 'ROLE_DESIGNER' },
  { display: 'SUPERVISOR', value: 'ROLE_SUPERVISOR' },
];

export const HIERARCHY_ROLES = [
    {
      rootlevel: {
        roleName: 'ROLE_ADMIN', responsabilitylevel: 'ALL',
        dependentlevel: [
          { roleName: 'ROLE_REGISTER' , responsabilitylevel: 'entitiesaccess' },
          { roleName: 'ROLE_DATAMANAGER' , responsabilitylevel: 'entitiesaccess',
            dependentlevel: [
              { roleName: 'ROLE_DESIGNER' , responsabilitylevel: 'entitiesaccess' },
              { roleName: 'ROLE_EXPERT', responsabilitylevel: 'entity',
                dependentlevel: [
                  { roleName: 'ROLE_SUPERVISOR' , responsabilitylevel: 'grpsaccess',
                    dependentlevel : [{ roleName: 'ROLE_USER' , responsabilitylevel: 'useraccess' },
                            ] // end supervisor dependentlevel
                  },
                ] // end expert dependentlevel
              }, // end expert
            ] // end data manager dependentlevel
          } // end data manager
        ] // end root dependentlevel
      }, // end rootlevel
    }
  ];

  export interface RoleDefModel {
    roleName: string;
    responsabilitylevel: string;
    dependentlevel: [RoleDefModel];
  }
  export interface HierarchicalRoles {
    rootlevel: RoleDefModel;
  }
export class RoleSupported {
  display: string;
  value: string;
}
export interface RoleModel {
  display: String;
  value: string;
}
export interface MaxDefaults {
  maxBdd: number;
  maxWebdav: number;
  maxSms: number;
  maxNotifs: number;
}
export class NotifMesUsers {
  usersMessage: string;
  dateSend: string;
  ID: string;
}
