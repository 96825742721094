import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray} from '@angular/forms';
import { EmailData, FieldInput  } from '../../../../models/data.model';
import { TranslateService } from '../../../../services/translate.service';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import { SendmailService } from '../../../common/services/sendmail.service';

@Component({
  selector: 'mdi-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit, OnChanges {

  @Input() applicationCall: string;
 /* @Input() from: string;
  @Input() recipients: string;
  @Input() copy: string;
  @Input() ccopy: string;
  @Input() subject: string;
  @Input() body: string;
  @Input() attachment: string;*/
  @Input() emailDataInput: EmailData;
  @Input() entity: string;
  @Output() dataOut = new EventEmitter<any>();

  emailForm: FormGroup;
  fromField:  FieldInput;
  recipientsList: FieldInput[];
  copyList: FieldInput[];
  ccopyList: FieldInput[];
  subjectField: FieldInput;
  bodyField: FieldInput;
  isReadOnly: boolean;
  emailAttr = environment.emailAttr;
  formIsBuild: boolean; 
  isCopy: boolean;
  isCcopy: boolean;
  buttonsCopyOK: boolean;
  isOkToValid: boolean;

  constructor( private translateService: TranslateService,
    private userService: UserService,
    private sendmailService: SendmailService,
    private fb: FormBuilder) { }
  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'applicationCall': {
            // tslint:disable-next-line:no-string-literal
            this.applicationCall = changes['applicationCall'].currentValue;
            break;
          }
          case 'entity': {
            // tslint:disable-next-line:no-string-literal
            this.entity = changes['entity'].currentValue;
            break;
          }
          case 'emailDataInput': {
            // tslint:disable-next-line:no-string-literal
            this.emailDataInput = changes['emailDataInput'].currentValue;
            break;
          }
        }
      }
    }
    this.initData();
  }
  initData() {
    this.formIsBuild = false;
    this.isReadOnly = false;
    this.isCcopy = false;
    this.isCcopy = false;
    this.buttonsCopyOK = true;
    this.isOkToValid = false;
    this.buildForm();
    this.fillForm();
    if (this.applicationCall === 'docShared') {
      this.buttonsCopyOK = false;
    }
  }

  buildForm(): void {

    const recipientsArray = this.fb.array([]);
    const copyArray = this.fb.array([]);
    const ccopyArray = this.fb.array([]);
    this.emailForm = new FormGroup({
      fromField: new FormControl({value: '', disabled: this.isReadOnly}),
      recipîentsFg: recipientsArray,
      copyFg: copyArray,
      ccopyFg: ccopyArray,
      subjectField:  new FormControl({value: '', disabled: this.isReadOnly}),
      // bodyField:  new FormControl({value: '', disabled: this.isReadOnly}),
    });
    this.formIsBuild = true;
  }
  buildItemEmail(val: any): FormGroup {
    if (val === '') {
      return new FormGroup ({
        emailAddress: new FormControl({value: '', disabled: this.isReadOnly}),
      });
    } else {
      return new FormGroup ({
        emailAddress: new FormControl({value: val, disabled: this.isReadOnly}),
      });
    }
  }
  clearError(typeRaw: string, iRow: number, value: string): void {
    if (value !== '') {
      return;
    }
    if (typeRaw === 'fromField') {
      this.emailForm.controls.fromField.setValue('');
      this.fromField = {
        value: '',
        inputError: false
      };
    }
    if (typeRaw === 'recipients') {
      this.recipientsList[iRow].value = '';
      this.recipientsList[iRow].inputError = false;
    }
    if (typeRaw === 'copy') {
      this.copyList[iRow].value = '';
      this.copyList[iRow].inputError = false;
    }
    if (typeRaw === 'ccopy') {
      this.ccopyList[iRow].value = '';
      this.ccopyList[iRow].inputError = false;
    }
  }
  formatField(typeRaw: string, iRow: number, value: string): void {
   
    if (typeRaw === 'bodyField') {
      if (value === '') {
        this.bodyField.inputError = true;
      }  
      this.ctrlValidForm();
      return;
    }
    if (typeRaw === 'subjectField') {
      if (value === '') {
        this.subjectField.inputError = true;
      }  
      this.ctrlValidForm();
      return;
    }
    const fieldLength = this.emailAttr.emailLengthMax;

    const pattern = new RegExp(this.emailAttr.emailRegex, 'i');
  
    const inputValue = value;
    let inputError = false;
    let valueReturn: string;

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.replace(pattern, this.emailAttr.emailDisplay);
    if (testMatch === null) {
      inputError = true;
    } else {
      inputError = false;
    }

    valueReturn = matchesGroup;
    
    if (typeRaw === 'fromField') {
      this.emailForm.controls.fromField.setValue(valueReturn);
      this.fromField = {
        value: valueReturn,
        inputError: inputError
      };
      this.ctrlValidForm();
    }
    if (typeRaw === 'recipients') {
      this.recipientsList[iRow].value = valueReturn;
      this.recipientsList[iRow].inputError = inputError;
      this.ctrlValidForm();
    }
    if (typeRaw === 'copy') {
      this.copyList[iRow].value = valueReturn;
      this.copyList[iRow].inputError = inputError;
      this.ctrlValidForm();
    }
    if (typeRaw === 'ccopy') {
      this.ccopyList[iRow].value = valueReturn;
      this.ccopyList[iRow].inputError = inputError;
      this.ctrlValidForm();
    }

  }
  ctrlValidForm() {
    this.isOkToValid = true;
    if (this.fromField.inputError) {
      this.isOkToValid = false;
      return;
    }
    if (this.bodyField.inputError) {
      this.isOkToValid = false;
      return;
    }
    if (this.subjectField.inputError) {
      this.isOkToValid = false;
      return;
    }
    let iRecipient = 0;    
    for (const fieldCur of this.recipientsList) {
      if (fieldCur.inputError) {
        this.isOkToValid = false;
        return;
      }
      if (fieldCur.value === '' && iRecipient === 0 ) {
        this.isOkToValid = false;
        return;
      }
      iRecipient++;
    }
    for (const fieldCur of this.copyList) {
      if (fieldCur.inputError) {
        this.isOkToValid = false;
        return;
      }
    }
    for (const fieldCur of this.ccopyList) {
      if (fieldCur.inputError) {
        this.isOkToValid = false;
        return;
      }
    }
  }
  onEvent(event: any) {
    event.stopPropagation();
  }
  textBody($event: any): void {
    this.bodyField.value = $event;
    this.bodyField.inputError = false;
    if (this.bodyField.value === '') {
      this.bodyField.inputError = true;
    } 
    this.ctrlValidForm();
  }
  clickCcopy() {
    this.isCcopy = !this.isCcopy;
  }
  clickCopy() {
    this.isCopy = !this.isCopy;
  }
  addEmail(typeRaw: string, index: number) {
    let arrayGrp;
    if (typeRaw === 'recipients') {   
      arrayGrp = this.emailForm.get('recipîentsFg') as FormArray;
      arrayGrp.push(this.buildItemEmail(''));
      const emailRow = {
        value: '',
        inputError: false,
      };
      this.recipientsList.push(emailRow);
    }
    if (typeRaw === 'copy') {
      arrayGrp = this.emailForm.get('copyFg') as FormArray;
      arrayGrp.push(this.buildItemEmail(''));
      const emailRow = {
        value: '',
        inputError: false,
      };
      this.copyList.push(emailRow);
    }
    if (typeRaw === 'ccopy') {
      arrayGrp = this.emailForm.get('ccopyFg') as FormArray;
      arrayGrp.push(this.buildItemEmail(''));
      const emailRow = {
        value: '',
        inputError: false,
      };
      this.ccopyList.push(emailRow);
    }
  }
  removeEmail(typeRaw: string, index: number) {
    if (typeRaw === 'recipients') {
      const arrayGrp = this.emailForm.get('recipîentsFg') as FormArray;
      if (arrayGrp.length === 0) {
        return;
      }
      if (arrayGrp.length < 1) {
        return;
      }
      arrayGrp.removeAt(index);
      this.recipientsList.splice(index, 1);
      if (arrayGrp.length === 0) {
        this.addEmail(typeRaw, -1);
      }
    }
    if (typeRaw === 'copy') {
      const arrayGrp = this.emailForm.get('copyFg') as FormArray;
      if (arrayGrp.length === 0) {
        return;
      }
      if (arrayGrp.length < 1) {
        return;
      }
      arrayGrp.removeAt(index);
      this.copyList.splice(index, 1);
      if (arrayGrp.length === 0) {
        this.addEmail(typeRaw, -1);
        this.isCopy = false;
      }
    }
    if (typeRaw === 'ccopy') {
      const arrayGrp = this.emailForm.get('ccopyFg') as FormArray;
      if (arrayGrp.length === 0) {
        return;
      }
      if (arrayGrp.length < 1) {
        return;
      }
      arrayGrp.removeAt(index);
      this.ccopyList.splice(index, 1);
      if (arrayGrp.length === 0) {
        this.addEmail(typeRaw, -1);
        this.isCcopy = false;
      }
    }
  }
  fillForm(): void {
    this.isOkToValid = true;
    if (this.emailDataInput.from) {
      this.fromField = {
        value: this.emailDataInput.from,
        inputError: false
      };
      this.emailForm.controls.fromField.setValue(this.fromField.value);
    } else {
      this.fromField = {
        value: '',
        inputError: false
      };
      this.emailForm.controls.fromField.setValue(this.fromField.value);
      this.isOkToValid = false;
    }
    if (this.emailDataInput.recipients) {
      const list =  this.emailDataInput.recipients.split(';');
      for (const currentValue of list) {
        const current = {
          value: currentValue,
          inputError: false
        };
        this.recipientsList.push(current);
      }
      this.emailForm.controls.recipientsList.setValue(this.recipientsList);
    } else {
      this.recipientsList = [];
      this.addEmail('recipients', 0);
      this.isOkToValid = false;
    }
    if (this.emailDataInput.copy) {
      const list =  this.emailDataInput.copy.split(';');
      for (const currentValue of list) {
        const current = {
          value: currentValue,
          inputError: false
        };
        this.copyList.push(current);
      }
      this.emailForm.controls.copyList.setValue(this.copyList);
    } else {
      this.copyList = [];
      this.addEmail('copy', 0);
      this.isOkToValid = false;
    }
    if (this.emailDataInput.ccopy) {

      const list =  this.emailDataInput.ccopy.split(';');
      for (const currentValue of list) {
        const current = {
          value: currentValue,
          inputError: false
        };
        this.ccopyList.push(current);
      }
      this.emailForm.controls.ccopyList.setValue(this.ccopyList);
    } else {
      this.ccopyList = [];
      this.addEmail('ccopy', 0);
      this.isOkToValid = false;
    }
    this.subjectField = {
      value: '',
      inputError: true,
    };
    if (this.emailDataInput.subject) {
      this.subjectField.value = this.emailDataInput.subject;
      this.subjectField.inputError = false;
      this.emailForm.controls.subjectField.setValue(this.subjectField.value);
    } else {
      this.isOkToValid = false;
    }
    this.bodyField = {
      value: '',
      inputError: true,
    };
    if (this.emailDataInput.body) {
      this.bodyField.value = this.emailDataInput.body;
      this.bodyField.initValue = this.emailDataInput.body;
      this.bodyField.inputError = false;
      // this.emailForm.controls.bodyField.setValue(this.bodyField.value);
    } else {
      this.isOkToValid = false;
    }
  }
  fillModel(): EmailData {
    // this.bodyField.value = this.emailForm.controls.bodyField.value;
    this.subjectField.value = this.emailForm.controls.subjectField.value;
    this.fromField.value = this.emailForm.controls.fromField.value;
    let recipientsCur = '';
    let copyCur = '';
    let ccopyCur = '';
    let idx = 0;
    for (const emailCur of this.recipientsList) {
      if (idx === 0) {
        recipientsCur = emailCur.value;
      } else {
        recipientsCur += ';' + emailCur.value;
      }
      idx++;
    }
    idx = 0;
    for (const emailCur of this.copyList) {
      if (idx === 0) {
        copyCur = emailCur.value;
      } else {
        copyCur += ';' + emailCur.value;
      }
      idx++;
    }
    idx = 0;
    for (const emailCur of this.ccopyList) {
      if (idx === 0) {
        ccopyCur = emailCur.value;
      } else {
        ccopyCur += ';' + emailCur.value;
      }
      idx++;
    }
    const emailData: EmailData = {
      from:  this.fromField.value,
      recipients: recipientsCur,
      copy: copyCur,
      ccopy: ccopyCur,
      subject: this.subjectField.value,
      body: this.bodyField.value,
      attachmentPath: this.emailDataInput.attachmentPath
    };
    return emailData;
  }
  doClose() {
    const closeModal = {
      close: true,
    };
    this.dataOut.emit(closeModal);
  }
  sendEmail() {
    const emailData = this.fillModel();

    this.sendmailService.sendMail(this.entity, emailData)
    .subscribe(
      () => {
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
      },
      (err) => {
        console.log('sendEmail ' + JSON.stringify(emailData) + ' KO ' + err );
      }
    );
      // console.log('saveEvent : ' + eventCur);
    this.doClose();
  }
}
