<div class="full-width-panel">
  <div ><b>{{zoomTitle}}</b></div>
    <!-- https://getbootstrap.com/docs/4.0/layout/grid/ -->
  <div class="full-width-details">
    <div>
      <h6 *ngIf="!isGrid">{{reportLabel}}</h6>
    </div>
    <div class="container-fluid" #detailZoom>

    </div>
    
    <div class ="w-100" *ngIf="isGrid">
      <mdi-report-grid [titleReport]="reportLabel" [statutCall]="'execute'" 
                    [reportToExecute]="reportToExecute" [subReportData]="subReportData" ></mdi-report-grid>
    </div>
  </div>
  <!--
  <div *ngIf="isGrid" class="full-width-grid">
    <ag-grid-angular
        #agGrid 
        style="width: 100%; height: 100%;"
        id="detailGrid"
        class="full-width-grid"
        [columnDefs]="colDefs"
        [rowData]="rowData"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
  -->
</div>
