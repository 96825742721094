import { Component, OnInit, ViewChild,  SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSidenav, MatTableDataSource } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { TranslateService } from '../../../../../../../services/translate.service';
import { UserService } from '../../../../../../../services/user.service';
import { WksCentralService } from '../../../../../../../job/job-wks/services/wks-central.service';
import { WksManufacturersService } from '../../../../../../../job/job-wks/services/wks-manufacturers.service';
import { RemoteFilesService } from '../../../../../../common/services/remote-files.service';
import { JobJson, SpecificationImportModel, SpecificationsEvent, SpecificationsJobs, SpecificationsModel, SpecjobsModel, specificationJob_sch } from '../../../../../../../job/job-wks/models/wks-manufacturers.model';

import { ModalCommonComponent } from '../../../../../../../job/common/components/modal-common/modal-common.component';
import { MessageDisplay } from '../../../../../../../models/common.model';
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';
import { IhmOptions } from '../../../../../../../job/job-wks/models/wks-param.model';

@Component({
  selector: 'mdi-specifications-table',
  templateUrl: './specifications-table.component.html',
  styleUrls: ['./specifications-table.component.css']
})
export class SpecificationsTableComponent implements OnChanges {

  @Input() specificationImport: SpecificationImportModel;
  @Input() rowImport: number;
  @Output() dataOut = new EventEmitter<SpecificationsEvent>();

  @ViewChild('sidenavSpec', {static: false}) sidenavSpec: MatSidenav;

  hasBackdrop: boolean;
  sidenavCollapsed: boolean;
  okToDisplay: boolean;
  isReadOnly: boolean;
  specificationCur: SpecificationsModel;
  timeFormat: string;
  dateFormat: string;
  specjobsList: SpecjobsModel[];
  specjobsUpdate: SpecjobsModel;
  idxUpdate: number;
  actionType: string;
  rowSpec: {
    isEven: boolean;
    isOdd: boolean;
    selected: boolean;
    isCollapse: boolean;
  }[];

  private readonly onDestroy = new Subject<void>();
  loadingIndicator: boolean;
  cacheDatatemp = [];
  ihmOptions: IhmOptions;
  dialogRef: MatDialogRef<ModalCommonComponent, any>;

  uuidCur: any;
  specBrand: string;
  cardIsOpen: boolean;

  columnSort: { 
    name: string;
    up: boolean;
    down: boolean;
    // cache: boolean;
  }[];

  constructor(private _userService: UserService,
    private _wksCentralService: WksCentralService,
    private _remoteFilesService: RemoteFilesService,
    private _wksManufacturersService: WksManufacturersService,
    private _dialog: MatDialog,
    private _translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'specificationImport': {
            this.specificationImport = changes['specificationImport'].currentValue as SpecificationImportModel;
            break;
          } // end case

        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {
    this.loadingIndicator = true;
    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this.specificationImport === undefined ) { return; }
    this.specificationCur = this.specificationImport.specificationsCur;
    this.specBrand = this.specificationCur.specBrand;
    this.actionType = undefined;
    this.initBooleans();
    this.initParams();
    this.settingIntFmts();
    this.subscribeAll(); 
    this.buildList();
  }
  initBooleans(): void {
    this.okToDisplay = false;
    this.isReadOnly = this.specificationImport.isReadOnly;
    this.ihmOptions =  { 
      readOnly: this.isReadOnly
    };
    this.hasBackdrop = true;
    this.sidenavCollapsed = true;
    this.loadingIndicator = true;
    this.cardIsOpen = false;
  }
  initParams(): void {
    this.initColmuns();
  }
  initColmuns(): void {
    const columnsList = ['jobPage', 'equipModel', 'jobLabel1', 'recordedDate', 'jobLevel'];
    this.columnSort = [];
    for (const colCur of columnsList) {
      const columnCur =  { 
        name: colCur,
        up: true,
        down: false,
        // cache: true
      };
      this.columnSort.push(columnCur);
    }
  }
  subscribeAll() {
  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    // const otherData = JSON.parse(wksEntity.otherData);

    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    return true;
  }
  toggleSortColumn(colIdx: number): void {
    /*if (this.columnSort[colIdx].cache) {
      this.columnSort[colIdx].cache = false;
      this.columnSort[colIdx].up = true;
      this.columnSort[colIdx].down = false;
      this.sortColumn(colIdx);
      return;
    } else 
    */if (this.columnSort[colIdx].up) {
      // this.columnSort[colIdx].cache = false;
      this.columnSort[colIdx].up = false;
      this.columnSort[colIdx].down = true;
      this.sortColumn(colIdx);
      return;
    } else if (this.columnSort[colIdx].down) {
      // this.columnSort[colIdx].cache = true;
      this.columnSort[colIdx].up = true;
      this.columnSort[colIdx].down = false;
      this.sortColumn(colIdx);
      return;
    }
  }
  sortColumn(colIdx: number): void {
    const colName = this.columnSort[colIdx].name;
    switch (colName) {
      case 'jobPage': {
        if (this.columnSort[colIdx].up) {
          this.specjobsList.sort((obj1, obj2) => {
            const c1 = (obj1.jobPage * 100) + obj1.jobNumber;
            const c2 = (obj2.jobPage * 100) + obj2.jobNumber;
            return c1 > c2 ? 1 : -1;
          });
        }
        if (this.columnSort[colIdx].down) {
          this.specjobsList.sort((obj1, obj2) => {
            const c1 = (obj1.jobPage * 100) + obj1.jobNumber;
            const c2 = (obj2.jobPage * 100) + obj2.jobNumber;
            return c1 > c2 ? -1 : 1;
          });
        }
        break;
      } // end case

    }  // end switch
  }
  toggleSidenavSpec(_arg: string): void {
   
    if (_arg === 'backdrop') {
      return;
    }
    
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.sidenavSpec.close();
        this.cardIsOpen = false;
      } catch (error) {
      } finally { }
    } else {
      try {
        this.uuidCur = uuidv4();
        this.sidenavSpec.open();
        this.cardIsOpen = true;
      } catch (error) {
      } finally { }
    }
  }
  buildList() {
    this.loadingIndicator = true;
    this.getImportsList();
  }
  refresh() {
    this.buildList();
  }
  getImportsList() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.loadingIndicator = true;
    this.specjobsList = [];
    this.rowSpec = [];
    const messageDisplay: MessageDisplay = {
      titleBox: 'readingImports',
      messageLabel: 'searching imports ',
      messageArgs: [],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'readingImports',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    const fileId = this.specificationCur.specFileid;
    this._wksManufacturersService.findSpecJobsByFileid(this._userService.getUserLogged().entity, fileId, undefined)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.closeMatDialog();
        const dataList = this.affectData(data.body);
        this.specjobsList = dataList;
        // this.extractColumnsName(this.dataSource.data);
        // this.settingLabels(this.dataSource.data);
        this.cacheDatatemp = [...this.specjobsList];
        this.loadingIndicator = false;
      },
      err => {
        // console.log(err);
        this.closeMatDialog();
        this.loadingIndicator = false;
        const messageDisplay1: MessageDisplay = {
          titleBox: 'searching imports',
          messageLabel: 'no import found ',
          messageArgs: [err.status],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'readingImports',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay1);
      }
    );
  }
  affectData(dataArray: SpecjobsModel[]): SpecjobsModel[] {
    let idxRow = 0;
    for (const itemCur of dataArray) {
      // itemCur.recordedDate = moment(itemCur.stdCreation).format(environment.fmtDateTimeTZ);
      if (itemCur.specOtherdata !== undefined && itemCur.specOtherdata !== null) { 
        itemCur.specJobOtherData = JSON.parse(itemCur.specOtherdata);
      } else { 
        itemCur.specJobOtherData = { 
          checked: false,
          jobLabels: undefined,
          taskrefTarget: undefined
        };
      } 
      if (itemCur.specJobOtherData.checked) {
        let specificationsJobs: SpecificationsJobs[] = [];
        if (itemCur.jobJson !== undefined 
          && itemCur.jobJson !== null 
          && itemCur.jobJson !== '') { 
          const jobJson = JSON.parse(itemCur.jobJson) as JobJson;
          if (jobJson.work) { 
            specificationsJobs = JSON.parse(jobJson.work) as SpecificationsJobs[];
            for (const jobCur of specificationsJobs ) { 
              if (jobCur.quantity === 0 ) { 
                itemCur.specJobOtherData.checked = false;
                break;
              }
            }
          } else { 
            itemCur.specJobOtherData.checked = false;
          }
        }
      }

      itemCur.recordedDate = CommonMethods.dateToString(itemCur.stdCreation, 'unixM', this.timeFormat);
      const rowCur = {
        isEven: (idxRow % 2 === 0 ? true : false),
        isOdd: (idxRow % 2 !== 0 ? true : false),
        selected: false,
        isCollapse: true,
      };
      this.rowSpec.push(rowCur);
      idxRow++;
    }
    dataArray.sort((obj1, obj2) => {
      const c1 = (obj1.jobPage * 100) + obj1.jobNumber;
      const c2 = (obj2.jobPage * 100) + obj2.jobNumber;
      return c1 > c2 ? 1 : -1;
    });
    return dataArray;
  }
  editSpecifications(row: SpecjobsModel, idxRow: number): void {
    this.specjobsUpdate = row;
    this.idxUpdate = idxRow;
    this.actionType = 'edit';
    this.toggleSidenavSpec('edit');
  }
  addSpecifications(): void {
    this.specjobsUpdate = undefined;
    this.actionType = 'add';
    this.idxUpdate = this.specjobsList.length;
    this.addSpecJobEmpty( this.idxUpdate - 1);
    this.toggleSidenavSpec('add');
  }

  addSpecJobEmpty(idxRowArg: number): void {

    const recordRef = this.specjobsList[idxRowArg];

    const newEmptyRecord: SpecjobsModel = {
      stdEntity: recordRef.stdEntity,
      specFileid: recordRef.specFileid,
      jobNumber: -1,
      jobLabel: '',
      jobPage: -1,
      jobNormal: false,
      jobMajor: false,
      equipBrand: recordRef.equipBrand,
      equipType: recordRef.equipType,
      equipModel: '',
      jobNotes: '',
      jobJson: undefined,
      specType: '',
      specFrequency: undefined,
      specHistory: '',
      taskrefId:  '',
      specOtherdata: JSON.stringify({ checked: false }),
      specJobOtherData: {
        checked: false,
        jobLabels: undefined,
        taskrefTarget: undefined
      }
    };
    this.specjobsList.push(newEmptyRecord);
    this.specjobsUpdate = newEmptyRecord;
    const idxRow = this.specjobsList.length - 1;
    const rowCur = {
      isEven: (idxRow % 2 === 0 ? true : false),
      isOdd: (idxRow % 2 !== 0 ? true : false),
      selected: false,
      isCollapse: true,
    };
    this.rowSpec.push(rowCur);
  }
  removeSpecifications(idxRow: number): void {
    const recordRef = this.specjobsList[idxRow];
    const messageDisplay: MessageDisplay = {
      titleBox: 'removeSpecification',
      messageLabel: 'remove specification',
      messageArgs: [recordRef.jobPage.toString(), recordRef.jobNumber.toString()],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'confirmAction',
      actionCur: 'removeSpecification',
      progressDeterminate: false,
      otherArgs: idxRow,
    };
    this.messageBoxDisplay(messageDisplay);
  }
  removeSpecificationsOK(idxRow: number): void {
    const recordRef = this.specjobsList[idxRow];
    let messageDisplay: MessageDisplay = {
      titleBox: 'removeSpecification',
      messageLabel: 'remove in progress',
      messageArgs: [],
      messageType: 'INFO',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'removeSpecification',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);

// ==========================
    this.register(recordRef)
    .then(
      (returnValue: any) => {


        this.buildList();
        this.closeMatDialog();
        messageDisplay = {
          titleBox: 'remove specification',
          messageLabel: 'remove OK',
          messageArgs: [],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'removeSpecificationOK',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
      },
      (error) => {

        console.log('removeSpecification KO  : ' + error);
        this.closeMatDialog();

        messageDisplay = {
          titleBox: 'remove specification',
          messageLabel: 'remove KO',
          messageArgs: [error.status],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'removeSpecificationKO',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay);
      } ); 
  }
  // register card
  
  register(recordRef: SpecjobsModel) {
    return new Promise((resolve, reject) => {
      this._wksManufacturersService.saveSpecjobs(recordRef, 'delete')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        specjobsData => {
          resolve(specjobsData.body);
        },
        (err) => {
          console.log ('error register specifications');
          reject(err.status);
        }
      );
    });
  }
// ====================
  toggleRow(idxRow: number): void {
    this.rowSpec[idxRow].isCollapse = !this.rowSpec[idxRow].isCollapse;
    if (!this.rowSpec[idxRow].isCollapse) {

    }
    return;
  }
  doCancelSpec(): void {
    this.toggleSidenavSpec('');
  }
  dataoutSpecificationsCard(event: SpecificationsEvent): void {
    this.specjobsUpdate = undefined;
    this.actionType = undefined;
    if (event.action === 'toggleSidenavSpec') {
      this.toggleSidenavSpec('');
      this.buildList();
    }
    if (event.action === 'refreshList') {
      this.buildList();
    }
  }
  doCancel(): void {
    const specificationsEvent: SpecificationsEvent = {
      objectCur: 'SpecificationsTable',
      action: 'toggleSidenav',
      rowIdx: this.rowImport
    };
    this.dataOut.emit(specificationsEvent);
  }
  // ========================================================== errors alerts 
  messageBoxDisplay(_messageDisplay: MessageDisplay) {

    const dialogConfig = new MatDialogConfig();
    const messageBox = this._translate.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate( _messageDisplay.titleBox),
      typeDialog: _messageDisplay.typeDialog,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: _messageDisplay.messageDatas,
      data2: '',
      actionCur: _messageDisplay.actionCur,
      progressDeterminate: _messageDisplay.progressDeterminate,
      errorsList: undefined,
      messageType: _messageDisplay.messageType
    };
    this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    
    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if ( _messageDisplay.actionCur === 'uploadOK') {
          this.endProcessFile();
        }
        if ( _messageDisplay.actionCur === 'uploadKO') {
          this.endProcessFile();
        }
        if (( data === 'okAction') && ( _messageDisplay.actionCur === 'removeSpecification')) {
          this.removeSpecificationsOK(_messageDisplay.otherArgs);
        }
      });

  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {
  
      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.dialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
}

