<!--
<mat-dialog-actions style="padding-top: 0px;margin: 0px;">
    <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
</mat-dialog-actions>
-->
<!--<mat-dialog-content style="padding-top: 0px;margin: 0px;height: 100%!important">-->
    <div class="container-fluid stdTheme " >
        <div class="flex-row row" style="padding-top: 0px;margin: 0px;">
            <div class="col-11"></div>
            <div class="col-1 d-flex justify-content-end" style="padding: 0px;" >
               
                <button mat-icon-button class="buttonTheme" (click)="close()">
                    <span class="fa fa-times fa-2xs" style="font-size: 20px" ></span>
                </button>   
            </div>
        </div>
        <div class="flex-row row" style="padding-top: 0px;margin: 0px;">
            <div *ngIf="workCur !== undefined" style="width: 100%!important; min-width: 100%!important;">  
                <mdi-works-card 
                [dataIn]="dataInWork" 
                [statutCall]="'popover'"  
                [actionType]="'display'"
                (onload)="ngOnInit()"></mdi-works-card>
            </div>
        </div>
    </div>
<!--</mat-dialog-content>-->
<!--    
<mat-dialog-actions style="padding-top: 0px;margin: 0px;">
        <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>
-->