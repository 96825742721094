import { MeasurementList } from './wks-common.model';
import { WksContactsModel } from './wks-business.model';
import { LabelValue } from '../../../models/data.model';

export interface WksEquipmentModel {
    id?: string;
    stdEntity: string;
    ownerId?: string;
    ownerName?: string;
    equipPartOf?: string;
    equipType?: string;
    equipDesign?: string;
    equipCountry?: string;
    equipImmat?: string;
    equipBrand?: string;
    equipModel?: string;
    equipYear?: string;
    equipName?: string;
    equipSN?: string;
    equipAddInfo?: string;
    equipHours?: number;
    equipHoursDate?: Date;
    equipValid?: boolean;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
    otherData?: any;
    equipQRcode?: string;
    equipBoatPropulsion?: string;
    equipBoatConfiguration?: string;
    equipBoatManeuvring?: boolean;
    equipBoatHomeport?: string;
    equipBoatAnchorage?: string;
    equipBoatWintering?: string;
    equipBoatBerthing?: string;
    equipBoatStorage?: string;
    equipJson?: string;
    thrusterOption?: boolean;
    motorType?: string;
    motorPosition?: string;
    commissioningDate?: Date;
    equipRevisions?: string;
    isWorkEquip?: boolean;
    isMainEquip?: boolean;
    isSubEquip?: boolean;
    doRemove?: boolean;
    equipNumber?: number;
    subEquipmentNew?: boolean;
    isSelected?: boolean;
    jsonTmpData?: string;
}
export interface EquipItemModel  {
    equipType?: string;
    equipBrand?: string;
    equipModel?: string;
    equipList?: LabelValue[];
}
export interface WksAnchoragesModel {
    id?: string;
    anchorageName: string;
    anchorageCountry?: string;
    anchorageCountryLabel?: string;
    anchorageState?: string;
    anchorageStateLabel?: string;
    anchorageCity?: string;
    anchorageAddress?: string;
    anchorageData?: string;
    anchoragePosition?: string;
    anchorageWintering?: boolean;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
    contacts?: WksContactsModel[];
    lineCur?: number;
}

export interface AnchorageEvent {
    searchCriteria: WksAnchoragesModel;
    searchResult: WksAnchoragesModel[];
}

export interface WksDocsModel {
    id?: string;
    stdEntity: string;
    ownerId?: string;
    equipType?: string;
    docBrand?: string;
    docModel?: string;
    docYear?: string;
    docVersion?: string;
    docLang?: string;
    docName: string;
    docStorage?: string;
    docPath?: string;
    docFilename: string;
    docTags?: string;
    docJson?: string;
    docComment?: string;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
    docRef?: string;
    docUrl?: string;
}

export interface EquipEvent {
    searchCriteria: WksEquipmentModel;
    searchResult: WksEquipmentModel[];
}
export interface DocEvent {
    searchCriteria: WksDocsModel;
    searchResult: WksDocsModel[];
}
export interface ParentParam {
    equipPartOf: string;
    equipType: string;
    ownerId: string;
    entity?: string;
    statutCall?: string;
    equipBoatPropulsion?: string;
    equipBoatConfiguration?: string;
    equipBoatManeuvring?: boolean;
    commissioningDate?: Date;
}
export interface PropulsionsModel {
    propulsionName: string;
    propulsionType: string;
}
export interface MotorsConfigModel {
    numberMotors: number;
    configName: string;
    positions: string[];
}
export interface ThrusterConfigModel {
    thrusterName: string;
}


export interface Hitch {
    name: string;
    sizes: MeasurementList[];
}
export interface TrailersOptions {
    hitches: Hitch[];
    tires: MeasurementList[];
    rims: MeasurementList[];
}

export interface RevisionModel {
    hours: number;
    months: number;
    taskrefId: string;
}

export const equipment_sch = [
    { colName: 'id', colType: 'number' },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'ownerId', colType: 'string' },
    { colName: 'ownerName', colType: 'string' },
    { colName: 'equipPartOf', colType: 'string' },
    { colName: 'equipType', colType: 'string' },
    { colName: 'equipDesign', colType: 'string' },
    { colName: 'equipCountry', colType: 'string' },
    { colName: 'equipImmat', colType: 'string' },
    { colName: 'equipBrand', colType: 'string' },
    { colName: 'equipModel', colType: 'string' },
    { colName: 'equipYear', colType: 'string' },
    { colName: 'equipName', colType: 'string' },
    { colName: 'equipSN', colType: 'string' },
    { colName: 'equipAddInfo', colType: 'string' },
    { colName: 'equipHours', colType: 'number' },
    { colName: 'equipHoursDate', colType: 'Date' },
    { colName: 'equipValid', colType: 'boolean' },
    { colName: 'stdCreation', colType: 'Date' },
    { colName: 'stdLastupdate', colType: 'Date' },
    { colName: 'stdDisable', colType: 'Date' },
    { colName: 'otherData', colType: 'string' },
    { colName: 'equipQRcode', colType: 'string' },
    { colName: 'equipBoatPropulsion', colType: 'string' },
    { colName: 'equipBoatConfiguration', colType: 'string' },
    { colName: 'equipBoatManeuvring', colType: 'boolean' },
    { colName: 'equipBoatHomeport', colType: 'string' },
    { colName: 'equipBoatAnchorage', colType: 'string' },
    { colName: 'equipBoatWintering', colType: 'string' },
    { colName: 'equipBoatBerthing', colType: 'string' },
    { colName: 'equipBoatStorage', colType: 'string' },
    { colName: 'equipJson', colType: 'string' },
    { colName: 'equipRevisions', colType: 'string' },
    { colName: 'commissioningDate', colType: 'Date' },
    { colName: 'jsonTmpData', colType: 'Date' },
    ];
export const docs_sch = [
    { colName: 'id', colType: 'number' },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'ownerId', colType: 'string' },
    { colName: 'equipType', colType: 'string' },
    { colName: 'docBrand', colType: 'string' },
    { colName: 'docModel', colType: 'string' },
    { colName: 'docYear', colType: 'string' },
    { colName: 'docVersion', colType: 'string' },
    { colName: 'docLang', colType: 'string' },
    { colName: 'docName', colType: 'string' },
    { colName: 'docStorage', colType: 'string' },
    { colName: 'docPath', colType: 'string' },
    { colName: 'docFilename', colType: 'string' },
    { colName: 'docTags', colType: 'string' },
    { colName: 'docJson', colType: 'string' },
    { colName: 'docComment', colType: 'string' },
    { colName: 'stdCreation', colType: 'Date' },
    { colName: 'stdLastupdate', colType: 'Date' },
    { colName: 'stdDisable', colType: 'Date' },
    { colName: 'docRef', colType: 'string' },
    ];

export const anchorages_sch = [
    { colName: 'id', colType: 'number' },
    { colName: 'anchorageName', colType: 'string' },
    { colName: 'anchorageCountry', colType: 'string' },
    { colName: 'anchorageState', colType: 'string' },
    { colName: 'anchorageCountryLabel', colType: 'string' },
    { colName: 'anchorageStateLabel', colType: 'string' },
    { colName: 'anchorageCity', colType: 'string' },
    { colName: 'anchorageAddress', colType: 'string' },
    { colName: 'anchorageData', colType: 'string' },
    { colName: 'anchoragePosition', colType: 'string' },
    { colName: 'anchorageWintering', colType: 'boolean' },
    { colName: 'lineCur', colType: 'string' },
    { colName: 'contacts', colType: 'string' },
    { colName: 'stdCreation', colType: 'Date' },
    { colName: 'stdLastupdate', colType: 'Date' },
    { colName: 'stdDisable', colType: 'Date' },
];
