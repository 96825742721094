import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { WksCentralService } from './wks-central.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./wks-central.service";
export class WksManufacturersService {
    constructor(httpClient, _wksCentralService) {
        this.httpClient = httpClient;
        this._wksCentralService = _wksCentralService;
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    saveSpecifications(specificationCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/saveSpecifications/?` + 'action=' + actionType;
        const body = JSON.stringify(specificationCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    processSpecifications(specificationCard, actionType, historyRow) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/processSpecifications/?` + 'action=' + actionType + '&historyRow=' + historyRow;
        const body = JSON.stringify(specificationCard);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    findAllSpecifications() {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/findAllSpecifications/`;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    findSpecificationsByFilename(stdEntity, specBrand, specFileType, specFileName) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecificationsByFilename/?` + 'stdEntity=' + stdEntity
            + '&specBrand=' + specBrand + '&specFileType=' + specFileType + '&specFileName=' + specFileName;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    findSpecificationsBySpecBrand(stdEntity, specBrand, specFileType, specFileName) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecificationsBySpecBrand/?` + 'stdEntity=' + stdEntity
            + '&specBrand=' + specBrand + '&specFileType=' + specFileType + '&specFileName=' + specFileName;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    saveSpecjobs(specjobsCard, actionType) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/saveSpecjobs/?` + 'action=' + actionType;
        const body = JSON.stringify(specjobsCard);
        return this.httpClient
            .put(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    findAllSpecjobs() {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/findAllSpecjobs/`;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    findSpecJobsByFileid(stdEntity, specFileId, jobNumber) {
        const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecJobsByFileid/?` + 'stdEntity=' + stdEntity
            + '&specFileId=' + specFileId + '&jobNumber=' + jobNumber;
        return this.httpClient.post(urlCur, undefined, {
            headers: this.httpHeaders,
            observe: 'response'
        })
            .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
    downloadDoc(_path, _mimeApp) {
        _path = CommonMethods.cleanURLString(_path);
        const urlCur = `${environment.baseUrl}/wks/manufacturers/download/?` + 'path=' + _path + '&type=' + _mimeApp;
        const localHttpHeaders = this.httpHeaders;
        localHttpHeaders.set('Accept', _mimeApp);
        return this.httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    saveToFileSystem(_data, _filename, _mimeApp) {
        const blob = new Blob([_data], { type: _mimeApp });
        saveAs(blob, _filename);
    }
    loadWorksType(entity, userlang) {
        return new Promise((resolve, reject) => {
            this._wksCentralService.getWksWorkRatesList(entity, userlang)
                .subscribe(data => {
                const dataReturn = data.body;
                resolve(dataReturn);
            }, err => {
                console.log(err);
                reject(err.status);
            });
        });
    }
}
WksManufacturersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WksManufacturersService_Factory() { return new WksManufacturersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.WksCentralService)); }, token: WksManufacturersService, providedIn: "root" });
