/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-grid.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../../services/translate.pipe";
import * as i6 from "../../../../../../services/translate.service";
import * as i7 from "@angular/material/form-field";
import * as i8 from "@angular/material/input";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/material/core";
import * as i12 from "@angular/cdk/text-field";
import * as i13 from "@angular/common";
import * as i14 from "../../../../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i15 from "ag-grid-angular";
import * as i16 from "../../display-pdf/display-pdf.component.ngfactory";
import * as i17 from "../../display-pdf/display-pdf.component";
import * as i18 from "../../../../services/job-data.service";
import * as i19 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i20 from "@angular/material/button";
import * as i21 from "@angular/cdk/a11y";
import * as i22 from "./report-grid.component";
import * as i23 from "../../../../../../services/user.service";
import * as i24 from "@angular/router";
import * as i25 from "ngx-bootstrap/modal";
import * as i26 from "@angular/platform-browser";
var styles_ReportGridComponent = [i0.styles];
var RenderType_ReportGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ReportGridComponent, data: {} });
export { RenderType_ReportGridComponent as RenderType_ReportGridComponent };
function View_ReportGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "stdTheme mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "stdTheme"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LoadingReportConfig")); _ck(_v, 5, 0, currVal_4); }); }
function View_ReportGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "stdTheme mat-progress-bar"], ["mode", "query"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "stdTheme"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var currVal_3 = "query"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LoadingReportData")); _ck(_v, 5, 0, currVal_4); }); }
function View_ReportGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "stdTheme mat-progress-bar"], ["mode", "query"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "stdTheme"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var currVal_3 = "query"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("settingReportFilters")); _ck(_v, 5, 0, currVal_4); }); }
function View_ReportGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "stdTheme mat-progress-bar"], ["mode", "query"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "stdTheme"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var currVal_3 = "query"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("settingReportPinnedRows")); _ck(_v, 5, 0, currVal_4); }); }
function View_ReportGridComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleReport; _ck(_v, 1, 0, currVal_0); }); }
function View_ReportGridComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleReport; _ck(_v, 1, 0, currVal_0); }); }
function View_ReportGridComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "flex-row row"], ["style", "margin-left: 5em; padding-left: 5em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["inputSearch", 1]], null, 3, "input", [["class", "mat-input-element mat-form-field-autofill-control"], ["id", "filter-text-box"], ["matInput", ""], ["style", "width: 100%"], ["type", "text"]], [[2, "mat-input-server", null], [1, "id", 0], [1, "placeholder", 0], [8, "disabled", 0], [8, "required", 0], [1, "readonly", 0], [1, "aria-describedby", 0], [1, "aria-invalid", 0], [1, "aria-required", 0]], [[null, "keyup"], [null, "blur"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._focusChanged(false) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._focusChanged(true) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._onInput() !== false);
        ad = (pd_2 && ad);
    } if (("keyup" === en)) {
        var pd_3 = (_co.onFilterTextBoxChanged(i1.ɵnov(_v, 1).value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i7.MatFormFieldControl, null, [i8.MatInput]), i1.ɵdid(3, 999424, null, 0, i8.MatInput, [i1.ElementRef, i9.Platform, [8, null], [2, i10.NgForm], [2, i10.FormGroupDirective], i11.ErrorStateMatcher, [8, null], i12.AutofillMonitor, i1.NgZone], { id: [0, "id"], placeholder: [1, "placeholder"], type: [2, "type"] }, null), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var currVal_9 = "filter-text-box"; var currVal_10 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform("global filter")), ""); var currVal_11 = "text"; _ck(_v, 3, 0, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._isServer; var currVal_1 = i1.ɵnov(_v, 3).id; var currVal_2 = i1.ɵnov(_v, 3).placeholder; var currVal_3 = i1.ɵnov(_v, 3).disabled; var currVal_4 = i1.ɵnov(_v, 3).required; var currVal_5 = ((i1.ɵnov(_v, 3).readonly && !i1.ɵnov(_v, 3)._isNativeSelect) || null); var currVal_6 = (i1.ɵnov(_v, 3)._ariaDescribedby || null); var currVal_7 = i1.ɵnov(_v, 3).errorState; var currVal_8 = i1.ɵnov(_v, 3).required.toString(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_ReportGridComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_6)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_7)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_8)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "flex-row row"], ["style", "height: 90%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-balham"], ["style", "width: 100%; height: 100%;"]], null, [[null, "filterModified"], [null, "rowClicked"], [null, "cellClicked"], [null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterModified" === en)) {
        var pd_0 = (_co.onFilterChanged() !== false);
        ad = (pd_0 && ad);
    } if (("rowClicked" === en)) {
        var pd_1 = (_co.onRowClicked($event) !== false);
        ad = (pd_1 && ad);
    } if (("cellClicked" === en)) {
        var pd_2 = (_co.onCellClicked($event) !== false);
        ad = (pd_2 && ad);
    } if (("gridReady" === en)) {
        var pd_3 = (_co.onGridReady($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i14.View_AgGridAngular_0, i14.RenderType_AgGridAngular)), i1.ɵprd(512, null, i15.AngularFrameworkOverrides, i15.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i15.AngularFrameworkComponentWrapper, i15.AngularFrameworkComponentWrapper, []), i1.ɵdid(12, 4898816, [["agGrid", 4]], 1, i15.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i15.AngularFrameworkOverrides, i15.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], frameworkComponents: [3, "frameworkComponents"], localeText: [4, "localeText"], getRowHeight: [5, "getRowHeight"], isFullWidthCell: [6, "isFullWidthCell"], fullWidthCellRenderer: [7, "fullWidthCellRenderer"], suppressAutoSize: [8, "suppressAutoSize"] }, { cellClicked: "cellClicked", filterModified: "filterModified", rowClicked: "rowClicked", gridReady: "gridReady" }), i1.ɵqud(603979776, 2, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSubReport; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isSubReport; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.isSubReport; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.gridOptions; var currVal_4 = _co.dataSource.data; var currVal_5 = _co.colDef; var currVal_6 = _co.frameworkComponents; var currVal_7 = _co.localeText; var currVal_8 = _co.getRowHeight; var currVal_9 = _co.isFullWidthCell; var currVal_10 = _co.fullWidthCellRenderer; var currVal_11 = false; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
function View_ReportGridComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mdi-display-pdf", [], null, [[null, "onload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onload" === en)) {
        var pd_0 = (_co.ngOnInit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DisplayPdfComponent_0, i16.RenderType_DisplayPdfComponent)), i1.ɵdid(2, 114688, null, 0, i17.DisplayPdfComponent, [i18.JobDataService], { statutCall: [0, "statutCall"], titleReport: [1, "titleReport"], dataRecord: [2, "dataRecord"], reportOrig: [3, "reportOrig"], reportNameToExecute: [4, "reportNameToExecute"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-footer "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-fluid d-flex justify-content-between"], ["style", "margin:0rem; padding: 0rem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-primary buttonTheme"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_MatButton_0, i19.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i20.MatButton, [i1.ElementRef, i21.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "subReportPdfViewer"; var currVal_1 = _co.modalText[0]; var currVal_2 = _co.currentNode; var currVal_3 = _co.reportToExecute; var currVal_4 = _co.reportPdf; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = (i1.ɵnov(_v, 6).disabled || null); var currVal_6 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("Close")); _ck(_v, 7, 0, currVal_7); }); }
export function View_ReportGridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid w-100 stdTheme"], ["style", "width: 100%; height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_1)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_2)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_3)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_4)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportGridComponent_5)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["modalViewer", 2]], null, 0, null, View_ReportGridComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.configLoaded; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.configLoaded && !_co.dataLoaded); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.dataLoaded && !_co.filtersSetting); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.filtersSetting && !_co.pinnedRowsSetting); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.configLoaded; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ReportGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-report-grid", [], null, null, null, View_ReportGridComponent_0, RenderType_ReportGridComponent)), i1.ɵdid(1, 638976, null, 0, i22.ReportGridComponent, [i23.UserService, i24.Router, i6.TranslateService, i18.JobDataService, i25.BsModalService, i26.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportGridComponentNgFactory = i1.ɵccf("mdi-report-grid", i22.ReportGridComponent, View_ReportGridComponent_Host_0, { statutCall: "statutCall", reportToExecute: "reportToExecute", titleReport: "titleReport", subReportData: "subReportData" }, {}, []);
export { ReportGridComponentNgFactory as ReportGridComponentNgFactory };
