import * as moment from 'moment';
import { CommonMethods } from './commonMethods';
import { ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '../../../services/translate.service';

export interface KeyValue {
    key: string;
    value: any;
}

export class GridTools {

    public static dateRenderer_unixM = function(params: any) {
        let timeCur: string;
        if (params.value === null) { 
            timeCur = ''; 
        } else if (!params.value) {
            timeCur = ''; 
        } else if (params.value) {
            timeCur = CommonMethods.dateToString(params.value, 'unixM', params.array[0]); 
        }
        return '<span >' + timeCur + '</span>';
    };
    public static dateRenderer_json = function(params: any) {
        let timeCur: string;
        if (params.value === null) { 
            timeCur = ''; 
        } else if (!params.value) {
            timeCur = ''; 
        } else if (params.value) {
            timeCur = CommonMethods.dateToString(params.value, 'json', params.array[0]); 
        }
        return '<span >' + timeCur + '</span>';
    };
    public static date_unix = function(value: string, format: string ) {
        let timeCur: string;
        if (value === null) { 
            timeCur = '';  
        } else if (!value) {
            timeCur = ''; 
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'unixM', format); 
        }
        return timeCur;
    };
    public static date_json = function(value: string, format: string ) {
        let timeCur: string;
        
        if (value === null) { 
            timeCur = ''; 
        } else if (!value) {
            timeCur = ''; 
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'json', format); 
        }
        return timeCur;
    };
    public static date_json1 = function(value: string, format: string ) {
        let timeCur: string;
        if (value === null) { 
            timeCur = ''; 
        } else if (!value) {
            timeCur = ''; 
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'json1', format); 
        }
        return timeCur;
    };
    public static date_to_string = function(value: string, format: string ) {
        let timeCur: string;
        if (value === null) { 
            timeCur = ''; 
        } else if (!value) {
            timeCur = ''; 
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'date', format); 
        }
        return timeCur;
    };
    public static hour_to_string = function(value: string, format: string ) {
        let timeCur: string;
        if (value === null) { 
            timeCur = ''; 
        } else if (!value) {
            timeCur = ''; 
        } else if (value) {
            timeCur = CommonMethods.dateToString(value, 'hour', format); 
        }
        return timeCur;
    };
    // JLG - 2019-05-31 :  NON OK (ne liste que 5 des 78 colonnes du test) : cause inconnue
    // https://www.ag-grid.com/javascript-grid-cell-rendering/
    public static cellRendererSelector = function (params: any) {
        if (params === null) { return; }
        const dataType = params.colDef.cellRendererParams.colFmtName;
        const numericData = {
            component: 'numericRendererComponent'
        };
        const alphanumData = {
            component: 'alphanumRendererComponent'
        };
        const dateData = {
            component: 'datetimeRendererComponent'
        };


        switch (dataType) {
            case 'numeric': {
                return numericData;
                break;
            }
            case 'date': {
                return dateData;
                break;
            }
            default : {
                return alphanumData;
                break;
            }
        }
        // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
        /*
        if (params.colDef.cellRendererParams.colFmtName === 'numeric')
            return numericData;
        else
            return alphanumData;
        */
    };
    public static cellRendererDetailSelector = function (params: any) {
        const dataType = params.colDef.cellRendererParams.colFmtName;
        const subGrid = {
            component: 'gridDetailRendererComponent'
        };

        const detailData = {
            component: 'dataDetailRendererComponent'
        };
        switch (dataType) {
            case 'subGrid': {
                return subGrid;
                break;
            }
            case 'detailData': {
                return detailData;
                break;
            }
            default : {
                return subGrid;
                break;
            }
        }

    };
    // Bootstrap Grid
    public static createBS_Row(_renderer: Renderer2, _classList: string[]): any {
        const divRow = _renderer.createElement('div');
        if (_classList !== undefined) {
            for (const classCur of _classList) {
            _renderer.addClass(divRow, classCur);
            }
        }
        return divRow;
    }
    public static createElementBR(_renderer: Renderer2): any {
        const brEl = _renderer.createElement('br');
        return brEl;
    }
    public static createBS_Text(_renderer: Renderer2, _classList: string[], _text: string, _listStyle: KeyValue[]): any {
        const divText = _renderer.createElement('div');
        const textCol = _renderer.createText(_text);
        if (_classList !== undefined) {
            for (const classCur of _classList) {
                _renderer.addClass(divText, classCur);
            }
        }
        if (_listStyle !== undefined) {
            for (const styleCur of _listStyle) {
                _renderer.setStyle(divText, styleCur.key, styleCur.value);
            }
        }
        _renderer.appendChild(divText, textCol);
        return divText;
    }
    public static createColText(_renderer: Renderer2, _classList: string[], _text: string): any {
        const divCol = _renderer.createElement('div');
        if (_classList !== undefined) {
          for (const classCur of _classList) {
            _renderer.addClass(divCol, classCur);
          }
    
        }
        if (_text !== undefined) {
        const textCol = _renderer.createText(_text);
        _renderer.appendChild(divCol, textCol);
        }
       return divCol;
    }
    public static createBS_LabelValue(_renderer: Renderer2, _classList: string [], _text: any []): any {
        const divCol = _renderer.createElement('div');
        if (_classList !== undefined) {
          for (const classCur of _classList) {
            _renderer.addClass(divCol, classCur);
          }
        }
        for (const textCur of _text) {
            _renderer.appendChild(divCol, textCur);
        }
       return divCol;
    }
    public static createBS_Col(_renderer: Renderer2, _classList: string[], _element: any): any {
        const divCol = _renderer.createElement('div');
        if (_classList !== undefined) {
          for (const classCur of _classList) {
            _renderer.addClass(divCol, classCur);
          }
    
        }
        if (_element !== undefined) {
            _renderer.appendChild(divCol, _element);
        }
       return divCol;
    }
}
