import * as i0 from "@angular/core";
// import ALL_COUNTRIES from '../../../../assets/data/countries_ref.json';
/*
const INIT_DATA_INT = <ShareDataInt>{
  isAdministration: false,
  isCompany: false,
  isPerson: false,
  isProfessionnal: false,
};
*/
export class RessourcesService {
    constructor() {
        // https://makina-corpus.com/blog/metier/2017/premiers-pas-avec-rxjs-dans-angular
        // protected shareDataInt: BehaviorSubject<ShareDataInt> = new BehaviorSubject<ShareDataInt>(INIT_DATA_INT);
        this.civilityList = ['mrs', 'miss', 'mr'];
        /*this.countriesList = [];
        Object.keys(ALL_COUNTRIES).map(key => {
          const locCountry = ALL_COUNTRIES[key] as CountryDef;
          this.countriesList.push({
            isoAlpha2: locCountry.isoAlpha2,
            commonName: locCountry.commonName,
          });
        });*/
    }
    setCountryFmt(countryFmt) {
        this.countryFmt = countryFmt;
    }
    getCountryFmt() {
        return this.countryFmt;
    }
    setWksEntityParams(wksEntityParams) {
        this.wksEntityParams = wksEntityParams;
    }
    getWksEntityParams() {
        return this.wksEntityParams;
    }
    setPhonesType(phonesTypes) {
        this.phonesTypes = phonesTypes;
    }
    setNotifApp(notificationsApp) {
        this.notificationsApp = notificationsApp;
    }
    getPhonesType() {
        return this.phonesTypes;
    }
    getNotifApp() {
        return this.notificationsApp;
    }
    setCountries(_countries) {
        this.allCountriesStr = _countries;
        this.allCountriesJson = JSON.parse(this.allCountriesStr);
        this.countriesList = [];
        Object.keys(this.allCountriesJson).map(key => {
            const locCountry = this.allCountriesJson[key];
            this.countriesList.push({
                isoAlpha2: locCountry.isoAlpha2,
                commonName: locCountry.commonName,
            });
        });
    }
    setCurrencies(currencies) {
        this.allCurrencies = currencies;
    }
    getCurrencies() {
        return this.allCurrencies;
    }
    setIntFormats(_intFormats) {
        this.intFormats = _intFormats;
        // this.getFormat('EN', 'numbers', 'currency');
    }
    getListIntFormat() {
        // const arr = [];
        // this.intFormats.forEach((value, key) => arr.push({ key, value }));
        // this.intFormats.forEach((value, key) => arr.push(key));
        /* const arr = Array.from(this.intFormats, ([key, value]) => {
          return key;
        });
        */
        /*
         Object.keys(this.intFormats).map(key => {
           console.log(JSON.stringify(key));
         });
       */
        // const arrayFmt = [...this.intFormats];
        const arr = [];
        /*
        for ( const fmtCur of arrayFmt) {
          console.log(JSON.stringify(fmtCur));
          console.log(JSON.parse(JSON.stringify(fmtCur)));
        }
        */
        const fmtJson = JSON.parse(JSON.stringify(this.intFormats));
        for (const key in fmtJson) {
            if (fmtJson.hasOwnProperty(key)) {
                // console.log(key + ' = ' + fmtJson[key]);
                arr.push(key);
            }
        }
        return arr;
    }
    getIntFormat(_keyFmt) {
        /*
        Object.keys(this.intFormats).map(key => {
          const locCountry = this.intFormats[key] as InternationalsFormats;
          console.log(key, locCountry);
        });
        */
        const intFormat = this.intFormats[_keyFmt];
        return intFormat;
    }
    getFormat(_keyFmt, _fmtType, _fmtName) {
        const intFormat = this.intFormats[_keyFmt];
        let fmtReturn;
        let namesValues;
        switch (_fmtType) {
            case 'datetime':
                namesValues = intFormat.datetime;
                break;
            case 'numbers':
                namesValues = intFormat.numbers;
                break;
            case 'separators':
                namesValues = intFormat.separators;
                break;
            case 'justifs':
                namesValues = intFormat.justifs;
                break;
            default:
                return null;
        }
        if ((namesValues === null) || (namesValues === undefined) || (namesValues.length === 0)) {
            return null;
        }
        for (const nameValue of namesValues) {
            if (nameValue.name === _fmtName) {
                fmtReturn = nameValue.value;
                break;
            }
        }
        // console.log(fmtReturn);
        return fmtReturn;
    }
    getDeptsList() {
        return ['purchase department', 'juridical department', 'financial department', 'technical department'];
    }
    getCivilityList() {
        return this.civilityList;
    }
    getCountry(countryKey) {
        const locCountry = this.allCountriesJson[countryKey];
        // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[countryKey])) as CountryDef;
        return locCountry;
    }
    getTimezones(countryKey) {
        const locCountry = this.allCountriesJson[countryKey];
        let timeZones;
        if (locCountry.timezones !== undefined) {
            timeZones = locCountry.timezones;
        }
        else {
            timeZones = [];
        }
        return timeZones;
    }
    getCountriesList() {
        return this.countriesList;
    }
    readAllData() {
        /*
            for (const key in ALL_COUNTRIES) {
              // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[key])) as CountryDef;
              const locCountry = ALL_COUNTRIES[key] as CountryDef;
              //if (key === 'FR' ) {
                console.log('jsonCountry : ' + key + ' ==> ' + locCountry.commonName);
              //}
            }
        */
        /*
            Object.keys(ALL_COUNTRIES).map(key => {
              // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[key])) as CountryDef;
              const locCountry = ALL_COUNTRIES[key] as CountryDef;
              // if (key === 'FR' ) {
              console.log('jsonCountry : ' + key + ' ==> ' + locCountry.commonName);
              // }
            });
        */
    }
}
RessourcesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RessourcesService_Factory() { return new RessourcesService(); }, token: RessourcesService, providedIn: "root" });
