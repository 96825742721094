import { NgZone } from '@angular/core';
import { EventSourcePolyfill } from 'ng-event-source';
import { Observable } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/user.service";
export class NotificationsService {
    constructor(_userService, _zone) {
        this._userService = _userService;
        this._zone = _zone;
    }
    // https://stackoverflow.com/questions/40987560/angular-2-spring-boot-server-side-events
    // https://blog.icod.de/2018/12/17/angular-eventsource-go-and-wasted-lifetime/
    /*
      connectStream(): void {
        const authorizationToken = this._userService.getAccessToken();
        const eventSourceInitDict = {
            headers: {
                'Authorization': 'Bearer ' + authorizationToken
            }
          };
        const urlCur = `${environment.baseUrl}/stream/`;
    
        const eventSource  = new EventSourcePolyfill(urlCur, eventSourceInitDict);
        eventSource.onmessage = (data => {
          this._zone.run(() => {
     
            const msgData: NotifUsers = JSON.parse(data.data);
            console.log('message onmessage ' + msgData.recipients, ' ' + msgData.contentMessage);
              // Do stuff here
          });
        });
        eventSource.onopen = (a) => {
          console.log('message onopen');
            // Do stuff here
        };
        eventSource.onerror = (e) => {
          console.log('message onerror');
            // Do stuff here
        }
    
      }
      */
    /*
    source.addEventListener('message', (message: any) => {
        let n: Notification; //need to have this Notification model class in angular2
        n = JSON.parse(message.data);
        console.log(message.data);
    });
    */
    /*}*/
    connectNotif(streamName) {
        const authorizationToken = this._userService.getAccessToken();
        // https://github.com/Yaffle/EventSource/issues/193 : 10 minutes
        // stop reconnecting every 45 seconds : 3 minutes  : 'heartbeatTimeout': 180000
        const eventSourceInitDict = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': 'Bearer ' + authorizationToken
            },
            polyfill: true,
            polyfillOptions: {
                heartbeatTimeout: 18000000,
            },
            forcePolyfill: true,
        };
        const urlCur = `${environment.baseUrl}/stream/`;
        return new Observable((observer) => {
            const eventSource = new EventSourcePolyfill(urlCur, eventSourceInitDict);
            eventSource.onmessage = (data => observer.next(data.data));
            eventSource.onopen = (a) => {
                // console.log('connectNotif Open');
            };
            eventSource.onerror = (e) => {
                // console.log('message onerror ' + JSON.stringify(e));
                // Do stuff here
            };
        });
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i0.NgZone)); }, token: NotificationsService, providedIn: "root" });
