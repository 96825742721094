<div id="home-content" #refElement class="mat-elevation-z8 container-fluid stdTheme" style="width: 50%; margin-top: 10px" >
  <mat-card class="register-card stdTheme" layout="column" flex>
   <!-- <mat-card-header>
      <mat-card-title  >
        <span #titleCard>{{ registerTitle | translate }}</span>
      </mat-card-title>
    </mat-card-header> -->
    <mat-card-actions>
      <div id="registration-error" class="alert alert-danger stdTheme" *ngIf="registrationFailed">
        <button type="button" class="close" aria-label="Close" (click)="registrationFailed = false;">
          <!-- <span aria-hidden="true">&#215;</span> -->
        </button>
        {{ messageTranslate }}
      </div>

    </mat-card-actions>
    <form  [formGroup]="requestForm"  >
      <mat-card-content class="stdTheme" >
        <div class="flex-row row">
          <div class="col stdTheme" style="margin-left: 0em; padding-left: 0em">
            <mat-form-field class="mdiinput form-group" appearance="standard">
              <mat-label>{{ 'requestLabel' | translate }}</mat-label>
              <input id="requestLabel" class="form-control" formControlName="requestLabel" matInput #input minlength="3" placeholder="{{ 'requestLabel' | translate }}"
                required>
              <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
              <mat-error *ngIf="requestLabelCtrl.invalid">{{getErrorMessage('requestLabel')}}</mat-error>
              <mat-error *ngIf="isRequestFound">{{getErrorMessage('isRequestFound')}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col" style="margin-right: 0em; padding-right: 0em">
            <mat-chip-list>
              <mat-chip class="stdTheme">{{ 'requestVersion' | translate }} {{ requestVersion }}</mat-chip>
            </mat-chip-list>
<!--
            <mat-checkbox id="requestDefault"  labelPosition= "after" style="margin-right: 0em; padding-right: 0em"
                      class="form-control stdTheme" formControlName="requestDefault" [checked]="'requestDefault'">
              <label>{{ 'requestDefault' | translate }}</label> 
            </mat-checkbox> 
-->
            <mat-checkbox id="requestDefault"
                class="checkbox-margin stdTheme"
                formControlName="requestDefault" 
                [checked]="'requestDefault'"
                labelPosition="after"
              >
              {{ 'requestDefault' | translate }}
            </mat-checkbox>
          </div>
        </div>

        <div class="flex-row row">
          <div class="col" style="margin-left: 0em; padding-left: 0em">
            <mat-form-field class="mdiselect form-group" appearance="standard">
              <mat-label>{{ 'requestEngine' | translate }}</mat-label>
              <mat-select id="requestEngine" placeholder="{{ 'Choose one engine' | translate }}" formControlName="requestEngine" 
                          panelClass="mdiselectcontent"
                          ngDefaultControl required>
                <mat-option class="selectOptionsTheme" *ngFor="let engine of engineList" [value]="engine">{{ engine}}</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
            <div class="col" style="margin-right: 0em; padding-right: 0em">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'dbsLabel' | translate }}</mat-label>
                <mat-select id="dbsLabel" placeholder="{{ 'Choose one datasource' | translate }}" formControlName="dbsLabel" 
                  panelClass="mdiselectcontent" ngDefaultControl required>
                  <mat-option class="selectOptionsTheme" *ngFor="let dbsLabel of dbsList" [value]="dbsLabel">{{ dbsLabel}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="flex-row row">
          <mat-form-field class="mdiselect form-group" appearance="standard">
            <mat-label>{{ 'accessEntities' | translate }}</mat-label>
              <mat-select #selectAccessEntities placeholder="{{ 'accessEntities' | translate }}" 
                          panelClass="mdiselectcontent" 
                          disableOptionCentering formControlName="accessEntities" multiple ngDefaultControl required>
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button color="primary"  (click)="selectAccessEntities.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option  class="selectOptionsTheme" *ngFor="let entity of entitiesList" [value]="entity" (onSelectionChange)="loadGrps($event)">{{ entity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="accessEntitiesCtrl.invalid">{{getErrorMessage('accessEntities')}}</mat-error>
          </mat-form-field>
          <!-- </section>-->
        </div> 
        <div class="flex-row row">
          <mat-form-field class="mdiselect form-group" appearance="standard" style="max-height: 50px" >
            <mat-label>{{ 'accessGrp' | translate }}</mat-label>
              <mat-select #selectAccessGrp placeholder="{{ 'accessGrp' | translate }}" 
                        panelClass="mdiselectcontent" 
                        disableOptionCentering formControlName="accessGrp" multiple ngDefaultControl required>
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button color="primary"  (click)="selectAccessGrp.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme" *ngFor="let accessGrp of grpsList" [value]="accessGrp">{{ accessGrp }}</mat-option>
            </mat-select>
            <mat-error *ngIf="accessGrpCtrl.invalid">{{getErrorMessage('accessGrp')}}</mat-error>
          </mat-form-field>
            <!-- </section>-->
        </div> 
        <!--
        <div class="flex-row row" >
          <mat-form-field class="mdiinput" appearance="standard" style="min-height: 120px; max-height: 200px">
            <mat-label>{{ 'requestContent' | translate }}</mat-label>
            <textarea style="min-height: 50px;max-height: 200px" id="requestContent" class="form-control" formControlName="requestContent" mat-autosize matInput #input placeholder="{{ 'requestContent' | translate }}"
              required></textarea>
            <mat-error *ngIf="requestContentCtrl.invalid">{{getErrorMessage('requestContent')}}</mat-error>
          </mat-form-field>
        </div>
        -->

        <div class="flex-row row" formGroupName="contentVerification">
          <mat-form-field class="mdiinput form-group" appearance="standard" style="min-height: 120px; max-height: 200px">
            <mat-label>{{ 'requestContent' | translate }}</mat-label>
            <textarea style="min-height: 50px;max-height: 200px" id="requestContent" class="form-control" formControlName="requestContent" mat-autosize matInput #input placeholder="{{ 'requestContent' | translate }}"
              required></textarea>
            <mat-error *ngIf="requestContentCtrl.invalid">{{getErrorMessage('requestContent')}}</mat-error>
          </mat-form-field>
          <mat-form-field class="mdiinput form-group" hidden=true appearance="standard" style="min-height: 0px; max-height: 0px">
            <textarea style="min-height: 0px;max-height: 0px" id="forbiddenWords" class="form-control" formControlName="forbiddenWords" 
                   matInput #input ></textarea>
          </mat-form-field>
        </div>
        <div formArrayName="restrictionsListGrp">
          <div><h6>{{ 'dynamic restrictions' | translate }}</h6></div>
          <div *ngFor= "let restriction of restrictionsList; let index=index let isLast=last">
            <div class="flex-row row"  [formGroupName]="index">
              <div class="col" style="margin-left: 0em; padding-left: 0em">
                <mat-form-field class="mdiselect form-group" appearance="standard">
                  <mat-label>{{ 'ColumnName' | translate }}</mat-label>
                  <mat-select #selectColumnName [(value)] = "restrictionsList[index].columnName" placeholder="{{ 'Choose one column' | translate }}" formControlName="columnName" 
                              panelClass="mdiselectcontent" name="columnName"
                              ngDefaultControl (selectionChange)="setValueArray('columnName',selectColumnName.value,index)" >
                    <mat-option class="selectOptionsTheme" *ngFor="let columnName of columnNameList" [value]="columnName">{{ columnName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col" style="margin-left: 0em; padding-left: 0em">
                <mat-form-field class="mdiselect form-group" appearance="standard">
                  <mat-label>{{ 'Operande' | translate }}</mat-label>
                  <mat-select #selectOperande placeholder="{{ 'Choose one operande' | translate }}" formControlName="operande" 
                              panelClass="mdiselectcontent" name= "operande"
                              ngDefaultControl (selectionChange)="setValueArray('operande',selectOperande.value,index)">
                    <mat-option class="selectOptionsTheme" *ngFor="let operande of operandeList" [value]="operande">{{ operande }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col" style="margin-right: 0em; padding-right: 0em">
                <mat-form-field class="mdiselect form-group" appearance="standard">
                  <mat-label>{{ 'RestrictValue' | translate }}</mat-label>
                  <mat-select #selectRestrictValue placeholder="{{ 'Choose one value' | translate }}" formControlName="restrictValue" 
                              panelClass="mdiselectcontent"
                              ngDefaultControl  (selectionChange)="setValueArray('restrictValue',selectRestrictValue.value,index)">
                    <mat-option class="selectOptionsTheme" *ngFor="let restrictValue of restrictValuesList"  [value]="restrictValue">{{ restrictValue }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col" style="margin-right: 0em; padding-right: 0em">
                <button mat-icon-button (click)="addRestrict(index)">
                  <span class="fa fa-plus" style="font-size: 20px" ></span>
                </button>
                <button mat-icon-button (click)="removeRestrict(index)">
                  <span class="fa fa-times" style="font-size: 20px" ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row row" style="padding-top: 10px; margin-top: 10px;">
       
            <mat-checkbox #docRequest
              class="checkbox-margin stdTheme"
              (change)="chkDocRequest($event)"
              formControlName="docRequest"
              labelPosition="before"
              >
              {{ 'docRequest' | translate }}
            </mat-checkbox>
        
        </div>
        <div *ngIf="isDocRequest">
          <div class="flex-row row" >
            <div class="col">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'docDbsLabel' | translate }}</mat-label>
                <mat-select #selectdocDbsLabel placeholder="{{ 'Choose one datasource' | translate }}" formControlName="docDbsLabel" 
                  panelClass="mdiselectcontent" ngDefaultControl [disabled]="!isDocRequest" required (selectionChange)="changeDocDbsLabel(selectdocDbsLabel.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let docDbsLabel of dbsListDoc" [value]="docDbsLabel">{{ docDbsLabel}}</mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="selectdocDbsLabel.value == undefined">{{getErrorMessage('docDbsLabel')}}</mat-error> -->
                <mat-error *ngIf="docDbsLabelCtrl.invalid">{{getErrorMessage('docDbsLabel')}}</mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'docPath' | translate }}</mat-label>
                <mat-select #selectdocPath  placeholder="{{ 'Choose one column' | translate }}" formControlName="docPath" 
                    panelClass="mdiselectcontent" name="docPath" [disabled]="!isDocRequest"
                    ngDefaultControl  (selectionChange)="ctrlMappingField(selectdocName.value,selectdocFilename.value,selectdocPath.value)" >
                  <mat-option class="selectOptionsTheme" *ngFor="let columnName of columnNameList" [value]="columnName">{{ columnName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'docFilename' | translate }}</mat-label>
                <mat-select #selectdocFilename  placeholder="{{ 'Choose one column' | translate }}" formControlName="docFilename" 
                    panelClass="mdiselectcontent" name="docFilename" [disabled]="!isDocRequest" required
                    ngDefaultControl  (selectionChange)="ctrlMappingField(selectdocName.value,selectdocFilename.value,selectdocPath.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let columnName of columnNameList" [value]="columnName">{{ columnName }}</mat-option>
                </mat-select>
                <mat-error *ngIf="docFilenameCtrl.invalid">{{getErrorMessage('docFilename')}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'docName' | translate }}</mat-label>
                <mat-select #selectdocName  placeholder="{{ 'Choose one column' | translate }}" formControlName="docName" 
                    panelClass="mdiselectcontent" name="docName" [disabled]="!isDocRequest" required
                    ngDefaultControl  (selectionChange)="ctrlMappingField(selectdocName.value,selectdocFilename.value,selectdocPath.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let columnName of columnNameList" [value]="columnName">{{ columnName }}</mat-option>
                </mat-select>
                <mat-error *ngIf="docNameCtrl.invalid">{{getErrorMessage('docName')}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div *ngIf="doTestRequest && !isTestRequestOK">
          <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
            <button mat-raised-button (click)="testRequest()" 
                    [disabled]="!requestForm.valid || isRequestFound || (isDocRequest && !isDocValuesOK)">
              <mat-icon>perm_data_setting</mat-icon>
              {{ 'Test request' | translate }}
            </button>
            <button mat-raised-button #buttonSubmit type="reset" (click)="doCancel()">
                <mat-icon>cancel</mat-icon>
                {{ 'cancel' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="!doTestRequest && isTestRequestOK">
          <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
            <button mat-raised-button #buttonSubmit type="submit" (click)="register()" [disabled]="!requestForm.valid || isRequestFound">
              <mat-icon>create</mat-icon>
              {{ 'submit' | translate }}
            </button>
            <button mat-raised-button #buttonSubmit type="reset" (click)="doCancel()">
              <mat-icon>cancel</mat-icon>
              {{ 'cancel' | translate }}
            </button>
          </div>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
