<div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 0px;height: 720px!important">
  <div *ngIf="loadingIndicator" class="flex-row row d-flex align-items-center" style="padding-top: 10px;margin: 0px;overflow-y: hidden;">
    <div class="spinner-container  stdTheme" fxLayout="row" fxLayoutAlign="space-around center">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
      <br>
      <span> 
      {{ 'loading specificationsImportList' | translate }}
      </span>
    </div>
  </div>
  <div *ngIf="!loadingIndicator" class="flex-row row d-flex align-items-center" style="padding-top: 10px;margin: 0px;overflow-y: hidden;">
    <div class="col-1">
        <button [disabled]="cardIsOpen" mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'goback' | translate }}
        </button>
    </div>
    <div class="col-10 d-flex justify-content-center">
        <span >
            {{ 'specificationsImportList' | translate }}
        </span>
        <span *ngIf="specificationImport !== undefined" style="margin-left: 10px">
            : {{ specificationImport.specificationsCur.specFilename }} ({{this.specjobsList.length}})
        </span>
    </div>                
    <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
      <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
              style="margin-left: 10px;margin-right: 10px;">
          <span class="fas fa-redo" style="font-size: 20px" ></span>
      </button>
  </div>

  <div *ngIf="!loadingIndicator" class="flex-row row d-flex align-items-center w-100" style="margin: 0px;overflow-y: hidden;height: 670px!important">
    <mat-sidenav-container  (backdropClick)="toggleSidenavSpec('backdrop')" [hasBackdrop]="hasBackdrop" fxFlexFill class="container-sidenav w-100" autosize>
        <mat-sidenav #sidenavSpec fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-90" disableClose style="overflow:hidden !important">
          <div class="flex-row row d-flex align-items-center w-100" style="padding-top: 10px;margin: 0px;overflow-y: hidden;">
            <div class="col-1">
                <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancelSpec()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ 'goback' | translate }}
                </button>
            </div>
            <div class="col-11 d-flex justify-content-center">
                <span *ngIf="specjobsUpdate !== undefined">
                  {{ 'jobPage' | translate }} <b>{{ specjobsUpdate.jobPage }}</b> / 
                  {{ 'equipModel' | translate }} <b>{{ specjobsUpdate.equipModel }}</b> / 
                  {{ 'jobLabel1' | translate }} <b>{{ specjobsUpdate.jobLabel }}</b>
                </span>
                <span *ngIf="specjobsUpdate === undefined">
                  {{ 'add tooltip' | translate }}
                </span>
            </div>
          </div>
          <div class="flex-row row d-flex align-items-center" style="padding-top: 10px;margin: 0px;overflow-y: hidden;">
              <mdi-specifications-card class="w-100"
                [specjobsUpdate]="specjobsUpdate"
                [specjobsList]="specjobsList"
                [idxUpdate]="idxUpdate"
                [statutCall]="imports"
                [actionType]="actionType"
                [ihmOptions]="ihmOptions"
                [specBrand]="specBrand"
                [uuidCur]="uuidCur"
                [workCodeDefault]="specificationImport.workCode"
                (dataOut)="dataoutSpecificationsCard($event)"
                >

              </mdi-specifications-card>
          </div>
        </mat-sidenav>
        <mat-sidenav-content style="padding-left: 15px;">
          <div *ngIf="!loadingIndicator">
            <div  class="flex-row row d-flex align-items-center rowTitleSpecifications" >
              <div  class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <span (click)="toggleSortColumn(0)"><b >{{ 'jobPage' | translate }}</b></span>
                <span *ngIf="columnSort[0].up" class="fa fa-caret-up" style="font-size: 15px;padding-top: 15px;padding-left: 10px" aria-hidden="true"></span>
                <span *ngIf="columnSort[0].down" class="fa fa-caret-down" style="font-size: 15px;padding-top: 15px;padding-left: 10px" aria-hidden="true"></span>
              </div>
              <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <b>{{ 'equipModel' | translate }}</b>
              </div>
              <div class="col-4 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <b>{{ 'jobLabel1' | translate }}</b>
              </div>
              <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <b>{{ 'recordedDate' | translate }}</b>
              </div>
              <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <b>{{ 'jobLevel' | translate }}</b>
              </div>

              <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                <button mat-icon-button (click)="addSpecifications()" [disabled]="isReadOnly"
                    data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                    <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="stdTheme" *ngFor="let specjobsItem of specjobsList; let idxRow = index" style="margin-left: 10px; margin-right: 10px" >
              <div class="flex-row row rowSpecifications align-items-center" [ngClass]="{'rowSpecOdd':rowSpec[idxRow].isOdd,'rowSpecEven':rowSpec[idxRow].isEven}" >
                <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                    <span>{{ specjobsItem.jobPage}}</span>
                </div>
                <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                    <span>{{ specjobsItem.equipModel }}</span>
                </div>
                <div class="col-4 d-flex justify-content-start text-wrap" style="padding-left: 0px;padding-right: 0px;">
                    <span>{{ specjobsItem.jobLabel}}</span>
                </div>
                <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                    <span>{{ specjobsItem.recordedDate }}</span>
                </div>
                <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                  <span *ngIf="specjobsItem.jobNormal">{{ 'jobNormal' | translate }}</span>
                  <span *ngIf="specjobsItem.jobMajor">{{ 'jobMajor' | translate }}</span>
                </div>
                <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;"
                      [ngClass]="{'greenTheme': specjobsItem.specJobOtherData.checked,
                                      'amberTheme': !specjobsItem.specJobOtherData.checked}"
                    >
                  <button mat-icon-button (click)="editSpecifications(specjobsItem, idxRow)" 
                          data-toggle="tooltip" data-placement="top" title="{{ 'edit tooltip' | translate }}">
                      <span class="fa fa-edit" style="font-size: 15px" aria-hidden="true"></span>
                  </button>
                </div>
                <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                  <span>
                    <button  mat-icon-button (click)="removeSpecifications(idxRow)" title="{{'delete row' | translate }}"
                            [disabled]="isReadOnly">
                        <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

