import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomValidators, ConfirmValidParentMatcher } from '../../../job/common/validators/customValidators';
import { TranslateService } from '../../../services/translate.service';

@Component({
  selector: 'mdi-user-pwd',
  templateUrl: './user-pwd.component.html',
  styleUrls: ['./user-pwd.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserPwdComponent implements OnInit {

  passwordCtrl: FormControl;
  confirmPasswordCtrl: FormControl;
  passwordForm: FormGroup;
  showPassword = true;
  @Output() pwdChanged: EventEmitter<string> = new EventEmitter<string>();
  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  constructor(private fb: FormBuilder, private _translate: TranslateService) { }

  ngOnInit() {
    this.passwordCtrl = this.fb.control('', Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    // this is for the letters (both uppercase and lowercase) and numbers validation
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9_?!-]+$')
                  ]));
    this.confirmPasswordCtrl = this.fb.control('', Validators.required);
    this.passwordForm = this.fb.group({
      password: this.passwordCtrl,
      confirmPassword: this.confirmPasswordCtrl
    }, {
        validator: CustomValidators.childrenEqual
      });
  }
  sendPasswd() {
    if (!this.passwordForm.invalid) {
      this.pwdChanged.next(this.passwordForm.value.password);
    }
  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'password': {
        !this.passwordForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.passwordForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Password is required') :
            this.passwordForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your Password should be more than 5  characters') : messageLib = '';
        break;
      }
      case 'confirmPassword': {
        !this.passwordForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.passwordForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Confirming password is required') :
            this.passwordForm.hasError('childrenNotEqual') ?
              messageLib = this._translate.getTranslate('Your password does not match') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
}
