<div #refElement class="mat-elevation-z8 container-fluid stdTheme">
    <!--[ngStyle]="{'margin-top': '50px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >-->
    <div style="padding-right: 5px; padding-left: 5px;">
        <form *ngIf="isLoaded" [formGroup]="anchorageForm"  >
            <section class="mat-typography">
                <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
            </section>
            <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between" >
                <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                    <mat-icon>close</mat-icon>
                    {{ 'cancel' | translate }}
                </button>
                <button *ngIf="(isInput && isOkToValid)" mat-raised-button  
                                    #buttonSubmit type="submit" (click)="registerAnchorage('card')" >
                    <mat-icon>save</mat-icon>
                    {{ 'save' | translate }}
                </button>
                <!--
                <button *ngIf="isSearch" mat-raised-button  #buttonSubmit type="submit" (click)="searching()" >
                    <mat-icon>search</mat-icon>
                    {{ 'search' | translate }}
                </button>
                -->
            </div>
            <div>
            <mat-tab-group mat-align-tabs="start" class="mat-tab-fill-height">
                <mat-tab label="{{ 'Location' | translate }}">
            <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                    <mat-card appMaterialElevation class="register-card stdTheme"  style="padding: 0px; padding-right: 5px; padding-left: 5px;margin-bottom: 25px;">
                        <!--
                        <mat-card-header>
                            <mat-card-title>
                                <section class="mat-typography">
                                    <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                                </section>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between" >
                                <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                    <mat-icon>close</mat-icon>
                                    {{ 'cancel' | translate }}
                                </button>
                                <button *ngIf="(isInput && !isRegistered) && anchorageForm.valid" mat-raised-button  
                                                    #buttonSubmit type="submit" (click)="registerAnchorage('card')" >
                                    <mat-icon>save</mat-icon>
                                    {{ 'save' | translate }}
                                </button>
                                <button *ngIf="isSearch" mat-raised-button  #buttonSubmit type="submit" (click)="searching()" >
                                    <mat-icon>search</mat-icon>
                                    {{ 'search' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>
-->
                        <mat-card-content class="stdTheme" style="height: 100%;">
                            <div class="flex-row row" style="margin: 0px;">
                                <div class="col-5">
        
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'anchorageCountry' | translate }}</mat-label>

                                        <mat-select id="anchorageCountry" #anchorageCountry placeholder="{{ 'Choose country' | translate }}" 
                                                    formControlName="anchorageCountry" 
                                                    [disabled]="isReadOnly"
                                                    panelClass="panelOptions" ngDefaultControl required 
                                                    (selectionChange)="onChangeCountry(anchorageCountry.value)">
                                            <mat-select-header>
                                                <div class="filterInput" style="display: inline-block;" >            
                                                    <button mat-icon-button matPrefix aria-label="search" >
                                                        <mat-icon>search</mat-icon>
                                                    </button>  
                                                    <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                                                style="margin: 5px" (keyup)="onKeyCountry($event.target.value)" >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                                *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                                                [value]="country.isoAlpha2"  >{{country.commonName}}
                                            </mat-option>
                                        </mat-select>    
                                    </mat-form-field>
                                </div>  

                                <div class="col-5" >

                                    <mat-form-field style="width: 100%" appearance="standard">
                                        <mat-label class="stdTheme">{{ 'anchorageState' | translate }}</mat-label>
                                        <mat-select class="stdTheme"  panelClass="panelSelectContent" #anchorageState  
                                                    disableOptionCentering [disabled]="!isState"
                                                    id="anchorageState"
                                                    formControlName="anchorageState" 
                                                    (selectionChange)="onChangeState(anchorageState.value)" >
                                            <div class="filterInput" style="display: inline-block;" >
                                                <input class="searchTheme" #filterState placeholder="{{ 'filterInput' | translate }}" 
                                                        style="margin: 5px" (keyup)="setFilterState($event.target.value)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="filterState.value" (click)="filterState.value='';setFilterState(filterState.value)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                            <mat-option class="stdTheme stdSelect" *ngFor="let state of statesFilteredList" 
                                                    class="selectOptionsTheme"  [value]="state.stateAbb" >
                                                    {{state.stateName }} ({{state.stateAbb}})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>  
                            </div>
                            <div class="flex-row row" style="margin: 0px">
                                <div class="col-5">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'anchorageCity' | translate }}</mat-label>
                                        <input id="anchorageCity" class="form-control inputTheme" formControlName="anchorageCity" 
                                                        matInput #anchorageCity placeholder="{{ 'anchorageCity' | translate }}"
                                                        (keyup)="anchorageCity.value = $event.target.value.toUpperCase()"
                                        required >
                                    </mat-form-field>
                                </div>  

                                <div class="col-5">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'anchorageName' | translate }}</mat-label>
                                        <input id="anchorageName" class="form-control inputTheme" formControlName="anchorageName" 
                                                        matInput #anchorageName placeholder="{{ 'anchorageName' | translate }}"
                                        required >
                                    </mat-form-field>
                                </div>  
                            </div>
                            <div class="flex-row row" style="margin: 0px">
                                <div class="col-4" style="margin-top: 15px">
                                    <mat-checkbox #anchorageWintering formControlName="anchorageWintering"  
                                        [labelPosition]="after"
                                        (change)="onChangeField('anchorageWintering',anchorageWintering.checked)" 
                                        [checked]="isAnchorageWintering">
                                            <div class="text-wrap">
                                                {{ 'anchorageWintering' | translate }}
                                            </div>
                                    </mat-checkbox> 
                                </div>
                            </div>
                            <div class="flex-row row" style="margin: 0px; margin-bottom: 10px!important">
                                <div class="col-6">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'anchoragePosition' | translate }}</mat-label>
                                        <input id="anchoragePosition" class="form-control inputTheme" formControlName="anchoragePosition" 
                                                        matInput #anchoragePosition placeholder="{{ 'anchoragePosition' | translate }}"
                                                        (change)="onChangePosition(anchoragePosition.value)" style="text-align: center;"
                                        >
                                       
                                        <button matSuffix type="button" class="btn btn-default" mat-icon-button (click)="displayMap()" >
                                            <mat-icon style="margin-top: -10px;margin-bottom: 0px;" >my_location</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>  

                                <div class="col-5">
                                    <mat-form-field class="mdiinput form-group" appearance="standard" style="text-align: center;">
                                        <input id="anchoragePositionCoord" class="form-control inputTheme" formControlName="anchoragePositionCoord" 
                                                        matInput #anchoragePositionCoord  readonly  >
                                    </mat-form-field>
                                </div>  
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>
                <mat-tab  label="{{ 'Contacts' | translate }}" >
                    <ng-template matTabContent >
                        <mdi-contacts-card [config]="configContacts" 
                                        [optionItem]="'wks-anchorages'" 
                                        [nameParent]="anchorageLocal"
                                        [ihmOptions]="ihmOptions" 
                                        [dataInput]="dataContacts" 
                                        (dataOut)="dataOutContacts($event)">
                        </mdi-contacts-card>
                    </ng-template>
                </mat-tab>
                <mat-tab [disabled]='!isGeoLocation' label="{{ 'geoLocation' | translate }}">
                    <ng-template matTabContent >
                        <div class="my-google-map">
                            <!-- https://medium.com/swlh/angular-google-map-component-basics-and-tips-7ff679e383ff -->
                            <!--
                            <google-map [width]="null" [height]="null" [options]="googleMaps.mapOptions">
                                <div *ngIf="isMarkers">
                                    <div  *ngFor="let markerItem of googleMaps.markers; let i = index">
                                        <map-marker [position]="markerItem.position" [title]="markerItem.title"></map-marker>
                                    </div>
                                </div>
                             </google-map>
                             -->
                             <mdi-google-location [googleMaps] = "googleMaps"  (dataOut)="dataOutContacts($event)"></mdi-google-location>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            </div>
        </form>
    </div>
</div>
<ng-template #modalMap let-modal >
    <mdi-google-location [googleMaps] = "googleMaps"  ></mdi-google-location>
</ng-template>