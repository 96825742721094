import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/translate.service';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { ColReportInt } from '../../../../models/job-data.model';

@Component({
  selector: 'mdi-report-columns',
  templateUrl: './report-columns.component.html',
  styleUrls: ['./report-columns.component.css']
})
export class ReportColumnsComponent implements OnInit, OnDestroy  {

  columnsHidden: ColReportInt[];
  columnsDisplayed: ColReportInt[];
  toDisplayed = false;
  toHidden = false;
  validOK = false;
  private readonly onDestroy = new Subject<void>();

  constructor(private _translate: TranslateService, private _jobDataService: JobDataService) { }

  ngOnInit() {

    this.initData();
    this._jobDataService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
     
      if (valueEvent === 'columnsActive') {
        this.initData();
      }
      if (valueEvent === 'validOK') {
        this.validOK = true;
      }
      if (valueEvent === 'validKO') {
        this.validOK = false;
      }

    }) ;
  }
  ngOnDestroy() {
    this.onDestroy.next();

  }
  initData() {
    this.columnsHidden = [];
    this.columnsDisplayed = [];
    const listColCur = this._jobDataService.getColReportService().colCur;
    
    let rankCur = 0;
    for (const colReading of listColCur)  {
      if (colReading.displayed) {
        if (this._jobDataService.getColReportService().colStatut === 'createReport') {
          rankCur++;
          colReading.ranking = rankCur;
        } else if (colReading.ranking === null) {
          rankCur++;
          colReading.ranking = rankCur;
        }     
        this.columnsDisplayed.push(colReading);
      } else {
        this.columnsHidden.push(colReading);
      }
    }
    if (this.columnsHidden.length > 0) {
      this.columnsHidden.sort(function(obj1, obj2) {
        return obj1.colName > obj2.colName ? 1 : -1;
      });
    } else {
      this.toHidden = true;
      this.toDisplayed = false;
    }
    if (this.columnsDisplayed.length === 0) {
      this.toHidden = false;
      this.toDisplayed = true;
      this._jobDataService.changeColReportService(null, null, null, null, null, null, false);
    } else {
      this._jobDataService.changeColReportService(null, null, null, null, null, null, true);
      this.columnsDisplayed.sort(function(obj1, obj2) {
        return obj1.ranking > obj2.ranking ? 1 : -1;
      });
    }
    
    const nbHiden = this.columnsHidden.length;
    const nbDisplay = this.columnsDisplayed.length;
    // const heigtRow = 35;
    let heigthCur = 35 * (nbHiden + nbDisplay);
    if ( heigthCur > 460) {
      heigthCur = 460;
    } 
    document.getElementById('columnsDisplayed').style.height = heigthCur.toString() + 'px';
    document.getElementById('columnsHidden').style.height = heigthCur.toString() + 'px';
    document.getElementById('columnCentral').style.height = heigthCur.toString() + 'px';
  }
  hiddenTodisplay() {

    // this.columnsDisplayed = [];
    let rankCur = 0;
    if (this.columnsDisplayed.length > 0) {
      rankCur = this.columnsDisplayed.length;
    }

    for (const colReading of this.columnsHidden)  {
      rankCur++;
      colReading.ranking = rankCur;
      colReading.displayed = true;
      this.columnsDisplayed.push(colReading);
    }
    this.columnsHidden = [];
    this.toDisplayed = false;
    this.toHidden = true;
  }
  displayTohidden() {
   //  this.columnsHidden = [];
    for (const colReading of this.columnsDisplayed)  {
      colReading.ranking = -1;
      colReading.displayed = false;
      this.columnsHidden.push(colReading);
    }
    this.columnsHidden.sort(function(obj1, obj2) {
      return obj1.colName > obj2.colName ? 1 : -1;
    });
    this.columnsDisplayed = [];
    this.toDisplayed = true;
    this.toHidden = false;
  }
 drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data, event.container.data, 
        event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } 
    let listCol: any[];
    if (event.container.id === 'columnsDisplayed') {
      this.columnsDisplayed[event.currentIndex].ranking = event.currentIndex + 1;
      this.columnsDisplayed[event.currentIndex].displayed = true;
      listCol = event.container.data ;
    } else {
      this.columnsHidden[event.currentIndex].displayed = false;
      listCol = event.previousContainer.data ;
    }
    // ranking calcul
    let idx = -1;
    for (const curRow of listCol) {
      // console.log(curRow.colName);
      idx++;
      for (const colList of this.columnsDisplayed) {
        if (curRow.colName === colList.colName) {
          colList.ranking = idx + 1;
        }
      }
    }
    if (this.columnsHidden.length > 0) {
      this.toDisplayed = true;
      this.columnsHidden.sort(function(obj1, obj2) {
        return obj1.colName > obj2.colName ? 1 : -1;
      });
    } else {
      this.toHidden = true;
      this.toDisplayed = false;
    }
    if (this.columnsDisplayed.length > 0) {
      this.toHidden = true;
      this._jobDataService.changeColReportService(null, null, null, null, null, null, true);

    } else {
      this.toHidden = false;
      this.toDisplayed = true;
      this._jobDataService.changeColReportService(null, null, null, null, null, null, false);
    }

  }
  checkChange(fieldChecked: string, inputValue: any, index: number) {

    if (fieldChecked === 'filterList') {
      // const valueFilter = document.getElementById('filter');
      // console.log(valueFilter);
      this.columnsDisplayed[index].filterList = inputValue;
    }
    if (fieldChecked === 'filterInput') {

      this.columnsDisplayed[index].filterInput = inputValue;
    }
    if (fieldChecked === 'grouping') {

      this.columnsDisplayed[index].grouping = inputValue;
    }
    if (fieldChecked === 'pinning') {

      this.columnsDisplayed[index].pinning = inputValue.value;
    }
  }
  register() {
    this._jobDataService.changeStatutEvent('doValidation');
  }
  doCancel() {
    this._jobDataService.changeStatutEvent('doCancel');
  }
}
