import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../services/translate.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';

import { ReportInt } from '../../../models/job-data.model';

@Component({
  selector: 'mdi-report-sheets',
  templateUrl: './report-sheets.component.html',
  styleUrls: ['./report-sheets.component.css']
})
export class ReportSheetsComponent implements OnInit, AfterContentInit {

  @Input() statutCall: any;
  @Input() reportToUpdate: ReportInt;
  private readonly onDestroy = new Subject<void>();

  tabLoadTimes: Date[] = [];

  showingActive = false;
  columnsActive = false;
  rowsPinnedActive = false;
  subReportActive = false;
  dashboardActive = false;
  reportLabelCur: string;
  constructor(private _translate: TranslateService, private _jobDataService: JobDataService ) { }

  ngOnInit() {
    if ((this.reportToUpdate !== null) && (this.reportToUpdate.reportLabel)) {
      this.reportLabelCur = this.reportToUpdate.reportLabel;
    } else {
      this.reportLabelCur = '';
    }
    this._jobDataService.onChangeStatut
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if (event === 'showingActive') {
          this.showingActive = true;
        }
        if (event === 'columnsActive') {
          this.columnsActive = true;
        }

        if (event === 'rowsPinnedActive') {
          this.rowsPinnedActive = true;
        }

        if (event === 'subReportActive') {
          this.subReportActive = true;
        }

        if (event === 'dashboardActive') {
          this.dashboardActive = true;
        }

    });
  }
  ngAfterContentInit() {
  } 
  getTimeLoaded(index: number) {
    if (!this.tabLoadTimes[index]) {
      this.tabLoadTimes[index] = new Date();
    }

    return this.tabLoadTimes[index];
  }
}
