<div [formGroup]="passwordForm" >
  <div class="flex-row row" >
    <div class="col inputTheme" style="margin-left: 0em; padding-left: 0em">
      <mat-form-field class="mdiinput form-group" appearance="standard">
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input id="password" class="form-control inputTheme" formControlName="password" matInput #input minlength="5" placeholder="{{ 'Password field' | translate }}"
          required [type]="showPassword ? 'password' : 'text'" (change)="sendPasswd();">
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-hint align="end">{{input.value?.length || 0}}/5</mat-hint>
        <mat-error *ngIf="passwordCtrl.invalid">{{getErrorMessage('password')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col inputTheme" style="margin-right: 0em; padding-right: 0em">
      <mat-form-field class="mdiinput form-group" appearance="standard">
        <mat-label>{{ 'Confirm password' | translate }}</mat-label>
        <input id="confirm" class="form-control inputTheme" [type]="showPassword ? 'password' : 'text'" formControlName="confirmPassword" [errorStateMatcher]="confirmValidParentMatcher"
          matInput placeholder="{{ 'Confirm password' | translate }}" required (change)="sendPasswd();">
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error style="margin-bottom: 20px;" *ngIf="confirmPasswordCtrl.invalid">{{getErrorMessage('confirmPassword')}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>