<mdi-topbar [isHome]="false"></mdi-topbar>

<div  class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">
    <div *ngIf="!okToDisplay" class="spinner-container  stdTheme" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        <br>
        <span> 
        {{ 'loading manufacturers specifications' | translate }}
        </span>
    </div>
    <div *ngIf="okToDisplay" class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">
        <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
            <div class="col-12 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                {{ 'manufacturers specifications' | translate }}
            </div>
        </div>
        <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
            <mat-sidenav-container  (backdropClick)="toggleSidenav('backdrop', -1 , -1)" [hasBackdrop]="hasBackdrop" fxFlexFill class="container-sidenav w-100" autosize>
                <mat-sidenav #sidenavMan fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-90" disableClose style="overflow:hidden !important">

                    <div class="flex-row row" style="margin: 0px;overflow-y: hidden;">
                        <mdi-specifications-table
                            [specificationImport]="specificationImport"
                            [rowImport]="rowImport"
                            (dataOut)="dataoutSpecificationsTable($event)"
                        ></mdi-specifications-table>
                    </div>
                </mat-sidenav>
                <mat-sidenav-content style="padding-left: 15px;">
                    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="selectedTabChange('', $event)" >
                        <div class="stdTheme" *ngFor="let manufacturerItem of manufacturersList; let row = index" >
                            <mat-tab  style="height: 100%!important;max-height: 100%!important;">
                                <ng-template mat-tab-label>
                                    <span (click)="currentTabSelected(manufacturerItem.brandName)">{{ manufacturerItem.brandName}}</span>
                                </ng-template>
                                <mat-tab-body >
                                    <ng-template matTabContent >
                                        <ng-container *ngTemplateOutlet="filesTemplate" [ngTemplateOutletContext]="{manufacturer:manufacturerItem}"></ng-container>
                                    </ng-template>
                                </mat-tab-body>
                            </mat-tab>
                        </div>
                    </mat-tab-group>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    </div>
</div>
<ng-template #filesTemplate let-manufacturer='manufacturer'>
    <mat-card appMaterialElevation class="stdTheme w-100"  style="padding: 0px; overflow-x: hidden; overflow-y: hidden">
        <mat-card-actions  >
            <div class="flex-row row" style="padding: 0px; margin-left: 0px; margin-right: 0px;padding-left: 10px;padding-right: 10px;">
                <div class="col-11 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                   
                </div>
            </div>
        </mat-card-actions>
        <mat-card-content>
            <div class="flex-row row d-flex align-items-center rowTitleSpecifications" >
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                            style="margin-left: 10px;margin-right: 10px;">
                        <span class="fas fa-redo" style="font-size: 20px" ></span>
                    </button>
                </div>
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b>{{ 'specFiletype' | translate }} / {{ 'specFileversion' | translate }}</b>
                </div>
                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b style="width: 70%; text-align: center">{{ 'workCode' | translate }}</b>
                    <b style="width: 30%; text-align: center">{{ 'lang' | translate }}</b>
                </div>
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b>{{ 'equipType' | translate }}</b>
                </div>
                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b>{{ 'specFilename' | translate }}</b>
                </div>
                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b>{{ 'recordedDate' | translate }}</b>
                </div>
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <b>{{ 'specStatut' | translate }}</b>
                </div>
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <span ><b>{{ 'ActionsFile' | translate }}</b></span>
                </div>
                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                    <button mat-icon-button (click)="addFileSpecifications()" [disabled]="isReadOnly"
                            data-toggle="tooltip" data-placement="top" title="{{ 'addFile' | translate }}">
                        <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="stdTheme" *ngFor="let specificationItem of specificationsList; let idxRow = index" style="margin-left: 10px; margin-right: 10px" >
                <fieldset class="specification-border" >
                    <!--'rowSpecificationsSelected':!specificationsRow[idxRow].isCollapse,-->
                    <div class="flex-row row rowSpecifications align-items-center" 
                        [ngClass]="{
                            'rowSpecificationsOdd': (specificationsRow[idxRow].isCollapse && specificationsRow[idxRow].isOdd),
                            'rowSpecificationsEven': (specificationsRow[idxRow].isCollapse && specificationsRow[idxRow].isEven)
                        }">
                        <div class="col-1 d-flex justify-content-cener" style="padding-left: 0px;padding-right: 0px;">
                            <span>
                                <button mat-icon-button (click)="toggleRow(idxRow)" 
                                        title="{{'detail row' | translate }}">
                                    <span *ngIf="specificationsRow[idxRow].isCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                    <span *ngIf="!specificationsRow[idxRow].isCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                </button> 
                            </span>
                        </div>
                        <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut" style="width:70%">
                                {{ specificationItem.specFiletype}}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut" style="padding-left: 0px;padding-right: 10px;width:70%">
                                <select class="form-control inputTheme" id="specFiletype" #specFiletype  
                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;"
                                        (change)="onChangeFileType( $event.target.value, idxRow)">
                                    <option class="selectOptionsTheme"  style="font-size: 15px!important;" *ngFor="let fileTypeCur of filetypeList" 
                                        [(value)]="fileTypeCur.value" >
                                            {{fileTypeCur.label}}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut" style="width:30%">
                                {{ specificationItem.specFileversion }}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut && inputValues.isFileType" style="padding-left: 0px;padding-right: 0px;width:30%">
                                <select class="form-control inputTheme" id="specFileversion" #specFileversion  
                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;"
                                        (change)="onChangeFileVersion( $event.target.value, idxRow)">
                                    <option class="selectOptionsTheme"  style="font-size: 15px!important;" *ngFor="let fileVersionCur of fileversionList" 
                                        [(value)]="fileVersionCur.value" >
                                            {{fileVersionCur.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut" style="width:70%">
                                {{ specificationItem.workCodeLabel }}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut && inputValues.isFileVersion" style="padding-left: 0px;padding-right: 0px;width:70%">
                                <select class="form-control inputTheme" id="workCode" #workCode  
                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;"
                                        (change)="onChangeParams('workCode',$event.target.value, idxRow)">
                                    <option class="selectOptionsTheme"  style="font-size: 15px!important;" *ngFor="let workCodeCur of workCodeList" 
                                        [(value)]="workCodeCur.value" >
                                            {{workCodeCur.label}}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut" style="width:30%">
                                {{ specificationItem.langLabel }}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut && inputValues.isWorkCode" style="padding-left: 0px;padding-right: 0px;width:30%">
                                <select class="form-control inputTheme" id="lang" #lang  
                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;width: 100%"
                                        (change)="onChangeParams('lang',$event.target.value, idxRow)">
                                    <option class="selectOptionsTheme"  style="font-size: 15px!important;" *ngFor="let langCur of langLabelsList" 
                                        [(value)]="langCur.langValue" >
                                            {{langCur.langDisplay}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut" style="width:100%">
                                {{ specificationItem.equipTypeLabel }}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut && inputValues.isLang" style="padding-left: 0px;padding-right: 0px;width:100%">
                                <select class="form-control inputTheme" id="equipType" #equipType  
                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;font-size: 15px!important;padding-left: 0px;padding-right: 0px;width: 100%"
                                        (change)="onChangeParams('equipType',$event.target.value, idxRow)">
                                    <option class="selectOptionsTheme"  style="font-size: 15px!important;" *ngFor="let equipTypeCur of equipTypeList" 
                                        [(value)]="equipTypeCur.value" >
                                            {{equipTypeCur.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                            <div *ngIf="!specificationsRow[idxRow].addFileStatut">
                                {{ specificationItem.specFilename }}
                            </div>
                            <div *ngIf="specificationsRow[idxRow].addFileStatut && inputValues.isEquipType" style="padding-left: 10px;padding-right: 10px;width:100%">
                                <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" >
                                    <mat-icon>library_add</mat-icon>
                                    <span style="margin-left: 10px">{{'SelectFile' | translate}}</span>
                                    <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                    <input #fileInput type="file" (select)="onFileSelect($event)" (change)="onFileChange($event, idxRow)" [accept]="fileExt" style="display:none;" />
                                </button>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                            {{getDateTimeFmt('recordedDate',specificationItem.recordedDate)}}
                        </div>
                        <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                            {{ specificationItem.specStatut | translate }}
                        </div>
                        <div *ngIf="specificationItem.specStatut !== 'waitingVerifInjection'" class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                            <span>
                                <button *ngIf="specificationsRow[idxRow].doUpload" mat-raised-button class="buttonTheme" 
                                    (click)="uploadFile(idxRow)" >
                                    <!--<mat-icon matTooltip="{{'doUpload' | translate}}">cloud_upload</mat-icon>-->
                                    <span style="margin-left: 10px">{{'doUpload' | translate}}</span>
                                </button>
                            </span>
                            <span>
                                <button *ngIf="!specificationsRow[idxRow].doUpload && !specificationsRow[idxRow].addFileStatut" mat-raised-button (click)="validAction(specificationsRow[idxRow].todo, idxRow)" title="{{specificationsRow[idxRow].todo | translate }}"
                                        [disabled]="isReadOnly">
                                        {{specificationsRow[idxRow].todo | translate }}
                                </button>
                            </span>     
                        </div>
                        <div *ngIf="specificationItem.specStatut === 'waitingVerifInjection'" class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                            <span>
                                <button  mat-raised-button (click)="validAction(specificationsRow[idxRow].todo, idxRow)" title="{{specificationsRow[idxRow].todo | translate }}"
                                        [disabled]="!specificationsRow[idxRow].isVerified">
                                        {{specificationsRow[idxRow].todo | translate }}
                                </button>
                            </span> 
                        </div>
                        <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                            <span>
                                <button *ngIf="specificationsRow[idxRow].isRemovable" mat-icon-button (click)="removeFileSpecifications(idxRow)" title="{{'delete row' | translate }}"
                                        [disabled]="isReadOnly">
                                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </span>
                        </div>
                        <div class="stdTheme w-100" *ngIf="!specificationsRow[idxRow].isCollapse" >
                            <div class="lineWks" style="margin-top: 5px;"></div>
                            <div class="flex-row row rowHistory align-items-center w-100"  >
                                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                </div>
                                <div class="col-4 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                    <span ><b>{{ 'specFilename' | translate }}</b></span>
                                </div>
                                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                    <span ><b>{{ 'historyDate' | translate }}</b></span>
                                </div>
                                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                    <span ><b>{{ 'specStatut' | translate }}</b></span>
                                </div>
                                <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                   
                                </div>
                            </div>
                            <div class="stdTheme w-100" *ngFor="let historyItem of specificationItem.historyData; let idxHisto = index"  >
                                <div class="flex-row row rowHistory align-items-center w-100"  >
                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                    </div>
                                    <div class="col-4 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                        <span >{{ historyItem.specFilename }}</span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                        <span >{{getDateTimeFmt('historyDate', historyItem.historyDate)}}</span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                        <span >{{ historyItem.specStatut | translate }}</span>
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                        <button *ngIf="historyItem.specFilename !== '' && historyItem.specFilename !== null" mat-icon-button class="buttonTheme" (click)="downloadFile(idxRow, idxHisto)" >
                                            <mat-icon matTooltip="{{'downloadFile' | translate}}">cloud_download</mat-icon>
                                        </button>
                                        <span>
                                            <button *ngIf="idxHisto === specificationsList[idxRow].historyData.length - 1 && (historyItem.specFilename !== '' && historyItem.specFilename !== null)" (click)="fileInput.click()" mat-icon-button class="buttonTheme" >
                                                <mat-icon matTooltip="{{'doUpload' | translate}}">cloud_upload</mat-icon>
                                                <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                                <input #fileInput type="file" (select)="onFileSelect($event)" (change)="uploadHistoFile($event, idxRow, idxHisto)" [accept]="fileExt" style="display:none;" />
                                            </button>
                                        </span>
                                        <span>
                                            <button *ngIf="specificationsHisto[idxRow][idxHisto].displayList"  mat-icon-button class="buttonTheme" 
                                                        (click)="toggleSidenav('displayList', idxRow, idxHisto)">
                                                <mat-icon matTooltip="{{'verifList' | translate}}">list</mat-icon>
                                
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            </mat-card-content>
    </mat-card>
</ng-template>