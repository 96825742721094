import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserModel } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'mdi-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit, OnDestroy {

  navbarCollapsed = true;

  user: UserModel;
  userEventsSubscription: Subscription;


  constructor(private userService: UserService, private router: Router) {  }

  ngOnInit() {
    this.userEventsSubscription = this.userService.userEvents.subscribe(user => this.user = user);
  }

  ngOnDestroy() {
    if (this.userEventsSubscription) {
      this.userEventsSubscription.unsubscribe();
    }
  }


  logout(event) {
    event.preventDefault();
    this.userService.logout();
    this.navbarCollapsed = true;
    this.router.navigate(['/login']);
  }
  jobHome(event) {
    event.preventDefault();
    this.navbarCollapsed = true;
    this.router.navigate(['/jobHome']);
  }
}
