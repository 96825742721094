<nav class="navbar navbar-light navbar-toggleable-md fixed-top " >
    <mat-toolbar class="mat-elevation-z24 fixed-top col-*-24">
        <mat-toolbar-row class="brandTheme">
            <div fxFlex fxLayout fxLayoutAlign="start" fxHide.xs >
                <a class="navbar-brand" >
                    <img src="../../assets/img/logo.png" alt="Logo" style="width:100px;">
                </a>
                <div fxFlex="10%" style="font-size: small" *ngIf="!isHome">
                    <button mat-icon-button  (click)="returnHome()" style="margin-right: 30px">
                        <mat-icon>home</mat-icon>
                    </button>
                </div>
                <div fxFlex="10%" style="font-size: small" *ngIf="!isReportHome && isReportActivate">
                    <button mat-icon-button matTooltip="{{ 'Report Area' | translate }}" (click)="navigateToReportHome()" >
                        <i class="fas fa-file-invoice"></i>
                    </button>
                </div>

                
                <div *ngIf="userLogged && workshop && isNwsActivate" >
                    <mat-menu #wksMenu overlapTrigger="false"  [hasBackdrop]="false">
                        <!-- <span  (mouseleave)="closeWksMenu()"> -->
                            <ng-container *ngFor="let item of wksItemList">
 
                                <button mat-menu-item [routerLink]="item.routing" *ngIf="!item.isTrigger">{{ item.label | translate }}</button>
                                <button mat-menu-item [matMenuTriggerFor]="submenu" *ngIf="item.isTrigger">{{ item.label | translate }}</button>
                                <mat-menu #submenu>
                                    <a *ngFor="let item1 of item.children">
                                        <button mat-menu-item [routerLink]="item1.routing"  *ngIf="!item1.isTrigger">{{ item1.label | translate }}</button>
                                        <button mat-menu-item [matMenuTriggerFor]="submenu2" *ngIf="item1.isTrigger">{{ item1.label | translate }}</button>
                                        <mat-menu #submenu2>
                                            <a *ngFor="let item2 of item1.children">
                                                <button mat-menu-item [routerLink]="item2.routing"  *ngIf="!item2.isTrigger">{{ item2.label | translate }}</button>
                                                <button mat-menu-item [matMenuTriggerFor]="submenu3" *ngIf="item2.isTrigger">{{ item2.label | translate }}</button>
                                                <mat-menu #submenu3>
                                                    <a *ngFor="let item3 of item2.children">
                                                        <button mat-menu-item [routerLink]="item3.routing"  *ngIf="!item3.isTrigger">{{ item3.label | translate }}</button>
                                                    </a>
                                                </mat-menu>
                                            </a>
                                        </mat-menu>
                                    </a>
                                </mat-menu>

                            </ng-container>
                        <!-- </span> -->
                    </mat-menu>
                    <button mat-icon-button matTooltip="Menu Wks" [matMenuTriggerFor]="wksMenu" >
                        <i class="fas fa-tools"></i>
                    </button>
                </div>
                
                <div *ngIf="userLogged">
                    <mat-menu #appMenu overlapTrigger="false" [hasBackdrop]="false">
                        <ng-container *ngFor="let item of itemList">
                            <button mat-menu-item [routerLink]="item.routing" *ngIf="!item.isTrigger">{{ item.label | translate }}</button>
                            <button mat-menu-item [matMenuTriggerFor]="submenu" *ngIf="item.isTrigger">{{ item.label | translate }}</button>
                            <mat-menu #submenu>
                                <a *ngFor="let item1 of item.children">
                                    <button mat-menu-item [routerLink]="item1.routing"  *ngIf="!item1.isTrigger">{{ item1.label | translate }}</button>
                                    <button mat-menu-item [matMenuTriggerFor]="submenu2" *ngIf="item1.isTrigger">{{ item1.label | translate }}</button>
                                    <mat-menu #submenu2>
                                        <a *ngFor="let item2 of item1.children">
                                            <button mat-menu-item [routerLink]="item2.routing"  *ngIf="!item2.isTrigger">{{ item2.label | translate }}</button>
                                        </a>
                                    </mat-menu>
                                </a>
                            </mat-menu>
                        </ng-container>
                    </mat-menu>
                    <button *ngIf="itemList !== undefined" mat-icon-button matTooltip="Menu" [matMenuTriggerFor]="appMenu" >
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex fxLayout fxLayoutAlign="center" fxHide.xs>
                <span style="font-size: small;" *ngIf="userLogged">
                    <h1 class="brandLabelTheme" >{{appSigle}} <small>{{appTitle}}</small></h1>
                </span>
            </div>
            <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
                <ul fxLayout fxLayoutGap="30px">
                    <button mat-icon-button [matMenuTriggerFor]="settings" *ngIf="userLogged" matTooltip="{{ 'Settings' | translate }}">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <mat-menu #settings="matMenu" [overlapTrigger]="false" yPosition="below">
                        <!--<span mat-menu-item ></span>-->
                        <button mat-menu-item class="buttonTheme" #buttonTheme (click)="toggleDarkTheme($event)">
                            <i class="fas fa-adjust"> </i>
                            <span *ngIf="!themeIsDark" style="padding-left: 10px;">{{ 'Dark Theme' | translate }}</span>
                            <span *ngIf="themeIsDark" style="padding-left: 10px;" > {{ 'Ligth Theme' | translate }}</span>
                        </button>
                        <button mat-menu-item [routerLink]="'/updatePwd'" >{{ 'UpdatePwd' | translate }}</button>
                    </mat-menu>
                    <button *ngIf="!themeIsDark" mat-icon-button matTooltip="{{ 'Dark Theme' | translate }}" (click)="toggleDarkTheme($event)">
                        <mat-icon>brightness_7</mat-icon>
                    </button>
                    <button *ngIf="themeIsDark" mat-icon-button matTooltip="{{ 'Ligth Theme' | translate }}" (click)="toggleDarkTheme($event)">
                        <mat-icon>brightness_5</mat-icon>
                    </button>
                    <!--
                    <button mat-icon-button matTooltip="{{ 'Help' | translate }}">
                        <mat-icon>help_outline</mat-icon>
                    </button>
                    -->
                    <button mat-icon-button [matMenuTriggerFor]="auth" matTooltip="{{ 'Connexion' | translate }}">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #auth="matMenu" [overlapTrigger]="false" yPosition="below">
                        <!--<span mat-menu-item ></span>-->
                        <span mat-menu-item *ngIf="userLogged">
                            <span>
                                {{ userLogged.id }} - {{ userLogged.username }}
                                <span class="fa fa-star"></span>
                                {{ userLogged.firstname }} {{ userLogged.lastname }}
                            </span>
                        </span>
                        <span mat-menu-item *ngIf="userLogged">
                            <span>
                                {{ 'entity ID' | translate }} : {{ userLogged.entity }}
                            </span>
                        </span>
                        <span mat-menu-item >
                            <span>
                                <p> Version FE {{ versionFE }} / BE {{ versionBE }} </p> 
                            </span>
                        </span>
                        <span mat-menu-item *ngIf="userLogged.isMaster" [matMenuTriggerFor]="entities" >{{ 'changeEntity' | translate }}</span>
                        <!--<button mat-menu-item  *ngIf="userLogged.isMaster" [matMenuTriggerFor]="entities" >{{ 'changeEntity' | translate }}</button>-->
                        <mat-menu  #entities="matMenu" overlapTrigger="false">
                            <div *ngFor="let item of entitiesList">
                                <button mat-menu-item (click)="changeEntity(item.value)">{{ item.label}}</button>
                            </div>
                        </mat-menu>
                        <button mat-menu-item (click)="logout($event)">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>{{ 'Sign out' | translate }}</span>
                        </button>
                    </mat-menu>

                </ul>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</nav>