<div>
  <div *ngIf="dataIsLoaded" style="display: block">
    <canvas baseChart

      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
    
  </div>
</div>
