import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { CommonMethods } from '../../../../common/tools/commonMethods';
import { UserService } from '../../../../../services/user.service';
import { TranslateService } from '../../../../../services/translate.service';
import { WksCentralService } from '../../../services/wks-central.service';
import { CalendarService } from '../../../../common/services/calendar.service';
import { RessourcesService } from '../../../../../job/common/services/ressources.service';

import { WksEntityModel } from '../../../models/wks-entity.model';
import { UserResponse } from '../../../../../models/user.model';
import { InternationalsFormats } from '../../../../../models/data.model';
import { WksEntityParams } from '../../../models/wks-common.model';
import { EventDef, CalendarEvt, CalendarEventBdd, EventRh, EventChanged, WorkingTime } from '../../../../../models/common.model';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'mdi-planning-hr',
  templateUrl: './planning-hr.component.html',
  styleUrls: ['./planning-hr.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PlanningHrComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();
  requestsLoaded: boolean;
  collabsLoaded: boolean;
  planningLoaded: boolean;
  doLoadCalendar: boolean;
  mechanicUsers: UserResponse[];

  eventsRh: EventRh[];
  wksEntity: WksEntityModel;
  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;
  eventsList: CalendarEvt[];
  calendardEventLBddist: CalendarEventBdd[];
  actorSelected: UserResponse;
  wksEntityParams: WksEntityParams;
  workingTime: WorkingTime;

  constructor( 
              private _userService: UserService,
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private _calendarService: CalendarService, 
              private _ressourcesService: RessourcesService,
              private adapterDate: DateAdapter<any>) { }

  ngOnInit() {
    if (this._userService.getUserLogged() === undefined)  { return ; }

    this.requestsLoaded = false;
    this.collabsLoaded = false;
    this.planningLoaded = false;
    this.doLoadCalendar = false;
    this.eventsRh = [];
    this.loadEntity().then(
    () => {
      this.settingIntFmts();
      this.getListMechanics(this._userService.getUserLogged().entity)
      .then (
        (responseMechanics: any ) => {
          this.mechanicUsers = responseMechanics;
          this.collabsLoaded = true;
          this.wksEntityParams = this._ressourcesService.getWksEntityParams();
          const workingTimeCur = {
            dayHours: this.wksEntityParams.dayHours,
            weekStartsOn: this.wksEntityParams.weekStartsOn,
            weekendDays: this.wksEntityParams.weekendDays,
          };
          this.workingTime = workingTimeCur;
          if (this.wksEntityParams.rhEvents) {
            this.eventsRh = this.wksEntityParams.rhEvents;
          }
          
        },
        (err: any) => {
          console.log('getListMechanics' + err.message);
          if (err.status === 404) {
          }
        }
      );
    });
  }
  loadEntity() {
    return  new Promise<void>((resolve, reject) => {
      this.wksEntity = this._wksCentralService.getWksEntityCur();
      resolve();
    });
  }
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);

    // this.localelang = userLang;
    this.localelang =  this._translate.currentLang;
    this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
  }

  getListMechanics(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this._userService.getEntityUsersList(entityCur, 'MECHANIC')
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('getListMechanics' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
loadEvents(entityCur: string, actor: string): any {
    let listActors: string;
    if (actor === undefined) {
      for (const actorCur of this.mechanicUsers) {
        if (listActors === undefined) {
          listActors = actorCur.username;
        } else {
          listActors += ';' + actorCur.username;
        }
      }
    } else {
      listActors = actor;
    }

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActors(entityCur, listActors, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, 
        err => {
          console.log('loadEvents' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadEventsDate(entityCur: string): any {
    let listActors: string;
    for (const actor of this.mechanicUsers) {
      if (listActors === null) {
        listActors = actor.username;
      } else {
        listActors += ';' + actor.username;
      }
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const startDate = moment({year: year, month: month, day: day - 1 }).toDate();
    const endDate = moment({year: year, month: month + 2, day: day}).toDate();

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActorsDates(entityCur, listActors, startDate, endDate, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadEventsDate' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  onChangeMechanic(event: { index: any, value: any }) {
    this.doLoadCalendar = true;
    this.planningLoaded = false;
    this.eventsList = [];
    this.calendardEventLBddist = [];
    
    for (const userCur of this.mechanicUsers) {
      if (userCur.username === event.value) {
        this.actorSelected = userCur;
        break;
      }
    }
    this.loadEvents(this._userService.getUserLogged().entity, this.actorSelected.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  }
  formatEvents() {
    this.eventsList = [];
    let evtId = 0;

    for (const eventCur of this.calendardEventLBddist) {
      let modifOption = false;
      if ((eventCur.calStatut === '') ||
        (eventCur.calStatut === null) ||
        (eventCur.calStatut === 'FIXED')) {
        modifOption = true;
      }

      if (eventCur.calDateStart === undefined) {
        eventCur.calDateStart = eventCur.calStart;
      }
      if (eventCur.calDateEnd === undefined) {
        eventCur.calDateEnd = eventCur.calEnd;
      }
      const otherDatas = JSON.parse(eventCur.calOtherdatas);
      this.eventsList.push({
        id: evtId,
        start: new Date(eventCur.calDateStart),
        end: new Date(eventCur.calDateEnd),
        title: eventCur.calTitle,     
        allDay: false,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: true,
        linkId: eventCur.calLinkid,
        calType: eventCur.calType,
        bddId: eventCur.id,
        calStatut: eventCur.calStatut,
        // "rhPlanning" :{"ref":"RH"}
        ref: (otherDatas && otherDatas.appointmentCustomer && otherDatas.appointmentCustomer.ref) ? otherDatas.appointmentCustomer.ref : 
            (otherDatas && otherDatas.rhPlanning && otherDatas.rhPlanning.ref) ?  otherDatas.rhPlanning.ref : undefined,
        outside: eventCur.calOutside,
        stdCreationDate: eventCur.stdCreationDate,
        stdCreationUser: eventCur.stdCreationUser,
        oldTime: {
          start: new Date(eventCur.calDateStart),
          end: new Date(eventCur.calDateEnd),
        }
      });
      evtId++;
    }
  }
  eventClicked(event: EventChanged): void {
    // console.log('eventClicked ' + JSON.stringify(event));
    const eventBdd = this.fillModelBdd(event.eventCur);
    if (eventBdd.id !== undefined) {
      // this.updateListEvents(eventBdd);
    }
    this.saveEvent(event.eventCur, event.action);
  }
  updateListEvents(calendarEventBdd: CalendarEventBdd, bddAction: string): void {
    let idxRow = 0;
    for (const eventTmp of this.calendardEventLBddist) {
      if (eventTmp.id === calendarEventBdd.id ) {
        this.calendardEventLBddist.splice(idxRow, 1);
        if ( (bddAction !== 'deleted')) {
          this.calendardEventLBddist.push(calendarEventBdd);
        }
        break;
      }
      idxRow ++ ;
    }
    if (bddAction === 'add')  {
      this.calendardEventLBddist.push(calendarEventBdd);
    }
    this.formatEvents();
  }
  
  saveEvent(eventCur: CalendarEvt, actionType: string) {
    
    let statutBdd = 'newDate';
    if (eventCur.bddAction === 'updated') {
      statutBdd = 'updateDate';
    }
    if (eventCur.bddAction === 'changed') {
      statutBdd = 'updateDate';
    }
    if (eventCur.bddAction === 'deleted') {
      statutBdd = 'cancelDate';
    }
    const calToValid = this.fillModelBdd(eventCur);
    this._calendarService.saveWksCalendar(calToValid, this.wksEntity.entityTz, statutBdd, this.actorSelected.email, undefined)
    .subscribe(
      (data) => {
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
        const calendarEventBddTmp = data.body;
        eventCur.bddId = calendarEventBddTmp.id;
        calToValid.id = calendarEventBddTmp.id;
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
        this.updateListEvents(calToValid, eventCur.bddAction);
      },
      (err) => {
        console.log('saveEvent ' + calToValid + ' KO ' + err );
      }
    );
    // console.log('saveEvent : ' + eventCur);
  }
  fillModelBdd(eventCur: CalendarEvt): CalendarEventBdd {
    let idxTmp;
    try {
      if (CommonMethods.isUUID(eventCur.id.toString())) {
        idxTmp = eventCur.id.toString();
      }
    } catch (error) {
      idxTmp = undefined;
    }
    const idxCur = (idxTmp ? idxTmp : eventCur.bddId ? eventCur.bddId : undefined);

    const appointmentCustomer = {'ref':  'RH'};
    const othersData = ' {"rhPlanning" :' + JSON.stringify(appointmentCustomer) + ',"actorMail" : "' + this.actorSelected.email + '"}';
    const dateStartTime: any = moment(eventCur.start).format(environment.fmtDateTimeBdd);
    const dateEndTime: any = moment(eventCur.end).format(environment.fmtDateTimeBdd);
    const calendarEventBdd: CalendarEventBdd = {
      id: idxCur,
      stdEntity: this.wksEntity.stdEntity,
      calApplication: 'rhPlanning',
      calType: eventCur.calType,
      calLinkid: eventCur.linkId,
      calStart: dateStartTime,
      calEnd: dateEndTime,
      calAction: '',
      calTitle: eventCur.title,
      calOutside: eventCur.outside,
      calDescription: eventCur.description,
      calComment: eventCur.comment,
      calAllday: false,
      calStatut: 'FIXED',
      calActor: this.actorSelected.username,
      calOtherdatas: othersData,
      stdCreationDate: eventCur.stdCreationDate,
      stdCreationUser: eventCur.stdCreationUser,
    };

    return calendarEventBdd;
  }
}
