import { CalculationCmd, CalculationValues, CmdExpression, CondExpression } from '../../../models/common.model';
import * as i0 from "@angular/core";
export class CalculationAlgoService {
    constructor() {
    }
    setParamCustoms(paramCustomsArg) {
        this.paramCustoms = paramCustomsArg;
    }
    getParamCustoms() {
        return this.paramCustoms;
    }
    setEconomicAreas(economicAreasArg) {
        this.economicAreas = economicAreasArg;
    }
    getEconomicArea(entityCountry) {
        let currentArea;
        for (const economicArea of this.economicAreas) {
            if (economicArea.countriesMembers.includes(entityCountry)) {
                currentArea = economicArea.eaId;
                break;
            }
        }
        return currentArea;
    }
    getParamCustomsByHsCode(hsCode) {
        let paramCustoms;
        if (hsCode === undefined || hsCode === '') {
            hsCode = 'default';
        }
        for (const paramCustomCur of this.paramCustoms) {
            if (paramCustomCur.hsCode === hsCode) {
                paramCustoms = paramCustomCur;
                break;
            }
        }
        if (paramCustoms === undefined) {
            for (const paramCustomCur of this.paramCustoms) {
                if (paramCustomCur.hsCode === 'default') {
                    paramCustoms = paramCustomCur;
                    break;
                }
            }
        }
        return paramCustoms;
    }
    executeAlgo(calculationAlgoArg) {
        this.calculationAlgo = calculationAlgoArg;
        let ecoZoneOrigin = '';
        let hsCode = '';
        for (const cmdCur of calculationAlgoArg) {
            let resultValue = 0;
            if (cmdCur.constantName === 'countryOrigin') {
                ecoZoneOrigin = cmdCur.otherData;
            }
            if (cmdCur.constantName === 'hsCode') {
                hsCode = cmdCur.currentValue;
            }
            for (const dataOp of cmdCur.dataOperations) {
                if (cmdCur.duties) {
                    const duties = this.customsDuties(dataOp.cmdExpression, ecoZoneOrigin, hsCode);
                    cmdCur.otherData = JSON.stringify(duties);
                    resultValue = 0;
                    for (const dutie of duties) {
                        resultValue += dutie.result;
                    }
                }
                else if (dataOp.conditionnal) {
                    resultValue = this.conditionnalCalcul(dataOp.conditionnalExpression, resultValue);
                }
                else {
                    resultValue = this.expressionCalcul(dataOp.cmdExpression, resultValue);
                }
            }
            if (!cmdCur.fixedData) {
                cmdCur.currentValue = isNaN(resultValue) ? 0 : resultValue;
            }
        }
    }
    customsDuties(cmdExpression, ecoZoneOrigin, hsCode) {
        const duties = [];
        const calculationValues = this.extractvalue(cmdExpression.numberValues, cmdExpression.technicalCodes);
        const baseCalcul = calculationValues.value1;
        const paramCustoms = this.getParamCustomsByHsCode(hsCode);
        const rulesJson = JSON.parse(paramCustoms.rulesJson);
        /*
        if (rulesJson.dutyFreeZones.includes(ecoZoneOrigin)) {
          const dutieTmp: Dutie = {
            dutieName: 'dutieFree',
            method: '',
            value: 0,
            base: baseCalcul,
            result: 0
          };
          duties.push(dutieTmp);
          return duties;
        }
        */
        // console.log(JSON.stringify(rulesJson));
        let isFound = false;
        let economicAreaDuties;
        for (const areaOrigin of rulesJson.economicAreasOrigin) {
            if (areaOrigin.originId === ecoZoneOrigin) {
                isFound = true;
                economicAreaDuties = areaOrigin;
                break;
            }
        }
        if (!isFound) {
            for (const areaOrigin of rulesJson.economicAreasOrigin) {
                if (areaOrigin.originId === 'default') {
                    isFound = true;
                    economicAreaDuties = areaOrigin;
                    break;
                }
            }
        }
        for (const dutie of economicAreaDuties.duties) {
            const dutieTmp = JSON.parse(JSON.stringify(dutie));
            if (dutie.method === 'rates') {
                dutieTmp.result = this.executeOperations(baseCalcul, 'multi', Number(dutie.value));
            }
            duties.push(dutieTmp);
        }
        return duties;
    }
    conditionnalCalcul(conditionnalExpression, resultValue) {
        if (!this.verifCond(conditionnalExpression)) {
            return resultValue;
        }
        for (const nestExpression of conditionnalExpression.nestedExpressions) {
            resultValue = this.expressionCalcul(nestExpression, resultValue);
        }
        return resultValue;
    }
    verifCond(conditionnalExpression) {
        const returnVerif = [];
        let comparatorCur;
        let iCond = 0;
        let andNum = 0;
        for (const clauseCond of conditionnalExpression.condClauses) {
            if (clauseCond.orAnd === 'empty') {
                comparatorCur = clauseCond.orAnd;
                returnVerif[iCond] = [];
            }
            else if (clauseCond.orAnd === 'or') {
                comparatorCur = clauseCond.orAnd;
                andNum = 0;
                iCond++;
                returnVerif[iCond] = [];
            }
            if (clauseCond.comparator === 'empty') {
                returnVerif[iCond][andNum] = false;
                break;
            }
            const calculationValues = this.extractvalue(clauseCond.numberValues, clauseCond.technicalCodes);
            if (calculationValues.value1 === undefined || calculationValues.value2 === undefined) {
                returnVerif[iCond][andNum] = false;
                break;
            }
            returnVerif[iCond][andNum] = this.comparatorNumericCond(clauseCond.comparator, calculationValues.value1, calculationValues.value2);
            andNum++;
        }
        const bValues = [];
        let bValue = 0;
        iCond = 0;
        for (const returnsCur of returnVerif) {
            bValue = 0;
            for (const rowCur of returnsCur) {
                bValue += rowCur ? 1 : 0;
            }
            if (bValue === returnsCur.length) {
                bValues[iCond] = true;
            }
            else {
                bValues[iCond] = false;
            }
            iCond++;
        }
        let isTrue = false;
        for (const rowCur of bValues) {
            if (rowCur) {
                isTrue = true;
                break;
            }
        }
        return isTrue;
    }
    comparatorNumericCond(comparatorArg, value1, value2) {
        let returnVerif;
        switch (comparatorArg) {
            case 'equal':
                returnVerif = (value1 === value2 ? true : false);
                break;
            case 'not-equal':
                returnVerif = (value1 !== value2 ? true : false);
                break;
            case 'gt-equal':
                returnVerif = (value1 >= value2 ? true : false);
                break;
            case 'lt-equal':
                returnVerif = (value1 <= value2 ? true : false);
                break;
            case 'gt':
                returnVerif = (value1 > value2 ? true : false);
                break;
            case 'lt':
                returnVerif = (value1 < value2 ? true : false);
                break;
            default:
                returnVerif = false;
                break;
        }
        return returnVerif;
    }
    extractvalue(numberValues, technicalCodes) {
        const calculationValues = {
            value1: 0,
            value2: 0
        };
        let incrementLoop = 0;
        for (const numberValue of numberValues) {
            if (technicalCodes[incrementLoop] === 'empty') {
                if (incrementLoop === 0) {
                    calculationValues.value1 = Number(numberValues[incrementLoop]);
                }
                else {
                    calculationValues.value2 = Number(numberValues[incrementLoop]);
                }
            }
            incrementLoop++;
        }
        incrementLoop = 0;
        for (const technicalCode of technicalCodes) {
            if (technicalCode !== 'empty') {
                if (incrementLoop === 0) {
                    calculationValues.value1 = Number(this.getCurrentValue(technicalCode));
                }
                else {
                    calculationValues.value2 = Number(this.getCurrentValue(technicalCode));
                }
            }
            incrementLoop++;
        }
        return calculationValues;
    }
    getCurrentValue(technicalCodeArg) {
        let currentValue;
        for (const cmdCur of this.calculationAlgo) {
            if (cmdCur.technicalCode === technicalCodeArg) {
                currentValue = cmdCur.currentValue;
                break;
            }
        }
        return currentValue;
    }
    expressionCalcul(cmdExpression, resultValue) {
        let calculValue = resultValue;
        const calculationValues = this.extractvalue(cmdExpression.numberValues, cmdExpression.technicalCodes);
        if (calculationValues.value2 === 0) {
            calculationValues.value2 = resultValue;
        }
        if (cmdExpression.operande[0] !== 'empty') {
            calculValue = this.executeOperations(resultValue, cmdExpression.operande[0], calculationValues.value1);
        }
        else {
            calculValue = calculationValues.value1;
        }
        if (cmdExpression.operande[1] !== 'empty') {
            calculValue = this.executeOperations(calculValue, cmdExpression.operande[1], calculationValues.value2);
        }
        return calculValue;
    }
    executeOperations(value1, operator, value2) {
        let returnValue;
        switch (operator) {
            case 'add':
                returnValue = value1 + value2;
                break;
            case 'sub':
                returnValue = value1 - value2;
                break;
            case 'multi':
                returnValue = value1 * value2;
                returnValue = Math.round(returnValue * 100) / 100;
                break;
            case 'divi':
                returnValue = value1 / value2;
                returnValue = Math.round(returnValue * 100) / 100;
                break;
            case 'percent':
                let percent = value2 / 100;
                percent = Math.round(percent * 100) / 100;
                returnValue = value1 * percent;
                returnValue = Math.round(returnValue * 100) / 100;
                break;
        }
        return returnValue;
    }
}
CalculationAlgoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalculationAlgoService_Factory() { return new CalculationAlgoService(); }, token: CalculationAlgoService, providedIn: "root" });
