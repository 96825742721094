import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../../../../../../services/user.service';
import { TranslateService } from '../../../../../../../services/translate.service';
import { WksCentralService } from '../../../../../services/wks-central.service';

import { WksTasksModel, TaskSN } from '../../../../../models/wks-param.model';
import { LabelValue } from '../../../../../../../models/data.model';


@Component({
  selector: 'mdi-taskref-parent',
  templateUrl: './taskref-parent.component.html',
  styleUrls: ['./taskref-parent.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TaskrefParentComponent implements OnChanges {

  @Input() tasksrefList: WksTasksModel[];
  @Input() taskrefParentCur: string;
  @Input() childRestrictSN: TaskSN[];
  @Output() dataOut = new EventEmitter<LabelValue>();

  @ViewChild('filterParent', {static: false}) filterParent: ElementRef;

  tasksRefParentForm: FormGroup;

  tasksrefListSelect: LabelValue[];
  tasksrefListFiltered: LabelValue[];


  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService) { }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'tasksrefList': {
            this.tasksrefList = changes['tasksrefList'].currentValue;
            break;
          }
          case 'taskrefParentCur': {
            this.taskrefParentCur = changes['taskrefParentCur'].currentValue;
            break;
          }
          case 'childRestrictSN': {
            this.childRestrictSN = changes['childRestrictSN'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } 

    this.initData();
  }
  initData() {
    
    this.buildList();
    this.buildForm();
    this.fillForm();
  }
  buildList() {
    
    const userLang = this._userService.getUserLogged().userlang;
    this.tasksrefListSelect = [];
    this.tasksrefListFiltered = [];
    for (const taskRef of this.tasksrefList) {
      const equipmentCur = this._translate.getTranslate(taskRef.equipType);
      if (!this.ctrlRestrict(taskRef)) {
        continue;
      }
      let currentLabel = '';
      for ( const local of taskRef.langLabels) {
        if (local.labelLang === userLang) {
          currentLabel = local.labelText;
        }
      }
      currentLabel += '--> ' + equipmentCur;

      if (taskRef.equipBrand !== '' && taskRef.equipBrand !== null && taskRef.equipBrand !== undefined) {
        currentLabel += '--> ' + taskRef.equipBrand;
      }
      if (taskRef.equipModel !== '' && taskRef.equipModel !== null && taskRef.equipModel !== undefined) {
        currentLabel += '--> ' + taskRef.equipModel;
      }

      let taskrefSN: TaskSN[] = [];

      if ((taskRef.taskrefSN !== undefined) && (taskRef.taskrefSN !== null)) {
        try {
          const taskrefSNJson = JSON.parse(taskRef.taskrefSN);
          taskrefSN = [...taskrefSNJson];
        } catch {
          taskrefSN = [];
        }
      }
      let otherDataTmp = '';
      let idxRow = 0;
      if (taskrefSN.length > 0) { 
        if (otherDataTmp === '') { 
          otherDataTmp = this._translate.getTranslate('snFrom') + ' : ' + taskrefSN[idxRow].snFrom;
          otherDataTmp += ' >> ';
          otherDataTmp += this._translate.getTranslate('snUpto') + ' : '  + taskrefSN[idxRow].snUpto ;
          /* otherDataTmp += '\n';
          otherDataTmp += 'test';*/
        } else  { 
          otherDataTmp += '\n';
          otherDataTmp += this._translate.getTranslate('snFrom') + ' : ' + taskrefSN[idxRow].snFrom;
          otherDataTmp += ' >> ';
          otherDataTmp += this._translate.getTranslate('snUpto') + ' : '  + taskrefSN[idxRow].snUpto ;
        }
        idxRow++;
      } else {
        otherDataTmp = this._translate.getTranslate('allSN');
      } 

      try {
        const tasksActionsContent = JSON.parse(taskRef.taskrefJson).tasksActionsContent;
        if (tasksActionsContent === undefined || tasksActionsContent === null) {
          continue;
        }
      } catch {
        continue;
      }
      const labelCur: LabelValue =  { 
        label: currentLabel,
        value: taskRef.taskrefId,
        otherData: otherDataTmp,
        additionnalValue: taskRef.taskrefJson
      };
      this.tasksrefListSelect.push(labelCur);
    }
    this.tasksrefListFiltered = [... this.tasksrefListSelect];
  }
  ctrlRestrict(parentTaskRefCur: WksTasksModel): boolean {
    // si pas de restriction NS dans la tache courante ni dans dans la tache enfant 
    if (parentTaskRefCur.taskrefSN === null 
        || parentTaskRefCur.taskrefSN === undefined 
        || parentTaskRefCur.taskrefSN === '') {
        if (this.childRestrictSN.length === 0) {
          return true;
        } 
    }
    // si restrictions NS dans la tache courante
    if (parentTaskRefCur.taskrefSN !== null 
      && parentTaskRefCur.taskrefSN !== undefined 
      && parentTaskRefCur.taskrefSN !== '') {
        // si pas de restriction dans la tache enfant
        if (this.childRestrictSN.length === 0) {
          return true;
        } 
        let isFound: boolean;
        let taskrefSN: TaskSN[] = [];
        isFound = false;
        try {
          const taskrefSNJson = JSON.parse(parentTaskRefCur.taskrefSN);
          taskrefSN = [...taskrefSNJson];
          isFound = this.searchSNRange(taskrefSN, this.childRestrictSN);
        } catch {
          taskrefSN = [];
          isFound = false;
        }
        if (!isFound) {
          return false;
        } 
    }
    return true;
  }
  searchSNRange(parentSN: TaskSN[], childSN: TaskSN[]): boolean {

    /*
    localeCompare returns:

    1 if string1 is greater (higher in the alphabetical order) than string2
    -1 if string1 is smaller (lower in the alphabetical order) than string2
    0 if string1 and string2 are equal in the alphabetical order
    
    For instance, to get a natural alphanumeric sort (x10 < x8), 
    you may pass the numeric: true option. To also make the case insensitive 
    while comparing the string, you may also pass sensitivity: 'base' option. 
    This would compare the two strings value and VALUE as equal, ignoring the casings.


    leftString.localeCompare(rightString, undefined, { numeric: true, sensitivity: 'base'})

    */

    interface RowSN {
      fromOK: boolean;
      uptoOK: boolean;
      betweenOK: boolean;
      isOK: boolean;
    }
    const rowSNtmp: RowSN =  {
      fromOK: false,
      uptoOK: false,
      betweenOK: false,
      isOK: false
    };
    const rowsSN: RowSN[] = [];
    for (let idx = 0; idx < childSN.length ; idx++) {
      rowsSN.push(rowSNtmp);
    }
    // boucle parent
    for (const parentItemSN of parentSN ) {
      // boucle enfant
      let idxRead = 0;
      for (const childItemSN of childSN ) {
        let testBetween = true;
        if (rowsSN[idxRead].isOK) {
          continue;
        }
        // parent : à partir de vide
          // enfant : à partir de vide >> vrai
          // enfant : à partir de non vide >> vrai
        if (parentItemSN.snFrom === null || parentItemSN.snFrom === undefined || parentItemSN.snFrom === '') {
          rowsSN[idxRead].fromOK = true;
          rowsSN[idxRead].betweenOK = true;
          testBetween = false;
        }
        // parent : jusqu'à vide
          // enfant : jusqu'à vide >> vrai
          // enfant : jusqu'à non vide >> vrai
        if (parentItemSN.snUpto === null || parentItemSN.snUpto === undefined || parentItemSN.snUpto === '') {
          rowsSN[idxRead].uptoOK = true;
          rowsSN[idxRead].betweenOK = true;
          testBetween = false;
        }
      // parent : entre à partir de et jusqu'à
        if (testBetween) {
        // enfant à partir de vide : faux
        // enfant à partir de < à partir de parent : faux
        // enfant à partir de >= à partir de parent : vrai
          if (childItemSN.snFrom === null || childItemSN.snFrom === undefined || childItemSN.snFrom === '') {
            rowsSN[idxRead].fromOK = false;
          } else {
            // if (childItemSN.snFrom.localeCompare(parentItemSN.snFrom , undefined, { numeric: true, sensitivity: 'base'}) === -1) {
            if (childItemSN.snFrom < parentItemSN.snFrom ) {
              rowsSN[idxRead].fromOK = false;
            } else {
              rowsSN[idxRead].fromOK = true;
            }
          }
        // enfant jusqu'à  vide : faux
        // enfant jusqu'à > jusqu'à parent : faux
        // enfant jusqu'à <= jusqu'à parent : vrai
          if (childItemSN.snUpto === null || childItemSN.snUpto === undefined || childItemSN.snUpto === '') {
            rowsSN[idxRead].uptoOK = false;
          } else {
            if (childItemSN.snUpto > parentItemSN.snUpto ) {
              rowsSN[idxRead].uptoOK = false;
            } else {
              rowsSN[idxRead].uptoOK = true;
            }
          }
          if (rowsSN[idxRead].uptoOK && rowsSN[idxRead].fromOK ) {
            rowsSN[idxRead].betweenOK = true;
          }
        }
        if (rowsSN[idxRead].uptoOK && rowsSN[idxRead].fromOK && rowsSN[idxRead].betweenOK) {
          rowsSN[idxRead].isOK = true;
        }
        idxRead++;
      }

    }
    let isFullOK = true;
    for (const rowSN of rowsSN) {
      if (!rowSN.isOK) {
        isFullOK = false;
        break;
      }
    }
    return isFullOK;
  }
  buildForm(): void {
    this.tasksRefParentForm = this.fb.group({
      tasksRefParentSelect: this.fb.control(''),
    },
    {
      updateOn: 'blur'
    });
  }
  fillForm(): void {
    if (this.taskrefParentCur) {
      this.tasksRefParentForm.controls.tasksRefParentSelect.setValue(this.taskrefParentCur);
    } else {
      this.tasksRefParentForm.controls.tasksRefParentSelect.setValue('');
    }
    
  }
  onChangeParent($event: string): void {
    // console.log('onChangeParent : ' + $event);
    let isFound = false;
    for ( const taskCur of this.tasksrefListSelect) {
      if (taskCur.value === $event)  {
        this.dataOut.emit(taskCur);
        isFound = true;
        break;
      }
    }
   if (!isFound) {
    this.dataOut.emit(undefined);
   }
  }
  onKeyParent($event: any): void {
    // console.log(value);

    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }

    if (valueFinal === '') { 
      this.tasksrefListFiltered = this.tasksrefListSelect;
    } else { 
      const filtersCur = valueFinal.split('+');
      this.tasksrefListFiltered = this.tasksrefListSelect;
      for (const filterCur of filtersCur ) { 
        this.tasksrefListFiltered = this.searchBrand(filterCur);
      }
    }

    this.filterParent.nativeElement.value = valueFinal;
  }
  searchBrand(value: string): any { 
    const filter = value.toLowerCase();
    return this.tasksrefListFiltered.filter(parentCur => parentCur.label.toLowerCase().includes(filter));
  }
}
