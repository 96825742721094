import { Component, OnInit, Input, NgZone  } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {take} from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';

import { WksCentralService } from '../../../../services/wks-central.service';
import { ParamWorksRatesModel, WorkPricing, LangLabelModel, ParamLabelsI18n } from '../../../../models/wks-param.model';

import { WksEntityModel } from '../../../../models/wks-entity.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-workrates-card',
  templateUrl: './workrates-card.component.html',
  styleUrls: ['./workrates-card.component.css']
})
export class WorkratesCardComponent implements OnInit {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() workRateToUpdate: ParamWorksRatesModel;

  private readonly onDestroy = new Subject<void>();

  isRegistered: boolean;
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];
  billingUnitsList: LabelValue[];
  workLabelCtrl: FormControl;
  workUnitCtrl: FormControl;

  workRateForm: FormGroup;
  rateList: WorkPricing[];
  titleCard: string;

  entityCur: string;
  wksEntity: WksEntityModel;
  isLoaded: boolean;
  currencyCur: string;
  // workRateToUpdate: ParamWorksRatesModel;

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _ressourcesService: RessourcesService,
    private router: Router,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService) { }

  ngOnInit() {
    this.isRegistered = false;
    this.isLoaded = false;
    this.initData();
  }
  initData() {

    
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }
    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }

    this.currencyCur = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry).currency;

    this.entityCur = this._userService.getUserLogged().entity;
    
    this.titleCard = this._translate.instant(this.optionItem);
  
    this.workLabelCtrl = this.fb.control('');
    this.workUnitCtrl = this.fb.control('');


    this.workRateForm = this.fb.group({
      workLabel: this.workLabelCtrl,
      workUnit: this.workUnitCtrl,

    },
    {
      updateOn: 'blur'
    });
    this.getBillingUnits();
    this.loadEntity();
  }
  loadEntity(): void {
    
    this._wksCentralService.getWksEntity(this.entityCur)
    .subscribe(
      data => {
        this.wksEntity = data.body;
        this.loadScales().then(
          () => {
            this.displayWorkRate();
            this.isLoaded = true;
          });
      },
      () => {
        this.errorMessage('entity');
      }
    );
  }
  loadScales() {
    return  new Promise<void>((resolve, reject) => {
      const scalesTmp = this.wksEntity.entityScales.split(',');
      this.rateList = [];
      for (const scaleCur of scalesTmp) {
        const scaleTmp: WorkPricing = {
          scaleName: scaleCur,
          scaleRate: 0.0,
        };
        this.rateList.push(scaleTmp);
      }
      resolve();
    }
    );
  }
  displayWorkRate() {
    if (this.workRateToUpdate === undefined) {
      return;
    }
    this.workRateForm.controls.workLabel.setValue(this.workRateToUpdate.workLabel);
    this.workRateForm.controls.workUnit.setValue(this.workRateToUpdate.workUnit);
    
    
    if ((this.workRateToUpdate.langLabels !== undefined) && (this.workRateToUpdate.langLabels !== null)) {
      for (const labelCur of this.workRateToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
          }
        } 
      }
    }
    if (this.workRateToUpdate.workPricing !== undefined) {
      const ratesTmp = JSON.parse(this.workRateToUpdate.workPricing) as WorkPricing[];
      for (const rateCur of this.rateList) {
        for (const rateLocal of ratesTmp) {
          if (rateLocal.scaleName === rateCur.scaleName) {
            rateCur.scaleRate = rateLocal.scaleRate;
            break;
          }
        }
      }
    }
  }
  getBillingUnits() {
    const listTmp = this._wksCentralService.getBillingUnits();
    this.billingUnitsList = [];
    for (const buCur of listTmp) {
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(buCur),
        value: buCur
      };
      this.billingUnitsList.push(curLabel);
    }
  }  
  setScaleRateValue(_event: string, _row: number) {
    const eventNum = Number(_event);
    this.rateList[_row].scaleRate = eventNum;
  }
  setLangLabelValue(_event: string, _row: string) {
    const value = _event.charAt(0).toUpperCase() + _event.slice(1);
    if (this.langLabelsList[_row].langValue === this._translate.getCurrentLang()) {
      this.workRateForm.controls.workLabel.setValue(value);
    }
    this.langLabelsList[_row].langLabel = value;
  }
  registerWorkRate() {
    const workRateValidation = this.prepareValidation();
    this._wksCentralService.saveWksWorkRates(workRateValidation, this.entityCur)
    .subscribe(
      paramWorksRatesReturn => {
        this.workRateToUpdate = paramWorksRatesReturn.body;
        this.isRegistered = true;
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('closeRegister');
          return;
        } else {
          this.router.navigate(['/jobHome']);
        }

      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  prepareValidation(): ParamWorksRatesModel {
    const paramLabelsI18nArray: ParamLabelsI18n[] = [];
    if ((this.workRateToUpdate !== undefined) 
      && (this.workRateToUpdate.langLabels !== undefined) 
      && (this.workRateToUpdate.langLabels !== null)) {
      for (const paramLabelsI18n of this.workRateToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = local.langLabel;
          }
        }
        paramLabelsI18nArray.push(paramLabelsI18n); 
      }
    } else {
      for (const labelCur of this.langLabelsList) {
        const paramLabelsI18n = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18n);
      }
    }
    const workRateValidation: ParamWorksRatesModel = {
      id: (this.workRateToUpdate !== undefined ? this.workRateToUpdate.id : undefined),
      stdEntity: this.entityCur,
      workCode: (this.workRateToUpdate !== undefined ? this.workRateToUpdate.workCode : undefined),
      workLabel: this.workRateForm.controls.workLabel.value,
      workUnit: this.workRateForm.controls.workUnit.value,
      workPricing: JSON.stringify(this.rateList),
      stdCreation: (this.workRateToUpdate !== undefined ? this.workRateToUpdate.stdCreation : undefined),
      langLabels: paramLabelsI18nArray,
      userLang: this._userService.getUserLogged().userlang,
    };
    return workRateValidation;
  }
 
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording work rate';
      
    }
    if (_actionCur === 'entity') {
      messageBox = 'error loading';
      titleBox = 'loading entity ';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doClose() {
    this.router.navigate(['/jobHome']);
  }
  doCancel() {
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeRegister');
      return;
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
}
