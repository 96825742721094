<div *ngIf="isOkToDisplay" class="stdTheme" style="overflow-x: hidden; overflow-y: auto;margin-bottom: 0px; width: 100%"
                                                [ngStyle]="{height: '590px'}">
    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
        <mat-card-actions style="margin-bottom: 0px;">
            <!--<div class="container-fluid d-flex justify-content-between" >-->
                <div class="flex-row row"  style="margin-left: 5px;margin-right: 5px;">
                    <div class="col-2 d-flex justify-content-start" >
                        <button style="margin-top: 5px;" class="mat-raised-button buttonTheme" 
                            [disabled]="!isOkToSave" style="margin-bottom: 10px;" (click)="registerUsageHours()" >{{'submit' | translate}}
                        </button>
                    </div>
                </div>
            <!--</div>-->
        </mat-card-actions>
        <mat-card-content class="stdTheme" style="padding-bottom: 20px;overflow-y: auto;overflow-x: hidden; height: 500px; max-height: 500px">
            <form [formGroup]="histoUsageForm"  style="margin: 0px; height: 100%; width: 100%;" >
                <div formArrayName="histoUsageFa">
                    <div  class="stdTheme" *ngFor="let argCur of histoUsageForm.get('histoUsageFa').controls; let idxRow = index" style="margin-left: 10px; margin-right: 10px" [formGroupName]="idxRow">
                        <fieldset class="histoUsage-border" style="margin-top: 0px; margin-bottom: 0px" >
                            <div class="flex-row row d-flex align-items-center rowHoursUsage " >
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button mat-icon-button  style="padding-top: 0px;margin-top: 25px;"
                                            (click)="addRowUsageHours(idxRow)" [disabled]="isReadOnly"
                                            data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                        <span class="fa fa-plus" style="font-size: 15px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-3 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'histoHours' | translate }}</mat-label>
                                        <input matInput type="number" class="form-control inputTheme" formControlName="hoursFg" 
                                                [ngStyle]="{'text-align': fieldFormat.justifNumbers}"
                                                value="{{usageHistoInput[idxRow].histoHours | formatNumber : ['quantity',fieldFormat.locale,0, 0 ]}}"
                                                (keypress)="numberAndDotOnly($event)"
                                                (change)="onHistoChange('hours', $event.target.value, idxRow)" >
                                    </mat-form-field>
                                </div>
                                <div class="col-3 d-flex justify-content-center" style="vertical-align: middle" >
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'histoDate' | translate }}</mat-label>
                                        <!-- 
                                            https://www.tutorialspoint.com/ngx_bootstrap/ngx_bootstrap_datepicker.htm 
                                            https://github.com/valor-software/ngx-bootstrap/issues/2716
                                        -->
                                        <input matInput style="text-align: center;"
                                            placeholder="Datepicker"
                                            class="form-control inputTheme"
                                            bsDatepicker 
                                            [bsValue]="currentDateValue[idxRow]"
                                            [minDate]="minDate"
                                            [maxDate]="maxDate"
                                            formControlName="dateFg"
                                            [bsConfig]="datePickerConfig"
                                            (bsValueChange)="onHistoChange('datePicker', $event, idxRow)">
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                                <div class="col-2 d-flex justify-content-center">
                                    <mat-checkbox class="histoRevision" color="warn" #histoRevisionFg formControlName="histoRevisionFg"  
                                        [labelPosition]="before" 
                                        (change)="onHistoChange('histoRevision', histoRevisionFg.checked, idxRow)" 
                                        [checked]="usageHistoInput[idxRow].histoRevision">
                                            <div class="text-wrap">
                                                {{ 'histoRevision' | translate }}
                                            </div>
                                    </mat-checkbox> 
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button  mat-icon-button  style="padding-top: 0px;margin-top: 25px;" 
                                                (click)="remmoveRowUsageHours(idxRow)" 
                                                data-toggle="tooltip" data-placement="top"
                                                title="{{'delete row' | translate }}"
                                                [disabled]="isReadOnly || argCur.disabled">
                                        <span class="fa fa-trash" style="font-size: 15px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="usageHistoInput[idxRow].histoRevision 
                                        && usageHistoInput[idxRow].taskrefRow !== 'work'
                                        && filteredTasksList !== undefined && filteredTasksList.length > 0" 
                                    class="flex-row row d-flex align-items-center rowHoursUsage w-100" style="margin-top: 15px;" >
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                                <div class="col-10 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;" >
                                    <mat-form-field  class="wksInput form-group" 
                                                    appearance="standard" style="margin-right: 15px;margin-top: -50px;">
                                        <mat-label>{{ 'tasksReferenced' | translate }}</mat-label>
                                        <mat-select id="tasksReferenced" 
                                                panelClass="mdiselectcontent" #tasksReferenced  required
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                formControlName="taskrefIdFg"
                                                (selectionChange)="onHistoChange('revisionsTaskrefId',tasksReferenced.value, idxRow)"
                                                [value]="usageHistoInput[idxRow].taskrefId"
                                                ngDefaultControl >
                                            <mat-select-filter 
                                                [placeholder]="'filterInput' | translate" 
                                                [displayMember]="'taskrefLabel'" 
                                                [array]="listTasksFiltered" 
                                                (filteredReturn)="filteredTasksList = $event">
                                            </mat-select-filter>
                                            <mat-option class="selectOptionsTheme" *ngFor="let taskCur of filteredTasksList" 
                                                [value]="taskCur.taskrefId"  >{{taskCur.taskrefLabel}}
                                            </mat-option>
                                        </mat-select>                   
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                            </div>
                            <div *ngIf="usageHistoInput[idxRow].histoRevision 
                                        && usageHistoInput[idxRow].taskrefRow === 'work'" 
                                    class="flex-row row d-flex align-items-center rowHoursUsage w-100" style="margin-top: 15px;" >
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                                <div class="col-10 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;" >
                                <!--<span>{{usageHistoInput[idxRow].taskrefLabel}}</span>-->
                                <!--<span>{{usageHistoInput[idxRow] | json }}</span>-->
                                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                    <mat-label>{{ 'tasksReferenced' | translate }}</mat-label>
                                    <input matInput class="form-control inputTheme" formControlName="taskrefIdFg" 
                                            value="{{usageHistoInput[idxRow].taskrefLabel}}" readonly
                                            >
                                </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"></div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>