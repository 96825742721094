export interface Imjlusers {
        id: string;
        username: string;
        firstname: string;
        lastname: string;
        sexe: string;
        birthday: Date;
        email: string;
        entity: string;
        profile: string;
        usergroup: string;
        datafilter: string;
        roles: string;
        accountNonExpired: boolean;
        accountNonLocked: boolean;
        credentialsNonExpired: boolean;
        enabled: boolean;
        userdebug: string;
        userlang: string;
        authorities: string;
        extendedgrp: String;
        extendedentities: String;
}
export interface ColDef {
        colName: string;
        colType: string;
        actionRules: {
                add: ActionRules;
                update: ActionRules;
                del: ActionRules;
        };
        selected?: boolean;
  }

export interface ActionRules {
        mandatory: boolean;
        defaultValue: string;
        forbidden: boolean;
        formatValues: string;
        acceptedValues: string;
}
export const mjlusers_sch = [
        { colName: 'id', colType: 'number', actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: true, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: ''  },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: ''  }, } },
        { colName: 'password', colType: 'string', actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'username', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'firstname', colType: 'string', actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'lastname', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'sexe', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: 'M,F,T' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: 'M,F,T' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'birthday', colType: 'Date',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'email', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '$email$', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '$email$', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'entity', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$Entities$' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$Entities$' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'profile', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'usergroup', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$UsersGroup$' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$UsersGroup$' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'datafilter', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'roles', colType: 'string',  actionRules: {
                add: { mandatory: true, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$Roles$' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$Roles$' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'accountNonExpired', colType: 'boolean',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'accountNonLocked', colType: 'boolean',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'credentialsNonExpired', colType: 'boolean',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'enabled', colType: 'boolean',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'userdebug', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: false, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'userlang', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '$defaultLang$', forbidden: false,
                                                                        formatValues: '', acceptedValues: '$supportedLangs$' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '$supportedLangs$' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'extendedgrp', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'extendedentities', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'accessentities', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'accessgrps', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'otherdata', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'authorities', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'dataHabil', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: true, forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'additionalInfo', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'entitycountry', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'entitylang', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } },
        { colName: 'applications', colType: 'string',  actionRules: {
                add: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                update: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' },
                del: { mandatory: false, defaultValue: '', forbidden: false, formatValues: '', acceptedValues: '' }, } }

];
export const mjlentities_sch = [
        { colName: 'entity', colType: 'string' },
        { colName: 'entitylabel', colType: 'string' },
        { colName: 'entitydesc', colType: 'string' },
        { colName: 'datafilter', colType: 'string' },
        { colName: 'supervisoremail', colType: 'string' },
        { colName: 'extendedaccess', colType: 'string' },
        { colName: 'master', colType: 'boolean' },
        { colName: 'tags', colType: 'string' },
        { colName: 'entitycountry', colType: 'string' },
        { colName: 'entitylang', colType: 'string' },
        { colName: 'otherDatas', colType: 'string' },
];

export const mjlusergroup_sch = [
        { colName: 'entity', colType: 'string' },
        { colName: 'usergroup', colType: 'string' },
        { colName: 'usergrouplabel', colType: 'string' },
        { colName: 'roles', colType: 'string' },
        { colName: 'datafilter', colType: 'string' },
        { colName: 'supervisoremail', colType: 'string' },
        { colName: 'extendedaccess', colType: 'string' },
        { colName: 'extendedaccess', colType: 'string' },
        { colName: 'authorizedfunctions', colType: 'string' },
        { colName: 'datahabil', colType: 'string' },
        { colName: 'tagsauthorized', colType: 'string' },
        { colName: 'tagsprohibited', colType: 'string' },
];
