
<!--
  https://angular2-tree.readme.io/docs/getting-started
  https://www.webappcomponents.com/2017/11/ngx-contextmenu.html
-->
<div class="container-fluid" >
  <mat-card class="register-card stdTheme" layout="column" flex >
    <mat-card-actions>

      <div id="registration-error" class="alert alert-danger" *ngIf="registrationFailed ">
        <button type="button" mat-icon-button class="close" aria-label="Close" (click)="registrationFailed = false;">
          <!-- <span aria-hidden="true">&#215;</span> -->
        </button>
        {{ messageTranslate }}
      </div>

    </mat-card-actions>
    <mat-card-content >
      <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="tabSelected($event);">
        <mat-tab  label="{{ 'MenuStructure' | translate }}" >
          <div class="contentTab mat-elevation-z4" [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'600'">
            <div fxLayout="row stdTheme" style="font-size: small;" >
                <button mat-icon-button (click)="doNewRoot();" 
                      matTooltip="{{'EntityMenuAddRoot' | translate}}">
                    <span class="fa fa-plus-square-o" style="font-size: small;" ></span>
                </button>
                <div *ngIf="isNewRoot">
                  <a style="font-size: small;"><input #input type="text"></a>
                  <button mat-icon-button (click)="addNewRoot(input.value)">
                      <span class="fa fa-check-circle" style="font-size: small;" ></span>
                  </button>
                </div>
            </div>
            <div fxLayout="column" style="font-size: small;" >
                <tree-root #tree [nodes]="nodesTree" [contextMenu]="basicMenu" [contextMenuSubject]="tree.treeModel.getFocusedNode()"
                    (activate)="onTreeEvent($event)"
                    [options]="optionsTree">
                </tree-root>  
            </div>
            <div class="flex-row row stdTheme">
                <mat-label>{{ 'sharedEntities' | translate }}</mat-label>
                
                <mat-select #selectSharedEntities placeholder="{{ 'sharedEntities' | translate }}" 
                            panelClass="mdiselectcontent" 
                            disableOptionCentering  multiple ngDefaultControl >
                  <mat-select-header>
                    <div class="flex-row button-row text-center" >
                      <button mat-raised-button  (click)="selectSharedEntities.toggle()">
                        {{'Close' | translate}}
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let entity of entitiesList" [value]="entity" (onSelectionChange)="setSharedEntities($event)">{{ entity }}</mat-option>
                </mat-select>
            </div> 
          </div>
        </mat-tab>
        <mat-tab  label="{{ 'MenuLabeling' | translate }}" >
          <div class="contentTab mat-elevation-z4" *ngIf="parentsLoad">
            <div class="col" style="align-content: flex-end; margin-right: 0em; padding-right: 0em">
              <mat-form-field style="padding-top: 0px; margin-top: 0px;">
                  <mat-label>{{ 'userLang' | translate }}</mat-label>
                  <mat-select [(value)]="userLangLabel" (selectionChange)="onLangChange($event)" style="padding-top: 0px; margin-top: 0px;" ngDefaultControl>
                    <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" selected="userLangLabel" [value]="lang" >{{ lang.display | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div  [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'600'">
                <table mat-table #tableLabel [dataSource]="dataLabel">
                  <ng-container matColumnDef="menuName">
                    <th mat-header-cell  *matHeaderCellDef>{{'menuName' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.menuName" >{{row.menuName}}</td>
                  </ng-container>

                  <ng-container matColumnDef="menuLabel">
                    <th mat-header-cell  *matHeaderCellDef>{{'menuLabel' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.menuLabel" >
                      <mat-form-field class="form-group" >
                        <input type="text" #menuLabel matInput #input  [(value)]="row.menuLabel" (change)="setValueArray('menuLabel',menuLabel.value,index)">
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="menuToolTip">
                    <th mat-header-cell *matHeaderCellDef>{{'menuToolTip' | translate}}</th>
                    <td mat-cell  *matCellDef="let row; let index=index" [(value)]="row.menuToolTip" >
                        <mat-form-field class="form-group" >
                            <input type="text" #menuToolTip matInput #input  [(value)]="row.menuToolTip" (change)="setValueArray('menuToolTip',menuToolTip.value,index)" >
                          </mat-form-field>
                    </td>
                  </ng-container>
                  <tr mat-header-row style="height: 50px"*matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
                </table>
              </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-fluid d-flex justify-content-between" style="margin-top: 10px;">
        <button mat-raised-button #buttonSubmit type="submit" (click)="register()" >
          <mat-icon>create</mat-icon>
          {{ 'submit' | translate }}
        </button>
        <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
          <mat-icon>cancel</mat-icon>
          {{ 'cancel' | translate }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<context-menu style="font-size: small;" >
  <div class="stdTheme" *ngIf="isItemActived">
    <ng-template contextMenuItem let-item passive="true">
      <a style="font-size: small;"><input #input type="text" matInput matTooltip="{{'EntityMenuAddNode' | translate: [item.data.name]}}"></a>
      <button mat-icon-button (click)="addNode(item,input.value)">
          <span class="fa fa-plus-square" style="color:darkviolet;font-size: small;" ></span>
      </button>
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item  passive="true">
      <a style="font-size: small;"><input #input [value]="item.data.name" type="text" matInput matTooltip="{{'EntityMenuRenameNode' | translate: [item.data.name]}}"></a>
      <button mat-icon-button (click)="renameNode(item,input.value)">
          <span class="fa fa-check-circle" style="color:green;font-size: small;" ></span>
      </button>
    </ng-template>

    <ng-template contextMenuItem let-item passive="true">
        <a style="font-size: small;"> 
         
          <input #input [value]="item.data.name" type="text" readonly matInput matTooltip="{{'EntityMenuRemoveNode' | translate: [item.data.name]}}">
        </a>
        <button mat-icon-button (click)="removeNode(item)">
          <span class="fa fa-minus-square" style="color:red;font-size: small;" ></span>
        </button>        
    </ng-template>
  </div>
</context-menu>
