/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-common.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../services/translate.pipe";
import * as i4 from "../../../../services/translate.service";
import * as i5 from "../../../job-wks/directives/safe-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./alert-common.component";
var styles_AlertCommonComponent = [i0.styles];
var RenderType_AlertCommonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertCommonComponent, data: {} });
export { RenderType_AlertCommonComponent as RenderType_AlertCommonComponent };
function View_AlertCommonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["style", "padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-dismissable"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.message; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.data1; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.data2; _ck(_v, 6, 0, currVal_3); }); }
function View_AlertCommonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-dismissable"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_AlertCommonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayMessages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"]], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-dismissable"); var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.message, "html")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AlertCommonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAlert(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_AlertCommonComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpad(3, 2), i1.ɵpid(0, i3.TranslatePipe, [i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.alertType !== "alertWks"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-dismissable"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit.message, _ck(_v, 3, 0, _v.context.$implicit.data1, _v.context.$implicit.data2))); _ck(_v, 2, 0, currVal_1); }); }
function View_AlertCommonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAlert(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_AlertCommonComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpad(3, 2), i1.ɵpid(0, i3.TranslatePipe, [i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_12)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.alertType !== "alertWks"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-dismissable"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit.message, _ck(_v, 3, 0, _v.context.$implicit.data1, _v.context.$implicit.data2))); _ck(_v, 2, 0, currVal_1); }); }
function View_AlertCommonComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_11)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertCommonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafeHtmlPipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_5)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_7)), i1.ɵdid(10, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_10)), i1.ɵdid(12, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionDisplay; _ck(_v, 2, 0, currVal_0); var currVal_1 = "TimeChanged"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "csvControl"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "htmlMessage"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "normalMessage"; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_AlertCommonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-alert-common", [], null, null, null, View_AlertCommonComponent_0, RenderType_AlertCommonComponent)), i1.ɵdid(1, 114688, null, 0, i7.AlertCommonComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertCommonComponentNgFactory = i1.ɵccf("mdi-alert-common", i7.AlertCommonComponent, View_AlertCommonComponent_Host_0, { messageInfo: "messageInfo", data1: "data1", data2: "data2", messageType: "messageType", typeDisplay: "typeDisplay", alertType: "alertType", actionCur: "actionCur", displayMessages: "displayMessages" }, {}, []);
export { AlertCommonComponentNgFactory as AlertCommonComponentNgFactory };
