
<div>
  <div *ngIf="dataIsLoaded" style="display: block">
    <canvas baseChart
      [datasets]="radarChartData"
      [options]="radarChartOptions"
      [labels]="radarChartLabels"
      [chartType]="radarChartType"
      [colors]="radarChartColors">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
  </div>
</div>

