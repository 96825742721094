import { Component, OnInit, OnDestroy , ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { UserService } from '../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../job/common/tools/gridTools';
import { ReportInt, mjljobreports_sch } from '../../../models/job-data.model';

@Component({
  selector: 'mdi-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class ReportsTableComponent implements OnInit {

  @ViewChild('modalTestReport', {static: false, read: TemplateRef}) modalTestReport: TemplateRef<any>;
  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  dataSource = new MatTableDataSource();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = mjljobreports_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterReport: boolean;
  doCreateReport: boolean;
  doUpdateReport = [];
  reportToUpdate: ReportInt;
  statutRegister: string;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 10;
  editing = {};
  rows = [];
  pwdFormat = '*******';
  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  optionItem: string;
  isReportDoc: boolean;
  isDashboard: boolean;
 // modal 
 modalRef: BsModalRef;
 config = {
   class: 'modal-dialog-centered modal-dialog-std modal-body-std',
   backdrop: true,
   ignoreBackdropClick: true,
   animated: true,
   
 };
 modalText: any[];


  constructor( private _userService: UserService, 
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private modalService: BsModalService,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
  }

  ngOnInit() {
    this.isReportDoc = false;
    this.optionItem = 'noItem';
    this.optionItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.displayedColumns = [
      { field: 'reportLabel', headerName: this._translate.instant('reportLabel'), sortable: true, filter: true, resizable: true, enableRowGroup: true },

      { field: 'reportVersion', headerName: this._translate.instant('reportVersion'), sortable: true, filter: true, resizable: true },
      { field: 'accessEntities', headerName: this._translate.instant('accessEntities'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'accessGrp', headerName: this._translate.instant('accessGrp'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'updateDate', headerName: this._translate.instant('updateDate'), type: 'DateFormat', 
      cellRendererParams: {
        array: [this.timeFormat]
      },
         sortable: true, filter: true, resizable: true },
      { field: '', headerName: this._translate.instant('Action'),
        // template: this.getActions(),
        cellRenderer: (params: any) => {
          if ((params.data.reportCompleted !== undefined) && (params.data.reportCompleted === true)) {
            if (this.optionItem === 'reports') {
              return '<button type="button" class="btn btn-default mat-icon-button" data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
                  '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' +
                  '<button type="button" class="btn btn-default mat-icon-button" data-toggle="tooltip" ' +
                  'data-placement="left"  data-action-type="try" title="' + 
                  this._translate.instant('try tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="try" class="fa fa-try" style="font-size: 20px"></span></button>';
            } else  if (this.optionItem === 'validation') {
              return '<button type="button" class="btn btn-default mat-icon-button" data-toggle="tooltip" ' +
                'data-placement="left" data-action-type="try" title="' + 
                this._translate.instant('try tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="try" class="fa fa-try" style="font-size: 20px"></span></button>';
            }
          } else {
            return '<button type="button" class="btn btn-default mat-icon-button"  data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
                  '<button type="button" class="btn btn-default buttonTheme" data-toggle="tooltip" data-action-type="trash" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                 '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>';
          }
        },
 
      },
    ];
    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
 
    };

    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) { return ; }
      if (data.reportStatut === 'PROD') {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#ff9933' };
      }
      if (data.reportCompleted === true) {
        // https://www.rapidtables.com/web/color/green-color.html
      // return { background: '#9ACD32' }
      }
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.rowGroupPanelShow = 'always';
    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getListReports();
 
    this._jobDataService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListReports();
      }
      if (event === 'deleteRegister') {
        this.getListReports();
      }
    });
    this.userLangCur = this._userService.getUserLogged().userlang;
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  getColDef() {
    return this.displayedColumns;
  }


  getListReports() {
    this.selected = [];
    this._jobDataService.getListReports(this.optionItem)
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {
    this.modalRef.hide();
  }
  acceptRepotModal() {
    this.register('PROD');
    this.modalRef.hide();
  }
  rejectReportModal() {
    this.register('DEV');
    this.modalRef.hide();
  }
  register(_statut: string) {

    this.reportToUpdate.reportStatut = _statut;
    this._jobDataService.saveReport(this.reportToUpdate, this.optionItem)
    // .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {
              this._jobDataService.onRegisterValid.emit('closeRegister');
        },
        () => {

        }
      );
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionType = e.event.target.getAttribute('data-action-type');

        switch (actionType) {
            case 'edit': {
                this.editReport(data);
                break;
            }
            case 'trash': {
                this.deleteReport(data);
                break;
            }
            case 'try': {
              if (!data.reportCompleted) { break; }
              this.isReportDoc = false;
              this.isDashboard = false;
              this.isReportDoc = this.getOption(data.reportOptions, 'documents');
              this.isDashboard = this.getOption(data.reportOptions, 'dashboard');
              this.tryReport(data);
              this.openModal(this.modalTestReport);
              break;
            }

        }
    }
  }
  getOption(_reportOptions: any, _option: string): boolean {

    let isOption = false;
    
    if (_reportOptions) {
      try {
        const jsonObject = JSON.parse(_reportOptions);
        for (const optCur  of jsonObject.options) {
          
          // console.log(optTmp.key + ' ' + optTmp.value);

          if (optCur.key === _option) {
            isOption = optCur.value;
          }
        }      
        // this.detailRowHeight = 260;
        // this.detailCellRenderer = 'datadetailRendererComponent';
      } catch (error) {
        console.log(error);
      }
    }
    return isOption;
  }
  
  onSelectionChanged($event: any) {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }

  translateLabels() {

    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateReport = [...Array(_dataArray.length)].map( x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  doAction(row: any): boolean {
    if (row.master) {
      return false;
    } else {
      return true;
    }
  }


  toggleSidenav(_arg: string) {

      this.isRegisterReport = false;

      if (_arg === 'registerReport') {
        this.isRegisterReport = true;
      }
      this.sidenavCollapsed = !this.sidenavCollapsed;
      if (this.sidenavCollapsed) {
        this.m_sidenav.close();
      } else {
        this.m_sidenav.open();
      }
    }


  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }

  refresh() {
    this.getListReports();
  }
  deleteReport(row: any) {
    // console.log('deleteEntity Row clicked: ', row);

    this._jobDataService.deleteReport(row.id)
      .subscribe(
        () => {
          this._jobDataService.onRegisterValid.emit('deleteRegister');
        },
        // () => this.registrationFailed = true
      );

  }
  tryReport(row: any) {
    if (!row.reportCompleted) { return; }
    this.reportToUpdate = row;
    this.modalText = [this.reportToUpdate.reportLabel];
    this.statutRegister = 'updateReport';
  }
  editReportAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.reportToUpdate = selectedRow;
    this.statutRegister = 'updateReport';
    this.doCreateReport = false;
    this.toggleSidenav('registerReport');
  }
  editReport(row: ReportInt) {
    this.reportToUpdate = row;
    this.statutRegister = 'updateReport';
    this.doCreateReport = false;
    this.toggleSidenav('registerReport');
  }
  addReport() {
    // console.log('addEntity : ');
    this.statutRegister = 'createReport';
    this.doCreateReport = true;
    this.reportToUpdate = null;
    this.toggleSidenav('registerReport');
  }

}
