import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges,
            ViewChild, TemplateRef, NgZone, HostListener, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { TranslateService } from '../../../../../services/translate.service';
import { WksCentralService } from '../../../services/wks-central.service';
import { RessourcesService } from '../../../../common/services/ressources.service';

import { GoogleMaps, LatLng, GeoValues, ElemAddr, FieldValue, LabelValue } from '../../../../../models/data.model';
import { GeolocationService } from '../../../../common/services/geolocation.service';
import { WksEntityParams, LabelInt} from '../../../models/wks-common.model';

import { DataLocationWorkModel } from '../../../models/wks-works.model';
import { WksEntityModel } from '../../../models/wks-entity.model';

import { environment } from '../../../../../../environments/environment';


@Component({
  selector: 'mdi-location-wks',
  templateUrl: './location-wks.component.html',
  styleUrls: ['./location-wks.component.less'],
  encapsulation : ViewEncapsulation.None,
})

export class LocationWksComponent implements OnChanges {
  @Input()
  applicationCall!: string;
  @Input()
  dataLocationArg: DataLocationWorkModel;
  @Input()
  readOnlyData: boolean;
  @Input() 
  dataLocationEquip: DataLocationWorkModel[];

  @Output() dataOut = new EventEmitter<any>();

  modalRef: BsModalRef;
  googleMaps: GoogleMaps;
  positionMap: boolean;
  choiceAnchorage: boolean;

  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;

  servicesList: LabelValue[];
  locationForm: FormGroup;

  homeAnchoragesCacheList: LabelValue[];
  homeAnchoragesFilteredList: LabelValue[];
  
  anotherCacheList: LabelValue[];
  anotherFilteredList: LabelValue[];

  anchoragesCacheList: LabelValue[];
  anchoragesFilteredList: LabelValue[];
  winteringsCacheList: LabelValue[];
  winteringsFilteredList: LabelValue[];
  scrHeight: string;
  scrWidth: string;
  sizeWidth: number;
  locationAddress: boolean;
  locationWorkshop: boolean;
  locationHomeAnchorage: boolean;
  locationAtSea: boolean;
  locationMooring: boolean;
  locationWintering: boolean;
  locationAnother: boolean;
  incompleteInput: boolean;
  choiceLocation: boolean;
  // bloc homeAnchorage
  rowsHomeAnchorage: any[];
  homeAnchorageBlockFg: FormGroup;
  // bloc mooring
  rowsMooring: any[];
  mooringBlockFg: FormGroup;
  // bloc wintering
  rowsWintering: any[];
  winteringBlockFg: FormGroup;
  // bloc another
  rowsAnother: any[];
  anotherBlockFg: FormGroup;
  // bloc address
  rowsAddress: any[];
  addressBlockFg: FormGroup;
  // arabic numbers
  arabicsToLatin: LabelInt[];
  latinToArabics: LabelValue[];
  hindusArabicNumbers: boolean;

  wksEntityParams: WksEntityParams;
  wksEntity: WksEntityModel;
  countryFmt: any;


  isRequest: boolean;
  isWork: boolean;
  isEquipment: boolean;

  locationCache: DataLocationWorkModel;
  locationInput: DataLocationWorkModel;

  initElements: boolean;
  typeLocation: string; 

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
        this.scrHeight = window.innerHeight + 'px';
        this.scrWidth = window.innerWidth + 'px';
        this.sizeWidth = window.innerWidth;
  }

  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private ressourceService: RessourcesService,
    private wksCentralService: WksCentralService,
    private geolocationService: GeolocationService,

    private coordinatesService: CoordinatesService) {
      this.getScreenSize();
      //  console.log('Window size ' + this.scrHeight, this.scrWidth);
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.initGooglesMaps();

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'applicationCall': {
            this.applicationCall = changes['applicationCall'].currentValue;
            break;
          }
          case 'dataLocationArg': {
            this.dataLocationArg = changes['dataLocationArg'].currentValue;
            break;
          }
          case 'readOnlyData': {
            this.readOnlyData = changes['readOnlyData'].currentValue;
            break;
          }
          /*
          case 'choiceLocation': {
            this.choiceLocation = changes['choiceLocation'].currentValue;
            break;
          }
          */
          case 'dataLocationEquip': {
            this.dataLocationEquip = changes['dataLocationEquip'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    
    this.initComponent();
  }
  initComponent(): void {
   
    this.initBooleans();
    this.settingHindusArabic();
    if (this.initData()) {
      this.buildFormBase();
      this.fillForm();
      this.initElements = true;
    } else  {
      return;
    }
  }
  initBooleans(): void {
    this.isEquipment = false;
    this.isRequest = false;
    this.isWork = false;
    this.locationAddress = false;
    this.locationWorkshop = false;
    this.locationHomeAnchorage = false;
    this.locationAtSea = false;
    this.locationMooring = false;
    this.locationWintering = false;
    this.locationAnother = false;
    this.incompleteInput = true;
    this.hindusArabicNumbers = false;
    this.positionMap = false;
    this.initElements = false;
    this.choiceAnchorage = false;
    this.choiceLocation = false;
  }
  initData(): boolean {

    this.initInput(); 
    this.initParams();
    return true;
  }
  initInput() {
    if (this.dataLocationArg !== undefined) {
      this.locationCache = JSON.parse(JSON.stringify(this.dataLocationArg));
      this.locationInput  = JSON.parse(JSON.stringify(this.dataLocationArg));
    } else {
      this.locationCache = undefined;
      this.locationInput = undefined;
    }
    this.choiceAnchorage = true;

    if (this.applicationCall === 'workIntervention') {
      this.choiceLocation = true;
    }
    if (this.applicationCall === 'appointmentWork') {
      this.choiceLocation = true;
    }
    if (this.locationInput !== undefined)  {
      this.typeLocation = this.locationInput.typeLocation;
    }
    switch (this.typeLocation) {
      case 'mooring' : {
        this.locationMooring = true;
        break;
      }
      case 'homeAnchorage' : {
        this.locationHomeAnchorage = true;
        break;
      }
      case 'address' : {
        this.locationAddress = true;
        break;
      }
      case 'wintering' : {
        this.locationWintering = true;
        break;
      }
      case 'workshop' : {
        this.locationWorkshop = true;
        break;
      }
      case 'another' : {
        this.locationAnother = true;
        break;
      }
      default: {
        this.locationWorkshop = true;
        break;
      }
    }
    
  }
  initParams(): void {
    this.wksEntity = this.wksCentralService.getWksEntityCur();
    this.wksEntityParams = this.ressourceService.getWksEntityParams();
    this.countryFmt =  this.ressourceService.getCountryFmt();
    if (this.choiceAnchorage) {
      this.homeAnchoragesCacheList = this.wksCentralService.getWksAnchorages();
      this.homeAnchoragesFilteredList = this.wksCentralService.getWksAnchorages();
      this.anchoragesCacheList = this.wksCentralService.getWksAnchorages();
      this.anchoragesFilteredList = this.wksCentralService.getWksAnchorages();
      this.anotherCacheList = this.wksCentralService.getWksAnchorages();
      this.anotherFilteredList = this.wksCentralService.getWksAnchorages();
      this.winteringsFilteredList = this.wksCentralService.getWksAnchoragesWintering();
      this.winteringsCacheList = this.wksCentralService.getWksAnchoragesWintering();
    }
  }
  buildFormBase(): void {
    this.addressBlockFg = new FormGroup({});
    this.mooringBlockFg = new FormGroup({});
    this.winteringBlockFg = new FormGroup({});
    this.homeAnchorageBlockFg = new FormGroup({});
    this.anotherBlockFg = new FormGroup({});
    this.locationForm = new FormGroup({
      // equipmentLocation: new FormControl(''),
      equipmentPosition: new FormControl(''),
      equipmentPositionCoord: new FormControl(''),
      jobsite: new FormControl(''),
      homeAnchorageBlockFg: this.homeAnchorageBlockFg,
      winteringBlockFg: this.winteringBlockFg,
      anotherBlockFg: this.anotherBlockFg,
      mooringBlockFg: this.mooringBlockFg,
      addressBlockFg: this.addressBlockFg,
    });
  }
  settingHindusArabic(): void {
    this.hindusArabicNumbers = false;
    if (!this.translate.currentLang.startsWith('ar')) {
      return;
    }
    const numbersFmt = this.countryFmt.numbers;
    for (const numberItem of numbersFmt) {
      if (numberItem.name === 'hindusArabics') {
        this.hindusArabicNumbers = (numberItem.value === 'true' ? true : false);
        break;
      }
    }
    if (this.hindusArabicNumbers) {
      const unitsArabicsNumbers = environment.indusArabicsNumbers.units;
      this.arabicsToLatin = [];
      this.latinToArabics = [];
      for (const item of unitsArabicsNumbers) {
        const latin = item.latin;
        const hindus = item.hindus;
        this.arabicsToLatin.push({
          label: item.hindus,
          value: parseInt(item.latin, 10)
        });
        this.latinToArabics.push({
          label: item.latin,
          value: item.hindus
        });
      }
    }
  }
  convertArabicHindusToLatin(value: any): number  {
    let isHindusArabic = true;
    let isFound = false;
    let valueLatin = '';

    const arrayChar = [...value];
    for (const charCur of arrayChar) {
      isFound = false;
      for (const arabicItem of this.arabicsToLatin) {
        if (charCur === arabicItem.label) {
          isFound = true;
          valueLatin += arabicItem.value;
          break;
        }
      }
      if (!isFound) {
        isHindusArabic = false;
        break;
      }
    }
    let latinNumber = -1;
    if (isHindusArabic) {
      try {
        latinNumber = parseInt(valueLatin, 10);
      } catch (err) {
      }
    }
    return  latinNumber;
  }
  /*
  subscribeForm(): void {
    this.locationForm.valueChanges.subscribe(x => {
      if (JSON.stringify(this.locationCache) !== JSON.stringify(this.fillModel())) {
        if (this.locationForm.status === 'VALID') {
          this.dataOut.emit(this.fillModel());
        this.locationCache = JSON.parse(JSON.stringify(this.fillModel()));
        }
      }
    });
  }*/
  dataOutForm(): void {
    const modelCard = this.fillModel();
    if (JSON.stringify(this.locationCache) !== JSON.stringify(modelCard)) {
      if (this.locationForm.status === 'VALID') {
        this.dataOut.emit(modelCard);
        this.locationCache = JSON.parse(JSON.stringify(modelCard));
      }
    }
  }
  jobsiteChange($event: any): void {
/*
    if (this.readOnlyData)   {
      return;
    }
  */
    this.locationAddress = false;
    this.locationWorkshop = false;
    this.locationAtSea = false;
    this.locationMooring = false;
    this.locationWintering = false;
    this.locationAnother = false;
    this.locationHomeAnchorage = false;
    this.locationForm.get('equipmentPosition').clearValidators();
    this.typeLocation = $event;
    switch ($event) {
      case 'workshop' : {
        this.locationWorkshop = true;
        this.dataOutForm();
        this.incompleteInput = false;
        break;
      }
      case 'address' : {
        this.locationAddress = true;
        this.incompleteInput = true;
        this.buildAddressBlock();
        // this.locationForm.get('equipmentLocation').setValidators(Validators.required);
        // this.locationForm.get('equipmentPosition').setValidators(Validators.required);
        // this.subscribeForm();
        break;
      }
      case 'atSea' : {
        this.locationAtSea = true;
        this.locationForm.get('equipmentPosition').setValidators(Validators.required);
        break;
      }
      case 'mooring' : {
        this.locationMooring = true;
        this.incompleteInput = true;

        this.buildMooringBlock();
        // this.subscribeForm();

        break;
      }
      case 'homeAnchorage' : {
        this.locationHomeAnchorage = true;
        this.incompleteInput = true;
        this.buildHomeAnchorageBlock();
        // this.subscribeForm();

        break;
      }
      case 'wintering' : {
        this.locationWintering = true;
        this.incompleteInput = true;

        this.buildWinteringBlock();
        // this.subscribeForm();

        break;
      }
      // locationAnother
      case 'another' : {
        this.locationAnother = true;
        this.incompleteInput = true;
        this.buildAnotherBlock();
        // this.subscribeForm();

        break;
      }
      default: {

        break;
      }
    }

  }
  onChangeField(field: string, value: any): void {

  }
  onChangeAnchorage(origine: string, $event: any) {
    this.dataOutForm();
  }

  onKeyWintering(value: any) {
    // console.log(value);
    this.winteringsFilteredList = this.searchWintering(value);
  }
  searchWintering(value: string) { 
    const filter = value.toLowerCase();
    return this.winteringsCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  onKeyAnother(value: any) {
    // console.log(value);
    this.anotherFilteredList = this.searchWintering(value);
  }
  searchAnother(value: string) { 
    const filter = value.toLowerCase();
    return this.anotherCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  onKeyAnchorage(value: any) {
    // console.log(value);
    this.anchoragesFilteredList = this.searchAnchorage(value);
  }
  searchAnchorage(value: string) { 
    const filter = value.toLowerCase();
    return this.anchoragesCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  onKeyHomeAnchorage(value: any) {
    // console.log(value);
    this.homeAnchoragesFilteredList = this.searchHomeAnchorage(value);
  }
  searchHomeAnchorage(value: string) { 
    const filter = value.toLowerCase();
    return this.homeAnchoragesCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  fillForm(): void {

    if (this.choiceLocation)  {
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation !== undefined ) ) {
          this.locationForm.controls.jobsite.setValue(this.locationInput.typeLocation);
      }
    }
    // homeAnchorage
    if (this.locationHomeAnchorage) {
      this.jobsiteChange('homeAnchorage');
    }
    if (this.locationWorkshop) {
      this.jobsiteChange('workshop');
    }
    if (this.locationAddress) {
      this.jobsiteChange('address');
    }
    if (this.locationMooring) {
      this.jobsiteChange('mooring');
    }
    if (this.locationWintering) {
      this.jobsiteChange('wintering');
    }
    if (this.locationAnother) {
      this.jobsiteChange('another');
    }
    // }
  }
  unValidInput(): void {
    this.incompleteInput = true;
  }
  validInput(jobSite: string): void {
    /* if (this.readOnlyData) {
      return;
    }
    */
    this.incompleteInput = true;
    switch (jobSite) {
      case 'workshop' : {
        this.incompleteInput = false;
        break;
      }
      case 'homeAnchorage' : {
        this.incompleteInput = false;
        break;
      }
      case 'address' : {
        if (this.locationForm.get('equipmentPosition').value !== '' ) {
            this.incompleteInput = false;
          }
        break;
      }
      case 'atSea' : {
        this.locationForm.get('equipmentPosition').setValidators(Validators.required);
        if (this.locationForm.get('equipmentPosition').value !== '' ) {
          this.incompleteInput = false;
        }
        break;
      }
      case 'mooring' : {
        this.incompleteInput = false;
        break;
      }
      case 'another' : {
        this.incompleteInput = false;
        break;
      }
      default: {
        this.incompleteInput = true;
        break;
      }
    }
  }
  fillModel(): DataLocationWorkModel {
    // tslint:disable-next-line:no-non-null-assertion
    // const locationValue = this.locationForm.get('equipmentPosition')!.value;
    let portIdCur: string;
    let dataArray: FieldValue[] = [];
    let rowsItem: any[] = [];
    if (this.typeLocation === 'mooring') {
      rowsItem = this.rowsMooring;
      if (this.applicationCall === 'workIntervention') {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'mooring') {
            portIdCur = dataCur.dataLocation.portId;
            break;
          }
        }
      } else {
        portIdCur = this.mooringBlockFg.controls.anchoragePort.value;
      }
    } else if (this.typeLocation === 'workshop') {
      rowsItem = undefined;
      portIdCur = undefined;
      dataArray = undefined;
    } else if (this.typeLocation === 'another') {
        rowsItem = this.rowsAnother;
        portIdCur = this.anotherBlockFg.controls.anotherPort.value;
    } else if (this.typeLocation === 'address') {
      rowsItem = this.rowsAddress;
    } else if (this.typeLocation === 'wintering') {
      if (this.applicationCall === 'workIntervention') {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'wintering') {
            portIdCur = dataCur.dataLocation.portId;
            break;
          }
        }
      } else {
        portIdCur = this.winteringBlockFg.controls.winteringPort.value;
      }

      rowsItem = this.rowsWintering;
    } else if (this.typeLocation === 'homeAnchorage') { 
      if (this.applicationCall === 'workIntervention') {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'homeAnchorage') {
            portIdCur = dataCur.dataLocation.portId;
            break;
          }
        }
      } else {
        portIdCur = this.homeAnchorageBlockFg.controls.homeAnchoragePort.value;
      }
    }

    if (rowsItem !== undefined  && rowsItem.length > 0) {
      for (const rowItem of rowsItem) {
        for (const colItem of rowItem.cols) {
          const dataItem = {
            name: colItem.dataName,
            value: colItem.inputValue,
          };
          dataArray.push(dataItem);
        }
      }
    }
    const returnData: DataLocationWorkModel = {
      typeLocation: this.typeLocation,
      dataLocation:  {
        portId: portIdCur,
        dataFields: dataArray
      }
    };
    return returnData;
  }
   // another block
   buildAnotherBlock(): void {
    const anotherFields = this.wksEntity.mooring;
    if (this.choiceAnchorage) {
      let portCur = '';
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.portId !== undefined ) ) {
          portCur = this.locationInput.dataLocation.portId;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'mooring') {
            portCur = dataCur.dataLocation.portId;
            break;
          }
        }
      }
      this.anotherBlockFg.removeControl('anotherPort');
      this.anotherBlockFg.addControl('anotherPort', new FormControl({value: '', disabled: this.readOnlyData, require: true}));
      this.anotherBlockFg.controls['anotherPort'].patchValue(portCur, { emitEvent: true, onlySelf: false });
    }
    if (anotherFields === undefined) {
      const addrElem = Object.keys(this.anotherBlockFg.controls);
      for (const elem of addrElem) {
        this.anotherBlockFg.removeControl(elem);
      }
      return;
    }
    let iRowCur = 0;
    let iElemCur = 0;
    this.rowsAnother = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    
    for (const elemAnother of anotherFields) {
      let elemCur = elemAnother;
      let controlTypeCur = '';
      if (elemCur.indexOf('|') > -1) {
        const elemBool = elemCur.split('|');
        elemCur = elemBool[0];
        const booleanFields = elemAnother.replace(elemCur, '');
        controlTypeCur = 'boolean' + booleanFields;
      }
      const elemAddr: ElemAddr = {
        elmtNumber: iElemCur.toString(),
        lineNumber: iRowCur.toString(),
        lineDataIndex:  iElemCur.toString(),
        dataName: elemCur,
        dataMandatory: 'N',
        dataInput: 'Y',
        dataValue: '',
        dataType: 'AN',
        dataLengthMax: '60',
        controlType: controlTypeCur,
        controlFmt: '.*',
        displayFmt: '',
        elemCountry: 'N',
        elemLabel: this.translate.instant( elemCur),
        inputValue: '',
        latinValue: undefined,
        inputError: false,
        elemUpperCase: false,
        enable: true,
      };
      iLengthMax = 50;
      let dataFields: FieldValue[] ;
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.dataFields !== undefined ) ) {
          dataFields = this.locationInput.dataLocation.dataFields;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'mooring') {
            dataFields = JSON.parse(JSON.stringify(dataCur.dataLocation.dataFields));
            break;
          }
        }
      }
      if ((dataFields !== undefined) && (dataFields !== null)) {
        for (const localDataItem of dataFields )  {
          if (localDataItem.value === '') {
            continue;
          }
          if (elemAddr.dataName === localDataItem.name) {
            elemAddr.inputValue = localDataItem.value;
            elemAddr.elemLabel = this.translate.instant((localDataItem.label ? localDataItem.label : localDataItem.name)) ;
            break;
          }
        }
      }

      colsArray.push(elemAddr);
      // if ((iRowBuild > 0) && (iRowCur !== iRowBuild)) {
      this.rowsAnother.push({
        row: Number(iRowCur).toString,
        lengthMax: iLengthMax,
        cols: colsArray,
      });
      colsArray = [];
      iRowBuild = iRowCur;
      iLengthMax = 0;
      // }
      if (iElemCur === 0 && !this.readOnlyData) {
        // {value: '', disabled: this.isReadOnly}
        this.anotherBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.readOnlyData, require: true}));
        // this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl('', Validators.required));
      } else  {
        this.anotherBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.readOnlyData}));
        // this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl(''));
      }
      this.anotherBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      iRowCur++;
      iElemCur++;
    }

  }
  // wintering block
  buildWinteringBlock(): void {
    const winteringFields = this.wksEntity.wintering;
    if (this.choiceAnchorage) {
      let portCur = '';
      let portLabel = '';
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.portId !== undefined ) ) {
          portCur = this.locationInput.dataLocation.portId;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'wintering') {
            portCur = dataCur.dataLocation.portId;
            break;
          }
        }
      }
      portLabel = '' + portCur;
      let readOnlyLoc = this.readOnlyData;
      if (this.applicationCall === 'workIntervention') {
        for (const labelCur of this.winteringsFilteredList )  {
          if (labelCur.value === portCur) {
            portLabel = labelCur.label;
            break;
          }
        }
        readOnlyLoc = true;
      }
      this.winteringBlockFg.removeControl('winteringPort');
      this.winteringBlockFg.addControl('winteringPort', new FormControl({value: '', disabled: readOnlyLoc, require: true}));
      this.winteringBlockFg.controls['winteringPort'].patchValue(portLabel, { emitEvent: true, onlySelf: false });
    }
    if (winteringFields === undefined) {
      const addrElem = Object.keys(this.winteringBlockFg.controls);
      for (const elem of addrElem) {
        this.winteringBlockFg.removeControl(elem);
      }
      return;
    }
    let iRowCur = 0;
    let iElemCur = 0;
    this.rowsWintering = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    
    for (const elemWintering of winteringFields) {
      let elemCur = elemWintering;
      let controlTypeCur = '';
      if (elemCur.indexOf('|') > -1) {
        const elemBool = elemCur.split('|');
        elemCur = elemBool[0];
        const booleanFields = elemWintering.replace(elemCur, '');
        controlTypeCur = 'boolean' + booleanFields;
      }
      const elemAddr: ElemAddr = {
        elmtNumber: iElemCur.toString(),
        lineNumber: iRowCur.toString(),
        lineDataIndex:  iElemCur.toString(),
        dataName: elemCur,
        dataMandatory: 'N',
        dataInput: 'Y',
        dataValue: '',
        dataType: 'AN',
        dataLengthMax: '60',
        controlType: controlTypeCur,
        controlFmt: '.*',
        displayFmt: '',
        elemCountry: 'N',
        elemLabel: this.translate.instant( elemCur),
        inputValue: '',
        latinValue: undefined,
        inputError: false,
        elemUpperCase: false,
        enable: true,
      };
      iLengthMax = 50;
      let dataFields: FieldValue[] ;
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.dataFields !== undefined ) ) {
          dataFields = this.locationInput.dataLocation.dataFields;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'wintering') {
            dataFields = JSON.parse(JSON.stringify(dataCur.dataLocation.dataFields));
            break;
          }
        }
      }
      if ((dataFields !== undefined) && (dataFields !== null)) {
        for (const localDataItem of dataFields )  {
          if (localDataItem.value === '') {
            continue;
          }
          if (elemAddr.dataName === localDataItem.name) {
            elemAddr.inputValue = localDataItem.value;
            elemAddr.elemLabel = this.translate.instant((localDataItem.label ? localDataItem.label : localDataItem.name)) ;
            break;
          }
        }
      }

      colsArray.push(elemAddr);
      // if ((iRowBuild > 0) && (iRowCur !== iRowBuild)) {
      this.rowsWintering.push({
        row: Number(iRowCur).toString,
        lengthMax: iLengthMax,
        cols: colsArray,
      });
      colsArray = [];
      iRowBuild = iRowCur;
      iLengthMax = 0;
      // }
      let readOnlyLoc = this.readOnlyData;
      if (this.applicationCall === 'workIntervention') {
        readOnlyLoc = true;
      }
      if (iElemCur === 0 && !readOnlyLoc) {
        this.winteringBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: readOnlyLoc, require: true}));
      } else  {
        this.winteringBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: readOnlyLoc}));
      }
      this.winteringBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      iRowCur++;
      iElemCur++;
    }
    if (this.applicationCall === 'workIntervention' ) {
      this.dataOutForm();
    }
  }
  // buildHomeAnchorageBlock
  buildHomeAnchorageBlock(): void {

    if (this.choiceAnchorage) {
      let portCur = '';
      if ((this.locationInput !== undefined) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.portId !== undefined ) ) {
          portCur = this.locationInput.dataLocation.portId;
      }
      this.homeAnchorageBlockFg.removeControl('homeAnchoragePort');
      this.homeAnchorageBlockFg.addControl('homeAnchoragePort', new FormControl({value: '', disabled: this.readOnlyData, require: true}));
      this.homeAnchorageBlockFg.controls['homeAnchoragePort'].patchValue(portCur, { emitEvent: true, onlySelf: false });
    }
  }
  // mooring block
  buildMooringBlock(): void {
    const mooringFields = this.wksEntity.mooring;
    if (this.choiceAnchorage) {
      let portCur = '';
      let portLabel = '';
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.portId !== undefined ) ) {
          portCur = this.locationInput.dataLocation.portId;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'mooring') {
            portCur = dataCur.dataLocation.portId;
            break;
          }
        }
      }
      portLabel = '' + portCur;
      let readOnlyLoc = this.readOnlyData;
      if (this.applicationCall === 'workIntervention') {
        for (const labelCur of this.anchoragesFilteredList )  {
          if (labelCur.value === portCur) {
            portLabel = labelCur.label;
            break;
          }
        }
        readOnlyLoc = true;
      }
      this.mooringBlockFg.removeControl('anchoragePort');
      this.mooringBlockFg.addControl('anchoragePort', new FormControl({value: '', disabled: readOnlyLoc, require: true}));
      this.mooringBlockFg.controls['anchoragePort'].patchValue(portLabel, { emitEvent: true, onlySelf: false });
    }
    if (mooringFields === undefined) {
      const addrElem = Object.keys(this.mooringBlockFg.controls);
      for (const elem of addrElem) {
        this.mooringBlockFg.removeControl(elem);
      }
      return;
    }
    let iRowCur = 0;
    let iElemCur = 0;
    this.rowsMooring = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    
    for (const elemMooring of mooringFields) {
      let elemCur = elemMooring;
      let controlTypeCur = '';
      if (elemCur.indexOf('|') > -1) {
        const elemBool = elemCur.split('|');
        elemCur = elemBool[0];
        const booleanFields = elemMooring.replace(elemCur, '');
        controlTypeCur = 'boolean' + booleanFields;
      }
      const elemAddr: ElemAddr = {
        elmtNumber: iElemCur.toString(),
        lineNumber: iRowCur.toString(),
        lineDataIndex:  iElemCur.toString(),
        dataName: elemCur,
        dataMandatory: 'N',
        dataInput: 'Y',
        dataValue: '',
        dataType: 'AN',
        dataLengthMax: '60',
        controlType: controlTypeCur,
        controlFmt: '.*',
        displayFmt: '',
        elemCountry: 'N',
        elemLabel: this.translate.instant( elemCur),
        inputValue: '',
        latinValue: undefined,
        inputError: false,
        elemUpperCase: false,
        enable: true,
      };
      iLengthMax = 50;
      let dataFields: FieldValue[] ;
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.dataFields !== undefined ) ) {
          dataFields = this.locationInput.dataLocation.dataFields;
      } else if (this.applicationCall === 'workIntervention' && this.dataLocationEquip !== undefined) {
        for (const dataCur of this.dataLocationEquip) {
          if (dataCur.typeLocation === 'mooring') {
            dataFields = JSON.parse(JSON.stringify(dataCur.dataLocation.dataFields));
            break;
          }
        }
      }
      if ((dataFields !== undefined) && (dataFields !== null)) {
        for (const localDataItem of dataFields )  {
          if (localDataItem.value === '') {
            continue;
          }
          if (elemAddr.dataName === localDataItem.name) {
            elemAddr.inputValue = localDataItem.value;
            elemAddr.elemLabel = this.translate.instant((localDataItem.label ? localDataItem.label : localDataItem.name)) ;
            break;
          }
        }
      }
      colsArray.push(elemAddr);
      // if ((iRowBuild > 0) && (iRowCur !== iRowBuild)) {
      this.rowsMooring.push({
        row: Number(iRowCur).toString,
        lengthMax: iLengthMax,
        cols: colsArray,
      });
      colsArray = [];
      iRowBuild = iRowCur;
      iLengthMax = 0;
      // }
      let readOnlyLoc = this.readOnlyData;
      if (this.applicationCall === 'workIntervention') {
        readOnlyLoc = true;
      }
      if (iElemCur === 0 && !readOnlyLoc) {
        this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: readOnlyLoc, require: true}));
      } else  {
        this.mooringBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: readOnlyLoc}));
      }
      this.mooringBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      iRowCur++;
      iElemCur++;
    }
    if (this.applicationCall === 'workIntervention' ) {
      this.dataOutForm();
    }
  }
  buildAddressBlock(): void {

    const localAddress = this.wksEntity.localAddress;
    const elemsAddr = localAddress.elemAddr;
    // const rowsUpperCase = localAddress.uppercaseLastLines.split(';') as string[];
    if (elemsAddr === undefined) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      return;
    }
    
    // console.log(elemsAddr);
    let iRow = 0;

    let iRowCur = 0;
    this.rowsAddress = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;

    let isMandatory = false;
    for (const elemAddr of elemsAddr) {
      iRowCur++;

      isMandatory = (elemAddr.dataMandatory !== 'N');
      elemAddr.inputValue = '';
      // console.log(elemAddr.dataName);
      if ((elemAddr.controlType === null) || (elemAddr.controlType === '')) {
        elemAddr.controlType = elemAddr.dataName;
      }
      iRow = Number(elemAddr.lineNumber);
      /*if (elemAddr.controlType === 'statesList') {
        this.buildStatesList(address.statesList);
        this.addressBlockFg.addControl('filterState', new FormControl());
      }*/
      if (elemAddr.dataInput === 'N') { continue; }
      if (elemAddr.dataInput === 'proID') { continue; }
      if (elemAddr.dataInput === 'individualID') { continue; }
      
      let isFound = false;
      if ((this.locationInput !== undefined) 
        && (this.locationInput.typeLocation === this.typeLocation ) 
        && (this.locationInput.dataLocation !== undefined ) 
        && (this.locationInput.dataLocation.dataFields !== undefined ) ) {
        for (const localDataItem of this.locationInput.dataLocation.dataFields )  {
          if (localDataItem.value === '') {
            continue;
          }
          if (elemAddr.dataName === localDataItem.name) {
            elemAddr.inputValue = localDataItem.value;
            elemAddr.elemLabel = localDataItem.label;
            isFound = true;
            break;
          }
        }
        if (!isFound ) {
          continue;
        }
      }
      
      if ((iRowBuild > 0) && (iRow !== iRowBuild)) {
        this.rowsAddress.push({
          row: Number(iRow).toString,
          lengthMax: iLengthMax,
          cols: colsArray,
        });
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      if (iRowBuild < 0) {
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      iLengthMax += Number(elemAddr.dataLengthMax);
      const labelElem = this.translate.instant(elemAddr.dataName.toLowerCase());

      if (labelElem === elemAddr.dataName) {
        elemAddr.elemLabel = ''; // if label is ''
      } else {
        elemAddr.elemLabel = labelElem;
      }

      elemAddr.elemUpperCase = false;

      colsArray.push(elemAddr);
      if (isMandatory) {
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.readOnlyData, require: true}));
        // this.addressBlockFg.addControl(elemAddr.dataName, new FormControl('', Validators.required));
      } else {
        // this.addressBlockFg.addControl(elemAddr.dataName, new FormControl(''));
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.readOnlyData}));
      }
      this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });

    } // elem address loop
    this.rowsAddress.push({
      row: Number(iRow).toString,
      lengthMax: iLengthMax,
      cols: colsArray,
    });
    // this.appointmentRequest.equipmentLocation = '';
  }
  calWidthCol(cols: any[], dataLengthMax: number, lengthMax: number): string {

    let fieldSize = dataLengthMax * 8;
    // padding left / right
    // if (fieldSize < 90 ) {
      fieldSize = fieldSize + (15 * 2);
    // }
    return fieldSize.toString() + 'px';
  }
  getColRowAddr(cols: any[]): any {
    // console.log(cols.length);
    return 'col';

  }
  formatFieldAnother(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;
 
    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.anotherBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAnother[iRow].cols[iCol].inputValue = valueReturn;

      if (this.rowsAnother[iRow].cols[iCol].controlType.startsWith('boolean')) {
        const elemsBool = this.rowsAnother[iRow].cols[iCol].controlType.split('|');
        for (const elmCur of elemsBool) {
            if (elmCur === 'boolean' ) {
              continue;
            }
            if (valueReturn === '') {
              this.anotherBlockFg.controls[elmCur].enable();
            } else  {
              this.anotherBlockFg.controls[elmCur].disable();
            }
        }
      }
      // this.verifMooring(iRow, iCol);
      
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;
    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;

    if (isNumeric && this.hindusArabicNumbers ) {
      this.anotherBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsAnother[iRow].cols[iCol].inputValue = value;
      this.rowsAnother[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.anotherBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAnother[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsAnother[iRow].cols[iCol].latinValue = undefined;
    }

    // this.verifMooring(iRow, iCol);

    return;
  }
  formatFieldMooring(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;
 
    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;

      if (this.rowsMooring[iRow].cols[iCol].controlType.startsWith('boolean')) {
        const elemsBool = this.rowsMooring[iRow].cols[iCol].controlType.split('|');
        for (const elmCur of elemsBool) {
            if (elmCur === 'boolean' ) {
              continue;
            }
            if (valueReturn === '') {
              this.mooringBlockFg.controls[elmCur].enable();
            } else  {
              this.mooringBlockFg.controls[elmCur].disable();
            }
        }
      }
      // this.verifMooring(iRow, iCol);
      
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;
    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    // this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
    // this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
    if (isNumeric && this.hindusArabicNumbers ) {
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsMooring[iRow].cols[iCol].inputValue = value;
      this.rowsMooring[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsMooring[iRow].cols[iCol].latinValue = undefined;
    }

    // this.verifMooring(iRow, iCol);

    return;
  }
  /*
  verifMooring(iRow: number, iCol: number): void  {
    let adressFields: FieldValue[];
    adressFields = [];
    let mandatoryFieldsOK = true;
    this.incompleteInput = false;
    let mooringCur = '';
    let mooringData = '';
    let iData = 0;
    let iDataPresent = 0;
    for ( const rowCur of this.rowsMooring ) {
      for (const colCur of rowCur.cols ) {
        if ((colCur.dataMandatory === 'O') && ( this.mooringBlockFg.controls[colCur.dataName].enabled)) {
          if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
            this.incompleteInput = true;
            mandatoryFieldsOK = false;
          }
        }
        if ((colCur.inputValue !== '') && (colCur.inputValue !== undefined)) {
          iDataPresent++;
        }
        let valueCur = colCur.inputValue;
        if (valueCur === undefined) {
          valueCur = '';
        }
        if (iData === 0) {
          mooringCur += valueCur;
        } else {
          mooringCur += ',' + valueCur;
        }
        if (iData === 0) {
          mooringData = colCur.elemLabel + ' : ' + valueCur;
          mooringCur += valueCur;
        } else {
          mooringData  += ',' + colCur.elemLabel + ' : ' + valueCur;
          mooringCur += ',' + valueCur;
        }

        adressFields.push( {
          label: colCur.elemLabel,
          name: colCur.dataName,
          value: colCur.inputValue,
          latin: colCur.latinValue
        });
        iData++;
      }
    }

    if (mandatoryFieldsOK) {
      // this.appointmentRequest.equipmentLocation = mooringData;
      // this.appointmentRequest.equipmentLocation = JSON.stringify(adressFields);
      this.dataOutForm();
      this.incompleteInput = false;
    }

  }
  */
  formatFieldWintering(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;
 
    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.winteringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsWintering[iRow].cols[iCol].inputValue = valueReturn;

      if (this.rowsWintering[iRow].cols[iCol].controlType.startsWith('boolean')) {
        const elemsBool = this.rowsWintering[iRow].cols[iCol].controlType.split('|');
        for (const elmCur of elemsBool) {
            if (elmCur === 'boolean' ) {
              continue;
            }
            if (valueReturn === '') {
              this.winteringBlockFg.controls[elmCur].enable();
            } else  {
              this.winteringBlockFg.controls[elmCur].disable();
            }
        }
      }
      // this.verifWintering(iRow, iCol);
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;
    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    // this.mooringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
    // this.rowsMooring[iRow].cols[iCol].inputValue = valueReturn;
    if (isNumeric && this.hindusArabicNumbers ) {
      this.winteringBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsWintering[iRow].cols[iCol].inputValue = value;
      this.rowsWintering[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.winteringBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsWintering[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsWintering[iRow].cols[iCol].latinValue = undefined;
    }

    // this.verifWintering(iRow, iCol);

    return;
  }
  /*
  verifWintering(iRow: number, iCol: number): void  {
    let adressFields: FieldValue[];
    adressFields = [];
    let mandatoryFieldsOK = true;
    this.incompleteInput = false;
    let mooringCur = '';
    let mooringData = '';
    let iData = 0;
    let iDataPresent = 0;
    for ( const rowCur of this.rowsWintering ) {
      for (const colCur of rowCur.cols ) {
        if ((colCur.dataMandatory === 'O') && ( this.winteringBlockFg.controls[colCur.dataName].enabled)) {
          if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
            this.incompleteInput = true;
            mandatoryFieldsOK = false;
          }
        }
        if ((colCur.inputValue !== '') && (colCur.inputValue !== undefined)) {
          iDataPresent++;
        }
        let valueCur = colCur.inputValue;
        if (valueCur === undefined) {
          valueCur = '';
        }
        if (iData === 0) {
          mooringCur += valueCur;
        } else {
          mooringCur += ',' + valueCur;
        }
        if (iData === 0) {
          mooringData = colCur.elemLabel + ' : ' + valueCur;
          mooringCur += valueCur;
        } else {
          mooringData  += ',' + colCur.elemLabel + ' : ' + valueCur;
          mooringCur += ',' + valueCur;
        }

        adressFields.push( {
          label: colCur.elemLabel,
          name: colCur.dataName,
          value: colCur.inputValue,
          latin: colCur.latinValue
        });
        iData++;
      }
    }

    if (mandatoryFieldsOK) {
      // this.appointmentRequest.equipmentLocation = mooringData;
      // this.appointmentRequest.equipmentLocation = JSON.stringify(adressFields);
      this.dataOutForm();
      this.incompleteInput = false;
    }
  }
  */
  // formatField(typeRaw: string, iRow: number, iCol: number, paramsCur: any, { target = {} as HTMLInputElement }): void {
  formatFieldAddress(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;

    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
      this.verifAddress(iRow, iCol);
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      if (this.hindusArabicNumbers ) {
        inputValue = this.convertArabicHindusToLatin(inputValue).toString();
      } else {
        inputValue = inputValue.replace(/\D/g, '');
      }
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;

    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    if (isNumeric && this.hindusArabicNumbers ) {
      this.addressBlockFg.controls[paramsCur.dataName].setValue(value);
      this.rowsAddress[iRow].cols[iCol].inputValue = value;
      this.rowsAddress[iRow].cols[iCol].latinValue = valueReturn;
    } else {
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
      this.rowsAddress[iRow].cols[iCol].latinValue = undefined;
    }

    this.verifAddress(iRow, iCol);

    return;
  }
  verifAddress(iRow: number, iCol: number): void  {
    // if (iRow === this.rowsAddress.length - 1) {
      let adressFields: FieldValue[];
      adressFields = [];
      let mandatoryFieldsOK = true;
      this.incompleteInput = false;
      // if (iCol === this.rowsAddress[iRow].cols.length - 1) {
      let addressCur = '';
      let addressData = '';
      let iData = 0;
      for ( const rowCur of this.rowsAddress ) {
        for ( const colCur of rowCur.cols ) {
          if (colCur.dataMandatory === 'O') {
            if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
              this.incompleteInput = true;
              mandatoryFieldsOK = false;
            }
          }
          let valueCur = colCur.inputValue;
          if (valueCur === undefined) {
            valueCur = '';
          }
          if (iData === 0) {
            addressData = colCur.elemLabel + ' : ' + valueCur;
            addressCur += valueCur;
          } else {
            addressData  += ',' + colCur.elemLabel + ' : ' + valueCur;
            addressCur += ',' + valueCur;
          }
          adressFields.push( {
            label: colCur.elemLabel,
            name: colCur.dataName,
            value: colCur.inputValue,
            latin: colCur.latinValue,
          });

          iData++;

        }
      }
      if (mandatoryFieldsOK) {
        // this.appointmentRequest.equipmentLocation = addressData;
        // this.appointmentRequest.equipmentLocation = JSON.stringify(adressFields);
        // this.addressToCoordinates(addressCur);
        this.dataOutForm();
      }
      // }
    // }
  }
// ================================ maps ==
  getReturnMap($event: any): void {
    this.closeModal();
    if ($event !== undefined)  {
      try  {
        const latLng = JSON.parse($event.latLng) as LatLng;
        if ((latLng.lat !== undefined) && (latLng.lng !== undefined)) {
          this.displayLatLng(latLng);
        }
        /*if ($event.address) {
          this.locationForm.controls.equipmentLocation.setValue($event.address);
        }*/
        this.validInput(this.locationForm.controls.jobsite.value);
      }
      finally {
      }
    }
  }
  displayLatLng(coord: LatLng): void {
    const values: string[] = [];
    values[0] = coord.lat.toString();
    values[1] = coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.positionMap = true;
      this.locationForm.controls.equipmentPosition.setValue(values[0] + ',' + values[1]);
      this.locationForm.controls.equipmentPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.positionMap = false;
    }
  }
  getLatLng(values: string[]): any[] {
    const latLng = [];
    const lat = Number.parseFloat(values[0]);
    const lng = Number.parseFloat(values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this.coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this.coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }
  checkGeoLocAddress(event: Event): void {

    if ((this.locationForm.controls.equipmentLocation.value !== null)
        && (this.locationForm.controls.equipmentLocation.value !== '')) {
      this.searchLatLngMap(this.locationForm.controls.equipmentLocation.value);
    } else if (this.locationForm.controls.equipmentPosition.value !== null) {
      this.displayMap(this.locationForm.controls.equipmentPosition.value);
    }

  }
  addressToCoordinates(address: string): void {
    this.incompleteInput = true;
    let geoValue: GeoValues = {
    };
    this.geolocationService.addressTolatLng(address)
    .subscribe((geoValues: GeoValues) => {
        if (geoValues.status === 'OK') {
          geoValue = {
            latLng: geoValues.latLngs[0],
            address: geoValues.addresses[0],
          };
          this.displayLatLng(geoValue.latLng);
          this.validInput(this.locationForm.controls.jobsite.value);
        }
        // this.locationForm.controls.equipmentLocation.setValue(geoValue.address);

        // this.validInput(this.locationForm.controls.jobsite.value);
        /*
        Object.keys(this.locationForm.controls).forEach(field => { // {1}
          const control = this.locationForm.get(field);            // {2}
          control.markAsTouched({ onlySelf: true });       // {3}
        });
        */
      },
      (err) => {
        // alert('Geocode was not successful for the following reason: ' + status);
      }
    );
  }
  coordinatesToAddress(positionCur: LatLng): void {
    let geoValue: GeoValues = {
    };
    const latLngCur = new google.maps.LatLng(positionCur.lat, positionCur.lng);

    this.geolocationService.latLngToAddress(latLngCur)
    .subscribe((geoValues: GeoValues) => {
        if (geoValues.status === 'OK') {
          geoValue = {
            address: geoValues.addresses[0],
          };
        }
        return geoValue;
      },
      (err) => {
        // alert('Geocode was not successful for the following reason: ' + status);
      }
    );
  }
  displayMap(positionCur: string): void {

    let latLng: LatLng;
    const values = positionCur.split(',');
    latLng = {
        lat: Number.parseFloat(values[0]),
        lng: Number.parseFloat(values[1])
    };
    if (positionCur === '') {
      this.googleMaps = {
        isModal: true,
        displayLocal: true,
        style: {
          width: this.scrWidth,
          height: this.scrHeight,
        },
        // displayLatLng , getLatLng
        action: 'getLatLng',
        position: undefined,
      markers:  [],
      };
    } else {
      this.googleMaps = {
        isModal: true,
        displayLocal: true,
        style: {
          width: this.scrWidth,
          height: this.scrHeight,
        },
        // displayLatLng , getLatLng
        action: 'getLatLng',
        position: latLng,
        mapOptions:  {
          center: latLng,
          zoom : 18
      },
      markers:  [],
      };
      if (positionCur !== undefined) {
        this.googleMaps.markers.push({
          position: latLng,
          title: ''}
        );
      }
    }
    this.openModalMap();
  }
  searchLatLngMap(address: string): void {

    this.googleMaps = {
      isModal: true,
      addressInput: address,
      zoom: 18,
      style: {
        width: this.scrWidth,
        height: this.scrHeight,
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'searchLatLng',
      position: undefined,
      mapOptions:  { },
    markers:  [],
    };

    this.openModalMap();
  }
  openModalMap(): void {

    const configModalMap = {
      class: 'modal-dialog-centered handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  closeModal(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }
}
