<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >
       
        <div *ngIf="!isFinishLoading" class="flex-row row" style="margin: 0px">
            <div class="container-fluid d-flex justify-content-center" >
                <mat-progress-bar 
                    mode="indeterminate"
                    style="margin-left: 30px;">
              </mat-progress-bar>
            </div>
        </div>
        <div *ngIf="isFinishLoading" class="flex-row row dropzone" style="margin: 0px"  draggable="true" #dropArea >
            <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
                <form [formGroup]="docsForm"  >
                    <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                        <mat-card-header>
                            <mat-card-title>
                            <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                            <section class="mat-typography">
                                <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                            </section>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="container-fluid d-flex justify-content-between" >
                                <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                    <mat-icon>arrow_back</mat-icon>
                                    {{ 'goback' | translate }}
                                </button>
                                
                                <button mat-raised-button *ngIf="isInput && isOkToValid"  #buttonSubmit type="submit" (click)="registerDoc()" >
                                    <mat-icon>save</mat-icon>
                                    {{ 'save' | translate }}
                                </button>
                                
                                <button  mat-raised-button *ngIf="isSearch"  #buttonSubmit type="submit" (click)="searching()" >
                                    <mat-icon>search</mat-icon>
                                    {{ 'search' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>

                        <mat-card-content class="stdTheme">
                            <div class="flex-row row">
                                <div class="col-5">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docName' | translate }}</mat-label>
                                        <input id="docName" class="form-control inputTheme" 
                                            (change)="controlValidForm()" formControlName="docName" matInput #input placeholder="{{ 'docName' | translate }}"
                                        required >
                                    </mat-form-field>
                                </div>  
                                <div class="col-2">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docVersion' | translate }}</mat-label>
                                        <input id="docVersion" class="form-control inputTheme" 
                                            (change)="controlValidForm()" formControlName="docVersion" matInput #input placeholder="{{ 'docVersion' | translate }}"
                                         >
                                    </mat-form-field>
                                </div>  
                                <div *ngIf="!isModalBsn && !isModalEquip" class="col-5">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'equipType' | translate }}</mat-label>
                                        <mat-select id="equipType" placeholder="{{ 'Choose type' | translate }}" formControlName="equipType" 
                                            panelClass="mdiselectcontent" #equipType
                                            required
                                            (ngModelChange)="controlValidForm()"
                                            ngDefaultControl >

                                        <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList" 
                                            [value]="equipTypeCur.equipType"  >{{equipTypeCur.labelType}}
                                        </mat-option>
                                    </mat-select> 
                                    </mat-form-field>
                                </div> 
                            </div>
                            <div *ngIf="!isModalBsn && !isModalEquip" class="flex-row row">
                                <div class="col-5" >
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docBrand' | translate }}</mat-label>
                                        <mat-select id="equipBrand" placeholder="{{ 'Choose brand' | translate }}" formControlName="docBrand" 
                                                    panelClass="mdiselectcontent" #equipBrand
                                                    (ngModelChange)="onChangeBrand($event); controlValidForm()"
                                                    ngDefaultControl >
                                            <mat-select-header>
                                                <div style="display: inline-block;" >
                                                    <button mat-icon-button matPrefix aria-label="search" >
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                            (keyup)="onKeyBrand($event)" >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                                [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                            </mat-option>
                                        </mat-select>                         
                                    </mat-form-field>
                                </div>
                                <div class="col-5" >
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docModel' | translate }}</mat-label>

                                        <mat-select id="docModel" placeholder="{{ 'Choose model' | translate }}" formControlName="docModel" 
                                                panelClass="mdiselectcontent"
                                                (ngModelChange)="controlValidForm()"
                                                ngDefaultControl >
                                            <mat-select-header>
                                                <div style="display: inline-block;" >
                                                    <button mat-icon-button matPrefix aria-label="search" >
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <input class="searchTheme" #filtermodel placeholder="{{ 'filterInput' | translate }}" 
                                                            (keyup)="onKeyModel($event)" >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="filtermodel.value" (click)="filtermodel.value=''; onKeyModel('');">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <button mat-icon-button matSuffix aria-label="add" 
                                                        *ngIf="modelFilteredList.length === 0" (click)="addModel(filtermodel.value)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let modelCur of modelFilteredList" 
                                                [value]="modelCur.modelName"  >{{modelCur.modelName}}
                                            </mat-option>
                                        </mat-select>    
                                    </mat-form-field>
                                </div>      
                                <div class="col-2">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docYear' | translate }}</mat-label>
                                        <mat-select id="docYear" placeholder="{{ 'Choose year' | translate }}" 
                                                (ngModelChange)="controlValidForm()" formControlName="docYear" 
                                                panelClass="mdiselectcontent"   ngDefaultControl  >
                                                <mat-option class="selectOptionsTheme" *ngFor="let yearCur of listYears" 
                                                    [value]="yearCur"  >{{yearCur}}
                                                </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>   
                            </div>
                            <div class="flex-row row">
                                <div *ngIf="!isModalBsn && !isModalEquip" class="col-3">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docTags' | translate }}</mat-label>
                                        <mat-select id="docTags" placeholder="{{ 'Choose tag' | translate }}" 
                                                (ngModelChange)="controlValidForm()" formControlName="docTags" 
                                                panelClass="mdiselectcontent"   ngDefaultControl required >
                                                <mat-option class="selectOptionsTheme" *ngFor="let tagCur of classificationList" 
                                                    [value]="tagCur"  >{{tagCur | translate}}
                                                </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-3" >
                                   <!--https://getbootstrap.com/docs/4.4/utilities/spacing/-->
                                    <div class="pt-4 mt-2">
                                        <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" 
                                                                >
                                                    <mat-icon>library_add</mat-icon>
                                            <span>{{'SelectFiles' | translate}}</span>
                                            <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                            <input #fileInput type="file" (change)="onFileChange($event)" [accept]="fileExt" style="display:none;" />
                                        </button>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'docFilename' | translate }}</mat-label>
                                        <input id="docFileName" class="form-control inputTheme" 
                                            (change)="controlValidForm()" formControlName="docFileName"  readonly 
                                            matInput #input placeholder="{{ 'docFilename' | translate }}"
                                            >
                                        </mat-form-field>
                                </div>
                               
                            </div>
                            
                            <div class="flex-row row">
                                
                                <div class="col-12">
                                    <!-- https://stackblitz.com/angular/gdbgelrxapk?file=src%2Fapp%2Ftext-field-autosize-textarea-example.ts-->
                                    <mat-form-field  style="width: 100%">
                                        <mat-label>{{ 'docComment' | translate }}</mat-label>
                                        <textarea id="docComment" class="form-control inputTheme docComment" formControlName="docComment" 
                                                    matInput #docComment placeholder="{{ 'docComment' | translate }}" 
                                                   
                                                  cdkTextareaAutosize
                                                  #autosizeComment="cdkTextareaAutosize"
                                                  cdkAutosizeMinRows="1"
                                                  cdkAutosizeMaxRows="4"></textarea>
                                      </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>

                        <mat-card-actions *ngIf="actionType === 'update'">
                            <div class="container-fluid d-flex justify-content-center" >
                                <button mat-raised-button  #buttonSubmit type="reset" (click)="removeDoc()">
                                    <mat-icon>delete_sweep</mat-icon>
                                    {{ 'Delete' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </form>
            </div>
        </div>

</div>
