import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FieldBase } from '../field-base';
import { FieldsFormgroupService } from '../fields-formgroup.service';

@Component({
  selector: 'mdi-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldBase<any>[] = [];
  @Input() currentFilters: any;

  formFields: FormGroup;
  payLoad = '';

  constructor(private _ffgs: FieldsFormgroupService) {  }

  ngOnInit() {
    this.formFields = this._ffgs.toFormGroup(this.fields);
    if (this.currentFilters) {
      this.formFields.setValue(this.currentFilters);
    }
   // console.log(this.formFields.value);
  }
  applyFilter() {
    this._ffgs.onFiltersChanged.emit(this.formFields.value); // publish changes
  }
  onSubmit() {
    this.payLoad = JSON.stringify(this.formFields.value);
    this._ffgs.onFiltersChanged.emit(this.formFields.value); // publish changes
    this._ffgs.onFiltersClosed.emit('closedFilters');
  }

}
