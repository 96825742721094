import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { MdePopoverTrigger } from '@material-extended/mde';

import { CommonMethods } from '../../../../common/tools/commonMethods';
import { UserService } from '../../../../../services/user.service';
import { TranslateService } from '../../../../../services/translate.service';
import { WksCentralService } from '../../../services/wks-central.service';
import { CalendarService } from '../../../../common/services/calendar.service';
import { RessourcesService } from '../../../../../job/common/services/ressources.service';
import { ModalCommonComponent } from '../../../../../job/common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../models/wks-entity.model';
import { UserResponse } from '../../../../../models/user.model';
import { InternationalsFormats } from '../../../../../models/data.model';
import { AppointmentModel } from '../../../models/wks-appointments.model';
import { WksEntityParams } from '../../../models/wks-common.model';
import { CalendarEvt, CalendarEventBdd, EventChanged, WorkingTime } from '../../../../../models/common.model';
import { environment } from '../../../../../../environments/environment';


@Component({
  selector: 'mdi-appointments-planning',
  templateUrl: './appointments-planning.component.html',
  styleUrls: ['./appointments-planning.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppointmentsPlanningComponent implements OnInit {

  @ViewChild(MdePopoverTrigger, { static: false }) localPopover: MdePopoverTrigger;
  @ViewChild('detailPopover', { static: false }) detailPopover: MdePopoverTrigger;

  private readonly onDestroy = new Subject<void>();
  requestsLoaded: boolean;
  planningLoaded: boolean;
  dotsLine: string;
  cacheDatatemp: any[];
  appointmentsList: AppointmentModel[];
  eventsList: CalendarEvt[];
  calendardEventLBddist: CalendarEventBdd[];
  appointmentsCur: AppointmentModel;
  wksEntity: WksEntityModel;
  mechanicUsers: UserResponse[];
  rows = new Array<AppointmentModel>();
  
  actorSelected: UserResponse;
  selectedRow: number;
  wksEntityParams: WksEntityParams;
  displayPopover: boolean;

  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;
  doLoadCalendar: boolean;
  workingTime: WorkingTime;

  constructor(private _dialog: MatDialog,
              private router: Router, 
              private _userService: UserService,
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private _calendarService: CalendarService, 
              private _ressourcesService: RessourcesService,
              private adapterDate: DateAdapter<any>) { }

    ngOnInit() {
      if (this._userService.getUserLogged() === undefined)  { return ; }
      this.displayPopover = false;
      this.requestsLoaded = false;
      this.planningLoaded = false;
      this.doLoadCalendar = false;
      this.loadEntity().then(
      () => {
        this.settingIntFmts();
        this.getListMechanics(this._userService.getUserLogged().entity)
        .then (
          (responseMechanics: any ) => {
            this.mechanicUsers = responseMechanics;
          },
          (err: any) => {
            console.log('getListMechanics' + err.message);
            if (err.status === 404) {
            }
          }
        );
        this.getAppointmentsWaitingDate(this._userService.getUserLogged().entity, this.wksEntity.entityTz, undefined)
        .then(
          () => {
            this.requestsLoaded = true;
            this.wksEntityParams = this._ressourcesService.getWksEntityParams();
            this.workingTime = {
              dayHours: this.wksEntityParams.dayHours,
              weekStartsOn: this.wksEntityParams.weekStartsOn,
              weekendDays: this.wksEntityParams.weekendDays,
            };
          },
          err => {
            console.log('getAppointmentsWaitingDate' + err.message);
            this.requestsLoaded = false;
          });
    });
  }
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
    // this.localelang = userLang;
    this.localelang =  this._translate.currentLang;
    this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
  }
  fillDotsLine(dotsLine: string)  {
    
    for (let i = 0; i < 10; i++ ) {
      this.addNSecondsDelay(5)
      .then(() => {
        dotsLine += '.';
      }) ;
    }
  }
  addNSecondsDelay(n: number) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, n * 1000);
    });
  }
  setClickedRow(row: number)  {

    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.appointmentsCur = undefined;
    } else {
      this.selectedRow = row;
      this.appointmentsCur = this.appointmentsList[row];
      this._calendarService.setCalObject(this.appointmentsCur);
    }
  }
  // https://stackblitz.com/edit/mde-popover-dynamic-target-position?file=app%2Fapp.component.html
  displayRequest(event: EventChanged): void {
    if (this.localPopover === undefined) {
      return;
    } 
    if (event.action === 'displayRequest' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
        return;
      }
      this.getAppointmentUUID(this._userService.getUserLogged().entity,  this.wksEntity.entityTz, event.eventCur.linkId)
        .then (
          (data) => {
            this.appointmentsCur = data;
            this.detailPopover._elementRef.nativeElement.style.top = '25px';
            this.detailPopover._elementRef.nativeElement.style.left = '15px';
            this.localPopover.openPopover();
            this.displayPopover = true;
          },
          (err: any) => {
            console.log('displayRequest / getAppointmentUUID error : ' + err);
          }
        );
    }
    if (event.action === 'closePopover' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
    }
  }
  displayDetail(event: Event, row: number)  {
    
    if (this.localPopover === undefined) {
      return;
    } 
    // this.detailPopover.closePopover();
    if ( this.selectedRow !== row) {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
      return;
    } 
    // console.log('displayDetail : ' + row + ' displayPopover ' + this.displayPopover);
    // https://stackblitz.com/edit/mde-popover-dynamic-target-position?file=app%2Fapp.component.ts

    if (this.displayPopover) {
      this.localPopover.togglePopover();
      this.displayPopover = false;
    } else  {
      this.detailPopover._elementRef.nativeElement.style.top = '25px';
      this.detailPopover._elementRef.nativeElement.style.left = '15px';
      this.localPopover.openPopover();
      this.displayPopover = true;
    }
  }
  loadEntity() {
    
    return  new Promise<void>((resolve, reject) => {
      this.wksEntity = this._wksCentralService.getWksEntityCur();
      resolve();
    });
   
  }
  
  getListMechanics(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this._userService.getEntityUsersList(entityCur, 'MECHANIC')
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('getListMechanics' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }

  loadEvents(entityCur: string, actor: string): any {
    let listActors: string;
    if (actor === undefined) {
      for (const actorCur of this.mechanicUsers) {
        if (listActors === undefined) {
          listActors = actorCur.username;
        } else {
          listActors += ';' + actorCur.username;
        }
      }
    } else {
      listActors = actor;
    }

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActors(entityCur, listActors, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, 
        err => {
          console.log('loadEvents' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadEventsDate(entityCur: string): any {
    let listActors: string;
    for (const actor of this.mechanicUsers) {
      if (listActors === null) {
        listActors = actor.username;
      } else {
        listActors += ';' + actor.username;
      }
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const startDate = moment({year: year, month: month, day: day - 1 }).toDate();
    const endDate = moment({year: year, month: month + 2, day: day}).toDate();

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActorsDates(entityCur, listActors, startDate, endDate, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadEventsDate' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  onChangeMechanic(event: { index: any, value: any }) {
    this.doLoadCalendar = true;
    this.planningLoaded = false;
    this.eventsList = [];
    this.calendardEventLBddist = [];
    for (const userCur of this.mechanicUsers) {
      if (userCur.username === event.value) {
        this.actorSelected = userCur;
        break;
      }
    }
    this.loadEvents(this._userService.getUserLogged().entity, this.actorSelected.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  }
  formatEvents() {
    this.eventsList = [];
    let evtId = 0;

    for (const eventCur of this.calendardEventLBddist) {
      let modifOption = false;
      if ((eventCur.calStatut === '') ||
        (eventCur.calStatut === null) ||
        (eventCur.calStatut === 'WAITING')) {
        modifOption = true;
      }
      if (eventCur.calDateStart === undefined) {
        eventCur.calDateStart = eventCur.calStart;
      }
      if (eventCur.calDateEnd === undefined) {
        eventCur.calDateEnd = eventCur.calEnd;
      }
      const otherDatas = JSON.parse(eventCur.calOtherdatas);
      this.eventsList.push({
        id: evtId,
        start: new Date(eventCur.calDateStart),
        end: new Date(eventCur.calDateEnd),
        title: eventCur.calTitle,     
        allDay: false,
        resizable: {
          beforeStart: modifOption,
          afterEnd: modifOption
        },
        draggable: modifOption,
        linkId: eventCur.calLinkid,
        calType: eventCur.calType,
        bddId: eventCur.id,
        calStatut: eventCur.calStatut,
        ref: (otherDatas && otherDatas.appointmentCustomer && otherDatas.appointmentCustomer.ref) ? otherDatas.appointmentCustomer.ref : undefined,
        outside: eventCur.calOutside,
        stdCreationDate: eventCur.stdCreationDate,
        stdCreationUser: eventCur.stdCreationUser,
        oldTime: {
          start: new Date(eventCur.calDateStart),
          end: new Date(eventCur.calDateEnd),
        }
      });
      evtId++;
    }
  }

  eventClicked(event: EventChanged): void {
    // console.log('eventClicked ' + JSON.stringify(event));
    // const eventBdd = this.fillModelBdd(event.eventCur);
    let isFound = false;
    if (this.appointmentsCur === undefined) {
      for (const appointment of this.appointmentsList) {
        if (appointment.id === event.eventCur.linkId) {
          this.appointmentsCur  = appointment;
          isFound = true;
          break;
        }
      }
      if (!isFound)  {
        this.getAppointmentUUID(this._userService.getUserLogged().entity,  this.wksEntity.entityTz, event.eventCur.linkId)
        .then (
          (data) => {
            this.appointmentsCur = data;
            this.saveEvent(event.eventCur, event.action);
          },
          (err: any) => {
            console.log('getAppointmentUUID error : ' + err);
          }
        );
      } else {
        this.saveEvent(event.eventCur, event.action);
      }
    } else {
      this.saveEvent(event.eventCur, event.action);
    }
  }
  updateListEvents(calendarEventBdd: CalendarEventBdd, bddAction: string): void {
    let idxRow = 0;
    for (const eventTmp of this.calendardEventLBddist) {
      if (eventTmp.id === calendarEventBdd.id ) {
        this.calendardEventLBddist.splice(idxRow, 1);
        if ( (bddAction !== 'deleted')) {
          this.calendardEventLBddist.push(calendarEventBdd);
        }
        break;
      }
      idxRow ++ ;
    }
    if (bddAction === 'add')  {
      this.calendardEventLBddist.push(calendarEventBdd);
    }
    this.formatEvents();
  }
  getAppointmentsWaitingDate(_entity: string, _timeZone: string, _statutCur: string) {
    this.requestsLoaded = false;
    let curentStatut = _statutCur;
    this.cacheDatatemp = [];
    this.appointmentsList = [];
    if (_statutCur === 'AllStatuts') {
      curentStatut = undefined;
    }
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.getAppointmentsWaitingDate(_entity, curentStatut, _timeZone)
      .subscribe(
        data => {
          this.cacheDatatemp = [...data.body];
          this.appointmentsList = [...data.body];
          resolve();
        },
        err => {
          console.log('getAppointmentsWaitingDate' + err.message);
          this.requestsLoaded = false;
          if (err.status === 404) {
          }
          this.errorMessage('nodata', _statutCur);
          reject(err);
        });
      }
    );
  }
  getAppointmentUUID(_entity: string, _timeZone: string, _uuid: string) {

    return  new Promise<AppointmentModel>((resolve, reject) => {
      this._wksCentralService.getAppointmentUUID(_entity, _uuid, _timeZone)
      .subscribe(
        data => {
          const response = data.body ;
          
          resolve(response);
        },
        err => {
          console.log('getAppointmentUUID' + err.message);
          reject(err);
        });
      }
    );
  }
  errorMessage(option: string, statut: string): void {
    const titleBox = this._translate.getTranslate('appointmentsSearch');
    let messageBox: string;
    if (option === 'nodata') {
      if ( statut !== undefined)  {
          messageBox = this._translate.getTranslate('statut') + ' : ' +  
                        this._translate.getTranslate(statut) + ' --> ' + 
                        this._translate.getTranslate('noAppointments') ;
      } else  {
        messageBox =  this._translate.getTranslate('noAppointments') ;
      }
    }
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'nodata');
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') ) {
          
        }
        if ((_actionCur === 'nodata')) {
          this.router.navigate(['/jobHome']);
        }
      });

  }
 
  saveEvent(eventCur: CalendarEvt, actionType: string) {
    let statutBdd = 'newDate';
    let okToSplice = true;
    if (eventCur.bddAction === 'updated') {
      statutBdd = 'updateDate';
      okToSplice = false;
    }
    if (eventCur.bddAction === 'changed') {
      statutBdd = 'updateDate';
      okToSplice = false;
    }
    if (eventCur.bddAction === 'deleted') {
      statutBdd = 'cancelDate';
      okToSplice = false;
    }
    const calToValid = this.fillModelBdd(eventCur);
    this._calendarService.saveWksCalendar(calToValid, this.wksEntity.entityTz, statutBdd, this.actorSelected.email, this.appointmentsCur.client.id )
    .subscribe(
      (data) => {
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
        const calendarEventBddTmp = data.body;
        eventCur.bddId = calendarEventBddTmp.id;
        calToValid.id = calendarEventBddTmp.id;
        if (okToSplice) {
          this.appointmentsList.splice(this.selectedRow, 1);
        }
        this.registerAppointment(this.appointmentsCur, eventCur, actionType);
        this.selectedRow = -1;
        this.updateListEvents(calToValid, eventCur.bddAction );
      },
      (err) => {
        console.log('saveEvent ' + calToValid + ' KO ' + err );
      }
    );

  }
  registerAppointment(appointmentItem: AppointmentModel, eventCur: CalendarEvt, actionType: string) {
    
    let actionEmail = '';
    if (actionType.toLowerCase() === 'deleted') {
      appointmentItem.interventionDate = undefined;
      appointmentItem.interventionTechnician = undefined;
      appointmentItem.appointmentStatut = 'quotation_waiting';
      // : AppointmentTracking[] 
     const appointmentTracking = appointmentItem.appointmentTracking;
     if (appointmentTracking !== undefined) {
       for (const trackingCur of appointmentTracking) {
         // work_assigned
         // appointment_fixed
         if (trackingCur.statut === 'work_assigned') {
           continue;
         }
         if (trackingCur.statut === 'appointment_fixed') {
          continue;
        }
         if (trackingCur.trackingActive) {
          appointmentItem.appointmentStatut = trackingCur.statut;
         }
         // console.log(trackingCur);
       }
     }
      actionEmail = 'cancelDate';
    }
    if (actionType.toLowerCase() === 'timechanged') {
      actionType = 'Edited';
    }
    if (actionType.toLowerCase() === 'edited') {
      if ((appointmentItem.interventionDate === undefined) || (appointmentItem.interventionDate === null)) {
        if (eventCur.start) {
          actionEmail = 'newDate';
        }
      } else {
        actionEmail = 'updateDate';
      }

      if (appointmentItem.nbEquipments === null) {
        appointmentItem.nbEquipments = 1;
      }
      appointmentItem.interventionDate = eventCur.start;
      appointmentItem.interventionTechnician = this.actorSelected.username;
      appointmentItem.workAssigned = this.actorSelected.username;
      appointmentItem.appointmentStatut = 'work_assigned';
    }
    this._wksCentralService.saveWksAppointment(appointmentItem, actionEmail, this.actorSelected.email)
    .subscribe(
      () => {
        if (actionType.toLowerCase() === 'deleted') {
          this.appointmentsList.unshift(appointmentItem);
        }
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
      },
      (err) => {
        console.log('registerAppointment ' + appointmentItem + ' KO ' + err );
      }
    );
   }
  fillModelBdd(eventCur: CalendarEvt): CalendarEventBdd {

    let idxTmp;
    try {
      if (CommonMethods.isUUID(eventCur.id.toString())) {
        idxTmp = eventCur.id.toString();
      }
    } catch (error) {
      idxTmp = undefined;
    }
    /*
    if (idxTmp === undefined) {
      try {
        const idxNum = Number.parseInt(eventCur.id.toString(), 10);
        idxTmp = (!idxNum ? eventCur.id.toString() : undefined );
        // idxTmp = eventCur.id.toString();
      } catch (error) {
        idxTmp = undefined;
      }
    }
    */
    const idxCur = (idxTmp ? idxTmp : eventCur.bddId ? eventCur.bddId : undefined);

    const appointmentCustomer = {'ref':  eventCur.ref};
    const othersData = ' {"appointmentCustomer" :' + JSON.stringify(appointmentCustomer) + ',"actorMail" : "' + this.actorSelected.email + '"}';
    const dateStartTime: any = moment(eventCur.start).format(environment.fmtDateTimeBdd);
    const dateEndTime: any = moment(eventCur.end).format(environment.fmtDateTimeBdd);
    const calendarEventBdd: CalendarEventBdd = {
      id: idxCur,
      stdEntity: this.wksEntity.stdEntity,
      calApplication: 'appointmentCustomer',
      calType: eventCur.calType,
      calLinkid: eventCur.linkId,
      calStart: dateStartTime,
      calEnd: dateEndTime,
      calDateStart: dateStartTime,
      calDateEnd: dateEndTime,
      calAction: '',
      calTitle: eventCur.title,
      calOutside: eventCur.outside,
      calDescription: eventCur.description,
      calComment: eventCur.comment,
      calAllday: false,
      calActor:  this.actorSelected.username,
      calOtherdatas: othersData,
      calStatut: eventCur.calStatut,
      stdCreationDate: eventCur.stdCreationDate,
      stdCreationUser: eventCur.stdCreationUser,
    };

    return calendarEventBdd;
  }
}
