/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./works-img.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../../directives/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i10 from "./works-img.component";
import * as i11 from "../../../../services/wks-central.service";
var styles_WorksImgComponent = [i0.styles];
var RenderType_WorksImgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorksImgComponent, data: {} });
export { RenderType_WorksImgComponent as RenderType_WorksImgComponent };
function View_WorksImgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "flex-row row"], ["style", "margin: 0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-left: 30px;"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_WorksImgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "picsum-img-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "carousel-caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.imageToShow, "resourceUrl")); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.fileName, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.fileLegend; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.fileName; _ck(_v, 7, 0, currVal_3); }); }
function View_WorksImgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 1, null, View_WorksImgComponent_3)), i1.ɵdid(2, 16384, [[1, 4]], 0, i5.NgbSlide, [i1.TemplateRef], { id: [0, "id"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.fileName, ""); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WorksImgComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "container stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksImgComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ngb-carousel", [["class", "carousel slide"], ["interval", "12000"], ["tabIndex", "0"]], [[4, "display", null]], [[null, "keydown.arrowLeft"], [null, "keydown.arrowRight"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("keydown.arrowLeft" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 5).keyboard && i1.ɵnov(_v, 5).prev(i1.ɵnov(_v, 5).NgbSlideEventSource.ARROW_LEFT)) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.arrowRight" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 5).keyboard && i1.ɵnov(_v, 5).next(i1.ɵnov(_v, 5).NgbSlideEventSource.ARROW_RIGHT)) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).mouseEnter() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).mouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i9.View_NgbCarousel_0, i9.RenderType_NgbCarousel)), i1.ɵdid(5, 3325952, null, 1, i5.NgbCarousel, [i5.NgbCarouselConfig, i1.PLATFORM_ID, i1.NgZone, i1.ChangeDetectorRef], { interval: [0, "interval"], wrap: [1, "wrap"], keyboard: [2, "keyboard"], pauseOnHover: [3, "pauseOnHover"], showNavigationArrows: [4, "showNavigationArrows"], showNavigationIndicators: [5, "showNavigationIndicators"] }, null), i1.ɵqud(603979776, 1, { slides: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksImgComponent_2)), i1.ɵdid(8, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.allIsLoaded; _ck(_v, 3, 0, currVal_0); var currVal_2 = "12000"; var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.imgFiles; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = "block"; _ck(_v, 4, 0, currVal_1); }); }
export function View_WorksImgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-works-img", [], null, null, null, View_WorksImgComponent_0, RenderType_WorksImgComponent)), i1.ɵdid(1, 638976, null, 0, i10.WorksImgComponent, [i11.WksCentralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorksImgComponentNgFactory = i1.ɵccf("mdi-works-img", i10.WorksImgComponent, View_WorksImgComponent_Host_0, { statutCall: "statutCall", dataList: "dataList" }, {}, []);
export { WorksImgComponentNgFactory as WorksImgComponentNgFactory };
