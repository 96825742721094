/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./numeric-renderer.component";
import * as i2 from "../../../../../services/job-data.service";
var styles_NumericRendererComponent = [];
var RenderType_NumericRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NumericRendererComponent, data: {} });
export { RenderType_NumericRendererComponent as RenderType_NumericRendererComponent };
export function View_NumericRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["style", "width: 100%;text-align: right!important;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; _ck(_v, 1, 0, currVal_0); }); }
export function View_NumericRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mdi-numeric-renderer", [], null, null, null, View_NumericRendererComponent_0, RenderType_NumericRendererComponent)), i0.ɵdid(1, 114688, null, 0, i1.NumericRendererComponent, [i2.JobDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumericRendererComponentNgFactory = i0.ɵccf("mdi-numeric-renderer", i1.NumericRendererComponent, View_NumericRendererComponent_Host_0, {}, {}, []);
export { NumericRendererComponentNgFactory as NumericRendererComponentNgFactory };
