import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ModalCommonComponent } from '../../../job/common/components/modal-common/modal-common.component';

import { CmdService } from '../../../services/cmd.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mdi-application-test',
  templateUrl: './application-test.component.html',
  styleUrls: ['./application-test.component.css']
})
export class ApplicationTestComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  constructor(private _cmdService: CmdService,
              private _dialog: MatDialog,
              private _userService: UserService, 
              private _translate: TranslateService) { }

  ngOnInit() {
  }



  testJms(): void {
    this.displayMessage('testJms', undefined);
    this._cmdService.runCmd('testJms', undefined)
    .subscribe(
      data => {
        this._dialog.closeAll();
      },
      err =>  {
        this._dialog.closeAll();
        console.log('testJms : ' + JSON.stringify(err));
        this.displayMessage('testJms', [JSON.stringify(err)]);
      }
    );
  }
  testValidMail(): void {
    this.displayMessage('testValidMail', undefined);

    const jsonArg = {
      email: environment.customerValidEmailTest.email,
      entity: environment.customerValidEmailTest.entity
    };
    this._cmdService.runCmd('testValidMail', jsonArg)
    .subscribe(
      data => {
        this._dialog.closeAll();
      },
      err =>  {
        this._dialog.closeAll();
        console.log('testValidMail : ' + JSON.stringify(err));
        this.displayMessage('testValidMail', [JSON.stringify(err)]);
      }
    );
  }
  displayMessage(_actionCur: string, args: any[] ) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    titleBox = 'testappli';
    let messageBox: string;
    let typeDialogArg: string;
    if (_actionCur === 'testJms') {
      messageBox = this._translate.instant('test submitted');
      typeDialogArg = undefined;
      if (args !== undefined) {
        messageBox = args[0];
      }
    } 

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog:  typeDialogArg !== undefined ? typeDialogArg : 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: messageBox,
        data1: '',
        data2: '',
        messageType: 'ERROR'
    };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data !== undefined)  {
          if (( data === 'okAction')) {
            this._dialog.closeAll();
          }
         
        }
      });
  }
}
