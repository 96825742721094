// https://www.ag-grid.com/javascript-grid-master-detail/

import { Component, ElementRef, Renderer2, ViewChild , OnDestroy} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { catchError, takeUntil } from 'rxjs/operators';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GridTools } from '../../../../../../common/tools/gridTools';
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';
import { JobDataService } from '../../../../../services/job-data.service';
import {  ColLabelRow, SubReports, ReportInt, SubReport, KeyValue } from '../../../../../models/job-data.model';

@Component({
  selector: 'mdi-datadetail-renderer',
  templateUrl: './datadetail-renderer.component.html',
  styleUrls: ['./datadetail-renderer.component.css']
})
export class DataDetailRendererComponent implements ICellRendererAngularComp, OnDestroy {

  @ViewChild('detailZoom', {static: true})  private detailZoom: ElementRef;	

  private readonly onDestroy = new Subject<void>();

  params: ICellRendererParams;
  masterGridApi: any;
  masterRowIndex: any;
  zoomTitle: string;
  isDetailZoom = false;

  isGrid = false;
  colDefs: any;
  rowData: any;
  colZoom: any[];
  subReportData: any[];


  reportToExecute: ReportInt;
  subReportCur: SubReport;
  reportLabel: string;

  constructor(private renderer: Renderer2, private _jobDataService: JobDataService) {

  }
  agInit(params: any): void {

    this.params = params;
    
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
    // const test = this._jobDataService.gettingDetailValues(params.rowIndex)
    const colApi = params.columnApi;
    // const allColumn:any[] = colApi.getAllColumns();

    this.colZoom = [];
    const dataRecord = params.data;
  
   
    const reportOrig = dataRecord['_reportOrig'];
    let subReports: SubReports = null;
    const jsonObject = JSON.parse(reportOrig.reportChildren);
    subReports = jsonObject.subreports as SubReports;
    const subReportAction = dataRecord['_subReport'];
    let subReportToExecute: SubReport;
    if ( subReportAction !== undefined)  {
      this.reportLabel = reportOrig.reportLabel ;
      subReports.zoomPresent = false;
      for (const subReportCur of subReports.subReport) {
          if (subReportCur.reportInitialAction === subReportAction) {
            subReportToExecute = subReportCur;
            break;
          }
      }
      if (subReportToExecute) {
        this.subReportData = [];
        for (const foreignKey of subReportToExecute.foreignKeys) {
          const subData = {
            colName: foreignKey.colTarget,
            colValue: dataRecord[foreignKey.colOrig],
          };
          // this.subReportData[foreignKey.colTarget] = dataRecord[foreignKey.colOrig];
          this.subReportData.push(subData);
        }
        if (subReportToExecute.listColRecall) {
          
          // const listCol = subReportToExecute.listColRecall.split(',');
          for (const dataLabel of subReportToExecute.listColRecall) {
            this.reportLabel += ' \ ' + dataRecord[dataLabel];
          }
        }
        this.getLastVersionReport(subReportToExecute.reportName)
        .then ((reportCur: ReportInt) => {
         
          this.reportToExecute = reportCur;
          this.isGrid = true;
        })
        .then((error) => {
    
        });

      }
    } else {
      const colTitle = dataRecord['_colTitle'];
      this.zoomTitle = dataRecord[colTitle];
      if ( subReports.zoomPresent) {
        this.buildZoom(subReports , dataRecord);
      }
    }



  }
  
  getLastVersionReport (_reportLabel: string)  {
    const dataResponse: ReportInt = null;
    return new Promise((resolve, reject) => {
      this._jobDataService.getLastVersionReport(_reportLabel)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          // dataResponse = data.body;
          resolve(data.body);
        }, err => {
          // dataResponse = null;
          reject(err);
        }
      );
    });
    
  }
  buildZoom (subReports: SubReports, _firstRecord: any) {
    const currentLang = _firstRecord['_currentLang'];
    const colDefs: any[] = _firstRecord['_colDef'];
    const dataLabel: Map<string, ColLabelRow> = _firstRecord['_dataLabel'];
    const colTitle = _firstRecord['_colTitle'];

    for (const colCur of colDefs) {
      if (colCur.field === '') {
        continue;
      }
      if (colCur.field === colTitle) {
        continue;
      }
      let colFmtNameCur = 'alphanumeric';
      let colFmtStringCur = 'alphanumeric';
      if (dataLabel.get(colCur.field).colAttributes) {
        const stringFmt = dataLabel.get(colCur.field).colAttributes.split(';');
        colFmtNameCur = stringFmt[0];
        colFmtStringCur = stringFmt[1];
      }
      const col = {
        field: colCur.field,
        headerName : colCur.headerName,
        value: _firstRecord[colCur.field],
        colFmtName : colFmtNameCur,
        colFmtString : colFmtStringCur,
      }; 
      this.colZoom.push(col);
    }
    if (subReports.zoomRecall) {
      this.reportLabel = '';
      // const listCol = subReports.zoomRecall.split(',');
      for (const dataLabel1 of subReports.zoomRecall) {
        this.reportLabel += ' \ ' + _firstRecord[dataLabel1];
      }
    }
    const nbCol = subReports.zoomCols; // Math.round(this.colList.length/10);
    const nbRow =  Math.round( (this.colZoom.length / nbCol) + 0.5);

    this.isDetailZoom = true;
  
    let divRow: any;

    let textLabel: any;
    let textValue: any;
    let divLabelValue: any;
    let elemLabelValue: any[];
    let divColRow: any;
    let classPosition: any;
    let iPos = 0 ;

    // let iLoop = 0 ;
    const listStyle = [];
    const styleLabel: KeyValue = {
      key: 'color',
      value: 'grey',
    };

    listStyle.push(styleLabel);
    for (let iRow = 0; iRow < nbRow; iRow++) {
      // if (this.colList[iPos] === undefined ) { continue; }
      divRow = GridTools.createBS_Row(this.renderer, ['row', 'justify-content-center']);
      iPos = iRow;
      elemLabelValue = [];
      for (let iData = 0; iData < nbCol; iData++) {
        classPosition = 'text-right';
        iPos = (iData * nbRow) + iRow;
        if (this.colZoom[iPos] === undefined ) { 
          // https://getbootstrap.com/docs/4.0/utilities/spacing/
          textLabel = GridTools.createBS_Text(this.renderer, ['col-1', 'text-left', 'border', 'border-primary', 'm-x-0', 'p-x-0'],
                    '', listStyle);
          elemLabelValue.push(textLabel);
          textValue = GridTools.createBS_Text(this.renderer, ['col-2', classPosition , 'border', 'border-primary', 'm-x-0', 'p-x-0'],
                      '', undefined);
          elemLabelValue.push(textValue);
          continue; 
        }

        /*if (this.isEven(iData)) {
          classPosition = 'text-center';
        }*/
        textLabel = GridTools.createBS_Text(this.renderer, ['col-1', 'text-left', 'border', 'border-primary', 'm-x-0', 'p-x-0'],
                            this.colZoom[iPos].headerName, listStyle);
        elemLabelValue.push(textLabel);
        let currentValue = this.colZoom[iPos].value;
        if (this.colZoom[iPos].colFmtName === 'numeric') {
          currentValue = CommonMethods.fmtNumber(this.colZoom[iPos].value, this.colZoom[iPos].colFmtString, currentLang);
        }
        textValue = GridTools.createBS_Text(this.renderer, ['col-2', classPosition , 'border', 'border-primary', 'm-x-0', 'p-x-0'],
                    currentValue, undefined);
        elemLabelValue.push(textValue);
      }
      divLabelValue = GridTools.createBS_LabelValue(this.renderer, ['row'], elemLabelValue);
      divColRow = GridTools.createBS_Col(this.renderer, ['col'], divLabelValue);
      this.renderer.appendChild(divRow, divColRow);
      this.renderer.appendChild(this.detailZoom.nativeElement, divRow);
    }
  }
  isEven(_num: number ) {
    return  ((_num % 2) === 0) ? true : false;
  }
  // called when the cell is refreshed
  refresh(params: any): boolean {
      return false;
  }

  onGridReady(params: any) {
      /*var detailGridId = "detail_" + this.masterRowIndex;

      var gridInfo = {
          id: detailGridId,
          api: params.api,
          columnApi: params.columnApi
      };

      console.log("adding detail grid info with id: ", detailGridId);
      this.masterGridApi.addDetailGridInfo(detailGridId, gridInfo);*/
  }

  ngOnDestroy(): void {
      /*var detailGridId = "detail_" + this.masterRowIndex;

      // ag-Grid is automatically destroyed

      console.log("removing detail grid info with id: ", detailGridId);
      this.masterGridApi.removeDetailGridInfo(detailGridId);*/
  }

}
