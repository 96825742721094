<span  *ngIf="!checkEquipmentsIsLoaded" style="background-color: transparent!important">
    <mat-progress-spinner  [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
    <br>
    <span class="blink_text">Please wait...</span>
</span>
<div *ngIf="checkEquipmentsIsLoaded" class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
    <mat-tab-group #tabsSubEquipment mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" 
                            style="margin: 0px; width: 100%" (selectedTabChange)="selectedTabChange('checkEquip',$event)">
        <div *ngIf="workEquipments.subEquipments === undefined || workEquipments.subEquipments.length === 0 ">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex-row row" >
                        <span class="col-10 left-label-span" style="text-align: start; font-size: 15px; margin-top: 5px;">
                            {{ workEquipments.mainEquipment.equipDesign ? workEquipments.mainEquipment.equipDesign : workEquipments.mainEquipment.equipName }}
                        </span>
                    </div>
                </ng-template>
                <mat-card-content class="stdTheme" style="margin-bottom: 0px;">
                    <ng-container [ngTemplateOutlet]="checkEquipTemplate"  ></ng-container>
                </mat-card-content>
            </mat-tab>
        </div>                 
        <div *ngIf="workEquipments.subEquipments !== undefined && workEquipments.subEquipments.length > 0 ">                            
            <div class="stdTheme" *ngFor="let subItem of workEquipments.subEquipments; let row = index" >
                <!--<mat-tab label="{{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}" >-->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="flex-row row" >
                            <span class="col-10 left-label-span" style="text-align: start; font-size: 15px; margin-top: 5px;">
                                {{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}
                            </span>
                        </div>
                    </ng-template>
                    <mat-card-content class="stdTheme" style="margin-bottom: 0px;">
                        <ng-container [ngTemplateOutlet]="checkEquipTemplate"  ></ng-container>
                    </mat-card-content>
                </mat-tab>
            </div>
        </div>
    </mat-tab-group>
</div>
<div *ngIf="checkEquipmentsIsLoaded" class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
    <div *ngIf="isReadOnly && isOKToUpdate" class="container-fluid d-flex justify-content-center">
        <button  class="mat-raised-button buttonTheme"  style="margin-bottom: 10px;" (click)="setUpdate()" >{{'Update' | translate}}</button>
    </div>
    <div *ngIf="!isReadOnly" class="container-fluid d-flex justify-content-between">
        <button  class="mat-raised-button buttonTheme"  style="margin-bottom: 10px;" (click)="displayModalVerification()" >{{'verif check' | translate}}</button>
        <button  class="mat-raised-button buttonTheme"  style="margin-bottom: 10px;" (click)="cancelUpdate()" >{{'cancel' | translate}}</button>
        <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToValid" style="margin-bottom: 10px;" (click)="validData()" >{{'submit' | translate}}</button>
    </div>
</div>
<ng-template let-value="value" #checkEquipTemplate>
    <div *ngIf="isCheckLoaded">
        <mdi-tasks-checklist
            [ihmOptions]="ihmOptionsCkeck"
            [statutCall]="''" 
            [tasksActionsArgs]="tasksActionsArgs"
            [itemEquip]="itemEquip"
            (dataOut)="inputData($event)"
        ></mdi-tasks-checklist>
    </div>
</ng-template>

