<div *ngIf="criticalAlert" >
    <button type="button" style="text-align: center!important;" class="btn btn-default mat-icon-button "
        matTooltip="{{ 'unvailable parts' | translate }}" matTooltipClass="red-tooltip">
        <!--<i style="color: red!important; font-size: large;" class="far fa-flag"></i>-->
        <i style="color: red!important; font-size: large;" class="fas fa-flag"></i>
    </button>
</div>
<div *ngIf="!criticalAlert" >
    <button type="button" style="text-align: center!important;" class="btn btn-default mat-icon-button "
        matTooltip="{{ 'all parts availables' | translate }}" matTooltipClass="green-tooltip">
        <i style="color: green!important; font-size: large;" class="far fa-flag"></i>
    </button>
</div>