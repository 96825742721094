import { Component, OnInit, OnDestroy , ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { UserService } from '../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../job/common/tools/gridTools';
import { RequestInt, mjljobrequests_sch } from '../../../models/job-data.model';


@Component({
  selector: 'mdi-requests-table',
  templateUrl: './requests-table.component.html',
  styleUrls: ['./requests-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RequestsTableComponent implements OnInit {

  @ViewChild('modalTemmplate', {read: TemplateRef, static: false}) modalTemplate: TemplateRef<any>;

  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  dataSource = new MatTableDataSource();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = mjljobrequests_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterRequest: boolean;
  doCreateRequest: boolean;
  doUpdateRequest = [];
  requestToUpdate: RequestInt;
  statutRegister: string;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];
  pwdFormat = '*******';
  // mapExpectedSave: Map<string, string> = new Map<string, string>();
  // modal
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true
  };
  modalText: any[];
  isTestRequestOK: boolean;
  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  optionItem: string;
constructor( private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private modalService: BsModalService,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
  }

  ngOnInit() {
    this.optionItem = 'noItem';
    this.optionItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.timeFormat = this._translate.getLocalFmt('timeFormat');

   /* this.displayedColumns = [
      { prop: 'requestLabel', name: this._translate.instant('requestLabel'), isDate: false, isPwd: false },
      { prop: 'requestEngine', name: this._translate.instant('requestEngine'), isDate: false, isPwd: false  },
      { prop: 'dbsId', name: this._translate.instant('dbsId'), isDate: false, isPwd: false  },
      { prop: 'requestVersion', name: this._translate.instant('requestVersion'), isDate: false, isPwd: false  },
      { prop: 'accessEntities', name: this._translate.instant('accessEntities'), isDate: false, isPwd: false  },
      { prop: 'accessGrp', name: this._translate.instant('accessGrp'), isDate: false, isPwd: false  },
      { prop: 'updateDate', name: this._translate.instant('updateDate'), isDate: true, isPwd: false  },
    ];*/
    // https://stackblitz.com/edit/angular-ag-grid-button-renderer?file=src/app/app.component.ts

    this.displayedColumns = [
      { field: 'requestLabel', headerName: this._translate.instant('requestLabel'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'requestEngine', headerName: this._translate.instant('requestEngine'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'dbsId', headerName: this._translate.instant('dbsId'), sortable: true, filter: true, resizable: true },
      { field: 'requestVersion', headerName: this._translate.instant('requestVersion'), sortable: true, filter: true, resizable: true },
      { field: 'accessEntities', headerName: this._translate.instant('accessEntities'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'accessGrp', headerName: this._translate.instant('accessGrp'), sortable: true, filter: true, resizable: true, enableRowGroup: true },
      { field: 'updateDate', headerName: this._translate.instant('updateDate'), type: 'DateFormat',
      cellRendererParams: {
        array: [this.timeFormat]
      },
         sortable: true, filter: true, resizable: true },
      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(),
      },
    ];
    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }
      /*
      if (params.node.rowIndex % 2 === 0) {
          return { background: 'red' }
      }*/
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.rowGroupPanelShow = 'always';
    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getListRequests();

    this._jobDataService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListRequests();
      }
      if (event === 'deleteRegister') {
        this.getListRequests();
      }
    });
    this.userLangCur = this._userService.getUserLogged().userlang;
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
    if (this.optionItem === 'request') {
      actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
            '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' +
            '<button type="button" class="btn btn-default mat-icon-button" data-action-type="try" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('try tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="try" class="fa fa-try" style="font-size: 20px"></span></button>';
    } else if (this.optionItem === 'accessData')  {
      actions = '<button type="button" class="btn btn-default mat-icon-button" data-action-type="dataAccess" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('dataAccess tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="dataAccess" class="fa fa-edit" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="try" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('try tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="try" class="fa fa-try" style="font-size: 20px"></span></button>';
    } else if (this.optionItem === 'labelData')  {
      actions = '<button type="button" class="btn btn-default mat-icon-button" data-action-type="labelData" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('labelData tooltip')  + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="labelData" class="fa fa-edit" style="font-size: 20px"></span></button>';
    }
    return actions;
  }
  getListRequests() {
    this.selected = [];
    this._jobDataService.getListRequests('ALL')
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      }
    );
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionType = e.event.target.getAttribute('data-action-type');

        switch (actionType) {
            case 'edit': {
                this.editRequest(data);
                break;
            }
            case 'trash': {
                this.deleteRequest(data);
                break;
            }
            case 'try': {
                this.tryRequest(data);
                this.openModal(this.modalTemplate);
                break;
            }
            case 'dataAccess': {
              this.editAccessData(data);
              break;
            }
            case 'labelData': {
              this.editLabelData(data);
              break;
            }
        }
    }
}
  onSelectionChanged($event: any) {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );
    /*
    var selectedRowsString = "";
    selectedRows.forEach(function(selectedRow, index: any) {
      if (index !== 0) {
        selectedRowsString += ", ";
      }
      selectedRowsString += selectedRow.requestLabel;
    });
    document.querySelector("#selectedRows").innerHTML = selectedRowsString;
    */
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {
    this.modalRef.hide();
  }
  translateLabels() {

    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateRequest = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  doAction(row: any): boolean {
    if (row.master) {
      return false;
    } else {
      return true;
    }
  }


  toggleSidenav(_arg: string) {

      this.isRegisterRequest = false;

      if (_arg === 'registerRequest') {
        this.isRegisterRequest = true;
      }
      this.sidenavCollapsed = !this.sidenavCollapsed;
      if (this.sidenavCollapsed) {
        this.m_sidenav.close();
      } else {
        this.m_sidenav.open();
      }
    }


  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }

  refresh() {
    this.getListRequests();
  }
  deleteRequest(row: any) {
    // console.log('deleteEntity Row clicked: ', row);

    this._jobDataService.deleteRequest(row.id)
      .subscribe(
        () => {
          this._jobDataService.onRegisterValid.emit('deleteRegister');
        },
        // () => this.registrationFailed = true
      );

  }
  tryRequest(row: any) {

    // console.log('deleteEntity Row clicked: ', row);
    // const messageTranslate: string;
    this.modalText = [row.requestLabel + ' / Version ' + row.requestVersion];
    this._jobDataService.testRequest(row)
    .subscribe(
      (retMes) => {
        const response = JSON.parse(JSON.stringify(retMes.body));
        if (response.statut === 'OK') {
         this.isTestRequestOK = true;
        } else {
          this.isTestRequestOK = false;
        }
      },
      () => {
        this.isTestRequestOK = false;
      }
    );
  }
  editRequestAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.requestToUpdate = selectedRow;
    this.statutRegister = 'updateRequest';
    this.doCreateRequest = false;
    this.toggleSidenav('registerRequest');
  }
  editRequest(row: RequestInt) {
    this.requestToUpdate = row;
    this.statutRegister = 'updateRequest';
    this.doCreateRequest = false;
    this.toggleSidenav('registerRequest');
  }
  addRequest() {
    // console.log('addEntity : ');
    this.statutRegister = 'createRequest';
    this.doCreateRequest = true;
    this.toggleSidenav('registerRequest');
  }
  editAccessData(row: RequestInt) {
    this.requestToUpdate = row;
    this.statutRegister = 'accessData';
    this.doCreateRequest = false;
    this.toggleSidenav('accessData');
  }
  editLabelData(row: RequestInt) {
    this.requestToUpdate = row;
    this.statutRegister = 'labelData';
    this.doCreateRequest = false;
    this.toggleSidenav('labelData');
  }
}
