import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions, TreeComponent } from 'angular-tree-component';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';

import { UserService } from '../../../../../services/user.service';
import { MenuInt, MenuModel, DataLabelInt } from '../../../models/job-data.model';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { CommonMethods } from '../../../../common/tools/commonMethods';
import { SUPPORTED_LANGS, LangSupported } from '../../../../../i18n/translation';

class MenuNode {
  id: string;
  name: string;
  requestRef?: string;
  children?: MenuNode[];
}

@Component({
  selector: 'mdi-entities-menu',
  templateUrl: './entities-menu.component.html',
  styleUrls: ['./entities-menu.component.css']
})
export class EntitiesMenuComponent implements OnInit {

  @Input() statutCall: any;
  @Input() entityToUpdate: string;
  @ViewChild(ContextMenuComponent, {static: false}) public basicMenu: ContextMenuComponent;
  @ViewChild(TreeComponent, {static: false})  private tree: TreeComponent;
  @ViewChild('selectSharedEntities', {static: false})  private selectSharedEntities: MatSelect;
  
  
  private readonly onDestroy = new Subject<void>();

  idNode: number;
  nodesTree: any;
  optionsTree: ITreeOptions;
  isNewRoot: boolean;
  isItemActived: boolean;
  registrationFailed: boolean;
  messageTranslate: string;
  menuToUpdate: MenuInt;
  entitiesList: string[];
  statutRegister: string;
  userLangCur: string;
  userLangLabel: LangSupported;
  supportedLangs: any[];
  userLangId: string;
  parentList: string;
  displayedColumns: any;
  displayedColumns2: any;
  parentsLoad: boolean;
  dataLabel: DataLabelInt[];

  constructor(private router: Router, 
    private _userService: UserService, 
    private _translate: TranslateService,
    private _jobDataService: JobDataService) { }

  ngOnInit() {
    this.statutRegister = 'updateMenu';
    this.userLangCur =  this._userService.getUserLogged().userlang;
    this.supportedLangs = SUPPORTED_LANGS;
    this.userLangId = this.userLangCur;
    for (const langEvent of this.supportedLangs) {
      if (this.userLangCur === langEvent.value) {
        this.userLangLabel = langEvent;
        this.userLangLabel.display = this._translate.instant(langEvent.display);
        break;
      }
    }

    this.buildOptionsTree();
    this.loadData();
    this.isNewRoot = false;
    this.isItemActived = false;
  }
  onLangChange(event: any) {
    const langEvent: LangSupported = event.value;
    this.userLangId = langEvent.value;
    this.userLangLabel = langEvent;
    this.userLangLabel.display = this._translate.instant(langEvent.display);
    this.parentsLoad  = false;
    
  }
  tabSelected(event: number) {
    // console.log(event);
    this.parentsLoad  = false;
    if (event === 1) { this.getListParentLabels(); }
  }
  setValueArray (dataField: string, dataValue: any, index: number) {
    
    if ( dataField ===  'menuLabel') { this.dataLabel[index].menuLabel  = dataValue; }
    if ( dataField ===  'menuToolTip')  { this.dataLabel[index].menuToolTip  = dataValue; }

  }
  getListParentLabels() {
 // this.displayedColumns = ['colName','colHeader','colToolTip'];
    this.displayedColumns = ['menuName', 'menuLabel', 'menuToolTip'];
    const parentArray =  this.getParents(this.nodesTree, []);
    if (this.menuToUpdate.menuRef) {
      this.parentList = '';
      for (const node of parentArray) {
        if (this.parentList === '') { 
          this.parentList = node; 
        } else { 
          this.parentList += 'XX' + node; 
        }
      }
      this._jobDataService.getListRequestLabels(this.menuToUpdate.menuRef, this.userLangId, this.parentList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          // this.dataSource.data = data.body;
          const dataResponse = [...data.body];
          this.setValuesToUpdate(dataResponse);
          // console.log(dataResponse);
        }
      );
    } else {
      const dataArray: Array<any> = [];
      if ((parentArray === null) || (parentArray.length === 0)) {
        this.setValuesToUpdate(dataArray);
      } else {
        for (const node of parentArray) {
          const nodeCur = {
            colName: node,
            colHeader: node,
            colToolTip: null,
            userLang: this.userLangCur,
            };
            dataArray.push(nodeCur);
        }
        dataArray.sort(function(obj1, obj2) {
          return obj1.colName > obj2.colName ? 1 : -1;
        });
        this.setValuesToUpdate(dataArray);
      }
    }
  }
  setValuesToUpdate(_dataArray: Array<any>) {


    this.dataLabel = [];
 
    // this.dataLabel = _dataArray;
    for (const col of _dataArray) {
      // console.log(col.colName);

      const dataCur: DataLabelInt = {
        menuName: col.colName,
        menuLabel: col.colHeader,
        menuToolTip: col.colToolTip,
        userLang: col.userLang,
      };
      this.dataLabel.push(dataCur);
    }
    this.parentsLoad  = true;
  }
  buildOptionsTree() {
    this.optionsTree = {
      displayField: 'name',
      isExpandedField: 'expanded',
      idField: 'id',
      hasChildrenField: 'children',
      actionMapping: {
        mouse: {
          dblClick: (tree: any, node: any, $event: any) => {
            if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
          },
          contextMenu: (model: any, node: any, event: any) => {
            // this.onContextMenu(model, node, event);
            // console.log('in context menu...');
            // this.basicMenu;
           //  [contextMenu]="basicMenu" [contextMenuSubject]="nodesTree"
        }
          
        },
        keys: {
          [KEYS.ENTER]: (tree: any , node: any , $event: any) => {
            node.expandAll();
          }
        }
      },
      nodeHeight: 18,
      allowDrag: (node: any) => {
        return true;
      },
      allowDrop: (node: any) => {
        return true;
      },
      allowDragoverStyling: true,
      levelPadding: 10,
      // useVirtualScroll: true,
      animateExpand: true,
      scrollOnActivate: true,
      animateSpeed: 30,
      animateAcceleration: 1.2,
      scrollContainer: document.documentElement // HTML
    };
  }
  loadData() {
 
    this._jobDataService.getMenu(this.entityToUpdate, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data.body === null) {
          this.menuToUpdate = undefined;
          this.nodesTree = [];
          /*let curEntities = this._userService.getUserLogged().accessentities.split(',');
          let iPos = curEntities.indexOf('ALL');
          if (iPos > -1) curEntities=['ALL'];
          const bindEntities: any[] = [];
          for (const curEntity of curEntities) {
            bindEntities.push(curEntity);
          }
          this.selectSharedEntities.value = bindEntities;*/
        } else {
          this.menuToUpdate = data.body[0];
          const jsonObject = JSON.parse(this.menuToUpdate.entitymenu);
          this.nodesTree = jsonObject.menutree;
          // let curEntities = this._userService.getUserLogged().accessentities.split(',');
          // let iPos = curEntities.indexOf('ALL');
          // if (iPos > -1) curEntities=['ALL'];
          if ((this.menuToUpdate.entitiesShared !== undefined) && 
                  (this.menuToUpdate.entitiesShared !== null) && 
                  (this.menuToUpdate.entitiesShared.trim().length > 3)) {
            let curEntities = this.menuToUpdate.entitiesShared.split(',');
            const iPos = curEntities.indexOf('ALL');
            if (iPos > -1) { curEntities = ['ALL']; }
            
            const bindEntities: any[] = [];
            for (const curEntity of curEntities) {
              bindEntities.push(curEntity);
            }
            this.selectSharedEntities.value = bindEntities;
            this.getListParentLabels();
          }
        }
      }, () => {
        // console.log('Entity unfound : ', _entity);
      }
    );
    this.getListEntities();

  }
  setSharedEntities(event: any) {

    // console.log(this.selectSharedEntities);
  }
  showMessage(message: any) {
    // console.log(message);
  }
  onTreeEvent(event: any) {
    this.isItemActived = true;
    if (event.eventName === 'focus') {
      // console.log(event.node.data);

    }
  }
  getListEntities() {
    this.entitiesList = [];
    if (this._userService.getUserLogged().extendedentities.includes('ALL')) {
      this._userService.getEntities()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const listEntities = data.body;
          // this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
          this.entitiesList.push('ALL');
          const curEntities = CommonMethods.jsonPropToArray(listEntities, 'entity');
          for (const curEntity of curEntities) {
            this.entitiesList.push(curEntity);
            }
        }
      );
    } else {
      const curEntities = this._userService.getUserLogged().extendedentities.split(',');
      for (const curEntity of curEntities) {
        this.entitiesList.push(curEntity);
        }
    }
  }
  doNewRoot() {
    this.isNewRoot = !this.isNewRoot;
  }
  addNewRoot(_nodeName: string) {

    let curId: number;
    const nbId = this.nodesTree.length;
    if (nbId > 0) { 
      curId = this.nodesTree[nbId - 1].id + 100; 
    } else { curId = 1; }
    const localNode = {
      id: curId,
      name: _nodeName,
    };
    this.nodesTree.push(localNode);
    this.tree.treeModel.update();
    this.doNewRoot();
  }
  addNode(_nodeContext: any, _nodeName: string) {
    let curId: number;
    if (_nodeContext.children !== undefined) {
      const nbId = _nodeContext.data.children.length;
      curId = _nodeContext.data.children[nbId - 1].id + 1;
    } else {
      curId = _nodeContext.parent.data.id + 10;
      _nodeContext.data.children = [];
    }
    const localNode = {
      id: curId,
      name: _nodeName,
    };
    _nodeContext.data.children.push(localNode);
    this.tree.treeModel.update();

  }
  removeNode(_nodeContext: any) {
    // console.log(_nodeContext.data);
    const childrenNew = [];
    const childrenList = _nodeContext.parent.data.children;
    let idxChild = -1;
    let isFound = false;
    for (const child of childrenList ) {
      idxChild++;
      if (child.name === _nodeContext.data.name) { 
        isFound = true;
        continue;
      }
      childrenNew.push(child);
    }
    if (isFound)  {
      _nodeContext.parent.data.children = childrenNew;
      if (childrenNew.length === 0) {
        const localNode = {
          id: _nodeContext.parent.data.id,
          name: _nodeContext.parent.data.name,
        };
        _nodeContext.parent.data = localNode;
      }
    }
    this.tree.treeModel.update();
  }
  renameNode(_nodeContext: any, _nodeName: string) {
    // console.log(_nodeContext.data);
    _nodeContext.data.name = _nodeName;
    this.tree.treeModel.update();
  }
  register() {
    this.registrationFailed = false;
    const registerMenu = this.prepareValidation();
    this._jobDataService.saveMenu(registerMenu, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {

          if (this.statutCall) {
            this._userService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/jobHome']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );

  }
  prepareValidation(): MenuModel {
    this.idNode = 0;
    const nodesTreeTmp = this.resetIds(this.nodesTree);
    const nodesTreeParents =  this.getParents(nodesTreeTmp, []);
    // console.log(this.dataLabel);
    const menuCur = new MenuModel();
    if (this.menuToUpdate !== undefined) {
      menuCur.id = this.menuToUpdate.id;
      menuCur.menuRef = this.menuToUpdate.menuRef;
    }
    menuCur.entity = this.entityToUpdate;
    menuCur.entitymenu = '{\"menutree\":' + JSON.stringify(nodesTreeTmp) +
              ',\"menuparents\":' + JSON.stringify(nodesTreeParents) +
              ',\"menulabels\":' + JSON.stringify(this.dataLabel) + '}';
    menuCur.entitymenuparams = null;
    if (this.selectSharedEntities.value !== null) {
      if ('ALL' === this.selectSharedEntities.value[0]) {
        menuCur.entitiesShared = CommonMethods.arrayStringToString(this.entitiesList, ',');
      } else {
        menuCur.entitiesShared  = CommonMethods.arrayStringToString(this.selectSharedEntities.value, ',');
      }
    }
    return menuCur;
  }
  getParents (_nodesTree: any, _nodesParents: any): any {
   
    for (const child of _nodesTree ) {
      if ((child.children !== undefined) && (child.children.length > 0)) {
        _nodesParents = this.getParents(child.children, _nodesParents);
      }
      _nodesParents.push(child.name);
    }
    return _nodesParents;
  }
  resetIds(_nodesTree: any): any {
    let nbChild = 0;
    const nodesTreeTmp = [];
    for (const child of _nodesTree ) {
      this.idNode++;
      child.id = this.idNode;
      if ((child.children !== undefined) && (child.children.length > 0)) {
        child.children = this.resetIds(child.children);
      }
      nbChild++;
      nodesTreeTmp.push(child);
    }
    return nodesTreeTmp;
  }
  doCancel() {
    if (this.statutCall) {
      this._userService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
}
