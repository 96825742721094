<div #refElement class="mat-elevation-z8 container-fluid stdTheme " style="padding: 20px" >
  <mat-card class="register-card stdTheme" layout="column" fxFlex>
   <!-- <mat-card-header>
      <mat-card-title  >
        <span #titleCard>{{ RegisterTitle | translate }}</span>
      </mat-card-title>
    </mat-card-header> -->
    <mat-card-actions>
      <div id="registration-error" class="alert alert-danger stdTheme" *ngIf="registrationFailed || birthdayFailed || isEmailAlreadyUsed">
        <button type="button" class="close buttonTheme" aria-label="Close" (click)="registrationFailed = false; birthdayFailed = false; isEmailAlreadyUsed = false;">
          <!-- <span aria-hidden="true">&#215;</span> -->
        </button>
        {{ messageTranslate }}
      </div>
    </mat-card-actions>
    <form (ngSubmit)="register()" [formGroup]="userForm" (click)="initDpBirthDay($event)" >
      <mat-card-content>
        <div class="flex-row row stdTheme">
          <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" >
            <mat-label >{{ 'ID' | translate }}</mat-label>
            <input id="login" class="form-control inputTheme" formControlName="login" matInput #input minlength="3" placeholder="{{ 'ID field' | translate }}"
              required >
            <mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>
            <mat-error *ngIf="loginCtrl.invalid">{{getErrorMessage('login')}}</mat-error>
            <mat-error *ngIf="isUserFound">{{getErrorMessage('userFound')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row row">
          <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
            <mat-label>{{ 'FirstName' | translate }}</mat-label>
            <input id="firstname" class="form-control inputTheme" formControlName="firstname" matInput #input minlength="3" placeholder="{{ 'FirstName field' | translate }}"
              required>
            <mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>
            <mat-error *ngIf="firstnameCtrl.invalid">{{getErrorMessage('firstName')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row row">
          <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
            <mat-label>{{ 'LastName' | translate }}</mat-label>
            <input id="lastname" class="form-control inputTheme" formControlName="lastname" matInput #input minlength="3" placeholder="{{ 'LastName field' | translate }}"
              required >
            <mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>
            <mat-error *ngIf="lastnameCtrl.invalid">{{getErrorMessage('lastName')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row row" >
          <div class="col" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" >
                <mat-label>{{ 'Email' | translate }}</mat-label>
                <input id="email" class="form-control inputTheme" formControlName="email" matInput #input minlength="3" placeholder="{{ 'Email field' | translate }}"
                  required style="margin: 0em!important; padding: 0em!important">
                <mat-error *ngIf="emailCtrl.invalid">{{getErrorMessage('email')}}</mat-error>
              </mat-form-field>
          </div>
        </div>
        <div class="flex-row row" style="padding-top: 10px;">
          <div class="col" style="margin-left: 0em; padding-left: 0em">
            <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
              <mat-label>{{ 'Sexe' | translate }}</mat-label>
              <mat-select placeholder="{{ 'Choose one gender' | translate }}" formControlName="sexe" 
                          panelClass="mdiselectcontent"
                          ngDefaultControl required>
                <mat-option class="selectOptionsTheme" *ngFor="let sexe of sexeList" [value]="sexe">{{ sexe | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="sexeCtrl.invalid">{{getErrorMessage('sexe')}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col" style="margin-right: 0em; padding-right: 0em">
            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
              <mat-label>{{ 'Birth day' | translate }}</mat-label>
              <input matInput [min]="minBithDayDate" class="inputTheme" [max]="maxBithDayDate" formControlName="dpBirthDay" 
                        [matDatepicker]="picker" placeholder="{{ 'Birth day' | translate }}" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker name="matDpBirthDay" #picker  disabled="false" (closed)="validDpBirthDay();"></mat-datepicker>
              <mat-error *ngIf="dpBirthDayCtrl.invalid">{{getErrorMessage('dpBirthDay')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row" style="padding-top: 10px;">
          <div class="col" style="margin-left: 0em; padding-left: 0em">
            <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
            <mat-label>{{ 'entity' | translate }}</mat-label>
              <mat-select id="entity" #selectEntity placeholder="{{ 'Choose entity' | translate }}" 
                          formControlName="entity" ngDefaultControl required (selectionChange)="doGetListGrps(selectEntity.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let entity of entitiesList" [value]="entity">{{ entity }}</mat-option>
              </mat-select>
              <mat-error *ngIf="entityCtrl.invalid">{{getErrorMessage('entityCtrl')}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col" style="margin-right: 0em; padding-right: 0em">
            <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
              <mat-label>{{ 'usergroup' | translate }}</mat-label>
                <mat-select #selectAccess placeholder="{{ 'Choose groupe' | translate }}" 
                          formControlName="usergroup" ngDefaultControl required>
                  <mat-option class="selectOptionsTheme" *ngFor="let usergroup of usergroupList" [value]="usergroup">{{ usergroup }}</mat-option>
              </mat-select>
              <mat-error *ngIf="usergroupCtrl.invalid">{{getErrorMessage('usergroup')}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col" style="margin-right: 0em; padding-right: 0em">
            <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
              <mat-label>{{ 'userLang' | translate }}</mat-label>
              <mat-select placeholder="{{ 'Choose lang' | translate }}" formControlName="userlang" 
                          panelClass="mdiselectcontent"
                          (selectionChange)="onLangChange($event)"
                          ngDefaultControl required>
                <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" selected="isCurrentLang(lang)" [value]="lang">{{ lang.display | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-row row" style="padding-top: 10px;">
            <!-- <section class="mat-typography">-->
          <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
            <mat-label>{{ 'Roles' | translate }}</mat-label>
            <mat-select #selectRoles placeholder="{{ 'Choice a role' | translate }}" 
                        panelClass="mdiselectcontent"
                        disableOptionCentering formControlName="roles" multiple ngDefaultControl required>
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button class="buttonTheme"  (click)="selectRoles.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                    
                  </div>
                </mat-select-header>
                 <mat-option class="selectOptionsTheme" *ngFor="let role of supportedRoles" [value]="role">{{ role.display | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="rolesCtrl.invalid">{{getErrorMessage('roles')}}</mat-error>
          </mat-form-field>
          <!-- </section>-->
        </div>
        <div class="flex-row row" style="padding-top: 30px;">
          <div class="col stdTheme" style="margin-left: 0em; padding-left: 0em">
            <mat-checkbox color="warn" #enabledAccount [(ngModel)]="userCur.enabledAccount" (change)="toggle('enabledAccount',enabledAccount.checked)" class="form-group" formControlName="enabledAccount"  labelPosition="after">
              {{ 'enabledAccount' | translate }}
            </mat-checkbox>
          </div>
          <div class="col stdTheme" style="margin-right: 0em; padding-right: 0em">
            <mat-checkbox color="warn" #accountNonExpired  [(ngModel)]="userCur.accountNonExpired" (change)="toggle('accountNonExpired',accountNonExpired.checked)" class="form-group" formControlName="accountNonExpired"  labelPosition="after">
              {{ 'accountNonExpired' | translate }}
            </mat-checkbox>
          </div>
          <div class="col stedTheme" style="margin-right: 0em; padding-right: 0em">
            <mat-checkbox color="warn" #accountNonLocked [(ngModel)]="userCur.accountNonLocked" (change)="toggle('accountNonLocked',accountNonLocked.checked)" class="form-group" formControlName="accountNonLocked"  labelPosition="after">
              {{ 'accountNonLocked' | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div class="flex-row row">
          <div class="col stdTheme" style="margin-left: 0em; padding-left: 0em">
            <mat-checkbox color="warn" #credentialsNonExpired [(ngModel)]="userCur.credentialsNonExpired" (change)="toggle('credentialsNonExpired',credentialsNonExpired.checked)" class="form-group" formControlName="credentialsNonExpired" labelPosition="after">
              {{ 'credentialsNonExpired' | translate }}
            </mat-checkbox>
          </div>
          <div class="col stdTheme" style="margin-right: 0em; padding-right: 0em">
            <span class="horizontal-spacer5"></span>
          </div>
          <div class="col stdTheme" style="margin-right: 0em; padding-right: 0em">
            <mat-checkbox color="warn" #userdebug [(ngModel)]="userCur.userdebug" class="form-group" (change)="toggle('userdebug',userdebug.checked)" formControlName="userdebug" labelPosition="after">
              {{ 'userdebug' | translate }}
            </mat-checkbox>
          </div>

        </div>
        <div *ngIf="statutCall=='createUser'">
          <mdi-user-pwd (pwdChanged)="getPasswd($event)"></mdi-user-pwd>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
          <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!userForm.valid || isUserFound || birthdayFailed || isEmailAlreadyUsed || !passwordOk">
            <mat-icon>create</mat-icon>
            {{ 'submit' | translate }}
          </button>
          <button mat-raised-button class="buttonTheme" #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
  <!-- https://material.angular.io/components/form-field/overview -->
  <!--
      <div class=row>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-info'>1</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-success'>2</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-warning'>3</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-danger'>4</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-info'>5</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-success'>6</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-warning'>7</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-danger'>8</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-info'>9</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-success'>10</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-warning'>11</div>
        <div class='col-xs-1 col-md-1 col-lg-1 alert alert-danger'>12</div>
    </div>
  -->


</div>
