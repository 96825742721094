import { Component, OnInit, OnChanges, Input, ViewChild, ViewEncapsulation, Renderer2, SimpleChanges, ElementRef } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
// https://github.com/angular/material2/issues/5917

import { Observable, BehaviorSubject } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatTableDataSource, MatTable } from '@angular/material';
import { Router } from '@angular/router';
// import { IMyDpOptions, IMyDateModel } from 'mydatepicker'; // https://github.com/kekeh/mydatepicker

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';

import { UsersGrpCard, UsersGrpModel, HabilData, EntityModel } from '../../../models/user.model';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { environment } from '../../../../environments/environment';

import { ConfirmValidParentMatcher } from '../../../job/common/validators/customValidators';

@Component({
  selector: 'mdi-usersgrp-card',
  templateUrl: './usersgrp-card.component.html',
  styleUrls: ['./usersgrp-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersgrpCardComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() grpToUpdate: UsersGrpModel;

  @ViewChild('buttonSubmit', {static: false}) buttonSubmit;
  @ViewChild('refElement', {static: true}) refElement;
  @ViewChild('titleCard', {static: false}) titleCard;
  @ViewChild(MatTable, {static: false}) tableHabil;

  @ViewChild('tagInput', {static: false}) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  tagsAuthorized: any;
  allTagsCache: any;
  tagsNotAuthorized: any[];
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  usersGrpCard = new UsersGrpCard();
  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  registrationFailed: boolean;

  entityCtrl: FormControl;
  usergroupCtrl: FormControl;
  usergrouplabelCtrl: FormControl;

  supervisoremailCtrl: FormControl;
  extendedaccessCtrl: FormControl;
  authorizedFunctionsCtrl: FormControl;
  authorizedTagsCtrl: FormControl;
  prohibitedTagsCtrl: FormControl;

  grpForm: FormGroup;
  RegisterTitle = 'Register';
  messageTranslate: string;

  isUserGroupFound: boolean;
  isUpdateStatut: boolean;
  usergroupList: any;
  usergroupListCache: any;

  entitiesList: any;
  entitiesListCache: any;

  authorizedFunctionsList: any;

  dataCategoriesList: any;
  dataHabilitationsColumns: any;
  dataHabilitations: HabilData[];
  dataSource: any;
  selectedEntity: EntityModel;
  // dataSubject = new BehaviorSubject<HabilData[]>([]);
  tagVisible = true;
  tagSelectable = true;
  tagRemovable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private renderer: Renderer2) { }

  ngOnInit() {
   
  }

  ngOnChanges(changes: SimpleChanges) {
    // const statutCallValue = changes['statutCall'];
    // const userToUpdateValue = changes['grpToUpdate'];

    this.initData();
  }
  initData() {
    // console.log('Testing ngOnInit');
    
    this.allTagsCache = [];
    this.tagsNotAuthorized = [];
    this.tagsAuthorized = [];
    const tagsList = this._userService.getUserLogged().entityCur.tags;

    for (const tagCur of tagsList.split(',')) {
      this.allTagsCache.push({display : tagCur, value : tagCur});
      this.tagsNotAuthorized.push({display : tagCur, value : tagCur});
      this.tagsAuthorized.push({display : tagCur, value : tagCur});
    }


    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createGrp';
    }

    this.entityCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.usergroupCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.usergrouplabelCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.authorizedTagsCtrl = this.fb.control('');
    this.prohibitedTagsCtrl = this.fb.control('');
    this.extendedaccessCtrl = this.fb.control('', Validators.required);
    this.authorizedFunctionsCtrl = this.fb.control('');
    this.supervisoremailCtrl = this.fb.control('', [Validators.required, Validators.pattern(`${environment.emailPattern}`)]);

    this.grpForm = this.fb.group({
      entity: this.entityCtrl,
      usergroup: this.usergroupCtrl,
      usergrouplabel: this.usergrouplabelCtrl,
      supervisoremail: this.supervisoremailCtrl,
      extendedaccess: this.extendedaccessCtrl,
      authorizedFunctions: this.authorizedFunctionsCtrl,
      authorizedTags: this.authorizedTagsCtrl,
      prohibitedTags: this.prohibitedTagsCtrl,
    },
      {
        updateOn: 'blur'
      });

    const curFunctions = this._userService.getUserLogged().authorizedFunctionsList.split(',');
    this.authorizedFunctionsList = [];

    for (const curFunct of curFunctions) {
        this.authorizedFunctionsList.push(curFunct);
      }
    // data categ start
    // https://github.com/angular/material2/issues/5917
    const dataCategories = this._userService.getUserLogged().dataCategories.split(',');
    dataCategories.push('ALLData');
    this.dataCategoriesList = [];
    this.dataHabilitationsColumns = [];
    this.dataHabilitations = [];

    for (const dataCateg of dataCategories) {
      const dataCat = {
        dataCategory: dataCateg,
        label: this._translate.getTranslate(dataCateg),
      }; 
        this.dataCategoriesList.push(dataCat);
    }
    this.dataHabilitationsColumns = ['dataCategory', 'level', 'actionsColumn'];
    const dataHab = {
      dataCategory: '',
      dataLevel: '0',
    };
    this.dataHabilitations.push(dataHab);
   // this.dataSubject.next(this.dataHabilitations);
   //  this.dataSource = new HabilDataDS(this.dataSubject);
    this.dataSource = new MatTableDataSource<HabilData>(this.dataHabilitations);
    // data categ end 
    this.isUserGroupFound = false;
    if (this.statutCall === 'createGrp') {
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Creation';
      this.getListEntities();
      this.grpForm.controls['usergroup'].valueChanges.subscribe (
        () => {
          this.validUserGrp();
        }
      );
    }
    this.isUpdateStatut = false;
    if (this.statutCall === 'updateGrp') {
      this.isUpdateStatut = true;
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Update';
      this.grpForm.controls['entity'].disable();
      this.grpForm.controls['usergroup'].disable();

      this.setValuesToUpdate();

    }

  }
  setValueArray(dataField: string, dataValue: string, index: number) {
    if ( dataField ===  'dataCategory') { this.dataHabilitations[index].dataCategory  = dataValue; }
    if ( dataField ===  'dataLevel') { this.dataHabilitations[index].dataLevel  = dataValue; }
  }
  addNewHabData() {
    // https://github.com/angular/components/issues/5917
// https://stackblitz.com/edit/angular-5zu7px
    const dataHab = {
      dataCategory: '',
      dataLevel: '0',
    };
    // this.dataHabilitations.push(dataHab);
   // this.dataSubject.next(this.dataHabilitations);
   //  this.dataSource = new HabilDataDS(this.dataSubject);
    // this.dataSource = new MatTableDataSource<HabilData>(this.dataHabilitations);
    const dataList = this.dataSource.data;

    dataList.push({
      dataCategory: '',
      dataLevel: '0',
    });
    this.dataSource.data = dataList;
    this.tableHabil.renderRows();
  }
  deleteHabData(index: number) {

    this.dataSource.data.splice(index, 1);
    this.tableHabil.renderRows();
  }
  levelPlus(raw: any) {
    let levelCur = +raw.dataLevel;
    if (levelCur === undefined) { levelCur = 0; }
    if (levelCur < 9) {
      levelCur += 1 ;
      raw.dataLevel = levelCur.toString();
      return raw.dataLevel;
    } else {
      return raw.dataLevel;
    }
  }
  levelMinus(raw: any) {
    let levelCur = +raw.dataLevel;
    if (levelCur === undefined) { levelCur = 0; }
    if (levelCur > 0) {
      levelCur -= 1;
      raw.dataLevel = levelCur.toString();
      return raw.dataLevel;
    } else {
      return raw.dataLevel;
    }
  }
  getListEntities() {
    this._userService.getEntities()
    .subscribe(
      data => {
        const listEntities = data.body;
        this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
        this.entitiesListCache = this.entitiesList;
      }
    );
  }
  doGetListGrps(_entity: string) {
    this.getEntity(_entity)
    .then ((entity) => {
      const entityTags = CommonMethods.listToArray(this.selectedEntity.tags, ',');
      this.allTagsCache = [];
      for (const tagName of entityTags) {
        this.allTagsCache.push({display : tagName, value : tagName});
        this.tagsAuthorized.push({display : tagName, value : tagName});
        this.tagsNotAuthorized.push({display : tagName, value : tagName});
      }
     
      this.getListGrps(this.selectedEntity.entity)
      .then ((usergroupLists) => {

      })
      .then((error) => {

      });
    })
    .then((error) => {

    });
  }

  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .subscribe(
          data => {
            const usergroupLists = data.body;
            this.usergroupList = CommonMethods.jsonPropToArray(usergroupLists, 'usergroup');
            this.usergroupListCache = this.usergroupList;
            this.usergroupList.splice(0, 0, 'ALL');
            resolve(usergroupLists);
          }, err => {
            this.usergroupListCache = [];
            this.usergroupList = [];
            reject(err);
          }
        );
      });

  }
  getEntity(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntity(_entity)
        .subscribe(
          data => {
            this.selectedEntity = data.body;

            resolve(data.body);
          }, err => {
            this.usergroupListCache = [];
            this.usergroupList = [];
            reject(err);
          }
        );
      });

  }
  doCancel() {
    this.grpForm.reset();
    if (this.statutCall) {
      this._userService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  validUserGrp() {
    const entity = this.grpForm.controls['entity'].value;
    const usergroup = this.grpForm.controls['usergroup'].value;
    if ((usergroup) && (usergroup.length > 3)) {
      if ((this.statutCall === 'createGrp')
       || ((this.statutCall === 'updateGrp')
              && (usergroup !== this.grpToUpdate.usergroup))) {
        this.ctrlGrp(entity, usergroup );
      }
    }
  }
  ctrlGrp(_entity: string, _usergroup: string): UsersGrpModel {
    this.isUserGroupFound = false;
    let dataResponse: UsersGrpModel = null;
    this._userService.getGrp(_entity, _usergroup)
    .subscribe(
      data => {
        dataResponse = data.body;
        this.isUserGroupFound = true;
        if (this.statutCall === 'createGrp') {
          this.grpForm.controls['usergroup'].setErrors({ 'isUserGroupFound': true});
        }
        // console.log('User Group found : ', _usergroup);
      }, () => {
        // console.log('User Group not found : ', _usergroup);
        const list: Array<string> = [_usergroup];
        this.usergroupList = this.usergroupListCache;
        this.usergroupList.push(_usergroup);
        this.grpForm.controls.extendedaccess.setValue(list);
        this.grpForm.controls.usergrouplabel.setValue(_usergroup);
      }
    );
    return dataResponse;
  }
  // TAGS START
  onSelectTag(event: any) {
    console.log('onSelectTag : ' + event);

  }
  onAddTag(event: any) {
    // console.log('onAddTag : ' + event);
    // const index = this.tagsNotAuthorized.indexOf(event.value);
    let index = -1;
    
    this.tagsNotAuthorized.find( function (item, i) {
      if (item.value === event.value) {
        index = i;
        return true;
      }
     });

    if (index >= 0) {
      this.tagsNotAuthorized.splice(index, 1);
    }
    index = -1;
    const listProhibited = this.grpForm.value.prohibitedTags;
    listProhibited.find( function (item, i) {
      if (item.value === event.value) {
        index = i;
        return true;
      }
    });
    if (index >= 0) {
      listProhibited.splice(index, 1);
    }
    this.grpForm.controls.prohibitedTags.setValue(listProhibited);
  }
  onRemoveTag(event: any) {
    // console.log('onRemoveTag : ' + event);
    const index = this.tagsNotAuthorized.indexOf(event.value);
    if (index === -1) {
      this.tagsNotAuthorized.push(event.value);
      this.tagsNotAuthorized.sort(function(obj1, obj2) {
        return obj1.display > obj2.display ? 1 : -1;
      });
    }

  }
  updateAuthorizedTags() {
    let index = -1;
    const listAuthorizedTags = [];

    for (const tagCur of this.allTagsCache) {
      listAuthorizedTags.push({display : tagCur.display, value : tagCur.value});
    }
    const prohibitedTags = this.grpForm.value.prohibitedTags;
    for ( const tagCur of prohibitedTags) {
      index = -1;
      listAuthorizedTags.find( function (item: any, i: number) {
        if (item.display === tagCur) {
          index = i;
          return true;
        }
      });
      if (index >= 0) {
        listAuthorizedTags.splice(index, 1);
      }
    }
    // this.grpForm.controls.authorizedTags.setValue(listAuthorizedTags);
    this.tagsAuthorized = listAuthorizedTags;
  }
  updateProhibitedTags() {
    let index = -1;

    const listProhibited = [];
    for (const tagCur of this.allTagsCache) {
      listProhibited.push({display : tagCur.display, value : tagCur.value});
    }
    const authorizedTags = this.grpForm.value.authorizedTags;
    for ( const tagCur of authorizedTags) {
      index = -1;
      listProhibited.find( function (item: any, i: number) {
        if (item.display === tagCur) {
          index = i;
          return true;
        }
      });
      if (index >= 0) {
        listProhibited.splice(index, 1);
      }
    }
    this.tagsNotAuthorized = listProhibited;
    // this.grpForm.controls.prohibitedTags.setValue(listProhibited);
  }
  // TAGS END
  register() {
    this.registrationFailed = false;

    this.prepareValidation();
    this._userService.saveGrp(this.usersGrpCard, this.statutCall)
      .subscribe(
        () => {
          this.grpForm.reset();
          if (this.statutCall) {
            this._userService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation() {
    this.usersGrpCard.entity = this.grpForm.value.entity;
    this.usersGrpCard.usergroup = this.grpForm.value.usergroup;
    if (this.statutCall === 'updateGrp') {
      this.usersGrpCard.entity = this.grpToUpdate.entity;
      this.usersGrpCard.usergroup = this.grpToUpdate.usergroup;
    }
    this.usersGrpCard.usergrouplabel = this.grpForm.value.usergrouplabel;

    this.usersGrpCard.datafilter = '';
    this.usersGrpCard.supervisoremail = this.grpForm.value.supervisoremail;

    this.usersGrpCard.extendedaccess = CommonMethods.arrayStringToString(this.grpForm.value.extendedaccess, ',');
    this.usersGrpCard.authorizedfunctions = CommonMethods.arrayStringToString(this.grpForm.value.authorizedFunctions, ',');
    
    if (this.grpForm.value.authorizedTags.length === 0 ) { 
      this.usersGrpCard.tagsauthorized = undefined; 
    } else { 
      this.usersGrpCard.tagsauthorized =  CommonMethods.arrayStringToString(this.grpForm.value.authorizedTags, ','); 
    }
    
    if (this.grpForm.value.prohibitedTags.length === 0 ) { 
      this.usersGrpCard.tagsprohibited = undefined; 
    } else { 
      this.usersGrpCard.tagsprohibited =  CommonMethods.arrayStringToString(this.grpForm.value.prohibitedTags, ','); 
    }
    

    const pattern = new RegExp(this.usersGrpCard.usergroup);
    if (!pattern.test(this.usersGrpCard.extendedaccess)) {
      this.usersGrpCard.extendedaccess = this.usersGrpCard.usergroup + ',' + this.usersGrpCard.extendedaccess;
    }
    this.usersGrpCard.datahabil = '';
    if (this.dataHabilitations.length > 0) {
      for (const dataHabilCur of this.dataHabilitations) {

        if (dataHabilCur.dataCategory) {
          if (this.usersGrpCard.datahabil !== '') {
            this.usersGrpCard.datahabil += ',';
          }
          this.usersGrpCard.datahabil += dataHabilCur.dataCategory + '|' + dataHabilCur.dataLevel;
        }
      }
    }
  }

  setValuesToUpdate() {
    
    const bindEntity: any[] = [];
    bindEntity.push(this.grpToUpdate.entity);
    this.entitiesList = bindEntity;
    this.entitiesListCache = this.entitiesList;
    this.grpForm.controls.entity.setValue(bindEntity);
    this.grpForm.controls.usergroup.setValue(this.grpToUpdate.usergroup);
    this.grpForm.controls.usergrouplabel.setValue(this.grpToUpdate.usergrouplabel);
    this.getEntity(this.grpToUpdate.entity)
    .then ((entity) => {
      const entityTags = CommonMethods.listToArray(this.selectedEntity.tags, ',');
      const bindTagsAuthorized: any[] = [];
      const bindTagsNotAuthorized: any[] = [];
      this.allTagsCache = [];
      this.tagsNotAuthorized = [];
      this.tagsAuthorized = [];
      for (const tagName of entityTags) {

        this.allTagsCache.push({display : tagName, value : tagName});
        this.tagsNotAuthorized.push({display : tagName, value : tagName});
        this.tagsAuthorized.push({display : tagName, value : tagName});
      }
      
      if ((this.grpToUpdate.tagsauthorized != null) && (this.grpToUpdate.tagsauthorized.trim().length > 0)) {
        const authorizedTags = CommonMethods.listToArray(this.grpToUpdate.tagsauthorized, ',');
        const listAuthorized = [];
        for (const tagName of authorizedTags) {
          listAuthorized.push({display : tagName, value : tagName});
          bindTagsAuthorized.push(tagName);
        }
        this.grpForm.controls.authorizedTags.setValue(bindTagsAuthorized);
        for (const tagCur of listAuthorized) {
          // const index = this.tagsNotAuthorized.indexOf(tagCur);
          let index = -1;
      
          this.tagsNotAuthorized.find(function(item, i) {
            if (item.value === tagCur.value) {
              index = i;
              return true;
            }
           });
      
          if (index >= 0) {
            this.tagsNotAuthorized.splice(index, 1);
          }

        }
      }
      if ((this.grpToUpdate.tagsprohibited != null) && (this.grpToUpdate.tagsprohibited.trim().length > 0)) {
        const prohibitedTags = CommonMethods.listToArray(this.grpToUpdate.tagsprohibited, ',');
        const listProhibited = [];
        for (const tagName of prohibitedTags) {
          listProhibited.push({display : tagName, value : tagName});
          bindTagsNotAuthorized.push(tagName);
        }
        for (const tagCur of listProhibited) {
          // const index = this.tagsNotAuthorized.indexOf(tagCur);
          let index = -1;
      
          this.tagsAuthorized.find(function(item, i) {
            if (item.value === tagCur.value) {
              index = i;
              return true;
            }
           });
      
          if (index >= 0) {
            this.tagsAuthorized.splice(index, 1);
          }

        }
        this.grpForm.controls.prohibitedTags.setValue(bindTagsNotAuthorized);
      }
    })
    .then((error) => {

    });
    
    this.grpForm.controls.supervisoremail.setValue(this.grpToUpdate.supervisoremail);
    // this.grpForm.controls.extendedaccess.setValue(this.grpToUpdate.extendedaccess);
    this.getListGrps(this.grpToUpdate.entity)
      .then ((usergroupLists) => {
          const curGrps = this.grpToUpdate.extendedaccess.split(',');
          const bindGrps: any[] = [];

          for (const curGrp of curGrps) {
            for (const itemGrp of this.usergroupList) {
              if (itemGrp === curGrp) {
                bindGrps.push(curGrp);
              }
            }
          }
          this.grpForm.controls.extendedaccess.setValue(bindGrps);
          if ((this.grpToUpdate.extendedaccess === null) || (this.grpToUpdate.extendedaccess.trim().length < 3)) {
            bindGrps.push(this.grpToUpdate.usergroup);
            this.grpForm.controls.extendedaccess.setValue(bindGrps);
          }
      })
      .then((error) => {

      });
      if (this.grpToUpdate.authorizedfunctions != null) {
        const curFunctions = this.grpToUpdate.authorizedfunctions.split(',');
          const bindFunctions: any[] = [];

          for (const curFunct of curFunctions) {
            for (const itemFunct of this.authorizedFunctionsList) {
              if (itemFunct === curFunct) {
                bindFunctions.push(curFunct);
              }
            }
          }
          this.grpForm.controls.authorizedFunctions.setValue(bindFunctions);
      }
      this.dataHabilitations = [];
      if (this.grpToUpdate.datahabil) {
        const dataHabilList = this.grpToUpdate.datahabil.split(',');
  
        for (const dataHabilRow of dataHabilList ) {
          const dataHabilCur = dataHabilRow.split('|');
          this.dataHabilitations.push({
            dataCategory: dataHabilCur[0],
            dataLevel: dataHabilCur[1],
          });
        }
      } else {
        this.dataHabilitations.push({
          dataCategory: 'COMMON',
          dataLevel: '0',
        });
      }
      this.dataSource.data = this.dataHabilitations;
      // this.tableHabil.renderRows();
  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {

      case 'entity': {
        !this.grpForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.grpForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Entity is required') :
            this.grpForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your entity should be at 2 characters') : messageLib = '';
        break;
      }

      case 'isUserGroupFound': {
        messageLib = this._translate.getTranslate('isUserGroupFound');
        break;
      }
      case 'usergrouplabel': {
        !this.grpForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.grpForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Group label is required') :
            this.grpForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your group label should be at 3 characters') : messageLib = '';
        break;
      }
      case 'supervisoremail': {
        !this.grpForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.grpForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Supervisor email is required') :
            this.grpForm.controls[_ctrlName].hasError('pattern') ?
              messageLib = this._translate.getTranslate('This is not a valid email') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }

}
