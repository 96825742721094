import { Component, OnInit, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/translate.service';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { SubReport, ColReportModel, SubReports, ColReportInt } from '../../../../models/job-data.model';
import { CommonMethods } from '../../../../../common/tools/commonMethods';

export interface ReportList {
  reportRef: string,
  reportLabel: string
}
@Component({
  selector: 'mdi-report-subreport',
  templateUrl: './report-subreport.component.html',
  styleUrls: ['./report-subreport.component.css']
})
export class ReportSubreportComponent implements OnInit {

  @Input() reportLabelCur: any;
  private readonly onDestroy = new Subject<void>();

  doValidation: boolean; 
  isLoaded: boolean;
  listSubReports: SubReports;
  listReports: ReportList[];
  cacheReports: ReportList[];
  operandeList=['Contain','notContain','Equal','notEqual'];
  listColOrig: ColReportInt[];
  listColRecall: ColReportInt[];
  // listColTarget: ColReportInt[];
  cacheColOrig: ColReportInt[];
  // cacheColTarget: ColReportInt[];
  blankCol : ColReportInt;
  subListColTarget : any[]
  subCacheColTarget : any[]
  listColZoomCur: any;
  constructor(private _translate: TranslateService, private _jobDataService: JobDataService) { }

  ngOnInit() {
    this.isLoaded = false;
    this.initData();
   
    this._jobDataService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
     
      if (valueEvent === 'subReportActive') {
        this.initData();
      }
      if (valueEvent === 'validOK') {
        this.doValidation = true;
      }
      if (valueEvent === 'validKO') {
        this.doValidation = false;
      }

    }) ;
  }
  ngOnDestroy() {
    this.onDestroy.next();

  }
  initData() {
    this.subListColTarget = [];
    this.subCacheColTarget = [];
    this.listColOrig = [];
    this.listColRecall = [];
    this.listSubReports = undefined;
    this.blankCol = {
      colName: '',
      colType: '',
      displayed: false,
      grouping: false,
      filterList: false,
      filterInput: false,
      pinning: '',
      ranking: 0,
      pinnedRow: false,
    };
    
    this.getListReports()
    .then ((data) => {

      this.listColOrig  = this._jobDataService.getColReportService().colCur;
      this.listColOrig.sort(function(obj1, obj2) {
        return obj1.colName > obj2.colName ? 1 : -1;
      })
      this.cacheColOrig =  [...this.listColOrig] ;
      this.listColOrig.splice(0,0,this.blankCol);
      this.listColRecall =  this.cacheColOrig ;
      
      if(this._jobDataService.getColReportService().subReports) {
        this.listSubReports = this._jobDataService.getColReportService().subReports;
        this.listColZoomCur = this.listSubReports.zoomRecall;

        
        if(this.listSubReports.subReport !== undefined) {
          let curIdx = 0;
          for (const subCur of this.listSubReports.subReport) {
            this.loadColTarget(subCur.reportRef, curIdx);

            curIdx++;
          }
        }
      } else {
        this.listSubReports = {
          zoomPresent: false,
          zoomCols: 0,
          zoomRows: 0,
          zoomRecall: '',
          subReport: []
        }
        const dataReport = {
          reportRef: '',
          reportName: '',
          reportInitialAction: '',
          listColRecall: '',
          foreignKeys: [],
          isCollapsed: false,
          isTargetReport: false,
          limitationCol: false,
        }
        const foreignKey = {
          columnName: '',
          operande: '',
          value: '',
        }
        dataReport.foreignKeys.push(foreignKey);
        this.listSubReports.subReport.push(dataReport);
      }
      this.isLoaded = true;
    })
    .then((error) => {

    });
  }
  getListReports() {
    this.listReports = [];
    
    const blankReport = {
      reportRef: '',
      reportLabel: '',
    };
    return new Promise((resolve, reject) => {
      this._jobDataService.getListReports('subreport')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          this.listReports = CommonMethods.sqlSearch(data.body,'reportRef','reportLabel', 'distinct');
          this.listReports.splice(0,0,blankReport);
          this.cacheReports = data.body;
          let idx = 0 ;
          for (const curLabel of this.listReports) {
            if (curLabel.reportLabel === this.reportLabelCur) {
              this.listReports.splice(idx,1);
              break;
            }
            idx++;
          }
          resolve(this.listReports);
        }, err => {
          this.listReports = [];
          reject(err);
        }
      );
    });
  }
  setValueZoom(_item: string, _value: any) {
    if(_item === 'zoomPresent') {
      this.listSubReports.zoomPresent = _value;
      this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
    }
  }
  setListColRecall(_value: string, _idx: number) {
    // console.log(_value);
    if(_idx === -1) {
      this.listSubReports.zoomRecall = _value;
    } else {
      this.listSubReports.subReport[_idx].listColRecall = _value;
    }

    this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
  }
  setListCol(_checked: boolean, _idx: number) {
    this.listSubReports.subReport[_idx].limitationCol = _checked;
    this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
    if(_checked) {
      this.setLimitationCol(_idx);
    }
    else {
      this.listColOrig = this.cacheColOrig;
  
      this.subListColTarget[_idx] =  this.subCacheColTarget[_idx];
    }
  }
  setValueArraySubReport(_dataField: string, _dataValue: any, _index: number) {
    this.doValidation  =true;
    if( _dataField ===  'reportName') { 
      if (_dataValue === '') {
        this.listSubReports.subReport[_index].reportName  = '';
        this.listSubReports.subReport[_index].limitationCol = false;
        this.listSubReports.subReport[_index].isTargetReport  = false;
        this.listSubReports.subReport[_index].reportRef = '';
        this.subCacheColTarget[_index] = [];
        this.subListColTarget[_index]= [];

        this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
        return;
      }
      this.listSubReports.subReport[_index].reportName  = _dataValue;
      this.listSubReports.subReport[_index].isTargetReport  = true;
      this.listSubReports.subReport[_index].limitationCol = false;
      for (const reportCur of this.listReports) {
        if(reportCur.reportLabel === _dataValue) {
          this.listSubReports.subReport[_index].reportRef = reportCur.reportRef;
          break;
        }
      }
      this.loadColTarget(this.listSubReports.subReport[_index].reportRef,_index);
 
      this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
    }
    if( _dataField ===  'reportInitialAction') {
      this.listSubReports.subReport[_index].reportInitialAction  = _dataValue;
      this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
    }
    return _dataValue;
  }
  loadColTarget(reportRef: string, _idx: number) {
    let listColTarget: ColReportInt[];
    let cacheColTarget: ColReportInt[];
    listColTarget = [];
    cacheColTarget = [];
    let tmpList = CommonMethods.sqlSearch(this.cacheReports,'reportRef',reportRef, 'select');
    tmpList = CommonMethods.sqlSearch(tmpList,'id','reportColumns', 'selectMax');
    tmpList = CommonMethods.sqlSearch(this.cacheReports,'id',tmpList[0].id, 'select');
    const jsonObject = JSON.parse(tmpList[0].reportColumns);
    listColTarget = jsonObject.columns;
    listColTarget.sort(function(obj1, obj2) {
      return obj1.colName > obj2.colName ? 1 : -1;
    })
    listColTarget.splice(0,0,this.blankCol);
    cacheColTarget = listColTarget;
    this.subListColTarget[_idx] = listColTarget;
    this.subCacheColTarget[_idx] = cacheColTarget;
  }
  counter(_item: string, _idx: number) {
    if(_item === 'zoomCols') {
      if(!this.listSubReports.zoomCols) {
        this.listSubReports.zoomCols = 0;
      }
      this.listSubReports.zoomCols += _idx;
      if(this.listSubReports.zoomCols < 1) this.listSubReports.zoomCols = 1;
      if(this.listSubReports.zoomCols > 12) this.listSubReports.zoomCols = 12;     
    }
    if(_item === 'zoomRows') {
      if(!this.listSubReports.zoomRows) {
        this.listSubReports.zoomRows = 0;
      }
      this.listSubReports.zoomRows += _idx;
      if(this.listSubReports.zoomRows < 1) this.listSubReports.zoomRows = 1;
      if(this.listSubReports.zoomRows > 20) this.listSubReports.zoomRows = 20;     
    }
  }
  addSubReport() {
    const numReport = this.listSubReports.subReport.length+1;
    const dataReport = {
      reportRef: '',
      reportName: '',
      reportInitialAction: '',
      listColRecall: '',
      foreignKeys: [],
      isCollapsed: false,
      isTargetReport: false,
      limitationCol: false,
    }
    const foreignKey = {
      colOrig: '',
      operande: '',
      colTarget: '',
    }
    dataReport.foreignKeys.push(foreignKey);
    this.listSubReports.subReport.push(dataReport);
    this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
  }
  removeSubReport(i: number) {
    this.listSubReports.subReport.splice(i,1);
    this._jobDataService.changeColReportService(null, this.listSubReports,null,null,false,null,null);
  }

  setLimitationCol( _idx: number) {
    this.listColOrig = [];
    let listColTarget: ColReportInt[];
    listColTarget = [];

   
    const cacheColTarget: ColReportInt[] = this.subCacheColTarget[_idx];
    for(const colCur of this.cacheColOrig) {
      
      const result = cacheColTarget.find(item => {
        return item.colName === colCur.colName;
      });
      if (result!== undefined) {
        this.listColOrig.push(result);
        listColTarget.push(result);
      }
      
    }
    this.subListColTarget[_idx] =  listColTarget;
  }
  removeForeignKey(_rowLine:number,_i:number) {
    this.listSubReports.subReport[_rowLine].foreignKeys.splice(_i,1);
    this._jobDataService.changeColReportService(null, this.listSubReports, null,null,false,null,null);
  }
  addForeignKey(_rowLine:number, _iCol: number) {
    const foreignKey = {
      colOrig: '',
      operande: '',
      colTarget: '',
    }
    if(this.listSubReports.subReport[_rowLine].foreignKeys === undefined) {
      this.listSubReports.subReport[_rowLine].foreignKeys = [];
    }
    if(_iCol+1<this.listSubReports.subReport[_rowLine].foreignKeys.length) {
      this.listSubReports.subReport[_rowLine].foreignKeys.splice(_iCol+1,0,foreignKey);
    }
    else this.listSubReports.subReport[_rowLine].foreignKeys.push(foreignKey);
    this._jobDataService.changeColReportService(null, this.listSubReports, null,null,false,null,null);
  }

  register() {
    this._jobDataService.changeStatutEvent('doValidation');
  }
  doCancel() {
    this._jobDataService.changeStatutEvent('doCancel');
  }
}
