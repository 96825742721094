<div class="container-fluid stdTheme " >
    <div class="flex-row row stdTheme" style="margin: 0px;">
      <div class="col-8 table-responsive" style="height: 90%;">
        <table class="table stdTheme" id="trackingWorksList"  >
          <thead class="theadWork">
            <tr class="trWork">
                <th style="width: 5%; padding: 0px; text-align: center;" >{{'numberTrack' | translate}}</th>
                <th style="width: 20%; padding: 0px; text-align: center;" >{{'recordedTrack' | translate}}</th>
                <th style="width: 20%; padding: 0px; text-align: center;">{{'userLogged' | translate}}</th>
                <th style="width: 15%; padding: 0px; text-align: center;">{{'workStatut' | translate}}</th>
                <th style="width: 35%; padding: 0px; text-align: center;">{{'workComment' | translate}}</th>
                <th style="width: 5%; padding: 0px; text-align: center;">{{'Mes' | translate}}</th>
            </tr>
          </thead>
          <tbody class="tbodyWork" >
            <div *ngFor="let worksTrackingCur of trackingList; let i = index">
             <tr class="trWork " [class.data-selected]="i === selectedRow" (click)="setClickedRow(i)">
                <td style="width: 5%; padding: 0px; text-align: center;" >{{worksTrackingCur.numberTrack + 1 }}</td>
                <td style="width: 20%; padding: 0px; text-align: center;">{{getDateTracking(worksTrackingCur.recordedTrack)}}</td>
                <td style="width: 20%; padding: 0px; text-align: center;">{{worksTrackingCur.userLogged }}</td>
                <td style="width: 15%; padding: 0px; text-align: left;">{{worksTrackingCur.workStatut  | translate }}</td>
                <td style="width: 35%; padding: 0px; text-align: left;">{{worksTrackingCur.workComment }}</td>
                <td *ngIf="worksTrackingCur.alarmsMes !== null && worksTrackingCur.alarmsMes.length > 0" style="width: 5%; padding: 0px; text-align: center;">
                      <b>({{worksTrackingCur.alarmsMes.length}})</b></td>
                <td *ngIf="worksTrackingCur.alarmsMes !== null &&  worksTrackingCur.alarmsMes.length === 0" style="width: 5%; padding: 0px; text-align: center;">
                  </td>
              </tr>
              <tr class="trWork " *ngIf="i === selectedRow && this.wksAlarmMessages.length > 0">
                <td >
                  <table style="width: 100%;">
                    <thead class="theadWork">
                      <tr class="trWork">
                          <th style="width: 10%; padding: 0px; text-align: center;" >{{'sendBy' | translate}}</th>
                          <th style="width: 20%; padding: 0px; text-align: center;" >{{'recipientsMes' | translate}}</th>
                          <th style="width: 20%; padding: 0px; text-align: center;">{{'sendDate' | translate}}</th>
                          <th style="width: 20%; padding: 0px; text-align: center;">{{'mediaMes' | translate}}</th>
                          <th style="width: 20%; padding: 0px; text-align: center;">{{'objectMes' | translate}}</th>
                          <!--<th style="width: 10%; padding: 0px; text-align: center;">{{'bodyMes' | translate}}</th>-->
                      </tr>
                    </thead>
                    <tbody class="tbodyWork" >
                      <div *ngFor="let alarmMesCur of wksAlarmMessages; let i1 = index">
                        <tr class="trWork " [class.alarm-selected]="i1 === selectedAlarm" (click)="setClickedAlarm(i1)">
                          <td style="width: 10%; padding: 0px; text-align: center;" >{{alarmMesCur.sendBy }}</td>
                          <td style="width: 20%; padding: 0px; text-align: center;" >{{alarmMesCur.recipientsMes }}</td>
                          <td style="width: 20%; padding: 0px; text-align: center;">{{getDateTracking(alarmMesCur.sendDate)}}</td>
                          <td style="width: 20%; padding: 0px; text-align: center;">{{alarmMesCur.mediaMes }}</td>
                          <td style="width: 20%; padding: 0px; text-align: left;">{{alarmMesCur.objectMes }}</td>
                          <!--<td style="width: 10%; padding: 0px; text-align: left;">{{alarmMesCur.bodyMes }}</td>-->
                        </tr>
                      </div>
                    </tbody>
                  </table>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
      <div class="col-4" style="height: 90%;">
        <div *ngIf="displayTracking && (trackingCur !== undefined)">
          <!--https://www.w3schools.com/howto/howto_css_inline_form.asp-->
          <!--
          <form class="form-inline" >
            <label for="email">Email:</label>
            <input type="email" id="email" placeholder="Enter email" name="email" class="inputTheme" disabled>
            <label for="pwd">Password:</label>
            <input type="password" id="pwd" placeholder="Enter password" name="pswd" disabled>
            <label>
              <input type="checkbox" name="remember"> Remember me
            </label>
          </form>
          -->
          <div>
            <h4>{{'statutDetail' | translate}}</h4>
          </div>
          <form  >
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;" >
                <label for="recordedTrack">{{'recordedTrack' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="recordedTrack" name="recordedTrack" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{getDateTracking(trackingCur.recordedTrack)}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;" >
                <label for="userLogged">{{'userLogged' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="userLogged" name="userLogged" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{trackingCur.userLogged}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;">
                <label for="workStatut">{{'workStatut' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="workStatut" name="workStatut" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{trackingCur.workStatut}}" disabled>
              </div>
            </div>

            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-12" style="padding: 0px;">
                <label for="workComment">{{'workComment' | translate}} </label>
              </div>
            </div>

            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-12" style="padding: 0px;">
                <textarea id="workComment" name="workComment" class="displayTheme" disabled
                    rows="5" cols="60" value="{{trackingCur.workComment}}">
                </textarea>
              </div>
            </div>
          </form>
        </div>
        <!--
              sendBy: string;
    recipientsMes: string;
    sendDate: Date;
    mediaMes: string;
    objectMes: string;
    bodyMes: string;
        -->
        <div *ngIf="displayAlarm && (alarmMessagesCur !== undefined)">
          <div>
            <h4>{{'messageDetail' | translate}}</h4>
          </div>
          <form  >
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;" >
                <label for="sendDate">{{'sendDate' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="sendDate" name="sendDate" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{getDateTracking(alarmMessagesCur.sendDate)}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3"  style="padding: 0px;" >
                <label for="sendBy">{{'sendBy' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="sendBy" name="sendBy" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{alarmMessagesCur.sendBy}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;">
                <label for="recipientsMes">{{'recipientsMes' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="recipientsMes" name="recipientsMes" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{alarmMessagesCur.recipientsMes}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3"  style="padding: 0px;" >
                <label for="mediaMes">{{'mediaMes' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="mediaMes" name="mediaMes" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{alarmMessagesCur.mediaMes}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-3" style="padding: 0px;">
                <label for="objectMes">{{'objectMes' | translate}} :</label>
              </div>
              <div class="col-9" style="padding: 0px;" >
                <input type="text" id="objectMes" name="objectMes" class="displayTheme" style="margin-left: 10px;padding-left: 0px;padding-right: 0px;"
                            value="{{alarmMessagesCur.objectMes}}" disabled>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-12" style="padding: 0px;">
                <label for="bodyMes">{{'bodyMes' | translate}} </label>
              </div>
            </div>
            <div class="flex-row row stdTheme" style="margin: 0px;">
              <div class="col-12" style="padding: 0px;" >
                <textarea id="bodyMes" name="bodyMes" class="displayTheme" disabled
                    rows="5" cols="60" value="{{alarmMessagesCur.bodyMes}}">
                </textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>

