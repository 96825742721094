import { Component, Input, SimpleChanges, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { CommonMethods } from '../../../../../common/tools/commonMethods';

import { AccountingBalance, AccountingModel, WksBusinessModel } from '../../../../models/wks-business.model';
import { IhmOptions } from '../../../../models/wks-param.model';
import { WksWorksModel } from '../../../../models/wks-works.model';
import { LabelValue } from '../../../../../../models/data.model';


@Component({
  selector: 'mdi-works-accounting',
  templateUrl: './works-accounting.component.html',
  styleUrls: ['./works-accounting.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksAccountingComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() accountingBalance: AccountingBalance;
  @Input() ihmOptions: IhmOptions;
  @Input() workCur: WksWorksModel;
  @Input() bsnCur: WksBusinessModel;

  private readonly onDestroy = new Subject<void>();

  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
  };
  OKtoDisplayAccoutingRows: boolean;
  isReadOnly: boolean;

  rowStatut: {
    newRow: boolean;
    editDisplay: boolean,
    editOpen: boolean,
    submitDisplay: boolean,
    trashDisplay: boolean,
    detailCollapse: boolean,
    detailDisplay: boolean,
    generated: boolean
  } [];
  statutsList: LabelValue[];
  operationsList: LabelValue[];

  rowInput: { 
    paymentRef: string;
  } [];
  accountingBalanceCache: AccountingBalance;
  timeFormat: string;
  accountingPosition: string;

  constructor(private _userService: UserService,
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private _dialog: MatDialog,
              private _ressources: RessourcesService) { }
  
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case
            // accountingBalance
          case 'accountingBalance': {
            this.accountingBalance = changes['accountingBalance'].currentValue;
            break;
          } // end case
          // ihmOptions
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {
    if (this._userService.getUserLogged() === undefined) {
      return ;
    }
    if (!this.initParam()) {
      return;
    }
    this.initBooleans();

    if (this.accountingBalance === undefined) {
      return;
    }
    if (this.accountingBalance !== null && this.accountingBalance.balance === 0 && this.accountingBalance.customerOutstanding === 0 ) {
      return;
    }
    if (this.workCur !== undefined) {
    this.getAccountingCustomerBalance(this.workCur.stdEntity, this.workCur.ownerId);
    }
    if (this.bsnCur !== undefined) {
      this.getAccountingCustomerBalance(this.bsnCur.stdEntity, this.bsnCur.id);
      }
    // this.OKtoDisplayAccoutingRows = true;
  }
  initBooleans() {
    this.OKtoDisplayAccoutingRows = false;
    this.isReadOnly = this.ihmOptions.readOnly;
  }
  initParam(): boolean {
    if (!this.settingIntFmts()) {
      return false;
    }
    this.statutsList = [];
    let statutItem = {
      value: 'paid',
      label: this._translate.instant('paid')
    };
    this.statutsList.push(statutItem);
    statutItem = {
      value: 'unpaid',
      label: this._translate.instant('unpaid')
    };
    this.statutsList.push(statutItem);
    this.operationsList = [];
    let operationItem = {
      value: '',
      label: ''
    };
    this.operationsList.push(operationItem);
    operationItem = {
      value: 'payment',
      label: this._translate.instant('payment')
    };
    this.operationsList.push(operationItem);

    return true;
  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText:  this._ressources.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers:  this._ressources.getFormat(internationnalFormat, 'justifs', 'numbers')
    };
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    return true;
  }
  onStatutSelected(idxItem: number, idxRow: number, selectedOption: string): void {
    this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut = selectedOption;
    this.rowStatut[idxRow].detailDisplay = false;
    if (this.accountingBalanceCache.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut !== selectedOption) {
      this.rowStatut[idxRow].submitDisplay = !this.rowStatut[idxRow].submitDisplay;
    }
    if (selectedOption === 'paid') {
      this.rowStatut[idxRow].detailDisplay = true;
      this.rowStatut[idxRow].detailCollapse = false;
      if (this.rowInput[idxRow].paymentRef === null || this.rowInput[idxRow].paymentRef === undefined ) {
        this.rowStatut[idxRow].submitDisplay = false;
      }
    }
    if (selectedOption === 'unpaid') {
      this.rowInput[idxRow].paymentRef = undefined;
    }
  }
  onRowInputChange(_typeInput, inputValue: any, idxItem: number, idxRow: number): void {
    switch (_typeInput) {
      case 'paymentRef':
        this.rowInput[idxRow].paymentRef = inputValue;
        if (this.rowInput[idxRow].paymentRef === '' 
          || this.rowInput[idxRow].paymentRef === null  
          || this.rowInput[idxRow].paymentRef === undefined) {
            this.rowStatut[idxRow].submitDisplay = false;
        } else  {
          this.rowStatut[idxRow].submitDisplay = true;
          this.rowStatut[idxRow].detailCollapse = false;
          this.rowStatut[idxRow].detailDisplay = true;
        }
        break;
        case 'accOperation':
          this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accOperation = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break; 
        case 'accLabel':
          this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accLabel = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break; 
        /*
        case 'accStatut':
          this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break;
        */ 
        case 'accAmount':
          this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accAmount = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break;  
        case 'accNwsref':
          this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accNwsref = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break;  
        case 'paymentRef1':
          this.rowInput[idxRow].paymentRef = inputValue;
          this.rowStatut[idxRow].submitDisplay = this.verifDataRow(idxItem, idxRow);
        break;          
    }
  }
  getDateTimeFmt(value: any): string {
    return CommonMethods.dateToString(value, 'unixM', this.timeFormat);
  }
  verifDataRow(idxItem: number, idxRow: number): boolean {
    let ctrlCur = this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accOperation;
    if (ctrlCur === null || ctrlCur === undefined) {
      return false;
    }
    ctrlCur = this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accLabel;
    if (ctrlCur === null || ctrlCur === undefined) {
      return false;
    }
    ctrlCur = this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accNwsref;
    if (ctrlCur === null || ctrlCur === undefined) {
      return false;
    }
    /*
    ctrlCur = this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut;
    if (ctrlCur === null || ctrlCur === undefined) {
      return false;
    }
    */
    const ctrlNumCur = this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accAmount;
    if (ctrlNumCur === null || ctrlNumCur === undefined) {
      return false;
    }
    
    if (this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accOperation === 'payment') {
      ctrlCur = this.rowInput[idxRow].paymentRef;
      if (ctrlCur === null || ctrlCur === undefined) {
        return false;
      }
    }
    return true;
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  addRow(indexItem: number): void {

    const newRow = {
      id: undefined,
      stdEntity: this.workCur.stdEntity,
      workId: this.workCur.id,
      customerId: this.workCur.ownerId,
      accOperation: undefined,
      accLabel: '',
      accStatut: undefined,
      accNwsref: '',
      accToTransfer: false,
      accTransferDate: undefined,
      accTransferEntry: undefined,
      accTransferAccount: undefined,
      accJson: undefined,
      accAmount: 0.0
    };

    this.accountingBalance.wksAccountingWorks[indexItem].wksAccountings.push(newRow);

    const rowStatutItem = {
      newRow: true,
      editDisplay: false,
      editOpen: true,
      submitDisplay: false,
      trashDisplay: false,
      detailCollapse: false,
      detailDisplay: false,
      generated: false
    };
    this.rowStatut.push(rowStatutItem);
    const rowInputItem = {
      paymentRef: undefined
    };
    this.rowInput.push(rowInputItem);
  }
  toggleRow(idxRow: number): void {
    this.rowStatut[idxRow].detailCollapse = !this.rowStatut[idxRow].detailCollapse;

    return;
  }
  editRow(idxItem: number, idxRow: number): void {
    this.rowStatut[idxRow].editOpen = !this.rowStatut[idxRow].editOpen;
    if (!this.rowStatut[idxRow].editOpen) {
      this.rowStatut[idxRow].submitDisplay = false;
      this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut = this.accountingBalanceCache.wksAccountingWorks[idxItem].wksAccountings[idxRow].accStatut;
    }
  }
  saveRow(idxItem: number, idxRow: number): void {
    this.displayMessage('saveRow', [
        this._translate.instant('save row') + ' ' +
        this._translate.instant(this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accOperation),
        this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accLabel,
        idxItem, 
        idxRow ]
      );
  }
  removeRow(idxItem: number, idxRow: number): void {
    this.displayMessage('removeRow', [
      this._translate.instant('remove row') + ' ' +
        this._translate.instant(this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accOperation),
        this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow].accLabel,
        idxItem, 
        idxRow ]);
  }
  removeAccountingRow(idxItem: number, idxRow: number): void {
    const accountingCur = JSON.parse(JSON.stringify(this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow]));
    this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings.splice(idxRow, 1);
    this.rowStatut.splice(idxRow, 1);
    this.rowInput.splice(idxRow, 1);
    this.saveAccountingRow(accountingCur, 'delete');
  }
  saveAccountingRowItem(idxItem: number, idxRow: number): void {
    const accountingCur =  this.fillModel(idxItem, idxRow);
    
    this.saveAccountingRow(accountingCur, 'update');
  }
  saveAccountingRow(accountingRow: AccountingModel, actionArg: string): void {
    this._wksCentralService.saveWksAccounting(accountingRow, actionArg)
    .subscribe(
      returnValue => {
        const accountingReturn = returnValue.body;
        this.getAccountingCustomerBalance(this.workCur.stdEntity, this.workCur.ownerId);
      },
      err => {
        const message = JSON.stringify(err);
        // console.log(JSON.stringify(err));
        this._dialog.closeAll();
        this.displayMessage('validationError', undefined);
      }
    );
  }
  fillModel(idxItem: number, idxRow: number): AccountingModel {
    const accountingCur = JSON.parse(JSON.stringify(this.accountingBalance.wksAccountingWorks[idxItem].wksAccountings[idxRow])) as AccountingModel;
    let accJSON: any;
    try {
      accJSON = JSON.parse(accountingCur.accJson);
    } catch (error) {
    } finally { }

    if (accJSON === null || accJSON === undefined ) {
      accJSON = {};
    }
    for (const rowInputItem of this.rowInput) {
      if (rowInputItem.paymentRef !== undefined) {
        accJSON.paymentRef = rowInputItem.paymentRef;
      }
    }
    accountingCur.accJson = JSON.stringify(accJSON);
    return accountingCur;
  }
  getAccountingCustomerBalance(entity: string, customerId: string): void {
    this.OKtoDisplayAccoutingRows = false;
    this.displayMessage('waitingBalance', undefined);
    this._wksCentralService.getAccountingCustomerBalance(entity, customerId)
    .subscribe(
      returnValue => {
        const accountingReturn = returnValue.body;
        this.fillForm(accountingReturn);
        
        this._dialog.closeAll();
        this.OKtoDisplayAccoutingRows = true;
      },
      err => {
        const message = JSON.stringify(err);
        // console.log(JSON.stringify(err));
        this._dialog.closeAll();
        this.displayMessage('accountingError', undefined);
      }
    );
  }

  fillForm(accountingReturn: AccountingBalance) {
    this.accountingPosition = 'green';
    if (accountingReturn.balance > 0) {
      this.accountingPosition = 'amber';
    }
    if (accountingReturn.balance >= accountingReturn.customerOutstanding) {
      this.accountingPosition = 'red';
    }
    this.accountingBalance = JSON.parse(JSON.stringify(accountingReturn));
    this.accountingBalance.wksAccountingWorks = [];
    if (this.statutCall === 'work') {
      for (const accountingWork of accountingReturn.wksAccountingWorks) {
        if (accountingWork.workId === this.workCur.id) {
          this.accountingBalance.wksAccountingWorks.push(accountingWork);
          break;
        }
      }
    } else {
      this.accountingBalance.wksAccountingWorks = accountingReturn.wksAccountingWorks;
    }

    this.rowStatut = [];
    this.rowInput = [];
    if (this.accountingBalance.wksAccountingWorks !== null)  {
      for (const accountingRow of this.accountingBalance.wksAccountingWorks[0].wksAccountings) {
        const accJson = JSON.parse(accountingRow.accJson);
        const generatedJson = accJson.generated;
        const paymentRef = accJson.paymentRef; 
        const rowStatutItem = {
          newRow: false,
          editDisplay: accountingRow.accTransferDate === null ? true : false,
          editOpen: false,
          submitDisplay: false,
          trashDisplay: accountingRow.accTransferDate === null ? true : false,
          detailCollapse: true,
          detailDisplay: accountingRow.accTransferDate !== null || (paymentRef !== null && paymentRef !== undefined) ? true : false,
          generated: generatedJson
        };
        this.rowStatut.push(rowStatutItem);
        const rowInputItem = {
          paymentRef: accJson.paymentRef ? accJson.paymentRef : undefined
        };
        this.rowInput.push(rowInputItem);
      }
    }
    this.accountingBalanceCache = JSON.parse(JSON.stringify(this.accountingBalance));
  }
  displayMessage(_actionCur: string, args: any[] ) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    titleBox = 'accounting';
    let messageBox: string;
    let idxRow: number;
    let idxItem: number;
    let typeDialogArg: string;
    if (_actionCur === 'removeRow') {
      messageBox = args[0] + ' ' + args[1] + ' ?';
      idxItem = args[2];
      idxRow = args[3];
      typeDialogArg = 'confirmAction';
    } 
    if (_actionCur === 'saveRow') {
      messageBox = args[0] + ' ' + args[1] + ' ?';
      idxItem = args[2];
      idxRow = args[3];
      typeDialogArg = 'confirmAction';
    } 
    if (_actionCur === 'validationError') {
      messageBox = this._translate.instant('validationError');
    } 
    if (_actionCur === 'waitingBalance') {
      messageBox = this._translate.instant('balance_inprogress');
      typeDialogArg = 'infoProgressWks';
    }
    if (_actionCur === 'accountingError') {
      messageBox = 'balance_failure';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog:  typeDialogArg !== undefined ? typeDialogArg : 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: messageBox,
        data1: '',
        data2: '',
        messageType: 'ERROR'
    };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data !== undefined)  {
          if (( data === 'okAction') && (_actionCur === 'removeRow')) {
            this.removeAccountingRow(idxItem, idxRow);
          }
          if (( data === 'okAction') && (_actionCur === 'saveRow')) {
            this.saveAccountingRowItem(idxItem, idxRow);
          }
          if (( data === 'okAction') && (_actionCur === 'accountingError')) {
            this._dialog.closeAll();
          }
        }
      });
  }
}
