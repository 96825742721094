import { light, dark } from '../../assets/themes/themes';
import * as i0 from "@angular/core";
export class ThemeService {
    constructor() {
        this.active = light;
        this.availableThemes = [light, dark];
    }
    getAvailableThemes() {
        return this.availableThemes;
    }
    getActiveTheme() {
        return this.active;
    }
    isDarkTheme() {
        return this.active.name === dark.name;
    }
    setDarkTheme(isDarkCur) {
        (isDarkCur ? this.setActiveTheme(dark) : this.setActiveTheme(light));
        // this.setActiveTheme(dark);
    }
    setLightTheme() {
        this.setActiveTheme(light);
    }
    setActiveTheme(theme) {
        this.active = theme;
        Object.keys(this.active.properties).forEach(property => {
            document.documentElement.style.setProperty(property, this.active.properties[property]);
        });
    }
}
ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(); }, token: ThemeService, providedIn: "root" });
