    <mat-card class="wkscard stdTheme" *ngIf="isReady" layout="column" flex style="padding-top: 5px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;">
      <form *ngIf="wksBnsFormInit" [formGroup]="wksBnsForm" >
        <mat-card-content class="wkscard" >
          <div class="flex-row row wkscard">
            <div class="col-6 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
              <mat-radio-group #structure="matRadioGroup" aria-label="Select an option" 
                    formControlName="bsnStructure" (change)="onStructureChange(structure.value)">
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="company">{{ 'company' | translate }}</mat-radio-button>
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="administration">{{ 'administration' | translate }}</mat-radio-button>
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="person">{{ 'person' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <!--<div class="col-1" >
              
            </div>-->
            <div class="col-6 " style="padding-left: 0px;padding-right: 0px;">
              <div *ngIf="isPerson"  >
                <span style="margin-top: 5px;margin-right:3%;">Statut : </span>
                <mat-radio-group  #taxsystem="matRadioGroup" aria-label="Select an option" 
                      formControlName="bsnTaxsystem" (change)="onTaxsystemChange(taxsystem.value)">
                      
                  <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="professional">{{ 'professional' | translate }}</mat-radio-button>
                  <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="individual">{{ 'individual' | translate }}</mat-radio-button>
                  <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="unknow">{{ 'unknow' | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="flex-row row wkscard">
            <div class="col-3" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'Country' | translate }}</mat-label>
                <mat-select id="bsnCountry" #bsnCountry placeholder="{{ 'Choose country' | translate }}" formControlName="bsnCountry" 
                          panelClass="panelOptions" ngDefaultControl required (selectionChange)="countrySelected(bsnCountry.value)">
                  <mat-select-header>
                    <div class="filterInput" style="display: inline-block;" >            
                      <button mat-icon-button matPrefix aria-label="search" >
                        <mat-icon>search</mat-icon>
                    </button>  
                      <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                  style="margin: 5px" (keyup)="onKeyCountry($event.target.value)" >
                      <button mat-icon-button matSuffix aria-label="clear" 
                                  *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                      [value]="country.isoAlpha2"  >{{country.commonName | translate}}
                  </mat-option>
                </mat-select>      
              </mat-form-field>
            </div>
            <div class="col-2 " >
              <div class="checkbox" style="height: 40px;margin-top: 25px">
                <label class="labelLine" style="margin-top: 10px; margin-left: 5px; margin-right: 10px" >{{ 'bsnCashPay' | translate }}</label>
                <input type="checkbox" id="bsnCashpay"  #bsnCashpay class="inputTheme" formControlName="bsnCashpay"
                      [checked]="bsnAccountingCur.bsnCashpay" 
                      (click)="changeCashPay(bsnCashpay.checked)">
              </div>
            </div>
            <div class="col-1">
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'bsnAccount' | translate }}</mat-label>
                <input id="bsnAccount" class="form-control inputTheme" formControlName="bsnAccount" 
                        matInput #input placeholder="{{ 'bsnAccount' | translate }}" 
                        [value] = "bsnAccountingCur.bsnAccount" 
                        (keyup)="changeBsnAccountField('bsnAccount',$event.target.value)"
                  >
              </mat-form-field>
            </div>
            <div class="col-2 d-flex justify-content-end"  >
              <mat-form-field class="mdiinput form-group" appearance="standard">
                  <mat-label>{{ 'customerOutstanding' | translate }}</mat-label>
                  <input class="form-control inputTheme" name="customerOutstanding" #customerOutstanding formControlName="customerOutstanding"  style="text-align: right" 
                          value="{{customerOutstandingValue | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                          (change)="onChangeCustomerOutstanding(customerOutstanding.value)"
                          matInput (keypress)="numberAndDotOnly($event)"
                          placeholder="{{ 'customerOutstanding' | translate }}"
                      />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field class="mdiinput form-group" appearance="standard"  style="margin-bottom: 0px;">
                  <mat-label>{{ 'scaleName' | translate }}</mat-label>
                  <mat-select id="scaleName" #scaleName placeholder="{{ 'Choose scaleName' | translate }}" formControlName="scaleName" 
                          panelClass="mdiselectcontent"   ngDefaultControl required 
                          (selectionChange)="changeBsnAccountField('scaleName',scaleName.value)">
                      <mat-option class="selectOptionsTheme" *ngFor="let scaleCur of scalesList" 
                          [value]="scaleCur"  >{{scaleCur }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            <div class="col-1" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'bsnCoef' | translate }}</mat-label>
                <input id="bsnCoef" class="form-control inputTheme" formControlName="bsnCoef" 
                        matInput #input placeholder="{{ 'bsnCoef' | translate }}" 
                        [value] = "bsnAccountingCur.bsnCoef" 
                        (keyup)="changeBsnAccountField('bsnCoef',$event.target.value)"
                  >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row wkscard" style="margin-bottom: 10px;">
            
            <div class="col-2 " >
              <div class="checkbox" style="height: 40px;margin-top: 25px">
                <label class="labelLine" style="margin-top: 10px; margin-left: 5px; margin-right: 10px" >{{ 'taxableVat' | translate }}</label>
                <input type="checkbox" id="taxableVat"  #taxableVat class="inputTheme" formControlName="taxableVat"
                      [checked]="bsnAccountingCur.taxableVat" 
                      (click)="changeTaxableVat(taxableVat.checked)">
              </div>
            </div>
            <div class="col-2" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'idVat' | translate }}</mat-label>
                <input id="idVat" class="form-control inputTheme" formControlName="idVat" 
                        [required]="bsnAccountingCur.taxableVat"
                        matInput #input placeholder="{{ 'idVat' | translate }}" 
                  >
              </mat-form-field>
            </div>
            <div class="col-2 " >
              <div class="checkbox" style="height: 40px;margin-top: 25px">
                <label class="labelLine" style="margin-top: 10px; margin-left: 5px; margin-right: 10px" >{{ 'customsRegistration' | translate }}</label>
                <input type="checkbox" id="customsRegistration"  #customsRegistration class="inputTheme" formControlName="customsRegistration"
                      [checked]="bsnAccountingCur.customsRegistration" 
                      (click)="changeCustomsRegistration(customsRegistration.checked)">
              </div>
            </div>
            <div class="col-2" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'customsId' | translate }}</mat-label>
                <input id="customsId" class="form-control inputTheme" formControlName="customsId" 
                        [required]="bsnAccountingCur.customsRegistration"
                        matInput #input placeholder="{{ 'customsId' | translate }}" 
                  >
              </mat-form-field>
            </div>
            <div class="col-2 " >
              <div class="checkbox" style="height: 40px;margin-top: 25px">
                <label class="labelLine" style="margin-top: 10px; margin-left: 5px; margin-right: 10px" >{{ 'freeZone' | translate }}</label>
                <input type="checkbox" id="freeZone"  #freeZone class="inputTheme" formControlName="freeZone"
                      [checked]="bsnAccountingCur.freeZone" 
                      (click)="changeFreeZone(freeZone.checked)">
              </div>
            </div>
            <div class="col-2" >
              <mat-form-field class="wksInput form-group" appearance="standard">
                <mat-label>{{ 'freeZoneId' | translate }}</mat-label>
                <input id="freeZoneId" class="form-control inputTheme" formControlName="freeZoneId" 
                        [required]="bsnAccountingCur.freeZone"
                        matInput #input placeholder="{{ 'freeZoneId' | translate }}" 
                  >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row wkscard" *ngIf="!isCashPay" style="margin-bottom: 20px;">

            <div class="col-3" >
              <mat-form-field class="mdiinput form-group" appearance="standard"  style="margin-bottom: 0px;">
                  <mat-label>{{ 'bsnDelayDays' | translate }}</mat-label>
                  <mat-select id="bsnDelayDays" #bsnDelayDays  formControlName="bsnDelayDays" 
                          panelClass="mdiselectcontent"   ngDefaultControl required 
                          (selectionChange)="changeBsnAccountField('bsnDelayDays',bsnDelayDays.value)">
                      <mat-option class="selectOptionsTheme" *ngFor="let delayCur of delayList" 
                          [value]="delayCur.value"  >{{delayCur.label}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field class="mdiinput form-group" appearance="standard"  style="margin-bottom: 0px;">
                  <mat-label>{{ 'bsnDefferedPaiement' | translate }}</mat-label>
                  <mat-select id="bsnDefferedPaiement" #bsnDefferedPaiement  formControlName="bsnDefferedPaiement" 
                          panelClass="mdiselectcontent"   ngDefaultControl required 
                          (selectionChange)="changeBsnAccountField('bsnDefferedPaiement',bsnDefferedPaiement.value)">
                      <mat-option class="selectOptionsTheme" *ngFor="let condCur of conditionsList" 
                          [value]="condCur.value"  >{{condCur.label }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
          </div> 
          <div *ngIf="isPerson || isCompany || isAdministration" class="flex-row row wkscard">
            <hr class="lineCard"/>
            <!--<div class="col-11 lineCard" ></div>-->
          </div>
        
          <div *ngIf="isPerson || isCompany || isAdministration" style="margin-right: 10px; margin-left: 10px;">
            <mdi-address-block [config]="configAddress" 
                            [dataIn]="addressCur" 
                            [markChange]="markChange" 
                            (dataOut)="dataOutAddress($event)"></mdi-address-block>
          </div>
        </mat-card-content>

      </form>
     
    </mat-card>

