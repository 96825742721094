import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-row-pinned',
  template: `<div [ngStyle]="style" >{{currentValue}}</div>`,
})
// https://www.ag-grid.com/javascript-grid-row-pinning/ 
export class RowPinnedComponent  implements ICellRendererAngularComp {

  public params: any;
  public style: string;
  currentValue : any;
  agInit(params: any): void {
      this.params = params;
      this.style = this.params.style;
      this.currentValue = params.value;
      const currentLang = params.colDef.pinnedRowCellRendererParams.currentLang;
      const colFmtString = params.colDef.pinnedRowCellRendererParams.colFmtString;
      const colFmtName = params.colDef.pinnedRowCellRendererParams.colFmtName;
      const pinnedRow = params.colDef.pinnedRowCellRendererParams.pinnedRow;
      if ((colFmtName === 'numeric') && (pinnedRow) ) {
        this.currentValue = CommonMethods.fmtNumber(params.value,colFmtString, currentLang);
      }
  }

  refresh():boolean{
      return false;
  }
}
