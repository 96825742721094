import { MatPaginatorIntl } from '@angular/material';
export class MDIMatPaginatorIntl extends MatPaginatorIntl {
    constructor() {
        super(...arguments);
        this.firstPageLabel = 'First page';
        this.itemsPerPageLabel = 'Items per page';
        this.lastPageLabel = 'Last page';
        this.nextPageLabel = 'Next page';
        this.previousPageLabel = 'Previous page';
        /*  constructor(private _translate: TranslateService) {
              this.translate = _translate;
          }*/
        this.getRangeLabel = (page, pageSize, length) => {
            const of = this.translate ? this.translate.getTranslate('mat-paginator-intl.of') : 'of';
            if (length === 0 || pageSize === 0) {
                return '0 ' + of + ' ' + length;
            }
            length = Math.max(length, 0);
            const startIndex = ((page * pageSize) > length) ?
                (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
            const endIndex = Math.min(startIndex + pageSize, length);
            return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
        };
    }
    injectTranslateService(translate) {
        this.translate = translate;
        this.translate.onLangChanged.subscribe(() => {
            this.translateLabels();
        });
        this.translateLabels();
    }
    translateLabels() {
        this.firstPageLabel = this.translate.getTranslate('mat-paginator-intl.first_page');
        this.itemsPerPageLabel = this.translate.getTranslate('mat-paginator-intl.items_per_page');
        this.lastPageLabel = this.translate.getTranslate('mat-paginator-intl.last_page');
        this.nextPageLabel = this.translate.getTranslate('mat-paginator-intl.next_page');
        this.previousPageLabel = this.translate.getTranslate('mat-paginator-intl.previous_page');
    }
}
