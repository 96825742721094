// https://www.geeksforgeeks.org/how-to-display-images-in-angular2/
// https://stackblitz.com/edit/get-img-from-url-observable-angular
// https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { WksCentralService } from '../../../../services/wks-central.service';
import { FileToDisplay } from '../../../../../../models/common.model';
@Component({
  selector: 'mdi-works-img',
  templateUrl: './works-img.component.html',
  styleUrls: ['./works-img.component.css']
})
export class WorksImgComponent implements OnInit, OnChanges {

  @Input() statutCall: any;

  @Input() dataList: FileToDisplay[];

  imageToShow: any;
  allIsLoaded: boolean;

  private readonly onDestroy = new Subject<void>();

  imgFiles: any[];

  constructor(private _wksCentralService: WksCentralService) {}
  ngOnInit() {
    // this.generateImgList();
  }
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'dataList': {
            this.dataList = changes['dataList'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
   
    if ((this.dataList.length !== 0) || (this.dataList[0] !== undefined)) {
      this.generateImgList();
     } else {
       this.allIsLoaded = true;
     }
  }
  generateImgList() {
    this.allIsLoaded = false;
    let nbFilesLoaded = 0;
    this.imgFiles = [];
    
    for (const docCur of this.dataList ) {
      const fileCur =    {
        fileName: docCur.fileName,
        fileLegend: docCur.fileLegend,
        filePath: docCur.filePath,
        dataImgLoaded: false,
        imageToShow: undefined,
      };
      this.imgFiles.push(fileCur);
      this._wksCentralService.downloadImgFile(fileCur.filePath, fileCur.fileName)
      .then ((data: Blob) => {
        this.createImageFromBlob(data, fileCur);
        nbFilesLoaded++;
        if (nbFilesLoaded === this.dataList.length)  {
          this.allIsLoaded = true;
        }
      },
      (error: any) => { // error read label
        fileCur.dataImgLoaded = false;
        fileCur.imageToShow = undefined;
        this.allIsLoaded = false;
      });  
    }
  }
 
  // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: Blob, _imgCur: FileToDisplay): any {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      _imgCur.imageToShow = reader.result;
      _imgCur.dataImgLoaded = true;
      }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
