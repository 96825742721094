import { Component, OnInit, ViewChild, ViewEncapsulation, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GridTools } from '../../../../../common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksCentralService } from '../../../../services/wks-central.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { ParamWorksRatesModel, paramworksrates_sch, ParamWorksRatesEvent } from '../../../../models/wks-param.model';
import { LabelValue, InternationalsFormats } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-workrates-table',
  templateUrl: './workrates-table.component.html',
  styleUrls: ['./workrates-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorkratesTableComponent implements OnInit {

  displayType: string;
  optionItem: string;
  sidenavCollapsed: boolean;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;

  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = paramworksrates_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator: boolean;
  cacheDatatemp = [];

  isRegisterWorkRate: boolean;
  doCreateWorkRate: boolean;
  doUpdateDoc = [];
  workRateToUpdate: ParamWorksRatesModel;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;

  actionType: string;

  workRateList: ParamWorksRatesModel[];

  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _ressourcesService: RessourcesService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    // let labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });
    if (this.displayType === null) {
      this.displayType = 'list';
    }
    const labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.sidenavCollapsed = true;

    this._wksCentralService.onRegisterValid
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      if (event === 'cancelRegister') {
        this.toggleSidenav('');

      }
      if (event === 'closeRegister') {
        this.toggleSidenav('');
        this.buildList();
      }
      if (event === 'deleteWorkRate') {
        this.buildList();
      }
    });
    /*
    this._wksCentralService.onSearchDoc
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: ParamWorksRatesEvent) => {
      this.workRateList = event.searchResult;
      this.workRateToUpdate = event.searchCriteria;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
    */
    this.optionItem = labelItem;
    // this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.settingFormats();
    if (this.displayType === 'list') {
      this.buildList();
    }
  }
  settingFormats() {
    if (!this._wksCentralService.getWksEntityCur())  {
      return;
    }
    const otherDataString = this._wksCentralService.getWksEntityCur().otherData;
    const otherDataJson = JSON.parse(otherDataString);
    const intFormat = otherDataJson.internationnalFormat;
    this.timeFormat = this._ressourcesService.getFormat(intFormat, 'datetime', 'timeFormat');
    this.timeFormat = this.timeFormat.replace('dd', 'DD');
    this.timeFormat = this.timeFormat.replace('yyyy', 'YYYY');
    /*
    this.numberFormat = { 
      locale: this._ressourcesService.getFormat(this.intFormat, 'numbers', 'locale'),
      minDecimal: Number.parseInt(this._ressourcesService.getFormat(this.intFormat, 'numbers', 'minimumFractionDigits'), 10),
      maxDecimal: Number.parseInt(this._ressourcesService.getFormat(this.intFormat, 'numbers', 'maximumFractionDigits'), 10),
      currency:  this._ressourcesService.getFormat(this.intFormat, 'numbers', 'currency'),
    };
    */
  }
  buildList() {
    this.loadingIndicator = true;
    this.displayedColumns = [
      { field: 'workLabel', headerName: this._translate.instant('workLabel'), sortable: true, filter: true, resizable: true },

      /*{ field: 'stdCreation', headerName: this._translate.instant('stdCreation'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: 'stdLastupdate', headerName: this._translate.instant('stdLastupdate'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
      /*{ field: 'stdDisable', headerName: this._translate.instant('stdDisable'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },*/
      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(), width: 300
      },
    ];

    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }

    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getParamWorksRatesList();

    
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  refresh() {
    // this.getParamWorksRatesList();
    this.buildList();
  }
  
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
    
    actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>'
                  ;
    
    return actions;
  }
  getParamWorksRatesList() {
    this.selected = [];
    
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.dataSource.data = [];
    this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.dataSource.data = this.translateLabelsRates(data.body);
        // this.cacheDatatemp = [...data.body];
        this.cacheDatatemp = [...this.translateLabelsRates(data.body)];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        if ((err.status === 500) || (err.status === 404)) {
          let titleBox: string;
          let messageBox: string;
          titleBox = 'searching works rates';
          messageBox = 'no work rate found';
          

          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeWorkRate')) {
          this.deleteWorkRateOK(dataCur);
        }
      });

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editWorkRate(data);
                break;
            }
            case 'trash': {
                this.deleteWorkRate(data);
                break;
            }
        }
    }
  }
  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }
  translateLabelsRates(_dataArray: Array<any>):  Array<any> {
    if (!_dataArray[0]) {
      return null;
    }
    // ParamWorksRatesModel[]
    // langLabels?: ParamLabelsI18n[];
    const paramWorksRatesModel: ParamWorksRatesModel[] = [];
    for (const rowCur of _dataArray) {
   
      const langLabels = rowCur.langLabels;
      if (langLabels === null ) {
        continue;
      }
      for (const langLabel of langLabels) {
        if (langLabel.labelLang === this._translate.getCurrentLang()) {
          rowCur.workLabel =  langLabel.labelText;
          break;
        }
      }
      paramWorksRatesModel.push(rowCur);
    }
    return paramWorksRatesModel;
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateDoc = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
                                      this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
 
  toggleSidenav(_arg: string) {

    this.isRegisterWorkRate = false;
    if (this.displayType === 'newsearch') {
      return;
    }

    if (_arg === 'registerWorkRate') {
      this.isRegisterWorkRate = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.m_sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.m_sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  editWorkRateAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.workRateToUpdate = selectedRow;
    

    this.actionType = 'update';

    this.doCreateWorkRate = false;
    this.toggleSidenav('registerWorkRate');
  }
  editWorkRate(row: ParamWorksRatesModel) {
    this.workRateToUpdate = row;

    this.actionType = 'update';

    this.doCreateWorkRate = false;
    this.toggleSidenav('registerWorkRate');
  }
  addWorkRate() {
  
    this.doCreateWorkRate = true;
    this.actionType = 'add';
    this.workRateToUpdate = undefined;
    this.toggleSidenav('registerWorkRate');
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteWorkRate(row: ParamWorksRatesModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'deleting work rate';
    

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.workLabel;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeWorkRate', row );

  }
  deleteWorkRateOK(row: ParamWorksRatesModel) {

      this._wksCentralService.saveWksWorkRates(row, 'deleteWorkRate')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteWorkRate');
        },
        () => {

        }
      );
    
  }
  
}
