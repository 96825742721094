import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { WksCentralService } from '../../../../services/wks-central.service';

@Component({
  selector: 'mdi-doc-image',
  templateUrl: './doc-image.component.html',
  styleUrls: ['./doc-image.component.css']
})
export class DocImageComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() titleReport: string;
  @Input() dataList: any[];

  imageToShow: any;
  isImageLoading: boolean;
  private readonly onDestroy = new Subject<void>();

  imgFiles: any[];
  dataSource: any[];
  dataLoaded: boolean;
  selectedPdf: any;
  imgDisplay: any;
  dataImgLoaded: boolean;

  constructor(private _wksCentralService: WksCentralService) {}
  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    this.dataImgLoaded = false;
    this.dataLoaded = false;


    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'titleReport': {
            this.titleReport = changes['titleReport'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'dataList': {
            this.dataList = changes['dataList'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.generateImgList();
  }
  generateImgList() {
    this.loadListImg()
    .then (() => {
      if (this.imgFiles[0] === undefined) { return; }
      this.downloadImgFile(this.imgFiles[0].filePath, this.imgFiles[0].fileName)
        .then ((data: Blob) => {
          this.createImageFromBlob(data);
          this.isImageLoading = false;

        })
        .then((error: any) => { // error read label
          //  console.log('Read data :'+ error);
        });
    })
  .then((error) => { // error read label
    //  console.log('Read data :'+ error);
    });
  }
  loadListImg() {
    this.imgFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of this.dataList ) {
        const fileCur =    {
          fileName: docCur.docFilename,
          fileLabel: docCur.docName,
          filePath: docCur.docPath + '/' + docCur.docFilename,
          fileStorage: docCur.docStorage,
          localUrl: undefined
        };
        this.imgFiles.push(fileCur);
      }
      this.selectedPdf = this.imgFiles[0];

      if (this.imgFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  createImageFromBlob(image: Blob) {
   const reader = new FileReader();
   reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
   }, false);

   if (image) {
      reader.readAsDataURL(image);
   }
  }

  downloadImgFile(_path: string, _imgFile: string) {
     
    const fileExtension = _imgFile.split('.').pop();
    const mimeApp = this._wksCentralService.getContentMime(fileExtension);
      return new Promise((resolve, reject) => {
        this._wksCentralService.downloadDoc(_path, mimeApp)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(
          (retMes: any) => {
            const response = retMes.body;
            const blob = new Blob([response], { type: mimeApp, });
            // const url = URL.createObjectURL(pdfData);
            this.dataImgLoaded = true;
            resolve(blob);
          }, err => {
            reject(err);
          }
        );
      });
  }
/**
 *     return new Promise((resolve, reject) => {
      this._wksCentralService.getPdfDoc(_path)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          const pdfData = new Blob([response], { type: 'application/pdf', });
          const url = URL.createObjectURL(pdfData);
          this.dataPdfLoaded = true;
          resolve(response);
        }, err => {
          reject(err);
        }
      );
    });
 */
}
