
<!---<angular-editor formControlName="htmlContent" [config]="editorConfig"></angular-editor>-->
<div class="container" >
    <!--
    <header class="header">
      <h1>AngularEditor</h1>
      <p>Native WYSIWYG editor for Angular 6+</p>    
    </header>
    -->
    <!--
    <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
        <div class="col-12" style="padding-right: 2px; padding-left: 2px" >
            <angular-editor  [ngModel]="htmlContent" (ngModelChange)="onChange($event)" [config]="editorConfig"></angular-editor>
        </div>
    </div>
    -->
    <!--
    <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
        <div class="col-12" style="padding-right: 2px; padding-left: 2px">
            <h5 class="html-header">HTML Output</h5>
            <div class="html">
                {{ htmlContent }}
            </div>
        </div>
    </div>
    -->
    <form [formGroup]="editorForm">
        <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" >
            <div class="col-12" style="padding-right: 2px; padding-left: 2px" >
                <angular-editor formControlName="htmlContent" (ngModelChange)="onChange($event)" [config]="editorConfig"></angular-editor>
                <!--<angular-editor  [ngModel]="htmlContent" (ngModelChange)="onChange($event)" [config]="editorConfig"></angular-editor>-->
            </div>
        </div>
    </form>
</div>