
<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid stdTheme" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <!---
      <mat-form-field>
          <mat-label style="font-size: 12px">{{ 'global filter' | translate }}</mat-label>
          <mat-icon matSuffix>search</mat-icon>
          <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
      -->
      <span class="horizontal-spacer5"></span>
      <button mat-icon-button class="buttonTheme" matTooltip="{{ 'filter editor' | translate }}" (click)="toggleSidenav('filterContent')"
          style="margin-left: 10px;margin-right: 10px;">
        <mat-icon style="font-size: 18px;">filter_tilt_shift</mat-icon>
      </button>
      <button mat-icon-button class="buttonTheme" matTooltip="{{ 'filter by column' | translate }}" (click)="toggleFilters()"
          style="margin-left: 10px;margin-right: 10px;">
        <mat-icon style="font-size: 18px;">filter_list</mat-icon>
      </button>
      <span class="horizontal-spacer5"></span>
      <button mat-icon-button class="buttonTheme" matTooltip="{{ 'columns selection' | translate }}" (click)="toggleSidenav('choiceCol')"
          style="margin-left: 10px;margin-right: 10px;">
        <mat-icon style="font-size: 18px;">view_column</mat-icon>
      </button>
      <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadFiles' | translate }}" (click)="toggleUploadFiles()"
          style="margin-left: 10px;margin-right: 10px;">
        <mat-icon style="font-size: 18px;">cloud_upload</mat-icon>
      </button>
      <!--
      <button mat-icon-button matTooltip="{{ 'modifPwd' | translate }}" (click)="toggleUpdatePwd()">
        <mat-icon style="font-size: 18px;">vpn_key</mat-icon>
      </button>
      -->
      <span style="margin-left: 35%; font-size:large">{{ 'UsersAccount' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <mat-sidenav-container fxFlexFill class="container-sidenav w-100 stdTheme">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" class="lateral-sidenav stdTheme" 
          style="padding-left: 20px;padding-right: 10px;">
        <div fxLayout="column" *ngIf="isFilterContent">
          <a (click)="toggleSidenav('')" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Apply' | translate}}</a>
          <a>
            <button mat-icon-button class="buttonTheme" [matMenuTriggerFor]="filterMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </a>
        </div>

        <div fxLayout="column" *ngIf="isChoiceCol">
          <a (click)="toggleSidenav('')" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Apply' | translate}}</a>
          <a>
            <!-- <li *ngFor="let dataColumn of dataColumns">{{ dataColumn.dataname }}</li> -->
            <ng-template ngFor let-dataColumn [ngForOf]="dataColumns">
              <li>
                <!--
                <mat-checkbox [checked]="dataColumn.isSelected" (change)="chkbColumnChoice($event)" style="font-size: 12px;line-height: 10px;"
                  id="{{dataColumn.value}}">
                  {{dataColumn.label}}
                </mat-checkbox>
              -->
                <div class="checkbox">
                  <label class="labelLine" >
                    <input type="checkbox" id="{{dataColumn.value}}"  class="inputTheme" style="font-size: 10px;line-height: 8px;"
                      [checked]="dataColumn.isSelected" 
                      (change)="chkbColumnChoice($event)" >
                    {{dataColumn.label}}
                  </label>
                </div>
              </li>
            </ng-template>
          </a>
        </div>
        <div fxLayout="column" *ngIf="isRegisterUser">
          <a (click)="toggleSidenav('')" *ngIf="doCreateUser" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}</a>
          <a (click)="toggleSidenav('')" *ngIf="!doCreateUser"mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}</a>
          <mdi-usercard [statutCall]="statutRegister" [userToUpdate]="userToUpdate" (onload)="ngOnInit()"></mdi-usercard>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div *ngIf="openFilters" class="mat-elevation-z8 container-fluid" [@fadeInOut]="stateFilters">
          <mdi-dynamic-form [fields]="fields" [currentFilters]="currentFilters"></mdi-dynamic-form>
        </div>
        <div *ngIf="openUploadFiles" class="mat-elevation-z8 container-fluid" style="margin: 15px;" [@fadeInOut]="stateUploadFiles">
          <mdi-upload-files fileExt="CSV" uploadType="users" ></mdi-upload-files>
        </div>
        <div class="container-fluid stdTheme">
            <div class="spinner-container" *ngIf="loading$ | async">
                <mat-spinner></mat-spinner>
              </div>
          <ngx-datatable [rows]="dataSource.data" class="material striped" [loadingIndicator]="loadingIndicator" [columnMode]="'force'"
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [reorderable]="reorderable" [limit]="pageSize" [sortable]="true"
            [draggable]="true" [resizeable]="true" [canAutoResize]="true" [scrollbarH]="true" [selected]="selected" [selectionType]="'single'"
            (select)='onSelect($event)' [frozenLeft]="true">
            <ngx-datatable-column *ngFor="let col of displayedColumns" [name]="col.name" [prop]="col.prop">
              <ng-template *ngIf="col.isDate" let-value="value" ngx-datatable-cell-template>
                {{ value | date : dateFormat}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions">
              <ng-template let column="column" ngx-datatable-header-template>
                <button mat-icon-button class="buttonTheme" (click)="addUser()"
                    style="margin-left: 10px;margin-right: 10px;">
                    <span class="fa fa-user-plus" style="font-size: 20px" ></span>
                </button>
              </ng-template>
              <ng-template let-row="row" ; let-value="value" ngx-datatable-cell-template style="margin:0px;padding:0px">
                <button  mat-icon-button class="buttonTheme" (click)="editUser(row)" 
                            matTooltip="{{'edit user' | translate : [row.username]}}"
                            style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-edit" style="font-size: 20px" ></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="disableUser(row)" 
                              matTooltip="{{'delete user' | translate : [row.username]}}"
                              style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-trash" style="font-size: 20px"></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="changePwd(row); openModal(template)" 
                      matTooltip="{{'change password' | translate : [row.username] }}"
                      style="margin-left: 10px;margin-right: 10px;">
                    <span class="fas fa-key" style="font-size: 20px"></span>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
</div>

<!-- menu des filtres par colonnes-->
<span [matMenuTriggerFor]="filterMenu" id="filter-panel" class="stdTheme">

  <mat-menu #filterMenu="matMenu" [overlapTrigger]="false">
    <div (click)="$event.stopPropagation()" (keyup)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <form autocomplete="off" (ngSubmit)="onFilterSubmit()" [formGroup]="filterForm">

        <mat-form-field style="width: auto;" class="mdiselect stdTheme">
          <mat-select formControlName="op" placeholder="Condition:">
            <mat-option class="selectOptionsTheme" *ngFor="let con of conditions" [value]="con.id">
              {{ con.text | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!forFirstValue">
          <input formControlName="val1" matInput placeholder="Value" class="inputTheme">
        </mat-form-field>

        <mat-form-field class="font-sm" *ngIf="forSecondValue ">
          <input formControlName="val2" matInput placeholder="Second Value" class="inputTheme">
        </mat-form-field>

        <button [disabled]="hasNoValue" matRipple class="buttonTheme" type="submit"></button>
      </form>
    </div>
  </mat-menu>
</span>
<!--https://valor-software.com/ngx-bootstrap/#/modals#confirm-window-->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'Confirmation' | translate}}</h4>
    <button type="button" class="close pull-right buttonTheme" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body stdTheme">
    <p>{{'confirm change password' | translate : modalText }}</p>
    <button type="button" class="btn btn-success pull-right buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
</ng-template>