import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav, MatTabChangeEvent } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subscription, Subject } from 'rxjs';
import * as moment from 'moment';

import { TranslateService } from '../../../../../services/translate.service';
import { UserService } from '../../../../../services/user.service';
import { WksCentralService } from '../../../../../job/job-wks/services/wks-central.service';
import { WksManufacturersService } from '../../../../../job/job-wks/services/wks-manufacturers.service';
import { RemoteFilesService } from '../../../../common/services/remote-files.service';
import { HistoryData, JobJson, Manufacturers, ManufacturersFilesType, SpecOtherData, SpecificationImportModel, 
        SpecificationsEvent, SpecificationsJobs, SpecificationsModel, SpecificationsProcess, SpecjobsModel, StepProcess } from '../../../../../job/job-wks/models/wks-manufacturers.model';
import { ReadFile,  FileUpload } from '../../../../../models/common.model';
// import { LabelValue } from '../../../../../job/job-wks/models/wks-common.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { environment } from '../../../../../../environments/environment';
import { ModalCommonComponent } from '../../../../../job/common/components/modal-common/modal-common.component';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../i18n/translation';
import { ViewDocComponent } from '../../../../common/components/view-doc/view-doc.component';
import { IhmOptions, LangLabelModel, ParamWorksRatesModel } from '../../../../../job/job-wks/models/wks-param.model';
import { LabelValue } from '../../../../../models/data.model';

@Component({
  selector: 'mdi-manufacturers-specifications',
  templateUrl: './manufacturers-specifications.component.html',
  styleUrls: ['./manufacturers-specifications.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ManufacturersSpecificationsComponent implements OnInit {

  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  @Input() maxSizePdf = 50; // 5MB

  @ViewChild('sidenavMan', {static: false}) sidenavMan: MatSidenav;
  
  private readonly onDestroy = new Subject<void>();

  manufacturersList: Manufacturers[];
  manufacturersItem: Manufacturers;
  stepsProcess:  StepProcess[];
  specificationsList: SpecificationsModel[];
  specificationsCacheList: SpecificationsModel[];
  specificationsRow: { 
    nextStatut: string;
    todo: string;
    isRemovable?: boolean;
    isEven?: boolean, 
    isOdd?: boolean, 
    isCollapse?: boolean, 
    addFileStatut?: boolean, 
    doUpload?: boolean, 
    isVerified: boolean;
    isRegistered?: boolean }[];
  specificationsHisto: { 
      displayList?: boolean
    }[][];
  progress: { percentage: number } = { percentage: 0 };

  okToDisplay: boolean;
  isReadOnly: boolean;
  isFinishLoading: boolean; 
  alertMessage: boolean; 
  hasBackdrop: boolean;
  sidenavCollapsed: boolean;
  timeFormat: string;
  isUploadInProgress: boolean;

  inputValues: { 
    isFileType: boolean;
    isFileVersion: boolean;
    isWorkCode: boolean;
    isLang: boolean;
    isEquipType: boolean;
  };

  fileExt: String = 'PDF';
  fileRead: ReadFile;
  messageInfo = '';
  dataMessageInfo1 = '';
  dataMessageInfo2 = '';
  messageType = '';
  dialogRef: MatDialogRef<ModalCommonComponent, any>;
  filetypeList: LabelValue[];
  fileversionList: LabelValue[];
  listFiles: any[];
  doExtractText: boolean;
  doProcessRegex: boolean;
  doValidRegex: boolean;
  doInjectProcess: boolean;
  doValidInjection: boolean;

  specificationImport: SpecificationImportModel;
  rowImport: number;

  ihmOptions: IhmOptions;
  equipTypeList: LabelValue[];
  workCodeList: LabelValue[];
  langLabelsList: LangLabelModel[];

  constructor(private _userService: UserService,
              private _wksCentralService: WksCentralService,
              private _remoteFilesService: RemoteFilesService,
              private _wksManufacturersService: WksManufacturersService,
              private _dialog: MatDialog,
              private _translate: TranslateService) { }

  ngOnInit() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.initBooleans();
    if (this.initParams()) {
      this.buildForm();
      this._wksManufacturersService.loadWorksType(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
      .then (dataReturn => {
        this.initWorksTypeList(dataReturn as ParamWorksRatesModel[] );
        this.loadSpecificationsList()
        .then ((responseCur: SpecificationsModel[]) => {
          const dataList = this.affectData(responseCur);
          this.specificationsList = [];
          this.specificationsCacheList = [];
          this.specificationsList = [...dataList ];
          this.specificationsCacheList = [...dataList ];
          this.fillForm();
        })
        .catch ((error: number) => {
          console.log('loadSpecificationsList ' + error);
          return;
        });
      })            
      .catch(error  => {
        console.log('loadWorksType ' + error);
        return;
      });
      this.okToDisplay = true;
    }
  }
  initBooleans(): void {
    this.okToDisplay = false;
    this.isReadOnly = false;
    this.isFinishLoading = false;
    this.alertMessage = false;

    this.isUploadInProgress = false;

    this.doExtractText = false;
    this.doProcessRegex = false;
    this.doValidRegex = false;
    this.doInjectProcess = false;
    this.doValidInjection = false;
    this.hasBackdrop = true;
    this.sidenavCollapsed = true;
    this.initInputValues();
  }
  initInputValues(): void {
    this.inputValues = { 
      isFileType: false,
      isFileVersion: false,
      isWorkCode: false,
      isLang: false,
      isEquipType: false,
    };
  }
  initParams(): boolean {
    
    try {
      this.manufacturersList = this._wksCentralService.getManufacturers();
      // this.stepsProcess = this._wksCentralService.getSpecificationsProcess();
      this.stepsProcess = this.sortingStepsProcess(this._wksCentralService.getSpecificationsProcess());
      this.buildManufacturer(0);
    } catch (error) {
      console.log('initParams ' + error);
      return false;
    } finally { }
    this.settingIntFmts();

    const listItem = this._wksCentralService.getComponentsList();

    this.equipTypeList = [];
    for (const item of listItem) {
      const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.equipTypeList.push(itemCur);
    }
    const listEquip = this._wksCentralService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      const itemCur: LabelValue = {
          label: this._translate.getTranslate(item.equipName),
          value: item.equipType,
      };
      this.equipTypeList.push(itemCur);
    }
    this.equipTypeList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });

    this.equipTypeList.splice(0, 0, {
      label: '',
      value: ''
    });
    this.langLabelsList = [];
    const supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }
    this.langLabelsList.sort((obj1: LangLabelModel, obj2: LangLabelModel) => {
      return obj1.langDisplay > obj2.langDisplay ? 1 : -1;
    });

    this.langLabelsList.splice(0, 0, {
      langValue: '',
      langDisplay: '',
      langDirection: '',
      langLabel: ''
    });
    return true;
  }
  initWorksTypeList(dataReturn: ParamWorksRatesModel[]) {

    this.workCodeList = [];

    for (const workCur of dataReturn) {
      const jobTypeCur: LabelValue = {
        label: workCur.workLabel,
        value: workCur.workCode
      };
      this.workCodeList.push(jobTypeCur);
    }
    
    this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.workCodeList.splice(0, 0, {
      label: '',
      value: ''
    });
    
  }
  // stepsProcess:  SpecificationsProcess[]
  sortingStepsProcess(stepsProcessArgs: StepProcess[]): StepProcess[] {
    const stepsProcessTmp: StepProcess[] = [];

    if (stepsProcessArgs === undefined) {
      return stepsProcessTmp;
    }
    // search first step (no next present)
    let stepFirst: StepProcess;
    for (const stepCur of stepsProcessArgs) {
      let isFound = false;
      for (const stepNext of stepsProcessArgs) {
        if (stepNext.nextStep === stepCur.step) {
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        stepFirst = stepCur;
        stepsProcessTmp.push(stepFirst);
        break;
      }
    }
    // searching next steps
    let stepCurrent = stepFirst;
    for (let i = 0; i < stepsProcessArgs.length ; i++ ) {
      /*
      if (stepCurrent.step === 'processDone') {
        stepsProcessTmp.push(stepCurrent);
        break;
      }
      */
      stepCurrent = this.searchNextStep(stepsProcessArgs, stepCurrent.nextStep);
      if (stepCurrent === undefined) {
        break;
      } else {
        stepsProcessTmp.push(stepCurrent);
      } 
    }
    return stepsProcessTmp;
  }
  searchNextStep(stepsArg: StepProcess[], nextStepName: string): StepProcess {
    let stepNext: StepProcess;
    for (const stepCur of stepsArg) {
      if (stepCur.step === nextStepName) {
        stepNext = stepCur;
        break;
      } 
    }
    return stepNext;
  } 
  searchStep(stepName: string): StepProcess {
    let stepFound: StepProcess;
    for (const stepCur of this.stepsProcess) {
      if (stepCur.step === stepName) {
        stepFound = stepCur;
        break;
      }
    }
    return stepFound;
  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }

    const otherData = JSON.parse(wksEntity.otherData);
    const langs =  [... otherData.usersLanguages];
    
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    return true;
  }
  getDateTimeFmt(origin: string, value: any): string {
    // console.log('origine : ' + origin + ' ' + value);
    if (value === undefined) {
      return '';
    }
    return CommonMethods.dateToString(value, 'unixM', this.timeFormat);
  }
  buildManufacturer(idxManufature: number): void {
    this.manufacturersItem = this.manufacturersList[0];

    this.filetypeList = [];
    const filesType = this.manufacturersItem.filesType as ManufacturersFilesType[];
    for (const fileTypeCur of filesType) {
      const labelValue: LabelValue =  {
        label: fileTypeCur.typeName,
        value:  fileTypeCur.typeName,
      };
      this.filetypeList.push(labelValue);
    }
    this.filetypeList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.filetypeList.splice(0, 0, {
      value: '',
      label: '',
    });
  }
  buildForm(): void {
  }
  fillForm(): void {
    this.specificationsRow = [];
    this.specificationsHisto = [] ;
    if (this.specificationsList !== undefined && this.specificationsList.length > 0) {
      let idxRow = -1;
      for (const specificationItem of this.specificationsList) {
        idxRow++;
        let nextStep: string;
        let removableOK: boolean;
        let todoAction: string;

        for (const stepCur of this.stepsProcess) {
          if (stepCur.step === specificationItem.specStatut) {
            nextStep = stepCur.nextStep;
            removableOK = stepCur.removable;
            todoAction = stepCur.todo;

          }
        }
        this.specificationsRow.push({
            nextStatut: nextStep,
            todo: todoAction,
            isRemovable: removableOK,
            isEven: (idxRow % 2 === 0 ? true : false),
            isOdd: (idxRow % 2 !== 0 ? true : false),
            isCollapse: true, 
            addFileStatut: false, 
            doUpload: false, 
            isVerified: false,
            isRegistered: false});
      }
    }
  }
  verifSpecJobs(idxRow: number, specCur: SpecificationsModel): void {
    const fileId = specCur.specFileid;
    let isCompleteVerify = true;
    this._wksManufacturersService.findSpecJobsByFileid(this._userService.getUserLogged().entity, fileId, undefined)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const dataArray = data.body as SpecjobsModel[];

        isCompleteVerify = this.analyzeSpecJobs(dataArray);

        this.specificationsRow[idxRow].isVerified = isCompleteVerify;
      },
      err => {
        console.log('verifSpecJobs ' + err);

      }
    );
  }
  analyzeSpecJobs(arrayData: SpecjobsModel[]): boolean {
    let isCompleteVerify = true;
    for (const itemCur of arrayData) {
      if (itemCur.specOtherdata !== undefined && itemCur.specOtherdata !== null) { 
        try {
          itemCur.specJobOtherData = JSON.parse(itemCur.specOtherdata);
          if (!itemCur.specJobOtherData.checked) { 
            isCompleteVerify = false;
            break;
          }

          if (itemCur.specJobOtherData.checked) {
            let specificationsJobs: SpecificationsJobs[] = [];
            if (itemCur.jobJson !== undefined 
              && itemCur.jobJson !== null 
              && itemCur.jobJson !== '') { 
              const jobJson = JSON.parse(itemCur.jobJson) as JobJson;
              if (jobJson.work) { 
                specificationsJobs = JSON.parse(jobJson.work) as SpecificationsJobs[];
                for (const jobCur of specificationsJobs ) { 
                  if (jobCur.quantity === 0 ) { 
                    isCompleteVerify = false;
                    break;
                  }
                }
              } else { 
                isCompleteVerify = false;
                break;
              }
            }
          }

        } catch (error) {
          isCompleteVerify = false;
          break;
        } finally { }
      } else { 
        isCompleteVerify = false;
        break;
      } 
    }
    return isCompleteVerify;
  }
  affectDataHisto(idxRow: number, dataArray: SpecificationsModel[]) {

    this.specificationsHisto[idxRow] = [];
    for (const specRow of dataArray[idxRow].historyData) {
      const stepFound: StepProcess = this.searchStep(specRow.specStatut);
      const sepcHisto = {
        displayList: stepFound.displayList
      };
      this.specificationsHisto[idxRow].push(sepcHisto);
    }
  }
  affectData(dataArray: SpecificationsModel[]): SpecificationsModel[] {
    let idxRow = -1;
    this.specificationsHisto = [];
    for (const itemCur of dataArray) {
      idxRow++;
      itemCur.recordedDate = moment(itemCur.stdCreation).format(environment.fmtDateTimeTZ);
      if (itemCur.specStatut === 'waitingVerifInjection') {
        this.verifSpecJobs(idxRow, itemCur);
      }
      if (itemCur.specOtherdata !== undefined && itemCur.specOtherdata !== null && itemCur.specOtherdata !== '') {
        itemCur.specOtherDatas = JSON.parse(itemCur.specOtherdata);
        itemCur.workCodeLabel = '';
        itemCur.langLabel = '';
        itemCur.equipTypeLabel = '';
        if (itemCur.specOtherDatas.workCode && itemCur.specOtherDatas.workCode !== '') {
          for (const workCodeCur of this.workCodeList) {
            if (workCodeCur.value === itemCur.specOtherDatas.workCode) {
              itemCur.workCodeLabel = workCodeCur.label;
              break;
            }
          }
        }
        if (itemCur.specOtherDatas.lang && itemCur.specOtherDatas.lang !== '') {
          for (const langCur of this.langLabelsList) {
            if (langCur.langValue === itemCur.specOtherDatas.lang) {
              itemCur.langLabel = langCur.langDisplay;
              break;
            }
          }
        }
        if (itemCur.specOtherDatas.equipType && itemCur.specOtherDatas.equipType !== '') {
          for (const equipCur of this.equipTypeList) {
            if (equipCur.value === itemCur.specOtherDatas.equipType) {
              itemCur.equipTypeLabel = equipCur.label;
              break;
            }
          }
        }
      } else  {
        itemCur.specOtherDatas = this.emptySpecOtherDatas();
        itemCur.workCodeLabel = '';
        itemCur.langLabel = '';
        itemCur.equipTypeLabel = '';
      }
      const historyJson = JSON.parse(dataArray[idxRow].specHistory);
      dataArray[idxRow].historyData = historyJson.historyRows as HistoryData[];
      this.affectDataHisto(idxRow, dataArray);
    }
    return dataArray;
  }
  emptySpecOtherDatas(): SpecOtherData {
    const specOtherData: SpecOtherData = {
      lang: '',
      workCode: '',
      equipType: ''
    };
    return specOtherData;
  }
  fillModel(idxRow: number, statut: string, action: string, stepCur: StepProcess): SpecificationsModel {
    const fileName = this.specificationsList[idxRow].specFilename;
    let historyDatas: HistoryData[] = [];
    if (action === 'add') {
      const historyDataTmp: HistoryData =  {
        specFilename: this.fileRead.nameFile, 
        specStatut: statut
      };
      historyDatas.push(historyDataTmp);
    }
    if (action === 'update') {
      const historyJson = JSON.parse(this.specificationsList[idxRow].specHistory);
      const trtType = (stepCur !== undefined ? stepCur.action : '');
      historyDatas = historyJson.historyRows as HistoryData[];
      const historyDataTmp: HistoryData =  {
        specFilename: '', 
        specStatut: statut
      };
      historyDatas.push(historyDataTmp);
    }
    const otherData = JSON.stringify(this.specificationsList[idxRow].specOtherDatas);
    const specificationCard: SpecificationsModel = {
      id: (this.specificationsList[idxRow].id) ? this.specificationsList[idxRow].id : undefined,
      stdEntity: this._userService.getUserLogged().entity,
      specBrand: this.specificationsList[idxRow].specBrand,
      specFiletype: this.specificationsList[idxRow].specFiletype,
      specFileversion: this.specificationsList[idxRow].specFileversion,
      specFileid: (this.specificationsList[idxRow].specFileid ? this.specificationsList[idxRow].specFileid : undefined),
      specFilename: this.specificationsList[idxRow].specFilename,
      specStatut: statut,
      historyData: historyDatas,
      specHistory: (this.specificationsList[idxRow].specHistory ? this.specificationsList[idxRow].specHistory : undefined),
      specOtherdata: (otherData ? otherData : undefined),
      stdCreation: (this.specificationsList[idxRow].stdCreation) ? this.specificationsList[idxRow].stdCreation : undefined,
    };
    return specificationCard;
  }
  loadSpecificationsList() {
    
    return new Promise((resolve, reject) => {
      this._wksManufacturersService.findSpecificationsBySpecBrand(this._userService.getUserLogged().entity, this.manufacturersItem.brandName, undefined, undefined)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  register(idxRow: number, statut: string, action: string, stepCur: StepProcess) {
    return new Promise((resolve, reject) => {
      this._wksManufacturersService.saveSpecifications(this.fillModel(idxRow, statut, action, stepCur), action)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        specificationsReturn => {
          this.specificationsRow[idxRow].isRegistered = true;
          resolve(specificationsReturn.body);
        },
        (err) => {
          console.log ('error register specifications');
          reject(err.status);
        }
      );
    });
  }
  refresh() {
    this.okToDisplay = false;
    this.specificationsList = [];
    this.specificationsCacheList = [];
    this.loadSpecificationsList()
    .then ((responseCur: SpecificationsModel[]) => {
      const dataList = this.affectData(responseCur);
      this.specificationsList = [];
      this.specificationsCacheList = [];
      this.specificationsList = [...dataList ];
      this.specificationsCacheList = [...dataList ];
      this.fillForm();
    })
    .catch ((error: number) => {
      console.log('loadSpecificationsList ' + error);
      return;
    });

    this.okToDisplay = true;
  }
  toggleSidenav(_arg: string, idxRow: number, idxHisto: number ) {
   
    if (_arg === 'backdrop') {
      return;
    }
    
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.sidenavMan.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.sidenavMan.open();
        this.rowImport = idxRow;
        this.specificationImport = {
          specificationsCur: this.specificationsList[idxRow],
          statutCall: this.specificationsList[idxRow].specStatut,
          isReadOnly: !this.specificationsRow[idxRow].isRemovable,
          workCode: this.specificationsList[idxRow].specOtherDatas.workCode
        };
        this.ihmOptions =  { 
          readOnly: !this.specificationsRow[idxRow].isRemovable
        };

      } catch (error) {
      } finally { }
    }
  }
  dataoutSpecificationsTable(event: SpecificationsEvent): void {
    if (event.action === 'toggleSidenav') {
      this.toggleSidenav('', -1, -1);
      const specificationCur = this.specificationsList[event.rowIdx];
      if (specificationCur.specStatut === 'waitingVerifInjection') {
        this.verifSpecJobs(event.rowIdx, specificationCur);
      }
    }
  }
  processAction(idxRow: number, statut: string, action: string) {
    const historyJson = JSON.parse(this.specificationsList[idxRow].specHistory);
    let idxHisto = 0;
    for (const historyRow of historyJson.historyRows) {
      if (historyRow.specStatut === statut) {
        break;
      }
      idxHisto++;
    }
    return new Promise((resolve, reject) => {
      this._wksManufacturersService.processSpecifications(this.fillModel(idxRow, statut, action, undefined), action, idxHisto)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        specificationsReturn => {
          this.specificationsRow[idxRow].isRegistered = true;
          resolve(specificationsReturn.body);
        },
        (err) => {
          console.log ('error doProcess specifications');
          reject(err.status);
        }
      );
    });
  }
  selectedTabChange(orig: string, matTabSelected: MatTabChangeEvent) {

    this.buildManufacturer(matTabSelected.index);
    this.loadSpecificationsList();
    switch (matTabSelected.index) {
      case 0: { 
        
        break;
      }
    }
  }
  currentTabSelected(tabName: string) {
    // console.log (tabName);

  }
  onChangeFileType(fileType: any, idxRow: number) {
    if (fileType === '') {
      this.initInputValues();
      this.specificationsList[idxRow].specFiletype = '';
      this.specificationsList[idxRow].specFileversion = '';
      return;
    }
    this.inputValues.isFileType = true;
    this.specificationsList[idxRow].specFiletype = fileType;
    this.specificationsList[idxRow].specFileversion = '';
    this.fileversionList = [];
    const filesType = this.manufacturersItem.filesType as ManufacturersFilesType[];
    for (const fileTypeCur of filesType) {
      if (fileTypeCur.typeName === fileType) {
        const fileVersions = fileTypeCur.versions as string[];
        for (const versionCur of fileVersions) {
          const labelValue: LabelValue =  {
            label: versionCur,
            value:  versionCur,
          };
          this.fileversionList.push(labelValue);

        }
        this.fileversionList.sort((obj1, obj2) => {
          return obj1.label > obj2.label ? 1 : -1;
        });
        this.fileversionList.splice(0, 0, {
          value: '',
          label: '',
        });
      }
    }
  }
  onChangeFileVersion(fileVersion: any, idxRow: number): void {
    if (fileVersion === '') {
      this.inputValues.isFileVersion = false;
      this.specificationsList[idxRow].specFileversion = '';
      return;
    }
    this.specificationsList[idxRow].specFileversion = fileVersion;
    this.inputValues.isFileVersion = true;
  }
  onChangeParams(orig: string, value: string, idxRow: number): void {
    switch (orig) {
      case 'workCode':
        this.specificationsList[idxRow].specOtherDatas.workCode = value;
        this.inputValues.isWorkCode = false;
        if (value !== '') {
          this.inputValues.isWorkCode = true;
          for (const workCodeCur of this.workCodeList) {
            if (workCodeCur.value === value) {
              this.specificationsList[idxRow].workCodeLabel = workCodeCur.label;
            }
          }
        }
        break;
      case 'lang':
        this.specificationsList[idxRow].specOtherDatas.lang = value;
        this.inputValues.isLang = false;
        if (value !== '') {
          this.inputValues.isLang = true;
          for (const langCur of this.langLabelsList) {
            if (langCur.langValue === value) {
              this.specificationsList[idxRow].langLabel = langCur.langDisplay;
            }
          }
        }
        break;
      case 'equipType':
        this.specificationsList[idxRow].specOtherDatas.equipType = value;
        this.inputValues.isEquipType = false;
        if (value !== '') {
          this.inputValues.isEquipType = true;
            for (const equipCur of this.equipTypeList) {
              if (equipCur.value === value) {
                this.specificationsList[idxRow].equipTypeLabel = equipCur.label;
              }
            }
        }
        break;
    }
  }
  toggleRow(idxRow: number): void {
    this.specificationsRow[idxRow].isCollapse = !this.specificationsRow[idxRow].isCollapse;
    if (!this.specificationsRow[idxRow].isCollapse) {
      const historyJson = JSON.parse(this.specificationsList[idxRow].specHistory);
      this.specificationsList[idxRow].historyData = historyJson.historyRows as HistoryData[];
      this.affectDataHisto(idxRow, this.specificationsList );
    }
    return;
  }
  doCancel(): void {
    this.toggleSidenav('', -1, -1);
  }
  downloadFile(idxRow: number, idxHisto: number): void {
    const fileName = this.specificationsList[idxRow].historyData[idxHisto].specFilename;
    const fileExtension = fileName.split('.').pop();
    const filePath = this._userService.getUserLogged().entity + '/specifications/' + this.specificationsList[idxRow].specBrand + '/' + fileName;
    const fileAttibuts =  {
      fileName: fileName,
      fileLabel: fileName,
      filePath: filePath,
      fileStorage: 'webadv',
      fileUrl: '/wks/manufacturers/download/'
    };
    if ( fileExtension === 'pdf') {
      this.listFiles = [];
      this.listFiles.push(fileAttibuts);
      this.displayDocPdf();
    } else {
    // } else if ( fileExtension === 'txt') {
      this.listFiles = [];
      this.listFiles.push(fileAttibuts);
      this.downloadFileHisto(filePath, fileName, fileExtension);
    }
  }
  uploadHistoFile(event: any, idxRow: number, idxHisto: number): void {

    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;
    const fileName = this.specificationsList[idxRow].historyData[idxHisto].specFilename;
    if (event.target.files && event.target.files.length > 0) {
      const readingFile: ReadFile = new ReadFile();
      const files = event.target.files;
      if (files.length > 0)  {
        const file: File = files[0];
        if (file.name !== fileName) {
          console.log('Fichier sélectionné : ' + file.name + ' Fichier attendu : ' + fileName);
          this.closeMatDialog();
          this.messageInfo = 'badFileName';
          this.dataMessageInfo1 = file.name ;
          this.dataMessageInfo2 = fileName ;
          this.messageBoxDisplay('uploadKO');
          return;
        }
        readingFile.nameFile = file.name;
        readingFile.sizeFile = file.size;
        readingFile.typeFile = file.type;
        readingFile.fileObject = file;
        readingFile.messageFile = '';
        readingFile.uploadedFile = false;
        readingFile.validatedFile = false;
      }

      this.closeMatDialog();
      this.messageInfo = 'uploadProcess';
      this.dataMessageInfo1 = '' ;
      this.dataMessageInfo2 = '' ;
      this.messageBoxDisplay('uploadProcess');
      this.uploadProcess(readingFile.fileObject, 'replace').then(
      (val: any) => {
        readingFile.nameFile = val;
        this.closeMatDialog();
        this.messageInfo = 'upload file OK';
        this.dataMessageInfo1 = readingFile.nameFile ;
        this.dataMessageInfo2 = this._translate.instant('uploadProcessDone') ;
        this.messageBoxDisplay('uploadOK');
      },
      (error) => {
        console.log('UPLOAD KO ' + readingFile.fileObject.name + ' : ' + error);
        this.closeMatDialog();
        this.messageInfo = 'upload file KO';
        this.dataMessageInfo1 = readingFile.nameFile ;
        this.dataMessageInfo2 = '(' + error + ')'  ;
        this.messageBoxDisplay('uploadKO');
      });

    } // end test files
  } 
  addFileSpecifications() {

    this.initInputValues();
    if (this.specificationsList === undefined) {
      this.specificationsList = [];
      this.specificationsCacheList = [];
      this.specificationsRow = [];
      this.specificationsHisto = [];
    }

    const specificationsTmp: SpecificationsModel = {
      stdEntity: this._userService.getUserLogged().entity,
      specBrand: this.manufacturersItem.brandName,
      specFiletype: undefined,
      specFileversion: undefined,
      specFilename: undefined,
      specStatut: 'doUpload',
      specOtherDatas: this.emptySpecOtherDatas(),
      langLabel: '',
      equipTypeLabel: '',
      workCodeLabel: ''
    };
    const stepCur = this.searchStep('doUpload');
    this.specificationsList.push(specificationsTmp);
    const idxRow = this.specificationsList.length - 1;
    this.specificationsRow.push({
      nextStatut: stepCur.step,
      todo: stepCur.todo,
      isEven: (idxRow % 2 === 0 ? true : false),
      isOdd: (idxRow % 2 !== 0 ? true : false),
      isRemovable: true,
      isCollapse: true, 
      addFileStatut: true, 
      doUpload: false, 
      isVerified: false,
      isRegistered: false
    });
    this.ihmOptions =  { 
      readOnly: false
    };
    this.specificationsHisto[idxRow] = [];
    this.specificationsHisto[idxRow].push({
      displayList: false
    });
  }
  removeFileSpecifications(idxRow: number): void {

    if (this.specificationsRow[idxRow].todo === 'doUpload') { 
      this.specificationsList.splice(idxRow, 1);
      this.specificationsRow.splice(idxRow, 1);
      this.specificationsHisto.splice(idxRow, 1);
    } else {
      this.messageBoxDisplay('removeFileSpecifications', idxRow);
    }
  }
  removeFileSpecificationsOK(idxRow: number): void {
    const fileName = this.specificationsList[idxRow].specFilename;
    this.register(idxRow, '', 'delete', undefined)
    .then(
      (returnValue: any) => {
      this.specificationsList.splice(idxRow, 1);
      this.specificationsRow.splice(idxRow, 1);
      this.specificationsHisto.splice(idxRow, 1);
      this.closeMatDialog();

      },
      (error) => {
        console.log('REMOVE REGISTER KO ' + fileName + ' : ' + error);
        this.closeMatDialog();

        this.messageBoxDisplay('removeFileSpecificationsKO', idxRow);
      } ); 
  }
  validAction(todoAction: string, idxRow: number): void {
    this.messageBoxDisplay(todoAction, idxRow, 'confirm');
  } 
  registerNextstep(todoAction: string, idxRow: number): void {
    const stepCur = this.searchStep(this.specificationsList[idxRow].specStatut);
    const stepNow = this.searchStep(stepCur.nextStep);
    this.register(idxRow, stepNow.step, 'update', stepNow)
        .then(
          (returnValue: any) => {
          returnValue.recordedDate = moment(returnValue.stdCreation).format(environment.fmtDateTimeTZ);
          this.specificationsList[idxRow] = returnValue;
          this.specificationsRow[idxRow].nextStatut = stepNow.nextStep;
          this.specificationsRow[idxRow].todo = stepNow.todo;
          this.specificationsRow[idxRow].addFileStatut = false;
          this.specificationsRow[idxRow].doUpload = false;
          this.affectData(this.specificationsList);
          },
          (error) => {
            console.log('REGISTER KO ' + this.specificationsList[idxRow].specFilename + ' : ' + error);
            this.closeMatDialog();
            this.messageInfo = 'register action KO';
            this.dataMessageInfo1 = this.specificationsList[idxRow].specFilename ;
            this.dataMessageInfo2 = '(' + error + ')'  ;
            this.messageBoxDisplay('registerKO');
          } ); 
  }
  validActionOK(todoAction: string, idxRow: number): void {
    this.endProcessFile();
    this.messageBoxDisplay(todoAction, idxRow);
    this.processAction(idxRow, this.specificationsList[idxRow].specStatut, todoAction)
    .then(
      (returnValue: any) => {
      returnValue.recordedDate = moment(returnValue.stdCreation).format(environment.fmtDateTimeTZ);
      this.specificationsList[idxRow] = returnValue;
      this.affectData(this.specificationsList);
      this.closeMatDialog();
      const stepCur = this.searchStep(this.specificationsList[idxRow].specStatut);
      this.specificationsRow[idxRow].nextStatut = stepCur.nextStep;
      this.specificationsRow[idxRow].todo = stepCur.todo;
      this.specificationsRow[idxRow].addFileStatut = false;
      this.specificationsRow[idxRow].doUpload = false;
      this.messageBoxDisplay(todoAction, idxRow, 'done');
      },
      (error) => {
        console.log('validAction KO ' + this.specificationsList[idxRow].specFilename + ' : ' + error);
        this.closeMatDialog();
        this.specificationsRow[idxRow].addFileStatut = false;
        this.specificationsRow[idxRow].doUpload = false;
        this.messageBoxDisplay(todoAction, idxRow, 'KO');
      } ); 

  } 
  // ============================================================== start files
  onFileSelect(event: any) {
    console.log(JSON.stringify(event));
  } 
  onFileChange(event: any, idxRow: number) {
    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;

    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if ((files.length > 0) && (this.isValidFiles(files))) {
        const file: File = files[0];
        const readingFile: ReadFile = new ReadFile();
        readingFile.nameFile = file.name;
        readingFile.sizeFile = file.size;
        readingFile.typeFile = file.type;
        readingFile.fileObject = file;
        readingFile.messageFile = '';
        readingFile.uploadedFile = false;
        readingFile.validatedFile = false;
 
        this.fileRead = readingFile;
  
        this.specificationsList[idxRow].specFilename = file.name;
        this.specificationsList[idxRow].specStatut = 'doUpload';
        this.specificationsRow[idxRow].addFileStatut = false;
        this.specificationsRow[idxRow].doUpload = true;
      } else  {
        this.specificationsList[idxRow].specFilename = '';
        this.specificationsList[idxRow].specStatut = '';
        this.fileRead = undefined;
        this.specificationsRow[idxRow].addFileStatut = false;
        this.specificationsRow[idxRow].doUpload = false;
      }
    } 

  }
  private isValidFiles(files): boolean {

    // Check Number of files
    if (files.length > this.maxFiles) {
      this.alertMessage = true;
      this.messageType = 'ERROR';
      this.messageInfo = 'The maximum number of files is reached';
      this.dataMessageInfo1 = files.length.toString() ;
      this.dataMessageInfo2 = this.maxFiles.toString() ;
      this.messageBoxDisplay('lenghtfile');
      return false;
    } else {
      return this.isValidFileExtension(files);
    }

  }
  private isValidFileExtension(files: any): boolean {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
                    .map(function (x) { return x.toLocaleUpperCase().trim(); } );
    this.alertMessage = false;
    for (let i = 0; i < files.length; i++) {
        // Get file extension
        const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
        // Check the extension exists
        const exists = extensions.includes(ext);
        if (!exists) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'unrecognized extension';
            this.dataMessageInfo1 = ext ;
            this.dataMessageInfo2 = files[i].name ;
            this.messageBoxDisplay('extensionfile');
            break;
        }
        if (!this.alertMessage) {
        // Check file size
          this.isValidFileSize(files[i], ext);
          break;
        } else {
          continue;
        }
    }
    if (this.alertMessage) {
      return false;
    } else {
      return true;
    }
  }

  private isValidFileSize(_file: any, ext: string): boolean {
    const fileSizeinMB = _file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place

    if (((ext === 'PDF') && (size > this.maxSizePdf)) || 
        ((ext !== 'PDF') &&  (size > this.maxSize))) {
        this.alertMessage = true;
        this.messageType = 'ERROR';
        this.messageInfo = 'too heavy file size';
        this.dataMessageInfo1 = _file.name  ;
        this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSize.toString() + 'MB MAX' ;
        return false;
      } else { return true; }
  }
  uploadFile(idxRow: number) {
    this.closeMatDialog();

    this.messageInfo = 'uploadProcess';
    this.dataMessageInfo1 = '' ;
    this.dataMessageInfo2 = '' ;
    this.messageBoxDisplay('uploadProcess');
    this.uploadProcess(this.fileRead.fileObject, 'new').then(
      (val: any) => {
        this.fileRead.nameFile = val;
        const stepNow = this.searchStep(this.specificationsList[idxRow].specStatut);
        this.register(idxRow, 'waitingExtractText', 'add', stepNow)
        .then(
          (returnValue: any) => {
          returnValue.recordedDate = moment(returnValue.stdCreation).format(environment.fmtDateTimeTZ);
          this.specificationsList[idxRow] = returnValue;
          this.closeMatDialog();
          this.messageInfo = 'upload file OK';
          this.dataMessageInfo1 = this.specificationsList[idxRow].specFilename ;
          this.dataMessageInfo2 = this._translate.instant('uploadProcessDone') ;
          const stepCur = this.searchStep('waitingExtractText');
          this.specificationsRow[idxRow].nextStatut = stepCur.nextStep;
          this.specificationsRow[idxRow].todo = stepCur.todo;
          this.specificationsRow[idxRow].addFileStatut = false;
          this.specificationsRow[idxRow].doUpload = false;
          this.affectData(this.specificationsList);
          this.messageBoxDisplay('uploadOK');
          },
          (error) => {
            console.log('REGISTER KO ' + this.fileRead.fileObject.name + ' : ' + error);
            this.closeMatDialog();
            this.messageInfo = 'upload file KO';
            this.dataMessageInfo1 = 'REGISTER KO ' + this.fileRead.fileObject.name ;
            this.dataMessageInfo2 = '(' + error + ')'  ;
            this.specificationsRow[idxRow].addFileStatut = false;
            this.specificationsRow[idxRow].doUpload = false;
            this.specificationsList[idxRow].specFilename = '';
            this.messageBoxDisplay('uploadKO');
          } ); 
      },
      (error) => {
        console.log('UPLOAD KO ' + this.fileRead.fileObject.name + ' : ' + error);
        this.closeMatDialog();
        this.messageInfo = 'upload file KO';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        this.dataMessageInfo2 = '(' + error + ')'  ;
        this.specificationsRow[idxRow].addFileStatut = false;
        this.specificationsRow[idxRow].doUpload = false;
        this.specificationsList[idxRow].specFilename = '';
        this.messageBoxDisplay('uploadKO');
      }
    ); // end then uploadFile

  }
  private uploadProcess(_file: File, action: string) {
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFileSpecifications(_file, 'specifications', this._userService.getUserLogged().entity, this.manufacturersItem.brandName, action).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translate.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this._translate.getTranslate('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
              case 200:
                break;
              case 202:
                break;
            default:
              message = this._translate.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }
            if (numError === 200 || numError === 202 ) {
              this.isUploadInProgress = false;
              const messageText = response.error.text;
              resolve(messageText);
            }
          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  // ============================================================== end files
  // ========================================================== errors alerts 
  messageBoxDisplay(_actionCur: string, _idxRow?: number, _option?: string) {

    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let progressDeterminate: boolean;
    let typeBox = 'alertWks';
    let fileName: string;
    const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
    let messageBox = this._translate.instant(this.messageInfo, argsMessage );
    progressDeterminate = false;

    switch (_actionCur) {
      case 'extractText':
      case 'processingRegex':
      case 'validRegex':
      case 'injectProcess':
      case 'validInjection':
        fileName = this.specificationsList[_idxRow].specFilename;
        titleBox = _actionCur;
        if (_option === 'KO') {
          typeBox = 'alertWks';
          messageBox =  this._translate.instant(_actionCur + '_KO', [fileName] );
        } else if (_option === 'confirm') {
          typeBox = 'confirmAction';
          messageBox =  this._translate.instant(_actionCur + '_OK', [fileName] );
        } else if (_option === 'done') {
          typeBox = 'alertWks';
          messageBox =  this._translate.instant(_actionCur + '_DONE', [fileName] );
        } else {
          typeBox = 'infoProgressWks';
          progressDeterminate = false;
          messageBox =  this._translate.instant(_actionCur + '_PROGRESS', [fileName] );
        }
        break;
      case 'lenghtfile': 
        titleBox = 'too heavy file'; 
        break;
      case 'extensionfile':
        titleBox = 'extension error';
        break;
      case 'uploadOK':
        titleBox = 'upload File parts & supplies';
        break;
      case 'uploadKO': 
        titleBox = 'upload File parts & supplies';
        break;
      case 'registerKO': 
        titleBox = 'register';
        break;
      case 'uploadProcess': 
        titleBox = 'uploadProcessTitle';
        typeBox = 'infoProgressWks';
        break;
      case 'extractData': 
        titleBox = 'extractData';
        typeBox = 'infoProgressWks';
        progressDeterminate = true;
        break;
      case 'removeFileSpecifications':
        titleBox = 'removeFileTitle';
        typeBox = 'confirmAction';
        fileName = this.specificationsList[_idxRow].specFilename;
        messageBox =  this._translate.instant('removefileLabel', [fileName] );
        break;
      case 'removeFileSpecificationsKO': 
        titleBox = 'removeFileTitle';
        fileName = this.specificationsList[_idxRow].specFilename;
        messageBox =  this._translate.instant('removefileLabelKO', [fileName] );
        break;
    }  // end switch
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: typeBox,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: '',
      data2: '',
      actionCur: _actionCur,
      progressDeterminate: progressDeterminate,
      errorsList: undefined,
      messageType: 'ERROR'
    };
    this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    
    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (_actionCur === 'uploadOK') {
          this.endProcessFile();
        }
        if (_actionCur === 'uploadKO') {
          this.endProcessFile();
        }
        if (( data === 'okAction') && (_actionCur === 'removeFileSpecifications')) {
          this.removeFileSpecificationsOK(_idxRow);
        }

        if (( data === 'okAction') && 
          ((_actionCur === 'doUpload') 
          || (_actionCur === 'extractText') 
          || (_actionCur === 'processingRegex') 
          || (_actionCur === 'injectProcess'))
          || (_actionCur === 'validInjection') ) {
            if (_option === 'confirm') {
            this.validActionOK(_actionCur, _idxRow);
            }
        }
        if (( data === 'okAction') && 
        ((_actionCur === 'validRegex')) ) {
          if (_option === 'confirm') {
          this.registerNextstep(_actionCur, _idxRow);
          }
      }
        // console.log('Dialog output:', data);
        // this.dialogRef = null;
      });

  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {
  
      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.dialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
  downloadFileHisto(filePath: string, fileName: string, fileExtension: string) {

    const mimeApp = this._wksCentralService.getContentMime(fileExtension);
    this._wksManufacturersService.downloadDoc(filePath, mimeApp)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          this._wksManufacturersService.saveToFileSystem(response, fileName, mimeApp);
        }, err => {
 
        }
      );
  }
  displayDocPdf() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '85%';
    dialogConfig.width = '60%';

    dialogConfig.data = {
      statutCall: 'readPdf',
      dataList: this.listFiles,
      titleReport: undefined
      };
    
    const dialogRef = this._dialog.open(ViewDocComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        this._dialog.closeAll();
      });

  }
}

