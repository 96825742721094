import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientModel, AppointmentModel } from '../../../../../models/wks-appointments.model';
import { TranslateService } from '../../../../../../../services/translate.service';
import {  WksEntityModel } from '../../../../../models/wks-entity.model';
import { FieldValue } from '../../../../../../../models/data.model';

@Component({
  selector: 'mdi-appointment-client-card',
  templateUrl: './appointment-client-card.component.html',
  styleUrls: ['./appointment-client-card.component.css'],
  encapsulation : ViewEncapsulation.None,
})
export class AppointmentClientCardComponent implements OnChanges {
  @Input()
  appointment: AppointmentModel;
  @Input()
  client!: ClientModel;
  @Input()
  wksEntity: WksEntityModel;
  @Output()
  nextStepEvent = new EventEmitter<number>();

  @Output()
  nbEquipmentsEvent = new EventEmitter<number>();

  clientForm: FormGroup;
  incompleteInput: boolean;
  rowsAddress: any[];
  addressBlockFg: FormGroup;

  locationAddress: boolean;
  equipNb = {
    min: 1,
    max: 4,
  };
  constructor( private translate: TranslateService) {
  }
  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'client': {
            // tslint:disable-next-line:no-string-literal
            this.client = changes['client'].currentValue;
            break;
          }
          case 'wksEntity': {
            // tslint:disable-next-line:no-string-literal
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'appointment': {
            // tslint:disable-next-line:no-string-literal
            this.appointment = changes['appointment'].currentValue;
            break;
          }
        } // end switch
      } // end if
    }
    this.initData();
  }

  initData(): void {
  
    this.addressBlockFg = new FormGroup({});
    this.incompleteInput = true;
    this.clientForm = new FormGroup({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      companyName: new FormControl(''),
      // phoneNumber: new FormControl('', [Validators.required, Validators.pattern(this.wksEntity.telFmt.domesticFmt)]),
      phoneNumber: new FormControl('', [Validators.required]),
      mailAddress: new FormControl('', [Validators.required, Validators.email]),
      // equipmentLocation: new FormControl(''),
      addressBlockFg: this.addressBlockFg,
      nbEquipments: new FormControl('', [Validators.max(this.equipNb.max), Validators.min(this.equipNb.min)]),
    });
    this.locationAddress = false;
    if (this.appointment.equipmentJobsite === 'address')  {
      this.locationAddress = true;
      this.buildAddressBlock();
    }
    this.fillForm();
  }
  buildAddressBlock(): void {

    const localAddress = this.wksEntity.localAddress;
    const elemsAddr = localAddress.elemAddr;
    // const rowsUpperCase = localAddress.uppercaseLastLines.split(';') as string[];
    if (elemsAddr === undefined) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      return;
    }
    let localAddressData: FieldValue[];
    localAddressData = [];
    if ((this.appointment.equipmentLocation != null) && (this.appointment.equipmentLocation !== undefined) ) {
      localAddressData = JSON.parse(this.appointment.equipmentLocation);
    }
    // console.log(elemsAddr);
    let iRow = 0;

    let iRowCur = 0;
    this.rowsAddress = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;

    let isMandatory = false;
    for (const elemAddr of elemsAddr) {
      iRowCur++;

      isMandatory = (elemAddr.dataMandatory !== 'N');
      if (elemAddr.dataInput === 'proID') { continue; }
      if (elemAddr.dataInput === 'individualID') { continue; }
      elemAddr.inputValue = '';
      let isFound = false;
      if ((localAddressData != null) && (localAddressData !== undefined) ) {
        for (const localData of localAddressData )  {
          if (localData.value === '') {
            continue;
          }
          if (elemAddr.dataName === localData.name) {
            elemAddr.inputValue = localData.value;
            elemAddr.elemLabel = localData.label;
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        continue;
      }
      // console.log(elemAddr.dataName);
      if ((elemAddr.controlType === null) || (elemAddr.controlType === '')) {
        elemAddr.controlType = elemAddr.dataName;
      }
      iRow = Number(elemAddr.lineNumber);

      if (elemAddr.dataInput === 'N') { continue; }

      if ((iRowBuild > 0) && (iRow !== iRowBuild)) {
        this.rowsAddress.push({
          row: Number(iRow).toString,
          lengthMax: iLengthMax,
          cols: colsArray,
        });
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      if (iRowBuild < 0) {
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      iLengthMax += Number(elemAddr.dataLengthMax);
      /*
      const labelElem = this.translate.instant(elemAddr.dataName);
      if (labelElem === elemAddr.dataName) {
        elemAddr.elemLabel = ''; // if label is ''
      } else {
        elemAddr.elemLabel = labelElem;
      }
      */
      elemAddr.elemUpperCase = false;

      colsArray.push(elemAddr);
      if (isMandatory) {
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl('', Validators.required));
      } else {
        this.addressBlockFg.addControl(elemAddr.dataName, new FormControl(''));
      }
 
      this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });

    } // elem address loop
    this.rowsAddress.push({
      row: Number(iRow).toString,
      lengthMax: iLengthMax,
      cols: colsArray,
    });
    // this.appointment.equipmentLocation = '';
  }
  calWidthCol(cols: any[], dataLengthMax: number, lengthMax: number): string {

    let fieldSize = dataLengthMax * 11;
    // padding left / right
    if (fieldSize < 90 ) {
      fieldSize = fieldSize + (15 * 2);
    }
    return fieldSize.toString() + 'px';
  }
  getColRowAddr(cols: any[]): any {
    // console.log(cols.length);
    return 'col';

  }
  formatFieldAddress(typeRaw: string, iRow: number, iCol: number, paramsCur: any, value: any): void {
    let valueReturn: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;

    let  inputError: boolean;
    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + paramsCur + ' ' + _value);
    inputValue = value;
    inputError = false;

    displayFmt = paramsCur.displayFmt;
    dataName = paramsCur.dataName;
    if (paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
      this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
      this.verifAddress(iRow, iCol);
      return;
    }
    if (paramsCur.dataType !== undefined) {
      isNumeric = (paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    if (isNumeric) {
      inputValue = inputValue.replace(/\D/g, '');
    }

    let pattern: any;
    let fmtCur: string ;

    fmtCur = paramsCur.controlFmt;
    displayFmt = paramsCur.displayFmt;

    pattern = new RegExp(fmtCur, 'i');

    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }
    valueReturn = matchesGroup;
    this.addressBlockFg.controls[paramsCur.dataName].setValue(valueReturn);
    this.rowsAddress[iRow].cols[iCol].inputValue = valueReturn;
    this.verifAddress(iRow, iCol);

    return;
  }
  verifAddress(iRow: number, iCol: number): void  {
    // if (iRow === this.rowsAddress.length - 1) {
      let adressFields: FieldValue[];
      adressFields = [];
      let mandatoryFieldsOK = true;
      this.incompleteInput = false;
      // if (iCol === this.rowsAddress[iRow].cols.length - 1) {
      let addressCur = '';
      let addressData = '';
      let iData = 0;
      for ( const rowCur of this.rowsAddress ) {
        for ( const colCur of rowCur.cols ) {
          if (colCur.dataMandatory === 'O') {
            if ((colCur.inputValue === '') || (colCur.inputValue === undefined)) {
              this.incompleteInput = true;
              mandatoryFieldsOK = false;
            }
          }
          let valueCur = colCur.inputValue;
          if (valueCur === undefined) {
            valueCur = '';
          }
          adressFields.push( {
            label: colCur.elemLabel,
            name: colCur.dataName,
            value: valueCur
          });
          if (iData === 0) {
            addressData = colCur.elemLabel + ' : ' + valueCur;
            addressCur += valueCur;
          } else {
            addressData  += ',' + colCur.elemLabel + ' : ' + valueCur;
            addressCur += ',' + valueCur;
          }
          iData++;
          /*if (colCur.inputValue !== '') {
            addressCur += colCur.inputValue + ',';
          }*/
        }
      }
      if (mandatoryFieldsOK) {
        this.appointment.equipmentLocation = addressData;
        this.appointment.equipmentLocation = JSON.stringify(adressFields);
        // this.addressToCoordinates(addressCur);
      }
      // }
    // }
  }
  counter(field: string, increment: number, value: any ): void  {

    switch (field) {
      case 'nbEquipments': {
        let valueCur = 0;
        if (value === '') {
          valueCur = 0;
        } else {
          valueCur = Number.parseInt(value, 10);
        }
        valueCur = valueCur + increment;
        if (valueCur < this.equipNb.min)  {
          valueCur = this.equipNb.min;
        }
        if (valueCur > this.equipNb.max)  {
          valueCur = this.equipNb.max;
        }
        this.clientForm.controls.nbEquipments.setValue(valueCur);
        this.nbEquipmentsEvent.emit(valueCur);
        break;
      }
      default: {
        break;
      }
    }
  }
  onSubmit(): void {
    this.fillModel();
    this.nextStepEvent.emit(1);
  }
  onGoHome(): void {
    this.nextStepEvent.emit(-1);
  }

  fillForm(): void {
    /*
    this.clientForm.setValue({
      lastName: this.client.lastName,
      firstName: this.client.firstName,
      companyName: this.client.companyName,
      phoneNumber: this.client.phoneNumber,
      mailAddress: this.client.mailAddress,
      equipmentLocation: this.appointment.equipmentLocation,
      equipmentsNb:  this.appointment.equipments.length,
    });*/
    this.clientForm.controls.lastName.setValue(this.client.lastName);
    this.clientForm.controls.firstName.setValue(this.client.firstName);
    this.clientForm.controls.companyName.setValue(this.client.companyName);
    this.clientForm.controls.phoneNumber.setValue(this.client.phoneNumber);
    this.clientForm.controls.mailAddress.setValue(this.client.mailAddress);
    // this.clientForm.controls.equipmentLocation.setValue(this.appointment.equipmentLocation);
    this.clientForm.controls.nbEquipments.setValue(this.appointment.nbEquipments);
  }

  fillModel(): void {
    // tslint:disable-next-line:no-non-null-assertion
    this.client.lastName = this.clientForm.get('lastName')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.client.firstName = this.clientForm.get('firstName')!.value;
    // tslint:disable-next-line:no-non-null-assertion
    this.client.companyName = this.clientForm.get('companyName')!.value;
        // tslint:disable-next-line:no-non-null-assertion
    this.client.phoneNumber = this.clientForm.get('phoneNumber')!.value;
        // tslint:disable-next-line:no-non-null-assertion
    this.client.mailAddress = this.clientForm.get('mailAddress')!.value;
  }
}
