/*
https://stackoverflow.com/questions/56661380/how-to-make-disable-right-click-function-in-angular-called-from-any-component
https://stackblitz.com/edit/angular-ryh715?file=src%2Fapp%2Fapp.component.html 
*/
import { Directive, HostListener  } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[mdiNoRightClick]'
})
export class NoRightClickDirective {
// env.rigthClickEvent
// @HostListener('contextmenu', ['$event'])


  constructor() { }
  
  @HostListener(environment.rigthClickEvent, ['$event'])
  onRightClick(event: any) {
    event.preventDefault();
  }
}
