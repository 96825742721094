/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./criticality-renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../services/translate.pipe";
import * as i3 from "../../../../../../../../services/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./criticality-renderer.component";
var styles_CriticalityRendererComponent = [i0.styles];
var RenderType_CriticalityRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CriticalityRendererComponent, data: {} });
export { RenderType_CriticalityRendererComponent as RenderType_CriticalityRendererComponent };
function View_CriticalityRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "background: red!important;text-align: center!important;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.currentValue)); _ck(_v, 1, 0, currVal_0); }); }
function View_CriticalityRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "background: green!important;text-align: center!important;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.currentValue)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CriticalityRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CriticalityRendererComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CriticalityRendererComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.criticalAlert; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.criticalAlert; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CriticalityRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-criticality-renderer", [], null, null, null, View_CriticalityRendererComponent_0, RenderType_CriticalityRendererComponent)), i1.ɵdid(1, 114688, null, 0, i5.CriticalityRendererComponent, [i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CriticalityRendererComponentNgFactory = i1.ɵccf("mdi-criticality-renderer", i5.CriticalityRendererComponent, View_CriticalityRendererComponent_Host_0, {}, {}, []);
export { CriticalityRendererComponentNgFactory as CriticalityRendererComponentNgFactory };
