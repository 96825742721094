/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../auth/login/login.component.ngfactory";
import * as i3 from "../auth/login/login.component";
import * as i4 from "@angular/router";
import * as i5 from "../services/theme.service";
import * as i6 from "../services/user.service";
import * as i7 from "../services/translate.service";
import * as i8 from "../job/job-wks/services/wks-central.service";
import * as i9 from "../services/auth.service";
import * as i10 from "@angular/common";
import * as i11 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mdi-login", [], null, null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(2, 245760, null, 0, i3.LoginComponent, [i4.Router, i5.ThemeService, i6.UserService, i7.TranslateService, i8.WksCentralService, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "um container-fluid "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "homeTheme container-fluid text-center col"], ["style", "margin-top: 75px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h1", [["class", "homeTheme"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.user; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appSigle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.appTitle; _ck(_v, 6, 0, currVal_1); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i11.HomeComponent, [i6.UserService, i4.Router, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("mdi-home", i11.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
