import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/user.service";
import * as i2 from "@angular/common/http";
export class SendmailService {
    constructor(userService, httpClient) {
        this.userService = userService;
        this.httpClient = httpClient;
    }
    sendMail(entity, emailData) {
        const localHttpHeaders = this.userService.getHeaders();
        const urlService = `${environment.baseUrl}/wks/jobs/sendEmail/?` + 'entity=' + entity;
        const body = JSON.stringify(emailData);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
SendmailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendmailService_Factory() { return new SendmailService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.HttpClient)); }, token: SendmailService, providedIn: "root" });
