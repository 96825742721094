import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ModalCommonComponent } from '../../../job/common/components/modal-common/modal-common.component';

import { RemoteFilesService } from '../../../job/common/services/remote-files.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';

@Component({
  selector: 'mdi-reload-config',
  templateUrl: './reload-config.component.html',
  styleUrls: ['./reload-config.component.css']
})
export class ReloadConfigComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  constructor(private _remoteService: RemoteFilesService,
              private _dialog: MatDialog,
              private _userService: UserService, 
              private _translate: TranslateService) { }

  ngOnInit() {
  }
  reloadWebdav(): void {
    this.displayMessage('reloadWebdav', 'inProgress');
    this._remoteService.reloadWebdav()
    .subscribe(
      data => {
        this._dialog.closeAll();
        this.displayMessage('reloadWebdav', 'done');
      },
      err =>  {
        this._dialog.closeAll();
        console.log('reloadWebdav : ' + JSON.stringify(err));
        this.displayMessage('reloadWebdav', 'error', [JSON.stringify(err)]);
      }
    );
  }
  displayMessage(_actionCur: string, _status?: string, args?: any[] ) {
    const dialogConfig = new MatDialogConfig();
    let progressDeterminate: boolean;
    let typeBox = 'alertWks';
    let titleBox = '' ;
    let messageBox: string;

    if (_actionCur === 'reloadWebdav' && _status === 'inProgress' ) {
      titleBox = 'Reloadwebdav';
      typeBox = 'infoProgressWks';
      messageBox = this._translate.instant('ReloadWebdavInprogress');
      progressDeterminate = true;
    }



    if (_actionCur === 'reloadWebdav' && _status === 'done') {
      messageBox = this._translate.instant('ReloadWebdavDone');
    } 
    if (_actionCur === 'reloadWebdav' && _status === 'error') {
      messageBox = this._translate.instant('ReloadWebdavError');

      if (args !== undefined) {
        messageBox = args[0];
      }
    } 
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog:  typeBox ,
        panelClass: 'stdTheme',
        contentMessage: messageBox,
        data1: '',
        data2: '',
        actionCur: _actionCur,
        progressDeterminate: progressDeterminate,
        messageType: 'ERROR'
    };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data !== undefined)  {
          if (( data === 'okAction')) {
            this._dialog.closeAll();
          }
        }
      });
  }
}
