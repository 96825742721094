<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span style="margin-left: 45%; font-size:large">{{ 'param_worksrates' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <div *ngIf="displayType === 'directCreate' ">
      <mdi-workrates-card 
          [statutCall]="'directCreate'" 
          [optionItem]="'param_worksrates'" 
          [actionType]="displayType" 
          [docToUpdate]="workRateToUpdate">
      </mdi-workrates-card>
    </div>
    <mat-sidenav-container *ngIf="displayType === 'list'" fxFlexFill class="container-sidenav w-100" autosize>
      <mat-sidenav #sidenav fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-50">
          <div fxLayout="column" *ngIf="isRegisterWorkRate">
            <a (click)="toggleSidenav('')" *ngIf="doCreateWorkRate" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}
              <a> ({{ 'param_worksrates' | translate }})</a>
            </a>
            <a (click)="toggleSidenav('')" *ngIf="!doCreateWorkRate" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}
              <a> ({{ 'param_worksrates' | translate }})</a>
            </a>
            <a>
              <mdi-workrates-card style="width: 100%;"
                [statutCall]="'sidenav'" 
                [optionItem]="'param_worksrates'" 
                [actionType]="actionType" 
                [workRateToUpdate]="workRateToUpdate">
              </mdi-workrates-card>
              </a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div class="container-fluid" style="height: 100%;">
          <div class="flex-row row stdTheme w-100" >
            <div class="col-4 d-flex justify-content-start align-items-center  " >
              <input type="text"  id="filter-text-box" matInput
                    style="height: 50px;"
                    placeholder="{{ 'global filter' | translate }}" 
                    (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
            </div>
            <div class="col-2 d-flex justify-content-start align-items-center  "  >
             
            </div>
             <div class="col-6 d-flex justify-content-end align-items-center">
              <button mat-icon-button class="buttonTheme" 
                     style="margin-left: 10px;margin-right: 10px;" (click)="addWorkRate()">
                <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                      style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
             
            </div>
          </div>
          <div style="height: 90%;">
            <ag-grid-angular *ngIf="!loadingIndicator"
              style="width: 100%; height: 100%;" 
              class="ag-theme-material"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [localeText]="localeText"
              [pagination]="true"
              [paginationAutoPageSize]="true"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
