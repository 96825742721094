import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ReportEvent, AnalyseDataCsv, CommonReport, ColumnsHeaderStatut } from '../../../models/common.model';
import { mjlusers_sch, ColDef, ActionRules } from '../../../models/tables.model';
import { UserModel , RoleDefModel, HierarchicalRoles, RoleModel} from '../../../models/user.model';
import { TranslateService } from '../../../services/translate.service';
import { UserService } from '../../../services/user.service';
import { CommonMethods } from '../tools/commonMethods';
import { GlobalEventsManagerService } from './global-events-manager.service';
import { RolesAdmitted } from '../../../organization/roles/roles';
@Injectable({
  providedIn: 'root'
})

export class ControlCsvService {

  public onControlCsv: EventEmitter<ReportEvent> = new EventEmitter<ReportEvent>();

  rootDependentLevels: RoleDefModel[];
  supportedRolesNames: Array<string> = [];
  isAdminApplicant = false;
  authorities: Array<string> = [];
  userLogged: UserModel;
  rolesAdmitted: Array<string> = [];
  entitiesAdmitted: Array<string> = [];
  // grpsAdmitted: Array<string> = [];
  mapGrpsAdmitted = new Map();
  // columnsHeader: Array<string> = [];
  columnsHeader: Map<string, ColDef[]>;

  constructor(private _httpClient: HttpClient, 
              private _translateService: TranslateService,
              private _userService: UserService, 
              private _globalEventsManagerService: GlobalEventsManagerService  ) {

    const supportedRolesList: RoleModel[]  = JSON.parse(JSON.stringify(this._userService.getSupportedRoles()));
    for (const currentRole of supportedRolesList) {
      const localRole = currentRole;
      this.supportedRolesNames.push(localRole.value);
    }
    this.loadAllEntities();
    /// userLogged and autorities
    this.userLogged = _userService.getUserLogged();
    this.isAdminApplicant = false;
    const authoritiesLogged = this.userLogged.authorities;
    this.authorities = CommonMethods.jsonPropToArray(authoritiesLogged, 'authority');
    const rolesTmp = CommonMethods.arrayStringToString(this.authorities, ',');
    if ( rolesTmp.indexOf('ROLE_ADMIN') > -1) { this.isAdminApplicant = true; }
    // limits of the authorizations
    if (this.isAdminApplicant ) {
      this.rolesAdmitted.push('ALL');
    } else {
      let hierarchyRoleRoot: HierarchicalRoles  = JSON.parse(JSON.stringify(this._userService.getHierarchyRoles()[0]));
      this.rootDependentLevels = hierarchyRoleRoot.rootlevel.dependentlevel;
      let computeRolesAdmitted = new RolesAdmitted(this.rootDependentLevels, this.authorities);
      this.rolesAdmitted = computeRolesAdmitted.getAuthorizedRoles();
      computeRolesAdmitted = null;
      hierarchyRoleRoot = null;
    }
    // limits of entities

  }
  public setColumsHeaders(headerName: string, headercolmuns: ColDef[]): void {
    if (this.columnsHeader === undefined) {
      this.columnsHeader = new Map<string, ColDef[]>();
    }
    this.columnsHeader.set(headerName, headercolmuns);
  }
  public getColumsHeaders(headerName: string): ColDef[] {
    return this.columnsHeader.get(headerName);
    
  }
  public readCsvFile (_fileToRead: File) {
    return new Promise<any>((resolve, reject) => {
     
      const reader = new FileReader();

      reader.readAsText(_fileToRead, 'utf8');
      reader.onloadend = () => {
          // console.log('Name : ' + _fileToRead.name + '>>> Type : ' + _fileToRead.type);
          const content = reader.result;
          const regexSplit = new RegExp('\r\n|\n\r|\n|\r', 'g');
          const lineString = content.toString();
          const arrayOfLines = lineString.replace(regexSplit, '\n').split('\n');
          resolve(arrayOfLines);
        };
        reader.onerror = function(event) {
          console.error('File could not be read! Code ' + event.target);
          reject(event.target);
        };
      });
  }
/**
 * Control csv file (format and content)
 * @param _fileToRead
 * @param _userLogged
 * @param _uploadType
 */
  public controlCsvFile (_fileToRead: File, _uploadType: string) {

    let waiting = true;
    return new Promise<void>((resolve, reject) => {
      let returnStatus = false;
      const reader = new FileReader();

      reader.readAsText(_fileToRead);
      reader.onloadend = () => {
          // console.log('Name : ' + _fileToRead.name + '>>> Type : ' + _fileToRead.type);
          const content = reader.result;
          const regexSplit = new RegExp('\r\n|\n\r|\n|\r', 'g');
          const lineString = content.toString();
          const arrayOfLines = lineString.replace(regexSplit, '\n').split('\n');
          if ( _uploadType === 'users') {
            const commonReport =  new CommonReport();
            commonReport.isNewParent = true;
            commonReport.id = _fileToRead.name;
            commonReport.level = 0;
            commonReport.pathParent = _fileToRead.name;
            commonReport.type = 0 ;
            commonReport.message = this.creatErrorLine(_fileToRead.name, '', '', '', '' ,
                        '', '', '', 'AnalyzeFile').errorMessage;
            this._globalEventsManagerService.commonReportSubject.next(commonReport);

            if (this.controlUsersData(arrayOfLines, this.userLogged, _fileToRead.name)) {
              returnStatus = true;
              waiting = false;
              console.log('controlUsersData return : ' + returnStatus);
              resolve();
            } else {
              console.log('controlUsersData return : ' + returnStatus);
              returnStatus = false;
              waiting = false;
              reject();
            }
          }
        };
        reader.onerror = function(event) {
          console.error('File could not be read! Code ' + event.target);
          reject();
        };
      });
    // =======================================
    /*
    reader.onerror = function(e) {
          switch (e.target)
          {
              case e.target.error.NOT_FOUND_ERR:
                  alert('File Not Found!');
                  break;
              case e.target.error.NOT_READABLE_ERR:
                  alert('File is not readable');
                  break;
              case e.target.error.ABORT_ERR:
                  break; // noop
              default:
                  alert('An error occurred reading this file.');
          };
      };
    */
    // console.log('controlCsvFile return : ' + returnStatus);
    // return returnStatus;
  }
  /*
  public controlPartsSuppliesColumns(_arrayOfLines: any[], _nameObject): ColumnsHeaderStatut[] {
    const headers: ColDef[] = this.getColumsHeaders('partsSupplies');
    const colErrors: ColumnsHeaderStatut[] = this.controlColumnsName(_arrayOfLines[0], headers);

    return colErrors;
  }
  public controlAnchoragesColumns(_arrayOfLines: any[], _nameObject): ColumnsHeaderStatut[] {
    const headers: ColDef[] = this.getColumsHeaders('anchorages');
    const colErrors: ColumnsHeaderStatut[] = this.controlColumnsName(_arrayOfLines[0], headers);

    return colErrors;
  }
  */
  public controlColumns(_arrayOfLines: any[], _nameObject, _headerName: string): ColumnsHeaderStatut[] {
    const headers: ColDef[] = this.getColumsHeaders(_headerName);
    const colErrors: ColumnsHeaderStatut[] = this.controlColumnsName(_arrayOfLines[0], headers);

    return colErrors;
  }
  private controlColumnsName( _headerLine: string, _columnsHeaders: ColDef[]): ColumnsHeaderStatut[] {
    const colHeaders = _headerLine.split(';');
    let isFound: boolean;
    const colErrors: ColumnsHeaderStatut[] = [];
    let colCurTmp: ColDef;
    for (const colName of colHeaders) {
      isFound = false;
      const colCur: ColumnsHeaderStatut = {
        columnName: colName
      };
      _columnsHeaders.find(c => {
        if (c.colName.toLowerCase() === colName.toLowerCase()) {
          colCur.ok = true;
          colCurTmp = c;
          isFound = true;
        }
      });
      if (!isFound) {
        colCur.unKnow = true;
        colErrors.push(colCur);
      }
      
    }
    for (const colCurrent of _columnsHeaders) {
      // || 
      if (!colCurrent.actionRules.add.mandatory 
        && !colCurrent.actionRules.del.mandatory 
        && !colCurrent.actionRules.update.mandatory) {
          continue;
      }

      if (colCurrent.actionRules.add.mandatory 
        && colCurrent.actionRules.del.mandatory 
        && colCurrent.actionRules.update.mandatory) {
        if (colHeaders.includes(colCurrent.colName)) {
          continue;
        }
      }

      const colCur: ColumnsHeaderStatut = {
        columnName: colCurrent.colName,
        notPresent: true
      };
      colErrors.push(colCur);
    }
    return colErrors;
  }
/**
 * Control data for user schema
 * @param _arrayOfLines
 * @param _userLogged
 * @param _nameObject
 */
 private controlUsersData(_arrayOfLines: any[], _userLogged: UserModel, _nameObject): boolean {
    const dataColumnsDef = mjlusers_sch as ColDef[];
    const reportEvent = new ReportEvent();
    const reportMessages = [];
    const lstErrors = this.controlHeadersName(_arrayOfLines[0], dataColumnsDef);
    // console.log('controlHeadersName : ' + lstErrors);
     // reading headers
    // control header names : event emit if error / OK
    if (lstErrors.length === 0) {
    // control mandatories columns : event emit if false / OK
      reportEvent.level = 'INFO';
      reportEvent.message = 'allColumnsPresents';
      reportEvent.nameObject = _nameObject;
      this.emitEventAnalyze(reportEvent);
    } else {
      reportEvent.level = 'ERROR';
      reportEvent.message = 'headersNameError';
      reportEvent.data = lstErrors.toString();
      reportEvent.nameObject = _nameObject;
      this.emitEventAnalyze(reportEvent);
      return false;
    }

    // reading data lines : event emit if no lines / OK
    if (_arrayOfLines.length === 1 ) {
      reportEvent.level = 'ERROR';
      reportEvent.message = 'noLinesDataPresents';
      reportEvent.nameObject = _nameObject;
      this.emitEventAnalyze(reportEvent);
      return false;
    }

    // control lines content : mandatory data : event emit if error /OK

    const listError = this.linesAnalyzeProcess(_arrayOfLines, dataColumnsDef, _userLogged, _nameObject);
    if ((listError) && (listError.length > 0)) {
      reportEvent.level = 'INFO';
      reportEvent.message = 'dataLinesAnalyzedKO';
      reportEvent.nameObject = _nameObject;
      reportEvent.data = listError.length.toString();
      this.emitEventAnalyze(reportEvent);
      let error: AnalyseDataCsv;
      let commonReport =  new CommonReport();
      for (error of listError) {
        reportMessages.push(this.getErrorProcess(error));
        commonReport =  new CommonReport();
        commonReport.id = error.errorMessage;
        commonReport.message = this.getErrorProcess(error);
        commonReport.level = 1; // child
        commonReport.type = 2;
        commonReport.state = 0;
        commonReport.pathParent = _nameObject;
        this._globalEventsManagerService.commonReportSubject.next(commonReport);
      }

      return false;
    } else {
      reportEvent.level = 'INFO';
      reportEvent.message = 'dataLinesAnalyzed';
      reportEvent.nameObject = _nameObject;
      this.emitEventAnalyze(reportEvent);
      return true;
    }
  }

  /**
   * line analysis and application of the rules for the requested action
   * @param _arrayOfLines
   * @param _dataColumnsDef
   * @param _userLogged
   * @param _nameObject
   */
  public linesAnalyzeProcess(_arrayOfLines: any[], _dataColumnsDef: ColDef[], _userLogged: UserModel, _nameObject: string ): any[] {
    const errors = []; // Clear error
    const analyseProcessErrors = [];

    const colSort = [];
    const supportedLangs = [];
    let colTmp: ColDef;
    let actionTmp: ActionRules;
    let errorLine = new AnalyseDataCsv();
    let lineRead = 0;
    let entityCur = '';
    const colHeaders = _arrayOfLines[0].split(';'); // split headers
    let colAction = -1;
    let colCur = 0;
    let action = '';
    // supported langs
    for (const langCur of this._translateService.getSupportedLangs()) {
        supportedLangs.push(langCur.value);
    }
    // sorting column def in order columns file
    for (const colName of colHeaders) {
      if (colName.toLowerCase() === 'action') {
        colAction = colCur;
      }
      colTmp = _dataColumnsDef.find(c => {
        if (c.colName.toLowerCase() === colName.toLowerCase()) {
          return c;
        }
      });
      if (colTmp !== undefined) {
        colSort.push(colTmp);
      }
      
      colCur++;
    }
    if ( colAction < 0 ) { // no col action
      errors.push('columnActionMissing');
      errorLine = this.creatErrorLine(_nameObject, '', 'action', -1, 0 , '', '', '', 'columnActionMissing');
      analyseProcessErrors.push(errorLine);
      errorLine = null;
      return analyseProcessErrors;
    }

    // reading lines
    for (const curLine of _arrayOfLines) {
      if (curLine.trim().length === 0) { continue; }
      if (lineRead === 0) {
        lineRead++;
        continue;
      } // headers line no read
      entityCur = '';
      const dataLine = curLine.split(';');
      action = dataLine[colAction].toUpperCase();
      const verifAction = action.match(/ADD|DEL|UPDATE/gi);
      if (!verifAction) {
        errors.push('actionLabelKO');
        errorLine = this.creatErrorLine(_nameObject, action, 'action', colAction, lineRead ,
                                        dataLine[colAction], 'ADD, DEL, UPDATE', '', 'actionLabelKO');
        analyseProcessErrors.push(errorLine);
        errorLine = null;
        return analyseProcessErrors;
      } else {
        const colMissing = this.controlMandatoryData(action, colSort, _dataColumnsDef);
        if ((colMissing) && (colMissing.length > 0)) {
          errors.push('columnsMissing');
          errorLine = this.creatErrorLine(_nameObject, action, '', -1, lineRead , '', colMissing.toString(), '', 'columnsMissing');
          analyseProcessErrors.push(errorLine);
          errorLine = null;
          return analyseProcessErrors;
        }
      }
      let colRead = 0;
      // read data cell in line
      for (const colData of dataLine) {
        if (colRead === colAction ) {
          colRead++;
          continue ;
        } // action column not analyzed
        // read action for current line
        colTmp = colSort[colRead]; // column object
        // action rules for column and action specified
        switch (action) {
          case 'ADD':
            actionTmp = colTmp.actionRules.add;
            break;
          case 'DEL':
            actionTmp = colTmp.actionRules.del;
            break;
          case 'UPDATE':
            actionTmp = colTmp.actionRules.update;
            break;
        }
        colRead++;
        // analyze rule attributes

        if (( actionTmp.mandatory) && ( colData.trim().length < 1)) {
          errors.push('dataKOMandatory');
          errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                                  colData, actionTmp.defaultValue, '', 'dataKOMandatory');
          analyseProcessErrors.push(errorLine);
          errorLine = null;
          continue;
          }
        if (( actionTmp.forbidden) && ( colData.trim().length > 1)) {
          errors.push('dataKOForbidden');
          errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead , colData, '', '', 'dataKOForbidden');
          analyseProcessErrors.push(errorLine);
          errorLine = null;
          continue;
        }
        // formatValues
        errorLine = null;
        if (actionTmp.formatValues.trim().length > 0 ) {
          const formatValues = actionTmp.formatValues.trim();
          switch (formatValues) {
            case '$email$':
              if (!this.controlEmail(colData)) {
                errors.push('EmailFormatKO');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead , colData, '', '', 'EmailFormatKO');
              }
              break;
            }
          if (colTmp.colType === 'decimal') {
            try  {
              Number(colData);
            } catch (e) {
              errors.push('decimalFormatKO');
              errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead , colData, '', '', 'decimalFormatKO');
            }
            try  {
              // const pattern = new RegExp(formatValues, 'i');
              // let testMatch: any;
              // testMatch = colData.match(pattern);
              const testMatch = CommonMethods.controlField(colData, formatValues );
              if (testMatch === null) {
                errors.push('decimalFormatKO');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead , colData, '', '', 'decimalFormatKO');
              }
            } catch (e) {
              errors.push('decimalFormatKO');
              errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead , colData, '', '', 'decimalFormatKO');
            }
          }
          if (errorLine) {
            analyseProcessErrors.push(errorLine);
            errorLine = null;
            continue;
          }
        }
        // accepted values
        if (actionTmp.acceptedValues.trim().length > 0 ) {
          const acceptedValues = actionTmp.acceptedValues.trim();
          switch (acceptedValues) {
            case '$Roles$':
              if (!this.controlRolesName(colData)) {
                errors.push('SupportedRoleKO');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                          colData, this.supportedRolesNames.toString(), '', 'SupportedRoleKO');
              } else {
                if (!this.controlRoles(colData)) {
                  errors.push('ControlRoleKO');
                  errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                                        colData, '', '', 'ControlRoleKO');
                }
              }
              break;
            case '$UsersGroup$':
            // verif grp habilitations
              if (((!entityCur) || (entityCur.trim().length < 1 )) && ((!colData) || (colData.trim().length < 1 ))) {
                break;
              }
              const grpsAdmitted = this.mapGrpsAdmitted.get(entityCur);
              if (!grpsAdmitted) {
                errors.push('userGrploadKO');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                          colData, entityCur, '', 'userGrploadKO');
              } else {
                let habilGrpName = grpsAdmitted.toString().indexOf(colData);
                  if (habilGrpName === -1) {
                    errors.push('userGrpNameKO');
                    errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                              colData, grpsAdmitted, '', 'userGrpNameKO');
                  } else if (!this.isAdminApplicant) {
                    habilGrpName = this.userLogged.extendedgrp.indexOf(colData);
                    if (habilGrpName === -1) {
                      errors.push('habilGrpNameKO');
                      errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                            colData, this.userLogged.extendedgrp, '', 'habilGrpNameKO');
                    }
                  }
                }


              break;
            case '$Entities$':
              // verif entity name
              let habilEntityName = this.entitiesAdmitted.toString().indexOf(colData);
              if (habilEntityName === -1) {
                errors.push('userEntityNameKO');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                              colData, this.entitiesAdmitted, '', 'userEntityNameKO');
              } else if (!this.isAdminApplicant) {
                // verif entity habilitations
                habilEntityName = this.userLogged.extendedentities.indexOf(colData);
                if (habilEntityName === -1) {
                  errors.push('habilEntityNameKO');
                  errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead ,
                          colData, this.userLogged.extendedentities, '', 'habilEntityNameKO');
                }
              }
              entityCur = colData;
              break;
            case '$supportedLangs$':
             const verifLang = supportedLangs.toString().indexOf(colData);
             if (verifLang === -1) {
              errors.push('SupportedLangKO');
              errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead,
                    colData, supportedLangs.toString(), '', 'SupportedLangKO');
            }
             break;
            default:
              const verifContent = acceptedValues.indexOf(colData);
              if (verifContent === -1) {
                errors.push('dataKOAcceptedValues');
                errorLine = this.creatErrorLine(_nameObject, action, colTmp.colName, colRead, lineRead,
                  colData, actionTmp.acceptedValues, '', 'dataKOAcceptedValues');
              }
              break;
          }
          if (errorLine) {
            analyseProcessErrors.push(errorLine);
            errorLine = null;
            continue;
          }

        }
      } // end col loop
      lineRead++;
    } // end lines loop

    return analyseProcessErrors;
  }
/**
 * Creat object error
 * @param _nameObject
 * @param _action
 * @param _colName
 * @param _numCol
 * @param _numLine
 * @param _cellData
 * @param _otherValue1
 * @param _otherValue2
 * @param _errorMessage
 */
creatErrorLine(_nameObject, _action, _colName, _numCol, _numLine, _cellData, _otherValue1, _otherValue2, _errorMessage ): AnalyseDataCsv {
  const errorLineValues = new AnalyseDataCsv();
  errorLineValues.fileName = _nameObject;
  errorLineValues.action = _action;
  errorLineValues.columnName = _colName;
  errorLineValues.numColumn = _numCol;
  errorLineValues.numLine = _numLine;
  errorLineValues.cellValue = _cellData;
  errorLineValues.otherValue1 = '';
  if (_otherValue1) { errorLineValues.otherValue1 = _otherValue1; }
  if (_otherValue2) { errorLineValues.otherValue2 = _otherValue2; }
  errorLineValues.errorMessage = _errorMessage;
  return errorLineValues;
}
emitEventAnalyze(event: ReportEvent) {

  const commonReport =  new CommonReport();
  // this._translateService.instant(_error.errorMessage, [_error.fileName]);
  commonReport.id = event.message;
if (event.data) {
    commonReport.message = this._translateService.instant(event.message, [event.nameObject, event.data]);
  } else {
    commonReport.message = this._translateService.instant(event.message, [event.nameObject]);
  }
  commonReport.level = 1; // child
  commonReport.type = CommonMethods.getAlertType(event.level);
  commonReport.state = 0;
  commonReport.pathParent = event.nameObject;
  this._globalEventsManagerService.commonReportSubject.next(commonReport);
}
/**
 * control email format
 * @param _emailCur
 */
  private controlEmail(_emailCur: string): boolean {
    return CommonMethods.controlEmail(_emailCur);
  }
/**
 * Validate roles names
 * @param _rolesName
 */
private controlRolesName(_rolesName: string): boolean {
  const rolesList = _rolesName.split(',');
  let existsName;
  let roleOK = 0;
  for ( const roleCur of rolesList) {
    if ((roleCur === 'ROLE_ADMIN') && (this.isAdminApplicant)) {
      roleOK++;
    } else {
      existsName = this.supportedRolesNames.toString().indexOf(roleCur);
      if (existsName !== -1) {
        roleOK++;
      }
    }
  }
  if (roleOK === rolesList.length) { return true; } else { return false; }
}
/**
 * controle habilitations
 * @param _rolesName
 */
private controlRoles(_rolesName: string): boolean {

  let existsName;
  const rolesList = _rolesName.split(',');

  for ( const roleCur of rolesList) {
    if (roleCur === 'ROLE_ADMIN') {
      // request ADMIN and applicant non ADMIN : false
      // else applicant has all the authorizations : true
      if (this.isAdminApplicant) { return true; } else { return false; }
    }
    existsName = this.supportedRolesNames.toString().indexOf(roleCur);
    if (existsName === -1) {
      return false;
    }
  }
  return true;
 }
  
/**
 * control of column name compliance
 * @param _headerLine
 * @param _dataColumnsDef
 */
  private controlHeadersName( _headerLine: string, _dataColumnsDef: any): any [] {
    const colHeaders = _headerLine.split(';');
    let dataType: any;
    const colError = [];
    let colAction = -1;
    let colCur = 0;
    for (const colName of colHeaders) {
      if (colName.toLowerCase() === 'action') {
        colAction = colCur;
      } else {
        dataType = _dataColumnsDef.find(c => {
          if (c.colName.toLowerCase() === colName.toLowerCase()) {
            return c.colType;
          }
        });
        if (!dataType) {
          colError.push(colName);
        }
      }
      colCur++;
    }

    if (colAction < 0 ) {
      colError.push('Action'); // action column missing
    }
    return colError;
  }
  /**
   * Control of the required columns for a row
   * @param _action
   * @param _colsPresents
   */
  private controlMandatoryData(_action: string, _colsPresents: any[], _dataColumnsDef: any): string[] {
    const colMissing = [];
    const colList = [];
    let actionTmp: ActionRules;
    for (const colTmp of _colsPresents) {
      colList.push(colTmp.colName.toLowerCase());
    }

    for (const colTmp of _dataColumnsDef) {
      switch (_action.toLocaleLowerCase()) {
        case 'add':
          actionTmp = colTmp.actionRules.add;
          break;
        case 'del':
          actionTmp = colTmp.actionRules.del;
          break;
        case 'update':
          actionTmp = colTmp.actionRules.update;
          break;
      }
      if (actionTmp.mandatory) {
        const verifContent = colList.toString().indexOf(colTmp.colName.toLowerCase());
        if (verifContent === -1) {
          colMissing.push(colTmp.colName);
        }
      }
    }
    return colMissing;
  }
  loadAllEntities() {
    this.getListEntities()
    .then((result) => {
      this.entitiesAdmitted = CommonMethods.jsonPropToArray(result, 'entity');
      this.loadAllGrps();
      // console.log(JSON.stringify(result));
    })
    .catch((error) => {
      console.error(error);
      this.entitiesAdmitted = [];
    });
  }
  getListEntities() {
    return new Promise((resolve, reject) => {
        this._userService.getEntities()
        .subscribe(
          data => {
            const entitiesList = data.body;
              resolve(entitiesList);
          }, err => {
            reject(err);
          }
        );
      });
  }
  loadAllGrps() {
    this.mapGrpsAdmitted = new Map();
    for (const entityCur of this.entitiesAdmitted) {
      this.getListGrps(entityCur)
      .then((result) => {
        const grpsAdmitted = CommonMethods.jsonPropToArray(result, 'usergroup');
        this.mapGrpsAdmitted.set(entityCur, grpsAdmitted);
      })
      .catch(() => {
        this.mapGrpsAdmitted.set(entityCur, null);
      });
    }
  }
  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .subscribe(
          data => {
            const usergroupLists = data.body;
              resolve(usergroupLists);
          }, err => {
            reject(err);
          }
        );
      });
  }

/**
   * getting translated error content
   * @param _error
   */
  public getErrorProcess(_error: AnalyseDataCsv): string {

    let message = '';
    switch (_error.errorMessage) {
      case 'AnalyzeFile':
        message = this._translateService.instant(_error.errorMessage, [_error.fileName]);
        break;
      case 'columnActionMissing':
        message = this._translateService.instant(_error.errorMessage, [_error.fileName]);
        break;
      case 'actionLabelKO':
      // 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) action non acceptée - attendu : %4',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'dataKOMandatory':
      // 'Fichier : %0 (col %1) (lgn %2) Valeur obligatoire pour action ( %3 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.action]);
        break;
      case 'decimalFormatKO':
        // 'Fichier : %0 (col %1) (lgn %2) Valeur non conforme ( %3 )',
          message = this._translateService.instant(_error.errorMessage,
              [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue]);
          break;
      case 'dataKOForbidden':
      // 'Fichier : %0 (col %1) (lgn %2) Valeur interdite pour action ( %3 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.action]);
        break;
      case 'dataKOAcceptedValues':
      // 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) non acceptée pour action  ( %4 )- attendu : %5'
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.action, _error.otherValue1]);
        break;
      case 'columnsMissing':
      // 'Fichier : %0 Colonne(s) obligatoire(s) absente(s) %1 pour action  ( %2 )',
        message = this._translateService.instant(_error.errorMessage,
                  [_error.fileName, _error.otherValue1, _error.action]);
        break;
      case 'SupportedLangKO':
      //   'SupportedLangKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) langue non supportée  - attendu : %4',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'SupportedRoleKO':
      // 'SupportedRoleKO' : 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) role non supporté  - attendu : %4',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'EmailFormatKO':
      // 'EmailFormatKO' : 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) format email non conforme',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue]);
        break;
      case 'userEntityNameKO':
      /// 'userEntityNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) entité non reconnue ( %4 )',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'habilEntityNameKO':
      // 'habilEntityNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) entité non autorisée ( %4 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'userGrpNameKO':
      // 'userGrpNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non reconnu ( %4 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'habilGrpNameKO':
      // 'habilGrpNameKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non autorisé ( %4 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'userGrploadKO':
      // 'userGrploadKO': 'Fichier : %0 (col %1) (lgn %2) Valeur ( %3 ) groupe non chargé pour entité ( %4 )',
        message = this._translateService.instant(_error.errorMessage,
            [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue, _error.otherValue1]);
        break;
      case 'convertJsonDateKO':
        // 'convertJsonDateKO': 'Fichier : %0 lgn (%1) conversion impossible de la date de naissance (%2)',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue]);
        break;
      case 'insertJsonDateKO':
        // 'insertJsonDateKO': 'Fichier : %0 lgn (%1) insertion impossible JSON de la date de naissance (%2)',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.columnName, _error.numLine.toString(), _error.cellValue]);
        break;
      case 'convertJsonToModelKO':
        // 'convertJsonToModelKO': 'Fichier : %0 lgn (%1) conversion JSON vers Modele impossible',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.columnName, _error.numLine.toString()]);
        break;
      case 'accountExistsAddKO':
        // 'accountExistsAddKO': 'Fichier : %0 lgn (%1) le compte utilisateur indiqué (%2) existe déjà',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.numLine.toString(), _error.cellValue]);
        break;
      case 'accountNotExists':
        // 'accountNotExists': 'Fichier : %0 lgn (%1) opération %2 impossible compte (%3) inexistant',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.numLine.toString(), _error.action, _error.cellValue]);
        break;
      case 'operationNotExecuted':
        // 'operationNotExecuted': 'Fichier : %0 lgn (%1) opération %2 compte (%3) non exécutée',
        message = this._translateService.instant(_error.errorMessage,
          [_error.fileName, _error.numLine.toString(), _error.action, _error.cellValue]);
        break;
    }
    return message;
  }
}

