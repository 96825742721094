/** https://github.com/angular-university/angular-material-course/tree/3-dialog-finished */
import { Component, AfterViewInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';

import { Subscription } from 'rxjs';
import { GlobalEventsManagerService } from '../../services/global-events-manager.service';
import { CommonReport, LevelType, AlertType } from '../../../../models/common.model';
import { CommonMethods } from '../../tools/commonMethods';

export class ParentAttributes {
  parent: string;
  parentId: number;
  childId: number;
}

@Component({
  selector: 'mdi-modal-common',
  templateUrl: './modal-common.component.html',
  styleUrls: ['./modal-common.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalCommonComponent implements AfterViewInit, OnDestroy  {


  modalTitle: string;

  listErrors: any;
  modalSubTitle: string;
  isEndReport: boolean;
  reportIsStarted: boolean;
  // textHTML;
  displayStatus: string;
  eventOkToLaunch: string;
  subscription: Subscription;
  jsonData;
  nbParent = -1;

  uploadProcess: boolean;
  confirmAction: boolean;
  updatePwd: boolean;
  confirmAlert: boolean;
  newPasswd: string;
  newPasswdOK: boolean;
  displayMessages: any[] = [];
  numberMessages: number[] = [0];
  numberErrors: number[] = [0];
  mapParent = new Map();
// alert
  contentMessage: string;
  data1: string;
  data2: string;
  messageType: string;
  typeDisplay: string;
  alertWks: boolean;
  actionCur: string;
  infoWks: boolean;
  infoProgressWks: boolean;
  progressBarWks: boolean;
  progressValue: number;
  progressDeterminate: boolean;

  constructor(
      // private fb: FormBuilder,
      private _globalEventsManagerService: GlobalEventsManagerService,
      private dialogRef: MatDialogRef<ModalCommonComponent>,
      @Inject(MAT_DIALOG_DATA) data: any) {

        this.modalTitle = data.title;
        this.uploadProcess = false;
        this.confirmAlert = false;
        this.updatePwd = false;
        this.alertWks = false;
        this.infoWks = false;
        this.infoProgressWks = false;
        this.progressBarWks = false;
        this.confirmAction = false;

        if (data.typeDialog === 'upload') {
          this.uploadProcess = true;
        }

        if (data.typeDialog === 'updatePwd') {
          this.updatePwd = true;
          this.newPasswdOK = false;
        }

        if (data.typeDialog === 'confirmAction') {
          this.confirmAction = true;
          this.contentMessage = data.contentMessage;
          this.typeDisplay = data.typeDisplay;
          this.data1 = data.data1;
          this.data2 = data.data2;
          this.messageType = data.messageType;
          if (data.actionCur) {
            this.actionCur = data.actionCur;
          }
        }
        if (data.typeDialog === 'infoProgressWks') {
          this.infoProgressWks = true;
          this.contentMessage = data.contentMessage;
          this.typeDisplay = data.typeDisplay;
          this.data1 = data.data1;
          this.data2 = data.data2;
          this.messageType = data.messageType;
          if (data.actionCur) {
            this.actionCur = data.actionCur;
          }
        }
        
        if (data.typeDialog === 'alertWks') {
          this.confirmAlert = true;
          this.alertWks = true;
          this.contentMessage = data.contentMessage;
          this.typeDisplay = data.typeDisplay;
          this.data1 = data.data1;
          this.data2 = data.data2;
          this.messageType = data.messageType;
          if (data.actionCur) {
            this.actionCur = data.actionCur;
          }
          if (data.errorsList) {
            this.displayMessages = data.errorsList;
          }
        }
        if (data.typeDialog === 'infoWks') {
          this.confirmAlert = true;
          this.infoWks = true;
          this.contentMessage = data.contentMessage;
          this.typeDisplay = data.typeDisplay;
          this.data1 = data.data1;
          this.data2 = data.data2;
          this.messageType = data.messageType;
          if (data.actionCur) {
            this.actionCur = data.actionCur;
          }
        }
        this.eventOkToLaunch = data.eventOkToLaunch;
        this.subscription = this._globalEventsManagerService.commonReportSubject.subscribe((event: CommonReport) => {
          if (event instanceof CommonReport) {
            // console.log('ModalCommonComponent :commonReportSubject  ' + event.toString());
            this.applyEvent(event);
          }
        });
  }
  ngAfterViewInit () {

    this._globalEventsManagerService.synchroEvents.next(this.eventOkToLaunch);
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.dialogRef.close();
  }
  onNoClick() {
    if (this.confirmAction) {
      this._globalEventsManagerService.synchroEvents.next('cancelAction');
      this.dialogRef.close('cancelAction');
    } else {
      this._globalEventsManagerService.synchroEvents.next('cancelModal');
    }
  }
  onOKClick() {
    if (this.updatePwd) {
      this._globalEventsManagerService.synchroEvents.next('newPwd:' + this.newPasswd);
    } else {
      this._globalEventsManagerService.synchroEvents.next('okAction');
      this.dialogRef.close('okAction');
    }
  }
  getPasswd(event: string) {
    this.newPasswd = event;
    this.newPasswdOK = true;
  }
  loadInit() {
  }
  toggleMessages(event: any) {
    return this.displayMessages[event] = ! this.displayMessages[event];
  }
  private applyEvent (event: CommonReport) {
   const commonMessage: CommonReport = event;
   let tmpParent: ParentAttributes;
    if (commonMessage.isEndReport) {
      this.isEndReport = true;
      this.reportIsStarted = false;
    } else
    if (commonMessage.isStartReport) {

      this.reportIsStarted = true;
      this.jsonData = JSON.parse('[ ]');
      this.isEndReport = false;
      tmpParent = new ParentAttributes();
      tmpParent.parentId = -1;
      tmpParent.childId = -1;
    } else {
     // console.log(commonMessage.message);
      if (commonMessage.isNewParent) {
        this.nbParent++;

        tmpParent = new ParentAttributes();
        tmpParent.parentId = this.nbParent;
        tmpParent.childId = -1;
        this.mapParent.set(commonMessage.pathParent, tmpParent);
        this.numberMessages[tmpParent.parentId] = 0;
        this.numberErrors[tmpParent.parentId] = 0;
        this.displayMessages[tmpParent.parentId] = false;
        // const jsonTmp = `{ "parent": "${commonMessage.id}","children": [ ]}`;
        const jsonTmp = `{ "parent": "${commonMessage.id}","children": [ ]}`;
        this.jsonData = CommonMethods.jsonAddNode(this.jsonData, false, jsonTmp, tmpParent.parentId);
        // console.log('Parent : ' + this.nbParent.toString() + ' JSON ' + JSON.stringify(this.jsonData));
      } else if (commonMessage.level === LevelType.child) {
        tmpParent = this.mapParent.get(commonMessage.pathParent);
        tmpParent.childId++;
        if (commonMessage.type === AlertType.Error) {
          this.numberErrors[tmpParent.parentId]++;
        }
        this.mapParent.set(commonMessage.pathParent, tmpParent);
        this.numberMessages[tmpParent.parentId]++;
        const jsonTmp = `{ "parent": "${commonMessage.pathParent}", "message": "${commonMessage.message}" }`;
        this.jsonData[tmpParent.parentId].children =
                      CommonMethods.jsonAddNode(this.jsonData[tmpParent.parentId].children, false, jsonTmp, tmpParent.childId);
        // this.jsonData = CommonMethods.jsonAddNode(this.jsonData, false, jsonTmp, tmpParent.childId);

       /* console.log('Parent / added Child : ' + tmpParent.parentId.toString() + '/' + tmpParent.childId.toString() +
                    ' JSON ' + JSON.stringify(this.jsonData));*/
      }
       // console.log('Parent / Child : ' + tmpParent.parentId.toString() + '/' + tmpParent.childId.toString() +
       //         ' JSON ' + JSON.stringify(this.jsonData));
      // console.log('Row : ' + tmpParent.childId.toString() + ' JSON ' + JSON.stringify(this.jsonData));
    }

  }
  toggleRow(event) {
  }
  /*
  close() {

  }*/
/*  save() {
    this.dialogRef.close(this.form.value.description);
  } */

  close() {
      this.dialogRef.close('okAction');
    }
  // https://stackblitz.com/edit/angular-js6t7b?file=app%2Fapp.component.ts
  // autre exemple possoble pour passer des données 
  // this.dialogRef.componentInstance.settingExternalData();
  // Mat dialog await 
  // https://stackblitz.com/edit/mat-dialog-promise-await?file=app%2Fmy-dialog%2Fmy-dialog.component.ts
  public settingExternalData() {
    
  }

}
