<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="container-fluid" style="margin-top: 75px; height: 80%;  width: 100%;">
    <div class="flex-row row  stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col top-sidenav" style ="text-align: center!important">
          <span style="font-size:large"><strong>{{ 'param_rh_planning' | translate }}</strong></span>
        </div>
    </div>
    <div class="flex-row row stdTheme" *ngIf="collabsLoaded" style="padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col-3 "></div>
        <div class="col-3 ">
          <mat-form-field  class="mdiinput form-group stdTheme" appearance="standard" style="margin-top: -15px; margin-bottom: 25px">
            <mat-label>{{ 'technician' | translate }}</mat-label>
            <mat-select class="stdTheme"  panelClass="panelSelectContent" #userMechanic  
                      disableOptionCentering required
                      id="userMechanic"
                      required  
                      (selectionChange)="onChangeMechanic($event)" >
              <mat-option class="stdTheme stdSelect" *ngFor="let userMechanic of mechanicUsers" 
                      class="selectOptionsTheme"  [value]="userMechanic.username" >
                            {{userMechanic.username }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>
    <div class="flex-row row  stdTheme" style="margin-left: 0px; margin-right: 0px;width: 100%;">
        <div class="spinner-container  stdTheme" *ngIf="doLoadCalendar && !planningLoaded" fxLayout="row" fxLayoutAlign="space-around center">
            <!--<mat-spinner diameter="50" strokeWidth="5"></mat-spinner>-->
            <span>Loading planning ...</span>
        </div>
        <mdi-calendar *ngIf="planningLoaded" style="padding: 0px; margin-left: 0px; margin-right: 0px;width: 100%;"
              [applicationCall]="'rhPlanning'"
              [eventsRh]= 'eventsRh'
              [localelang]="localelang"
              [localeDateFmt]="localeDateFmt"
              [dateTimeFormat]="dateTimeFormat"
              [events]="eventsList"
              [calendarEvents]="wksEntityParams.calendarEvents"
              [workingTime]="workingTime"
              [modalMode]="false"
              (eventClicked)="eventClicked($event)"
          ></mdi-calendar>
      </div>
</div>
