import { Component, OnInit, Input, SimpleChanges,  OnChanges, ViewChild, ElementRef } from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { ParamActionsrefModel, LangLabelModel, ParamLabelsI18n, ParamRatesModel } from '../../../../models/wks-param.model';
import { BrandWksModel, MeasurementList } from '../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';
import { ParamPartsSuppliesModel } from '../../../../models/wks-param.model';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-parts-supplies-card',
  templateUrl: './parts-supplies-card.component.html',
  styleUrls: ['./parts-supplies-card.component.css']
})
export class PartsSuppliesCardComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() partSupplieToUpdate: ParamPartsSuppliesModel;

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  private readonly onDestroy = new Subject<void>();

  isRegistered: boolean;
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];

  paramTypeList: LabelValue[];
  unitsQuantity: LabelValue[];


  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  modelCacheList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;

  paramLabelCtrl: FormControl;
  paramTypeCtrl: FormControl;
  brandNameCtrl: FormControl;
  brandRefCtrl: FormControl;
  paramUnitCtrl: FormControl;
  paramRateCtrl: FormControl;
  paramCurrencyCtrl: FormControl;

  partSupplieForm: FormGroup;

  titleCard: string;

  entityCur: string;
  entityCurrency: string;
  wksEntity: WksEntityModel;
  isLoaded: boolean;
  paramRatesLocal: ParamRatesModel[];

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _ressourcesService: RessourcesService,
    private router: Router,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'partSupplieToUpdate': {
            this.partSupplieToUpdate = changes['partSupplieToUpdate'].currentValue;

            break;
          }
        } // end switch
      } // end if
    } 

    this.initData();
  }
  initData() {
    this.isRegistered = false;
    this.isLoaded = false;
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }
    if ((this.actionType === 'add') && (this.statutCall === 'modal')) {
      this.partSupplieToUpdate = undefined;
    }
    const listItem = this._wksCentralService.getTypesPartsSupplies();
    this.paramTypeList = [];
    for (const item of listItem) {
           const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.paramTypeList.push(itemCur);
    }
    const countryEntity  = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry);
    const isMetric = (countryEntity.measurementUnit === 'METRIC');
    const jsonUnits = this._wksCentralService.getUnitsQuantity() as MeasurementList[];
    let measurementLocal = 'METRIC';
    if (!isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    // tires
    this.unitsQuantity  = [];
    for (const measurLocal of jsonUnits) {
      if (measurLocal.measurement === measurementLocal) {
        const arrayUnits = [...measurLocal.list];
        for (const unitName of arrayUnits) {

          this.unitsQuantity.push({
            label: this._translate.getTranslate(unitName),
            value: unitName
          });
        }
        /*this.unitsQuantity.splice(0, 0, {
          label: '',
          value: ''
        });*/
        break;
      }
    }

    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }

    this.entityCur = this._userService.getUserLogged().entity;   
    this.titleCard = this._translate.instant(this.optionItem);
    this.paramLabelCtrl = this.fb.control('');
    this.paramTypeCtrl = this.fb.control('');
    this.brandNameCtrl = this.fb.control('');
    this.brandRefCtrl = this.fb.control('');
    this.paramUnitCtrl = this.fb.control('');
    this.paramRateCtrl = this.fb.control('');
    this.paramCurrencyCtrl = this.fb.control('');

    this.partSupplieForm = this.fb.group({
      paramLabel: this.paramLabelCtrl,
      paramType: this.paramTypeCtrl,
      brandName: this.brandNameCtrl,
      brandRef: this.brandRefCtrl,
      paramUnit: this.paramUnitCtrl,
      paramRate: this.paramRateCtrl,
      paramCurrency: this.paramCurrencyCtrl,
    },
    {
      updateOn: 'blur'
    });

    this.loadEntity();
  }
  loadEntity() {
    
    this._wksCentralService.getWksEntity(this.entityCur)
    .subscribe(
      data => {
        this.wksEntity = data.body;
        const otherData = JSON.parse(this.wksEntity.otherData);
        const currencyCur = otherData.entityCurrency;
        this.entityCurrency = currencyCur;
        this.partSupplieForm.controls.paramCurrency.setValue(currencyCur);
        // this.displayJobRef();
        this.isLoaded = true;
        this.loadBrands()
        .then (brandList => {
          if ((this.partSupplieToUpdate !== undefined) && (this.partSupplieToUpdate.brandName)) {
            let brandSelected: BrandWksModel;
            for (const curBrand of this.brandCacheList) {
              if (curBrand.brandName === this.partSupplieToUpdate.brandName) {
                brandSelected = curBrand;
                break;
              }
            }
          }
          this.fillForm();
          this.isLoaded = true;
        })
        .catch(error  => {
          console.log(error);
          this.isLoaded = true;
        });
      },
      () => {
        this.errorMessage('entity');
      }
    );
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    this.modelCacheList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    this.brandSelected = brandSelected;
  }

  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '') {
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];
    this.modelCacheList = [];
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this.saveBrandModel(newBrand);
  }
  saveBrandModel(newBrandModel: BrandWksModel) {
    this._wksCentralService.saveWksBrand( newBrandModel, 'create')
    .subscribe(
      () => {
        console.log('saveBrandModel OK');
      },
      () => {
        console.log('saveBrandModel KO');
      }
    );
  }
  fillForm() {
    if (this.partSupplieToUpdate === undefined) {
      return;
    }
    if (this.partSupplieToUpdate.paramRates) {
      this.paramRatesLocal = this.partSupplieToUpdate.paramRates;
    } else {
      this.paramRatesLocal = [];
    }
    
    
    this.partSupplieForm.controls.paramType.setValue(this.partSupplieToUpdate.paramType); 

    this.partSupplieForm.controls.brandName.setValue(this.partSupplieToUpdate.brandName);
    this.partSupplieForm.controls.brandRef.setValue(this.partSupplieToUpdate.brandRef);
    this.partSupplieForm.controls.paramUnit.setValue(this.partSupplieToUpdate.paramUnit);
    this.partSupplieForm.controls.paramRate.setValue(this.paramRatesLocal[0].paramRate ? this.paramRatesLocal[0].paramRate : '0.0');
    this.partSupplieForm.controls.paramCurrency.setValue(this.entityCurrency);

    if ((this.partSupplieToUpdate.langLabels !== undefined) && (this.partSupplieToUpdate.langLabels !== null)) {
      for (const labelCur of this.partSupplieToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
            this.partSupplieForm.controls.paramLabel.setValue(local.langLabel); 
          }
        } 
      }
    }
  }
  setLangLabelValue(_event: string, _row: string) {
    const value = _event.charAt(0).toUpperCase() + _event.slice(1);
    if (this.langLabelsList[_row].langValue === this._translate.getCurrentLang()) {
      this.partSupplieForm.controls.paramLabel.setValue(value);
    }
    this.langLabelsList[_row].langLabel = value;
  }

  registerPartSupplie() {
    const partSupplieValidation = this.fillModel();
    this._wksCentralService.saveParamPartSupplie(partSupplieValidation, this.entityCur)
    .subscribe(
      paramWorksRatesReturn => {
        this.partSupplieToUpdate = paramWorksRatesReturn.body;
        this.isRegistered = true;
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
          return;
        } else if  (this.statutCall === 'modal') {
            this._globalEventsManagerService.synchroEvents.next('closeRegisterActionModal');
            return;
        } else {
          this.router.navigate(['/jobHome']);
        }

      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  fillModel(): ParamPartsSuppliesModel {
    const paramLabelsI18nArray: ParamLabelsI18n[] = [];
    if ((this.partSupplieToUpdate !== undefined) 
      && (this.partSupplieToUpdate.langLabels !== undefined) 
      && (this.partSupplieToUpdate.langLabels !== null)) {
      /*
      for (const paramLabelsI18n of this.partSupplieToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = local.langLabel;
          }
        }
        paramLabelsI18nArray.push(paramLabelsI18n); 
      }*/
      let isExists = false;
      for (const labelCur of this.langLabelsList) {
        for (const paramLabelsI18n of this.partSupplieToUpdate.langLabels) {
          if (labelCur.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = labelCur.langLabel;
            paramLabelsI18nArray.push(paramLabelsI18n); 
            isExists = true;
          }
        }
        if (isExists) {
          continue;
        }
        const paramLabelsI18nTmp = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18nTmp);
      }
    } else {
      for (const labelCur of this.langLabelsList) {
        const paramLabelsI18n = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18n);
      }
    } 

    const partSupplieValidation: ParamPartsSuppliesModel = {
      id: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.id : undefined),
      stdEntity: this.entityCur,
      paramType: this.partSupplieForm.controls.paramType.value,
      paramLabel: this.partSupplieForm.controls.paramLabel.value,
      brandName: this.partSupplieForm.controls.brandName.value,
      brandRef: this.partSupplieForm.controls.brandRef.value,
      paramUnit: this.partSupplieForm.controls.paramUnit.value,
      paramRate: this.partSupplieForm.controls.paramRate.value,
      paramCurrency: this.partSupplieForm.controls.paramCurrency.value,
      paramScaleName: 'PUBLIC',
      otherData: (this.partSupplieToUpdate !== undefined && this.partSupplieToUpdate.otherData !== undefined ? this.partSupplieToUpdate.otherData : ''),
      stdCreation: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.stdCreation : undefined),
      langLabels: paramLabelsI18nArray,
      userLang: this._userService.getUserLogged().userlang,
    };

    const paramRatesModel: ParamRatesModel = {
      id : (this.paramRatesLocal && this.paramRatesLocal[0] && this.paramRatesLocal[0].id ? this.paramRatesLocal[0].id : undefined),
      stdEntity: this.entityCur,
      paramId: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.id : undefined),
      paramScaleName: 'PUBLIC',
      paramRate: partSupplieValidation.paramRate,
      paramCurrency: this.entityCurrency,
      otherData: ''    
    };
    const paramRatesLocal: ParamRatesModel[] = [];
    paramRatesLocal.push(paramRatesModel);
    partSupplieValidation.paramRates = paramRatesLocal;

    return partSupplieValidation;
  }
 
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording action ref';
      
    }
    if (_actionCur === 'entity') {
      messageBox = 'error loading';
      titleBox = 'loading entity ';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doClose() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    this.router.navigate(['/jobHome']);
  }
  doCancel() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
      return;
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
}
