<div  >
<!-- JLG - 2019-10-09 - WARNING problème data-action-type avec Firefox nécessité doubler les lignes -->
  <button *ngIf="detailTypeZoom && detailIsOpen" type="button" class="btn btn-outline-primary btn-circle m-1" data-toggle="tooltip" 
              data-placement="left" title="{{ 'detailZoom' | translate }}" data-action-type="detail-close">
      <i  data-action-type="detail-close" class="fa fa-angle-up" >Z</i>
  </button>

  <button *ngIf="detailTypeZoom && !detailIsOpen" type="button" class="btn btn-outline-primary btn-circle m-1" data-toggle="tooltip" 
          data-placement="left" title="{{ 'detailZoom' | translate }}" data-action-type="detail-open">
    <i data-action-type="detail-open" class="fa fa-angle-down" >Z</i>
  </button>
<!--
  <button *ngIf="detailSubReport && !isDocReport"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
              data-placement="left" title="{{ subReportName }}" data-action-type="{{subReportStatut}}">
      <i *ngIf="subReportIsOpen" data-action-type="subreport-close" class="fa fa-angle-up" >{{subReportAction}}</i>
      <i *ngIf="!subReportIsOpen" data-action-type="subreport-open" class="fa fa-angle-down" >{{subReportAction}}</i>
  </button>
-->
  <button *ngIf="detailSubReport && !isDocReport && subReportIsOpen"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
          data-placement="left" title="{{ subReportName }}" data-action-type="subreport-close">
    <i  data-action-type="subreport-close" class="fa fa-angle-up" >{{subReportAction}}</i>
  </button>
  <button *ngIf="detailSubReport && !isDocReport && !subReportIsOpen"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
          data-placement="left" title="{{ subReportName }}" data-action-type="subreport-open">
    <i  data-action-type="subreport-open" class="fa fa-angle-down" >{{subReportAction}}</i>
  </button>
<!--
  <button *ngIf="detailSubReport && isDocReport"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
              data-placement="left" title="{{ subReportName }}" data-action-type="{{pdfStatut}}" >
      <i *ngIf="docReportIsOpen" data-action-type="pdf-close" class="fa fa-angle-up" >{{subReportAction}}</i>
      <i *ngIf="!docReportIsOpen" data-action-type="pdf-open" class="fa fa-angle-down" >{{subReportAction}}</i>
  </button>
-->
  <button *ngIf="detailSubReport && isDocReport && docReportIsOpen"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
            data-placement="left" title="{{ subReportName }}" data-action-type="pdf-close" >
    <i  data-action-type="pdf-close" class="fa fa-angle-up" >{{subReportAction}}</i>
  </button>

  <button *ngIf="detailSubReport && isDocReport && !docReportIsOpen"  type="button" class="btn btn-outline-info btn-report m-1"  data-toggle="tooltip" 
            data-placement="left" title="{{ subReportName }}" data-action-type="pdf-open" >
    <i data-action-type="pdf-open" class="fa fa-angle-down" >{{subReportAction}}</i>
  </button>

</div>
