<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid " style="margin-top: 75px; width: 100%;">
    <div class="flex-row row" style="margin-left: 5px;margin-right: 5px;">
        <div class="col-12 d-flex justify-content-center"  >
            <h6 style="padding-top: 10px;"> {{ 'reloadConfig' | translate }}</h6>
        </div>
    </div>
    <div class="flex-row row" style="margin-left: 5px;margin-right: 5px;">
        <div class="col-2 d-flex justify-content-end" >
            <button style="margin-top: 5px;" class="mat-raised-button buttonTheme" 
                style="margin-bottom: 10px;" (click)="reloadWebdav()" >{{'reloadWebdav' | translate}}
            </button>
        </div>
    </div>
</div>
