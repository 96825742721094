import { Component, OnInit } from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-datetime-renderer',
  template: `<div >{{currentValue}}</div>`,
})
export class DatetimeRendererComponent implements OnInit, ICellRendererAngularComp  {

  params: ICellRendererParams;
  currentValue: any;

  constructor(private  _translate: TranslateService) { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.currentValue = params.value;
    const currentLang = params.colDef.cellRendererParams.currentLang;
    const curFmt = params.colDef.cellRendererParams.colFmtString;
    let localFmt: any;
    switch (curFmt) {
      case 'fmtDate': {
        localFmt = this._translate.getLocalFmt('dateFormat');
        this.currentValue = CommonMethods.dateToString(params.value, 'date', localFmt);
        break;
      }
      case 'fmtHour': {
        localFmt = this._translate.getLocalFmt('hourFormat');
        this.currentValue = CommonMethods.dateToString(params.value, 'hour', localFmt);
        break;
      }
      case 'fmtTime': {
        localFmt = this._translate.getLocalFmt('timeFormat');
        this.currentValue = CommonMethods.dateToString(params.value, 'unixM', localFmt);
        break;
      }
      default : {
        localFmt = this._translate.getLocalFmt('timeFormat');
        this.currentValue = CommonMethods.dateToString(params.value, 'unixM', localFmt);
        break;
      }

    }
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
}
