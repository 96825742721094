<div id="rowPinned" #refElement class="mat-elevation-z8 stdTheme " style="width: 100%;" >

  <mat-card class="subreport-card stdTheme" layout="column" flex >
     <mat-card-header>
       <!-- <mat-card-title  >
          <span #titleCard>{{ registerTitle | translate }}</span>
        </mat-card-title> -->

    </mat-card-header>

    <mat-card-content  >

      <div *ngIf="isLoaded"  [style.overflow]="'auto'" [style.height.px]="'460'" [style.width.px]="'1050'" >
          <!--<ul class='fa-ul'> -->
        <div class="container-fluid stdTheme" style="margin-top: 0px; margin-bottom: 0px;">
          <div class="row justify-content-around">
            
            <div class="col-2 stdTheme">
              <label style="margin-right: 20px; margin-top: 10px"> {{ 'zoomPresent' | translate }}</label>

              <input type="checkbox" class="inputTheme" #zoomPresent #input  [checked]="listSubReports.zoomPresent" [(value)]="listSubReports.zoomPresent" (change)="setValueZoom('zoomPresent',zoomPresent.checked)">
            </div>
            
            <div class="col-2">
                <mat-form-field>
                  <mat-label>{{'dataRecall' | translate }}</mat-label>
                  <mat-select  #listColZoom multiple  [disabled]="!listSubReports.zoomPresent" [(value)]="listColZoomCur" (selectionChange)="setListColRecall(listColZoom.value,-1)">
                    <mat-option class="selectOptionsTheme" *ngFor="let colCur of listColRecall"  [value]="colCur.colName">{{ colCur.colName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
              <label style="margin-left: 10px; margin-right: 10px" > {{ 'zoomCols' | translate }}</label>

              <button type="button" class="btn btn-default" mat-icon-button [disabled]="!listSubReports.zoomPresent" (click)="counter('zoomCols',-1)">
                <span class="fa fa-minus"></span>
              </button>

              <span class="form-group" >
                  <input class="input-sm" matInput type="number" #zoomCols  #input readonly 
                        [(value)]="listSubReports.zoomCols" 
                        style="width: 40px;  text-align: center; padding:0px; margin: 0px"
                        (blur)="listSubReports.zoomCols = zoomCols.value">
              </span>

              <button type="button" class="btn btn-default" mat-icon-button [disabled]="!listSubReports.zoomPresent" (click)="counter('zoomCols',1)">
                  <span class="fa fa-plus"></span>
              </button>
            </div>
            <div class="col-4">
              <label class="pinnedRow" style="margin-right: 10px" > {{ 'zoomRows' | translate }}</label>

              <button type="button" class="btn btn-default" mat-icon-button [disabled]="!listSubReports.zoomPresent" (click)="counter('zoomRows',-1)">
                <span class="fa fa-minus"></span>
              </button>

              <span class="form-group" >
                  <input class="input-sm" matInput type="number" #zoomRows  #input readonly 
                        [(value)]="listSubReports.zoomRows" 
                        style="width: 40px;  text-align: center; padding:0px; margin: 0px"
                        (blur)="listSubReports.zoomRows = zoomRows.value">
              </span>
 
              <button type="button" class="btn btn-default" mat-icon-button [disabled]="!listSubReports.zoomPresent" (click)="counter('zoomRows',1)">
                  <span class="fa fa-plus"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex-row row">
          <button  mat-icon-button  (click)="addSubReport()">
            <span class="fa fa-plus-square" 
                style="margin-left: 20px; margin-top: 0px; margin-bottom: 0px; font-size: 20px" ></span>
          </button>
        </div>
        <ng-template ngFor let-iRow="index" let c = "count" let-subReport [ngForOf]="listSubReports.subReport">
          <div class="container-fluid subReport pinnedRow" style="margin-bottom: 15px; margin-top: 0px; "  >
              <!-- <div id="pinnedRow_{{i}}" #pinnedRow_{{i}} class="row"></div>--> 
            <div id="subReport_{{iRow}}" #subReport_{{iRow}} class="row rowLine" style="line-height: 25px" >
              <div class="col-1" style="margin-top: 10px;">
   
                  <button mat-icon-button (click)="removeSubReport(iRow) ">
                    <span class="fa fa-times" style="font-size: 15px" ></span>
                  </button>      
              </div>

              <div class="col-10" style="margin-top: 10px;">
                  <span class="pinnedRow" style="margin: 0px;"><h6>{{ subReport.reportName }}</h6> </span>               
              </div>
              <div class="col-1" style="margin-top: 10px;">
                <button mat-icon-button
                    (click)="subReport.isCollapsed = !subReport.isCollapsed"
                    [attr.aria-expanded]="!subReport.isCollapsed" aria-controls="collapseExample">
                    <span *ngIf="subReport.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                    <span *ngIf="!subReport.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                </button> 
              </div>
              <div class="col-5" [ngbCollapse]="subReport.isCollapsed">
                <div class="row">
                  <div class="col">
                    <label class="pinnedRow"> {{ 'reportName' | translate }}</label>
                  </div>
                  <div class="col">
                    <select class="form-control form-control-sm stdTheme" id="reportName" [(value)]="subReport.reportName" #reportName 
                        style="width:auto; font-size:small" 
                        (change)="setValueArraySubReport('reportName',reportName.value,iRow)"
                        (blur)="subReport.reportName = reportName.value" >
                      <option class="selectOptionsTheme" *ngFor="let reportCur of listReports"  
                                    [selected]="reportCur.reportLabel === subReport.reportName"
                                    [(value)]="reportCur.reportLabel">{{ reportCur.reportLabel}}</option>
                    </select>
                  </div>
   
                </div>
                <div class="row">
                  <div class="col">
                    <label class="pinnedRow"> {{ 'reportInitialAction' | translate }}</label>
                  </div>
                  <div class="col">
                    <input type="text" matInput #reportInitialAction #input  minlength="1" maxlength="3" class="text-uppercase"
                        [(value)]="subReport.reportInitialAction" (change)="setValueArraySubReport('reportInitialAction',reportInitialAction.value,iRow)">
                  </div>
                </div>
                
                <div class="row">
                  <div class="col">
                    <label class="pinnedRow"> {{ 'limitationCol' | translate }}</label>
                  </div>
                  <div class="col">
                    <input type="checkbox" class="inputTheme" #limitationCol #input  [checked]="subReport.limitationCol"
                                [disabled]="!subReport.isTargetReport" (change)="setListCol(limitationCol.checked,iRow)">
                  </div>
                </div>
                <div class="row">
                  <div class="col" style="margin-top: 10px">
                    <label class="pinnedRow">{{'dataRecall' | translate }}</label>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <!--<mat-label>{{'dataRecall' | translate }}</mat-label>-->
                      <mat-select class="stdTheme" #listColRecallCur multiple [(value)]="subReport.listColRecall" (selectionChange)="setListColRecall(listColRecallCur.value,iRow)">
                        <mat-option class="selectOptionsTheme" *ngFor="let colCur of listColRecall"  [value]="colCur.colName">{{ colCur.colName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-7" [ngbCollapse]="subReport.isCollapsed" >

                <table class="table table-striped stdTheme" >
     
                  <tbody style="overflow-y: auto;">
                    <tr *ngFor="let foreignKey of subReport.foreignKeys; let iCol = index" 
                            class="StdTheme" style="height: 10px; line-height: 10px; padding:0px;margin-top: 0px;">
                        <td style="width: 2%">  
                          <button mat-icon-button (click)="removeForeignKey(iRow,iCol)">
                            <span class="fa fa-minus-square" style="font-size: 15px" ></span>
                          </button>                    
                        </td>
                        <td style="width: 40%" >
                          <select class="form-control form-control-sm stdTheme " id="colOrig" [(value)]="foreignKey.colOrig" #colOrig 
                                style="width:auto; font-size:small; padding:0px; margin: 0px" 
                                placeholder="{{'ChoiceColumn' | translate}}"
                                (blur)="foreignKey.colOrig = colOrig.value">
                            <option class="selectOptionsTheme" *ngFor="let colCur of listColOrig" [(value)]="colCur.colName">{{ colCur.colName}}</option>
                          </select>
                        </td>
                        <td style="width: 16%">
                          <select class="form-control form-control-sm stdTheme" id="operande" [(value)]="foreignKey.operande" #operande 
                                style="width:auto; font-size:small; padding:0px; margin: 0px" 
                                placeholder="{{'ChoiceOper' | translate}}"
                                (blur)="foreignKey.operande = operande.value">
                            <option class="selectOptionsTheme" *ngFor="let operande of operandeList" [(value)]="operande">{{ operande | translate}}</option>
                          </select>
                        </td>
                        <td style="width: 40%" >
                          <select class="form-control form-control-sm stdTheme" id="colTarget" [(value)]="foreignKey.colTarget" #colTarget 
                                style="width:auto; font-size:small; padding:0px; margin: 0px" 
                                placeholder="{{'ChoiceColumn' | translate}}"
                                (blur)="foreignKey.colTarget = colTarget.value">
                            <option class="selectOptionsTheme" *ngFor="let colCur of subListColTarget[iRow]" [(value)]="colCur.colName">{{ colCur.colName}}</option>
                          </select>
                        </td>
                        <td style="width: 2%">            
                          <button mat-icon-button (click)="addForeignKey(iRow,iCol)">
                            <span class="fa fa-plus-square" style="font-size: 15px" ></span>
                          </button>
                        </td>
                    </tr>
                  </tbody>
                  
                </table>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div>        
        <div class="container-fluid d-flex justify-content-between" style="margin-top: 10px;">
          <button mat-raised-button #buttonSubmit type="submit" (click)="register()" [disabled]="!doValidation ">
            <mat-icon>create</mat-icon>
            {{ 'submit' | translate }}
          </button>
          <button mat-raised-button #buttonSubmit type="reset" (click)="doCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'cancel' | translate }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>