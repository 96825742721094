import { Component, ViewEncapsulation, OnInit, Input, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
// import WKS_PARAMS from '../../../../../../../assets/data/wks_params.json';
import { TranslateService } from '../../../../../../services/translate.service';
import { ParentParam, WksEquipmentModel } from '../../../../models/wks-equipment.model';
import { WksCentralService } from '../../../../services/wks-central.service';
import { IhmOptions } from '../../../../models/wks-param.model';

@Component({
  selector: 'mdi-equipment-accordion',
  templateUrl: './equipment-accordion.component.html',
  styleUrls: ['./equipment-accordion.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentAccordionComponent implements OnInit {

  @Input() parentParam: ParentParam;
  @Input() ihmOptions: IhmOptions;
  @Input() okInputHours: boolean;

  private readonly onDestroy = new Subject<void>();

  dataList: any  = [];
  panelOpenState = false;
  constructor(private _translate: TranslateService, private _wksCentralService: WksCentralService) { }

  ngOnInit() {
    //  const wksParamsStr = JSON.stringify(WKS_PARAMS);
    // const wksParamsJson = JSON.parse(wksParamsStr);

    const listItem = this._wksCentralService.getComponentsList();
    let idxItem = 0;
    for (const item of listItem) {
      /*
           const itemCur = {
        'parentName': this._translate.getTranslate(item),
        'parentTech': item,
        'xpandStatus' : false,
        'childProperties': [
          { 'propertyName': 'Property ' + this._translate.getTranslate(item),
            'propertyContent': 'Content property ' + this._translate.getTranslate(item),
            'xpandStatus' : false,
            'actionType': 'add',
          },
        ]
      };
      */
      const itemCur = {
        'parentName': this._translate.getTranslate(item),
        'parentTech': item,
        'hoursInput': this._wksCentralService.getComponentsHours()[idxItem],
        'xpandStatus' : false,
        'childProperties': [ ]
      };
      this.dataList.push(itemCur);
      idxItem++;
    }
    this.dataList.sort((obj1: any, obj2: any) => {
      return obj1.parentName > obj2.parentName ? 1 : -1;
    });
    if ((this.parentParam !== undefined) && (this.parentParam.statutCall === 'loadEquipment')) {
      this.getListBoatEquipment();
    }
  }
  addNewChild(parentTech: string, idxParent: number, childList: any[]) {
    const nbList = childList.length;
    this.dataList[idxParent].xpandStatus = true;
    childList.push(
        { 'propertyName': '',
          'propertyContent': '',
          'xpandStatus' : true,
          'actionType': 'add',
        }
    );
    
  }
  removeChild(idx: number, childList: any[]) {
    childList.splice(idx, 1);
  }
  editItem($event: any, row: number, childList: any[] ) {
    const child = childList[row];
    if (child.actionType === 'add') {
      return;
    }
    child.xpandStatus = true;
    child.actionType = 'update';
  }
  saveItem(itemReturn: any, row: number, childList: any[]) {
    let typeReturn: any = null;
    const child = childList[row];
    if (typeof(itemReturn) === 'string') {
      typeReturn = itemReturn.toString();
    } else {
      typeReturn = typeof(itemReturn);
    }
    if (typeReturn === 'collapseNode') {
      if (child.propertyName === '') {
        this.removeChild(row, childList);
      }
      child.xpandStatus = false;
    } else if ( (child.actionType === 'update') && (typeReturn === 'removeNode')) {
      this.removeChild(row, childList);
    } else if ((child.actionType === 'update') && ( typeReturn.startsWith('collapseNode;') )) { // return for close without modif
      child.xpandStatus = false;
      child.actionType = '';
    } else if ((child.actionType === 'add')  || (child.actionType === 'update')) {
      child.propertyName = itemReturn.equipDesign;
      child.propertyContent = itemReturn as WksEquipmentModel;
      child.xpandStatus = false;
      child.actionType = '';
    }
   
  }
  getListBoatEquipment() {
    this._wksCentralService.getWksEquipmentListBoat(this.parentParam.entity, this.parentParam.equipPartOf, undefined)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.loadBoatEquipement(data.body);
       },
      err => {
        // console.log(err);

      }
    );
  }
  loadBoatEquipement(_wksEquipmentList: WksEquipmentModel[]) {
    for (const equipCur of _wksEquipmentList) {
      let childList = [];
      for (const childItem of this.dataList) {
        if (childItem.parentTech ===  equipCur.equipType) {
          childList = childItem.childProperties;
          break;
        }
      }
      childList.push(
        { 'propertyName': equipCur.equipDesign,
          'propertyContent': equipCur,
          'xpandStatus' : false,
          'actionType': '',
        });
      childList.sort((obj1: any, obj2: any) => {
        return obj1.propertyName > obj2.propertyName ? 1 : -1;
      });
    }
  }
}
