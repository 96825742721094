// http://jasonwatmore.com/post/2017/06/25/angular-2-4-alert-toaster-notifications

import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { TranslateService } from '../../../../services/translate.service';

import { Alert, AlertType } from '../../../../models/common.model';
@Component({
  selector: 'mdi-alert-common',
  templateUrl: './alert-common.component.html',
  styleUrls: ['./alert-common.component.css']
})
export class AlertCommonComponent implements OnInit {

  alerts: Alert[] = [];

  @Input() messageInfo: string;
  @Input() data1: string;
  @Input() data2: string;
  @Input() messageType: string;
  @Input() typeDisplay: string;
  @Input() alertType: string;
  @Input() actionCur: string;
  @Input() displayMessages: any[];
  constructor(private _translate: TranslateService) { }

  actionDisplay: string;

  ngOnInit() {
    const currentAlert = new Alert();
    currentAlert.type = this.getAlertType(this.messageType);
    currentAlert.message = this.messageInfo;
    currentAlert.data1 = this.data1;
    currentAlert.data2 = this.data2;
    currentAlert.alertType = this.alertType;
    this.alerts.push(currentAlert);

    if (this.actionCur === undefined) {
        this.actionDisplay = 'normalMessage';
    } else  {
        this.actionDisplay = this.actionCur.slice();
    }
    if (this.typeDisplay !== undefined && this.typeDisplay === 'HTML') {
        this.actionDisplay = 'htmlMessage';
    }
  }
  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
}

getAlertType(messageType: string) {

    switch (messageType) {
        case 'SUCCESS':
            return AlertType.Success;
        case 'ERROR':
            return AlertType.Error;
        case 'INFO':
            return AlertType.Info;
        case 'IMPORTANT':
            return AlertType.Important;
        case 'WARNING':
            return AlertType.Warning;
    }
}
cssClass(alert: Alert) {
    if (!alert) {
        return;
    }

    // return css class based on alert type
    switch (alert.type) {
        case AlertType.Success:
            return 'alert alert-success';
        case AlertType.Important:
            return 'alert alert-primary';
        case AlertType.Error:
            return 'alert alert-danger';
        case AlertType.Info:
            return 'alert alert-info';
        case AlertType.Warning:
            return 'alert alert-warning';
    }
  }
}
