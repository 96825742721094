
<div  >
  <div class="row" style="width: 1200px;" >
      <nav class="navbar navbar-default" style="width: 1200px;">
          <div class="container-fluid">
            <div class="navbar-header">
              <a class="navbar-brand" href="#">{{reportToExecute.reportLabel}}</a>
            </div>
      
              <button class="btn  navbar-btn mat-icon-button" (click)="setEditOption();" data-toggle="tooltip" title="{{'editWidgets' | translate}}">
                  <i class="far fa-edit"></i>
              </button>
              <select *ngIf="isEditOpen" class="form-control nav-item mat-icon-button" id="widgetSelect"  #widgetSelect 
                        style="width: auto;" (change)="addWidgetSelect(widgetSelect.value)">
                <option *ngFor="let widgetCur of widgetsList"  >{{ widgetCur.widgetName}}</option>
              </select>
              <button *ngIf="isEditOpen" class="btn  navbar-btn mat-icon-button" (click)="setDisplayTimeOption();" data-toggle="tooltip" title="{{'datetime' | translate}}">
                  <i class="far fa-clock"></i>
              </button>
              <button *ngIf="isEditOpen && isChangedDashBoard" class="btn  navbar-btn mat-icon-button" (click)="saveUserEnvt();" data-toggle="tooltip" title="{{'saveWidgets' | translate}}">
                  <i class="fas fa-save"></i>
              </button>
        
          </div>
        </nav>

  </div>
  <div class="row" >

    <ngx-widget-grid id="widget-grid"  [rows]="rowsGrid" [columns]="colsGrid" 
                      [highlightNextPosition]="isEditOpen"
                      [showGrid]="isEditOpen" 
                      (widgetPositionChange)="onWidgetChange($event)"
                      (gridFull)="onGridFull($event)">
    
      <ngx-widget class="ngxwidget" *ngFor="let widgetCur of widgetsDisplay;let $index = index;"
                [position]="widgetCur"
                [movable]="isEditOpen" 
                [swapOnMove]="isEditOpen"
                [resizable]="isEditOpen">
        <div style="height:100%;width:100%; display:flex; opacity: 1">
          <div style="height:100%;width:100%; opacity: 1">
            <button *ngIf="isEditOpen" type="button" class="btn trashbtn mat-icon-button"  (click)="removeWidget($index);"> 
              <i class="fas fa-trash-alt" ></i>
            </button>
            <mdi-barchart *ngIf="widgetCur.chartType == 'bar'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-barchart>
            <mdi-doughnut-chart *ngIf="widgetCur.chartType == 'doughnut'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-doughnut-chart>
            <mdi-linechart *ngIf="widgetCur.chartType == 'line'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-linechart>
            <mdi-pie-chart *ngIf="widgetCur.chartType == 'pie'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-pie-chart>
            <mdi-radar-chart *ngIf="widgetCur.chartType == 'radar'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-radar-chart>
            <mdi-polar-area-chart *ngIf="widgetCur.chartType == 'polarArea'" [widgetData]="widgetCur" [displayTime]="displayTime"></mdi-polar-area-chart>
          </div>
        </div>
      </ngx-widget>
    </ngx-widget-grid>
  </div>
</div>
