<div class="container-fluid" *ngIf="mechanicUsersLoaded">
    <div class="flex-row row stdTheme" style="padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col-8 "></div>
        <div class="col-3 ">
          <mat-form-field  class="mdiinput form-group stdTheme" appearance="standard" style="margin-top: -35px; margin-bottom: 25px; height: 50px!important;">
            <mat-label>{{ 'technician' | translate }}</mat-label>
            <mat-select class="stdTheme"  panelClass="panelSelectContent" #userMechanic  
                      disableOptionCentering required
                      id="userMechanic"
                      required  
                      [value]="actorSelected !== undefined ? actorSelected.username : undefined"
                      (selectionChange)="onChangeMechanic($event)" >
              <mat-option class="stdTheme stdSelect" *ngFor="let userMechanic of mechanicUsers" 
                      class="selectOptionsTheme"  [value]="userMechanic.username" >
                            {{userMechanic.username }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>
    <div class="flex-row row  stdTheme" style="margin-left: 0px; margin-right: 0px;width: 100%;">
        <div class="spinner-container  stdTheme" *ngIf="doLoadCalendar && !planningLoaded" fxLayout="row" fxLayoutAlign="space-around center">
            <!--<mat-spinner diameter="50" strokeWidth="5"></mat-spinner>-->
            <span>Loading planning ...</span>
        </div>
        <mdi-calendar *ngIf="planningLoaded" style="padding: 0px; margin-left: 0px; margin-right: 0px;width: 100%;"
                [applicationCall]="'appointmentWork'"
                [localelang]="localelang"
                [localeDateFmt]="localeDateFmt"
                [dateTimeFormat]="dateTimeFormat"
                [events]="eventsList"
                [calendarEvents]="wksEntityParams.calendarEvents"
                [workingTime]="workingTime"
                [modalMode]="isModalMode"
                (eventClicked)="eventClicked($event)"
                (displayRequest)="displayRequest($event)"
          ></mdi-calendar>

    </div>
    <div *ngIf="isModalMode" class="flex-row row  stdTheme" style="margin-left: 0px; margin-right: 0px;width: 100%;">
      <div fxLayoutAlign="end" style="padding-inline-end: 10px ;">
        <button class="mat-raised-button" (click)="close()">{{ 'close' | translate }}</button>
      </div>
    </div>
</div>
