import { Component, OnInit, AfterContentInit, Input, ViewChild, Renderer2, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder,  FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { environment } from '../../../../../../../environments/environment';
import { UserService } from '../../../../../../services/user.service';
import { JobDataService } from '../../../../services/job-data.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { ReportInt, ReportModel, ColLabelRow, RequestInt, ColReportInt, DataLabelInt, KeyValue, SubReports, Widgets } from '../../../../models/job-data.model';


@Component({
  selector: 'mdi-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {

  @Input() statutCall: any;
  @Input() reportToUpdate: ReportInt;
  
  private readonly onDestroy = new Subject<void>();

  @ViewChild('buttonSubmit', {static: true}) buttonSubmit: any;
  @ViewChild('refElement', {static: true}) refElement: any;
  @ViewChild('titleCard', {static: true}) titleCard: any;

  registrationFailed: boolean;

  reportLabelCtrl: FormControl;
  reportSampleCtrl: FormControl;
  reportPaginationCtrl: FormControl;
  reportDocumentCtrl: FormControl;
  reportDashboardCtrl: FormControl;
  requestRefSelectCtrl: FormControl;
  reportStatutCtrl: FormControl;
  menuParentSelectCtrl: FormControl;
  menuRankSelectCtrl: FormControl;
  accessEntitiesCtrl: FormControl;
  accessGrpCtrl: FormControl;

  reportForm: FormGroup;
  registerTitle = 'Register';
  messageTranslate: string;

  isReportFound: boolean;
  isUpdateStatut: boolean;
  reportCard: ReportInt;

  doTestreport = false;
  isTestreportOK = true;
  reportVersion: any;
  reportColumns: string;

  statutsReportList: string[];
  requestsList: any[];
  entitiesList: string[];
  grpsList: string[];

  parentsList: DataLabelInt[];
  ranksList: string[];

  columnNameList: any;
  isNewRequestRef: boolean;
  isNewListCol: boolean;
  isValidForm: boolean;
  isAlreadyValidate = false;
  
  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }
  ngAfterContentInit() {

  } 
  ngOnDestroy() {
    // this.onDestroy.next();
    
  }
  ngOnChanges(changes: SimpleChanges) {
    const statutCallValue = changes['statutCall'];
    const userToUpdateValue = changes['reportToUpdate'];

    this.initData();
  }
  focusout(event: any) {
    console.log('onFocusOut ');
  }
  numericOnly(event: any): boolean {    
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);
    return result;
  }
  initData() {
    this._jobDataService.setColReportService();
    this.ranksList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
    this.isValidForm = false;
    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createReport';
    }

    this.statutsReportList = CommonMethods.listToArray(`${environment.statutsReport}`, ',');
    this.reportLabelCtrl = this.fb.control('', Validators.required);
    this.reportPaginationCtrl = this.fb.control('');
    this.reportStatutCtrl = this.fb.control('');
    this.reportSampleCtrl = this.fb.control('');
    this.menuParentSelectCtrl = this.fb.control('');
    this.menuRankSelectCtrl = this.fb.control('');
    this.requestRefSelectCtrl = this.fb.control('');
    this.accessEntitiesCtrl = this.fb.control('', Validators.required);
    this.accessGrpCtrl = this.fb.control('', Validators.required);

    this.reportForm = this.fb.group({
      reportLabel: this.reportLabelCtrl,
      reportVersion: this.fb.control(''),
      reportStatut: this.reportStatutCtrl,   
      reportSample: this.reportSampleCtrl, 
      menuParentSelect: this.menuParentSelectCtrl,
      menuRankSelect: this.menuRankSelectCtrl,
      requestRefSelect: this.requestRefSelectCtrl,   
      accessEntities: this.accessEntitiesCtrl,
      accessGrp: this.accessGrpCtrl,
      reportPagination: this.reportPaginationCtrl,
      reportDocument: this.reportDocumentCtrl,
      reportDashboard: this.reportDashboardCtrl,
      },
      {
        updateOn: 'blur'
      },
    );

    this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
    if (this.statutCall === 'createReport') {
      this.registerTitle = 'Creation';
      this.reportForm.controls.reportVersion.setValue('1');
    }
    this.isUpdateStatut = false;
    
    if (this.statutCall === 'updateReport') {
      this.isUpdateStatut = true;
      this.registerTitle = 'Update';

      this.setValuesToUpdate();
    }

    this.getListEntities();

    this.isReportFound = false;

    this.reportForm.controls['reportLabel'].valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe (
        () => {
          this.validReportLabel();

        }
    );
    
    this.reportForm.controls['accessEntities'].valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe (
        (val) => {
          this.loadListGrps(val);
          this.getListParents(val);
          // console.log(this.parentsList);
        }
    );
    this.getListRequests();

    this._jobDataService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
     
      if (valueEvent === 'doValidation') {
        this.register();
      }
      if (valueEvent === 'doCancel') {
        this.doCancel();
      }
    }) ;
    this.reportForm.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
      if (this.isValidForm !== this.reportForm.valid) {
        this.isValidForm = this.reportForm.valid;
        if (this.isValidForm) { this._jobDataService.changeStatutEvent('validOK'); }
        if ((!this.isValidForm) && (!this.isAlreadyValidate)) { this._jobDataService.changeStatutEvent('validKO'); }
      }
      // console.log(this.reportForm.valid);
    });
  }
  getListEntities() {
    this.entitiesList = [];
    if (this._userService.getUserLogged().extendedentities.includes('ALL')) {
      this._userService.getEntities()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const listEntities = data.body;
          // this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
          this.entitiesList.push('ALL');
          const curEntities = CommonMethods.jsonPropToArray(listEntities, 'entity');
          for (const curEntity of curEntities) {
            this.entitiesList.push(curEntity);
            }
        }
      );
    } else {
      const curEntities = this._userService.getUserLogged().extendedentities.split(',');
      for (const curEntity of curEntities) {
        this.entitiesList.push(curEntity);
        }
    }
  }
  loadListGrps(_val: any) {

    if (_val === null ) { return; }
    this.grpsList = [];
    if (('ALL' === _val[0]) && (_val.length > 1)) {
      this.reportForm.controls.accessEntities.setValue(['ALL']);
      this.reportForm.controls.accessGrp.setValue(['ALL']);
    }
    if ('ALL' === _val[0]) {
      this.grpsList.push('ALL');
    } else {
      // grps of entity
      this.doGetListGrps(_val[0]);
    }
  }
  doGetListGrps(_entity: string) {
    // this.getListGrps(_entity);
    this.getListGrps(_entity)
    .then ((grpsList) => {

    })
    .then((error) => {

    });
  }
  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(
          data => {
            const usergroupLists = data.body;
            this.grpsList = CommonMethods.jsonPropToArray(usergroupLists, 'usergroup');
              resolve(usergroupLists);
          }, err => {
            this.grpsList = [];
            reject(err);
          }
        );
      });
  }
  loadGrps(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value, event.source.selected);
    }
  }
  getListParents(_val: any) {
    this.parentsList = [];
    const itemHome = {
      menuName: 'HomeMenu',
      menuLabel: this._translate.getTranslate('HomeMenu'),
      menuToolTip: this._translate.getTranslate('HomeMenu'),
      userLang: this._userService.getUserLogged().userlang,
    };

    const itemEmpty = {
      menuName: '',
      menuLabel: '',
      menuToolTip: '',
      userLang: this._userService.getUserLogged().userlang,
    };
   
    if (_val === null) {
      this.parentsList = [];
      this.parentsList.splice(0, 0, itemHome);
      this.parentsList.splice(0, 0, itemEmpty);
      return;
    }
    let entity = _val[0];
    if ('ALL' === _val[0]) {
      entity = this.entitiesList[0];
    }
    this._jobDataService.getMenu(entity, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const menuCur = data.body[0];
        // const jsonObject = JSON.parse(menuCur.entitymenu);
        // this.parentsList = jsonObject.menuparents;
   
        const jsonMenu = JSON.parse(menuCur.otherData);
        const menuItems: ColLabelRow [] = jsonMenu.labels;
        for (const menuItem of menuItems) {
          const itemCur = {
            menuName: menuItem.colName,
            menuLabel: menuItem.colHeader,
            menuToolTip: menuItem.colToolTip,
            userLang: this._userService.getUserLogged().userlang,
          };
          this.parentsList.push(itemCur);
        }

        this.parentsList.splice(0, 0, itemHome);
        this.parentsList.splice(0, 0, itemEmpty);

      }, () => {
        this.parentsList = [];

        this.parentsList.splice(0, 0, itemHome);
        this.parentsList.splice(0, 0, itemEmpty);
        
      }
    );
  }
  getListRequests() {
    this.requestsList = [];
    this._jobDataService.getListRequests('onlyDefault')
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.initSelectRequest(data.body);
      }
    );
  }
  doCancel() {
    this.reportForm.reset();
    if (this.statutCall) {
      this._jobDataService.onRegisterValid.emit('cancelRegister');
      
    } else {
      this.router.navigate(['/']);
    }
  }
  validReportLabel() {
    const reportLabel = this.reportForm.controls['reportLabel'].value;
    if ((reportLabel) && (reportLabel.length > 3)) {
      if ((this.statutCall === 'createReport')
       || ((this.statutCall === 'updateReport')
              && (reportLabel !== this.reportToUpdate.reportLabel))) {
        this.ctrlReportLabel(reportLabel);
      }
    }
  }
  ctrlReportLabel(_reportLabel: string): ReportInt {
    this.isReportFound = false;
    let dataResponse: ReportInt = null;
    this._jobDataService.getReport(_reportLabel)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        dataResponse = data.body;
        this.isReportFound = true;
        if (this.statutCall === 'createReport') {
          this.reportForm.controls['reportLabel'].setErrors({ 'isReportFound': true});
        }
        // console.log('Entity found : ', _entity);
      }, (err) => {
        console.log('Error : ', err);
      }
    );
    return dataResponse;
  }
  initSelectRequest(dataRequestList: RequestInt[]) {
    this.requestsList = [];
    for (const requestCur of dataRequestList) {
      const reqCur = {
        requestLabel: requestCur.requestLabel,
        requestRef: requestCur.requestRef,
        requestColumns: requestCur.requestColumns,
      };
      this.requestsList.push(reqCur);
    }
    if (this.statutCall === 'updateReport') {
      for (const curRequest of this.requestsList) {
        if (curRequest.requestRef === this.reportToUpdate.requestRef) {
          this.reportForm.controls.requestRefSelect.setValue(curRequest);
          break;
        }
      }
    }
  }
  setReportColumns(_requestSelected: any) {
    console.log(_requestSelected.requestRef);
    if (this.statutCall === 'createReport') {
      this.isNewRequestRef = true;
      this.isNewListCol = true;
    } else {
      if (this.reportToUpdate.requestRef === _requestSelected.requestRef) {
        this.isNewRequestRef = false;
        this.isNewListCol = true;
      } else {
        this.isNewRequestRef = true;
        this.isNewListCol = true;
      }
    }
    this.initColumnReportList(_requestSelected.requestColumns);
  }
  initColumnReportList(_columnsRequest: any) {
    const jsonObject = JSON.parse(_columnsRequest);
    // console.log(jsonObject.columns[0].colName);
    // const arrayColumns: ColumnsList[] = this.requestColumns;
    // console.log(arrayColumns);
    this.columnNameList = [];
    let curRanking = -1;
    for (const col of jsonObject.columns) {
      // console.log(col.colName);
      let colTypeCur = '';
      curRanking++;
      if (col.colDesc) {colTypeCur = col.colDesc.split(';')[1]; }
      const colCur = {
        colName: col.colName,
        colType: colTypeCur,
        displayed: true,
        grouping: false,
        search: false,
        filter: false,
        pinning: null,
        ranking: curRanking,
      };
      this.columnNameList.push(colCur);
    }
    this._jobDataService.changeStatutEvent('columnsActive');
    this._jobDataService.changeStatutEvent('rowsPinnedActive');
    this._jobDataService.changeStatutEvent('subReportActive');
    this._jobDataService.changeStatutEvent('dashboardActive');
    this._jobDataService.changeColReportService(null, null, null, this.columnNameList, false, 'createReport', false);
  }
  setMenuParent(_menuParent: string) {

  }
  setMenuRank(_menuRank: string) {
    
  }
  register() {
    this.registrationFailed = false;

    this.reportCard = this.prepareValidation();
    this._jobDataService.saveReport(this.reportCard, this.statutCall)
    // .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {
          this.isAlreadyValidate = true;
          this.reportForm.reset();
          if (this.statutCall) {
            this._jobDataService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation(): ReportInt {

    const reportCard: ReportModel = new ReportModel();
    reportCard.id = null;
    reportCard.reportRef = null;
    reportCard.updateDate = null;
    if (this.statutCall === 'updateReport') {
      reportCard.reportRef = this.reportToUpdate.reportRef;
    }
    reportCard.reportLabel = this.reportForm.value.reportLabel;
    reportCard.reportSample = this.reportForm.value.reportSample;
    reportCard.reportMenu = this.reportForm.value.menuParentSelect + ';' + this.reportForm.value.menuRankSelect;
    reportCard.reportStatut = this.reportForm.value.reportStatut;
    reportCard.requestRef = this.reportForm.value.requestRefSelect.requestRef;
    reportCard.reportColumns = null;
    reportCard.reportCompleted = this._jobDataService.getColReportService().isCompleted;
    this.columnNameList = this._jobDataService.getColReportService().colCur;
    this.setPinnedRows();
    reportCard.reportColumns = '{\"columns\":' + JSON.stringify(this.columnNameList);
    const rowPinned = this._jobDataService.getColReportService().rowPinned;
    if (rowPinned) { reportCard.reportColumns += ',\"rowpinned\":' + JSON.stringify(rowPinned); }
    reportCard.reportColumns += '}';
    reportCard.reportVersion =  this.reportForm.value.reportVersion;

    if ('ALL' === this.reportForm.value.accessEntities[0]) {
      reportCard.accessEntities = 'ALL';
    } else {
      reportCard.accessEntities = CommonMethods.arrayStringToString(this.reportForm.value.accessEntities, ',');
    }
    if (('ALL' === this.reportForm.value.accessGrp[0]) || (reportCard.accessEntities === 'ALL')) {
      reportCard.accessGrp = 'ALL';
    } else if (this.reportForm.value.accessEntities.length > 1 ) {
      reportCard.accessGrp = 'ALL';
     } else {
      reportCard.accessGrp = CommonMethods.arrayStringToString(this.reportForm.value.accessGrp, ',');
    }

    reportCard.reportOptions = '{\"options\":';
    let options: KeyValue [];
    options = [];
    let OptCur = {
      key: 'pagination',
      value: this.reportForm.value.reportPagination
    };
    options.push(OptCur);
    OptCur = {
      key: 'documents',
      value: this.reportForm.value.reportDocument
    };
    options.push(OptCur);
    OptCur = {
      key: 'dashboard',
      value: this.reportForm.value.reportDashboard
    };
    options.push(OptCur);

    reportCard.reportOptions += JSON.stringify(options);
    reportCard.reportOptions += '}';
    reportCard.reportChildren = undefined;
    const subReports = this._jobDataService.getColReportService().subReports;
    if (subReports)  { 
      reportCard.reportChildren = '{\"subreports\":' + JSON.stringify(subReports);
      
    }
    const dashBoard = this._jobDataService.getColReportService().widgets;
    if (dashBoard) {
      const dashBoardTmp = JSON.stringify(dashBoard);
      if (reportCard.reportChildren) { 
        reportCard.reportChildren += ',\"dashboard\":' + dashBoardTmp;
      } else {
        reportCard.reportChildren = '{\"dashboard\":' + dashBoardTmp;
      }
    }
    if (reportCard.reportChildren) {
      reportCard.reportChildren += '}';
    }
    return reportCard;
  }
  setPinnedRows() {
    const rowsPinnedList = this._jobDataService.getColReportService().rowPinned;
    if ((rowsPinnedList === null) || (rowsPinnedList === undefined)) { return; }
    // const columnNameList = this.columnNameList;
    // this.columnNameList = []
    let numCol = 0;
    for (const rowPinned of rowsPinnedList) {
      // row display
      numCol = 0;
      for (const colCur of this.columnNameList) {
        if (colCur.colName === rowPinned.colNameDisplay) {
          this.columnNameList[numCol].pinnedRow = true;
          break;
        }
        numCol++;
      }
      // rows aggregate
      for (const colAgg of rowPinned.colAggregate) {
        numCol = 0;
        for (const colCur of this.columnNameList) {
          if (colCur.colName === colAgg.colName) {
            this.columnNameList[numCol].pinnedRow = true;
            break;
          }
          numCol++;
        }
      }
    }
  }
  setValuesToUpdate() {
    
    this.reportForm.controls.reportLabel.setValue(this.reportToUpdate.reportLabel);
    this.reportForm.controls.reportStatut.setValue(this.reportToUpdate.reportStatut);

    this.reportForm.controls.requestRefSelect.setValue(this.reportToUpdate.requestRef);
    this.reportForm.controls.reportSample.setValue(0);
    this.reportForm.controls.reportSample.setValue(this.reportToUpdate.reportSample);
    if (this.reportToUpdate.reportMenu) {
      const menuElement = this.reportToUpdate.reportMenu.split(';');
      this.reportForm.controls.menuParentSelect.setValue(menuElement[0]);
      this.reportForm.controls.menuRankSelect.setValue(menuElement[1]);
    } else {
      this.reportForm.controls.menuParentSelect.setValue('');
      this.reportForm.controls.menuRankSelect.setValue('');
    }
    // this.columnNameList = this.reportToUpdate.reportColumns;
    this.setColumnReportList(this.reportToUpdate, this.reportToUpdate.reportCompleted);
    this.setSubReports(this.reportToUpdate);
    this.setDashboard(this.reportToUpdate);
    this._jobDataService.changeStatutEvent('columnsActive');
    this._jobDataService.changeStatutEvent('rowsPinnedActive');
    this._jobDataService.changeStatutEvent('subReportActive');
    this._jobDataService.changeStatutEvent('dashboardActive');
    this.reportVersion = this.reportToUpdate.reportVersion;
   
    let curEntities = this._userService.getUserLogged().accessentities.split(',');
    if ((this.reportToUpdate.accessEntities !== null) && (this.reportToUpdate.accessEntities.trim().length > 3)) {
      curEntities = this.reportToUpdate.accessEntities.split(',');
    }
    
    const bindEntities: any[] = [];
    for (const curEntity of curEntities) {
      bindEntities.push(curEntity);
    }
    this.reportForm.controls.accessEntities.setValue(bindEntities);
    this.getListParents(bindEntities);
    // let curGrps: any[] = ['ALL'];
    this.loadListGrps(bindEntities);
    let curGrps: any[];
    if ((this.reportToUpdate.accessGrp !== null) && (this.reportToUpdate.accessGrp.trim().length > 1)) {
      curGrps = this.reportToUpdate.accessGrp.split(',');
      curGrps = ['ALL'];
    }
    const bindGrps: any[] = [];
    for (const curGrp of curGrps) {
      bindGrps.push(curGrp);
    }
    this.reportForm.controls.accessGrp.setValue(bindGrps);

    this.isNewRequestRef = false;
    this.isNewListCol = false;
    if (this.reportToUpdate.reportOptions) {
      try {
        const jsonObject = JSON.parse(this.reportToUpdate.reportOptions);
        for (const optCur  of jsonObject.options) {
          const optTmp = optCur as KeyValue;
          // console.log(optTmp.key + ' ' + optTmp.value);
          if (optTmp.key === 'pagination') {
            this.reportForm.controls.reportPagination.setValue(optTmp.value);
          }
          if (optTmp.key === 'documents') {
            this.reportForm.controls.reportDocument.setValue(optTmp.value);
          }
          if (optTmp.key === 'dashboard') {
            this.reportForm.controls.reportDashboard.setValue(optTmp.value);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  setColumnReportList(_reportCur: any, _isCompleted: boolean) {
    const jsonObject = JSON.parse(_reportCur.reportColumns);
    // console.log(jsonObject.columns[0].colName);
    // const arrayColumns: ColumnsList[] = this.requestColumns;
    // console.log(arrayColumns);
    this.columnNameList = [];
    const curRanking = -1;
    for (const colCur of jsonObject.columns) {
      // console.log(col.colName);
      const colLocal = colCur as ColReportInt;
      if (colLocal.colName === '') { continue; }
      if (colLocal.pinnedRow === undefined) { colLocal.pinnedRow = false; }
      this.columnNameList.push(colLocal);
    }
    let rowPinned = null;
    if (jsonObject.rowpinned) {
      rowPinned = [];
      for (const colCur of jsonObject.rowpinned) {
        // console.log(col.colName);
        rowPinned.push(colCur);
      }
    }
    const subReports = null;
    this._jobDataService.changeColReportService(null, null, rowPinned, this.columnNameList, false, 'updateReport', _isCompleted);
  }
  setSubReports(_reportCur: any) {
    let subReports: SubReports = null;
    if (_reportCur.reportChildren) {
      const jsonObject = JSON.parse(_reportCur.reportChildren);
      subReports = jsonObject.subreports as SubReports;
    }
    this._jobDataService.changeColReportService(null, subReports, null, null, false, 'updateReport', _reportCur.reportCompleted);
  }
  setDashboard(_reportCur: any) {
    let dasboard: Widgets = null;
    if (_reportCur.reportChildren) {
      const jsonObject = JSON.parse(_reportCur.reportChildren);
      dasboard = jsonObject.dashboard as Widgets;
    }
    this._jobDataService.changeColReportService(dasboard, null, null, null, false, 'updateReport', _reportCur.reportCompleted);
  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'reportLabel': {
        !this.reportForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.reportForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Report label is required') : messageLib = '';
        break;
      }
      case 'isReportFound': {
        messageLib = this._translate.getTranslate('reportFound');
        break;
      }
      case 'reportStatut': {
        !this.reportForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.reportForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Statut is required') : messageLib = '';
        break;
      }
      case 'requestRef': {
        !this.reportForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.reportForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request is required') : messageLib = '';
        break;
      }

      case 'accessEntities': {
        break;
      }
      case 'accessGrp': {
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
}
