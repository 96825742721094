import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';
import { CountryDef, Currencies, InternationalsFormats, NameValue, CountryLib } from '../../../models/data.model';
import { WksEntityParams } from '../../job-wks/models/wks-common.model';

// import ALL_COUNTRIES from '../../../../assets/data/countries_ref.json';


/*
const INIT_DATA_INT = <ShareDataInt>{
  isAdministration: false,
  isCompany: false,
  isPerson: false,
  isProfessionnal: false,
};
*/
@Injectable({
  providedIn: 'root'
})
export class RessourcesService {

  // https://makina-corpus.com/blog/metier/2017/premiers-pas-avec-rxjs-dans-angular
  // protected shareDataInt: BehaviorSubject<ShareDataInt> = new BehaviorSubject<ShareDataInt>(INIT_DATA_INT);


  private civilityList = ['mrs', 'miss', 'mr'];
  // allCountriesStr = JSON.stringify(ALL_COUNTRIES);
  // allCountriesJson = JSON.parse(this.allCountriesStr);
  private allCountriesStr: any;
  private allCountriesJson: any;
  private allCurrencies: any[];
  private countriesList: CountryLib[];
  private intFormats: Map<string, InternationalsFormats>;
  private wksEntityParams: WksEntityParams;
  private countryFmt: any;
  private phonesTypes: string[];
  private notificationsApp: string[];

  constructor() {
    /*this.countriesList = [];
    Object.keys(ALL_COUNTRIES).map(key => {
      const locCountry = ALL_COUNTRIES[key] as CountryDef;
      this.countriesList.push({
        isoAlpha2: locCountry.isoAlpha2,
        commonName: locCountry.commonName,
      });
    });*/
  }
  public setCountryFmt(countryFmt: any): void {
    this.countryFmt = countryFmt;
  }
  public getCountryFmt(): any {
    return this.countryFmt;
  }
  public setWksEntityParams(wksEntityParams: WksEntityParams): void {
    this.wksEntityParams = wksEntityParams;
  }
  public getWksEntityParams(): WksEntityParams {
    return this.wksEntityParams;
  }
  public setPhonesType(phonesTypes: string[]): void {
    this.phonesTypes = phonesTypes;
  }
  public setNotifApp(notificationsApp: string[]): void {
    this.notificationsApp = notificationsApp;
  }
  public getPhonesType(): string[] {
    return this.phonesTypes;
  }
  public getNotifApp(): string[] {
    return this.notificationsApp;
  }

  public setCountries(_countries: any) {
    this.allCountriesStr = _countries;
    this.allCountriesJson = JSON.parse(this.allCountriesStr);
    this.countriesList = [];
    Object.keys(this.allCountriesJson).map(key => {
      const locCountry = this.allCountriesJson[key] as CountryDef;
      this.countriesList.push({
        isoAlpha2: locCountry.isoAlpha2,
        commonName: locCountry.commonName,
      });
    });
  }

  public setCurrencies(currencies: Currencies[]) {
    this.allCurrencies = currencies;
  }
  public getCurrencies(): Currencies[] {
    return this.allCurrencies;
  }
  public setIntFormats(_intFormats: Map<string, InternationalsFormats>)  {

    this.intFormats = _intFormats;
    // this.getFormat('EN', 'numbers', 'currency');
  }
  public getListIntFormat(): string[] {
    // const arr = [];
    // this.intFormats.forEach((value, key) => arr.push({ key, value }));
    // this.intFormats.forEach((value, key) => arr.push(key));
    /* const arr = Array.from(this.intFormats, ([key, value]) => {
      return key;
    });
    */
   /*
    Object.keys(this.intFormats).map(key => {
      console.log(JSON.stringify(key));
    });
  */
    // const arrayFmt = [...this.intFormats];
    const arr = [];
    /*
    for ( const fmtCur of arrayFmt) {
      console.log(JSON.stringify(fmtCur));
      console.log(JSON.parse(JSON.stringify(fmtCur)));
    }
    */
    const fmtJson = JSON.parse(JSON.stringify(this.intFormats));
    for (const key in fmtJson) {
      if (fmtJson.hasOwnProperty(key)) {
          // console.log(key + ' = ' + fmtJson[key]);
          arr.push(key);
      }
      
    } 

    return arr;
  }
  
  public getIntFormat( _keyFmt: string): InternationalsFormats {
    /*
    Object.keys(this.intFormats).map(key => {
      const locCountry = this.intFormats[key] as InternationalsFormats;
      console.log(key, locCountry);
    });
    */
    const intFormat = this.intFormats[_keyFmt];
    return intFormat;
  }
  public getFormat( _keyFmt: string, _fmtType: string, _fmtName: string ): string {
    const intFormat: InternationalsFormats = this.intFormats[_keyFmt];
    let fmtReturn: string;
    let namesValues: NameValue[];
    switch (_fmtType) {
      case 'datetime':
        namesValues = intFormat.datetime;
        break;
      case 'numbers':
        namesValues = intFormat.numbers;
        break;
      case 'separators':
        namesValues = intFormat.separators;
        break;
      case 'justifs':
        namesValues = intFormat.justifs;
        break;
      default:
        return null;
    }
    if ((namesValues === null)  || (namesValues === undefined)  || (namesValues.length === 0)) {
      return null;
    }
    for (const nameValue of namesValues) {
      if (nameValue.name === _fmtName)  {
        fmtReturn = nameValue.value;
        break;
      }
    }
   // console.log(fmtReturn);
   return fmtReturn;
  }
  public getDeptsList(): string[] {
    return ['purchase department', 'juridical department', 'financial department', 'technical department'];
  }
  public getCivilityList() {
    return this.civilityList;
  }
  public getCountry(countryKey: string): CountryDef {

    const locCountry = this.allCountriesJson[countryKey] as CountryDef;
    // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[countryKey])) as CountryDef;
    return locCountry;
  }
  public getTimezones(countryKey: string): string[] {

    const locCountry = this.allCountriesJson[countryKey] as CountryDef;
    let timeZones: string[]; 
    if (locCountry.timezones !== undefined) {
      timeZones = locCountry.timezones;
    } else  {
      timeZones = []; 
    }
    return timeZones;
  }
  public getCountriesList(): CountryLib[] {
 
    return this.countriesList;
  }

  public readAllData() {
/*
    for (const key in ALL_COUNTRIES) {
      // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[key])) as CountryDef;
      const locCountry = ALL_COUNTRIES[key] as CountryDef;
      //if (key === 'FR' ) {
        console.log('jsonCountry : ' + key + ' ==> ' + locCountry.commonName);
      //}
    }
*/
/*
    Object.keys(ALL_COUNTRIES).map(key => {
      // const locCountry = JSON.parse(JSON.stringify(ALL_COUNTRIES[key])) as CountryDef;
      const locCountry = ALL_COUNTRIES[key] as CountryDef;
      // if (key === 'FR' ) {
      console.log('jsonCountry : ' + key + ' ==> ' + locCountry.commonName);
      // }
    });
*/
  }

}
