import { OnInit, EventEmitter, SimpleChanges, TemplateRef, Injector, ElementRef, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksEquipmentModel, EquipEvent, ParentParam, MotorsConfigModel, PropulsionsModel, ThrusterConfigModel, WksDocsModel, TrailersOptions, RevisionModel } from '../../../../models/wks-equipment.model';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { EquipmentOwnerComponent } from '../equipment-owner/equipment-owner.component';
import { EquipmentData } from '../equipment-owner/equipment-data';
import { EquipmentMetadata } from '../equipment-metadata/equipement-metadata';
import { DataWorkModel, DataLocationWorkModel, WksEquipmentsRequest } from '../../../../models/wks-works.model';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
const ɵ0 = MAT_MOMENT_DATE_FORMATS;
export class EquipmentCardComponent {
    constructor(fb, _userService, router, modalService, _translate, _ressources, _wksCentralService, _globalEventsManagerService, _dialog, adapterDate, parentInjector) {
        this.fb = fb;
        this._userService = _userService;
        this.router = router;
        this.modalService = modalService;
        this._translate = _translate;
        this._ressources = _ressources;
        this._wksCentralService = _wksCentralService;
        this._globalEventsManagerService = _globalEventsManagerService;
        this._dialog = _dialog;
        this.adapterDate = adapterDate;
        this.parentInjector = parentInjector;
        this.dataOut = new EventEmitter();
        // @ViewChild('titleCard', {static: false}) titleCard: any;
        this.onDestroy = new Subject();
        this.configModal = {
            class: 'modal-dialog-centered modal-lg modal-dialog-std modal-body-std',
            backdrop: true,
            ignoreBackdropClick: true,
            animated: false,
        };
        this.valueWidth = false;
        this.typeOwner = EquipmentOwnerComponent;
        this.now = new Date();
        this.year = this.now.getFullYear();
        this.month = this.now.getMonth();
        this.day = this.now.getDate();
        this.tabChanged = (tabChangeEvent) => {
            // console.log('tabChangeEvent => ', tabChangeEvent); 
            // console.log('index => ', tabChangeEvent.index); 
            this.isRemovePossible = false;
            if (tabChangeEvent.index === 0) {
                this.isRemovePossible = true;
            }
            if (tabChangeEvent.index === 1) {
                this.currentTabSelected('hours');
            }
        };
    }
    ngOnInit() {
        //  this.initDp();
    }
    ngOnChanges(changes) {
        const listKey = Object.keys(changes);
        for (const propName of listKey) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'actionType': {
                        this.actionValue = changes['actionType'].currentValue;
                        break;
                    }
                    case 'statutCall': {
                        this.statutCall = changes['statutCall'].currentValue;
                        break;
                    }
                    case 'optionItem': {
                        this.optionItem = changes['optionItem'].currentValue;
                        break;
                    }
                    case 'equipmentToUpdate': {
                        this.equipmentToUpdate = changes['equipmentToUpdate'].currentValue;
                        break;
                    }
                    case 'isBoatEquip': {
                        this.isBoatEquip = changes['isBoatEquip'].currentValue;
                        break;
                    }
                    // isWorkEquip
                    case 'isWorkEquip': {
                        this.isWorkEquip = changes['isWorkEquip'].currentValue;
                        break;
                    }
                    case 'parentParam': {
                        this.parentParam = changes['parentParam'].currentValue;
                        break;
                    }
                    // ownerArg
                    case 'ownerArg': {
                        this.ownerArg = changes['ownerArg'].currentValue;
                        break;
                    }
                    // equipNumber
                    case 'equipNumber': {
                        this.equipNumber = changes['equipNumber'].currentValue;
                        break;
                    }
                    // ihmOptions
                    case 'ihmOptions': {
                        this.ihmOptions = changes['ihmOptions'].currentValue;
                        break;
                    }
                    // randomIdString
                    case 'randomIdString': {
                        this.randomIdString = changes['randomIdString'].currentValue;
                        break;
                    }
                } // end switch
            } // end if
        } // end loop
        this.initData();
    }
    initData() {
        if (this._translate.currentLang === undefined) {
            return;
        }
        this.hoursInput = false;
        this.isRemovePossible = true;
        this.countryEntity = this._ressources.getCountry(this._userService.getUserLogged().entitycountry);
        if (this.formChangeSubscribe !== undefined) {
            this.formChangeSubscribe.unsubscribe();
        }
        this.initDp();
        this.initBooleans();
        this.initParams();
        this.settingIntFmts();
        if (this.actionType === 'add') {
            this.titleCard = 'New ';
        }
        if ((this.actionType === 'update') || (this.actionType === 'updateWork') || (this.actionType === 'display')) {
            this.titleCard = '';
        }
        else if ((this.actionType === 'search') || (this.actionType === 'newsearch')) {
            this.titleCard = 'Searching ';
        }
        switch (this.optionItem) {
            case 'boats': {
                this.isBoat = true;
                this.titleCard += 'boat';
                break;
            }
            case 'jets': {
                this.isJet = true;
                this.titleCard += 'jet';
                break;
            }
            case 'trailers': {
                this.isTrailer = true;
                this.titleCard += 'trailer';
                break;
            }
            case 'outboard engine': {
                this.isEngine = true;
                this.titleCard += 'engine';
                break;
            }
            case 'inboard engine': {
                this.isEngine = true;
                this.titleCard += 'engine';
                break;
            }
            case 'engine': {
                this.isEngine = true;
                this.titleCard += 'engine';
                break;
            }
            case 'engines': {
                this.isEngine = true;
                this.titleCard += 'engine';
                break;
            }
            default:
                this.titleCard = this.optionItem;
        }
        this.equipmentCol = 'col-12';
        if (this._translate.currentLang !== undefined) {
            this.adapterDate.setLocale(this._translate.currentLang);
        }
        const subEquipId = [];
        if (this.isBoatEquip) {
            subEquipId.push((this.equipmentToUpdate !== undefined ? this.equipmentToUpdate.id : undefined));
        }
        // history works
        this.equipIdCall = (this.equipmentToUpdate !== undefined ? this.equipmentToUpdate.id : undefined);
        this.workEquipArgs = {
            stdEntity: this._userService.getUserLogged().entity,
            mainEquipment: (!this.isBoatEquip ? (this.equipmentToUpdate !== undefined ? this.equipmentToUpdate.id : undefined) : undefined),
            subEquipments: (this.isBoatEquip ? subEquipId : undefined),
            allSubEquip: (!this.isBoatEquip ? true : false),
            onlySubEquip: (this.isBoatEquip ? true : false),
            onlyMainEquip: (!this.isBoatEquip ? true : false)
        };
        // config
        if ((this.isBoat) || (this.isEngine)) {
            this.isSingleMotor = true;
            this.motorsConfigList = [];
            this.propulsionsList = [];
            this.thrustersConfigList = [];
            this.motorsConfigList = this._wksCentralService.getMotorsconfigList();
            this.propulsionsList = this._wksCentralService.getPropulsionsList();
            this.thrustersConfigList = this._wksCentralService.getThrustersconfigList();
        }
        if (this.isEngine && this.isBoatEquip) {
            this.isMotorBoat = true;
            if (this.parentParam !== undefined) {
                if ((this.parentParam.equipBoatManeuvring !== undefined)
                    && (this.parentParam.equipBoatManeuvring)) {
                    // this.thrustersConfigList = this._wksCentralService.getThrustersconfigList();
                    this.thrusterPossible = true;
                } // end test maneuvring
                if ((this.parentParam.equipBoatPropulsion !== undefined)
                    && (this.parentParam.equipBoatPropulsion !== 'sails')
                    && (this.parentParam.equipBoatConfiguration !== undefined)) {
                    if (this.parentParam.equipBoatConfiguration === 'single installation') {
                        this.isSingleMotor = true;
                    }
                    else {
                        this.isSingleMotor = false;
                        // this.motorsConfigList = this._wksCentralService.getMotorsconfigList();
                        for (const configMotors of this.motorsConfigList) {
                            if (configMotors.configName === this.parentParam.equipBoatConfiguration) {
                                this.positionsMotorList = configMotors.positions;
                                break;
                            }
                        }
                    }
                } // end test propulsion
            } // end test parentParam
        } // test is Engine
        this.loadListsParameters();
        this.allSubscribe();
        // this.loadBrands();
    }
    initBooleans() {
        this.isPopupOpened = false;
        this.isRegistered = true;
        this.isBoatRegistered = false;
        this.isReadOnly = false;
        this.isSearch = false;
        this.isInput = false;
        this.equipBoatManeuvringCheck = false;
        this.thrusterPossible = false;
        this.isFinishLoading = false;
        this.isThusterMotor = false;
        this.isTechnicalDoc = false;
        this.isSingleMotor = false;
        this.isUserDoc = false;
        this.formIsActive = false;
        this.isMotorBoat = false;
        this.isMetric = (this.countryEntity.measurementUnit === 'METRIC');
        this.isBoat = false;
        this.isJet = false;
        this.isSingleMotor = false;
        this.isTrailer = false;
        this.isEngine = false;
        if (this.actionValue === 'display') {
            this.isReadOnly = true;
        }
        if ((this.actionValue === 'add') || (this.actionValue === 'update')) {
            this.isInput = true;
        }
        if ((this.actionValue === 'search') || (this.actionValue === 'newsearch')) {
            this.isSearch = true;
        }
        this.ownerWork = false;
        if (this.ownerArg !== undefined) {
            this.ownerWork = true;
        }
        this.isWorkDisplay = false;
        if (this.statutCall === 'work') {
            this.isWorkDisplay = true;
        }
        this.okInputHours = false;
    }
    initParams() {
        this.monthsList = [];
        for (let iIdx = 1; iIdx < 37; iIdx++) {
            const labelValue = {
                label: iIdx.toString(),
                value: iIdx.toString()
            };
            this.monthsList.push(labelValue);
        }
        this.hoursList = [];
        for (let iIdx = 50; iIdx < 550; iIdx = iIdx + 50) {
            const labelValue = {
                label: iIdx.toString(),
                value: iIdx.toString()
            };
            this.hoursList.push(labelValue);
        }
    }
    settingIntFmts() {
        const wksEntity = this._wksCentralService.getWksEntityCur();
        if (wksEntity === undefined) {
            return false;
        }
        const otherData = JSON.parse(wksEntity.otherData);
        const internationnalFormat = otherData.internationnalFormat;
        this.numberFormat = {
            locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
            minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
            maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
            currency: this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
            justifCurrency: this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
            justifText: this._ressources.getFormat(internationnalFormat, 'justifs', 'text'),
            justifNumbers: this._ressources.getFormat(internationnalFormat, 'justifs', 'numbers')
        };
        this.timeFormat = this._translate.getLocalFmt('timeFormat');
        return true;
    }
    loadParamTasksRefByType(_equipType, _equipBrand, _equipModel) {
        this.listTasksCur = [];
        return new Promise((resolve, reject) => {
            this._wksCentralService.getParamTasksrefByTypeList(this._userService.getUserLogged().entity, _equipType, this._userService.getUserLogged().userlang, _equipBrand, _equipModel)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(data => {
                const tasksRead = data.body;
                this.listTasksCur = [];
                this.listTasksCache = [];
                for (const taskCur of tasksRead) {
                    if (!taskCur.taskrefJson) {
                        continue;
                    }
                    const taskrefJson = JSON.parse(taskCur.taskrefJson);
                    if (!taskrefJson.jobManufacturerAtt) {
                        continue;
                    }
                    const jobManufacturerAtt = taskrefJson.jobManufacturerAtt;
                    if (jobManufacturerAtt !== undefined) {
                        taskCur.jobManufacturerAtt = JSON.parse(JSON.stringify(jobManufacturerAtt));
                        const isRevision = (taskCur.jobManufacturerAtt.specType.startsWith('revision') ? true : false);
                        if (isRevision) {
                            this.listTasksCur.push(taskCur);
                            continue;
                        }
                    }
                }
                /*
                if (_equipSN !== undefined && _equipSN !== null && _equipSN !== '') {
                  this.listTasksCur = [];
                  this.listTasksCur = this.getRestrictedList(tasksRead, _equipSN);
                }
                */
                // this.affectTooltip(this.tasksReferenced);
                this.listTasksCache = JSON.parse(JSON.stringify(this.listTasksCur));
                for (const taskRef of this.listTasksCur) {
                    const labelValue = {
                        label: taskRef.taskrefLabel,
                        value: taskRef.taskrefLabel
                    };
                    taskRef.selectedTask = false;
                    taskRef.taskrefLabel += (taskRef.equipBrand ? ' - ' + taskRef.equipBrand : '');
                    taskRef.taskrefLabel += (taskRef.equipModel ? ' - ' + taskRef.equipModel : '');
                }
                this.listTasksFiltered = JSON.parse(JSON.stringify(this.listTasksCur));
                this.filteredTasksList = this.listTasksCur.slice();
                this.filteredCacheTasksList = JSON.parse(JSON.stringify(this.filteredTasksList));
                this.filteredTasksList.sort((obj1, obj2) => {
                    return obj1.taskrefLabel > obj2.taskrefLabel ? 1 : -1;
                });
                resolve(tasksRead);
            }, err => {
                console.log(err);
                reject(err.status);
            });
        });
    }
    buildForm() {
        if (this.ihmOptions !== undefined) {
            this.isReadOnly = this.ihmOptions.readOnly;
        }
        const yearMax = (new Date()).getFullYear();
        const yearMin = yearMax - 50;
        this.listYears = this.initYearsList(50);
        this.ownerNameCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.ownerIdCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipImmatCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipDesignCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipCountryCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBrandCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipModelCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipYearCtrl = this.fb.control({ value: '', disabled: this.isReadOnly }, [Validators.max(yearMax), Validators.min(yearMin)]);
        this.equipNameCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipSNCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipAddInfoCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipHoursCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipHoursDateCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBoatPropulsionCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBoatConfigurationCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBoatManeuvringCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.thrusterOptionCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.motorPositionCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipHitchCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipHitchSizeCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipAxlesCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipTiresCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipRimsCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipNutsCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBoatHomeportCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipBoatAnchorageCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.portPoolCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.portPontoonCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.portRingCtrl = this.fb.control({ value: '', disabled: this.isReadOnly });
        this.equipmentForm = this.fb.group({
            ownerId: this.ownerIdCtrl,
            ownerName: this.ownerNameCtrl,
            equipImmat: this.equipImmatCtrl,
            equipDesign: this.equipDesignCtrl,
            equipCountry: this.equipCountryCtrl,
            equipBrand: this.equipBrandCtrl,
            equipModel: this.equipModelCtrl,
            equipYear: this.equipYearCtrl,
            equipName: this.equipNameCtrl,
            equipSN: this.equipSNCtrl,
            equipAddInfo: this.equipAddInfoCtrl,
            equipHours: this.equipHoursCtrl,
            equipHoursDate: this.equipHoursDateCtrl,
            equipBoatPropulsion: this.equipBoatPropulsionCtrl,
            equipBoatConfiguration: this.equipBoatConfigurationCtrl,
            equipBoatManeuvring: this.equipBoatManeuvringCtrl,
            thrusterOption: this.thrusterOptionCtrl,
            motorPosition: this.motorPositionCtrl,
            equipHitch: this.equipHitchCtrl,
            equipHitchSize: this.equipHitchSizeCtrl,
            equipAxles: this.equipAxlesCtrl,
            equipTires: this.equipTiresCtrl,
            equipRims: this.equipRimsCtrl,
            equipNuts: this.equipNutsCtrl,
            commissioningDate: this.fb.control({ value: '', disabled: this.isReadOnly }),
            equipBoatHomeport: this.fb.control({ value: '', disabled: this.isReadOnly }),
            equipBoatAnchorage: this.fb.control({ value: '', disabled: this.isReadOnly }),
            equipBoatWintering: this.fb.control({ value: '', disabled: this.isReadOnly }),
            portPool: this.fb.control({ value: '', disabled: this.isReadOnly }),
            portPontoon: this.fb.control({ value: '', disabled: this.isReadOnly }),
            portRing: this.fb.control({ value: '', disabled: this.isReadOnly }),
            revisionsArray: this.fb.array([]),
        }, {
            updateOn: 'blur'
        });
    }
    // start revisionsArray methods
    get revisionsArray() {
        return this.equipmentForm.get('revisionsArray');
    }
    newRevisionRow() {
        return this.fb.group({
            taskrefId: this.fb.control({ value: '', disabled: this.isReadOnly })
        });
    }
    addRevisionRow() {
        this.revisionsArray.push(this.newRevisionRow());
    }
    removeRevisionRow(idxRevisioon) {
        this.revisionsArray.removeAt(idxRevisioon);
    }
    // end revisionsArray methods
    /*
    onFormValid(validity: string) {
      switch (validity) {
        case 'VALID':
          if (this.isBoatEquip) {
            this.dataOut.emit('okToValidSubEquip');
          } else {
            this.dataOut.emit('okToValidMainEquip');
          }
          break;
        case 'INVALID':
          if (this.isBoatEquip) {
            this.dataOut.emit('koToValidSubEquip');
          } else {
            this.dataOut.emit('koToValidMainEquip');
          }
          break;
      }
    }
    */
    loadListsParameters() {
        this.isUpdateStatut = false;
        this.buildForm();
        if (this.isTrailer) {
            this.loadTrailerOptions();
        }
        this.loadCountries();
        this.loadTypeComponents();
        this.loadCustomers();
        this.loadBrands()
            .then(brandList => {
            if ((this.equipmentToUpdate !== undefined) && (this.equipmentToUpdate.equipBrand) && (this.equipmentToUpdate.equipModel)) {
                let brandSelected;
                for (const curBrand of this.brandCacheList) {
                    if (curBrand.brandName === this.equipmentToUpdate.equipBrand) {
                        brandSelected = curBrand;
                        break;
                    }
                }
                this.loadModels(brandSelected)
                    .then(modelList => {
                    if (this.actionValue === 'update') {
                        this.isUpdateStatut = true;
                        this.fillForm();
                        this.loadDocs();
                    }
                    if (this.actionValue === 'display') {
                        this.isUpdateStatut = false;
                        this.fillForm();
                        this.loadDocs();
                    }
                    if (this.actionValue === 'newsearch') {
                        this.fillForm();
                    }
                    this.loadParamTasksRefByType(this.equipmentToUpdate.equipType, this.equipmentToUpdate.equipBrand, undefined)
                        .then((responseType2) => {
                    })
                        .catch((error) => {
                        console.log('loadParamTasksRefByType : ' + error);
                        if (error === 404) {
                        }
                        else {
                        }
                    });
                })
                    .catch(error => {
                    console.log(error);
                    this.isFinishLoading = true;
                });
            }
            else {
                if (this.actionValue === 'update') {
                    this.isUpdateStatut = true;
                    this.fillForm();
                }
                if (this.actionValue === 'display') {
                    this.isUpdateStatut = false;
                    this.fillForm();
                    this.loadDocs();
                }
                if (this.actionValue === 'newsearch') {
                    this.fillForm();
                }
                if (this.actionValue === 'add') {
                    this.fillForm();
                }
            }
        })
            .catch(error => {
            console.log(error);
            this.isFinishLoading = true;
        });
    }
    allSubscribe() {
        this._globalEventsManagerService.synchroEvents
            .pipe(takeUntil(this.onDestroy))
            .subscribe(event => {
            if (event === 'closeDocModal') {
                this.closeModal();
                this.loadDocs();
            }
            else if (event === 'closeWorkModal') {
                this.closeModal();
            }
            else if (event === 'cancelModal') {
                // console.log('fermeture');
            }
            else if (event) {
                const typeEvent = typeof event;
                // const eventString = JSON.stringify(event).toString();
                const eventString = String(event);
                // console.log(typeEvent);
            }
        });
        this._wksCentralService.onChangeStatut
            .pipe(takeUntil(this.onDestroy))
            .subscribe(valueEvent => {
            if (valueEvent === 'validMainEquipment') {
                this.registerEquip('card', 'validMainEquipment');
            }
            if (valueEvent === 'validSubEquipment') {
                this.registerEquip('card', 'validSubEquipment');
            }
        });
    }
    initDp() {
        /* this.minStartDate = moment({year: this.year - 100, month: this.month, day: this.day});
        this.maxStartDate = moment({year: this.year, month: this.month, day: this.day}); */
        // this.minDate = moment({year: this.year - 100, month: this.month, day: this.day});
        // this.maxDate = moment({year: this.year, month: this.month, day: this.day});
        this.minBirthDayDate = new Date(this.year - 10, this.month, this.day);
        this.maxBirthDayDate = new Date();
        this.minDate = new Date(this.year - 100, this.month, this.day);
        this.maxDate = new Date(this.year, this.month, this.day);
    }
    getPopoverData() {
        if (this.equipmentToUpdate === undefined) {
            return;
        }
        const item = new EquipmentData();
        item.ownerName = this.equipmentToUpdate.ownerName;
        item.ownerId = this.equipmentToUpdate.ownerId;
        item.stdEntity = this.equipmentToUpdate.stdEntity;
        return item;
    }
    getPopoverMetadata() {
        if (this.equipmentToUpdate === undefined) {
            return;
        }
        const item = new EquipmentMetadata();
        item.ownerName = (!this.isBoatEquip ? this.equipmentToUpdate.ownerName : '');
        item.ownerId = (!this.isBoatEquip ? this.equipmentToUpdate.ownerId : '');
        item.stdEntity = this.equipmentToUpdate.stdEntity;
        item.equipName = this.equipmentToUpdate.equipName;
        item.equipDesign = this.equipmentToUpdate.equipDesign;
        item.equipId = this.equipmentToUpdate.id;
        return item;
    }
    mouseEnter() {
        this.formIsActive = true;
    }
    mouseLeave() {
        this.formIsActive = false;
    }
    currentTabSelected(tabName) {
        // console.log (tabName);
        switch (tabName) {
            case 'hours': {
                this.randomIdString = CommonMethods.randomString(5) + ' ' + Math.floor(Math.random() * 10000).toString();
                this.okInputHours = true;
                break;
            }
        }
    }
    loadTrailerOptions() {
        this.trailerOptions = this._wksCentralService.getTrailersOptions();
        // hitches
        this.hitchesList = [];
        this.hitchesList.push({
            label: '',
            value: ''
        });
        for (const hitch of this.trailerOptions.hitches) {
            const localItem = {
                label: this._translate.getTranslate(hitch.name),
                value: hitch.name
            };
            this.hitchesList.push(localItem);
        }
        // axles 
        this.axlesList = [' ', '1', '2', '3'];
        let measurementLocal = 'METRIC';
        if (!this.isMetric) {
            measurementLocal = 'NON_METRIC';
        }
        // tires
        this.tiresList = [' '];
        for (const measurLocal of this.trailerOptions.tires) {
            if (measurLocal.measurement === measurementLocal) {
                this.tiresList = measurLocal.list;
                this.tiresList.splice(0, 0, '');
                break;
            }
        }
        // rims
        this.rimsList = [' '];
        for (const measurLocal of this.trailerOptions.rims) {
            if (measurLocal.measurement === measurementLocal) {
                this.rimsList = measurLocal.list;
                this.rimsList.splice(0, 0, '');
                break;
            }
        }
        // nuts
        this.nutsList = [' ', '1', '2', '3', '4', '5', '6'];
    }
    getHitchesSizes(_hitchName, _valueSelected) {
        let measurementLocal = 'METRIC';
        if (!this.isMetric) {
            measurementLocal = 'NON_METRIC';
        }
        this.hitchSizes = [];
        let isFound = false;
        for (const hitchCur of this.trailerOptions.hitches) {
            if (hitchCur.name === _hitchName) {
                for (const sizesCur of hitchCur.sizes) {
                    if (sizesCur.measurement === measurementLocal) {
                        this.hitchSizes = [...sizesCur.list];
                        isFound = true;
                        break;
                    }
                }
            }
            if (isFound) {
                this.hitchSizes.splice(0, 0, '');
                break;
            }
        }
        if (_valueSelected !== undefined) {
            this.equipmentForm.controls.equipHitchSize.setValue(_valueSelected);
        }
    }
    onChangeOthers($event, _oring) {
        this.isRegistered = false;
    }
    initYearsList(minYear) {
        const currentYear = new Date().getUTCFullYear();
        const years = [];
        let startYear = currentYear - minYear;
        while (startYear <= currentYear) {
            years.push((startYear++).toString());
        }
        years.sort(function (a, b) {
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            return 0;
        });
        years.splice(0, 0, '');
        return years;
    }
    validCommissioningDate(event) {
        // console.log('validCommissioningDate :' + event.value);
        const commissionningDateValue = event.target.value;
        // console.log('validDpStartDate :' + event.value + ' : ' + this.startDateValue);
        if (commissionningDateValue > new Date()) {
            this.equipmentForm.controls.commissionningDate.setValue(new Date());
        }
        if (this.minDate > commissionningDateValue) {
            this.equipmentForm.controls.commissionningDate.setValue(this.minDate);
        }
        this.isRegistered = false;
    }
    onChangeField(field, value) {
        this.isRegistered = false;
        if (field = 'equipHours') {
            if ((this.equipmentForm.controls.equipHoursDate.value === null) ||
                (this.equipmentForm.controls.equipHoursDate.value === '') ||
                (this.equipmentForm.controls.equipHoursDate.value === undefined)) {
                this.equipmentForm.controls.equipHoursDate.setValue(new Date());
            }
        }
    }
    onKeyCountry(value) {
        // console.log(value);
        this.countriesFilteredList = this.searchCountry(value);
    }
    searchCountry(value) {
        const filter = value.toLowerCase();
        return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
    }
    onChangeCountry($event) {
        // console.log('onChangeCountry : ' + $event);
        this.isRegistered = false;
    }
    loadCountries() {
        const countriesListTmp = this._ressources.getCountriesList();
        this.countriesCacheList = [];
        for (const countryTmp of countriesListTmp) {
            const labelLang = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
            this.countriesCacheList.push({
                isoAlpha2: countryTmp.isoAlpha2,
                commonName: labelLang,
            });
        }
        this.countriesCacheList.sort((obj1, obj2) => {
            return obj1.commonName > obj2.commonName ? 1 : -1;
        });
        this.countriesCacheList.splice(0, 0, {
            isoAlpha2: '',
            commonName: '',
        });
        this.countriesFilteredList = [...this.countriesCacheList];
    }
    dataOutLocation(dataReturn) {
        switch (dataReturn.typeLocation) {
            case 'mooring': {
                this.mooringData = dataReturn;
                this.isRegistered = false;
                break;
            }
            case 'wintering': {
                this.winteringData = dataReturn;
                this.isRegistered = false;
                break;
            }
            case 'homeAnchorage': {
                this.homeAnchorageData = dataReturn;
                this.isRegistered = false;
                break;
            }
            default:
                break;
        }
    }
    changeEquipBoatManeuvring($event) {
        // console.log($event);
        /*
        if ($event) {
          this.equipmentForm.get('equipBoatManeuvring').disable();
        } else {
          this.equipmentForm.get('equipBoatManeuvring').enable();
        }
        */
        this.equipBoatManeuvringCheck = $event;
        this.isRegistered = false;
        //  this.equipmentToUpdate.equipBoatManeuvring = $event;
    }
    changeThrusterOption($event) {
        this.isThusterMotor = $event;
        if (this.isThusterMotor) {
            this.isMotorBoat = false;
        }
        else {
            this.isMotorBoat = true;
        }
        this.isRegistered = false;
    }
    onChangePropulsion($event) {
        for (const curProp of this.propulsionsList) {
            if (curProp.propulsionName === $event) {
                this.isMotorBoat = (curProp.propulsionType === 'motor');
                break;
            }
        }
        this.isRegistered = false;
    }
    onChangeMotorConfig($event, _selectedValue) {
        if (this.isBoatEquip) {
            return;
        }
        if ($event === 'single installation') {
            this.isSingleMotor = true;
        }
        else {
            this.isSingleMotor = false;
            for (const configMotors of this.motorsConfigList) {
                if (configMotors.configName === $event) {
                    this.positionsMotorList = configMotors.positions;
                    if (_selectedValue !== undefined) {
                        this.equipmentForm.controls.motorPosition.setValue(_selectedValue);
                    }
                    break;
                }
            }
        }
        this.isRegistered = false;
    }
    onChangeMotorPosition($event, _selectedValue) {
        this.isRegistered = false;
        this.equipmentForm.controls.equipDesign.setValue(this._translate.instant($event));
    }
    onKeyClearOwnerName() {
        // console.log(value);
        this.equipmentForm.controls.ownerName.setValue('');
    }
    onKeyOwner(value) {
        // console.log(value);
        this.customerFilteredList = this.searchOwner(value);
    }
    searchOwner(value) {
        const filter = value.toLowerCase();
        return this.customerCacheList.filter(customerCur => customerCur.bsnName.toLowerCase().includes(filter));
    }
    onChangeOwner($event) {
        // console.log('onChangeOwner : ' + $event);
        if ($event === '') {
            this.customerFilteredList = this.customerCacheList;
        }
        this.isRegistered = false;
    }
    onChangeHomeport($event) {
        if ((this.equipmentForm.controls.equipBoatAnchorage.value === '') ||
            (this.equipmentForm.controls.equipBoatAnchorage.value === null)) {
            this.equipmentForm.controls.equipBoatAnchorage.setValue($event);
        }
        this.isRegistered = false;
    }
    onKeyHomeport(value) {
        // console.log(value);
        this.homePortFiltredList = this.searchAnchorage(value);
    }
    onChangeAnchorage($event) {
        this.isRegistered = false;
    }
    onKeyAnchorage(value) {
        // console.log(value);
        this.anchorageFiltredList = this.searchAnchorage(value);
    }
    searchAnchorage(value) {
        const filter = value.toLowerCase();
        return this.anchoragesCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
    }
    onChangeWintering($event) {
        this.isRegistered = false;
    }
    onKeyWintering(value) {
        // console.log(value);
        this.anchorageFiltredList = this.searchAnchorage(value);
    }
    searchWintering(value) {
        const filter = value.toLowerCase();
        return this.winteringCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
    }
    loadCustomers() {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        this.customerCacheList = [];
        this.customerFilteredList = [];
        this._wksCentralService.getWksBusinessList(this._userService.getUserLogged().entity, 'wks-customers')
            .pipe(takeUntil(this.onDestroy))
            .subscribe(data => {
            this.customerList = data.body;
            for (const customerCur of this.customerList) {
                this.customerCacheList.push({
                    id: customerCur.id,
                    bsnName: customerCur.bsnName,
                });
            }
            this.customerCacheList.sort((obj1, obj2) => {
                return obj1.bsnName > obj2.bsnName ? 1 : -1;
            });
            this.customerFilteredList = [...this.customerCacheList];
            this.customerCacheList.splice(0, 0, { id: '', bsnName: '' });
        }, err => {
            console.log(err);
        });
    }
    loadTypeComponents() {
        const listType = this._wksCentralService.getComponentsList();
        this.equipTypeList = [];
        for (const typeCur of listType) {
            this.equipTypeList.push({
                labelType: this._translate.getTranslate(typeCur),
                equipType: typeCur,
                equipAttachedBoat: true
            });
        }
        this.equipTypeList.sort((obj1, obj2) => {
            return obj1.labelType > obj2.labelType ? 1 : -1;
        });
        this.equipTypeList.splice(0, 0, { labelType: '', equipType: '', equipAttachedBoat: false });
    }
    initBrandList() {
        this.loadBrands()
            .then((brandList) => {
        })
            .catch((error) => {
            console.log(error);
        });
    }
    loadBrands() {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        this.brandCacheList = [];
        this.brandFilteredList = [];
        this.modelCacheList = [];
        this.modelFilteredList = [];
        let brandList;
        return new Promise((resolve, reject) => {
            this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(data => {
                brandList = data.body;
                for (const brandCur of brandList) {
                    this.brandCacheList.push(brandCur);
                }
                this.brandCacheList.sort((obj1, obj2) => {
                    return obj1.brandName > obj2.brandName ? 1 : -1;
                });
                this.brandCacheList.splice(0, 0, { stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: '' });
                this.brandFilteredList = [...this.brandCacheList];
                resolve(brandList);
            }, err => {
                // console.log(err);
                if (err.status === 404) {
                    this.brandCacheList.push({
                        stdEntity: this._userService.getUserLogged().entity,
                        brandRow: 'brand',
                        brandName: ''
                    });
                    this.brandFilteredList = [...this.brandCacheList];
                }
                reject(err.status);
            });
        });
    }
    onChangeBrand($event) {
        // console.log('onChangeBrand : ' + $event);
        let brandSelected;
        for (const curBrand of this.brandCacheList) {
            if (curBrand.brandName === $event) {
                brandSelected = curBrand;
                break;
            }
        }
        this.brandSelected = brandSelected;
        this.initModelList(brandSelected);
    }
    onKeyBrand($event) {
        // console.log(value);
        let valueFinal;
        if ($event === '') {
            valueFinal = '';
        }
        else {
            valueFinal = $event.target.value;
            if ($event.keyCode === 32) {
                valueFinal += ' ';
            }
        }
        this.brandFilteredList = this.searchBrand(valueFinal);
        this.filterbrand.nativeElement.value = valueFinal;
    }
    searchBrand(value) {
        const filter = value.toLowerCase();
        return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
    }
    addBrand(newBrandName) {
        // console.log(newBrand);
        if (newBrandName === '') {
            return;
        }
        const newBrand = {
            stdEntity: this._userService.getUserLogged().entity,
            brandRow: 'brand',
            brandName: newBrandName,
            equipType: '',
            modelName: '',
        };
        this.brandCacheList.push(newBrand);
        this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
        });
        if (this.brandCacheList[0].brandName === '') {
            this.brandCacheList.splice(0, 1);
        }
        this.brandFilteredList = [...this.brandCacheList];
        this.modelCacheList = [];
        this.modelFilteredList = [...this.modelCacheList];
        // this._wksCentralService.saveWksBrand( newBrand, 'create');
        this.saveBrandModel(newBrand);
    }
    initModelList(_brandSelected) {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        this.modelCacheList = [];
        this.modelFilteredList = [];
        this.loadModels(_brandSelected)
            .then((modelList) => {
        })
            .catch((error) => {
        });
    }
    loadModels(_brandSelected) {
        let modelList;
        const entityLocal = this._userService.getUserLogged().entity;
        return new Promise((resolve, reject) => {
            this._wksCentralService.getWksModelsList(entityLocal, _brandSelected.brandName, this.optionItem)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(data => {
                modelList = data.body;
                for (const brandCur of modelList) {
                    this.modelCacheList.push(brandCur);
                }
                this.modelCacheList.sort((obj1, obj2) => {
                    return obj1.modelName > obj2.modelName ? 1 : -1;
                });
                this.modelCacheList.splice(0, 0, {
                    stdEntity: this._userService.getUserLogged().entity,
                    brandRow: 'model',
                    brandName: '',
                    equipType: '',
                    modelName: ''
                });
                this.modelFilteredList = [...this.modelCacheList];
                resolve(modelList);
            }, err => {
                console.log(err);
                if (err.status === 404) {
                    this.modelCacheList.push({
                        stdEntity: this._userService.getUserLogged().entity,
                        brandRow: 'model',
                        brandName: _brandSelected.brandName,
                        equipType: this.optionItem,
                        modelName: '',
                    });
                    this.modelFilteredList = [...this.modelCacheList];
                }
                reject(err);
            });
        });
    }
    onKeyModel($event) {
        let valueFinal;
        if ($event === '') {
            valueFinal = '';
        }
        else {
            valueFinal = $event.target.value;
            if ($event.keyCode === 32) {
                valueFinal += ' ';
            }
        }
        this.modelFilteredList = this.searchModel(valueFinal);
        this.filtermodel.nativeElement.value = valueFinal;
    }
    searchModel(value) {
        const filter = value.toLowerCase();
        return this.modelCacheList.filter(modelCur => modelCur.modelName.toLowerCase().includes(filter));
    }
    addModel(newModelName) {
        // console.log(newModel);
        const newModel = {
            stdEntity: this._userService.getUserLogged().entity,
            brandRow: 'model',
            brandName: this.brandSelected.brandName,
            equipType: this.optionItem,
            modelName: newModelName,
        };
        this.modelCacheList.push(newModel);
        this.modelCacheList.sort((obj1, obj2) => {
            return obj1.modelName > obj2.modelName ? 1 : -1;
        });
        if (this.modelCacheList[0].modelName === '') {
            this.modelCacheList.splice(0, 1);
        }
        this.modelFilteredList = [...this.modelCacheList];
        // this._wksCentralService.saveWksBrand( newModel, 'create');
        this.saveBrandModel(newModel);
    }
    saveBrandModel(newBrandModel) {
        this._wksCentralService.saveWksBrand(newBrandModel, 'create')
            .subscribe(() => {
            console.log('saveBrandModel OK');
        }, () => {
            console.log('saveBrandModel KO');
        });
    }
    loadDocs() {
        this.technicalDocList = [];
        this.administrativeList = [];
        this.userDocList = [];
        let equipType;
        if (this.parentParam === undefined) {
            equipType = this.optionItem;
        }
        else {
            equipType = this.parentParam.equipType;
            if (this.parentParam.equipType === '') {
                equipType = this.optionItem;
            }
        }
        this._wksCentralService.getWksDocListEquip(this._userService.getUserLogged().entity, equipType, this.equipmentForm.controls.equipBrand.value, this.equipmentForm.controls.equipModel.value)
            .subscribe(data => {
            const listCur = data.body;
            for (const docCur of listCur) {
                switch (docCur.docTags) {
                    case 'administrative': {
                        this.administrativeList.push(docCur);
                        break;
                    }
                    case 'technical': {
                        this.technicalDocList.push(docCur);
                        this.isTechnicalDoc = true;
                        break;
                    }
                    default: {
                        this.userDocList.push(docCur);
                        this.isUserDoc = true;
                    }
                }
            }
            this.administrativeList.sort((obj1, obj2) => {
                return obj1.docName > obj2.docName ? 1 : -1;
            });
            this.technicalDocList.sort((obj1, obj2) => {
                return obj1.docName > obj2.docName ? 1 : -1;
            });
            this.userDocList.sort((obj1, obj2) => {
                return obj1.docName > obj2.docName ? 1 : -1;
            });
        }, () => {
            // this.errorMessage('searching');
        });
    }
    searching() {
        const currentEquipment = this.fillModel();
        this._wksCentralService.searchWksEquipment(currentEquipment)
            .subscribe(data => {
            const equipEvent = {
                searchCriteria: currentEquipment,
                searchResult: data.body,
            };
            this._wksCentralService.onSearchList.emit(equipEvent);
        }, () => {
            this.errorMessage('searching');
        });
    }
    onRevisionChange(_typeInput, inputValue, idxRevision) {
        switch (_typeInput) {
            case 'revisionsHours':
                this.revisionsList[idxRevision].hours = inputValue;
                break;
            case 'revisionsMonths':
                this.revisionsList[idxRevision].months = inputValue;
                break;
            case 'revisionsTaskrefId':
                let taskCur;
                for (const taskTmp of this.listTasksCur) {
                    if (taskTmp.taskrefId === inputValue) {
                        taskCur = taskTmp;
                        break;
                    }
                }
                // this.revisionsArray.at[idxRevision].taskrefId.setValue(taskCur.taskrefId);
                this.revisionsList[idxRevision].taskrefId = taskCur.taskrefId;
                this.revisionsList[idxRevision].months = taskCur.jobManufacturerAtt.months;
                this.revisionsList[idxRevision].hours = taskCur.jobManufacturerAtt.hours;
                const fieldFg = this.revisionsArray.controls[idxRevision];
                fieldFg.controls.taskrefId.setValue(taskCur.taskrefId);
                let idxItem = 0;
                let inputValid = true;
                for (const fgItem of this.revisionsList) {
                    const taskRefIdCur = this.revisionsArray.at(idxItem);
                    if (idxItem !== idxRevision) {
                        if (inputValue === taskRefIdCur.controls.taskrefId.value) {
                            fieldFg.controls.taskrefId.setValue(undefined);
                            this.revisionsList[idxRevision].taskrefId = undefined;
                            this.revisionsList[idxRevision].months = undefined;
                            this.revisionsList[idxRevision].hours = undefined;
                            inputValid = false;
                            break;
                        }
                    }
                    idxItem++;
                }
                /*
                let idxItem = 0 ;
                for (const taskItem of this.filteredTasksList) {
                  if (taskItem.taskrefId === taskCur.taskrefId) {
                    this.filteredTasksList.splice(idxItem, 1);
                    break;
                  }
                  idxItem++;
                }*/
                if (inputValid) {
                    this.isRegistered = false;
                }
                break;
        }
    }
    addRowRevisions(idxRevision) {
        const revisionEmpty = {
            hours: -1,
            months: -1,
            taskrefId: ''
        };
        this.revisionsList.splice(idxRevision + 1, 0, revisionEmpty);
        this.revisionsArray.insert(idxRevision + 1, this.newRevisionRow());
    }
    removeRowRevisions(idxRevision) {
        // const taskRefIdCur = this.revisionsArray.at(idxRevision) as FormGroup;
        this.revisionsList.splice(idxRevision, 1);
        this.revisionsArray.removeAt(idxRevision);
        /*
        if (this.revisionsArray.length === 0) {
          this.filteredTasksList = JSON.parse(JSON.stringify(this.filteredCacheTasksList));
          return;
        } else {
          let taskRefFound: WksTasksModel ;
          for (const taskItem of this.filteredCacheTasksList) {
            if (taskItem.taskrefId === taskRefIdCur.controls.taskrefId.value) {
              taskRefFound = taskItem;
              break;
            }
          }
          this.filteredTasksList.push(taskRefFound);
          this.filteredTasksList.sort((obj1, obj2) => {
            return obj1.taskrefLabel > obj2.taskrefLabel ? 1 : -1;
          });
        // this.filteredTasksList = this.listTasksCur.slice();
        // this.filteredCacheTasksList = JSON.parse(JSON.stringify(this.filteredTasksList));
        }*/
        if (this.revisionsList.length === 0) {
            this.addRowRevisions(0);
            this.addRevisionRow();
        }
    }
    registerEquip(_orig, _typeValid) {
        if (_orig !== 'card') {
            return;
        }
        const currentEquipment = this.fillModel();
        this._wksCentralService.saveWksEquipment(currentEquipment, this.actionType)
            .subscribe(wksEquipReturn => {
            const currentEquipmentResponse = wksEquipReturn.body;
            currentEquipmentResponse.isWorkEquip = this.isWorkEquip;
            if (_typeValid === 'validMainEquipment') {
                currentEquipmentResponse.isMainEquip = true;
            }
            this.isRegistered = true;
            if (this.isBoat && !this.isSearch && !this.isBoatEquip) { // sidenav add boat
                this.parentParam = {
                    equipPartOf: currentEquipmentResponse.id,
                    ownerId: currentEquipmentResponse.ownerId,
                    equipType: '',
                    equipBoatPropulsion: currentEquipmentResponse.equipBoatPropulsion,
                    equipBoatConfiguration: currentEquipmentResponse.equipBoatConfiguration,
                    equipBoatManeuvring: currentEquipmentResponse.equipBoatManeuvring,
                    commissioningDate: currentEquipmentResponse.commissioningDate,
                };
                this.isBoatRegistered = true;
                if (this.statutCall !== 'work') {
                    this.equipmentCol = 'col-6';
                }
            }
            else if ((this.statutCall === 'accordion') && (this.isBoatEquip)) { // accordion
                this.dataOut.emit(currentEquipmentResponse);
            }
            else if (this.statutCall === 'sidenav') { // sidenav list equipments
                this._wksCentralService.onRegisterValid.emit('closeRegister');
                return;
            }
            else if (this.statutCall === 'work') { // modal cork card
                // this._wksCentralService.onRegisterValid.emit('closeRegister');
                currentEquipmentResponse.equipNumber = this.equipNumber;
                this.dataOut.emit(currentEquipmentResponse);
                return;
            }
            else { // unknown origin
                this.router.navigate(['/jobHome']);
            }
        }, () => {
            this.errorMessage('register');
        });
    }
    removeEquip() {
        this._wksCentralService.saveWksEquipment(this.equipmentToUpdate, 'delete')
            .subscribe(() => {
            if (!this.isBoat && !this.isBoatEquip) {
                this.router.navigate(['/jobHome']);
            }
            else if (this.isBoatEquip) {
                this.dataOut.emit('removeNode');
            }
        }, () => {
            this.errorMessage('remove');
        });
    }
    fillModel() {
        let equipPartOfLocal = null;
        let equipTypeLocal = null;
        let ownerIdLocal = null;
        if (this.equipmentToUpdate !== undefined) {
            if ((this.equipmentToUpdate.equipPartOf !== undefined) && (this.equipmentToUpdate.equipPartOf !== null)) {
                equipPartOfLocal = this.equipmentToUpdate.equipPartOf;
            }
            if ((this.equipmentToUpdate.equipType !== undefined) && (this.equipmentToUpdate.equipType !== null)) {
                equipTypeLocal = this.equipmentToUpdate.equipType;
            }
            if ((this.equipmentToUpdate.ownerId !== undefined) && (this.equipmentToUpdate.ownerId !== null)) {
                ownerIdLocal = this.equipmentToUpdate.ownerId;
            }
        }
        else if (this.parentParam !== undefined) {
            if ((this.parentParam.equipPartOf !== undefined) && (this.parentParam.equipPartOf !== null)) {
                equipPartOfLocal = this.parentParam.equipPartOf;
            }
            if ((this.parentParam.equipType !== undefined) && (this.parentParam.equipType !== null)) {
                equipTypeLocal = this.parentParam.equipType;
            }
            if ((this.parentParam.ownerId !== undefined) && (this.parentParam.ownerId !== null)) {
                ownerIdLocal = this.parentParam.ownerId;
            }
        }
        if ((this.ownerArg !== undefined) && (this.ownerArg.id !== undefined) && (this.ownerArg.id !== null)) {
            ownerIdLocal = this.ownerArg.id;
        }
        let motorConfig = {};
        let motorJson;
        if (this.isEngine) {
            motorConfig = {
                thrusterOption: this.isThusterMotor,
                motorPosition: this.equipmentForm.controls.motorPosition.value,
                equipBoatConfiguration: this.equipmentForm.controls.equipBoatConfiguration.value,
            };
            motorJson = '{ "motorJson": ' + JSON.stringify(motorConfig) + '}';
        }
        let trailerValues = {};
        let trailerJson;
        if (this.isTrailer) {
            trailerValues = {
                equipHitch: this.equipmentForm.controls.equipHitch.value,
                equipHitchSize: this.equipmentForm.controls.equipHitchSize.value,
                equipAxles: this.equipmentForm.controls.equipAxles.value,
                equipTires: this.equipmentForm.controls.equipTires.value,
                equipRims: this.equipmentForm.controls.equipRims.value,
                equipNuts: this.equipmentForm.controls.equipNuts.value,
            };
            trailerJson = '{ "trailerJson": ' + JSON.stringify(trailerValues) + '}';
        }
        let equipJsonLocal;
        if ((motorJson !== undefined) || (trailerJson !== undefined)) {
            equipJsonLocal = '{ "jsonEquip": ';
            if (motorJson !== undefined) {
                equipJsonLocal += motorJson;
                if (trailerJson !== undefined) {
                    equipJsonLocal += ',' + trailerJson;
                }
            }
            else if (trailerJson !== undefined) {
                equipJsonLocal += trailerJson;
            }
            equipJsonLocal += '}';
        }
        const wksEquipmentCard = {
            id: (this.equipmentToUpdate === undefined ? undefined :
                this.equipmentToUpdate.id ? this.equipmentToUpdate.id : undefined),
            stdEntity: this._userService.getUserLogged().entity,
            ownerId: (ownerIdLocal ? ownerIdLocal : this.isInput ? this.equipmentForm.controls.ownerId.value : undefined),
            ownerName: (this.isSearch ? this.equipmentForm.controls.ownerName.value : undefined),
            equipPartOf: (equipPartOfLocal ? equipPartOfLocal : undefined),
            equipImmat: this.equipmentForm.controls.equipImmat.value,
            equipType: (equipTypeLocal ? equipTypeLocal : this.optionItem),
            equipDesign: this.equipmentForm.controls.equipDesign.value,
            equipCountry: this.equipmentForm.controls.equipCountry.value,
            equipBrand: this.equipmentForm.controls.equipBrand.value,
            equipModel: this.equipmentForm.controls.equipModel.value,
            equipYear: this.equipmentForm.controls.equipYear.value,
            equipName: this.equipmentForm.controls.equipName.value,
            equipSN: this.equipmentForm.controls.equipSN.value,
            equipAddInfo: this.equipmentForm.controls.equipAddInfo.value,
            equipHours: ((this.equipmentForm.controls.equipHours.value === null ||
                this.equipmentForm.controls.equipHours.value === undefined ||
                this.equipmentForm.controls.equipHours.value === '')
                ? -1 : this.equipmentForm.controls.equipHours.value),
            equipHoursDate: this.equipmentForm.controls.equipHoursDate.value,
            stdCreation: (this.equipmentToUpdate === undefined ? undefined :
                this.equipmentToUpdate.stdCreation ? this.equipmentToUpdate.stdCreation : undefined),
            equipBoatPropulsion: (this.isBoat ? this.equipmentForm.controls.equipBoatPropulsion.value : undefined),
            equipBoatConfiguration: (this.isBoat ? this.equipmentForm.controls.equipBoatConfiguration.value : undefined),
            equipBoatManeuvring: (this.isBoat ? this.equipBoatManeuvringCheck : undefined),
            equipJson: (equipJsonLocal ? equipJsonLocal : undefined),
            equipBoatHomeport: (this.isBoat ? this.homeAnchorageData.dataLocation.portId : undefined),
            equipBoatAnchorage: (this.isBoat ? this.mooringData.dataLocation.portId : undefined),
            equipBoatWintering: (this.isBoat ? this.winteringData.dataLocation.portId : undefined),
            equipBoatBerthing: (this.isBoat ? JSON.stringify(this.mooringData.dataLocation.dataFields) : undefined),
            equipBoatStorage: (this.isBoat ? JSON.stringify(this.winteringData.dataLocation.dataFields) : undefined),
            commissioningDate: this.equipmentForm.controls.commissioningDate.value,
            equipValid: true,
            equipRevisions: (this.revisionsList.length > 0 ? JSON.stringify(this.revisionsList) : '')
        };
        return wksEquipmentCard;
    }
    fillForm() {
        if (this.actionType === 'add') {
            this.equipmentForm.controls.ownerId.setValue(this.parentParam.ownerId);
            if (this.isBoatEquip) {
                this.equipmentForm.controls.commissioningDate.setValue(this.parentParam.commissioningDate);
            }
            this.homeAnchorageData = {
                typeLocation: 'homeAnchorage',
                dataLocation: undefined
            };
            this.mooringData = {
                typeLocation: 'mooring',
                dataLocation: undefined
            };
            this.winteringData = {
                typeLocation: 'wintering',
                dataLocation: undefined
            };
            return;
        }
        if (this.equipmentToUpdate === undefined) {
            return;
        }
        if (this.equipmentToUpdate.ownerId) {
            this.equipmentForm.controls.ownerId.setValue(this.equipmentToUpdate.ownerId);
        }
        if (this.actionValue === 'newsearch') {
            this.equipmentForm.controls.ownerName.setValue(this.equipmentToUpdate.ownerName);
        }
        // this.equipmentForm.controls.equipPartOf.setValue(this.equipmentToUpdate.equipPartOf);
        this.equipmentForm.controls.equipImmat.setValue(this.equipmentToUpdate.equipImmat);
        //  this.equipmentForm.controls.equipType.setValue(this.equipmentToUpdate.equipType);
        this.equipmentForm.controls.equipDesign.setValue(this.equipmentToUpdate.equipDesign);
        this.equipmentForm.controls.equipCountry.setValue(this.equipmentToUpdate.equipCountry);
        this.equipmentForm.controls.equipBrand.setValue(this.equipmentToUpdate.equipBrand);
        this.equipmentForm.controls.equipModel.setValue(this.equipmentToUpdate.equipModel);
        this.equipmentForm.controls.equipYear.setValue(this.equipmentToUpdate.equipYear);
        this.equipmentForm.controls.equipName.setValue(this.equipmentToUpdate.equipName);
        this.equipmentForm.controls.equipSN.setValue(this.equipmentToUpdate.equipSN);
        this.equipmentForm.controls.equipAddInfo.setValue(this.equipmentToUpdate.equipAddInfo);
        this.equipmentForm.controls.equipHours.setValue(this.equipmentToUpdate.equipHours);
        this.equipmentForm.controls.equipHoursDate.setValue(this.equipmentToUpdate.equipHoursDate);
        this.equipmentForm.controls.commissioningDate.setValue(this.equipmentToUpdate.commissioningDate);
        if ((this.isBoat) && this.actionType === 'update' && this.statutCall !== 'work') {
            this.parentParam = {
                equipPartOf: this.equipmentToUpdate.id,
                ownerId: this.equipmentToUpdate.ownerId,
                equipType: '',
                entity: this.equipmentToUpdate.stdEntity,
                statutCall: 'loadEquipment',
                equipBoatPropulsion: this.equipmentToUpdate.equipBoatPropulsion,
                equipBoatConfiguration: this.equipmentToUpdate.equipBoatConfiguration,
                equipBoatManeuvring: this.equipmentToUpdate.equipBoatManeuvring,
            };
            if (this.statutCall !== 'work') {
                this.equipmentCol = 'col-6';
            }
            this.isBoatRegistered = true;
        }
        if (this.isBoat) {
            this.equipmentForm.controls.equipBoatPropulsion.setValue(this.equipmentToUpdate.equipBoatPropulsion);
            this.equipmentForm.controls.equipBoatConfiguration.setValue(this.equipmentToUpdate.equipBoatConfiguration);
            this.equipmentForm.controls.equipBoatManeuvring.setValue(this.equipmentToUpdate.equipBoatManeuvring);
            this.homeAnchorageData = {
                typeLocation: 'homeAnchorage',
                dataLocation: {
                    portId: this.equipmentToUpdate.equipBoatHomeport,
                    dataFields: undefined,
                }
            };
            this.mooringData = {
                typeLocation: 'mooring',
                dataLocation: {
                    portId: this.equipmentToUpdate.equipBoatAnchorage,
                    dataFields: JSON.parse(this.equipmentToUpdate.equipBoatBerthing)
                }
            };
            this.winteringData = {
                typeLocation: 'wintering',
                dataLocation: {
                    portId: this.equipmentToUpdate.equipBoatWintering,
                    dataFields: JSON.parse(this.equipmentToUpdate.equipBoatStorage)
                }
            };
            this.isMotorBoat = false;
            for (const curProp of this.propulsionsList) {
                if (curProp.propulsionName === this.equipmentToUpdate.equipBoatPropulsion) {
                    this.isMotorBoat = (curProp.propulsionType === 'motor');
                    break;
                }
            }
        }
        this.revisionsList = []; // : RevisionModel[];
        if (this.equipmentToUpdate.equipRevisions !== undefined
            && this.equipmentToUpdate.equipRevisions !== null
            && this.equipmentToUpdate.equipRevisions !== '') {
            this.revisionsList = JSON.parse(this.equipmentToUpdate.equipRevisions);
            for (const revisionsRow of this.revisionsList) {
                const fgRevision = this.newRevisionRow();
                fgRevision.controls.taskrefId.setValue(revisionsRow.taskrefId);
                this.revisionsArray.push(fgRevision);
            }
        }
        if (this.revisionsList.length === 0) {
            this.addRowRevisions(0);
        }
        const equipJsonLocal = this.equipmentToUpdate.equipJson;
        let jsonEquip;
        if ((equipJsonLocal !== undefined) && (equipJsonLocal !== null)) {
            const jsonData = JSON.parse(equipJsonLocal);
            jsonEquip = jsonData.jsonEquip;
        }
        if (this.isEngine && jsonEquip !== undefined) {
            const jsonMotor = (jsonEquip.motorJson ? jsonEquip.motorJson : jsonEquip);
            /* if (jsonMotor === undefined) {
               jsonMotor = jsonEquip;
             }*/
            if ((jsonMotor.motorPosition !== undefined) && (jsonMotor.motorPosition !== '')) {
                this.isMotorBoat = true;
                this.equipmentForm.controls.motorPosition.setValue(jsonMotor.motorPosition);
            }
            if (jsonMotor.thrusterOption !== undefined) {
                this.equipmentForm.controls.thrusterOption.setValue(jsonMotor.thrusterOption);
                this.isThusterMotor = jsonMotor.thrusterOption;
                if ((this.isThusterMotor) && (jsonMotor.motorPosition !== undefined)) {
                    this.equipmentForm.controls.motorPosition.setValue(jsonMotor.motorPosition);
                }
            }
            if ((!this.isThusterMotor) && (jsonMotor.equipBoatConfiguration !== undefined)) {
                this.equipmentForm.controls.equipBoatConfiguration.setValue(jsonMotor.equipBoatConfiguration);
                this.isThusterMotor = false;
                if (jsonMotor.equipBoatConfiguration === 'single installation') {
                    this.isSingleMotor = true;
                }
                else {
                    this.isSingleMotor = false;
                    if (jsonMotor.motorPosition !== undefined) {
                        this.onChangeMotorConfig(jsonMotor.equipBoatConfiguration, jsonMotor.motorPosition);
                    }
                }
            }
        }
        if (this.isTrailer) {
            const jsonTrailer = jsonEquip.trailerJson;
            if (jsonTrailer !== undefined) {
                this.equipmentForm.controls.equipHitch.setValue(jsonTrailer.equipHitch);
                this.equipmentForm.controls.equipAxles.setValue(jsonTrailer.equipAxles);
                this.equipmentForm.controls.equipTires.setValue(jsonTrailer.equipTires);
                this.equipmentForm.controls.equipRims.setValue(jsonTrailer.equipRims);
                this.equipmentForm.controls.equipNuts.setValue(jsonTrailer.equipNuts);
                this.getHitchesSizes(jsonTrailer.equipHitch, jsonTrailer.equipHitchSize);
            }
        }
        /*
        if (this.statutCall === 'work') {
          this.jsonInputForm = JSON.stringify(this.fillModel());
          this.formChangeSubscribe = this.equipmentForm.valueChanges.subscribe(
            result => {
                if (this.jsonInputForm !== JSON.stringify(this.fillModel())) {
                  this.onFormValid(this.equipmentForm.status);
                  this.jsonInputForm = JSON.stringify(this.fillModel());
                }
            }
          );
        }*/
        this.isFinishLoading = true;
    }
    errorMessage(_actionCur) {
        const dialogConfig = new MatDialogConfig();
        let titleBox;
        let messageBox;
        if (_actionCur === 'register') {
            messageBox = 'error recording';
            titleBox = 'recording ';
            switch (this.optionItem) {
                case 'boats': {
                    titleBox += 'boat';
                    break;
                }
                case 'jets': {
                    titleBox += 'jet';
                    break;
                }
                case 'trailers': {
                    titleBox += 'trailer';
                    break;
                }
                case 'engines': {
                    titleBox += 'engine';
                    break;
                }
            }
        }
        else {
            switch (this.optionItem) {
                case 'boats': {
                    titleBox = 'searching boats';
                    messageBox = 'no boat found';
                    break;
                }
                case 'jets': {
                    titleBox = 'searching jets';
                    messageBox = 'no jet found';
                    break;
                }
                case 'trailers': {
                    titleBox = 'searching trailers';
                    messageBox = 'no trailer found';
                    break;
                }
                case 'engines': {
                    titleBox = 'searching engines';
                    messageBox = 'no engine found';
                    break;
                }
            }
        }
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            id: 1,
            title: this._translate.getTranslate(titleBox),
            typeDialog: 'alertWks',
            panelClass: 'stdTheme',
            contentMessage: this._translate.getTranslate(messageBox),
            data1: '',
            data2: '',
            messageType: 'ERROR'
        };
        //   this._dialog.open(ModalCommonComponent, dialogConfig);
        const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(data => {
            console.log('Dialog output:', data);
        });
    }
    // isBoatEquip
    doClose() {
        if (this.equipmentToUpdate) {
            this.dataOut.emit('collapseNode;' + this.equipmentToUpdate.id);
        }
        else {
            if (this.statutCall === 'work' && this.optionItem === 'boats') {
                this.dataOut.emit('closeBoat');
            }
            this.dataOut.emit('collapseNode');
        }
    }
    doCancel() {
        if (this.isBoatEquip) {
            return;
        }
        if (this.statutCall === 'sidenav') {
            this._wksCentralService.onRegisterValid.emit('closeRegister');
            return;
        }
        if ((this.actionValue === 'add') || (this.actionValue === 'search') || (this.actionValue === 'newsearch')) {
            this.router.navigate(['/jobHome']);
            return;
        }
        if (this.statutCall) {
            this._wksCentralService.onRegisterValid.emit('cancelRegister');
        }
        else {
            this.router.navigate(['/jobHome']);
        }
    }
    saveDoc(row, fileExtension) {
        const path = row.docPath + '/' + row.docFilename;
        const mimeApp = this._wksCentralService.getContentMime(fileExtension);
        this._wksCentralService.downloadDoc(path, mimeApp)
            .pipe(takeUntil(this.onDestroy))
            .subscribe((retMes) => {
            const response = retMes.body;
            // window.location.href = response.url;
            this._wksCentralService.saveToFileSystem(response, row.docFilename, mimeApp);
        }, err => {
        });
    }
    openDoc(docCur) {
        const fileExtension = docCur.docFilename.split('.').pop();
        if (fileExtension === 'pdf') {
            this.openPdf(docCur);
        }
        else if ((fileExtension === 'jpeg') ||
            (fileExtension === 'jpg') ||
            (fileExtension === 'png')) {
            this.openImg(docCur);
        }
        else {
            this.saveDoc(docCur, fileExtension);
        }
    }
    openImg(docCur) {
        this.openModal(this.imgModalViewer, docCur);
    }
    openPdf(docCur) {
        this.openModal(this.pdfModalViewer, docCur);
    }
    openModal(template, docCur) {
        this.dataList = [];
        this.dataList.push(docCur);
        this.modalText = [docCur.docName];
        this.modalRef = this.modalService.show(template, this.configModal);
    }
    closeModal() {
        if (this.modalRef !== undefined) {
            this.modalRef.hide();
        }
    }
    /*
    openModalWork() {
      this.dataInWork = {
        work: undefined,
        iRow: -1,
        newWork :  {
          ownerId: this.equipmentToUpdate.ownerId,
          equipments: {
            mainEquipment: this.equipmentToUpdate,
          }
        }
      };
      const configModalWork = {
        class: 'modal-works',
        backdrop: true,
        ignoreBackdropClick: true,
        animated: true,
        
      };
      this.modalRef = this.modalService.show(this.modalWorks, configModalWork);
    }
    */
    addDoc(docType) {
        // console.log('add --> ' + docType);
        this.pseudoDoc = {
            stdEntity: this.equipmentToUpdate.stdEntity,
            equipType: this.equipmentToUpdate.equipType,
            docBrand: this.equipmentToUpdate.equipBrand,
            docModel: this.equipmentToUpdate.equipModel,
            docTags: docType,
            docName: '',
            docFilename: '',
        };
        this.openModal(this.docModal, this.pseudoDoc);
    }
    removeDoc(docCur) {
        this._wksCentralService.saveWksDoc(docCur, 'delete')
            .subscribe(() => {
            this.loadDocs();
        }, () => {
            // this.errorMessage('remove', null);
        });
    }
    getDateCreation(dateCur) {
    }
}
export { ɵ0 };
