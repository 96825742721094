<div *ngIf="equipmentChoiceType" class="flex-row row stdTheme" style="margin: 0px;margin-bottom: 10px;">
    <div class="col-3"></div>
    <div class="col-6">
        <div class="flex-row row stdTheme">
            <div class="col-4" style="padding-top: 20px;">
                <label class="stdTheme ">{{ 'equipType' | translate }}</label>
            </div>
            <div class="col-6" style="padding-top: 10px;">
                <mat-select id="equipType" placeholder="{{ 'choose type equipment' | translate }}"
                    panelClass="mdiselectcontent" #equipTypeSelect ngDefaultControl
                    (selectionChange)="onChangeEquipType(equipTypeSelect.value)">
                    <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList"
                        [value]="equipTypeCur">{{equipTypeCur.labelType}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
       
        <div class="flex-row row stdTheme" *ngIf="choiceBoatDisplay" id="boatsSelect" style="padding-top: 10px;">
            <div class="col-6">
                <div *ngIf="mainEquipmentsLoading">
                    <span class="blink_text">
                        {{ 'loadingBoats' | translate }}
                    </span>
                </div>
                <div *ngIf="mainEquipmentsLoaded">
                    <!-- [(value)]="defaultBoat" -->
                    <mat-select id="workBoat" #workBoat panelClass="mdiselectcontent" ngDefaultControl
                        placeholder=" {{ 'choice boat' | translate }}"
                        (selectionChange)="onChangeworkBoat(workBoat.value)">
                        <mat-option class="selectOptionsTheme" *ngFor="let workBoatCur of boatsList"
                            [value]="workBoatCur"> {{workBoatCur.equipName}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-6" *ngIf="mainEquipmentsLoaded && !isReadOnly" style="padding-top: 10px;">
                <button  class="mat-button-toggle" (click)="addMainEquipment()">
                    <mat-icon style="margin-top: 0px;padding-top: 0px;vertical-align:middle" >add_circle_outline</mat-icon>
                    {{ 'create equipment' | translate }}
                </button>
            </div>
        </div>
       
    </div>
    <div class="col-3">

    </div>
</div>
<div *ngIf="!equipmentChoiceType" class="flex-row row" style="margin: 0px;margin-bottom: 10px;">
    <!-- boat -->
    <div class="col-6" *ngIf="mainEquipmentDisplay">
        <mdi-equipment-card style="width: 100%" 
            [optionItem]="mainItemType" 
            [ownerArg]="ownerArg"
            [ihmOptions]="ihmOptions"
            [actionType]="(mainEquipmentNew ? 'add' : 'update')" 
            [isBoatEquip]="false"
            [isWorkEquip]="isMainWorkEquip"
            [equipmentToUpdate]="mainEquipmentCur ? mainEquipmentCur : undefined" 
            [statutCall]="'work'"
            [hoursInput]="((mainItemType === 'jets' || mainItemType !== 'boats') ? true: false)" 
            [parentParam]="parentParam"
            [equipNumber]="-1"
            (dataOut)="dataOutEquipmentCard('mainEquipment',$event)"></mdi-equipment-card>
    </div>
    <!-- no boat (equipment, jets, trailers, etc ..) -->
    <div class="col-6" *ngIf="subEquipmentDisplay">
        
        <div *ngIf="!isReadOnly" class="flex-row row" style="width: 100%">
            <div class="col-1">
                <!-- <mat-icon>refresh</mat-icon>-->
                <button class="buttonFunction tooltipRefreshIcon right-label-span" mat-icon-button (click)="refreshSubEquipment()" >
                    <mat-icon class="iconeFunction" >refresh</mat-icon>
                    <span class="tooltipRefreshIconText">{{'Refresh' | translate }}</span>
                </button>
            </div>
            <div class="col-10">

            </div>

            <div *ngIf="numberSubEquipements === -1 || subEquipmentsCur.length < numberSubEquipements" class="col-1">
                <button class="buttonFunction tooltipAddIcon right-label-span" mat-icon-button (click)="addSubEquipment()" >
                    <mat-icon class="iconeFunction" >add_circle_outline</mat-icon>
                    <span class="tooltipAddIconText">{{'New engine' | translate }}</span>
                </button>
            </div>
           
        </div>
        
        <div class="flex-row row">
            <div class="col-12">
                <mat-tab-group #tabsSubEquipment mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px;">
                    <div class="stdTheme" *ngFor="let subItem of subEquipmentsCur; let row = index" >
                        <!--<mat-tab label="{{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}" >-->
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div class="flex-row row" style="min-width: 150px">
                                    <span class="col-10 left-label-span" style="text-align: start; font-size: 15px; margin-top: 5px;">
                                        {{ subItem.equipDesign ? subItem.equipDesign : subItem.equipName }}
                                    </span>
                                    <span *ngIf="subEquipmentsCur.length > 1 && !isReadOnly" class="col-2 right-label-span" style="text-align: end; padding-top: 5px;">
                                        <mat-icon class="iconeFunction tooltipRemoveIcon" (click)="removeSubEquipment(row)" >
                                            close
                                            <span class="tooltipRemoveIconText">{{'remove from the list' | translate }}</span>
                                        </mat-icon>
                                    </span>
                                </div>
                            </ng-template>
                            <mat-card-content class="stdTheme">
                                <mdi-equipment-card style="width: 100%" 
                                    [optionItem]="subItem.equipType" 
                                    [ihmOptions]="ihmOptions"
                                    [ownerArg]="ownerArg"
                                    [actionType]="(subItem.subEquipmentNew ? 'add' : 'update')" 
                                    [isBoatEquip]="true"
                                    [isWorkEquip]="true"
                                    [equipmentToUpdate]="subItem ? subItem : undefined" 
                                    [statutCall]="'work'"
                                    [hoursInput]="((subItem.equipType === 'engines' || subItem.equipType === 'engine' || subItem.equipType === 'outboard engine' || subItem.equipType === 'inboard engine' ) ? true: false)" 
                                    [parentParam]="parentParam"
                                    [equipNumber]="row"
                                    (dataOut)="dataOutEquipmentCard('subEquipment',$event)">
                                </mdi-equipment-card>
                            </mat-card-content>
                        </mat-tab>
                    </div>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>