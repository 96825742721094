<!-- https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts-->
<div  class="container stdTheme"  >
    <div class="row"> 
        <div class="col-12"> 
            <h4 align="center">{{titleReport}}</h4>
        </div>
    </div>
    <div *ngIf="!dataImgLoaded" class="flex-row row" style="margin: 0px">
        <div class="container-fluid d-flex justify-content-center" >
            <mat-progress-bar 
                mode="indeterminate"
                style="margin-left: 30px;">
            </mat-progress-bar>
        </div>
    </div>
    <!--        
        <div class="row">
            <div class="container-fluid d-flex justify-content-between">
                <select class="form-control stdTheme " id="pdfSelect"  #pdfSelect style="width:auto;"  (change)="setPdfCur(pdfSelect.value)" >
                    <option class="optionSelect" *ngFor="let pdfCur of pdfFiles" [selected]="pdfCur.fileLabel === selectedPdf.fileLabel" >{{ pdfCur.fileLabel}}</option>
                </select>
            </div>
        </div>
    -->
    <div class="row">
        <div class="col-12" style="height: 75vh">
            <img [src]="imageToShow" alt="{{titleReport}}" *ngIf="!isImageLoading">
        </div>
    </div>
    
</div>