import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SchedulerJobInfosModel, SchedulerMessage } from '../models/schedulerJob.model';

@Injectable({
    providedIn: 'root'
  })
export class SchedulerService {

    httpHeaders: HttpHeaders;
    httpParams: HttpParams;

    constructor(public  httpClient: HttpClient) {
        
    }
    setHeaders(_headers: HttpHeaders) {
        this.httpHeaders = _headers;
    }
    getAllJobs(_action: string): Observable<HttpResponse<SchedulerJobInfosModel[]>> {
        const urlService = `${environment.baseUrl}/quartz/getAllJobs/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        return this.httpClient
        .post<SchedulerJobInfosModel[]>(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    saveJob(_action: string, schedulerJobInfos: SchedulerJobInfosModel): Observable<HttpResponse<SchedulerMessage>> {

        const urlService = `${environment.baseUrl}/quartz/saveOrUpdate/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);

        return this.httpClient
        .post<SchedulerMessage>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    runJob(_action: string, schedulerJobInfos: SchedulerJobInfosModel): Observable<HttpResponse<SchedulerMessage>> {

        const urlService = `${environment.baseUrl}/quartz/runJob/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);

        return this.httpClient
        .post<SchedulerMessage>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    pauseJob(_action: string, schedulerJobInfos: SchedulerJobInfosModel): Observable<HttpResponse<SchedulerMessage>> {

        const urlService = `${environment.baseUrl}/quartz/pauseJob/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);

        return this.httpClient
        .post<SchedulerMessage>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    resumeJob(_action: string, schedulerJobInfos: SchedulerJobInfosModel): Observable<HttpResponse<SchedulerMessage>> {

        const urlService = `${environment.baseUrl}/quartz/resumeJob/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);

        return this.httpClient
        .post<SchedulerMessage>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    deleteJob(_action: string, schedulerJobInfos: SchedulerJobInfosModel): Observable<HttpResponse<SchedulerMessage>> {

        const urlService = `${environment.baseUrl}/quartz/deleteJob/?` + 'action=' + _action;
        const localHttpHeaders: HttpHeaders  = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);

        return this.httpClient
        .post<SchedulerMessage>(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
