import { Injectable } from '@angular/core';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import * as locales from 'ngx-bootstrap/locale';
/* import { defineLocale, arLocale, bgLocale, caLocale, csLocale, daLocale, deLocale, enGbLocale, 
          esDoLocale, esLocale, esUsLocale, etLocale, fiLocale, frLocale, glLocale, heLocale, hiLocale, huLocale, 
          hrLocale, idLocale, itLocale, jaLocale, kaLocale, kkLocale, koLocale, ltLocale, lvLocale, mnLocale, nbLocale, 
          nlBeLocale, nlLocale, plLocale, ptBrLocale, roLocale, ruLocale, skLocale, slLocale, sqLocale, svLocale, thLocale, 
          thBeLocale, trLocale, ukLocale, viLocale, zhCnLocale  } from 'ngx-bootstrap/chronos';
*/

import { defineLocale, frLocale, enGbLocale } from 'ngx-bootstrap/chronos';

@Injectable({
  providedIn: 'root'
})
export class BootstrapI18nService {

  constructor(private _localeService: BsLocaleService) {
    defineLocale('fr', frLocale);
    defineLocale('en', enGbLocale);
   }

   public setLanguage(lang: string): void {
    this._localeService.use(lang);
   } 

}
