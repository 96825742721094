// https://github.com/envyN/ngx-widget-grid/blob/master/src/app/components/basic-example/basic-example.component.ts
import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReportInt, Widgets, UserEnvt } from '../../../../models/job-data.model';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../../services/translate.service';

@Component({
  selector: 'mdi-widget-grid-ngx',
  templateUrl: './widget-grid-ngx.component.html',
  styleUrls: ['./widget-grid-ngx.component.css']
})
export class WidgetGridNgxComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();

  @Input() statutCall: any;
  @Input() reportToExecute: ReportInt;
  @Input() titleReport: string;
 
  widgetMovable: boolean;
  widgetResizable: boolean;
  isEditOpen: boolean;
  isChangedDashBoard: boolean;
  widgetsList: any[];
  widgetsDisplay: any[];
  rowsGrid: number;
  colsGrid: number;
  displayTime: boolean;

  constructor( private _jobDataService: JobDataService, private _translate: TranslateService) { }

  ngOnInit() {

    this.isEditOpen = false;
    this.displayTime = false;
    this.widgetMovable = false;
    this.widgetResizable = false;
    this.isChangedDashBoard = false;
    const widthCur = 95;
    const heightCur = 95;
    document.getElementById('widget-grid').style.height = heightCur.toString() + '%';
    document.getElementById('widget-grid').style.width = widthCur.toString() + '%';
    this.initData();
  }
  initData() {
    let dasboard: Widgets = null;
    if (this.reportToExecute.reportChildren) {
      const jsonObject = JSON.parse(this.reportToExecute.reportChildren);
      dasboard = jsonObject.dashboard as Widgets;
    } else {
      return;
    }
    this.rowsGrid = dasboard.dashboardRows;
    this.colsGrid = dasboard.dashboardCols;
    this.widgetsList = [];
    const widget = {
      widgetName: '',
      widgetUpdate: 0,
      chartType: '',
      updateFrequency: 0,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      defaultDisplay: false,
      requestRef: '',
      requestRestrictions:  [],
    };

    this.widgetsList.push(widget, ...dasboard.widgets);
    this.widgetsDisplay = [];
    const dashboard = this._jobDataService.getUserEnvtElement('dashboard', this.reportToExecute.reportLabel);
    if (dashboard) {
      this.widgetsDisplay = dashboard.dashBoardDisplay;
      this.displayTime = dashboard.displayTime;
    } else {
      for (const widgetTmp of this.widgetsList) {
        if (widgetTmp.defaultDisplay) {
          this.widgetsDisplay.push(widgetTmp);
        }
      }
    }


/*
    this.rowsGrid = 20;
    this.colsGrid = 25;

    this.widgetsDisplay = [
      {
        top: 2,left: 2,height: 8,width: 10,
        chartType: 'bar',

      },
      {
        top: 2,left: 12,height: 8,width: 10,
        chartType: 'doughnut',
      },
      {
        top: 10,left: 2,height: 8,width: 10,
        chartType: 'line',
      },
      {
        top: 10,left: 12,height: 8,width: 10,
        chartType: 'pie',
      },
    ];
    */
  }
  saveUserEnvt() {
    console.log('saveUserEnvt :');
    let userEnvtCur  = this._jobDataService.getUserEnvt();
    const dashBoardCur = {
      dashboardName : this.reportToExecute.reportLabel,
      displayTime: this.displayTime,
      dashBoardDisplay : this.widgetsDisplay,
    };
    let dashBoardsTmp = '{\"dashboards\":[' + JSON.stringify(dashBoardCur) + ']}';
    if (!userEnvtCur) { // no userenvt

      userEnvtCur = {
        id: this._jobDataService.getUserLogged().id,
        userenvt: dashBoardsTmp,
      };

    } else {

      const dashboards  = this._jobDataService.getUserEnvtElement('dashboards', '');
      let userEnvtJson: any;
      if (!userEnvtCur.userenvt) {
        userEnvtJson = {};
      } else {
        userEnvtJson = JSON.parse(userEnvtCur.userenvt);
      }
      if (dashboards) { // dahsboards property exist
        let idx = 0;
        for (const dashboardRead of dashboards) {
          // const dashboardRead = JSON.parse(dashBoard);
          if (dashboardRead.dashboardName === this.reportToExecute.reportLabel ) {
            dashboards.splice(idx, 1);
            dashboards.push(dashBoardCur);
          }
          idx++;
        }
        dashBoardsTmp = '{\"dashboards\":' + JSON.stringify(dashboards) + '}';
        userEnvtJson['dashboards'] = JSON.stringify(dashboards);
      } else {
        userEnvtJson['dashboards'] =  [JSON.stringify(dashBoardCur)];
      } 

      userEnvtCur.userenvt = JSON.stringify(userEnvtJson);
    }
    this._jobDataService.setUserEnvt(userEnvtCur);

    this._jobDataService.saveEnvtUser()
    .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {

          this.isChangedDashBoard = false;
          this.isEditOpen = false;
        },
        () => {

        }
      );
  }
  onWidgetChange(event: any) {
    // console.log('onWidgetChange :' + event);
    const pos = event.newPosition;
    // console.log('onWidgetChange : ' + pos);
    const l_index = event.index;
    const l_width = pos.width;
    const l_height = pos.height;
    const l_left = pos.left;
    const l_top = pos.top;
    console.log('onWidgetChange : index > ' + this.widgetsDisplay[l_index].widgetName + ' width > ' + l_width + ' height > ' +
                l_height + ' left > ' + l_left + ' top > ' + l_top);
    if ((this.widgetsDisplay[l_index].left !== l_left) ||
        (this.widgetsDisplay[l_index].top !== l_top) ||
        (this.widgetsDisplay[l_index].height !== l_height) ||
        (this.widgetsDisplay[l_index].width !== l_width) ) {
          this.isChangedDashBoard = true;
          this.widgetsDisplay[l_index].left = l_left;
          this.widgetsDisplay[l_index].top = l_top;
          this.widgetsDisplay[l_index].height = l_height;
          this.widgetsDisplay[l_index].width = l_width;
      }
  }
  onGridFull(event: any) {
    console.log('onGridFull : ' + event);

  }
  setEditOption() {
    this.isEditOpen = !this.isEditOpen;
  }
  setDisplayTimeOption() {
    this.displayTime = !this.displayTime;
    if (this.displayTime) {
      this._jobDataService.displayGenerateTime.emit('displayTime');
    } else {
      this._jobDataService.displayGenerateTime.emit('hideTime');
    }
  }
  removeWidget(_index: any) {
    // console.log('removeWidget : ' + _index);
    this.widgetsDisplay.splice(_index, 1);
    this.isChangedDashBoard = true;
    
  }
  addWidgetSelect(_widgetSelected: any) {
    let widgetExists = false;
    if (_widgetSelected === '') { return; }
    for (const widgetTmp of this.widgetsList) {
      if (_widgetSelected === widgetTmp.widgetName) {
        for (const widgetDisplayed of this.widgetsDisplay) {
          if (widgetDisplayed.widgetName === _widgetSelected) {
            widgetExists = true;
            break;
          }
        }
        if (!widgetExists) {
          this.widgetsDisplay.push(widgetTmp);
          this.isChangedDashBoard = true;
        } else {
         //  console.log('widget existant : '+ _widgetSelected);
        }
      }
  }
  }
}
