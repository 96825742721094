<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span *ngIf="optionItem === 'boats'" style="margin-left: 45%; font-size:large">{{ 'boats' | translate }}</span>
      <span *ngIf="optionItem === 'jets'" style="margin-left: 45%; font-size:large">{{ 'jets' | translate }}</span>
      <span *ngIf="optionItem === 'trailers'" style="margin-left: 45%; font-size:large">{{ 'trailers' | translate }}</span>
      <span *ngIf="optionItem === 'engines'" style="margin-left: 45%; font-size:large">{{ 'engines' | translate }}</span>
    </div>
  </div>

  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px; overflow-x: hidden; overflow-y: hidden;">
    <div *ngIf="displayType === 'add' || displayType === 'search' || displayType === 'newsearch' || displayType === 'update'">
      <mdi-equipment-card 
                      [statutCall]="'direct'" 
                      [optionItem]="optionItem" 
                      [actionType]="displayType" 
                      [isBoatEquip]="false" 
                      [equipmentToUpdate]="wksEquipmentToUpdate"
                      [parentParam]="parentParam"
                      [hoursInput]="((optionItem === 'engines' || optionItem === 'jets') ? true: false)"
                      [randomIdString]="randomIdString" 
                      [ihmOptions]="ihmOptions"
              ></mdi-equipment-card>
    </div>
    <mat-sidenav-container *ngIf="displayType === 'list'" fxFlexFill class="container-sidenav w-100" autosize  
                  style="overflow-x: hidden; overflow-y: hidden;" (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav-90-100" 
                style="overflow-x: hidden; overflow-y: hidden;" disableClose>
        <div fxLayout="column" *ngIf="doCreateWork" style="height: 100%!important;overflow-x: hidden; overflow-y: hidden;">
          <div style="height: 100%!important;">
            <mdi-works-card [dataIn]="dataIn" 
                      [statutCall]="'sidenavEquip'"  
                      [actionType]="'addWork'"  
                      (onload)="ngOnInit()">
            </mdi-works-card>
          </div>
        </div>
        <div fxLayout="column" *ngIf="isRegisterEquip" style="height: 100%!important;overflow-x: hidden; overflow-y: hidden;">
          <a (click)="toggleSidenav('')" *ngIf="doCreateEquip" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}
            <a *ngIf="optionItem === 'boats'" > ({{ 'boats' | translate }})</a>
            <a *ngIf="optionItem === 'jets'">({{ 'jets' | translate }})</a>
            <a *ngIf="optionItem === 'trailers'">({{ 'trailers' | translate }})</a>
            <a *ngIf="optionItem === 'engines'">({{ 'engines' | translate }})</a>
          </a>
          <a (click)="toggleSidenav('')" *ngIf="!doCreateEquip" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}
              <a *ngIf="optionItem === 'boats'" > ({{ 'boats' | translate }})</a>
              <a *ngIf="optionItem === 'jets'">({{ 'jets' | translate }})</a>
              <a *ngIf="optionItem === 'trailers'">({{ 'trailers' | translate }})</a>
              <a *ngIf="optionItem === 'engines'">({{ 'engines' | translate }})</a>
          </a>
          <div style="height: 100%!important;overflow-x: hidden; overflow-y: hidden;">

            <mdi-equipment-card style="width: 100%; height: 100%!important;display: block;"  
                    [statutCall]="'sidenav'"
                    [optionItem]="optionItem" 
                    [actionType]="actionType" 
                    [isBoatEquip]="false" 
                    [equipmentToUpdate]="wksEquipmentToUpdate"
                    [parentParam]="parentParam"
                    [hoursInput]="((optionItem === 'engines' || optionItem === 'jets' || optionItem === 'boats') ? true: false)"
                    [randomIdString]="randomIdString" 
                    [ihmOptions]="ihmOptions"
            ></mdi-equipment-card>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px">

        <div class="container-fluid" style="height: 100%;">
          <div class="flex-row row stdTheme w-100" >
            <div class="col-4 d-flex justify-content-start align-items-center  " >
              <input type="text"  id="filter-text-box" matInput
                    style="height: 50px;"
                    placeholder="{{ 'global filter' | translate }}" 
                    (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
            </div>
            <div class="col-5 d-flex justify-content-start align-items-center  "  >
             
            </div>
             <div class="col-3 d-flex justify-content-end align-items-center">
              <button *ngIf="(optionItem === 'boats') || 
                                    (optionItem === 'jets') || 
                                    (optionItem === 'trailers') || 
                                    (optionItem === 'engines')" 
                      mat-icon-button class="buttonTheme" 
                     style="margin-left: 10px;margin-right: 10px;" (click)="addEquipment()">
                <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                      style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
              <button *ngIf="isInjected" mat-icon-button class="buttonTheme"  (click)="newsearch()" 
                  style="margin-left: 10px;margin-right: 10px;">
                <span class="fas fa-search" style="font-size: 20px" ></span>
              </button>
              
            </div>
          </div>
          <div style="width: 100%; height: 90%" >
            <ag-grid-angular 
              style="width: 100%; height: 100%;" 
              class="ag-theme-material"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [localeText]="localeText"
              [pagination]="true"
              [paginationAutoPageSize]="true"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<!-- popup voir :   https://www.c-sharpcorner.com/article/use-of-ngx-bootstrap-modal-popup-in-angular-8/ -->
<ng-template #modalWorks let-modal >
  <!--
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'works' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  -->
  <div class="modal-body modal-works-body" >
    <mdi-works-card [dataIn]="dataIn" 
                    [statutCall]="'modal'"  
                    [actionType]="'addWork'"  
                    (onload)="ngOnInit()">
    </mdi-works-card>
  </div>
  <!--
  <div class="modal-footer ">
    <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">
      <button type="button" mat-raised-button class="btn btn-primary buttonTheme" (click)="modalRef.hide()" >{{'Close' | translate}}</button>
    </div>
  </div>
  -->
</ng-template>

