import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { CommonNode } from '../../../../models/common.model';

@Component({
  selector: 'mdi-tree-common',
  templateUrl: './tree-common.component.html',
  styleUrls: ['./tree-common.component.css']
})
export class TreeCommonComponent implements OnInit, OnChanges {

  @Input() dataNodes: CommonNode[];
  @Output() treeNodeSelected: EventEmitter<any> = new EventEmitter<any>();
  treeControl = new NestedTreeControl<CommonNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CommonNode>();

  constructor() {

  }

  hasChild = (_: number, node: CommonNode) => !!node.children && node.children.length > 0;

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) { 
    this.dataSource.data = this.dataNodes;
  }
  onClick($event: any) {
    console.log($event);

    this.treeNodeSelected.emit($event);
  }

}
/*
    this._userService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListUsers();
      }
      if (event === 'deleteRegister') {
        this.getListUsers();
      }
    });
    this._userService.onRegisterValid.emit('closeRegister');
*/
