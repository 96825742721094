/**
 * Composant destiné à lister les groupes uttilisateurs .
 * Les fonctionnalités sont :
 *  - selection globale
 *  - selection par colonnes
 *  - ajouter / supprimer ds colonnes dans la liste affichée
 *  - gestion de la pagination
 *  - afficher le détail de le groupe sur le survol de sa ligne
 *  - éditer un groupe pour la modifier
 *  - supprimer un groupe
 *
 */
import { Component, OnInit, OnDestroy , ViewChild, AfterViewInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { animate, style, state, transition, trigger } from '@angular/animations';

import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { mjlusergroup_sch } from '../../../models/tables.model';
import { UsersGrpModel } from '../../../models/user.model';

import { FieldsListService } from '../../../job/common/components/dynamic-form/fields-list.service';
import { FieldsFormgroupService } from '../../../job/common/components/dynamic-form/fields-formgroup.service';

@Component({
  selector: 'mdi-usersgrp-table',
  templateUrl: './usersgrp-table.component.html',
  styleUrls: ['./usersgrp-table.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open <=> closed', animate('1000ms ease-in')),
    ])
  ],
  providers: [FieldsListService],
  encapsulation: ViewEncapsulation.None,
})
export class UsersgrpTableComponent implements OnInit, OnDestroy, AfterViewInit  {


    sidenavCollapsed = true;
    @ViewChild('sidenav', {static: false}) m_sidenav;
    dataSource = new MatTableDataSource();

    private loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();
    pageSize = 12;
    templateFor: string;
    // menu
    conditions: any;
    filterForm: FormGroup;
    columnsForm: FormGroup;
    opCtrl: FormControl;

    columnsChoiceCtrl: FormControl;
    forFirstValue: true;
    forSecondValue: true;
    hasNoValue: true;
    // fin menu
    // sidenav
    isFilterContent: boolean;
    isChoiceCol: boolean;
    isRegisterGrp: boolean;
    doCreateGrp: boolean;
    grpToUpdate: UsersGrpModel;
    statutRegister;

    loadingIndicator = true;
    reorderable = true;
    openFilters = false;

    stateFilters = 'closed';
    fields: any[];

    displayedColumns: any;

    dataColumnsName: any;
    dataColumnsDef = mjlusergroup_sch;
    dataColumns;
    selectedRowIndex;
    selected = [];
    cacheDatatemp = [];
    currentFilters;
    dateFormat;

  constructor( private renderer: Renderer2,
    private _userService: UserService,
    private _translate: TranslateService,
    private _fls: FieldsListService,
    private _ffgs: FieldsFormgroupService,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
      this.fields = _fls.getFields();
  }

  ngOnInit() {
    this.displayedColumns = [
      { prop: 'entity', name: this._translate.instant('entity'), isDate: false },
      { prop: 'usergroup', name: this._translate.instant('usergroup'), isDate: false },
      { prop: 'extendedaccess', name: this._translate.instant('extendedaccess'), isDate: false },
      { prop: 'supervisoremail', name: this._translate.instant('supervisoremail'), isDate: false },
    ];
    /// 'id', 'firstName', 'lastName', 'email', 'actions'];
    this.loadingSubject.next(true);
    this.getListGrps();
    this.conditions = CommonMethods.jsonToArray(environment.conditions);

    this.opCtrl = this.fb.control('');

    this.columnsChoiceCtrl = this.fb.control('', Validators.required);

    this.columnsForm = this.fb.group({
      columnsChoice: this.columnsChoiceCtrl,
    });
    this._ffgs.onFiltersChanged.subscribe((filters: any) => {
      this.dataSource.data = this.cacheDatatemp;
      const filtered = this.multiFilter(this.dataSource.data, filters);
      this.dataSource.data = filtered;
      this.currentFilters = filters;
    });

    this._userService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListGrps();
      }
      if (event === 'deleteRegister') {
        this.getListGrps();
      }
    });
  }
  getListGrps() {
    this._userService.getGrps()
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        // this.dataSource.data.sort((a: EntityModel, b: EntityModel) => a.entity - b.entity);
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;

      }
    );
  }
  ngOnDestroy() {

  }
  translateLabels() {

    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }

    this.dateFormat = this._translate.getLocalFmt('dateFormat');
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }

    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
      this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }

  ngAfterViewInit() {


  }

  toggleSidenav(_arg: string) {

    this.isFilterContent = false;
    this.isChoiceCol = false;
    this.isRegisterGrp = false;

    if (_arg === 'choiceCol') {
      this.isFilterContent = false;
      this.isChoiceCol = true;
      this.isRegisterGrp = false;
    }
    if (_arg === 'registerGrp') {
      this.isFilterContent = false;
      this.isChoiceCol = false;
      this.isRegisterGrp = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.m_sidenav.close();
    } else {
      this.m_sidenav.open();
    }
  }
  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }
  doAction(row): boolean {
    if (row.master) {
      return false;
    } else {
      return true;
    }
  }

  deleteGrp(row) {
    // console.log('deleteGrp Row clicked: ', row);

    this._userService.deleteGrp(row.entity, row.usergroup)
      .subscribe(
        () => {
          this._userService.onRegisterValid.emit('deleteRegister');
        },
        // () => this.registrationFailed = true
      );
  }
  editGrp(row) {
    this.grpToUpdate = row;
    // const localDate = new Date(row.birthday);
    // console.log('date ', localDate.getDate());
    // this.userToUpdate.birthday = localDate;
    this.statutRegister = 'updateGrp';
    // console.log('editGrp Row clicked: ', row);
    this.doCreateGrp = false;
    this.toggleSidenav('registerGrp');
  }
  addGrp() {
    // console.log('addGrp: ');
    this.statutRegister = 'createGrp';
    this.doCreateGrp = true;
    this.toggleSidenav('registerGrp');
  }
  columnFilter(colName: string, filterValue: string) {
    const filters = { [colName]: [filterValue] };
    const filtered = this.multiFilter(this.dataSource.data, filters);
    // update the rows
    this.dataSource.data = filtered;
    // console.log('columnFilter: ', colName, ' : ', filterValue);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.dataSource.data = this.cacheDatatemp;
    const filters = { firstName: [filterValue], lastName: [filterValue] };
    const filtered = this.multiFilter(this.dataSource.data, filters);
    // update the rows
    this.dataSource.data = filtered;

    // Whenever the filter changes, always go back to the first page
    // this.tableCur.offset = 0;
  }
  multiFilter(array: any[], filters: Object) {
    const colType = 'login';
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => {
          // return o[k].toLowerCase().includes(v);
          return this.filterComparator(o[k], v, k);
        }
        )
      )
    );
  }
  filterComparator(_dataValue: any, _dataFilter: any, _dataName: string): boolean {
    let dataType: string;
    this.dataColumnsDef.find(c => {
      if (c.colName === _dataName) {
        dataType = c.colType;
        return true;
      }
    });
    if (!dataType) {
      return false;
    }
    switch (dataType) {
      case 'string': {
        // console.log(_dataValue.toLowerCase(), '  ' , _dataFilter.toLowerCase());
        // return _dataValue.toLowerCase().includes(_dataFilter.toLowerCase());
        return new RegExp(_dataFilter, 'i').test(_dataValue);
      }
      case 'number': {
        return _dataValue <= _dataFilter;
      }
      default: {
        // return _dataValue.toLowerCase().includes(_dataFilter);
        return new RegExp(_dataFilter, 'i').test(_dataValue);
      }
    }

  }
  onFilterClick(templateFor: string) {
    this.templateFor = templateFor;
  }
  onFilterSubmit() {
    // console.log('onFilterSubmit ');
  }

  chkbColumnChoice(event) {
    // const checked = event.source.checked ? 'Oui' : 'Non';
    if (event.source.checked) {
      this.addColumn(event.source.id);
      this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
        this.dataColumnsName, this.displayedColumns, false);
    } else {
      this.removeColumn(event.source.id);
      this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
        this.dataColumnsName, this.displayedColumns, false);
    }
  }
  addColumn(_colAdded: string) {
    this.displayedColumns = CommonMethods.addOption(this.displayedColumns, this.dataColumnsDef, _colAdded);
    this.translateLabels();
  }
  removeColumn(_colDeleted: string) {
    this.displayedColumns = CommonMethods.removeOption(this.displayedColumns, _colDeleted);
  }

}
