import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { UserModel } from '../models/user.model';
import { UserService } from '../services/user.service';
import { TranslateService } from '../services/translate.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'mdi-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  user: UserModel;
  userEventsSubscription: Subscription;

  appTitle = environment.appTitle;
  appSigle = environment.appSigle;
  navigatorLang: string;

  constructor(private userService: UserService, private router: Router, private _translate: TranslateService) {
    this.navigatorLang = navigator.language; 
    _translate.setDefaultLang(this.navigatorLang);
  }

  ngOnInit() {
    this.userEventsSubscription = this.userService.userEvents.subscribe(user => this.user = user);
  }

  ngOnDestroy() {
    if (this.userEventsSubscription) {
      this.userEventsSubscription.unsubscribe();
    }
  }
  logout(event: any) {
    event.preventDefault();
    this.userService.logout();
    // this.router.navigate(['/login']);
    this.router.navigate(['']);
  }

}
