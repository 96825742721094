<div  id="actionsLists" class="container stdTheme" #refElement style="width: 100%;height: 100%; overflow-x: hidden; overflow-y: hidden;" >
  <div *ngIf="optionItem === 'supplies&parts'" style="width: 100%; overflow-x: hidden; overflow-y: hidden;" >
    <div class="flex-row row" style="width: 100%; margin: 0px; overflow-x: hidden; overflow-y: hidden;">
      <!--<div class="col-2"></div>-->
      <div class="col-11" style="margin-top: 10px">
        <h6>{{'supplies&parts' | translate}}</h6>
      </div>
      <div class="col">
        <button *ngIf="!isReadOnly" mat-icon-button (click)="addSupplies(0)">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex-row row" style="width: 100%; margin: 0px;overflow-x: hidden!important; overflow-y: auto!important;height: 550px!important">
      <div class="tableActionsReferenced actionsReferenced stdTheme col-12" style="padding-left: 0px;padding-right: 0px;" >

          <table class="listAction stdTheme" id="actionsSelected" style="padding: 5px" >
            <thead  style="margin-left: 5px; margin-right: 5px;">
              <tr class="thTaskRef">
                <th style="width: 5%; padding: 2px" >#</th>
                <th style="width: 5%; padding: 2px" >#</th>
                <th *ngIf="optionItem === 'supplies&parts'" [ngStyle]="{'width': (actionType === 'taskdef') ? '20%' : '20%', 'padding': '2px'}">
                  {{'suppliesLabel' | translate}}
                </th>
                <th  *ngIf="optionItem !== 'supplies&parts'" [ngStyle]="{'width': (actionType === 'taskdef') ? '40%' : '20%', 'padding': '2px'}">
                 {{'actionrefLabel' | translate}}
                </th>
                <th style="width: 10%; padding: 2px">{{'brandRef' | translate}}</th>
                <th style="width: 10%; padding: 2px">{{'billingUnit' | translate}}</th>
                <th style="width: 5%; padding: 2px">{{'quantity' | translate}}</th>
                <th *ngIf="optionItem === 'supplies&parts'"style="width: 10%; padding: 2px">{{'actionTask' | translate}}</th>
                
                <th *ngIf="(actionType === 'withprice')" style="width: 10%; padding: 2px">{{'unitPrice' | translate}}</th>
                <th *ngIf="actionType === 'withprice'" style="width: 10%; padding: 2px">{{'extPrice' | translate}}</th>
                <th *ngIf="actionType === 'withprice'" style="width: 5%; padding: 2px">{{'available' | translate}}</th>
                <th style="width: 5%; padding: 2px" >#</th>
              </tr>
            </thead>
            <tbody  style="margin-left: 5px; margin-right: 5px;">
              <tr class="trTaskRef curAction curActionDisplayed" *ngFor="let suppliesCur of suppliesList; let i = index"  cdkDrag>
                <td  style="width: 5%; padding: 2px" >
                  <mat-icon style="margin-top: 8px" class="reorder" cdkDragHandle>reorder</mat-icon></td>
                <td style="width: 5%; padding: 2px" >
                  <div *ngIf="!isReadOnly && (suppliesCur.quoted === null || suppliesCur.quoted === '') && !suppliesCur.isParentElement">
                    <button mat-icon-button (click)="removeSupplies(i)">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                  </div>
                </td>
                <td  [ngStyle]="{'width': (actionType === 'taskdef') ? '20%' : '20%', 'padding': '2px', 'text-align': 'left'}">
                  <input class="form-control inputTheme" name="actionrefLabel" #actionrefLabel value="{{suppliesCur.actionrefLabel}}"
                        matInput (change)="setChangeValues('actionrefLabel',actionrefLabel.value, i)"
                        [readonly]="true"
                  />
                </td>
                <td style="width: 10%; padding: 2px; text-align: left;">
                  <input class="form-control inputTheme" name="brandRef" #brandRef value="{{suppliesCur.brandRef}}"
                          matInput (change)="setChangeValues('brandRef',brandRef.value, i)"
                          [readonly]="true"
                  />
                </td>
                <td style="width: 10%; padding: 2px; text-align: center;">
                  <!--
                  <div *ngIf="suppliesCur.quoted === null || suppliesCur.quoted === ''">
                    <mat-select id="billingUnit"  readonly="((suppliesCur.quoted === null || suppliesCur.quoted === '') ? false : readonly)"
                            panelClass="mdiselectcontent" [(value)]="suppliesCur.billingUnit" [readonly]="true" >
                        <mat-option class="selectOptionsTheme" *ngFor="let unitsQuantityCur of unitsQuantity" 
                              (onSelectionChange)="onChangeUnitQuantity($event, i)"
                              [value]="unitsQuantityCur.value"  >{{unitsQuantityCur.label }}
                        </mat-option>
                    </mat-select>
                  </div>
                  -->
                  <div >
                    <input class="form-control inputTheme" name="billingUnit" #billingUnit value="{{suppliesCur.billingUnit | translate}}"
                             matInput 
                             [readonly]="true"
                    />
                  </div>
                </td>
                <!-- onchange="setQuantityValue('supplies',quantity.value, i)-->
                <td style="width: 5%; padding: 2px; text-align: right;">
                  <input class="form-control inputTheme" name="quantity" #quantity 
                        value="{{suppliesCur.quantity  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}"
                        matInput (change)="setQuantityValue('supplies',quantity.value, i)"
                        [readonly]="(isReadOnly ? true : (suppliesCur.quoted === undefined || suppliesCur.quoted === null || suppliesCur.quoted === '') ? false : true)"
                  />
                </td>
                <!-- *ngIf="optionItem === 'supplies&parts'"-->
                <td *ngIf="optionItem === 'supplies&parts'"  style="width: 10%; padding: 2px; text-align: right;">
                  <mat-select id="actionTask"  readonly="(isReadOnly ? true : (suppliesCur.quoted === null || suppliesCur.quoted === '') ? false : readonly)"
                      panelClass="mdiselectcontent" [(value)]="suppliesCur.actionTask"  >
                      <mat-option class="selectOptionsTheme" *ngFor="let actionTaskCur of taskActionsList" 
                            (onSelectionChange)="onChangeActionTask($event, i)"
                            [value]="actionTaskCur.value"  >{{actionTaskCur.label }}
                      </mat-option>
                  </mat-select>
                </td>
                <td *ngIf="actionType === 'withprice'"  style="width: 10%; padding: 2px; text-align: right;">
                  <input class="form-control inputTheme" name="unitPrice" #unitPrice 
                        value="{{suppliesCur.unitPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency]}}"
                        matInput (change)="setUnitPriceValue('supplies',unitPrice.value, i)"
                        [readonly]="(isReadOnly ? true : (suppliesCur.quoted === undefined || suppliesCur.quoted === null || suppliesCur.quoted === '') ? false : true)"
                  />
                </td>
                <td *ngIf="(actionType === 'withprice')"  style="width: 10%; padding: 2px; text-align: right;">
                  <input class="form-control inputTn  heme" name="extPrice" #extPrice 
                        value="{{suppliesCur.extPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency]}}"
                        matInput (change)="setExtPriceValue('supplies',extPrice.value, i)"
                        [readonly]="(isReadOnly ? true : (suppliesCur.quoted === undefined || suppliesCur.quoted === null || suppliesCur.quoted === '') ? false : true)"
                  />
                </td>
                <td *ngIf="actionType === 'withprice'"  style="width: 10%; padding: 2px; text-align: right;">
                  <input type="checkbox" id="available"  #available class="inputTheme" [disabled]="isReadOnly"
                                                [checked]="(suppliesCur.available !== undefined ) ? suppliesCur.available : false" 
                                                (change)="setChangeValues('available',available.checked, i)"/>
                  <!--
                  <input class="form-control inputTheme" name="available" #available value="{{suppliesCur.available}}"
                      matInput (change)="setChangeValues('available',available.value, i)"                
                  />
                  -->
                  <!--
                  <mat-checkbox class="inputTheme" name="available" #available [value]="suppliesCur.available"
                      (change)="setChangeValues('available',$event.checked, i)" ></mat-checkbox>
                  -->
                </td>
                <td style="width: 5%; padding: 2px" >
                    <!-- <button mat-icon-button (click)="addSupplies(i)">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button> -->
                </td>
              </tr>
            </tbody>
          </table>

      </div>
      <!--<div class="col-2"></div>-->
    </div>
  </div>
  <div *ngIf="optionItem === 'works'" style="width: 100%" >
    <div class="flex-row row" style="width: 100%; margin: 0px; overflow-y: auto;">
      <div class="actionsReferenced stdTheme col-4" >
        <h6>{{'actionsReferenced' | translate}}</h6>
        <mat-select *ngIf="!isReadOnly" id="workCode" placeholder="{{ 'workCode' | translate }}" #workCode
                panelClass="mdiselectcontent"   ngDefaultControl 
                (selectionChange)="onChangeWork(workCode.value)" >
            <mat-option class="selectOptionsTheme" *ngFor="let workCodeCur of workCodeList" 
                [value]="workCodeCur.value"  >{{workCodeCur.label}}
            </mat-option>
        </mat-select>
      </div>
      <div  *ngIf="!isReadOnly" class="flex-row row" style="width: 100%; margin: 0px">
        <div class="col-3" style="padding: 0px">
          <button mat-icon-button matPrefix aria-label="search" >
              <mat-icon>search</mat-icon>
          </button>
          <input class="searchTheme" #filterActions placeholder="{{ 'filterInput' | translate }}" 
                  (keyup)="onKeyFilterActions($event)" >
          <button mat-icon-button matSuffix aria-label="clear" 
                  *ngIf="filterActions.value" (click)="filterActions.value=''; onKeyFilterActions('');">
              <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="col-2">

        </div>

        <div class="col-7">
          <h6>{{'actionsSelected' | translate}}</h6>
        </div>
      </div>
      <div class="flex-row row" style="width: 100%; ">
        <div class="col-4 ">
          <table class="listAction stdTheme" id="actionsReferenced"  cdkDropList [cdkDropListData]="actionsReferenced"
              [cdkDropListConnectedTo]="secondList" #firstList="cdkDropList"
              (cdkDropListDropped)="drop($event) ">
            <thead class="theadTaskRef" style="width: 100%; margin-left: 5px; margin-right: 5px;" >
              <th  colspan="3" class="trTaskRef">
                {{'actionrefLabel' | translate}}
                <span style="margin-left: 80px; margin-right: 2px;">
                  <button *ngIf="!isReadOnly" mat-button (click)="openActionModal()" style="padding-right: 0px; padding-left: 0px;">
                    {{ 'create action' | translate }}
                    <i class="fa fa-plus-square" style="margin-left: 10px;font-size: 20px" ></i>
                  </button>
                </span>
              </th>
            </thead>
            <tbody class="tbodyTaskRef" style="margin-left: 5px; margin-right: 5px;">
              <tr class=" trTaskRef curAction curActionReferenced" *ngFor="let referencedCur of actionsReferenced; let i = index"  cdkDrag>
                <td style="width: 5%" ><mat-icon class="reorder" cdkDragHandle>reorder</mat-icon></td>
                <td style="width: 70%; text-align: left;">{{referencedCur.actionrefLabel}}</td>
                <td style="width: 5%" >
                  <input type="checkbox"  id="referenced" #referenced [disabled]="isReadOnly"
                          [(checked)]="referencedCur.selected" (change)="checkChange('checked',referenced.checked,i)">
                </td>
              </tr>
            </tbody>
          </table>
          </div>


        <div id="columnCentral" class="columnCentralTask stdTheme col-1"  >
          <p>
            <button class="righttoleft" style="width:100%" (click)="selectedToReferenced();" [disabled]="!toReferenced || isAlreadyQuoted || isReadOnly"> 
              <i class='fa fa-angle-double-left fa-1x'></i>
            </button>
          </p>
          <p>
            <button class="lefttoright" style="width:100%" (click)="referencedToSelected('');" [disabled]="!toSelected || isReadOnly">
              <i class='fa fa-angle-double-right fa-1x'></i>
            </button>
          </p>
          <p>
            <button class="lefttoright" style="width:100%" (click)="referencedToSelected('checked');" [disabled]="!toSelected || isReadOnly">
              <i class='fa fa-angle-right fa-1x'></i>
            </button>
          </p>
          <!--
          <span class="centralIcon" style="position: absolute; top:40vh; left: 30px;"><i class='fa fa-angle-double-left'></i></span>
          <span class="centralIcon" style="position: absolute; top:45vh; left: 30px;"><i class='fa fa-angle-double-right'></i></span>
        -->
        </div>
        <div class="actionsSelected stdTheme col-7" >
          <table class="tableTaskRef listAction stdTheme" id="actionsSelected" cdkDropList [cdkDropListData]="actionsSelected"
              [cdkDropListConnectedTo]="firstList" #secondList="cdkDropList"
              (cdkDropListDropped)="drop($event) ">
            <thead class="theadTaskRef" style="width: 100%">
              <!-- [ngStyle]="{'margin-top': '10px', width: (((isBoat && !isSearch && !isBoatEquip && isBoatRegistered) || (statutCall === 'tasks')) ? '100%' : (isBoatEquip) ? '100%' : '50%'),
                          padding: (isBoatEquip) ? '5px' : '10px'}"-->
              <th style="width: 5%; padding: 0px" ></th>
              <th style="width: 5%; padding: 0px">{{'ranking' | translate}}</th>
              <th [ngStyle]="{'width': (actionType === 'taskdef') ? '40%' : '40%', 'padding': '0px'}">{{'actionrefLabel' | translate}}</th>

              <th style="width: 10%; padding: 0px">{{'billingUnit' | translate}}</th>
              <th style="width: 5%; padding: 0px">{{'duration' | translate}}</th>
              <th *ngIf="(actionType === 'withprice')" style="width: 10%; padding: 2px">{{'unitPrice' | translate}}</th>
              <th *ngIf="actionType === 'withprice'" style="width: 10%; padding: 2px">{{'extPrice' | translate}}</th>

            </thead>
            <tbody  class="tbodyTaskRef" >
              <tr class="trTaskRef curAction curActionDisplayed" *ngFor="let selectedCur of actionsSelected; let i = index"  cdkDrag>
                <td style="width: 5%; padding: 0px" >
                  <div *ngIf="(!isReadOnly && (selectedCur.quoted === undefined || selectedCur.quoted === null || selectedCur.quoted === ''))">
                    <mat-icon class="reorder" cdkDragHandle>reorder</mat-icon>
                  </div>
                </td>
                <td style="width: 5%; padding: 0px">{{selectedCur.ranking }}</td>
                <td [ngStyle]="{'width': (actionType === 'taskdef') ? '40%' : '40%', 'padding': '0px', 'text-align': 'left'}">{{selectedCur.actionrefLabel}}</td>
                <td style="width: 10%; padding: 0px; text-align: center;">{{selectedCur.billingUnit | translate}}</td>
                <td style="width: 5%; padding: 0px; text-align: right;">
                  <input class="form-control inputTheme" name="quantity" #quantity 
                      value="{{selectedCur.quantity | formatNumber : ['quantity',numberFormat.locale,0,2]}}"
                        matInput (change)="setQuantityValue('works',quantity.value, i)"
                        [readonly]="(isReadOnly ? true: (selectedCur.quoted === undefined || selectedCur.quoted === null || selectedCur.quoted === '') ? false : true)"
                  />
                </td>
                <td *ngIf="(actionType === 'withprice')"  style="width: 10%; padding: 2px; text-align: right;">
                  <input class="form-control inputTheme" name="unitPrice" #unitPrice 
                        value="{{selectedCur.unitPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                        matInput (change)="setUnitPriceValue('works',unitPrice.value, i)"
                        [readonly]="(isReadOnly ? true:  (selectedCur.quoted === undefined || selectedCur.quoted === null || selectedCur.quoted === '') ? false : true)"
                  />
                </td>
                <td *ngIf="actionType === 'withprice'"  style="width: 10%; padding: 2px; text-align: right;">
                  <input class="form-control inputTheme" name="extPrice" #extPrice 
                        value="{{selectedCur.extPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                        matInput (change)="setExtPriceValue('works',extPrice.value, i)"
                        [readonly]="(isReadOnly ? true: (selectedCur.quoted === undefined || selectedCur.quoted === null || selectedCur.quoted === '') ? false : true)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #actionModal style="width: 100%">
  <!--
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  -->
  <div class="modal-body" style="height: 500px;padding: 0px">
      <mdi-actionsref-card
        [statutCall]="'modal'" 
        [optionItem]="'param_actionsref'" 
        [actionType]="'add'" 
        [dataUpstream]="dataUpstream"
        [actionsrefToUpdate]="undefined">
      </mdi-actionsref-card>
  </div>
   
</ng-template>
<ng-template #searchModal style="width: 100%">

    <div class="modal-content" >
      <!--
      <div class="modal-header" style="padding-top: 0px;padding-bottom: 0px;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      -->
      <div class="modal-body" style="padding: 0px">
          <mdi-parts-supplies-search
            (dataOut)="addListSuppliesModal($event)"
            [statutCall]="'modal'" 
            [optionItem]="'param_actionsref'" 
            [actionType]="'search'"
            [wksEntity]="wksEntity" 
            [suppliesList]="suppliesList"
            [brandName]="(parentTask.parentParam ? parentTask.parentParam.equipBrand ? parentTask.parentParam.equipBrand : undefined : undefined )"
            >
          </mdi-parts-supplies-search>
      </div>
    </div>

</ng-template>
