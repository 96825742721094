import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { JobDataService } from '../../../services/job-data.service';
import { ReportInt, RequestInt, SubReports, SubReport } from '../../../models/job-data.model';

@Component({
  selector: 'mdi-display-pdf',
  templateUrl: './display-pdf.component.html',
  styleUrls: ['./display-pdf.component.css']
})
export class DisplayPdfComponent implements OnInit {

  @Input() statutCall: any;
  @Input() reportToExecute: ReportInt;
  @Input() titleReport: string;
  @Input() dataRecord: any;

  @Input() reportOrig: ReportInt;
  @Input() reportNameToExecute: string;

  @ViewChild('pdfViewer', {static: false}) pdfViewer: any;

  private readonly onDestroy = new Subject<void>();

  pdfFiles: any[];
  dataSource: any[];
  dataLoaded: boolean;
  selectedPdf: any;
  pdfDisplay: any;
  dataPdfLoaded: boolean;
  requestCur: RequestInt;
  subReportData: any[];

  constructor(private _jobDataService: JobDataService) { }

  ngOnInit() {

    this.dataPdfLoaded = false;
    this.dataLoaded = false;
    if (this.statutCall === 'subReportPdfViewer') {
      this.loadReport();
    } else {
      this.loadData();
    }

  }
  loadReport() {
    let subReports: SubReports = null;
    const jsonObject = JSON.parse(this.reportOrig.reportChildren);
    subReports = jsonObject.subreports as SubReports;
    const subReportAction = this.reportNameToExecute;
    let subReportToExecute: SubReport;
    for (const subReportCur of subReports.subReport) {
      if (subReportCur.reportInitialAction === subReportAction) {
        subReportToExecute = subReportCur;
        break;
      }
    }
    this.subReportData = [];
    for (const foreignKey of subReportToExecute.foreignKeys) {
      const subData = {
        colName: foreignKey.colTarget,
        colValue: this.dataRecord[foreignKey.colOrig],
      };
      // this.subReportData[foreignKey.colTarget] = dataRecord[foreignKey.colOrig];
      this.subReportData.push(subData);
    }

    if (subReportToExecute.listColRecall) {
      this.titleReport = subReportToExecute.reportName + ' : ';
      // const listCol = subReportToExecute.listColRecall.split(',');
      for (const dataLabel of subReportToExecute.listColRecall) {
        this.titleReport += ' \ ' + this.dataRecord.data[dataLabel];
      }
    }
    this.getLastVersionReport(subReportToExecute.reportName)
    .then ((reportCur: ReportInt) => {

      this.reportToExecute = reportCur;
      this.loadData();
    })
    .then((error) => {

    });
  }
  getLastVersionReport(_reportLabel: string)  {
    const dataResponse: ReportInt = null;
    return new Promise((resolve, reject) => {
      this._jobDataService.getLastVersionReport(_reportLabel)
      .pipe(takeUntil(this.onDestroy))
      .subscribe (
        data => {
          // dataResponse = data.body;
          resolve(data.body);
        }, err => {
          // dataResponse = null;
          reject(err);
        }
      );
    });

  }
  loadData() {
    this.getRequest() // read request
    .then (() => {
        this.launchReport() // reading labels
        .then ((dataList: any[]) => {
          this.generatePdfList(dataList);
        })
        .then((error) => { // error read label
          // console.log('Label :'+ error);
        });

      })
    .then((error) => { // error reading request
      // console.log('Request :'+ error);
    });
  }
  generatePdfList(dataList: any[]) {
    this.loadListPdf(dataList)
    .then (() => {
      if (this.pdfFiles[0] === undefined) { return; }
      this.loadPdfFile(this.pdfFiles[0].filePath)
        .then ((res) => {
          this.dataPdfLoaded = true;

            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfFiles[0].localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf

          /*
          this.pdfDisplay = url;
          this.pdfFiles[0].localUrl = url;*/
        })
        .then((error) => { // error read label
          //  console.log('Read data :'+ error);
        });
    })
  .then((error) => { // error read label
    //  console.log('Read data :'+ error);
    });
  }
  getRequest() {

    this.requestCur = null;
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestRef(this.reportToExecute.requestRef, true)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
          data => {
            this.requestCur  = data.body[0];
            resolve();
          }, err => {
            this.requestCur = null;
            reject(err);
          }
        );
    });
  }
  launchReport() {

    // console.log('deleteEntity Row clicked: ', row);
    // let messageTranslate: string;
    let nbLimit = this.reportToExecute.reportSample;
    if (this.statutCall === 'execute') { nbLimit = 10000; }

    return new Promise((resolve, reject) => {
      this._jobDataService.launchReport(this.reportToExecute, nbLimit)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes) => {
          const response = JSON.parse(JSON.stringify(retMes.body));
          // console.log(retMes.body);
          this.dataSource = retMes.body;
          this.dataLoaded = true;
          resolve(retMes.body);
        }, err => {

          reject(err);
        }
      );
    });
  }
  loadListPdf(_dataList: any[]) {
    this.pdfFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of _dataList ) {
        const fileCur =    {
          fileName: docCur[this.requestCur.docFilename],
          fileLabel: docCur[this.requestCur.docName],
          filePath: docCur[this.requestCur.docPath] + '/' + docCur[this.requestCur.docFilename],
          localUrl: undefined
        };
        this.pdfFiles.push(fileCur);
      }
      this.selectedPdf = this.pdfFiles[0];

      if (this.pdfFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  loadPdfFile(_path: any) {
    return new Promise((resolve, reject) => {
      this._jobDataService.pdfViewerRequest(_path)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes) => {
          // const response = JSON.parse(JSON.stringify(retMes.body))
          // console.log(retMes.body);
          const response = retMes.body;

          // this.pdfDisplay = new Uint8Array(response[0]);
          const pdfData = new Blob([response], { type: 'application/pdf', });
          const url = URL.createObjectURL(pdfData);
          // this._sanitizer.bypassSecurityTrustUrl(url);

          this.dataPdfLoaded = true;
          resolve(response);
        }, err => {

          reject(err);
        }
      );
    });
  }
  setPdfCur(_pdfName: any) {

    for (const pdfSelected of this.pdfFiles) {
      if (pdfSelected.fileLabel === _pdfName) {

        if ( pdfSelected.localUrl !== undefined) {
          this.pdfDisplay = pdfSelected.localUrl;
          this.pdfViewer.pdfSrc = pdfSelected.localUrl;
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        } else {
          this.loadPdfFile(pdfSelected.filePath)
          .then ((res) => {
            this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            pdfSelected.localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
            /*
            this.pdfDisplay = url;
            pdfSelected.localUrl = url;*/
          })
          .then((error) => { // error read label
            //  console.log('Read data :'+ error);
          });
        }
        break;
      }
    }
  }
  afterLoadPdfComplete(event: any) {
    console.log('pdf loaded)');
  }
}
