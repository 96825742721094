import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '../../../../../../services/translate.service';
@Component({
  selector: 'mdi-calendar-header',
  template: `
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
            [excludeDays]="excludeDays"
          >
          {{'Calendar.previous'| translate}}
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
          {{'Calendar.today'| translate}}
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
            [excludeDays]="excludeDays"
          >
          {{'Calendar.next'| translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn "
            (click)="viewChange.emit('month')"
            [class.btn-outline-secondary]="view === 'month'"
            [class.btn-primary]="view !== 'month'"
          >
          {{'Calendar.month'| translate}}
          </div>
          <div
            class="btn "
            (click)="viewChange.emit('week')"
            [class.btn-outline-secondary]="view === 'week'"
            [class.btn-primary]="view !== 'week'"
          >
          {{'Calendar.week'| translate}}
          </div>
          <div
            class="btn"
            (click)="viewChange.emit('day')"
            [class.btn-outline-secondary]="view === 'day'"
            [class.btn-primary]="view !== 'day'"
          >
          {{'Calendar.day'| translate}}
          </div>
        </div>
      </div>
    </div>
    <br />
  `
})
export class CalendarHeaderComponent {
  @Input() view: string;
  @Input() viewDate: Date;
  @Input() locale: string;
  @Input() excludeDays: number[];

  @Output() viewChange: EventEmitter<string> = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  constructor( private _translate: TranslateService) {
  }
}
