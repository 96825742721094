<div class="um container-fluid " >
  <div class="row">
    <div class="homeTheme container-fluid text-center col" style="margin-top: 75px">
      <h1 class="homeTheme" >{{appSigle}} <small>{{appTitle}}</small></h1>
      <div class="container-fluid"  *ngIf="!user;">
        <mdi-login></mdi-login>
      </div>
    </div>
  </div>
</div>
