<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="formFields" class="stdTheme">

    <div [formGroup]="formFields" class="form-group row flex-v-center">
      <span *ngFor="let field of fields" class="form-inline stdTheme" >
          <span [ngSwitch]="field.controlType">
              <span *ngSwitchCase="'textbox'" >
                <mat-form-field  class="mdiinput stdTheme" appearance="standard" style="padding-left: 15px">
                  <mat-label style="font-size: 12px">{{ field.label | translate }}</mat-label>
                  <input matInput [name]="field.key" [id]="field.key" [type]="field.type"  [formControlName]="field.key" 
                  (keyup)="applyFilter()" class="imputTheme">
                </mat-form-field>
              </span>
              <!--
                <select [id]="field.key" *ngSwitchCase="'dropdown'" [formControlName]="field.key">
                  <option *ngFor="let opt of field.optionsDD" [value]="opt.key">{{opt.value}}</option>
                </select>
              -->
          </span>
      </span>
    </div>
    <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px; margin-bottom: 10px">
      <button type="submit" [disabled]="!formFields.valid"  
                  mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Apply' | translate}}
    </button>
    </div>
  </form>
<!--
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
-->
</div>
