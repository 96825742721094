import { Injectable, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FieldBase } from './field-base';

@Injectable()
export class FieldsFormgroupService {

  constructor() { }

  public onFiltersChanged: EventEmitter<string> = new EventEmitter<string>();
  public onFiltersClosed: EventEmitter<string> = new EventEmitter<string>();

  toFormGroup(_fields: FieldBase<any>[] ) {
    const group: any = {};

    // tslint:disable-next-line:no-shadowed-variable
    _fields.forEach(_fields => {
      group[_fields.key] = _fields.required ? new FormControl(_fields.value || '', Validators.required)
                                              : new FormControl(_fields.value || '');
    });
    return new FormGroup(group);
  }
}
