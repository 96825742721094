<div class="container-fluid" style="height: 800px;">
    <div fxFlex style="font-size: small;padding-top: 0.5%;">
        <mdi-topbar [isHome]="false" [isReportHome]="true"></mdi-topbar>
    </div>
    <!-- <div class="row">-->
    <div class="text-center w-100" style="margin-top: 75px; height: 100%;">
       <mat-sidenav-container  class="container-sidenav row w-100 stdTheme"  style="height: 100%;">
            <mat-sidenav #sidenav  [fixedInViewport]="true" [fixedTopGap]="70"  [fixedBottomGap]= "20" 
                                    fxLayout="column" role="navigation" mode="push" opened="false" position="start" >
                <div class="d-flex justify-content-between stdTheme" style="margin: 10px;width: 120px" >
                  
                    <button mat-icon-button  (click)="displayHome()" >
                        <mat-icon>home</mat-icon>
                    </button>
                    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav('')" >
                                <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
                    </button>
                    <!--<a href="#" data-toggle="tooltip" title="Hooray!">
                        <span class="badge badge-success">{{capitalize('Hooray')}}</span>
                        <span style="width:85px;height:85px;background:#cc0000;font:bold 13px Arial;border-radius:50%;border:none;color:#fff;">
                                {{capitalize('Hooray')}}
                        </span>
                    </a>-->
                        
                        <!--<button type="button" class="btn btn-circle btn-success" style="border-radius: 50%;font-size: small;">{{capitalize('Hooray')}}</button>-->
                </div>
                <!-- https://angular2-tree.readme.io/docs/templates -->
                <div fxLayout="column" class="mat-menu" style="font-size: small;" >
                    <mdi-tree-common [dataNodes]="dataNodes" (treeNodeSelected)="onReceptAction($event)"> </mdi-tree-common>
                </div>
            </mat-sidenav>

            <mat-sidenav-content class="stdTheme" [fixedInViewport]="true" [fixedTopGap]="70"  [fixedBottomGap]= "20" style="height: 100%;">
               
                <button  type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav('')" >
                    <mat-icon aria-label="Side nav toggle icon">more_vert</mat-icon>
                </button>
                <div *ngIf="!isAgGrid && !isDashboard" fxFlex fxLayout fxLayoutAlign="center">
                    <h1>MJL-Data <small>{{ 'reports validated' | translate }}</small></h1>
                </div>
                <div class ="w-100" *ngIf="isAgGrid">
                    <mdi-report-grid [titleReport]="reportToExecute.reportLabel" 
                                    [statutCall]="'execute'" 
                                    [reportToExecute]="reportToExecute" 
                                    (onload)="ngOnInit()">
                    </mdi-report-grid>
                </div>
                <div *ngIf="isDashboard">
                    <div class="container-fluid" >
                        <div class="row" style="width: 100%; height: 95%">
                            <mdi-widget-grid-ngx  [statutCall]="'testReport'" [reportToExecute]="reportToExecute" (onload)="ngOnInit()"></mdi-widget-grid-ngx>
                        </div>
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>  
