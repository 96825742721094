
<div *ngIf="currentValue !== ''" class="container-fluid " style="height: 100%;width: 350px" >
    <!--{{currentValue  | translate }}-->
    <div class="flex-row row  w-100 rowOne"  >
        <div class="col-12" style="padding-left: 0px;padding-right: 0px;">
            {{currentValue }}
        </div>
    </div>
</div>

<div *ngIf="!allSN" >
    <div class="container-fluid " style="height: 100%;width: 350px">
        <div *ngFor="let itemRow of rowsSN;let i = index;">
            <div *ngIf="nbRows === 1">
                <div class="flex-row row  w-100 rowOne"  >
                    <div class="col-12" style="padding-left: 0px;padding-right: 0px;">
                        {{itemRow }}
                    </div>
                </div>
            </div>
            <div *ngIf="nbRows > 1">
                <div class="flex-row row  w-100 rowlist"  >
                    <div class="col-12" style="padding-left: 0px;padding-right: 0px;">
                        {{itemRow }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
