/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datadetail-renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../grid/report-grid.component.ngfactory";
import * as i3 from "../../grid/report-grid.component";
import * as i4 from "../../../../../../../services/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../../services/translate.service";
import * as i7 from "../../../../../services/job-data.service";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "./datadetail-renderer.component";
var styles_DataDetailRendererComponent = [i0.styles];
var RenderType_DataDetailRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataDetailRendererComponent, data: {} });
export { RenderType_DataDetailRendererComponent as RenderType_DataDetailRendererComponent };
function View_DataDetailRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_DataDetailRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mdi-report-grid", [], null, null, null, i2.View_ReportGridComponent_0, i2.RenderType_ReportGridComponent)), i1.ɵdid(2, 638976, null, 0, i3.ReportGridComponent, [i4.UserService, i5.Router, i6.TranslateService, i7.JobDataService, i8.BsModalService, i9.DomSanitizer], { statutCall: [0, "statutCall"], reportToExecute: [1, "reportToExecute"], titleReport: [2, "titleReport"], subReportData: [3, "subReportData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "execute"; var currVal_1 = _co.reportToExecute; var currVal_2 = _co.reportLabel; var currVal_3 = _co.subReportData; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DataDetailRendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { detailZoom: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "full-width-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "full-width-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataDetailRendererComponent_1)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["detailZoom", 1]], null, 0, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataDetailRendererComponent_2)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isGrid; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isGrid; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zoomTitle; _ck(_v, 4, 0, currVal_0); }); }
export function View_DataDetailRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-datadetail-renderer", [], null, null, null, View_DataDetailRendererComponent_0, RenderType_DataDetailRendererComponent)), i1.ɵdid(1, 180224, null, 0, i11.DataDetailRendererComponent, [i1.Renderer2, i7.JobDataService], null, null)], null, null); }
var DataDetailRendererComponentNgFactory = i1.ɵccf("mdi-datadetail-renderer", i11.DataDetailRendererComponent, View_DataDetailRendererComponent_Host_0, {}, {}, []);
export { DataDetailRendererComponentNgFactory as DataDetailRendererComponentNgFactory };
