import { Component, OnInit, ViewChild, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { WksCentralService } from '../../../../services/wks-central.service';
import { FileToDisplay } from '../../../../../../models/common.model';
import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';

@Component({
  selector: 'mdi-works-pdf',
  templateUrl: './works-pdf.component.html',
  styleUrls: ['./works-pdf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksPdfComponent implements OnInit,  OnDestroy {

  @Input() statutCall: any;
  @Input() dataList: FileToDisplay[];

  
  @ViewChild('pdfViewer', {static: false}) pdfViewer: any;
  private readonly onDestroy = new Subject<void>();
  subscription: Subscription;
  pdfFiles: any[];
  displayClose: boolean;
  selectedPdf: any;
  pdfDisplay: any;
  dataPdfLoaded: boolean;
  callChain: string;
  isQuoteView: boolean;
  toValidated: boolean;
  toApproved: boolean;
  toRefused: boolean;
  toRead: boolean;

  constructor(private _globalEventsManagerService: GlobalEventsManagerService,
                  private _wksCentralService: WksCentralService,
                  private dialogRef: MatDialogRef<WorksPdfComponent>,
                  @Inject(MAT_DIALOG_DATA) data: any) { 
                    this.initBooleans();
                    if (data !== undefined) { 
                      this.statutCall = data.statutCall;
                      this.initCallParam();
                      this.dataList = data.dataList;
                      if ((this.dataList.length !== 0) || (this.dataList[0] !== undefined)) {
                        this.generatePdfList();
                       } else {
                         this.dataPdfLoaded = true;
                       }
                    }
                  }

  ngOnInit() {

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.dialogRef.close();
  }
  initBooleans() {

  this.dataPdfLoaded = false;
  this.displayClose = false;
  this.toValidated = false;
  this.toRead = false;
  this.toApproved = false;
  this.toRefused = false;
  }
  initCallParam() {
    this.callChain = this.statutCall as string;
    if (this.callChain.startsWith('quotes')) { 
      this.displayClose = true;
    }
    if (this.callChain.startsWith('invoice')) { 
      this.displayClose = true;
    }
    if (this.callChain.includes('read')) { 
      this.toRead = true;
    }
    if (this.callChain.includes('approved')) { 
      this.toApproved = true;
    }
    if (this.callChain.includes('validated')) { 
      this.toValidated = true;
    }
    if (this.callChain.includes('refused')) { 
      this.toRefused = true;
    }
  }
  generatePdfList() {
    this.loadListPdf()
    .then (() => {
      if (this.pdfFiles[0] === undefined) { return; }
      this.loadPdfFile(this.pdfFiles[0].filePath)
        .then ((res) => {
          this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfFiles[0].localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        })
        .then((error: any) => { // error read label
          //  console.log('Read data :'+ error);
        });
    })
  .then((error) => { // error read label
    //  console.log('Read data :'+ error);
    });
  }
  loadListPdf() {
    this.pdfFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of this.dataList ) {
        const fileCur =    {
          fileLabel: (docCur.fileLegend ? docCur.fileLegend : docCur.fileName),
          fileLegend: (docCur.fileLegend ? docCur.fileLegend : docCur.fileName),
          filePath: docCur.filePath,
          fileRef: (docCur.fileRef ? docCur.fileRef : undefined),
          dataImgLoaded: false,
          imageToShow: undefined,
        };
        this.pdfFiles.push(fileCur);
      }
      this.pdfFiles.sort((obj1, obj2) => {
        return obj1.fileLabel < obj2.fileLabel ? 1 : -1;
      });
      this.selectedPdf = this.pdfFiles[0];
      // const status = this.pdfFiles[0].filePath.split('/').pop();
      const status: string[] = this.pdfFiles[0].filePath.split('/');
      const item = status.length - 2;
      if (!this.toRead) {
        this.toValidated = (status[item] === 'new' ? true : false);
        this.toApproved = (status[item] === 'submitted' ? true : false);
      }
// var filename = nameString.split("/").pop();
      if (this.pdfFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  loadPdfFile(_path: any) {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getPdfDoc(_path)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          const pdfData = new Blob([response], { type: 'application/pdf', });
          const url = URL.createObjectURL(pdfData);
          this.dataPdfLoaded = true;
          resolve(response);
        }, err => {
          reject(err);
        }
      );
    });
  }
  setPdfCur(_pdfName: any) {

    for (const pdfSelected of this.pdfFiles) {
      if (pdfSelected.fileLabel === _pdfName) {

        if ( pdfSelected.localUrl !== undefined) {
          this.pdfDisplay = pdfSelected.localUrl;
          this.pdfViewer.pdfSrc = pdfSelected.localUrl;
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        } else {
          this.loadPdfFile(pdfSelected.filePath)
          .then ((res) => {
            this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            pdfSelected.localUrl = res;
            this.selectedPdf = pdfSelected;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
            /*
            this.pdfDisplay = url;
            pdfSelected.localUrl = url;*/
          })
          .then((error) => { // error read label
            //  console.log('Read data :'+ error);
          });
        }
        break;
      }
    }
  }
  afterLoadPdfComplete(event: any) {
    console.log('pdf loaded)');
  }
  closeEvent($event: Event)  {
    /*
    if ((this.callChain.startsWith('quotes'))  && (this.toValidated))  { 
      this._globalEventsManagerService.synchroEvents.next('removeQuoteModal__' + this.selectedPdf.fileRef);
    }
    */
    if ((this.callChain.startsWith('quotes'))  && ((this.toRead) || (this.toApproved) || (this.toValidated)))  { 
      this._globalEventsManagerService.synchroEvents.next('closeQuoteModal__' + this.selectedPdf.fileRef);
    }
    if ((this.callChain.startsWith('invoice'))  && ((this.toRead) || (this.toApproved) || (this.toValidated)))  { 
      this._globalEventsManagerService.synchroEvents.next('closeInvoiceModal__' + this.selectedPdf.fileRef);
    }
    this.dialogRef.close('closeEvent');
    return;
  }
  validateEvent($event: Event)  {
    
    if ((this.callChain.startsWith('quotes')) && (this.toValidated) )  { 
      this._globalEventsManagerService.synchroEvents.next('validateQuoteModal__' + this.selectedPdf.fileRef);
    }
    this.dialogRef.close('validateEvent');
    return;
  }
  approvedEvent($event: Event)  {
    
    if ((this.callChain.startsWith('quotes')) && (this.toApproved) )  { 
      this._globalEventsManagerService.synchroEvents.next('approveQuoteModal__' + this.selectedPdf.fileRef);
    }
    this.dialogRef.close('approvedEvent');
    return;
  }
  refusedEvent($event: Event)  {
    
    if ((this.callChain.startsWith('quotes')) && (this.toRefused) )  { 
      this._globalEventsManagerService.synchroEvents.next('refuseQuoteModal__' + this.selectedPdf.fileRef);
    }
    this.dialogRef.close('refusedEvent');
    return;
  }
}
