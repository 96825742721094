import { FieldTextbox } from './field-textbox';
import * as i0 from "@angular/core";
export class FieldsListService {
    constructor() {
    }
    // TODO: get from a remote source of question metadata
    // TODO: make asynchronous
    getFields() {
        const fields = [
            /*
                  new FieldDropdown({
                    key: 'brave',
                    label: 'Bravery Rating',
                    options: [
                      {key: 'solid',  value: 'Solid'},
                      {key: 'great',  value: 'Great'},
                      {key: 'good',   value: 'Good'},
                      {key: 'unproven', value: 'Unproven'}
                    ],
                    order: 3
                  }),
            */
            new FieldTextbox({
                key: 'lastName',
                label: 'lastName',
                value: '',
                required: false,
                order: 3
            }),
            new FieldTextbox({
                key: 'firstName',
                label: 'firstName',
                value: '',
                required: false,
                order: 1
            }),
            new FieldTextbox({
                key: 'email',
                label: 'Email',
                value: '',
                required: false,
                type: 'email',
                order: 2
            })
        ];
        return fields.sort((a, b) => a.order - b.order);
    }
}
FieldsListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldsListService_Factory() { return new FieldsListService(); }, token: FieldsListService, providedIn: "root" });
