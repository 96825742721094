<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="container-fluid" style="margin-top: 75px; height: 80%;  width: 100%;">
    <div class="flex-row row  stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col top-sidenav" style ="text-align: center!important">
          <span style="font-size:large"><strong>{{ 'Appointments planning' | translate }}</strong></span>
        </div>
    </div>
    <div class="flex-row row  stdTheme" style="padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col-4" styl="padding: 0px"> <!-- Pending requests -->
            <div class="spinner-container  stdTheme" *ngIf="!requestsLoaded" fxLayout="row" fxLayoutAlign="space-around center">
              <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
              <br>
              <span> 
                  Loading requests ....
              </span>
            </div>
            <mat-card class="stdTheme" *ngIf="requestsLoaded" style="padding: 0px;margin-top: 25px">
                <mat-card-title style="text-align: center;" >{{ 'Pending requests' | translate }}</mat-card-title> 
                <mat-card-content>
                    <fieldset >
                        <div class="flex-row row stdTheme" style="margin: 0px;">
                            <div class="col-12 table-responsive" style="padding: 0px; margin: 0px;">
                              <table class="table stdTheme" id="appointmentsList" style="width: 100%" >
                                <thead class="theadWork">
                                  <tr class="trWork">
                                    <th style="width: 5%;padding: 1px; text-align: center;vertical-align: middle;">#</th>
                                    <th style="width: 12%;padding: 1px; text-align: center;vertical-align: middle;" >{{'appointmentRef' | translate}}</th>
                                    <th style="width: 40%;padding: 1px; text-align: center;vertical-align: middle;">{{'customer' | translate}}</th>
                                    <th style="width: 10%;padding: 1px; text-align: center;vertical-align: middle;">{{'phoneNum' | translate}}</th>
                                    <th style="width: 10%;padding: 1px; text-align: center;vertical-align: middle;">{{'statut' | translate}}</th>
                                    <th style="width: 5%;padding: 1px; text-align: center;vertical-align: middle;"></th>
                                  </tr>
                                </thead>
                                <tbody class="tbodyWork" >
                                    <tr *ngFor="let appointmentCur of appointmentsList; let i = index" class="trWork " 
                                          [class.data-selected]="i === selectedRow" 
                                          (click)="setClickedRow(i)" 
                                          (mouseover)="displayDetail($event, i)" 
                                          style="width: 100%!important">
                                      <td style="width: 5%;padding: 1px; text-align: center;">
                                        <span *ngIf="i === selectedRow" 
                                                    [mdePopoverTriggerFor]="detailPopover" 
                                                    mdePopoverTriggerOn="hover" 
                                                    #popoverTrigger="mdePopoverTrigger">
                                            <i class="fas fa-asterisk fa-xs"></i>
                                        </span>
                                      </td>       
                                      <td style="width: 12%;padding: 1px; text-align: left; vertical-align: middle; " >{{appointmentCur.appointmentRef}}</td>
                                      <td style="width: 40%;padding: 1px; text-align: left; vertical-align: middle; ">{{appointmentCur.customer}}</td>
                                      <td style="width: 10%;padding: 1px; text-align: center; vertical-align: middle;">{{appointmentCur.client.phoneNumber}}</td>
                                      <td style="width: 10%;padding: 1px; text-align: left; vertical-align: middle; ">{{appointmentCur.appointmentStatut | translate }}</td>

                                    </tr>
                                
                                </tbody>
                              </table>
                            </div>
                          </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>                
        </div>
        <div class="col-8" *ngIf="requestsLoaded"> <!-- Calendar-->
          <div class="flex-row row stdTheme" style="padding: 0px; margin-left: 0px; margin-right: 0px;">
            <div class="col-8 "></div>
            <div class="col-3 ">
              <mat-form-field  class="mdiinput form-group stdTheme" appearance="standard" style="margin-top: -35px; margin-bottom: 25px">
                <mat-label>{{ 'technician' | translate }}</mat-label>
                <mat-select class="stdTheme"  panelClass="panelSelectContent" #userMechanic  
                          disableOptionCentering required
                          id="userMechanic"
                          required  
                          (selectionChange)="onChangeMechanic($event)" >
                  <mat-option class="stdTheme stdSelect" *ngFor="let userMechanic of mechanicUsers" 
                          class="selectOptionsTheme"  [value]="userMechanic.username" >
                                {{userMechanic.username }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div  class="targetPopover" [mdePopoverTriggerFor]="detailPopover" mdePopoverTriggerOn="none" #target="mdePopoverTrigger"></div>
          <div class="flex-row row  stdTheme" style="margin-left: 0px; margin-right: 0px;width: 100%;">
            <div class="spinner-container  stdTheme" *ngIf="doLoadCalendar && !planningLoaded" fxLayout="row" fxLayoutAlign="space-around center">
                <!--<mat-spinner diameter="50" strokeWidth="5"></mat-spinner>-->
                <span>Loading planning ...</span>
            </div>
            <mdi-calendar *ngIf="planningLoaded" style="padding: 0px; margin-left: 0px; margin-right: 0px;width: 100%;"
                  [applicationCall]="'appointmentCustomer'"
                  [localelang]="localelang"
                  [localeDateFmt]="localeDateFmt"
                  [dateTimeFormat]="dateTimeFormat"
                  [events]="eventsList"
                  [calendarEvents]="wksEntityParams.calendarEvents"
                  [workingTime]="workingTime"
                  (eventClicked)="eventClicked($event)"
                  (displayRequest)="displayRequest($event)"
              ></mdi-calendar>
          </div>
        </div>
    </div>
</div>
<mde-popover #detailPopover="mdePopover" [mdePopoverOverlapTrigger]="true" [mdePopoverCloseOnClick]="false">
  <div *ngIf="appointmentsCur !== undefined">

      <mdi-appointments-card [rowCard]="appointmentsCur" 
                              [wksEntity]="wksEntity"
                              [mechanicUsers]="mechanicUsers"
                              [wksEntityParams]="wksEntityParams"
                              [popoverAppointment]="'true'" 
                              >
      </mdi-appointments-card>

  </div>
</mde-popover>
