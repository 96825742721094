
<div #refElement *ngIf="isOkToBuild" class="container-fluid stdTheme "
    [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px',overflow: 'auto'}" >
    <div class="flex-row row " style="margin-left: 0px;margin-right: 0px" >
        <div class="col-lg-12"  style="padding-right: 5px; padding-left: 5px;" >
            <form [formGroup]="checkEquipForm"  style="margin: 0px; height: 100%;" >
                <div class="flex-row row" style="margin-left: 0px; margin-right: 0px; min-height: 51px;margin-bottom: 10px;padding-bottom: 20px;"
                    [ngClass]="{greenBorder: getThemeRow('SN-hours'), redBorder: !getThemeRow('SN-hours')}">
                    <div class="col-lg-6 col-xs-12" >
                        <mat-form-field class="wksInput form-group" appearance="standard"  >
                            <mat-label >{{ 'equipSN' | translate }}</mat-label>
                            <input id="equipSN" #equipSN class="form-control inputTheme" formControlName="equipSN" 
                                        matInput (change)="changeSN_Hours('equipSN', equipSN.value)"
                                        [readonly]="isReadOnly">
                            <mat-placeholder >{{ 'equipSN' | translate }}</mat-placeholder>
                        </mat-form-field>
                    </div>  
                    <div class="col-lg-6 col-xs-12" >
                        <mat-form-field class="wksInput form-group" appearance="standard" >
                            <mat-label >{{ 'equipHours' | translate }}</mat-label>
                            <input id="equipHours" #equipHours  class="form-control inputTheme" 
                                    formControlName="equipHours" matInput #equipHours (keypress)="numberOnly($event)"
                                    (change)="changeSN_Hours('equipHours', equipHours.value)"
                                    [readonly]="isReadOnly"
                            >
                            <mat-placeholder >{{ 'equipHours' | translate }}</mat-placeholder>
                        </mat-form-field>
                    </div>   
                </div>
            </form>
        </div>
    </div>
    <div  style="overflow-x: hidden!important; overflow-y: auto!important; height: 400px!important; max-height: 400px!important;" >
        <div  *ngFor= "let taskActionsCur of tasksActionsCur; let iRow=index; let c=count;let isLast=last">
            <div  style="margin: 0px; padding: 0px;"  >
                <div id="taskActionsCur_{{iRow}}" #taskActionsCur_{{iRow}} class="row rowLine" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
                    <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
                        <button mat-icon-button
                            (click)="taskActionsCur.isCollapsed = !taskActionsCur.isCollapsed"
                            [attr.aria-expanded]="!taskActionsCur.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="!taskActionsCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="taskActionsCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                    </div>
                    <div class="col-lg-9 col-xs-9 labelLine" style="margin-top: 10px;" >
                        <p style="margin: 0px;"><b>{{ taskActionsCur.taskrefLabel }}</b> 
                            <a *ngIf="taskActionsCur.taskDuration"> ({{ taskActionsCur.taskDuration }} h) </a>              
                        </p>
                    </div>
                    <div class="col-lg-2 col-xs-2" style="padding-right: 0px;padding-left: 0px;">
                        <!--
                        <button mat-icon-button
                            (click)="taskActionsCur.isCollapsed = !taskActionsCur.isCollapsed"
                            [attr.aria-expanded]="!taskActionsCur.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="!taskActionsCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="taskActionsCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                        -->
                        <button *ngIf="iRow === 0 && !isReadOnly && statutCall !== 'invoicing'" mat-icon-button matSuffix aria-label="add" 
                                (click)="addAdditionalTasks(iRow)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                </div>
                <div  [ngbCollapse]="!taskActionsCur.isCollapsed">
                    <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-lg-12 col-xs-12 ">
                            <span>
                                <h6>
                                    {{'supplies&parts' | translate}}
                                </h6>
                            </span>
                        </div>

                    </div>
                    <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-lg-12 col-xs-12 actionsSelected " >
                            <table class="table tableSupplies" #tableSupplies border="0" cellpadding="0" cellspacing="0" id="tableSupplies"  >
                                <thead >
                                    <tr >
                                        <th style="width: 20%; padding: 2px; text-align: center; vertical-align: middle;">{{'suppliesLabel' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'brandRef' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'billingUnit' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'quantity' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'actionTask' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;" >{{'realized' | translate}}</th>
                                        <th *ngIf="statutCall === 'invoicing'" 
                                            style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'unitPrice' | translate}}</th>
                                        <th *ngIf="statutCall === 'invoicing'"  
                                            style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'extPrice' | translate}}</th>
                                        </tr>
                                </thead>
                                
                                <tbody #bodySupplies  id="bodySupplies">
                                    <!-- [ngClass]="{greenBorder: getThemeRow('taskAction'), redBorder: !getThemeRow('taskAction')}">-->
                                    <tr class="curActionDisplayed"  *ngFor="let suppliesCur of taskActionsCur.supplies; let i = index" style="margin: 0px; margin-top: 10px;"
                                                [ngClass]="{'greenBorder':!waitAction[iRow][i],'redBorder':waitAction[iRow][i]}">
                                        <td [attr.data-label]="dataLabelCur[0]" style=" padding: 2px; text-align: left; vertical-align: middle;">
                                            <span >
                                                {{suppliesCur.actionrefLabel}} 
                                            </span>
                                            <span *ngIf="suppliesCur.actionTaken === 'not-changed' " style="margin-top: 5px;width:100%!important">
                                                <table class="subtable" style="width:100%!important">
                                                    <tr  style="width:100%!important">
                                                        <td style="padding-top: 0px;padding-bottom: 0px;">
                                                            <textarea  id="suppliesCurComment" class="form-control inputTheme textarea1"  
                                                                        matInput #suppliesCurComment placeholder="{{ 'taskrefComment' | translate }}"
                                                                        cdkTextareaAutosize
                                                                        #autosize="cdkTextareaAutosize"
                                                                        cdkAutosizeMinRows="1"
                                                                        cdkAutosizeMaxRows="4"
                                                                        [value]="suppliesCur.comment" 
                                                                        [readonly]="isReadOnly"
                                                                        (change)="changeTaskActionComment(suppliesCurComment.value, iRow,  i)"
                                                            ></textarea >
                                                        </td>
                                                    </tr>
                                                </table>
                                            </span>
                                        </td>
                                        
                                        <td [attr.data-label]="dataLabelCur[1]" style=" padding: 2px; text-align: left; vertical-align: middle;">{{suppliesCur.brandRef}}</td>
                                        <td [attr.data-label]="dataLabelCur[2]" style=" padding: 2px; text-align: center; vertical-align: middle;">{{'' + suppliesCur.billingUnit | translate}}</td>
                                        <td [attr.data-label]="dataLabelCur[3]" style=" padding: 2px; text-align: right; vertical-align: middle;">
                                            {{suppliesCur.quantity  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                        </td>
             
                                        <td *ngIf="suppliesCur.actionTask !== undefined" [attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: center; vertical-align: middle;">
                                                                {{'' + suppliesCur.actionTask | translate}}</td>
                                        <td *ngIf="suppliesCur.actionTask === undefined"[attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: center; vertical-align: middle;">
                                                                {{'changed' | translate}}</td>
                                        <td  [attr.data-label]="dataLabelCur[5]" style=" padding: 2px; vertical-align: middle;" >
                                            <div *ngIf="suppliesCur.actionTask ==='Check'" >
                                                <mat-radio-group class="taskAction-radio-group"  [disabled]="isReadOnly" #taskAction="matRadioGroup" aria-label="Select an option" labelPosition="before"
                                                        style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"
                                                        [value]="suppliesCur.actionTaken" 
                                                        (change)="changeAction(suppliesCur.actionTask,taskAction.value, i ,iRow)">
                                                    <mat-radio-button class="radioButton" style="padding-right: 15px;" value="checked">{{ 'checked' | translate }}</mat-radio-button>
                                                    <mat-radio-button class="radioButton" value="changed">{{ 'changed' | translate }}</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div *ngIf="suppliesCur.actionTask==='Exchange'  || suppliesCur.actionTask==='ExchFirst'" >
                                                <mat-radio-group class="taskAction-radio-group" [disabled]="isReadOnly" #taskAction="matRadioGroup"  aria-label="Select an option" labelPosition="before"
                                                        style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"
                                                        [value]="suppliesCur.actionTaken" 
                                                        (change)="changeAction(suppliesCur.actionTask,taskAction.value, i ,iRow)">
                                                    <mat-radio-button class="radioButton" style="padding-right: 15px;" value="not-changed">{{ 'not_changed' | translate }}</mat-radio-button>
                                                    <mat-radio-button class="radioButton" value="changed">{{ 'changed' | translate }}</mat-radio-button>
                                                </mat-radio-group> 
                                            </div>
                                        </td>
                                        <td *ngIf="(statutCall === 'invoicing' && suppliesCur.actionTaken === 'changed')"  style="padding: 2px; text-align: right; vertical-align: middle;">
                                            <input class="form-control inputTheme" name="unitPrice" #unitPrice 
                                                value="{{suppliesCur.unitPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                                matInput (change)="setUnitPriceValue('supplies',unitPrice.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                            />
                                        </td>
                                        <td *ngIf="(statutCall === 'invoicing' && suppliesCur.actionTaken === 'changed')"  style="padding: 2px; text-align: right; vertical-align: middle;">
                                            <input class="form-control inputTheme" name="extPrice" #extPrice 
                                                value="{{suppliesCur.extPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                                matInput (change)="setExtPriceValue('supplies',extPrice.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="statutCall === 'invoicing'" class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-lg-12 col-xs-12 ">
                            <span>
                                <h6>
                                    {{'labor' | translate}}
                                </h6>
                            </span>
                        </div>

                    </div>
                    <div  *ngIf="statutCall === 'invoicing'" class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-lg-12 col-xs-12 actionsSelected " >
                            <table class="table tableLabor" border="0" cellpadding="0" cellspacing="0" id="tableLabor"  >
                                <thead >
                                    <tr >
                                        <th style="width: 20%; padding: 2px; text-align: center; vertical-align: middle;">{{'actionrefLabel' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'billingUnit' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'quantity' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'unitPrice' | translate}}</th>
                                        <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'extPrice' | translate}}</th>
                                        </tr>
                                </thead>
                                
                                <tbody>
                                    <!-- [ngClass]="{greenBorder: getThemeRow('taskAction'), redBorder: !getThemeRow('taskAction')}">-->
                                    <tr class="curActionDisplayed"  *ngFor="let laborCur of taskActionsCur.works; let i = index" style="margin: 0px; margin-top: 10px;"
                                                [ngClass]="{'greenBorder':!waitAction[iRow][i],'redBorder':waitAction[iRow][i]}">
                                        <td [attr.data-label]="dataLabelCur[0]" style=" padding: 2px; text-align: left; vertical-align: middle;">{{laborCur.actionrefLabel}}</td>

                                        <td [attr.data-label]="dataLabelCur[2]" style=" padding: 2px; text-align: center; vertical-align: middle;">{{'' + laborCur.billingUnit | translate}}</td>
                                        <td [attr.data-label]="dataLabelCur[3]" style=" padding: 2px; text-align: right; vertical-align: middle;">
                                            {{laborCur.quantity  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                        </td>
                                        
                                        <td  style="padding: 2px; text-align: right; vertical-align: middle;">
                                            <input class="form-control inputTheme" name="unitPriceLabor" #unitPriceLabor 
                                                value="{{laborCur.unitPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                                matInput (change)="setUnitPriceValue('work',unitPriceLabor.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                            />
                                        </td>
                                        <td  style="padding: 2px; text-align: right; vertical-align: middle;">
                                            <input class="form-control inputTheme" name="extPriceLabor" #extPriceLabor 
                                                value="{{laborCur.extPrice | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                                matInput (change)="setExtPriceValue('work',extPriceLabor.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="taskActionsCur.additionalTasks !== undefined">
                    <div *ngFor="let additionalTaskCur of taskActionsCur.additionalTasks; let i = index" style="margin: 0px; margin-top: 0px;">

                        <div id="taskActionsCur_{{iRow}}_{{i}}" #taskActionsCur_{{iRow}}_{{i}} class="row rowLine" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
                            <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
                                <button mat-icon-button
                                    (click)="additionalTaskCur.isCollapsed = !additionalTaskCur.isCollapsed"
                                    [attr.aria-expanded]="!additionalTaskCur.isCollapsed" aria-controls="collapseExample">
                                        <span *ngIf="!additionalTaskCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                        <span *ngIf="additionalTaskCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                </button> 
                            </div>
                            <div class="col-lg-9 col-xs-9 labelLine" style="margin-top: 10px;" >  
                                <p style="margin: 0px;"><b>{{ additionalTaskCur.taskDescription }}</b> 
                                    <a *ngIf="additionalTaskCur.taskDuration"> ({{ additionalTaskCur.taskDuration }} h) </a>              
                                </p>
                            </div>
                            <div class="col-lg-2 col-xs-2" style="padding-right: 0px;padding-left: 0px;">
                                <button *ngIf="!isReadOnly" mat-icon-button matSuffix aria-label="add" 
                                        (click)="addAdditionalTasks(iRow)">
                                    <mat-icon>add</mat-icon>
                                </button>

                                <button *ngIf="!isReadOnly"  mat-icon-button matSuffix
                                        (click)="removeAdditionalTasks(iRow, i)">
                                        <mat-icon>cancel</mat-icon>
                                </button>
                            </div>

                        </div>
                        <div  [ngbCollapse]="!additionalTaskCur.isCollapsed">
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-10 col-xs-10" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'taskDescription' | translate }}</mat-label>
                                        <input id="taskDescription" #taskDescription  class="form-control inputTheme" 
                                                placeholder="{{ 'taskDescription' | translate }}"
                                                [value]="additionalTaskCur.taskDescription"
                                                matInput (keyup)="onChangeAdditionalTask('description', taskDescription.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-xs-2" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="wksInput form-group" appearance="standard" >
                                        <mat-label >{{ 'taskDuration' | translate }}</mat-label>
                                        <input id="taskDuration" #taskDuration  class="form-control inputTheme" 
                                                placeholder="{{ 'taskDuration' | translate }}"
                                                [value]="additionalTaskCur.taskDuration"
                                                matInput (keyup)="onChangeAdditionalTask('duration', taskDuration.value, iRow, i)"
                                                [readonly]="isReadOnly"
                                        >
                                    </mat-form-field>
                                </div>
                            </div> 
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-12 col-xs-12" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'taskParts' | translate }}</mat-label>
                                        <textarea  id="taskParts" class="form-control inputTheme textarea1" 
                                                    [readonly]="isReadOnly"
                                                    matInput #taskParts placeholder="{{ 'taskParts' | translate }}"
                                                    [value]="additionalTaskCur.taskParts"
                                                    cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="2"
                                                    cdkAutosizeMaxRows="4"
                                                    (keyup)="onChangeAdditionalTask('parts', taskParts.value, iRow, i)"
                                        ></textarea >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div  class="flex-row row" style="margin: 0px;">
                                <div class="col-lg-12 col-xs-12" style="margin-bottom: 5px;height: 90px">
                                    <mat-form-field class="form-group" appearance="standard" 
                                            style="display: flex; width: 100%; flex-direction: column;margin-bottom: 0px;height: 90px">
                                        <mat-label>{{ 'taskComment' | translate }}</mat-label>
                                        <textarea  id="taskComment" class="form-control inputTheme textarea1" 
                                                    [readonly]="isReadOnly"
                                                    matInput #taskComment placeholder="{{ 'taskComment' | translate }}"
                                                    [value]="additionalTaskCur.taskComment"
                                                    cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="2"
                                                    cdkAutosizeMaxRows="4"
                                                    (keyup)="onChangeAdditionalTask('comment', taskComment.value, iRow, i)"
                                        ></textarea >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
