
<!--  https://stackblitz.com/angular/aqmjygvrdnn?file=src%2Fapp%2Fexpansion-overview-example.html  -->
<div class="equip-accordion">
    <mat-accordion class="stdTheme equip-accordion" >
        <div *ngFor="let item of dataList;let i = index;">
            <div class="flex-row row stdTheme" style="margin-left: 0px;margin-right: 0px;">
                <div style="width: 95%">
                    <mat-expansion-panel class="stdTheme" #parent{{i}} [(expanded)]="item.xpandStatus" >
                        <mat-expansion-panel-header>
                            <mat-panel-title *ngIf="item.childProperties.length === 0">{{item.parentName}}</mat-panel-title>
                            <mat-panel-title *ngIf="item.childProperties.length !== 0"><b>{{item.parentName}} ({{item.childProperties.length}})</b></mat-panel-title>
                            <!--<mat-panel-description>
                                <button mat-icon-button (click)="addNewChild(item.parentTech,i,item.childProperties)"><mat-icon>add</mat-icon></button>
                            </mat-panel-description>-->
                            
                        </mat-expansion-panel-header>
                        <div class="stdTheme" *ngFor="let child of item.childProperties; let row = index" >
                            <!--
                            <mat-expansion-panel class="stdTheme" #parent{{row}} 
                                (opened)="item.xpandStatus = true" 
                                (closed)="item.xpandStatus = false" 
                                [expanded]="item.xpandStatus"
                                style="padding-right: 0px;">
                            -->
                            <!--<fieldset class="equip-border">-->
                                <mat-expansion-panel class="stdTheme" #parent{{row}} (opened)="editItem($event,row,item.childProperties)"
                                                        [(expanded)]="child.xpandStatus"
                                                        style="padding-right: 0px;">
                                    <mat-expansion-panel-header  [ngClass]="{'headerSelected':child.xpandStatus,
                                                                'stdTheme':!child.xpandStatus}" >
                                        <mat-panel-title [ngClass]="{'headerSelected':child.xpandStatus,
                                                                'stdTheme':!child.xpandStatus}">{{child.propertyName}} </mat-panel-title>
                                        <mat-panel-description [ngClass]="{'headerSelected':child.xpandStatus,
                                                                'stdTheme':!child.xpandStatus}"> 
                                            <!--
                                            <button  mat-icon-button  (click)="editItem($event,row,item.childProperties)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <!--<p>{{child.propertyContent}}</p>--> 

                                    <mdi-equipment-card style="width: 100%" *ngIf="item.xpandStatus" [statutCall]="'accordion'" 
                                                        [optionItem]="item.parentTech" 
                                                        [actionType]="child.actionType" 
                                                        [isBoatEquip]="true" 
                                                        [hoursInput]="item.hoursInput"
                                                        [equipmentToUpdate]="(child.propertyContent ? child.propertyContent : undefined)"
                                                        [parentParam]="parentParam"
                                                        [ihmOptions]="ihmOptions"
                                                        [okInputHours]="okInputHours"
                                                        (dataOut)="saveItem($event,row,item.childProperties)">
                                    </mdi-equipment-card>
                                </mat-expansion-panel>

                            <!--</fieldset>-->
                            <div class="lineWks"></div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <div class="stdTheme " style="width: 5%">
                    <span>
                        <button mat-icon-button (click)="addNewChild(item.parentTech,i,item.childProperties)"><mat-icon>add</mat-icon></button>
                    </span>
                </div>
            </div>
        </div>
    </mat-accordion>
</div>

