
<div mat-dialog-title class="stdTheme" style="margin: 0px!important">

    <span>
       <h4>{{modalTitle}}</h4> 
    </span>

    <span  *ngIf="!checkEquipmentsIsLoaded" style="background-color: transparent!important">
        <mat-progress-spinner class="waiting-data" [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
        <br>
        <span class="blink_text">Please wait...</span>
    </span>

</div>

<mat-dialog-content *ngIf="checkEquipmentsIsLoaded"  class="stdTheme verifCheck" style="padding: 0px!important; margin: 0px!important">

    <div *ngIf="isCheckLoaded" >
        <div #refElement  class="container-fluid stdTheme"
            [ngStyle]="{margin: '0px', 'margin-top': '0px', width: '100%', padding: '0px', height: '100%'}" >
            <div class="flex-row row " style="margin-left: 0px;margin-right: 0px">
                <div class="col-lg-12 col-xs-12 " style="padding-left: 0px;padding-right: 0px">
                    <table  style="width:100%!important;" class="stdTheme" >
                        <thead style="width:100%!important;">
                            <tr style="width:100%!important;">
                                <th style="width: 5%; ">#</th>
                                <th style="width: 55%; ">{{equipLabels[0]}}</th>
                                <th style="width: 13%; ">{{equipLabels[1]}}</th>
                                <th style="width: 13%; ">{{equipLabels[2]}}</th>
                                <th style="width: 14%; ">{{equipLabels[3]}}</th>
                            </tr>
                        </thead>
                        <tbody style="width:100%!important;overflow: auto!important;" >
                           <!--<div *ngFor="let equipCur of checkEquipmentsList; let i = index" style="width:100%!important;" >-->
                                <tr class="curActionDisplayed" *ngFor="let equipCur of checkEquipmentsList; let i = index"  style="width:100%!important"
                                    [ngClass]="{'greenBorder':!equipCur.equipFailed,'redBorder':equipCur.equipFailed}" >
                                    <td style="width: 5%; padding: 2px; text-align: center; vertical-align: middle;"  >
                                        <span style="margin-top: 5px" *ngIf="equipCur.tasksList.length > 0" (click)="equipCur.isCollapsed = !equipCur.isCollapsed">
                                            <span *ngIf="equipCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                            <span *ngIf="!equipCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                        </span>
                                        <span style="margin-top: 5px" *ngIf="equipCur.tasksList.length < 1" (click)="equipCur.isCollapsed = !equipCur.isCollapsed"> </span>
                                    </td>
                                    <td style="width: 55%; padding: 2px; text-align: start; vertical-align: middle" >
                                        <span class="label" style="margin-top: 5px;">
                                            {{equipCur.equipDesign}}
                                        </span>
                                        <span *ngIf="!equipCur.isCollapsed && equipCur.tasksList.length > 0" style="margin-top: 5px;">
                                            <table >
                                                <tr *ngFor="let taskItem of equipCur.tasksList; let i = index" style="width:100%!important">
                                                    <td colspan="4">
                                                        <span *ngIf="(taskItem.missingParts)" class="red-line">
                                                            &nbsp;{{taskItem.taskrefLabel}}&nbsp;
                                                        </span>
                                                        <span *ngIf="(!taskItem.missingParts)" class="green-line">
                                                            &nbsp;{{taskItem.taskrefLabel}}&nbsp;
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </span>
                                    </td>
                                    <td style="width: 13%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipHours !== undefined && equipCur.equipHours > 1)" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipHours === undefined || equipCur.equipHours < 1)" class="red-dot"></span>
                                    </td>
                                    <td style="width: 13%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipSN !== null && equipCur.equipSN !== undefined && equipCur.equipSN !== '')" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.equipSN === null || equipCur.equipSN === undefined || equipCur.equipSN === '')" class="red-dot"></span>
                                    </td>
                                    <td style="width: 14%; padding: 2px; text-align: center; vertical-align: middle;">
                                        <span style="margin-top: 5px" *ngIf="(!equipCur.missingParts)" class="green-dot"></span>
                                        <span style="margin-top: 5px" *ngIf="(equipCur.missingParts)" class="red-dot"></span>
                                    </td>
                                </tr>
                            <!--</div>-->
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="checkEquipmentsIsLoaded "  >
    <button  class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="closeModal()" >{{'close' | translate}}</button>
    <div fxFlex></div>
    <button  *ngIf="isOkToValid && !ihmOptions.readOnly" 
            class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" 
            (click)="validFinished()" >
        {{'completed' | translate}}
    </button>
    <!--<button  *ngIf="isOkToValid" class="mat-raised-button buttonTheme" mat-dialog-close style="margin-bottom: 10px;" (click)="lockWork()" >{{'WORK_TASK.completed' | translate}}</button>-->
</mat-dialog-actions>