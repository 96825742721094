import { Component, OnInit, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Color } from 'ng2-charts/ng2-charts';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit {

  @Input() widgetData: Widget;
  @Input() displayTime: boolean;
  private readonly onDestroy = new Subject<void>();

  // public doughnutChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  public doughnutChartLabels: Label[];

  /*public doughnutChartData: MultiDataSet = [
    [350, 450, 100],
    [50, 150, 120],
    [250, 130, 70],
  ];*/
  public doughnutChartData: MultiDataSet;
  // public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartType: ChartType;
  updateFrequency: number;

  dataIsLoaded : boolean = false;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  colorsCur: Color[];
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
   this.displayDateTime = false;
    console.log('widgetData doughnut : ' + this.widgetData);
    this.updateFrequency = this.widgetData.updateFrequency;
    this._jobDataService.displayGenerateTime
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      if ((event === 'displayTime') || (event === 'hideTime')) {
        this.toogleTime(event);
      }
    });

    this.buildRequest();
    this.doughnutChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction,'unixM',localFmt);
      const jsonData = JSON.parse(resultChart.json_data);
      this.doughnutChartLabels = jsonData.labelCols as Label[];
      const doughnutChartDataCur = jsonData.datasets as MultiDataSet;

      const colorAlphas = CommonMethods.genAlphaHslaColors(-1,70,[20,100,80],doughnutChartDataCur.length);
      const alpha = 0;
     
      // this.barChartData[0].backgroundColor = colors;
      let colorLocalCur: any[];
      colorLocalCur = [];
      let colorList = [];
 
      let idxa: number;
      for(const rowCur of doughnutChartDataCur) {
        idxa = 0;
        colorList = [];
        for(const colCur of rowCur) {
          colorList.push(colorAlphas[idxa][0]);
          idxa++;
        }
        const colorCur = {
          backgroundColor: colorList, 
        }
        colorLocalCur.push(colorCur);
      }
      this.colorsCur = colorLocalCur;
      this.doughnutChartData = doughnutChartDataCur;
      this.dataIsLoaded = true;
    })
    .then((error) => {

    });

  }
  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if(restrict.columnName) {
        if(requestRestrictions != '') {
          requestRestrictions+=';';
        }
        requestRestrictions+= restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if(resultChart.length === 0) {
            reject();
          }
          else resolve(resultChart[0]);
        }, err => {
          
          reject(err);
        }
      );
    });
  }
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
