import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CmdService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    runCmd(_action, bodyArg) {
        const urlService = `${environment.baseUrl}/cmd/testcmd/?` + 'cmdarg=' + _action;
        const localHttpHeaders = this.httpHeaders;
        let body;
        if (bodyArg !== undefined) {
            body = JSON.stringify(bodyArg);
        }
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
CmdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CmdService_Factory() { return new CmdService(i0.ɵɵinject(i1.HttpClient)); }, token: CmdService, providedIn: "root" });
