/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./doc-image.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./doc-image.component";
import * as i7 from "../../../../services/wks-central.service";
var styles_DocImageComponent = [i0.styles];
var RenderType_DocImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocImageComponent, data: {} });
export { RenderType_DocImageComponent as RenderType_DocImageComponent };
function View_DocImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "flex-row row"], ["style", "margin: 0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-left: 30px;"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DocImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageToShow; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.titleReport, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DocImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["align", "center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocImageComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"], ["style", "height: 75vh"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocImageComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.dataImgLoaded; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isImageLoading; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleReport; _ck(_v, 4, 0, currVal_0); }); }
export function View_DocImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-doc-image", [], null, null, null, View_DocImageComponent_0, RenderType_DocImageComponent)), i1.ɵdid(1, 638976, null, 0, i6.DocImageComponent, [i7.WksCentralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocImageComponentNgFactory = i1.ɵccf("mdi-doc-image", i6.DocImageComponent, View_DocImageComponent_Host_0, { statutCall: "statutCall", titleReport: "titleReport", dataList: "dataList" }, {}, []);
export { DocImageComponentNgFactory as DocImageComponentNgFactory };
