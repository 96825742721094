<!--
<div *ngIf="actionCur !== 'TimeChanged'">
    <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
                        style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
        {{ alert.message | translate:[alert.data1, alert.data2] }}
        <a *ngIf="alert.alertType !== 'alertWks'" class="close" (click)="removeAlert(alert)">&times;</a>
    </div>
</div>
<div *ngIf="actionCur === 'TimeChanged'">
    <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
        style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
        {{ alert.message }} <br>
        {{ alert.data1 }} <br>
        {{ alert.data2 }} <br>
    </div>
</div>
-->
<div [ngSwitch]="actionDisplay">
    <div *ngSwitchCase="'TimeChanged'">
        <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
            style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
            <span>{{ alert.message }} <br>
            {{ alert.data1 }} <br>
            {{ alert.data2 }} <br></span>
        </div>
    </div>
    <div *ngSwitchCase="'csvControl'">
        <div *ngFor="let alert of displayMessages" class="{{ cssClass(alert) }} alert-dismissable" 
            style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
            <span>{{ alert }} <br></span>
        </div>
    </div>
    <div *ngSwitchCase="'htmlMessage'">
        <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
            style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;"
                [innerHTML]="alert.message | safeHtml: 'html'">
        </div>
    </div>
    <div *ngSwitchCase="'normalMessage'">
        <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
                style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                <span>{{ alert.message | translate:[alert.data1, alert.data2] }}</span>
            <a *ngIf="alert.alertType !== 'alertWks'" class="close" (click)="removeAlert(alert)">&times;</a>
        </div>
    </div>
    <div *ngSwitchDefault>
        <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }} alert-dismissable" 
                style="padding-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                <span>{{ alert.message | translate:[alert.data1, alert.data2] }}</span>
            <a *ngIf="alert.alertType !== 'alertWks'" class="close" (click)="removeAlert(alert)">&times;</a>
        </div>
    </div>
</div>
