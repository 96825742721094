/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-owner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./equipment-owner.component";
import * as i3 from "./equipment-data";
import * as i4 from "../../../../services/wks-central.service";
var styles_EquipmentOwnerComponent = [i0.styles];
var RenderType_EquipmentOwnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentOwnerComponent, data: {} });
export { RenderType_EquipmentOwnerComponent as RenderType_EquipmentOwnerComponent };
export function View_EquipmentOwnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataIn.ownerName; var currVal_1 = _co.dataIn.ownerId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_EquipmentOwnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-equipment-owner", [], null, null, null, View_EquipmentOwnerComponent_0, RenderType_EquipmentOwnerComponent)), i1.ɵdid(1, 638976, null, 0, i2.EquipmentOwnerComponent, [i3.EquipmentData, i4.WksCentralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentOwnerComponentNgFactory = i1.ɵccf("mdi-equipment-owner", i2.EquipmentOwnerComponent, View_EquipmentOwnerComponent_Host_0, { dataIn: "dataIn" }, {}, []);
export { EquipmentOwnerComponentNgFactory as EquipmentOwnerComponentNgFactory };
