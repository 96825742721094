<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span style="margin-left: 45%; font-size:large">{{ 'works' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <div *ngIf="loadingIndicator" class="spinner-container  stdTheme" >
      <div class="flex-row row d-flex justify-content-center" style="padding: 0px; margin-left: 0px; margin-right: 0px;margin-top: 50px">
        <mat-spinner diameter="40" strokeWidth="5"></mat-spinner>
      </div>
      <div class="flex-row row d-flex justify-content-center" style="padding: 0px; margin-left: 0px; margin-right: 0px;margin-top: 70px">
        <!--
        <span style="font-size: 25px" > 
          <b>{{ 'loadingWorks' | translate }}</b>
        </span>
        -->
        <div class="loader-container">
          <div class="loading-text">{{ 'loadingWorks' | translate }}<span class="dots"></span></div>
      </div>
      </div>
    </div>
    <mat-sidenav-container *ngIf="!loadingIndicator && displayType === 'list'" (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop" fxFlexFill class="container-sidenav w-100" autosize>
      <mat-sidenav #sidenav fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-90" disableClose style="overflow:hidden !important">
          <div fxLayout="column" *ngIf="displayWorkCard">

            <a (click)="toggleSidenav('')"  mat-raised-button class="buttonTheme" style="font-size: 12px">
              {{ updatestatut === 'create' ? 'Create' :'Update' | translate}}
              <a> ({{ 'works' | translate }})</a>
            </a>
            <a>
              <mdi-works-card 
                [dataIn]="dataInWork" 
                [statutCall]="'sidenav'"  
                [actionType]="actionType"
                (dataOut)="dataOutWork($event)" 
                (onload)="ngOnInit()">
              </mdi-works-card>
            </a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div class="container-fluid" >
          <div class="flex-row row stdTheme w-100" >
            <div class="col-4 d-flex justify-content-start align-items-center  " >
              <input type="text"  id="filter-text-box" matInput
                    style="height: 50px;"
                    placeholder="{{ 'global filter' | translate }}" 
                    (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
            </div>
            <div class="col-1 d-flex justify-content-start align-items-center  "  >
             
            </div>
            <div class="col-3" >
              <mat-select id="statutLabelSelect" placeholder="{{ 'Choose one statut' | translate }}"
                  panelClass="mdiselectcontent" #statutLabelSelect ngDefaultControl
                  (selectionChange)="onChangeStatutSelect(statutLabelSelect.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let statutlabelCur of workStatutsList"
                      [value]="statutlabelCur.label">{{statutlabelCur.labelDisplay }}
                  </mat-option>
              </mat-select>
            </div>
            <div class="col-1 d-flex justify-content-start align-items-center  "  >
              
            </div>
             <div class="col-2 d-flex justify-content-end align-items-center">
              <button mat-icon-button class="buttonTheme" 
                      style="margin-left: 10px;margin-right: 10px;" (click)="addWork()">
                  <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                      style="margin-left: 10px;margin-right: 10px;">
                  <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
             
            </div>
          </div>
          <div style="height: 90%;">
            <ag-grid-angular 
              style="width: 100%; height: 100%;" 
              class="ag-theme-material"
              #agGrid
              id="agGrid"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [localeText]="localeText"
              [pagination]="true"
              [paginationAutoPageSize]="true"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              [frameworkComponents]="frameworkComponents"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

