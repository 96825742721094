export interface ParamCustoms {
  id: string;
  ecoZoneCode: string;
  hsCode: string;
  rulesJson: string;
  otherData: string;
  stdEntity: string;
  stdCreation: string;
  stdLastupdate: string;
  stdDisable: string;
}

export interface RulesJson {
  dutyFreeZones: string[];
  economicAreasOrigin: EconomicAreaDuties[];
} 
export interface EconomicAreaDuties {
  originId: string;
  duties: Dutie[];
}
export interface Dutie {
  dutieName: string;
  method: string;
  value: number;
  base?: number;
  result?: number;
}
export interface ParamLabelsI18n {
  id?: number;
  stdEntity: string;
  labelModule?: string;
  labelObject?: string;
  labelLang: string;
  labelText: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
}

export interface LangLabelModel {
  langDisplay: string;
  langValue: string;
  langDirection: string;
  langLabel: string;
}

export interface ParamWorksRatesModel {
  id?: string;
  stdEntity?: string;
  workCode?: string;
  workLabel?: string;
  workUnit?: string;
  workPricing?: string;
  otherData?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
  langLabels?: ParamLabelsI18n[];
  userLang?: string;
}
export interface WorkPricing {
  scaleName: string;
  scaleRate: number;
}
export interface ParamWorksRatesEvent {
  searchCriteria: ParamWorksRatesModel;
  searchResult: ParamWorksRatesModel[];
}
export const paramworksrates_sch = [
  { colName: 'id', colType: 'string' },
  { colName: 'stdEntity', colType: 'string' },
  { colName: 'workCode', colType: 'string' },
  { colName: 'workLabel', colType: 'string' },
  { colName: 'workUnit', colType: 'string' },
  { colName: 'workPricing', colType: 'string' },
  { colName: 'otherData', colType: 'string' },
  { colName: 'stdCreation', colType: 'Date' },
  { colName: 'stdLastupdate', colType: 'Date' },
  { colName: 'stdDisable', colType: 'Date' },
  { colName: 'langLabels', colType: 'string' },
  { colName: 'userLang', colType: 'string' },
];
export interface ParamRatesModel {
  id?: string;
  stdEntity?: string;
  paramId?: string;
  paramScaleName?: string;
  paramRate?: number;
  paramCurrency?: string;
  otherData?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
}

export interface ParamPartsSuppliesModel {
  id?: string;
  stdEntity?: string;
  paramType?: string;
  brandName?: string;
  brandRef?: string;
  paramUnit?: string;
  paramCurrency?: string;
  otherData?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
  langLabels?: ParamLabelsI18n[];
  userLang?: string;
  paramRate?: number;
  paramRates?: ParamRatesModel[];
  paramLabel: string;
  paramScaleName?: string;
  partsSuppliesLabel?: string;
  paramTypeLabel?: string;
  isSelected?: boolean;
  paramMaj?: string;
}
export interface UpdateRatesModel {
  stdEntity: string;
  paramType: string;
  brandName: string;
  brandRefs: string;
  percentSens: string;
  percentRate: number;
  paramScaleName: string;
  statut?: string;
}
export interface EventPartsSupplies {
  statut: string;
  partsSupplies: ParamPartsSuppliesModel[];
}
export interface EventStatutCur {
  subject: string;
  data?: string;
  failed: boolean;
}
export const ParamPartsSupplies_sch = [
  { colName: 'id', colType: 'string' },
  { colName: 'stdEntity', colType: 'string' },
  { colName: 'paramType', colType: 'string' },
  { colName: 'brandName', colType: 'string' },
  { colName: 'brandRef', colType: 'string' },
  { colName: 'paramUnit', colType: 'string' },
  { colName: 'otherData', colType: 'string' },
  { colName: 'stdCreation', colType: 'Date' },
  { colName: 'stdLastupdate', colType: 'Date' },
  { colName: 'stdDisable', colType: 'Date' },
  { colName: 'langLabels', colType: 'string' },
  { colName: 'userLang', colType: 'string' },
  { colName: 'paramRate', colType: 'string' },
  { colName: 'paramRates', colType: 'string' },
  { colName: 'paramLabel', colType: 'string' },
  { colName: 'partsSuppliesLabel', colType: 'string' },
  { colName: 'paramCurrency', colType: 'string' },
  { colName: 'paramScaleName', colType: 'string' },
  { colName: 'paramTypeLabel', colType: 'string' },
  { colName: 'paramMaj', colType: 'string' },
  { colName: 'stockRefExists', colType: 'string' },
  { colName: 'otherDataJson', colType: 'string' },
  { colName: 'otherDataToJson', colType: 'string' },
];

export interface ParamActionsrefModel {
  id?: string;
  stdEntity?: string;
  actionrefId?: string;
  equipType?: string;
  equipBrand?: string;
  equipModel?: string;
  brandRef?: string;
  workCode?: string;
  actionrefLabel?: string;
  billingUnit?: string;
  actionrefJson?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
  langLabels?: ParamLabelsI18n[];
  userLang?: string;
  paramWorksRates?: ParamWorksRatesModel;
  workLabel?: string;
  equipTypeLabel?: string;
}

export interface TasksActionsModel {
  ranking?: number;
  partSupplieId?: string;
  brandRef?: string;
  actionrefId?: string;
  actionrefLabel?: string;
  billingUnit?: string;
  workCode?: string;
  quantity?: number;
  unitPrice?: number;
  extPrice?: number;
  vat?: number;
  comment?: string;
  selected?: boolean;
  quoted?: string;
  invoiced?: string;
  status?: string;
  available?: boolean;
  parentElement?: boolean;
  actionTask?: string;
  actionTaken?: string;
}
export interface TasksActionsContentModel {
  supplies: TasksActionsModel[];
  works: TasksActionsModel[];
  equipments?: {
      all: boolean;
      equipId: string[];
  };
  rowTask?: number;
}
export interface OtherTasksModel {
  taskDescription: string;
  taskParts: string;
}
export interface TasksCompletedModel {
  equipId: string;
  equipSN: string;
  equipHours: number;
  taskrefId: string;
  taskrefLabel: string;
  supplies: TasksActionsModel[];
  works: TasksActionsModel[];
  equipments?: {
    all: boolean;
    equipId: string[];
};
  otherJobs?: OtherTasksModel;
  
  isCollapsed?: boolean;
  taskDuration?: number;
}
export interface JobManufacturerAtt {
  hours: number;
  months: number;
  specType: string;
  jobMajor: boolean;
  jobNormal: boolean;
  jobPud: Date; // Parts Update Date
  specjobId: string;
}
export interface AdditionalTasksModel {
  taskDescription: string;
  taskParts: string;
  // taskParts: TasksActionsModel[];
  taskDuration: number;
  taskComment: string;
  isCollapsed?: boolean;
}
export interface TasksActionCompletedModel {
  equipId: string;
  equipSN?: string;
  equipHours?: number;
  equipType?: string;
  equipBrand?: string;
  equipModel?: string;
  taskrefId: string;
  taskrefLabel: string;
  supplies: TasksActionsModel[];
  works: TasksActionsModel[];
  equipments?: {
    all: boolean;
    equipId: string[];
  };
  additionalTasks?: AdditionalTasksModel[];
  isCollapsed?: boolean;
  taskDuration?: number;
}
export interface RecapAmountPriceModel {
  labelPrice: string;
  extPrice?: number;
}

export interface TaskActionRecapAmountModel {
  taskrefId: string;
  taskrefLabel: string;
  // other?: RecapAmountPriceModel[];
  suppliesTotalBT: number;
  laborTotalBT: number;
  otherTotalBT: number;
  isCollapsed?: boolean;
}

export interface TaskrefJsonModel  {
  unitquantity?: string;
  taskrefComment?: string;
  tasksActionsContent: TasksActionsContentModel;
  tasksActionsParentContent?: TasksActionsContentModel;
  tasksActionCompleted?: TasksActionCompletedModel[];
  jobManufacturerAtt?: JobManufacturerAtt;
}

export const ParamActionsref_sch = [
  { colName: 'id', colType: 'string' },
  { colName: 'stdEntity', colType: 'string' },
  { colName: 'actionrefId', colType: 'string' },
  { colName: 'equipType', colType: 'string' },
  { colName: 'equipTypeLabel', colType: 'string' },
  { colName: 'equipBrand', colType: 'string' },
  { colName: 'equipModel', colType: 'string' },
  { colName: 'brandRef', colType: 'string' },
  { colName: 'workCode', colType: 'string' },
  { colName: 'actionrefLabel', colType: 'string' },
  { colName: 'billingUnit', colType: 'string' },
  { colName: 'actionrefJson', colType: 'string' },
  { colName: 'stdCreation', colType: 'Date' },
  { colName: 'stdLastupdate', colType: 'Date' },
  { colName: 'stdDisable', colType: 'Date' },
  { colName: 'langLabels', colType: 'string' },
  { colName: 'userLang', colType: 'string' },
  { colName: 'paramWorksRates', colType: 'string' },
  { colName: 'workLabel', colType: 'string' },
];
export interface ParamActionsrefEvent {
  searchCriteria: ParamActionsrefModel;
  searchResult: ParamActionsrefModel[];
}

export interface WksTasksModel {
  id?: string;
  ranking?: number;
  stdEntity?: string;
  taskrefId?: string;
  taskrefRow?: string;
  taskrefParent?: string;
  taskrefOrig?: string;
  equipType?: string;
  equipBrand?: string;
  equipModel?: string;
  workCode?: string;
  workType?: string;
  workId?: string;
  actionrefType?: string;
  actionrefId?: string;
  partRef?: string;
  taskrefLabel?: string;
  equipTypeLabel?: string;
  taskrefJson?: string;
  stdCreation?: Date;
  stdLastupdate?: Date;
  stdDisable?: Date;
  taskrefSN?: string;
  langLabels?: ParamLabelsI18n[];
  userLang?: string;
  allAvailable?: boolean;
  selectedTask?: boolean;
  disabledTask?: boolean;
  taskMaj?: string;
  snTooltip?: string;
  jobManufacturerAtt?: JobManufacturerAtt;
}
export interface TaskSN {
  snFrom: string;
  snUpto: string;
}
export const Paramtasksref_sch = [
  { colName: 'id', colType: 'number' },
  { colName: 'stdEntity', colType: 'string' },
  { colName: 'taskrefId', colType: 'string' },
  { colName: 'taskrefRow', colType: 'string' },
  { colName: 'taskrefParent', colType: 'string' },
  { colName: 'taskrefOrig', colType: 'string' },
  { colName: 'equipType', colType: 'string' },
  { colName: 'equipBrand', colType: 'string' },
  { colName: 'equipModel', colType: 'string' },
  { colName: 'workCode', colType: 'string' },
  { colName: 'workType', colType: 'string' },
  { colName: 'workId', colType: 'string' },
  { colName: 'actionrefType', colType: 'string' },
  { colName: 'actionrefId', colType: 'string' },
  { colName: 'partRef', colType: 'string' },
  { colName: 'taskrefLabel', colType: 'string' },
  { colName: 'equipTypeLabel', colType: 'string' },
  { colName: 'taskrefJson', colType: 'string' },
  { colName: 'stdCreation', colType: 'Date' },
  { colName: 'stdLastupdate', colType: 'Date' },
  { colName: 'stdDisable', colType: 'Date' },
  { colName: 'langLabels', colType: 'string' },
  { colName: 'taskrefSN', colType: 'string' },
  { colName: 'userLang', colType: 'string' },
  { colName: 'allAvailable', colType: 'boolean' },
  { colName: 'selectedTask', colType: 'string' },
  { colName: 'disabledTask', colType: 'string' },
  { colName: 'taskMaj', colType: 'string' },
  { colName: 'taskSN', colType: 'string' },
  { colName: 'taskActionContent', colType: 'string' },
  { colName: 'taskActionParent', colType: 'string' },
  { colName: 'taskActionsContent', colType: 'string' },
  { colName: 'tasksActionsContent', colType: 'string' },
  { colName: 'tasksActionCompleted', colType: 'string' },
  { colName: 'taskRefJsonToJson', colType: 'string' },
  { colName: 'tasksActionsParentContent', colType: 'string' },
  { colName: 'actionsJson', colType: 'string' },
];
export interface ParamTasksrefEvent {
  searchCriteria: WksTasksModel;
  searchResult: WksTasksModel[];
}

export interface ParentTask {
  rootParam?: WksTasksModel;
  parentParam: WksTasksModel;
  statutCall?: string;
  stdEntity: string;
  userlang: string;
}

export interface IhmOptions {
  optionName?: string;
  display?: boolean;
  readOnly?: boolean;
  collapse?: boolean;
}
export interface WorkStatutModel  {
  number: number;
  label: string;
  tooltip: string;
  processtodo: string; 
  next: number;
  command: string;
  commentRequired: boolean;
  ihmOptions: IhmOptions[];
  date?: Date;
  labelDisplay?: string;
}

export interface HoursRange {
  min: number;
  max: number;
  pleasurStep: number;
  professionnalStep: number;
}
export interface ServicesItem {
  serviceName: string;
  hours: HoursRange;
}
export interface EquipmentItem {
  equipmentType: string;
  servicesList: ServicesItem[];
}
export interface RegularServiceItem {
  brandName: string;
  equipmentsList: EquipmentItem[];
}
