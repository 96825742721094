
    <ul class="pager">
      <li [class.disabled]="!canPrevious()">
        <a class="stdTheme"
          href="javascript:void(0)"
          (click)="selectPage(1)">
          <i class="{{pagerPreviousIcon}}" style="color: var(--foreground-primary)!important;"></i>
        </a>
      </li>
      <li [class.disabled]="!canPrevious()">
        <a 
          href="javascript:void(0)"
          (click)="prevPage()">
          <i class="{{pagerLeftArrowIcon}}" style="color: var(--foreground-primary)!important;"></i>
         <!-- <i class="fa fa-chevron-left " style="color: var(--foreground-primary)!important;" aria-hidden="true"></i>-->
        </a>
      </li>
      <li
        class="pages"
        *ngFor="let pg of pages"
        [class.active]="pg.number === page" >
        <a class="stdTheme"
          href="javascript:void(0)"
          (click)="selectPage(pg.number)">
          {{pg.text}}
        </a>
      </li>
      <li [class.disabled]="!canNext()">
        <a
          href="javascript:void(0)"
          (click)="nextPage()">
          <i class="{{pagerRightArrowIcon}}" style="color: var(--foreground-primary)!important;"></i>
        </a>
      </li>
      <li [class.disabled]="!canNext()">
        <a
          href="javascript:void(0)"
          (click)="selectPage(totalPages)">
          <i class="{{pagerNextIcon}}" style="color: var(--foreground-primary)!important;"></i>
        </a>
      </li>
    </ul>
  