<form [formGroup]="clientForm" [dir]="wksEntity.directionInput" >
    <mat-card class="stdTheme" style="padding: 0px!important;">
      <!--<mat-card-title style="text-align: center;" >{{ 'CLIENT.FORM_TITLE' | translate }}</mat-card-title> -->
      <mat-card-content>
        <fieldset class="informationsborder">
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;" class="limit-wrapper">
                <mat-label class="appointmentsLabel">{{ 'FirstName' | translate }}</mat-label>
                <input formControlName="firstName" matInput type="text" required>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;">
                <mat-label>{{ 'LastName' | translate }}</mat-label>
                <input formControlName="lastName" matInput type="text" required >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;">
                <mat-label>{{ 'companyName' | translate }}</mat-label>
                <input formControlName="companyName" matInput type="text">
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;">
                <mat-label>{{ 'mailAddress' | translate }}</mat-label>
                <input formControlName="mailAddress" matInput placeholder="pat@example.com" type="email" required>
                <mat-icon matSuffix>email</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;">
                <mat-label>{{ 'phoneNumber' | translate }}</mat-label>
                <input formControlName="phoneNumber" matInput [placeholder]="wksEntity.telFmt.domesticMask" [mask]="wksEntity.telFmt.domesticMask" type="tel" required>
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!--
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; height: 60px" >
            <div class="col-10">
              <mat-form-field appearance="standard" style="width: 100%!important;">
                <mat-label>{{ 'equipmentLocation' | translate }}</mat-label>
                <textarea matInput  style="margin: 0px;padding: 0px;"
                    type="text"
                    formControlName="equipmentLocation"
                    cdkTextareaAutosize
                    #equipmentLocation="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="2"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        -->
          <div *ngIf="locationAddress" class="stdTheme " style="margin-right: 0px;margin-left: 0px;" >
            <div #addressBlock class="stdTheme margin-0 padding-0"  formGroupName="addressBlockFg" style="width: 100%">
              <fieldset >
                <legend style="width: 40%">{{ 'equipmentLocation' | translate }}</legend>
                <div *ngFor="let rowCur of rowsAddress; let iRow = index" >
                  <div  class="flex-row  row stdTheme" style="margin-right: 0px;margin-left: 0px;" >
                    <!--<div class="col-1"></div>-->
                    <div *ngFor="let colCur of rowCur.cols; let iCol = index" 
                            [ngStyle]="{width: calWidthCol(rowCur.cols,colCur.dataLengthMax,38)}"
                            [ngSwitch]="colCur.controlType">
                      <div  [class]="getColRowAddr(rowCur.cols)">
                        <div *ngSwitchDefault>
                            <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                                <mat-label >{{colCur.elemLabel}}</mat-label>
                                <input matInput class="addressItem" type="text" #nbHoursOfUse id="addressItem_+{{colCur.elmtNumber}}" 
                                    [attr.maxlength]="colCur.dataLengthMax"
                                    [ngClass]="{
                                        'inputAlert': colCur.inputError,
                                        'inputNormal': !colCur.inputError
                                        }"
                                    [value]="colCur.inputValue" 
                                    (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                                    formControlName="{{colCur.dataName}}"
                                    >
                            </mat-form-field>
                        </div> 
                        <div *ngSwitchCase="'zipcode'">
                            <mat-form-field style="width: 100%" appearance="standard" class="itemAddress form-group">
                                <mat-label >{{colCur.elemLabel}}</mat-label>
                                <input matInput class='addressItem' type="text" id="addressItem_+{{colCur.elmtNumber}}" 
                                    [attr.maxlength]="colCur.dataLengthMax"
                                    [ngClass]="{
                                        'inputAlert': colCur.inputError,
                                        'inputNormal': !colCur.inputError
                                        }"
                                    [value]="colCur.inputValue" 
                                    (input)="formatFieldAddress('address', iRow, iCol, colCur,$any($event.target).value)"
                                    formControlName="{{colCur.dataName}}"
                                    >
                            </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div> <!-- end addressBlock -->
          </div>
          <div class="flex-row row stdTheme" style="margin-right: 0px;margin-left: 0px;padding: 0px; margin-bottom:0px; margin-top:10px; height: 60px" >
            <div class="col-5" style="margin-top: 0px;">
              <mat-label>{{ 'nbEquipments' | translate }}</mat-label>
            </div>
            <div class="col-7" style="margin-top: -30px;padding-left: 0px;margin-left: 0px;margin-left: -10px;">
              <mat-form-field appearance="standard" floatLabel="never">
                <!-- <mat-label>{{ 'EQUIPMENT.NB_HOURS_OF_USE' | translate }}</mat-label>-->
                <button 
                  mat-icon-button 
                  matPrefix 
                  aria-label="Remove" 
                  (click)="counter('nbEquipments',-1, nbEquipments.value)" >
                  <mat-icon style="margin-top: 10px;">remove</mat-icon>
                </button>
                <input matInput formControlName="nbEquipments" #nbEquipments required readonly style="height: 30px;text-align: center" />
                <div matSuffix style="position: relative">
                  <button 
                    mat-icon-button
                    aria-label="Add" 
                    (click)="counter('nbEquipments',1,  nbEquipments.value)" >
                    <mat-icon style="margin-top: 10px;">add</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </mat-card-content>
      <!--
      <mat-card-actions class="matCardActions">
        <button  mat-raised-button  (click)="onGoHome()">
          {{ 'COMMUN.BUTTON.HOME' | translate }}
          <mat-icon>home</mat-icon>
        </button>
        <div fxFlex></div>
        <button [disabled]="clientForm.invalid" mat-raised-button  (click)="onSubmit()" type="submit">
          {{ 'COMMUN.BUTTON.NEXT' | translate }}
          <mat-icon>navigate_next</mat-icon>
        </button>
      </mat-card-actions>
      -->
    </mat-card>
  </form>
  