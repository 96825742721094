
    <!--  https://stackblitz.com/angular/aqmjygvrdnn?file=src%2Fapp%2Fexpansion-overview-example.html  -->
    <mat-accordion class="stdTheme" style="overflow-x: hidden; overflow-y: hidden;">
        <div *ngFor="let item of dataList;let i = index;" style="overflow-x: hidden; overflow-y: hidden;">
            <div class="flex-row row stdTheme" style="margin-left: 0px;margin-right: 0px;" style="overflow-x: hidden; overflow-y: hidden;">
                <div style="width: 100%" style="overflow-x: hidden; overflow-y: hidden;width: 100%">
                    <mat-expansion-panel class="stdTheme" #parent{{i}} [(expanded)]="item.xpandStatus" style="margin-top: 0px;">
                        <mat-expansion-panel-header  [ngClass]="{
                            'headerSelected':item.xpandStatus,
                            'stdTheme':!item.xpandStatus
                          }" >
                            
                            <mat-panel-title *ngIf="item.childProperties[0].propertyLength === 0" [ngClass]="{
                                'headerSelected':item.xpandStatus,
                                'stdTheme':!item.xpandStatus
                              }" 
                                >
                                    {{item.parentName}}
                                </mat-panel-title>
                            <mat-panel-title *ngIf="item.childProperties[0].propertyLength !== 0" [ngClass]="{
                                'headerSelected':item.xpandStatus,
                                'stdTheme':!item.xpandStatus
                              }" 
                                ><b>
                                    {{item.parentName}} ({{item.childProperties[0].propertyLength}})</b>
                            </mat-panel-title> 
                            
                            <!-- <mat-panel-title >{{item.parentName}}</mat-panel-title> -->
                            <mat-panel-description [ngClass]="{
                                'headerSelected':item.xpandStatus,
                                'stdTheme':!item.xpandStatus
                              }" >
                                <div *ngIf="item.parentTech === 'works' && (item.childProperties[0].duration) && (item.childProperties[0].duration >0)">
                                    {{ 'totalDuration' | translate }} : {{item.childProperties[0].duration}}
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div *ngIf="item.xpandStatus && item.parentTech === 'works'" >
                            <mdi-taskref-actions style="width: 100%" 
                                [optionItem]="item.parentTech" 
                                [ihmOptions]="ihmOptions"
                                [actionType]="actionType" 
                                [wksEntity]="wksEntity"
                                [parentTask]="parentTask"
                                [parentParam]="item.childProperties[0].propertyContent.parentParam"
                                [rowTask]="rowTask"
                                (dataOut)="statutCur($event)"
                                >
                            </mdi-taskref-actions>
                        </div>    
                        <div *ngIf="item.xpandStatus && item.parentTech === 'supplies&parts'" >
                            <mdi-taskref-actions style="width: 100%" 
                                [optionItem]="item.parentTech" 
                                [ihmOptions]="ihmOptions"
                                [actionType]="actionType" 
                                [wksEntity]="wksEntity"
                                [parentTask]="parentTask"
                                [parentParam]="item.childProperties[0].propertyContent.parentParam"
                                [rowTask]="rowTask"
                                (dataOut)="statutCur($event)"
                                >
                            </mdi-taskref-actions>
                        </div>    
                    </mat-expansion-panel>
                </div>
            </div>
        </div>
    </mat-accordion>

