import { Component, OnInit, OnChanges, Input, ViewChild, ViewEncapsulation, Renderer2, SimpleChanges } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
// import { IMyDpOptions, IMyDateModel } from 'mydatepicker'; // https://github.com/kekeh/mydatepicker

import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';

import { UserCard, SUPPORTED_ROLES, RoleModel, RoleSupported, UserResponse } from '../../../models/user.model';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { environment } from '../../../../environments/environment';
import { SUPPORTED_LANGS, LangSupported } from '../../../i18n/translation';
import { WksCentralService } from '../../../job/job-wks/services/wks-central.service';

@Component({
  selector: 'mdi-usercard',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserCardComponent implements OnChanges {
  @Input() statutCall: any;
  @Input() userToUpdate: UserResponse;

  @ViewChild('buttonSubmit', {static: false}) buttonSubmit;
  @ViewChild('refElement', {static: true}) refElement;
  @ViewChild('titleCard', {static: false}) titleCard;

  minBithDayDate = new Date(1900, 0, 1);
  maxBithDayDate = new Date();
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  selectedRoles: RoleModel;
  supportedLangs: any[];
  userCard = new UserCard();

  registrationFailed: boolean;
  birthdayFailed: boolean;
  passwordOk: boolean;
  isEmailAlreadyUsed: boolean;
  loginCtrl: FormControl;
  firstnameCtrl: FormControl;
  lastnameCtrl: FormControl;
  sexeCtrl: FormControl;

  rolesCtrl: FormControl;
  rolesMultiSelect: RoleModel[];

  dpBirthDayCtrl: FormControl;
  emailCtrl: FormControl;
  enabledAccountCtrl: FormControl;
  accountNonExpiredCtrl: FormControl;
  accountNonLockedCtrl: FormControl;
  credentialsNonExpiredCtrl: FormControl;
  userdebugCtrl: FormControl;
  entityCtrl: FormControl;
  userlangCtrl: FormControl;
  usergroupCtrl: FormControl;
  userForm: FormGroup;

  RegisterTitle = 'Register';
  messageTranslate: string;
  tooltipBirthDay: string;
  fmtDate: string;
  sexeList: string[];
  supportedRoles: RoleModel[];
  selectedRole: RoleSupported;
  isUserFound: boolean;
  isUpdateStatut: boolean;
  usergroupList: any;
  entitiesList: any;

  userCur: {
    enabledAccount: boolean,
    accountNonExpired: boolean,
    accountNonLocked: boolean,
    credentialsNonExpired: boolean,
    userdebug: boolean,
  };
  /*
    static passwordMatch(control: FormGroup) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;
      return password !== confirmPassword ? { matchingError: true } : null;
    }
  */
  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private adapterDate: DateAdapter<any>, 
    private _wksCentralService: WksCentralService,
    private renderer: Renderer2) {
      // console.log('Testing constructor');
     }
  ngOnChanges(changes: SimpleChanges) { 
      const listKey = Object.keys(changes);
      for (const propName of listKey) {
        if (changes.hasOwnProperty(propName)) {
          switch (propName) {
            case 'statutCall': {
              this.statutCall = changes['statutCall'].currentValue;

              break;
            }
            case 'userToUpdate': {
              this.userToUpdate = changes['userToUpdate'].currentValue;
              break;
            }

          } // end switch
        } // end if
      } // end loop
      this.initData();
    }

  initData() {
    // console.log('Testing ngOnInit');
    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createUser';
    }
    this.supportedRoles = [];
    this.supportedRoles = SUPPORTED_ROLES;
    if (this._wksCentralService.getWksSupportedRoles() !== undefined)  {
      const listRolesWks = this._wksCentralService.getWksSupportedRoles();
      for (const roleCur of listRolesWks) {
        this.supportedRoles.push(roleCur);
      }
    }
    this.supportedRoles.sort((obj1: RoleModel, obj2: RoleModel) => {
      return obj1.display > obj2.display ? 1 : -1;
    });
    this.supportedLangs = SUPPORTED_LANGS; 
    this.userCur = {
      enabledAccount: true,
      accountNonExpired: true,
      accountNonLocked: true,
      credentialsNonExpired:  true,
      userdebug: true,
    };
    this.loginCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.firstnameCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.lastnameCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.dpBirthDayCtrl = this.fb.control('', [Validators.required]);
    this.sexeCtrl = this.fb.control('', Validators.required);
    this.entityCtrl = this.fb.control('', Validators.required);
    this.usergroupCtrl = this.fb.control('', Validators.required);
    this.userlangCtrl = this.fb.control('', Validators.required);
    this.rolesCtrl = this.fb.control('', Validators.required);
    this.emailCtrl = this.fb.control('', [Validators.required, Validators.pattern(`${environment.emailPattern}`)]);
    this.enabledAccountCtrl = this.fb.control('');
    this.accountNonExpiredCtrl = this.fb.control('');
    this.accountNonLockedCtrl = this.fb.control('');
    this.credentialsNonExpiredCtrl = this.fb.control('');
    this.userdebugCtrl = this.fb.control('');
    this.userForm = this.fb.group({
      login: this.loginCtrl,
      firstname: this.firstnameCtrl,
      lastname: this.lastnameCtrl,
      sexe: this.sexeCtrl,
      // passwordForm: this.passwordForm,
      dpBirthDay: this.dpBirthDayCtrl,
      email: this.emailCtrl,
      entity: this.entityCtrl,
      usergroup: this.usergroupCtrl,
      userlang: this.userlangCtrl,
      roles: this.rolesCtrl,
      enabledAccount: this.enabledAccountCtrl,
      accountNonExpired: this.accountNonExpiredCtrl,
      accountNonLocked: this.accountNonLockedCtrl,
      credentialsNonExpired: this.credentialsNonExpiredCtrl,
      userdebug: this.userdebugCtrl,
    },
      {
        updateOn: 'blur'
      });

    this.fmtDate = this._translate.getLocalFmt('dateFormat');
    this.tooltipBirthDay = this._translate.getTranslate('tooltipBirthDay');
    this.tooltipBirthDay = this._translate.replace(this.tooltipBirthDay, this.fmtDate);
    this.sexeList = CommonMethods.listToArray(`${environment.sexes}`, ',');
    this.birthdayFailed = false;
    this.isEmailAlreadyUsed = false;
    this.adapterDate.setLocale(this._translate.currentLang);
    if (this.statutCall === 'createUser') {
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Creation';
      this.passwordOk = false; // control input
      // this.renderer.setValue(this.titleCard.nativeElement, 'Creation');
    }
    this.isUpdateStatut = false;
    this.getListEntities();
    if (this.statutCall === 'updateUser') {
      this.passwordOk = true; // no control
      this.isUpdateStatut = true;
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Update';
      this.userForm.controls['login'].disable();
      this.isEmailAlreadyUsed = false;
      this.setValuesToUpdate();
      // this.renderer.setValue(this.titleCard.nativeElement, 'Creation');
    }

    this.isUserFound = false;
    this.userForm.controls['login'].valueChanges.subscribe (
      () => {
        this.validUserName();
      }
    );
    this.userForm.controls['dpBirthDay'].valueChanges.subscribe (
      () => {
        this.validBirthDay();
      }
    );
    this.userForm.controls['email'].valueChanges.subscribe (
      () => {
        if (this.statutCall === 'createUser') {
          this.ctrlUserEmail(this.userForm.controls['email'].value);
        }
      }
    );
  }
  initDpBirthDay(event: MouseEvent) {

    if ((event.target as Element).tagName.toLowerCase() !== 'matDpBirthDay') {
      this.adapterDate.setLocale(this._translate.currentLang);
    }

  }
  toggle(field: string, $event: any) {
    // console.log($event);

    switch (field) {
      case 'enabledAccount':
        this.userCur.enabledAccount = $event;
        break;
      case 'accountNonExpired':
        this.userCur.accountNonExpired = $event;
        break;
      case 'accountNonLocked':
        this.userCur.accountNonLocked = $event;
        break;
      case 'credentialsNonExpired':
        this.userCur.credentialsNonExpired = $event;
        break;
      case 'userdebug':
        this.userCur.userdebug = $event;
        break;
    }
  }
  onLangChange(event: LangSupported) {
    this.userCard.userlang = event.value;
  }
  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    return lang === this._translate.currentLang;
  }
  getPasswd(event: string) {
    this.userCard.password = event;
    this.passwordOk = true;
  }
  getListEntities() {
    this._userService.getEntities()
    .subscribe(
      data => {
        const listEntities = data.body;
        this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
      }
    );
  }
  doGetListGrps(_entity: string) {
    // this.getListGrps(_entity);
    this.getListGrps(_entity)
    .then ((usergroupLists) => {

    })
    .then((error) => {

    });
  }
  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .subscribe(
          data => {
            const usergroupLists = data.body;
            this.usergroupList = CommonMethods.jsonPropToArray(usergroupLists, 'usergroup');
              resolve(usergroupLists);
          }, err => {
            this.usergroupList = [];
            reject(err);
          }
        );
      });
  }

  validAge() {
    const birthDay: Date = this.userForm.value.dpBirthDay;
    // const inputDate = new Date(birthDay.jsdate);
    const today = moment().startOf('day');
    const delta = today.diff(birthDay, 'years', false);
    if (Number.isNaN(delta)) { return null; }
    const ageMin = this._translate.getLocalFmt('ageMin');
    const ageMax = this._translate.getLocalFmt('ageMax');
    return delta < ageMin || delta > ageMax ? false : true;
  }

  doCancel() {
    this.userForm.reset();
    if (this.statutCall) {
      this._userService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  validDpBirthDay() {
    this.validBirthDay();
  }
  validBirthDay() {
    this.birthdayFailed = false;
    if (!this.validAge()) {
      this.messageTranslate = this._translate.getTranslate('Invalid birthday');
      this.birthdayFailed = true;
      return false;
    } else {
      return true;
    }
  }
  validUserName() {
    const userName = this.userForm.controls['login'].value;
    if ((userName) && (userName.length > 3)) {
      if ((this.statutCall === 'createUser')
       || ((this.statutCall === 'updateUser')
              && (userName !== this.userToUpdate.username))) {
        this.ctrlUser(userName);
      }
    }
  }
  ctrlUser(_userName: String): UserResponse {
    this.isUserFound = false;
    let dataResponse: UserResponse = null;
    this._userService.getUser(_userName)
    .subscribe(
      data => {
        dataResponse = data.body;
        this.isUserFound = true;
        if (this.statutCall === 'createUser') {
          this.userForm.controls['login'].setErrors({ 'isUserFound': true});
        }
        // console.log('User found : ', _userName);
      }, () => {
        // console.log('User unfound : ', _userName);
      }
    );
    return dataResponse;
  }
  ctrlUserEmail(_email: String): UserResponse {
    this.isEmailAlreadyUsed = false;
    let dataResponse: UserResponse = null;
    this._userService.getUserEmail(_email)
    .subscribe(
      data => {
        dataResponse = data.body;
        if (this.statutCall === 'createUser') {
          this.isEmailAlreadyUsed = true;
          this.messageTranslate = this._translate.getTranslate('Email is already used');
        }
        // console.log('Email found : ', _email);
      }, () => {
        // console.log('Email unfound : ', _email);
      }
    );
    return dataResponse;
  }
  register() {
    this.registrationFailed = false;


    this.prepareValidation();
    this._userService.register(this.userCard, this.statutCall)
      .subscribe(
        () => {
          this.userForm.reset();
          if (this.statutCall) {
            this._userService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation() {
    this.userCard.username = this.userForm.value.login;
    if (this.statutCall === 'updateUser') {
      this.userCard.username = this.userToUpdate.username;
    }
    this.userCard.firstname = this.userForm.value.firstname;
    this.userCard.lastname = this.userForm.value.lastname;
    /* if (this.statutCall === 'createUser') {
      this.userCard.password = this.userForm.get('passwordForm.password').value;
    } */
    this.userCard.sexe = this.userForm.value.sexe;
    this.userCard.email = this.userForm.value.email;
    this.userCard.birthday = CommonMethods.dateMomentForBdd(this.userForm.value.dpBirthDay, `${environment.fmtDateTimeBdd}`);
    this.userCard.roles = CommonMethods.arrayStringToString(this.userForm.value.roles, ',');
    const bindRoles: RoleModel[] = this.userForm.value.roles;
    let roleObj$: RoleModel;
    let roles$ = '';
    let nbRoles$ = 0;
    for (roleObj$ of bindRoles) {
      if (nbRoles$ === 0) {
        roles$ = roleObj$.value;
        nbRoles$++;
      } else {
        roles$ = roles$ + ',' + roleObj$.value;
      }
    }
    this.userCard.roles = roles$;
    this.userCard.enabled =  this.userCur.enabledAccount;
    this.userCard.accountNonExpired =  this.userCur.accountNonExpired;
    this.userCard.accountNonLocked =  this.userCur.accountNonLocked;
    this.userCard.credentialsNonExpired =  this.userCur.credentialsNonExpired;
    this.userCard.userdebug =  this.userCur.userdebug;
    this.userCard.entity = this.userForm.value.entity;
    this.userCard.usergroup = this.userForm.value.usergroup;
    const lsInput: LangSupported = this.userForm.value.userlang;
    this.userCard.userlang = lsInput.value;
  }

  setValuesToUpdate() {
    this.userForm.controls.login.setValue(this.userToUpdate.username);
    this.userForm.controls.firstname.setValue(this.userToUpdate.firstname);
    this.userForm.controls.lastname.setValue(this.userToUpdate.lastname);
    this.userForm.controls.sexe.setValue(this.userToUpdate.sexe);
    this.userForm.controls.email.setValue(this.userToUpdate.email);
    const localDate = new Date(this.userToUpdate.birthday);
    // console.log('date ', localDate.getDate());
    this.userToUpdate.birthday = localDate;
    // const localDate = moment(this.userToUpdate.birthday, 'x').format(this._translate.getLocalFmt('dateFormat'));
    this.userForm.controls.dpBirthDay.setValue(this.userToUpdate.birthday);

    const bindRoles: RoleModel[] = [];
    let roleObj: RoleModel;
    const curRoles = this.userToUpdate.roles.split(',');

    for (const roleValue of curRoles) {
      for (roleObj of this.supportedRoles) {
        if (roleObj.value === roleValue) {
          bindRoles.push(roleObj);
        }
      }
    }
    this.userForm.controls.roles.setValue(bindRoles);
    /*
    this.userForm.controls.enabledAccount.setValue(this.userToUpdate.enabled);
    this.userForm.controls.accountNonExpired.setValue(this.userToUpdate.accountNonExpired);
    this.userForm.controls.accountNonLocked.setValue(this.userToUpdate.accountNonLocked);
    this.userForm.controls.credentialsNonExpired.setValue(this.userToUpdate.credentialsNonExpired);
    this.userForm.controls.userdebug.setValue(this.userToUpdate.userdebug);
    */
    this.userForm.controls.entity.setValue(this.userToUpdate.entity);
    this.userCur = {
      enabledAccount: this.userToUpdate.enabled,
      accountNonExpired: this.userToUpdate.accountNonExpired,
      accountNonLocked: this.userToUpdate.accountNonLocked,
      credentialsNonExpired:  this.userToUpdate.credentialsNonExpired,
      userdebug:  this.userToUpdate.userdebug,
    };




    this.doGetListGrps(this.userToUpdate.entity);
    this.userForm.controls.usergroup.setValue(this.userToUpdate.usergroup);
    const lsUpdate: LangSupported = this._translate.getLangOject(this.userToUpdate.userlang);
    this.userForm.controls.userlang.setValue(lsUpdate);
  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'login': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Login is required') :
            this.userForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your login should be at 3 characters') : messageLib = '';
        break;
      }
      case 'userFound': {
        messageLib = this._translate.getTranslate('userNameFound');
        break;
      }
      case 'lastname': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('lastname is required') :
            this.userForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your lastname should be at 3 characters') : messageLib = '';
        break;
      }
      case 'firstname': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('firstname is required') :
            this.userForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your firstname should be at 3 characters') : messageLib = '';
        break;
      }
      case 'email': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Email is required') :
              this.userForm.controls[_ctrlName].hasError('pattern') ?
                messageLib = this._translate.getTranslate('This is not a valid email') : messageLib = '';
        break;
      }
      case 'sexe': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Sexe is required') : messageLib = '';
        break;
      }
      case 'entity': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Entity is required') : messageLib = '';
        break;
      }
      case 'usergroup': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Group is required') : messageLib = '';
        break;
      }

      case 'dpBirthDay': {
        !this.userForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.userForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Birth day is Required') :
            this.userForm.controls[_ctrlName].hasError('invalidBirthDay') ?
              messageLib = this._translate.getTranslate('This is not a valid birth day') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
}
