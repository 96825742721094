import { Component, OnDestroy, OnInit, NgZone , ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { ColLabelRow, ReportInt  } from '../job-data/models/job-data.model';
import { UserService } from '../../services/user.service';
import { JobDataService } from '../job-data/services/job-data.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '../../services/translate.service';
import { NotificationsService } from '../common/services/notifications.service';
import { NotifUsers } from '../../models/common.model';
import { EntityModel, UsersGrpModel } from '../../models/user.model';
import { UserEnvtInt } from '../job-data/models/job-data.model';
import { WksCentralService } from '../job-wks/services/wks-central.service';



@Component({
  selector: 'mdi-job-home',
  templateUrl: './job-home.component.html',
  styleUrls: ['./job-home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class JobHomeComponent implements OnInit, OnDestroy {


  nodesTree: any;
  optionsTree: ITreeOptions;
  isAgGrid: boolean;
  isDashboard: boolean;
  reportToExecute: ReportInt;
  sidenavCollapsed = true;
  homeItem: any;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  private readonly onDestroy = new Subject<void>();
  notificationsSubscription: Subscription;

  appTitle = environment.appTitle;
  appSigle = environment.appSigle;
  wksItemRecept: any;
  equipButtonsItems:  any[];
  othersButtonsItems:  any[];
  settingsButtonsItems:  any[];
  jobsButtonsItems:  any[];

  menuIsLoaded: boolean;
  constructor( private _userService: UserService,
              private _notificationsService: NotificationsService,
              private _router: Router, 
              private _translate: TranslateService,
              private _jobDataService: JobDataService,
              private sanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              private _wksCentralService: WksCentralService) {
  }
  
  
  ngOnInit() {

    this.isAgGrid = false;
    this.isDashboard = false;
    this.menuIsLoaded = false;
    this.loadData();
    this.loadEntityLogged();
    this.loadGrpLogged();
    /*
    if (environment.connectStream) {
      this.notificationsSubscription = this._notificationsService.connectNotif('')
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: notif => {
          this._zone.run(() => {
            const msgData: NotifUsers = JSON.parse(notif);
            console.log(msgData.recipients, ' ' + msgData.contentMessage);
            this.openSnackBar(msgData.contentMessage, 'No-Action');
              // Do stuff here
          });
        }
      });
    }*/
        // WKS -MENU
    this._wksCentralService.wksMenuButtonEvents.subscribe ( valueMenu => {
      // this.itemList = [{ label: '', routing: '' }];
      const menuTemp = JSON.stringify(valueMenu);
      if (this.wksItemRecept === menuTemp) {
        return;
      }

      if (valueMenu) {
        this.equipButtonsItems = [];
        this.jobsButtonsItems = [];
        this.othersButtonsItems = [];
        this.settingsButtonsItems = [];
        this.wksItemRecept = menuTemp;
        const autorizedItems = [];
        // const menuList = JSON.parse(valueMenu);
        const menuList = valueMenu as any[];
/*
        menuList.sort((obj1: any, obj2: any) => {
          return obj1.id > obj2.id ? 1 : -1;
        });
*/
        for (const item of menuList) {
          if (item.itemParent === 'wks_parameters') {
            this.settingsButtonsItems.push(item);
          }
          if (item.itemParent === 'wks_appointments') {
            this.settingsButtonsItems.push(item);
          }
          if (item.itemParent === 'wks_worksOptions') {
            this.settingsButtonsItems.push(item);
          }
          if ((item.itemMenupage === undefined) || (item.itemMenupage === null) || (item.itemMenupage === '')) {
            continue;
          }
          // console.log('jobHome : ' + item.itemParent + ' ' + item.itemLabel + ' ' + item.itemMenupage);
          const menuOption = JSON.parse(item.itemMenupage);
          const menuConstraint = JSON.parse(item.itemConstraints);
          // item.options = [];
          if ((menuConstraint !== undefined) && (menuConstraint !== null) && ( menuConstraint.submenu !== undefined)) {
            item.options = menuConstraint.submenu;
            /*item.options = [];
            for (const option of menuConstraint.submenu) {
              item.options.push(option);
            }*/
          }

          if ( menuOption.homepageEquip !== undefined) {
            item.itemImg = menuOption.homepageEquip.img + '-light.' + menuOption.homepageEquip.type;
            item.buttonHtml = this.generateHtmlButton(item.itemLabel, item.itemImg);
            this.equipButtonsItems.push(item);
          }
          if ( menuOption.homepageOther !== undefined) {
            item.itemImg = menuOption.homepageOther.img + '-light.' + menuOption.homepageOther.type;
            item.buttonHtml = this.generateHtmlButton(item.itemLabel, item.itemImg);
            this.othersButtonsItems.push(item);
          }
          if ( menuOption.homepageJobs !== undefined) {
            item.itemImg = menuOption.homepageJobs.img + '-light.' + menuOption.homepageJobs.type;
            item.buttonHtml = this.generateHtmlButton(item.itemLabel, item.itemImg);
            this.jobsButtonsItems.push(item);
          }
        }

      }
      this.menuIsLoaded = true;
    }) ;
  }  
  // generateHtmlButton --> Problème : " converti en \" par le sanitizer
  generateHtmlButton(labelCur: string, imgSrc: string): any  {

    const label = this._translate.instant(labelCur);
    const template = '' +
    '<button class="btn buttonMenu mat-raised-button mat-elevation-z8" id="' + labelCur + '"' +
    ' [mdePopoverTriggerFor]="' + labelCur + '"' +
    ' mdePopoverTriggerOn="hover"' +
    ' #popoverTrigger="mdePopoverTrigger"' +
    ' mdePopoverOffsetX="-1"' +
    ' mdePopoverOffsetY="-20"' +
    ' [routerLink]="[item.itemRoutingPath,\'list\']">' +
    ' <a> <img src="assets/img/' + imgSrc + '" width="100" height="100"> </a>' +
    ' </button>' +
    ' <mde-popover #' + labelCur + '="mdePopover" [mdePopoverOverlapTrigger]="false" >' + label + ' </mde-popover>';

    return this.sanitizer.bypassSecurityTrustHtml(template);
  }
  loadData() {
   if (this._userService.getUserLogged() === undefined) { return; }
   this._jobDataService.buildMenu(this._userService.getUserLogged().entity, 
                                  this._userService.getUserLogged().userlang, 
                                  this._userService.getUserLogged().id)
   .pipe(takeUntil(this.onDestroy))
   .subscribe(
     data => {
      if (data.body === null) {
        this.nodesTree = [];
      } else {
        const jsonObject = JSON.parse(data.body[0].entitymenu);
        this.nodesTree = jsonObject.menutree;
        const jsonMenu = JSON.parse(data.body[0].otherData);
        const userEnvt = data.body[0].userEnvt as UserEnvtInt;
        if (userEnvt) {
          this._jobDataService.setUserEnvt(userEnvt);
        }
        const menuLabels: ColLabelRow [] = jsonMenu.labels;
        const menuReports: ReportInt [] = jsonMenu.reports;
        menuReports.sort(function(obj1, obj2) {
          return obj1.reportMenu > obj2.reportMenu ? 1 : -1;
        });
        // console.log( menuItems + ' ' + menuReports);

      }
     }, () => {
       // console.log('Entity unfound : ', _entity);
     }
   );
  }
  loadEntityLogged() {
    if (this._userService.getUserLogged() === undefined) { return; }
    this.getEntity(this._userService.getUserLogged().entity)
    .then ((entity: EntityModel) => {
      this._userService.getUserLogged().entityCur = entity;
      this._userService.setEntityLogged(entity);
      this.loadGrpLogged();
    })
    .then((error) => {

    });
    
  }
  loadGrpLogged() {
    if (this._userService.getUserLogged() === undefined) { return; }
    this.getGrp(this._userService.getUserLogged().entity, this._userService.getUserLogged().usergroup)
    .then ((grpCur: UsersGrpModel) => {
      this._userService.getUserLogged().grpCur = grpCur;
    })
    .then((error) => {

    });
    
  }
  getEntity(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntity(_entity)
        .subscribe(
          data => {
            resolve(data.body);
          }, err => {
             reject(err);
          }
        );
      });

  }
  getGrp(_entity: string, _grp: string) {
    return new Promise((resolve, reject) => {
        this._userService.getGrp(_entity, _grp)
        .subscribe(
          data => {
            resolve(data.body);
          }, err => {
             reject(err);
          }
        );
      });

  }
  
  // https://material.angular.io/components/snack-bar/overview
  // evolution : http://www.eduforbetterment.com/snackbar-model-in-angualr/
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  capitalize(s) {
      return s && s[0].toUpperCase();
  }
  toggleSidenav(_eventArg: any) {

   //  if((_eventArg.data != undefined) && (_eventArg.data.requestRef)) console.log('Request : ' + _eventArg.data.requestRef);
   if (_eventArg === 'close')  {
    this.sidenavCollapsed = true;
    this.m_sidenav.close();
    return false;
   }
   this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.m_sidenav.close();
    } else {
      this.m_sidenav.open();
    }
  }
  closeSidenav() {
    this.sidenavCollapsed = !this.sidenavCollapsed;
  }
 
  ngOnDestroy() {

    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }
  logout(event: any) {
    event.preventDefault();
    this._userService.logout();
    this._router.navigate(['']);
  }

}
