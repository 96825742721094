import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder,  FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { UserService } from '../../../../../../../services/user.service';
import { CountryLabel, StatesLabel, CountryDef } from '../../../../../../../models/data.model';
import { WksCentralService } from '../../../../../services/wks-central.service';
import { WksWorksService } from '../../../../../services/wks-works.service';
import { WksBusinessModel, BsnAccountingModel, PaiementsTerm } from '../../../../../models/wks-business.model';
import { WksEntityModel } from '../../../../../models/wks-entity.model';
import { CommonMethods } from '../../../../../../../job/common/tools/commonMethods';
import { TranslateService } from '../../../../../../../services/translate.service';
import { RessourcesService } from '../../../../../../common/services/ressources.service';
import { EventBroadcastService } from '../../../../../../common/services/event-broadcast.service';
import { LabelValue} from '../../../../../../../models/data.model';
import { IhmOptions } from '../../../../../models/wks-param.model';
// WorkInputEvent
import { WorkInputEvent} from '../../../../../models/wks-works.model';
@Component({
  selector: 'mdi-bns-card',
  templateUrl: './bns-card.component.html',
  styleUrls: ['./bns-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BnsCardComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() wksBnsToUpdate: WksBusinessModel;
  @Input() optionItem: string;
  @Input() ihmOptions: IhmOptions;

  @Output() dataOut = new EventEmitter<WorkInputEvent>();
  private readonly onDestroy = new Subject<void>();

  wksBnsForm: FormGroup;

  bsnAccountingCur: BsnAccountingModel;

  isUpdateStatut: boolean;
  structureCtrl: FormControl;
  taxsystemCtrl: FormControl;
  wksBnsFormInit: boolean; 
  isSupplier: boolean;
  isSubContractor: boolean;
  isCustomer: boolean;
  isCashPay: boolean;

  isProfessionnal: boolean;
  isIndividual: boolean;
  isCompany: boolean;
  isAdministration: boolean;
  isPerson: boolean;
  isCountrySelected: boolean;
  isProfID: boolean;
  isNatIdentID: boolean;
  markChange: boolean;
  isReadOnly: boolean;
// country
  countryKeyToLoad: string;
  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];

  countriesCount: number;
  // countryMap: Map<string, CountryDef>;
  countryCur: CountryDef;
  addressCur: any;
  civilityList: any[];

// phone / email
  configAddress: any;
  dataAddressInput: any;
  dataIdentifierInput: any;
  scalesList: string[];
  isReady: boolean;
  paiementsTerm: PaiementsTerm;
  delayList: LabelValue[];
  conditionsList: LabelValue[];
  displayData: boolean;
  customerOutstandingValue: any;
  customerOutstandingEntity: any;

  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };

  constructor(private _userService: UserService,
              private eventBroadcastService: EventBroadcastService,
              private _translate: TranslateService,
              private _ressources: RessourcesService,
              private _wksCentralService: WksCentralService,
              private _wksWorksService: WksWorksService,
              private fb: FormBuilder) {
                
               }


  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case
           // wksBnsToUpdate
          case 'wksBnsToUpdate': {
            this.wksBnsToUpdate = changes['wksBnsToUpdate'].currentValue;
            break;
          } // end case
          // optionItem
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          } // end case
          // ihmOptions
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop

    this.initData();
  }
  initData() {
    this.isReadOnly = true;
    this.isSupplier = false;
    this.isSubContractor = false;
    this.isCustomer = false;
    this.isCountrySelected = false;
    this.isProfID = false;
    this.isCashPay = false;
    this.isCompany = false;
    this.isAdministration = false;
    this.isPerson = false;
    this.isProfessionnal = false;
    this.isIndividual = false;
    this.wksBnsFormInit = false;
    this.displayData = false;
    if ( this.optionItem === 'wks-customers') {
      this.isCustomer = true;
    }
    if ( this.optionItem === 'wks-subcontractors') {
      this.isSubContractor = true;
    }
    if ( this.optionItem === 'wks-suppliers') {
      this.isSupplier = true;
    }
    this.isReady = false;
    this.initConfigAddress(undefined, undefined);
    /*
    this.configAddress = {
      langCur: this._translate.getCurrentLang(),
      countryDefault: this._userService.getUserLogged().entitycountry,
      address: false,
      telephone: true,
      email: true,
      contact: false,
      corporate: false,
      identifier: undefined,
      isReadOnly: false,
      fieldsId: []
    };
    */
    this.paiementsTerm = this._wksCentralService.getPaiementsTerm();
    const delaysItem = this.paiementsTerm.delaysDays;
    this.delayList =  [];
    for (const item of delaysItem) {
      const label = item.toString() + ' (' + this._translate.instant('days') + ')';
      const opt = {
        value: item,
        label: label,
      };
      this.delayList.push(opt);
    }
    const conditionsITems = this.paiementsTerm.conditions;
    this.conditionsList =  [];
    for (const item of conditionsITems) {
      const label = this._translate.instant(item);
      const opt = {
        value: item,
        label: label,
      };
      this.conditionsList.push(opt);
    }
    
    const itemEmpty = {
      value: 'no condition',
      label: this._translate.instant('no condition'),
    }; 
    this.conditionsList.splice(0, 0, itemEmpty);

    if ((this.isSubContractor) || (this.isSupplier)) {
      this.isProfessionnal = true;
    }

    const civilityItems = this._ressources.getCivilityList();
    this.civilityList = [];

    for (const item of civilityItems) {
      const label = this._translate.instant(item);
      const opt = {
        item: item,
        label: label,
      };
      this.civilityList.push(opt);
    }
    this.loadCountries();

    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createBsn';
    }
    this.isUpdateStatut = false;
    if (this.statutCall === 'updateBsn') {
      this.isUpdateStatut = true;
      this.isReadOnly = false;
    } else if (this.statutCall === 'readBsn') {
      this.isUpdateStatut = false;
      this.isReadOnly = true;
    } else {
      this.isReadOnly = false;
    }
    const wksEntity = this._wksCentralService.getWksEntityCur();
    const scalesTmp = wksEntity.entityScales.split(',');
    this.scalesList = [];
    for (const scaleCur of scalesTmp) {
      this.scalesList.push(scaleCur);
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
    };
   
    const customerOutstanding = this._wksCentralService.getWksEntityCur().customerOutstanding;
    if (customerOutstanding !== undefined) {
      const regexNum = new RegExp('[^0-9]', 'g');
      this.customerOutstandingEntity = customerOutstanding.replace(regexNum, '');
    } else  {
      this.customerOutstandingEntity = 0;
    }
    this.buildForm();

    this.initForm();
    
  }
  initForm() {
          
      if (this.statutCall === 'updateBsn') {
        this.fillForm();
      } else if (this.statutCall === 'readBsn') {
        this.fillForm();
      } else {
        if (this.wksBnsToUpdate === undefined)  {
          this.countrySelected(this._userService.getUserLogged().entitycountry); 
          this.wksBnsForm.controls.bsnCountry.setValue(this.countryCur.isoAlpha2);
          this.setValuesToSave();
        } else  {
          this.fillForm();
        }
      }
      
      this.wksBnsFormInit = true;
  }
  buildForm() {

    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.wksBnsForm = this.fb.group({
        bsnStructure: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnTaxsystem: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnCountry: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnCorporateName: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnCivility: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnFirstName: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnLastName: this.fb.control({value: false, disabled: this.isReadOnly}),
        bsnCashpay: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnAccount: this.fb.control({value: '', disabled: this.isReadOnly}),
        customerOutstanding: this.fb.control({value: '', disabled: this.isReadOnly}),
        scaleName: this.fb.control({value: '', disabled: this.isReadOnly}),
        bsnCoef: this.fb.control({value: '1.0', disabled: this.isReadOnly}),
        bsnDelayDays: this.fb.control({value: '0', disabled: this.isReadOnly}),
        bsnDefferedPaiement: this.fb.control({value: '', disabled: this.isReadOnly}),
      //  bsnNatIdentity: this.fb.control(''),
        bsnNatRegister: this.fb.control({value: '', disabled: this.isReadOnly}),
        taxableVat: this.fb.control({value: '0', disabled: this.isReadOnly}),
        idVat: this.fb.control({value: '', disabled: this.isReadOnly}),
        customsRegistration: this.fb.control({value: '0', disabled: this.isReadOnly}),
        customsId: this.fb.control({value: '', disabled: this.isReadOnly}),
        freeZone: this.fb.control({value: '0', disabled: this.isReadOnly}),
        freeZoneId: this.fb.control({value: '', disabled: this.isReadOnly}),
      },
      {
        updateOn: 'blur'
      },
    );    
    
    this.wksBnsForm.valueChanges.subscribe(
      result => { 
        // console.log(this.wksBnsForm.status);
        if (!this.displayData)  { 
          const wksBnsCur = this.fillModel();
          if (wksBnsCur) { 
            this.onFormValidation(this.wksBnsForm.status, wksBnsCur);
          }
        }
      }
    );
    this.isReady = true;
    /* 
    this.wksBnsForm.statusChanges
      .subscribe(val => this.onFormValidation(val));
    */
  }
  
  onFormValidation(validity: string, wksBnsCur: WksBusinessModel) {
    let workInputEvent: WorkInputEvent = {
      objectCur: undefined,
      action: undefined, 
      customer: undefined,
    };
    switch (validity) {
      case 'VALID':
        // WorkInputEvent
        workInputEvent = {
          objectCur: this.optionItem,
          action: this.statutCall, 
          customer: wksBnsCur,
        };
        this.dataOut.emit(workInputEvent);
        break;
      case 'INVALID':
        workInputEvent = {
          objectCur: this.optionItem,
          action: 'INVALID', 
          customer: wksBnsCur,
        };
        this.dataOut.emit(workInputEvent);
        break;
    }
  }
  
  loadCountries() {
    const countriesListTmp = this._ressources.getCountriesList();
    // this.countryMap = new Map<string, CountryDef>();
    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
      // this.countryMap.set(countryTmp.isoAlpha2, countryTmp);
    }

    this.countriesCount = this.countriesCacheList.length;
    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];
 
  }
  onKeyCountry(value: any) {
    // console.log(value);
    this.countriesFilteredList = this.searchCountry(value);
  }
  searchCountry(value: string) { 
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  onStructureChange(event: any) {
    // console.log('onStructureChange : ' + event);
    let newEvent: any;
    this.isCompany = false;
    this.isAdministration = false;
    this.isPerson = false;
    this.isProfessionnal = false;
    /*
    if ((this.isSubContractor) || (this.isSupplier)) {
      this.isProfessionnal = true;
      this.configAddress.identifier = 'professional';
    }
    */
    this.isIndividual = false;
    this.wksBnsToUpdate.bsnType = event;
    this.wksBnsForm.controls.bsnTaxsystem.setValue('unknow');
    this.wksBnsForm.controls.bsnCashpay.setValue(true);
    this.isCashPay = true;
    this.wksBnsForm.controls.bsnAccount.setValue(this._wksCentralService.getWksEntityCur().accountCash);
    // scaleName
    this.wksBnsForm.controls.scaleName.setValue('PUBLIC');
    this.wksBnsForm.controls.bsnDefferedPaiement.setValue(this.conditionsList[0].value);
    if ((event === 'company') || (event === 'administration')) {
      this.isCompany = true;
      this.isAdministration = (event === 'administration');
      this.isProfessionnal = true;
      this.markChange = true;
      this.initConfigAddress(event, 'professional');
      /*
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = false;
      this.configAddress.contact = false;
      this.configAddress.corporate = true;
      this.configAddress.email = false;
      this.configAddress.identifier = 'professional';
      */
      // Remplace Onchange àInput fonctionnement aléatoire
      newEvent = {
        eventType: 'config',
        eventData : this.configAddress,
       };
      this.eventBroadcastService.broadcastEvent(newEvent);
      newEvent = {
        eventType: 'contacts',
        eventData : event,
       };
      this.eventBroadcastService.broadcastEvent(newEvent);
      // this.configContacts = Object.assign({}, this.configContacts);
    } else {
      this.isPerson = true;

      this.markChange = true;
      this.initConfigAddress('person', this.wksBnsForm.controls.bsnTaxsystem.value);
      /*
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = true;
      this.configAddress.contact = true;
      this.configAddress.corporate = false;
      this.configAddress.email = true;
      this.configAddress.identifier = undefined;
      */
      // Remplace Onchange àInput fonctionnement aléatoire
      newEvent = {
        eventType: 'config',
        eventData : this.configAddress,
       };
      this.eventBroadcastService.broadcastEvent(newEvent);
      newEvent = {
        eventType: 'contacts',
        eventData : 'person',
       };
      this.eventBroadcastService.broadcastEvent(newEvent);
      // this.configContacts = Object.assign({}, this.configContacts);
    }

  }

  onTaxsystemChange(event: any) {
    // console.log('onTaxsystemChange : ' + event);

    this.isProfessionnal = false;
    this.isIndividual = false;
    let identifierId: string;
    if (event === 'professional') {
      this.isProfessionnal = true;
      identifierId = 'professional';
    } else if (event === 'individual' ) {
      this.isIndividual = true;
      identifierId = 'individual';
    } else {
      this.isIndividual = false;
      this.isProfessionnal = false;
      identifierId = 'unknow';
    }
    this.wksBnsToUpdate.bsnTax = event;
    const newEvent = {
      eventType: 'identifier',
      eventData : identifierId,
     };
    this.eventBroadcastService.broadcastEvent(newEvent);


  }
  countrySelected($country: any) {
    // console.log('countrySelected : ' + $country);
    this.isCountrySelected = true;
    this.isProfID = false;
    this.countryCur = this._ressources.getCountry($country);
    this.wksBnsForm.controls.bsnCountry.setValue($country);
    this.initConfigAddress(this.wksBnsForm.controls.bsnStructure.value, this.wksBnsForm.controls.bsnTaxsystem.value);
  }
  changeCashPay($event: boolean) {
    // console.log($event);
    if ((this.bsnAccountingCur === undefined) || 
        (this.bsnAccountingCur === null)) {
      
      this.bsnAccountingCur = JSON.parse(JSON.stringify(this.getBsnAccountingEmpty()));
     }
     this.isCashPay = $event;
     if (!this.isReadOnly) {
      if ($event) {
        this.wksBnsForm.get('bsnAccount').disable();
        this.wksBnsForm.get('bsnDelayDays').disable();
        this.wksBnsForm.get('bsnDefferedPaiement').disable();
      } else {
        this.wksBnsForm.get('bsnAccount').enable();
        this.wksBnsForm.get('bsnDelayDays').enable();
        this.wksBnsForm.get('bsnDefferedPaiement').enable();
      }
    }
    this.bsnAccountingCur.bsnCashpay = $event;
  }
  changeTaxableVat($event: boolean) {
    this.bsnAccountingCur.taxableVat = $event;
    if (!this.bsnAccountingCur.taxableVat) {
      this.wksBnsForm.controls.idVat.disable();
      this.wksBnsForm.controls.idVat.setValue('');
    } else  {
      this.wksBnsForm.controls.idVat.enable();
    }

  }
  changeCustomsRegistration($event: boolean) {
    this.bsnAccountingCur.customsRegistration = $event;
    if (!this.bsnAccountingCur.customsRegistration) {
      this.wksBnsForm.controls.customsId.disable();
      this.wksBnsForm.controls.customsId.setValue('');
    } else  {
      this.wksBnsForm.controls.customsId.enable();
    }
  }
  changeFreeZone($event: boolean) {
    this.bsnAccountingCur.freeZone = $event;
    if (!this.bsnAccountingCur.freeZone) {
      this.wksBnsForm.controls.freeZoneId.disable();
      this.wksBnsForm.controls.freeZoneId.setValue('');
    } else  {
      this.wksBnsForm.controls.freeZoneId.enable();
    }
  }
  changeBsnAccountField(_field, $value: string) {
    if ((this.bsnAccountingCur === undefined) || 
        (this.bsnAccountingCur === null)) {
      
        this.bsnAccountingCur = JSON.parse(JSON.stringify(this.getBsnAccountingEmpty()));
     }
    if (_field === 'bsnAccount') {
      this.bsnAccountingCur.bsnAccount = $value;
    }
    if (_field === 'scaleName') {
      this.bsnAccountingCur.scaleName = $value;
    }
    if (_field === 'bsnCoef') {
      this.bsnAccountingCur.bsnCoef = Number.parseInt($value, 10);
    }
    if (_field === 'bsnDelayDays') {
      this.bsnAccountingCur.bsnDelayDays = Number.parseInt($value, 10);
    }
    if (_field === 'bsnDefferedPaiement') {
      this.bsnAccountingCur.bsnDefferedPaiement = $value;
    }
  }
  onChangeCustomerOutstanding($event: any) {
    this.customerOutstandingValue = $event;
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  dataOutAddress(event: any) {
    this.dataAddressInput = event;
    if (!this.dataAddressInput.formAdressValid) {
      return;
    }
    if (this.wksBnsToUpdate.bsnAddress === undefined) {
      this.wksBnsToUpdate.bsnAddress = {};
     }
    const cityTest = this.dataAddressInput.address.city;
    
    if ((this.dataAddressInput.formAdressValid) && (cityTest !== '')) {
      this.wksBnsToUpdate = this.fillModel();
      const workInputEvent: WorkInputEvent = {
        objectCur: this.optionItem,
        action: this.statutCall, 
        customer: this.wksBnsToUpdate,
      };
      this.dataOut.emit(workInputEvent);
    }
  }

  doCancel() {
    this.wksBnsForm.reset();

  }
  setValuesToSave() {
   
    this.wksBnsToUpdate =  {
      bsnCountry: this.wksBnsForm.controls.bsnCountry.value,
      stdEntity: this._userService.getUserLogged().entity,
      bsnNature: this.optionItem,
      bsnParent: '',
    };

    if ((this.bsnAccountingCur === undefined) || 
        (this.bsnAccountingCur === null)) {
          this.bsnAccountingCur = JSON.parse(JSON.stringify(this.getBsnAccountingEmpty()));
     }
  } 
  initConfigAddress(organization: string, tax: string) {
    this.configAddress = undefined;
    this.configAddress = {
      langCur: this._translate.getCurrentLang(),
      countryDefault: this._userService.getUserLogged().entitycountry,
      address: false,
      telephone: true,
      email: true,
      contact: false,
      corporate: false,
      identifier: undefined,
      isReadOnly: false,
      choiceCountry: true,
      fieldsId: []
    };
    this.configAddress.isReadOnly = this.isReadOnly;
    this.configAddress.choiceCountry = false;
    if ((organization === 'company') || (organization === 'administration')) {
      this.configAddress.countryDefault = this.wksBnsForm.controls.bsnCountry.value;
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = false;
      this.configAddress.contact = false;
      this.configAddress.corporate = true;
      this.configAddress.email = false;
      this.configAddress.identifier = 'professional';
    } else if (organization === 'person') {
      this.configAddress.countryDefault = this.wksBnsForm.controls.bsnCountry.value;
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = true;
      this.configAddress.contact = true;
      this.configAddress.corporate = false;
      this.configAddress.email = true;
      this.configAddress.identifier = tax;
    } 
  }
  fillForm() {
    this.displayData = true; 
    const countryCur = (this.wksBnsToUpdate && this.wksBnsToUpdate.bsnCountry ? this.wksBnsToUpdate.bsnCountry : this._userService.getUserLogged().entitycountry);

    this.wksBnsForm.controls.bsnCountry.setValue(countryCur);
    this.countrySelected(countryCur); 

    if (this.wksBnsToUpdate.bsnAccounting) {
      this.bsnAccountingCur =  JSON.parse(this.wksBnsToUpdate.bsnAccounting);
      // correct reocrd error
      if (typeof this.bsnAccountingCur === 'string') {
        this.bsnAccountingCur = JSON.parse(this.bsnAccountingCur);
      }
      if (this.bsnAccountingCur.bsnCashpay !== undefined) {
        this.wksBnsForm.controls.bsnCashpay.setValue(this.bsnAccountingCur.bsnCashpay);
      } else {
        this.wksBnsForm.controls.bsnCashpay.setValue(false);
      }
      if (this.bsnAccountingCur.bsnAccount) {
        this.wksBnsForm.controls.bsnAccount.setValue(this.bsnAccountingCur.bsnAccount);
      } else {
        this.wksBnsForm.controls.bsnAccount.setValue(this._wksCentralService.getWksEntityCur().accountCash);
      }
      if (this.bsnAccountingCur.scaleName) {
        this.wksBnsForm.controls.scaleName.setValue(this.bsnAccountingCur.scaleName);
      } else {
        this.wksBnsForm.controls.scaleName.setValue('');
      }
      if (this.bsnAccountingCur.bsnCoef) {
        this.wksBnsForm.controls.bsnCoef.setValue(this.bsnAccountingCur.bsnCoef);
      } else {
        this.wksBnsForm.controls.bsnCoef.setValue(1.0);
      }
      if (this.bsnAccountingCur.bsnDelayDays) {
        this.wksBnsForm.controls.bsnDelayDays.setValue(this.bsnAccountingCur.bsnDelayDays);
      } else {
        this.wksBnsForm.controls.bsnDelayDays.setValue(0);
      }
      if (this.bsnAccountingCur.bsnDefferedPaiement) {
        this.wksBnsForm.controls.bsnDefferedPaiement.setValue(this.bsnAccountingCur.bsnDefferedPaiement);
      } else {
        this.wksBnsForm.controls.bsnDefferedPaiement.setValue(this.wksBnsForm.controls.bsnDefferedPaiement.setValue(this.conditionsList[0].value));
      }
      // customerOutstanding
      if (this.bsnAccountingCur.customerOutstanding) {
        const customerOutstanding = this.bsnAccountingCur.customerOutstanding;
        if (customerOutstanding !== undefined) {
          // const regexNum = new RegExp('[^0-9]', 'g');
          this.customerOutstandingValue = customerOutstanding;
          this.wksBnsForm.controls.customerOutstanding.setValue(this.customerOutstandingValue);
        } else  {
          this.wksBnsForm.controls.customerOutstanding.setValue(0);
          this.customerOutstandingValue = 0;
        }
      } else {
        this.customerOutstandingValue = this.customerOutstandingEntity;
        this.wksBnsForm.controls.customerOutstanding.setValue(this.customerOutstandingEntity);
      }
      if (this.bsnAccountingCur.taxableVat) {
        this.wksBnsForm.controls.taxableVat.setValue(this.bsnAccountingCur.taxableVat);
      }
      if (this.bsnAccountingCur.idVat) {
        this.wksBnsForm.controls.idVat.setValue(this.bsnAccountingCur.idVat);
      }
      if (this.bsnAccountingCur.customsRegistration) {
        this.wksBnsForm.controls.customsRegistration.setValue(this.bsnAccountingCur.customsRegistration);
      }
      if (this.bsnAccountingCur.customsId) {
        this.wksBnsForm.controls.customsId.setValue(this.bsnAccountingCur.customsId);
      }
      if (this.bsnAccountingCur.freeZone) {
        this.wksBnsForm.controls.freeZone.setValue(this.bsnAccountingCur.freeZone);
      }
      if (this.bsnAccountingCur.freeZoneId) {
        this.wksBnsForm.controls.freeZoneId.setValue(this.bsnAccountingCur.freeZoneId);
      }
    } else {    
      this.bsnAccountingCur = JSON.parse(JSON.stringify(this.getBsnAccountingEmpty()));
      this.wksBnsForm.controls.bsnCashpay.setValue(true);
      this.isCashPay = true;
      this.wksBnsForm.controls.bsnAccount.setValue(this._wksCentralService.getWksEntityCur().accountCash);
      this.wksBnsForm.controls.scaleName.setValue('PUBLIC');
      this.wksBnsForm.controls.bsnStructure.setValue('unknow');
      this.wksBnsForm.controls.bsnCoef.setValue(1.0);
      this.wksBnsForm.controls.bsnDelayDays.setValue(0);
      this.wksBnsForm.controls.bsnDefferedPaiement.setValue(this.conditionsList[0].value);
      this.wksBnsForm.controls.taxableVat.setValue(false);
      this.wksBnsForm.controls.idVat.setValue('');
      this.wksBnsForm.controls.customsRegistration.setValue(false);
      this.wksBnsForm.controls.customsId.setValue('');
      this.wksBnsForm.controls.freeZone.setValue(false);
      this.wksBnsForm.controls.freeZoneId.setValue('');
    }
    if (this.bsnAccountingCur.bsnCashpay) {
      this.isCashPay = true;
      this.wksBnsForm.get('bsnAccount').disable();
      this.wksBnsForm.get('bsnDelayDays').disable();
      this.wksBnsForm.get('bsnDefferedPaiement').disable();
    } else {
      if (!this.isReadOnly) {
        this.wksBnsForm.get('bsnAccount').enable();
        this.wksBnsForm.get('bsnDelayDays').enable();
        this.wksBnsForm.get('bsnDefferedPaiement').enable();
      }
    }
    if (this.wksBnsToUpdate.bsnType !== undefined) {
      this.wksBnsForm.controls.bsnStructure.setValue(this.wksBnsToUpdate.bsnType);
    }
    if ((this.wksBnsToUpdate.bsnType === 'company') || (this.wksBnsToUpdate.bsnType === 'administration')) {
      this.isCompany = true;
      this.isAdministration = (this.wksBnsToUpdate.bsnType === 'administration');
      this.isPerson = false;
      this.isProfessionnal = true;
      this.markChange = true;
      this.initConfigAddress(this.wksBnsToUpdate.bsnType, this.wksBnsToUpdate.bsnTax);
      /*
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = false;
      this.configAddress.contact = false;
      this.configAddress.corporate = true;
      this.configAddress.email = false;
      this.configAddress.identifier = 'professional';
    */
      this.addressCur = (this.wksBnsToUpdate.bsnCompany !== undefined ? JSON.parse(this.wksBnsToUpdate.bsnCompany) : '');
      this.wksBnsToUpdate.bsnAddress = this.addressCur;
      this.dataAddressInput = this.addressCur;
    } else if (this.wksBnsToUpdate.bsnType === 'person') {
      this.isCompany = false;
      this.isAdministration = false;
      this.isPerson = true;
      this.isProfessionnal = false;
      if (this.wksBnsToUpdate.bsnTax === 'professional') {
        this.isProfessionnal = true;
      }
      this.markChange = true;
      this.initConfigAddress(this.wksBnsToUpdate.bsnType, this.wksBnsToUpdate.bsnTax);
      /*
      this.configAddress.address = true;
      this.configAddress.localAddress = true;
      this.configAddress.telephone = true;
      this.configAddress.contact = true;
      this.configAddress.corporate = false;
      this.configAddress.email = true;
      this.configAddress.identifier = this.wksBnsToUpdate.bsnTax;
      */
      this.addressCur = (this.wksBnsToUpdate.bsnPerson !== undefined ? JSON.parse(this.wksBnsToUpdate.bsnPerson) : '');
      this.wksBnsToUpdate.bsnAddress = this.addressCur;
      this.dataAddressInput = this.addressCur;
    }
    this.wksBnsForm.controls.bsnTaxsystem.setValue(this.wksBnsToUpdate.bsnTax);
    if (!this.bsnAccountingCur.taxableVat) {
      this.wksBnsForm.controls.idVat.disable();
    } else  {
      this.wksBnsForm.controls.idVat.enable();
    }

    this.displayData = false;
  }
  getBsnAccountingEmpty(): BsnAccountingModel {
    const bsnAccountingTmp: BsnAccountingModel = {
      bsnCashpay: true,
      bsnAccount: '',
      scaleName: '',
      bsnDiscount: 0,
      bsnCoef: 1.0,
      bsnDelayDays: 0,
      bsnDefferedPaiement: '',
      customerOutstanding: 0,
      taxableVat: false,
      idVat: '',
      customsRegistration: false,
      customsId: '',
      freeZone: false,
      freeZoneId: '',
    };
    return bsnAccountingTmp;
  }

  fillModel(): WksBusinessModel {
    if (this.wksBnsToUpdate === undefined)  {
      return undefined;
    }
    if (this.dataAddressInput === undefined)  {
      return undefined;
    }
    const wksBusinessCur: WksBusinessModel = {
      id: (this.wksBnsToUpdate.id) ? this.wksBnsToUpdate.id : undefined,
      stdEntity: this._wksCentralService.getWksEntityCur().stdEntity,
      bsnCountry: this.wksBnsForm.controls.bsnCountry.value,
      bsnNature: this.wksBnsToUpdate.bsnNature,
      bsnParent:  this.wksBnsToUpdate.bsnParent,
      bsnType: this.wksBnsForm.controls.bsnStructure.value,
      bsnTax: this.wksBnsForm.controls.bsnTaxsystem.value,
      bsnAddress: this.dataAddressInput,
      stdCreation: (this.wksBnsToUpdate && this.wksBnsToUpdate.stdCreation) ? this.wksBnsToUpdate.stdCreation : undefined,
    };
    if ((this.wksBnsToUpdate.bsnType === 'company') || (this.wksBnsToUpdate.bsnType === 'administration')) {
      wksBusinessCur.bsnName = this.dataAddressInput.corporateName;
      wksBusinessCur.bsnCompany = this.dataAddressInput;
    } else {
      wksBusinessCur.bsnName = this.dataAddressInput.identity.cntLastname + ' ' + this.dataAddressInput.identity.cntFirstname;
      wksBusinessCur.bsnPerson = this.dataAddressInput;
    }
    wksBusinessCur.bsnZipcode = this.dataAddressInput.zipcode;
    wksBusinessCur.bsnState = this.dataAddressInput.state;
    const cashPay = this.wksBnsForm.controls.bsnCashpay.value;
    let bsnAccounting: BsnAccountingModel ;
    
    if (this.wksBnsForm.controls.customerOutstanding.value !== undefined) {
      const regexNum = new RegExp('[^0-9]', 'g');
      this.customerOutstandingValue = this.wksBnsForm.controls.customerOutstanding.value.replace(regexNum, '');
    }
    bsnAccounting  = this.getBsnAccountingEmpty();
    if (cashPay) {
      bsnAccounting  = {
        bsnCashpay: true,
        bsnAccount: this.wksBnsForm.controls.bsnAccount.value,
        scaleName: this.wksBnsForm.controls.scaleName.value,
        bsnCoef: this.wksBnsForm.controls.bsnCoef.value,
        bsnDiscount: 0,
        bsnDelayDays: undefined,
        bsnDefferedPaiement: undefined,
        customerOutstanding: this.customerOutstandingValue,
        taxableVat: this.bsnAccountingCur.taxableVat,
        idVat: this.wksBnsForm.controls.idVat.value,
        customsRegistration: this.bsnAccountingCur.customsRegistration,
        customsId: this.wksBnsForm.controls.customsId.value, 
        freeZone: this.bsnAccountingCur.freeZone,
        freeZoneId: this.wksBnsForm.controls.freeZoneId.value
      };
        
    } else {
      bsnAccounting  = {
        bsnCashpay: false,
        bsnAccount: this.wksBnsForm.controls.bsnAccount.value,
        scaleName: this.wksBnsForm.controls.scaleName.value,
        bsnCoef: this.wksBnsForm.controls.bsnCoef.value,
        bsnDiscount: 0,
        bsnDelayDays: this.wksBnsForm.controls.bsnDelayDays.value,
        bsnDefferedPaiement: this.wksBnsForm.controls.bsnDefferedPaiement.value,
        customerOutstanding: this.customerOutstandingValue,
        taxableVat: this.bsnAccountingCur.taxableVat,
        idVat: this.wksBnsForm.controls.idVat.value,
        customsRegistration: this.bsnAccountingCur.customsRegistration,
        customsId: this.wksBnsForm.controls.customsId.value, 
        freeZone: this.bsnAccountingCur.freeZone,
        freeZoneId: this.wksBnsForm.controls.freeZoneId.value
      };
    }
    wksBusinessCur.bsnAccounting = bsnAccounting;
    return wksBusinessCur;
  }
}
