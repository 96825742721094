import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp } from 'ag-grid-angular';

import { TranslateService } from '../../../../../../../services/translate.service';

@Component({
  selector: 'mdi-sn-renderer',
  templateUrl: './sn-renderer.component.html',
  styleUrls: ['./sn-renderer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SnRendererComponent implements OnInit, ICellRendererAngularComp {


  params: ICellRendererParams;
  currentValue: any;
  rowsSN: string[];
  allSN: boolean;
  nbRows: number;

  constructor(private _translate: TranslateService) { }

  ngOnInit() {
    this.initBooleans();
  }
  initBooleans(): void {
    this.allSN = false;
  }
  refresh(params: any): boolean {
    return false;
    // throw new Error("Method not implemented.");
  }
  
  agInit(params: ICellRendererParams): void {
    this.allSN = false;
    this.params = params;
    // this.currentValue = params.value;
    this.currentValue = '';
    
    if (this.params.data.taskSN === 'allSN') {
      this.allSN = true;
      this.currentValue = this._translate.getTranslate('allSN');
    } else  {
      this.currentValue = '';
      const snRows = this.params.data.taskSN.split(';');
      this.rowsSN = [];
      this.nbRows = 0;
      for (const rowLabel of snRows) {
        this.nbRows++;
        this.rowsSN.push(rowLabel);
      }
    }
    // console.log(params.colDef.colId+' '+ params.colDef.cellRendererParams.colFmtName);
    // throw new Error("Method not implemented.");
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    // throw new Error("Method not implemented.");
  }
}
