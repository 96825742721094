import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * JLG - 2018-11-29
 * https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable
 * Difference entre observable et BehaviorSubject :
 * le premier ré-exécute le code observé et donc peut renvoyer des valeurs différentes : exempel httpclient
 * le second n'exécute le code observé qu'une seule fois et envoie une valeur unique à tous ses souscripteurs.
 *
 * https://stackoverflow.com/questions/43348463/what-is-the-difference-between-subject-and-behaviorsubject
 * difference entre Subject et BehaviorSubject :
 * BehaviorSubject : renvoie la dernière valeur connue transmise par le dernier next avant la subscription
 * Subject : renvoie la valeur courante émise par le premier next après la subscription. Pas de mémorisation des next précédents.
 *
 * ATTENTION : ces objets doivent être placé dans des objest RxJS subscription au moment de la commande subscribe.
 *
 * Autre recommandation, n'utiliser EventEmitter qu'entre un composant parent et son fils. Pour qu'il n'y ait pas de souscription.
 * Evenement lié à un bouton dans dans le composant fils qui fait un ouput en eventemitter qui sera capturé par le parent
 */
export class GlobalEventsManagerService {
    constructor() {
        this.commonReportEvent = new EventEmitter();
        this.commonReportSubject = new Subject();
        this.synchroEvents = new Subject();
        this.nodeSelectedEvent = new EventEmitter();
        this.calendarEvent = new EventEmitter();
        this.progressBarEvent = new EventEmitter();
    }
}
GlobalEventsManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalEventsManagerService_Factory() { return new GlobalEventsManagerService(); }, token: GlobalEventsManagerService, providedIn: "root" });
