import { Component, OnInit, ViewChild, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { RemoteFilesService } from '../../services/remote-files.service';
import { CommonMethods } from '../../tools/commonMethods';

@Component({
  selector: 'mdi-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewDocComponent implements OnInit, OnDestroy {

  @Input() statutCall: string;
  @Input() titleReport: string;
  @Input() dataList: any[];

  @ViewChild('pdfViewer', {static: false}) pdfViewer: any;

  pdfFiles: any[];
  dataSource: any[];
  dataLoaded: boolean;
  selectedPdf: any;
  pdfDisplay: any;
  dataPdfLoaded: boolean;
  isDocPdf: boolean;
  private readonly onDestroy = new Subject<void>();
  subscription: Subscription;
  
  constructor(private remoteFilesService: RemoteFilesService,
    private dialogRef: MatDialogRef<ViewDocComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      this.initBooleans();
      if (data !== undefined) { 
        this.statutCall = data.statutCall;
        this.isDocPdf =  this.statutCall.toLowerCase().includes('pdf');
        this.initData();
        this.dataList = data.dataList;
        if ((this.dataList.length !== 0) || (this.dataList[0] !== undefined)) {
          this.generatePdfList();
         } else {
           this.dataPdfLoaded = true;
         }
      }
    }
  ngOnInit() {
    
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.dialogRef.close();
  }
  initData() {

  }
  initBooleans() {
    this.dataPdfLoaded = false;
    this.dataLoaded = false;
    this.isDocPdf = false;
  }
  generatePdfList() {
    this.loadListPdf()
    .then (() => {
      if (this.pdfFiles[0] === undefined) { return; }
      this.loadPdfFile(this.pdfFiles[0].fileUrl, this.pdfFiles[0].filePath)
        .then ((res) => {
          this.dataPdfLoaded = true;
          this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
          this.pdfFiles[0].localUrl = res;
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        })
        .then((error: any) => { // error read label
          //  console.log('Read data :'+ error);
        });
    })
  .then((error) => { // error read label
    //  console.log('Read data :'+ error);
    });
  }
  loadListPdf() {
    this.pdfFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of this.dataList ) {
        const fileCur =    {
          fileName: docCur.fileName,
          fileLabel: docCur.fileLabel,
          filePath: docCur.filePath,
          fileStorage: docCur.fileStorage,
          fileUrl: docCur.fileUrl,
          localUrl: undefined
        };
        this.pdfFiles.push(fileCur);
      }
      this.selectedPdf = this.pdfFiles[0];

      if (this.pdfFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  loadPdfFile(_url: string, _path: any) {
    return new Promise((resolve, reject) => {

      this.remoteFilesService.downloadDoc(_url, _path, 'pdf')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          const pdfData = new Blob([response], { type: 'application/pdf', });
          const url = URL.createObjectURL(pdfData);
          this.dataPdfLoaded = true;
          resolve(response);
        }, err => {
          reject(err);
        }
      );
    });
  }
  setPdfCur(_pdfName: any) {

    for (const pdfSelected of this.pdfFiles) {
      if (pdfSelected.fileLabel === _pdfName) {

        if ( pdfSelected.localUrl !== undefined) {
          this.pdfDisplay = pdfSelected.localUrl;
          this.pdfViewer.pdfSrc = pdfSelected.localUrl;
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        } else {
          const url = CommonMethods.cleanURLString(pdfSelected.fileUrl);
          this.loadPdfFile(url, pdfSelected.filePath)
          .then ((res) => {
            this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            pdfSelected.localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
          })
          .then((error) => { // error read label
            //  console.log('Read data :'+ error);
          });
        }
        break;
      }
    }
  }
  afterLoadPdfComplete(event: any) {
    console.log('pdf loaded)');
  }
  closeEvent($event: Event)  {

    this.dialogRef.close('closeEvent');
    return;
  }
}

