import { environment } from '../../../../../../environments/environment';
const ɵ0 = (msg) => {
    console.log(new Date(), msg);
}, ɵ1 = (stompClient) => {
    const token = localStorage.getItem('JWT_TOKEN');
    return new Promise((resolve, _) => {
        stompClient.connectHeaders = {
            Authorization: 'Bearer ' + token,
        };
        resolve();
    });
};
export const localRxStompConfig = {
    // Which server?
    brokerURL: environment.wsBaseUrl + '/stomp/',
    /*
      // Headers
      // Typical keys: login, passcode, host
      connectHeaders: {
        login: '',
        passcode: ''
      },
    */
    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 0,
    heartbeatOutgoing: 0,
    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds)
    reconnectDelay: 200,
    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    debug: ɵ0,
    beforeConnect: ɵ1,
};
export { ɵ0, ɵ1 };
