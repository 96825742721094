<div #refElement *ngIf="isLoaded" class="mat-elevation-z8 container-fluid stdTheme"
    [ngStyle]="{'margin-top': '20px', width: '80%' ,padding: '10px'}" >
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="workRateForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-header>
                        <mat-card-title>
                        <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                        <section class="mat-typography">
                            <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                        </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="container-fluid d-flex justify-content-between" >
                            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'goback' | translate }}
                            </button>
                            <button *ngIf="(!isRegistered && workRateForm.valid)" mat-raised-button  #buttonSubmit type="submit" (click)="registerWorkRate()" >
                                <mat-icon>save</mat-icon>
                                {{ 'save' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                    <mat-card-content class="stdTheme">
                        <div  class="flex-row row">          
                            <div class="col-12">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'workLabel' | translate }}</mat-label>
                                    <input id="workLabel" class="form-control inputTheme" formControlName="workLabel" readonly matInput #input placeholder="{{ 'workLabel' | translate }}"
                                    required >
                                </mat-form-field>
                            </div>    
                        </div>
                        <div class="flex-row row">
                            <div class="col-12" style="margin-bottom: 10px; margin-top: 10px">
                                <div *ngFor= "let langCur of langLabelsList; let rowLang=index; let isLast=last" >
                                    <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                        <tbody class="stdTheme" >
                                            <tr  style="height: 100%">
                                                <td class="stdTheme" style="width: 10%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{langCur.langDisplay}}
                                                </td>
                                                <td class="stdTheme" style="width: 90%; padding: 2px; padding-left: 5px; padding-right: 5px;">

                                                    <input type="text" style="width: 100%" class="inputNormal" #langLabel name="langLabel" value="{{langCur.langLabel}}"
                                                                    (keyup)="setLangLabelValue(langLabel.value,rowLang)" [direction]="langCur.langDirection"
                                                    />
                                                </td>
 
                                            </tr>
                                        </tbody>    
                                    </table>
                                </div>                                      
                            </div>
                        </div>
                        <div class="flex-row row" style="margin-top: -30px; margin-bottom: 20px;">
                            <div class="col-4"></div>
                            <div class="col-4" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'workUnit' | translate }}</mat-label>
                                    <mat-select id="workUnit" placeholder="{{ 'Choose workunit' | translate }}" formControlName="workUnit" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let workUnitCur of billingUnitsList" 
                                            [value]="workUnitCur.value"  >{{workUnitCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                        </div>
                        
                        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                            <fieldset style="width: 100%;">
                                <legend style="width: 40%">{{ 'unitary price per contract' | translate }}</legend>
                                <div class="col-6" style="margin-bottom: 10px;">
                                    <div *ngFor= "let rateCur of rateList; let rowRate=index; let isLast=last" >
                                        <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                            <tbody class="stdTheme" >
                                                <tr  style="height: 100%">
                                                    <td class="stdTheme" style="width: 50%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                        {{rateCur.scaleName}}
                                                    </td>
                                                    <td class="stdTheme" style="width: 20%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                        <!--<input type="number" onchange="setTreeNumberDecimal(scaleRate.value, rowRate)" value="{{rateCur.scaleRate}}" />-->
                                                        <!--{{rateCur.scaleRate}}-->
                                                        <!-- onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,3})?$/.test(this.value)?'inherit':'red'; 
                                                                setScaleRateValue(scaleRate.value,rowRate)"-->
                                                        <input  class="inputNormal" #scaleRate name="scaleRate" value="{{rateCur.scaleRate}}" 
                                                                (keyup)="setScaleRateValue(scaleRate.value,rowRate)"
                                                                onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,3})?$/.test(this.value)?'inherit':'red'"
                                                        />
                                                    </td>
                                                    <td class="stdTheme" style="width: 5%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                        {{currencyCur}}
                                                    </td>
                                                </tr>
                                            </tbody>    
                                        </table>
                                    </div>                                      
                                </div>
                            </fieldset>
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</div>
