import * as i0 from "@angular/core";
export class EnvService {
    constructor() {
        // The values that are defined here are the default values that can
        // be overridden by env.js
        // API url
        this.apiUrl = '';
        // Whether or not to enable debug mode
        this.enableDebug = true;
        this.rigthClickEvent = '';
    }
}
EnvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvService_Factory() { return new EnvService(); }, token: EnvService, providedIn: "root" });
