import { Component, OnInit, Input,  OnChanges, SimpleChanges, AfterContentInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject,Subject } from 'rxjs';

import { catchError, takeUntil } from 'rxjs/operators';

import { RequestInt,  ColLabelList, ColLabelRow, ColAttrRow } from '../../../models/job-data.model';
import { UserService } from '../../../../../services/user.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { SUPPORTED_LANGS, LangSupported } from '../../../../../i18n/translation';

@Component({
  selector: 'mdi-request-datalabel',
  templateUrl: './request-datalabel.component.html',
  styleUrls: ['./request-datalabel.component.css']
})
export class RequestDatalabelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() statutCall: any;
  @Input() requestToUpdate: RequestInt;
  @Input() userLangCur: string;

  private readonly onDestroy = new Subject<void>();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];
  titleRequest: ColLabelList;
  titleRequestLabel: string;
  displayedColumns: any;
  displayedColumns2: any;
  dataLabel: ColLabelRow[];
  dataFmt: ColAttrRow[];
  dataSource: any;
  doValidation = false;
  colNameList: string; 
  colMap = new Map<string,string>();
  supportedLangs: any[];
  userLangLabel: LangSupported;
  userLangId: string;
  dateFmtList: any;
  numFmtList: any;
  constructor(private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    ) { }


  ngOnInit() {
    this.dateFmtList = [];
    const dateFmt =['fmtDate','fmtHour','fmtTimeStamp']
    for (const dataCateg of dateFmt) {
      const dataCat = {
        dataFmt: dataCateg,
        label: this._translate.getTranslate(dataCateg),
      } 
        this.dateFmtList.push(dataCat);
    }
    this.numFmtList = [];
    // const numFmt =['fmtInteger','fmtDecimal','fmtDollar','fmtEuro']
    const numFmt =[
      {label:'fmtInteger', value: 'integer'},
      {label:'fmtDec2', value: 'dec2'},
      {label:'frmtZeroLleftInt5', value: 'zero_left_Int5'},
      {label:'frmtZeroLleftInt6', value: 'zero_left_Int6'},
      {label:'fmtDecCurrency2', value: 'decCurrency2'},
      {label:'fmtIntCurrency', value: 'intCurrency'},
      {label:'fmtPercent', value: 'percent'},
       ]
    for (const dataCateg of numFmt) {
      const dataCat = {
        dataFmt: dataCateg.value,
        label: this._translate.getTranslate(dataCateg.label),
      } 
      this.numFmtList.push(dataCat);
    }
    // this.initData();
  }
  ngOnDestroy() {
    this.onDestroy.next();
}
  ngOnChanges(changes: SimpleChanges) {
   for (let propName in changes) {  
      let change = changes[propName];
      
      let curVal  = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);
      let changeLog = `${propName}: currentValue = ${curVal}, previousValue = ${prevVal}`;
      // console.log(changeLog);
    }
    this.initData();    
  } 
  initData() {
    this.supportedLangs = SUPPORTED_LANGS;
    const dataCategories = this._userService.getUserLogged().dataCategories.split(',');
    this.displayedColumns = ['colName','checkLabel','colHeader','colToolTip'];
    this.displayedColumns2 = ['colName','colType','colFmt'];
    const requestCol = JSON.parse(this.requestToUpdate.requestColumns);
    this.colNameList = 'titleRequest';
    for(const col of requestCol.columns) {
      this.colNameList+='XX'+ col.colName
      this.colMap.set(col.colName, col.colDesc);
    }
    this.userLangId = this.userLangCur;
    for(const langEvent of this.supportedLangs) {
      if(this.userLangCur === langEvent.value) {
        this.userLangLabel = langEvent;
        this.userLangLabel.display = this._translate.instant(langEvent.display);
        break;
      }
    }

    this.getListRequestLabels();
  }
  getListRequestLabels() {
  
    this._jobDataService.getListRequestLabels(this.requestToUpdate.requestRef, this.userLangId, this.colNameList)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.setValuesToUpdate(this.cacheDatatemp);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      }
    );
  }
  onLangChange(event: any) {
    const langEvent: LangSupported = event.value;
    this.userLangId = langEvent.value;
    this.userLangLabel = langEvent;
    this.userLangLabel.display = this._translate.instant(langEvent.display);
    this.getListRequestLabels();
  }

  setValueTitle(_titleValue: string) {
    this.titleRequest.colToolTip = _titleValue;
  }
  setValueArray(dataField: string, dataValue: any,index: number) {
    this.doValidation  =true;
    if( dataField ===  'colHeader') this.dataLabel[index].colHeader  = dataValue;
    if( dataField ===  'colToolTip') this.dataLabel[index].colToolTip  = dataValue;
    if( dataField ===  'checkLabel') {
      this.dataLabel[index].checkLabel = dataValue;
    }
    return dataValue;
  }
  setValuesToUpdate(_dataArray: Array<any>) {

    // const doUpdateRequest = [...Array(_dataArray.length)].map(x=>false);
    // const dataColumnsName = Object.keys(_dataArray[0]);

    const requestCol = JSON.parse(this.requestToUpdate.requestColumns);
    this.dataLabel = [];
    this.dataFmt = [];
    //this.dataLabel = _dataArray;
    for(const col of _dataArray) {
      // console.log(col.colName);
      let checkRequestRef = false;
      if(col.requestRef === 'SHARED_LABEL') checkRequestRef = true;
      if(col.colName === 'titleRequest') {
        this.titleRequest = col;
        this.titleRequestLabel = col.colToolTip;
        continue;
      }
      const dataCur: ColLabelRow = {
        id: col.id,
        colName: col.colName,
        userLang: col.userLang,
        checkLabel: checkRequestRef,
        colHeader: col.colHeader,
        colToolTip: col.colToolTip,
        colAttributes: col.colAttributes,
      }
      this.dataLabel.push(dataCur);
      let colDesc: any;
      let colFmt : ColAttrRow;
      if((col.colAttributes === null) || (col.colAttributes === undefined)) {
        colDesc = this.colMap.get(col.colName);
        colFmt = {
          id: col.id,
          colName: col.colName,
          colType: colDesc.split(';')[1],
          colFmt: null,
        }
      } else {
        colDesc = col.colAttributes.split(';');
        colFmt = {
          id: col.id,
          colName: col.colName,
          colType: colDesc[0],
          colFmt: colDesc[1],
        }
      }
      this.dataFmt.push(colFmt);
    } // end for
    if((this.titleRequest === null) || (this.titleRequest.colToolTip === null)) {
      this.titleRequest.colToolTip = this.requestToUpdate.requestLabel;
      this.titleRequest.requestRef = this.requestToUpdate.requestRef;
      this.titleRequest.userLang = this.userLangId;
      this.titleRequestLabel = this.requestToUpdate.requestLabel;
    }
  }
  register() {

    this._jobDataService.saveRequestLabels(this.prepareValid(), this.requestToUpdate.requestRef, this.userLangId, this.colNameList)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      () => {
        if (this.statutCall) {
          this._jobDataService.onRegisterValid.emit('closeRegister');
          this.doValidation  =false;
        } else {
          this.router.navigate(['/']);
        }
      },
      () => {
        // this.messageTranslate = this._translate.getTranslate('Validation error');
        // this.registrationFailed = true;
      }
    );

  }
  prepareValid() : ColLabelList[] {
    let colLabelInput : ColLabelList[];
    let refRequest: string;
    colLabelInput = [];
    for(const col of this.dataLabel) { 
      let checklabel = col.checkLabel;
      if(checklabel) refRequest = 'SHARED_LABEL';
      else refRequest = this.requestToUpdate.requestRef;
      const frmRow = this.dataFmt.find(frmRow => frmRow.colName == col.colName);
      const colLabel = {
        id: col.id,
        colName: col.colName,
        userLang: col.userLang,
        requestRef: refRequest,
        colHeader: col.colHeader,
        colToolTip: col.colToolTip,
        colAttributes: frmRow.colType+";"+frmRow.colFmt,    
        itemUsage: 'RequestLabel' ,   
      }
      colLabelInput.push(colLabel);
     //  console.log(col.colName);
    }
    // title request
    const colLabel = {
      id: this.titleRequest.id,
      colName: 'titleRequest',
      userLang: this.titleRequest.userLang,
      requestRef: this.requestToUpdate.requestRef,
      colHeader: '',
      colToolTip: this.titleRequest.colToolTip,
      colAttributes: '', 
      itemUsage: 'RequestLabel' ,              
    }
    colLabelInput.push(colLabel);
    return colLabelInput
  }
  doCancel() {

    if (this.statutCall) {
      this._jobDataService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
}
