import { Component, OnInit, Output, Input, ViewEncapsulation, EventEmitter, SimpleChanges, OnChanges, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { UserService } from '../../../../../../services/user.service';
import { WksAnchoragesModel} from '../../../../models/wks-equipment.model';
import { WksContactsModel } from '../../../../models/wks-business.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { CountryLabel, CountryDef, StatesLabel, ContactInt, GoogleMaps, LatLng } from '../../../../../../models/data.model';
import { IhmOptions } from '../../../../models/wks-param.model';
// import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'mdi-anchorages-card',
  templateUrl: './anchorages-card.component.html',
  styleUrls: ['./anchorages-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AnchoragesCardComponent implements OnInit, OnChanges, AfterViewInit {

  /*
  private _statutCall: any;
  @Input() 
  set statutCall(value: any) {
    this._statutCall = value;
  }
  get statutCall(): any {
    return this._statutCall;
  }
*/
  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() anchorageToUpdate: WksAnchoragesModel;
  @Output() dataOut = new EventEmitter<any>();
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;

  private readonly onDestroy = new Subject<void>();

  isUpdateStatut: boolean;

  // actionValue: string;
  isSearch: boolean;
  isReadOnly: boolean;
  isInput: boolean;
  isAddOne: boolean;

  isOkToValid: boolean;
  isState: boolean; 
  isLoaded: boolean;
  isAnchorageWintering: boolean;

  anchorageForm: FormGroup;
  titleCard: string;
  lineCur: number;

  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  countryCur: CountryDef;
  countryKeyToLoad: string;

  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];

  contactsList: WksContactsModel[];

  configContacts: any;
  dataContacts: ContactInt[]; 
  anchorageLocal: string;

  googleMaps: GoogleMaps;
  isGeoLocation: boolean;
  
  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modalTask',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  ihmOptions: IhmOptions;
  anchorageToCache: WksAnchoragesModel;
  constructor(private fb: FormBuilder, 
    private _userService: UserService,
    private router: Router,
    private _ressources: RessourcesService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _dialog: MatDialog,
    private _coordinatesService: CoordinatesService,
    private modalService: BsModalService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    ) { 
    }

  ngOnInit() {
  }
  ngAfterViewInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.initGooglesMaps();
    this.anchorageLocal = '';
    this.isAnchorageWintering = false;
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'anchorageToUpdate': {
            this.anchorageToUpdate = changes['anchorageToUpdate'].currentValue;
            break;
          }

        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    this.initBooleans();
    this.initSubscribes();


    if ((this.actionType === 'add') &&  (this.statutCall === 'direct')) {
      this.isAddOne = true;
    }
    if ( this._translate.currentLang === undefined) {
      return;
    }
    if (this.actionType === 'display') {
      this.isReadOnly = true;
    }
    if ((this.actionType === 'add') || (this.actionType === 'update')) {
      this.isReadOnly = false;
      this.isInput = true;
    }
    if ((this.actionType === 'search') || (this.actionType === 'newsearch')) {
      this.isSearch = true;
    }

    if (this.actionType === 'add') {
      this.titleCard = 'new anchorage';
    } else if (this.actionType === 'update') {
      this.titleCard = 'update anchorage';
      this.isUpdateStatut = true;
    } else if ((this.actionType === 'search') || (this.actionType === 'newsearch')) {
      this.titleCard = 'searching anchorage';
    } else {
      this.titleCard = 'anchorage';
    }
    this.ihmOptions = {
      optionName: '',
      display: true,
      readOnly: this.isReadOnly,
    };
    this.buildForm();
    this.configContacts = {
      langCur: this._translate.getCurrentLang(),
      countryDefault: this._userService.getUserLogged().entitycountry,
      address: false,
      telephone: true,
      email: true,
      contact: true,
      professionnal: true,
      /*identity: 'pro',
      fieldsId: ['proID']*/
      identity: undefined,
      fieldsId: []
    };


    if (this.actionType === 'nothing') {
      return;
    }

    // this.setValuesToUpdate();
    this.loadCountries()
    .then ( () => {
      if (this.anchorageToUpdate !== undefined) {
        this.loadContacts()
        .then ( returnData => {
          this.fillForm();
          this.isLoaded = true;
        })            
        .catch(error  => {
          // console.log(error);
          this.fillForm();
          this.isLoaded = true;
        });
      } else {
        this.fillForm();
        this.isLoaded = true;
      }
    });
  }
  initBooleans(): void {
    this.isLoaded = false;
    this.isAddOne = false;
    this.isReadOnly = true;
    this.isSearch = false;
    this.isInput = false;
    this.isUpdateStatut = false;
    this.isState = false;
    this.isOkToValid = false;

  }
  initSubscribes(): void {
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // closeMapModalLatLng__
      let eventString: string;
        const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString.startsWith('closeMapModalLatLng__')) {
          this.closeModal();
          const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;

          this.displayLatLng(latLng);
          // this.loadDocs();
          // console.log(typeEvent);
        }
      }
    });
    
  }
  buildForm(): void {
    this.anchorageForm =  new FormGroup ({
      anchorageCountry: this.fb.control({value: '', disabled: this.isReadOnly}),
      anchorageState:  this.fb.control({value: '', disabled: this.isReadOnly}),
      anchorageCity:  this.fb.control({value: '', disabled: this.isReadOnly}),
      anchorageName:  this.fb.control({value: '', disabled: this.isReadOnly}),
      anchorageWintering: this.fb.control({value: '', disabled: this.isReadOnly}),
      anchoragePosition: this.fb.control({value: '', disabled: this.isReadOnly}),
      anchoragePositionCoord:  this.fb.control({value: '', disabled: true}),

    },
    {
      updateOn: 'blur'
    });
  }
  onKeyCountry(value: any) {
    // console.log(value);
    this.countriesFilteredList = this.searchCountry(value);
  }
  searchCountry(value: string) { 
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  onChangeCountry($event: any) {
    this.isState = false;
    this.statesCacheList = [];
    this.statesFilteredList = [];
    // console.log('onChangeCountry : ' + $event);
    this.countryCur = this._ressources.getCountry($event);
    if (this.countryCur.address.statesList !== undefined) {
      this.buildStatesList(this.countryCur.address.statesList);
      this.isState = true;
    }
  }
  loadCountries() {
    return new Promise<void>((resolve, reject) => {
      this.statesCacheList = [];
      this.statesFilteredList = [];
      const countriesListTmp = this._ressources.getCountriesList();

      this.countriesCacheList = [];
      for (const countryTmp of countriesListTmp) {
        const labelLang = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
        this.countriesCacheList.push({
          isoAlpha2: countryTmp.isoAlpha2,
          commonName: labelLang,
        });
      }

      this.countriesCacheList.sort((obj1, obj2) => {
        return obj1.commonName > obj2.commonName ? 1 : -1;
      });
      this.countriesCacheList.splice(0, 0, {
        isoAlpha2: '',
        commonName: '',
      });
      this.countriesFilteredList = [...this.countriesCacheList];
      resolve();
    });
  }
  onChangeState($event: any) {
    console.log('onChangeState : ' + $event);
  }
  buildStatesList(stateList: any) {
    this.statesCacheList = [];
    for (const stateTmp of stateList) {
      this.statesCacheList.push({
        stateName: stateTmp.stateName,
        stateAbb: stateTmp.stateAbb,
      });
    }

    this.statesCacheList.sort((obj1, obj2) => {
      return obj1.stateName > obj2.stateName ? 1 : -1;
    });

    this.statesFilteredList = [...this.statesCacheList];
  }
  setFilterState(selectInput: string) {
    // console.log('selectInput : ' + selectInput);

    this.statesFilteredList = this.statesCacheList
      .filter(stateLoc => {
        const stateLoc1 = Object.values(stateLoc)[1];
        if (stateLoc1 !== undefined) {
          return stateLoc1.toLowerCase().includes(selectInput.toLowerCase());
        }
      });
  }
  onChangeField(_field: string, value: any) {
    console.log(_field);
    switch (_field) {
      case 'anchorageWintering': {
        this.isAnchorageWintering = value;
        this.anchorageForm.controls.anchorageWintering.setValue(this.isAnchorageWintering);
        break;
      }
      default: {
        break;
      }
    }
  }
  loadContacts() {
    this.contactsList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getContactsList(this._userService.getUserLogged().entity, 'wks-anchorages', this.anchorageToUpdate.id )
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
        data => {
          this.contactsList = data.body as WksContactsModel[];
          this.dataContacts = [];
          if (this.contactsList.length > 0) {
            for (const cntCur of this.contactsList) {
              this.dataContacts.push({
                id: cntCur.id,
                selectDept: cntCur.cntSpeciality,
                fonctionDept: cntCur.cntFunction,
                selectApps: cntCur.cntChannels,
                emails: JSON.parse(cntCur.cntMails),
                phones: JSON.parse(cntCur.cntPhones),
                identity: {
                  cntCivility: cntCur.cntCivility,
                  cntFirstname: cntCur.cntFirstname,
                  cntLastname: cntCur.cntLastname,
                },
                statut: 'existant',
              });
            }
          }
          resolve(this.contactsList) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  onChangePosition(_value: string) {
    this.isGeoLocation = false;
    const tmp = _value.split(',');
    const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
    if ((tmp === null) || (tmp.length < 2)) {
      this.anchorageForm.controls.anchoragePositionCoord.setValue(wrongPosition);
      return;
    }
    const latLng = this.getLatLng(tmp);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.anchorageForm.controls.anchoragePositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.anchorageForm.controls.anchoragePositionCoord.setValue(wrongPosition);
    }
    const latCur = Number.parseFloat(tmp[0]);
    const lngCur = Number.parseFloat(tmp[1]);
    const myLatLng1 = { lat: latCur, lng: lngCur };
    // const myLatLng2 = { lat: 51.24132136413212, lng: 4.4073388696813875 };

    this.googleMaps = {
      isModal: false,
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'displayLatLng',
      style: undefined,
      position: myLatLng1,
      mapOptions:  {
        center: myLatLng1,
        zoom : 14
     },
     markers:  [],
    };
   
    // this.googleMaps.markers = [];
    this.googleMaps.markers.push({ 
        position: myLatLng1,
        title: this.anchorageForm.controls.anchorageName.value}
    );
   
    this.isGeoLocation = true;
  }
  displayLatLng(_coord: LatLng) {
    const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = _coord.lat.toString();
    values[1] = _coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.anchorageForm.controls.anchoragePosition.setValue(values[0] + ',' + values[1]);
      this.anchorageForm.controls.anchoragePositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.anchorageForm.controls.anchoragePositionCoord.setValue(wrongPosition);
    }
  }
  displayMap() {
    if (this.isReadOnly) {
      return;
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: undefined,
      mapOptions:  {
        center: undefined,
        zoom : 1
     },
     markers:  [],
    };
    this.openModalMap();
  }
  getLatLng(_values: string[]): number[] {
    const latLng = [];
    const lat = Number.parseFloat(_values[0]);
    const lng = Number.parseFloat(_values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this._coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this._coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }

  registerAnchorage(_orig: string) {
    if (_orig !== 'card') {
      return;
    }
    
    const currentAnchorage = this.fillModel();
    this._wksCentralService.saveWksAnchorage(currentAnchorage, this.statutCall)
    .subscribe(
      wksAnchorageReturn => {
        this.anchorageToUpdate = wksAnchorageReturn.body;
        this.anchorageForm.reset();
        this.dataContacts = [];
        if (this.lineCur) {
          this.anchorageToUpdate.lineCur = this.lineCur;
        }

        if (!this.isSearch ) {
          this.isOkToValid = false;
          this.dataOut.emit(this.anchorageToUpdate);
          this.isReadOnly = true;
          this.anchorageForm.reset();
          if (this.statutCall === 'direct') {
            this.router.navigate(['/jobHome']);
            return;
          }
          this._wksCentralService.loadAnchorages()
          .then ((anchorageList: any) => {
            
          }) // end loadAnchorages
          .catch((error: any) => {
            console.log('reloadAnchorages : ' + error);
            return;
          }); // end loadAnchorages   
          this._wksCentralService.onRegisterValid.emit('closeRegisterAnchorage');
          return;
        } 
        
      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  fillModel(): WksAnchoragesModel {
    
    this.contactsList = undefined;

    if (this.dataContacts) {
      this.contactsList = [];
      for (const cntCur of this.dataContacts) {
        const localCnt: WksContactsModel = {
          id: cntCur.id,
          stdEntity: this._userService.getUserLogged().entity,
          cntAppli: 'wks-anchorages',
          cntAppliLnk: (this.anchorageToUpdate ? this.anchorageToUpdate.id : undefined),
          cntSpeciality: cntCur.selectDept,
          cntFunction: cntCur.fonctionDept,
          cntChannels: cntCur.selectApps,
          cntMails: JSON.stringify(cntCur.emails),
          cntPhones: JSON.stringify(cntCur.phones),
          cntCivility: cntCur.identity.cntCivility,
          cntFirstname: cntCur.identity.cntFirstname,
          cntLastname: cntCur.identity.cntLastname,
          cntStatut: cntCur.statut,
        };
        this.contactsList.push(localCnt);
      }
    }
    const wksAnchorageCard: WksAnchoragesModel = {
      id: (this.anchorageToUpdate === undefined ? undefined : 
        this.anchorageToUpdate.id ? this.anchorageToUpdate.id : undefined),
      anchorageCountry: this.anchorageForm.controls.anchorageCountry.value,
      anchorageState: this.anchorageForm.controls.anchorageState.value,
      anchorageCity: this.anchorageForm.controls.anchorageCity.value.toUpperCase(),
      anchorageName: this.anchorageForm.controls.anchorageName.value,
      anchorageWintering: this.isAnchorageWintering,
      anchoragePosition: this.anchorageForm.controls.anchoragePosition.value,
      contacts: this.contactsList,
    };

    return wksAnchorageCard;
  }

  fillForm() {
    this.isGeoLocation = false;
    if (this.anchorageToUpdate === undefined) {
      return;
    }
    this.anchorageLocal = this.anchorageToUpdate.anchorageName + ' - ' + this.anchorageToUpdate.anchorageCity; 
    if (this.anchorageToUpdate.anchorageState) {
      this.anchorageLocal += '(' + this.anchorageToUpdate.anchorageState + ')';
    }
    this.anchorageLocal += ' - ' + this.anchorageToUpdate.anchorageCountryLabel;

    this.lineCur = this.anchorageToUpdate.lineCur;
    this.anchorageForm.controls.anchorageCountry.setValue(this.anchorageToUpdate.anchorageCountry);
   //  this.anchorageForm.controls.equipType.setValue(this.anchorageToUpdate.equipType);
    this.anchorageForm.controls.anchorageState.setValue(this.anchorageToUpdate.anchorageState);
    this.anchorageForm.controls.anchorageCity.setValue(this.anchorageToUpdate.anchorageCity);
    this.anchorageForm.controls.anchorageName.setValue(this.anchorageToUpdate.anchorageName);
    if ((this.anchorageToUpdate.anchoragePosition !== undefined) 
          && (this.anchorageToUpdate.anchoragePosition !== null)) { 
        this.anchorageForm.controls.anchoragePosition.setValue(this.anchorageToUpdate.anchoragePosition);
        this.onChangePosition(this.anchorageToUpdate.anchoragePosition);
    }
    this.isAnchorageWintering  = false;
    this.isAnchorageWintering = this.anchorageToUpdate.anchorageWintering;
    this.anchorageForm.controls.anchorageWintering.setValue(this.isAnchorageWintering);
    if (this.isInput) {
      this.anchorageForm.valueChanges.subscribe(x => {
        this.isOkToValid = false;
        if (JSON.stringify(this.anchorageToCache) !== JSON.stringify(this.fillModel())) {
          if (this.anchorageForm.status === 'VALID') {
            this.isOkToValid = true;
          }
        }
      });
    }
    this.anchorageToCache = JSON.parse(JSON.stringify(this.anchorageToUpdate));
  }
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording anchorage';
      
    } else {

      titleBox = 'searching anchorages';
      messageBox = 'no anchorage found';

    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doCancel() {
    this.isReadOnly = true;
    this.isGeoLocation = false;
    this.anchorageForm.reset();
    this.dataContacts = [];
    if (this.statutCall === 'direct') {
      this.router.navigate(['/jobHome']);
      return;
    }
    this._wksCentralService.onRegisterValid.emit('cancelRegisterAnchorage');
  }
  dataOutContacts(event: any) {
    // this.dataContacts = event;
    // this.dataContacts = JSON.parse(JSON.stringify(event));
    // ContactInt
    // console.log(event);
    this.dataContacts = event;
  }

  // Maps
  openModalMap() {

    const configModalMap = {
      class: 'modal-dialog-centered modalQuote handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }

  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
}
