import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit {


  @Input() widgetData: Widget;
  @Input() displayTime: boolean;
  private readonly onDestroy = new Subject<void>();

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  // public radarChartLabels: Label[] = ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'];
  public radarChartLabels: Label[];
  /*public radarChartData: ChartDataSets[] = [
    { data: [65, 59, 90, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 96, 27, 100], label: 'Series B' }
  ];*/
  public radarChartData: ChartDataSets[];
  // public radarChartType: ChartType = 'radar';
  public radarChartType: ChartType ;
  public radarChartColors: any[];
  updateFrequency: number;

  chartData: ChartDataSets[];
  dataIsLoaded : boolean = false;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
    console.log('widgetData radarChart : ' + this.widgetData);
    this.updateFrequency = this.widgetData.updateFrequency;
    this.displayDateTime = this.displayTime;

    this._jobDataService.displayGenerateTime
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if ((event === 'displayTime') || (event === 'hideTime')) {
          this.toogleTime(event);
        }
    });
    this.buildRequest();
    this.radarChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction,'unixM',localFmt);
      const jsonData = JSON.parse(resultChart.json_data);
      this.radarChartLabels = jsonData.labelCols as Label[];
      this.radarChartData = jsonData.datasets as ChartDataSets[];
      const colorAlphas = CommonMethods.genAlphaHslaColors(-1,70,[1,100,80],this.radarChartData.length);
      this.radarChartColors = [];
      let colorList = [];
      let idx = 0;
      for(const serieCur of this.radarChartData) {
        const colorCur = {
          backgroundColor: 'transparent',
          borderColor: colorAlphas[idx][1],
          pointBackgroundColor: colorAlphas[idx][1],
          pointBorderColor: '#fff',
  
        }
        this.radarChartColors.push(colorCur);
        idx++;
      }


      this.dataIsLoaded = true;
    })
    .then((error) => {

    });
  }
  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if(restrict.columnName) {
        if(requestRestrictions != '') {
          requestRestrictions+=';';
        }
        requestRestrictions+= restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if(resultChart.length === 0) {
            reject();
          }
          else resolve(resultChart[0]);
        }, err => {
          
          reject(err);
        }
      );
    });
  }
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  
}
