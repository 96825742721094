<div class="container-fluid" >
    <div fxFlex style="font-size: small;padding-top: 0.5%;">
        <mdi-topbar [isHome]="false"></mdi-topbar>
    </div>
</div>

<div #refElement class="mat-elevation-z8 container-fluid stdTheme" style="overflow: hidden!important"
        [ngStyle]="{'margin-top': '90px', width: '70%' ,padding: '0px', 'margin-left': '50px'}" >
    <div class="flex-row row stdTheme justify-content-center" style="margin: 0px">
        <h4 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h4>
    </div>
    <div class="flex-row row stdTheme justify-content-center" style="margin: 0px; margin-left: 10px; margin-right: 10px">
        <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;width: 100%"
                        (selectedIndexChange)="onTabChanged($event)">
            <mat-tab label="{{ 'description' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                        <div *ngIf="tabSelected === 0">
                            <div class="flex-row row stdTheme" style="margin: 0px">
                                <div class="container-fluid d-flex justify-content-between" >
                                    <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{ 'goback' | translate }}
                                    </button>
                                    <button *ngIf="(!isRegistered && ( 
                                        locationForm !== undefined && locationForm.valid) && 
                                        generalSettingsForm !== undefined && generalSettingsForm.valid && 
                                        isOkToSave)" mat-raised-button  #buttonSubmit type="submit" (click)="registerEntity()" >
                                        <mat-icon>save</mat-icon>
                                        {{ 'save' | translate }}
                                    </button>
                                </div>
                            </div>
                            <div class="flex-row row stdTheme" style="margin: 0px">
                                <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab label="{{ 'subsidiaries' | translate }}">
                <mat-tab-body >
                    <ng-template matTabContent >
                        <div *ngIf="tabSelected === 1">
                            <ng-container *ngTemplateOutlet="subsidiariesTemplate"></ng-container>
                        </div>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab label="{{ 'models' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                        <div *ngIf="tabSelected === 2">
                            <ng-container *ngTemplateOutlet="modelsTemplate"></ng-container>
                        </div>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab label="{{ 'sales price calculation' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                        <div *ngIf="tabSelected === 3">
                            <div class="flex-row row stdTheme" style="margin: 0px">
                                <ng-container *ngTemplateOutlet="calculationsTemplate"></ng-container>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab label="{{ 'API' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                        <div *ngIf="tabSelected === 4">
                            <div class="flex-row row stdTheme" style="margin: 0px">
                                <ng-container *ngTemplateOutlet="apiTemplate"></ng-container>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<ng-template #descriptionTemplate>
    <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;width: 100%">
        <mat-tab label="{{ 'location' | translate }}" >
            <ng-template matTabContent >
                <ng-container *ngTemplateOutlet="locationTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'generalSettings' | translate }}" >
            <mat-tab-body >
                <ng-template matTabContent >
                    <ng-container *ngTemplateOutlet="generalSettingsTemplate"></ng-container>
                </ng-template>
            </mat-tab-body>
        </mat-tab>
        <mat-tab label="{{ 'SMTP' | translate }}" >
            <mat-tab-body >
                <ng-template matTabContent >
                    <ng-container *ngTemplateOutlet="smtpTemplate"></ng-container>
                </ng-template>
            </mat-tab-body>
        </mat-tab>
    </mat-tab-group>
</ng-template>
<ng-template #subsidiariesTemplate>
    <mdi-subsidiaries [entityParent]="entityToUpdate"  [config]="configAddress">

    </mdi-subsidiaries>
</ng-template>
<ng-template #locationTemplate>
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="locationForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-content class="stdTheme">
                        <div  class="flex-row row">          
                            <div class="col-2" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'subsidiaryOf' | translate }}</mat-label>
                                    <mat-select id="subsidiaryOf" #subsidiaryOf  formControlName="subsidiaryOf" 
                                            panelClass="mdiselectcontent" ngDefaultControl
                                            (selectionChange)="onChangeSubsidiary(subsidiaryOf.value)" >
                                        <mat-option class="selectOptionsTheme" *ngFor="let entityCur of entitiesList" 
                                            [value]="entityCur.value"  >{{entityCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'internationalFormat' | translate }}</mat-label>
                                    <mat-select id="internationalFormat" placeholder="{{ 'Choose format' | translate }}" formControlName="internationalFormat" 
                                                panelClass="mdiselectcontent" #internationalFormat
                                                (ngModelChange)="onChangeFmt($event)"
                                                ngDefaultControl >
                                        
                                        <mat-option class="selectOptionsTheme" *ngFor="let fmtCur of supportedFmts" 
                                            [value]="fmtCur.value"  >{{fmtCur.label  | translate}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'language' | translate }}</mat-label>
                                    <mat-select id="language" placeholder="{{ 'Choose language' | translate }}" formControlName="language" 
                                                panelClass="mdiselectcontent" #internationalFormat
                                                (ngModelChange)="onChangeLanguage($event)"
                                                ngDefaultControl >
                                        
                                                <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" 
                                                            [value]="lang.value"  >{{ lang.display | translate }}</mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>

                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'usersLanguages' | translate }}</mat-label>
                                    <mat-select id="usersLanguages" placeholder="{{ 'Choose language' | translate }}" formControlName="usersLanguages" 
                                                panelClass="mdiselectcontent" #usersLanguages
                                                multiple
                                                ngDefaultControl >
                                                <mat-select-header>
                                                    <div class="flex-row button-row text-center" >
                                                    <button mat-raised-button class="buttonTheme"  (click)="usersLanguages.toggle()">
                                                        {{'Close' | translate}}
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                    </div>
                                                </mat-select-header>
                                                <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" 
                                                            [value]="lang.value"  >{{ lang.display | translate }}</mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'entityTz' | translate }}</mat-label>
                                    <mat-select id="entityTz"  formControlName="entityTz" 
                                                panelClass="mdiselectcontent" #internationalFormat
                                                (ngModelChange)="onChangeTz($event)"
                                                ngDefaultControl >
                                        
                                        <mat-option class="selectOptionsTheme" *ngFor="let tzCur of entityTzList" 
                                            [value]="tzCur"  >{{tzCur}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                        </div>
                        <div  class="flex-row row"> 
                            <div class="col-2" style="padding-top: 25px">
                                <a><h6>{{ 'entityPosition' | translate }}</h6></a>
                            </div>
                            <div class="col-4"  >
                                
                                <mat-form-field class="mdiinput form-group" appearance="standard" style="text-align: center;">
                                    <button  matPrefix type="button" class="btn btn-default" mat-icon-button (click)="displayMap()" >
                                        <mat-icon style="margin-top: -10px;margin-bottom: 0px;" >my_location</mat-icon>
                                    </button>
                                    <input id="entityPosition" class="form-control inputTheme" formControlName="entityPosition" 
                                                    matInput #entityPosition  readonly  >
                                </mat-form-field>
                            </div>
                            <div class="col-3" >
                                <mat-form-field class="mdiinput form-group" appearance="standard" style="text-align: center;">
                                    <input id="entityPositionCoord" class="form-control inputTheme" formControlName="entityPositionCoord" 
                                                    matInput #entityPositionCoord  readonly  >
                                </mat-form-field>
                            </div>
                        </div>
                        
                        
                        <div class="flex-row row">
                            <div class="col-12">
                                <div  class="flex-row row wkscard">
                                    <hr class="lineCard"/>
                                    <!--<div class="col-11 lineCard" ></div>-->
                                </div>
                    
                                <div *ngIf="isformOK" style="margin-bottom: 20px;">
                                    <mdi-address-block 
                                    [config]="configAddress" 
                                    [dataIn]="addressCur" 
                                    [markChange]="markChange" 
                                    (dataOut)="dataOutAddress($event)"></mdi-address-block>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #generalSettingsTemplate>
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="generalSettingsForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-content class="stdTheme">
                        <div  class="flex-row row">          
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'accountCash' | translate }}</mat-label>
                                    <input id="accountCash" class="form-control inputTheme" formControlName="accountCash" matInput #input placeholder="{{ 'accountCash' | translate }}"
                                    required >
                                </mat-form-field>
                            </div>    
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'accountFmt' | translate }}</mat-label>
                                    <input id="accountFmt" class="form-control inputTheme" formControlName="accountFmt" matInput #input placeholder="{{ 'accountFmt' | translate }}"
                                        >
                                </mat-form-field>
                            </div>  
                            <div class="col-2">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'entityDataPurge' | translate }}</mat-label>
                                    <input id="entityDataPurge" class="form-control inputTheme" formControlName="entityDataPurge" matInput #input placeholder="{{ 'entityDataPurge' | translate }}"
                                    maxlength="3" type="number" required >
                                </mat-form-field>
                            </div>  
                            <div  class="col-3" style="padding-right: 0px; padding-left: 0px">
                                <mat-form-field appearance="none" style="width: 100%;">
                                    <mat-label >{{ 'Select apps' | translate }}</mat-label>       
                                    <mat-select #notificationsAppFg class="stdTheme stdSelect"  panelClass="panelOptions" #listApps  formControlName="notificationsAppFg" 
                                                ngDefaultControl multiple required
                                                (selectionChange)="notificationsAppChange(notificationsAppFg.value)">
                                        <mat-option class="selectOptionsTheme" *ngFor="let app of notificationsAppList"  [value]="app">
                                                {{ app }}
                                        </mat-option>
                                        <div class="flex-row button-row text-center" style="margin-bottom: 5px;">
                                            <button mat-raised-button class="buttonTheme"  (click)="notificationsAppFg.toggle()">
                                              {{'Close' | translate}}
                                              <mat-icon>cancel</mat-icon>
                                            </button>    
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="flex-row row">
                            <div class="col-1">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'entityCurrency' | translate }}</mat-label>
                                    <input id="entityCurrency" class="form-control inputTheme" formControlName="entityCurrency" matInput #entityCurrency placeholder="{{ 'entityCurrency' | translate }}"
                                            maxlength="3" required oninput="this.value = this.value.toUpperCase()"  type="text">
                                </mat-form-field>
                            </div>  
                            <div class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'entityScales' | translate }}</mat-label>
                                    <textarea id="entityScales" class="form-control inputTheme textarea1" formControlName="entityScales" 
                                        matInput required #entityScales placeholder="{{ 'entityScales' | translate }}" 
                                        oninput="this.value = this.value.toUpperCase()"
                                        cdkTextareaAutosize
                                        #entityScales="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="4"></textarea>
                                </mat-form-field>
                            </div> 
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'activity' | translate }}</mat-label>
                                    <mat-select id="activity"  formControlName="activity" 
                                                panelClass="mdiselectcontent" #internationalFormat
                                                (ngModelChange)="onChangeActivity($event)"
                                                ngDefaultControl >
                                        
                                        <mat-option class="selectOptionsTheme" *ngFor="let activityCur of activitiesList" 
                                            [value]="activityCur.value"  >{{activityCur.label}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <div class="checkbox" 
                                    style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;margin-top: 30px;margin-left: 0px;padding-left: 10px;padding-right: 10px;">
                                    <label class="labelLine" for="quotationActivation" style="margin-left: 0px; margin-right: 15px" >{{ 'quotationActivation' | translate }}</label>
                                    <input type="checkbox" id="quotationActivation"  #quotationActivation class="inputTheme" name="quotationActivation"
                                        (click)="onChangeField('quotationActivation', quotationActivation.checked)" formControlName="quotationActivation" 
                                        [checked]="isQuotationActive" 
                                        />
                                </div>
                            </div>
                        </div>
                        <div class="flex-row row">
                            <div class="col-3">
                                <div class="checkbox" 
                                    style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;margin-top: 30px;margin-left: 0px;padding-left: 10px;padding-right: 10px;">
                                    <label class="labelLine" for="invoicingActivation" style="margin-left: 0px; margin-right: 15px" >{{ 'invoicingActivation' | translate }}</label>
                                    <input type="checkbox" id="invoicingActivation"  #invoicingActivation class="inputTheme" name="invoicingActivation" style="padding-top: 0px;"
                                        (click)="onChangeField('invoicingActivation', invoicingActivation.checked)" formControlName="invoicingActivation" 
                                        [checked]="isInvoicingActive" 
                                        />
                                </div>
                            </div>
                            <div class="col-3" *ngIf="isInvoicingActive">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'invoicingNumber' | translate }}</mat-label>
                                    <input id="invoicingNumber" class="form-control inputTheme" formControlName="invoicingNumber" matInput 
                                            #invoicingNumber placeholder="{{ 'invoicingNumber' | translate }}"
                                            [required]="isInvoicingActive" (keyup)="doVerifNumber(invoicingNumber)" >
                                </mat-form-field>
                            </div>
                            <div class="col-3" *ngIf="isInvoicingActive || isQuotationActive">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taxProfile' | translate }}</mat-label>
                                    <mat-select id="taxProfile"  formControlName="taxProfile" 
                                                panelClass="mdiselectcontent" #internationalFormat
                                                (ngModelChange)="onChangeTaxProfile($event)"
                                                ngDefaultControl 
                                                [required]="isInvoicingActive || isQuotationActive" >
                                        <mat-option class="selectOptionsTheme" *ngFor="let taxProfileCur of taxProfilesList" 
                                            [value]="taxProfileCur.profileName"  >{{taxProfileCur.profileName | translate}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <!--
                            <div class="col-2 d-flex justify-content-end" *ngIf="isInvoicingActive || isQuotationActive" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'customerOutstandingDefault' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="customerOutstanding" #customerOutstanding formControlName="customerOutstanding"  style="text-align: right" 
                                            value="{{customerOutstandingValue | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                            (change)="onChangeCustomerOutstanding(customerOutstanding.value)"
                                            matInput (keypress)="numberAndDotOnly($event)" 
                                            placeholder="{{ 'customerOutstandingDefault' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                            -->
                            <div class="col-2 d-flex justify-content-end" *ngIf="isInvoicingActive || isQuotationActive" >
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'customerOutstandingDefault' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="customerOutstanding" #customerOutstanding formControlName="customerOutstanding"  style="text-align: right" 
                                            value="{{customerOutstandingValue | formatNumber : ['currency',numberFormat.locale,numberFormat.currency,'symbol']}}"
                                            (change)="onChangeCustomerOutstanding(customerOutstanding.value)"
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'customerOutstandingDefault' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex-row row" >
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'maxBdd' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="maxBdd" #maxBdd formControlName="maxBdd"  style="text-align: right" 
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'maxBdd' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'maxWebdav' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="maxWebdav" #maxWebdav formControlName="maxWebdav"  style="text-align: right" 
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'maxWebdav' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'maxSms' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="maxSms" #maxSms formControlName="maxSms"  style="text-align: right" 
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'maxSms' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'maxNotifs' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="maxNotifs" #maxNotifs formControlName="maxNotifs"  style="text-align: right" 
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'maxNotifs' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex-row row" style="margin-bottom: 20px">
                            <div class="col-3">
                                <div class="checkbox" 
                                    style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;margin-top: 30px;margin-left: 0px;padding-left: 10px;padding-right: 10px;">
                                    <label class="labelLine" for="paginationActivation" style="margin-left: 0px; margin-right: 15px" >{{ 'paginationActivation' | translate }}</label>
                                    <input type="checkbox" id="paginationActivation"  #paginationActivation class="inputTheme" name="paginationActivation" style="padding-top: 0px;"
                                        (click)="onChangeField('paginationActivation', paginationActivation.checked)" formControlName="paginationActivation" 
                                        [checked]="isPaginationActive" 
                                        />
                                </div>
                            </div>
                            <!-- isPaginationActive -->
                            <div class="col-2" *ngIf="isPaginationActive"> 
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paginationLabel' | translate }}</mat-label>
                                    <input id="paginationLabel" class="form-control inputTheme" formControlName="paginationLabel" matInput 
                                            #paginationLabel  required placeholder="{{ 'paginationLabel' | translate }}"
                                        >
                                </mat-form-field>
                            </div>  
                            <div class="col-3" *ngIf="isPaginationActive"> 
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paginationFormat' | translate }}</mat-label>
                                    <!--<input id="paginationFormat" class="form-control inputTheme" formControlName="paginationFormat" matInput #paginationFormat placeholder="{{ 'accountFmt' | translate }}"
                                        >-->
                                    <mat-select id="paginationFormat"  formControlName="paginationFormat" 
                                        panelClass="mdiselectcontent" #paginationFormat
                                        (ngModelChange)="onChangeField('paginationFormat', $event)"
                                        ngDefaultControl 
                                        required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let paginationFormatCur of paginationFormats" 
                                            [value]="paginationFormatCur.value"  >{{paginationFormatCur.label | translate}}
                                        </mat-option>
                                    </mat-select> 
                                </mat-form-field>
                            </div>  
                            <div class="col-2" *ngIf="isPaginationActive"> 
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paginationPosH' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="paginationPosH" #paginationPosH formControlName="paginationPosH"  
                                            style="text-align: right" required 
                                            matInput (keypress)="numberAndDotOnly($event)" 
                                            placeholder="{{ 'paginationPosH' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf="isPaginationActive"> 
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'paginationPosV' | translate }}</mat-label>
                                    <input class="form-control inputTheme" name="paginationPosV" #paginationPosV formControlName="paginationPosV"  
                                            style="text-align: right" required 
                                            matInput (keypress)="numberAndDotOnly($event)"
                                            placeholder="{{ 'paginationPosV' | translate }}"
                                        />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #smtpTemplate>
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="smtpForm"  >
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;overflow: hidden!important">
                        <div class="flex-row row " style="margin-left: 0px;margin-right: 0px;overflow: hidden!important">
                            <div class="col-10 d-flex justify-content-center">
                                
                            </div>

                            <div class="col-2 d-flex justify-content-end">
                                <button  mat-raised-button  #buttonSubmit [disabled]="smtpForm.status === 'INVALID' || !isFillData" (click)="testSmtp()">
                                    {{ 'smtpTest' | translate }}
                                </button>
                            </div>
                        </div>
                    </mat-card-actions>
                    <mat-card-content class="stdTheme" style="width: 100%;margin-bottom: 0px;overflow-y: scroll; height: 500px; max-height: 500px">
                        <fieldset class="smtp-border" style="margin-top: 0px; margin-bottom: 10px">
                            <legend class="smtp-border"  ><b>{{ 'smtpServerParams' | translate }}</b></legend>
                            <div class="flex-row row w-100 stdTheme" style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpServer' | translate }}</mat-label>
                                        <input id="smtpServer" class="form-control inputTheme" formControlName="smtpServer" 
                                            matInput #smtpServer 
                                            placeholder="{{ 'smtpServer' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>  
                                <div class="col-2">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpPort' | translate }}</mat-label>
                                        <!--<input id="smtpPort" class="form-control inputTheme" formControlName="smtpPort" 
                                            matInput #smtpPort 
                                            placeholder="{{ 'smtpPort' | translate }}"
                                            required >-->
                                        <mat-select id="smtpPort"  formControlName="smtpPort" 
                                            panelClass="mdiselectcontent" #smtpPort
                                            (ngModelChange)="onChangeField('smtpPort', $event)"
                                            ngDefaultControl 
                                            required >
                                            <mat-option class="selectOptionsTheme" *ngFor="let smtpPortCur of smtpPortsList" 
                                                [value]="smtpPortCur.value"  >{{smtpPortCur.label | translate}}
                                            </mat-option>
                                        </mat-select>   
                                    </mat-form-field>
                                </div>  
                                <div class="col-3">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpConnectSecurity' | translate }}</mat-label>
                                        <!--
                                        <input id="smtpConnectSecurity" class="form-control inputTheme" formControlName="smtpConnectSecurity" 
                                            matInput #smtpConnectSecurity 
                                            placeholder="{{ 'smtpConnectSecurity' | translate }}"
                                            required >
                                        -->
                                        <mat-select id="smtpConnectSecurity"  formControlName="smtpConnectSecurity" 
                                            panelClass="mdiselectcontent" #smtpConnectSecurity
                                            (ngModelChange)="onChangeField('smtpConnectSecurity', $event)"
                                            ngDefaultControl 
                                            required >
                                            <mat-option class="selectOptionsTheme" *ngFor="let smtpConnectCur of smtpConnectSecurityList" 
                                                [value]="smtpConnectCur.value"  >{{smtpConnectCur.label | translate}}
                                            </mat-option>
                                        </mat-select>   
                                    </mat-form-field>
                                </div> 
                                <div class="col-3">
                                    <div class="checkbox" 
                                        style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;margin-top: 30px;margin-left: 0px;padding-left: 10px;padding-right: 10px;">
                                        <label class="labelLine" for="smtpDebug" style="margin-left: 0px; margin-right: 15px" >{{ 'smtpDebug' | translate }}</label>
                                        <input type="checkbox" id="smtpDebug"  #smtpDebug class="inputTheme" name="smtpDebug" style="padding-top: 0px;"
                                            (click)="onChangeField('smtpDebug', smtpDebug.checked)" formControlName="smtpDebug" 
                                            [checked]="isSmtpDebug" 
                                            />
                                    </div>
                                </div>
                            </div> 
                        </fieldset>                        
                        <fieldset class="smtp-border" style="margin-top: 0px; margin-bottom: 10px">
                            <legend class="smtp-border"  ><b>{{ 'smtpUserAuthentification' | translate }}</b></legend>
                            <div class="flex-row row w-100 stdTheme d-flex align-items-center" style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpUser' | translate }}</mat-label>
                                        <input id="smtpUser" class="form-control inputTheme" formControlName="smtpUser" 
                                            matInput #smtpUser 
                                            placeholder="{{ 'smtpUser' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpPasswd' | translate }}</mat-label>
                                        <input id="smtpPasswd" type="password" class="form-control inputTheme" 
                                            formControlName="smtpPasswd" 
                                            matInput #smtpPasswd 
                                            [type]="showSmtpPassword ? 'text' : 'password'"
                                            placeholder="{{ 'smtpPasswd' | translate }}"
                                            required >
                                        <mat-icon matSuffix (click)="showSmtpPassword = !showSmtpPassword" style="margin-left: 10px;">{{showSmtpPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="smtp-border" style="margin-top: 0px; margin-bottom: 10px">
                            <legend class="smtp-border"  ><b>{{ 'smtpParamResponse' | translate }}</b></legend>
                            <div class="flex-row row w-100 stdTheme" style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpFrom' | translate }}</mat-label>
                                        <input id="smtpFrom" class="form-control inputTheme" formControlName="smtpFrom" 
                                            matInput #smtpFrom 
                                            placeholder="{{ 'smtpFrom' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpReplyTo' | translate }}</mat-label>
                                        <input id="smtpReplyTo" class="form-control inputTheme" formControlName="smtpReplyTo" 
                                            matInput #smtpReplyTo 
                                            placeholder="{{ 'smtpReplyTo' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'smtpNoreply' | translate }}</mat-label>
                                        <input id="smtpNoreply" class="form-control inputTheme" formControlName="smtpNoreply" 
                                            matInput #smtpNoreply 
                                            placeholder="{{ 'smtpNoreply' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="smtp-border" style="margin-top: 0px; margin-bottom: 10px">
                            <legend class="smtp-border"  ><b>{{ 'smtpStorage' | translate }}</b></legend>
                            <div class="flex-row row w-100 stdTheme justify-content-center" style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storePrefix' | translate }}</mat-label>
                                        <input id="storePrefix" class="form-control inputTheme" formControlName="storePrefix" 
                                            matInput #storePrefix 
                                            placeholder="{{ 'storePrefix' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="flex-row row w-100 stdTheme " style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
 
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeWorks' | translate }}</mat-label>
                                        <input id="storeWorks" class="form-control inputTheme" formControlName="storeWorks" 
                                            matInput #storeWorks 
                                            placeholder="{{ 'storeWorks' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
 
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeAppointments' | translate }}</mat-label>
                                        <input id="storeAppointments" class="form-control inputTheme" formControlName="storeAppointments" 
                                            matInput #storeAppointments 
                                            placeholder="{{ 'storeAppointments' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeRH' | translate }}</mat-label>
                                        <input id="storeRH" class="form-control inputTheme" formControlName="storeRH" 
                                            matInput #storeRH 
                                            placeholder="{{ 'storeRH' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                            </div>
  
                            <div class="flex-row row w-100 stdTheme " style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeQuotation' | translate }}</mat-label>
                                        <input id="storeQuotation" class="form-control inputTheme" formControlName="storeQuotation" 
                                            matInput #storeQuotation 
                                            placeholder="{{ 'storeQuotation' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeInvoice' | translate }}</mat-label>
                                        <input id="storeInvoice" class="form-control inputTheme" formControlName="storeInvoice" 
                                            matInput #storeInvoice 
                                            placeholder="{{ 'storeInvoice' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeAdvance' | translate }}</mat-label>
                                        <input id="storeAdvance" class="form-control inputTheme" formControlName="storeAdvance" 
                                            matInput #storeAdvance 
                                            placeholder="{{ 'storeAdvance' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row w-100 stdTheme " style="margin: 0px; margin-left: 0px; margin-right: 0px;" >
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeCustomer' | translate }}</mat-label>
                                        <input id="storeCustomer" class="form-control inputTheme" formControlName="storeCustomer" 
                                            matInput #storeCustomer 
                                            placeholder="{{ 'storeCustomer' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeRevisions' | translate }}</mat-label>
                                        <input id="storeRevisions" class="form-control inputTheme" formControlName="storeRevisions" 
                                            matInput #storeRevisions 
                                            placeholder="{{ 'storeRevisions' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'storeSmtp' | translate }}</mat-label>
                                        <input id="storeSmtp" class="form-control inputTheme" formControlName="storeSmtp" 
                                            matInput #storeSmtp 
                                            placeholder="{{ 'storeSmtp' | translate }}"
                                            required >
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #modelsTemplate >
    <!--   https://stackblitz.com/angular/ynbnyenqnvx?file=src%2Fapp%2Fsidenav-open-close-example.ts -->
    <div class="container-fluid stdTheme " >
        <div class="flex-row row stdTheme" style="margin: 0px; margin-left: 0px; margin-right: 0px;">
            <div class="col-1" style="margin-top: 20px;; padding-left: 0px; padding-right: 0px;">
                <mat-toolbar class="stdTheme">
                    <mat-toolbar-row class="toolbar-work" *ngIf="!displayUpload">
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadFiles' | translate }}" 
                                (click)="toggleUploadFiles()"
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">cloud_upload</mat-icon>
                        </button>
                    </mat-toolbar-row>
    
                    <mat-toolbar-row class="toolbar-work"  *ngIf="!displayList">
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayList' | translate }}" 
                                (click)="toggleDisplayList()"
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">list</mat-icon>
                        </button>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>                              
        
            <div class="col-11" *ngIf="displayUpload" style="margin-top: 20px; padding-left: 0px; padding-right: 0px;">
                <!--<mdi-upload-files fileExt="XLSX, DOCX, DOC, ODT" uploadType="entity" ></mdi-upload-files>-->
                <mdi-works-docs 
                    [statutCall]='model'
                    [fileExt]='listExt'
                    [wksEntity]='entityToUpdate'
                    [actionType]=''
                    [title]='"new doc"'
                    [tagsArg] = 'tagsArg'
                    (dataOut)="newListDocs($event)"
                    >
                </mdi-works-docs>
            </div>
            <div class="col-11" *ngIf="displayList" style="margin-top: 20px; padding-left: 0px; padding-right: 0px;">
                <div *ngIf="displayList" [ngStyle]="{'margin-top': '10px', 'width': '100%','padding': '0px'}" >
                    <div *ngIf="entityToUpdate.workDocs === null ||entityToUpdate.workDocs === undefined || entityToUpdate.workDocs.length === 0" class="flex-row row stdTheme justify-content-center">
                        <h4 class="titleTheme" style="padding-top: 10px;">{{ 'no models' | translate }}</h4>
                    </div>
                    <div *ngIf="entityToUpdate.workDocs !== null && entityToUpdate.workDocs !== undefined && entityToUpdate.workDocs.length > 0">
                        <table class="table stdTheme" id="wksWorksDocs"  >
                            <thead class="theadWork">
                                <tr class="trWork">
                                    <th style="width: 30%; padding: 0px; text-align: left;vertical-align: middle!important;" >{{'workdocLabel' | translate}}</th>
                                    <th style="width: 35%; padding: 0px; text-align: left;vertical-align: middle!important;" >{{'workdocFilename' | translate}}</th>
                                    <th style="width: 20%; padding: 0px; text-align: center;vertical-align: middle!important;" >{{'uploadDate' | translate}}</th>
                                    <!--<th style="width: 5%; padding: 0px; text-align: center;">{{'workdocType' | translate}}</th>-->
                                    <th style="width: 5%; padding: 0px; text-align: center;vertical-align: middle!important;"></th>
                                </tr>
                            </thead>
                            <tbody class="tbodyWork" *ngFor="let docCur of entityToUpdate.workDocs; let i = index">
                                <!--<div *ngFor="let docCur of entityToUpdate.workDocs; let i = index">-->
                                    <tr class="trWork " [class.data-selected]="i === selectedDoc" (click)="setClickedDoc(i)">
                                        <td style="width: 30%; padding: 0px; text-align: left;vertical-align: middle!important;">{{(docCur.workdocLegend === null ? ' ' : docCur.workdocLegend) | translate }}</td>
                                        <td style="width: 35%; padding: 0px; text-align: left;vertical-align: middle!important;">{{docCur.workdocFilename }}</td>
                                        <td style="width: 20%; padding: 0px; text-align: center;vertical-align: middle!important;">{{getDateUpload(docCur.stdCreation)}}</td>

                                        <td style="width: 5%; padding: 0px; text-align: center;vertical-align: middle!important;">
                                            <button mat-icon-button class="local-button" (click)="removeDoc(i)">
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                               <!-- </div>-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modalMap let-modal >
    <mdi-google-location [googleMaps] = "googleMaps"  ></mdi-google-location>
</ng-template>
<ng-template #calculationsTemplate >
    <div class="flex-row row stdTheme justify-content-center" style="margin: 0px; margin-left: 0px; margin-right: 0px;width: 100%">
        <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;width: 100%"
                        (selectedIndexChange)="onCalculationChanged($event)">
            <mat-tab label="{{ 'local price' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-calculation-algo-table
                            [otherData]=''
                            [calculationAlgo]='algoLocalPrice'
                            [calculationType]='"localPrice"'
                            [entityCur]='entityCur'
                            [isReadOnly]='isReadOnly'
                            (dataOut)="dataOutAlgo('localPrice', $event)" 
                        ></mdi-calculation-algo-table>

                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab label="{{ 'imported price' | translate }}" >
                <mat-tab-body >
                    <ng-template matTabContent >
                            <mdi-calculation-algo-table
                            [otherData]=''
                            [calculationAlgo]='algoImportedPrice'
                            [calculationType]='"importedPrice"'
                            [entityCur]='entityCur'
                            [isReadOnly]='isReadOnly'
                            (dataOut)="dataOutAlgo('importedPrice', $event)" 
                        ></mdi-calculation-algo-table>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-template>
<ng-template #apiTemplate >
    <div class="flex-row row stdTheme justify-content-center" style="margin: 0px; margin-left: 0px; margin-right: 0px;width: 100%">
        <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;width: 100%"
                        (selectedIndexChange)="onAPIChanged($event)">
            <mat-tab >
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" style="height: 40px!important;margin-left: 0px;margin-right: 0px;" 
                                [ngClass]="{'stdTheme': !apisEntity[0].apiActive,
                                            'amberTheme': apisEntity[0].apiActive && !apisEntity[0].apiTestedOK,
                                            'greenTheme': apisEntity[0].apiActive && apisEntity[0].apiTestedOK}">
                        <span class="col-12" >{{ 'stock management api' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-apis
                            [otherData]=''
                            [apiContent]='stockManagementApi'
                            [apiType]='"stockManagement"'
                            [entityCur]='entityCur'
                            [isReadOnly]='isReadOnly'
                            (dataOut)="dataOutAPI('stockManagement', $event)" 
                        >
                        </mdi-apis>

                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab  >
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" style="height: 40px!important;margin-left: 0px;margin-right: 0px;" 
                                [ngClass]="{'stdTheme': !apisEntity[1].apiActive,
                                            'amberTheme': apisEntity[1].apiActive && !apisEntity[1].apiTestedOK,
                                            'greenTheme': apisEntity[1].apiActive && apisEntity[1].apiTestedOK}">
                        <span class="col-12" >{{ 'accounting api' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-apis
                        [otherData]=''
                        [apiContent]='accountingApi'
                        [apiType]='"accounting"'
                        [entityCur]='entityCur'
                        [isReadOnly]='isReadOnly'
                        (dataOut)="dataOutAPI('accounting', $event)" 
                        ></mdi-apis>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab >
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" style="height: 40px!important;margin-left: 0px;margin-right: 0px;" 
                                [ngClass]="{'stdTheme': !apisEntity[2].apiActive,
                                            'amberTheme': apisEntity[2].apiActive && !apisEntity[2].apiTestedOK,
                                            'greenTheme': apisEntity[2].apiActive && apisEntity[2].apiTestedOK}">
                        <span class="col-12" >{{ 'notifications api' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-apis
                        [otherData]=''
                        [apiContent]='notificationsApi'
                        [apiType]='"notifications"'
                        [entityCur]='entityCur'
                        [isReadOnly]='isReadOnly'
                        (dataOut)="dataOutAPI('notifications', $event)" 
                        ></mdi-apis>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab >
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" style="height: 40px!important;margin-left: 0px;margin-right: 0px;" 
                                [ngClass]="{'stdTheme': !apisEntity[3].apiActive,
                                            'amberTheme': apisEntity[3].apiActive && !apisEntity[3].apiTestedOK,
                                            'greenTheme': apisEntity[3].apiActive && apisEntity[3].apiTestedOK}">
                        <span class="col-12" >{{ 'invoicing' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-apis
                        [otherData]=''
                        [apiContent]='invoicingApi'
                        [apiType]='"invoicing"'
                        [entityCur]='entityCur'
                        [isReadOnly]='isReadOnly'
                        (dataOut)="dataOutAPI('invoicing', $event)" 
                        ></mdi-apis>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab >
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" style="height: 40px!important;margin-left: 0px;margin-right: 0px;" 
                                [ngClass]="{'stdTheme': apisEntity[4].apiActive,
                                            'redTheme': apisEntity[4].apiActive && !apisEntity[4].apiTestedOK,
                                            'greenTheme': apisEntity[4].apiActive && apisEntity[4].apiTestedOK}">
                        <span class="col-12" >{{ 'customers api' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <mdi-apis
                        [otherData]=''
                        [apiContent]='customersApi'
                        [apiType]='"customers"'
                        [entityCur]='entityCur'
                        [isReadOnly]='isReadOnly'
                        (dataOut)="dataOutAPI('customers', $event)" 
                        ></mdi-apis>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-template>