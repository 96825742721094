import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ModalCommonComponent } from '../../../job/common/components/modal-common/modal-common.component';

import { SchedulerService } from '../../../services/scheduler.service';
import { WksCentralService } from '../../../job/job-wks/services/wks-central.service';
import { ArgsDef, ClassDef, SchedulerJobInfosModel } from '../../../models/schedulerJob.model';

import { TranslateService } from '../../../services/translate.service';
import { CommonMethods } from '../../../job/common/tools/commonMethods';

import { LabelValue, NameValueArgs } from '../../../models/data.model';
import { BrandWksModel } from '../../../job/job-wks/models/wks-common.model';

@Component({
  selector: 'mdi-scheduler-card',
  templateUrl: './scheduler-card.component.html',
  styleUrls: ['./scheduler-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCardComponent implements OnInit, OnChanges {

  @Output() dataOut = new EventEmitter<any>();
  
  @Input() schedulerJobInfosCur: SchedulerJobInfosModel;
  @Input() statutCall: string;
  @Input() selectedRow: number; 
  @Input() entitiesList: LabelValue[];
  
  private readonly onDestroy = new Subject<void>();

  notificationsSubscription: Subscription;
  
  schedulerJobInfosCache: SchedulerJobInfosModel;
  okToDisplay: boolean;
  jobInfosForm: FormGroup;
  isOkToSave: boolean; 
  isOkToAction: boolean;
  timeFormat: string;

  dataReturn: {
    row: number,
    schedulerJobInfosCur: SchedulerJobInfosModel,
    action: string
  };

  brandsList: LabelValue[] = [];
  classList: LabelValue[];
  entityList: LabelValue[];
  argsNameList: LabelValue[];
  // argsValuesList: LabelValue[];
  classParamList: ClassDef[];
  arglistRef: ArgsDef[];
  argListFa: FormArray;

  inputArgs: { 
    nameArg: string;
    valueArg: string;
    mandatory: boolean;
  }[];
  argsValueList: any[][];
  constructor(private _schedulerService: SchedulerService,
              private _wksCentralService: WksCentralService,
              private _dialog: MatDialog,
              private _translate: TranslateService) { }

  ngOnInit(): void { 

    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'selectedRow': {
            this.selectedRow = changes['selectedRow'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'schedulerJobInfos': {
            this.schedulerJobInfosCur = changes['schedulerJobInfos'].currentValue;
            break;
          }
          case 'entitiesList': {
            this.entitiesList = changes['entitiesList'].currentValue;
            break;
          }
          case 'brandsList': {
            this.brandsList = changes['brandsList'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.initData();
  }
  
  initData(): void {
 
    this.schedulerJobInfosCache =  JSON.parse(JSON.stringify(this.schedulerJobInfosCur));

    this.initAllBooleans();
    this.initAllParams();
    this.buildForm();
    this.fillForm();
    this.initAllSubscribe();

    this.okToDisplay = true;
  }
  initAllBooleans(): void {
    this.okToDisplay = false;
    this.isOkToSave = false;
    this.isOkToAction = false;
    if (this.statutCall === 'update') {
      this.isOkToAction = true;
    }
  }
  initAllParams(): void {    
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    /*
    this.argsNameList = [];
    const argItem = {
      value: 'entity',
      label: 'entity'
    };
    this.argsNameList.push(argItem);
    */
    this.classParamList = this._wksCentralService.getSchedulerClasses();
    this.inputArgs = [];
    this.classList = [];
    /*
    let classItem = {
      value: 'com.nautikoWS.quartz.jobs.SimpleJob',
      label: 'com.nautikoWS.quartz.jobs.SimpleJob'
    };
    this.classList.push(classItem);
    classItem = {
      value: 'com.nautikoWS.quartz.jobs.SampleCronJob',
      label: 'com.nautikoWS.quartz.jobs.SampleCronJob'
    };
    this.classList.push(classItem);
    */
    // com.nautikoWS.quartz.jobs.GenerateKeys
    /*
    const classItem = {
      value: 'com.nautikoWS.quartz.jobs.GenerateKeys',
      label: 'com.nautikoWS.quartz.jobs.GenerateKeys'
    };
        this.classList.push(classItem);
    */
    for (const classDefItem of this.classParamList) {
      const classItem = {
        value: classDefItem.classPath,
        label: classDefItem.classPath
      };
      this.classList.push(classItem);
    }

  }
  getArgList(classPathArg: string): void {

    this.argsNameList = [];
    this.arglistRef = [];
    this.argsValueList = [];
    this.inputArgs = [];
  
    this.buildArrayFa();

    for (const classDefItem of this.classParamList) {
      if (classDefItem.classPath !== classPathArg) {
          continue;
      }
      this.arglistRef = classDefItem.args;
      let idxRow = 0;
      for (const argItem of this.arglistRef) {
        const argOption = {
          value: argItem.argName,
          label: argItem.argName
        };
        this.argsNameList.push(argOption);
        this.argsValueList[idxRow] = [];
        if (argItem.argName === 'entity') {
          this.argsValueList[idxRow] = [... this.entitiesList];
        }
        if (argItem.argName === 'brand') {
          this.argsValueList[idxRow] = [... this.brandsList];
        }
        this.inputArgs[idxRow] = ({ 
          nameArg: argItem.argName,
          valueArg: '',
          mandatory: argItem.mandatory
        });
        this.addItemArg(argItem);
        idxRow++;
      }
      break;
    }
  }
  initAllSubscribe(): void {    
    this.jobInfosForm.valueChanges.subscribe(x => {
      this.isOkToSave = false;
      const locItem = this.fillModel();
      if ((JSON.stringify(this.schedulerJobInfosCache) !== JSON.stringify(locItem))) {
        if (this.jobInfosForm.status === 'VALID' && this.argListFa.status === 'VALID' ) {
          this.isOkToSave = true;
          this.isOkToAction = false;
        }
      }
    });
    this.argListFa.valueChanges.subscribe(x => {
      this.isOkToSave = false;
      const locItem = this.fillModel();
      if ((JSON.stringify(this.schedulerJobInfosCache) !== JSON.stringify(locItem))) {
        if (this.jobInfosForm.status === 'VALID' && this.argListFa.status === 'VALID' ) {
          this.isOkToSave = true;
          this.isOkToAction = false;
        }
      }
    });
  }
  initBrandsList(entity: string): void {
    this.loadWksBrands(entity)
    .then ((responseBrand: BrandWksModel[]) => {
      this.brandsList = [];
      for (const brandCur of responseBrand) {

        const opt = {
          value: brandCur.brandName,
          label: brandCur.brandName
        };
        this.brandsList.push(opt);
      }
      this.brandsList.sort((obj1: LabelValue, obj2: LabelValue) => {
        return obj1.label > obj2.label ? 1 : -1;
      });
      const optAll = {
        value: 'ALL',
        label: 'ALL'
      };
      this.brandsList.unshift(optAll);
      let idxItem = 0 ;
      for (const itemArg of this.argsNameList) {
        if (itemArg.value === 'brand') {
          this.argsValueList[idxItem] = [... this.brandsList];
          break;
        }
        idxItem++;
      }
    })
    .catch ((error: any) => {
      console.log('loadWksBrands : ' + error);
      return;
    });
  }
  loadWksBrands(entity: string): any {
   
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const brandList = data.body;

          resolve(brandList);
        }, err => {
          // console.log(err);
          reject(err.status);
        }
      );
    });
  }
  buildForm(): void {
    this.jobInfosForm = undefined;

    this.buildArrayFa();
    
    this.jobInfosForm = new FormGroup({
      jobName: new FormControl({value: '', disabled: this.statutCall === 'add' ? false : true}),
      jobDesc: new FormControl({value: '', disabled: this.statutCall === 'update' || this.statutCall === 'add' ? false : true }),
      jobGroup: new FormControl({value: '', disabled: this.statutCall === 'update' || this.statutCall === 'add' ? false : true }),
      jobClass: new FormControl({value: ''}), // if disabled one time : disabled. This attribute must be managed in HTML template.  [disabled] = "expression"
      argListFg: this.argListFa,
      cronExpression: new FormControl({value: '', disabled: this.statutCall === 'update' || this.statutCall === 'add' ? false : true }),
      jobStatus: new FormControl({value: '', disabled: true }),
      lastRun: new FormControl({value: '', disabled: true }),
      nextRun: new FormControl({value: '', disabled: true }),
    });

  }
  buildArrayFa(): void {
    // this.argListFa = new FormArray([this.buildItemArg()]);
    this.argListFa = new FormArray([]);
  } 
  addItemArg(argItem: ArgsDef): void {
    this.argListFa.push(this.buildItemArg(argItem));
    this.jobInfosForm.controls.argListFg = this.argListFa;
    this.inputArgs.push({ 
      nameArg: '',
      valueArg: '',
      mandatory: argItem.mandatory
    });
    this.argsValueList.push([]);
  }
  /*
  removeItemArg(idxRow: number): void {
    this.argListFa.removeAt(idxRow);
    this.jobInfosForm.controls.argListFg = this.argListFa;
    this.inputArgs.splice(idxRow, 1);
    this.argsValueList.splice(idxRow, 1);
  }
  */
  buildItemArg(argItem: ArgsDef): FormGroup {
    const isDisabled = this.statutCall === 'update' || this.statutCall === 'add' ? false : true;
    const isRequired = isDisabled ? false : argItem.mandatory;
    const defaulfValue = argItem.defaultValue ? argItem.defaultValue : '';
    const formGroupTmp: FormGroup = new FormGroup ({
      nameArg: new FormControl({value: argItem.argName, disabled: isDisabled}),
      valueArg: new FormControl({value: defaulfValue, disabled: isDisabled})
    });

    /*
    if (isRequired) {
      formGroupTmp = new FormGroup ({
        nameArg: new FormControl({value: argItem.argName, disabled: isDisabled}, Validators.required),
        valueArg: new FormControl({value: defaulfValue, disabled: isDisabled}, Validators.required)
      });


      formGroupTmp.get('nameArg').setValidators(Validators.required);
      formGroupTmp.get('valueArg').setValidators(Validators.required);

      formGroupTmp.controls['nameArg'].setValidators([Validators.required]);
      formGroupTmp.controls['nameArg'].updateValueAndValidity();
      formGroupTmp.controls['valueArg'].setValidators([Validators.required]);
      formGroupTmp.controls['valueArg'].updateValueAndValidity();
    }
    */
    if (!isDisabled) {
      if (formGroupTmp.controls.nameArg.value === '') {
        formGroupTmp.controls['nameArg'].setErrors({'incorrect': true});
      }
      if (formGroupTmp.controls.valueArg.value === '') {
        formGroupTmp.controls['valueArg'].setErrors({'incorrect': true});
      }
    }
    
    return formGroupTmp;
  }
  fillForm(): void {
    this.jobInfosForm.controls.jobName.setValue(this.schedulerJobInfosCur.jobName);
    this.jobInfosForm.controls.jobDesc.setValue(this.schedulerJobInfosCur.jobDesc);
    this.jobInfosForm.controls.jobGroup.setValue(this.schedulerJobInfosCur.jobGroup);
    this.jobInfosForm.controls.jobStatus.setValue(this.schedulerJobInfosCur.jobStatus);
    this.jobInfosForm.controls.jobClass.setValue(this.schedulerJobInfosCur.jobClass);
    // this.jobInfosForm.controls.jobArgs.setValue(this.schedulerJobInfosCur.jobArgs);
    this.jobInfosForm.controls.cronExpression.setValue(this.schedulerJobInfosCur.cronExpression);
    if (this.schedulerJobInfosCur.lastRun !== undefined && this.schedulerJobInfosCur.lastRun !== null) {
      this.jobInfosForm.controls.lastRun.setValue(CommonMethods.dateToString(this.schedulerJobInfosCur.lastRun, 'unixMnoZ', this.timeFormat));
    }
    if (this.schedulerJobInfosCur.nextRun !== undefined && this.schedulerJobInfosCur.nextRun !== null) {
      this.jobInfosForm.controls.nextRun.setValue(CommonMethods.dateToString(this.schedulerJobInfosCur.nextRun, 'unixMnoZ', this.timeFormat));
    }
    if (this.schedulerJobInfosCur.jobArgs !== undefined 
        && this.schedulerJobInfosCur.jobArgs !== null
        && this.schedulerJobInfosCur.jobArgs !== '') {
      //  argsList: NameValue[];
      const argsList = JSON.parse(this.schedulerJobInfosCur.jobArgs) as NameValueArgs[];
      this.getArgList(this.schedulerJobInfosCur.jobClass);
      this.buildArrayFa();
      this.inputArgs = [];
      for (const argRow of argsList) {
        let formGrpItem: FormGroup;
        for (const argDefItem of this.arglistRef) {
          if (argRow.nameArg === argDefItem.argName) {
            formGrpItem = this.buildItemArg(argDefItem);
            formGrpItem.controls.nameArg.setValue(argRow.nameArg);
            formGrpItem.controls.valueArg.setValue(argRow.valueArg);
            this.argListFa.push(formGrpItem);
            this.inputArgs.push({ 
              nameArg: argRow.nameArg,
              valueArg: argRow.valueArg,
              mandatory: argDefItem.mandatory
            });
            if (argRow.nameArg === 'entity' && argRow.valueArg !== 'ALL') {
              this.initBrandsList(argRow.valueArg);
            }
            break;
          }
        }

      }
    }
    this.jobInfosForm.controls.argListFg = this.argListFa;
  }

  fillModel(): SchedulerJobInfosModel {

    const argList: NameValueArgs[] = [];
    const nbRow = this.argListFa.length;
    for (let i = 0; i < nbRow ; i++) {
      const itemRow: FormGroup = this.argListFa.get(i.toString()) as FormGroup; 
      const argItem: NameValueArgs =  {
        nameArg: itemRow.controls.nameArg.value,
        valueArg: itemRow.controls.valueArg.value
      };
      argList.push(argItem);
    }

    const locItem: SchedulerJobInfosModel = {
      jobId: this.schedulerJobInfosCur.jobId,
      jobName:  this.jobInfosForm.controls.jobName.value,
      jobDesc:  this.jobInfosForm.controls.jobDesc.value,
      jobGroup:  this.jobInfosForm.controls.jobGroup.value,
      jobStatus:  this.jobInfosForm.controls.jobStatus.value,
      jobClass:  this.jobInfosForm.controls.jobClass.value,
      jobArgs:  (argList.length > 0 ? JSON.stringify(argList) : ''),
      cronExpression:  this.jobInfosForm.controls.cronExpression.value,
      interfaceName: this.schedulerJobInfosCur.interfaceName,
      repeatTime: this.schedulerJobInfosCur.repeatTime,
      cronJob: (this.jobInfosForm.controls.cronExpression.value .length > 0 ? true : false)
    };

    return locItem;
  }
  onClassSelected(classValue: string): void {
    this.schedulerJobInfosCur.jobClass = classValue;
    this.getArgList(classValue);
    this.buildArrayFa();
    for (const argDefItem of this.arglistRef) {
      this.addItemArg(argDefItem);
    }
  }
  onChangeArgs(typeArg: string, idxRow: number, argInput: string): void {
    const itemFG: FormGroup = this.argListFa.get(idxRow.toString()) as FormGroup;  
    switch (typeArg) {
      case 'nameArg': {
        itemFG.controls.nameArg.setValue(argInput);
        this.jobInfosForm.controls.argListFg = this.argListFa;
        if (argInput === '') {
          itemFG.controls['nameArg'].setErrors({'incorrect': true});
        }
        this.inputArgs[idxRow].nameArg = argInput;

        break;
      }
      case 'valueArg': {
        itemFG.controls.valueArg.setValue(argInput);
        this.jobInfosForm.controls.argListFg = this.argListFa;
        if (argInput === '') {
          itemFG.controls['valueArg'].setErrors({'incorrect': true});
        }
        this.inputArgs[idxRow].valueArg = argInput;
        if (this.inputArgs[idxRow].nameArg === 'entity' && argInput !== 'ALL') {
          this.initBrandsList(argInput);
        }
        break;
      }
    } // end switch
  }

  validData(): void {
    const schedulerJobInfosValid = this.fillModel();
    this.dataReturn = {
      row: (this.statutCall === 'add' ? -10 : this.selectedRow),
      schedulerJobInfosCur: schedulerJobInfosValid,
      action: this.statutCall
    };

    this._schedulerService.saveJob(this.dataReturn.action, schedulerJobInfosValid)
    .subscribe(
      data => {
        this.isOkToAction = true;
        this.isOkToSave = false;
        this.dataOut.emit(this.dataReturn);
      },
      err =>  {
        this.isOkToAction = true;
        this.isOkToSave = false;
        console.log('validData : ' + JSON.stringify(err));
      }
    );
  }
  deleteJob(): void {
    this.displayMessage('removeJob', [
      this._translate.instant('remove job') + ' ' + this.schedulerJobInfosCur.jobName]);
  }
  cancelJob(): void {
    const dataReturn = '{}';
    this.dataOut.emit(dataReturn);
  }
  
  removeJob(): void {
    this.dataReturn = {
      row: (this.statutCall === 'add' ? -10 : this.selectedRow),
      schedulerJobInfosCur: this.schedulerJobInfosCur,
      action: 'removeJob'
    };
    this._schedulerService.deleteJob(this.dataReturn.action, this.schedulerJobInfosCur)
    .subscribe(
      data => {
        this.isOkToAction = false;
        this.isOkToSave = false;
        this._dialog.closeAll();
        this.dataOut.emit(this.dataReturn);
      },
      err =>  {
        this.isOkToAction = false;
        this.isOkToSave = false;
        this._dialog.closeAll();
        console.log('removeJob : ' + JSON.stringify(err));
      }
    );
  }
  runJob(): void {
    this.dataReturn = {
      row: (this.statutCall === 'add' ? -10 : this.selectedRow),
      schedulerJobInfosCur: this.schedulerJobInfosCur,
      action: 'runJob'
    };
    this._schedulerService.runJob(this.dataReturn.action, this.schedulerJobInfosCur)
    .subscribe(
      data => {
        this.isOkToAction = false;
        this.isOkToSave = false;
        this.dataOut.emit(this.dataReturn);
      },
      err =>  {
        this.isOkToAction = false;
        this.isOkToSave = false;
        console.log('runJob : ' + JSON.stringify(err));
      }
    );
  }

  pauseJob(): void {
    this.dataReturn = {
      row: (this.statutCall === 'add' ? -10 : this.selectedRow),
      schedulerJobInfosCur: this.schedulerJobInfosCur,
      action: 'pauseJob'
    };
    this._schedulerService.pauseJob(this.dataReturn.action, this.schedulerJobInfosCur)
    .subscribe(
      data => {
        this.isOkToAction = false;
        this.isOkToSave = false;
        this.dataOut.emit(this.dataReturn);
      },
      err =>  {
        this.isOkToAction = false;
        this.isOkToSave = false;
        console.log('pauseJob : ' + JSON.stringify(err));
      }
    );
  }
  resumeJob(): void {
    this.dataReturn = {
      row: (this.statutCall === 'add' ? -10 : this.selectedRow),
      schedulerJobInfosCur: this.schedulerJobInfosCur,
      action: 'resumeJob'
    };
    this._schedulerService.resumeJob(this.dataReturn.action, this.schedulerJobInfosCur)
    .subscribe(
      data => {
        this.isOkToAction = false;
        this.isOkToSave = false;
        this.dataOut.emit(this.dataReturn);
      },
      err =>  {
        this.isOkToAction = false;
        this.isOkToSave = false;
        console.log('resumeJob : ' + JSON.stringify(err));
      }
    );
  }
  
  displayMessage(_actionCur: string, args: any[] ) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    titleBox = 'accounting';
    let messageBox: string;
    let typeDialogArg: string;
    if (_actionCur === 'removeJob') {
      messageBox = args[0]  + ' ?';
      typeDialogArg = 'confirmAction';
    } 
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog:  typeDialogArg !== undefined ? typeDialogArg : 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: messageBox,
        data1: '',
        data2: '',
        messageType: 'ERROR'
    };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data !== undefined)  {
          if (( data === 'okAction') && (_actionCur === 'removeJob')) {
            this.removeJob();
          }
         
        }
      });
  }
}
