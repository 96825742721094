/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./detailicon-renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../services/translate.pipe";
import * as i3 from "../../../../../../../services/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./detailicon-renderer.component";
var styles_DetailiconRendererComponent = [i0.styles];
var RenderType_DetailiconRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DetailiconRendererComponent, data: {} });
export { RenderType_DetailiconRendererComponent as RenderType_DetailiconRendererComponent };
function View_DetailiconRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-circle m-1"], ["data-action-type", "detail-close"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "fa fa-angle-up"], ["data-action-type", "detail-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("detailZoom")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DetailiconRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-circle m-1"], ["data-action-type", "detail-open"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "fa fa-angle-down"], ["data-action-type", "detail-open"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("detailZoom")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DetailiconRendererComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-info btn-report m-1"], ["data-action-type", "subreport-close"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa fa-angle-up"], ["data-action-type", "subreport-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.subReportName, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.subReportAction; _ck(_v, 2, 0, currVal_1); }); }
function View_DetailiconRendererComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-info btn-report m-1"], ["data-action-type", "subreport-open"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa fa-angle-down"], ["data-action-type", "subreport-open"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.subReportName, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.subReportAction; _ck(_v, 2, 0, currVal_1); }); }
function View_DetailiconRendererComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-info btn-report m-1"], ["data-action-type", "pdf-close"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa fa-angle-up"], ["data-action-type", "pdf-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.subReportName, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.subReportAction; _ck(_v, 2, 0, currVal_1); }); }
function View_DetailiconRendererComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-info btn-report m-1"], ["data-action-type", "pdf-open"], ["data-placement", "left"], ["data-toggle", "tooltip"], ["type", "button"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa fa-angle-down"], ["data-action-type", "pdf-open"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.subReportName, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.subReportAction; _ck(_v, 2, 0, currVal_1); }); }
export function View_DetailiconRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailiconRendererComponent_6)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.detailTypeZoom && _co.detailIsOpen); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.detailTypeZoom && !_co.detailIsOpen); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.detailSubReport && !_co.isDocReport) && _co.subReportIsOpen); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.detailSubReport && !_co.isDocReport) && !_co.subReportIsOpen); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.detailSubReport && _co.isDocReport) && _co.docReportIsOpen); _ck(_v, 10, 0, currVal_4); var currVal_5 = ((_co.detailSubReport && _co.isDocReport) && !_co.docReportIsOpen); _ck(_v, 12, 0, currVal_5); }, null); }
export function View_DetailiconRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-detailicon-renderer", [], null, null, null, View_DetailiconRendererComponent_0, RenderType_DetailiconRendererComponent)), i1.ɵdid(1, 180224, null, 0, i5.DetailiconRendererComponent, [i3.TranslateService], null, null)], null, null); }
var DetailiconRendererComponentNgFactory = i1.ɵccf("mdi-detailicon-renderer", i5.DetailiconRendererComponent, View_DetailiconRendererComponent_Host_0, {}, {}, []);
export { DetailiconRendererComponentNgFactory as DetailiconRendererComponentNgFactory };
