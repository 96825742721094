/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application-test.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../job/common/components/topbar/topbar.component.ngfactory";
import * as i3 from "../../../job/common/components/topbar/topbar.component";
import * as i4 from "../../../services/user.service";
import * as i5 from "../../../services/theme.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/translate.service";
import * as i9 from "../../../job/job-wks/services/wks-central.service";
import * as i10 from "../../../services/translate.pipe";
import * as i11 from "./application-test.component";
import * as i12 from "../../../services/cmd.service";
import * as i13 from "@angular/material/dialog";
var styles_ApplicationTestComponent = [i0.styles];
var RenderType_ApplicationTestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationTestComponent, data: {} });
export { RenderType_ApplicationTestComponent as RenderType_ApplicationTestComponent };
export function View_ApplicationTestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-topbar", [], null, null, null, i2.View_TopbarComponent_0, i2.RenderType_TopbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopbarComponent, [i4.UserService, i5.ThemeService, i6.AuthService, i7.Router, i8.TranslateService, i9.WksCentralService], { isHome: [0, "isHome"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "mat-elevation-z8 container-fluid "], ["style", "margin-top: 75px; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "flex-row row"], ["style", "margin-left: 5px;margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-12 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h6", [["style", "padding-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(0, i10.TranslatePipe, [i8.TranslateService]), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "flex-row row w-100"], ["style", "margin-left: 5px;margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-2 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.testJms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", " "])), i1.ɵpid(0, i10.TranslatePipe, [i8.TranslateService]), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "col-2 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.testValidMail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", " "])), i1.ɵpid(0, i10.TranslatePipe, [i8.TranslateService])], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("testappli")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("testJms")); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("testValidMail")); _ck(_v, 15, 0, currVal_3); }); }
export function View_ApplicationTestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-application-test", [], null, null, null, View_ApplicationTestComponent_0, RenderType_ApplicationTestComponent)), i1.ɵdid(1, 114688, null, 0, i11.ApplicationTestComponent, [i12.CmdService, i13.MatDialog, i4.UserService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationTestComponentNgFactory = i1.ɵccf("mdi-application-test", i11.ApplicationTestComponent, View_ApplicationTestComponent_Host_0, {}, {}, []);
export { ApplicationTestComponentNgFactory as ApplicationTestComponentNgFactory };
