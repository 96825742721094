<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >
       
        <div *ngIf="!isFinishLoading || isUploadInProgress" class="flex-row row" style="margin: 0px">
            <div class="container-fluid d-flex justify-content-center" >
                <mat-progress-bar 
                    mode="indeterminate"
                    style="margin-left: 30px;">
              </mat-progress-bar>
            </div>
        </div>
        <div *ngIf="isFinishLoading" class="flex-row row" style="margin: 0px"  >
            <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
                <form [formGroup]="updateRatesForm"  >
                    <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                        <mat-card-header>
                            <mat-card-title>
                            <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                            <section class="mat-typography">
                                <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                            </section>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="container-fluid d-flex justify-content-between" >
                                <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                    <mat-icon>arrow_back</mat-icon>
                                    {{ 'goback' | translate }}
                                </button>
                                <button *ngIf="updateRatesForm.valid" mat-raised-button  #buttonSubmit type="submit" (click)="processUpdate()" >
                                    <mat-icon>system_update</mat-icon>
                                    {{ 'processRates' | translate }}
                                </button>
 
                            </div>
                        </mat-card-actions>

                        <mat-card-content class="stdTheme" >
                            <div class="flex-row row" >
                                <div class="col-3" >
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'paramType' | translate }}</mat-label>
                                        <mat-select id="equipType" placeholder="{{ 'Choose paramType' | translate }}" formControlName="paramType" 
                                                panelClass="mdiselectcontent"   ngDefaultControl required >
                                            <mat-option class="selectOptionsTheme" *ngFor="let paramTypeCur of paramTypeList" 
                                                [value]="paramTypeCur.value"  >{{paramTypeCur.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> 

                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'brandName' | translate }}</mat-label>
                                        <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" formControlName="brandName" 
                                                    panelClass="mdiselectcontent" #equipBrand required
                                                    (ngModelChange)="onChangeBrand($event)"
                                                    ngDefaultControl >
                                            <mat-select-header>
                                                <div style="display: inline-block;" >
                                                    <button mat-icon-button matPrefix aria-label="search" >
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                            (keyup)="onKeyBrand($event)" >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <button mat-icon-button matSuffix aria-label="add" 
                                                        *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                                [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                            </mat-option>
                                        </mat-select>                         
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row" >
                                <div class="col-10">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'brandRefs' | translate }}</mat-label>
                                        <input id="brandRefs" class="form-control inputTheme" formControlName="brandRefs"  matInput #brandRefs placeholder="{{ 'brandRefs' | translate }}"
                                        >
                                    </mat-form-field>
                                </div> 
                            </div> 
                            <div class="flex-row row" style="padding-bottom: 10px;">
                                <div class="col-6" style="padding-top: 30px;">
                                    <mat-radio-group #percentSens="matRadioGroup" aria-label="Select an option" required
                                          formControlName="percentSens" (change)="onStructureChange(percentSens.value)">
                                      <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="increase">{{ 'increaseRates' | translate }}</mat-radio-button>
                                      <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="reduction">{{ 'reductionRates' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                <div class="col-2">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'percentRate' | translate }}</mat-label>
                                        <input id="percentRate" class="form-control inputTheme" formControlName="percentRate" maxlength="3" #percentRate
                                            onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,3})?$/.test(this.value)?'inherit':'red'" 
                                            type="number" matInput #percentRate placeholder="{{ 'percentRate' | translate }}" required
                                            (change)= "percentRate.value < 1.00 ? percentRate.value = 1.00 : percentRate.value; percentRate.value > 100.00 ? percentRate.value = 100.00 : percentRate.value;"
                                        >
                                    </mat-form-field>
                                </div> 
                            </div> 
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>

</div>
