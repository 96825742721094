

<div #refElement *ngIf="isLoaded" class="mat-elevation-z8 container-fluid stdTheme" 
        style="width: 100%!important;padding-left: 10px;padding-right: 10px;">
   
        <div class="flex-row row stdTheme" style="margin: 0px"  >
            <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-header>
                        <mat-card-title>
                        <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                        <section class="mat-typography">
                            <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                        </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="stdTheme" >
                        <form [formGroup]="partSupplieSearchForm"  >
                            <div class="flex-row row">
                                <div class="col-3">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'brandName' | translate }}</mat-label>
                                        <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" formControlName="brandName" 
                                            panelClass="mdiselectcontent" #equipBrand
                                            required
                                            (selectionChange)="onChangeBrand($event)"
                                            ngDefaultControl >
                                            <mat-select-header>
                                                <div style="display: inline-block;" >
                                                    <button mat-icon-button matPrefix aria-label="search" >
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                    <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                            (keyup)="onKeyBrand($event)" >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandList" 
                                                [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                            </mat-option>
                                        </mat-select>                          
                                    </mat-form-field>
                                </div>
    <!--                        
                            </div>
                            <div class="flex-row row">
    -->
                                <div class="col-3" style="margin-bottom: 10px;">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'paramType' | translate }}</mat-label>
                                        <mat-select id="equipType" placeholder="{{ 'Choose paramType' | translate }}" formControlName="paramType" 
                                                panelClass="mdiselectcontent"   ngDefaultControl required >
                                            <mat-option class="selectOptionsTheme" *ngFor="let paramTypeCur of paramTypeList" 
                                                [value]="paramTypeCur.value"  >{{paramTypeCur.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> 
                                <div class="col-3">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'brandRef' | translate }}</mat-label>
                                        <input id="brandRef" class="form-control inputTheme" formControlName="brandRef" 
                                                        maxlength="25" matInput #brandRef placeholder="{{ 'Ex: X;8 Ex: X|8' | translate }}"
                                        >
                                    </mat-form-field>
                                </div>  
                                <!--
                                <div class="col-3">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'partsSuppliesLabel' | translate }}</mat-label>
                                        <input id="partsSuppliesLabel" class="form-control inputTheme" formControlName="partsSuppliesLabel" 
                                                        maxlength="25" matInput #brandRef placeholder="{{ 'partsSuppliesLabel' | translate }}"
                                        >
                                    </mat-form-field>
                                </div> 
                                -->
                                
                                <div class="col-3" style="padding-top: 30px;">
                                    <div class="float-right">
                                        <button *ngIf="partSupplieSearchForm.valid" mat-raised-button  #buttonSubmit type="submit" (click)="searchReferences()" >
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div> 
                            
                            </div>
                        </form>
                        <fieldset style="margin-left: 10%;padding-top: 30px;width: 70%;">
                            <legend style="width: 40%">{{ 'partsSuppliesFounded' | translate }}</legend>
                            <div class="flex-row row" style="width: 98%;margin-left: 0px;">

                                <table class="listAction stdTheme" id="actionsSelected" style="padding: 5px" >
                                    <thead class="theadTaskRef" style="margin-left: 5px; margin-right: 5px;">
                                        <tr class="thTaskRef">
                                            <th [ngStyle]="{'width':  '50%', 'padding': '2px'}">
                                            {{'suppliesLabel' | translate}}
                                            </th>
                                            <th style="width: 10%; padding: 2px">{{'brandRef' | translate}}</th>
                                            <th style="width: 10%; padding: 2px">{{'billingUnit' | translate}}</th>
                                            <th style="width: 5%; padding: 2px" >#</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbodyRefSearch" style="margin-left: 5px; margin-right: 5px;">
                                        <tr class="trTaskRef curAction curActionDisplayed" *ngFor="let suppliesCur of paramPartsSuppliesList; let i = index" >

                                            <td  [ngStyle]="{'width': '50%', 'padding': '2px', 'text-align': 'left'}">
                                                {{suppliesCur.partsSuppliesLabel}}
                                            </td>
                                            <td style="width: 10%; padding: 2px; text-align: left;">
                                                {{suppliesCur.brandRef}}
                                            </td>
                                            <td style="width: 10%; padding: 2px; text-align: center;">
                                                {{suppliesCur.paramUnit}}
                                            </td>
                                        
                                            <td style="width: 5%; padding: 2px" >
                                                <button *ngIf="!suppliesCur.isSelected" mat-icon-button (click)="addSupplies(i)">
                                                    <mat-icon>add_circle_outline</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                        <fieldset style="margin-left: 10%;padding-top: 30px;width: 70%;">
                            <legend style="width: 40%">{{ 'partsSuppliesSelected' | translate }}</legend>
                            <div class="flex-row row" style="width: 98%;margin-left: 0px;">

                                <table class="listAction stdTheme" id="actionsSelected" style="padding: 5px" >
                                    <thead class="theadTaskRef" style="margin-left: 5px; margin-right: 5px;">
                                        <tr class="thTaskRef">
                                            <th [ngStyle]="{'width':  '50%', 'padding': '2px'}">
                                            {{'suppliesLabel' | translate}}
                                            </th>
                                            <th style="width: 10%; padding: 2px">{{'brandRef' | translate}}</th>
                                            <th style="width: 10%; padding: 2px">{{'billingUnit' | translate}}</th>
                                            <th style="width: 5%; padding: 2px" >#</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbodyRefSearch" style="margin-left: 5px; margin-right: 5px;">
                                        <tr class="trTaskRef curAction curActionDisplayed" *ngFor="let suppliesCur of paramPartsSuppliesSelected; let i = index" >

                                            <td  [ngStyle]="{'width': '50%', 'padding': '2px', 'text-align': 'left'}">
                                                {{suppliesCur.partsSuppliesLabel}}
                                            </td>
                                            <td style="width: 10%; padding: 2px; text-align: left;">
                                                {{suppliesCur.brandRef}}
                                            </td>
                                            <td style="width: 10%; padding: 2px; text-align: center;">
                                                {{ suppliesCur.paramUnit | translate }}
                                            </td>
                                        
                                            <td style="width: 5%; padding: 2px" >
                                                <button mat-icon-button (click)="removeSupplies(i)">
                                                    <mat-icon>remove_circle_outline</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
 
                    </mat-card-content>
                    <mat-card-actions style="margin-bottom: 10px;padding-bottom: 0px;margin-top: -15px;">
                        <div class="container-fluid d-flex justify-content-between" >
                            <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'close' | translate }}
                            </button>
                            <button *ngIf="isSelectedParts" mat-raised-button  #buttonSubmit type="submit" (click)="addPartSupplie()" >
                                <mat-icon>add_to_queue</mat-icon>
                                {{ 'addPartSupplieList' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>

</div>
