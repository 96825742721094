import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { MenuComponent } from './auth/menu/menu.component';
import { JobHomeComponent } from './job/job-home/job-home.component';
import { UsersTableComponent } from './organization/users/users-table/users-table.component';
import { UpdatePwdComponent } from './organization/users/update-pwd/update-pwd.component';
import { EntitiesTableComponent } from './organization/entities/entities-table/entities-table.component';
import { UsersgrpTableComponent } from './organization/usersgrp/usersgrp-table/usersgrp-table.component';
import { DatasourcesComponent } from './job/job-data/components/datasources/datasources-table/datasources.component';
import { RequestsTableComponent } from './job/job-data/components/requests/requests-table/requests-table.component';
import { ReportsTableComponent } from './job/job-data/components/reports/reports-table/reports-table.component';
import { JobReportHomeComponent } from './job/job-data/components/reports/jobreporthome/jobreporthome.component';
// CalendarComponent
import { CalendarComponent } from './job/common/components/calendar-agl/calendar/calendar.component';
// WKS
import { WksEntityComponent } from './job/job-wks/components/param/wks-entity/wks-entity.component';
import { BnsTableComponent } from './job/job-wks/components/repositories/bns/bns-table/bns-table.component';
import { EquipmentTableComponent } from './job/job-wks/components/repositories/equipment/equipment-table/equipment-table.component';
import { DocsTableComponent } from './job/job-wks/components/repositories/docs/docs-table/docs-table.component';
import { AnchoragesTableComponent } from './job/job-wks/components/repositories/anchorages/anchorages-table/anchorages-table.component';
import { WorkratesTableComponent } from './job/job-wks/components/param/work-rates/workrates-table/workrates-table.component';
import { ActionsrefTableComponent } from './job/job-wks/components/param/actionsref/actionsref-table/actionsref-table.component';
import { TasksrefTableComponent } from './job/job-wks/components/param/tasksref/tasksref-table/tasksref-table.component';
import { WorksTableComponent } from './job/job-wks/components/works/works-jobs/works-table/works-table.component';
import { AppointmentsTableComponent } from './job/job-wks/components/works/appointments-follow/appointments-table/appointments-table.component';
import { AppointmentsPlanningComponent } from './job/job-wks/components/works/appointments-planning/appointments-planning.component';
import { PlanningHrComponent } from './job/job-wks/components/param/planning-hr/planning-hr.component';
import { PartsSuppliesTableComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-table/parts-supplies-table.component';
import { WorksAssignmentsComponent } from './job/job-wks/components/works/works-jobs/works-assignments/works-assignments.component';
import { WorksInvoicingComponent } from './job/job-wks/components/works/works-jobs/works-invoicing/works-invoicing.component';
import { SchedulerTableComponent } from './organization/scheduler/scheduler-table/scheduler-table.component';
import { ApplicationTestComponent } from './organization/application/application-test/application-test.component';
import { ReloadConfigComponent } from './organization/application/reload-config/reload-config.component';
import { ManufacturersSpecificationsComponent } from './job/job-wks/components/others/manufacturers-specifications/manufacturers-specifications.component';
const ɵ0 = [{ itemOption: 'admin' }], ɵ1 = [{ itemOption: 'request' }], ɵ2 = [{ itemOption: 'accessData' }], ɵ3 = [{ itemOption: 'labelData' }], ɵ4 = [{ itemOption: 'reports' }], ɵ5 = [{ itemOption: 'dataDisplay' }], ɵ6 = [{ itemOption: 'dataAttributes' }], ɵ7 = [{ itemOption: 'validation' }], ɵ8 = [{ itemOption: 'menu' }], ɵ9 = [{ itemOption: 'principal' }], ɵ10 = [{ itemOption: 'principal' }], ɵ11 = [{ itemOption: 'wks-customers' }], ɵ12 = [{ itemOption: 'wks-customers' }], ɵ13 = [{ itemOption: 'wks-subcontractors' }], ɵ14 = [{ itemOption: 'wks-suppliers' }], ɵ15 = [{ itemOption: 'boats' }], ɵ16 = [{ itemOption: 'boats' }], ɵ17 = [{ itemOption: 'jets' }], ɵ18 = [{ itemOption: 'jets' }], ɵ19 = [{ itemOption: 'trailers' }], ɵ20 = [{ itemOption: 'trailers' }], ɵ21 = [{ itemOption: 'engines' }], ɵ22 = [{ itemOption: 'engines' }], ɵ23 = [{ itemOption: 'list' }], ɵ24 = [{ itemOption: 'list' }], ɵ25 = [{ itemOption: 'list' }], ɵ26 = [{ itemOption: 'list' }], ɵ27 = [{ itemOption: 'list' }], ɵ28 = [{ itemOption: 'list' }], ɵ29 = [{ itemOption: 'list' }], ɵ30 = [{ itemOption: 'list' }], ɵ31 = [{ itemOption: 'list' }], ɵ32 = [{ itemOption: 'list' }], ɵ33 = [{ itemOption: 'list' }], ɵ34 = [{ itemOption: 'list' }], ɵ35 = [{ itemOption: 'list' }], ɵ36 = [{ itemOption: 'list' }], ɵ37 = [{ itemOption: 'list' }], ɵ38 = [{ itemOption: 'list' }];
/*
exemple routing args
    { path: 'userList/:id/:username/:firstname/:lastname', component: UsersTableComponent },
settings :
            this.router.navigate(['/userList',
                          this.userLogged.id,
                          this.userLogged.username,
                          this.userLogged.firstName,
                          this.userLogged.lastName
                        ]);
getting :
        @Input() userLogged: UserLoggedImpl;
ou bien :
  constructor(private _currentRoute: ActivatedRoute) {
                const userLoggedLocal = _currentRoute.snapshot.paramMap;
                if (userLoggedLocal) {
                  this.userLogged = new UserLogged();
                  this.userLogged.id = _currentRoute.snapshot.params['id'];
                  this.userLogged.username = _currentRoute.snapshot.params['username'];
                  this.userLogged.firstName = _currentRoute.snapshot.params['firstname'];
                  this.userLogged.lastName = _currentRoute.snapshot.params['lastname'];
                }
              }
*/
export const ROUTES = [
    { path: 'app', component: AppComponent },
    { path: '', component: HomeComponent },
    { path: 'menu', component: MenuComponent },
    { path: 'userList', component: UsersTableComponent },
    { path: 'grpList', component: UsersgrpTableComponent },
    { path: 'schedulerList', component: SchedulerTableComponent },
    { path: 'testAppli', component: ApplicationTestComponent },
    { path: 'reloadConfig', component: ReloadConfigComponent },
    { path: 'entities', component: EntitiesTableComponent, data: ɵ0 },
    { path: 'login', component: LoginComponent },
    { path: 'jobHome', component: JobHomeComponent },
    { path: 'jobReportHome', component: JobReportHomeComponent },
    { path: 'updatePwd', component: UpdatePwdComponent },
    { path: 'datasources', component: DatasourcesComponent },
    { path: 'requests', component: RequestsTableComponent, data: ɵ1 },
    { path: 'accessData', component: RequestsTableComponent, data: ɵ2 },
    { path: 'labelData', component: RequestsTableComponent, data: ɵ3 },
    { path: 'reports', component: ReportsTableComponent, data: ɵ4 },
    { path: 'dataDisplay', component: ReportsTableComponent, data: ɵ5 },
    { path: 'dataAttributes', component: ReportsTableComponent, data: ɵ6 },
    { path: 'validationReport', component: ReportsTableComponent, data: ɵ7 },
    { path: 'entitiesMenu', component: EntitiesTableComponent, data: ɵ8 },
    // WKS
    { path: 'wksEntity', component: WksEntityComponent, data: ɵ9 },
    { path: 'wksManufacturersSpecifications', component: ManufacturersSpecificationsComponent, data: ɵ10 },
    { path: 'bnsTableCustomers', component: BnsTableComponent, data: ɵ11 },
    { path: 'bnsTableCustomers/:display', component: BnsTableComponent, data: ɵ12 },
    { path: 'bnsTableSubcontractors', component: BnsTableComponent, data: ɵ13 },
    { path: 'bnsTableSuppliers', component: BnsTableComponent, data: ɵ14 },
    { path: 'equipboats', component: EquipmentTableComponent, data: ɵ15 },
    { path: 'equipboats/:display', component: EquipmentTableComponent, data: ɵ16 },
    { path: 'equipjets', component: EquipmentTableComponent, data: ɵ17 },
    { path: 'equipjets/:display', component: EquipmentTableComponent, data: ɵ18 },
    { path: 'equiptrailers', component: EquipmentTableComponent, data: ɵ19 },
    { path: 'equiptrailers/:display', component: EquipmentTableComponent, data: ɵ20 },
    { path: 'equipengines', component: EquipmentTableComponent, data: ɵ21 },
    { path: 'equipengines/:display', component: EquipmentTableComponent, data: ɵ22 },
    { path: 'docstable', component: DocsTableComponent, data: ɵ23 },
    { path: 'docstable/:display', component: DocsTableComponent, data: ɵ24 },
    { path: 'anchoragestable', component: AnchoragesTableComponent, data: ɵ25 },
    { path: 'anchoragestable/:display', component: AnchoragesTableComponent, data: ɵ26 },
    { path: 'docstable/:display', component: DocsTableComponent, data: ɵ27 },
    { path: 'param_worksrates', component: WorkratesTableComponent, data: ɵ28 },
    { path: 'param_partssupplies', component: PartsSuppliesTableComponent, data: ɵ29 },
    { path: 'param_actionsref', component: ActionsrefTableComponent, data: ɵ30 },
    { path: 'param_tasksref', component: TasksrefTableComponent, data: ɵ31 },
    { path: 'param_rh_planning', component: PlanningHrComponent, data: ɵ32 },
    /*{ path: 'jobsWorks/:display', component: WorksTableComponent, data: [{itemOption: 'list'}]},*/
    { path: 'jobsWorks/:display', component: WorksTableComponent, data: ɵ33 },
    { path: 'jobsAssignments/:display', component: WorksAssignmentsComponent, data: ɵ34 },
    /*{ path: 'jobsAppointments/:display', component: AppointmentsTableComponent, data: [{itemOption: 'list'}]},*/
    { path: 'jobsAppointments', component: AppointmentsTableComponent, data: ɵ35 },
    { path: 'jobsCalendar', component: CalendarComponent, data: ɵ36 },
    { path: 'jobsAppointmentsPlanning', component: AppointmentsPlanningComponent, data: ɵ37 },
    // WorksInvoicesComponent
    { path: 'jobsInvoicing/:display', component: WorksInvoicingComponent, data: ɵ38 },
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38 };
