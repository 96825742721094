/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./datatable-pager.component";
var styles_DataTablePagerComponent = [];
var RenderType_DataTablePagerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataTablePagerComponent, data: {} });
export { RenderType_DataTablePagerComponent as RenderType_DataTablePagerComponent };
function View_DataTablePagerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "pages"]], [[2, "active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "stdTheme"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPage(_v.context.$implicit.number) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.number === _co.page); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_1); }); }
export function View_DataTablePagerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "ul", [["class", "pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [], [[2, "disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "stdTheme"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["style", "color: var(--foreground-primary)!important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "li", [], [[2, "disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["style", "color: var(--foreground-primary)!important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataTablePagerComponent_1)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "li", [], [[2, "disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["style", "color: var(--foreground-primary)!important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "li", [], [[2, "disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPage(_co.totalPages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["style", "color: var(--foreground-primary)!important;"]], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.pages; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canPrevious(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.pagerPreviousIcon, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.canPrevious(); _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.pagerLeftArrowIcon, ""); _ck(_v, 6, 0, currVal_3); var currVal_5 = !_co.canNext(); _ck(_v, 9, 0, currVal_5); var currVal_6 = i0.ɵinlineInterpolate(1, "", _co.pagerRightArrowIcon, ""); _ck(_v, 11, 0, currVal_6); var currVal_7 = !_co.canNext(); _ck(_v, 12, 0, currVal_7); var currVal_8 = i0.ɵinlineInterpolate(1, "", _co.pagerNextIcon, ""); _ck(_v, 14, 0, currVal_8); }); }
export function View_DataTablePagerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mdi-datatable-pager", [["class", "datatable-pager"]], null, null, null, View_DataTablePagerComponent_0, RenderType_DataTablePagerComponent)), i0.ɵdid(1, 49152, null, 0, i2.DataTablePagerComponent, [], null, null)], null, null); }
var DataTablePagerComponentNgFactory = i0.ɵccf("mdi-datatable-pager", i2.DataTablePagerComponent, View_DataTablePagerComponent_Host_0, { pagerLeftArrowIcon: "pagerLeftArrowIcon", pagerRightArrowIcon: "pagerRightArrowIcon", pagerPreviousIcon: "pagerPreviousIcon", pagerNextIcon: "pagerNextIcon", size: "size", count: "count", page: "page", visiblePagesCount: "visiblePagesCount" }, { change: "change" }, []);
export { DataTablePagerComponentNgFactory as DataTablePagerComponentNgFactory };
