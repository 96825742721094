<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span *ngIf="optionItem === 'reports'" style="margin-left: 45%; font-size:large">{{ 'Reports' | translate }}</span>
      <span *ngIf="optionItem === 'validation'" style="margin-left: 45%; font-size:large">{{ 'Reports validation' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <mat-sidenav-container fxFlexFill class="container-sidenav w-100">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav">
          <div fxLayout="column" *ngIf="isRegisterReport">
            <a (click)="toggleSidenav('')" *ngIf="doCreateReport" mat-raised-button  style="font-size: 12px">{{'Creation' | translate}}</a>
            <a (click)="toggleSidenav('')" *ngIf="!doCreateReport" mat-raised-button  style="font-size: 12px">{{'Update' | translate}}</a>
            <a>
              <mdi-report-sheets [statutCall]="statutRegister" [reportToUpdate]="reportToUpdate" (onload)="ngOnInit()"></mdi-report-sheets>
            </a>
          </div>

      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">

        <div class="container-fluid ">
          <div class="flex-row row stdTheme w-100">
            <div class="col-4 d-flex justify-content-start align-items-center" >
              <input type="text" matInput id="filter-text-box" 
                style="height: 50px;" 
                placeholder="{{ 'global filter' | translate }}" 
                (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch/>
            </div>
            <div class="col-5 d-flex justify-content-start align-items-center  "  >
             
            </div>
             <div class="col-3 d-flex justify-content-end align-items-center">
              <button *ngIf="optionItem === 'reports'" mat-icon-button class="buttonTheme" 
                style="margin-left: 10px;margin-right: 10px;" (click)="addReport()">
                <span class="fa fa-plus-square" style="font-size: 20px" ></span>
              </button>
              <button mat-icon-button class="buttonTheme" 
                 style="margin-left: 10px;margin-right: 10px;" (click)="refresh()">
                <span class="fas fa-redo" style="font-size: 20px" ></span>
              </button>
            </div>
          </div>
          <div>
            <ag-grid-angular 
              style="width: 100%; height: 600px;" 
              class="ag-theme-material"
              [gridOptions]="gridOptions"
              [groupMultiAutoColumn] = "true"
              [groupHideOpenParents]="true"
              [rowData]="dataSource.data"
              [pagination]="true"
              paginationPageSize=10
              [autoGroupColumnDef]="autoGroupColumnDef"
              [suppressDragLeaveHidesColumns]="true"
              [suppressMakeColumnVisibleAfterUnGroup]="true"
              [rowGroupPanelShow]="rowGroupPanelShow"
              (selectionChanged)="onSelectionChanged($event)"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)"
              [defaultColDef]="defaultColDef"
              >
            </ag-grid-angular>
          </div>
 
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<!--https://valor-software.com/ngx-bootstrap/#/modals#confirm-window-->
<ng-template #modalTestReport>
  <!--
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{'testReport' | translate : modalText[0]}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    -->
    <div class="modal-body">
      <div *ngIf="!isReportDoc && !isDashboard">
          <mdi-report-grid [titleReport]="modalText[0]" [statutCall]="'testReport'" [reportToExecute]="reportToUpdate" (onload)="ngOnInit()"></mdi-report-grid>
      </div>
      <div *ngIf="isReportDoc && !isDashboard">
        <mdi-display-pdf [titleReport]="modalText[0]" [statutCall]="'testReport'" [reportToExecute]="reportToUpdate" (onload)="ngOnInit()"></mdi-display-pdf>
      </div>
      <div *ngIf="isDashboard">
        <div  style="width: 750px; height: 75vh">
          <mdi-widget-grid-ngx [titleReport]="modalText[0]" [statutCall]="'testReport'" [reportToExecute]="reportToUpdate" (onload)="ngOnInit()"></mdi-widget-grid-ngx>
        </div>
      </div>
    </div>
    <div class="modal-footer ">
      <!--<p>{{modalText}}</p>-->
      <div class="container-fluid d-flex justify-content-between" style="margin:0rem; padding: 0rem">

          <button *ngIf="optionItem === 'validation'" type="button" mat-raised-button class="btn btn-success" (click)="acceptRepotModal()" >{{'reportAccept' | translate}}</button>
          <button *ngIf="optionItem === 'validation'" type="button" mat-raised-button class="btn btn-danger" (click)="rejectReportModal()" >{{'reportReject' | translate}}</button>
          <button type="button" mat-raised-button class="btn btn-primary" (click)="closeModal()" >{{'Close' | translate}}</button>

      </div>
    </div>
  
</ng-template>