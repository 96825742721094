
<!--<div class="container-fluid stdTheme " style="padding: 0px; margin: 0px;" -->
<mat-card class="stdTheme" style="padding: 0px!important;max-height: 60%;">
  <mat-card-content >
    <div [@openClose]="isDetailOpen ? 'open' : 'closed'" >
      <p *ngIf="!isDetailOpen" (click)="toggleDetail('add')" style="text-align: right;">
        {{'addingStatut' | translate}}
        <button mat-icon-button class="buttonTheme" 
            style="margin-left: 10px;margin-right: 10px;" (click)="toggleDetail('add')">
          <span class="fa fa-plus-square" style="font-size: 20px" ></span>
        </button>
      </p>
      <div  *ngIf="isDetailOpen" >
        <div *ngIf="addOption">
          <ng-container [ngTemplateOutlet]="trackingDetail" ></ng-container> 
        </div>
        <div *ngIf="editOption">
          <ng-container [ngTemplateOutlet]="trackingDetail" ></ng-container> 
        </div>
      </div>
    </div>
    <!--<div *ngIf="dataLoaded" class="flex-row row stdTheme" style="margin: 0px;">-->
      <div *ngIf="dataLoaded" class="col-12 table-responsive" style="padding: 0px; margin: 0px;max-height: 60%;">
        <table class="table stdTheme" id="trackingWorksList"  >
          <thead class="theadWorkTrack">
            <tr class="trWorkTrack">
              <th style="width: 5%; padding: 0px; text-align: center;">#</th>
              <th style="width: 2%; padding: 0px; text-align: center;" >{{'numberTrack' | translate}}</th>
              <th style="width: 20%; padding: 0px; text-align: center;" >{{'recordedTrack' | translate}}</th>
              <th style="width: 20%; padding: 0px; text-align: center;">{{'userLogged' | translate}}</th>
              <th style="width: 25%; padding: 0px; text-align: center;">{{'statut' | translate}}</th>
              <th style="width: 5%; padding: 0px; text-align: center;">#</th>
            </tr>
          </thead>
          <tbody class="tbodyWorkTrack" >
            
              <tr *ngFor="let trackingCur of trackingList; let i = index" class="trWork " [class.data-selected]="i === selectedRow" 
                    [class.inactiveRow]="!trackingCur.trackingActive" (click)="setClickedRow(i)" style="width: 100%!important">
                <td style="width: 5%; padding: 0px; text-align: center;">
                  <button mat-icon-button class="buttonTheme" *ngIf="trackingCur.numberTrack > 0 && trackingCur.trackingActive && !isDetailOpen"  
                            style="width: 20px;height: 20px;margin-top: -10px;">
                    <span class="fa fa-trash" style="font-size: 20px!important" (click)="toggleDetail('disable', i)"></span>
                  </button>
                </td>       
                <td style="width: 2%; padding: 0px; text-align: center; vertical-align: middle; " >{{trackingCur.numberTrack + 1 }}</td>
                <td style="width: 20%; padding: 0px; text-align: center; vertical-align: middle; ">{{getDateTracking(trackingCur.creationDate)}}</td>
                <td style="width: 20%; padding: 0px; text-align: center; vertical-align: middle; ">{{trackingCur.creationUser }}</td>
                <td style="width: 25%; padding: 0px; text-align: center; vertical-align: middle; ">{{trackingCur.statut | translate }}</td>
                <td style="width: 5%; padding: 0px; text-align: center;">
                  <button mat-icon-button class="buttonTheme" *ngIf="trackingCur.numberTrack > 0  && !isDetailOpen" 
                          style="width: 20px;height: 20px;margin-top: -10px;">
                    <span class="fa fa-edit" style="font-size: 20px!important" (click)="toggleDetail('edit', i)"></span>
                  </button>
                </td>
              </tr>
          
          </tbody>
        </table>
      </div>
    <!--</div>-->
  </mat-card-content>
</mat-card>
<!--</div>  -->

<ng-template #trackingDetail  >
  <!--<p (click)="toggleDetail()" >Statut is now add</p>-->
  <div *ngIf="statutsLoad"  class="container-fluid stdTheme " style="padding: 0px; margin: 0px;" >
    <mat-card class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px">

      <mat-card-content class="stdTheme">
        <form [formGroup]="statutForm" >
          <div class="flex-row row" style= "margin: 0px;">
            <div class="col-5" >
                <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-select id="trackingStatut" #trackingStatut formControlName="trackingStatut" 
                            panelClass="mdiselectcontent" ngDefaultControl
                            (selectionChange)="onChangeStatut(trackingStatut.value)" >
                        <mat-option class="selectOptionsTheme" *ngFor="let statutCur of statutsList" 
                            [value]="statutCur.value"  >{{statutCur.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              </div> 
            </div>
            <div *ngFor="let validationField of validatingFields; let i = index">
              <div class="flex-row row" style= "margin: 0px;">
                <div class="col-5" >
                  <mat-form-field *ngIf="validationField.fieldType === 'text'" class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ validationField.fieldName | translate }}</mat-label>
                    <input matInput class="mdiinput form-group stdTheme" type="text" #{{validationField.fieldName}} id="{{validationField.fieldName}}" 
                              formControlName="{{validationField.fieldName}}" required (change)="controlFields();"
                    >
                  </mat-form-field>
                  <mat-form-field *ngIf="validationField.fieldType === 'date'" class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ validationField.fieldName | translate }}</mat-label>
                    <input matInput  class="form-control inputTheme" 
                                  formControlName="{{validationField.fieldName}}"
                                  #dateInput 
                                  placeholder="{{ validationField.fieldName | translate }}" disabled
                                  [max]="maxInputDate" 
                                  [min]="minInputDate"  
                                  [matDatepickerFilter]="filterDate"
                                  [matDatepicker]="startPicker" 
                                  (dateChange)="validStatutDate($event, validationField.fieldName);controlFields();" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker name="startPicker" #startPicker disabled="false">
                    </mat-datepicker>
                  </mat-form-field>
                  <!-- https://www.npmjs.com/package/ngx-material-timepicker -->
                  <mat-form-field *ngIf="validationField.fieldType === 'time'" class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ validationField.fieldName | translate }}</mat-label>
                    <input matInput [ngxTimepicker]="toggleTimepicker" [disableClick]="true" readonly required
                            formControlName="{{validationField.fieldName}}"  [format]="24" [min]="'6:00'" [max]="'18:00'" [value]="validateTime" >
                    <!--<ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker"></ngx-material-timepicker-toggle>-->
                    <!--
                    <mat-datepicker-toggle matSuffix [for]="toggleTimepicker">
                      <mat-icon matDatepickerToggleIcon style="margin-top: 20px;" class="stdTheme">access_time</mat-icon>
                    </mat-datepicker-toggle>
                    -->
                    <!-- https://github.com/h2qutc/angular-material-components/issues/170 -->
                    <button mat-icon-button matSuffix (click)="toggleTimepicker.open(); $event.stopPropagation();">
                      <mat-icon>access_time</mat-icon>
                    </button>
                    <ngx-material-timepicker  (timeChanged)="changeTime($event); controlFields();" #toggleTimepicker></ngx-material-timepicker>
                  </mat-form-field>
                  <div *ngIf="validationField.fieldType === 'list' && validationField.fieldList === 'MECHANIC'">
                    <mat-form-field  class="mdiinput form-group stdTheme" appearance="standard">
                      <mat-label>{{ validationField.fieldName | translate }}</mat-label>
                      <mat-select class="stdTheme"  panelClass="panelSelectContent" #{{validationField.fieldName}}  
                                disableOptionCentering required
                                id="{{validationField.fieldName}}"
                                required formControlName="{{validationField.fieldName}}" 
                                (selectionChange)="onChangeMechanic($event); controlFields();" >
                        <mat-option class="stdTheme stdSelect" *ngFor="let userMechanic of mechanicUsers" 
                                class="selectOptionsTheme"  [value]="userMechanic.username" >
                                      {{userMechanic.username }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                  </div> 

                </div>
              </div>
              <div >
                <div class="flex-row row" style= "margin: 0px;padding-bottom: 10px;"></div>
              </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-action>
        <div class="container-fluid d-flex justify-content-between" style="margin-bottom: 10px;">
            <button mat-raised-button  #buttonCancel type="reset" (click)="doCancelTrack()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'cancel' | translate }}
            </button>
            <button mat-raised-button  [disabled]="statutForm.invalid || !doRegisterTrack" #buttonSubmit type="submit" (click)="registerTrack()" >
                <mat-icon>save</mat-icon>
                {{ 'save' | translate }}
            </button>
        </div>
      </mat-card-action>
    </mat-card>
  </div>
</ng-template>
