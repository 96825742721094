import { Component, EventEmitter, SimpleChanges, OnChanges, Input, ViewEncapsulation, Output, ViewChild } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { environment } from '../../../../../../../../environments/environment';
import { CommonMethods } from '../../../../../../common/tools/commonMethods';
import { TranslateService } from '../../../../../../../services/translate.service';
import { WksCentralService } from '../../../../../services/wks-central.service';
import { UserService } from '../../../../../../../services/user.service';
import { RessourcesService } from '../../../../../../../job/common/services/ressources.service';

import { GridTools } from '../../../../../../../job/common/tools/gridTools';
import { UserResponse } from '../../../../../../../models/user.model';
import { WksEntityParams, Statuts, ValidatingField } from '../../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../../models/wks-entity.model';
import { LabelValue } from '../../../../../../../models/data.model';
import { AppointmentModel, AppointmentTracking } from '../../../../../models/wks-appointments.model';
import { InternationalsFormats } from '../../../../../../../models/data.model';

@Component({
  selector: 'mdi-appointment-tracking',
  templateUrl: './appointment-tracking.component.html',
  styleUrls: ['./appointment-tracking.component.css'],
  // https://nicolasfazio.ch/programmation/animation-angular-introduction
  animations: [
    trigger('openClose', [
      state('open', style({
        'min-height': '200px',
        // opacity: 1,
        // backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '35px',
        // opacity: 0.5,
        // backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppointmentTrackingComponent implements OnChanges {

  @Input() appointment: AppointmentModel;
  @Input() wksEntity: WksEntityModel;
  @Input() wksEntityParams: WksEntityParams; 
  @Input() mechanicUsers: UserResponse[];

  @Output() dataOut = new EventEmitter<any>();

  internationalsFormats: InternationalsFormats;
  statutsList: LabelValue[];
  trackingList: AppointmentTracking[];
  trackingTemp: AppointmentTracking[];
  trackingCur: AppointmentTracking;
  statutsParams: Statuts[];
  dataLoaded: boolean;
  selectedRow: number;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  isDetailOpen = false;
  addOption: boolean;
  editOption: boolean;

  // tracking detail
  statutForm: FormGroup;
  doRegisterTrack: boolean;
  isReadOnly: boolean;
  validatingFields: ValidatingField[];
  updatestatut: string;
  minAppointmentDate: any;
  maxAppointmentDate: any;
  minInputDate: any;
  maxInputDate: any;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  doAddingTrack: boolean;
  doDisableTrack: boolean;
  doUpdateTrack: boolean;
  rowSelected: number;
  doInitTracking: boolean;
  statutsLoad: boolean;
  validateTime: any;
  daysWeek: number[];

  constructor( private translate: TranslateService, 
              private adapterDate: DateAdapter<any>,
              private fb: FormBuilder,
              private ressourcesService: RessourcesService,
              private wksCentralService: WksCentralService,
              private _userService: UserService) {
  }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'appointment': {
            this.appointment = changes['appointment'].currentValue;
            if (this.appointment === undefined)  {
              break;
            }
            if (this.appointment.appointmentTracking === undefined) {
              this.trackingList = [];
            } else  {
              this.trackingList = this.appointment.appointmentTracking;
            }
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'wksEntityParams': {
            this.wksEntityParams = changes['wksEntityParams'].currentValue;
            break;
          }
          case 'mechanicUsers': {
            this.mechanicUsers = changes['mechanicUsers'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.daysWeek = [0, 6];
    this.initData();
  }
  filterDate = (d: Date): boolean => {
    // console.log('Filter is called');
    const day = moment(d).day();
    // const day = d.getDay();
    return day !== this.daysWeek[0] && day !== this.daysWeek[1];
    // return true;
    // 0 means sunday
    // 6 means saturday
  }
  initData(): void {

    this.dataLoaded = false;
    this.addOption = false;
    this.editOption = false;
    this.doRegisterTrack = false;
    this.isReadOnly = true;
    this.statutsList = [];
    this.statutsParams = [];
    this.statutsLoad = false;
    this.minAppointmentDate = moment({year: this.year, month: this.month , day: this.day});
    this.maxAppointmentDate = moment({year: this.year, month: this.month + 3, day: this.day});
    this.minInputDate = moment({year: this.year, month: this.month - 1 , day: this.day});
    this.maxInputDate = moment({year: this.year, month: this.month + 2, day: this.day});
    if (this.wksEntityParams.statuts === undefined) {
      return;
    }
    this.doInitTracking = false;
    if ((this.trackingList === null) || (this.trackingList.length === 0)) {
      this.trackingList = [];
      // const dateCreat = CommonMethods.dateMomentForBdd(dateFinal, undefined);
      // console.log(dateCreat);
      const trackingCur = {
        stdEntity: this.appointment.stdEntity,
        appointmentId: this.appointment.id,
        statut: 'quotation_waiting',
        creationUser: this._userService.getUserLogged().username,
        creationDate: undefined,
        trackingDetail: '',
        numberTrack: 0,
        trackingActive: true,
      };
      this.registerTracking('firstTrack', trackingCur);
      this.doInitTracking = true;
    }
    this.trackingList.sort((obj1: AppointmentTracking, obj2: AppointmentTracking) => {
      return obj1.numberTrack > obj2.numberTrack ? 1 : -1;
    });
    this.settingIntFmts();
    this.dataLoaded = true;
  }
  // Info : https://stackblitz.com/edit/multi-lang-dateformat
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this.ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
      }
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
  }
  getDateTracking(_value: string) {
    return GridTools.date_json1(_value, this.dateTimeFormat);
  }
  changeTime($event: any) {
    // console.log($event);
  }
  onChangeMechanic(event: { index: any, value: any }) {
    this.doRegisterTrack = true;
  }
  /*
  filterDate(): boolean  {
    console.log('filterDate');
    return true;
  }*/
  setClickedRow(row: number)  {

    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.trackingCur = undefined;
    } else {
      this.selectedRow = row;
      this.trackingCur = this.trackingList[row];
    }
  }
  toggleDetail(option: string, idxRow: number) {
    if (option !== 'disable') {
      this.isDetailOpen = !this.isDetailOpen;
    }
    this.addOption = false;
    this.editOption = false;
    this.doRegisterTrack = false;
    this.doAddingTrack = false;
    this.doDisableTrack = false;
    this.doUpdateTrack = false;
    this.rowSelected = idxRow;
    this.statutsLoad = false;
    const now = moment();
    this.validateTime = moment(now).format('HH:mm');
    if (this.isDetailOpen)  {
      if (option === 'add') {
        this.addOption = true;
        this.isReadOnly = false;
        this.doAddingTrack = true;
        this.buildStatutsList();
        this.statutsLoad = true;
        this.buildTrackingForm();

      }
      if (option === 'edit') {
        this.trackingCur = this.trackingList[this.rowSelected];
        this.editOption = true;
        this.isReadOnly = false;
        this.buildStatutsList();
        this.statutsLoad = true;
        this.buildTrackingForm();

        this.isReadOnly = this.trackingCur.trackingActive ? false : true;
        this.doUpdateTrack = true;
      }
    } else  {
      if (option === 'disable') {
        this.trackingCur = this.trackingList[this.rowSelected];
        if (this.trackingCur.creationDate === undefined) {
          this.trackingList.splice(this.rowSelected, 1);
        } else {
          this.trackingCur.trackingActive = false;
          this.doDisableTrack = true;
          this.registerTrack();
        }
      }
    }
  }
  // detail
  doCancelTrack(): any {
    this.isDetailOpen = false,
    this.doRegisterTrack = false;
    const dataReturn =  {
      statut: 'cancel'
    };
   return dataReturn;
  }
 
  registerTrack(): void {
    
    if (this.doAddingTrack)  {
      this.trackingCur.numberTrack = this.trackingList.length;
      this.trackingCur.trackingDetail = this.getValidationgFieldsValues();
      this.cacheTemp();
      this.trackingTemp.push(this.trackingCur);
      this.registerTracking('addTrack', this.trackingCur);

    }
    if (this.doUpdateTrack)  {
      this.trackingCur.trackingDetail = this.getValidationgFieldsValues();
      this.trackingList[this.rowSelected] = this.trackingCur;
      this.isDetailOpen = false;
      this.editOption = false;
      this.cacheTemp();
      this.registerTracking('updateTrack', this.trackingCur);
    }
    if (this.doDisableTrack)  {
      this.trackingList[this.rowSelected] = this.trackingCur;
      this.cacheTemp();
      this.registerTracking('disableTrack', this.trackingCur);
    }
    const lastTracking = this.getLastTracking();
    this.appointment.appointmentStatut = lastTracking.statut;
    if (this.trackingCur.statut === 'work_assigned') {
      this.appointment.workAssigned = this.statutForm.controls['workerName'].value;
    }
    if (this.trackingCur.statut === 'appointment_fixed') {
      // const dateInput = moment(this.statutForm.controls['appointmentDate'].value).format();
      const dateValue = new Date(this.statutForm.controls['appointmentDate'].value);
      // const dateInput = moment(dateValue).format(this.dateFormatMoment);
      // const dateInput = moment(dateValue).format(this.dateFormat);
      const valueCur = this.getBddFmtDateTime('Date', dateValue);
      this.appointment.appointmentFixed = valueCur + ' ' + this.statutForm.controls['appointmentTime'].value;
    }
    const dataReturn =  {
      statut: 'register',
      rowCard: this.appointment
    };
    this.dataOut.emit(dataReturn);
    
  }
  getValidationgFieldsValues(): string {
    let values = '';
    for (const validateField of this.validatingFields) {
      let valueCur = this.statutForm.controls[validateField.fieldName].value;
     
      if (values === '') {
        values = '{';
      } else  {
        values += ',';
      }
      if (validateField.fieldType === 'date') {
        const dateValue = new Date(valueCur);
        const dateInput = moment(dateValue).format(this.dateFormat);
        valueCur = this.getBddFmtDateTime('Date', dateValue);
        // console.log (validateField.fieldName + ' : ' + dateValue + ' : ' + dateInput + ' : ' + valueCur);
      }
      if (validateField.fieldType === 'time') {
        // const timeValue = new Date(valueCur);
        valueCur = this.getBddFmtDateTime('Hour', valueCur);
        /// console.log (validateField.fieldName + ' : ' + valueCur);
      }
      
      values += '"' + validateField.fieldName + '":' + '"' + valueCur + '"';
    }
    if (values !== '') {
      values += '}';
    }
    return values;
  }
  onChangeStatut(_value: any): void {
    // console.log('onChangeStatut : ' + _value);
    // this.statutForm.controls.trackingStatut.setValue(_value);
    this.trackingCur.statut = _value;
    for (const statutCur of this.statutsParams ) {
      if ( statutCur.name === _value) {
        this.getValidateFields(statutCur.validating);
        this.buildTrackingForm();
        break;
      }
    }
  }
  buildStatutsList(): void {
   
    let nextStatut: string;
    this.statutsList = [];
    this.statutsParams = JSON.parse(JSON.stringify(this.wksEntityParams.statuts));
    this.statutsParams.sort((obj1: Statuts, obj2: Statuts) => {
      return obj1.ranking > obj2.ranking ? 1 : -1;
    });
    this.cacheTemp();
    const lastTracking = this.getLastTracking();
    let rankCur = 0;
    for (const statutCur of this.statutsParams ) {
      if ((lastTracking.statut === statutCur.name) && (lastTracking.trackingActive)) {
        rankCur = statutCur.ranking;
        break;
      }
    }
    nextStatut = '';
    for (const statutCur of this.statutsParams ) {
      if ((statutCur.ranking <= rankCur) && (this.addOption)) {
        continue;
      }
      if ((this.trackingCur !== undefined) && 
        (statutCur.name === this.trackingCur.statut) && 
        (this.editOption)) {
        this.getValidateFields(statutCur.validating);
        this.statutsList.push({
          value: statutCur.name,
          label: this.translate.instant(statutCur.name)
        });
        continue;
      }
      if ((statutCur.ranking < rankCur) && (this.editOption)) {
        continue;
      }
      if ((nextStatut === '') && (this.addOption)) {
        nextStatut = statutCur.name;
        this.getValidateFields(statutCur.validating);
      }
      this.statutsList.push({
        value: statutCur.name,
        label: this.translate.instant(statutCur.name)
      });
    }
    if (this.addOption)  {
      this.buildAddTracking(nextStatut, lastTracking.numberTrack);
    }
  }
  buildTrackingForm(): void {
    this.doRegisterTrack = false;
    if (this.statutForm !== undefined) {
      const formElem = Object.keys(this.statutForm.controls);
      for (const elem of formElem) {
        this.statutForm.removeControl(elem);
        if (elem === 'trackingStatut') {
          this.statutForm.addControl('trackingStatut', new FormControl({value: '', disabled: this.isReadOnly}));
        }
      }
    } else {
      this.statutForm = this.fb.group({
        trackingStatut: this.fb.control({value: '', disabled: this.isReadOnly}),
        });
    }
    for (const validateField of this.validatingFields) {
      this.statutForm.addControl(validateField.fieldName, new FormControl({value: '', disabled: this.isReadOnly}));
    }
    this.fillTrackingForm();
  }
  buildAddTracking(statutArg: string, numberTrackArg: number): void {
    this.trackingCur = {
      appointmentId: this.appointment.id,
      stdEntity: this.appointment.stdEntity,
      statut: statutArg,
      creationUser: this._userService.getUserLogged().username,
      creationDate: undefined,
      trackingDetail: '',
      numberTrack: numberTrackArg + 1,
      trackingActive: true,
    };
  }
  getLastTracking(): AppointmentTracking {
    let lastTracking: AppointmentTracking;

    this.trackingTemp.sort((obj1: AppointmentTracking, obj2: AppointmentTracking) => {
    return obj1.numberTrack > obj2.numberTrack ? 1 : -1;
    });
    for (const trackingItem of this.trackingTemp ) {
      if (trackingItem.trackingActive) {
        lastTracking = trackingItem;
      }
    }
    return lastTracking;
  }
  fillTrackingForm(): void {
    // this.statutForm.controls.trackingStatut.setValue(this.trackingCur.statut);
    this.statutForm.controls['trackingStatut'].patchValue(this.trackingCur.statut, { emitEvent: true, onlySelf: false });
    let jsonData: any;
    try {
      jsonData = JSON.parse(this.trackingCur.trackingDetail);
    } catch (e) {
    }
    for (const validateField of this.validatingFields) {
      let dataValue = ((jsonData !== undefined) && (jsonData[validateField.fieldName])) ? jsonData[validateField.fieldName] : '';
      if (validateField.fieldType === 'date')  {
        if (dataValue !== '')  {
        // tslint:disable-next-line:radix
          // const dateValue = new Date(parseInt(dataValue));
          const dateValue = new Date(dataValue);
          dataValue = dateValue;
        } else  {
          const dateValue = new Date();
          dataValue = dateValue;
        }
      }
      if (validateField.fieldType === 'time')  {
        if (dataValue !== '') {
          this.validateTime = dataValue;
        } else {
          const now = moment();
          this.validateTime = moment(now).format('HH:mm');
          dataValue = this.validateTime;
        }
      }
      this.statutForm.controls[validateField.fieldName].patchValue(dataValue, { emitEvent: true, onlySelf: false });
    }
    if (this.validatingFields.length === 0) {
      this.doRegisterTrack = true;
    }
  }
  cacheTemp(): void {
    this.trackingTemp = [];
    this.trackingTemp = [...this.trackingList];
  }
  // ValidatingField[]
  getValidateFields(validatingArg: string[]): void {
    this.validatingFields = [];
    for (const validatingCur of validatingArg) {
      const validating = validatingCur.split(':');
      if (validating[0] === 'alone') {
        break;
      }
      this.validatingFields.push({
        fieldName: validating[0],
        fieldType: validating[1],
        fieldList: validating[1] === 'list' ? validating[2] : '',
      });
    }
  }
  validStatutDate(event: MatDatepickerInputEvent<Date>, fieldName: string) {
    // console.log('validStatutDate :' + event.value);
    // const currentDate = CommonMethods.dateMomentForBdd(event.value, undefined);
    // const formatDate = GridTools.date_json1(currentDate, this.dateFormat);
    // console.log(currentDate);
    const dateInput = moment(event.value);
    const okDate = dateInput.isBetween(this.minAppointmentDate, this.maxAppointmentDate);

     if (okDate) {
       this.statutForm.controls[fieldName].setValue(event.value);
       this.doRegisterTrack = true;
     } else {
      this.statutForm.controls[fieldName].setValue('');
     }
   
   }
   registerTracking(action: string, trackingItem: AppointmentTracking) {
    this.wksCentralService.saveWksAppointmentTracking(trackingItem, action)
    .subscribe(
      data => {
        trackingItem = data.body;
        // console.log('registerTracking ' + trackingItem + ' OK' );
        if ((action === 'addTrack') || (action === 'firstTrack')) {
          this.trackingList.push(trackingItem);
          this.isDetailOpen = false;
          this.addOption = false;
          this.appointment.appointmentTracking = this.trackingList;
        }

        // disableTrack
        if (action === 'disableTrack') {
          this.trackingList.splice(this.rowSelected, 1);
          this.appointment.appointmentTracking = this.trackingList;
        }
      },
      err => {
        console.log('registerTracking ' + trackingItem + ' KO ' + err );
      }
    );
   }
   getBddFmtDateTime(option: string, value: any): string {
     // const dateInput = moment(dateValue).format(this.dateFormat);
    let valueFmt: string;
    let bddFormat: string;
    switch (option) {
      case 'Date': {
        bddFormat = `${environment.fmtDateBdd}`;
        valueFmt = GridTools.date_to_string(value, bddFormat);
        break;
      }
      case 'Hour': {
        // bddFormat = `${environment.fmtHourBdd}`;
        // valueFmt = GridTools.hour_to_string(value, bddFormat);
        valueFmt = value;
        break;
      }
    }
    return valueFmt;
   }
   controlFields() {
     this.doRegisterTrack = false;
     for (const validateField of this.validatingFields) {
      let valueCur: any;
      valueCur = this.statutForm.controls[validateField.fieldName].value;
      if ((valueCur === undefined) || (valueCur === ''))  {
        return;
      }
     }
     this.doRegisterTrack = true;
   }
}
