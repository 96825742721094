import {Component, ViewChild, ViewContainerRef} from '@angular/core';

import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from 'ag-grid-community';
import {IFilterAngularComp} from 'ag-grid-angular';

import { JobDataService } from '../../../../../../job-data/services/job-data.service';

@Component({
  selector: 'mdi-filter-cell',
  templateUrl: './filter-cell.component.html', 
  styles: [
    `
        .container {
            border: 2px solid #22ff22;
            border-radius: 5px;
            background-color: #bbffbb;
            width: 150px;
            height: 30px
        }
        select {
          width: 100px;
          height: 20px
      }
        input {
            height: 20px
        }
    `
]
  // styles: ['./filter-cell.component.css']
  })
export class FilterCellComponent implements IFilterAngularComp {

  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text = '';
  listOptions: any[];
  colName: string;
  @ViewChild('input', {read: ViewContainerRef, static: false}) public input: any;
  @ViewChild('select', { read: ViewContainerRef, static: false }) public select: any;

  constructor (private _jobDataService: JobDataService) { }
  agInit(params: IFilterParams): void {
      this.params = params;
      this.valueGetter = params.valueGetter;
      this.colName = this.params.column.getColId();
      this.listOptions = [];
      const optionsCur = this._jobDataService.filtersValues.get(this.colName);
      for (const current of optionsCur) {
        this.listOptions.push(current['a']); // Letter a from goup by (alaSQL : sqlArrayGroup)
      }
      // console.log(this.listOptions);
  }

  isFilterActive(): boolean {
      return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
      return this.text.toLowerCase()
          .split(' ')
          .every((filterWord) => {
              return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
          });
  }

  getModel(): any {
      return {value: this.text};
  }

  setModel(model: any): void {
      this.text = model ? model.value : '';
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
     /* window.setTimeout(() => {
          this.input.element.nativeElement.focus();
      })*/
      window.setTimeout(() => {
        this.select.element.nativeElement.focus();
      });
  }
/*
  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
      alert(`Alert from PartialMatchFilterComponent ${message}`);
  }
*/
  onSelectChange(newValue: any): void {
    if (this.text !== newValue) {
        this.text = newValue;
        this.params.filterChangedCallback();
    }
  }
  onChange(newValue: any): void {
      if (this.text !== newValue) {
          this.text = newValue;
          this.params.filterChangedCallback();
      }
  }

}
