import {
  Component, OnInit, AfterContentInit, Input, Output , ViewChild, TemplateRef ,
  OnChanges, SimpleChanges, ElementRef, Renderer2, EventEmitter, ViewEncapsulation
} from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder, FormArray} from '@angular/forms';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RessourcesService } from '../../services/ressources.service';
import { EventBroadcastService } from '../../services/event-broadcast.service';
import { TranslateService } from '../../../../services/translate.service';
import { UserService } from '../../../../services/user.service';
import { CommonMethods } from '../../../../job/common/tools/commonMethods';
import { GlobalEventsManagerService } from '../../../../job/common/services/global-events-manager.service';
import { CountryDef, CountryLabel, PhoneInput, StatesLabel, EmailInput, ElemAddr,  GoogleMaps, LatLng, LabelValue  } from '../../../../models/data.model';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'mdi-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddressBlockComponent implements OnInit, AfterContentInit, OnChanges {

  // firering OnChange
  private _markChange: Object;
  @Input() 
  set param(value: Object) {
    this._markChange = value;
  }
  get markChange(): Object {
    return this.markChange;
  }
 
  @Input() dataIn: any;
  @Input() config: any;
 //  @Input() markChange: boolean;
  @Output() dataOut = new EventEmitter<any>();

  private readonly onDestroy = new Subject<void>();
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;


  // config
  langCur: string;
  testAlone: string;
  countryDefault: string;
  address: boolean;
  telephone: boolean;
  email: boolean;
  contactRequired: boolean;
  corporate: boolean;
  uiType: string ;
  dataOrig: any;
  countryCur: CountryDef;
  countryKeyToLoad: string;
  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];
  dataCur: any;
  
  countriesCount: number;
  countryIndex: number;
  countryLoaded: string;
  addressForm: FormGroup;
  identifierBlockFg: FormGroup;
  selectAppsFg: FormGroup;
  addressBlockFg: FormGroup;
  contactBlockFg: FormGroup;
  latLngBlockFg: FormGroup;
  corporateBlockFg: FormGroup;
  // addressElems: FormArray;
  countrySelect: FormControl;
  selectApps: FormControl;
  filterCountry: FormControl;
  telephoneListFg: FormArray;
  emailsListFg: FormArray;
  phonesList: PhoneInput[];
  emailsList: EmailInput[];
  civilityList: any[];

  // listAdressBlock: any[];
  rowArray: any[];
  isFormLoaded: boolean;
  isAddressLoaded: boolean;
  isPhonesLoaded: boolean;
  isEmailsLoaded: boolean;
  isDataIn: boolean;
  isReadOnly: boolean;

  phoneTypeList = ['phoneDesk', 'phoneCell', 'phoneCellWhatsApp', 'phoneHome', 'phoneOther'];
  // emailRegexCur = new RegExp(/(^[a-z]+[a-z0-9._]+)(@)([a-z]+\.[a-z.]{2,5}$)/);
  // defaultEmailFmt = '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)';
  emailAttr = environment.emailAttr;
  isDomesticCountry: boolean;
  phoneFormat: string;
  phonePrefix: string;
  phoneLengthMax: string;

  identifier: ElemAddr;

  identifierInput: boolean;
  identifierMandatory: boolean;
  appList =  ['Email', 'SMS', 'WhatsApp', 'XMPP'];

  isProfessionnal: boolean;
  
  inputLatLng: boolean;

  deptList: LabelValue[];

  // maps
  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modalTask',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  googleMaps: GoogleMaps;
  isGeoLocation: boolean;
  choiceCountry: boolean;
  countryLabel: string;

  constructor(private ressources: RessourcesService,
    private translateService: TranslateService,
    private userService: UserService,
    private fb: FormBuilder,
    private el: ElementRef,
    private renderer: Renderer2,
    private _modalService: BsModalService,
    private eventBroadcastService: EventBroadcastService,
    private _coordinatesService: CoordinatesService,
    private _globalEventsManagerService: GlobalEventsManagerService
    ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {

           // actionType
          case 'config': {
            this.config = changes['config'].currentValue;
            break;
          } // end case
          // dataIn
          case 'dataIn': {
            this.dataIn = changes['dataIn'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }

  ngOnInit() {

    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // closeMapModalLatLng__
      let eventString: string;
        const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString.startsWith('closeMapModalLatLng__')) {
          this.closeModal();
          const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;

          this.displayLatLng(latLng);

        }
      }
    });

  this.eventBroadcastService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      // console.log('event change ');
      if (event === undefined) {
        return;
      }
      if (event === null) {
        return;
      }
      if (event.eventType !== undefined) {
        if (event.eventType === 'config') {
          this.config = event.eventData;
          this.initData();
        }
        if (event.eventType === 'identifier') {
          this.config.identifier = event.eventData;
          this.buildIdentifierBlock();
        }
        return;
      }

    });
  }

  ngAfterContentInit() {
    if (this.config === undefined) {
      return;
    }
    if ((this.config.telephone) && ((this.phonesList === null) || (this.phonesList.length === 0))) {

      const phoneLengthMax = this.ressources.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');

      const lengthCur = Number.parseInt(phoneLengthMax[0], 10);
      const lengthAdd = Number.parseInt(phoneLengthMax[1], 10);

      const phoneRow = {
        controlFmt: this.ressources.getCountry(this.countryDefault).telephone.domesticFmt,
        displayFmt: this.ressources.getCountry(this.countryDefault).telephone.domesticDisplay,
        phoneLengthMax: this.ressources.getCountry(this.countryDefault).telephone.phoneLengthMax,
        phoneLength: (lengthCur).toString(),
        phoneCountry: this.countryDefault,
        phonePrefix: '',
        phoneNumber: '',
        phoneType: '',
        dataType: 'N',
        inputError: false,
      };
  
      this.phonesList.push(phoneRow);
    }
    if ((this.config.email) && ((this.emailsList === null) || (this.emailsList.length === 0))) {
      const emailRow = {
        controlFmt: this.emailAttr.emailRegex,
        displayFmt: this.emailAttr.emailDisplay,
        emailAddress: '',
        emailType: '',
        dataType: 'AN',
      };
      this.emailsList.push(emailRow);
    }

  }

  updateData(dataReturn: any) {
    this.controlFormGroup();
    dataReturn.formAdressValid = (this.addressForm.status === 'VALID');
    this.dataOut.emit(dataReturn);
  }
  updateErrorInput(event: any) {

    if (event.inputError === undefined) {
      return;
    }
    switch (event.eventBloc) {
      case 'identifier' : {
        this.identifier.inputError = event.inputError;
        break;
      }
      case 'corporate': {

        break;
      }
      case 'contact': {

        break;
      }
      case 'address': {
        if (this.rowArray[event.eventIdx].cols[event.eventCol] !== undefined) {
          this.rowArray[event.eventIdx].cols[event.eventCol].inputError = event.inputError;
        }
        break;
      }
      case 'phoneRow': {
        if (this.phonesList[event.eventIdx] !== undefined) {
          this.phonesList[event.eventIdx].inputError = event.inputError;
        }
        break;
      }
      case 'emailRow': {
        if (this.emailsList[event.eventIdx] !== undefined) {
          this.emailsList[event.eventIdx].inputError = event.inputError;
        }
        break;
      }
      default: {
        break;
      }
    }

  }
  initData() {

    this.isFormLoaded = false;
    this.isAddressLoaded = false;
    this.isPhonesLoaded = false;
    
    this.isDataIn = false;
    this.phonesList = [];
    this.emailsList = [];
    this.countryLoaded = undefined;
    this.langCur = this.userService.getUserLogged().userlang;
    this.testAlone = this.config.testAlone;
    this.countryDefault = this.userService.getUserLogged().entitycountry;
    if (this.config.countryDefault !== undefined) {
      this.countryDefault = this.config.countryDefault;
    }
    this.address = this.config.address;
    this.telephone = this.config.telephone;
    this.contactRequired = this.config.contact;
    this.corporate = this.config.corporate;
    this.email = this.config.email;
    this.identifierInput = this.config.identifier;
    this.choiceCountry = (this.config.choiceCountry !== undefined ? this.config.choiceCountry : true);

    if (this.ressources.getPhonesType() !== undefined) {
      this.phoneTypeList = this.ressources.getPhonesType();
    }
    if (this.ressources.getNotifApp() !== undefined) {
      this.appList = this.ressources.getNotifApp();
    }
    this.isReadOnly = (this.config.isReadOnly ? this.config.isReadOnly : false);
    this.inputLatLng = (this.config.latLng ? this.config.latLng : false);

    this.selectAppsFg = new FormGroup({});
    this.identifierBlockFg = new FormGroup({});
    this.addressBlockFg = new FormGroup({});
    this.latLngBlockFg = new FormGroup({});
    this.contactBlockFg = new FormGroup({});
    this.corporateBlockFg = new FormGroup({});
    this.telephoneListFg = this.fb.array([this.buildItemTel('')]);
    this.emailsListFg = this.fb.array([this.buildItemEmail('')]);

    this.addressForm = new FormGroup({
      identifierBlockFg: this.identifierBlockFg,
      contactBlockFg: this.contactBlockFg,
      corporateBlockFg: this.corporateBlockFg,
      selectAppsFg: this.selectAppsFg,
      countrySelect: new FormControl(null, [Validators.required]),
      filterCountry: new FormControl(''),
      addressBlockFg: this.addressBlockFg,
      latLngBlockFg: this.latLngBlockFg,
      telephoneListFg: this.telephoneListFg,
      emailsListFg: this.emailsListFg,
    });

    this.countryKeyToLoad = this.countryDefault;

    if ((this.dataIn !== undefined) && (this.dataIn !== null) && (this.dataIn !== '')) {
      if (this.dataIn === '') {
        this.dataCur = '';
      } else if (typeof this.dataIn === 'string') {
        this.dataCur = JSON.parse(this.dataIn);
      } else {
        this.dataCur = this.dataIn;
      }
      // this.dataCur = JSON.parse(this.dataIn);
      // this.dataCur = this.dataIn;
      this.dataOrig = Object.assign('', this.dataCur);
      this.countryKeyToLoad = (this.dataCur.country !== undefined ? this.dataCur.country : this.countryDefault);
      this.isDataIn = true;
    } else {
      this.isAddressLoaded = true;
      this.isPhonesLoaded = true;
      this.isDataIn = false;
    }

    this.countryCur = this.ressources.getCountry(this.countryKeyToLoad);
    this.isDomesticCountry = true;
    this.phoneFormat = JSON.stringify(this.countryCur.telephone);
    this.phonePrefix = this.countryCur.telephone.ITU_T_Code;

    const civilityItems = this.ressources.getCivilityList();
    this.civilityList = [];

    for (const item of civilityItems) {
      const label = this.translateService.instant(item);
      const opt = {
        item: item,
        label: label,
      };
      this.civilityList.push(opt);
    }
    const deptsItem = this.ressources.getDeptsList();
    this.deptList = [];

    for (const item of deptsItem) {
      const label = this.translateService.instant(item);
      const opt = {
        value: item,
        label: label,
      };
      this.deptList.push(opt);
    }

    const countriesListTmp = this.ressources.getCountriesList();

    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this.translateService.getLabelCountry(this.langCur.toUpperCase(), countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
      if (!this.choiceCountry && countryTmp.isoAlpha2 === this.countryDefault) {
        this.countryLabel = labelLang;
      }
    }

    this.countriesCount = this.countriesCacheList.length;
    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];
    if (this.countryKeyToLoad) {
      this.addressForm.patchValue({
        filterCountry: '',
        countrySelect: this.countryKeyToLoad,
      });
    }
    if (this.identifierInput) {
      this.buildIdentifierBlock();
    }
    if (this.contactRequired) {
      this.buildContactsBlock();
    }
    if (this.corporate) {
      this.buildCorporateBlock();
    }
    this.buildAddressBlock(this.countryKeyToLoad);
    if (this.telephone) {
      this.loadPhones();
    }
    if (this.email) {
      this.loadEmails();
    }
    this.isFormLoaded = true;
  }
  onSelectionCountryChangeMat(event: { index: any, value: any }) {
    // console.log(JSON.stringify(event));
    console.log(event.value);
    this.countryKeyToLoad = event.value;
    if (this.countryCur === undefined) {
      this.countryCur = this.ressources.getCountry(this.countryKeyToLoad);
    } else if (this.countryCur.isoAlpha2 !== this.countryKeyToLoad) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      // const phonesElem = Object.keys(this.telephoneList[0].controls);
      let arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
      let nbRow = arrayGrp.length;
      for (let row = 0; row < nbRow; row++) {
        arrayGrp.removeAt(row);
      }
      arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
      nbRow = arrayGrp.length;
      for (let row = 0; row < nbRow; row++) {
        arrayGrp.removeAt(row);
      }
      
      /*
      this.dataCur = undefined;
      const updateInput = {
        cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
        eventBloc: 'address',
        eventOrig : 'country',
        eventIdx: event.index,
        eventCol: 0,
        eventValue: event.value,
        inputError: false,
       };
      this.updateData(this.getDataOut(updateInput));
      */
      this.phonesList = [];
      this.emailsList = [];
      this.countryCur = this.ressources.getCountry(this.countryKeyToLoad);
      this.countryLoaded = undefined;
    }
    if (this.countryLoaded !== this.countryKeyToLoad) {
      this.buildAddressBlock(this.countryKeyToLoad);
      if (this.identifierInput) {
        this.buildIdentifierBlock();
      }
      if (this.telephone) {
        this.loadPhones();
      }
      if (this.email) {
        this.loadEmails();
      }
    }
    this.isFormLoaded = true;
  }
  onPhoneCountryChangeMat(event: any,  _iRow: number) {
    // console.log(event.value + ' ' + row);

    
    const countryCur = this.ressources.getCountry(event.value) as CountryDef;
    this.phonesList[_iRow].phoneCountry = countryCur.isoAlpha2;
    this.phonesList[_iRow].phonePrefix = countryCur.telephone.ITU_T_Code;
    this.phonesList[_iRow].phoneLengthMax = this.ressources.getCountry(countryCur.isoAlpha2).telephone.phoneLengthMax;
    this.phonesList[_iRow].inputError = false;
    const phoneLengthCur = this.ressources.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');
    const lengthCur = Number.parseInt(phoneLengthCur[0], 10);
    const lengthAdd = Number.parseInt(phoneLengthCur[1], 10);
    this.phonesList[_iRow].phoneLength = (lengthCur).toString();
    if (event.value !== this.countryDefault) {
      this.phonesList[_iRow].displayFmt = this.ressources.getCountry(countryCur.isoAlpha2).telephone.E_164_Display;
      this.phonesList[_iRow].controlFmt = this.ressources.getCountry(countryCur.isoAlpha2).telephone.E_164_Fmt;
    } else {
      this.phonesList[_iRow].displayFmt = this.ressources.getCountry(countryCur.isoAlpha2).telephone.domesticDisplay;
      this.phonesList[_iRow].controlFmt = this.ressources.getCountry(countryCur.isoAlpha2).telephone.domesticFmt;
    }
    let updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phoneCountry',
      eventIdx: _iRow,
      eventCol: -1,
      eventValue: this.phonesList[_iRow].phoneCountry,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
    updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phonePrefix',
      eventIdx: _iRow,
      eventCol: -1,
      eventValue: this.phonesList[_iRow].phonePrefix,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  onSelectionStateChangeMat(event: { index: any, value: any }) {
    // console.log(event.source.id + ' ' + event.value);
    // console.log('onSelectionStateChange : ' + event.index + ' ' + event.value);
    // console.log('state : ' + this.statesFilteredList[event.index].stateAbb);
    // const valueCur = (event.index === -1 ? '' : this.statesFilteredList[event.index].stateAbb);

    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'address',
      eventOrig : 'state',
      eventIdx: event.index,
      eventCol: 0,
      eventValue: event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
    // this.eventBroadcastService.broadcastEvent(newEvent);
  }

  onSelectionEmailTypeChangeMat(event: { index: any, value: any }, _iRow: number) {
    /*
    const newEvent = {
      eventBloc: 'emailRow',
      eventOrig: 'emailType',
      eventIdx: row.toString(),
      eventValue: event.value,
    };
*/
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'emailRow',
      eventOrig : 'emailType',
      eventIdx: _iRow,
      eventCol: -1,
      eventValue: event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
    // this.eventBroadcastService.broadcastEvent(newEvent);
  }
 
  onSelectionPhoneTypeChangeMat(event: { index: any, value: any }, _iRow: number) {
/*
    const newEvent = {
      eventBloc: 'phoneRow',
      eventOrig: 'phoneType',
      eventIdx: row.toString(),
      eventValue: event.value,
    };
    this.eventBroadcastService.broadcastEvent(newEvent);
*/
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'phoneRow',
      eventOrig : 'phoneType',
      eventIdx: _iRow,
      eventCol: -1,
      eventValue: event.value,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  onKeyCountry(value: any) {
    // console.log(value);
    this.countriesFilteredList = this.searchCountry(value);
  }
  searchCountry(value: string) { 
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  corporateChange(value: string) {
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    this.dataCur.corporateName = value;
    this.dataCur.cpltEventName = (this.config.cpltEventName ? this.config.cpltEventName : undefined);
    // this.dataIn = JSON.stringify(this.dataCur);
    this.updateData(this.dataCur);
  }
  contactChange(part: string, value: any) {
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    if (this.dataCur.identity === undefined) {
      this.dataCur.identity = {
        cntCivility: '',
        cntFirstname: '',
        cntLastname: '',
      };
    }
    this.dataCur.cpltEventName = (this.config.cpltEventName ? this.config.cpltEventName : undefined);
    switch (part) {
      case 'civility': {
        this.dataCur.identity.cntCivility = value.value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'firstname': {
        this.dataCur.identity.cntFirstname = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'lastname': {
        this.dataCur.identity.cntLastname = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'selectApps': {
        this.dataCur.selectApps = CommonMethods.arrayStringToString(value, ',');
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'selectDept': {
        this.dataCur.selectDept = CommonMethods.arrayStringToString(value, ',');
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
      case 'fonctionDept': {
        this.dataCur.fonctionDept = value;
        // this.dataIn = this.dataCur;
        this.updateData(this.dataCur);
        break;
      }
    }
  }
  buildCorporateBlock () {
    let valueCur: string;
    const fieldName = 'corporateName';
    if (this.corporate) {
      if (this.dataCur !== undefined) {
        valueCur = (this.dataCur[fieldName] === undefined ? '' : this.dataCur[fieldName]);
      }
    } else {
      valueCur = '';
    }

    this.corporateBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
  }
  buildIdentifierBlock() {
    this.identifierInput = false;

    if (this.config.identifier === undefined) {
      return;
    }
    if (this.config.identifier === 'unknow') {
      this.identifierBlockFg.removeControl('identifier');
      return;
    }
    const identifierDefault = {
      dataType: 'AN',
      dataLengthMax: '25',
      controlFmt: '.*',
    };
    this.identifier = undefined;
    // dataMandatory
    const elemsAddr = this.countryCur.address.elemAddr;
    for (const elemAddr of elemsAddr) {
      if ((elemAddr.dataInput === 'proID') && (this.config.identifier === 'professional')) {
        elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
        elemAddr.inputValue = '';
        this.identifierMandatory = (elemAddr.dataMandatory === 'N' ? false : true);
        this.identifier = elemAddr;
        break;
      }
      if ((elemAddr.dataInput === 'individualID')  && (this.config.identifier === 'individual')) {
        elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
        this.identifierMandatory = (elemAddr.dataMandatory === 'N' ? false : true);
        elemAddr.inputValue = '';
        this.identifier = elemAddr;
        break;
      }
    }
    if (this.identifier === undefined) {
      return;
    }
    this.identifierInput = true;
    const valueCur = (((this.dataCur === undefined) || (this.dataCur.identifier === undefined)) ? '' : this.dataCur.identifier);
    this.identifier.inputValue = valueCur;
    this.identifier.inputError = false;
    /*
    if ((this.identifier.displayFmt !== undefined) && (this.identifier.displayFmt !== null)) {
      const fmtCur = this.identifier.controlFmt;
      const displayFmt = this.identifier.displayFmt;
      const pattern = new RegExp(fmtCur, 'i');
      let matchesGroup: any;
      let testMatch: any;
      testMatch = valueCur.match(pattern);
      matchesGroup = valueCur.toUpperCase().replace(pattern, displayFmt);
      if (testMatch === null) {
        this.identifier.inputError = true;
      } else {
        this.identifier.inputError = false;
      }
      valueCur = matchesGroup;

    }*/
    this.identifierBlockFg.addControl('identifier', new FormControl({value: valueCur, disabled: this.isReadOnly}));
  }
  buildContactsBlock() {
    this.isProfessionnal = this.config.professionnal;
    /*
    if (this.config.identifier === 'professional') {
      this.isProfessionnal = true;
    }
    */
    const contactFields = ['cntCivility', 'cntFirstname', 'cntLastname'];
    let valueCur: string;
    for (const fieldName of contactFields) {
      if (this.contactRequired) {
        if ((this.dataCur !== undefined) && (this.dataCur.identity !== undefined)) {
          valueCur = (this.dataCur.identity[fieldName] === undefined ? '' : this.dataCur.identity[fieldName]);
        }
      } else {
        valueCur = '';
      }

      this.contactBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
    }

    if (this.isProfessionnal) {
      const professionnalFields = ['selectDept', 'fonctionDept'];
      for (const fieldName of professionnalFields) {
        if (this.dataCur !== undefined ) {
          valueCur = (this.dataCur[fieldName] === undefined ? '' : this.dataCur[fieldName]);
        } else {
          valueCur = '';
        }
        this.contactBlockFg.addControl(fieldName, new FormControl({value: valueCur, disabled: this.isReadOnly}));
      }
      if ((this.dataCur !== undefined) && (this.dataCur.selectDept !== undefined)) {
        const selectDepts = this.dataCur.selectDept.split(',');
        this.contactBlockFg.controls.selectDept.setValue(selectDepts);
      }
    }
    this.selectAppsFg.addControl('selectApps', new FormControl({value: '', disabled: this.isReadOnly}));
    if ((this.dataCur !== undefined) && (this.dataCur.selectApps !== undefined)) {
      // const selectApps = CommonMethods.listToArray(this.dataCur.selectApps, ',');
      const selectApps = this.dataCur.selectApps.split(',');
      /*const bindApps: any[] = [];
      for (const tagName of selectApps) {
        bindApps.push(tagName);
      }*/
      this.selectAppsFg.controls.selectApps.setValue(selectApps);
    }

  }
  buildAddressBlock(country: string) {

    if (country === '') {
      return;
    }
    let noDataIn = false;
    let noAddressIn = false;

    if (!this.address)  {
      return;
    }
    if ((this.dataCur === undefined)) {
      noDataIn = true;
      noAddressIn = true;
    } else if ((this.dataCur.address === undefined)) {
      noAddressIn = true;
    }
    if ((this.dataIn !== undefined) && (this.dataIn !== null) && (this.dataIn !== ''))  {
      this.isDataIn = true;
    }
    this.countryLoaded = this.countryKeyToLoad;

    let address = this.countryCur.address;
    if (this.config.localAddress && this.countryCur.localAddress) {
      address = this.countryCur.localAddress;
    }
    const elemsAddr = address.elemAddr;
    let rowsUpperCase: string | string[] = [];
    try {
      rowsUpperCase = address.uppercaseLastLines.split(';') as string[];
    } catch (err) {
    }
    if (elemsAddr === undefined) {
      const addrElem = Object.keys(this.addressBlockFg.controls);
      for (const elem of addrElem) {
        this.addressBlockFg.removeControl(elem);
      }
      return;
    }
    // console.log(elemsAddr);
    let iRow = 0;
    const identityField = this.config.identifier;
    const fieldsId = this.config.fieldsId;
    let iRowCur = 0;
    this.rowArray = [];
    let colsArray = [];
    let iRowBuild = -1;
    let iLengthMax = 0;
    // let labelOk = false;
    for (const elemAddr of elemsAddr) {
      iRowCur++;
      // JLG - 2022-09-17
      /*
      if (iRowCur < 3) { // recipient lines
        if (identityField === undefined) { // recipient lines
          continue;
        } else {
          if ((identityField === 'pro') && (!labelOk)) {
            elemAddr.dataName = 'denomination';
            labelOk = true;
          } else
          if ((identityField === 'perso') && (!labelOk)) {
            elemAddr.dataName = 'fullName';
            labelOk = true;
          } else if (!labelOk) {
            continue;
          }
        }
      }*/
      // console.log(elemAddr.dataName);
      if (this.corporate || this.contactRequired) {
        if (elemAddr.dataName.toLowerCase() === 'addrelem1' 
        || elemAddr.dataName.toLowerCase() === 'addrelem2') {
          continue;
        }
      }
      if ((elemAddr.controlType === null) || (elemAddr.controlType === '')) {
        elemAddr.controlType = elemAddr.dataName;
      }
      iRow = Number(elemAddr.lineNumber);
      if (elemAddr.controlType === 'statesList') {
        this.buildStatesList(address.statesList);
        this.addressBlockFg.addControl('filterState', new FormControl());
      }
      if (elemAddr.dataInput === 'N') { continue; }

      if ((elemAddr.dataInput === 'proID')  || (elemAddr.dataInput === 'individualID')) {
          continue;
      }
      if ((iRowBuild > 0) && (iRow !== iRowBuild)) {
        this.rowArray.push({
          row: Number(iRow).toString,
          lengthMax: iLengthMax,
          cols: colsArray,
        });
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      if (iRowBuild < 0) {
        colsArray = [];
        iRowBuild = iRow;
        iLengthMax = 0;
      }
      iLengthMax += Number(elemAddr.dataLengthMax);
      const labelElem = this.translateService.instant(elemAddr.dataName);
      if (labelElem === elemAddr.dataName) {
        elemAddr.elemLabel = ''; // if label is ''
      } else {
        elemAddr.elemLabel = labelElem;
      }
      // elemAddr.elemLabel = this.translateService.instant(elemAddr.dataName);
      if (noDataIn || noAddressIn) {
        elemAddr.inputValue = '';
      } else {
        elemAddr.inputValue = (this.dataCur.address[elemAddr.dataName] === undefined ? '' : this.dataCur.address[elemAddr.dataName]);
      }
      elemAddr.elemUpperCase = false;
      if (rowsUpperCase.includes(elemAddr.lineNumber)) {
        elemAddr.elemUpperCase = true;
      }
      colsArray.push(elemAddr);
      this.addressBlockFg.addControl(elemAddr.dataName, new FormControl({value: '', disabled: this.isReadOnly}));
      this.addressBlockFg.controls[elemAddr.dataName].patchValue(elemAddr.inputValue, { emitEvent: true, onlySelf: false });
      if (noDataIn) {
        this.dataCur = {};
      }
      if (noAddressIn) {
        this.dataCur.address = {};
      }
      if ((this.dataCur.address[elemAddr.dataName] === undefined)) {
        this.dataCur.address[elemAddr.dataName] = '';
      }
    } // elem address loop
    this.rowArray.push({
      row: Number(iRow).toString,
      lengthMax: iLengthMax,
      cols: colsArray,
    });
    if (this.inputLatLng)  {
      this.buildLatLngBlock();
    }
    this.isAddressLoaded = true;

  }
  buildLatLngBlock() {
    this.latLngBlockFg.addControl('addressPosition', new FormControl({value: '', disabled: this.isReadOnly}));
    this.latLngBlockFg.addControl('addressPositionCoord', new FormControl({value: '', disabled: this.isReadOnly}));
    if ((this.dataIn !== undefined ) 
      && (this.dataIn.addressPosition !== undefined )
      && (this.dataIn.addressPosition !== '' )) {
        this.latLngBlockFg.controls['addressPosition'].patchValue(this.dataIn.addressPosition, { emitEvent: true, onlySelf: false });
        const latLng  = this.getLatLng(this.dataIn.addressPosition.split(','));
        this.latLngBlockFg.controls.addressPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.latLngBlockFg.controls['addressPosition'].patchValue('', { emitEvent: true, onlySelf: false });
      this.latLngBlockFg.controls.addressPositionCoord.setValue('');
    }
  }
  getColRowAddr(cols: any[]) {
    // console.log(cols.length);
    return 'col';
    /* if ( cols.length === 1) {
      return 'col-6';
    } else {
      const nbCol = (12 / cols.length);
      // return 'col-' + nbCol.toString();
      return 'col';
    }*/
  }
  calWidthCol(cols: any[], dataLengthMax: number, lengthMax: number): string {

    let fieldSize = dataLengthMax * 11;
    // padding left / right
    if (fieldSize < 70 ) {
      fieldSize = fieldSize + (15 * 2);
    }
    return fieldSize.toString() + 'px';

/*
    if ((cols !== undefined) && (cols.length === 1)) {
      return '100%';
    }

    const width = ((100 / cols.length));
    return Number(width).toString() + '%';
  */
  }

  buildStatesList(stateList: any) {
    this.statesCacheList = [];
    for (const stateTmp of stateList) {
      this.statesCacheList.push({
        stateName: stateTmp.stateName,
        stateAbb: stateTmp.stateAbb,
      });
    }

    this.statesCacheList.sort((obj1, obj2) => {
      return obj1.stateName > obj2.stateName ? 1 : -1;
    });

    this.statesFilteredList = [...this.statesCacheList];
  }
  setFilterState(selectInput: string) {
    // console.log('selectInput : ' + selectInput);

    this.statesFilteredList = this.statesCacheList
      .filter(stateLoc => {
        const stateLoc1 = Object.values(stateLoc)[1];
        if (stateLoc1 !== undefined) {
          return stateLoc1.toLowerCase().includes(selectInput.toLowerCase());
        }
      });
  }
// ------------------------------------------------------------------------------ load telephone

  loadPhones() {
    
    this.isDomesticCountry = (this.countryCur.isoAlpha2 === this.countryDefault);
    this.phoneFormat = JSON.stringify(this.countryCur.telephone);
    this.phonePrefix = this.countryCur.telephone.ITU_T_Code;
    if ((this.countryCur.telephone.phoneLengthMax === undefined) ||
        (this.countryCur.telephone.phoneLengthMax === '')) {
          this.countryCur.telephone.phoneLengthMax = '10;4';
    }
    const phoneLength = this.countryCur.telephone.phoneLengthMax.split(';');
    const lengthCur = Number.parseInt(phoneLength[0], 10);
    const lengthAdd = Number.parseInt(phoneLength[1], 10);

    this.phoneLengthMax = (lengthCur + lengthAdd).toString();
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    if ((this.dataCur.phones === undefined) || (this.dataCur.phones === null)) {
      if (arrayGrp.length === 0) {
        this.addPhone(0);
      }
      this.dataCur.phones = this.phonesList;
      this.isPhonesLoaded = true;
      const formGroup = arrayGrp.get('0') as FormGroup;
      formGroup.controls['phoneNumber'].setErrors({'incorrect': true});
      return;
    }
    if (this.dataCur.phones.length === 0) {
      if (arrayGrp.length === 0) {
        this.addPhone(0);
      }
      this.dataCur.phones = this.phonesList;
      this.isPhonesLoaded = true;
      const formGroup = arrayGrp.get('0') as FormGroup;
      formGroup.controls['phoneNumber'].setErrors({'incorrect': true});
      return;
    }
    this.phonesList = this.dataCur.phones as PhoneInput[];
    
    arrayGrp.removeAt(0);
    for (const phoneTmp of this.phonesList) {
      // arrayGrp.push(this.buildItemTel(phoneTmp));
      // const phoneNumber = CommonMethods.getNumbers(phoneTmp.phoneNumber);
      const ctrlTmp = new FormGroup({
        phonePrefix: new FormControl({value: phoneTmp.phonePrefix, disabled: this.isReadOnly}),
        phoneNumber: new FormControl({value: phoneTmp.phoneNumber, disabled: this.isReadOnly, validators: [Validators.required, Validators.maxLength(lengthCur)]}),
        phoneType: new FormControl({value: phoneTmp.phoneType, disabled: this.isReadOnly}),
      });
      phoneTmp.phoneLength = lengthCur.toString();
      // KO : ctrlTmp.controls['phoneNumber'].setValidators([Validators.required]);
      // ctrlTmp.controls['phoneNumber'].setValidators([Validators.maxLength(lengthCur)]);
      // console.log(ctrlTmp);
      // ctrlTmp.controls['phoneNumber'].patchValue(phoneNumber, { emitEvent: true, onlySelf: false });
      if ((phoneTmp.phoneNumber === '')  || (phoneTmp.phoneType === '')) {
        ctrlTmp.controls['phoneNumber'].setErrors({'incorrect': true});
      }
      arrayGrp.push(ctrlTmp);

    }

    this.isPhonesLoaded = true;
    return;

  }
  addPhone(index: number) {
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    arrayGrp.push(this.buildItemTel(''));
    const phoneLengthMax = this.ressources.getCountry(this.countryDefault).telephone.phoneLengthMax.split(';');

    const lengthCur = Number.parseInt(phoneLengthMax[0], 10);
    const lengthAdd = Number.parseInt(phoneLengthMax[1], 10);

    const phoneRow = {
      controlFmt: this.ressources.getCountry(this.countryDefault).telephone.domesticFmt,
      displayFmt: this.ressources.getCountry(this.countryDefault).telephone.domesticDisplay,
      phoneLengthMax: this.ressources.getCountry(this.countryDefault).telephone.phoneLengthMax,
      phoneLength: (lengthCur).toString(),
      phoneCountry: this.countryDefault,
      phonePrefix: '',
      phoneNumber: '',
      phoneType: '',
      dataType: 'N',
      inputError: false,
    };
    this.phonesList.push(phoneRow);
  }
  buildItemTel(val: any): FormGroup {
    if (val === '') {
      const FormGroupTmp: FormGroup = new FormGroup ({
        phoneCountry: new FormControl(this.countryDefault),
        phonePrefix: new FormControl({value: '', disabled: this.isReadOnly}),
        phoneNumber: new FormControl({value: '', disabled: this.isReadOnly}),
        phoneType: new FormControl({value: '', disabled: this.isReadOnly}),

      });
      FormGroupTmp.controls['phoneNumber'].setErrors({'incorrect': true});
      return FormGroupTmp;
    } else {
      if (val instanceof PhoneInput) {
        return new FormGroup ({
          phoneCountry: new FormControl({value: val.phoneCountry, disabled: this.isReadOnly}),
          phonePrefix: new FormControl({value: val.phonePrefix, disabled: this.isReadOnly}),
          phoneNumber: new FormControl({value: val.phoneNumber, disabled: this.isReadOnly}),
          phoneType: new FormControl({value: val.phoneType, disabled: this.isReadOnly}),
        });
      }
    }
  }
  removePhone(index: number) {
    const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
    if (arrayGrp.length === 0) {
      return;
    }
    if (arrayGrp.length < 2) {
      return;
    }
    arrayGrp.removeAt(index);
    this.phonesList.splice(index, 1);
    if (arrayGrp.length === 0) {
      this.addPhone(-1);
    }
    this.dataCur.phones = this.phonesList;
  }
  // ------------------------------------------------------------------------------ load email
  loadEmails() {

    if (this.dataCur === undefined) {
      this.dataCur = {};
    }
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    if ((this.dataCur.emails === undefined) || (this.dataCur.emails === null)) {
      if (arrayGrp.length === 0) {
        this.addEmail(0);
      }
      this.dataCur.emails = this.emailsList;
      this.isEmailsLoaded = true;
     
      const formGroup = arrayGrp.get('0') as FormGroup;
      formGroup.controls['emailAddress'].setErrors({'incorrect': true});
      return;
    }
    if (this.dataCur.emails.length === 0) {
      if (arrayGrp.length === 0) {
        this.addEmail(0);
      }
      this.dataCur.emails = this.emailsList;
      this.isEmailsLoaded = true;
      const formGroup = arrayGrp.get('0') as FormGroup;
      formGroup.controls['emailAddress'].setErrors({'incorrect': true});
      return;
    }
    this.emailsList = this.dataCur.emails as EmailInput[];

    arrayGrp.removeAt(0);
    for (const emailTmp of this.emailsList) {
      // arrayGrp.push(this.buildItemTel(phoneTmp));
      const ctrlTmp = new FormGroup({
        emailAddress: new FormControl({value: emailTmp.emailAddress, disabled: this.isReadOnly}),
        emailType: new FormControl({value: emailTmp.emailType, disabled: this.isReadOnly}),
      });
      // console.log(ctrlTmp);
      if ((emailTmp.emailAddress === '') || (emailTmp.emailType === '')) {
        ctrlTmp.controls['emailAddress'].setErrors({'incorrect': true});
      }
      arrayGrp.push(ctrlTmp);
    }

    this.isEmailsLoaded = true;
    return;

  }
  addEmail(index: number) {
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    arrayGrp.push(this.buildItemEmail(''));
    const emailRow = {
      controlFmt: this.emailAttr.emailRegex,
      displayFmt: this.emailAttr.emailDisplay,
      emailAddress: '',
      emailType: '',
      dataType: 'AN',
      emailLengthMax: this.emailAttr.emailLengthMax,
    };
    this.emailsList.push(emailRow);
  }
  buildItemEmail(val: any): FormGroup {
    if (val === '') {
      const FormGroupTmp: FormGroup = new FormGroup({
        emailAddress: new FormControl({value: '', disabled: this.isReadOnly}),
        emailType: new FormControl({value: '', disabled: this.isReadOnly}),
      });
      FormGroupTmp.controls['emailAddress'].setErrors({'incorrect': true});
      return FormGroupTmp;
    } else {
      if (val instanceof EmailInput) {
        return new FormGroup({
          emailAddress: new FormControl({value: val.emailAddress, disabled: this.isReadOnly}),
          emailType: new FormControl({value: val.emailType, disabled: this.isReadOnly}),
        });
      }
    }
  }
  removeEmail(index: number) {
    const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
    if (arrayGrp.length === 0) {
      return;
    }
    if (arrayGrp.length < 2) {
      return;
    }
    arrayGrp.removeAt(index);
    this.emailsList.splice(index, 1);
    if (arrayGrp.length === 0) {
      this.addEmail(-1);
    }
    this.dataCur.emails = this.emailsList;
  }

  getDataOut(event: {
            cpltEventName: string,
            eventBloc: string,
            eventOrig: string,
            eventIdx: number,
            eventValue: string
            }): any {
   
    this.dataCur.country = this.countryCur.isoAlpha2;
    this.dataCur.countryInput = this.countryDefault;

    if (event.cpltEventName !== undefined) {
      this.dataCur.cpltEventName = event.cpltEventName;
    }

    const phonePrefix = 'phonePrefix';
//    const phonesList = [];
    if (event.eventBloc === 'appsSelected') {
      this.dataCur.appsSelected = event.eventValue;
    }
    if (event.eventBloc === 'address') {
      // getting state value
      if (this.dataCur.address === undefined)  {
        this.dataCur.address = {};
      }
      this.dataCur.address[event.eventOrig] = event.eventValue;

    }
    if (event.eventBloc === 'phoneRow') {
      // getting phoneType Value
      /*
      if ((this.dataCur.phones === undefined) || (this.dataCur.phones === null) || (this.dataCur.phones.length === 0) )  {
        this.addPhone(0);
      }
      */
      this.dataCur.phones[event.eventIdx][event.eventOrig] = event.eventValue;
      this.dataCur.phones[event.eventIdx][phonePrefix] = this.phonePrefix;

    } // end telephone test
    if (event.eventBloc === 'emailRow') {
      /*
      if ((this.dataCur.emails === undefined) || (this.dataCur.emails === null) || (this.dataCur.emails.length === 0)) {
        this.addEmail(0);
      }
      */
      this.dataCur.emails[event.eventIdx][event.eventOrig] = event.eventValue;

    } // end email test
    if (event.eventBloc === 'identifier') {
      this.dataCur.identifier =  event.eventValue;
    }
    if (event.eventBloc === 'latLng') {
      this.dataCur.addressPosition =  event.eventValue;
    }
    // this.dataIn = JSON.stringify(this.dataCur);
    // this.dataIn = this.dataCur;
    return this.dataCur;
  }

  formatField(_typeRaw: string, _iRow: number, _iCol: number, _paramsCur: any, _value: string): string {
  
    let valueReturn: string;
    let blocField: string;
    let dataName: string;
    let displayFmt: string;
    let inputValue: string;
    let isNumeric: boolean;
    let isDomesticCountry: boolean;
    let updateInput: {
      cpltEventName: string,
      eventBloc: string,
      eventOrig: string,
      eventIdx: number,
      eventCol?: number,
      eventValue: string,
      inputError: boolean,
    };
    const defaultFmtTel = '([0-9]{2})([0-9]{4})([0-9]{4})';
    const defaultEmailFmt = '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)';
    const defaultEmailDisplay = '$1$2$3$4';
    const defaultDisplayTel = '($1) $2 $3';
    const defaultLength = '10;4';
  
    let  inputError: boolean;

    // console.log(_typeRaw + ' ' + _iRow.toString() + ' ' + _paramsCur + ' ' + _value);
    inputValue = _value;
    inputError = false;

    blocField = 'address';
    displayFmt = _paramsCur.displayFmt;
    dataName = _paramsCur.dataName;
    if (_paramsCur.controlFmt === '.*') {
      valueReturn = inputValue;
      if (_paramsCur.elemUpperCase) {
        valueReturn = inputValue.toUpperCase();
      }
      this.addressBlockFg.controls[_paramsCur.dataName].setValue(valueReturn);
      updateInput = {
        cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
        eventBloc: blocField,
        eventOrig : dataName,
        eventIdx: _iRow,
        eventCol: _iCol,
        eventValue: valueReturn,
        inputError: inputError,
       };
       this.updateErrorInput(updateInput);
       this.updateData(this.getDataOut(updateInput));
      return;
    }
    if (_paramsCur.dataType !== undefined) {
      isNumeric = (_paramsCur.dataType.startsWith('N'));
    } else {
      isNumeric = false;
    }

    const isTelephone = (_typeRaw === 'telephone');
    const isIdentifier = (_typeRaw === 'identifier');
    const isEmail = (_typeRaw === 'email');

    let fieldLength = 0;
    if (isTelephone) {
      blocField = 'phoneRow';
      isNumeric = true;
      isDomesticCountry = (_paramsCur.phoneCountry === this.countryDefault ? true : false);
      let phoneArray: string[];
      if ( _paramsCur.phoneLengthMax === undefined) {
        _paramsCur.phoneLengthMax = defaultLength;
      }
      phoneArray = _paramsCur.phoneLengthMax.split(';');
      fieldLength = Number(phoneArray[0]);
      if (inputValue.length > fieldLength ) {
        inputValue = inputValue.substring(0, fieldLength );
      }
    }
    if (isEmail) {
      blocField = 'emailRow';
      fieldLength = _paramsCur.emailLengthMax;
    }
    
    if (isNumeric) {
      inputValue = inputValue.replace(/\D/g, '');
    }
    if ((isTelephone) && (!isDomesticCountry)) {
      inputValue = inputValue.replace(/^(?!00[^0])0/, '');
    }
    if (isIdentifier)  {
      inputValue = CommonMethods.getNumbersandLetters(_value);
    }
    let pattern: any;
    let fmtCur: string ;
    if (isEmail) {
      pattern = new RegExp(defaultEmailFmt, 'i');
      displayFmt = _paramsCur.displayFmt;
    } else if (!isTelephone) {
      pattern = new RegExp(_paramsCur.controlFmt, 'i');
    } else {
      if (_paramsCur.controlFmt === undefined) {
        _paramsCur.controlFmt = defaultFmtTel;
      }
      if (_paramsCur.displayFmt === undefined) {
        _paramsCur.displayFmt = defaultDisplayTel;
      }
      fmtCur = _paramsCur.controlFmt;
      displayFmt = _paramsCur.displayFmt;

      if (displayFmt === undefined ) {
        fmtCur = defaultFmtTel;
        displayFmt = defaultDisplayTel;
      }
      pattern = new RegExp(fmtCur, 'i');
    }
    let matchesGroup: any;
    let testMatch: any;
    testMatch = inputValue.match(pattern);
    if (!isEmail) {
      matchesGroup = inputValue.toUpperCase().replace(pattern, displayFmt);
    } else {
      matchesGroup = inputValue.replace(pattern, defaultEmailDisplay);
    }

    if (testMatch === null) {

      inputError = true;
    } else {
      inputError = false;
    }

    valueReturn = matchesGroup;
  
    if (isTelephone) {
      this.phonesList[_iRow].phoneNumber = valueReturn;
      const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
      const itemFG: FormGroup = arrayGrp.get(_iRow.toString()) as FormGroup;      
      if (inputError)  {
        itemFG.controls['phoneNumber'].setErrors({'incorrect': true});
        // itemFG.controls['phoneNumber'].setValue(valueReturn);
      } else {
        // itemFG.controls['phoneNumber'].setValue(valueReturn);
      }
    } else if (isEmail) {
      
      this.emailsList[_iRow].emailAddress = valueReturn;
      if (inputError)  {
        // this.emailsListFg.controls['emailAddress'].setErrors({'incorrect': true});
        const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
        const itemFG: FormGroup = arrayGrp.get(_iRow.toString()) as FormGroup;
        itemFG.controls['emailAddress'].setErrors({'incorrect': true});
      }
    } else {
      if (_typeRaw === 'identifier') {
        this.identifierBlockFg.controls[_typeRaw].setValue(valueReturn);
        if (inputError)  {
          this.identifierBlockFg.controls[_typeRaw].setErrors({'incorrect': true});
        }
        blocField = _typeRaw;
      } else {
        this.addressBlockFg.controls[_paramsCur.dataName].setValue(valueReturn);
        if (inputError)  {
          this.addressBlockFg.controls[_paramsCur.dataName].setErrors({'incorrect': true});
        }
      }
    }
    updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: blocField,
      eventOrig : dataName,
      eventIdx: _iRow,
      eventCol: _iCol,
      eventValue: valueReturn,
      inputError: inputError,
     };
    this.updateErrorInput(updateInput);
    this.updateData(this.getDataOut(updateInput));
    return;
  }
  // JLG - 2022-11-09 : suite à des problèmes de non respect de la directive required
  controlFormGroup() {

    let valueCur: string;
/*
    if (this.config.telephone) {
      const arrayGrp = this.addressForm.get('telephoneListFg') as FormArray;
      const lastItem = arrayGrp.length - 1;
      const itemFG: FormGroup = arrayGrp.get(lastItem.toString()) as FormGroup;
      valueCur = itemFG.controls['phoneNumber'].value;

      if (valueCur === '') {
        itemFG.controls['phoneNumber'].setErrors({'incorrect': true});
      }
    }
    if (this.config.email) {
      const arrayGrp = this.addressForm.get('emailsListFg') as FormArray;
      const lastItem = arrayGrp.length - 1;
      const itemFG: FormGroup = arrayGrp.get(lastItem.toString()) as FormGroup;
      valueCur = itemFG.controls['emailAddress'].value;
      if (valueCur === '') {
        itemFG.controls['emailAddress'].setErrors({'incorrect': true});
      }
    }
    */
    if (this.contactRequired) {

      const contactFields = ['cntCivility', 'cntFirstname', 'cntLastname'];
      for (const fieldName of contactFields) {
        valueCur = this.contactBlockFg.controls[fieldName].value;
        if (valueCur === '') {
          this.contactBlockFg.controls[fieldName].setErrors({'incorrect': true});
        }
      }
      valueCur = this.selectAppsFg.controls['selectApps'].value;
      if (valueCur === '') {
        this.selectAppsFg.controls['selectApps'].setErrors({'incorrect': true});
      }
    }
    if (this.isProfessionnal) {

      const professionnalFields = ['selectDept', 'fonctionDept'];
      for (const fieldName of professionnalFields) {
        valueCur = this.contactBlockFg.controls[fieldName].value;
        if (valueCur === '') {
          this.contactBlockFg.controls[fieldName].setErrors({'incorrect': true});
        }
      }
    }

  }
// ================================ maps 
  checkGeoLocAddress() {
    
    let addressCur: string = null;
    for (const rowCur of this.rowArray)  {
      for (const colCur of rowCur.cols) {
        const value = this.addressBlockFg.controls[colCur.dataName].value;
        if ((value !== undefined) && (value !== null) && (value !== '')) { 
          if (addressCur === null) {
            addressCur = value;
          } else {
            addressCur += ',' + value;
          }
        }
      }
    }
    addressCur += ',' + this.countryCur.commonName;
    // console.log(addressCur);
    this.searchLatLngMap(addressCur);
  }
  positionChange() {
    const addressPosition = this.latLngBlockFg.controls.addressPosition.value;
    const updateInput = {
      cpltEventName: (this.config.cpltEventName ? this.config.cpltEventName : undefined),
      eventBloc: 'latLng',
      eventOrig : 'latLng',
      eventIdx: -1,
      eventCol: -1,
      eventValue: addressPosition,
      inputError: false,
     };
    this.updateData(this.getDataOut(updateInput));
  }
  searchLatLngMap(_address: string) {

    this.googleMaps = {
      isModal: true,
      addressInput: _address,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'searchLatLng',
      position: undefined,
      mapOptions:  { },
    markers:  [],
    };
    
    this.openModalMap();
  }
  displayMap() {

    let positionCur ;
    let zoom = 1;
    let latLng: LatLng;
    if ((this.latLngBlockFg.controls.addressPosition !== undefined) 
      && (this.latLngBlockFg.controls.addressPosition.value !== null)
      && (this.latLngBlockFg.controls.addressPosition.value !== '')) {
      zoom = 14;
      positionCur = this.latLngBlockFg.controls.addressPosition.value;
      const values = positionCur.split(',');
      latLng = {
          lat: Number.parseFloat(values[0]),
          lng: Number.parseFloat(values[1])
      };
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: latLng,
      mapOptions:  {
        center: latLng,
        zoom : 1
    },
    markers:  [],
    };
    if (positionCur !== undefined) {
      this.googleMaps.markers.push({ 
        position: latLng,
        title: ''}
      );
    }
    this.openModalMap();
  }
  openModalMap() {

    const configModalMap = {
      class: 'modal-dialog-centered handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this._modalService.show(this.modalMap, configModalMap);
  }
  
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
  displayLatLng(_coord: LatLng) {
    const wrongPosition = this.translateService.getTranslate('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = _coord.lat.toString();
    values[1] = _coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((this.latLngBlockFg.controls !== undefined) && 
        (this.latLngBlockFg.controls.addressPosition !== undefined))  {
      if ((latLng !== undefined) && (latLng !== null)) {
          this.latLngBlockFg.controls.addressPosition.setValue(values[0] + ',' + values[1]);
          this.latLngBlockFg.controls.addressPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
          this.positionChange();
      } else {
        this.latLngBlockFg.controls.addressPositionCoord.setValue(wrongPosition);
      }
    }
  }
  getLatLng(_values: string[]): number[] {
    const latLng = [];
    const lat = Number.parseFloat(_values[0]);
    const lng = Number.parseFloat(_values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this._coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this._coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }
}

