import { RoleDefModel } from '../../models/user.model';

export class RolesAdmitted {

    authorizedRoles: Array<string> = [];
    dependentLevels: RoleDefModel[];
    autorities: any[];
    parentFound = false;
    constructor (_dependentLevels: RoleDefModel[], _autorities: any[]) {
      this.autorities = _autorities;
      this.dependentLevels = _dependentLevels;
      this.readAddmittedRoles();
    }
    public getAuthorizedRoles() {
      return this.authorizedRoles;
    }
    private readAddmittedRoles() {

      for (const autority of this.autorities) {
        this.parentFound = false;
        this.searchAuthorizedLevels(this.dependentLevels, autority);
      }
    }
    private searchAuthorizedLevels(_dependentLevels: RoleDefModel[], _autority: string) {
      for (const levelCur of _dependentLevels) {
        if (levelCur.roleName === _autority) {
          this.parentFound = true;
          this.authorizedRoles.push(levelCur.roleName);
        } else if (this.parentFound) {
          this.authorizedRoles.push(levelCur.roleName);
        }
        if (levelCur.dependentlevel) {
          this.searchAuthorizedLevels (levelCur.dependentlevel, _autority);
        } else if (this.parentFound) { return; } // no children
      }
    }
  }
