import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksDocsModel, DocEvent } from '../../../../models/wks-equipment.model';

@Component({
  selector: 'mdi-doc-pdf',
  templateUrl: './doc-pdf.component.html',
  styleUrls: ['./doc-pdf.component.css']
})
export class DocPdfComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() titleReport: string;
  @Input() dataList: any[];


  @ViewChild('pdfViewer', {static: false}) pdfViewer: any;

  private readonly onDestroy = new Subject<void>();

  pdfFiles: any[];
  dataSource: any[];
  dataLoaded: boolean;
  selectedPdf: any;
  pdfDisplay: any;
  dataPdfLoaded: boolean;

  constructor(private _wksCentralService: WksCentralService,
                      private dialogRef: MatDialogRef<DocPdfComponent>,
                      @Inject(MAT_DIALOG_DATA) data: any) { 
                        // this.initBooleans();
                        if (data !== undefined) { 
                          this.statutCall = data.statutCall;
                          // this.initCallParam();
                          this.dataList = data.dataList;
                          if ((this.dataList.length !== 0) || (this.dataList[0] !== undefined)) {
                            this.generatePdfList();
                          } else {
                            this.dataPdfLoaded = true;
                          }
                        }
                      }
  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    this.dataPdfLoaded = false;
    this.dataLoaded = false;


    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'titleReport': {
            this.titleReport = changes['titleReport'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'dataList': {
            this.dataList = changes['dataList'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    this.generatePdfList();
  }
  generatePdfList() {
    this.loadListPdf()
    .then (() => {
      if (this.pdfFiles[0] === undefined) { return; }
      this.loadPdfFile(this.pdfFiles[0].filePath)
        .then ((res) => {
          this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfFiles[0].localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        })
        .then((error: any) => { // error read label
          //  console.log('Read data :'+ error);
        });
    })
  .then((error) => { // error read label
    //  console.log('Read data :'+ error);
    });
  }
  loadListPdf() {
    this.pdfFiles = [];
    return new Promise((resolve, reject) => {

      for (const docCur of this.dataList ) {
        const fileCur =    {
          fileName: docCur.docFilename,
          fileLabel: docCur.docName,
          filePath: docCur.docPath + '/' + docCur.docFilename,
          fileStorage: docCur.docStorage,
          localUrl: undefined
        };
        this.pdfFiles.push(fileCur);
      }
      this.selectedPdf = this.pdfFiles[0];

      if (this.pdfFiles.length > 0) {
        resolve('Success: loadListPdf');
      } else {
        reject('Error: loadListPdf');
      }
    });
  }
  loadPdfFile(_path: any) {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getPdfDoc(_path)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          const pdfData = new Blob([response], { type: 'application/pdf', });
          const url = URL.createObjectURL(pdfData);
          this.dataPdfLoaded = true;
          resolve(response);
        }, err => {
          reject(err);
        }
      );
    });
  }
  setPdfCur(_pdfName: any) {

    for (const pdfSelected of this.pdfFiles) {
      if (pdfSelected.fileLabel === _pdfName) {

        if ( pdfSelected.localUrl !== undefined) {
          this.pdfDisplay = pdfSelected.localUrl;
          this.pdfViewer.pdfSrc = pdfSelected.localUrl;
          this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
        } else {
          this.loadPdfFile(pdfSelected.filePath)
          .then ((res) => {
            this.dataPdfLoaded = true;
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            pdfSelected.localUrl = res;
            this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
            /*
            this.pdfDisplay = url;
            pdfSelected.localUrl = url;*/
          })
          .then((error) => { // error read label
            //  console.log('Read data :'+ error);
          });
        }
        break;
      }
    }
  }
  afterLoadPdfComplete(event: any) {
    console.log('pdf loaded)');
  }
  closeEvent($event: Event)  {
    /*
    if ((this.callChain.startsWith('quotes'))  && (this.toValidated))  { 
      this._globalEventsManagerService.synchroEvents.next('removeQuoteModal__' + this.selectedPdf.fileRef);
    }
    */

    this.dialogRef.close('closeEvent');
    return;
  }
}
