<div  class="container-fluid " style="width: 100%; margin-left: 10px;" > 
    <div class="row">
        <div class="col-md-3"  >
            <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" 
                style="margin-bottom: 20px; font-size: 12px; width: 100%; white-space: pre-wrap!important;">
                <mat-icon>library_add</mat-icon>
                <span style="margin-left: 10px">{{'SelectFiles' | translate}}</span>
                <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                <input #fileInput type="file" (change)="onFileChange($event)" [accept]="fileExt" style="display:none;" />
            </button>
        </div>
        <div class="col-md-6 "  >
            <mdi-alert-common *ngIf="alertMessage" [messageInfo]="messageInfo"  style="min-height: 50px!important;"
                            [data1] = "dataMessageInfo1" 
                            [data2] = "dataMessageInfo2"
                            [messageType] = "messageType"
            ></mdi-alert-common>
        </div>
        <div class="col-md-3 "  >
            <button (click)="startUploadProcess() " mat-raised-button class="buttonTheme" [disabled]="!isUploadTodo" 
                style="font-size: 12px; width: 100%; white-space: pre-wrap!important;">
                <mat-icon>cloud_upload</mat-icon>
                <span style="margin-left: 10px">{{'uploadFiles' | translate}}</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2"  >   
            <div draggable="true" #dropArea class="dropzone" style="width: 100%; height: 150px;" >
                <div class="text-wrapper">
                    <div class="centered">{{'Drop your file here!' | translate}}</div>
                </div>
            </div>
        </div>
        <div class="container-fluid col-md-10" style="margin-bottom: 40px; width: 100%; line-height: 1.0;padding-left: 0px; padding-right: 0px;">
            <table class="table table-bordered table-condensed table-responsive stdTheme">
                <thead class="theadDocWork">
                    <tr class="trDocWork ">
                        <th  *ngFor="let titleColum of displayedColumns; let i=index"
                            [ngStyle]="{'width': styleColumns[i].width, 'text-align':'center'}">
                            {{titleColum | translate}} 
                        </th>
                        <th style="width: 5%"></th>
                    </tr>
                </thead>

                <tbody class="tbodyDocWork" >
                    <tr *ngFor="let file of files; let i = index" class="trDocWork" style="height: 60px!important;"> 
                        <td class="tdDocWork align-middle" 
                            [ngStyle]="{'width': styleColumns[0].width, 'text-align': styleColumns[0].textAlign }" >{{file.nameFile}}</td> 
                        <td class="tdDocWork align-middle" 
                            [ngStyle]="{'width': styleColumns[1].width, 'text-align': styleColumns[1].textAlign }">({{file.sizeFile}} bytes)</td> 
                        <td class="tdDocWork align-middle" 
                            [ngStyle]="{'width': styleColumns[2].width, 'text-align': styleColumns[2].textAlign }">{{file.typeFile}} </td>
                        <td *ngIf="!file.uploadedFile" class="tdDocWork align-middle" 
                            [ngStyle]="{'width': styleColumns[3].width, 'text-align': styleColumns[3].textAlign }">
                            {{'waiting' | translate}} 
                            <span class="fa fa-cloud-upload mx-auto" style="color:green;font-size: 20px;" ></span>
                        </td>
                        <td *ngIf="file.uploadedFile" class="tdDocWork align-middle" 
                            [ngStyle]="{'width': styleColumns[3].width, 'text-align': styleColumns[3].textAlign }">
                            {{'uploaded' | translate}} 
                            <span class="fa fa-cloud" style="color:red;font-size: 20px;" ></span>
                        </td>
                        <td style="width: 5%;padding-left: 0px; padding-right: 0px;">
                            <button mat-icon-button  #buttonSubmit type="reset" (click)="removeFile(i)" style="padding-left: 0px; padding-right: 0px;">
                                <mat-icon>delete_sweep</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>
