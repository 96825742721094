<h6 mat-dialog-title>
    {{modalTitle}}
    <br>
    {{workTitle}}
</h6>
<mat-dialog-content class="mat-typography" style="height: 100px">
    <div class="container-fluid stdTheme" >
        <form [formGroup]="workStatutForm" >
            <div class="flex-row row" style= "margin: 0px;">
                <div class="col-6">
                    <mat-form-field class="wksInput form-group" appearance="standard">
                        <mat-label>{{ 'workStatutCur' | translate }}</mat-label>
                        <input id="workStatutCur" #workStatutCur class="form-control inputTheme" 
                            matInput formControlName="workStatutCur" readonly>
                    </mat-form-field>
                </div>  
                <div class="col-6">
                    <mat-form-field class="wksInput form-group" appearance="standard">
                        <mat-label>{{ 'workStatutNew' | translate }}</mat-label>
                        <mat-select id="workStatutNew" #workStatutNew  formControlName="workStatutNew" 
                                panelClass="mdiselectcontent" ngDefaultControl required
                                (selectionChange)="onChangeWorkStatut(workStatutNew.value)" >
                            <mat-option class="selectOptionsTheme" *ngFor="let workstatutCur of workStatutsList" 
                                [value]="workstatutCur.value"  >{{workstatutCur.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <!--<button mat-button matDialogClose>Dismiss</button>-->
    <button style="margin-left:10px; margin-right: 40px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onNoClick()">{{'cancel' | translate}}</button>
    <button style="margin-left:30px; margin-right: 10px;" class="mat-raised-button buttonTheme" mat-dialog-close (click)="onOKClick()">{{'submit' | translate}}</button> 
</mat-dialog-actions>