<div class="flex">
  <div *ngIf="dataIsLoaded" style="display: block;">
    <canvas baseChart 
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)">
    </canvas>
    <span *ngIf="displayTime" style="color:grey">{{ 'generatedTime' | translate : [productedDate] }}</span>
  </div>
</div>
