<div class="container-fluid stdTheme " >
    <!--
    <div  class="targetPopover" 
        [mdePopoverTriggerFor]="detailWorkPopover" 
        mdePopoverTriggerOn="none" #target="mdePopoverTrigger">
    </div>
-->
    <div class="flex-row row stdTheme" style="margin: 0px;">
        <div class="col-12 table-responsive" style="height: 90%;">
            <table class="table stdTheme" id="historyWorksList"  >
                <thead class="theadWork">
                    <tr class="trWork">
                        <!--<th style="width: 5%;padding: 1px; text-align: center;vertical-align: middle;">#</th>-->
                        <th style="width: 10%; padding: 0px; text-align: center;" >{{'workRef' | translate}}</th>
                        <th style="width: 60%; padding: 0px; text-align: center;" >{{'workObject' | translate}}</th>
                        <th style="width: 20%; padding: 0px; text-align: center;">{{'workStatut' | translate}}</th>
                    </tr>
                </thead>
                <tbody class="tbodyWork" >
                    <tr *ngFor="let workCurRow of historyWorksList; let i = index" class="trWork " 
                            [class.data-selected]="i === selectedRow" 
                            (click)="setClickedRow(i)" 
                            style="width: 100%!important">
                        <td style="width: 10%; padding: 0px; text-align: center;" >{{workCurRow.workRef}}</td>
                        <td style="width: 60%; padding: 0px; text-align: left;">{{workCurRow.workObject}}</td>
                        <td style="width: 20%; padding: 0px; text-align: center;">{{workCurRow.workStatut  | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</div>
