<div>
    <form [formGroup]="tasksRefParentForm"  >
        <mat-form-field class="mdiinput form-group" appearance="standard">
            <mat-label>{{ 'taskRefParent' | translate }}</mat-label>
            <mat-select id="tasksRefParentSelect" placeholder="{{ 'Choose brand' | translate }}" formControlName="tasksRefParentSelect" 
                        panelClass="mdiselectcontent" #tasksRefParentSelect
                        (ngModelChange)="onChangeParent(tasksRefParentSelect.value)"
                        ngDefaultControl >
                <mat-select-header>
                    <div style="display: inline-block;" >
                        <button mat-icon-button matPrefix aria-label="search" >
                            <mat-icon>search</mat-icon>
                        </button>
                        <input class="searchTheme" #filterParent placeholder="{{ 'filterInput' | translate }}" 
                                (keyup)="onKeyParent($event)" >
                        <button mat-icon-button matSuffix aria-label="clear" 
                                *ngIf="filterParent.value" (click)="tasksRefParentSelect.value=''; onKeyParent('');">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme" *ngFor="let parentCur of tasksrefListFiltered" 
                    [value]="parentCur.value" 
                    matTooltip={{parentCur.otherData}} 
                    matTooltipClass="custom-tooltip multiline-tooltip"
                    matTooltipPosition="right">
                    {{parentCur.label}}
                </mat-option>
            </mat-select>                         
        </mat-form-field>
    </form>
</div>