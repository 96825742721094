import { Component, OnDestroy, OnInit, NgZone , ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { ColLabelRow, ReportInt  } from '../../../../job-data/models/job-data.model';
import { UserService } from '../../../../../services/user.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { environment } from '../../../../../../environments/environment';
import { TranslateService } from '../../../../../services/translate.service';
import { NotificationsService } from '../../../../common/services/notifications.service';

import { NotifUsers } from '../../../../../models/common.model';
import { EntityModel, UsersGrpModel } from '../../../../../models/user.model';
import { UserEnvtInt } from '../../../../job-data/models/job-data.model';
import { CommonNode } from '../../../../../models/common.model';
/*
interface MenuNode {
  id: string;
  name: string;
  report?: any;
  children?: MenuNode[];
}
*/
@Component({
  selector: 'mdi-jobreporthome',
  templateUrl: './jobreporthome.component.html',
  styleUrls: ['./jobreporthome.component.css']
})
export class JobReportHomeComponent implements OnInit, OnDestroy {

  nodesTree: any;
  dataNodes: CommonNode[];

  optionsTree: ITreeOptions;
  isAgGrid: boolean;
  isDashboard: boolean;
  reportToExecute: ReportInt;
  sidenavCollapsed = true;
  homeItem: any;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  private readonly onDestroy = new Subject<void>();
  notificationsSubscription: Subscription;

  constructor( private _userService: UserService,
              private _notificationsService: NotificationsService,
              private _router: Router, 
              private _translate: TranslateService,
              private _jobDataService: JobDataService,
              private _zone: NgZone,
              private snackBar: MatSnackBar) {
  }
  // hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;
  
  ngOnInit() {
    this.isAgGrid = false;
    this.isDashboard = false;
    this.buildOptionsTree();
    this.loadData();
    this.loadEntityLogged();
    this.loadGrpLogged();
    /*
    if (environment.connectStream) {
      this.notificationsSubscription = this._notificationsService.connectNotif('')
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: notif => {
          this._zone.run(() => {
            const msgData: NotifUsers = JSON.parse(notif);
            console.log(msgData.recipients, ' ' + msgData.contentMessage);
            this.openSnackBar(msgData.contentMessage, 'No-Action');
              // Do stuff here
          });
        }
      });
    }*/

  }
  buildOptionsTree() {
    this.optionsTree = {
      displayField: 'name',
      isExpandedField: 'expanded',
      idField: 'id',
      hasChildrenField: 'children',
      actionMapping: {
        mouse: {
          dblClick: (tree: any, node: any, $event: any) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            } else {
              this.toggleSidenav(node);
            }
          }
        },
        keys: {
          [KEYS.ENTER]: (tree: any , node: any , $event: any) => {
            node.expandAll();
          }
        }
      },
      nodeHeight: 18,
      allowDrag: (node: any) => {
        return true;
      },
      allowDrop: (node: any) => {
        return true;
      },
      allowDragoverStyling: true,
      levelPadding: 10,
      // useVirtualScroll: true,
      animateExpand: true,
      scrollOnActivate: true,
      animateSpeed: 30,
      animateAcceleration: 1.2,
      scrollContainer: document.documentElement // HTML
    };
  }
  loadData() {
   if (this._userService.getUserLogged() === undefined) { return; }
   this._jobDataService.buildMenu(this._userService.getUserLogged().entity, 
                                  this._userService.getUserLogged().userlang, 
                                  this._userService.getUserLogged().id)
   .pipe(takeUntil(this.onDestroy))
   .subscribe(
     data => {
      if (data.body === null) {
        this.nodesTree = [];
      } else {
        const jsonObject = JSON.parse(data.body[0].entitymenu);
        this.nodesTree = jsonObject.menutree;
        const jsonMenu = JSON.parse(data.body[0].otherData);
        const userEnvt = data.body[0].userEnvt as UserEnvtInt;
        if (userEnvt) {
          this._jobDataService.setUserEnvt(userEnvt);
        }
        const menuLabels: ColLabelRow [] = jsonMenu.labels;
        const menuReports: ReportInt [] = jsonMenu.reports;
        menuReports.sort(function(obj1, obj2) {
          return obj1.reportMenu > obj2.reportMenu ? 1 : -1;
        });
        // console.log( menuItems + ' ' + menuReports);
        this.buildTreeNodes(this.nodesTree); // new tree
        this.buildTreeItems(menuReports);  // new tree
        this.removeTreeNoChild(this.dataNodes);
        this.buildItems(this.nodesTree, menuReports);

        this.removeNoChildParents(this.nodesTree);
        this.settingLabels(this.nodesTree, menuLabels);

        
      }
     }, () => {
       // console.log('Entity unfound : ', _entity);
     }
   );
  }
  loadEntityLogged() {
    if (this._userService.getUserLogged() === undefined) { return; }
    this.getEntity(this._userService.getUserLogged().entity)
    .then ((entity: EntityModel) => {
      this._userService.getUserLogged().entityCur = entity;
    })
    .then((error) => {

    });
    
  }
  loadGrpLogged() {
    if (this._userService.getUserLogged() === undefined) { return; }
    this.getGrp(this._userService.getUserLogged().entity, this._userService.getUserLogged().usergroup)
    .then ((grpCur: UsersGrpModel) => {
      this._userService.getUserLogged().grpCur = grpCur;
    })
    .then((error) => {

    });
    
  }
  getEntity(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntity(_entity)
        .subscribe(
          data => {
            resolve(data.body);
          }, err => {
             reject(err);
          }
        );
      });

  }
  getGrp(_entity: string, _grp: string) {
    return new Promise((resolve, reject) => {
        this._userService.getGrp(_entity, _grp)
        .subscribe(
          data => {
            resolve(data.body);
          }, err => {
             reject(err);
          }
        );
      });

  }
  removeTreeNoChild(_nodesTree: any) {

    for (let iParent = _nodesTree.length ; iParent > 0 ; iParent-- ) {
      const objectCur = _nodesTree[iParent - 1];
      if ((objectCur.children === undefined) || (objectCur.children.length === 0)) { break ; }
      const child = objectCur.children;

      // console.log(child.name)

      if ((child.children !== undefined) && (child.children.length > 0)) {

        for (let iCur = child.children.length ; iCur > 0 ; iCur-- ) {
        // for (const nestedChild of child.children) {
          const nestedChild = child.children[iCur - 1];
   
          if ((nestedChild.children !== undefined) && (nestedChild.children.length > 0)) {
            this.removeNoChildParents(nestedChild);
          } else {
            if (nestedChild.report === undefined)  {
              child.children.splice(iCur - 1, 1);

            }
          }
        } 
      } else {
        if (child.length > 0 ) {
          for (let iRoot = child.length; iRoot > 0 ; iRoot--) {
            const childRoot = child[iRoot - 1];
            if (childRoot.action === undefined)  {
              child.splice(iRoot - 1, 1);
            }
          }
        }
        if (child.length === 0)  {
          _nodesTree.splice(iParent - 1, 1);
        }
      }
    }
  }
  removeNoChildParents(_nodesTree: any) {

    for (let iParent = _nodesTree.length ; iParent > 0 ; iParent-- ) {
      const objectCur = _nodesTree[iParent - 1];
      if ((objectCur.children === undefined) || (objectCur.children.length === 0)) { break ; }
      const child = objectCur.children;

      // console.log(child.name)

      if ((child.children !== undefined) && (child.children.length > 0)) {

        for (let iCur = child.children.length ; iCur > 0 ; iCur-- ) {
        // for (const nestedChild of child.children) {
          const nestedChild = child.children[iCur - 1];
   
          if ((nestedChild.children !== undefined) && (nestedChild.children.length > 0)) {
            this.removeNoChildParents(nestedChild);
          } else {
            if (nestedChild.report === undefined)  {
              child.children.splice(iCur - 1, 1);

            }
          }
        } 
      } else {
        if (child.length > 0 ) {
          for (let iRoot = child.length; iRoot > 0 ; iRoot--) {
            const childRoot = child[iRoot - 1];
            if (childRoot.report === undefined)  {
              child.splice(iRoot - 1, 1);
            }
          }
        }
        if (child.length === 0)  {
          _nodesTree.splice(iParent - 1, 1);
        }
      }
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array
  /*
  build nodes of tree
  */
  buildTreeNodes(_nodesTree: any) {
    this.dataNodes = [ ];
    let nodeTreeCur: CommonNode;
    for (const nodeCur of _nodesTree) {
      nodeTreeCur = {
        name: nodeCur.name,
        children: []
      };
      this.dataNodes.push(nodeTreeCur);
      if ((nodeCur.children !== undefined) && (nodeCur.children.length > 0)) {
        this.settingTreeNodes(nodeCur.children, nodeTreeCur);
      }
    }
  }
  settingTreeNodes(_nodeCur: any, _nodeTreeCur: CommonNode) {
    let nodeTreeCur: CommonNode;
    for ( const nodeCur of _nodeCur) {
      nodeTreeCur = {
        name: nodeCur.name,
        children: []
      };
      _nodeTreeCur.children.push(nodeTreeCur);
      if ((nodeCur.children !== undefined) && (nodeCur.children.length > 0)) {
        this.settingTreeNodes(nodeCur.children, _nodeTreeCur);
      }
    }
  }
  buildTreeItems(_menuReports: ReportInt []) {
    for (const report of _menuReports) {
      if (report.menuParent === 'HomeMenu') {
        const menuItem  = { 
          id: 0,
          name: report.reportLabel,
          report: report,
        };
        this.homeItem = menuItem;
      } else {
        this.settingTreeItems(this.dataNodes, report);
      }
    }
  }
  settingTreeItems (_nodesTree: any, _report: ReportInt)  { 
    let nodeItemCur: CommonNode;
    for (const nodeCur of _nodesTree ) {
      // console.log(child.name)

      if (_report.menuParent === nodeCur.name) {
        nodeItemCur = {
          name: _report.reportLabel,
          action: _report
        };
        nodeCur.children.push(nodeItemCur);
        break;
      } else if ((nodeCur.children !== undefined) && (nodeCur.children.length > 0)) {
          this.settingItems(nodeCur.children, _report);
      } 
    }
    return ;
  }
  buildItems(_nodesTree: any, menuReports: ReportInt []) {
    for (const report of menuReports) {
      if (report.menuParent === 'HomeMenu') {
        const menuItem  = { 
          id: 0,
          name: report.reportLabel,
          report: report,
        };
        this.homeItem = menuItem;
      } else {
        this.settingItems(_nodesTree, report);
      }
    }
  }
  settingItems (_nodesTree: any, _report: ReportInt)  { 
    for (const nodeCur of _nodesTree ) {
      // console.log(child.name)

        if (_report.menuParent === nodeCur.name) {
          const menuItem  = { 
            id: 0,
            name: _report.reportLabel,
            report: _report,
          };
          nodeCur.children.push(menuItem);
        }

      if ((nodeCur.children !== undefined) && (nodeCur.children.length > 0)) {

          this.settingItems(nodeCur.children, _report);
      } 
    }
    return ;
  }
  settingLabels (_nodesTree: any, _menuItems: any)  {
   
    for (const child of _nodesTree ) {
      // console.log(child.name)
      for (const menuItem of _menuItems) {
        if (menuItem.colName === child.name) {
          child.name = menuItem.colHeader;
        }
      }
      if ((child.children !== undefined) && (child.children.length > 0)) {
          this.settingLabels(child.children, _menuItems);
      } 
    }
    return ;
  }
  // https://material.angular.io/components/snack-bar/overview
  // evolution : http://www.eduforbetterment.com/snackbar-model-in-angualr/
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  capitalize(s) {
      return s && s[0].toUpperCase();
  }
  toggleSidenav(_eventArg: any) {

   //  if((_eventArg.data != undefined) && (_eventArg.data.requestRef)) console.log('Request : ' + _eventArg.data.requestRef);
   if (_eventArg === 'close')  {
    this.sidenavCollapsed = true;
    this.m_sidenav.close();
    return false;
   }
   this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.m_sidenav.close();
    } else {
      this.m_sidenav.open();
    }
  }
  closeSidenav() {
    this.sidenavCollapsed = !this.sidenavCollapsed;
  }
  displayHome() {
    // this._router.navigate(['/jobHome']);
    this.isDashboard = false;
    this.isAgGrid = false;
    this.toggleSidenav('');
    if (this.homeItem) {
      this.reportToExecute = this.homeItem.report;
      this.isDashboard = this.getOption(this.homeItem.report.reportOptions, 'dashboard');
      if (!this.isDashboard ) {
        this.isAgGrid = true;
      }
    }
  }
  getOption(_reportOptions: any, _option: string): boolean {

    let isOption = false;
    
    if (_reportOptions) {
      try {
        const jsonObject = JSON.parse(_reportOptions);
        for (const optCur  of jsonObject.options) {
          if (optCur.key === _option) {
            isOption = optCur.value;
          }
        }      
      } catch (error) {
        console.log(error);
      }
    }
    return isOption;
  }
  onReceptAction(event: any) {
    console.log(event);

    this.toggleSidenav('close');
    // console.log(event.node.data);
    this.isDashboard = false;
    this.isAgGrid = false;
    this.isDashboard = this.getOption(event.reportOptions, 'dashboard');
    if (!this.isDashboard ) {
      this.isAgGrid = true;
    }
    this.reportToExecute = event;
  }
  onTreeEvent(event: any) {
    if (event.eventName === 'focus') {
      if (event.node.data.report) {
        
        this.toggleSidenav('close');
        // console.log(event.node.data);
        this.isDashboard = false;
        this.isAgGrid = false;
        this.isDashboard = this.getOption(event.node.data.report.reportOptions, 'dashboard');
        if (!this.isDashboard ) {
          this.isAgGrid = true;
        }
        this.reportToExecute = event.node.data.report;
        return false;
      }
    }
  }
  ngOnDestroy() {

    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }
  logout(event: any) {
    event.preventDefault();
    this._userService.logout();
    this._router.navigate(['']);
  }

}
