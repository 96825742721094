<div id="columnsLists" class="container stdTheme" #refElement style="width: 950px" >
  <div class="columnsHidden stdTheme" style="width: 200px">
    <h6>{{'columnsHidden' | translate}}</h6>

<table class="table listcol" id="columnsHidden" class="listCol stdTheme" cdkDropList [cdkDropListData]="columnsHidden"
        [cdkDropListConnectedTo]="secondList" #firstList="cdkDropList"
        (cdkDropListDropped)="drop($event) ">
      <thead >
        <th style="width: 5%" ></th>
        <th style="width: 95%">{{'colNameReport' | translate}}</th>
      </thead>
      <tbody style="overflow-y: auto;  height: 480px;">
        <tr class="curCol curColHidden" *ngFor="let hiddenCur of columnsHidden; let i = index"  cdkDrag>
          <td style="width: 5%" ><mat-icon class="reorder" cdkDragHandle>reorder</mat-icon></td>
          <td style="width: 95%; text-align: left;">{{hiddenCur.colName }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div id="columnCentral" class="columnCentral stdTheme" style="width: 70px">
    <p>
      <button class="righttoleft" style="width:100%" (click)="displayTohidden();" [disabled]="!toHidden"> 
        <i class='fa fa-angle-double-left fa-2x'></i>
      </button>
    </p>
    <p>
      <button class="lefttoright" style="width:100%" (click)="hiddenTodisplay();" [disabled]="!toDisplayed">
        <i class='fa fa-angle-double-right fa-2x'></i>
      </button>
    </p>
    <!--
    <span class="centralIcon" style="position: absolute; top:40vh; left: 30px;"><i class='fa fa-angle-double-left'></i></span>
    <span class="centralIcon" style="position: absolute; top:45vh; left: 30px;"><i class='fa fa-angle-double-right'></i></span>
  -->
  </div>
  <div class="columnsDisplayed stdTheme" style="width: 680px">
    <h6>{{'columnsDisplayed' | translate}}</h6>
    <table class="table listcol stdTheme" id="columnsDisplayed" class="listCol" cdkDropList [cdkDropListData]="columnsDisplayed"
        [cdkDropListConnectedTo]="firstList" #secondList="cdkDropList"
        (cdkDropListDropped)="drop($event) ">
      <thead >
        <th style="width: 5%" ></th>
        <th style="width: 10%">{{'ranking' | translate}}</th>
        <th style="width: 40%">{{'colNameReport' | translate}}</th>
        <th style="width: 10%">{{'grouping' | translate}}</th>
        <th style="width: 10%">{{'filterInput' | translate}}</th>
        <th style="width: 10%">{{'filterList' | translate}}</th>
        <th style="width: 15%">{{'pinning' | translate}}</th>
      </thead>
      <tbody style="overflow-y: auto;  height: 480px;">
        <tr class="curCol curColDisplayed" *ngFor="let displayedCur of columnsDisplayed; let i = index"  cdkDrag>
          <td style="width: 5%" ><mat-icon class="reorder" cdkDragHandle>reorder</mat-icon></td>
          <td style="width: 10%">{{displayedCur.ranking }}</td>
          <td style="width: 40%; text-align: left;">{{displayedCur.colName }}</td>
          <td style="width: 10%" >
            <input type="checkbox" class="inputTheme" id="grouping" #grouping 
                    [(checked)]="displayedCur.grouping" (change)="checkChange('grouping',grouping.checked,i)">
            
          </td>
          <td style="width: 10%">
            <input type="checkbox" id="filterInput" #filterInput [(checked)]="displayedCur.filterInput" (change)="checkChange('filterInput',filterInput.checked,i)">
          </td>
          <td style="width: 10%">
            <input type="checkbox" id="filterList" #filterList [(checked)]="displayedCur.filterList" (change)="checkChange('filterList',filterList.checked,i)">
          </td>
          <td style="width: 15%">
            <select class="form-control stdTheme" id="pinning" [(value)]="displayedCur.pinning" #pinning (change)="checkChange('pinning',pinning,i)">
              <option value="none" [selected]="displayedCur.pinning =='none' || displayedCur.pinning ==null">{{'nonePinning' | translate}}</option>
              <option value="left" [selected]="displayedCur.pinning =='left'">{{'startPinning' | translate}}</option>
              <option value="right" [selected]="displayedCur.pinning =='right'">{{'endPinning' | translate}}</option>
            </select>
          </td>.
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div >
  <div class="container-fluid d-flex justify-content-between" >
    <button mat-raised-button (click)="register()" [disabled]="!validOK">
      <mat-icon>create</mat-icon>
      {{ 'submit' | translate }}
    </button>
    <button mat-raised-button (click)="doCancel()">
      <mat-icon>cancel</mat-icon>
      {{ 'cancel' | translate }}
    </button>
  </div>
</div>