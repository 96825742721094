import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Base64 } from 'js-base64';
import { environment } from '../../environments/environment';
import { TranslateService } from '../services/translate.service';
import { UserModel, UserCard, UserResponse, MenuModel, EntityModel, UsersGrpModel,
                HIERARCHY_ROLES, SUPPORTED_ROLES } from '../models/user.model';
import { CommonMethods } from '../job/common/tools/commonMethods';
import { OauthResp } from '../models/oAuth.model';
import { BodyResp } from '../models/oAuth.model';


@Injectable()
export class UserService {

  httpHeaders: HttpHeaders;
  httpParams: HttpParams;
  body:  string;
  tokenCur: string;
  userCur: UserModel;
  menuItems: any;
  hierarchyRoles: any;
  supportedRoles: any;
  accessToken: string; 
  jwtHelper = new JwtHelperService();
  entityLogged: EntityModel;
  public userEvents = new BehaviorSubject<UserModel>(undefined);
  public menuItemsEvents = new BehaviorSubject<string>(undefined);
  public onRegisterValid: EventEmitter<string> = new EventEmitter<string>();

  constructor(public  httpClient: HttpClient, private _translate: TranslateService) {
    this.hierarchyRoles = HIERARCHY_ROLES;
    this.supportedRoles = SUPPORTED_ROLES;
    // this.retrieveUser();
  }
  setEntityLogged(entityLogged: EntityModel): void {
    this.entityLogged = entityLogged;
  }
  getEntityLogged(): EntityModel {
    return this.entityLogged;
  }
  getNavigatorLanguage(): string {
    return navigator.language; 
  }
  deleteUser(userName: String): Observable<HttpResponse<String>> {
    let urlCur = `${environment.baseUrl}/users/deleteUser/`;
    urlCur = urlCur + userName;
    const body = userName;
    // this.retrieveUser();


    return this.httpClient
      .post<String>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );

  }
  register(userCard: UserCard, statutCall: String ): Observable<HttpResponse<UserResponse>> {
    let urlCur = `${environment.baseUrl}/users/updateUser/`;
    if (statutCall === 'createUser') {
      urlCur = `${environment.baseUrl}/users/addUser/`;
    }
    // updatePwd
    if (statutCall === 'updatePwd') {
      urlCur = `${environment.baseUrl}/users/updatePwd/`;
    }
      // changePwd
    if (statutCall === 'changePwd') {
        urlCur = `${environment.baseUrl}/users/changePwd/`;
      }
    if (statutCall === 'disableUser') {
        urlCur = `${environment.baseUrl}/users/disableUser/`;
      }
    // const body = { login, password, birthYear };
    const body = JSON.stringify(userCard);
    // this.retrieveUser();

    return this.httpClient
      .put<UserResponse>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );

  }
  getHierarchyRoles() {
    return this.hierarchyRoles;
  }
  getSupportedRoles() {
    return this.supportedRoles;
  }
  getUser( _userName: String): Observable<any> {
    const urlCur = `${environment.baseUrl}/users/userAccount/` + _userName;
    // this.retrieveUser();

    return this.httpClient.get(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getUserEmail( _email: String): Observable<any> {
    const urlCur = `${environment.baseUrl}/users/userEmail/` + _email;
    // this.retrieveUser();

    return this.httpClient.get(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getEntityUsersList(entity: string, role: string): Observable<HttpResponse<UserResponse[]>> {
    if (this.getUserLogged() === null) { return null; }
    if (role === undefined) {
      role = 'norole';
    }
    const urlCur = `${environment.baseUrl}/users/getEntityUsers/?` + 'entity=' + entity + '&role=' + role;
    const body = urlCur;
   
    return this.httpClient.post<UserResponse[]>(urlCur, body,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getUsersList(): Observable<HttpResponse<UserResponse[]>> {
    const urlCur = `${environment.baseUrl}/users/getAllUsers`;
    // this.retrieveUser();
    // reload auto
    if (this.getUserLogged() === null) { return null; }
    return this.httpClient.get<UserResponse[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  connectToken (credentials: any): Observable<HttpResponse<OauthResp>> {
    /*
    const paramDico: { [name: string]: string } = {};

    paramDico['username'] = credentials.login;
    paramDico['password'] = credentials.password;
*/
    const urlCur = `${environment.keycloakProp.url}/${environment.keycloakProp.realm}/protocol/openid-connect/token`;
    this.httpHeaders = new HttpHeaders ()
        .append('Content-Type', 'application/x-www-form-urlencoded')
        // .append('Access-Control-Allow-Origin', '*')
        .append('Accept', 'application/json')
        ;    
      
    const urlArgs = 'grant_type=password&username=' + credentials.login + '&password=' + credentials.password +
                    `&client_id=${environment.keycloakProp.clientId}&client_secret=${environment.keycloakProp.clientSecret}`  ;
   
    return this.httpClient
          .post<OauthResp>(urlCur, urlArgs, {
            // params: paramDico,
            headers: this.httpHeaders,
            observe: 'response',
          }).pipe(
          tap(userOauth => this.storeToken(userOauth)),
          catchError(this.handleErrorObservable), );
  }
  getUserDetail (credentials: any): Observable<HttpResponse<any>> {

    const urlCur = `${environment.baseUrl}/users/userDetail/` + credentials.login;
    // this.retrieveUser();

    return this.httpClient.get<any>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      }).pipe(
        tap(userOauth => this.storeLoggedInUser(userOauth)),
        catchError(this.handleErrorObservable) );
  }
  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
  public getAccessToken(): string {
    return this.accessToken;
  }
  storeToken(userOauth: any) {
    const userBody: BodyResp = userOauth.body;
    // https://www.baeldung.com/keycloak-custom-user-attributes
    // https://www.npmjs.com/package/@auth0/angular-jwt
    const payload  = this.jwtHelper.decodeToken(userBody.access_token);
    const organization = payload.organization; 
    // console.log(organization);
    const mjlProperties = '';
    this.httpHeaders = new HttpHeaders ({
        'Authorization': `Bearer ${userBody.access_token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-MjlProperties': `${mjlProperties}`
      });

    this.accessToken = userBody.access_token;
   // this._translate.use(userBody.userlang);
  }
  storeLoggedInUser(userOauth: any) {
    const userBody: BodyResp = userOauth.body;

    const roles = CommonMethods.arrayStringToString(CommonMethods.jsonPropToArray(userBody.authorities, 'authority'), ',');
    const mjlProperties = userBody.username + ';' 
                    + userBody.accessentities + ';' 
                    + userBody.accessgrps + ';' 
                    + userBody.dataHabil + ';' 
                    + userBody.userlang;

    this.httpHeaders = new HttpHeaders ({
        'Authorization': `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-MjlProperties': `${mjlProperties}`
      });

    this.userEvents.next(this.oAuthToUser(userOauth));
  }

  public getUserLogged(): UserModel {
    return this.userCur;
  }
  // convert oAuth Response to User
  oAuthToUser(oAuthCur: OauthResp): UserModel {
    const bodyCur: BodyResp = oAuthCur.body;

    const userConvert: UserModel = {
      id: bodyCur.id,
      username: bodyCur.username,
      password: null,
      firstname: bodyCur.firstname,
      lastname: bodyCur.lastname,
      sexe: bodyCur.sexe,
      birthday: bodyCur.birthday,
      email: bodyCur.email,
      datafilter: bodyCur.datafilter,
      authorities: bodyCur.authorities,
      accountNonExpired: bodyCur.accountNonExpired,
      accountNonLocked: bodyCur.accountNonLocked,
      credentialsNonExpired: bodyCur.credentialsNonExpired,
      enabled: bodyCur.enabled,
      entity: bodyCur.entity,
      profile: bodyCur.profile,
      usergroup: bodyCur.usergroup,
      bearerToken: this.accessToken,
      jti: bodyCur.jti,
      loglang: bodyCur.additionalInfo['loglang'],
      userdebug: bodyCur.additionalInfo['userdebug'],
      userlang: bodyCur.userlang,
      extendedgrp: bodyCur.extendedgrp,
      extendedentities: bodyCur.extendedentities,
      accessentities: bodyCur.accessentities,
      accessgrps: bodyCur.accessgrps,
      otherData: bodyCur.otherdata,
      forbiddenWords: bodyCur.additionalInfo['forbiddenWords'],
      authorizedFunctionsList: bodyCur.additionalInfo['authorizedFunctionsList'],
      dataCategories: bodyCur.additionalInfo['dataCategories'],
      dataCategoriesTypeDefault: bodyCur.additionalInfo['dataCategoriesTypeDefault'],
      dataCategoriesLevelDefault: bodyCur.additionalInfo['dataCategoriesLevelDefault'],
      dataHabil: bodyCur.additionalInfo['dataHabil'],
      entitycountry: bodyCur.entitycountry,
      entitylang: bodyCur.entitylang,
      applications: bodyCur.applications,
      isMaster: bodyCur.additionalInfo['entityMaster'] as boolean
    };
    this.userCur = userConvert;
    return userConvert;
  }
  getHeaders(): HttpHeaders {
    return this.httpHeaders;
  }
  logout() {
    this.userEvents.next(null);
    // window.localStorage.removeItem('rememberMe');
    // window.localStorage.removeItem('rememberLang');
    // window.localStorage.removeItem('menuItems');
    // this.httpHeaders.delete('Authorization');
  }
  /*========================================================================= Menu --*/
  getMenu(): Observable<HttpResponse<MenuModel[]>> {
    const urlCur = `${environment.baseUrl}/organization/menu/`;
    // this.retrieveUser();

    return this.httpClient.get<MenuModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  public setMenuItems(_menuItems: any) {
    this.menuItems = _menuItems;
    this.menuItemsEvents.next(_menuItems);
  }
  public getMenuItems(): any {
    return this.menuItems;
  }
  /*========================================================================= Entities --*/
  getEntities(): Observable<HttpResponse<EntityModel[]>> {
    const urlCur = `${environment.baseUrl}/organization/entities/`;
    // this.retrieveUser();

    return this.httpClient.get<EntityModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getEntity(_entity: string): Observable<HttpResponse<EntityModel>> {
    const urlCur = `${environment.baseUrl}/organization/entities/` + _entity;
    // this.retrieveUser();

    return this.httpClient.get<EntityModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  saveEntity(_entityCard: EntityModel, statutCall: String ): Observable<HttpResponse<EntityModel>> {
    let urlCur = `${environment.baseUrl}/organization/entities/updateEntity/`;
    if (statutCall === 'createEntity') {
      urlCur = `${environment.baseUrl}/organization/entities/addEntity/`;
    }

    const body = JSON.stringify(_entityCard);
    // this.retrieveUser();

    return this.httpClient
      .put<EntityModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  deleteEntity(_entity: String): Observable<HttpResponse<String>> {
    const urlCur = `${environment.baseUrl}/organization/entities/` + _entity;
    // this.retrieveUser();
    return this.httpClient
      .delete<String>(urlCur, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  /*========================================================================= UsersGrp --*/
  getGrps(): Observable<HttpResponse<UsersGrpModel[]>> {
    const urlCur = `${environment.baseUrl}/organization/usersgrp/`;
    // this.retrieveUser();

    return this.httpClient.get<UsersGrpModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getEntityGrps(_entity: string): Observable<HttpResponse<UsersGrpModel[]>> {
    const urlCur = `${environment.baseUrl}/organization/usersgrp/entity/` + _entity;
    // this.retrieveUser();

    return this.httpClient.get<UsersGrpModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  getGrp(_entity: string, _usersGrp: string): Observable<HttpResponse<UsersGrpModel>> {
    const urlCur = `${environment.baseUrl}/organization/usersgrp/` + _entity + ':' + _usersGrp;
    // this.retrieveUser();

    return this.httpClient.get<UsersGrpModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  saveGrp(_grpCard: UsersGrpModel, statutCall: String ): Observable<HttpResponse<UsersGrpModel>> {
    let urlCur = `${environment.baseUrl}/organization/usersgrp/updateUsergrp/`;
    if (statutCall === 'createGrp') {
      urlCur = `${environment.baseUrl}/organization/usersgrp/addUsergrp/`;
    }

    const body = JSON.stringify(_grpCard);
    // this.retrieveUser();

    return this.httpClient
      .put<UsersGrpModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  deleteGrp(_entity: string, _usersGrp: string): Observable<HttpResponse<String>> {
    const urlCur = `${environment.baseUrl}/organization/usersgrp/`  + _entity + ':' + _usersGrp;
    // this.retrieveUser();
    return this.httpClient
      .delete<String>(urlCur, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
}
