import { Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { FormFieldFmt } from '../../../models/common.model';

@Directive({
  selector: 'input[mdiFormatField]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormatFieldDirective}],
})
export class FormatFieldDirective implements OnChanges {

  private valueCur: string | null;
  argsJson: FormFieldFmt;

  // @Input() mdiFormatField = () => {};
  @Input() mdiFormatField: any;
  constructor(private elementRef: ElementRef<HTMLInputElement> ) {}

  ngOnChanges(changes: SimpleChanges): void {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'mdiFormatField': {
            this.mdiFormatField = changes.mdiFormatField.currentValue;
            if (this.mdiFormatField !== '') {
              try {
                // this.argsJson = JSON.parse(JSON.stringify(this.mdiFormatField));
                const tmpJson = this.mdiFormatField.replace(/'/g, '"');
                this.argsJson = JSON.parse(tmpJson);
              } catch (error) {
                console.error(error);

              }
            }
            break;
          }
          case 'value': {
            this.valueCur = changes.value.currentValue;
            this.formatValue(this.valueCur);
            break;
          }
        } // end switch
      } // end if
    }

  }
  get value(): string | null {
    return this.valueCur;
  }
  
  @Input('value')
  set value(value: string | null) {
    this.valueCur = value;
    this.formatValue(this.valueCur);
  }
  
  private formatValue(inputValue: string | null): void {
    if (this.argsJson === undefined) {
      return;
    }
    let matchesGroup: any;
    // console.log(inputValue + ' ' + this.argsJson.pattern + ' ' + this.argsJson.displayFmt);
    if ((inputValue !== undefined) && (inputValue !== null)) {
      if (this.argsJson.pattern === '.*') {
        this.elementRef.nativeElement.value = inputValue;
        return;
      }
      const pattern = new RegExp(this.argsJson.pattern, 'i');
      if (this.argsJson.fieldType !== 'email') {
        if (this.argsJson.upperCase) {
          matchesGroup = inputValue.toUpperCase().replace(pattern, this.argsJson.displayFmt);
        } else {
          matchesGroup = inputValue.replace(pattern, this.argsJson.displayFmt);
        }
      } else {
        matchesGroup = inputValue.replace(pattern, this.argsJson.displayFmt);
      }
      this.elementRef.nativeElement.value = matchesGroup;
    } else {
      this.elementRef.nativeElement.value = '';
    }

  }
  private unFormatValue(): void {
    if (this.argsJson === undefined) {
      this.valueCur = '';
      this.elementRef.nativeElement.value = '';
    } else {
      const value = this.elementRef.nativeElement.value;
      const pattern = new RegExp(this.argsJson.displayUnfmt, 'g');
      // this._value = value.replace(/[^\d.- ]/g, '');
      this.valueCur = value.replace(pattern, '');
      if (value) {
        this.elementRef.nativeElement.value = this.valueCur;
      } else {
        this.elementRef.nativeElement.value = '';
      }
    }
  }
  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    // here we cut any non numerical symbols
    if (this.argsJson === undefined) {
      this.valueCur = '';
    } else {
      const pattern = new RegExp(this.argsJson.displayUnfmt, 'g');
      // this._value = value.replace(/[^\d.-]/g, '');
      this.valueCur = value.replace(pattern, '');
    }
  }

  @HostListener('blur')
  _onBlur(): void {
    this.formatValue(this.valueCur);
  }
  @HostListener('change')
  _onChange(): void {
    this.formatValue(this.valueCur);
  }
  @HostListener('onChange')
  _onLoad(): void {
    this.formatValue(this.valueCur);
  }

  @HostListener('focus')
  onFocus(): void {
    this.unFormatValue(); // remove commas for editing purpose
  }
}
