import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material';

import { UserLogged } from '../../../../models/user.model';
import { UserService } from '../../../../services/user.service';
import { TranslateService } from '../../../../services/translate.service';
import { CommonMethods } from '../../tools/commonMethods';
import { environment } from '../../../../../environments/environment';
import { ThemeService } from '../../../../services/theme.service'; 
import { WksCentralService } from '../../../job-wks/services/wks-central.service';
import { ItemMenu } from '../../../../models/common.model';
import { LabelValue } from '../../../../models/data.model';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'mdi-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  @Input() isHome: boolean;
  @Input() isReportHome: boolean;


  isReportActivate: boolean;
  isNwsActivate: boolean;
  itemList: any;
  wksItemList: any;
  itemRecept: any;
  wksItemRecept: any;

  userLogged = new UserLogged();
  displayMenuBar = false;
  currentAutorities: any;
  appTitle = environment.appTitle;
  appSigle = environment.appSigle;
  themeIsDark: boolean;
  workshop: boolean;
  versionFE: string;
  versionBE: string;

  buildTimestampFE: string;
  buildTimestampBE: string;

  entitiesList: LabelValue[];
  
  constructor(private _userService: UserService, 
                private themeService: ThemeService,
                private authService: AuthService,
                private _router: Router, 
                private _translate: TranslateService,
                private _wksCentralService: WksCentralService ) {

                  this.workshop = environment.workshop;
  }

  ngOnInit() {
    this.versionFE = environment.version;
    this.buildTimestampFE = 'Date : ' + environment.buildTimestamp;
    try  {
      this.versionBE = this._wksCentralService.getWksEntityCur().versionBE;
      this.buildTimestampBE = 'Date : ' + this._wksCentralService.getWksEntityCur().versionDateBE;
    } catch (error) {
      this._router.navigate(['']);
    }

    this.themeIsDark = this.themeService.isDarkTheme();
    this.isReportActivate = false;
    this.isNwsActivate = false;
    this._userService.userEvents.subscribe ( data => {
      if (data) {
        const curUser1 = data;
        this.userLogged.id = curUser1.id;
        this.userLogged.username = curUser1.username;
        this.userLogged.firstname = curUser1.firstname;
        this.userLogged.lastname = curUser1.lastname;
        this.userLogged.authorities = curUser1.authorities;
        this.userLogged.entity = curUser1.entity;
        this.userLogged.isMaster = curUser1.isMaster;
        const applications = curUser1.applications;
        if (applications === 'ALL') {
          this.isReportActivate = true;
          this.isNwsActivate = true;
        } else if (applications.indexOf('nautiko') > -1) {
          this.isNwsActivate = true;
        } else if (applications.indexOf('reporting') > -1) {
          this.isReportActivate = true;
        }
        this.currentAutorities = CommonMethods.arrayStringToString(CommonMethods.jsonPropToArray(curUser1.authorities, 'authority'), ',');
        if (this.userLogged.isMaster) {
          this.getListEntities();
        }
      } else {
        this._router.navigate(['']);
      }
    });
    // Standard Mneu
    this._userService.menuItemsEvents.subscribe ( valueMenu => {
      // this.itemList = [{ label: '', routing: '' }];
      if (this.itemRecept === valueMenu) {
        return;
      }
      if (valueMenu === 'null') {
        return;
      }
      if (valueMenu) {
        this.itemList = [];
        this.itemRecept = valueMenu;
        const autorizedItems = [];
        const menuList = JSON.parse(valueMenu);

        for (const item of menuList) {
          if (!this.isReportActivate) {
            if ( item.itemLabel !== 'organization' &&  item.itemParent !== 'organization') {
              continue;
            }
          }
          if (this.validateItemRole(item.itemRoles)) {
            autorizedItems.push(item);
          }
        }
        this.itemList = this.constructMenu(autorizedItems);
      }
    }) ;
    // WKS -MENU
    this._wksCentralService.wksMenuItemsEvents.subscribe ( valueMenu => {
      // this.itemList = [{ label: '', routing: '' }];
      if (this.wksItemRecept === valueMenu) {
        return;
      }
      if (valueMenu) {
        this.wksItemList = [];
        this.wksItemRecept = valueMenu;
        const autorizedItems = [];
        const menuList = JSON.parse(valueMenu);
        if (menuList === null) {
          return;
        }
        for (const item of menuList) {
          if (this.validateItemRole(item.itemRoles)) {
            autorizedItems.push(item);
          }
        }
        this.wksItemList = this.constructMenu(autorizedItems);
        this._wksCentralService.setWksMenuButton(autorizedItems);
      }
    }) ;

  }
  toggleDarkTheme(event: any) {
    this.themeIsDark = !this.themeIsDark;
    this.themeService.setDarkTheme(this.themeIsDark);
  }
/**
 * Storing menus by parent in map
 * @param _autorizedItems 
 */
  private constructMenu(_autorizedItems: ItemMenu[]) {

    const mapChildren = new Map<string, ItemMenu[]>();
    let listItems: ItemMenu[];

    const listWitness: ItemMenu[] = [];
    for (const item of _autorizedItems) {
      listItems = mapChildren.get(item.itemParent);

      if (listItems) {
        listItems.push(item);
        listItems.sort(function (x, y) {
          const a = x.itemOrder.toUpperCase();
          const b = y.itemOrder.toUpperCase();
          if (a > b) {
              return 1;
          }
          if (a < b) {
              return -1;
          }
          return 0;
        });
        mapChildren.set(item.itemParent, listItems);
      } else {
        listItems = [];
        listItems.push(item);

        mapChildren.set(item.itemParent, listItems);
      } 
    } // end loop _autorizedItems
    // const newMenu = this.buildMenu(_autorizedItems, 'root', []);
    // console.log(JSON.stringify(newMenu));
    // this.organizeMenu(mapChildren, 'root');
    // console.log(JSON.stringify(this.organizedMenu));
    return this.organizeMenu(mapChildren, 'root');
  }
  getListEntities() {
    this.entitiesList = [];
    this._userService.getEntities()
    .subscribe(
      data => {
        const response = data.body as  Array<any>;

        for (const entityItem of response) {
          const item =  {
            label: entityItem.entitylabel,
            value: entityItem.entity
          };
          this.entitiesList.push(item);
        }
        this.entitiesList.sort((obj1: LabelValue, obj2: LabelValue) => {
          return obj1.label.toLowerCase() > obj2.label.toLowerCase() ? 1 : -1;
        });
        // console.log(JSON.stringify(this.entitiesList));
      }
    );
  }
  buildMenu(_autorizedItems: ItemMenu[], _parent: string, _witNess: ItemMenu[]) {
    if ( _parent === 'root') {
      for (const item of _autorizedItems) {
        if (item.itemParent !== _parent) {
          continue;
        }
        _witNess.push(item);
        _witNess.sort(function (x, y) {
          const a = x.itemOrder.toUpperCase();
          const b = y.itemOrder.toUpperCase();
          if (a > b) {
              return 1;
          }
          if (a < b) {
              return -1;
          }
          return 0;
        });
      }
    }
    for (const item of _witNess) {
      item.itemChildren = this.buildParent(_autorizedItems, item.itemLabel);
      for ( const nestedItem of item.itemChildren) {
        nestedItem.itemChildren = this.buildParent(_autorizedItems, nestedItem.itemLabel);
      }
    }
    return _witNess;
  }
  buildParent(_autorizedItems: ItemMenu[], _parent: string) {
    const listItems: ItemMenu[] = [];
    for (const item of _autorizedItems) {
      if (item.itemParent !== _parent) {
        continue;
      }
      listItems.push(item);
      listItems.sort(function (x, y) {
        const a = x.itemOrder.toUpperCase();
        const b = y.itemOrder.toUpperCase();
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
      });
    }
    return listItems;
  }
  /**
   * organize start menu tree
   * @param _mapChildren 
   * @param _parentItem 
   */
  private organizeMenu(_mapChildren: Map<string, any []>, _parentItem: string): any[] {
    const currentItems = [];
    const listItems = _mapChildren.get(_parentItem);
    if (listItems === undefined) {
      return;
    }
    for (const item of listItems) {
      // testing is parent
      const childrenList = _mapChildren.get(item.itemLabel);
      if (childrenList) {
        currentItems.push({
          'label': item.itemLabel,
          'isTrigger': true,
          'children': this.getChildrenItems(_mapChildren, item.itemLabel),
        });
      } else {
        if (item.itemRoutingArgs) {
          currentItems.push({
            'label': item.itemLabel,
            'isTrigger': false,
            'routing': [item.itemRoutingPath, item.itemRoutingArgs]
            });
        } else {
          currentItems.push({
            'label': item.itemLabel,
            'isTrigger': false,
            'routing': [item.itemRoutingPath]
            });
        }

      }
    }
    return currentItems;
  }
  /**
   * organize menu tree for children 
   * @param _mapChildren 
   * @param _parentItem 
   */
  private getChildrenItems(_mapChildren: Map<string, any []>, _parentItem: string): any[] {
    const currentItems = [];
    const listItems = _mapChildren.get(_parentItem);
    for (const item of listItems) {
      const childrenList = _mapChildren.get(item.itemLabel);
      if (childrenList) {
        currentItems.push({
          'label': item.itemLabel,
          'isTrigger': true,
          'children': this.getChildrenItems(_mapChildren, item.itemLabel),
        });
      } else {
      currentItems.push({
        'label': item.itemLabel,
        'isTrigger': false,
        'routing': [item.itemRoutingPath]
        });
      }
    }
    return currentItems;
  }

 /**
  * Validation Item with role
  * @param _itemRoles 
  */
  private validateItemRole(_itemRoles: string): boolean {
    const itemRoles = CommonMethods.listToArray(_itemRoles, ',');
    for (const role of itemRoles) {
      const pattern = new RegExp(role);
      if (pattern.test(this.currentAutorities)) {
        return true;
      }
    }
    return false;
  }
  logout (event: any) {
    event.preventDefault();
    this._userService.logout();
    this._router.navigate(['']);

  }
  returnHome() {
    this._router.navigate(['/jobHome']);
  }
  changeEntity(entitySelected: string) {
    this.authService.loadEnvironment(this.userLogged, entitySelected);
  } 
  navigateToReportHome() {
    this.isReportHome = true;
    this._router.navigate(['/jobReportHome']);
  }

}
