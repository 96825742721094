<div id="home-content" #refElement class="mat-elevation-z8  stdTheme" style="width: 50%;" >
    <mat-card class="register-card stdTheme" layout="column" flex >
     <!-- <mat-card-header>
        <mat-card-title  >
          <span #titleCard>{{ registerTitle | translate }}</span>
        </mat-card-title>
      </mat-card-header> -->
      <mat-card-actions>
        <div id="registration-error" class="alert alert-danger" *ngIf="registrationFailed">
          <button type="button" class="close buttonTheme" aria-label="Close" (click)="registrationFailed = false;">
            <!-- <span aria-hidden="true">&#215;</span> -->
          </button>
          {{ messageTranslate }}
        </div>
      </mat-card-actions>
      <form  [formGroup]="reportForm"  >
        <mat-card-content class="stdTheme" >
          <div class="flex-row row">
            <div class="col stdTheme" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'reportLabel' | translate }}</mat-label>
                <input id="reportLabel" class="form-control" formControlName="reportLabel" 
                        matInput #input minlength="3" [readonly]="statutCall == 'updateReport'" placeholder="{{ 'reportLabel' | translate }}" required>
                <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
                <mat-error *ngIf="reportLabelCtrl.invalid">{{getErrorMessage('reportLabel')}}</mat-error>
                <mat-error *ngIf="isReportFound">{{getErrorMessage('isreportFound')}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col stdTheme" style="margin-right: 0em; padding-right: 0em">
              <mat-chip-list>
                <mat-chip class="stdTheme">{{ 'reportVersion' | translate }} {{ reportVersion }}</mat-chip>
              </mat-chip-list>
            </div>
          </div>
  
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'reportStatut' | translate }}</mat-label>
                <mat-select id="reportStatut" placeholder="{{ 'Choose one statut' | translate }}" formControlName="reportStatut" 
                            panelClass="mdiselectcontent"
                            ngDefaultControl required>
                  <mat-option class="selectOptionsTheme" *ngFor="let statutCur of statutsReportList" [value]="statutCur">{{ statutCur}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-row row">
            <mat-form-field class="mdiselect form-group" appearance="standard">
              <mat-label>{{ 'accessEntities' | translate }}</mat-label>
              <mat-select #selectAccessEntities placeholder="{{ 'accessEntities' | translate }}" 
                          panelClass="mdiselectcontent" 
                          disableOptionCentering formControlName="accessEntities" multiple ngDefaultControl required>
                <mat-select-header>
                  <div class="flex-row button-row text-center" >
                    <button mat-raised-button color="primary"  (click)="selectAccessEntities.toggle()">
                      {{'Close' | translate}}
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </mat-select-header>
                <mat-option class="selectOptionsTheme" *ngFor="let entity of entitiesList" [value]="entity" (onSelectionChange)="loadGrps($event)">{{ entity }}</mat-option>
              </mat-select>
              <mat-error *ngIf="accessEntitiesCtrl.invalid">{{getErrorMessage('accessEntities')}}</mat-error>
            </mat-form-field>
          </div> 
          <div class="flex-row row" style="margin-bottom: 0px">
            <mat-form-field class="mdiselect form-group" appearance="standard" style="max-height: 50px" >
              <mat-label>{{ 'accessGrp' | translate }}</mat-label>
                <mat-select #selectAccessGrp placeholder="{{ 'accessGrp' | translate }}" 
                          panelClass="mdiselectcontent" 
                          disableOptionCentering formControlName="accessGrp" multiple ngDefaultControl required>
                  <mat-select-header>
                    <div class="flex-row button-row text-center" >
                      <button mat-raised-button color="primary"  (click)="selectAccessGrp.toggle()">
                        {{'Close' | translate}}
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-select-header>
                  <mat-option class="selectOptionsTheme" *ngFor="let accessGrp of grpsList" [value]="accessGrp">{{ accessGrp }}</mat-option>
              </mat-select>
              <mat-error *ngIf="accessGrpCtrl.invalid">{{getErrorMessage('accessGrp')}}</mat-error>
            </mat-form-field>
          </div> 
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em; margin-top: 0em">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'requestRefSelect' | translate }}</mat-label>
                <mat-select id="requestRefSelect" #requestRefSelect placeholder="{{ 'Choose one request' | translate }}" formControlName="requestRefSelect" 
                            panelClass="mdiselectcontent"
                            ngDefaultControl required (selectionChange)="setReportColumns(requestRefSelect.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let requestCur of requestsList" [value]="requestCur">{{ requestCur.requestLabel}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col" style="margin-left: 0em; padding-left: 0em; margin-top: 0em">
              <mat-form-field class="mdiinput form-group" appearance="standard" style=" margin-top: 0em">
                <mat-label>{{ 'reportSample' | translate }}</mat-label>
                <input type="text" pattern="[0-9]+" id="reportSample" class="form-control" formControlName="reportSample" 
                        matInput #input  placeholder="{{ 'reportSample' | translate }}" (keypress)="numericOnly($event)" >
              </mat-form-field>
            </div>
          </div>
          <div class="flex-row row">
            <div class="col" style="margin-left: 0em; padding-left: 0em;">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'menuParent' | translate }}</mat-label>
                <mat-select id="menuParentSelect" #menuParentSelect placeholder="{{ 'Choose one parent' | translate }}" formControlName="menuParentSelect" 
                            panelClass="mdiselectcontent"
                            ngDefaultControl (selectionChange)="setMenuParent(menuParentSelect.value)">
                  <mat-option class="selectOptionsTheme" *ngFor="let parentCur of parentsList" [value]="parentCur.menuName">{{ parentCur.menuLabel}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col" style="margin-left: 0em; padding-left: 0em;">
                <mat-form-field class="mdiselect form-group" appearance="standard">
                  <mat-label>{{ 'menuRank' | translate }}</mat-label>
                  <mat-select id="menuRankSelect" #menuRankSelect placeholder="{{ 'Choose one rank' | translate }}" formControlName="menuRankSelect" 
                              panelClass="mdiselectcontent"
                              ngDefaultControl (selectionChange)="setMenuRank(menuRankSelect.value)">
                    <mat-option class="selectOptionsTheme" *ngFor="let rankCur of ranksList" [value]="rankCur">{{ rankCur}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
          </div>

          <div class="flex-row row" style="margin-top: 20px;">
            <div class="col" style="margin-left: 0em; padding-left: 0em">
              <!--
              <mat-checkbox  class="inputTheme" labelPosition="before" formControlName="reportPagination" >
                  {{ 'reportPagination' | translate }}
              </mat-checkbox>
              -->
              <div class="checkbox">
                <label class="labelLine" style="margin-left: 10px; margin-right: 10px" >{{ 'reportPagination' | translate }}</label>
                <input type="checkbox" id="reportPagination" #reportPagination class="inputTheme" formControlName="reportPagination" >
              </div>
            </div>
            <div class="col" style="margin-right: 0em; padding-right: 0em">
              <!--
              <mat-checkbox  class="inputTheme" labelPosition="before" formControlName="reportDocument" >
                  {{ 'reportDocument' | translate }}
              </mat-checkbox>
              -->
              <div class="checkbox">
                <label class="labelLine" style="margin-left: 10px; margin-right: 10px" >{{ 'reportDocument' | translate }}</label>
                <input type="checkbox" id="reportDocument" #reportDocument class="inputTheme" formControlName="reportDocument" >
              </div>
            </div>
            <div class="col" style="margin-right: 0em; padding-right: 0em">
              <!--
              <mat-checkbox  class="inputTheme" labelPosition="before" formControlName="reportDashboard" >
                  {{ 'reportDashboard' | translate }}
              </mat-checkbox>
            -->
              <div class="checkbox">
                <label class="labelLine" style="margin-left: 10px; margin-right: 10px" > {{ 'reportDashboard' | translate }}</label>
                <input type="checkbox" id="reportDashboard" #reportDashboard class="inputTheme" formControlName="reportDashboard" >
              </div>
            </div>
          </div>

        </mat-card-content>
        <mat-card-actions>
          <div >
            <div class="flex-row button-row text-center mdiinput" style="margin-top: 20px;">
              <button mat-raised-button  #buttonSubmit type="submit" (click)="register()" [disabled]="!reportForm.valid || isReportFound">
                <mat-icon>create</mat-icon>
                {{ 'submit' | translate }}
              </button>
              <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                <mat-icon>cancel</mat-icon>
                {{ 'cancel' | translate }}
              </button>
            </div>
          </div>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  