import { Component, OnInit, Output, Input, ViewChild, NgZone, ViewEncapsulation, 
          EventEmitter, SimpleChanges, HostListener, OnChanges } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import {take} from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RemoteFilesService } from '../../../../../common/services/remote-files.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { BootstrapI18nService } from '../../../../../../i18n/bootstrap/bootstrap-i18n.service';

import { ReadFile, CommonReport, AlertType, LevelType } from '../../../../../../models/common.model';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksWorksModel, WksWorksDocs, PhotoData  } from '../../../../models/wks-works.model';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { LabelValue} from '../../../../../../models/data.model';
import { environment } from '../../../../../../../environments/environment';


@Component({
  selector: 'mdi-works-docs',
  templateUrl: './works-docs.component.html',
  styleUrls: ['./works-docs.component.css']
})
export class DocworksWksComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() fileExt: String = 'JPEG, JPG, GIF, PNG, PDF, MP4, pdf';
  @Input() actionType: any;
  @Input() wksWorks: WksWorksModel;
  @Input() wksEntity: WksEntityModel;
  @Input() tagsArg: LabelValue[];
  @Input() title: string;
  @Input() photoDataBase64: string;

  @Output() dataOut = new EventEmitter<any>();

  private readonly onDestroy = new Subject<void>();
  // upload files
  // fileExt: String = 'JPG, GIF, PNG, PDF, TXT, CSV, XLSX';

  fileRead: ReadFile;
  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  @Input() maxSizePdf = 15; // 5MB
  progress: { percentage: number } = { percentage: 0 };
  listFilesJson: any;
  
  filesRead: Array<ReadFile> = [];
  // public readMode = ReadMode.dataURL;

  filesDeposit: Array<ReadFile> = [];
  newListFile: WksWorksDocs[];

  // public readMode = ReadMode.dataURL;
  public picked: ReadFile;
  public status: string;
  displayedColumns: any;
  styleColumns: any;
  isUploadTodo = false;
  isFilesOK = false;
  isUploadInProgress = false;
  // end upload file
  alertMessage: boolean;
  messageData = {
    messageInfo: '',
    dataMessageInfo1: '',
    dataMessageInfo2: '',
    messageType: ''
    };
  subscription: Subscription;

  actionValue: string;
  isSearch: boolean;
  isReadOnly: boolean;
  isInput: boolean;
  isAddOne: boolean;
  isRegistered: boolean;
  isLoaded: boolean;
  doComment: boolean;
  uploadPhotos: boolean; 

  docCommentCtrl: FormControl;
  docworksForm: FormGroup;
  
  titleCard: string;
  messageTranslate: string;
  selectTags: boolean; 
  tagValue: string;

  datePickerConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  maxDate: Date;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();
  minDayDate: Date;
  maxDayDate: Date;
  currentDateValue: Date[] = [];
  defaultDateRef: Date;
  timeFormat: string;
  dateFormat: string;
  fieldFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
    language: string;

  };
  dateFormatMoment: string;
  sequenceDate: string;
  sequenceDateInput: any;
  attSequence: string; 
  sequenceTime: string;
  sequenceTimeInput: {
    hour: number;
    minute: number;
  };
  sequenceDateTime: string;
  sequenceForm: FormGroup;
  taskId: string;
  photoData: PhotoData;

  @ViewChild('autosizeComment', {static: true})  autosizeComment: CdkTextareaAutosize;
  @ViewChild('dropArea', {static: false}) dropArea: any;

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _bsI18nService: BootstrapI18nService,
    private _remoteFilesService: RemoteFilesService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _ressources: RessourcesService,
    private _dialog: MatDialog,
    private _ngZone: NgZone) { }

  ngOnInit() {

    this.initData();

  }
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionValue = changes['actionType'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'fileExt': {
            this.fileExt = changes['fileExt'].currentValue;
            break;
          }
          case 'wksWorks': {
            this.wksWorks = changes['wksWorks'].currentValue;
            break;
          }
          case 'wksEntity': {
            this.wksEntity = changes['wksEntity'].currentValue;
            break;
          }
          case 'title': {
            this.titleCard = changes['title'].currentValue;
            break;
          }
          case 'tagsArg': {
            this.tagsArg = changes['tagsArg'].currentValue;
            break;
          }
          case 'photoDataBase64': {
            this.photoDataBase64 = changes['photoDataBase64'].currentValue;
            if (this.photoDataBase64 !== undefined) {
              const stringDecryted = atob(this.photoDataBase64);
              this.photoData = JSON.parse(stringDecryted);
              if (this.ctrlPhotosData() && this.isFilesOK) {
                this.isUploadTodo = true;
              }  
            }
            break;
          }
        } // end switch
      } // end if
    } // end loop

    this.initData();
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosizeComment.resizeToFitContent(true));
  }
  initData() {
    if ( this._translate.currentLang === undefined) {
      return;
    }
    this.initBooleans();
    this.settingIntFmts();
    this.titleCard = 'new doc';
    if (this.actionType === 'photos') {
      this.titleCard = 'new photo';
      this.uploadPhotos = true;
      this.initDatePicker();
      this.buildFormSequence();
    }

    this.buildForm();

    this.isLoaded = true;
  }
  initBooleans(): void  {

    this.isLoaded = false;
    this.isAddOne = false;
    this.isRegistered = false;
    this.isReadOnly = false;
    this.isInput = false;
    this.doComment = false;
    // this.isUploadTodo = false;

    this.uploadPhotos = false;
    if ((this.actionValue === 'add') &&  (this.statutCall === 'direct')) {
      this.isAddOne = true;
    }

    if (this.actionValue === 'display') {
      this.isReadOnly = true;
    }
    if ((this.actionValue === 'add') || (this.actionValue === 'update')) {
      this.isInput = true;
    }
    this.selectTags = false;
    if (this.tagsArg !== undefined && this.tagsArg.length > 0)  {
      this.selectTags = true;
    }

  }
  initDatePicker(): void {
    this.initDp();

    this.sequenceDateInput = new Date();
    this.datePickerConfig = {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: true,
        minDate: this.minDate,
        maxDate: this.maxDate,
        dateInputFormat: this.dateFormat,
        isAnimated: true
      };
    this._bsI18nService.setLanguage(this.fieldFormat.locale.toLowerCase());

    this.sequenceTimeInput = { hour: new Date().getHours(), minute: new Date().getMinutes() };
    this.sequenceTime = this.sequenceTimeInput.hour + ':' + this.sequenceTimeInput.minute;
    this.sequenceDate = CommonMethods.dateToString(this.sequenceDateInput, 'date', environment.fmtDateBdd);
    this.sequenceDateTime = this.sequenceDate + '_' + this.sequenceTime;
  }
  initDp() {

    this.minDayDate = new Date(this.year - 10, this.month, this.day);
    this.maxDayDate = new Date();
    this.minDate = new Date(this.year - 100, this.month, this.day);
    this.maxDate =  new Date(this.year, this.month, this.day);

  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.fieldFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency: this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency: this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText: this._ressources.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers: this._ressources.getFormat(internationnalFormat, 'justifs', 'numbers'),
      language:  this._ressources.getFormat(internationnalFormat, 'numbers', 'language'),
    };
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.dateFormat  = this._translate.getLocalFmt('dateFormat');

    this.dateFormatMoment = this.dateFormat;
    this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
    this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');

    return true;
  }
  buildForm(): void {

    let labelLegend = 'legendFile';
    if (this.selectTags) {
      labelLegend = 'Type';
    }
    this.tagValue = undefined ;
    this.displayedColumns = [labelLegend, 'nameFile', 'sizeFile', 'typeFile', 'uploadedFile'];
    this.styleColumns = [{width: '35%', textAlign: 'left'},
                        {width: '20%', textAlign: 'left'},  
                        {width: '12%', textAlign: 'left'}, 
                        {width: '12%', textAlign: 'center'}, 
                        {width: '12%', textAlign: 'center'}];
    this.docCommentCtrl = this.fb.control({value: '', disabled: this.isReadOnly});
    this.docworksForm = this.fb.group({
      docworksComment: this.docCommentCtrl,
    },
    {
      updateOn: 'blur'
    });
                        
  }
  buildFormSequence(): void {
    this.sequenceForm = this.fb.group({
      sequenceDateFc: this.fb.control(''),
      sequenceTimeFc: this.fb.control(''),
    },
    {
      updateOn: 'blur'
    });
    this.sequenceForm.controls.sequenceDateFc.setValue(this.sequenceDateInput);
  }
  onTagSelected(selectedOption: string): void {
    this.tagValue = selectedOption;
    if (selectedOption !== '') {
      this.isUploadTodo = true;
      this.isFilesOK = true;
      if (this.uploadPhotos && !this.ctrlPhotosData()) {
        this.isUploadTodo = false;
      }
    }
  }
// ============================================================== photos input date sequence
  onSequenceDateChange(inputValue: any): void {
    if (inputValue !== null) {
      this.sequenceDateInput = inputValue;
      this.sequenceDate = CommonMethods.dateToString(inputValue, 'date', environment.fmtDateBdd);
      this.sequenceDateTime = this.sequenceDate + '_' + this.sequenceTime;
    }
  }
  onSequenceTimeChange(inputValue: any): void {
    if (inputValue !== null) {
      this.sequenceTimeInput = JSON.parse(JSON.stringify(inputValue));
      this.sequenceTime = this.sequenceTimeInput.hour + '-' + (this.sequenceTimeInput.minute < 10 ? '0' + this.sequenceTimeInput.minute : this.sequenceTimeInput.minute);
      this.sequenceDateTime = this.sequenceDate + '_' + this.sequenceTime;
    }
  }
// ============================================================== start files
  ctrlPhotosData(): boolean  {
    if (this.photoData === undefined ||
      this.photoData.equipId === undefined || 
      this.photoData.workId === undefined ||
      this.photoData.taskId === undefined ||
      this.photoData.sequenceAtt === undefined
    ) {
      return false;
    } else  {
      return true;
    }
  }
  setCommentValue(_comment: string, raw: number) {
    this.filesDeposit[raw].legendFile = _comment;
    this.filesRead[raw].legendFile = _comment;
  }
  removeFile(row: number) {
    this.filesDeposit.splice(row, 1);
    this.filesRead.splice(row, 1);
    if (this.filesDeposit.length === 0) {
      this.isUploadTodo = false;
      this.isFilesOK = false;
    }
  } 
  addFile(file: File) {
    this.tagValue = undefined ;
    const readingFile: ReadFile = new ReadFile();
    readingFile.nameFile = file.name;
    readingFile.sizeFile = file.size;
    readingFile.typeFile = file.type;
    if (file.type.length > 15)  {
      readingFile.typeFile = '';
      const tmpLabel = file.type.split('.');
      for (const labelCur of tmpLabel) {
        readingFile.typeFile += labelCur + ' ';
      }
    }
    readingFile.fileObject = file;
    readingFile.messageFile = '';
    readingFile.uploadedFile = false;
    readingFile.validatedFile = false;

    if (!this.existsFile(readingFile)) {
      this.filesDeposit.push(readingFile);
      this.filesRead.push(readingFile);
      this.listFilesJson = JSON.stringify(this.filesRead);
      if (!this.selectTags) {
        this.isUploadTodo = true;
        this.isFilesOK = true;
        if (this.uploadPhotos && !this.ctrlPhotosData()) {
          this.isUploadTodo = false;
        }
      }
    }
  }
  private existsFile(_readingFile: ReadFile): boolean {
    let readLocalFile$: ReadFile;

    for (readLocalFile$ of this.filesRead) {
      if (readLocalFile$.nameFile === _readingFile.nameFile) {
        this.alertMessage = true;
        this.messageData.messageType = 'ERROR';
        this.messageData.messageInfo = 'This file already exist';
        this.messageData.dataMessageInfo1 = _readingFile.nameFile ;
        this.messageData.dataMessageInfo2 = '' ;
        return true;
      }
    }
    return false;
  }
  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'blue';
    this.dropArea.nativeElement.style.border = 'dashed 3px red';
    this.alertMessage = false;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'rgb(99, 177, 230)';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
  }
  onFileChange(event) {
    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if (files.length > 0)  {
        for (const fileCur of files)  {
          if (this.isValidFiles(fileCur)) {
            this.addFile(fileCur);
          } else {
            break;
          }
        }
      }

    }
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'rgb(99, 177, 230)';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
    const files = evt.dataTransfer.files;
    if (files.length > 0)  {
      for (const fileCur of files)  {
        if (this.isValidFiles(fileCur)) {
          this.addFile(fileCur);
        } else {
          break;
        }
      }
    }

  }
  private isValidFiles(file: any): boolean {
    // Check Number of files
    if (this.filesDeposit.length >= this.maxFiles) {
      
      this.messageData.messageType = 'ERROR';
      this.messageData.messageInfo = 'The maximum number of files is reached';
      this.messageData.dataMessageInfo1 = this.filesDeposit.length.toString() ;
      this.messageData.dataMessageInfo2 = this.maxFiles.toString() ;
      this.alertMessage = true;
    return false;
    } else {
      return this.isValidFileExtension(file);
    }

  }
  private isValidFileExtension(file: any): boolean {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
                    .map(function (x) { return x.toLocaleUpperCase().trim(); } );
   
        // Get file extension
        const ext = file.name.toUpperCase().split('.').pop() || file.name;
        // Check the extension exists
        const exists = extensions.includes(ext);
        if (!exists) {
            this.messageData.messageType = 'ERROR';
            this.messageData.messageInfo = 'unrecognized extension';
            this.messageData.dataMessageInfo1 = ext ;
            this.messageData.dataMessageInfo2 = file.name ;
            this.alertMessage = true;
        }
        if (!this.alertMessage) {
        // Check file size
          return this.isValidFileSize(file);
        } else {
          return false;
        }
  
  }

  private isValidFileSize(_file: any): boolean {
    const fileSizeinMB = _file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    let maxSizeLocal = this.maxSize;
    const suffix = _file.name.split('.').pop();
    if (suffix.toUpperCase() === 'PDF')  {
      maxSizeLocal = this.maxSizePdf ;
    }
    if (size > maxSizeLocal) {
       
        this.messageData.messageType = 'ERROR';
        this.messageData.messageInfo = 'too heavy file size';
        this.messageData.dataMessageInfo1 = _file.name  ;
        this.messageData.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSize.toString() + 'MB MAX' ;
        this.alertMessage = true;
        return false;
      } else { return true; }
  }
// ============================================================== end files
  startUploadProcess() {
    this.newListFile = [];
    this.alertMessage = true;
    this.subscription = this._globalEventsManagerService.synchroEvents.subscribe( event => {
      if (event === 'okToLaunchUpload') {
        this.registerDocs();
      } else  {
        this.endProcessFile('KO');
      }
    });

    this.openDialog();

  }

  registerDocs() {

    // const nbRows = this.filesDeposit.length;
    let commonReport =  new CommonReport();
    commonReport.isStartReport = true;
    commonReport.orig = 'registerDocs start';
    this._globalEventsManagerService.commonReportSubject.next(commonReport);
    let intCount = 0;
    for (const docCur of this.filesDeposit)  {
      if (intCount > 0) {
       CommonMethods.sleep(1000);
      }
      intCount++;
      const currentDoc = this.fillModel(docCur);

      commonReport =  new CommonReport();
      commonReport.isNewParent = true;
      commonReport.id  = (docCur.legendFile ? docCur.legendFile + ' (' + docCur.nameFile + ')' : docCur.nameFile);
      commonReport.level = LevelType.parent;
      commonReport.type = AlertType.Success; 
      commonReport.pathParent = docCur.nameFile;
      commonReport.orig = 'registerDocs new file';
      commonReport.message = this._translate.instant('StartUploadFile', [commonReport.pathParent]);
      this._globalEventsManagerService.commonReportSubject.next(commonReport);

      this.uploadFile(docCur.fileObject, currentDoc).then(
        (val: any) => {
          commonReport =  new CommonReport();
          commonReport.level = LevelType.child;
          commonReport.type = AlertType.Success; 
          commonReport.pathParent = docCur.nameFile;
          commonReport.message = this._translate.instant('UploadFileOK', [commonReport.pathParent]);
          commonReport.orig = 'registerDocs UploadFileOK file';
          this._globalEventsManagerService.commonReportSubject.next(commonReport);
          this.endProcessFile('OK');
        },
        (error) => {
          console.log('UPLOAD KO ' + docCur.fileObject.name + ' : ' + error);
          commonReport =  new CommonReport();
          commonReport.level = LevelType.child;
          commonReport.isNewParent = true;
          commonReport.type = AlertType.Error;
          commonReport.pathParent = docCur.nameFile;
          commonReport.message = this._translate.instant('UPLOAD KO ', [commonReport.pathParent, error]);
          commonReport.orig = 'registerDocs UploadFileOK file';
          this._globalEventsManagerService.commonReportSubject.next(commonReport);
          this.errorMessage('upload', this._translate.instant('UploadFileKO', [commonReport.pathParent, error]));
          this.endProcessFile('KO');
        }
      ); // end then uploadFile

    }
  }
  private uploadFile(_file: File, currentDoc: WksWorksDocs) {

    // console.log('fichier transféré ' + _file.name);
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFileToStorageWksWorkdav(_file, currentDoc, 'add', this.sequenceDateTime).subscribe((event) => {
          if (event instanceof HttpResponse) {
            // console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translate.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this._translate.getTranslate('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
  
            default:
              message = this._translate.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }

          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  private endProcessFile(status: string) {

    if (status === 'OK')  {
      this.filesRead.splice(0, 1);
      this.filesDeposit.splice(0, 1);

      if (this.filesRead.length === 0 ) {
        const commonReport =  new CommonReport();
        commonReport.isEndReport = true;
        commonReport.orig = 'endProcessFile filesRead = 0 ';
        this._globalEventsManagerService.commonReportSubject.next(commonReport);
      }
    } else  {
      this.filesRead = [];
      this.filesDeposit = [];
    }
  }
  
  fillModel(_docCur: ReadFile): WksWorksDocs {
    const suffix =  _docCur.nameFile.split('.').pop();
    const localFmt = this._translate.getLocalFmt('timeFormat');
    // let fileProperties: any;

    const filePropertiesTmp = {
      lastModified: _docCur.fileObject.lastModified,
      lastModifiedDate: CommonMethods.dateToString(_docCur.fileObject.lastModified, 'date', localFmt),
      name: _docCur.fileObject.name,
      size: _docCur.fileObject.size,
      type: _docCur.fileObject.type,
    };

    const jsonData =  {
      fileProperties: filePropertiesTmp,
      photoData: this.photoData,
    };
    
    const wksDocCard: WksWorksDocs = {
      workId: (this.wksWorks !== undefined ? this.wksWorks.id : this.wksEntity.entityUUID),
      stdEntity: this._userService.getUserLogged().entity,
      workdocLegend: this.selectTags ? this.tagValue : _docCur.legendFile,
      workdocFilename: _docCur.nameFile,
      workdocType: (this.wksEntity !== undefined ? 'model' : this.uploadPhotos ? 'photo' : this.getDocType(suffix)), 
      workdocJson: JSON.stringify(jsonData),
     };
     this.newListFile.push(wksDocCard);
     this.dataOut.emit(this.newListFile);
    return wksDocCard;
  }
  getDocType(_suffix: string): string {
    const extensions = this.fileExt.split(',');
    // JPEG, JPG, GIF, PNG, PDF, TXT, CSV, XLSX, MP4
    switch (_suffix.toUpperCase()) {
      case 'JPEG': {
        return 'image';
        break;
      }
      case 'JPG': {
        return 'image';
        break;
      }
      case 'GIF': {
        return 'image';
        break;
      }
      case 'PNG': {
        return 'image';
        break;
      }
      case 'PDF': {
        return 'document';
        break;
      }
      case 'TXT': {
        return 'document';
        break;
      }
      case 'CSV': {
        return 'document';
        break;
      }
      case 'XLSX': {
        return 'document';
        break;
      }
      case 'MP4': {
        return 'video';
        break;
      }
    } // end switch
  }
  errorMessage(_actionCur: string, _sMessage: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording doc';
    } if (_actionCur === 'upload') {
      titleBox = 'upload doc';
    } else {
      titleBox = 'searching documents';
      messageBox = 'no doc found';
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (_sMessage === null ) {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: this._translate.getTranslate(messageBox),
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    } else {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: _sMessage,
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    }
 //   this._dialog.open(ModalCommonComponent, dialogConfig);
    this._globalEventsManagerService.synchroEvents
      .pipe(takeUntil(this.onDestroy))
      .subscribe( event => {
        if (event === 'cancelModal') {
          console.log('fermeture');
        } else if (event) {
          const typeEvent = typeof event;
          // const eventString = JSON.stringify(event).toString();
          const eventString = String(event);
          console.log(typeEvent);
        }
        // this.router.navigate(['/jobHome']);
      });
    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
      });
  }
  // isBoatEquip
  doClose() {
    /*
    if  ((this.statutCall === 'modalBsn')  || (this.statutCall === 'modalEquip') ) {
      this._globalEventsManagerService.synchroEvents.next('closeDocModal');
      return;
    }*/

  }
  /** Gestion affichage modal */
/** https://blog.angular-university.io/angular-material-dialog/  */
  openDialog() {

  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: this._translate.getTranslate('Upload document'),
    eventOkToLaunch: 'okToLaunchUpload',
    typeDialog: 'upload',
    };
//   this._dialog.open(ModalCommonComponent, dialogConfig);

  const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(
    data => {
      console.log('Dialog output workDoc:', (data !== undefined ? data : 'no Data'));
      this.isUploadTodo = false;
      this.alertMessage = false;
      this.isFilesOK = false;
      this._globalEventsManagerService.synchroEvents.next('closeUploadFiles');
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    });

  }
}
