/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./available-renderer.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../../../../../services/translate.pipe";
import * as i10 from "../../../../../../../../services/translate.service";
import * as i11 from "@angular/common";
import * as i12 from "./available-renderer.component";
var styles_AvailableRendererComponent = [i0.styles];
var RenderType_AvailableRendererComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AvailableRendererComponent, data: {} });
export { RenderType_AvailableRendererComponent as RenderType_AvailableRendererComponent };
function View_AvailableRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "button", [["class", "btn btn-default mat-icon-button "], ["matTooltipClass", "red-tooltip"], ["style", "text-align: center!important;"], ["type", "button"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), i1.ɵpid(0, i9.TranslatePipe, [i10.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-flag"], ["style", "color: red!important; font-size: large;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("unvailable parts")), ""); var currVal_1 = "red-tooltip"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AvailableRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "button", [["class", "btn btn-default mat-icon-button "], ["matTooltipClass", "green-tooltip"], ["style", "text-align: center!important;"], ["type", "button"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), i1.ɵpid(0, i9.TranslatePipe, [i10.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-flag"], ["style", "color: green!important; font-size: large;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("all parts availables")), ""); var currVal_1 = "green-tooltip"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AvailableRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableRendererComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableRendererComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.criticalAlert; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.criticalAlert; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AvailableRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-available-renderer", [], null, null, null, View_AvailableRendererComponent_0, RenderType_AvailableRendererComponent)), i1.ɵdid(1, 114688, null, 0, i12.AvailableRendererComponent, [i10.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvailableRendererComponentNgFactory = i1.ɵccf("mdi-available-renderer", i12.AvailableRendererComponent, View_AvailableRendererComponent_Host_0, {}, {}, []);
export { AvailableRendererComponentNgFactory as AvailableRendererComponentNgFactory };
