/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alphanum-renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alphanum-renderer.component";
import * as i3 from "../../../../../services/job-data.service";
var styles_AlphanumRendererComponent = [i0.styles];
var RenderType_AlphanumRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlphanumRendererComponent, data: {} });
export { RenderType_AlphanumRendererComponent as RenderType_AlphanumRendererComponent };
export function View_AlphanumRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "text-align: left"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; _ck(_v, 1, 0, currVal_0); }); }
export function View_AlphanumRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-alphanum-renderer", [], null, null, null, View_AlphanumRendererComponent_0, RenderType_AlphanumRendererComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlphanumRendererComponent, [i3.JobDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlphanumRendererComponentNgFactory = i1.ɵccf("mdi-alphanum-renderer", i2.AlphanumRendererComponent, View_AlphanumRendererComponent_Host_0, {}, {}, []);
export { AlphanumRendererComponentNgFactory as AlphanumRendererComponentNgFactory };
