import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/translate.service';
import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { RowPinned, ColReportInt, ColAggregateInt } from '../../../../models/job-data.model';

@Component({
  selector: 'mdi-report-pinned-row',
  templateUrl: './report-pinned-row.component.html',
  styleUrls: ['./report-pinned-row.component.css']
})
export class ReportPinnedRowComponent implements OnInit, OnDestroy {

  doValidation = false;
  private readonly onDestroy = new Subject<void>();

  listPinnedRows: RowPinned[];
  listColActive: ColReportInt[];
  listColAgg : ColAggregateInt[];
  listOperations: string[];
  listPositions: string[];
  constructor(private _translate: TranslateService, private _jobDataService: JobDataService) { }

  ngOnInit() {
    this.initData();
    this.listOperations =['colSum','colAvg','colCount'];
    this.listPositions =['colTop','colBottom'];
    this._jobDataService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe ( valueEvent => {
     
      if (valueEvent === 'columnsActive') {
        this.initData();
      }
      if (valueEvent === 'validOK') {
        this.doValidation = true;
      }
      if (valueEvent === 'validKO') {
        this.doValidation = false;
      }

    }) ;
  }
  ngOnDestroy() {
    this.onDestroy.next();

  }
  initData() {
    this.listPinnedRows = [];
    if(this._jobDataService.getColReportService().rowPinned) {
      this.listPinnedRows = this._jobDataService.getColReportService().rowPinned;
    } else {
      let listColAgg : ColAggregateInt[];
      listColAgg = [];
      const colAgg = {
        colName: '',
        aggregateType: '',
        colFormat: '',
      }
      listColAgg.push(colAgg);
      const testRow = {
        rowPosition: '',
        rowRank: 1,
        rowLabel: '',
        colNameDisplay: '',
        colAggregate: listColAgg,
        isCollapsed: false,
      }
      this.listPinnedRows.push(testRow);
    }
    this.listColActive  = this._jobDataService.getColReportService().colCur;
    this.listColActive.sort(function(obj1, obj2) {
      return obj1.ranking > obj2.ranking ? 1 : -1;
    })
  }
  levelRank(_iRow: number, _idx: number) {
    if(!this.listPinnedRows[_iRow].rowRank) {
      this.listPinnedRows[_iRow].rowRank = 0;
    }
    this.listPinnedRows[_iRow].rowRank += _idx;
    if(this.listPinnedRows[_iRow].rowRank > 3) this.listPinnedRows[_iRow].rowRank = 3;
    if(this.listPinnedRows[_iRow].rowRank < 1) this.listPinnedRows[_iRow].rowRank = 1;
    
  }
  addPinnedRow() {
    const numRow = this.listPinnedRows.length+1;
    const testRow = {
      rowPosition: '',
      rowRank: numRow,
      rowLabel: '',
      colNameDisplay: '',
      colAggregate: [],
      isCollapsed: false,
    }
    const colAgg = {
      colName: '',
      aggregateType: '',
      colFormat: '',
    }
    testRow.colAggregate.push(colAgg);
    this.listPinnedRows.push(testRow);
    this._jobDataService.changeColReportService(null,null,this.listPinnedRows,null,false,null,null);
  }
  removeColumnAggregate(rowLine:number,i:number) {
    this.listPinnedRows[rowLine].colAggregate.splice(i,1);
    this._jobDataService.changeColReportService(null,null,this.listPinnedRows,null,false,null,null);
  }
  addColumnAggregate(rowLine:number, iCol: number) {
    const colAgg = {
      colName: '',
      aggregateType: '',
      colFormat: '',
    }
    if(this.listPinnedRows[rowLine].colAggregate === undefined) {
      this.listPinnedRows[rowLine].colAggregate = [];
    }
    if(iCol+1<this.listPinnedRows[rowLine].colAggregate.length) {
      this.listPinnedRows[rowLine].colAggregate.splice(iCol+1,0,colAgg);
    }
    else this.listPinnedRows[rowLine].colAggregate.push(colAgg);
    this._jobDataService.changeColReportService(null,null,this.listPinnedRows,null,false,null,null);
  }
  removePinnedRow(i: number) {
    this.listPinnedRows.splice(i,1);
    this._jobDataService.changeColReportService(null,null,this.listPinnedRows,null,false,null,null);
  }
  checkChange(fieldChecked: string,inputValue:any) {

  }
  setValueArrayRow(dataField: string, dataValue: any,index: number) {
    this.doValidation  =true;

    if( dataField ===  'rowLabel') this.listPinnedRows[index].rowLabel  = dataValue;
    if( dataField ===  'rowPosition') this.listPinnedRows[index].rowPosition  = dataValue;
    if( dataField ===  'rowRank') this.listPinnedRows[index].rowRank = dataValue;
    if( dataField ===  'colNameDisplay') this.listPinnedRows[index].colNameDisplay = dataValue;
    return dataValue;
  }
  changeValue(event: any) {
    console.log(event.srcElement);
  }
  setValueArrayColAgg(dataField: string, dataValue: any,iRow: number, iCol: number) {
  // setValueArrayColAgg('colAggcolName',colNameDisplay,iRow, iCol) {

    if( dataField ===  'colAggcolName') this.listPinnedRows[iRow].colAggregate[iCol].colName = dataValue;
    return dataValue;
  }
  register() {
    this._jobDataService.changeStatutEvent('doValidation');
  }
  doCancel() {
    this._jobDataService.changeStatutEvent('doCancel');
  }
}
