import { Component, OnInit, SimpleChanges, Input, OnChanges, EventEmitter, Injectable, Inject } from '@angular/core';

import { Subscription, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

import { WksBusinessModel } from '../../../../models/wks-business.model';
import { EquipmentData } from './equipment-data';
import { WksCentralService } from '../../../../services/wks-central.service';

@Component({
  selector: 'mdi-equipment-owner',
  templateUrl: './equipment-owner.component.html',
  styleUrls: ['./equipment-owner.component.css']
})
// @Injectable()
export class EquipmentOwnerComponent implements OnInit, OnChanges {

  @Input() dataIn: any;
  private readonly onDestroy = new Subject<void>();

  alreadyPass: boolean;
  contactsLoaded: boolean;
  constructor(@Inject(EquipmentData) private equipData: EquipmentData,     
              private _wksCentralService: WksCentralService) { 
    this.dataIn = this.dataIn || this.equipData;
    // console.log(this.dataIn.ownerName);
    this.contactsLoaded = false;
    this.alreadyPass = false;
  }

  ngOnInit() {

    if (this.alreadyPass) {
      return;
    }
    this.alreadyPass = true;
    let wksBusinessCur: WksBusinessModel;

    this.loadBusiness()
    .then (wksBusiness => { 
      wksBusinessCur = wksBusiness;
      if (wksBusinessCur.bsnPerson) {
        this.generateContact(wksBusinessCur);
      } else {
        this.generateContactsList(wksBusinessCur);
      }
    })
    .catch(error  => {
      console.log(error);
      this.contactsLoaded = false;
    });
  }
  generateContact(wksBusinessCur: WksBusinessModel) {

    this.contactsLoaded = true;
  }
  generateContactsList(wksBusinessCur: WksBusinessModel) {
    
    this.contactsLoaded = true;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.dataIn = this.dataIn || this.equipData;
    if (this.dataIn === undefined) {
      return;
    }
    // console.log(this.dataIn.name);
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {

          case 'dataIn': {
            this.dataIn = changes['dataIn'].currentValue;
            break;
          }

        } // end switch
      } // end if
    } // end loop
  }
  loadBusiness() {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBusinessId(this.dataIn.stdEntity, this.dataIn.ownerId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
         // const bsnEntity = data.body;
 
          resolve(data.body);
        }, err => {
          console.log(err);
         
           reject(err);
        }
      );
    });
  }
}
