// A TESTER
// https://www.amadousall.com/angular-pipes-unit-testing-angular-pipes/
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service'; // our translate service

@Pipe({
    name: 'translate',
    pure: false // add in this line, update value when we change language
})

export class TranslatePipe implements PipeTransform {

    constructor(private _translate: TranslateService) { }

    transform(value: string, args: string | string[]): any { // args can be string or string array
        // tslint:disable-next-line:curly
        if (!value) return;
        return this._translate.instant(value, args); // pass in args
    }
}
