import { Component, OnInit, OnDestroy , ViewChild, AfterViewInit, ViewEncapsulation, Renderer2, TemplateRef } from '@angular/core';

import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { DatasourceInt, mjljobdbs_sch } from '../../../models/job-data.model';


@Component({
  selector: 'mdi-datasources',
  templateUrl: './datasources.component.html',
  styleUrls: ['./datasources.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DatasourcesComponent implements OnInit, OnDestroy, AfterViewInit  {



  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  dataSource = new MatTableDataSource();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = mjljobdbs_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterDatasource: boolean;
  doCreateDatasource: boolean;
  doUpdateDatasource = [];
  datasourceToUpdate: DatasourceInt;
  statutRegister: string;

  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];
  pwdFormat = '*******';
  // mapExpectedSave: Map<string, string> = new Map<string, string>();
  // modal
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg modal-dialog-centered modal-dialog-std modal-body-std',
    backdrop: true,
    ignoreBackdropClick: true
  };
  modalText: any[];
  isTestConnectOK: boolean;

  constructor( private renderer: Renderer2,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private modalService: BsModalService,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
  }

  ngOnInit() {
    this.displayedColumns = [
      { prop: 'dbsLabel', name: this._translate.instant('dbsLabel'), isDate: false, isPwd: false },
      { prop: 'dbsEngine', name: this._translate.instant('dbsEngine'), isDate: false, isPwd: false  },
      { prop: 'dbsServer', name: this._translate.instant('dbsServer'), isDate: false, isPwd: false  },
      { prop: 'dbsPort', name: this._translate.instant('dbsPort'), isDate: false, isPwd: false  },
      { prop: 'dbsName', name: this._translate.instant('dbsName'), isDate: false, isPwd: false  },
      { prop: 'dbsUser', name: this._translate.instant('dbsUser'), isDate: false, isPwd: false  },
    //  { prop: 'dbsPwd', name: this._translate.instant('dbsPwd'), isDate: false, isPwd: true  },
    //  { prop: 'accessEntities', name: this._translate.instant('accessEntities'), isDate: false, isPwd: false  },
    //  { prop: 'accessGrp', name: this._translate.instant('accessGrp'), isDate: false, isPwd: false  },
     //  { prop: 'updateDate', name: this._translate.instant('updateDate'), isDate: true, isPwd: false  },
    ];
    this.loadingSubject.next(true);
    this.getListDatasources();
    this._jobDataService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListDatasources();
      }
      if (event === 'deleteRegister') {
        this.getListDatasources();
      }
    });
    this.timeFormat = this._translate.getLocalFmt('timeFormatNgx');
  }
  getListDatasources() {
    this.selected = [];
    this._jobDataService.getDatasources()
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      }
    );
  }
  ngOnDestroy() {

  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {
    this.modalRef.hide();
  }
  translateLabels() {

    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateDatasource = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
                    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  doAction(row: any): boolean {
    if (row.master) {
      return false;
    } else {
      return true;
    }
  }
  ngAfterViewInit() {
  }

  toggleSidenav(_arg: string) {

      this.isRegisterDatasource = false;

      if (_arg === 'registerDatasource') {
        this.isRegisterDatasource = true;
      }
      this.sidenavCollapsed = !this.sidenavCollapsed;
      if (this.sidenavCollapsed) {
        this.m_sidenav.close();
      } else {
        this.m_sidenav.open();
      }
    }


  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }

  refresh() {
    this.getListDatasources();
  }
  deleteDatasource(row: any, rowIndex: any) {
    // console.log('deleteEntity Row clicked: ', row);

    this._jobDataService.deleteDatasource(row.dbsName)
      .subscribe(
        () => {
          this._jobDataService.onRegisterValid.emit('deleteRegister');
        },
        // () => this.registrationFailed = true
      );

  }
  tryDatasource(row: any, rowIndex: any) {
    // console.log('deleteEntity Row clicked: ', row);
    // let messageTranslate: string;
    this.modalText = [row.dbsLabel];
    this._jobDataService.testConnect(row)
    .subscribe(
      (isOK) => {
        if (isOK) {
         this.isTestConnectOK = true;
        } else {
          this.isTestConnectOK = false;
        }
      },
      () => {
        this.isTestConnectOK = false;
      }
    );
  }
  editDatasource(row: DatasourceInt) {
    this.datasourceToUpdate = row;
    this.statutRegister = 'updateDatasource';
    this.doCreateDatasource = false;
    this.toggleSidenav('registerDatasource');
  }
  addDatasource() {
    // console.log('addEntity : ');
    this.statutRegister = 'createDatasource';
    this.doCreateDatasource = true;
    this.toggleSidenav('registerDatasource');
  }
}
