import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class EquipmentMetadata {
    ownerName: string;
    ownerId: string;
    stdEntity: string;
    equipName: string;
    equipDesign: string;
    equipId: string;
}
