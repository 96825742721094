import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material';
import { Subscription } from 'rxjs';
import {  HostListener, Input} from '@angular/core';

import { HttpResponse, HttpEventType } from '@angular/common/http';
import { ModalCommonComponent } from '../modal-common/modal-common.component';

import { UserService } from '../../../../services/user.service';
import { TranslateService } from '../../../../services/translate.service';
import { RemoteFilesService } from '../../services/remote-files.service';
import { ControlCsvService } from '../../services/control-csv.service';
import { ReadFile, ReportEvent, CommonReport, AlertType, LevelType, AnalyseDataCsv } from '../../../../models/common.model';

import { GlobalEventsManagerService } from '../../services/global-events-manager.service';
import { CommonMethods } from '../../tools/commonMethods';

export class RetVal {
  status: string;
  data: string;
  message: string;
}

@Component({
  selector: 'mdi-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UploadFilesComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('dropArea', {static: false}) dropArea: any;

  @Input() fileExt: String = 'JPG, GIF, PNG, PDF, TXT, CSV, XLSX, DOCX';
  @Input() uploadType: string;

  filesRead: Array<ReadFile> = [];
  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  progress: { percentage: number } = { percentage: 0 };
  listFilesJson: any;
  // public readMode = ReadMode.dataURL;
  public picked: ReadFile;
  public status: string;
  displayedColumns: any;
  styleColumns: any;
  public files: Array<ReadFile> = [];
  isUploadTodo = false;
  isUploadInProgress = false;

  messageInfo = '';
  dataMessageInfo1 = '';
  dataMessageInfo2 = '';
  messageType = '';
  alertMessage = false;
  subscription: Subscription;

  constructor (private _userService: UserService,
              private _translateService: TranslateService,
              private _remoteFilesService: RemoteFilesService,
              private _dialog: MatDialog,
              private _controlCsvService: ControlCsvService,
              private _globalEventsManagerService: GlobalEventsManagerService) {
  }
  ngOnInit() {
    this.displayedColumns = ['nameFile', 'sizeFile', 'typeFile', 'uploadedFile'];

    this.styleColumns = [{width: '25%', textAlign: 'left'},  
                        {width: '20%', textAlign: 'left'}, 
                        {width: '30%', textAlign: 'center'}, 
                        {width: '20%', textAlign: 'center'}];
    this.isUploadTodo = false;

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
  }

  addFile(file: File) {

    const readingFile: ReadFile = new ReadFile();
    readingFile.nameFile = file.name;
    readingFile.sizeFile = file.size;
    readingFile.typeFile = file.type;
    if (file.type.length > 15)  {
      readingFile.typeFile = '';
      const tmpLabel = file.type.split('.');
      for (const labelCur of tmpLabel) {
        readingFile.typeFile += labelCur + ' ';
      }
    }
    readingFile.fileObject = file;
    readingFile.messageFile = '';
    readingFile.uploadedFile = false;
    readingFile.validatedFile = false;

    if (!this.existsFile(readingFile)) {
      this.files.push(readingFile);
      this.filesRead.push(readingFile);
      this.listFilesJson = JSON.stringify(this.filesRead);
      this.isUploadTodo = true;
    }

  }
  removeFile(row: number) {
    this.files.splice(row, 1);
    this.filesRead.splice(row, 1);
  } 
  private existsFile(_readingFile: ReadFile): boolean {
    let readLocalFile$: ReadFile;

    for (readLocalFile$ of this.filesRead) {
      if (readLocalFile$.nameFile === _readingFile.nameFile) {
        this.alertMessage = true;
        this.messageType = 'ERROR';
        this.messageInfo = 'This file already exist';
        this.dataMessageInfo1 = _readingFile.nameFile ;
        this.dataMessageInfo2 = '' ;
        return true;
      }
    }
    return false;
  }
  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'blue';
    this.dropArea.nativeElement.style.border = 'dashed 3px red';
    this.alertMessage = false;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'rgb(99, 177, 230)';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
  }
  onFileChange(event) {
    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if ((files.length > 0) && (this.isValidFiles(files))) {
        const file: File = files[0];
        this.addFile(file);
      }
    }
  }
  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'rgb(99, 177, 230)';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
    const files = evt.dataTransfer.files;

    if ((files.length > 0) && (this.isValidFiles(files))) {
      const file: File = files[0];
      this.addFile(file);
    }
  }
  startUploadProcess() {
    this.alertMessage = true;
    this.subscription = this._globalEventsManagerService.synchroEvents.subscribe( event => {
      if (event === 'okToLaunchUpload') {
        this.uploadFiles();
      }
    });
    this.openDialog();
  }
  uploadFiles() {
    let readLocalFile$: ReadFile;
    let commonReport =  new CommonReport();
    commonReport.isStartReport = true;
    this._globalEventsManagerService.commonReportSubject.next(commonReport);

    this._controlCsvService.onControlCsv.subscribe((event: ReportEvent) => {
      // console.log(event.message);

      this.messageType = event.level;
      this.messageInfo = event.message;
      this.dataMessageInfo1 = event.nameObject ;
      this.dataMessageInfo2 = event.data ;

    });
    let intCount = 0;
    for (readLocalFile$ of this.filesRead) {
      if (!readLocalFile$.uploadedFile && !readLocalFile$.validatedFile) {
        console.log('ANALYSE : ' + readLocalFile$.fileObject.name );
        if (intCount > 0) {
          CommonMethods.sleep(1000);
         }
         intCount++;
        this.verifyFile(readLocalFile$.fileObject)
          .then (
            (retVal: RetVal) => {
                  if (retVal.status === 'endProcessControl') {
                    commonReport =  new CommonReport();
                    commonReport.level = LevelType.child;
                    commonReport.type = AlertType.Success;
                    commonReport.pathParent = retVal.data;
                    commonReport.message = this._translateService.instant('verifyFileOK', [commonReport.pathParent]);
                    this._globalEventsManagerService.commonReportSubject.next(commonReport);
                    this.uploadFile(readLocalFile$.fileObject).then(
                      () => {
                        commonReport =  new CommonReport();
                        commonReport.level = LevelType.child;
                        commonReport.type = AlertType.Success;
                        commonReport.pathParent = retVal.data;
                        commonReport.message = this._translateService.instant('UploadFileOK', [commonReport.pathParent]);
                        this._globalEventsManagerService.commonReportSubject.next(commonReport);

                        this.endProcessFile();
                      },
                      (error) => {
                        console.log('UPLOAD KO ' + readLocalFile$.fileObject.name + ' : ' + error);
                        commonReport =  new CommonReport();
                        commonReport.level = LevelType.child;
                        commonReport.type = AlertType.Error;
                        commonReport.pathParent = retVal.data;
                        commonReport.message = this._translateService.instant('UploadFileKO', [commonReport.pathParent, error]);
                        this._globalEventsManagerService.commonReportSubject.next(commonReport);

                        this.endProcessFile();
                      }
                    ); // end then uploadFile
                  } else {

                    this.endProcessFile();
                  }// end if retVal
                },
            (retVal: RetVal) => {
                  console.log('verifyFile ' + retVal.data + ' Error : ' + retVal.message);
                  commonReport =  new CommonReport();
                  commonReport.level = LevelType.child;
                  commonReport.type = AlertType.Error;
                  commonReport.pathParent = readLocalFile$.fileObject.name;
                  commonReport.message = this._translateService.instant('verifyFileKO', [commonReport.pathParent]);

                  this.endProcessFile();
              }
          ); // end then verify file
        } // end if
      }
  }
/**
 * finish process
 */
  private endProcessFile() {
    // if (this.filesRead.length > _iRead - 1) { this.filesRead[_iRead - 1].uploadedFile = true; }
    this.filesRead.splice(0, 1);
    this.files.splice(0, 1);

    if (this.filesRead.length === 0 ) {
      const commonReport =  new CommonReport();
      commonReport.isEndReport = true;
      this._globalEventsManagerService.commonReportSubject.next(commonReport);
    }
  }
  private verifyFile(_file: File) {
    let retVal: RetVal;
    return  new Promise((resolve, reject) => {
        this._controlCsvService.controlCsvFile(_file, this.uploadType).then(
          () => {
            retVal = new RetVal();
            retVal.data = _file.name;
            retVal.status = 'endProcessControl';
            retVal.message = '';
            resolve(retVal);
          },
          () => {
            retVal = new RetVal();
            retVal.data = _file.name;
            retVal.status = 'ProcessControlKO';
            retVal.message = '';
            reject(retVal);
          },
     ); });
  }
  private uploadFile(_file: File) {
    let lstAnalyseDataCsv: any [];
    // console.log('fichier transféré ' + _file.name);
  return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFileToStorage(_file, this.uploadType).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
                    this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translateService.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 515:
              console.log(response.error);
              lstAnalyseDataCsv = JSON.parse(JSON.stringify(response.error));
              // const jsonResponse = JSON.stringify(response.error);
              console.log('longueur ' + lstAnalyseDataCsv.length);
              if (lstAnalyseDataCsv.length > 1) {
                for ( const errorCur of lstAnalyseDataCsv) {
                  const analyseDataCsv = this.convertErrorLIne(errorCur);
                  const messageCur = this._controlCsvService.getErrorProcess(analyseDataCsv);
                  this.emitErrorLine(analyseDataCsv, messageCur);
                  // console.log(messageCur);
                }
              }
              message = this._translateService.getTranslate('Upload lines error');
              break;
            default:
              message = this._translateService.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }

          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  private convertErrorLIne(_errorCur: any): AnalyseDataCsv {

    const jsonConv = JSON.parse(_errorCur);
    const objErrorCsv = new AnalyseDataCsv();
    objErrorCsv.action = jsonConv.action;
    objErrorCsv.cellValue = jsonConv.cellValue;
    objErrorCsv.columnName = jsonConv.columnName;
    objErrorCsv.errorMessage = jsonConv.errorMessage;
    objErrorCsv.fileName = jsonConv.fileName;
    objErrorCsv.numColumn = jsonConv.numColumn;
    objErrorCsv.numLine = jsonConv.numLine;
    objErrorCsv.otherValue1 = jsonConv.otherValue1;
    objErrorCsv.otherValue2 = jsonConv.otherValue2;
    return objErrorCsv;
  }
   private emitErrorLine(analyseDataCsv: AnalyseDataCsv, _messageCur: string) {
    const commonReport =  new CommonReport();
    commonReport.isNewParent = false;
    commonReport.id = analyseDataCsv.numLine.toString();
    commonReport.level = LevelType.child;
    commonReport.pathParent = analyseDataCsv.fileName;
    commonReport.type = AlertType.Error ;
    commonReport.message = _messageCur;
    this._globalEventsManagerService.commonReportSubject.next(commonReport);
   }
  private isValidFiles(files): boolean {
    // Check Number of files
    if (files.length > this.maxFiles) {
      this.alertMessage = true;
      this.messageType = 'ERROR';
      this.messageInfo = 'The maximum number of files is reached';
      this.dataMessageInfo1 = files.length.toString() ;
      this.dataMessageInfo2 = this.maxFiles.toString() ;
    return false;
    } else {
      return this.isValidFileExtension(files);
    }

  }
  private isValidFileExtension(files): boolean {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
                    .map(function (x) { return x.toLocaleUpperCase().trim(); } );
    for (let i = 0; i < files.length; i++) {
        // Get file extension
        const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
        // Check the extension exists
        const exists = extensions.includes(ext);
        if (!exists) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'unrecognized extension';
            this.dataMessageInfo1 = ext ;
            this.dataMessageInfo2 = files[i].name ;
            break;
        }
        if (!this.alertMessage) {
        // Check file size
          return this.isValidFileSize(files[i]);
        } else {
          return false;
        }
    }
  }

  private isValidFileSize(_file): boolean {
    const fileSizeinMB = _file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place

    if (size > this.maxSize) {
        this.alertMessage = true;
        this.messageType = 'ERROR';
        this.messageInfo = 'too heavy file size';
        this.dataMessageInfo1 = _file.name  ;
        this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSize.toString() + 'MB MAX' ;
        return false;
      } else { return true; }
  }
/** Gestion affichage modal */
/** https://blog.angular-university.io/angular-material-dialog/  */
  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translateService.getTranslate('ReportAnalyzeCSV'),
      eventOkToLaunch: 'okToLaunchUpload',
      typeDialog: 'upload',
      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        console.log('Dialog output:', data);
        this.isUploadTodo = false;
        this.alertMessage = false;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      });

  }
}

