import { Timestamp } from 'rxjs/internal/operators/timestamp';

// mjl_jobdbs
export interface DatasourceInt {
    id: string;
    dbsLabel: string;
    dbsEngine: string;
    dbsServer: string;
    dbsPort: string;
    dbsName: string;
    dbsUser: string;
    dbsPwd: string;   
    dbsConnect: string; 
    accessEntities: string;  
    accessGrp: string;  
    updateDate: string;  
    dbsDoc: boolean;
}

export class DatasourceModel {
    id: string;
    dbsLabel: string;
    dbsEngine: string;
    dbsServer: string;
    dbsPort: string;
    dbsName: string;
    dbsUser: string;
    dbsPwd: string;  
    dbsConnect: string;  
    accessEntities: string;  
    accessGrp: string;  
    updateDate: string;  
    dbsDoc: boolean;
}

export interface DbsListInt {
    dbsId: string;
    dbsLabel: string;
    dbsDoc: boolean;
}
export class DbsList {
    dbsId: string;
    dbsLabel: string;
    dbsDoc: boolean;
}
// mjl_jobrequests
export interface RequestInt {
    id: string;
    requestLabel: string;
    requestEngine: string;
    dbsId: number;
    requestVersion: number;
    requestContent: string;
    accessEntities: string;  
    accessGrp: string;  
    updateDate: string;
    requestColumns: string;  
    requestDefault: string;  
    requestRestrictions: string;  
    requestRef: string;
    docRequest: boolean;
    docDbsId: number;
    docPath: string;
    docFilename: string;
    docName: string;
}
export class RequestModel {
    id: string;
    requestLabel: string;
    requestEngine: string;
    dbsId: number;
    requestVersion: number;
    requestContent: string;
    accessEntities: string;  
    accessGrp: string;  
    updateDate: string;  
    requestColumns: string;
    requestDefault: string;  
    requestRestrictions: string; 
    requestRef: string;
    docRequest: boolean;
    docDbsId: number;
    docPath: string;
    docFilename: string;
    docName: string;
}
// mjl_jobreports
export class ReportModel {
    id: string;
    reportLabel: string;
    reportRef: string;
    reportVersion: string;
    requestRef: string;
    accessEntities: string;
    accessGrp: string;
    updateDate: Date;
    reportColumns: string;
    reportStatut: string;
    reportCompleted: boolean;
    reportSample: number;
    reportMenu: string;
    reportOptions: string;
    reportChildren: string;
    menuParent?: string;
    menuRanking?: string;
}
export interface ReportInt {
    id: string;
    reportLabel: string;
    reportRef: string;
    reportVersion: string;
    requestRef: string;
    accessEntities: string;
    accessGrp: string;
    updateDate: Date;
    reportColumns: string;
    reportStatut: string;
    reportCompleted: boolean;
    reportSample: number;
    reportMenu: string;
    reportOptions: string;
    reportChildren: string;
    menuParent?: string;
    menuRanking?: string;
    subReportData?: string;
    tagsauthorized?: string;
    tagsprohibited?: string;
}

export interface Restrictions {
    columnName: string;
    operande: string;
    restrictValue: string;
}
export interface ColumnsList {
    colName: string;
    colDesc: string;
    dataCategory: string;
    dataLevel: string;
}

export interface ColLabelList {
    id: string;
    colName: string;
    userLang: string;
    requestRef: string;
    colHeader: string;
    colToolTip: string;
    colAttributes: string;
    itemUsage?: string;
}
export interface ColLabelRow {
    id: string;
    colName: string;
    userLang: string;
    checkLabel: boolean;
    colHeader: string;
    colToolTip: string;
    colAttributes: string;
    itemUsage?: string;
}
export interface ColAttrRow {
    id: string;
    colName: string;
    colType: string;
    colFmt: string;
}
export interface RowPinned {
    rowPosition: string;
    rowRank: number;
    rowLabel: string;
    colNameDisplay: string;
    colAggregate: ColAggregateInt[];
    isCollapsed: boolean;
}

export interface SubReports {
    zoomPresent: boolean;
    zoomCols: number;
    zoomRows: number;
    zoomRecall: any;
    subReport: SubReport[];
}
export interface SubReport {
    reportRef: string;
    reportName: string;
    listColRecall: any;
    reportInitialAction: string;
    foreignKeys: ForeignKeys[];
    isCollapsed: boolean;
    isTargetReport: boolean;
    limitationCol: boolean;
}
export interface Widgets {

    dashboardCols: number;
    dashboardRows: number;
    widgets: Widget[];
}

export interface Widget {
    widgetName: string;
    widgetUpdate: number;
    chartType: string;
    updateFrequency: number;
    top: number;
    left: number;
    height: number;
    width: number;
    defaultDisplay: boolean;
    requestRef: string;
    requestRestrictions: RestrictionsModel [];
}
export interface ForeignKeys {
    colOrig: string;
    operande: string;
    colTarget: string;
}
export interface ColAggregate {
    colName: string;
    aggregateType: string;
    prefixLabel: string;
    fmtType: string;
    suffixLabel: string;
}
export interface ColAggregateInt {
    colName: string;
    aggregateType: string;
    colFormat: string;
}
export interface ColReportInt {
    colName: string;
    colType: string;
    displayed: boolean;
    grouping: boolean;
    filterList: boolean;
    filterInput: boolean;
    pinning: string;
    ranking: number;
    pinnedRow: boolean;
}
export class ColReportModel {
    colName: string;
    colType: string;
    display: boolean;
    grouping: boolean;
    filterList: boolean;
    filterInput: boolean;
    pinning: string;
    ranking: number;
    pinnedRow: boolean;
}
export interface ColReportServiceInt {
    colStatut: string;
    colDoEvent: boolean;
    colAgg: ColAggregateInt[];
    colCur: ColReportInt[];
}
export class ColReportService {
    colStatut: string;
    colDoEvent: boolean;
    isCompleted: boolean;
    rowPinned: RowPinned[];
    colCur: ColReportInt[];
    subReports: SubReports;
    widgets: Widgets;
}

export class RestrictionsModel {
    columnName: string;
    operande: string;
    restrictValue: string;
}

export class MenuModel {
    id: number;
    entity: string;
    entitymenu: string;
    entitymenuparams: string;
    updateDate?: Date;
    entitiesShared: string;
    menuRef?: string;
}
export interface DataLabelInt {
    menuName: string;
    menuLabel: string;
    menuToolTip: string;
    userLang: string;
  }

export interface MenuInt {
    id?: number;
    entity: string;
    entitymenu: string;
    entitymenuparams?: string;
    updateDate?: Date;
    entitiesShared?: string;
    menuRef?: string;
    otherData?: string;
    userEnvt?: any;
}
export interface UserEnvtInt {
    id: number;
    userenvt: any;
}
export class UserEnvt {
    id: number;
    userenvt: string;
}
export interface AgGridColModel {
    headerName: string;
    field: string;
    filter: string;
    filterParams?: any;
    cellRendererSelector?: any;
    cellRenderer?: any;
    cellRendererParams?: any;
    detailCellRenderer?: any;
    type?: string [];
    width: number;
    editable: boolean;
    pinned: string;
    rowGroupIndex: number;
    colId: string;
    resizable: boolean; 
    sortable: boolean;
    enableRowGroup: boolean;
    menuTabs: string[];
    pinnedRowCellRenderer: string;
    pinnedRowCellRendererParams: any;
}

export interface KeyValue {
    key: string;
    value: any;
}

export const mjljobdbs_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'dbsLabel', colType: 'string' },
    { colName: 'dbsEngine', colType: 'string' },
    { colName: 'dbsServer', colType: 'string' },
    { colName: 'dbsPort', colType: 'string' },
    { colName: 'dbsName', colType: 'string' },
    { colName: 'dbsUser', colType: 'string' },
    { colName: 'dbsPwd', colType: 'string' },
    { colName: 'dbsConnect', colType: 'string' },
    { colName: 'accessEntities', colType: 'string' },
    { colName: 'accessGrp', colType: 'string' },
    { colName: 'updateDate', colType: 'Date' },
    { colName: 'dbsDoc', colType: 'boolean' },
];

export const mjljobrequestsLabel_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'colName', colType: 'string' },
    { colName: 'userLang', colType: 'string' },
    { colName: 'requestRef', colType: 'string' },
    { colName: 'checkLabel', colType: 'boolean' },
    { colName: 'colHeader', colType: 'string' },
    { colName: 'colToolTip', colType: 'string' },
    { colName: 'colAttributes', colType: 'string' },
    { colName: 'itemUsage', colType: 'string' },
];
export const mjljobrequests_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'requestLabel', colType: 'string' },
    { colName: 'requestEngine', colType: 'string' },
    { colName: 'dbsId', colType: 'string' },
    { colName: 'requestVersion', colType: 'string' },
    { colName: 'requestContent', colType: 'string' },
    { colName: 'accessEntities', colType: 'string' },
    { colName: 'accessGrp', colType: 'string' },
    { colName: 'updateDate', colType: 'Date' },
    { colName: 'requestColumns', colType: 'string' },
    { colName: 'requestDefault', colType: 'boolean' },
    { colName: 'requestRestrictions', colType: 'string' },
    { colName: 'requestRef', colType: 'string' },
    { colName: 'docRequest', colType: 'boolean' },
    { colName: 'docDbsId', colType: 'number' },
    { colName: 'docPath', colType: 'string' },
    { colName: 'docFilename', colType: 'string' },
    { colName: 'docName', colType: 'string' },
];
export const mjljobreports_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'reportLabel', colType: 'string' },
    { colName: 'reportRef', colType: 'string' },
    { colName: 'reportVersion', colType: 'string' },
    { colName: 'requestRef', colType: 'string' },
    { colName: 'accessEntities', colType: 'string' },
    { colName: 'accessGrp', colType: 'string' },
    { colName: 'updateDate', colType: 'Date' },
    { colName: 'reportColumns', colType: 'string' },
    { colName: 'reportStatut', colType: 'string' },
    { colName: 'reportCompleted', colType: 'boolean' },
    { colName: 'reportSample', colType: 'number' },
    { colName: 'reportMenu', colType: 'string' },
    { colName: 'menuParent', colType: 'string' },
    { colName: 'menuRanking', colType: 'string' },
    { colName: 'reportOptions', colType: 'string' },
    { colName: 'reportChildren', colType: 'string' }, 
    { colName: 'subReportData', colType: 'string' },
    { colName: 'tagsauthorized', colType: 'string' },
    { colName: 'tagsprohibited', colType: 'string' },
];
