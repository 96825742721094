<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '0px', width:  '100%' ,
                        padding: '10px', height: '100%'}" >
    <mat-card class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px; height: 100%;">
        <mat-card-action>
            <div class="container-fluid d-flex justify-content-between" >
                <button *ngIf="popoverAppointment === false" mat-raised-button  #buttonCancel type="reset" (click)="doCancel()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ 'goback' | translate }}
                </button>
                <span style="text-align: center!important;font-size:large"><strong>{{ titleCard | translate }}</strong></span>
                <span style="text-align: right!important;font-size:large">{{ rowCard.appointmentRef }}</span>
            </div>
        </mat-card-action>
        <mat-card-content class="stdTheme">
            <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px;">
                <mat-tab label="{{ 'tracking' | translate }}" >
                    <!--<div *ngIf="rowCard && rowCard.trackingList">-->
                    <div *ngIf="rowCard">
                        <div>
                            <ng-container [ngTemplateOutlet]="trackingCard"></ng-container> 
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{ 'customer' | translate }}" >
                    <div *ngIf="rowCard && rowCard.client">
                        <!--<mdi-appointment-client-card [wksEntity]="wksEntity" [client]="rowCard.client" [appointment]="rowCard" ></mdi-appointment-client-card>-->
                        <div>
                            <ng-container [ngTemplateOutlet]="clientCard" [ngTemplateOutletContext]="{client:rowCard.client}"></ng-container> 
                        </div>
                    </div>
                </mat-tab>
                <div *ngIf="rowCard && rowCard.equipments">
                    <div  *ngFor="let equipment of rowCard.equipments; let index = index; let isFirst = first; let isOdd = odd;">
                        <mat-tab label="{{ 'equipment' | translate }} {{equipment.equipmentNum}}" >
                            <div>
                                <ng-container [ngTemplateOutlet]="equipmentCard" 
                                [ngTemplateOutletContext]="{equipment:equipment, client:rowCard.client}"></ng-container> 
                            </div>
                        </mat-tab>
                    </div>
                </div>
                
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #trackingCard  >
    <mdi-appointment-tracking  
        [wksEntity]="wksEntity" 
        [wksEntityParams]="wksEntityParams" 
        [mechanicUsers]="mechanicUsers" 
        [appointment]="rowCard"
        (dataOut)="saveAppointment($event)">
    </mdi-appointment-tracking>
</ng-template>
<ng-template #clientCard let-client='client' >
    <mdi-appointment-client-card [wksEntity]="wksEntity" 
                    [client]="client" 
                    [appointment]="rowCard" >
    </mdi-appointment-client-card>
</ng-template>
<ng-template #equipmentCard let-equipmentCur='equipment' let-client='client' >
    <mdi-appointment-equipment-card [wksEntity]="wksEntity" 
                    [equipment]="equipmentCur" 
                    [wksEntityParams]="wksEntityParams" 
                    [client]="client" 
                    [appointment]="rowCard" >
    </mdi-appointment-equipment-card>
</ng-template>