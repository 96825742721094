import { Component, OnInit, AfterContentInit, OnDestroy, OnChanges, Input, ViewChild, ViewEncapsulation, Renderer2, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { CustomValidators } from '../../../../common/validators/customValidators';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../services/user.service';
import { JobDataService } from '../../../../job-data/services/job-data.service';
import { TranslateService } from '../../../../../services/translate.service';
import { RequestInt, RequestModel, DbsListInt, DbsList, 
          Restrictions, ColumnsList, RestrictionsModel } from '../../../models/job-data.model';

@Component({
  selector: 'mdi-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.css']
})
export class RequestCardComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {

  @Input() statutCall: any;
  @Input() requestToUpdate: RequestInt;

  @ViewChild('buttonSubmit', {static: false}) buttonSubmit: any;
  @ViewChild('refElement', {static: true}) refElement: any;
  @ViewChild('titleCard', {static: false}) titleCard: any;

  private readonly onDestroy = new Subject<void>();

  registrationFailed: boolean;

  requestLabelCtrl: FormControl;
  requestEngineCtrl: FormControl;
  dbsLabelCtrl: FormControl;

  requestContentCtrl: FormControl;
  requestDefaultCtrl: FormControl;

  accessEntitiesCtrl: FormControl;
  accessGrpCtrl: FormControl;
  forbiddenWordsCtrl: FormControl;
  columnNameCtrl: FormControl;
  operandeCtrl: FormControl;
  restrictValueCtrl: FormControl;
  docRequestCtrl: FormControl;
  docDbsLabelCtrl: FormControl;
  docPathCtrl: FormControl;
  docFilenameCtrl: FormControl;
  docNameCtrl: FormControl;


  restrictionsListGrp: FormGroup;

  requestForm: FormGroup;
  registerTitle = 'Register';
  messageTranslate: string;

  isRequestFound: boolean;
  isDocValuesOK: boolean;
  isUpdateStatut: boolean;
  requestCard: RequestInt;

  doTestRequest = false;
  isTestRequestOK = true;
  requestVersion: number;
  requestColumns: string;

  engineList: string[];
  dbsListCache: DbsListInt[];
  dbsList: string[];
  dbsListDoc: string[];
  entitiesList: string[];
  grpsList: string[];
  forbiddenWords: any;
  restrictValuesList = ['GRP', 'ENT', 'TAGS', 'VALUE'];
  operandeList = ['Contain', 'notContain', 'Equal', 'notEqual'];
  columnNameList: any;
  restrictionsList: Restrictions[];
  isDocRequest: boolean;
  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _jobDataService: JobDataService,
    private renderer: Renderer2) { }

  ngOnInit() {

  }
  ngAfterContentInit() {
    if ((this.restrictionsList === null) || (this.restrictionsList.length === 0)) {
      const grpRestrict = {
        columnName: '',
        operande: '',
        restrictValue : '',
      }; 
      this.restrictionsList.push(grpRestrict);
    }
  }
    
  ngOnDestroy() {
    this.onDestroy.next();
   }
  ngOnChanges(changes: SimpleChanges) {
    const statutCallValue = changes['statutCall'];
    const userToUpdateValue = changes['requestToUpdate'];

    this.initData();
  }
  initData() {
    this.isDocRequest = false;
    // console.log('Testing ngOnInit');
    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createRequest';
    }
    this.restrictionsList = [];

    this.forbiddenWords =  this._userService.getUserLogged().forbiddenWords;
    this.engineList = CommonMethods.listToArray(`${environment.enginesDb}`, ',');
    this.requestLabelCtrl = this.fb.control('', Validators.required);
    this.requestEngineCtrl = this.fb.control('');
    this.dbsLabelCtrl = this.fb.control('');
    this.forbiddenWordsCtrl = this.fb.control('');
    this.requestContentCtrl = this.fb.control('', [Validators.required]);
    this.accessEntitiesCtrl = this.fb.control('', Validators.required);
    this.accessGrpCtrl = this.fb.control('', Validators.required);
    this.requestDefaultCtrl = this.fb.control('');
    this.operandeCtrl = this.fb.control('');
    this.columnNameCtrl = this.fb.control('');
    this.restrictValueCtrl = this.fb.control('');

    this.docRequestCtrl = this.fb.control('');
    this.docDbsLabelCtrl = this.fb.control('');
    this.docPathCtrl = this.fb.control('');
    this.docFilenameCtrl = this.fb.control('');
    this.docNameCtrl = this.fb.control('');

    this.requestForm = this.fb.group({
      requestLabel: this.requestLabelCtrl,
      requestEngine: this.requestEngineCtrl,
      dbsLabel: this.dbsLabelCtrl,
      requestDefault: this.requestDefaultCtrl ,
      
      contentVerification: this.fb.group({
        requestContent: this.requestContentCtrl,
        forbiddenWords: this.forbiddenWordsCtrl},
        {validator: CustomValidators.wordsValid}),
      
      accessEntities: this.accessEntitiesCtrl,
      accessGrp: this.accessGrpCtrl,
      restrictionsListGrp: this.fb.array([this.buildItem('')]),
      docRequest: this.docRequestCtrl,
      docDbsLabel: this.docDbsLabelCtrl,
      docName: this.docNameCtrl,
      docPath: this.docPathCtrl,
      docFilename: this.docFilenameCtrl,
      },
      {
        updateOn: 'blur'
      },

    );

    this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
    if (this.statutCall === 'createRequest') {
      this.registerTitle = 'Creation';
    }
    this.isUpdateStatut = false;
    if (this.statutCall === 'updateRequest') {
      this.isUpdateStatut = true;
      this.registerTitle = 'Update';

      this.setValuesToUpdate();
    } else {
      const grpRestrict = {
        columnName: '',
        operande: '',
        restrictValue : '',
      };
      this.restrictionsList.push(grpRestrict);
    }

    this.getListEntities();

    this.isRequestFound = false;

    this.requestForm.controls['requestLabel'].valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe (
      () => {
        this.validRequestLabel();
        this.doTestRequest = false;
        this.isTestRequestOK = true;
      }
    );
    this.requestForm.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe (
      () => {
        this.doTestRequest = true;
        this.isTestRequestOK = false;
      }
    );
    this.requestForm.controls['accessEntities'].valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe (
      (val) => {
        this.loadListGrps(val);
      }
    );
    this.requestForm.controls['requestEngine'].valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe (
      (val) => {
        this.getListDbs(val, '');
      }
    );
  }
  chkDocRequest(event: any) {
    // console.log('event : ' + event);
    
    if (event.source.checked) {
      this.isDocRequest = true;
      this.isDocValuesOK = false;
    } else {
      this.isDocRequest = false;
      this.isDocValuesOK = true;
    }
  }
  changeDocDbsLabel(event: any) {
   
  }
  ctrlMappingField(_docNameField: string, _fileNameField: string, _pathField: string) {
    this.registrationFailed = false;
    if ((_pathField) && (_fileNameField) &&  (_fileNameField === _pathField )) {
      this.isDocValuesOK = false;
      this.registrationFailed = true;
      this.messageTranslate = this._translate.getTranslate('Path and filename fields can not be the same') ;
    } if ((_pathField) && (_docNameField) &&  (_docNameField === _pathField )) {
      this.isDocValuesOK = false;
      this.registrationFailed = true;
      this.messageTranslate = this._translate.getTranslate('Path and docname fields can not be the same') ;
    } if ((_fileNameField) && (_docNameField) &&  (_docNameField === _fileNameField )) {
      this.isDocValuesOK = false;
      this.registrationFailed = true;
      this.messageTranslate = this._translate.getTranslate('docname and filename fields can not be the same') ;
    } else {
      this.isDocValuesOK = true;
    }
  }
  getListEntities() {
    this.entitiesList = [];
    let curExtEntities = this._userService.getUserLogged().extendedentities;
    if ((curExtEntities === undefined) || (curExtEntities === null)) {
      curExtEntities = this._userService.getUserLogged().entity; }
    if (curExtEntities.includes('ALL')) {
      this._userService.getEntities()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const listEntities = data.body;
          // this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
          this.entitiesList.push('ALL');
          const curEntities = CommonMethods.jsonPropToArray(listEntities, 'entity');
          for (const curEntity of curEntities) {
            this.entitiesList.push(curEntity);
            }
        }
      );
    } else {
      let curEntities = [];
      curEntities = curExtEntities.split(',');
      for (const curEntity of curEntities) {
        this.entitiesList.push(curEntity);
        }
    }
  }
  loadListGrps(_val: any) {

    if (_val === null ) { return; }
    this.grpsList = [];
    if (('ALL' === _val[0]) && (_val.length > 1)) {
      this.requestForm.controls.accessEntities.setValue(['ALL']);
      return;
    }
    if (('ALL' === _val[0]) || (_val.length > 1)) {
      this.grpsList.push('ALL');
    } else {
      // grps of entity
      this.doGetListGrps(_val[0]);
    }
  }
  doGetListGrps(_entity: string) {
    // this.getListGrps(_entity);
    this.getListGrps(_entity)
    .then ((grpsList) => {

    })
    .then((error) => {

    });
  }
  getListGrps(_entity: string) {
    return new Promise((resolve, reject) => {
        this._userService.getEntityGrps(_entity)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(
          data => {
            const usergroupLists = data.body;
            this.grpsList = CommonMethods.jsonPropToArray(usergroupLists, 'usergroup');
              resolve(usergroupLists);
          }, err => {
            this.grpsList = [];
            reject(err);
          }
        );
      });
  }
  getListDbs(_requestEngine: string, _callStatut: string) {
    return new Promise<void>((resolve, reject) => {
        // this._jobDataService.getListDbs(_requestEngine)
        this._jobDataService.getDatasources()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(
          data => {
            this.doListDbs(data.body, _callStatut, _requestEngine);
              resolve();
          }, err => {
            this.dbsList = [];
            this.dbsListDoc = [];
            reject(err);
          }
        );
      });
  }
  doListDbs(_dbsResponse: Array<any>, _callStatut: string, _requestEngine: string) {

    this.dbsListCache = [];
    this.dbsList = [];
    this.dbsListDoc = [];
    if (_dbsResponse === null ) { return null; }
    if (!_dbsResponse[0])  {return null; }
    
    for (const x of _dbsResponse) {
      const dbsList = new DbsList();
      dbsList.dbsId = x.id;
      dbsList.dbsLabel = x.dbsLabel;
      dbsList.dbsDoc = x.dbsDoc;
      this.dbsListCache.push(dbsList);
      if (x.dbsEngine === _requestEngine) {
        this.dbsList.push(x.dbsLabel);
      }
      if (x.dbsDoc) {
        this.dbsListDoc.push(x.dbsLabel);
      }
      if (_callStatut === 'initUpdate') {
        if (this.requestToUpdate.dbsId === x.id) { this.requestForm.controls.dbsLabel.setValue(x.dbsLabel); }
        if (this.requestToUpdate.docDbsId === x.id)  { this.requestForm.controls.docDbsLabel.setValue(x.dbsLabel); }
      }
    }

  }

  getIdDbs(_dbsLabel: any): any {
    if (this.dbsListCache === null ) { return null; }
    for (const x of this.dbsListCache) {
      if (x.dbsLabel === _dbsLabel) {
        // tslint:disable-next-line: radix
        return Number.parseInt(x.dbsId);
      }
    }
  }
  setDocDbsId(_docDbsId: any) {

  }
  loadGrps(event: any) {
    if (event.isUserInput) {
      // console.log(event.source.value, event.source.selected);
    }

  }
  setValueArray (dataType: string, dataValue: string, index: number) {
    const arrayGrp = <FormArray> this.requestForm.get('restrictionsListGrp');
    if (dataType === 'columnName') {
      this.restrictionsList[index].columnName = dataValue;
      // arrayGrp.controls[index].get('columnName').setValue(dataValue);
    }
    if (dataType === 'operande') {
      this.restrictionsList[index].operande = dataValue; 
    }
    if (dataType === 'restrictValue') {
      this.restrictionsList[index].restrictValue = dataValue;
    }
  }
  addRestrict(index: number) {
    const arrayGrp = <FormArray> this.requestForm.get('restrictionsListGrp');
    arrayGrp.push(this.buildItem(''));
    const grpRestrict = {
      columnName: '',
      operande: '',
      restrictValue : '',
    };
    this.restrictionsList.push(grpRestrict);
  }
  buildItem(val: any): FormGroup {
    if (val === '') { 
      return this.fb.group({
            columnName: new FormControl(''),
            operande: new FormControl(''),
            restrictValue: new FormControl(''),
        });
    } else {
      if (val instanceof RestrictionsModel) {
        return this.fb.group({
          columnName: new FormControl(val.columnName),
          operande: new FormControl(val.operande),
          restrictValue: new FormControl(val.restrictValue),
      });
      }
    }
  }
  removeRestrict(index: number) {
    const arrayGrp = this.requestForm.get('restrictionsListGrp') as FormArray;
    if (arrayGrp.length === 0) { return; }
    arrayGrp.removeAt(index);
    this.restrictionsList.splice( index, 1 );
  }

  doCancel() {
    this.requestForm.reset();
    if (this.statutCall) {
      this._jobDataService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  validRequestLabel() {
    const requestLabel = this.requestForm.controls['requestLabel'].value;
    if ((requestLabel) && (requestLabel.length > 3)) {
      if ((this.statutCall === 'createRequest')
       || ((this.statutCall === 'updateRequest')
              && (requestLabel !== this.requestToUpdate.requestLabel))) {
        this.ctrlRequestLabel(requestLabel);
      }
    }
  }
  ctrlRequestLabel(_requestLabel: string): RequestInt {
    this.isRequestFound = false;
    let dataResponse: RequestInt = null;
    this._jobDataService.getRequestLabel(_requestLabel)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        dataResponse = data.body[0];
        this.isRequestFound = true;
        if (this.statutCall === 'createRequest') {
          this.requestForm.controls['requestLabel'].setErrors({ 'isRequestFound': true});
        }
        // console.log('Entity found : ', _entity);
      }, () => {
        // console.log('Entity unfound : ', _entity);
      }
    );
    return dataResponse;
  }

  testRequest() {
    this.doTestRequest = false;
    this.isTestRequestOK = false;

    this.requestCard = this.prepareValidation();
    this._jobDataService.testRequest(this.requestCard)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      (retMes) => {
        const response = JSON.parse(JSON.stringify(retMes.body));
        if (response.statut === 'OK') {
          this.doTestRequest = false;
          this.isTestRequestOK = true;
          this.messageTranslate = this._translate.getTranslate('Test request OK');
          this.requestColumns = JSON.stringify(response.columns);

          this.getColumnNameList();
        } else {
          this.doTestRequest = true;
          this.isTestRequestOK = false;
          this.registrationFailed = true;
          this.messageTranslate = this._translate.getTranslate(response.message);
        }

      },
      (retMes: any) => {
        this.messageTranslate = this._translate.getTranslate('Test request error');
        this.doTestRequest = true;
        this.isTestRequestOK = false;
        this.registrationFailed = true;
      }
    );
    
  }

  getColumnNameList() {
   
    const jsonObject = JSON.parse(this.requestColumns);
    // console.log(jsonObject.columns[0].colName);
    // const arrayColumns: ColumnsList[] = this.requestColumns;
    // console.log(arrayColumns);
    this.columnNameList = [];
    for (const col of jsonObject.columns) {
      // console.log(col.colName);
      this.columnNameList.push(col.colName);
    }
  }
  register() {
    this.registrationFailed = false;

    this.requestCard = this.prepareValidation();
    this._jobDataService.saveRequest(this.requestCard, this.statutCall)
    .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {
          this.requestForm.reset();
          if (this.statutCall) {
            this._jobDataService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation(): RequestInt {

    const requestCard: RequestModel = new RequestModel();
    requestCard.requestLabel = this.requestForm.value.requestLabel;
    requestCard.requestEngine = this.requestForm.value.requestEngine;
    requestCard.dbsId = this.getIdDbs(this.requestForm.value.dbsLabel);
    requestCard.requestDefault = this.requestForm.value.requestDefault;
    requestCard.requestVersion = this.requestVersion;
    requestCard.docRequest = this.requestForm.value.docRequest;
    requestCard.docDbsId = this.getIdDbs(this.requestForm.value.docDbsLabel);
    requestCard.docPath = this.requestForm.value.docPath;
    requestCard.docFilename = this.requestForm.value.docFilename;
    requestCard.docName = this.requestForm.value.docName;
    if (this.requestToUpdate) {
      requestCard.requestRef = this.requestToUpdate.requestRef;
    } else {
      requestCard.requestRef = null;
      requestCard.requestVersion = 1;
    }
    if (this.requestColumns !== null) {
      // let columnsList = JSON.stringify(this.requestColumns);
      // columnsList = columnsList.replace(new RegExp('\}', 'g'),'');
      // columnsList = columnsList.replace(new RegExp('\{', 'g'),'');
      requestCard.requestColumns = this.requestColumns;

    }
    
    // requestCard.requestContent = this.requestForm.value.requestContent;
    // requestCard.requestContent = this.requestForm.value.contentVerification['controls'].requestContent
    // https://www.concretepage.com/angular-2/angular-2-formgroup-example#nested-fg
    requestCard.requestContent = this.requestForm.get('contentVerification').get('requestContent').value;
    if ('ALL' === this.requestForm.value.accessEntities[0]) {
      requestCard.accessEntities = 'ALL';
    } else {
      requestCard.accessEntities = CommonMethods.arrayStringToString(this.requestForm.value.accessEntities, ',');
    }
    if (('ALL' === this.requestForm.value.accessGrp[0]) || (requestCard.accessEntities === 'ALL')) {
      requestCard.accessGrp = 'ALL';
    } else if (this.requestForm.value.accessEntities.length > 1) {
      requestCard.accessGrp = 'ALL';
    } else {
      requestCard.accessGrp = CommonMethods.arrayStringToString(this.requestForm.value.accessGrp, ',');
    }
    requestCard.requestRestrictions = '';
    if (this.restrictionsList.length > 0) {
      for (const restrict of this.restrictionsList) {
        // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
        if (restrict.columnName) {
          if (requestCard.requestRestrictions !== '') {
            requestCard.requestRestrictions += ';';
          }
          requestCard.requestRestrictions += restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
        }
      }
    }
    

    return requestCard;
  }

  setValuesToUpdate() {
    
    this.isDocValuesOK = true;
    this.requestForm.controls.requestLabel.setValue(this.requestToUpdate.requestLabel);
    this.requestForm.controls.requestEngine.setValue(this.requestToUpdate.requestEngine);
    this.requestForm.controls.requestDefault.setValue(this.requestToUpdate.requestDefault);
    this.getListDbs(this.requestToUpdate.requestEngine, 'initUpdate');
    this.requestColumns = this.requestToUpdate.requestColumns;
    if (this.requestColumns) {
      this.getColumnNameList();
    }
    this.requestVersion = this.requestToUpdate.requestVersion;
    this.requestForm.controls.docRequest.setValue(this.requestToUpdate.docRequest);
    this.isDocRequest = this.requestToUpdate.docRequest;

    this.requestForm.controls.docPath.setValue(this.requestToUpdate.docPath);
    this.requestForm.controls.docFilename.setValue(this.requestToUpdate.docFilename);
    this.requestForm.controls.docName.setValue(this.requestToUpdate.docName);
    this.restrictionsList = [];
    const arrayGrp = <FormArray> this.requestForm.get('restrictionsListGrp');
    // if(arrayGrp.length === 1 ) arrayGrp.removeAt(0);
    if (this.requestToUpdate.requestRestrictions) {
      const restrictList = this.requestToUpdate.requestRestrictions.split(';');
      if (restrictList) { arrayGrp.removeAt(0); }
      for (const restrictRow of restrictList ) {
        const restrict = restrictRow.split('|');
        const restrictUnit = new RestrictionsModel();
        restrictUnit.columnName = restrict[0];
        restrictUnit.operande = restrict[1];
        restrictUnit.restrictValue = restrict[2];
        const grpRestrict = {
          columnName: restrict[0],
          operande: restrict[1],
          restrictValue : restrict[2],
        };        
        this.restrictionsList.push(grpRestrict);
        arrayGrp.push(this.buildItem(restrictUnit));
      }
    }
    this.requestForm.get('contentVerification').get('requestContent').setValue(this.requestToUpdate.requestContent);
    this.requestForm.get('contentVerification').get('forbiddenWords').setValue(this.forbiddenWords);

    let curEntities = this._userService.getUserLogged().accessentities.split(',');
    if ((this.requestToUpdate.accessEntities !== null) && (this.requestToUpdate.accessEntities.trim().length > 3)) {
      curEntities = this.requestToUpdate.accessEntities.split(',');
    }
    
    const bindEntities: any[] = [];
    for (const curEntity of curEntities) {
      bindEntities.push(curEntity);
    }
    this.requestForm.controls.accessEntities.setValue(bindEntities);

    let curGrps: any[] = ['ALL'];
    if ((this.requestToUpdate.accessGrp !== null) && (this.requestToUpdate.accessGrp.trim().length > 1)) {
      curGrps = this.requestToUpdate.accessGrp.split(',');
    }
    const bindGrps: any[] = [];
    for (const curGrp of curGrps) {
      bindGrps.push(curGrp);
    }
    this.requestForm.controls.accessGrp.setValue(bindGrps);
    this.loadListGrps(bindEntities);

  }
  getErrorMessage(_ctrlName: string): string {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'requestLabel': {
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.requestForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request label is required') : messageLib = '';
        break;
      }
      case 'isRequestFound': {
        messageLib = this._translate.getTranslate('requestFound');
        break;
      }
      case 'requestEngine': {
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.requestForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Request engine is required') : messageLib = '';
        break;
      }
      case 'dbsId': {
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.requestForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Datasource is required') : messageLib = '';
        break;
      }
      case 'requestContent': {
        
        !this.requestForm.get('contentVerification').get('requestContent').dirty ? messageLib = '' :
        this.requestForm.get('contentVerification').get('requestContent').hasError('required') ?
          messageLib = this._translate.getTranslate('Request is required') :
          this.requestForm.get('contentVerification').get('requestContent').hasError('pattern') ?
            messageLib = this._translate.getTranslate('Request not valid') : messageLib = '';
        break;
      }
      case 'accessEntities': {
        break;
      }
      case 'accessGrp': {
        break;
      }
      case 'docDbsLabel': {
        /*!this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
        this.requestForm.controls[_ctrlName].hasError('required') ?
          messageLib = this._translate.getTranslate('Datasource is required') : messageLib = '';
        if(this.requestForm.controls[_ctrlName].value === '') {
          messageLib = this._translate.getTranslate('Documentary datasource is required');
          this.registrationFailed = true;
        } */
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
        this.requestForm.controls[_ctrlName].hasError('required') ?
          messageLib = this._translate.getTranslate('Documentary datasource is required') : messageLib = '';
      
        break;
      }
      
      case 'docFilename': {
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
        this.requestForm.controls[_ctrlName].hasError('required') ?
          messageLib = this._translate.getTranslate('Mandatory filename field mapping') : messageLib = '';

        break;
      }
      case 'docName': {
        !this.requestForm.controls[_ctrlName].dirty ? messageLib = '' :
        this.requestForm.controls[_ctrlName].hasError('required') ?
          messageLib = this._translate.getTranslate('Mandatory document name field mapping') : messageLib = '';

        break;
      }
      default: {
        break;
      }
    }
    this.messageTranslate = messageLib;
    return messageLib;
  }
}
