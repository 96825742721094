
export interface ClientModel {
    id?: string;
    stdEntity: string;
    lastName: string;
    firstName: string;
    companyName?: string;
    phoneNumber: string;
    mailAddress: string;
    creationDate: Date;
    lastUpdateDate: Date;
}

export const ClientColumns = [
    { colName: 'id', colType: 'string' },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'lastName', colType: 'string' },
    { colName: 'firstName', colType: 'string' },
    { colName: 'companyName', colType: 'string' },
    { colName: 'phoneNumber', colType: 'string' },
    { colName: 'mailAddress', colType: 'string' },
    { colName: 'creationDate', colType: 'date' },
    { colName: 'lastUpdateDate', colType: 'date' },
    { colName: 'appointmentsOtherdata', colType: 'string' },
];

export const ClientDefaultCol = ['lastName', 'firstName', 'companyName', 'phoneNumber', 'mailAddress'];

export interface EquipmentModel {
    id?: string;
    appointmentId?: string;
    type: string;
    brand: string;
    model: string;
    serialNumber?: string;
    nbHoursOfUse: number;
    equipmentNum: number;
    askedService: string;
    askedServiceHours: number;
    creationDate: Date;
    lastUpdateDate: Date;
    equipmentOtherdata: string;
}

export const EquipmentColumns = [
    { colName: 'id', colType: 'string' },
    { colName: 'appointmentId', colType: 'string' },
    { colName: 'type', colType: 'string' },
    { colName: 'brand', colType: 'string' },
    { colName: 'model', colType: 'string' },
    { colName: 'serialNumber', colType: 'string' },
    { colName: 'nbHoursOfUse', colType: 'number' },
    { colName: 'equipmentNum', colType: 'number' },
    { colName: 'askedService', colType: 'string' },
    { colName: 'askedServiceHours', colType: 'number' },
    { colName: 'creationDate', colType: 'date' },
    { colName: 'lastUpdateDate', colType: 'date' },
    { colName: 'appointmentsOtherdata', colType: 'string' },
];
export const EquipmentDefaultCol = ['type', 'brand', 'model', 'serialNumber', 'nbHoursOfUse'];

export interface AppointmentModel {
    id?: string;
    client: ClientModel;
    equipments: EquipmentModel[];
    equipmentLocation: string;
    equipmentPosition: string;
    equipmentJobsite: string;
    askedComment: string;
    appointmentStatut: string;
    interventionDate: Date;
    interventionTechnician: string;
    nbEquipments: number;
    stdEntity: string;
    creationDate: Date;
    lastUpdateDate: Date;
    appointmentsOtherdata: string;
    customer?: string;
    appointmentRef?: string;
    appointmentFixed?: string;
    workAssigned?: string;
    appointmentTracking?: AppointmentTracking[];
}
export interface AppointmentTracking  {
    id?: string;
    appointmentId: string;
    stdEntity: string;
    numberTrack: number;
    statut: string;
    trackingDetail: string;
    trackingActive: boolean;
    creationUser: string;
    creationDate: Date;
    lastUpdateUser?: string;
    lastUpdateDate?: Date;
}
export const AppointmentColumns = [
    { colName: 'id', colType: 'string', width: 50 },
    { colName: 'client', colType: 'string' },
    { colName: 'equipments', colType: 'string' },
    { colName: 'equipmentLocation', colType: 'string' },
    { colName: 'equipmentPosition', colType: 'string' },
    { colName: 'equipmentJobsite', colType: 'string' },
    { colName: 'askedComment', colType: 'string' },
    { colName: 'appointmentStatut', colType: 'string', width: 50 },
    { colName: 'interventionTechnician', colType: 'string', width: 50 },
    { colName: 'interventionDate', colType: 'date', width: 50 },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'creationDate', colType: 'date', width: 50 },
    { colName: 'lastUpdateDate', colType: 'date', width: 50 },
    { colName: 'appointmentsOtherdata', colType: 'string' },
    { colName: 'customer', colType: 'string' },
    { colName: 'appointmentFixed', colType: 'date', width: 30 },
    { colName: 'workAssigned', colType: 'string', width: 50 },
    { colName: 'appointmentRef', colType: 'string', width: 30 },
];
export const AppointmentDefaultCol = ['appointmentRef', 'customer', 'creationDate', 'lastUpdateDate', 'appointmentStatut', 'appointmentFixed', 'workAssigned'];
