import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';

import { catchError, tap, map} from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';

import { EmailData } from '../../../models/data.model';

@Injectable({
  providedIn: 'root'
})
export class SendmailService {

  constructor(private userService: UserService, 
              private httpClient: HttpClient) { }

  sendMail(entity: string, emailData: EmailData): Observable<HttpResponse<any>> {
  
    const localHttpHeaders: HttpHeaders  = this.userService.getHeaders();
    const urlService = `${environment.baseUrl}/wks/jobs/sendEmail/?` + 'entity=' + entity;

    const body = JSON.stringify(emailData);

    return this.httpClient
    .post<any>(urlService, body, {
        headers: localHttpHeaders,
        observe: 'response'
    });
  }

}
