import { Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { MenuComponent } from './auth/menu/menu.component';
import { JobHomeComponent } from './job/job-home/job-home.component';
import { UsersTableComponent } from './organization/users/users-table/users-table.component';
import { UpdatePwdComponent } from './organization/users/update-pwd/update-pwd.component';
import { EntitiesTableComponent } from './organization/entities/entities-table/entities-table.component';
import { UsersgrpTableComponent } from './organization/usersgrp/usersgrp-table/usersgrp-table.component';
import { DatasourcesComponent } from './job/job-data/components/datasources/datasources-table/datasources.component';
import { RequestsTableComponent } from './job/job-data/components/requests/requests-table/requests-table.component';
import { ReportsTableComponent } from './job/job-data/components/reports/reports-table/reports-table.component';
import { JobReportHomeComponent } from './job/job-data/components/reports/jobreporthome/jobreporthome.component';
// CalendarComponent
import { CalendarComponent } from './job/common/components/calendar-agl/calendar/calendar.component';
// WKS
import { WksEntityComponent } from './job/job-wks/components/param/wks-entity/wks-entity.component';
import { BnsTableComponent } from './job/job-wks/components/repositories/bns/bns-table/bns-table.component';
import { EquipmentTableComponent } from './job/job-wks/components/repositories/equipment/equipment-table/equipment-table.component';
import { DocsTableComponent } from './job/job-wks/components/repositories/docs/docs-table/docs-table.component';
import { AnchoragesTableComponent } from './job/job-wks/components/repositories/anchorages/anchorages-table/anchorages-table.component';
import { WorkratesTableComponent } from './job/job-wks/components/param/work-rates/workrates-table/workrates-table.component';
import { ActionsrefTableComponent } from './job/job-wks/components/param/actionsref/actionsref-table/actionsref-table.component';
import { TasksrefTableComponent} from './job/job-wks/components/param/tasksref/tasksref-table/tasksref-table.component';
import { WorksTableComponent} from './job/job-wks/components/works/works-jobs/works-table/works-table.component';
import { AppointmentsTableComponent} from './job/job-wks/components/works/appointments-follow/appointments-table/appointments-table.component';
import { AppointmentsPlanningComponent} from './job/job-wks/components/works/appointments-planning/appointments-planning.component';
import { PlanningHrComponent } from './job/job-wks/components/param/planning-hr/planning-hr.component';
import { PartsSuppliesTableComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-table/parts-supplies-table.component';
import { WorksAssignmentsComponent } from './job/job-wks/components/works/works-jobs/works-assignments/works-assignments.component';
import { WorksInvoicingComponent } from './job/job-wks/components/works/works-jobs/works-invoicing/works-invoicing.component';
import { SchedulerTableComponent } from './organization/scheduler/scheduler-table/scheduler-table.component';
import { ApplicationTestComponent } from './organization/application/application-test/application-test.component';
import { ReloadConfigComponent } from './organization/application/reload-config/reload-config.component';
import { ManufacturersSpecificationsComponent } from './job/job-wks/components/others/manufacturers-specifications/manufacturers-specifications.component';
/*
exemple routing args
    { path: 'userList/:id/:username/:firstname/:lastname', component: UsersTableComponent },
settings :
            this.router.navigate(['/userList',
                          this.userLogged.id,
                          this.userLogged.username,
                          this.userLogged.firstName,
                          this.userLogged.lastName
                        ]);
getting :
        @Input() userLogged: UserLoggedImpl;
ou bien :
  constructor(private _currentRoute: ActivatedRoute) {
                const userLoggedLocal = _currentRoute.snapshot.paramMap;
                if (userLoggedLocal) {
                  this.userLogged = new UserLogged();
                  this.userLogged.id = _currentRoute.snapshot.params['id'];
                  this.userLogged.username = _currentRoute.snapshot.params['username'];
                  this.userLogged.firstName = _currentRoute.snapshot.params['firstname'];
                  this.userLogged.lastName = _currentRoute.snapshot.params['lastname'];
                }
              }
*/
export const ROUTES: Routes = [
  
    { path: 'app', component: AppComponent },
    { path: '', component: HomeComponent },
    { path: 'menu', component: MenuComponent },
    { path: 'userList', component: UsersTableComponent },
    { path: 'grpList', component: UsersgrpTableComponent },
    { path: 'schedulerList', component: SchedulerTableComponent },
    { path: 'testAppli', component: ApplicationTestComponent },
    { path: 'reloadConfig', component: ReloadConfigComponent },
    { path: 'entities', component: EntitiesTableComponent, data: [{itemOption: 'admin'}]  },
    { path: 'login', component: LoginComponent },
    { path: 'jobHome', component: JobHomeComponent },
    { path: 'jobReportHome', component: JobReportHomeComponent },
    { path: 'updatePwd', component: UpdatePwdComponent },
    { path: 'datasources', component: DatasourcesComponent },
    { path: 'requests', component: RequestsTableComponent, data: [{itemOption: 'request'}] },
    { path: 'accessData', component: RequestsTableComponent, data: [{itemOption: 'accessData'}] },
    { path: 'labelData', component: RequestsTableComponent, data: [{itemOption: 'labelData'}] },
    { path: 'reports', component: ReportsTableComponent, data: [{itemOption: 'reports'}] },
    { path: 'dataDisplay', component: ReportsTableComponent, data: [{itemOption: 'dataDisplay'}] },
    { path: 'dataAttributes', component: ReportsTableComponent, data: [{itemOption: 'dataAttributes'}] },
    { path: 'validationReport', component: ReportsTableComponent, data: [{itemOption: 'validation'}] },
    { path: 'entitiesMenu', component: EntitiesTableComponent, data: [{itemOption: 'menu'}]  },
    // WKS
    { path: 'wksEntity', component: WksEntityComponent, data: [{itemOption: 'principal'}]  },
   
    { path: 'wksManufacturersSpecifications', component: ManufacturersSpecificationsComponent, data: [{itemOption: 'principal'}]  },
    { path: 'bnsTableCustomers', component: BnsTableComponent, data: [{itemOption: 'wks-customers'}] },
    { path: 'bnsTableCustomers/:display', component: BnsTableComponent, data: [{itemOption: 'wks-customers'}] },
    { path: 'bnsTableSubcontractors', component: BnsTableComponent, data: [{itemOption: 'wks-subcontractors'}] },
    { path: 'bnsTableSuppliers', component: BnsTableComponent, data: [{itemOption: 'wks-suppliers'}] },
    { path: 'equipboats', component: EquipmentTableComponent, data: [{itemOption: 'boats'}] },
    { path: 'equipboats/:display', component: EquipmentTableComponent, data: [{itemOption: 'boats'}] },
    { path: 'equipjets', component: EquipmentTableComponent, data: [{itemOption: 'jets'}] },
    { path: 'equipjets/:display', component: EquipmentTableComponent, data: [{itemOption: 'jets'}] },
    { path: 'equiptrailers', component: EquipmentTableComponent, data: [{itemOption: 'trailers'}] },
    { path: 'equiptrailers/:display', component: EquipmentTableComponent, data: [{itemOption: 'trailers'}] },
    { path: 'equipengines', component: EquipmentTableComponent, data: [{itemOption: 'engines'}] },
    { path: 'equipengines/:display', component: EquipmentTableComponent, data: [{itemOption: 'engines'}] },
    { path: 'docstable', component: DocsTableComponent, data: [{itemOption: 'list'}] },
    { path: 'docstable/:display', component: DocsTableComponent, data: [{itemOption: 'list'}] },
    { path: 'anchoragestable', component: AnchoragesTableComponent, data: [{itemOption: 'list'}] },
    { path: 'anchoragestable/:display', component: AnchoragesTableComponent, data: [{itemOption: 'list'}] },
    { path: 'docstable/:display', component: DocsTableComponent, data: [{itemOption: 'list'}] },
    { path: 'param_worksrates', component: WorkratesTableComponent, data: [{itemOption: 'list'}] },
    { path: 'param_partssupplies', component: PartsSuppliesTableComponent, data: [{itemOption: 'list'}] },
    { path: 'param_actionsref', component: ActionsrefTableComponent, data: [{itemOption: 'list'}] },
    { path: 'param_tasksref', component: TasksrefTableComponent, data: [{itemOption: 'list'}] },
    { path: 'param_rh_planning', component: PlanningHrComponent, data: [{itemOption: 'list'}] },
    /*{ path: 'jobsWorks/:display', component: WorksTableComponent, data: [{itemOption: 'list'}]},*/
    { path: 'jobsWorks/:display', component: WorksTableComponent, data: [{itemOption: 'list'}]},
    { path: 'jobsAssignments/:display', component: WorksAssignmentsComponent, data: [{itemOption: 'list'}] },
    /*{ path: 'jobsAppointments/:display', component: AppointmentsTableComponent, data: [{itemOption: 'list'}]},*/
    { path: 'jobsAppointments', component: AppointmentsTableComponent, data: [{itemOption: 'list'}]},
    { path: 'jobsCalendar', component: CalendarComponent, data: [{itemOption: 'list'}]},
    { path: 'jobsAppointmentsPlanning', component: AppointmentsPlanningComponent, data: [{itemOption: 'list'}]},
    // WorksInvoicesComponent
    { path: 'jobsInvoicing/:display', component: WorksInvoicingComponent, data: [{itemOption: 'list'}]},
];
