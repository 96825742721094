import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../../job/common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

import { WksCentralService } from '../../../../services/wks-central.service';
import { WksAnchoragesModel, anchorages_sch, AnchorageEvent } from '../../../../models/wks-equipment.model';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';

@Component({
  selector: 'mdi-anchorages-table',
  templateUrl: './anchorages-table.component.html',
  styleUrls: ['./anchorages-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnchoragesTableComponent implements OnInit {
  displayType: string;
  optionItem: string;
  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = anchorages_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterAnchorage: boolean;
  doCreateAnchorage: boolean;
  doUpdateAnchorage = [];
  anchorageToUpdate: WksAnchoragesModel;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;

  actionType: string;

  anchoragesList: WksAnchoragesModel[];
  isInjected: boolean;

  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modal-lg',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    
  };
  valueWidth = false;
  modalText: any[];
  dataList: WksAnchoragesModel[];


  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _ressources: RessourcesService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });
    if (this.displayType === null) {
      this.displayType = 'list';
    }
    const labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this._wksCentralService.onSearchAnchorage
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: AnchorageEvent) => {
      this.anchoragesList = event.searchResult;
      this.anchorageToUpdate = event.searchCriteria;
      this.isInjected = true;
      this.displayType = 'list';
      this.buildList();
      // this.getWksEquipmentList();
    });
    this.optionItem = labelItem;
    this.timeFormat = this._translate.getLocalFmt('timeFormat');

    if (this.displayType === 'list') {
      this.buildList();
    }
  }
buildList() {
    this.displayedColumns = [
      { field: 'anchorageCountryLabel', headerName: this._translate.instant('anchorageCountry'), sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'anchorageState', headerName: this._translate.instant('anchorageState'), sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'anchorageCity', headerName: this._translate.instant('anchorageCity'), sortable: true, filter: true, resizable: true, width: 150 },
      { field: 'anchorageName', headerName: this._translate.instant('anchorageName'), sortable: true, filter: true, resizable: true },
      { field: 'stdLastupdate', headerName: this._translate.instant('stdLastupdate'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },

      { field: '', headerName: this._translate.instant('Action'),
          template: this.getActions(), width: 150, maxWidth: 150, minWidth: 150
      },
    ];

    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }


    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getWksAnchoragesList();

    this._wksCentralService.onRegisterValid
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if (event === 'cancelRegisterAnchorage') {
          // this.toggleSidenav('');

          this.cancelRegister();
        }
        // closeUploadAnchorages
        if (event === 'closeUploadAnchorages') {
          
          this.getWksAnchoragesList();
        }
        // cancelUploadAnchorages
        if (event === 'cancelUploadAnchorages') {
          
          this.displayType = 'list';
        }
        if (event === 'closeRegisterAnchorage') {
          this.closeRegister();
          this.getWksAnchoragesList();
        }
        if (event === 'closeRegisterAnchorage') {
          this.closeRegister();
          this.getWksAnchoragesList();
        }
        if (event === 'deleteAnchorage') {
          this.getWksAnchoragesList();
        }
      });
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  refresh() {
    this.getWksAnchoragesList();
  }
  newsearch() {
    this.displayType = 'newsearch';

  }
  getColDef() {
    return this.displayedColumns;
  }

  getActions() {
    let actions: string;
   
    actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
              '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' 
                  ;
   
    return actions;
  }
  updateRow($event: WksAnchoragesModel) {
    if ($event.lineCur) {
      this.dataSource.data[$event.lineCur] = $event;
    }
  }
  getWksAnchoragesList() {
    this.selected = [];

    if (this._userService.getUserLogged() === undefined ) { return; }
    if (this.isInjected) {
      this.dataSource.data = this.anchoragesList;
      this.cacheDatatemp = [...this.anchoragesList];
      this.extractColumnsName(this.dataSource.data);
      this.loadingSubject.next(false);
      this.loadingIndicator = false;
      return;
    }
    this._wksCentralService.getWksAnchoragesList()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.translateCountryLabel(this.dataSource.data);
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        if ((err.status === 500) || (err.status === 404)) {
          let titleBox: string;
          let messageBox: string;
          titleBox = 'searching anchorages';
          messageBox = 'no anchorage found';
          
          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  cancelRegister() {
    this.actionType = 'nothing';
  }
  closeRegister() {
    this.actionType = 'nothing';
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeDoc')) {
          this.deleteDocOK(dataCur);
        }
      });

  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editAnchorage(data);
                break;
            }
            case 'trash': {
                this.deleteAnchorage(data);
                break;
            }

        }
    }
}
  onSelectionChanged($event: any) {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    // console.log('Row selected : ' + selectedRow );
    this.anchorageToUpdate = selectedRow;
    // this.actionType = 'display';
  }
  translateCountryLabel(_dataArray: Array<any>) {
    for (const row of _dataArray) {
      const countryTmp = this._ressources.getCountry(row.anchorageCountry);
      row.anchorageCountryLabel = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
    }
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateAnchorage = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  
  editAnchorageAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.anchorageToUpdate = selectedRow;
    

    this.actionType = 'update';

    this.doCreateAnchorage = false;
    // this.toggleSidenav('registerDoc');
  }
  editAnchorage(row: WksAnchoragesModel) {
    const selectedRow = this.gridApi.getSelectedRows();
    this.anchorageToUpdate = selectedRow;
    this.anchorageToUpdate = row;

    this.actionType = 'update';
    this.displayType = 'list';
    this.doCreateAnchorage = false;
    // this.toggleSidenav('registerDoc');
  }
  addAnchorage() {
  
    this.doCreateAnchorage = true;
    this.anchorageToUpdate = undefined;
    this.actionType = 'add';
    this.displayType = 'list';
    // this.toggleSidenav('registerDoc');
  }
  uploadAnchorages() {
  
    this.doCreateAnchorage = false;
    this.anchorageToUpdate = undefined;
    this.displayType = 'upload';
    this.actionType = 'upload';

    // this.toggleSidenav('registerDoc');
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteAnchorage(row: WksAnchoragesModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'deleting docs';
    

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.anchorageName;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeDoc', row );

  }
  deleteDocOK(row: WksAnchoragesModel) {

      this._wksCentralService.saveWksAnchorage(row, 'deleteAnchorage')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteAnchorage');
        },
        () => {

        }
      );
    
  }
 
}
