/**
 * Composant destiné à lister les utilisateurs.
 * Les fonctionnalités sont :
 *  - selection globale
 *  - selection par colonnes
 *  - ajouter / supprimer ds colonnes dans la liste affichée
 *  - gestion de la pagination
 *  - afficher le détail de l'utilisateur sur le survol de sa ligne
 *  - éditer un utilisateur pour le modifier
 *  - supprimer un utilisateur
 *
 */
import { Component, OnInit, OnDestroy , ViewChild, AfterViewInit, ViewEncapsulation, Renderer2, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { animate, style, state, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { mjlusers_sch } from '../../../models/tables.model';
import { UserResponse, UserCard } from '../../../models/user.model';
import { FieldsListService } from '../../../job/common/components/dynamic-form/fields-list.service';
import { FieldsFormgroupService } from '../../../job/common/components/dynamic-form/fields-formgroup.service';


@Component({
  selector: 'mdi-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open <=> closed', animate('1000ms ease-in')),
    ])
  ],
  providers: [FieldsListService],
  /* animations: [
     trigger('fadeInOut', [
       state('open', style({transform: 'scale(0.1)'})),
       state('closed', style({transform: 'scale(1)'})),
       transition('open <=> closed', animate( '800ms ease-in' )),
     ])
   ],*/
  encapsulation: ViewEncapsulation.None,
})
export class UsersTableComponent implements OnInit, OnDestroy, AfterViewInit {

  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) m_sidenav;
  dataSource = new MatTableDataSource();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  pageSize = 12;
  templateFor: string;
  // menu
  conditions: any;
  filterForm: FormGroup;
  columnsForm: FormGroup;
  opCtrl: FormControl;
  val1Ctrl: FormControl;
  val2Ctrl: FormControl;
  columnsChoiceCtrl: FormControl;
  forFirstValue: true;
  forSecondValue: true;
  hasNoValue: true;
  // fin menu
  // sidenav
  isFilterContent: boolean;
  isChoiceCol: boolean;
  isRegisterUser: boolean;
  isUploadFiles: boolean;
  doCreateUser: boolean;
  userToUpdate: UserResponse;
  statutRegister;

  loadingIndicator = true;
  reorderable = true;
  openFilters = false;
  openUploadFiles = false;

  stateFilters = 'closed';
  stateUploadFiles = 'closed';
  fields: any[];

  displayedColumns: any;

  dataColumnsName: any;
  dataColumnsDef = mjlusers_sch;
  dataColumns;
  selectedRowIndex;
  selected = [];
  cacheDatatemp = [];
  currentFilters: any;
  dateFormat: any;
  // modal 
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg modal-dialog-centered modal-dialog-std modal-body-std',
    backdrop: true,
    ignoreBackdropClick: true
  };
  modalText: any[];
  constructor( private renderer: Renderer2,
    private _userService: UserService,
    private _translate: TranslateService,
    private _fls: FieldsListService,
    private _ffgs: FieldsFormgroupService,
    private fb: FormBuilder,
    private modalService: BsModalService) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
      this.fields = _fls.getFields();
  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */


  ngOnInit() {

    // this.dataSource = new UsersTableDataSource(this.paginator, this.sort, this.userService);
    // this.dataSource.loadUsers();
    this.displayedColumns = [
      { prop: 'id', name: this._translate.instant('id'), isDate: false },
      { prop: 'username', name: this._translate.instant('username'), isDate: false },
      { prop: 'firstname', name: this._translate.instant('firstName'), isDate: false },
      { prop: 'lastname', name: this._translate.instant('lastName'), isDate: false },
      { prop: 'email', name: this._translate.instant('email'), isDate: false }
    ];
    /// 'id', 'firstName', 'lastName', 'email', 'actions'];
    this.loadingSubject.next(true);
    this.getListUsers();
    this.conditions = CommonMethods.jsonToArray(environment.conditions);

    this.opCtrl = this.fb.control('');
    this.val1Ctrl = this.fb.control('');
    this.val2Ctrl = this.fb.control('');
    this.columnsChoiceCtrl = this.fb.control('', Validators.required);
    this.filterForm = this.fb.group({
      op: this.opCtrl,
      val1: this.val1Ctrl,
      val2: this.val2Ctrl,
    });
    this.columnsForm = this.fb.group({
      columnsChoice: this.columnsChoiceCtrl,
    });
    this._ffgs.onFiltersChanged.subscribe((filters: any) => {
      this.dataSource.data = this.cacheDatatemp;
      const filtered = this.filterByColumn(this.dataSource.data, filters);
      // this.AllColumnsFilter(filters.firstName);
      this.dataSource.data = filtered;
      this.currentFilters = filters;
    });
    this._ffgs.onFiltersClosed.subscribe((event: any) => {
      if (event === 'closedFilters') {
        this.toggleFilters();
      }
    });
    this._userService.onRegisterValid.subscribe((event: any) => {
      if ((event === 'closeRegister') || (event === 'cancelRegister')) {
        this.toggleSidenav('');
        this.getListUsers();
      }
      if (event === 'deleteRegister') {
        this.getListUsers();
      }
    });

  }
  getListUsers() {
    this._userService.getUsersList()
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.dataSource.data.sort((a: UserResponse, b: UserResponse) => a.id - b.id);
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
        // console.log('Utilisateurs ' + JSON.stringify(this.data));
      },
      error => {
        console.log(error);
      }
    );
  }
  ngOnDestroy() {

    /* this._userService.onRegisterValid.unsubscribe();
    this._ffgs.onFiltersClosed.unsubscribe();
    this._ffgs.onFiltersChanged.unsubscribe();*/
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {
    this.modalRef.hide();
  }
  translateLabels() {

    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];
      let colDate = false;
      if (lTmp.prop === 'birthday') {
        colDate = true;
      }
      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': colDate
      });
    }

    this.dateFormat = this._translate.getLocalFmt('dateFormat');
  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }

    this.dataColumnsName = Object.keys(_dataArray[0]);
    // console.log('Columns current : ', this.dataColumns);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
      this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }

  ngAfterViewInit() {


  }
  toggleFilters() {
    this.openFilters = !this.openFilters;
    this.stateFilters = this.stateFilters === 'open' ? 'closed' : 'open';
  }
  toggleUploadFiles() {
    this.openUploadFiles = !this.openUploadFiles;
    this.stateUploadFiles = this.stateUploadFiles === 'open' ? 'closed' : 'open';
  }

  toggleSidenav(_arg: string) {

    this.isFilterContent = false;
    this.isChoiceCol = false;
    this.isRegisterUser = false;
    this.isUploadFiles = false;

    if (_arg === 'filterContent') {
      this.isFilterContent = true;
      this.isChoiceCol = false;
      this.isRegisterUser = false;
      this.isUploadFiles = false;
    }
    if (_arg === 'choiceCol') {
      this.isFilterContent = false;
      this.isChoiceCol = true;
      this.isRegisterUser = false;
      this.isUploadFiles = false;
    }
    if (_arg === 'registerUser') {
      this.isFilterContent = false;
      this.isChoiceCol = false;
      this.isRegisterUser = true;
      this.isUploadFiles = false;
    }
    if (_arg === 'uploadFiles') {
      this.isFilterContent = false;
      this.isChoiceCol = false;
      this.isRegisterUser = false;
      this.isUploadFiles = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.m_sidenav.close();
    } else {
      this.m_sidenav.open();
    }
  }

  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }


  disableUser(row: any) {
    console.log('disableUser Row clicked: ', row);
    const userCard = new UserCard();
    userCard.username = row.username;
    userCard.id = row.id;
    this._userService.register(userCard, 'disableUser')
      .subscribe(
        () => {
          this._userService.onRegisterValid.emit('deleteRegister');
        },
        // () => this.registrationFailed = true
      );
  }
  editUser(row: any) {
    this.userToUpdate = row;

    this.statutRegister = 'updateUser';
    console.log('editUser Row clicked: ', row);
    this.doCreateUser = false;
    this.toggleSidenav('registerUser');
  }
  changePwd(row: any) {
    console.log('changePwd Row clicked: ', row);
    const userCard = new UserCard();
    userCard.username = row.username;

    this._userService.register(userCard, 'changePwd')
      .subscribe(
        () => {
          this._userService.onRegisterValid.emit('pwdChanged');
        },
        // () => this.registrationFailed = true
      );
      this.modalText = [row.username, row.email];
  }
  addUser() {
    console.log('addUser: ');
    this.statutRegister = 'createUser';
    this.doCreateUser = true;
    this.toggleSidenav('registerUser');
  }
  columnFilter(colName: string, filterValue: string) {
    const filters = { [colName]: [filterValue] };
    const filtered = this.filterByColumn(this.dataSource.data, filters);
    // update the rows
    this.dataSource.data = filtered;
    // console.log('columnFilter: ', colName, ' : ', filterValue);
  }
  AllColumnsFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    // console.log('columnFilter: ', colName, ' : ', filterValue);
  }
  filterByColumn(array: any[], filters: Object) {

    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => {
          // return o[k].toLowerCase().includes(v);
          return this.filterComparator(o[k], v, k);
        }
        )
      )
    );
  }
  filterComparator(_dataValue: any, _dataFilter: any, _dataName: string): boolean {
    let dataType: string;
    this.dataColumnsDef.find(c => {
      if (c.colName === _dataName) {
        dataType = c.colType;
        return true;
      }
    });
    if (!dataType) {
      return false;
    }
    switch (dataType) {
      case 'string': {
        // console.log(_dataValue.toLowerCase(), '  ' , _dataFilter.toLowerCase());
        // return _dataValue.toLowerCase().includes(_dataFilter.toLowerCase());
        return new RegExp(_dataFilter, 'i').test(_dataValue);
      }
      case 'number': {
        return _dataValue <= _dataFilter;
      }
      default: {
        // return _dataValue.toLowerCase().includes(_dataFilter);
        return new RegExp(_dataFilter, 'i').test(_dataValue);
      }
    }

  }
  onFilterClick(templateFor: string) {
    this.templateFor = templateFor;
  }
  onFilterSubmit() {
    console.log('onFilterSubmit ');
  }

  chkbColumnChoice(event) {
    // const checked = event.source.checked ? 'Oui' : 'Non';
    if (event.source.checked) {
      this.addColumn(event.source.id);
      this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
        this.dataColumnsName, this.displayedColumns, false);
    } else {
      this.removeColumn(event.source.id);
      this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
        this.dataColumnsName, this.displayedColumns, false);
    }
  }
  addColumn(_colAdded: string) {
    this.displayedColumns = CommonMethods.addOption(this.displayedColumns, this.dataColumnsDef, _colAdded);
    this.translateLabels();
  }
  removeColumn(_colDeleted: string) {
    this.displayedColumns = CommonMethods.removeOption(this.displayedColumns, _colDeleted);
  }
}
