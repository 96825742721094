import { Component, Input, OnInit, SimpleChanges, OnChanges, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import getSymbolFromCurrency from 'currency-symbol-map';

import { CalculationCmd, CmdExpression, CondClause, CondExpression, DataOperation } from '../../../../../models/common.model';
import { CommonMethods } from '../../../tools/commonMethods';
import { EconomicAreas, LabelValue, CountryLib } from '../../../../../models/data.model';
import { TranslateService } from '../../../../../services/translate.service';
import { WksCentralService } from '../../../../job-wks/services/wks-central.service';
import { RessourcesService } from '../../../services/ressources.service';
import { RemoteFilesService } from '../../../../../job/common/services/remote-files.service';
import { CalculationAlgoService } from '../../../services/calculation-algo.service';
import { ParamCustoms } from '../../../../job-wks/models/wks-param.model';

@Component({
  selector: 'mdi-calculation-algo-table',
  templateUrl: './calculation-algo-table.component.html',
  styleUrls: ['./calculation-algo-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalculationAlgoTableComponent implements OnInit, OnChanges {

  @Input() calculationType: string;
  @Input() calculationAlgo:  CalculationCmd[];
  @Input() entityCur: string;
  @Input() otherData: any;
  @Input() isReadOnly: boolean;

  @Output() dataOut = new EventEmitter<CalculationCmd[]>();
  
  sidenavCollapsed: boolean;

  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  
  OKtoDisplayCalculation: boolean;

  cmdList: LabelValue[];

  fixedRows: number;
  editedRow: number;
  optionDuties: boolean;
  operatorsList: LabelValue[];
  comparatorsList: LabelValue[];
  andOrList: LabelValue[];
  hasBackdrop: boolean;
  testOK: boolean;

  currenciesCacheList: LabelValue[];
  currenciesFilteredList: LabelValue[];
  countriesFilteredList: LabelValue[]; 
  countriesCacheList: LabelValue[];
  currencyImported: string;
  currencyLocale: string;
  currencyImportedDisplay: boolean;
  exchangeRateTechnicalCode: string;

  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
  };
  economicAreaCur: string; 

  constructor( private _translate: TranslateService,
            private _calculationAlgoService: CalculationAlgoService,
            private _wksCentralService: WksCentralService,
            private _remoteFilesService: RemoteFilesService,
            private _ressourcesService: RessourcesService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'calculationAlgo': {
            this.calculationAlgo = changes['calculationAlgo'].currentValue;
            if ( this.calculationAlgo.length === 0) {
              this.calculationAlgo = undefined;
            }
            break;
          }
          case 'calculationType': {
            this.calculationType = changes['calculationType'].currentValue;
            break;
          }
          case 'entityCur': {
            this.entityCur = changes['entityCur'].currentValue;
            break;
          }
          case 'otherData': {
            this.otherData = changes['otherData'].currentValue;
            break;
          }
          case 'isReadOnly': {
            this.isReadOnly = changes['isReadOnly'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    this.initBoolean();

    if (!this.initParam())  {
      return ;
    }
    this.loadEconomicAreas(this._wksCentralService.getWksEntityCur().entityCountry);
    this.hasBackdrop = true;
   
  }
  initBoolean(): void {
    this.hasBackdrop = false;
    this.OKtoDisplayCalculation = false;
    this.sidenavCollapsed = true;
    this.testOK = false;
    this.optionDuties = false;
    this.currencyImportedDisplay = false;
  }
  initParam(): boolean {
    
    this.buildListOperators();
    this.buildListComparators();
    this.buildListAndOr();
    if (!this.settingIntFmts())  {
      return false;
    }
    this.loadCurrencies();
    this.loadCountries();

    this.cmdList = [];
    
    this.fixedRows = 0;
    if (this.calculationType === 'localPrice') {
      this.fixedRows = 1;
    }
    if (this.calculationType === 'importedPrice') {
      this.fixedRows = 2;
    }

    this.editedRow = -1;
    return true;
  }
  initCurrencies(): void {
    for (const rowCur of this.calculationAlgo) {
      if (rowCur.constantName === 'incomingCurrency') {
        this.currencyImported = getSymbolFromCurrency(rowCur.currentValue);
        this.currencyImportedDisplay = true;

      }
      // exchangeRateTechnicalCode
      if (rowCur.constantName === 'exchangeRate') {
        this.exchangeRateTechnicalCode = rowCur.technicalCode;
      }
      for (const operCur of rowCur.dataOperations) {
        if (operCur.cmdExpression !== undefined && operCur.cmdExpression.technicalCodes[1] === this.exchangeRateTechnicalCode ) {
          this.currencyImportedDisplay = false;
        }
      }
    }
  }
  initCalculationAlgo(): void  {
    this.calculationAlgo = [];
    if (this.calculationType === 'localPrice') {
      const emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('purchasePriceET');
      emptyRow.constantName = 'purchasePriceET';
      emptyRow.currentValue = 100;
      emptyRow.currencyFmt = true;
      emptyRow.currencyImported = false;
      emptyRow.numberValue = true;
      emptyRow.fixedData = true;
      this.calculationAlgo.push(emptyRow);
    } else if (this.calculationType === 'importedPrice') {
      let emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('economicAreaEntity');
      emptyRow.constantName = 'economicAreaEntity';
      emptyRow.fixedData = true;
      emptyRow.noCmdData = true;
      emptyRow.currentValue = this.economicAreaCur;
      emptyRow.inputTest = false;
      emptyRow.stringValue = true;
      this.calculationAlgo.push(emptyRow);
      emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('countryOrigin');
      emptyRow.constantName = 'countryOrigin';
      emptyRow.fixedData = true;
      emptyRow.noCmdData = true;
      emptyRow.countryInput = true;
      emptyRow.currentValue = '';
      emptyRow.stringValue = true;
      this.calculationAlgo.push(emptyRow);
      emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('hsCode');
      emptyRow.constantName = 'hsCode';
      emptyRow.noCmdData = true;
      emptyRow.fixedData = true;
      emptyRow.currentValue = '';
      emptyRow.stringValue = true;
      this.calculationAlgo.push(emptyRow);
      emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('incomingCurrency');
      emptyRow.constantName = 'incomingCurrency';
      emptyRow.currentValue = '';
      emptyRow.noCmdData = true;
      emptyRow.currencyInput = true;
      emptyRow.stringValue = true;
      emptyRow.fixedData = true;
      this.calculationAlgo.push(emptyRow);
      emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('purchasePriceET');
      emptyRow.constantName = 'purchasePriceET';
      emptyRow.currentValue = 100;
      emptyRow.currencyFmt = true;
      emptyRow.currencyImported = true;
      emptyRow.numberValue = true;
      emptyRow.fixedData = true;
      this.calculationAlgo.push(emptyRow);
      emptyRow = this.emptyRowCalculation();
      emptyRow.dataLabel = this._translate.getTranslate('exchangeRate');
      emptyRow.constantName = 'exchangeRate';
      emptyRow.fixedData = true;
      emptyRow.currentValue = 1;
      emptyRow.numberValue = true;
      this.exchangeRateTechnicalCode = emptyRow.technicalCode;
      this.calculationAlgo.push(emptyRow);
    } else {
      this.calculationAlgo.push(this.emptyRowCalculation());
    } 
  } 
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }

    
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'numbers')
    };
    this.currencyLocale = getSymbolFromCurrency(this.numberFormat.currency);
    return true;
  }
  loadEconomicAreas(entityCountry: string)  {
    this._remoteFilesService.loadEconomicAreas()
    .subscribe(
      data => {
        const economicAreas = data.body as EconomicAreas[];
        this._calculationAlgoService.setEconomicAreas(economicAreas);
        this.economicAreaCur = this._calculationAlgoService.getEconomicArea(entityCountry);
        if (this.calculationAlgo === undefined) {
          this.initCalculationAlgo();
        } else  {
          if (this.calculationType === 'importedPrice') {
            this.initCurrencies();
            this.loadParamCustomsEcoZoneList();
          }
          this.buildCmdList([]);
        }
        this.OKtoDisplayCalculation = true;
      },
      err =>  {
        console.log('loadEconomicAreas ' + err);
      }
    );
  }
  loadParamCustomsEcoZoneList(): void {
    this._wksCentralService.getParamCustomsEcoZoneList(this.economicAreaCur)
    .subscribe(
      data => {
        const paramCustoms = data.body as ParamCustoms[];
        this._calculationAlgoService.setParamCustoms(paramCustoms);
      },
      err =>  {
        console.log('loadParamCustomsEcoZoneList ' + err);
      }
    );
  
  }
  loadCountries(): void {
    const countriesListTmp = this._ressourcesService.getCountriesList();
    this.countriesCacheList = [];
    const countryTmp = {
      label: '',
      value: ''
    };
    this.countriesCacheList.push(countryTmp);

    for (const countryCur of countriesListTmp) {
      
      this.countriesCacheList.push({
        label: countryCur.isoAlpha2,
        value: countryCur.isoAlpha2
      });

    }

    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.value > obj2.value ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];

  }
  loadCurrencies(): void {
    const currenciesOrig = this._ressourcesService.getCurrencies();
    this.currenciesFilteredList = [];
    this.currenciesCacheList = [];
    const currrencyTmp = {
      label: '',
      value: ''
    };
    this.currenciesCacheList.push(currrencyTmp);
    for (const curTmp of currenciesOrig) {
      const currrency = {
        label: curTmp.label,
        value: curTmp.iso,
      };
      this.currenciesCacheList.push(currrency);
    }
    this.currenciesCacheList.sort((obj1, obj2) => {
      return obj1.value > obj2.value ? 1 : -1;
    });

    this.currenciesFilteredList = [...this.currenciesCacheList];
  }
  onKeyCurrency($event: any) {
    // console.log(value);
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.currenciesFilteredList = this.searchCurrency(valueFinal);

  }
  searchCurrency(value: string) { 
    const filter = value.toLowerCase();
    return this.currenciesCacheList.filter(brandCur => brandCur.value.toLowerCase().includes(filter));
  }
  buildListAndOr(): void {
    this.andOrList = [];

    let andOrCur: LabelValue = {
      label: '',
      value: 'empty'
    };
    this.andOrList.push(andOrCur);
    andOrCur = {
      label: '&&',
      value: 'and'
    };
    this.andOrList.push(andOrCur);
    andOrCur = {
      label: '||',
      value: 'or'
    };
    this.andOrList.push(andOrCur);
    
  }
  buildListComparators(): void {
    this.comparatorsList = [];

    let comparatorCur: LabelValue = {
      label: '',
      value: 'empty'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '==',
      value: 'equal'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '!=',
      value: 'not-equal'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '>=',
      value: 'gt-equal'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '<=',
      value: 'lt-equal'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '>',
      value: 'gt'
    };
    this.comparatorsList.push(comparatorCur);
    comparatorCur = {
      label: '<',
      value: 'lt'
    };
    this.comparatorsList.push(comparatorCur);
  }
  buildListOperators(): void {
    this.operatorsList = [];
    let operatorCur: LabelValue = {
      label: ' ',
      value: 'empty'
    };
    this.operatorsList.push(operatorCur);
    operatorCur = {
      label: '+',
      value: 'add'
    };
    this.operatorsList.push(operatorCur);
    operatorCur = {
      label: '-',
      value: 'sub'
    };
    this.operatorsList.push(operatorCur);
    operatorCur = {
      label: '*',
      value: 'multi'
    };
    this.operatorsList.push(operatorCur);
    operatorCur = {
      label: '/',
      value: 'divi'
    };
    this.operatorsList.push(operatorCur);
    operatorCur = {
      label: '*%',
      value: 'percent'
    };
    this.operatorsList.push(operatorCur);
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  addRowCalculation(idxRow: number): void {
    this.calculationAlgo.splice(idxRow + 1, 0, this.emptyRowCalculation());
    this.editedRow = idxRow + 1;
    this.calculationAlgo[this.editedRow].currentValue = 0;
    this.calculationAlgo[this.editedRow].currencyFmt = true;
    this.calculationAlgo[this.editedRow].numberValue = true;
    this.calculationAlgo[this.editedRow].currencyImported = this.currencyImportedDisplay;

    if (this.calculationType === 'importedPrice') {
      this.optionDuties = true;
      for (const calAlgoCur of this.calculationAlgo) {
        if (calAlgoCur.duties) {
          this.optionDuties = false;
        }
      }
    }
    this.addRowOperation(0, 'expression');
    this.toggleSidenav('');
  }
  editRowCalculation(idxRow: number): void {
    this.editedRow = idxRow;
    this.toggleSidenav('');
  }
  emptyRowCalculation(): CalculationCmd {
    const emptyRow: CalculationCmd = {
      dataLabel: '',
      technicalCode: CommonMethods.randomString(3) + '_' + Math.floor(Math.random() * 10000).toString(),
      constantName: '',
      countryInput: false,
      currencyInput: false,
      currencyFmt: false,
      currencyImported: false,
      fixedData: false,
      noCmdData: false,
      inputTest: true,
      duties: false,
      dataOperations:  [],
      stringValue: false,
      numberValue: false,
      currentValue: undefined,
      otherData: ''
    };
     return  emptyRow;
  }

  removeRowCalculation(idxRow: number): void {
    if (idxRow === 0) {
      return;
    }
    if (this.calculationAlgo[idxRow].constantName === 'exchangeRate') {
      this.currencyImportedDisplay = false;

    }
    this.calculationAlgo.splice(idxRow, 1);
    this.editedRow -= 1;
  }
  onRowInputChange(_typeInput, inputValue: any, idxItem: number): void {
    switch (_typeInput) {
      case 'dataLabel':
        this.calculationAlgo[this.editedRow].dataLabel = inputValue;
        this.buildCmdList([this.calculationAlgo[this.editedRow].technicalCode]);
        break;
      case 'duties':
        this.calculationAlgo[this.editedRow].duties = inputValue;
        this.buildCmdList([this.calculationAlgo[this.editedRow].technicalCode]);
        break;
      case 'incomingCurrency':
        this.calculationAlgo[idxItem].currentValue = inputValue;
        this.currencyImported = getSymbolFromCurrency(inputValue);
        
        this.currencyImportedDisplay = true;
        break;
      case 'countryOrigin':
        this.calculationAlgo[idxItem].currentValue = inputValue;
        const ecoZoneCur = this._calculationAlgoService.getEconomicArea(inputValue);

        this.calculationAlgo[idxItem].otherData = (ecoZoneCur !== undefined ? ecoZoneCur : inputValue);
        break;
      case 'stringValue':
        this.calculationAlgo[idxItem].currentValue = inputValue;
        break;
      case 'numberValue':
        this.calculationAlgo[idxItem].currentValue = inputValue;
        break;
    }
  }
  addRowOperation(idxOperation: number, typeCmd: string): void {
    switch (typeCmd) {
      case 'expression':
        this.calculationAlgo[this.editedRow].dataOperations.splice(idxOperation + 1, 0, this.emptyRowOperation());
        break;
      case 'conditionnal':
        this.calculationAlgo[this.editedRow].dataOperations.splice(idxOperation + 1, 0, this.emptyConditionnalOperation());
        break;
    }

  }
  removeRowOperation(idxOperation: number): void {
    if (this.calculationAlgo[this.editedRow].dataOperations.length === 1) {
      return;
    }
    this.calculationAlgo[this.editedRow].dataOperations.splice(idxOperation, 1);
  }
  emptyRowOperation(): DataOperation {
    const emptyRow: DataOperation = {
      conditionnal: false,
      conditionnalExpression: undefined,
      cmdExpression: this.emptyCmdExpression()
    };
     return  emptyRow;
  }
  addRowCondOperation(idxOperation: number, idxExpression: number): void {
    // nestedExpressions
    
    switch (idxExpression) {
      case -1:
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions = [];
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions.push(this.emptyCmdExpression());
        break;
      default:
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions.splice(idxExpression + 1, 0, this.emptyCmdExpression());
        break;
    }
  }
  removeRowCondOperation(idxOperation: number, idxExpression: number): void {
    if ( this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions.length === 1) {
      return;
    }
    this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions.splice(idxExpression, 1);
  }
  addRowClauseCond(idxOperation: number, idxClause: number): void {
    // nestedExpressions
    
    switch (idxClause) {
      case -1:
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses = [];
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses.push(this.emptyConditionalClause());
        break;
      default:
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses.splice(idxClause + 1, 0, this.emptyConditionalClause());
        break;
    }
  }
  removeRowClauseCond(idxOperation: number, idxClause: number): void {
    if ( this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses.length === 1) {
      return;
    }
    this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses.splice(idxClause, 1);
  }
  emptyConditionnalOperation(): DataOperation {
    const emptyRow: DataOperation = {
      conditionnal: true,
      conditionnalExpression: this.emptyConditionnalExpression(),
      cmdExpression: undefined
    };
     return  emptyRow;
  }
  emptyConditionnalExpression(): CondExpression {
    const emptyRow: CondExpression = {
      condClauses: [],
      nestedExpressions: []
    };
    emptyRow.condClauses.push(this.emptyConditionalClause());
    emptyRow.nestedExpressions.push(this.emptyCmdExpression());
    return  emptyRow;
  } 
  emptyConditionalClause(): CondClause {
    const emptyRow: CondClause = {
      orAnd: 'empty',
      comparator: 'empty',
      numberValues:  [, ],
      stringValues: ['empty', 'empty'],
      technicalCodes: ['empty', 'empty'],
    };
    return  emptyRow;
  }
  emptyCmdExpression(): CmdExpression {
    const emptyExpression: CmdExpression = {
      operande: ['empty', 'empty'],
      numberValues:  [, ],
      stringValues: ['empty', 'empty'],
      technicalCodes: ['empty', 'empty']
    };
     return  emptyExpression;
  }
  onOperationInputChange(_typeInput, inputValue: any, idxOperation: number): void {
    switch (_typeInput) {
      case 'operande1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.operande[0] = inputValue;
        break;
      case 'operande2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.operande[1] = inputValue;
        break;
      case 'input1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.technicalCodes[0] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.numberValues[0] = 0;
        }
        break;
      case 'input2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.technicalCodes[1] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.numberValues[1] = 0;
        }
        if (this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.technicalCodes[1] === this.exchangeRateTechnicalCode) {
          this.currencyImportedDisplay = false;
          this.calculationAlgo[this.editedRow].currencyImported = false;
        }

        break;
      case 'numberValue1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.numberValues[0] = inputValue;
        break;
      case 'numberValue2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].cmdExpression.numberValues[1] = inputValue;
        break;
    }
  }
  onCondClauseInputChange(_typeInput, inputValue: any, idxOperation: number, idxClause: number): void {
    switch (_typeInput) {
      case 'andOrInput':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].orAnd = inputValue;
        if (inputValue === 'empty') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].comparator = inputValue;
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].technicalCodes[0] = inputValue;
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].technicalCodes[1] = inputValue;
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].numberValues[0] = 0;
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].numberValues[1] = 0;
        }
        break;
      case 'comparatorInput':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].comparator = inputValue;
        break;
      case 'condInput1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].technicalCodes[0] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].numberValues[0] = 0;
        }
        break;
      case 'condInput2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].technicalCodes[1] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].numberValues[1] = 0;
        }
        break;
      case 'condDataValue':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.condClauses[idxClause].numberValues[1] = inputValue;
        break;
      
    }
  }
  onCondOperationInputChange(_typeInput, inputValue: any, idxOperation: number, idxCondOperation: number): void {
    switch (_typeInput) {
      
      case 'condExpOperande1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].operande[0] = inputValue;
        break;
      case 'condExpOperande2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].operande[1] = inputValue;
        break;
      case 'condExpInput1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].technicalCodes[0] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].numberValues[0] = 0;
        }
        break;
      case 'condExpInput2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].technicalCodes[1] = inputValue;
        if (inputValue !== '') {
          this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].numberValues[1] = 0;
        }
        break;
      case 'condExpDataValue1':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].numberValues[0] = inputValue;
        break;
      case 'condExpDataValue2':
        this.calculationAlgo[this.editedRow].dataOperations[idxOperation].conditionnalExpression.nestedExpressions[idxCondOperation].numberValues[1] = inputValue;
        break;
    }
  }
  buildCmdList(excludeTechnicalsCode: string[]): void {
    this.cmdList = [];
    this.cmdList.push({
      label: '',
      value: 'empty'
    });
    for (const rowCur of this.calculationAlgo) {
      let isFound = false;
      if (rowCur.noCmdData) {
        continue;
      }
      for (const technicalCur of excludeTechnicalsCode) {
        if (rowCur.technicalCode === technicalCur && !rowCur.duties) {
          isFound = true;
          break;
        }
      }
      if (isFound) {
        continue;
      }
      const cmdCur: LabelValue = {
        label: rowCur.dataLabel,
        value: rowCur.technicalCode
      };
      this.cmdList.push(cmdCur);
    }
    this.cmdList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.cmdList.push({
      label: this._translate.getTranslate('previous oper'),
      value: 'prev'
    });
  }
  checkCmdSelection(cmdCur: LabelValue, idxOperation: number): boolean {
    if (idxOperation > 0 ) {
      return false;
    }
    if (cmdCur.value === 'prev') {
      return true;
    }
  }
  toggleSidenav(_arg: string): void {
    if (_arg === 'backdrop') {
      return;
    }

    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.m_sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.m_sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  validAlgo(): void {

    this.dataOut.emit(this.calculationAlgo);
    this.testOK = false;
  }
  doTest(): void {
    this._calculationAlgoService.executeAlgo(this.calculationAlgo);
    if (!this.isReadOnly) {
      this.testOK = true;
    }
  }
}
