import { SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgZone } from '@angular/core';
import { take } from 'rxjs/operators';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GridTools } from '../../../../../common/tools/gridTools';
const ɵ0 = MAT_MOMENT_DATE_FORMATS;
export class WorksRecapAmountComponent {
    constructor(_userService, ressourceService, _translate, _wksWorksService, _wksCentralService, _ressourcesService, adapterDate, _ngZone) {
        this._userService = _userService;
        this.ressourceService = ressourceService;
        this._translate = _translate;
        this._wksWorksService = _wksWorksService;
        this._wksCentralService = _wksCentralService;
        this._ressourcesService = _ressourcesService;
        this.adapterDate = adapterDate;
        this._ngZone = _ngZone;
        this.dataOut = new EventEmitter();
        this.onDestroy = new Subject();
        this._translate.onLangChanged.subscribe(() => {
            // this.translateLabels();
        });
    }
    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosizeComment.resizeToFitContent(true));
    }
    ngOnChanges(changes) {
        const listKey = Object.keys(changes);
        for (const propName of listKey) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'workCur': {
                        this.workCur = changes['workCur'].currentValue;
                        break;
                    }
                    case 'statutCall': {
                        this.statutCall = changes['statutCall'].currentValue;
                        break;
                    }
                    case 'ihmOptionsCkeck': {
                        this.ihmOptionsCkeck = changes['ihmOptionsCkeck'].currentValue;
                        break;
                    }
                } // end switch
            } // end if
        } // end loop
        this.initData();
    }
    initData() {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        this.initAllBooleans();
        this.loadParams();
        this.loadCheck(false)
            .then(() => {
            // recap Equip and totalisation
        }, err => {
            // console.log('getWorksWaitingDate' + err.message);
            // this.displayRecapAmountOK = true;
        });
    }
    initAllBooleans() {
        this.displayCheckOK = false;
        this.displayRecapAmountOK = false;
        this.onlyMainEquip = false;
        this.isReadOnly = true;
        this.isOkToSave = false;
        this.isOkToAction = false;
        this.paymentPercentOK = true;
        this.isOkToUpdateWorks = false;
        this.isCustomerCondition = false;
        this.displayAdvancesOK = false;
        this.displayCreditnotesOK = false;
    }
    loadParams() {
        this.adapterDate.setLocale(this._translate.currentLang);
        const paiementsTerm = this._wksCentralService.getPaiementsTerm();
        const delaysItem = paiementsTerm.delaysDays;
        this.delayList = [];
        for (const item of delaysItem) {
            const label = item.toString() + ' (' + this._translate.instant('days') + ')';
            const opt = {
                value: item,
                label: label,
            };
            this.delayList.push(opt);
        }
        this.delayList.splice(0, 0, {
            value: '',
            label: '',
        });
        let conditionsItems;
        if (this.statutCall === 'invoicing') {
            conditionsItems = paiementsTerm.conditions;
        }
        else {
            conditionsItems = paiementsTerm.conditionsWorks;
        }
        this.conditionsQuotes = [];
        this.conditionsAdvances = [];
        for (const item of paiementsTerm.conditionsWorks) {
            const label = this._translate.instant(item);
            const opt = {
                value: item,
                label: label,
            };
            this.conditionsQuotes.push(opt);
            this.conditionsAdvances.push(opt);
        }
        this.conditionsQuotes.sort((obj1, obj2) => {
            return obj1.label > obj2.label ? 1 : -1;
        });
        this.conditionsAdvances.sort((obj1, obj2) => {
            return obj1.label > obj2.label ? 1 : -1;
        });
        this.conditionsList = [];
        for (const item of conditionsItems) {
            const label = this._translate.instant(item);
            const opt = {
                value: item,
                label: label,
            };
            this.conditionsList.push(opt);
        }
        this.conditionsList.sort((obj1, obj2) => {
            return obj1.label > obj2.label ? 1 : -1;
        });
        /*
        this.conditionsList.splice(0, 0, {
          value: '',
          label: '',
        });
        */
        this.wksEntity = this._wksCentralService.getWksEntityCur();
        this.settingIntFmts();
        this.wksEntityParams = this._ressourcesService.getWksEntityParams();
        const optionsList = ['discount', 'discountCash', 'surcharge', 'rounding'];
        this.financialList = [];
        for (const itemValue of optionsList) {
            const itemLabel = this._translate.instant(itemValue);
            const opt = {
                value: itemValue,
                label: itemLabel,
            };
            this.financialList.push(opt);
        }
        this.financialList.sort((obj1, obj2) => {
            return obj1.label > obj2.label ? 1 : -1;
        });
        const optionsBaseList = ['supplies&parts', 'labor'];
        this.baseWorkList = [];
        for (const itemValue of optionsBaseList) {
            const itemLabel = this._translate.instant(itemValue);
            const opt = {
                value: itemValue,
                label: itemLabel,
            };
            this.baseWorkList.push(opt);
        }
        this.baseWorkList.sort((obj1, obj2) => {
            return obj1.label > obj2.label ? 1 : -1;
        });
        this.financialListRef = [];
        for (const itemValue of optionsList) {
            for (const itemBaseValue of optionsBaseList) {
                const itemRef = {
                    rowType: itemValue,
                    rowBaseWork: itemBaseValue
                };
                this.financialListRef.push(itemRef);
            }
        }
        this.isReadOnly = this.ihmOptionsCkeck.readOnly;
        this.actionTitle = 'quotation';
        if (this.statutCall === 'invoicing') {
            this.actionTitle = 'invoice';
        }
    }
    settingIntFmts() {
        const otherData = JSON.parse(this.wksEntity.otherData);
        const internationnalFormat = otherData.internationnalFormat;
        this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
        for (const fmtCur of this.internationalsFormats.datetime) {
            if (fmtCur.name === 'dateFormat') {
                this.dateFormat = fmtCur.value;
                this.dateFormatMoment = fmtCur.value;
                this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
                this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
            }
            if (fmtCur.name === 'timeFormat') {
                this.dateTimeFormat = fmtCur.value;
                this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
                this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
            }
        }
        // this.adapterDate.setLocale(this.translate.currentLang);
        const userLang = navigator.language;
        this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
        // this.localelang = userLang;
        this.localelang = this._translate.currentLang;
        this.localeDateFmt = userLang ? userLang : otherData.language + '_' + internationnalFormat;
        const intFormat = otherData.internationnalFormat;
        this.numberFormat = {
            locale: this.ressourceService.getFormat(intFormat, 'numbers', 'locale'),
            minDecimal: this.ressourceService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
            maxDecimal: this.ressourceService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
            currency: this.ressourceService.getFormat(intFormat, 'numbers', 'currency'),
            justifCurrency: this.ressourceService.getFormat(internationnalFormat, 'justifs', 'currency'),
            justifText: this.ressourceService.getFormat(internationnalFormat, 'justifs', 'text'),
            justifNumbers: this.ressourceService.getFormat(internationnalFormat, 'justifs', 'numbers')
        };
        const countryDef = this._ressourcesService.getCountry(this._userService.getEntityLogged().entitycountry);
        const taxProfilesList = countryDef.taxation.profilesTax;
        const entityOptions = JSON.parse(this.wksEntity.entityOptions);
        const profileName = entityOptions.taxProfile;
        for (const taxProfileCur of taxProfilesList) {
            if (taxProfileCur.profileName === profileName) {
                this.profileTax = taxProfileCur;
                break;
            }
        }
    }
    loadCheck(doValidData) {
        return new Promise((resolve, reject) => {
            this.workEquipments = {
                mainEquipment: undefined,
                subEquipments: undefined
            };
            this.workEquipmentId = JSON.parse(this.workCur.workEquip);
            this.loadMainEquipment(this.workEquipmentId.mainEquipment)
                .then((response) => {
                this.workEquipments.mainEquipment = response;
                if (this.workEquipmentId.subEquipments) {
                    this.workEquipments.subEquipments = [];
                    this.loadsubEquipments()
                        .then((responseCur) => {
                        // responseCur.isWorkEquip = true;
                        this.workEquipments.subEquipments = responseCur;
                        for (const itemEquipment of this.workEquipments.subEquipments) {
                            itemEquipment.isWorkEquip = true;
                        }
                        this.initCheck();
                        this.displayCheckOK = true;
                    })
                        .catch((error) => {
                        console.log('loadSubEquipment ' + error);
                        return;
                    });
                }
                else {
                    this.initCheck();
                    this.displayCheckOK = true;
                }
                resolve();
            })
                .catch((error) => {
                console.log('loadEquipment ' + error);
                this.displayCheckOK = false;
                return;
            });
        });
    }
    loadsubEquipments() {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        let equipList = '';
        for (const equipCur of this.workEquipmentId.subEquipments) {
            if (equipList === '') {
                equipList = equipCur;
            }
            else {
                equipList = equipList + ';' + equipCur;
            }
        }
        return new Promise((resolve, reject) => {
            this._wksCentralService.getWksSubEquipment(this.workCur.stdEntity, equipList)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(data => {
                resolve(data.body);
            }, err => {
                // console.log('getOwnerEquipmentList ' + err.message);
                reject(err.status);
            });
        });
    }
    loadMainEquipment(equipId) {
        if (this._userService.getUserLogged() === undefined) {
            return;
        }
        return new Promise((resolve, reject) => {
            this._wksCentralService.getWksEquipmentByEquipId(this.workCur.stdEntity, equipId)
                .pipe(takeUntil(this.onDestroy))
                .subscribe(data => {
                resolve(data.body);
            }, err => {
                // console.log('getOwnerEquipmentList ' + err.message);
                reject(err.status);
            });
        });
    }
    inputData(dataReturn) {
        const dataTasks = dataReturn.tasksActions;
        const inputChanged = dataReturn.inputChange;
        if (!inputChanged) {
            this.isOkToUpdateWorks = false;
            return;
        }
        // console.log(dataTasks);
        for (const taskInput of dataTasks) {
            let isFound = false;
            let idx = 0;
            for (let taskCur of this.tasksActionsCompleted) {
                if ((taskInput.equipId === taskCur.equipId) && (taskInput.taskrefId === taskCur.taskrefId)) {
                    isFound = true;
                    taskCur = taskInput;
                    this.tasksActionsCompleted.splice(idx, 1);
                    this.tasksActionsCompleted.push(taskInput);
                    break;
                }
                idx++;
            }
            if (!isFound) {
                this.tasksActionsCompleted.push(taskInput);
            }
        }
        this.isOkToUpdateWorks = true;
    }
    updateWorks() {
        this._wksWorksService.fillModelTasksCompleted(this.workCur, this.tasksActionsCompleted, this.workEquipments, this._userService.getUserLogged().entity);
        this._wksWorksService.saveWksWorksCheckList(this.workCur, this.workEquipments, this._wksCentralService)
            .then((reponseCur) => {
        }, (err) => {
            console.log(err);
        });
        this.initCheck('update');
        this.isOkToUpdateWorks = false;
    }
    initCheck(_action) {
        this.checkEquipments = [];
        this.onlyMainEquip = false;
        if (this.workEquipments.subEquipments) {
            this.checkEquipments = this.workEquipments.subEquipments;
        }
        else {
            this.onlyMainEquip = true;
            this.checkEquipments.push(this.workEquipments.mainEquipment);
        }
        this.fillCheckTasks(_action);
        if (this.statutCall === 'invoicing') {
            this.initInvoicingDataFromJson(_action, 'final');
        }
        else if (this.statutCall === 'advances') {
            this.initAdvancesDataFromJson(_action);
        }
        else if (this.statutCall === 'creditnotes') {
            this.initCreditnotesDataFromJson(_action);
        }
        else if (this.statutCall === 'quotation') {
            this.initQuotationDataFromJson(_action);
        }
        if (this.statutCall === 'invoicing') { // for equipments tabs
            this.itemEquip = this.checkEquipments[0];
            this.tasksActionsArgs = [];
            for (const itemTasks of this.tasksActionsCompleted) {
                if (itemTasks.equipId === this.itemEquip.id) {
                    this.tasksActionsArgs.push(itemTasks);
                }
            }
            this.checkEquipments[0].isSelected = true;
        }
        this.checkEquipmentsIsLoaded = true;
    }
    fillCheckTasks(action) {
        // pour TEST
        // this.workCur.workJsonQuotation = undefined;
        // fin TEST
        let worksActionsSelected = [];
        let suppliesActionsSelected = [];
        let equipments;
        let okToCheck;
        this.tasksActionsCompleted = [];
        let workEquipmentId;
        let numberEquip = 0;
        workEquipmentId = JSON.parse(this.workCur.workEquip);
        if (this.onlyMainEquip) {
            numberEquip = 1;
        }
        else {
            numberEquip = workEquipmentId.subEquipments.length;
        }
        let durationCur = 0;
        let equipmentsCur = 0;
        for (const taskCur of this.workCur.workTasksContent) {
            if (taskCur.taskrefId === null) {
                continue;
            }
            worksActionsSelected = [];
            suppliesActionsSelected = [];
            const taskrefJson = JSON.parse(taskCur.taskrefJson);
            if (taskrefJson.tasksActionsParentContent !== undefined) {
                const tasksActionsParentContent = taskrefJson.tasksActionsParentContent;
                worksActionsSelected = tasksActionsParentContent.works;
                suppliesActionsSelected = tasksActionsParentContent.supplies;
            }
            if (taskrefJson.tasksActionsContent !== undefined) {
                const tasksActionsContent = taskrefJson.tasksActionsContent;
                worksActionsSelected.push(...tasksActionsContent.works);
                suppliesActionsSelected.push(...tasksActionsContent.supplies);
                equipments = tasksActionsContent.equipments;
                if (equipments.all) {
                    equipmentsCur = numberEquip;
                }
                else {
                    equipmentsCur = equipments.equipId.length;
                }
                // console.log(tasksActionsContent);
            }
            if (taskrefJson.tasksActionCompleted !== undefined && this.statutCall === 'invoicing') { // invoice
                // this.tasksActionsCompleted = [...taskrefJson.tasksActionCompleted as TasksActionCompletedModel[]];
                for (const taskTmpCur of taskrefJson.tasksActionCompleted) {
                    if (this.onlyMainEquip) {
                        taskTmpCur.equipSN = this.workEquipments.mainEquipment.equipSN;
                    }
                    else {
                        if (this.workEquipments.subEquipments) {
                            for (const equipCur of this.workEquipments.subEquipments) {
                                if (taskTmpCur.equipId === equipCur.id) {
                                    taskTmpCur.equipSN = equipCur.equipSN;
                                    break;
                                }
                            }
                        }
                    }
                    this.tasksActionsCompleted.push(taskTmpCur);
                }
            }
            else { // quotation
                for (const equipItem of this.checkEquipments) {
                    okToCheck = false;
                    if (equipments.all) {
                        okToCheck = true;
                    }
                    else {
                        for (const idEquip of equipments.equipId) {
                            if (idEquip === equipItem.id) {
                                okToCheck = true;
                                break;
                            }
                        }
                    }
                    if (okToCheck) {
                        durationCur = 0;
                        for (const workTmp of worksActionsSelected) {
                            durationCur += Number(workTmp.quantity);
                        }
                        const taskEmpty = {
                            equipId: equipItem.id,
                            equipSN: (equipItem.equipSN ? equipItem.equipSN : undefined),
                            taskrefId: taskCur.taskrefId,
                            taskrefLabel: taskCur.taskrefLabel,
                            works: worksActionsSelected,
                            supplies: suppliesActionsSelected,
                            additionalTasks: undefined,
                            equipHours: (equipItem.equipHours ? equipItem.equipHours : -1),
                            taskDuration: durationCur
                        };
                        this.tasksActionsCompleted.push(taskEmpty);
                    }
                }
                if (worksActionsSelected.length > 0) {
                    durationCur = 0;
                    for (const workTmp of worksActionsSelected) {
                        durationCur += Number(workTmp.quantity);
                    }
                    this.workCur.workDuration += (durationCur * equipmentsCur);
                }
            }
        }
    }
    initAdvancesDataFromJson(action) {
        this.workEquipRecapAmount = [];
        if (this.workCur.workJsonInvoicing === undefined
            || this.workCur.workJsonInvoicing === null
            || this.workCur.workJsonInvoicing === '') {
            this.workInvoicingData = this.initWorkInvoicingData();
            const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
            this.initAdvances(workQuotation);
            this.totalisationRecap(false);
        }
        else {
            this.workInvoicingData = JSON.parse(this.workCur.workJsonInvoicing);
            if (this.workInvoicingData.advances === undefined) {
                const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
                this.initAdvances(workQuotation);
                this.isOkToAction = true;
            }
            else {
                this.itemCur = {
                    itemType: this.statutCall,
                    itemData: '',
                    itemRow: -1
                };
                this.workInvoicingDataCache = this.workInvoicingData;
                this.displayAdvancesOK = true;
            }
        }
    }
    initCreditnotesDataFromJson(action) {
        this.workEquipRecapAmount = [];
        if (this.workCur.workJsonInvoicing === undefined
            || this.workCur.workJsonInvoicing === null
            || this.workCur.workJsonInvoicing === '') {
            this.totalisationRecap(false);
        }
        else {
            this.workInvoicingData = JSON.parse(this.workCur.workJsonInvoicing);
            if (this.workInvoicingData.creditnotes === undefined) {
                this.initCreditnotes();
                this.isOkToAction = false;
            }
            else {
                if (this.workInvoicingData.creditnotes.length === 0) {
                    this.initCreditnotes();
                }
                else {
                    this.itemCreditnote = [];
                    for (const itemCur of this.workInvoicingData.creditnotes) {
                        const itemaction = {
                            isOkToAction: itemCur.emitOK ? false : true
                        };
                        this.itemCreditnote.push(itemaction);
                    }
                }
                this.itemCur = {
                    itemType: this.statutCall,
                    itemData: '',
                    itemRow: -1
                };
                this.workInvoicingDataCache = this.workInvoicingData;
                this.displayCreditnotesOK = true;
            }
        }
    }
    initInvoicingDataFromJson(action, itemArg) {
        this.workEquipRecapAmount = [];
        this.itemCur = {
            itemType: this.statutCall,
            itemData: itemArg,
            itemRow: -1
        };
        if (action === 'update') {
            this.totalisationRecap(false);
            this.verifDataInvoicing(this.recapAmountInput);
            this.displayRecapAmountOK = true;
        }
        else if (this.workCur.workJsonInvoicing === undefined
            || this.workCur.workJsonInvoicing === null
            || this.workCur.workJsonInvoicing === '') {
            this.workInvoicingData = this.initWorkInvoicingData();
            this.totalisationRecap(false);
            this.initConditionsFromQuotation(this.recapAmountInput);
            this.displayRecapAmountOK = true;
        }
        else {
            this.workInvoicingData = JSON.parse(this.workCur.workJsonInvoicing);
            if (this.workInvoicingData.invoices !== undefined && this.workInvoicingData.invoices.length > 0) {
                let isFound = false;
                for (const itemInvoice of this.workInvoicingData.invoices) {
                    if (itemInvoice.item === itemArg) {
                        this.recapAmountInput = itemInvoice.recapAmount;
                        this.recapAmountCache = itemInvoice.recapAmount;
                        isFound = true;
                        break;
                    }
                }
                if (!isFound) {
                    return;
                }
                this.initRecapEquip();
                this.initAdvances(this.recapAmountInput);
                if (this.recapAmountCache.paymentRecap === undefined) {
                    this.initPaymentFromQuotation(this.recapAmountInput);
                }
                else {
                    this.isCustomerCondition = this.recapAmountCache.paymentRecap.customerConditions;
                }
                this.isOkToAction = true;
                this.displayRecapAmountOK = true;
            }
            else {
                this.recapAmountInput = this.initWorkRecapAmount();
                this.initAdvances(this.recapAmountInput);
                this.totalisationRecap(false);
                this.initConditionsFromQuotation(this.recapAmountInput);
                this.displayRecapAmountOK = true;
            }
        }
    }
    initQuotationDataFromJson(action) {
        this.workEquipRecapAmount = [];
        this.itemCur = {
            itemType: this.statutCall,
            itemData: '',
            itemRow: -1
        };
        if (this.workCur.workJsonQuotation === undefined
            || this.workCur.workJsonQuotation === null
            || this.workCur.workJsonQuotation === '') {
            this.totalisationRecap(false);
            // this.recapAmountCache = this.recapAmountInput;
            this.displayRecapAmountOK = true;
            this.isOkToAction = true;
        }
        else {
            if (action === 'update') {
                this.totalisationRecap(false);
                this.verifDataInvoicing(this.recapAmountInput);
                this.displayRecapAmountOK = true;
            }
            else {
                this.recapAmountInput = JSON.parse(this.workCur.workJsonQuotation);
                this.recapAmountCache = JSON.parse(this.workCur.workJsonQuotation);
                this.initRecapEquip();
                if (this.recapAmountCache.paymentRecap === undefined) {
                    this.initPaymentFromQuotation(this.recapAmountInput);
                }
                else {
                    this.isCustomerCondition = this.recapAmountCache.paymentRecap.customerConditions;
                }
                this.isOkToAction = true;
                this.displayRecapAmountOK = true;
            }
        }
    }
    selectedTabChange($event, matTabSelected) {
        // console.log(matTabSelected);
        if (matTabSelected.index + 1 > this.checkEquipments.length) {
            return;
        }
        const itemArg = this.checkEquipments[matTabSelected.index];
        itemArg.isSelected = true;
        this.itemEquip = itemArg;
        this.tasksActionsArgs = [];
        for (const itemTasks of this.tasksActionsCompleted) {
            if (itemTasks.equipId === itemArg.id) {
                this.tasksActionsArgs.push(itemTasks);
            }
        }
    }
    toggleRecap() {
        this.recapAmountInput.isCollapsed = !this.recapAmountInput.isCollapsed;
        return;
    }
    toggleOtherRows() {
        if (this.recapAmountInput.otherWorkRows === undefined || this.recapAmountInput.otherWorkRows.length === 0) {
            this.addOtherRow(-1);
        }
        this.recapAmountInput.isOtherCollapsed = !this.recapAmountInput.isOtherCollapsed;
        return;
    }
    addOtherRow(idxRow) {
        const otherItem = {
            rowId: '',
            rowLabel: '',
            rowSubtracting: false,
            supplies: 0,
            labor: 0,
        };
        // this.recapAmountInput.otherWork.push(otherItem);
        if (idxRow === -1) {
            this.recapAmountInput.otherWorkRows = [];
            this.recapAmountInput.otherWorkRows.push(otherItem);
        }
        else {
            this.recapAmountInput.otherWorkRows.splice(idxRow + 1, 0, otherItem);
            this.removeRoundingRows(undefined, true);
        }
    }
    removeOtherRow(idxRow) {
        this.recapAmountInput.otherWorkRows.splice(idxRow, 1);
        if (this.recapAmountInput.otherWorkRows.length === 0) {
            this.recapAmountInput.isOtherCollapsed = true;
        }
        this.removeRoundingRows(undefined, true);
        this.recapAmountInput = this.totalisationWork(true, true);
    }
    changeOtherRowSubtracting(idxRow) {
        this.recapAmountInput.otherWorkRows[idxRow].rowSubtracting = !this.recapAmountInput.otherWorkRows[idxRow].rowSubtracting;
        if (this.recapAmountInput.otherWorkRows[idxRow].rowSubtracting) {
            this.recapAmountInput.otherWorkRows[idxRow].labor = this.recapAmountInput.otherWorkRows[idxRow].labor * -1;
            this.recapAmountInput.otherWorkRows[idxRow].supplies = this.recapAmountInput.otherWorkRows[idxRow].supplies * -1;
        }
        else {
            if (this.recapAmountInput.otherWorkRows[idxRow].labor < 0) {
                this.recapAmountInput.otherWorkRows[idxRow].labor = this.recapAmountInput.otherWorkRows[idxRow].labor * -1;
            }
            if (this.recapAmountInput.otherWorkRows[idxRow].supplies < 0) {
                this.recapAmountInput.otherWorkRows[idxRow].supplies = this.recapAmountInput.otherWorkRows[idxRow].supplies * -1;
            }
        }
        this.removeRoundingRows(undefined, true);
        this.recapAmountInput = this.totalisationWork(true, true);
        this.newPercentCalculation();
    }
    toggleFinancialRows() {
        if (this.recapAmountInput.financialWorkRows === undefined || this.recapAmountInput.financialWorkRows.length === 0) {
            this.addFinancialRow(-1);
        }
        this.recapAmountInput.isFinancialCollapse = !this.recapAmountInput.isFinancialCollapse;
        return;
    }
    addFinancialRow(idxRow) {
        if (this.recapAmountInput.financialWorkRows.length === this.financialListRef.length) {
            return;
        }
        const financialItem = {
            rowType: '',
            rowLabel: '',
            rowLabelCplt: '',
            rowSubtracting: true,
            rowPercent: 0,
            rowBaseWork: '',
            rowBase: 0,
            rowAmount: 0,
        };
        this.hiddenRowTypeList();
        for (const itemCur of this.financialList) {
            if (!itemCur.hidden) {
                financialItem.rowType = itemCur.value;
                this.hiddenBaseWorkList(itemCur.value);
                for (const itemTmp of this.baseWorkList) {
                    if (!itemTmp.hidden) {
                        financialItem.rowBaseWork = itemTmp.value;
                        break;
                    }
                }
                break;
            }
        }
        // this.recapAmountInput.otherWork.push(otherItem);
        if (idxRow === -1) {
            this.recapAmountInput.financialWorkRows = [];
            this.recapAmountInput.financialWorkRows.push(financialItem);
            idxRow = 0;
        }
        else {
            this.recapAmountInput.financialWorkRows.splice(idxRow + 1, 0, financialItem);
            if (financialItem.rowType !== 'rounding') {
                this.removeRoundingRows(undefined, true);
            }
        }
    }
    removeFinancialRow(idxRow) {
        if (this.recapAmountInput.financialWorkRows[idxRow].rowType !== 'rounding') {
            this.removeRoundingRows(undefined, true);
        }
        this.recapAmountInput.financialWorkRows.splice(idxRow, 1);
        this.hiddenRowTypeList();
        if (this.recapAmountInput.financialWorkRows.length === 0) {
            this.recapAmountInput.isFinancialCollapse = true;
        }
        this.recapAmountInput = this.totalisationWork(true, true);
    }
    removeRoundingRows(rowBaseWorkArg, removeAllRows) {
        let idxRow = 0;
        if (this.recapAmountInput === undefined
            || this.recapAmountInput.financialWorkRows === undefined) {
            return;
        }
        let nbRowsRounding = 0;
        if (!removeAllRows) {
            for (const itemRow of this.recapAmountInput.financialWorkRows) {
                if (rowBaseWorkArg === undefined) {
                    if (itemRow.rowType === 'rounding') {
                        nbRowsRounding++;
                    }
                }
                else if (itemRow.rowType === 'rounding' && itemRow.rowBaseWork === rowBaseWorkArg) {
                    nbRowsRounding++;
                }
            }
        }
        if (nbRowsRounding > 1 || removeAllRows) {
            for (const itemRow of this.recapAmountInput.financialWorkRows) {
                if (rowBaseWorkArg === undefined) {
                    if (itemRow.rowType === 'rounding') {
                        this.recapAmountInput.financialWorkRows.splice(idxRow, 1);
                    }
                }
                else if (itemRow.rowType === 'rounding' && itemRow.rowBaseWork === rowBaseWorkArg) {
                    this.recapAmountInput.financialWorkRows.splice(idxRow, 1);
                    break;
                }
                idxRow++;
            }
        }
        this.hiddenRowTypeList();
    }
    onFinancialSelected(idxRow, selectedOption) {
        this.recapAmountInput.financialWorkRows[idxRow].rowType = selectedOption;
        this.hiddenBaseWorkList(selectedOption);
        for (const itemCur of this.baseWorkList) {
            if (!itemCur.hidden) {
                this.recapAmountInput.financialWorkRows[idxRow].rowBaseWork = itemCur.value;
                break;
            }
        }
        if (selectedOption === 'discount') {
            this.recapAmountInput.financialWorkRows[idxRow].rowSubtracting = true;
        }
        if (selectedOption === 'discountCash') {
            this.recapAmountInput.financialWorkRows[idxRow].rowSubtracting = true;
        }
        if (selectedOption === 'surcharge') {
            this.recapAmountInput.financialWorkRows[idxRow].rowSubtracting = false;
        }
        this.percentCalculation(idxRow);
        if (selectedOption !== 'rounding') {
            this.removeRoundingRows(this.recapAmountInput.financialWorkRows[idxRow].rowBaseWork, true);
        }
        this.recapAmountInput = this.totalisationWork(true, true);
    }
    hiddenRowTypeList() {
        for (const itemCur of this.financialList) {
            itemCur.hidden = false;
            let iNb = 0;
            for (const itemFinancialRow of this.recapAmountInput.financialWorkRows) {
                if (itemFinancialRow.rowType === itemCur.value) {
                    iNb++;
                    if (iNb === this.baseWorkList.length) {
                        itemCur.hidden = true;
                        break;
                    }
                }
            }
        }
    }
    financialChangeValues(_typeInput, inputValue, index) {
        switch (_typeInput) {
            case 'rowLabelCplt':
                this.recapAmountInput.financialWorkRows[index].rowLabelCplt = inputValue;
                break;
            case 'rounding':
                // this.recapAmountInput.financialWorkRows[index].rowAmount = inputValue;
                let numberRounding = parseFloat(inputValue);
                numberRounding = Math.round(numberRounding * 100) / 100;
                // console.log(numberValue);
                if (isNaN(numberRounding)) {
                    this.recapAmountInput.financialWorkRows[index].rowBase = 0;
                }
                else {
                    this.recapAmountInput.financialWorkRows[index].rowBase = numberRounding;
                }
                this.roundingCalculation(numberRounding, this.recapAmountInput.financialWorkRows[index].rowBaseWork, index);
                break;
            case 'surcharge':
                // this.recapAmountInput.financialWorkRows[index].rowPercent = inputValue;
                let numberPercent1 = parseFloat(inputValue);
                numberPercent1 = Math.round(numberPercent1 * 100) / 100;
                // console.log(numberValue);
                if (isNaN(numberPercent1)) {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = 0;
                }
                else {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = numberPercent1;
                    this.recapAmountInput.financialWorkRows[index].rowSubtracting = false;
                }
                this.removeRoundingRows(this.recapAmountInput.financialWorkRows[index].rowBaseWork, true);
                this.percentCalculation(index);
                this.recapAmountInput = this.totalisationWork(true, true);
                break;
            case 'discount':
                // this.recapAmountInput.financialWorkRows[index].rowPercent = inputValue;
                let numberPercent2 = parseFloat(inputValue);
                numberPercent2 = Math.round(numberPercent2 * 100) / 100;
                // console.log(numberValue);
                if (isNaN(numberPercent2)) {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = 0;
                }
                else {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = numberPercent2;
                    this.recapAmountInput.financialWorkRows[index].rowSubtracting = true;
                }
                this.removeRoundingRows(this.recapAmountInput.financialWorkRows[index].rowBaseWork, true);
                this.percentCalculation(index);
                this.recapAmountInput = this.totalisationWork(true, true);
                break;
            case 'discountCash':
                // this.recapAmountInput.financialWorkRows[index].rowPercent = inputValue;
                let numberPercent3 = parseFloat(inputValue);
                numberPercent3 = Math.round(numberPercent3 * 100) / 100;
                // console.log(numberValue);
                if (isNaN(numberPercent3)) {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = 0;
                }
                else {
                    this.recapAmountInput.financialWorkRows[index].rowPercent = numberPercent3;
                    this.recapAmountInput.financialWorkRows[index].rowSubtracting = true;
                }
                this.removeRoundingRows(this.recapAmountInput.financialWorkRows[index].rowBaseWork, true);
                this.percentCalculation(index);
                this.recapAmountInput = this.totalisationWork(true, true);
                break;
        }
    }
    onBaseWorkSelected(idxRow, selectedOption) {
        this.recapAmountInput.financialWorkRows[idxRow].rowBaseWork = selectedOption;
        this.recapAmountInput.financialWorkRows[idxRow].rowPercent = 0;
        this.recapAmountInput.financialWorkRows[idxRow].rowAmount = 0;
        if (this.recapAmountInput.financialWorkRows[idxRow].rowType !== 'rounding') {
            this.hiddenBaseWorkList(this.recapAmountInput.financialWorkRows[idxRow].rowType);
            this.removeRoundingRows(selectedOption, true);
            this.newPercentCalculation();
        }
        else {
            this.newRoundingCalculation();
        }
        this.recapAmountInput = this.totalisationWork(true, true);
    }
    hiddenBaseWorkList(rowTypeArg) {
        for (const itemCur of this.baseWorkList) {
            itemCur.hidden = false;
            for (const itemFinancialRow of this.recapAmountInput.financialWorkRows) {
                if (itemFinancialRow.rowType === rowTypeArg && itemFinancialRow.rowBaseWork === itemCur.value) {
                    itemCur.hidden = true;
                    break;
                }
            }
        }
        // console.log('hiddenRowTypeList :' + JSON.stringify(this.baseWorkList) );
    }
    percentCalculation(index) {
        // this.totalisationBTEquip(this.recapAmountInput);
        // this.totalisationBTOther(this.recapAmountInput);
        const wksWorksRecapAmount = this.initWorkRecapAmount();
        this.totalisationBTEquip(wksWorksRecapAmount);
        this.totalisationBTOther(wksWorksRecapAmount);
        this.recapAmountInput.workTotalBT = wksWorksRecapAmount.workTotalBT;
        if (this.recapAmountInput.financialWorkRows[index].rowBaseWork === 'supplies&parts') {
            this.recapAmountInput.financialWorkRows[index].rowBase = this.recapAmountInput.workTotalBT.supplies;
        }
        else {
            this.recapAmountInput.financialWorkRows[index].rowBase = this.recapAmountInput.workTotalBT.labor;
        }
        const result = this.recapAmountInput.financialWorkRows[index].rowBase *
            (this.recapAmountInput.financialWorkRows[index].rowPercent / 100);
        const amount = parseFloat(result.toFixed(2)) * (this.recapAmountInput.financialWorkRows[index].rowSubtracting ? -1 : 1);
        this.recapAmountInput.financialWorkRows[index].rowAmount = amount;
    }
    newPercentCalculation() {
        if (this.recapAmountInput.financialWorkRows === undefined || this.recapAmountInput.financialWorkRows.length === 0) {
            return;
        }
        this.recapAmountInput.financialWorkTotalBT.labor = 0;
        this.recapAmountInput.financialWorkTotalBT.supplies = 0;
        let idxRow = 0;
        for (const itemFinancial of this.recapAmountInput.financialWorkRows) {
            if (itemFinancial.rowType === 'discount' || itemFinancial.rowType === 'discountCash' || itemFinancial.rowType === 'surcharge') {
                this.percentCalculation(idxRow);
            }
            idxRow++;
        }
        this.totalisationBTFinancial(this.recapAmountInput);
    }
    newRoundingCalculation() {
        if (this.recapAmountInput.financialWorkRows === undefined || this.recapAmountInput.financialWorkRows.length === 0) {
            return;
        }
        this.recapAmountInput.financialWorkTotalBT.labor = 0;
        this.recapAmountInput.financialWorkTotalBT.supplies = 0;
        let idxRow = 0;
        for (const itemFinancial of this.recapAmountInput.financialWorkRows) {
            if (itemFinancial.rowType === 'rounding') {
                this.roundingCalculation(itemFinancial.rowBase, itemFinancial.rowBaseWork, idxRow);
            }
            idxRow++;
        }
    }
    // HT = TTC / [1 + (taux de TVA / 100)]
    roundingCalculation(amountWT, rowBaseWork, index) {
        let taxPercent = 0;
        for (const itemTax of this.recapAmountInput.workTax) {
            if (itemTax.taxBaseWork === rowBaseWork) {
                taxPercent += itemTax.taxPercent;
            }
        }
        const coefficientTax = 1 + (taxPercent / 100);
        let resultBTtarget = amountWT / coefficientTax;
        resultBTtarget = Math.round(resultBTtarget * 100) / 100;
        let sumTaxTarget = amountWT - resultBTtarget;
        sumTaxTarget = Math.round(sumTaxTarget * 100) / 100;
        let newSumTax = 0;
        for (const itemTax of this.recapAmountInput.workTax) {
            if (rowBaseWork === 'labor' && itemTax.taxBaseWork === 'labor') {
                let amountTax = (resultBTtarget * itemTax.taxPercent) / 100;
                amountTax = Math.round(amountTax * 100) / 100;
                itemTax.labor = amountTax;
                newSumTax += amountTax;
            }
            if (rowBaseWork === 'supplies&parts' && itemTax.taxBaseWork === 'supplies&parts') {
                let amountTax = (resultBTtarget * itemTax.taxPercent) / 100;
                amountTax = Math.round(amountTax * 100) / 100;
                itemTax.supplies = amountTax;
                newSumTax += amountTax;
            }
        }
        if (newSumTax !== sumTaxTarget) {
            resultBTtarget = resultBTtarget + (newSumTax - sumTaxTarget);
        }
        // this.recapAmountInput.financialWorkRows[index].rowAmount
        this.recapAmountInput = this.totalisationWork(false, true);
        let amountTarget = 0;
        if (rowBaseWork === 'supplies&parts') {
            amountTarget = resultBTtarget - this.recapAmountInput.workTotalBT.supplies;
            amountTarget = Math.round(amountTarget * 100) / 100;
            this.recapAmountInput.financialWorkRows[index].rowAmount = amountTarget;
        }
        else {
            amountTarget = resultBTtarget - this.recapAmountInput.workTotalBT.labor;
            amountTarget = Math.round(amountTarget * 100) / 100;
            this.recapAmountInput.financialWorkRows[index].rowAmount = amountTarget;
        }
        this.recapAmountInput = this.totalisationWork(true, true);
    }
    numberAndDotOnly(event) {
        return CommonMethods.numberAndDotOnly(event);
    }
    setOtherChangeValues(_typeInput, inputValue, index) {
        switch (_typeInput) {
            case 'rowLabel':
                this.recapAmountInput.otherWorkRows[index].rowLabel = inputValue;
                this.verifDataInvoicing(this.recapAmountInput);
                break;
            case 'supplies':
                let numberValueSupplies = parseFloat(inputValue);
                numberValueSupplies = Math.round(numberValueSupplies * 100) / 100;
                // console.log(numberValue);
                if (isNaN(numberValueSupplies)) {
                    this.recapAmountInput.otherWorkRows[index].supplies = 0;
                }
                else {
                    this.recapAmountInput.otherWorkRows[index].supplies = numberValueSupplies;
                    if (this.recapAmountInput.otherWorkRows[index].rowSubtracting) {
                        this.recapAmountInput.otherWorkRows[index].supplies = this.recapAmountInput.otherWorkRows[index].supplies * -1;
                    }
                }
                // this.recapAmountInput.otherWork[index].supplies = numberValueSupplies;
                this.removeRoundingRows('supplies&parts', false);
                this.newPercentCalculation();
                this.newRoundingCalculation();
                this.recapAmountInput = this.totalisationWork(true, true);
                break;
            case 'labor':
                let numberValueLabor = parseFloat(inputValue);
                numberValueLabor = Math.round(numberValueLabor * 100) / 100;
                // console.log(numberValueLabor);
                if (isNaN(numberValueLabor)) {
                    this.recapAmountInput.otherWorkRows[index].labor = 0;
                }
                else {
                    this.recapAmountInput.otherWorkRows[index].labor = numberValueLabor;
                    if (this.recapAmountInput.otherWorkRows[index].rowSubtracting) {
                        this.recapAmountInput.otherWorkRows[index].labor = this.recapAmountInput.otherWorkRows[index].labor * -1;
                    }
                }
                // this.recapAmountInput.otherWork[index].labor = numberValueLabor;
                this.removeRoundingRows('labor', false);
                this.newPercentCalculation();
                this.newRoundingCalculation();
                this.recapAmountInput = this.totalisationWork(true, true);
                break;
        }
        // this.registerActions('supplies&parts');
    }
    onChangePaymentField(_field, value) {
        switch (_field) {
            case 'customerFinancials': {
                this.recapAmountInput.paymentRecap.customerFinancials = value;
                break;
            }
            case 'specificCashpay': {
                this.recapAmountInput.paymentRecap.specificsConditions.cashpay = value;
                if (this.recapAmountInput.paymentRecap.specificsConditions.cashpay) {
                    this.recapAmountInput.paymentRecap.specificsConditions.payments = [];
                    this.paymentPercentOK = true;
                    this.verifDataInvoicing(this.recapAmountInput);
                }
                else {
                    this.paymentPercentOK = false;
                    this.recapAmountInput.paymentRecap.specificsConditions.cashpay = false;
                    this.recapAmountInput.paymentRecap.specificsConditions.payments = [];
                    this.recapAmountInput.paymentRecap.specificsConditions.payments.push({ orderNumber: 0,
                        term: this.conditionsList[0].value,
                        delay: this.delayList[0].value,
                        percent: 0,
                        balance: false,
                        observations: '' });
                    this.verifDataInvoicing(this.recapAmountInput);
                }
                break;
            }
            case 'paymentCondition': {
                this.recapAmountInput.paymentRecap.customerConditions = false;
                this.isCustomerCondition = false;
                if (value === 'customerConditions') {
                    this.recapAmountInput.paymentRecap.customerConditions = true;
                    this.isCustomerCondition = true;
                    this.verifDataInvoicing(this.recapAmountInput);
                }
                else {
                    this.paymentPercentOK = false;
                    this.recapAmountInput.paymentRecap.specificsConditions.cashpay = false;
                    this.recapAmountInput.paymentRecap.specificsConditions.payments = [];
                    this.recapAmountInput.paymentRecap.specificsConditions.payments.push({ orderNumber: 0,
                        term: this.conditionsList[0].value,
                        delay: this.delayList[0].value,
                        percent: 0,
                        balance: false,
                        observations: '' });
                    this.verifDataInvoicing(this.recapAmountInput);
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    getPaymentPercentValue(_iRow) {
        return this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].percent;
    }
    onChangePaymentConditions(_field, _value, _iRow) {
        switch (_field) {
            case 'delayScheduler': {
                this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].delay = Number.parseInt(_value, 10);
                this.controlPaymentPercent(-1, 0, false);
                break;
            }
            // termPayment
            case 'termPayment': {
                this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].term = _value;
                this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].orderNumber = (_iRow + 1);
                if (_value === '') {
                    this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].percent = 0;
                }
                this.controlPaymentPercent(-1, 0, false);
                break;
            }
            case 'percentPayment': {
                this.controlPaymentPercent(_iRow, _value, true);
                break;
            }
            default: {
                break;
            }
        }
    }
    controlPaymentPercent(_iRow, _value, _setValue) {
        let valueCur = 0;
        if (_value === '') {
            valueCur = 0;
        }
        else {
            valueCur = Number.parseInt(_value, 10);
        }
        if (valueCur < 0) {
            valueCur = 0;
        }
        if (valueCur > 100) {
            valueCur = 100;
        }
        // this.shedulerPayments[_iRow].percent = valueCur;
        this.paymentPercentOK = false;
        this.totPaymentPercent = 0;
        let beforeValue = 0;
        if (_iRow !== -1) {
            if (this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].percent > 0) {
                beforeValue = this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].percent;
            }
        }
        this.totPaymentPercent = valueCur - beforeValue;
        for (const item of this.recapAmountInput.paymentRecap.specificsConditions.payments) {
            this.totPaymentPercent = this.totPaymentPercent + item.percent;
        }
        if (_setValue) {
            if (this.totPaymentPercent > 100) {
                valueCur = valueCur - (this.totPaymentPercent - 100);
                if (valueCur < 0) {
                    valueCur = 0;
                }
                this.totPaymentPercent = 100;
            }
            beforeValue = this.recapAmountInput.paymentRecap.specificsConditions.payments[_iRow].percent = valueCur;
        }
        if (this.totPaymentPercent !== 100) {
            this.paymentPercentOK = false;
        }
        else {
            this.paymentPercentOK = true;
        }
        this.verifDataInvoicing(this.recapAmountInput);
    }
    counterPayment(_field, _increment, idxRow) {
        switch (_field) {
            case 'numberPayments': {
                const newLine = { orderNumber: 0, term: this.conditionsList[0].value, delay: this.delayList[0].value, percent: 0, balance: false, observations: '' };
                if (this.recapAmountInput.paymentRecap.specificsConditions.numberPayments === -1) {
                    this.recapAmountInput.paymentRecap.specificsConditions.numberPayments = 0;
                }
                this.recapAmountInput.paymentRecap.specificsConditions.numberPayments += _increment;
                if (this.recapAmountInput.paymentRecap.specificsConditions.numberPayments < 1) {
                    this.recapAmountInput.paymentRecap.specificsConditions.numberPayments = 1;
                }
                if (this.recapAmountInput.paymentRecap.specificsConditions.numberPayments > 12) {
                    this.recapAmountInput.paymentRecap.specificsConditions.numberPayments = 12;
                }
                if (_increment === 1 && this.totPaymentPercent !== 100) {
                    newLine.orderNumber = this.recapAmountInput.paymentRecap.specificsConditions.payments.length;
                    this.recapAmountInput.paymentRecap.specificsConditions.payments.push(newLine);
                }
                else if ((this.recapAmountInput.paymentRecap.specificsConditions.payments.length > 1) && (_increment === -1)) {
                    this.recapAmountInput.paymentRecap.specificsConditions.payments.splice(idxRow, 1);
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    onChangeComment(value) {
        this.recapAmountInput.comments = value;
        this.verifDataInvoicing(this.recapAmountInput);
    }
    removeAdvanceRow(idxRow) {
        this.workInvoicingData.advances.splice(idxRow, 1);
    }
    removeCreditnoteRow(idxRow) {
        this.workInvoicingData.creditnotes.splice(idxRow, 1);
        this.itemCreditnote.splice(idxRow, 1);
    }
    sendAdvanceRow(idxRow) {
        this.itemCur.itemData = this.workInvoicingData.advances[idxRow].item;
        this.saveData('sendAdvance');
    }
    sendCreditnoteRow(idxRow) {
        this.itemCur.itemData = this.workInvoicingData.creditnotes[idxRow].item;
        this.itemCur.itemRow = idxRow;
        this.saveData('sendCreditnote');
    }
    addAdvanceRow() {
        if (this.workInvoicingData.advances === undefined) {
            this.workInvoicingData.advances = [];
        }
        const itemNum = this.workInvoicingData.advances.length + 1;
        const advanceDataItem = {
            rowType: 'advance',
            rowLabel: '',
            rowLabelCplt: '',
            rowPercent: undefined,
            labor: 0,
            supplies: 0,
        };
        const advanceItem = {
            id: undefined,
            item: itemNum.toString(),
            invoiceOtherData: advanceDataItem,
            internalRow: false,
            emitOK: false,
            generatedRow: false,
            emitDate: null
        };
        this.workInvoicingData.advances.push(advanceItem);
    }
    addCreditnoteRow() {
        if (this.workInvoicingData.creditnotes === undefined) {
            this.workInvoicingData.creditnotes = [];
            this.itemCreditnote = [];
        }
        const itemNum = this.workInvoicingData.creditnotes.length + 1;
        const creditnoteItem = this.initCreditnoteItem(itemNum);
        this.workInvoicingData.creditnotes.push(creditnoteItem);
        const itemaction = {
            isOkToAction: false
        };
        this.itemCreditnote.push(itemaction);
    }
    getDateEmit(_value) {
        return GridTools.date_unix(_value, this._translate.getLocalFmt('timeFormat'));
    }
    onChangeInput(_field, _value, _iRow) {
        switch (_field) {
            // termPayment
            case 'termPayment': {
                this.workInvoicingData.advances[_iRow].invoiceOtherData.rowLabel = _value;
                this.verifDataAdvances();
                break;
            }
            case 'percentPayment': {
                if (this.statutCall === 'advances') {
                    this.controlAdvancePercent(_iRow, _value);
                    this.verifDataAdvances();
                }
                if (this.statutCall === 'creditnotes') {
                    this.calculationgCreditnotePercent(_iRow, _value);
                    this.totalisationCreditnoteTax(this.workInvoicingData.creditnotes[_iRow]);
                    this.verifDataCreditnotes(_iRow);
                }
                break;
            }
            case 'rowLabel': {
                if (this.statutCall === 'advances') {
                    this.workInvoicingData.advances[_iRow].invoiceOtherData.rowLabel = _value;
                    this.verifDataAdvances();
                }
                if (this.statutCall === 'creditnotes') {
                    this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowLabel = _value;
                    this.verifDataCreditnotes(_iRow);
                }
                break;
            }
            case 'rowLabelCplt': {
                if (this.statutCall === 'advances') {
                    this.workInvoicingData.advances[_iRow].invoiceOtherData.rowLabelCplt = _value;
                    this.verifDataAdvances();
                }
                if (this.statutCall === 'creditnotes') {
                    this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowLabelCplt = _value;
                    this.verifDataCreditnotes(_iRow);
                }
                break;
            }
            case 'supplies':
                let numberValueSupplies = parseFloat(_value);
                numberValueSupplies = Math.round(numberValueSupplies * 100) / 100;
                if (this.statutCall === 'creditnotes') {
                    this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowPercent = 0;
                    if (isNaN(numberValueSupplies)) {
                        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.supplies = 0;
                    }
                    else {
                        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.supplies = numberValueSupplies;
                    }
                    this.totalisationCreditnoteTax(this.workInvoicingData.creditnotes[_iRow]);
                    this.verifDataCreditnotes(_iRow);
                }
                break;
            case 'labor':
                let numberValueLabor = parseFloat(_value);
                numberValueLabor = Math.round(numberValueLabor * 100) / 100;
                if (this.statutCall === 'creditnotes') {
                    this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowPercent = 0;
                    if (isNaN(numberValueLabor)) {
                        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.labor = 0;
                    }
                    else {
                        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.labor = numberValueLabor;
                    }
                    this.totalisationCreditnoteTax(this.workInvoicingData.creditnotes[_iRow]);
                    this.verifDataCreditnotes(_iRow);
                }
                break;
            default: {
                break;
            }
        }
    }
    controlAdvancePercent(_iRow, _value) {
        let valueCur = 0;
        if (_value === '') {
            valueCur = 0;
        }
        else {
            valueCur = Number.parseInt(_value, 10);
        }
        if (valueCur < 0) {
            valueCur = 0;
        }
        if (valueCur > 100) {
            valueCur = 100;
        }
        this.workInvoicingData.advances[_iRow].invoiceOtherData.rowPercent = _value;
        const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
        let amountCur = (workQuotation.workTotalWT.labor * _value) / 100;
        amountCur = Math.round(amountCur * 100) / 100;
        this.workInvoicingData.advances[_iRow].invoiceOtherData.labor = amountCur;
        amountCur = (workQuotation.workTotalWT.supplies * _value) / 100;
        amountCur = Math.round(amountCur * 100) / 100;
        this.workInvoicingData.advances[_iRow].invoiceOtherData.supplies = amountCur;
    }
    calculationgCreditnotePercent(_iRow, _value) {
        let valueCur = 0;
        if (_value === '') {
            valueCur = 0;
        }
        else {
            valueCur = Number.parseInt(_value, 10);
        }
        if (valueCur < 0) {
            valueCur = 0;
        }
        if (valueCur > 100) {
            valueCur = 100;
        }
        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowPercent = _value;
        const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
        let amountCur = (workQuotation.workTotalWT.labor * _value) / 100;
        amountCur = Math.round(amountCur * 100) / 100;
        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.labor = amountCur;
        amountCur = (workQuotation.workTotalWT.supplies * _value) / 100;
        amountCur = Math.round(amountCur * 100) / 100;
        this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.supplies = amountCur;
    }
    initConditionsFromQuotation(wksWorksRecapAmount) {
        if (this.workCur.workJsonQuotation === undefined) {
            const ownerCur = this._wksWorksService.getOwner();
            const accountTmp = JSON.parse(ownerCur.bsnAccounting);
            if (accountTmp.bsnDefferedPaiement === undefined || accountTmp.bsnDefferedPaiement === '') {
                accountTmp.bsnDefferedPaiement = 'invoiceEmit';
            }
            wksWorksRecapAmount.paymentRecap.customerAccount = accountTmp;
            this.isCustomerCondition = true;
            return;
        }
        const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
        if (wksWorksRecapAmount.equipTotalBT.labor + wksWorksRecapAmount.equipTotalBT.supplies ===
            workQuotation.equipTotalBT.labor + workQuotation.equipTotalBT.supplies) {
            wksWorksRecapAmount.equipRecapAmount = JSON.parse(JSON.stringify(workQuotation.equipRecapAmount));
            wksWorksRecapAmount.financialWorkRows = JSON.parse(JSON.stringify(workQuotation.financialWorkRows));
            wksWorksRecapAmount.otherWorkRows = JSON.parse(JSON.stringify(workQuotation.otherWorkRows));
            wksWorksRecapAmount.financialWorkTotalBT = JSON.parse(JSON.stringify(workQuotation.financialWorkTotalBT));
            wksWorksRecapAmount.otherWorkTotalBT = JSON.parse(JSON.stringify(workQuotation.otherWorkTotalBT));
            wksWorksRecapAmount.workTax = JSON.parse(JSON.stringify(workQuotation.workTax));
            wksWorksRecapAmount.workTotalTax = JSON.parse(JSON.stringify(workQuotation.workTotalTax));
            wksWorksRecapAmount.workTotalWT = JSON.parse(JSON.stringify(workQuotation.workTotalWT));
            wksWorksRecapAmount.paymentRecap = JSON.parse(JSON.stringify(workQuotation.paymentRecap));
            this.initAdvances(wksWorksRecapAmount);
            return;
        }
        else {
            wksWorksRecapAmount.otherWorkRows = JSON.parse(JSON.stringify(workQuotation.otherWorkRows));
            wksWorksRecapAmount.otherWorkTotalBT = JSON.parse(JSON.stringify(workQuotation.otherWorkTotalBT));
            wksWorksRecapAmount.financialWorkRows = [];
            for (const financialRowCur of workQuotation.financialWorkRows) {
                if (financialRowCur.rowType !== 'rounding') {
                    wksWorksRecapAmount.financialWorkRows.push(financialRowCur);
                }
            }
            this.newPercentCalculation();
            wksWorksRecapAmount.paymentRecap = JSON.parse(JSON.stringify(workQuotation.paymentRecap));
            this.initAdvances(wksWorksRecapAmount);
            this.recapAmountInput = this.totalisationWork(true, true);
        }
    }
    initPaymentFromQuotation(wksWorksRecapAmount) {
        wksWorksRecapAmount.paymentRecap = {
            workShopEditor: '',
            delayValidity: -1,
            customerConditions: true,
            customerAccount: undefined,
            specificsConditions: {
                numberPayments: -1,
                payments: [],
                cashpay: false,
            },
            customerFinancials: true,
            specificsFinancials: {
                scaleName: 'PUBLIC',
                bsnCoef: 1,
            }
        };
        this.isCustomerCondition = false;
        if (this.workCur.workJsonQuotation === undefined || this.workCur.workJsonQuotation === null) {
            const ownerCur = this._wksWorksService.getOwner();
            const accountTmp = JSON.parse(ownerCur.bsnAccounting);
            if (accountTmp.bsnDefferedPaiement === undefined || accountTmp.bsnDefferedPaiement === '') {
                accountTmp.bsnDefferedPaiement = 'invoiceEmit';
            }
            wksWorksRecapAmount.paymentRecap.customerAccount = accountTmp;
            this.isCustomerCondition = true;
            return;
        }
        const workQuotation = JSON.parse(this.workCur.workJsonQuotation);
        wksWorksRecapAmount.paymentRecap = JSON.parse(JSON.stringify(workQuotation.paymentRecap));
        this.initAdvances(workQuotation);
        wksWorksRecapAmount.paymentRecap.workShopEditor = this._userService.getUserLogged().username;
    }
    initAdvances(wksWorksRecapAmount) {
        if (this.statutCall === 'advances') {
            this.itemCur = {
                itemType: this.statutCall,
                itemData: '',
                itemRow: -1
            };
        }
        const paymentRecap = wksWorksRecapAmount.paymentRecap;
        this.displayAdvancesOK = false;
        if (this.workInvoicingData.advances !== undefined && this.workInvoicingData.advances.length > 0) { // already initialized
            this.displayAdvancesOK = true;
            return;
        }
        if (paymentRecap.specificsConditions === undefined || paymentRecap.specificsConditions.payments === undefined) {
            return;
        }
        if (paymentRecap.specificsConditions.payments.length <= 1) {
            return;
        }
        let itemNum = 0;
        this.workInvoicingData.advances = [];
        for (const paymentCur of paymentRecap.specificsConditions.payments) {
            itemNum++;
            const advanceDataItem = {
                rowType: 'advance',
                rowLabel: paymentCur.term,
                rowLabelCplt: '',
                rowPercent: paymentCur.percent,
                labor: wksWorksRecapAmount.workTotalWT.labor * paymentCur.percent / 100,
                supplies: wksWorksRecapAmount.workTotalWT.supplies * paymentCur.percent / 100,
            };
            const advanceItem = {
                id: undefined,
                item: itemNum.toString(),
                invoiceOtherData: advanceDataItem,
                internalRow: false,
                emitOK: false,
                generatedRow: true,
                emitDate: null
            };
            this.workInvoicingData.advances.push(advanceItem);
            if (itemNum === paymentRecap.specificsConditions.payments.length - 1) {
                break;
            }
        }
        this.workInvoicingDataCache = this.workInvoicingData;
        this.displayAdvancesOK = true;
    }
    initCreditnotes() {
        if (this.statutCall === 'creditnotes') {
            this.itemCur = {
                itemType: this.statutCall,
                itemData: '',
                itemRow: -1
            };
        }
        this.displayCreditnotesOK = false;
        if (this.workInvoicingData.creditnotes === undefined || this.workInvoicingData.creditnotes.length === 0) { // already initialized
            this.workInvoicingData.creditnotes = [];
            this.addCreditnoteRow();
        }
        this.displayCreditnotesOK = true;
    }
    initPaymentFromRecap(wksWorksRecapAmount) {
        this.isCustomerCondition = false;
        this.totPaymentPercent = 0;
        this.paymentPercentOK = false;
        this.recapAmountInput.paymentRecap.workShopEditor = this._userService.getUserLogged().username;
        wksWorksRecapAmount.paymentRecap = this.recapAmountInput.paymentRecap;
        if (this.recapAmountInput.paymentRecap.customerConditions) {
            this.isCustomerCondition = true;
        }
        if (this.recapAmountInput.paymentRecap.customerConditions !== undefined && !this.recapAmountInput.paymentRecap.customerConditions) {
            for (const paymentItem of this.recapAmountInput.paymentRecap.specificsConditions.payments) {
                this.totPaymentPercent += paymentItem.percent;
            }
            if (this.totPaymentPercent === 100) {
                this.paymentPercentOK = true;
            }
        }
        else {
            this.paymentPercentOK = true;
        }
    }
    translateLabel(_key) {
        return this._translate.getTranslate(_key);
    }
    initRecapEquip() {
        if (this.onlyMainEquip) { // only main equipment
            this.totalisationMainEquipment();
        }
        else {
            this.totalisationSubEquipments();
        }
        for (const equipRecapAmountCur of this.workEquipRecapAmount) {
            this.totalisationEquip(equipRecapAmountCur);
        }
    }
    totalisationRecap(doValidData) {
        if (this.onlyMainEquip) { // only main equipment
            this.totalisationMainEquipment();
        }
        else {
            this.totalisationSubEquipments();
        }
        for (const equipRecapAmountCur of this.workEquipRecapAmount) {
            this.totalisationEquip(equipRecapAmountCur);
        }
        this.recapAmountInput = this.totalisationWork(true, doValidData);
        this.displayRecapAmountOK = true;
    }
    totalisationMainEquipment() {
        const workEquipRecapAmountCache = this.initEquipRecapAmount();
        const workEquipRecapAmount = JSON.parse(JSON.stringify(workEquipRecapAmountCache));
        workEquipRecapAmount.equipId = this.workEquipmentId.mainEquipment;
        workEquipRecapAmount.equipDesign = this.workEquipments.mainEquipment.equipDesign;
        const taskActionRecapAmount = this.totalisationAction(workEquipRecapAmount.equipId);
        workEquipRecapAmount.tasksRecapAmount.push(taskActionRecapAmount);
        this.workEquipRecapAmount[0] = workEquipRecapAmount;
    }
    totalisationSubEquipments() {
        const workEquipRecapAmountCache = this.initEquipRecapAmount();
        for (const equipCur of this.workEquipments.subEquipments) {
            const workEquipRecapAmount = JSON.parse(JSON.stringify(workEquipRecapAmountCache));
            workEquipRecapAmount.equipId = equipCur.id;
            workEquipRecapAmount.equipDesign = equipCur.equipDesign;
            workEquipRecapAmount.equipSN = equipCur.equipSN;
            const taskActionRecapAmount = this.totalisationAction(workEquipRecapAmount.equipId);
            workEquipRecapAmount.tasksRecapAmount.push(...taskActionRecapAmount);
            this.workEquipRecapAmount.push(workEquipRecapAmount);
        }
    }
    totalisationAction(equipId) {
        const taskActionRecapAmount = [];
        // const taskActionRecapAmountTmp = this.initActionRecapAmount();
        for (const taskTmp of this.tasksActionsCompleted) {
            // let isFound = false;
            if (taskTmp.equipId === equipId) {
                const taskActionRecapAmountTmp = this.initActionRecapAmount();
                taskActionRecapAmountTmp.taskrefId = taskTmp.taskrefId;
                taskActionRecapAmountTmp.taskrefLabel = taskTmp.taskrefLabel;
                for (const suppliesTmp of taskTmp.supplies) {
                    if (this.statutCall === 'invoicing') {
                        if (suppliesTmp.actionTaken === 'changed') {
                            taskActionRecapAmountTmp.suppliesTotalBT += suppliesTmp.extPrice;
                        }
                    }
                    else {
                        taskActionRecapAmountTmp.suppliesTotalBT += suppliesTmp.extPrice;
                    }
                }
                for (const workTmp of taskTmp.works) {
                    taskActionRecapAmountTmp.laborTotalBT += workTmp.extPrice;
                }
                taskActionRecapAmount.push(taskActionRecapAmountTmp);
            }
            /*
            if (isFound) {
              break;
            }
            */
        }
        return taskActionRecapAmount;
    }
    totalisationEquip(workEquipRecapAmountItem) {
        workEquipRecapAmountItem.laborTotalBT = 0;
        workEquipRecapAmountItem.suppliesTotalBT = 0;
        workEquipRecapAmountItem.otherTotalBT = 0;
        for (const taskActionItem of workEquipRecapAmountItem.tasksRecapAmount) {
            workEquipRecapAmountItem.laborTotalBT += taskActionItem.laborTotalBT;
            workEquipRecapAmountItem.suppliesTotalBT += taskActionItem.suppliesTotalBT;
            workEquipRecapAmountItem.otherTotalBT += taskActionItem.otherTotalBT;
        }
        for (const otherItem of workEquipRecapAmountItem.otherEquip) {
            workEquipRecapAmountItem.otherEquipTotalBT += otherItem.extPrice;
        }
    }
    totalisationWork(inputRounding, doVerifData) {
        const wksWorksRecapAmount = this.initWorkRecapAmount();
        this.totalisationBTEquip(wksWorksRecapAmount);
        this.totalisationBTOther(wksWorksRecapAmount);
        this.totalisationBTFinancial(wksWorksRecapAmount);
        if (inputRounding) {
            this.totalisationBTFinancialRounding(wksWorksRecapAmount);
        }
        this.totalisationTax(wksWorksRecapAmount);
        if (doVerifData) {
            this.verifDataInvoicing(wksWorksRecapAmount);
        }
        return wksWorksRecapAmount;
    }
    totalisationBTEquip(wksWorksRecapAmountArg) {
        for (const equipRecapAmountCur of this.workEquipRecapAmount) {
            wksWorksRecapAmountArg.equipRecapAmount.push(equipRecapAmountCur);
            wksWorksRecapAmountArg.equipTotalBT.labor += equipRecapAmountCur.laborTotalBT;
            wksWorksRecapAmountArg.equipTotalBT.supplies += equipRecapAmountCur.suppliesTotalBT;
            wksWorksRecapAmountArg.workTotalBT.labor += equipRecapAmountCur.laborTotalBT;
            wksWorksRecapAmountArg.workTotalBT.supplies += equipRecapAmountCur.suppliesTotalBT;
            wksWorksRecapAmountArg.workTotalWT.labor += equipRecapAmountCur.laborTotalBT;
            wksWorksRecapAmountArg.workTotalWT.supplies += equipRecapAmountCur.suppliesTotalBT;
        }
    }
    totalisationBTOther(wksWorksRecapAmountArg) {
        if (this.recapAmountInput !== undefined && this.recapAmountInput.otherWorkRows !== undefined) {
            wksWorksRecapAmountArg.otherWorkRows = this.recapAmountInput.otherWorkRows;
        }
        else {
            return;
        }
        if (wksWorksRecapAmountArg.otherWorkRows !== undefined && wksWorksRecapAmountArg.otherWorkRows.length > 0) {
            for (const otherWorkItem of wksWorksRecapAmountArg.otherWorkRows) {
                wksWorksRecapAmountArg.otherWorkTotalBT.labor += otherWorkItem.labor;
                wksWorksRecapAmountArg.otherWorkTotalBT.supplies += otherWorkItem.supplies;
                wksWorksRecapAmountArg.workTotalBT.labor += otherWorkItem.labor;
                wksWorksRecapAmountArg.workTotalBT.supplies += otherWorkItem.supplies;
                wksWorksRecapAmountArg.workTotalWT.labor += otherWorkItem.labor;
                wksWorksRecapAmountArg.workTotalWT.supplies += otherWorkItem.supplies;
            }
        }
    }
    totalisationBTFinancial(wksWorksRecapAmountArg) {
        if (this.recapAmountInput !== undefined &&
            this.recapAmountInput.financialWorkRows !== undefined &&
            this.recapAmountInput.financialWorkRows.length > 0) {
            wksWorksRecapAmountArg.financialWorkRows = this.recapAmountInput.financialWorkRows;
            for (const financialItem of wksWorksRecapAmountArg.financialWorkRows) {
                if (financialItem.rowType === 'rounding') {
                    continue;
                }
                if (financialItem.rowBaseWork === 'supplies&parts') {
                    wksWorksRecapAmountArg.financialWorkTotalBT.supplies += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalBT.supplies += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalWT.supplies += financialItem.rowAmount;
                }
                else {
                    wksWorksRecapAmountArg.financialWorkTotalBT.labor += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalBT.labor += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalWT.labor += financialItem.rowAmount;
                }
            }
        }
    }
    totalisationBTFinancialRounding(wksWorksRecapAmountArg) {
        if (this.recapAmountInput !== undefined &&
            this.recapAmountInput.financialWorkRows !== undefined &&
            this.recapAmountInput.financialWorkRows.length > 0) {
            wksWorksRecapAmountArg.financialWorkRows = this.recapAmountInput.financialWorkRows;
            for (const financialItem of wksWorksRecapAmountArg.financialWorkRows) {
                if (financialItem.rowType !== 'rounding') {
                    continue;
                }
                if (financialItem.rowBaseWork === 'supplies&parts') {
                    wksWorksRecapAmountArg.financialWorkTotalBT.supplies += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalBT.supplies += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalWT.supplies += financialItem.rowAmount;
                }
                else {
                    wksWorksRecapAmountArg.financialWorkTotalBT.labor += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalBT.labor += financialItem.rowAmount;
                    wksWorksRecapAmountArg.workTotalWT.labor += financialItem.rowAmount;
                }
            }
        }
    }
    totalisationTax(wksWorksRecapAmount) {
        let fraction = 100;
        switch (this.numberFormat.maxDecimal) {
            case '3':
                fraction = 1000;
                break;
            default:
                fraction = 100;
                break;
        }
        for (const taxRow of this.profileTax.taxRows) {
            if (taxRow.taxBase === 'supplies' || taxRow.taxBase === 'all') {
                let amountTax = (wksWorksRecapAmount.workTotalBT.supplies * taxRow.taxPercent) / 100;
                amountTax = Math.round(amountTax * fraction) / fraction;
                const tmpTax = {
                    taxName: taxRow.taxName,
                    taxBaseWork: 'supplies&parts',
                    taxLabel: '',
                    taxPercent: taxRow.taxPercent,
                    supplies: amountTax,
                    labor: 0
                };
                wksWorksRecapAmount.workTax.push(tmpTax);
            }
            if (taxRow.taxBase === 'labor' || taxRow.taxBase === 'all') {
                let amountTax = (wksWorksRecapAmount.workTotalBT.labor * taxRow.taxPercent) / 100;
                amountTax = Math.round(amountTax * fraction) / fraction;
                const tmpTax = {
                    taxName: taxRow.taxName,
                    taxBaseWork: 'labor',
                    taxLabel: '',
                    taxPercent: taxRow.taxPercent,
                    supplies: 0,
                    labor: amountTax
                };
                wksWorksRecapAmount.workTax.push(tmpTax);
            }
        }
        for (const taxRow of wksWorksRecapAmount.workTax) {
            wksWorksRecapAmount.workTotalTax.labor += taxRow.labor;
            wksWorksRecapAmount.workTotalTax.supplies += taxRow.supplies;
            wksWorksRecapAmount.workTotalWT.labor += taxRow.labor;
            wksWorksRecapAmount.workTotalWT.supplies += taxRow.supplies;
        }
    }
    initActionRecapAmount() {
        const taskActionRecapAmount = {
            taskrefId: undefined,
            taskrefLabel: undefined,
            suppliesTotalBT: 0,
            laborTotalBT: 0,
            otherTotalBT: 0,
            isCollapsed: true,
        };
        return taskActionRecapAmount;
    }
    initEquipRecapAmount() {
        const workEquipRecapAmount = {
            equipId: undefined,
            equipDesign: undefined,
            equipSN: undefined,
            tasksRecapAmount: [],
            otherEquip: [],
            suppliesTotalBT: 0,
            laborTotalBT: 0,
            otherTotalBT: 0,
            otherEquipTotalBT: 0,
            isCollapsed: true
        };
        return workEquipRecapAmount;
    }
    initWorkInvoicingData() {
        const invoicingData = {
            invoices: [],
            advances: [],
            creditnotes: []
        };
        return invoicingData;
    }
    initCreditnoteItem(itemNum) {
        const creditnoteItem = {
            rowLabel: '',
            rowLabelCplt: '',
            rowPercent: undefined,
            labor: 0,
            supplies: 0,
        };
        const creditnoteWork = {
            id: undefined,
            item: itemNum.toString(),
            emitOK: false,
            emitDate: null,
            creditnoteWorkRow: creditnoteItem,
            creditnoteWorkTax: [],
            creditnoteWorkTotalTax: {
                rowLabel: 'totalCreditnoteTax',
                supplies: 0,
                labor: 0,
            },
            creditnoteWorkTotalWT: {
                rowLabel: 'totalCreditnoteWT',
                supplies: 0,
                labor: 0,
            },
        };
        this.totalisationCreditnoteTax(creditnoteWork);
        return creditnoteWork;
    }
    totalisationCreditnoteTax(creditnoteWork) {
        creditnoteWork.creditnoteWorkTax = [];
        let fraction = 100;
        switch (this.numberFormat.maxDecimal) {
            case '3':
                fraction = 1000;
                break;
            default:
                fraction = 100;
                break;
        }
        for (const taxRow of this.profileTax.taxRows) {
            if (taxRow.taxBase === 'supplies' || taxRow.taxBase === 'all') {
                let amountTax = (creditnoteWork.creditnoteWorkRow.supplies * taxRow.taxPercent) / 100;
                amountTax = Math.round(amountTax * fraction) / fraction;
                const tmpTax = {
                    taxName: taxRow.taxName,
                    taxBaseWork: 'supplies&parts',
                    taxLabel: '',
                    taxPercent: taxRow.taxPercent,
                    supplies: amountTax,
                    labor: 0
                };
                creditnoteWork.creditnoteWorkTax.push(tmpTax);
            }
            if (taxRow.taxBase === 'labor' || taxRow.taxBase === 'all') {
                let amountTax = (creditnoteWork.creditnoteWorkRow.labor * taxRow.taxPercent) / 100;
                amountTax = Math.round(amountTax * fraction) / fraction;
                const tmpTax = {
                    taxName: taxRow.taxName,
                    taxBaseWork: 'labor',
                    taxLabel: '',
                    taxPercent: taxRow.taxPercent,
                    supplies: 0,
                    labor: amountTax
                };
                creditnoteWork.creditnoteWorkTax.push(tmpTax);
            }
        }
        creditnoteWork.creditnoteWorkTotalWT.labor = creditnoteWork.creditnoteWorkRow.labor;
        creditnoteWork.creditnoteWorkTotalWT.supplies = creditnoteWork.creditnoteWorkRow.supplies;
        for (const taxRow of creditnoteWork.creditnoteWorkTax) {
            creditnoteWork.creditnoteWorkTotalTax.labor += taxRow.labor;
            creditnoteWork.creditnoteWorkTotalTax.supplies += taxRow.supplies;
            creditnoteWork.creditnoteWorkTotalWT.labor += taxRow.labor;
            creditnoteWork.creditnoteWorkTotalWT.supplies += taxRow.supplies;
        }
    }
    initWorkRecapAmount() {
        const wksWorksRecapAmount = {
            equipRecapAmount: [],
            equipTotalBT: {
                rowLabel: 'totalEquipBT',
                supplies: 0,
                labor: 0,
            },
            otherWorkRows: [],
            otherWorkTotalBT: {
                rowLabel: 'totalOtherBT',
                supplies: 0,
                labor: 0,
            },
            financialWorkRows: [],
            financialWorkTotalBT: {
                rowLabel: 'totalFinancialBT',
                supplies: 0,
                labor: 0,
            },
            workTotalBT: {
                rowLabel: 'totalGenBT',
                supplies: 0,
                labor: 0,
            },
            advanceWorkRows: [],
            advanceWorkTotalBT: {
                rowLabel: 'advanceWorkTotalBT',
                supplies: 0,
                labor: 0,
            },
            creditnoteWorkRows: [],
            creditnoteWorkTotalBT: {
                rowLabel: 'creditnoteWorkTotalBT',
                supplies: 0,
                labor: 0,
            },
            workTax: [],
            workTotalTax: {
                rowLabel: 'totalGenTax',
                supplies: 0,
                labor: 0,
            },
            workTotalWT: {
                rowLabel: 'totalGenWT',
                supplies: 0,
                labor: 0,
            },
            paymentRecap: {
                workShopEditor: '',
                delayValidity: -1,
                customerConditions: true,
                customerAccount: undefined,
                specificsConditions: {
                    numberPayments: -1,
                    payments: [],
                    cashpay: false,
                },
                customerFinancials: true,
                specificsFinancials: {
                    scaleName: 'PUBLIC',
                    bsnCoef: 1,
                }
            },
            comments: '',
            isCollapsed: true,
            isTaxCollapsed: true,
            isOtherCollapsed: true,
            isFinancialCollapse: true,
        };
        if (this.recapAmountInput !== undefined) {
            wksWorksRecapAmount.isCollapsed = this.recapAmountInput.isCollapsed !== undefined ? this.recapAmountInput.isCollapsed : true;
            wksWorksRecapAmount.isTaxCollapsed = this.recapAmountInput.isTaxCollapsed !== undefined ? this.recapAmountInput.isTaxCollapsed : true;
            wksWorksRecapAmount.isOtherCollapsed = this.recapAmountInput.isOtherCollapsed !== undefined ? this.recapAmountInput.isOtherCollapsed : true;
            wksWorksRecapAmount.isFinancialCollapse = this.recapAmountInput.isFinancialCollapse !== undefined ? this.recapAmountInput.isFinancialCollapse : true;
        }
        if (this.recapAmountInput !== undefined && this.recapAmountInput.paymentRecap !== undefined) {
            this.initPaymentFromRecap(wksWorksRecapAmount);
        }
        else {
            this.initPaymentFromQuotation(wksWorksRecapAmount);
        }
        return wksWorksRecapAmount;
    }
    verifDataInvoicing(wksWorksRecapAmount) {
        if (this.recapAmountCache === undefined) {
            this.recapAmountCache = JSON.parse(JSON.stringify(wksWorksRecapAmount));
            this.isOkToSave = true;
            this.isOkToAction = true;
        }
        else {
            if (!this.verifPayments(wksWorksRecapAmount)) {
                return;
            }
            if (JSON.parse(JSON.stringify(wksWorksRecapAmount)) !== JSON.parse(JSON.stringify(this.recapAmountCache))) {
                this.recapAmountCache = JSON.parse(JSON.stringify(wksWorksRecapAmount));
                this.isOkToSave = true;
                this.isOkToAction = true;
            }
        }
    }
    verifPayments(wksWorksRecapAmount) {
        if (wksWorksRecapAmount.paymentRecap.customerConditions) {
            return true;
        }
        else {
            if (!this.paymentPercentOK) {
                this.isOkToSave = false;
                this.isOkToAction = false;
                return false;
            }
        }
        return true;
    }
    verifDataAdvances() {
        if (this.workInvoicingDataCache.advances === undefined) {
            this.workInvoicingDataCache.advances = JSON.parse(JSON.stringify(this.workInvoicingData.advances));
            this.isOkToSave = true;
            this.isOkToAction = true;
        }
        else {
            if (JSON.parse(JSON.stringify(this.workInvoicingData.advances)) !== JSON.parse(JSON.stringify(this.workInvoicingDataCache.advances))) {
                this.workInvoicingDataCache.advances = JSON.parse(JSON.stringify(this.workInvoicingData.advances));
                this.isOkToSave = true;
                this.isOkToAction = true;
            }
        }
    }
    verifDataCreditnotes(_iRow) {
        if (this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.rowLabel === '') {
            return;
        }
        if (this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.labor +
            this.workInvoicingData.creditnotes[_iRow].creditnoteWorkRow.supplies === 0) {
            return;
        }
        if (this.workInvoicingDataCache.creditnotes === undefined) {
            this.workInvoicingDataCache.creditnotes = JSON.parse(JSON.stringify(this.workInvoicingData.creditnotes));
            this.isOkToSave = true;
            this.isOkToAction = true;
        }
        else {
            if (JSON.parse(JSON.stringify(this.workInvoicingData.creditnotes)) !== JSON.parse(JSON.stringify(this.workInvoicingDataCache.creditnotes))) {
                this.workInvoicingDataCache.creditnotes = JSON.parse(JSON.stringify(this.workInvoicingData.creditnotes));
                this.isOkToSave = true;
                this.isOkToAction = true;
            }
        }
    }
    saveData(option) {
        if (this.itemCur.itemType === 'invoicing') {
            if (this.workInvoicingData.invoices.length === 0) {
                const invoiceItemCur = {
                    item: this.itemCur.itemData,
                    recapAmount: this.recapAmountInput
                };
                this.workInvoicingData.invoices.push(invoiceItemCur);
            }
            else {
                let isFound = false;
                for (const invoiceTmp of this.workInvoicingData.invoices) {
                    if (invoiceTmp.item === this.itemCur.itemData) {
                        invoiceTmp.recapAmount = this.recapAmountInput;
                        isFound = true;
                        break;
                    }
                }
                if (!isFound) {
                    const invoiceItemCur = {
                        item: this.itemCur.itemData,
                        recapAmount: this.recapAmountInput
                    };
                    this.workInvoicingData.invoices.push(invoiceItemCur);
                }
            }
            const event = {
                action: option,
                typeEvent: this.statutCall,
                data: this.workInvoicingData,
                itemRow: -1
            };
            this.dataOut.emit(event);
            this.isOkToSave = false;
        }
        else if (this.itemCur.itemType === 'advances') {
            const event = {
                action: (option === 'sendAdvance' ? option + ';' + this.itemCur.itemData : option),
                typeEvent: this.statutCall,
                data: this.workInvoicingData,
                itemRow: -1
            };
            this.dataOut.emit(event);
            this.isOkToSave = false;
        }
        else if (this.itemCur.itemType === 'creditnotes') {
            const event = {
                action: option,
                typeEvent: this.statutCall,
                data: this.workInvoicingData,
                itemRow: this.itemCur.itemRow
            };
            this.dataOut.emit(event);
            this.isOkToSave = false;
        }
        else if (this.itemCur.itemType === 'quotation') {
            const event = {
                action: option,
                typeEvent: this.statutCall,
                data: this.recapAmountInput,
                itemRow: -1
            };
            this.dataOut.emit(event);
            this.isOkToSave = false;
        }
    }
}
export { ɵ0 };
