<div class="container">
    <select #select (ngModelChange)="onSelectChange($event)" [ngModel]="text">
      <option value=""></option>
      <!--<option value="AAAA">AAAA</option>
      <option value="BBBB">BBBB</option>-->
      <option *ngFor="let optCur of listOptions" [value]="optCur">{{optCur}}</option>
    </select>
    <!--
    <br>Partial Match Filter: <input #input (ngModelChange)="onChange($event)" [ngModel]="text" class="form-control">
    -->
</div>
