import { Injectable } from '@angular/core';
import { throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { SpecificationsModel, SpecjobsModel } from '../models/wks-manufacturers.model';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { ParamWorksRatesModel } from '../models/wks-param.model';
import { WksCentralService } from './wks-central.service';

@Injectable({
  providedIn: 'root'
})
export class WksManufacturersService {

  httpHeaders: HttpHeaders;
  httpParams: HttpParams;

  constructor(public  httpClient: HttpClient,
              public _wksCentralService: WksCentralService
          ) { }

  setHeaders(_headers: HttpHeaders) {
    this.httpHeaders = _headers;
  }
  public saveSpecifications(specificationCard: SpecificationsModel, actionType: String): Observable<HttpResponse<SpecificationsModel>> {

    const urlCur = `${environment.baseUrl}/wks/manufacturers/saveSpecifications/?` + 'action=' + actionType;

    const body = JSON.stringify(specificationCard);

    return this.httpClient
      .put<SpecificationsModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public processSpecifications(specificationCard: SpecificationsModel, actionType: String, historyRow: number): Observable<HttpResponse<SpecificationsModel>> {

    const urlCur = `${environment.baseUrl}/wks/manufacturers/processSpecifications/?` + 'action=' + actionType + '&historyRow=' + historyRow;

    const body = JSON.stringify(specificationCard);

    return this.httpClient
      .post<SpecificationsModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public findAllSpecifications(): Observable<HttpResponse<SpecificationsModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/manufacturers/findAllSpecifications/`;
   
    return this.httpClient.post<SpecificationsModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public findSpecificationsByFilename(stdEntity: string, specBrand: string, specFileType: string, specFileName: string): Observable<HttpResponse<SpecificationsModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecificationsByFilename/?` + 'stdEntity=' + stdEntity  
                                            + '&specBrand=' + specBrand + '&specFileType=' + specFileType + '&specFileName=' + specFileName;
   
    return this.httpClient.post<SpecificationsModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public findSpecificationsBySpecBrand(stdEntity: string, specBrand: string, specFileType: string, specFileName: string): Observable<HttpResponse<SpecificationsModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecificationsBySpecBrand/?` + 'stdEntity=' + stdEntity  
                          + '&specBrand=' + specBrand + '&specFileType=' + specFileType + '&specFileName=' + specFileName;
   
    return this.httpClient.post<SpecificationsModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public saveSpecjobs(specjobsCard: SpecjobsModel, actionType: String): Observable<HttpResponse<SpecjobsModel>> {

    const urlCur = `${environment.baseUrl}/wks/manufacturers/saveSpecjobs/?` + 'action=' + actionType;

    const body = JSON.stringify(specjobsCard);

    return this.httpClient
      .put<SpecjobsModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }

  public findAllSpecjobs(): Observable<HttpResponse<SpecjobsModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/manufacturers/findAllSpecjobs/`;
   
    return this.httpClient.post<SpecjobsModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  public findSpecJobsByFileid(stdEntity: string, specFileId: string, jobNumber: string): Observable<HttpResponse<SpecjobsModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/manufacturers/findSpecJobsByFileid/?` + 'stdEntity=' + stdEntity  
                                            + '&specFileId=' + specFileId + '&jobNumber=' + jobNumber ;
   
    return this.httpClient.post<SpecjobsModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  downloadDoc(_path: string, _mimeApp: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    const urlCur = `${environment.baseUrl}/wks/manufacturers/download/?` + 'path=' + _path + '&type=' + _mimeApp;
 
    const localHttpHeaders = this.httpHeaders;
    localHttpHeaders.set('Accept', _mimeApp);
    
    return this.httpClient.get<any> ( urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
  public saveToFileSystem(_data: any, _filename: string, _mimeApp: string) {

    const blob = new Blob([_data], { type: _mimeApp });
    saveAs(blob, _filename);
  }

  loadWorksType(entity: string, userlang: string) {

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(entity, userlang)
     .subscribe(
       data => {
          const dataReturn = data.body as ParamWorksRatesModel[] ;
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
}
