/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./work-id-renderer.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./work-id-renderer.component";
import * as i10 from "../../../../../../../../services/translate.service";
import * as i11 from "../../../../../../services/wks-central.service";
import * as i12 from "../../../../../../../common/services/global-events-manager.service";
var styles_WorkIdRendererComponent = [i0.styles];
var RenderType_WorkIdRendererComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_WorkIdRendererComponent, data: {} });
export { RenderType_WorkIdRendererComponent as RenderType_WorkIdRendererComponent };
export function View_WorkIdRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "button", [["class", "btn btn-default mat-icon-button "], ["matTooltipClass", "red-tooltip"], ["style", "text-align: center!important;"], ["type", "button"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.copyWorkIdToClipBoard() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.workId, ""); var currVal_1 = "red-tooltip"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.currentValue; _ck(_v, 3, 0, currVal_2); }); }
export function View_WorkIdRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-work-id-renderer", [], null, null, null, View_WorkIdRendererComponent_0, RenderType_WorkIdRendererComponent)), i1.ɵdid(1, 114688, null, 0, i9.WorkIdRendererComponent, [i10.TranslateService, i11.WksCentralService, i12.GlobalEventsManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkIdRendererComponentNgFactory = i1.ɵccf("mdi-work-id-renderer", i9.WorkIdRendererComponent, View_WorkIdRendererComponent_Host_0, {}, {}, []);
export { WorkIdRendererComponentNgFactory as WorkIdRendererComponentNgFactory };
