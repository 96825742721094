<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >
       
        <div *ngIf="!isFinishLoading || isUploadInProgress" class="flex-row row" style="margin: 0px">
            <div class="container-fluid d-flex justify-content-center" >
                <mat-progress-bar 
                    mode="indeterminate"
                    style="margin-left: 30px;">
              </mat-progress-bar>
            </div>
        </div>
        <div *ngIf="isFinishLoading" class="flex-row row dropzone" style="margin: 0px"  draggable="true" #dropArea >
            <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
                <form [formGroup]="uploadForm"  >
                    <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                        <mat-card-header>
                            <mat-card-title>
                            <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                            <section class="mat-typography">
                                <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                            </section>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-actions>
                            <div class="container-fluid d-flex justify-content-between" >
                                <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                    <mat-icon>arrow_back</mat-icon>
                                    {{ 'goback' | translate }}
                                </button>
                                <button *ngIf="uploadForm.valid && doUpload" mat-raised-button  #buttonSubmit type="submit" (click)="uploadFile()" >
                                    <mat-icon>cloud_upload</mat-icon>
                                    {{ 'uploadFiles' | translate }}
                                </button>
                                <button *ngIf="uploadForm.valid && doVerify" mat-raised-button  #buttonSubmit type="submit" (click)="verifyFile()" >
                                    <mat-icon> insert_drive_file</mat-icon>
                                    {{ 'verifyFiles' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>

                        <mat-card-content class="stdTheme" style="padding-bottom: 20px;">
                            <div class="flex-row row"  style="margin-bottom: 10px;">
                                <div class="col-2"></div>
                                <div class="col-3" >
                                   <!--https://getbootstrap.com/docs/4.4/utilities/spacing/-->
                                    <div class="pt-4 mt-2">
                                        <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" 
                                                                >
                                                    <mat-icon>library_add</mat-icon>
                                            <span>{{'SelectFiles' | translate}}</span>
                                            <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                            <input #fileInput type="file" (change)="onFileChange($event)" [accept]="fileExt" style="display:none;" />
                                        </button>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="mdiinput form-group" appearance="standard">
                                        <mat-label>{{ 'uploadFileName' | translate }}</mat-label>
                                        <input id="uploadFilename" class="form-control inputTheme" formControlName="uploadFileName" 
                                            required readonly
                                            matInput #input placeholder="{{ 'uploadFileName' | translate }}"
                                            >
                                        </mat-form-field>
                                </div>
                               
                            </div>
    
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>

</div>
