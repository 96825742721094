import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SingleDataSet, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';

import { JobDataService } from '../../../../../job-data/services/job-data.service';
import { Widget, RequestModel } from '../../../../models/job-data.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-polar-area-chart',
  templateUrl: './polar-area-chart.component.html',
  styleUrls: ['./polar-area-chart.component.css']
})
export class PolarAreaChartComponent implements OnInit {

  @Input() widgetData: Widget;
  @Input() displayTime: boolean;
  private readonly onDestroy = new Subject<void>();

  // public polarAreaChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail Sales', 'Telesales', 'Corporate Sales'];
  public polarAreaChartLabels: Label[];
  // public polarAreaChartData: SingleDataSet = [300, 500, 100, 40, 120];
  public polarAreaChartData: SingleDataSet;
  public polarAreaLegend = true;

  // public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaChartType: ChartType;
  public polarAreaChartColors: any[];
  updateFrequency: number;

  dataIsLoaded : boolean = false;
  requestToDo = new RequestModel();
  displayDateTime: boolean;
  productedDate: any;
  constructor( private _jobDataService: JobDataService, private  _translate: TranslateService) { }

  ngOnInit() {
    console.log('widgetData PolarAreaChart : ' + this.widgetData);
    this.updateFrequency = this.widgetData.updateFrequency;
    this.displayDateTime = this.displayTime;

    this._jobDataService.displayGenerateTime
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: any) => {
        if ((event === 'displayTime') || (event === 'hideTime')) {
          this.toogleTime(event);
        }
    });
    this.buildRequest();
    this.polarAreaChartType = this.widgetData.chartType as ChartType;
    
    this.getRequestChart()
    .then ((resultChart: any) => {
      const dateProduction = resultChart.generated_date as Date;
      const localFmt = this._translate.getLocalFmt('timeFormat');
      this.productedDate = CommonMethods.dateToString(dateProduction,'unixM',localFmt);
      const jsonData = JSON.parse(resultChart.json_data);
      this.polarAreaChartLabels = jsonData.labelCols as Label[];
      this.polarAreaChartData = jsonData.datasets[0].data as SingleDataSet;
      const colorAlphas = CommonMethods.genAlphaHslaColors(-1,70,[20,100,80],this.polarAreaChartData.length);
      this.polarAreaChartColors = [];
      let colorList = [];
      let idx = 0;
      for(const serieCur of this.polarAreaChartData) {
        colorList.push(colorAlphas[idx][1]);
        idx++;
      }
      const colorCur = {
        backgroundColor: colorList,
      }
      this.polarAreaChartColors.push(colorCur);
      this.dataIsLoaded = true;
    })
    .then((error) => {

    });
  }
  buildRequest() {

    let requestRestrictions = '';

    for (const restrict of this.widgetData.requestRestrictions) {
      // console.log(restrict.columnName + ' ' + restrict.operande + ' ' + restrict.restrictValue);
      if(restrict.columnName) {
        if(requestRestrictions != '') {
          requestRestrictions+=';';
        }
        requestRestrictions+= restrict.columnName + '|' + restrict.operande + '|' + restrict.restrictValue;
      }
    }

    this.requestToDo.requestRef = this.widgetData.requestRef,
    this.requestToDo.requestRestrictions = requestRestrictions
  }
  getRequestChart() {
    return new Promise((resolve, reject) => {
      this._jobDataService.getRequestChart(this.requestToDo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const resultChart = data.body;
          if(resultChart.length === 0) {
            reject();
          }
          else resolve(resultChart[0]);
        }, err => {
          
          reject(err);
        }
      );
    });
  }
  // events
  toogleTime (event: any) {
    if ((event === 'displayTime')) {
      this.displayDateTime = true;
    } else {
      this.displayDateTime = false;
    }
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
