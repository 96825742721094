/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./works-checklist.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i8 from "@angular/material/tabs";
import * as i9 from "@angular/material/card";
import * as i10 from "../../../../../../services/translate.pipe";
import * as i11 from "../../../../../../services/translate.service";
import * as i12 from "./tasks-checklist/tasks-checklist.component.ngfactory";
import * as i13 from "./tasks-checklist/tasks-checklist.component";
import * as i14 from "@angular/forms";
import * as i15 from "../../../../services/wks-central.service";
import * as i16 from "../../../../../common/services/ressources.service";
import * as i17 from "./works-checklist.component";
import * as i18 from "../../../../../../services/user.service";
import * as i19 from "@angular/material/dialog";
import * as i20 from "../../../../../common/services/global-events-manager.service";
import * as i21 from "../../../../services/wks-works.service";
var styles_WorksChecklistComponent = [i0.styles];
var RenderType_WorksChecklistComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_WorksChecklistComponent, data: {} });
export { RenderType_WorksChecklistComponent as RenderType_WorksChecklistComponent };
function View_WorksChecklistComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["style", "background-color: transparent!important"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["diameter", "40"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "blink_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait..."]))], function (_ck, _v) { var currVal_7 = "40"; var currVal_8 = "indeterminate"; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 2).mode === "determinate") ? i1.ɵnov(_v, 2).value : null); var currVal_6 = i1.ɵnov(_v, 2).mode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_WorksChecklistComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-row row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "col-10 left-label-span"], ["style", "text-align: start; font-size: 15px; margin-top: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.workEquipments.mainEquipment.equipDesign ? _co.workEquipments.mainEquipment.equipDesign : _co.workEquipments.mainEquipment.equipName); _ck(_v, 2, 0, currVal_0); }); }
function View_WorksChecklistComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 9, "mat-tab", [], null, null, null, i7.View_MatTab_0, i7.RenderType_MatTab)), i1.ɵdid(2, 770048, [[2, 4]], 2, i8.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 3, { templateLabel: 0 }), i1.ɵqud(335544320, 4, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WorksChecklistComponent_4)), i1.ɵdid(6, 16384, [[3, 4]], 0, i8.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "mat-card-content", [["class", "stdTheme mat-card-content"], ["style", "margin-bottom: 0px;"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i9.MatCardContent, [], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(10, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = i1.ɵnov(_v.parent.parent, 7); _ck(_v, 10, 0, currVal_0); }, null); }
function View_WorksChecklistComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-row row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "col-10 left-label-span"], ["style", "text-align: start; font-size: 15px; margin-top: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.equipDesign ? _v.parent.context.$implicit.equipDesign : _v.parent.context.$implicit.equipName); _ck(_v, 2, 0, currVal_0); }); }
function View_WorksChecklistComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "stdTheme"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 9, "mat-tab", [], null, null, null, i7.View_MatTab_0, i7.RenderType_MatTab)), i1.ɵdid(2, 770048, [[2, 4]], 2, i8.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 5, { templateLabel: 0 }), i1.ɵqud(335544320, 6, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WorksChecklistComponent_7)), i1.ɵdid(6, 16384, [[5, 4]], 0, i8.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "mat-card-content", [["class", "stdTheme mat-card-content"], ["style", "margin-bottom: 0px;"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i9.MatCardContent, [], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(10, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = i1.ɵnov(_v.parent.parent.parent, 7); _ck(_v, 10, 0, currVal_0); }, null); }
function View_WorksChecklistComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_6)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workEquipments.subEquipments; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WorksChecklistComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "flex-row row"], ["style", "margin-left: 0px;margin-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-tab-group", [["class", "mat-tab-fill-height mat-tab-group"], ["dynamicHeight", ""], ["mat-align-tabs", "start"], ["style", "margin: 0px; width: 100%"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.selectedTabChange("checkEquip", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatTabGroup_0, i7.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, [["tabsSubEquipment", 4]], 1, i8.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.MAT_TABS_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], { dynamicHeight: [0, "dynamicHeight"] }, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_5)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = ((_co.workEquipments.subEquipments === undefined) || (_co.workEquipments.subEquipments.length === 0)); _ck(_v, 5, 0, currVal_3); var currVal_4 = ((_co.workEquipments.subEquipments !== undefined) && (_co.workEquipments.subEquipments.length > 0)); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WorksChecklistComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i10.TranslatePipe, [i11.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Update")); _ck(_v, 2, 0, currVal_0); }); }
function View_WorksChecklistComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container-fluid d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayModalVerification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i10.TranslatePipe, [i11.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i10.TranslatePipe, [i11.TranslateService]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "mat-raised-button buttonTheme"], ["style", "margin-bottom: 10px;"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.validData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(0, i10.TranslatePipe, [i11.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("verif check")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("cancel")); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.isOkToValid; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("submit")); _ck(_v, 8, 0, currVal_3); }); }
function View_WorksChecklistComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "flex-row row"], ["style", "margin-left: 0px;margin-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_9)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_10)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isReadOnly && _co.isOKToUpdate); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isReadOnly; _ck(_v, 4, 0, currVal_1); }, null); }
function View_WorksChecklistComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mdi-tasks-checklist", [], null, [[null, "dataOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataOut" === en)) {
        var pd_0 = (_co.inputData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_TasksChecklistComponent_0, i12.RenderType_TasksChecklistComponent)), i1.ɵdid(2, 573440, null, 0, i13.TasksChecklistComponent, [i14.FormBuilder, i11.TranslateService, i15.WksCentralService, i16.RessourcesService], { statutCall: [0, "statutCall"], ihmOptions: [1, "ihmOptions"], tasksActionsArgs: [2, "tasksActionsArgs"], itemEquip: [3, "itemEquip"] }, { dataOut: "dataOut" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _co.ihmOptionsCkeck; var currVal_2 = _co.tasksActionsArgs; var currVal_3 = _co.itemEquip; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_WorksChecklistComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_12)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCheckLoaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WorksChecklistComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mSidenav: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorksChecklistComponent_8)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["checkEquipTemplate", 2]], null, 0, null, View_WorksChecklistComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.checkEquipmentsIsLoaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.checkEquipmentsIsLoaded; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.checkEquipmentsIsLoaded; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_WorksChecklistComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-works-checklist", [], null, null, null, View_WorksChecklistComponent_0, RenderType_WorksChecklistComponent)), i1.ɵdid(1, 114688, null, 0, i17.WorksChecklistComponent, [i18.UserService, i19.MatDialog, i20.GlobalEventsManagerService, i15.WksCentralService, i11.TranslateService, i16.RessourcesService, i21.WksWorksService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorksChecklistComponentNgFactory = i1.ɵccf("mdi-works-checklist", i17.WorksChecklistComponent, View_WorksChecklistComponent_Host_0, { statutCall: "statutCall", actionType: "actionType", workId: "workId", iRow: "iRow", ihmOptions: "ihmOptions" }, {}, []);
export { WorksChecklistComponentNgFactory as WorksChecklistComponentNgFactory };
