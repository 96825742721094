import { Observable } from 'rxjs';
import { LatLng, GeoValues } from '../../../models/data.model';
import * as i0 from "@angular/core";
export class GeolocationService {
    constructor() {
        this.geocoder = new google.maps.Geocoder();
    }
    latLngToAddress(latLng) {
        const latLngCur = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        const returnValue = {
            latLng: latLngCur,
        };
        return new Observable((observer) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder.geocode({ location: latLng }, ((results, status) => {
                returnValue.status = status;
                if (status === google.maps.GeocoderStatus.OK) {
                    returnValue.addresses = [];
                    for (const place of results) {
                        try {
                            returnValue.addresses.push(place.formatted_address);
                        }
                        catch (_a) {
                            continue;
                        }
                    }
                    observer.next(returnValue);
                    observer.complete();
                }
                else {
                    console.log('Geocoding service: geocoder failed due to: ' + status);
                    observer.error(returnValue);
                }
            }));
        });
    }
    addressTolatLng(addressCur) {
        const returnValue = {
            address: addressCur,
        };
        return new Observable((observer) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder.geocode({ address: addressCur }, ((results, status) => {
                returnValue.status = status;
                if (status === google.maps.GeocoderStatus.OK) {
                    returnValue.latLngs = [];
                    returnValue.addresses = [];
                    for (const place of results) {
                        try {
                            returnValue.latLngs.push(place.geometry.location.toJSON());
                            returnValue.addresses.push(place.formatted_address);
                        }
                        catch (_a) {
                            continue;
                        }
                    }
                    observer.next(returnValue);
                    observer.complete();
                }
                else {
                    console.log('Geocoding service: geocode was not successful for the following reason: ' + status);
                    observer.error(returnValue);
                }
            }));
        });
    }
}
GeolocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeolocationService_Factory() { return new GeolocationService(); }, token: GeolocationService, providedIn: "root" });
