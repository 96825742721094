import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../../services/user.service';
import { RessourcesService } from './ressources.service';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../tools/commonMethods';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/user.service";
import * as i3 from "./ressources.service";
// import { InternationalsFormats } from 'app/models/data.model';
// https://grokonez.com/frontend/angular/angular-4-uploadget-multipartfile-tofrom-spring-boot-server
export class RemoteFilesService {
    constructor(_httpClient, _userService, _ressourcesService) {
        this._httpClient = _httpClient;
        this._userService = _userService;
        this._ressourcesService = _ressourcesService;
    }
    downloadDoc(_url, _path, _type) {
        _path = CommonMethods.cleanURLString(_path);
        // const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;
        const urlCur = `${environment.baseUrl}` + _url + '?path=' + _path + '&type=' + _type;
        const localHttpHeaders = this._userService.getHeaders();
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map((result) => {
            return result;
        }));
    }
    loadCountries() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/countries`;
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadCurrencies() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/currencies`;
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadEconomicAreas() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/economicAreas`;
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    loadIntFormats() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/internationalsFormats`;
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    reloadWebdav() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/config/reloadWebdav`;
        return this._httpClient.post(urlCur, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    pushFileToStorage(_file, _uploadType) {
        const formdata = new FormData();
        formdata.append('file', _file, _file.name);
        /* const req = new HttpRequest('POST', '/post', formdata, {
           reportProgress: true,
           responseType: 'text'
         });*/
        // return this.http.request(req);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/file/${_uploadType}`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
    pushMultifilesPartsWks(filesLight, filesUpload, entity, criterias, targetDataList) {
        const formdata = new FormData();
        let fileNum = 0;
        for (const fileUploadCur of filesUpload) {
            fileNum++;
            if (fileNum > 4) {
                break;
            }
            let fileName = fileUploadCur.readFile.nameFile;
            fileName = fileName.replace(/[&]/g, '%26');
            fileName = fileName.replace(/[ ]/g, '%20');
            formdata.append('file' + fileNum, fileUploadCur.readFile.fileObject, fileName);
            formdata.append('upload' + fileNum, JSON.stringify(filesLight[fileNum - 1]));
        }
        if (fileNum < 4) {
            for (let i = fileNum + 1; i < 5; i++) {
                formdata.append('file' + i, new Blob(), '');
                formdata.append('upload' + i, '');
            }
        }
        formdata.append('entity', entity);
        formdata.append('criterias', JSON.stringify(criterias));
        formdata.append('dataList', JSON.stringify(targetDataList));
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/files/parts`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFileToStorageWks(_file, currentDoc) {
        const formdata = new FormData();
        formdata.append('file', _file, _file.name);
        formdata.append('wksDoc', JSON.stringify(currentDoc));
        /* const req = new HttpRequest('POST', '/post', formdata, {
           reportProgress: true,
           responseType: 'text'
         });*/
        // return this.http.request(req);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/documents/wks`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFileAnchorages(_file, _folder, _entity) {
        const formdata = new FormData();
        let fileName = _file.name;
        fileName = fileName.replace(/[&]/g, '%26');
        fileName = fileName.replace(/[ ]/g, '%20');
        formdata.append('file', _file, fileName);
        formdata.append('folder', _folder);
        formdata.append('entity', _entity);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/file/anchorages`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFileSpecifications(_file, _folder, _entity, _specBrand, _action) {
        const formdata = new FormData();
        let fileName = _file.name;
        fileName = fileName.replace(/[&]/g, '%26');
        fileName = fileName.replace(/[ ]/g, '%20');
        formdata.append('file', _file, fileName);
        formdata.append('folder', _folder);
        formdata.append('brand', _specBrand);
        formdata.append('entity', _entity);
        formdata.append('action', _action);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/file/uploadSpecificationsFile`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFilePartsSupplies(_file, _folder, _entity) {
        const formdata = new FormData();
        let fileName = _file.name;
        fileName = fileName.replace(/[&]/g, '%26');
        fileName = fileName.replace(/[ ]/g, '%20');
        formdata.append('file', _file, fileName);
        formdata.append('folder', _folder);
        formdata.append('entity', _entity);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/file/partssuplies`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFileToStorageWksdav(_file, currentDoc, _wksDocOld, _actionType) {
        const formdata = new FormData();
        let fileName = _file.name;
        fileName = fileName.replace(/[&]/g, '%26');
        fileName = fileName.replace(/[ ]/g, '%20');
        formdata.append('file', _file, fileName);
        formdata.append('wksDoc', JSON.stringify(currentDoc));
        formdata.append('wksDocOld', _wksDocOld);
        formdata.append('action', _actionType);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/documents/wksdav`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    pushFileToStorageWksWorkdav(_file, currentDoc, _actionType, _dateSequence) {
        const formdata = new FormData();
        let fileName = _file.name;
        fileName = fileName.replace(/[&]/g, '%26');
        fileName = fileName.replace(/[ ]/g, '%20');
        formdata.append('file', _file, fileName);
        formdata.append('wksworksDoc', JSON.stringify(currentDoc));
        formdata.append('action', _actionType);
        formdata.append('dateSequence', _dateSequence);
        const curHttpHeaders = this._userService.getHeaders();
        const localHttpHeaders = new HttpHeaders({
            'Authorization': `${curHttpHeaders.get('Authorization')}`,
            'Accept': 'application/json',
            'X-MjlProperties': `${curHttpHeaders.get('X-MjlProperties')}`
        });
        const urlCur = `${environment.baseUrl}/upload/documents/wksworkdav`;
        return this._httpClient
            .post(urlCur, formdata, {
            headers: localHttpHeaders,
            reportProgress: true,
            observe: 'response',
        });
    }
    getFiles() {
        // return this.http.get('/getallfiles');
        const localHttpHeaders = this._userService.getHeaders();
        const urlCur = `${environment.baseUrl}/upload/getallfiles`;
        return this._httpClient.get(urlCur, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
RemoteFilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoteFilesService_Factory() { return new RemoteFilesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.RessourcesService)); }, token: RemoteFilesService, providedIn: "root" });
