<div class="mat-elevation-z8 container-fluid stdTheme" style="height: 100%;">
    <mat-card  class="stdTheme" layout="column" flex 
        style="padding: 0px; padding-right: 5px; padding-left: 5px; overflow-y: auto!important;height: 100%!important;min-height: 100%!important;">
        <mat-card-content class="stdTheme ">
        <!-- <form [formGroup]="equipmentForm" (click)="initDpBirthDay($event)" >-->
            <form [formGroup]="workForm" >
                <div class="flex-row row" style= "margin: 0px;margin-bottom: 10px;">
                    <div class="col-5">
                        <mat-form-field class="wksInput form-group" appearance="standard">
                            <mat-label>{{ 'workObject' | translate }}</mat-label>
                            <input id="workObject" #workObject class="form-control inputTheme" 
                                    formControlName="workObject" required
                                    (keyup)="onKeyChangeObject($event.target.value)"
                                        matInput placeholder="{{ 'workObject' | translate }}">
                        </mat-form-field>
                    </div>  
                    <div class="col-4" >
                        <mat-form-field class="mdiinput form-group" appearance="standard">
                            <mat-label>{{ 'workPrevious' | translate }}</mat-label>
                            <mat-select id="workPrevious" #workPrevious  formControlName="workPrevious" 
                                    panelClass="mdiselectcontent" ngDefaultControl
                                    (selectionChange)="onChangeworkPrevious(workPrevious.value)" >
                                <mat-option class="selectOptionsTheme" *ngFor="let otherworkCur of wksOthersWorks" 
                                    [value]="otherworkCur.value"  >{{otherworkCur.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>  
                    <div class="col-3">
                        <mat-form-field class="mdiinput form-group" appearance="standard">
                            <mat-label>{{ 'workTicket' | translate }}</mat-label>
                            <input id="workTicket" #workTicket class="form-control inputTheme" 
                                    formControlName="workTicket" maxlength="25"
                                        matInput placeholder="{{ 'workTicket' | translate }}"
                                        (keyup)="onChangeField('workTicket', workTicket.value)">
                        </mat-form-field>
                    </div>  
                </div>
                <div class="flex-row row" style= "margin: 0px;">
                    <div class="col-3" style="padding-top: 0px;margin-top: -15px;">
                        <mat-form-field class="mdiinput form-group" appearance="standard">
                            <mat-label>{{ 'criticalityState' | translate }}</mat-label>
                            <mat-select id="criticalityState" #criticalityState placeholder="{{ 'Choose criticalityState' | translate }}" formControlName="criticalityState" 
                                    panelClass="mdiselectcontent" ngDefaultControl required
                                    (selectionChange)="onChangeCriticalityState(criticalityState.value)" >
                                <mat-option class="selectOptionsTheme" *ngFor="let criticalityCur of criticalityList" 
                                    [value]="criticalityCur.value"  >{{criticalityCur.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3" >

                    </div>
                    <div class="col-6">
                        <fieldset class="payment-border">
                            <legend class="payment-border" align="right"> {{ 'planning intervention' | translate }}</legend>
                            <div class="flex-row row wkscard" style="margin-left: 0px; margin-right: 0px;">
                                <div class="col-4" style="margin-right: 0em; padding-right: 0em">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'calStart' | translate }}</mat-label>
                                        <input matInput class="form-control inputTheme" formControlName="calStart" 
                                                    >
                                    </mat-form-field>
                                </div>
                                <div class="col-4" style="margin-right: 0em; padding-right: 0em">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'calEnd' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="calEnd" 
                                                    >
                                    </mat-form-field>
                                </div>
                                <div class="col-4" style="margin-right: 0em; padding-right: 0em">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'calActor' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="calActor" 
                                                    >
                                    </mat-form-field>
                                </div>
                            </div>

                        </fieldset>
                    </div> 
                </div>
                <div class="flex-row row" style="margin: 0px;padding: 0px; margin-top: -40px;padding-left: 10px; margin-bottom: 10px">
                    <div  class="container-fluid stdTheme" >
                        <mdi-location-wks
                            [applicationCall]="'workIntervention'" 
                            [dataLocationEquip]="dataLocationEquip"
                            [dataLocationArg]="dataLocationWork"
                            [readOnlyData]="isReadOnly" 
                            (dataOut)="dataOutLocation($event)">
                        </mdi-location-wks>
                    </div>
                </div>
                <div  class="flex-row row" style="margin-bottom: 10px; margin: 0px">
                    <div class="col-3" style="margin-bottom: 10px;">
                        <mat-form-field class="mdiinput form-group" appearance="standard">
                            <mat-label>{{ 'workStatut' | translate }}</mat-label>
                            <mat-select id="workStatut" #workStatut  formControlName="workStatut" 
                                    panelClass="mdiselectcontent" ngDefaultControl required
                                    (selectionChange)="onChangeWorkStatut(workStatut.value)" >
                                <mat-option class="selectOptionsTheme" *ngFor="let workstatutCur of workStatutsList" 
                                    [value]="workstatutCur.value"  >{{workstatutCur.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                    <div class="col-3" style="margin-right: 0em; padding-right: 0em;">
                        <mat-form-field class="mdiinput form-group" appearance="standard"  style="margin-bottom: 0px;">
                            <mat-label>{{ 'delayValidity' | translate }}</mat-label>
                            <mat-select id="delayValidity" #delayValidity  formControlName="delayValidity" 
                                    panelClass="mdiselectcontent"   ngDefaultControl  
                                    (selectionChange)="onChangeField('delayValidity',delayValidity.value)">
                                <mat-option class="selectOptionsTheme" *ngFor="let delayCur of delayList" 
                                    [value]="delayCur.value"  >{{delayCur.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="mdiinput form-group" appearance="standard">
                            <mat-label>{{ 'workComment' | translate }}</mat-label>
                            <textarea  id="workComment" class="form-control inputTheme textarea1" formControlName="workComment" 
                                        matInput #workComment placeholder="{{ 'workComment' | translate }}"
                                        cdkTextareaAutosize
                                        (keyup)="onChangeField('workComment', workComment.value)"
                                        #autosize="cdkTextareaAutosize"
                                        style="height: 80px; min-height: 80px;"
                                        [required] = "(((localWorkstatut !== null) && (localWorkstatut !== undefined) && (localWorkstatut.commentRequired !== undefined)) ? localWorkstatut.commentRequired : false)"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="5"
                            ></textarea >
                        </mat-form-field>
                    </div>
                </div>  
                <div class="flex-row row wkscard" >
                    <div class="col-6">
                        <fieldset class="payment-border">
                            <legend class="payment-border" align="right"> {{ 'terms of payment customer' | translate }}</legend>
                            <div class="flex-row row wkscard" style="margin-left: 0px; margin-right: 0px;">
                                <div class="col-4 stdTheme" >
                                    <!-- 
                                    <div class="checkbox" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                    <label class="labelLine" for="bsnCashpay" style="margin-left: 0px; margin-right: 15px" >{{ 'bsnCashPay' | translate }}</label>
                                    <input type="checkbox" id="bsnCashpay"  #bsnCashpay class="inputTheme" name="bsnCashpay"
                                        [checked]="accountCur.bsnCashpay" disabled >
                                    </div>
                                    -->
                                    <div *ngIf="accountCur.bsnCashpay" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                        <h6>{{ 'bsnCashPay' | translate }}</h6>
                                    </div>
                                    <div *ngIf="!accountCur.bsnCashpay" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                        <h6>{{ 'inAccount' | translate }}</h6>
                                    </div>
                                </div>
                                <!--
                                <div class="col-4 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;">
                                    <label class="labelLine" for="bsnAccount" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnAccount' | translate }}</label>
                                    <input id="bsnAccount" class="form-control stdTheme"  name="bsnAccount" style="width: 50%;padding: 0px;padding-left: 5px;"
                                            #bsnAccount [value] = "accountCur.bsnAccount" disabled >
                                </div>
                                -->
                                <div class="col-3 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;padding: 0px">
                                    <label class="labelLine" for="bsnDelayDays" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnDelayDays' | translate }}</label>
                                    <input id="bsnDelayDays" class="form-control stdTheme"  name="bsnDelayDays" style="width: 80%;padding: 0px;padding-left: 5px;"
                                            #bsnDelayDays [value] = "accountCur.bsnDelayDays + ' (' + translateLabel('days') + ')'" disabled >
                                </div>
                                <div class="col-4 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;">
                                    <label class="labelLine" for="bsnDefferedPaiement" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnDefferedPaiement' | translate }}</label>
                                    <input id="bsnDefferedPaiement" class="form-control stdTheme"  name="bsnDefferedPaiement" style="width: 80%;padding: 0px;padding-left: 5px;"
                                            #bsnDefferedPaiement [value] = "translateLabel(accountCur.bsnDefferedPaiement)" disabled >
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-6">
                        <fieldset class="payment-border">
                            <legend class="payment-border" align="right"> {{ 'terms of payment specifics' | translate }}</legend>
                            <div class="flex-row row wkscard" style="margin-bottom: 15px;margin-top: -10px">
                                <div class="col-4">
                                    <div class="checkbox" style="padding-top: 10px;display: flex;justify-content: space-between; margin: .5rem;">
                                        <label class="labelLine" for="customerConditions" style="margin-left: 0px; margin-right: 15px" >{{ 'customerConditions' | translate }}</label>
                                        <input type="checkbox" id="customerConditions"  #customerConditions class="inputTheme" name="bsnCashpay"
                                            (click)="onChangeField('customerConditions', customerConditions.checked)" formControlName="customerConditions" 
                                            [checked]="isCustomerConditions" 
                                            />
                                    </div>
                                </div>

                                <div class="col-6 form-inline" *ngIf="!isCustomerConditions">
                                    <!--<mat-label>{{ 'numberPayments' | translate }}</mat-label>-->
                                        <a><span>{{ 'numberPayments' | translate }}</span></a>
                                        <a><button type="button" class="btn btn-default" mat-icon-button (click)="counter('numberPayments',-1, numberPayments.value)">
                                            <span class="fa fa-minus"></span>
                                        </button></a>

                                        <a class="smallCounter">
                                            <input id="numberPayments" class="form-control stdTheme" formControlName="numberPayments" 
                                                min="1" max="12" style="width: 40px;  text-align: center; padding:0px; margin: 0px"
                                                    type="number" #numberPayments 
                                                (keyup)="onChangeField('numberPayments',numberPayments.value)"
                                            >
                                        </a>
                                        <a><button type="button" class="btn btn-default" mat-icon-button (click)="counter('numberPayments',1,  numberPayments.value)">
                                            <span class="fa fa-plus"></span>
                                        </button></a>

                                </div>
                                <div class="flex-row row wkscard" *ngIf="!isCustomerConditions" >
                                    <div  [ngStyle]="{'margin-top': '0px', 'width': '100%','padding': '0px'}" >
                                        <table class="table stdTheme table-striped" id="listConditions" style="margin-bottom: 0px;" #listConditions>
                                            <thead class="theadWork">
                                                <tr class="trWork">
                                                    <th style="width: 10%; padding: 0px; text-align: center;" >{{'orderNumber' | translate}}</th>
                                                    <th style="width: 40%; padding: 0px; text-align: center;" >{{'termPayment' | translate}}</th>
                                                    <th style="width: 30%; padding: 0px; text-align: center;" >{{'delayPayment' | translate}}</th>
                                                    <th style="width: 20%; padding: 0px; text-align: center;" >{{'percentPayment' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="tbodyWork" >
                                                <div *ngFor="let paymentCur of shedulerPayments; let i = index">
                                                    <tr class="trWork " >
                                                        <td style="width: 10%; padding: 0px; text-align: center;vertical-align: middle">
                                                            <span class="inputTheme">{{ i+1}}</span>
                                                        </td>
                                                        <td style="width: 40%; padding: 0px; text-align: center;vertical-align: middle">
                                                            <select class="form-control inputTheme" id="termPayment_{{i}}" #termPayment_{{i}}  
                                                                    style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;"
                                                                    (change)="onChangeConditions('termPayment', $event.target.value, i)">
                                                                <option class="selectOptionsTheme" 
                                                                            *ngFor="let condCur of conditionsList" 
                                                                            [(value)]="condCur.value" [selected]="condCur.value === paymentCur.term">
                                                                    {{ condCur.label}}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td style="width: 30%; padding: 0px; text-align: center;vertical-align: middle">     
                                                            <div *ngIf="paymentCur.term === 'afterInvoice'"  >                              
                                                                <select class="form-control inputTheme" id="shedulerPayment_{{i}}" #shedulerPayment_{{i}}  
                                                                        style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;"
                                                                        (change)="onChangeConditions('delayScheduler', $event.target.value, i)">
                                                                    <option class="selectOptionsTheme" 
                                                                                *ngFor="let delayCur of delayList" 
                                                                                [(value)]="delayCur.value" [selected]="delayCur.value === paymentCur.delay">
                                                                        {{ delayCur.label}}
                                                                    </option>
                                                                </select>
                                                            </div> 
                                                        </td>
                                                        <td class="inputTheme" style="width: 20%; padding: 0px; text-align: center;vertical-align: middle">
                                                            <div *ngIf="paymentCur.term !== ''"  > 
                                                                <input  #percentPayment_{{i}}   matInput (blur)="this.value = paymentCur.percent"
                                                                [(value)]="paymentCur.percent" (change)="onChangeConditions('percentPayment', $event.target.value,i)">
                                                            </div>  
                                                        </td>
                                                    </tr>
                                                </div>
                                            </tbody>
                                            
                                            <tfoot class="tbodyWork" *ngIf="100-totPercent !== 0">
                                                <tr class="trWork ">
                                                    <td style="width: 80%; padding: 0px; text-align: right;vertical-align: middle">
                                                        {{'controlPercent' | translate}}
                                                    </td>
                                                <!--    
                                                    <td style="width: 40%; padding: 0px; text-align: center;">
                                                    
                                                    </td>
                                                
                                                    <td style="width: 30%; padding: 0px; text-align:right">
                                                        <span> {{'controlPercent' | translate}}</span>
                                                    </td>
                                                -->
                                                    <td class="inputTheme" style="width: 20%; padding: 0px; text-align: center;vertical-align: middle">
                                                        <span>{{100-totPercent}}</span>
                                                    </td>

                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="flex-row row wkscard" >
                    <div class="col-6">
                        <fieldset class="payment-border">
                            <legend class="payment-border" align="right"> {{ 'financial conditions customer' | translate }}</legend>
                            <div class="flex-row row wkscard" style="margin-left: 0px; margin-right: 0px;">
                                <div class="col-7 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;" >
                                    <!-- -->
                                    <label class="labelLine" for="scaleName" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;" >{{ 'scaleName' | translate }}</label>
                                    <input id="scaleName" class="form-control stdTheme"  name="scaleName" style="width: 60%;padding: 0px;padding-left: 5px;"
                                            #scaleName [value] = "accountCur.scaleName" disabled >
                                </div>
                                <div class="col-4 stdTheme" style="display: flex;justify-content: space-between; margin: .5rem;">
                                    <label class="labelLine" for="bsnCoef1" style="margin-left: 0px; margin-right: 5px; padding-top: 10px;">{{ 'bsnCoef' | translate }}</label>
                                    <input id="bsnCoef1" class="form-control stdTheme"  name="bsnCoef1" style="width: 80%;padding: 0px;padding-left: 5px;"
                                            #bsnCoef1 [value] = "accountCur.bsnCoef" disabled >
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-6">
                        <fieldset class="payment-border">
                            <legend class="payment-border" align="right"> {{ 'financial conditions specifics' | translate }}</legend>
                            <div class="flex-row row wkscard" style="margin-bottom: 0px;margin-top: -15px">
                                <div class="col-5">
                                    <div class="checkbox" style="padding-top: 20px;display: flex;justify-content: space-between; margin: .5rem;">
                                        <label class="labelLine" for="customerFinancials" style="margin-left: 0px; margin-right: 15px" >{{ 'customerFinancials' | translate }}</label>
                                        <input type="checkbox" id="customerFinancials"  #customerFinancials class="inputTheme" name="bsnCashpay"
                                            [checked]="isCustomerFinancials" (click)="onChangeField('customerFinancials', customerFinancials.checked)"
                                            formControlName="customerFinancials"
                                            />
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="!isCustomerFinancials">
                                <mat-form-field class="mdiinput form-group" appearance="standard"  style="margin-bottom: 0px;">
                                    <mat-label>{{ 'scaleName' | translate }}</mat-label>
                                    <mat-select id="scaleName" #scaleName placeholder="{{ 'Choose scaleName' | translate }}" formControlName="scaleName" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required 
                                            (selectionChange)="onChangeField('scaleName',scaleName.value)">
                                        <mat-option class="selectOptionsTheme" *ngFor="let scaleCur of scalesList" 
                                            [value]="scaleCur"  >{{scaleCur }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                                <div class="col-3" *ngIf="!isCustomerFinancials">
                                <mat-form-field class="wksInput form-group" appearance="standard">
                                    <mat-label>{{ 'bsnCoef' | translate }}</mat-label>
                                    <input id="bsnCoef" class="form-control inputTheme" formControlName="bsnCoef" 
                                            matInput #input placeholder="{{ 'bsnCoef' | translate }}" 
                                            
                                            (keyup)="onChangeField('bsnCoef',$event.target.value)"
                                    >
                                </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>