import { BsLocaleService } from 'ngx-bootstrap/datepicker';
/* import { defineLocale, arLocale, bgLocale, caLocale, csLocale, daLocale, deLocale, enGbLocale,
          esDoLocale, esLocale, esUsLocale, etLocale, fiLocale, frLocale, glLocale, heLocale, hiLocale, huLocale,
          hrLocale, idLocale, itLocale, jaLocale, kaLocale, kkLocale, koLocale, ltLocale, lvLocale, mnLocale, nbLocale,
          nlBeLocale, nlLocale, plLocale, ptBrLocale, roLocale, ruLocale, skLocale, slLocale, sqLocale, svLocale, thLocale,
          thBeLocale, trLocale, ukLocale, viLocale, zhCnLocale  } from 'ngx-bootstrap/chronos';
*/
import { defineLocale, frLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/datepicker";
export class BootstrapI18nService {
    constructor(_localeService) {
        this._localeService = _localeService;
        defineLocale('fr', frLocale);
        defineLocale('en', enGbLocale);
    }
    setLanguage(lang) {
        this._localeService.use(lang);
    }
}
BootstrapI18nService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BootstrapI18nService_Factory() { return new BootstrapI18nService(i0.ɵɵinject(i1.BsLocaleService)); }, token: BootstrapI18nService, providedIn: "root" });
