<mat-card id="bns-contacts" class="wkscard stdTheme" layout="column" flex 
        style="width: 100%; margin-top: 0px; margin-bottom: 0px;padding: 0px;" >
    <mat-card-header>
        <div *ngIf="isLoaded" class="container-fluid" style="margin-top: 0px; margin-bottom: 0px;">
            <div class="row justify-content-around" style="margin-left: 0px;margin-right: 0px;">
                <div class="col-11">      
                    {{nameParent}}
                </div>
                <div class="col-1 container-fluid d-flex justify-content-end" style="padding-left: 0px; padding-right: 0px;">
                    <button *ngIf="!isReadOnly" mat-icon-button (click)="addContact()">
                        <span class="fa fa-plus-square" 
                            style="margin-left: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 30px" >
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-header>
    
    <mat-card-content class="wkscard" >
        <div *ngIf="isLoaded"  [style.overflow]="'auto'">
            <!--<ng-template ngFor let-iRow="index" let c = "count" let-contactCur [ngForOf]="listContacts">-->
            <div  *ngFor= "let contactCur of listContacts; let iRow=index; let c=count;let isLast=last" >
                <div class="container-fluid contactCur" style="margin: 0px; padding: 0px;"  >
                    <div id="contactCur_{{iRow}}" #contactCur_{{iRow}} class="row rowLine" style="line-height: 30px; " >
                        <div class="col-1" style="margin-top: 10px;">
                            <button *ngIf="!isReadOnly" mat-icon-button (click)="removeContact(iRow) ">
                                <span class="fa fa-trash" style="font-size: 15px" ></span>
                            </button>      
                        </div>
                        <div class="col-10 labelLine" style="margin-top: 10px;">
                            <span style="margin: 0px;"><h4>{{ contactCur.identity.cntFirstname }} {{ contactCur.identity.cntLastname }} </h4> </span>               
                        </div>
                        <div class="col-1" style="margin-top: 10px;">
                            <button mat-icon-button
                                (click)="contactCur.isCollapsed = !contactCur.isCollapsed"
                                [attr.aria-expanded]="!contactCur.isCollapsed" aria-controls="collapseExample">
                                    <span *ngIf="contactCur.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                    <span *ngIf="!contactCur.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                            </button> 
                        </div>

                    </div>             
                    <div class="row" [ngbCollapse]="contactCur.isCollapsed" style="margin-right: 0px;margin-left: 0px;">
                        <div class="col-12" style="margin-right: 0px; margin-left: 0px;padding-left: 0px; padding-right: 0px;">
                            <mdi-address-block [config]="configContacts" [dataIn]="getJsonContact(contactCur, iRow)" (dataOut)="dataOutContact($event,iRow)"></mdi-address-block>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

</mat-card>