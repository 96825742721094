<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="container-fluid" style="margin-top: 75px; width: 100%;">
    <div class="flex-row row  stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
        <div class="col top-sidenav" style ="text-align: center!important">
          <span style="font-size:large"><strong>{{ 'Appointments' | translate }}</strong></span>
        </div>
    </div>
    <div class="spinner-container  stdTheme" *ngIf="inProgress" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-spinner diameter="50" strokeWidth="5"></mat-spinner>
    </div>
    <div class="flex-row row stdTheme" *ngIf="!inProgress">
        <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
          <!--https://stackblitz.com/angular/kopvgpmkyodq?file=src%2Fapp%2Fsidenav-disable-close-example.ts-->
            <mat-sidenav-container fxFlexFill class="container-sidenav w-100 stdTheme" fullscreen (backdropClick)="toggleSidenav('backdrop')">
              <mat-sidenav #sidenav fxLayout="column" mode="push"  disableClose class="lateral-sidenav-30-100 stdTheme" 
                        style="padding-left: 20px;padding-right: 5px;">
                <a>
                  <mdi-appointments-card *ngIf="sidenavOpen" (dataOut)="saveAppointment($event)" 
                                  [rowCard]="rowCard" 
                                  [wksEntity]="wksEntity" 
                                  [wksEntityParams]="wksEntityParams" 
                                  [mechanicUsers]="mechanicUsers" 
                                  [popoverAppointment]="'false'" 
                                  (onload)="ngOnInit()"></mdi-appointments-card>
                </a>
              </mat-sidenav>
              <mat-sidenav-content style="padding-left: 15px;" >
                <div class="flex-row row stdTheme w-100" style="margin-left: 0px;margin-right: 0px;" >
                  <div class="col-3 d-flex justify-content-start align-items-center " >
                    <input type="text"  matInput id="inputSearch"
                          style="height: 40px;padding-left: 10px;padding-right: 10px;"
                          placeholder="{{ 'global filter' | translate }}" 
                          (keyup)="onFilterChanged(inputSearch.value)" #inputSearch />
                          <button mat-icon-button matSuffix (click)="clearfilter()" >
                            <mat-icon  matTooltip="{{ 'clear filter' | translate }}" >close</mat-icon>
                          </button>
                  </div>
                  
                  <div class="col d-flex justify-content-start align-items-center  "  >
                   
                  </div>
                  
                  <div class="col-2 d-flex justify-content-end " style="margin-top: 10px;"  >
                    <span >{{ 'selectedStatut' | translate }}</span>
                  </div>
                  <div class="col-2 d-flex justify-content-start align-items-center  "  >
                    <mat-form-field class="mdiinput form-group" appearance="standard" style="margin-top: -30px;">
                      <mat-select id="trackingStatut" #trackingStatut 
                              panelClass="mdiselectcontent" ngDefaultControl
                              [value]="statutCur"
                              (selectionChange)="onChangeStatut(trackingStatut.value)" >
                          <mat-option class="selectOptionsTheme" *ngFor="let statutCur of statutsList" 
                              [value]="statutCur.value"  >{{statutCur.label }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                  </div>
                  <!--
                  <div class="col-2 d-flex justify-content-end align-items-center">
                    <button mat-icon-button class="buttonTheme"  (click)="calendar()" 
                            style="margin-left: 10px;margin-right: 10px;">
                      <span class="fa fa-calendar" style="font-size: 20px" ></span>
                    </button>
                  </div>
                  -->
                   <div class="col-2 d-flex justify-content-end align-items-center">
                    <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                            style="margin-left: 10px;margin-right: 10px;">
                      <span class="fas fa-redo" style="font-size: 20px" ></span>
                    </button>
                  </div>
                </div>
                <div *ngIf="displayCalendar">
                  <ng-container [ngTemplateOutlet]="calendarTemplate"></ng-container> 
                </div>
                <div class="container-fluid " *ngIf="dataLoaded">
                  <ngx-datatable #ngxDatatable [rows]="rows" 
                    class="material striped"  
                    [columnMode]="'force'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [loadingIndicator]="inProgress"
                    [rowHeight]="50" 
                    [reorderable]="reorderable" 
                    [limit]="pageSize" 
                    [sortable]="true"
                    [draggable]="true" 
                    [resizeable]="true" 
                    [canAutoResize]="true" 
                    [scrollbarH]="true" 
                    [selected]="selected" 
                    [selectionType]="'single'"
                    [externalPaging]="true"
                    [count]="page.totalElements"
                    [offset]="page.pageNumber"
                    [limit]="page.size"
                    (tableContextmenu)="onTableContextMenu($event)"
                    (sort)="onSort($event)"
                   
                    (select)='onSelect($event)'>
                    <div  *ngFor="let col of  appointmentsDisplayedColumns">
                      <ngx-datatable-column *ngIf="!col.isDate" [name]="col.name" [prop]="col.prop" [width]="col.width" >
                        <ng-template let column="column" let-sort="sortFn" let-sortDir="sortDir" ngx-datatable-header-template>
                          <span  (click)="onSort($event)"><strong>{{ col.name }} </strong></span>
                        </ng-template>
                        <ng-template  let-row="row" ngx-datatable-cell-template>
                          {{ row[col.prop] | translate}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column *ngIf="col.isDate" [name]="col.name" [prop]="col.prop" [width]="col.width" >
                        <ng-template let column="column" let-sort="sortFn" let-sortDir="sortDir" ngx-datatable-header-template>
                          <span (click)="onSort($event)"><strong>{{ col.name }} </strong></span>
                        </ng-template>
                        <ng-template  let-row="row" ngx-datatable-cell-template>
                          {{ row[col.prop] | date : dateTimeFormat}}
                        </ng-template>
                      </ngx-datatable-column>
                    </div>

                    <ngx-datatable-column name="Actions" headerClass="stdTheme" [sortable]="false">
                      <ng-template let column="column" ngx-datatable-header-template>
   
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template >
                        <button mat-icon-button class="buttonTheme" (click)="editAppointment(row)" *ngIf="doAction(row)"
                                  style="margin-left: 10px;margin-right: 10px;">
                          <span class="fa fa-edit" style="font-size: 20px"></span>
                        </button>
                      </ng-template>
                    </ngx-datatable-column>
                    <!-- https://stackblitz.com/edit/angular-ngx-datatable-custom-paginator-ltqvk8?file=app%2Fpager.component.ts -->
                    <ngx-datatable-footer>
                      <ng-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        ngx-datatable-footer-template
                      >
                      <spam style="margin-left: 10px;">{{ 'responsesCount' | translate }} : {{ rowCount}}</spam>
                        <mdi-datatable-pager
                          [page]="curPage"
                          [visiblePagesCount]="5"
                          [size]="pageSize"
                          [count]="rowCount"
                          [hidden]="false"
                          (change)="setPagerFooter($event)">
                        </mdi-datatable-pager>
                      </ng-template>
                    </ngx-datatable-footer>
                  </ngx-datatable>
                </div>
              </mat-sidenav-content>
            </mat-sidenav-container>
          </div>
    </div> <!-- end !inProgress-->
    
</div>
<ng-template #calendarTemplate >
  <mdi-calendar></mdi-calendar>
</ng-template>