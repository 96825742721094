<!-- https://stackblitz.com/edit/get-img-from-url-observable-angular?file=app%2Fapp.component.html -->
<!--
<div class="container">
  <hello name="{{ name }}"></hello>
  <p>
    Start editing to see some magic happen :)
  </p>
  <img [src]="base64Image" />
</div>

<style>
  .container {
    margin: 0 auto;
    width: 880px;
  }

  .container img {
    width: 100%;
  }
</style>
-->
<!-- https://www.positronx.io/angular-responsive-carousel-examples/ -->
<div  class="container stdTheme"  >

  <div *ngIf="!allIsLoaded" class="flex-row row" style="margin: 0px">
    <div class="container-fluid d-flex justify-content-center" >
        <mat-progress-bar 
            mode="indeterminate"
            style="margin-left: 30px;">
        </mat-progress-bar>
    </div>
</div>


  <ngb-carousel
      [showNavigationArrows]="true" 
      [showNavigationIndicators]="true"
      interval="12000"
      [keyboard]="true"
      [pauseOnHover]="true"
      [wrap]="true"
      [activeId]=""
      >

      <div  *ngFor="let imgCur of imgFiles; let i=index">
        <ng-template ngbSlide id="{{imgCur.fileName}}">
          <div class="picsum-img-wrapper">
            <img [src]="imgCur.imageToShow  | safeHtml: 'resourceUrl' " alt="{{imgCur.fileName}}">
          </div>
          <div class="carousel-caption">
            <h3 class="stdTheme">{{imgCur.fileLegend}}</h3>
            <p class="stdTheme">{{imgCur.fileName}}</p>
          </div>
        </ng-template>
      </div>
    </ngb-carousel>
  </div>
  

