import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SchedulerService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    getAllJobs(_action) {
        const urlService = `${environment.baseUrl}/quartz/getAllJobs/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        return this.httpClient
            .post(urlService, undefined, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    saveJob(_action, schedulerJobInfos) {
        const urlService = `${environment.baseUrl}/quartz/saveOrUpdate/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    runJob(_action, schedulerJobInfos) {
        const urlService = `${environment.baseUrl}/quartz/runJob/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    pauseJob(_action, schedulerJobInfos) {
        const urlService = `${environment.baseUrl}/quartz/pauseJob/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    resumeJob(_action, schedulerJobInfos) {
        const urlService = `${environment.baseUrl}/quartz/resumeJob/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
    deleteJob(_action, schedulerJobInfos) {
        const urlService = `${environment.baseUrl}/quartz/deleteJob/?` + 'action=' + _action;
        const localHttpHeaders = this.httpHeaders;
        const body = JSON.stringify(schedulerJobInfos);
        return this.httpClient
            .post(urlService, body, {
            headers: localHttpHeaders,
            observe: 'response'
        });
    }
}
SchedulerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchedulerService_Factory() { return new SchedulerService(i0.ɵɵinject(i1.HttpClient)); }, token: SchedulerService, providedIn: "root" });
