import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { MdePopoverTrigger } from '@material-extended/mde';

import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { CalendarService } from '../../../../../common/services/calendar.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { UserResponse } from '../../../../../../models/user.model';
import { InternationalsFormats } from '../../../../../../models/data.model';
import { WksWorksModel, DataWorkModel, WorkEquipmentRef, WksWorksTracking } from '../../../../models/wks-works.model';
import { WksEntityParams } from '../../../../models/wks-common.model';
import { CalendarEvt, CalendarEventBdd, EventChanged, WorkingTime } from '../../../../../../models/common.model';
// TasksActionsContentModel
import { TasksActionsContentModel, TasksActionsModel } from '../../../../models/wks-param.model';
import { environment } from '../../../../../../../environments/environment';
import { WorksPlanningComponent } from '../works-planning/works-planning.component';

@Component({
  selector: 'mdi-works-assignments',
  templateUrl: './works-assignments.component.html',
  styleUrls: ['./works-assignments.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class WorksAssignmentsComponent implements OnInit {

  @ViewChild(MdePopoverTrigger, { static: false }) localPopover: MdePopoverTrigger;
  @ViewChild('detailPopover', { static: false }) detailPopover: MdePopoverTrigger;

  private readonly onDestroy = new Subject<void>();
  worksLoaded: boolean;
  planningLoaded: boolean;
  dotsLine: string;
  cacheDatatemp: any[];
  worksList: WksWorksModel[];
  eventsList: CalendarEvt[];
  calendardEventLBddist: CalendarEventBdd[];
  workCur: WksWorksModel;
  wksEntity: WksEntityModel;
  mechanicUsers: UserResponse[];
  rows = new Array<WksWorksModel>();
  
  actorSelected: UserResponse;
  selectedRow: number;
  wksEntityParams: WksEntityParams;
  displayPopover: boolean;

  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;
  doLoadCalendar: boolean;
  submitCalendarInprogress: boolean;
  workingTime: WorkingTime;
  dataInWork: DataWorkModel;

  constructor(private _dialog: MatDialog,
              private router: Router, 
              private _userService: UserService,
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private _calendarService: CalendarService, 
              private _ressourcesService: RessourcesService,
              private _wksWorksService: WksWorksService,
              private adapterDate: DateAdapter<any>) { }

  ngOnInit() {
    if (this._userService.getUserLogged() === undefined)  { return ; }
    this.initAllBooleans();
    this.loadParams();
    this.getWorksWaitingDate(this._userService.getUserLogged().entity, this.wksEntity.entityTz, 'approved')
    .then(
      () => {
        this.worksLoaded = true;
      },
      err => {
        // console.log('getWorksWaitingDate' + err.message);
        this.worksLoaded = true;
      });
  }
  initAllBooleans() {
    this.displayPopover = false;
    this.worksLoaded = false;
    this.planningLoaded = false;
    this.doLoadCalendar = false;
    this.submitCalendarInprogress = false;
  }
  loadParams() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.settingIntFmts();
    this.wksEntityParams = this._ressourcesService.getWksEntityParams();
    this.workingTime = {
      dayHours: this.wksEntityParams.dayHours,
      weekStartsOn: this.wksEntityParams.weekStartsOn,
      weekendDays: this.wksEntityParams.weekendDays,
    };
    this.getListMechanics(this._userService.getUserLogged().entity)
    .then (
      (responseMechanics: any ) => {
        this.mechanicUsers = responseMechanics;
      },
      (err: any) => {
        console.log('getListMechanics' + err.message);
        if (err.status === 404) {
        }
      }
    );
   
    
  }
  
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
    // this.localelang = userLang;
    this.localelang =  this._translate.currentLang;
    this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
  }
  getListMechanics(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this._userService.getEntityUsersList(entityCur, 'MECHANIC')
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('getListMechanics' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }

  loadEvents(entityCur: string, actor: string): any {
    let listActors: string;
    if (actor === undefined) {
      for (const actorCur of this.mechanicUsers) {
        if (listActors === undefined) {
          listActors = actorCur.username;
        } else {
          listActors += ';' + actorCur.username;
        }
      }
    } else {
      listActors = actor;
    }

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActors(entityCur, listActors, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, 
        err => {
          console.log('loadEvents' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  loadEventsDate(entityCur: string): any {
    let listActors: string;
    for (const actor of this.mechanicUsers) {
      if (listActors === null) {
        listActors = actor.username;
      } else {
        listActors += ';' + actor.username;
      }
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const startDate = moment({year: year, month: month, day: day - 1 }).toDate();
    const endDate = moment({year: year, month: month + 2, day: day}).toDate();

    return new Promise((resolve, reject) => {
      this._calendarService.getEventsByActorsDates(entityCur, listActors, startDate, endDate, this.wksEntity.entityTz)
      .subscribe(
        data => {
          const response = data ;
          resolve(response.body);
        }, err => {
          console.log('loadEventsDate' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  refresh() {
    this.buildListEvents();
  }
  onChangeMechanic(event: { index: any, value: any }) {

    for (const userCur of this.mechanicUsers) {
      if (userCur.username === event.value) {
        this.actorSelected = userCur;
        break;
      }
    }
    this.buildListEvents();
  }
  buildListEvents() {
    this.doLoadCalendar = true;
    this.planningLoaded = false;
    this.eventsList = [];
    this.calendardEventLBddist = [];
    this.loadEvents(this._userService.getUserLogged().entity, this.actorSelected.username)
    .then (
      (responseParams1: any ) => {
        this.calendardEventLBddist = responseParams1;
        this.formatEvents();
        this.planningLoaded = true;
      },
      (err: any) => {
        if (err === 404) {
          this.planningLoaded = true;
        } else {
          console.log('loadEvents error : ' + err);
        }
      }
    );
  } 
  formatEvents() {
    this.eventsList = [];
    let evtId = 0;

    for (const eventCur of this.calendardEventLBddist) {
      let modifOption = false;
      if ((eventCur.calStatut === '') ||
        (eventCur.calStatut === null) ||
        (eventCur.calStatut === 'WAITING')) {
        modifOption = true;
      }
      if (eventCur.calDateStart === undefined) {
        eventCur.calDateStart = eventCur.calStart;
      }
      if (eventCur.calDateEnd === undefined) {
        eventCur.calDateEnd = eventCur.calEnd;
      }
      const otherDatas = JSON.parse(eventCur.calOtherdatas);
      this.eventsList.push({
        id: evtId,
        start: new Date(eventCur.calDateStart),
        end: new Date(eventCur.calDateEnd),
        title: eventCur.calTitle,     
        allDay: false,
        resizable: {
          beforeStart: modifOption,
          afterEnd: modifOption
        },
        draggable: modifOption,
        linkId: eventCur.calLinkid,
        calType: eventCur.calType,
        bddId: eventCur.id,
        calStatut: eventCur.calStatut,
        ref: (otherDatas && otherDatas.appointmentCustomer && otherDatas.appointmentCustomer.ref) ? otherDatas.appointmentCustomer.ref :
                                      (otherDatas && otherDatas.workAssignment && otherDatas.workAssignment.workRef) ? otherDatas.workAssignment.workRef : undefined,
        outside: eventCur.calOutside,
        stdCreationDate: eventCur.stdCreationDate,
        stdCreationUser: eventCur.stdCreationUser,
        oldTime: {
          start: new Date(eventCur.calDateStart),
          end: new Date(eventCur.calDateEnd),
        },
        calObject: eventCur.calObject,
      });
      evtId++;
    }
  }

  eventClicked(event: EventChanged): void {
    // console.log('eventClicked ' + JSON.stringify(event));
    // const eventBdd = this.fillModelBdd(event.eventCur);
    if (event.action === 'NewDate') {
      this.getWorkUUID(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang,  event.eventCur.linkId)
      .then (
        (data) => {
          this.workCur = data;
          this._calendarService.setCalObject(this.workCur);
          this.displayPlanning(event);
        },
        (err: any) => {
          console.log('getWorkUUID error : ' + err);
        }
      );

    } else {
      let isFound = false;
      if (this.workCur === undefined) {
        for (const workTmp of this.worksList) {
          if (workTmp.id === event.eventCur.linkId) {
            this.workCur  = workTmp;
            isFound = true;
            break;
          }
        }
        if (!isFound)  {
          this.getWorkUUID(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang,  event.eventCur.linkId)
          .then (
            (data) => {
              this.workCur = data;
              this.saveEvent(event.eventCur, event.action);
            },
            (err: any) => {
              console.log('getWorkUUID error : ' + err);
            }
          );
        } else {
          this.saveEvent(event.eventCur, event.action);
        }
      } else {
        this.saveEvent(event.eventCur, event.action);
      }
    }
  }
  updateListEvents(calendarEventBdd: CalendarEventBdd, bddAction: string): void {
    let idxRow = 0;
    for (const eventTmp of this.calendardEventLBddist) {
      if (eventTmp.id === calendarEventBdd.id ) {
        this.calendardEventLBddist.splice(idxRow, 1);
        if ( (bddAction !== 'deleted')) {
          this.calendardEventLBddist.push(calendarEventBdd);
        }
        break;
      }
      idxRow ++ ;
    }
    if (bddAction === 'add')  {
      this.calendardEventLBddist.push(calendarEventBdd);
    }
    this.formatEvents();
  }
  displayDetail(event: Event, row: number)  {
    
    if (this.localPopover === undefined) {
      return;
    } 
    this.dataInWork = {
      work: this.workCur,
      iRow: row,
    };
    // this.detailPopover.closePopover();
    if ( this.selectedRow !== row) {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
      return;
    } 
    // console.log('displayDetail : ' + row + ' displayPopover ' + this.displayPopover);
    // https://stackblitz.com/edit/mde-popover-dynamic-target-position?file=app%2Fapp.component.ts

    if (this.displayPopover) {
      this.localPopover.togglePopover();
      this.displayPopover = false;
    } else  {
      this.detailPopover._elementRef.nativeElement.style.top = '25px';
      this.detailPopover._elementRef.nativeElement.style.left = '15px';
      this.localPopover.openPopover();
      this.displayPopover = true;
    }
  }
  setClickedRow(row: number)  {

    if ( this.selectedRow === row) {
      this.selectedRow = -1;
      this.workCur = undefined;
    } else {
      this.selectedRow = row;
      this.workCur = this.worksList[row];
      this._calendarService.setCalObject(this.workCur);
    }
  }
  getWorksWaitingDate(_entity: string, _timeZone: string, _statutCur: string) {
    this.worksLoaded = false;
    let curentStatut = _statutCur;
    this.cacheDatatemp = [];
    this.worksList = [];
    if (_statutCur === 'AllStatuts') {
      curentStatut = undefined;
    }
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.getWorksByStatut(_entity,  this._userService.getUserLogged().userlang, curentStatut)
      .subscribe(
        data => {
          this.cacheDatatemp = [...data.body];
          this.worksList = [...data.body];
          this.addingValues();
          resolve();
        },
        err => {
          // console.log('getWorksWaitingDate' + err.message);
          this.worksLoaded = true;
          if (err.status === 404) {
          }
          // this.errorMessage('nodata', _statutCur);
          reject(err);
        });
      }
    );
  }
  addingValues(): void {
  // workTasksContent?: WksTasksModel[]
  let works: TasksActionsModel[] = [];
  let supplies: TasksActionsModel[] = [];
  let workEquipmentId: WorkEquipmentRef;
  for (const workItem of this.worksList) {
      let numberEquip = 0;
      workItem.workDuration = 0;
      workEquipmentId = JSON.parse(workItem.workEquip);
      if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
        numberEquip = 1;
      } else {
        numberEquip = workEquipmentId.subEquipments.length;
      } 
      let durationCur = 0;
      let equipmentsCur = 0;
      if (workItem.workTasksContent !== null && workItem.workTasksContent !== undefined) {
        for (const taskCur of workItem.workTasksContent) {
          const taskrefJson = JSON.parse( taskCur.taskrefJson);

          if (taskrefJson.tasksActionsContent !== undefined) {
            const tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
            works = tasksActionsContent.works;
            supplies = tasksActionsContent.supplies;
            if (tasksActionsContent.equipments.all) {
              equipmentsCur = numberEquip;
            } else {
              equipmentsCur = tasksActionsContent.equipments.equipId.length;
            }
            // console.log(tasksActionsContent);
          }
          if (taskrefJson.tasksActionsParentContent !== undefined) {
            const tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
            works.push(...tasksActionsParentContent.works);
            supplies.push(...tasksActionsParentContent.supplies);
          }
          if (works.length > 0 ) {
            durationCur = 0;
            for (const workTmp of works) {
              durationCur += Number(workTmp.quantity);
            }
            workItem.workDuration += (durationCur * equipmentsCur);
          } /* else {
            workItem.workDuration = 0;
          }*/
        } // end loop
      } // end if
      /*
      if (works.length > 0 ) {
        for (const workTmp of works) {
          durationCur += Number(workTmp.quantity);
        }
        workItem.workDuration = (durationCur * equipmentsCur);
      } else {
        workItem.workDuration = -1;
      }
      */
    }
  }
  displayRequest(event: EventChanged): void {
    if (this.localPopover === undefined) {
      return;
    } 
    if (event.action === 'displayRequest' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
        return;
      }
      this.getWorkUUID(this._userService.getUserLogged().entity,  this._userService.getUserLogged().userlang, event.eventCur.linkId)
        .then (
          (data) => {
            this.workCur = data;
            this.detailPopover._elementRef.nativeElement.style.top = '25px';
            this.detailPopover._elementRef.nativeElement.style.left = '15px';
            this.localPopover.openPopover();
            this.displayPopover = true;
            this.dataInWork = {
              work: this.workCur,
              iRow: -1,
            };
          },
          (err: any) => {
            console.log('displayRequest / getWorkUUID error : ' + err);
          }
        );
    }
    if (event.action === 'closePopover' )  {
      if (this.displayPopover) {
        this.localPopover.closePopover();
        this.displayPopover = false;
      }
    }
  }
  
  getWorkUUID(_entity: string, _langUser: string, _uuid: string) {

    return  new Promise<WksWorksModel>((resolve, reject) => {
      this._wksCentralService.getWorksById(_entity, _langUser, _uuid)
      .subscribe(
        data => {
          const response = data.body ;
          
          resolve(response);
        },
        err => {
          console.log('getWorkUUID' + err.message);
          reject(err);
        });
      }
    );
  }
  errorMessage(option: string, statut: string): void {
    const titleBox = this._translate.getTranslate('appointmentsSearch');
    let messageBox: string;
    if (option === 'nodata') {
      if ( statut !== undefined)  {
          messageBox = this._translate.getTranslate('statut') + ' : ' +  
                        this._translate.getTranslate(statut) + ' --> ' + 
                        this._translate.getTranslate('noAppointments') ;
      } else  {
        messageBox =  this._translate.getTranslate('noAppointments') ;
      }
    }
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', 'nodata');
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType,
      actionCur: _actionCur
      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (_actionCur === 'htmlMessage' ) {
          
        }
        if (( data === 'okAction') ) {
          
        }
        if ((_actionCur === 'nodata')) {
          // this.router.navigate(['/jobHome']);
          this.worksLoaded = true;
        }
      });

  }
  displayPlanning(event: EventChanged): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80%';
    dialogConfig.width = '70%';
    dialogConfig.data = {
      actorSelected: this.actorSelected,
      linkId: event.eventCur.linkId,
      workCur: this.workCur
      };

    const dialogRef = this._dialog.open(WorksPlanningComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') ) {
          
        } else {
          const eventTmp = data as EventChanged;
          this.saveEvent(eventTmp.eventCur, eventTmp.action);
        }

      });
  }
  saveEvent(eventCur: CalendarEvt, actionType: string) {
    let messageBox: string;
    let titleBox = this._translate.instant('Works assignments');
    messageBox =  this._translate.getTranslate('calendar_validation') ;
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'infoProgressWks', 'nodata');

    let statutBdd = 'newDate';
    let okToSplice = true;
    if (eventCur.bddAction === 'updated') {
      statutBdd = 'updateDate';
      okToSplice = false;
    }
    if (eventCur.bddAction === 'changed') {
      statutBdd = 'updateDate';
      okToSplice = false;
    }
    if (eventCur.bddAction === 'deleted') {
      statutBdd = 'cancelDate';
      okToSplice = false;
    }
    if (actionType === 'renewal') {
      statutBdd = 'newDate';
      eventCur.bddAction = 'updated';
      okToSplice = false;
    }
    this.submitCalendarInprogress = true;
    const calToValid = this.fillModelBdd(eventCur);
    this._calendarService.saveWksCalendar(calToValid, this.wksEntity.entityTz, statutBdd, this.actorSelected.email, this.workCur.ownerId)
    .subscribe(
      (data) => {
        // console.log('registerAppointment ' + appointmentItem + ' OK' );
        const calendarEventBddTmp = data.body;
        eventCur.bddId = calendarEventBddTmp.id;
        calToValid.id = calendarEventBddTmp.id;
        if (okToSplice) {
          this.worksList.splice(this.selectedRow, 1);
        }
        this.registerWork(this.workCur, eventCur);
        this.selectedRow = -1;
        this.updateListEvents(calToValid, eventCur.bddAction );
        this.submitCalendarInprogress = false;
        this._dialog.closeAll();
        titleBox = this._translate.instant('Works assignments');
        messageBox = this._translate.instant('calendar_valid', [this.workCur.workRef]);
        this.displayMessageBox(titleBox, messageBox, 'INFO', 'infoWks', 'htmlMessage');
      },
      (err) => {
        console.log('saveEvent ' + calToValid + ' KO ' + err );
        this._dialog.closeAll();
        titleBox = this._translate.instant('Works assignments');
        messageBox = this._translate.instant('calendar_not_valid', [this.workCur.workRef]);
        this.displayMessageBox(titleBox, messageBox, 'INFO', 'alertWks', 'htmlMessage');
      }
    );

  }
  registerWork(workItem: WksWorksModel, eventCur: CalendarEvt) {
    
    workItem.statutChange = true;
    workItem.workStatutDate = undefined;
    workItem.workPlanningId = eventCur.bddId;
    workItem.workStatut = 'assigned';
    if (eventCur.bddAction === 'deleted') {
      workItem.workPlanningId = undefined;
      workItem.workStatut = 'approved';
    }
    workItem = this._wksWorksService.fillTracking(workItem, this._userService.getUserLogged().username, workItem.workStatut, 'Planning ' + eventCur.bddAction);
    /*
    let wksWorksTrackingTmp: WksWorksTracking[];
    if (workItem.workJsonData) {
      const workJsonData = JSON.parse(workItem.workJsonData);
      if (workJsonData.wksWorksTracking) {
        wksWorksTrackingTmp = JSON.parse(JSON.stringify(workJsonData.wksWorksTracking));
      }
    }
    if (wksWorksTrackingTmp === undefined) {
      wksWorksTrackingTmp = [];
    }

    wksWorksTrackingTmp.push({
      numberTrack: (wksWorksTrackingTmp.length === 0) ? 0 : wksWorksTrackingTmp.length,
      userLogged: this._userService.getUserLogged().username,
      recordedTrack: undefined,
      workStatut: workItem.workStatut,
      workComment: 'Planning ' + eventCur.bddAction,
      alarmsMes: undefined
      }
    );
    workItem.wksWorksTracking = wksWorksTrackingTmp;
      */
    this._wksCentralService.saveWksWorks(workItem, 'update')
    .subscribe(
      dataReturn => {
        if (eventCur.bddAction === 'deleted' || eventCur.bddAction === 'add') {
          this.getWorksWaitingDate(this._userService.getUserLogged().entity, this.wksEntity.entityTz, 'approved')
          .then(
            () => {
              this.worksLoaded = true;
            },
            err => {
              this.worksLoaded = true;
            });
        }
      },
      () => {
      }
    );
   }
  fillModelBdd(eventCur: CalendarEvt): CalendarEventBdd {

    let idxTmp;
    try {
      if (CommonMethods.isUUID(eventCur.id.toString())) {
        idxTmp = eventCur.id.toString();
      }
    } catch (error) {
      idxTmp = undefined;
    }

    const idxCur = (idxTmp ? idxTmp : eventCur.bddId ? eventCur.bddId : undefined);

    // const workAssignment = {'ref':  eventCur.ref};
    const workAssignment =  {'workRef':  this.workCur.workRef};
    const othersData = ' {"workAssignment" :' + JSON.stringify(workAssignment) + ',"actorMail" : "' + this.actorSelected.email + '"}';
    const dateStartTime: any = moment(eventCur.start).format(environment.fmtDateTimeBdd);
    const dateEndTime: any = moment(eventCur.end).format(environment.fmtDateTimeBdd);
    const calendarEventBdd: CalendarEventBdd = {
      id: idxCur,
      stdEntity: this.wksEntity.stdEntity,
      calApplication: 'workAssignment',
      calType: eventCur.calType,
      calLinkid: eventCur.linkId,
      calStart: dateStartTime,
      calEnd: dateEndTime,
      calDateStart: dateStartTime,
      calDateEnd: dateEndTime,
      calAction: '',
      calTitle: eventCur.title,
      calOutside: eventCur.outside,
      calDescription: eventCur.description,
      calComment: eventCur.comment,
      calAllday: false,
      calActor:  this.actorSelected.username,
      calOtherdatas: othersData,
      calStatut: eventCur.calStatut,
      stdCreationDate: eventCur.stdCreationDate,
      stdCreationUser: eventCur.stdCreationUser,
    };

    return calendarEventBdd;
  }
}
