/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sn-renderer.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sn-renderer.component";
import * as i4 from "../../../../../../../services/translate.service";
var styles_SnRendererComponent = [i0.styles];
var RenderType_SnRendererComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SnRendererComponent, data: {} });
export { RenderType_SnRendererComponent as RenderType_SnRendererComponent };
function View_SnRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid "], ["style", "height: 100%;width: 350px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex-row row  w-100 rowOne"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12"], ["style", "padding-left: 0px;padding-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValue; _ck(_v, 3, 0, currVal_0); }); }
function View_SnRendererComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex-row row  w-100 rowOne"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12"], ["style", "padding-left: 0px;padding-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_0); }); }
function View_SnRendererComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex-row row  w-100 rowlist"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12"], ["style", "padding-left: 0px;padding-right: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_0); }); }
function View_SnRendererComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnRendererComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnRendererComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nbRows === 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.nbRows > 1); _ck(_v, 4, 0, currVal_1); }, null); }
function View_SnRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid "], ["style", "height: 100%;width: 350px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnRendererComponent_3)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowsSN; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SnRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnRendererComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnRendererComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentValue !== ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.allSN; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SnRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mdi-sn-renderer", [], null, null, null, View_SnRendererComponent_0, RenderType_SnRendererComponent)), i1.ɵdid(1, 114688, null, 0, i3.SnRendererComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnRendererComponentNgFactory = i1.ɵccf("mdi-sn-renderer", i3.SnRendererComponent, View_SnRendererComponent_Host_0, {}, {}, []);
export { SnRendererComponentNgFactory as SnRendererComponentNgFactory };
