/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./update-pwd.component";
import * as i2 from "../../../services/user.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../services/translate.service";
import * as i5 from "../../../job/common/services/global-events-manager.service";
import * as i6 from "@angular/material/dialog";
var styles_UpdatePwdComponent = [];
var RenderType_UpdatePwdComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UpdatePwdComponent, data: {} });
export { RenderType_UpdatePwdComponent as RenderType_UpdatePwdComponent };
export function View_UpdatePwdComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_UpdatePwdComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mdi-update-pwd", [], null, null, null, View_UpdatePwdComponent_0, RenderType_UpdatePwdComponent)), i0.ɵdid(1, 245760, null, 0, i1.UpdatePwdComponent, [i2.UserService, i3.Router, i4.TranslateService, i5.GlobalEventsManagerService, i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdatePwdComponentNgFactory = i0.ɵccf("mdi-update-pwd", i1.UpdatePwdComponent, View_UpdatePwdComponent_Host_0, {}, {}, []);
export { UpdatePwdComponentNgFactory as UpdatePwdComponentNgFactory };
