<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid stdTheme" style="margin-top: 75px; width: 100%;">

  <div class="flex-row row stdTheme" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
    <div class="col top-sidenav">
      <span class="horizontal-spacer5"></span>
      <span style="margin-left: 45%; font-size:large">{{ 'datasources' | translate }}</span>
    </div>
  </div>
  <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
    <mat-sidenav-container fxFlexFill class="container-sidenav w-100 stdTheme">
      <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav">
          <div fxLayout="column" *ngIf="isRegisterDatasource">
            <a (click)="toggleSidenav('')" *ngIf="doCreateDatasource" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}</a>
            <a (click)="toggleSidenav('')" *ngIf="!doCreateDatasource" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}</a>
            <a>
              <mdi-datasource-card [statutCall]="statutRegister" [datasourceToUpdate]="datasourceToUpdate" (onload)="ngOnInit()"></mdi-datasource-card>
            </a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content style="padding-left: 15px;">
<!-- https://swimlane.gitbook.io/ngx-datatable -->
        <div class="container-fluid ">
          <ngx-datatable [rows]="dataSource.data" class="material striped"  [columnMode]="'force'" 
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [reorderable]="reorderable" [limit]="pageSize" [sortable]="true"
            [draggable]="true" [resizeable]="true" [canAutoResize]="true" [scrollbarH]="true" [selected]="selected" 
            [selectionType]="'single'" (select)='onSelect($event)'>
            <ngx-datatable-column *ngFor="let col of displayedColumns" [name]="col.name" [prop]="col.prop">
                <ng-template *ngIf="col.isPwd" let-value="value" ngx-datatable-cell-template>
                  {{ pwdFormat}}
                </ng-template>
                <ng-template *ngIf="col.isDate" let-value="value" ngx-datatable-cell-template>
                  {{value | date: timeFormat}}
                </ng-template>
<!-- input inline example -->
              <!--
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">

                <span
                  title="{{'Double click to edit' | translate}}"
                  (dblclick)="editing[rowIndex + '-' + col.prop ] = true"
                  *ngIf="!editing[rowIndex + '-' + col.prop]">
                  {{value}}
                </span>
                <input
                  autofocus
                  (blur)="updateValue($event, col.prop, rowIndex)"
                  *ngIf="editing[rowIndex+ '-' + col.prop]"
                  type="text"
                  [value]="value"
                />

              </ng-template>
-->
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions">
              <ng-template let column="column" ngx-datatable-header-template>
                <button mat-icon-button class="buttonTheme" (click)="addDatasource()" style="margin-left: 10px;margin-right: 10px;">
                  <!--<mat-icon aria-label="Add">person_add</mat-icon>-->
                  <span class="fa fa-plus-square" style="font-size: 20px" ></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="refresh()" style="margin-left: 10px;margin-right: 10px;">
                  <!--<mat-icon aria-label="Add">person_add</mat-icon>-->
                  <span class="fas fa-redo" style="font-size: 20px" ></span>
                </button>
              </ng-template>
              <ng-template let-row="row" ; let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex">
                <button mat-icon-button class="buttonTheme" (click)="editDatasource(row)" *ngIf="doAction(row)"
                    matTooltip="{{ 'edit tooltip' | translate }}" style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-edit" style="font-size: 20px"></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="deleteDatasource(row, rowIndex)" *ngIf="doAction(row) "
                    matTooltip="{{ 'trash tooltip' | translate }}" style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-trash" style="font-size: 20px"></span>
                </button>
                <button mat-icon-button class="buttonTheme" (click)="tryDatasource(row, rowIndex); openModal(templateTest)" *ngIf="doAction(row) "
                   matTooltip="{{ 'try tooltip' | translate }}" style="margin-left: 10px;margin-right: 10px;">
                  <span class="fa fa-try" style="font-size: 20px"></span>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<!--https://valor-software.com/ngx-bootstrap/#/modals#confirm-window-->
<ng-template #templateTest>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'Test connect' | translate}}</h4>
    <button type="button" class="close pull-right buttonTheme" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isTestConnectOK" >
        <p>{{'test connection OK' | translate : modalText }}</p>
        <button type="button" class="btn btn-success pull-right buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
    <div *ngIf="!isTestConnectOK" >
        <p>{{'test connection KO' | translate : modalText }}</p>
        <button type="button" class="btn btn-danger pull-right buttonTheme" (click)="closeModal()" >{{'Close' | translate}}</button>
    </div>
  </div>
</ng-template>
