<div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 0px;" >
    <fieldset class="specification-border" >
        <mat-card *ngIf="displayOK" appMaterialElevation class="stdTheme w-100" 
                        style="padding: 0px; overflow-x: hidden; overflow-y: hidden; height: 650px">
            <mat-card-actions style="margin-bottom: 0px;padding-bottom: 0px;">
                <div class="flex-row row d-flex align-items-center" style="padding: 0px; padding-top: 10px;margin: 0px;overflow-y: hidden;">
                    <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                        <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancelSpec()">
                            <mat-icon>arrow_back</mat-icon>
                            {{ 'goback' | translate }}
                        </button>
                    </div>
                    <div class="col-10 d-flex justify-content-center">
                        <span *ngIf="specjobsInput !== undefined">
                          {{ 'jobPage' | translate }} <b>{{ specjobsInput.jobPage }}</b> / 
                          {{ 'equipModel' | translate }} <b>{{ specjobsInput.equipModel }}</b> / 
                          {{ 'jobLabel1' | translate }} <b>{{ specjobsInput.jobLabel }}</b>
                        </span>
                        <!--
                        <span *ngIf="specjobsUpdate === undefined">
                          {{ 'add tooltip' | translate }}
                        </span>
                        -->
                    </div>
                    <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                        <button [disabled]="isReadOnly || !okToRegister" mat-raised-button  #buttonSubmit type="submit" (click)="registerSpecification()" 
                            style="padding-left: 5px;padding-right: 5px;" >
                            <mat-icon>save</mat-icon>
                            {{ 'save' | translate }}
                        </button>
                    </div>
                </div>
                <div class="lineWks" style="margin-top: 5px;"></div>
            </mat-card-actions>
            <mat-card-actions style="margin-bottom: 0px;padding-bottom: 0px;" >
                <div class="flex-row row d-flex align-items-center " style="padding: 0px; margin: 0px" >
                    <div class="col-6 d-flex justify-content-center colRightBorder" style="padding-left: 0px;padding-right: 0px">
                        <span >
                            {{ 'jobDesc' | translate }}
                        </span>
                    </div>
                    <div class="col-6 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px">
                        <span >
                            {{ 'jobParts' | translate }}
                        </span>
                    </div>
                </div>
                <div class="lineWks" style="margin-top: 5px;"></div>
            </mat-card-actions>
            <mat-card-content style="height: 74%!important; margin-bottom: 0px;padding-bottom: 0px;">
                <div class="flex-row row " 
                            style="padding: 0px; margin-left: 0px; margin-right: 0px;padding-left: 10px;padding-right: 10px;height: 100%">
                    <div class="col-6 d-flex justify-content-center colRightBorder" style="padding-left: 0px;padding-right: 0px">
                        <form [formGroup]="specJobForm" class="w-100" >
                            <div class="flex-row row d-flex align-items-center rowSpecJobs" >
                                <div class="col-12 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                        <mat-label>{{ 'equipModel' | translate }}</mat-label>
                                        <input id="equipModel" #equipModel class="form-control inputTheme" 
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                formControlName="equipModel" required
                                                value="{{specjobsInput.equipModel}}"
                                                matInput (change)="onInputChange('equipModel',equipModel.value)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowSpecJobs" >
                                <div class="col-12 d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px;">
                                    <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 15px;width: 20%;">
                                        <mat-label>{{ 'jobPage' | translate }}</mat-label>
                                        <input id="jobPage" #jobPage class="form-control inputTheme" 
                                                formControlName="jobPage" required
                                                [ngStyle]="{'text-align': fieldFormat.justifNumbers}"
                                                value="{{specjobsInput.jobPage }}"
                                                (keypress)="numberAndDotOnly($event)"
                                                matInput (change)="onInputChange('jobPage',jobPage.value)">
                                    </mat-form-field>

                                    <mat-form-field class="wksInput form-group" appearance="standard" style="width: 20%;">
                                        <mat-label>{{ 'jobNumber' | translate }}</mat-label>
                                        <input id="jobNumber" #jobNumber class="form-control inputTheme" 
                                                formControlName="jobNumber" required
                                                [ngStyle]="{'text-align': fieldFormat.justifNumbers}"
                                                value="{{specjobsInput.jobNumber }}"
                                                (keypress)="numberAndDotOnly($event)"
                                                matInput (change)="onInputChange('jobNumber',jobNumber.value)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowSpecJobs" >
                                <div class="col-12 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <!--
                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                        <mat-label>{{ 'jobLabel' | translate }}</mat-label>
                                        <input id="jobLabel" #jobLabel class="form-control inputTheme" 
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                formControlName="jobLabel" required
                                                value="{{specjobsInput.jobLabel}}"
                                                matInput (change)="onInputChange('jobLabel',jobLabel.value)">
                                    </mat-form-field>
                                -->
                                    <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 0px" >
                                        <mat-label>{{ 'jobLabel' | translate }}</mat-label>
                                        <mat-select id="jobLabel" 
                                                panelClass="mdiselectcontent" #jobLabel 
                                                formControlName="jobLabel"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                (selectionChange)="onInputChange('jobLabel',jobLabel.value)"
                                                [value]="specjobsInput.jobLabel"
                                                ngDefaultControl >
                                            <mat-select-header>
                                                <div class="w-100" style="display: inline-block;" >
                                                    <input style="width: 80%" class="inputTheme" #newJobLabel placeholder="{{ 'new job label' | translate }}"  >
                                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                            *ngIf="newJobLabel.value" (click)="newJobLabel.value=''; ">
                                                        <mat-icon style="margin-top: 10px;">close</mat-icon>
                                                    </button>
                                                    <button mat-icon-button matSuffix aria-label="add" 
                                                        (click)="onInputChange('newJobLabel',newJobLabel.value)">
                                                        <mat-icon style="margin-top: 10px;">add</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-select-header>
                                            <mat-option class="selectOptionsTheme" *ngFor="let taskLabelCur of listTasksLabel" 
                                                [value]="taskLabelCur.value"  >{{taskLabelCur.label}}
                                            </mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowSpecJobs" style="margin-bottom: 10px!important">
                                <div class="col-12 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;margin-bottom: 20px;">
                                    <mat-form-field class="wksInput form-group" appearance="standard">
                                        <mat-label>{{ 'jobNotes' | translate }}</mat-label>
                                        <textarea  id="jobNotes" class="form-control inputTheme textarea1"  
                                                matInput #jobNotes placeholder="{{ 'jobNotes' | translate }}"
                                                formControlName="jobNotes"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="4"
                                                [value]="specjobsInput.jobNotes"
                                                (change)="onInputChange('jobNotes',jobNotes.value)"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowSpecJobs " >
                                <div class="col-12 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 15px;width: 20%">
                                        <mat-label>{{ 'specType' | translate }}</mat-label>

                                        <mat-select id="specType" 
                                                    panelClass="mdiselectcontent" #specType required
                                                    formControlName="specType"
                                                    [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                    (selectionChange)="onInputChange('specType',specType.value)"
                                                    [value]="specjobsInput.specType"
                                                    ngDefaultControl >
                                            <mat-option class="selectOptionsTheme" *ngFor="let specTypeCur of specTypeList" 
                                                [value]="specTypeCur.value"  >{{specTypeCur.value}}
                                            </mat-option>
                                        </mat-select>                      
                                    </mat-form-field>
                                    <span style="margin-top: 35px;margin-right: 15px;width: 10%">
                                        {{ 'frequency' | translate }}
                                    </span>
                                     <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 15px;width: 10%" >
                                        <mat-label>{{ 'periodicityHours' | translate }}</mat-label>

                                        <mat-select id="periodicityHours" 
                                                panelClass="mdiselectcontent" #periodicityHours required
                                                formControlName="periodicityHours"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                (selectionChange)="onInputChange('periodicityHours',periodicityHours.value)"
                                                [value]="periodicityInput.hours"
                                                ngDefaultControl >
                                            <mat-select-filter 
                                                [placeholder]="'Filter'" 
                                                [displayMember]="'label'" 
                                                [array]="hoursList" 
                                                (filteredReturn)="filteredhoursList = $event">
                                            </mat-select-filter>
                                            <mat-option class="selectOptionsTheme" *ngFor="let hourCur of filteredhoursList" 
                                                [value]="hourCur.value"  >{{hourCur.value}}
                                            </mat-option>
                                        
                                        </mat-select> 
                                    </mat-form-field>

                                    <mat-form-field class="wksInput form-group" appearance="standard" style="width: 10%">
                                        <mat-label>{{ 'periodicityMonths' | translate }}</mat-label>
                                        <mat-select id="periodicityMonths" 
                                                panelClass="mdiselectcontent" #periodicityMonths required
                                                formControlName="periodicityMonths"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                (selectionChange)="onInputChange('periodicityMonths',periodicityMonths.value)"
                                                [value]="periodicityInput.months"
                                                ngDefaultControl >
                                            <mat-option class="selectOptionsTheme" *ngFor="let monthCur of monthsList" 
                                                [value]="monthCur.value"  >{{monthCur.value}}
                                            </mat-option>
                                        </mat-select>   
                                    </mat-form-field>

                                    <span style="margin-top: 35px;margin-left: 15px;width: 10%">
                                        {{ 'jobLevel' | translate }}
                                    </span>

                                    <mat-radio-group class="level-radio-group"  #jobLevel="matRadioGroup" labelPosition="before"
                                            style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"
                                            [value]="jobLevel"
                                            formControlName="jobLevel"
                                            (change)="onInputChange('jobLevel',jobLevel.value)">
                                        <mat-radio-button class="radioButton" style="padding-right: 15px;margin-top: 35px;" 
                                                [checked]="!jobMajor"
                                                value="{{levelOptions.normal}}">{{ 'jobNormal' | translate }}
                                        </mat-radio-button>
                                        <mat-radio-button class="radioButton" style="margin-top: 35px;" 
                                                [checked]="jobMajor"
                                                value="{{levelOptions.major}}">{{ 'jobMajor' | translate }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowSpecJobs " >
                                <div class="col-11 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 0px" >
                                        <mat-label>{{ 'tasksReferenced' | translate }}</mat-label>
                                        <mat-select id="taskrefId" 
                                                panelClass="mdiselectcontent" #taskrefId 
                                                formControlName="taskrefId"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                (selectionChange)="onInputChange('taskrefId',taskrefId.value)"
                                                [value]="specjobsInput.taskrefId"
                                                ngDefaultControl >
                                            <mat-select-filter 
                                                [placeholder]="'filterInput' | translate" 
                                                [displayMember]="'taskrefLabel'" 
                                                [array]="listTasksFiltered" 
                                                (filteredReturn)="filteredTasksList = $event">
                                            </mat-select-filter>
                                            <mat-option class="selectOptionsTheme" *ngFor="let taskCur of filteredTasksList" 
                                                [value]="taskCur.taskrefId"  >{{taskCur.taskrefLabel}}
                                            </mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <button mat-icon-button style="margin-top: 50px;margin-right: 0px;width: 10%">
                                        <mat-icon (click)="onClearTask($event, specjobsInput.taskrefId)">clear</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="flex-row row d-flex align-items-center rowTitleSpecs" style="margin: 0px">
                                <div class="col-6 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                    <b>{{ 'workLabel' | translate }}</b>
                                </div>

                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                    <b>{{ 'quantity' | translate }}</b>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button mat-icon-button (click)="addRowWork()" [disabled]="isReadOnly"
                                        data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                        <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="w-100" style="margin-left: 10px; margin-right: 10px; height:50px; overflow-y: auto" >
                                <div *ngFor="let jobItem of specificationsJobsInput; let idxJob = index" >
                                    <div class="flex-row row d-flex align-items-center rowSpecs" >
                                        <!--
                                        <div class="col-5 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                            <select class="form-control" id="workCode" #workCode}  
                                                style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;width: 100%"
                                                class="selectArray"
                                                [ngClass]="{'selectArrayOdd':jobItem.isOdd,'selectArrayEven':jobItem.isEven}"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                [disabled]="isReadOnly"
                                                (change)="onInputJobsArrayChange('workCode',$event.target.value, idxJob)">
                                                <option class="selectOptionsTheme" 
                                                    *ngFor="let workCur of workCodeList" 
                                                        [(value)]="workCur" [selected]="workCur.value === jobItem.specificationsJob.workCode">
                                                        {{workCur.label}}
                                                </option>
                                            </select>
                                        </div>
                                        -->
         
                                        <div class="col-5 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                            <select class="form-control" id="actionRef" #actionRef}  
                                                style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;width: 100%"
                                                class="selectArray"
                                                [ngClass]="{'selectArrayOdd':jobItem.isOdd,'selectArrayEven':jobItem.isEven}"
                                                [ngStyle]="{'text-align': fieldFormat.justifText}"
                                                [disabled]="isReadOnly"
                                                (change)="onInputJobsArrayChange('actionRef', $event.target.value, idxJob)">
                                                <option class="selectOptionsTheme" 
                                                    *ngFor="let actionRefCur of actionRefList" 
                                                        [(value)]="actionRefCur.value" 
                                                        [selected]="actionRefCur.value === jobItem.specificationsJob.actionrefId">
                                                        {{actionRefCur.label}}
                                                </option>
                                            </select>
                                        </div> 
                                        
                                        <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">  
                                            <input type="number" style="width: 100%" class="inputArray"
                                                [ngClass]="{'inputArrayOdd':jobItem.isOdd,'inputArrayEven':jobItem.isEven}"
                                                [ngStyle]="{'text-align': fieldFormat.justifNumbers}"
                                                #quantity name="quantity" 
                                                [readonly]="isReadOnly"
                                                value="{{jobItem.specificationsJob.quantity}}"
                                                [direction]="langCur.langDirection"
                                                (keypress)="numberAndDotOnly($event)"
                                                (change)="onInputJobsArrayChange('quantity',quantity.value, idxJob)"/> 
                                        </div>
                                        <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;"> 
                                            <span>
                                                <button  mat-icon-button (click)="removeRowWork(idxJob)" title="{{'delete row' | translate }}"
                                                        [disabled]="isReadOnly">
                                                    <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                                </button>
                                            </span>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <div class="col-6" style="padding-left: 0px;padding-right: 0px">
                        <div  class="flex-row row d-flex align-items-center rowTitleSpecs" >
                            <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                              <b>{{ 'brandRef' | translate }}</b>
                            </div>
                            <div class="col-4 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                              <b>{{ 'brandLabel' | translate }}</b>
                            </div>
                            <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                <b>{{ 'quantity' | translate }}</b>
                            </div>
                            <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
            
                            </div>
                            <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                              <b>{{ 'jobAction' | translate }}</b>
                            </div>
                            <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                <button mat-icon-button (click)="addRowPartSpecifications()" [disabled]="isReadOnly"
                                    data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                    <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                        <div class="w-100" style="margin-left: 10px; margin-right: 10px; height:420px; overflow-y: auto" >
                            <div *ngFor="let partItem of specificationsPartsInput; let idxPart = index" >
                                <div class="flex-row row d-flex align-items-center rowSpecs" 
                                        [ngClass]="{'rowSpecOdd':partItem.isOdd,'rowSpecEven':partItem.isEven}" >
                                    <div class="col-2 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                        <input type="text" style="width: 100%" class="inputArray" 
                                            [ngClass]="{'inputArrayOdd':partItem.isOdd,'inputArrayEven':partItem.isEven}"
                                            [ngStyle]="{'text-align': fieldFormat.justifText}"
                                            #brandRef name="brandRef" 
                                            [readonly]="isReadOnly"
                                            value="{{partItem.specificationsParts.brandRef}}"
                                            [direction]="langCur.langDirection"
                                            (change)="onInputPartsArrayChange('brandRef',brandRef.value, idxPart)"/>
                                    </div>
                                    <div class="col-4 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                                        <input type="text" style="width: 100%" class="inputArray" 
                                            [ngClass]="{'inputArrayOdd':partItem.isOdd,'inputArrayEven':partItem.isEven}"
                                            [ngStyle]="{'text-align': fieldFormat.justifText}"
                                            #brandLabel name="brandLabel" 
                                            value="{{partItem.specificationsParts.brandLabel}}"
                                            [direction]="langCur.langDirection"
                                            (change)="onInputPartsArrayChange('brandLabel',brandLabel.value, idxPart)"/>
                                    </div>
                                    <div class="col-1 d-flex justify-content-center " style="padding-left: 0px;padding-right: 0px;">
                                        <input type="number" style="width: 100%" class="inputArray"
                                            [ngClass]="{'inputArrayOdd':partItem.isOdd,'inputArrayEven':partItem.isEven}"
                                            [ngStyle]="{'text-align': fieldFormat.justifNumbers}"
                                            #quantity name="quantity" 
                                            [readonly]="isReadOnly"
                                            value="{{partItem.specificationsParts.quantity}}"
                                            [direction]="langCur.langDirection"
                                            (keypress)="numberAndDotOnly($event)"
                                            (change)="onInputPartsArrayChange('quantity',quantity.value, idxPart)"/>
                                    </div>
                                    <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
    
                                    </div>
                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                        
                                        <select class="form-control" id="jobAction" #jobAction}  
                                            style="vertical-align: middle;padding-top: 0px;padding-bottom: 0px;"
                                            class="selectArray"
                                            [ngClass]="{'selectArrayOdd':partItem.isOdd,'selectArrayEven':partItem.isEven}"
                                            [ngStyle]="{'text-align': fieldFormat.justifText}"
                                            [disabled]="isReadOnly"
                                            (change)="onInputPartsArrayChange('jobAction',$event.target.value, idxPart)">
                                            <option class="selectOptionsTheme" 
                                                *ngFor="let actionCur of jobActionList" 
                                                    [(value)]="actionCur.value" [selected]="actionCur.value === partItem.action">
                                                    {{actionCur.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                        <span>
                                            <button  mat-icon-button (click)="removeRowSpecifications(idxPart)" title="{{'delete row' | translate }}"
                                                    [disabled]="isReadOnly">
                                                <span class="fa fa-trash" style="font-size: 15px" aria-hidden="true"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
            <mat-card-actions style="margin-bottom: 0px;padding-bottom: 0px;" >
                <div class="lineWks" style="margin-top: 5px;"></div>
                <div class="flex-row row d-flex align-items-center " style="padding: 0px; margin: 0px" >

                    <div class="col-4 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px">
                        <!--
                        <mat-checkbox #checked formControlName="checked"  
                            [labelPosition]="after"
                            (change)="onInputChange('checked',checked.checked)"
                            [checked]="specjobsInput.specJobOtherData.checked ? true : false">
                                <div *ngIf="specjobsInput.specJobOtherData.checked" class="text-wrap">
                                    <b>{{ 'checked' | translate }}</b>
                                </div>
                                <div *ngIf="!specjobsInput.specJobOtherData.checked" class="text-wrap">
                                    <b>{{ 'unchecked' | translate }}</b>
                                </div>
                        </mat-checkbox> 
                            -->
                        <mat-radio-group class="level-radio-group"  #checked="matRadioGroup" labelPosition="before"
                                style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"
                                [disabled]="isReadOnly || isNotValid"
                                [value]="checkOptions.value"
                                (change)="onInputChange('checked',checked.value)">
                            <mat-radio-button class="radioButton" style="padding-right: 15px;margin-top: 10px;" 
                                    [checked]="specjobsInput.specJobOtherData.checked"
                                    value="{{checkOptions.checked}}">{{ 'checked' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="radioButton" style="margin-top: 10px;" 
                                    [checked]="!specjobsInput.specJobOtherData.checked"
                                    value="{{checkOptions.unchecked}}">{{ 'unchecked' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px">

                    </div>
                    <div class="col-6 d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px">
                        <button mat-icon-button class="buttonTheme"  (click)="navJob('firstJob')" 
                                [disabled]="idxUpdate === 0 || okToRegister"
                                style="margin-left: 10px;margin-right: 10px;">
                            <span class="fa fa-step-backward" style="font-size: 20px" ></span>
                        </button>
                        <button mat-icon-button class="buttonTheme"  (click)="navJob('previousJob')" 
                                [disabled]="idxUpdate === 0 || okToRegister"
                                style="margin-left: 10px;margin-right: 10px;">
                            <span class="fa fa-chevron-left" style="font-size: 20px" ></span>
                        </button>
                        <button mat-icon-button class="buttonTheme"  (click)="navJob('nextJob')" 
                                [disabled]="idxUpdate === specjobsList.length - 1 || okToRegister"
                                style="margin-left: 10px;margin-right: 10px;">
                            <span class="fa fa-chevron-right" style="font-size: 20px" ></span>
                        </button>
                        <button mat-icon-button class="buttonTheme"  (click)="navJob('lastJob')" 
                                [disabled]="idxUpdate === specjobsList.length - 1 || okToRegister"
                                style="margin-left: 10px;margin-right: 10px;">
                            <span class="fa fa-step-forward" style="font-size: 20px" ></span>
                        </button>
                    </div>

                </div>

            </mat-card-actions>
        </mat-card>
    </fieldset>
</div>

